import React, { useState, useEffect, useContext } from "react"
import { getRequest } from "../../../services/rest_service"

import EducatorLevelEngagement from "../EducatorLevelEngagement"
import SingleColorBarGraph from "../../InsightsReports/singleColorBarGraph"
import MultiColorBarGraph from "../../InsightsReports/multiColorBarGraph"
import { InsightsReportsContext } from "../../../stores/insights_reports/InsightsReportsStore"
import { SET_EDUCATOR_LEVEL_REPORTS_DATA } from "../../../stores/insights_reports/InsightsReportsActions"
import { getFromDateAndToDate } from "../utilities"
import ReportGraph from "../ReportGraph"
import Loader from "../../custom/Loader"

// !definition of component
/**
 *
 * @description --> Educator Level Report for insights page
 * @returns EducatorLevelReport component
 */
// ! component

const EducatorLevelReport = () => {
  const [state, dispatch] = useContext(InsightsReportsContext)

  const [fetchedclassesEngagementList, setFetchedclassesEngagementList] = useState(true)
  const [fetchedSessionsEngagementList, setFetchedSessionsEngagementList] = useState(true)
  const [fetchedTopicsEngagementList, setFetchedTopicsEngagementList] = useState(true)

  const currentEducatorData =
    state.educatorLevelReportsData[`educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}_${state.inClassOrOverall}`]

  const currentEducatorDataWithoutFilter = state.educatorLevelReportsData[`educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}`]

  useEffect(() => {
    if (!currentEducatorData?.course_wise_engagement && state.activeEducatorData?.id && state.educatorLevelTimePeriod) {
      fetchCourseWiseEngagement(state.activeEducatorData?.id)
    }
    if (!currentEducatorData?.class_time_wise_engagement && state.activeEducatorData?.id && state.educatorLevelTimePeriod) {
      fetchClassTimeWiseEngagement(state.activeEducatorData?.id)
    }
    if (!currentEducatorData?.batch_size_wise_engagement && state.activeEducatorData?.id && state.educatorLevelTimePeriod) {
      fetchBatchSizeWiseEngagement(state.activeEducatorData?.id)
    }
    if (!currentEducatorData?.classes_engagement_list && state.activeEducatorData?.id && state.educatorLevelTimePeriod) {
      setFetchedclassesEngagementList(false)
      fetchClassesEngagementList(state.activeEducatorData?.id)
    }
  }, [state.educatorLevelTimePeriod, state.activeEducatorData?.id, state.inClassOrOverall])

  useEffect(() => {
    if (!currentEducatorDataWithoutFilter?.sessions_engagement_list && state.activeEducatorData?.id && state.educatorLevelTimePeriod) {
      setFetchedSessionsEngagementList(false)
      fetchSessionsEngagementList(state.activeEducatorData?.id)
    }
    if (!currentEducatorDataWithoutFilter?.topics_engagement_list && state.activeEducatorData?.id && state.educatorLevelTimePeriod) {
      setFetchedTopicsEngagementList(false)
      fetchTopicsEngagementList(state.activeEducatorData?.id)
    }
  }, [state.educatorLevelTimePeriod, state.activeEducatorData?.id])

  const fetchBatchSizeWiseEngagement = async (educator_id) => {
    try {
      const { data, success } = await getRequest(
        `/engagement-score-educator-reports/batch_size_wise_engagement/educator/${educator_id}/${getFromDateAndToDate(
          state.educatorLevelTimePeriod
        )}only_live_class=${state.inClassOrOverall === "In-Class"}`
      )
      if (success) {
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `batch_size_wise_engagement`,
            value: data,
          },
        })
      } else {
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `batch_size_wise_engagement`,
            value: {},
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }
  const fetchClassTimeWiseEngagement = async (educator_id) => {
    try {
      const { data, success } = await getRequest(
        `/engagement-score-educator-reports/class_time_wise_engagement/educator/${educator_id}/${getFromDateAndToDate(state.educatorLevelTimePeriod)}`
      )
      if (success) {
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `class_time_wise_engagement`,
            value: data,
          },
        })
      } else {
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `class_time_wise_engagement`,
            value: {},
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchCourseWiseEngagement = async (educator_id) => {
    try {
      const { data, success } = await getRequest(
        `/engagement-score-educator-reports/course_wise_engagement/educator/${educator_id}/${getFromDateAndToDate(
          state.educatorLevelTimePeriod
        )}only_live_class=${state.inClassOrOverall === "In-Class"}`
      )
      if (success) {
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `course_wise_engagement`,
            value: data,
          },
        })
      } else {
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `course_wise_engagement`,
            value: {},
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchClassesEngagementList = async (educator_id) => {
    try {
      const { data, success } = await getRequest(
        `/engagement-score-educator-reports/training_wise_engagement/educator/${educator_id}/${getFromDateAndToDate(
          state.educatorLevelTimePeriod
        )}only_live_class=${state.inClassOrOverall === "In-Class"}`
      )
      if (success) {
        setFetchedclassesEngagementList(true)
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `classes_engagement_list`,
            value: data,
          },
        })
      } else {
        setFetchedclassesEngagementList(true)
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `classes_engagement_list`,
            value: [],
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchSessionsEngagementList = async (educator_id) => {
    try {
      const { data, success } = await getRequest(
        `/engagement-score-educator-reports/session_wise_engagement/educator/${educator_id}/${getFromDateAndToDate(state.educatorLevelTimePeriod)}`
      )
      if (success) {
        setFetchedSessionsEngagementList(true)
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}`,
            report_type: `sessions_engagement_list`,
            value: data,
          },
        })
      } else {
        setFetchedSessionsEngagementList(true)
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}`,
            report_type: `sessions_engagement_list`,
            value: [],
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchTopicsEngagementList = async (educator_id) => {
    try {
      const { data, success } = await getRequest(
        `/engagement-score-educator-reports/topic_wise_engagement/educator/${educator_id}/${getFromDateAndToDate(state.educatorLevelTimePeriod)}`
      )
      if (success) {
        setFetchedTopicsEngagementList(true)
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}`,
            report_type: `topics_engagement_list`,
            value: data,
          },
        })
      } else {
        setFetchedTopicsEngagementList(true)
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}`,
            report_type: `topics_engagement_list`,
            value: [],
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="w-100">
      <ReportGraph title="Courses">
        <MultiColorBarGraph title="Courses" graphData={currentEducatorData?.course_wise_engagement} />
      </ReportGraph>
      <ReportGraph title="Class Timings">
        <MultiColorBarGraph title="Class Timings" graphData={currentEducatorData?.class_time_wise_engagement} />
      </ReportGraph>
      <ReportGraph title="Batch Size">
        <MultiColorBarGraph title="Batch Size" graphData={currentEducatorData?.batch_size_wise_engagement} />
      </ReportGraph>
      <ReportGraph title="Classes Enagement">
        {!fetchedclassesEngagementList ? (
          <div className="w-100 d-flex h-32vh justify-center align-items-center">
            <Loader class={`text-white mx-auto`} />
          </div>
        ) : (
          <EducatorLevelEngagement title={"Classes"} icon={true} calender={true} data={currentEducatorData?.classes_engagement_list} />
        )}
      </ReportGraph>
      <ReportGraph title="Sessions Engagement">
        {!fetchedSessionsEngagementList ? (
          <div className="w-100 d-flex h-32vh justify-center align-items-center">
            <Loader class={`text-white mx-auto`} />
          </div>
        ) : (
          <EducatorLevelEngagement
            title={"Sessions"}
            type={`sessions-list`}
            calender={false}
            data={currentEducatorDataWithoutFilter?.sessions_engagement_list}
          />
        )}
      </ReportGraph>
      <ReportGraph title="Topics Engagement">
        {!fetchedTopicsEngagementList ? (
          <div className="w-100 d-flex h-32vh justify-center align-items-center">
            <Loader class={`text-white mx-auto`} />
          </div>
        ) : (
          <EducatorLevelEngagement title={"Topics"} type={`topics-list`} calender={false} data={currentEducatorDataWithoutFilter?.topics_engagement_list} />
        )}
      </ReportGraph>
    </div>
  )
}

export default EducatorLevelReport
