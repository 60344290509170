import React, { useEffect, useState, useCallback, useRef } from "react"
import _ from "lodash"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Card from "react-bootstrap/Card"
import Spinner from "react-bootstrap/Spinner"
import Dropdown from "react-bootstrap/Dropdown"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import Datetime from "react-datetime"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Droppable, Draggable } from "react-beautiful-dnd"
import Styles from "../../modularscss/styles.module.scss"
import StylesA from "./breakout-session.module.scss"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"
import Image from "../../components/image"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner, faChevronCircleUp, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons"

import video from "../../images/svgs/video.svg"
import link from "../../images/svgs/link.svg"
import turientimage from "../../images/svgs/turientimage.svg"
import blog from "../../images/svgs/blog.svg"
import quiz from "../../images/svgs/quiz.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import DragHandleIcon from "../../images/svgs/drag-handle-white.svg"
import CancelWhite from "../../images/svgs/close.svg"
import TrashCan from "../../images/svgs/trash-can-transparent.svg"
import CheckIcon from "../../images/svgs/check-white.svg"
import { WsConnect } from "../../services/socket"

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  background: "#303C54",
  boxShadow: "0px 3px 6px #00000029",

  // styles we need to apply on draggables
  ...draggableStyle,
})
const getTopicItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  border: "1px solid transparent",
  color: "#E0E2E5",
  background: "#42506C",
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})
const getLessonTopicItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "0px",
  border: "1px solid transparent",
  color: "#E0E2E5",
  background: "#42506C",
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})
const getResourceItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  // background: '#42506C',
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#000" : "#12172c",
  background: "#212C42",
  padding: "8px 8px 1px",
})
const getTopicListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#000" : "#12172c",
  // background: '#212C42',
  padding: grid,
})
const getLessonListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#000" : "#12172c",
  // background: '#212C42',
  padding: "0px",
})

const DateInputField = (props) => {
  const [inputValue, setInputValue] = useState(props.datum.start_date ? new Date(props.datum.start_date) : "")

  useEffect(() => {
    if (props.selectedInput === "module_" + props.datum.id + "start_date") {
      setInputValue(props.datum.start_date ? new Date(props.datum.start_date) : "")
    }
  }, [props.datum.start_date])

  function sendQuery(name, value, datum, type) {
    props.handleDurationSave(datum, value, name, type)
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )
  return (
    <Col
      lg="3"
      className={`${StylesA.border_on_hover} ml-2`}
      style={{ borderRadius: "8px", display: "flex", alignItems: "center", backgroundColor: "#42506C", border: "1px solid transparent" }}
    >
      <Datetime
        dateFormat={false}
        timeFormat="hh:mm A"
        closeOnSelect={true}
        onChange={(e) => {
          setInputValue(e)
          delayedQuery("start_date", e, props.datum, "module")
        }}
        value={inputValue}
        inputProps={{ placeholder: "Start time", className: "video-modal p-0" }}
        style={{ background: "inherit", color: "inherit", height: "100%" }}
        className={`module_start_time_picker blue-bg video-modal`}
      />
    </Col>
  )
}

const DurationInputField = (props) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const [timeType, setTimeType] = useState("Mins")
  const [inputValue, setInputValue] = useState(props.datum.duration)

  // useEffect(() => {
  //   if (inputValue !== props.datum.duration) {
  //     let current_value = timeType === 'Mins' ? inputValue : inputValue * 60
  //     props.handleDurationSave(props.datum,current_value,'duration','module')
  //   }
  // }, [inputValue])
  useEffect(() => {
    if (props.selectedInput === "module_" + props.datum.id + "duration") {
      setInputValue(props.datum.duration)
    }
  }, [props.datum.duration])

  function sendQuery(name, value, datum, type) {
    props.handleDurationSave(datum, value, name, type)
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, datum, type) => {
      let current_value = timeType === "Mins" ? value : value * 60
      sendQuery(name, current_value, datum, type)
    }, 1000),
    []
  )

  return (
    <div className="col-3 px-0" style={{ position: "relative" }}>
      {showDropDown ? (
        <input
          autoComplete="off"
          type="number"
          min="1"
          name="duration"
          autoFocus
          id={props.datum.id + `_module_duration`}
          value={inputValue}
          onChange={(e) => {
            e.preventDefault()
            setInputValue(e.target.value)
            delayedQuery("duration", e.target.value, props.datum, "module")
          }}
          placeholder={"Session duration"}
          className={`${StylesA.session_input_field} ${StylesA.session_number_field} form-control px-3 ${Styles.font_16}`}
          onClick={() => setShowDropDown(!showDropDown)}
          onBlur={() => setShowDropDown(false)}
          disabled={props.datum.session_type && props.datum.session_type === "2"}
          required
        />
      ) : (
        <div
          disabled={props.datum.session_type && props.datum.session_type === "2"}
          style={{ display: "flex", alignItems: "center", borderRadius: "8px", cursor: "text" }}
          onClick={() => {
            if (props.datum.session_type && props.datum.session_type !== "2") {
              setShowDropDown(!showDropDown)
            }
          }}
          className={`${StylesA.session_input_field} ${StylesA.border_on_hover} ${StylesA.session_number_field} px-2 ${Styles.font_16}`}
        >
          Duration: {inputValue} {timeType}
        </div>
      )}
      <Dropdown.Menu show={showDropDown} className={`${showDropDown ? StylesA.active_input_drop_down : ""}`}>
        {timeType === "Mins" ? (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(10)
                setShowDropDown(false)
              }}
            >
              <p>10 mins</p>
            </div>
            <div
              onClick={() => {
                setInputValue(20)
                setShowDropDown(false)
              }}
            >
              <p>20 mins</p>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(1)
                setShowDropDown(false)
              }}
            >
              <p>1 hrs</p>
            </div>
            <div
              onClick={() => {
                setInputValue(2)
                setShowDropDown(false)
              }}
            >
              <p>2 hrs</p>
            </div>
          </React.Fragment>
        )}
        <div style={{ fontSize: "12px", display: "flex", justifyContent: "flex-end" }}>
          <p onClick={() => setTimeType("Mins")} style={{ opacity: timeType === "Mins" ? 1 : 0.5 }}>
            Mins
          </p>
          <p onClick={() => setTimeType("Hours")} style={{ marginLeft: "10px", opacity: timeType === "Hours" ? 1 : 0.5 }}>
            Hours
          </p>
        </div>
      </Dropdown.Menu>
    </div>
  )
}

const SessionPlan = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  // for modules basic name list
  const [modules, setModules] = useState([])
  const [moduleFetched, setModuleFetched] = useState(false)
  const [modulesNext, setModulesNext] = useState(null)
  const [modulesLoader, setModulesLoader] = useState(false)
  const [moduleCreated, setModuleCreated] = useState(true)
  const [loader, setLoader] = useState(false)
  const [topicCreated, setTopicCreated] = useState(false)
  // for slected module
  const [currentModuleFetched, setCurrentModuleFetched] = useState(false)
  // for selected module topics basic name list
  const [currentModuleTopicsFetched, setCurrentModuleTopicsFetched] = useState(false)
  // to open delete modal of topic and module
  const [dmodal, setDmodal] = useState(false)
  const [selectedDeletingModule, setSelectedDeletingModule] = useState([])
  const [selectedDeletingTopic, setSelectedDeletingTopic] = useState([])
  // selceted module id
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedModuleToggle, setSelectedModuleToggle] = useState({})
  const [selectedModuleToggleChange, setSelectedModuleToggleChange] = useState(false)
  // selceted topic id
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedTopicToggle, setSelectedTopicToggle] = useState({})
  const [selectedTopicToggleChange, setSelectedTopicToggleChange] = useState(false)

  // each module full data in modules array
  const [modulesData, setModulesData] = useState({})

  // each topic full data in topics array
  const [topicFetched, setTopicFetched] = useState(false)
  const [topicsData, setTopicsData] = useState({})

  // for topics and module resources and respective loader
  const [modulesResources, setModulesResources] = useState({})
  const [modulesResourcesNext, setModulesResourcesNext] = useState({})
  const [modulesResourcesFetched, setModulesResourcesFetched] = useState(false)
  const [modulesResourcesFetchedx, setModulesResourcesFetchedx] = useState(true)
  const [topicsResources, setTopicsResources] = useState({})
  const [topicsResourcesNext, setTopicsResourcesNext] = useState({})
  const [topicsResourcesNextLoader, setTopicsResourcesNextLoader] = useState({})
  const [topicsResourcesFetched, setTopicsResourcesFetched] = useState(false)
  const [topicsResourcesFetchedex, setTopicsResourcesFetchedex] = useState({})
  const [topicsResourcesFetchedxx, setTopicsResourcesFetchedxx] = useState(true)
  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("video")
  // each resource type full data in allResources array
  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(false)

  const [searchValue, setSearchValue] = useState("")
  const [resourceDeleted, setResourceDeleted] = useState(true)
  const [isPublished, setIsPublished] = useState(true)
  const [initialFetched, setInitialFetched] = useState(false)
  const [collapse, setCollapse] = useState(false)

  const [visibleTopicData, setVisibleTopicData] = useState({})
  const [visibleTopicDataFetched, setVisibleTopicDataFetched] = useState(false)
  const [deleteVisibleResource, setDeleteVisibleResource] = useState([])

  const [lessonPlanModules, setLessonPlansModules] = useState([])
  const [lessonPlanTopics, setLessonPlansTopics] = useState({})
  const [lessonPlansFetched, setLessonPlansFetched] = useState(false)
  const [lessonPlansNext, setLessonPlansNext] = useState(null)
  const [lessonLoader, setLessonLoader] = useState(false)
  const [currentLessonModule, setCurrentLessonModule] = useState("")

  const [dataUpdated, setDataUpdated] = useState(false)
  const [moduleTriggerd, setModuleTriggerd] = useState({})
  const [topicsRefetched, setTopicsRefetched] = useState(false)
  const [bulkTopicsRefetched, setBulkTopicsRefetched] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)
  const [lessonPlanRefetched, setLessonPlanRefetched] = useState(false)
  const [topicEdited, setTopicEdited] = useState(false)

  const [updateInputData, setUpdateInputData] = useState({})
  const [topicDataModified, setTopicDataModified] = useState(false)
  const [selectedInput, setSelectedInput] = useState("")

  const fetchDataWs = useRef()

  useEffect(() => {
    connectSocket()
    return () => {
      if (fetchDataWs && fetchDataWs.current) {
        fetchDataWs.current.close()
      }
    }
  }, [])

  useEffect(() => {
    if (modules && modules.length > 0 && initialFetched && Object.keys(moduleTriggerd).length > 0) {
      if (moduleTriggerd.type === "module") {
        var index = modules.findIndex((res) => res.id == moduleTriggerd.id)
        if (index > -1) {
          let datum = modules
          datum[index].session_type = moduleTriggerd.value
          setModules(datum)
        }
        if (moduleTriggerd.current_module && moduleTriggerd.current_module !== "end_session" && moduleTriggerd.current_module !== "to_lesson_plan") {
          if (modulesData[`module_` + moduleTriggerd.id]) {
            fetchCurrentTopics(true, moduleTriggerd.id, moduleTriggerd.current_module)
          }
          setSelectedInput("fetched")
        } else if (moduleTriggerd.current_module && moduleTriggerd.current_module === "to_lesson_plan") {
          setCurrentModuleTopicsFetched(false)
          fetchCurrentTopics(true, moduleTriggerd.id)
          setLessonPlanRefetched(false)
          fetchLessonPlanTopics(lessonPlanModules[0].id)
        }
      } else if (moduleTriggerd.type === "topic") {
        if (modulesData[`module_` + moduleTriggerd.module_id] && modulesData[`module_` + moduleTriggerd.module_id].topics) {
          var index = modulesData[`module_` + moduleTriggerd.module_id].topics.findIndex((res) => res.id === parseInt(moduleTriggerd.topic_id))
          if (index > -1) {
            modulesData[`module_` + moduleTriggerd.module_id].topics[index].topic_stage = moduleTriggerd.value
          }
        }
      } else if (moduleTriggerd.type === "resources") {
        if (topicsResources[`topic_` + moduleTriggerd.id]) {
          setTopicsRefetched(false)
          fetchTopicResources(moduleTriggerd.id, true)
        }
        if (moduleTriggerd.value !== null && topicsResources[`topic_` + moduleTriggerd.value]) {
          setBulkTopicsRefetched(false)
          fetchTopicResources(moduleTriggerd.value, true, true)
        }
      } else if (moduleTriggerd.type === "lessonplanTopicCreated") {
        if (lessonPlanModules && lessonPlanModules.length > 0) {
          setLessonPlanRefetched(false)
          fetchLessonPlanTopics(lessonPlanModules[0].id)
        }
      } else if (moduleTriggerd.type === "lptopicdeleted") {
        if (moduleTriggerd.id === null) {
          setLessonPlanRefetched(false)
          fetchLessonPlanTopics(lessonPlanModules[0].id)
        } else {
          setCurrentModuleTopicsFetched(false)
          fetchCurrentTopics(true, moduleTriggerd.id)
        }
      } else if (moduleTriggerd.type === "lptopicedited") {
        if (moduleTriggerd.session_data === null) {
          let mte_index = lessonPlanTopics[`module_` + lessonPlanModules[0].id].topics.findIndex((res) => res.id === parseInt(moduleTriggerd.data.id))
          if (mte_index > -1) {
            let resource_data = { ...lessonPlanTopics }
            resource_data[`module_` + lessonPlanModules[0].id].topics[mte_index][moduleTriggerd.field] = moduleTriggerd.data.name
            setSelectedInput("topic_" + moduleTriggerd.data.id + moduleTriggerd.field)
            setLessonPlansTopics(resource_data)
          }
        } else if (modulesData[`module_` + moduleTriggerd.session_data.id] && modulesData[`module_` + moduleTriggerd.session_data.id].topics) {
          let module_id = moduleTriggerd.session_data.id
          let mte_index = modulesData[`module_` + moduleTriggerd.session_data.id].topics.findIndex((res) => res.id === parseInt(moduleTriggerd.data.id))
          if (mte_index > -1) {
            let resource_data = { ...modulesData }
            resource_data[`module_` + moduleTriggerd.session_data.id].topics[mte_index][moduleTriggerd.field] = moduleTriggerd.data.name
            setSelectedInput("topic_" + moduleTriggerd.data.id + moduleTriggerd.field)
            setModulesData(resource_data)
          }
        }
      } else if (moduleTriggerd.type === "lbsessionedited") {
        if (modules && modules.length > 0) {
          var index = modules.findIndex((res) => res.id === parseInt(moduleTriggerd.data.id))
          if (index > -1) {
            let current_modules = [...modules]
            if (moduleTriggerd.field === "start_date") {
              current_modules[index][moduleTriggerd.field] = new Date(parseInt(moduleTriggerd.data.name))
            } else {
              current_modules[index][moduleTriggerd.field] = moduleTriggerd.data.name
            }
            setModules(current_modules)
            setSelectedInput("module_" + moduleTriggerd.data.id + moduleTriggerd.field)
          }
        }
      } else if (moduleTriggerd.type === "lbtopicreordred") {
        setCurrentModuleTopicsFetched(false)
        fetchCurrentTopics(true, moduleTriggerd.id)
      } else if (moduleTriggerd.type === "lbsessiondeleted") {
        if (modules && modules.length > 0) {
          var index = modules.findIndex((res) => res.id === parseInt(moduleTriggerd.id))
          modules.splice(index, 1)
        }
        if (moduleTriggerd.value === "to_lesson_plan") {
          setLessonPlanRefetched(false)
          fetchLessonPlanTopics(lessonPlanModules[0].id)
        } else if (moduleTriggerd.value > 0) {
          setCurrentModuleTopicsFetched(false)
          fetchCurrentTopics(true, moduleTriggerd.value)
        }
      } else if (moduleTriggerd.type === "lbtopicdeleted") {
        let module_id = moduleTriggerd.previos_module
        let mte_index = -1
        if (modulesData[`module_` + moduleTriggerd.previos_module] && modulesData[`module_` + moduleTriggerd.previos_module].topics) {
          mte_index = modulesData[`module_` + moduleTriggerd.previos_module].topics.findIndex((res) => res.id === parseInt(moduleTriggerd.id))
        } else {
          mte_index = -2
        }
        if (mte_index > -1) {
          let resource_data = { ...modulesData }
          if (moduleTriggerd.current_module === "to_lesson_plan") {
            resource_data[`module_` + moduleTriggerd.previos_module].topics[mte_index].session_data = null
          } else {
            resource_data[`module_` + moduleTriggerd.previos_module].topics[mte_index].session_data.id = moduleTriggerd.current_module
          }
          let [current_topic] = resource_data[`module_` + moduleTriggerd.previos_module].topics
          if (moduleTriggerd.current_module === "to_lesson_plan") {
            let lesson_resource_data = { ...lessonPlanTopics }
            lesson_resource_data[`module_` + lessonPlanModules[0].id].topics.push(current_topic)
            setLessonPlansTopics(lesson_resource_data)
          } else {
            if (resource_data[`module_` + moduleTriggerd.current_module] && resource_data[`module_` + moduleTriggerd.current_module].topics) {
              resource_data[`module_` + moduleTriggerd.current_module].topics.push(current_topic)
            }
          }
          resource_data[`module_` + moduleTriggerd.previos_module].topics.splice(mte_index, 1)
          setSelectedInput("topic_" + moduleTriggerd.id + moduleTriggerd.field)
          setModulesData(resource_data)
        }

        if (mte_index === -2) {
          if (moduleTriggerd.current_module === "to_lesson_plan") {
            setLessonPlanRefetched(false)
            fetchLessonPlanTopics(lessonPlanModules[0].id)
          }
        }
      }
      setModuleTriggerd({})
      setDataUpdated(!dataUpdated)
    } else if (Object.keys(moduleTriggerd).length === 0) {
      setSelectedInput("")
      setTopicDataModified(!topicDataModified)
    }
  }, [Object.keys(moduleTriggerd).length > 0])

  function connectSocket() {
    if (authClient.isAuthenticated()) {
      let updateSocket = WsConnect(`/ws/fetch-data/${props.template_id}/`)
      updateSocket.onmessage = function (e) {
        let responseData = JSON.parse(JSON.parse(e.data).value)
        if (responseData.fetch_type.indexOf("spsessionupdate") > -1) {
          setModuleTriggerd({
            id: responseData.id,
            value: responseData.value.split("_$_")[0],
            type: "module",
            current_module: responseData.value.split("_$_")[1],
          })
        } else if (responseData.fetch_type.indexOf("topic_") > -1) {
          setModuleTriggerd({
            topic_id: responseData.fetch_type.split("topic_")[1],
            module_id: responseData.id,
            value: responseData.value.split("_$_")[0],
            type: "topic",
          })
        } else if (responseData.fetch_type.indexOf("lessonresources") > -1) {
          setModuleTriggerd({ id: responseData.id, type: "resources", value: responseData.value })
        } else if (responseData.fetch_type.indexOf("lessonplanTopicCreated") > -1) {
          setModuleTriggerd({ type: "lessonplanTopicCreated" })
        } else if (responseData.fetch_type.indexOf("lptopicdeleted") > -1) {
          setModuleTriggerd({ type: "lptopicdeleted", id: responseData.id, value: responseData.value })
        } else if (responseData.fetch_type.indexOf("lptopicedited") > -1 || responseData.fetch_type.indexOf("lbtopicedited") > -1) {
          setModuleTriggerd({
            field: responseData.id.split("_$_")[2],
            type: "lptopicedited",
            data: { id: responseData.id.split("_$_")[0], name: responseData.id.split("_$_")[1] },
            session_data: responseData.value === null ? null : { id: responseData.value.split("_")[0], session_type: responseData.value.split("_")[1] },
          })
        } else if (responseData.fetch_type.indexOf("lbsessionedited") > -1) {
          setModuleTriggerd({
            field: responseData.value,
            type: "lbsessionedited",
            data: { id: responseData.id.split("_$_")[0], name: responseData.id.split("_$_")[1] },
          })
        } else if (responseData.fetch_type.indexOf("lbtopicreordred") > -1) {
          setModuleTriggerd({ type: "lbtopicreordred", id: responseData.id })
        } else if (responseData.fetch_type.indexOf("lbsessiondeleted") > -1) {
          setModuleTriggerd({ type: "lbsessiondeleted", id: responseData.id, value: responseData.value })
        } else if (responseData.fetch_type.indexOf("lbtopicdeleted") > -1) {
          setModuleTriggerd({
            type: "lbtopicdeleted",
            id: responseData.id.split("_$_")[0],
            previos_module: responseData.id.split("_$_")[1],
            current_module: responseData.value,
          })
        }
      }
      updateSocket.onclose = function (e) {
        if (!e.wasClean) {
          setTimeout(connectSocket(), 5000)
        }
      }
      updateSocket.onopen = (e) => {
        fetchDataWs.current = updateSocket
      }
    }
  }

  function triggerChanges(fetch_type, id = null, value = null) {
    if (fetch_type) {
      let datum_id = id === null ? `` : `?id=${id}`
      let value_data = value === null ? `` : `&value=${value}`
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/lesson-board-triggering/${props.template_id}/${fetch_type}/${datum_id}${value_data}`, {
          headers: login_credentials,
        })
        .then((res) => {})
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  /*start-drag and drop all functions*/
  useEffect(() => {
    fetchModuleResources()
  }, [selectedModule])

  useEffect(() => {
    if (props.dragEnded && props.draggedItem) {
      onDragEnd(props.draggedItem)
      props.resetDragState()
    }
  }, [props.dragEnded])

  useEffect(() => {
    if (modulesResourcesFetched === false) {
      setModulesResourcesFetched(true)
    }
  }, [modulesResources[`module_` + selectedModule]])

  useEffect(() => {
    if (modulesResourcesFetchedx === false) {
      setModulesResourcesFetchedx(true)
    }
  }, [modulesResources[`module_` + selectedModule]])

  async function fetchModuleResources() {
    if (selectedModule !== null && modulesResources[`module_` + selectedModule] === undefined) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/resources-names-list/?module=${selectedModule}&paginate_by=10`, { headers: login_credentials })
        .then((res) => {
          modulesResources[`module_` + selectedModule] = res.data.results
          modulesResourcesNext[`module_` + selectedModule] = res.data.next === null ? {} : { next: res.data.next }
        })
    }
  }

  async function fetchNextModuleResources(next_url) {
    setModulesResourcesFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_module_resources = modulesResources[`module_` + selectedModule]
      if (res.data.results.length > 0) {
        for (var mrs = 0; mrs <= res.data.results.length - 1; mrs++) {
          next_module_resources.push(res.data.results[mrs])
        }
        modulesResources[`module_` + selectedModule] = next_module_resources
      }
      modulesResourcesNext[`module_` + selectedModule] = res.data.next === null ? {} : { next: res.data.next }
      setModulesResourcesFetched(true)
    })
  }

  useEffect(() => {
    if (selectedTopic != null) {
      fetchTopicResources()
    }
  }, [selectedTopic])

  useEffect(() => {
    if (topicsResourcesFetched === false) {
      setTopicsResourcesFetched(true)
    }
  }, [topicsResources[`topic_` + selectedTopic], topicsResourcesFetchedex[`topic_` + selectedTopic]])

  useEffect(() => {
    if (topicsResourcesFetchedxx === false) {
      setTopicsResourcesFetchedxx(true)
    }
  }, [topicsResources[`topic_` + selectedTopic], topicsResourcesFetchedex[`topic_` + selectedTopic], topicsResourcesFetchedxx])

  useEffect(() => {
    if (bulkTopicsRefetched) {
      setTimeout(function () {
        setDataFetched(!dataFetched)
      }, 300)
    }
  }, [bulkTopicsRefetched])

  async function fetchTopicResources(id = null, refetch = false, second = false) {
    let selected_topic
    if (refetch) {
      selected_topic = id
    } else {
      selected_topic = selectedTopic
    }
    if ((selected_topic !== null && topicsResources[`topic_` + selected_topic] === undefined) || refetch) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/resources-names-list/?topic=${selected_topic}&paginate_by=10`, { headers: login_credentials })
        .then((res) => {
          let datum = topicsResources
          datum[`topic_` + selected_topic] = res.data.results
          topicsResourcesFetchedex[`topic_` + selected_topic] = true
          setTopicsResourcesFetched(false)
          topicsResourcesNext[`topic_` + selected_topic] = res.data.next === null ? {} : { next: res.data.next }
          setTopicsResources(datum)
          setTopicsRefetched(true)
          if (second) {
            setBulkTopicsRefetched(true)
          }
        })
    }
  }

  async function fetchNextTopicResources(next_url) {
    setTopicsResourcesFetched(false)
    let resources_seemore_loader = { ...topicsResourcesNextLoader }
    resources_seemore_loader[`topic_` + selectedTopic] = true
    setTopicsResourcesNextLoader(resources_seemore_loader)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_topic_resources = topicsResources[`topic_` + selectedTopic]
      if (res.data.results.length > 0) {
        for (var tprs = 0; tprs <= res.data.results.length - 1; tprs++) {
          next_topic_resources.push(res.data.results[tprs])
        }
        topicsResources[`topic_` + selectedTopic] = next_topic_resources
      }
      topicsResourcesNext[`topic_` + selectedTopic] = res.data.next === null ? {} : { next: res.data.next }
      setTopicsResourcesFetched(true)
      let resources_seemore_loader = { ...topicsResourcesNextLoader }
      resources_seemore_loader[`topic_` + selectedTopic] = false
      setTopicsResourcesNextLoader(resources_seemore_loader)
    })
  }

  function deleteResource(id, push_to_bucket = false, item = {}, topic_id = null, type = "") {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        if (type === "topic") {
          triggerChanges("sessionresources", topic_id)
        }
        setDataUpdated(!dataUpdated)
        // if (push_to_bucket) {
        //   item.id = item.resource_data.id
        //   item.thumbnail_url = item.resource_data.thumbnail_url
        //   item.name = item.resource_data?.name
        //   // draggedItem.sequence = draggedItem.sequence
        //   let type = item.resource_type
        //   delete item['resource_data']
        //   delete item['resource_id']
        //   delete item['resource_type']
        //   setCurrentSelectedResourcesType(type)
        //   if (allResources[type]) {
        //     allResources[type].splice(0, 0, item);
        //   }
        // }
        setResourceDeleted(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  // const [id2List, setId2List] = useState({
  //   droppable: 'allResources',
  //   moduleDroppable: 'modulesResources',
  //   topicDroppable: 'topicsResources'
  // })

  // function getList(id){
  //   if (id2List[id] === 'allResources'){
  //     return allResources[props.currentSelectedResourcesType];
  //   }else if (id2List[id] === 'modulesResources') {
  //     return modulesResources[`module_`+selectedModule];
  //   }else if (id2List[id] === 'topicsResources') {
  //     return topicsResources[`topic_`+selectedTopic];
  //   }
  // }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  // useEffect(() => {
  //   setAllResourcesFetched(true);
  // }, [allResources[props.currentSelectedResourcesType]])

  function handleDraggedItemSequence(currentItem, type, to = `internal`, delete_topic = false) {
    if (currentItem) {
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      formData.append("published", currentItem.published)
      if (type !== `resources`) {
        formData.append("name", currentItem.name)
      } else {
        formData.append("resource_id", currentItem.resource_id)
        formData.append("resource_type", currentItem.resource_type)
      }
      if (to === `external` && type === `topics`) {
        formData.append("module", currentItem.module)
        if (!delete_topic) {
          // formData.append('duplicate',true)
        } else if (delete_topic === "add_to_do") {
          formData.append("duplicate", delete_topic)
        } else {
          formData.append("duplicate", false)
        }
      } else if (to === `topic_external` && type === `resources`) {
        formData.append("topic", currentItem.topic)
      } else if (to === `module_external` && type === `resources`) {
        formData.append("module", currentItem.module)
      }
      if (type === "topics") {
        formData.append("visible_as_resource", currentItem.visible_as_resource)
      }
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/` + currentItem.id + "/", formData, { headers: DefaultHeader })
        .then((res) => {
          if (type === "modules") {
            triggerChanges("lbsessions")
          } else if (to === `external`) {
            let tindex = modules.findIndex((res) => res.id === parseInt(currentItem.module))
            if (tindex > -1 || delete_topic === "add_to_do") {
              let session_datam = modules[tindex].id + `_` + modules[tindex].session_type
              if (delete_topic === "add_to_do") {
                session_datam = session_datam + `_` + currentItem.previos_module
              }
              triggerChanges("todo" + (delete_topic === "add_to_do" ? `` : modules[tindex].session_type), currentItem.id, session_datam)
            }
            if (delete_topic === false) {
              let t_index = modulesData[`module_` + currentItem.module].topics.findIndex((res) => res.id === parseInt(currentItem.id))
              modulesData[`module_` + currentItem.module].topics[t_index].session_data = res.data.session_data
              setDataUpdated(!dataUpdated)
            }
          } else if (type === `topics`) {
            triggerChanges("todo" + res.data.session_data.session_type, currentItem.id, res.data.session_data.id + `_` + res.data.session_data.session_type)
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
    }
  }

  function onDragEnd(result) {
    //only for resources
    let type = result.source.droppableId.split("_")[0].toString() + `_resourceDraggable`
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    if (result.type === "moduleDraggable") {
      const items = reorder(modules, sourceIndex, destIndex)
      let currentModule = items[result.destination.index]
      if (result.destination.index === 0) {
        currentModule.sequence = (parseFloat(modules[0].sequence) - 10).toFixed(20)
      } else if (result.destination.index === modules.length - 1) {
        currentModule.sequence = (parseFloat(modules[modules.length - 1].sequence) + 10).toFixed(20)
      } else {
        if (result.source.index > result.destination.index) {
          currentModule.sequence = (
            (parseFloat(modules[result.destination.index - 1].sequence) + parseFloat(modules[result.destination.index].sequence)) /
            2
          ).toFixed(20)
        } else if (result.source.index < result.destination.index) {
          currentModule.sequence = (
            (parseFloat(modules[result.destination.index].sequence) + parseFloat(modules[result.destination.index + 1].sequence)) /
            2
          ).toFixed(20)
        }
      }
      handleDraggedItemSequence(currentModule, "modules")
      setModules(items)
    } else if (result.type === "topicDraggable") {
      let source = result.draggableId.indexOf("_lesson") > -1 ? "lesson_plan" : "session_plan"
      let destination = result.destination.droppableId.indexOf("_lesson") > -1 ? "lesson_plan" : "session_plan"

      const sourceParentId = result.source.droppableId.replace("_lesson", "").replace("_session", "")
      const destParentId = result.destination.droppableId.replace("_lesson", "").replace("_session", "")

      let sourceSubItems = []
      let destSubItems = []
      if (source === "lesson_plan") {
        if (sourceParentId === destParentId) {
          sourceSubItems = lessonPlanTopics[sourceParentId].topics
          destSubItems = lessonPlanTopics[destParentId].topics
        } else {
          sourceSubItems = lessonPlanTopics[sourceParentId].topics
          destSubItems = modulesData[destParentId].topics
        }
      } else if (source === "session_plan") {
        if (sourceParentId === destParentId) {
          sourceSubItems = modulesData[sourceParentId].topics
          destSubItems = modulesData[destParentId].topics
        } else {
          if (destination === "lesson_plan") {
            sourceSubItems = modulesData[sourceParentId].topics
            destSubItems = lessonPlanTopics[destParentId].topics
          } else {
            sourceSubItems = modulesData[sourceParentId].topics
            destSubItems = modulesData[destParentId].topics
          }
        }
      }

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        let source_data = source === "lesson_plan" ? lessonPlanTopics : modulesData
        const items = reorder(source_data[destParentId].topics, sourceIndex, destIndex)
        let currentTopic = items[result.destination.index]
        let currentData = source_data[destParentId].topics
        if (result.destination.index === 0) {
          currentTopic.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
        } else if (result.destination.index === currentData.length - 1) {
          currentTopic.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (result.source.index > result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
              2
            ).toFixed(20)
          } else if (result.source.index < result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
              2
            ).toFixed(20)
          }
        }
        handleDraggedItemSequence(currentTopic, "topics")
        source_data[destParentId].topics = items
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = destSubItems
        newDestSubItems.splice(destIndex, 0, draggedItem)

        if (result.destination.index === 0 && destSubItems.length === 1) {
          draggedItem.sequence = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index + 1].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index + 1 === destSubItems.length) {
          draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem.sequence = (
            (parseFloat(destSubItems[result.destination.index + 1].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        let module_id = result.destination.droppableId.split("_")[1].toString()
        let delete_topic = false
        if (destination === "lesson_plan") {
          delete_topic = true
        } else if (source === destination && source === "session_plan") {
          delete_topic = "add_to_do"
          draggedItem.previos_module = result.source.droppableId.split("_")[1].toString()
        }
        draggedItem.module = module_id
        handleDraggedItemSequence(draggedItem, "topics", "external", delete_topic)
      }
    } else if (result.destination.droppableId.split("_")[0].toString() === "visibleTopicData") {
      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      const sourceSubItems = props.allResources[props.currentSelectedResourcesType]
      const destSubItems = visibleTopicData[destParentId] ? visibleTopicData[destParentId] : []

      let newSourceSubItems = sourceSubItems
      const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)
      let module_id = result.destination.droppableId.split("_")[1].toString()
      createTopic(null, module_id, draggedItem)

      let newDestSubItems = destSubItems
      newDestSubItems.splice(destIndex, 0, draggedItem)
      // newDestSubItems = [draggedItem]
      let temp_data = props.allResources
      temp_data[props.currentSelectedResourcesType] = newSourceSubItems
      props.setHomepageAllResource(temp_data)
      // allResources[props.currentSelectedResourcesType] = newSourceSubItems
      visibleTopicData[destParentId] = newDestSubItems
      setVisibleTopicDataFetched(!visibleTopicDataFetched)
    } else if (type === "module_resourceDraggable" || type === "topic_resourceDraggable") {
      const itemSubItemMap = type === "topic_resourceDraggable" ? topicsResources : modulesResources

      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      const sourceSubItems = itemSubItemMap[sourceParentId]
      const destSubItems = itemSubItemMap[destParentId]

      let newItems =
        result.destination.droppableId.split("_")[0].toString() === `resource`
          ? props.allResources[props.currentSelectedResourcesType]
          : type === "topic_resourceDraggable"
          ? topicsResources[result.destination.droppableId]
          : modulesResources[result.destination.droppableId]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        if (type === "topic_resourceDraggable") {
          const items = reorder(topicsResources[result.destination.droppableId], sourceIndex, destIndex)

          let currentResource = items[result.destination.index]
          let currentData = topicsResources[result.destination.droppableId]
          if (result.destination.index === 0) {
            currentResource.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
          } else if (result.destination.index === currentData.length - 1) {
            currentResource.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
          } else {
            if (result.source.index > result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
                2
              ).toFixed(20)
            } else if (result.source.index < result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
                2
              ).toFixed(20)
            }
          }
          handleDraggedItemSequence(currentResource, "resources")

          setTopicsResourcesFetched(!topicsResourcesFetched)
          triggerChanges("sessionresources", result.destination.droppableId.split("_")[1])
          topicsResources[result.destination.droppableId] = items
        } else {
          const items = reorder(modulesResources[result.destination.droppableId], sourceIndex, destIndex)

          let currentResource = items[result.destination.index]
          let currentData = modulesResources[result.destination.droppableId]
          if (result.destination.index === 0) {
            currentResource.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
          } else if (result.destination.index === currentData.length - 1) {
            currentResource.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
          } else {
            if (result.source.index > result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
                2
              ).toFixed(20)
            } else if (result.source.index < result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
                2
              ).toFixed(20)
            }
          }
          handleDraggedItemSequence(currentResource, "resources")

          setModulesResourcesFetched(!modulesResourcesFetched)
          setModulesResourcesFetchedx(!modulesResourcesFetchedx)
          modulesResources[result.destination.droppableId] = items
        }
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems =
          result.destination.droppableId.split("_")[0].toString() === `resource` ? props.allResources[props.currentSelectedResourcesType] : destSubItems
        if (result.destination.droppableId.split("_")[0].toString() === `resource`) {
          /*to be present in api success start*/
          draggedItem.dragged_id = draggedItem.id
          draggedItem.id = draggedItem.resource_data.id
          draggedItem.thumbnail_url = draggedItem.resource_data.thumbnail_url
          draggedItem.name = draggedItem.resource_data?.display_name ?? draggedItem.resource_data?.name
          // draggedItem.sequence = draggedItem.sequence
          delete draggedItem["resource_data"]
          delete draggedItem["resource_id"]
          delete draggedItem["resource_type"]
          /*to be present in api success end*/
          if (type === "topic_resourceDraggable") {
            deleteResource(draggedItem.dragged_id, false, {}, result.source.droppableId.split("_")[1].toString(), "topic")
          } else {
            deleteResource(draggedItem.dragged_id)
          }
        }
        newDestSubItems.splice(destIndex, 0, draggedItem)
        if (result.destination.droppableId.split("_")[0].toString() === `resource`) {
          setAllResourcesFetched(false)
          let temp_data = props.allResources
          temp_data[props.currentSelectedResourcesType] = newItems
          props.setHomepageAllResource(temp_data)
          // allResources[props.currentSelectedResourcesType] = newItems
        } else {
          /**/
          if (result.destination.index === 0 && destSubItems.length === 1) {
            draggedItem.sequence = result.destination.index
          } else if (result.destination.index === 0) {
            if (draggedItem) {
              draggedItem.sequence = (parseFloat(destSubItems[result.destination.index + 1].sequence) - 10).toFixed(20)
            }
          } else if (result.destination.index + 1 === destSubItems.length) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
          } else {
            draggedItem.sequence = (
              (parseFloat(destSubItems[result.destination.index + 1].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
              2
            ).toFixed(20)
          }

          /**/
          if (type === "topic_resourceDraggable") {
            let topic_id = result.destination.droppableId.split("_")[1].toString()
            draggedItem.topic = topic_id

            handleDraggedItemSequence(draggedItem, "resources", "topic_external")

            setTopicsResourcesFetched(false)
            triggerChanges("sessionresources", result.source.droppableId.split("_")[1], result.destination.droppableId.split("_")[1])
            topicsResources[result.destination.droppableId] = newItems
          } else {
            let module_id = result.destination.droppableId.split("_")[1].toString()
            draggedItem.module = module_id

            handleDraggedItemSequence(draggedItem, "resources", "module_external")

            setModulesResourcesFetched(false)
            setModulesResourcesFetchedx(false)
            modulesResources[result.destination.droppableId] = newItems
          }
        }
      }
    } else if (type === "resource_resourceDraggable") {
      const itemSubItemMap = props.allResources

      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      const sourceSubItems = itemSubItemMap[sourceParentId]
      const destSubItems = itemSubItemMap[destParentId]

      let newItems = props.allResources[props.currentSelectedResourcesType]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        // const items = reorder(allResources[props.currentSelectedResourcesType], sourceIndex, destIndex);
        // setAllResourcesFetched(false)
        // allResources[props.currentSelectedResourcesType] = items
      } else {
        let newSourceSubItems = itemSubItemMap[props.currentSelectedResourcesType]
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = []
        var formData = new FormData()
        if (result.destination.droppableId.split("_")[0].toString() === `module`) {
          newDestSubItems = modulesResources[destParentId]
          formData.append("add_to_module", result.destination.droppableId.split("_")[1])
          setModulesResourcesFetched(false)
        } else {
          newDestSubItems = topicsResources[destParentId]
          formData.append("add_to_topic", result.destination.droppableId.split("_")[1])
          setTopicsResourcesFetched(false)
        }

        let destSubItems = newDestSubItems

        if (result.destination.index === 0 && destSubItems.length === 0) {
          draggedItem.sequence = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index === destSubItems.length) {
          draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem.sequence = (
            (parseFloat(destSubItems[result.destination.index].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        /*api for creating resource to topic or module*/
        formData.append("sequence", draggedItem.sequence)
        if (props.currentSelectedResourcesType == "poll" || props.currentSelectedResourcesType == "pop-quiz") {
          formData.append("resource_type", "quiz")
        } else {
          formData.append("resource_type", props.currentSelectedResourcesType)
        }
        // formData.append('resource_type',props.currentSelectedResourcesType)
        formData.append("resource_id", draggedItem.id)
        // draggedItem.resource_type = props.currentSelectedResourcesType

        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
          .then((res) => {
            if (res.data) {
              draggedItem.id = res.data.id
              draggedItem.resource_data = res.data.resource_data
              draggedItem.resource_id = res.data.resource_id
              draggedItem.resource_type = res.data.resource_type
              draggedItem.sequence = res.data.sequence
              delete draggedItem["name"]
            }
            let data_id = result.destination.droppableId.split("_")[1]
            if (result.destination.droppableId.split("_")[0].toString() === `module`) {
              setModulesResourcesFetched(true)
            } else {
              setTopicsResourcesFetched(true)
            }
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()
              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
        /*api end*/
        newDestSubItems.splice(destIndex, 0, draggedItem)
        setAllResourcesFetched(false)
        let temp_data = props.allResources
        temp_data[props.currentSelectedResourcesType] = newItems
        props.setHomepageAllResource(temp_data)
      }
    }
  }

  /*end-drag and drop all functions*/

  const handleClose = () => {
    setDmodal(false)
    setSelectedDeletingModule([])
    setSelectedDeletingTopic([])
    setDeleteVisibleResource([])
  }

  useEffect(() => {
    // fetchStageData()
    fetchLessonPlans()
    fetchTemplateModules()
  }, [])

  // async function fetchStageData() {
  //   axios.get(process.env.GATSBY_REACT_APP_API_HOST+`/stages-details-list/${props.stage_id}/`, {headers: login_credentials})
  //   .then(res => {
  //     setStageData(res.data.results[0])
  //   })
  // }
  async function fetchLessonPlans() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/default-course-templates-modules-list/${props.template_id}/?module_type=1`, { headers: login_credentials })
      .then((res) => {
        if (res.data.results.length > 0) {
          setLessonPlansModules(res.data.results)
          setCurrentLessonModule(res.data.results[0].id)
          fetchLessonPlanTopics(res.data.results[0].id)
        }
      })
  }
  async function fetchLessonPlanTopics(plan_id) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-topics-list/?module=${plan_id}&paginate_by=15&exclude=session_topics`, {
        headers: login_credentials,
      })
      .then((res) => {
        let lesson_topics = lessonPlanTopics
        lesson_topics[`module_` + plan_id] = {}
        lesson_topics[`module_` + plan_id]["topics"] = res.data.results
        lesson_topics[`module_` + plan_id]["topics_next"] = res.data.next
        setLessonPlansTopics(lesson_topics)
        setLessonPlansFetched(true)
        setLessonPlanRefetched(true)
      })
      .catch((error) => {
        setLessonPlansFetched(false)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }
  async function fetchNextLessonTopics(next_url) {
    setLessonLoader(false)
    let current_module_data = { ...lessonPlanTopics }
    current_module_data[`module_` + currentLessonModule].topics_loader = true
    setLessonPlansTopics(current_module_data)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let lesson_topics = { ...lessonPlanTopics }
      lesson_topics[`module_` + currentLessonModule].topics = lesson_topics[`module_` + currentLessonModule].topics.concat(res.data.results)
      lesson_topics[`module_` + currentLessonModule].topics_next = res.data.next
      lesson_topics[`module_` + currentLessonModule].topics_loader = false
      setLessonPlansTopics(lesson_topics)
      // let lesson_topics = lessonPlanTopics
      // if (res.data.results.length > 0 ){
      //   lesson_topics[`module_`+currentLessonModule].topics = lesson_topics[`module_`+currentLessonModule].topics.concat(res.data.results)
      // }
      // lesson_topics[`module_`+currentLessonModule].topics_next = res.data.next
      // setLessonPlansTopics(lesson_topics)
      setLessonLoader(true)
    })
  }
  async function fetchTemplateModules() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/default-course-templates-modules-list/${props.template_id}/?module_type=3`, { headers: login_credentials })
      .then((res) => {
        setModules(res.data.results)
        setModulesNext(res.data.next)
        setInitialFetched(true)
        if (res.data.results.length > 0) {
          setCurrentModuleFetched(false)
          // setSelectedModule(res.data.results[0].id)
          // selectedModuleToggle[res.data.results[0].id] = true
        } else {
          setModuleCreated(false)
          createModule(null)
          setCurrentModuleFetched(true)
        }
        setLoader(true)
      })
  }

  async function fetchNextTemplateModules(next_url) {
    setModulesLoader(true)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_template_modules = modules
      if (res.data.results.length > 0) {
        for (var md = 0; md <= res.data.results.length - 1; md++) {
          next_template_modules.push(res.data.results[md])
        }
        setModules(next_template_modules)
      }
      setModulesNext(res.data.next)
      setModulesLoader(false)
    })
  }

  async function deleteModule() {
    let url = selectedDeletingModule && selectedDeletingModule.id ? `modules` : `topics`
    if (url === "modules") {
      var current_id = selectedDeletingModule.id
      var index = modules.findIndex((res) => res.id === current_id)
    } else {
      var current_id = selectedDeletingTopic.id
      var index = modulesData[`module_` + selectedModule].topics.findIndex((res) => res.id === current_id)
    }
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/${url}/` + current_id + "/", {
        headers: DefaultHeader,
        body: url === "modules" ? selectedDeletingModule : selectedDeletingTopic,
      })
      .then((res) => {
        if (url === "modules") {
          modules.splice(index, 1)
          if (modules && modules.length > 0) {
            // setCurrentModuleFetched(false)
            setSelectedModule(modules[0].id)
          } else {
            setSelectedModule(null)
          }
        } else {
          let item
          if (deleteVisibleResource && deleteVisibleResource.length > 0) {
            item = deleteVisibleResource[0]
            item.id = item.resource_data.id
            item.thumbnail_url = item.resource_data.thumbnail_url
            item.name = item.resource_data?.display_name ?? item.resource_data?.name
            let type = item["resource_type"]
            // draggedItem.sequence = draggedItem.sequence
            delete item["resource_data"]
            delete item["resource_id"]
            delete item["resource_type"]
            if (allResources[type]) {
              setCurrentSelectedResourcesType(type)
              allResources[type].splice(0, 0, item)
            }
          }
          modulesData[`module_` + selectedDeletingTopic.module].topics.splice(index, 1)
          if (modulesData[`module_` + selectedDeletingTopic.module].topics && modulesData[`module_` + selectedDeletingTopic.module].topics.length > 0) {
            setTopicFetched(false)
            setSelectedTopic(modulesData[`module_` + selectedDeletingTopic.module].topics[0].id)
          } else {
            setSelectedTopic(null)
          }
        }
        setSelectedDeletingModule([])
        setSelectedDeletingTopic([])
        setDeleteVisibleResource([])
        setDmodal(!dmodal)
        triggerChanges("lbsessions")
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }
  async function deleteTopic() {
    var formData = new FormData()
    formData.append("id", selectedDeletingTopic.id)
    formData.append("name", selectedDeletingTopic.name)
    formData.append("module", lessonPlanModules[0].id)
    formData.append("duplicate", false)
    var index = modulesData[`module_` + selectedDeletingTopic.module].topics.findIndex((res) => res.id === parseInt(selectedDeletingTopic.id))
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/topics/` + selectedDeletingTopic.id + "/", formData, { headers: DefaultHeader })
      .then((res) => {
        let topic = {}
        topic["name"] = res.data.name
        topic["id"] = res.data.id
        topic["published"] = res.data.published
        topic["resources_exist"] = res.data.resources_exist
        topic["sequence"] = res.data.sequence
        topic["visible_as_resource"] = res.data.visible_as_resource
        lessonPlanTopics[`module_` + lessonPlanModules[0].id].topics.push(topic)
        modulesData[`module_` + selectedDeletingTopic.module].topics.splice(index, 1)
        setSelectedDeletingModule([])
        setSelectedDeletingTopic([])
        setDeleteVisibleResource([])
        setDmodal(!dmodal)
        var mindex = modules.findIndex((res) => res.id === parseInt(selectedDeletingTopic.module))
        if (mindex > -1) {
          triggerChanges("todo" + modules[mindex].session_type + `__` + modules[mindex].id, res.data.id, null)
        }
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function handleDurationSave(datum, new_value, name, type) {
    if (datum.id) {
      datum[name] = new_value
      var formData = new FormData()
      for (var k in datum) {
        if (datum[k] !== null) {
          if (k === "start_date" || k === "end_date") {
            if (datum[k]) {
              formData.append(k, new Date(datum[k]).toISOString())
            }
          } else {
            formData.append(k, datum[k])
          }
        }
      }
      if (type) {
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}s/` + datum.id + "/?editing=true", formData, { headers: DefaultHeader })
          .then((res) => {
            if (type === "module") {
              triggerChanges(`spsessionedited`, datum.id + "_$_" + new_value, name)
            } else {
            }
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()

              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    } else {
    }
  }

  function handleSave(name, value, datum, type) {
    if (datum.id && (datum.loader || datum.loader1 || datum.loader2)) {
      datum[name] = value
      var formData = new FormData()
      for (var k in datum) {
        if (datum[k] !== null) {
          if (k === "start_date" || k === "end_date") {
            if (datum[k]) {
              formData.append(k, new Date(datum[k]).toISOString())
            }
          } else {
            formData.append(k, datum[k])
          }
        }
      }
      if (type) {
        setTopicEdited(false)
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}s/` + datum.id + "/?editing=true", formData, { headers: DefaultHeader })
          .then((res) => {
            if (type === "topic") {
              if (modulesData[`module_` + datum.module]) {
                let t_index = modulesData[`module_` + datum.module].topics.findIndex((res) => res.id === datum.id)
                if (t_index > -1) {
                  let t_data = modulesData
                  t_data[`module_` + datum.module].topics[t_index].name = value
                  t_data[`module_` + datum.module].topics[t_index].loader = false
                  setModulesData(t_data)
                  setTopicEdited(true)
                  let current_datam = t_data[`module_` + datum.module].topics[t_index]
                  triggerChanges(
                    `sptopicedited`,
                    datum.id + "_$_" + datum.name + `_$_` + name,
                    current_datam.session_data === null
                      ? current_datam.session_data
                      : current_datam.session_data.id + `_` + current_datam.session_data.session_type
                  )
                }
              } else if (lessonPlanTopics[`module_` + datum.module]) {
                let lt_index = lessonPlanTopics[`module_` + datum.module].topics.findIndex((res) => res.id === datum.id)
                if (lt_index > -1) {
                  let lt_data = lessonPlanTopics
                  lt_data[`module_` + datum.module].topics[lt_index].name = value
                  lt_data[`module_` + datum.module].topics[lt_index].loader = false
                  setLessonPlansTopics(lt_data)
                  setTopicEdited(true)
                  triggerChanges(`sptopicedited`, datum.id + "_$_" + datum.name + `_$_` + name, null)
                }
              }
            } else if (type === "module") {
              if (modules && modules.length > 0) {
                let m_index = modules.findIndex((res) => res.id === datum.id)
                if (m_index > -1) {
                  let m_data = modules
                  m_data[m_index][name] = value
                  if (name === "name") {
                    m_data[m_index].loader = false
                  } else if (name === "duration") {
                    m_data[m_index].loader1 = false
                  } else if (name === "description") {
                    m_data[m_index].loader2 = false
                  }
                  setModules(m_data)
                  setTopicEdited(true)
                  triggerChanges(`spsessionedited`, datum.id + "_$_" + datum[name], name)
                }
              }
            }
            setUpdateInputData({})
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()

              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    } else {
    }
  }

  useEffect(() => {
    if (moduleCreated === true && modules.length > 0) {
      setSelectedModule(modules[modules.length - 1].id)
      // if (stageData.name !== 'Workshop') {
      //   setCollapse(false)
      // }
    }
  }, [moduleCreated])

  function createModule(e) {
    if (e) {
      e.preventDefault()
    }
    let sequence = (modules.length + 1) * 10
    if (modules.length > 0) {
      sequence = parseInt(modules[modules.length - 1].sequence) + 10
    }
    let module = {}
    module.name = `Session`
    module.name = module.name + ` ${sequence / 10}`
    module.sequence = sequence
    module.add_to_template = props.template_id
    module.published = true
    // module.module_type = '3'
    var formData = new FormData()
    for (var k in module) {
      formData.append(k, module[k])
    }
    if (module.id !== undefined) {
    } else {
      /*to be fixed*/
      // setCurrentModuleFetched(false)
      // setLoader(false)
      setModuleCreated(false)
      var module_id = module.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/modules/create/", formData, { headers: login_credentials })
        .then((res) => {
          let new_data = {}
          let datum = modules
          if (res.data) {
            new_data.id = res.data.id
            new_data.name = res.data.name
            new_data.sequence = res.data.sequence
            new_data.published = res.data.published
            new_data.session_type = res.data.session_type
            new_data.duration = res.data.duration
            new_data.start_date = res.data.start_date
            setModules(datum.concat(new_data))
            setSelectedModule(res.data.id)
            toggleModule(e, res.data.id)
            scrollToSpecific(e, "modules_" + res.data.id)
            // setCurrentModuleFetched(true)
            setModuleCreated(true)
            // setLoader(true)
            triggerChanges("lbsessions")
          }
        })
        .catch((error) => {
          // setCurrentModuleFetched(true)
          // setLoader(true)
          // setModuleCreated(true)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  useEffect(() => {
    if (topicCreated === true && modulesData[`module_` + selectedModule]) {
      let datam = modulesData[`module_` + selectedModule].topics
      setSelectedTopic(datam[datam.length - 1].id)
      scrollToSpecific(null, "topics_" + datam[datam.length - 1].id, "topics")
      setCollapse(false)
      setSelectedTopic(datam[datam.length - 1].id)
      // setTopicFetched()
      // topicsData['topic_'+datam[datam.length - 1].id] = true
    }
  }, [topicCreated])

  function createTopicResource(draggedItem, topic, module_id) {
    var formData = new FormData()
    formData.append("add_to_topic", topic.id)
    setTopicsResourcesFetched(false)
    formData.append("sequence", 0)
    formData.append("resource_type", props.currentSelectedResourcesType)
    formData.append("resource_id", draggedItem.id)

    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
      .then((res) => {
        if (res.data) {
          draggedItem.id = res.data.id
          draggedItem.resource_data = res.data.resource_data
          draggedItem.resource_id = res.data.resource_id
          draggedItem.resource_type = res.data.resource_type
          draggedItem.sequence = res.data.sequence
          delete draggedItem["name"]
        }
        topicsResources[`topic_` + topic.id] = [res.data]
        modulesData[`module_` + module_id].topics[modulesData[`module_` + module_id].topics.length - 1].visible_resources = [res.data]
        setTopicsResourcesFetched(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function createTopic(e, module_id, draggedItem = null) {
    let topic = {}
    if (e) {
      e.preventDefault()
    } else {
      topic.visible_as_resource = true
    }
    let topics = modulesData[`module_` + module_id].topics
    let sequence = (topics.length + 1) * 10
    topic.name = "Activity " + sequence / 10
    topic.sequence = sequence
    topic.add_to_module = module_id
    topic.published = true
    var formData = new FormData()
    for (var k in topic) {
      formData.append(k, topic[k])
    }
    if (topic.id !== undefined) {
    } else {
      setTopicCreated(false)
      var topic_id = topic.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/topics/create/", formData, { headers: login_credentials })
        .then((res) => {
          let new_data = {}
          let datum = modulesData[`module_` + module_id].topics
          if (res.data) {
            new_data.id = res.data.id
            new_data.name = res.data.name
            new_data.sequence = res.data.sequence
            new_data.visible_as_resource = res.data.visible_as_resource
            new_data.published = res.data.published
            modulesData[`module_` + module_id].topics = datum.concat(new_data)
            // setSelectedTopic(res.data.id)
            toggleTopic(e, res.data.id)
            setCurrentModuleTopicsFetched(true)
            setTopicCreated(true)
            if (e === null) {
              setVisibleTopicData({})
              setVisibleTopicDataFetched(!visibleTopicDataFetched)
              createTopicResource(draggedItem, res.data, module_id)
            }
          }
          /*fetchCurrentTopics(true)*/
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  useEffect(() => {
    if (selectedModule != null) {
      fetchCurrentModule()
    }
  }, [selectedModule])

  async function fetchSelectedTopic() {
    if (topicsData && topicsData["topic_" + selectedTopic] === undefined) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/topic-basic-detail/${selectedTopic}/`, { headers: login_credentials })
        .then((res) => {
          topicsData["topic_" + selectedTopic] = res.data
          setTopicFetched(true)
        })
        .catch((error) => {
          setTopicFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      setTopicFetched(true)
    }
  }

  useEffect(() => {
    if (selectedTopic !== null) {
      fetchSelectedTopic()
    }
  }, [selectedTopic])

  async function fetchCurrentTopics(refetch = false, module_id = null, next_module_id = null) {
    let selected_module
    if (module_id === null) {
      selected_module = selectedModule
    } else {
      selected_module = module_id
    }
    if (refetch) {
      setCurrentModuleTopicsFetched(false)
    }
    if ((modulesData && modulesData[`module_` + selected_module] && modulesData[`module_` + selected_module].topics === undefined) || refetch) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-topics-list/?module=${selected_module}&paginate_by=15&is_plan=true`, {
          headers: login_credentials,
        })
        .then((res) => {
          if (modulesData[`module_` + selected_module] !== undefined) {
            modulesData[`module_` + selected_module].topics = res.data.results
            modulesData[`module_` + selected_module].topics_next = res.data.next
          }
          setSelectedTopic(null)
          setCurrentModuleTopicsFetched(true)
          if (next_module_id !== null && modulesData[`module_` + selected_module]) {
            fetchCurrentTopics(true, next_module_id)
          }
        })
        .catch((error) => {
          setCurrentModuleTopicsFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      setSelectedTopic(null)
      setCurrentModuleTopicsFetched(true)
    }
  }

  async function fetchNextCurrentTopics(next_url) {
    let current_module_data = { ...modulesData }
    current_module_data[`module_` + selectedModule].topics_loader = true
    setModulesData(current_module_data)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let current_module_data = { ...modulesData }
      current_module_data[`module_` + selectedModule].topics = current_module_data[`module_` + selectedModule].topics.concat(res.data.results)
      current_module_data[`module_` + selectedModule].topics_next = res.data.next
      current_module_data[`module_` + selectedModule].topics_loader = false
      setModulesData(current_module_data)
      setCurrentModuleTopicsFetched(true)
    })
  }

  async function fetchCurrentModule() {
    if (modulesData && modulesData[`module_` + selectedModule] === undefined) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/module-basic-detail/${selectedModule}/`, { headers: login_credentials })
        .then((res) => {
          modulesData[`module_` + selectedModule] = res.data
          setCurrentModuleTopicsFetched(false)
          fetchCurrentTopics()
          setCurrentModuleFetched(true)
        })
        .catch((error) => {
          setCurrentModuleFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      setCurrentModuleFetched(true)
      setCurrentModuleTopicsFetched(false)
      fetchCurrentTopics()
    }
  }

  function setType(value) {
    if (props.currentSelectedResourcesType !== value) {
      setCurrentSelectedResourcesType(value)
    }
  }

  function showTopicsData(topic, visible_as_resource = false, module) {
    let topic_id = topic.id
    let module_id = module.id
    if (topic_id !== null) {
      if (visible_as_resource) {
        return showResourcesButton(`topic`, topic, visible_as_resource, module)
      } else if (topic_id !== null && topicFetched && topicsData["topic_" + topic_id]) {
      }
      {
        return (
          <div className={`px-0`} style={{ borderTop: `1px solid #929BBD`, backgroundColor: "#42506c", borderRadius: "0px 0px 8px 8px" }}>
            {showResourcesButton(`topic`, topic, visible_as_resource, module)}
            <div className="d-none p-1" style={{ borderTop: `1px solid #929BBD`, borderBottom: `1px solid #929BBD`, borderRadius: "0px 0px 8px 8px" }}>
              <img
                onClick={() => props.openNavWithResourceSection("video")}
                src={video}
                alt={`add video button`}
                className={`my-auto mx-1 pointer`}
                style={{ objectFit: `contain`, width: `21px`, height: `21px` }}
              />
              <img
                onClick={() => props.openNavWithResourceSection("link")}
                src={link}
                alt={`add link button`}
                className={`my-auto mx-1 pointer`}
                style={{ objectFit: `contain`, width: `17px`, height: `17px` }}
              />
              <img
                onClick={() => props.openNavWithResourceSection("blog")}
                src={blog}
                alt={`add blog button`}
                className={`my-auto mx-1 pointer`}
                style={{ objectFit: `contain`, width: `17px`, height: `17px` }}
              />
              <img
                onClick={() => props.openNavWithResourceSection("quiz")}
                src={quiz}
                alt={`add quiz button`}
                className={`my-auto mx-1 pointer`}
                style={{ objectFit: `contain`, width: `17px`, height: `17px` }}
              />
              <img
                onClick={() => props.openNavWithResourceSection("turientimage")}
                src={turientimage}
                alt={`add file button`}
                className={`my-auto mx-1 pointer`}
                style={{ objectFit: `contain`, width: `17px`, height: `17px` }}
              />
            </div>
          </div>
        )
      }
    } else return null
  }

  function showVisibleTopicBtn(module_id, index) {
    var type = "visibleTopicData"
    let data = visibleTopicData[type + `_` + module_id] ? visibleTopicData[type + `_` + module_id] : []
    return (
      <Droppable droppableId={type + `_` + module_id} type={`resourceDraggable`}>
        {(provided, snapshot) => (
          <div className={`bg-transparent`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {data.map((item) => (
              <Draggable key={item.id + `_${type}_name_` + index} draggableId={item.id.toString() + `-${type}` + "_" + index.toString()} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`p-0 float-none`}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <div className={`p-2 mb-2`} style={{ color: `#C6CFF4`, position: `relative`, background: "#28314e" }}>
                      <p className={`m-0`}>
                        {item.resource_data ? (
                          item.resource_data.thumbnail_url ? (
                            <LazyLoadImage
                              className={`ml-1`}
                              src={item.resource_data.thumbnail_url}
                              alt={item.name}
                              style={{ position: `absolute`, left: 5, width: `30px`, height: `30px`, fontSize: `12px` }}
                            />
                          ) : item.resource_data.avatar ? (
                            <LazyLoadImage
                              className={`ml-1`}
                              src={
                                item.resource_data.avatar && item.resource_data.avatar.indexOf(".pdf") < 1
                                  ? item.resource_data.avatar
                                  : item.resource_type === "video"
                                  ? video
                                  : turientimage
                              }
                              alt={item.name}
                              style={{ position: `absolute`, left: 5, width: `30px`, height: `30px`, fontSize: `12px` }}
                            />
                          ) : (
                            <LazyLoadImage
                              className={`ml-1`}
                              src={
                                item.resource_type === "video"
                                  ? video
                                  : item.resource_type === "blog"
                                  ? blog
                                  : item.resource_type === "link"
                                  ? link
                                  : item.resource_type === "quiz"
                                  ? quiz
                                  : turientimage
                              }
                              alt={item.name}
                              style={{ position: `absolute`, left: 5, width: `30px`, height: `30px`, fontSize: `12px` }}
                            />
                          )
                        ) : (
                          ``
                        )}
                        {item.resource_data ? item.resource_data?.display_name ?? item.resource_data?.name : item.name}
                      </p>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {/*dont remove it migth be useful*/}
            {/*<div className={`justify-content-center py-2 d-flex bg-transparent`} style={{ border: `1px dashed white` }} >
              <p className={`m-0`} >
                <span className={`${Styles.font_14}`} style={{ color: `#C6CFF4` }}>Drag and drop media and files to add content.</span>
              </p>
            </div>*/}
          </div>
        )}
      </Droppable>
    )
  }
  function showLessonModuleData(module_id) {
    return (
      <Card className={`${StylesA.topic_card} w-100`}>
        <Card.Body className={`pt-0 px-0 pb-0`}>
          {showLessonTopicsButtons(module_id)}
          {lessonPlansNext !== null ? (
            <Col md="auto" className={`align-self-center ml-auto`}>
              <Button
                variant="none"
                onClick={(e) => {
                  e.preventDefault()
                  fetchNextLessonTopics(lessonPlansNext)
                }}
                className={`${Styles.font_12} text-center px-3 py-1 float-right`}
                style={{ backgroundColor: `#212c42`, color: `#c6cff4`, borderRadius: `15px` }}
              >
                See More
              </Button>
            </Col>
          ) : (
            ``
          )}
        </Card.Body>
      </Card>
    )
  }

  function showCurrentModuleData(module) {
    let module_id = module.id
    var isLoaded = currentModuleFetched
    if (modulesData[`module_` + module_id] !== undefined) {
      let datum = modulesData[`module_` + module_id]
      return (
        <Card className={`${StylesA.topic_card} w-100 pb-2`}>
          <Card.Body className={`pt-0 px-0 pb-0`} style={{ border: "1px dotted", borderRadius: "8px" }}>
            {/*topics view*/}
            {showTopicsButtons(module)}
          </Card.Body>
        </Card>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  useEffect(() => {
    setSelectedModuleToggleChange(!selectedModuleToggleChange)
  }, [selectedModuleToggle[selectedModule]])

  function toggleModule(e = null, datum_id) {
    if (e) {
      e.preventDefault()
    }
    selectedModuleToggle[datum_id] = !selectedModuleToggle[datum_id]
    setSelectedModuleToggleChange(!selectedModuleToggleChange)
  }

  useEffect(() => {
    setSelectedTopicToggleChange(!selectedModuleToggleChange)
  }, [selectedTopicToggle[selectedTopic]])

  function toggleTopic(e, datum_id) {
    if (e) {
      e.preventDefault()
    }
    selectedTopicToggle[datum_id] = !selectedTopicToggle[datum_id]
    setSelectedTopicToggleChange(!selectedTopicToggleChange)
  }
  function showLessonPlanModulesButtons() {
    return (
      <Droppable droppableId={`moduleDraggable`} type={`moduleDraggable`}>
        {(provided, snapshot) => (
          <div className={`col-12`} ref={provided.innerRef} style={getLessonListStyle(snapshot.isDraggingOver)}>
            {lessonPlanModules.map((datum, index) => (
              <Draggable key={datum.id + `_module_name_` + index} draggableId={datum.id.toString() + "-module" + "_" + index.toString()} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`m-0`}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <Col xs="12" sm="12" md="12" style={{ padding: "0px" }}>
                      {showLessonModuleData(datum.id)}
                    </Col>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }

  useEffect(() => {
    if (updateInputData && Object.keys(updateInputData).length > 0) {
      handleSave(updateInputData.name, updateInputData.value, updateInputData.datum, updateInputData.type)
    }
  }, [Object.keys(updateInputData).length > 0])

  function sendQuery(name, value, datum, type) {
    setUpdateInputData({ name: name, value: value, datum: datum, type: type })
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )

  function onBlurData(name, value, datum, type) {
    if (name !== null) {
      handleSave(name, value, datum, type)
    }
  }

  function settingLoader(module_id, index, type) {
    let datum
    if (type === "modulesData") {
      if (modulesData[`module_` + module_id].topics[index].loader === undefined || modulesData[`module_` + module_id].topics[index].loader !== true) {
        datum = modulesData
        datum[`module_` + module_id].topics[index].loader = true
        setModulesData(datum)
        setDataUpdated(!dataUpdated)
      }
    } else if (type === "lessonPlanTopics") {
      if (lessonPlanTopics[`module_` + module_id].topics[index].loader === undefined || lessonPlanTopics[`module_` + module_id].topics[index].loader !== true) {
        datum = lessonPlanTopics
        datum[`module_` + module_id].topics[index].loader = true
        setLessonPlansTopics(datum)
        setDataUpdated(!dataUpdated)
      }
    } else if (type === "module_data") {
      if (module_id === "name") {
        if (modules[index].loader === undefined || modules[index].loader !== true) {
          datum = modules
          datum[index].loader = true
          setModules(datum)
          setDataUpdated(!dataUpdated)
        }
      } else if (module_id === "duration") {
        if (modules[index].loader1 === undefined || modules[index].loader1 !== true) {
          datum = modules
          datum[index].loader1 = true
          setModules(datum)
          setDataUpdated(!dataUpdated)
        }
      } else if (module_id === "description") {
        if (modules[index].loader2 === undefined || modules[index].loader2 !== true) {
          datum = modules
          datum[index].loader2 = true
          setModules(datum)
          setDataUpdated(!dataUpdated)
        }
      }
    }
  }

  function showLessonTopicsButtons(module_id) {
    if (lessonPlanTopics[`module_` + currentLessonModule] && lessonPlanTopics[`module_` + currentLessonModule].topics) {
      if (lessonPlanTopics[`module_` + currentLessonModule].topics.length > 0) {
        return (
          <Droppable droppableId={`module_` + module_id + "_lesson"} type={`topicDraggable`} isDropDisabled={true}>
            {(provided, snapshot) => (
              <div className={`bg-transparent`} ref={provided.innerRef} style={getTopicListStyle(snapshot.isDraggingOver)}>
                {lessonPlanTopics[`module_` + currentLessonModule].topics.map((datum, index) => {
                  return (
                    <Draggable
                      key={datum.id + `_topic_name_` + index}
                      draggableId={datum.id.toString() + "-topic" + "_" + index.toString() + "_lesson"}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`bg-transparent float-none ${StylesA.lesson_plan_topic_item} ${StylesA.border_on_hover}`}
                          style={getLessonTopicItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div
                            variant="none"
                            id={"topics_" + datum.id}
                            className={`${Styles.font_16} px-2 py-1 shadow-none d-flex`}
                            style={{ color: `#F2F4FF`, justifyContent: "space-between", backgroundColor: "#42506C" }}
                          >
                            <div className="d-flex px-0 col-12">
                              <img
                                src={DragHandleIcon}
                                alt={`drag-handle`}
                                className={`my-auto mr-1 pointer`}
                                style={{ objectFit: `contain`, height: `13px`, width: "16px" }}
                              />
                              {selectedInput === "topic_" + datum.id + "name" ? (
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="name"
                                  id={datum.id + `_topic_input_name`}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                  }}
                                  value={datum.name}
                                  onChange={(e) => {
                                    e.preventDefault()
                                    datum.module = module_id
                                    settingLoader(module_id, index, "lessonPlanTopics")
                                    delayedQuery(e.target.name, e.target.value, datum, "topic")
                                  }}
                                  onBlur={(e) => {
                                    e.preventDefault()
                                    onBlurData(e.target.value === datum.name ? null : e.target.name, e.target.value, datum, "topic")
                                  }}
                                  placeholder={"Activity name"}
                                  className={`${StylesA.activity_input_field} form-control border-0 px-2 ${Styles.font_16}`}
                                  required
                                />
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      className={`${datum.name && datum.name.length > 45 ? "" : "d-none"} classroom-tooltip`}
                                      id="tooltip-disabled-session-button"
                                    >
                                      {datum.name}
                                    </Tooltip>
                                  }
                                >
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="name"
                                    id={datum.id + `_topic_input_name`}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                    }}
                                    defaultValue={datum.name}
                                    onChange={(e) => {
                                      e.preventDefault()
                                      datum.module = module_id
                                      settingLoader(module_id, index, "lessonPlanTopics")
                                      delayedQuery(e.target.name, e.target.value, datum, "topic")
                                    }}
                                    onBlur={(e) => {
                                      e.preventDefault()
                                      onBlurData(e.target.value === datum.name ? null : e.target.name, e.target.value, datum, "topic")
                                    }}
                                    placeholder={"Activity name"}
                                    className={`${StylesA.activity_input_field} form-control border-0 px-2 ${Styles.font_16}`}
                                    required
                                  />
                                </OverlayTrigger>
                              )}
                              {datum.loader != undefined && datum.loader === true ? (
                                <svg
                                  className="animate-spin my-auto"
                                  style={{ height: `16px`, width: `16px` }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
                {lessonPlanTopics[`module_` + currentLessonModule].topics_next !== null ? (
                  <Col lg="12" className={`text-center`}>
                    <Button
                      disabled={lessonPlanTopics[`module_` + currentLessonModule].topics_loader}
                      variant="none"
                      onClick={(e) => {
                        e.preventDefault()
                        fetchNextLessonTopics(lessonPlanTopics[`module_` + currentLessonModule].topics_next)
                      }}
                      className={`${Styles.font_12} text-center px-3 py-1 d-flex mx-auto`}
                      style={{ backgroundColor: `#212c42`, color: `#c6cff4`, borderRadius: `15px` }}
                    >
                      {lessonPlanTopics[`module_` + currentLessonModule].topics_loader ? (
                        <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />
                      ) : (
                        ""
                      )}
                      See More
                    </Button>
                  </Col>
                ) : (
                  ``
                )}
              </div>
            )}
          </Droppable>
        )
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <p style={{ fontSize: "13px", margin: "0px", color: "#E0E2E5" }}>All topics added!</p>
          </Col>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function showModulesButtons() {
    if (loader) {
      if (modules && modules.length > 0) {
        return (
          <Droppable droppableId={`moduleDraggable`} type={`moduleDraggable`}>
            {(provided, snapshot) => (
              <React.Fragment>
                <div className={`col-12`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {modules.map((datum, index) => (
                    <Draggable key={datum.id + `_module_name_` + index} draggableId={datum.id.toString() + "-module" + "_" + index.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`${datum.session_type && datum.session_type === "2" ? Styles.opacity_0_5 : Styles.opacity_1} ${StylesA.border_on_hover}`}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div
                            variant="none"
                            id={"modules_" + datum.id}
                            className={`${Styles.font_16} ${selectedModuleToggle[datum.id]} p-2 shadow-none d-flex`}
                            style={{ color: `#F2F4FF`, justifyContent: "space-between", position: "relative" }}
                          >
                            <Col xs="11" sm="11" md="11" lg="11" className="d-flex pl-0">
                              <img
                                src={DragHandleIcon}
                                alt={`drag-handle`}
                                className={`my-auto mr-1 pointer`}
                                style={{ objectFit: `contain`, height: `13px`, width: "16px" }}
                              />
                              {selectedInput === "module_" + datum.id + "name" ? (
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="name"
                                  id={datum.id + `_module_input_name`}
                                  value={datum.name}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                  }}
                                  onChange={(e) => {
                                    e.preventDefault()
                                    settingLoader("name", index, "module_data")
                                    delayedQuery(e.target.name, e.target.value, datum, "module")
                                  }}
                                  onBlur={(e) => {
                                    e.preventDefault()
                                    onBlurData(e.target.value === datum.name ? null : e.target.name, e.target.value, datum, "module")
                                  }}
                                  placeholder={"Enter Name"}
                                  className={`${StylesA.session_input_field} ${StylesA.border_on_hover} form-control px-3 mx-2 ${Styles.font_16}`}
                                  disabled={datum.session_type && datum.session_type === "2"}
                                  required
                                />
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      className={`${datum.name && datum.name.length > 45 ? "" : "d-none"} classroom-tooltip`}
                                      id="tooltip-disabled-session-button"
                                    >
                                      {datum.name}
                                    </Tooltip>
                                  }
                                >
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="name"
                                    id={datum.id + `_module_input_name`}
                                    defaultValue={datum.name}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                    }}
                                    onChange={(e) => {
                                      e.preventDefault()
                                      settingLoader("name", index, "module_data")
                                      delayedQuery(e.target.name, e.target.value, datum, "module")
                                    }}
                                    onBlur={(e) => {
                                      e.preventDefault()
                                      onBlurData(e.target.value === datum.name ? null : e.target.name, e.target.value, datum, "module")
                                    }}
                                    placeholder={"Enter Name"}
                                    className={`${StylesA.session_input_field} ${StylesA.border_on_hover} form-control px-3 mx-2 ${Styles.font_16}`}
                                    disabled={datum.session_type && datum.session_type === "2"}
                                    required
                                  />
                                </OverlayTrigger>
                              )}
                              {datum.loader != undefined && datum.loader === true ? (
                                <svg
                                  className="animate-spin my-auto"
                                  style={{ height: `16px`, width: `16px` }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              ) : null}
                              <DurationInputField datum={datum} selectedInput={selectedInput} handleDurationSave={handleDurationSave} />
                              <DateInputField datum={datum} selectedInput={selectedInput} handleDurationSave={handleDurationSave} />
                              {/*<input type="number" min={1} name="duration" id={datum.id+`_module_duration`} defaultValue={datum.duration}
                                onClick={(e) =>{e.preventDefault();e.stopPropagation()}} onChange={(e) => {e.preventDefault();settingLoader('duration',index,'module_data');delayedQuery(e.target.name,e.target.value,datum,'module');} } onBlur={(e) => {e.preventDefault(); onBlurData(e.target.value === datum.duration ? null : e.target.name,e.target.value,datum,'module') } } placeholder={"Session duration"} className={`${StylesA.session_input_field} form-control border-0 px-3 mx-2 col-4 ${Styles.font_16}`}
                                disabled={datum.session_type && datum.session_type === '2'}  required />
                                {datum.loader1 != undefined && datum.loader1 === true ?
                                  <svg className="animate-spin my-auto" style={{ height: `16px`, width: `16px` }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg> : null}*/}
                            </Col>
                            <div className="d-flex">
                              {selectedModuleToggle[datum.id] ? (
                                <FontAwesomeIcon
                                  className="mr-2 pointer"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    toggleModule(e, datum.id)
                                  }}
                                  icon={faChevronCircleUp}
                                  style={{ width: `16px`, height: `16px`, margin: `auto 0px` }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="mr-2 pointer"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedModule(datum.id)
                                    toggleModule(e, datum.id)
                                  }}
                                  icon={faChevronCircleDown}
                                  style={{ width: `16px`, height: `16px`, margin: `auto 0px` }}
                                />
                              )}
                              {datum.session_type && datum.session_type === "0" ? (
                                <div
                                  className={`my-auto pointer text-center d-inline-flex`}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setDmodal(true)
                                    setSelectedDeletingModule(datum)
                                  }}
                                >
                                  <img src={DeleteIcon} alt={`delete`} className={`my-auto`} style={{ objectFit: `contain`, height: `13px` }} />
                                  &nbsp;
                                </div>
                              ) : (
                                ``
                              )}
                            </div>
                            {datum.session_type && datum.session_type === "1" ? <div className={`${Styles.online_green_badge}`}></div> : ``}
                          </div>
                          <Col xs="12" sm="12" md="12" className={`${selectedModuleToggle[datum.id] ? `` : `d-none`}`} style={{ padding: "0px 12px 0px 39px" }}>
                            <div style={{ position: "relative" }}>
                              {selectedInput === "module_" + datum.id + "description" ? (
                                <textarea
                                  type="text"
                                  name="description"
                                  id={datum.id + `_module_input_description`}
                                  value={datum.description}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                  }}
                                  onChange={(e) => {
                                    e.preventDefault()
                                    settingLoader("description", index, "module_data")
                                    delayedQuery(e.target.name, e.target.value, datum, "module")
                                  }}
                                  onBlur={(e) => {
                                    e.preventDefault()
                                    onBlurData(e.target.value === datum.description ? null : e.target.name, e.target.value, datum, "module")
                                  }}
                                  placeholder={"Session Objective"}
                                  className={`${Styles.font_16} ${StylesA.session_text_area_field} ${StylesA.border_on_hover} p-2 border-none`}
                                  disabled={datum.session_type && datum.session_type === "2"}
                                  required
                                />
                              ) : (
                                <textarea
                                  type="text"
                                  name="description"
                                  id={datum.id + `_module_input_description`}
                                  defaultValue={datum.description}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                  }}
                                  onChange={(e) => {
                                    e.preventDefault()
                                    settingLoader("description", index, "module_data")
                                    delayedQuery(e.target.name, e.target.value, datum, "module")
                                  }}
                                  onBlur={(e) => {
                                    e.preventDefault()
                                    onBlurData(e.target.value === datum.description ? null : e.target.name, e.target.value, datum, "module")
                                  }}
                                  placeholder={"Session Objective"}
                                  className={`${Styles.font_16} ${StylesA.session_text_area_field} ${StylesA.border_on_hover} p-2 border-none`}
                                  disabled={datum.session_type && datum.session_type === "2"}
                                  required
                                />
                              )}
                              {datum.loader2 != undefined && datum.loader2 === true ? (
                                <svg
                                  className="animate-spin my-auto"
                                  style={{ height: `16px`, width: `16px`, position: "absolute", bottom: "15px", right: "5px" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              ) : null}
                            </div>
                            {showCurrentModuleData(datum)}
                          </Col>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
                <div className="px-2 pb-2 d-flex" style={{ justifyContent: "space-between" }}>
                  {loader && modulesNext === null ? (
                    <React.Fragment>
                      <div className="d-flex">
                        <Button
                          disabled={!moduleCreated}
                          className={`${StylesA.add_button_large} ${StylesA.border_on_hover} d-flex`}
                          variant="none"
                          onClick={(e) => {
                            setModuleCreated(false)
                            createModule(e)
                          }}
                        >
                          {moduleCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                          Add Session
                        </Button>
                        <Button
                          className={`${StylesA.add_button_large} ${StylesA.border_on_hover} ml-2`}
                          variant="none"
                          onClick={() => updateLessonSessionUrl()}
                        >
                          I am done!
                        </Button>
                      </div>
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, width: `13px` }} />
                        <p style={{ fontSize: "12px", margin: "0px", color: "#E0E2E5" }}>All changes are saved</p>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </React.Fragment>
            )}
          </Droppable>
        )
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-3">
            <p style={{ fontSize: "15px", marginBottom: "0px", color: "#E0E2E5" }}>No sessions created yet!</p>
            <div className="px-2 pt-2 d-flex justify-content-center">
              {loader && modulesNext === null ? (
                <div className="d-flex">
                  <Button
                    disabled={!moduleCreated}
                    className={`${StylesA.add_button_large} ${StylesA.border_on_hover} d-flex`}
                    variant="none"
                    onClick={(e) => {
                      setModuleCreated(false)
                      createModule(e)
                    }}
                  >
                    {moduleCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                    Add Session
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-3">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function showTopicsButtons(module) {
    let module_id = module.id
    if (modulesData[`module_` + module_id] && modulesData[`module_` + module_id].topics) {
      return (
        <Droppable droppableId={`module_` + module_id + "_session"} type={`topicDraggable`} isDropDisabled={module.session_type && module.session_type === "2"}>
          {(provided, snapshot) => (
            <div className={`bg-transparent`} ref={provided.innerRef} style={getTopicListStyle(snapshot.isDraggingOver)}>
              {modulesData[`module_` + module_id].topics.map((datum, index) => {
                return (
                  <Draggable
                    key={datum.id + `_topic_name_` + index}
                    draggableId={datum.id.toString() + "-topic" + "_" + index.toString() + "_session"}
                    index={index}
                    isDragDisabled={(module.session_type && module.session_type === "2") || (datum.topic_stage && datum.topic_stage === "disable")}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`bg-transparent float-none ${StylesA.border_on_hover} ${
                          datum.topic_stage && datum.topic_stage === "disable" ? Styles.opacity_0_5 : Styles.opacity_1
                        }`}
                        style={getTopicItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div
                          variant="none"
                          id={"topics_" + datum.id}
                          className={`${Styles.font_16} px-2 shadow-none d-flex`}
                          style={{
                            color: `#F2F4FF`,
                            justifyContent: "space-between",
                            backgroundColor: "#42506C",
                            borderRadius: selectedTopicToggle[datum.id] ? "8px 8px 0px 0px" : "8px",
                            padding: "4px 0px",
                          }}
                        >
                          <div className="d-flex pl-0 col-11">
                            <img
                              src={DragHandleIcon}
                              alt={`drag-handle`}
                              className={`my-auto mr-1 pointer`}
                              style={{ objectFit: `contain`, height: `13px`, width: "16px" }}
                            />
                            {selectedInput === "topic_" + datum.id + "name" ? (
                              <input
                                autoComplete="off"
                                type="text"
                                name="name"
                                id={datum.id + `_topic_input_name`}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                value={datum.name}
                                onChange={(e) => {
                                  e.preventDefault()
                                  datum.module = module_id
                                  settingLoader(module_id, index, "modulesData")
                                  delayedQuery(e.target.name, e.target.value, datum, "topic")
                                }}
                                onBlur={(e) => {
                                  e.preventDefault()
                                  onBlurData(e.target.value === datum.name ? null : e.target.name, e.target.value, datum, "topic")
                                }}
                                placeholder={"Activity name"}
                                className={`${StylesA.activity_input_field} form-control border-0 px-2 ${Styles.font_16}`}
                                disabled={(module.session_type && module.session_type === "2") || (datum.topic_stage && datum.topic_stage === "disable")}
                                required
                              />
                            ) : (
                              <input
                                autoComplete="off"
                                type="text"
                                name="name"
                                id={datum.id + `_topic_input_name`}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                defaultValue={datum.name}
                                onChange={(e) => {
                                  e.preventDefault()
                                  datum.module = module_id
                                  settingLoader(module_id, index, "modulesData")
                                  delayedQuery(e.target.name, e.target.value, datum, "topic")
                                }}
                                onBlur={(e) => {
                                  e.preventDefault()
                                  onBlurData(e.target.value === datum.name ? null : e.target.name, e.target.value, datum, "topic")
                                }}
                                placeholder={"Activity name"}
                                className={`${StylesA.activity_input_field} form-control border-0 px-2 ${Styles.font_16}`}
                                disabled={(module.session_type && module.session_type === "2") || (datum.topic_stage && datum.topic_stage === "disable")}
                                required
                              />
                            )}
                            {datum.loader != undefined && datum.loader === true ? (
                              <svg
                                className="animate-spin my-auto"
                                style={{ height: `16px`, width: `16px` }}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            ) : null}
                          </div>
                          <div className="d-flex">
                            <FontAwesomeIcon
                              onClick={(e) => {
                                e.preventDefault()
                                setSelectedTopic(datum.id)
                                toggleTopic(e, datum.id)
                              }}
                              className="mr-2 pointer"
                              icon={selectedTopicToggle[datum.id] ? faChevronCircleUp : faChevronCircleDown}
                              style={{ width: `16px`, height: `16px`, margin: `auto 0px` }}
                            />
                            {(module.session_type && module.session_type === "2") || (datum.topic_stage && datum.topic_stage === "disable") ? (
                              ``
                            ) : (
                              <div
                                className={`pointer d-inline-flex`}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setDmodal(true)
                                  datum.module = module_id
                                  setSelectedDeletingTopic(datum)
                                }}
                              >
                                <img src={DeleteIcon} alt={`topic-delete`} className={`my-auto`} style={{ objectFit: `contain`, height: `13px` }} />
                                &nbsp;
                              </div>
                            )}
                          </div>
                        </div>
                        {selectedTopicToggle[datum.id] ? showTopicsData(datum, false, module) : ``}
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
              {currentModuleTopicsFetched && modulesData[`module_` + module_id].topics_next !== null ? (
                <Col lg="12" className={`text-center`}>
                  <Button
                    disabled={modulesData[`module_` + module_id].topics_loader}
                    variant="none"
                    onClick={(e) => {
                      e.preventDefault()
                      fetchNextCurrentTopics(modulesData[`module_` + module_id].topics_next)
                    }}
                    className={`${Styles.font_12} text-center px-3 py-1 d-flex mx-auto`}
                    style={{ backgroundColor: `#212c42`, color: `#c6cff4`, borderRadius: `15px` }}
                  >
                    {modulesData[`module_` + module_id].topics_loader ? <Spinner animation="border" variant="secondary" size="sm" className="mr-2" /> : ""}
                    See More
                  </Button>
                </Col>
              ) : (
                ``
              )}
              {module.session_type && module.session_type === "2" ? (
                ""
              ) : (
                <div className={`justify-content-center pt-4 pb-3 d-flex bg-transparent`}>
                  <p className={`pl-3`}>
                    <span className={`${Styles.font_14}`} style={{ color: `#E0E2E5` }}>
                      Drag and drop topics to add content
                    </span>
                  </p>
                </div>
              )}
              {/*showVisibleTopicBtn(module_id,modulesData[`module_`+module_id].topics.length)*/}
            </div>
          )}
        </Droppable>
      )
    }
  }

  function showResourcesButton(type, topic, visible_as_resource = false, module) {
    let id = topic.id
    let module_id = module.id
    let logo = video
    if (props.currentSelectedResourcesType === "blog") {
      logo = blog
    } else if (props.currentSelectedResourcesType === "video") {
      logo = video
    } else if (props.currentSelectedResourcesType === "link") {
      logo = link
    } else if (props.currentSelectedResourcesType === "quiz") {
      logo = quiz
    } else {
      logo = turientimage
    }
    let data = []
    if (type === `topic`) {
      // data = topicsResources[`topic_`+id]
      var index = null
      if (modulesData[`module_` + module_id] && modulesData[`module_` + module_id].topics && modulesData[`module_` + module_id].topics.length > 0) {
        index = modulesData[`module_` + module_id].topics.findIndex((res) => res.id === id)
      }
      data =
        visible_as_resource === false
          ? topicsResources[`topic_` + id]
          : index !== null && modulesData[`module_` + module_id].topics[index].visible_resources
          ? modulesData[`module_` + module_id].topics[index].visible_resources
          : topicsResources[`topic_` + id]
    } else if (type === `module`) {
      data = modulesResources[`module_` + id]
    } else {
      data = allResources[props.currentSelectedResourcesType]
    }
    data = data ? data : []
    let next_url = null
    let see_more_loader = false
    if (type === "resource") {
      next_url = allResourcesNext[props.currentSelectedResourcesType]
    } else if (type === "topic") {
      next_url = topicsResourcesNext[`topic_` + id]
      see_more_loader = topicsResourcesNextLoader[`topic_` + id]
    } else {
      next_url = modulesResourcesNext[`module_` + id]
    }
    if (data.length > 0) {
      return (
        <Droppable
          droppableId={type + `_` + id}
          type={`resourceDraggable`}
          isDropDisabled={(module.session_type && module.session_type === "2") || (topic.topic_stage && topic.topic_stage === "disable")}
        >
          {(provided, snapshot) => (
            <div
              className={`bg-transparent px-4 ${type} ${type === `resource` ? `resources` : ``} ${
                type === `resource` && collapse ? `${Styles.opacity_0} p-0 true` : `${Styles.opacity_1} false`
              }`}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {data.map((item, index) => (
                <Draggable
                  key={item.id + `_${type}_name_` + index}
                  draggableId={item.id.toString() + `-${type}` + "_" + index.toString()}
                  index={index}
                  isDragDisabled={(module.session_type && module.session_type === "2") || (topic.topic_stage && topic.topic_stage === "disable")}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <div className={`pr-2 d-flex`} style={{ color: `#E0E2E5`, background: "#4F6080", justifyContent: "space-between" }}>
                        <div className="d-flex" style={{ alignItems: "center" }}>
                          <FontAwesomeIcon
                            className={`${modulesResourcesFetched} ${topicsResourcesFetched} ml-1 ${item.resource_type ? `d-none` : ``}`}
                            icon={faSpinner}
                            size="lg"
                          />
                          {item.resource_data ? (
                            item.resource_data.thumbnail_url ? (
                              <LazyLoadImage
                                className={`my-auto`}
                                src={item.resource_data.thumbnail_url}
                                alt={item.name}
                                style={{ width: `38px`, height: `30px`, fontSize: `12px` }}
                              />
                            ) : item.resource_data.avatar ? (
                              <LazyLoadImage
                                className={`my-auto`}
                                src={
                                  item.resource_data.avatar && item.resource_data.avatar.indexOf(".pdf") < 1
                                    ? item.resource_data.avatar
                                    : item.resource_type === "video"
                                    ? video
                                    : turientimage
                                }
                                alt={item.name}
                                style={{ width: `38px`, height: `30px`, fontSize: `12px` }}
                              />
                            ) : (
                              <LazyLoadImage
                                className={`my-auto`}
                                src={
                                  item.resource_type === "video"
                                    ? video
                                    : item.resource_type === "blog"
                                    ? blog
                                    : item.resource_type === "link"
                                    ? link
                                    : item.resource_type === "quiz"
                                    ? quiz
                                    : turientimage
                                }
                                alt={item.name}
                                style={{ width: `38px`, height: `30px`, fontSize: `12px` }}
                              />
                            )
                          ) : (
                            ``
                          )}
                          <p style={{ fontSize: "14px", margin: "0px 0px 0px 10px" }}>
                            {item.resource_data ? item.resource_data?.display_name ?? item.resource_data?.name : item.name}
                          </p>
                        </div>
                        <div
                          className={
                            (module.session_type && module.session_type === "2") || (topic.topic_stage && topic.topic_stage === "disable") ? `d-none` : ``
                          }
                        >
                          <img
                            src={DeleteIcon}
                            onClick={() => {
                              const newState = data
                              newState.splice(index, 1)
                              setResourceDeleted(false)
                              if (type === "topic") {
                                topicsResources[`topic_` + id] = newState
                                setTopicsResourcesFetched(false)
                              } else {
                                modulesResources[`module_` + id] = newState
                                setModulesResourcesFetched(false)
                              }
                              deleteResource(item.id, true, item, id, type)
                            }}
                            className={`my-auto pointer`}
                            style={{ objectFit: `contain`, height: `13px` }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <React.Fragment>
                {type === "module" && next_url && Object.keys(next_url).length > 0 ? (
                  <div className={`text-center`}>
                    <Button
                      variant="none"
                      onClick={(e) => {
                        e.preventDefault()
                        fetchNextModuleResources(next_url.next)
                      }}
                      className={`${Styles.font_12} text-center px-3 py-1 mt-2`}
                      style={{ backgroundColor: `#212c42`, color: `#c6cff4`, borderRadius: `15px` }}
                    >
                      See More
                    </Button>
                  </div>
                ) : type === "topic" && next_url && Object.keys(next_url).length > 0 ? (
                  <div className={`text-center pb-2`}>
                    <Button
                      disabled={see_more_loader}
                      variant="none"
                      onClick={(e) => {
                        e.preventDefault()
                        fetchNextTopicResources(next_url.next)
                      }}
                      className={`${Styles.font_12} text-center px-3 py-1 mt-2 d-flex mx-auto`}
                      style={{ backgroundColor: `#212c42`, color: `#c6cff4`, borderRadius: `15px` }}
                    >
                      {see_more_loader ? <Spinner animation="border" variant="secondary" size="sm" className="mr-2" /> : ""}
                      See More
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                {/*<div className={`justify-content-center pt-4 pb-3 ${type === 'resource' ? `d-none` : `d-flex bg-transparent` }`} >
                  <Image
                    style={{width: "100px",height: "80px"}}
                    alt="add resources here"
                    src="stages.png"
                    className="d-inline-block" />
                  <p className={`pl-3`} >
                    <span className={`font-weight-bold`} style={{ color: `#D4DCF7` }}>Add Content</span>
                    <br/>
                    <span className={`${Styles.font_14}`} style={{ color: `#C6CFF4` }}>Drag and drop media/document files to add content</span>
                  </p>
                </div>*/}
              </React.Fragment>
            </div>
          )}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-3">
          <p style={{ fontSize: "14px", margin: "0px", color: "#E0E2E5" }}>No resources added yet!</p>
        </Col>
      )
    }
  }

  let page_uri = isBrowser() ? "/" + window.location.pathname.substr(1) : "false"
  if (page_uri === "/logout" || page_uri === "logout" || page_uri === "logout/" || page_uri === "/logout/") {
    page_uri = "/"
  }

  useEffect(() => {
    if (initialFetched && modules.length > 0) {
      /*setTimeout(function(){
        let module_id = modules[modules.length - 1].id
        if (location.props.location.hash.split(',module=')[1]) {
          module_id = location.props.location.hash.split(',module=')[1]
        }
        setSelectedModule(module_id)
        selectedModuleToggle[module_id] = true
        scrollToSpecific(null,'modules_'+module_id)
      }, 1000);*/
    }
  }, [initialFetched])

  function scrollToSpecific(e, value) {
    if (e !== null) {
      e.preventDefault()
    }
    if (isBrowser() && value !== "" && document.getElementById(value) && document.getElementById("scrollable-div")) {
      var topic_div = document.getElementById(value).getBoundingClientRect()
      setTimeout(function () {
        document.getElementById("scrollable-div").scroll({
          behavior: "smooth",
          left: 0,
          top: topic_div.top - 120,
        })
      }, 200)
      /*setTimeout(function(){*/
      // document.getElementById('scrollable-div').scrollTo(0, document.getElementById(value).offsetTop );
      /*}, 300);*/
    }
  }

  function openNav() {
    let side_div_width = document.getElementById("mySidepanel").offsetWidth
    if (side_div_width > 250) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }

  function showMainContent() {
    if (lessonPlansFetched && initialFetched) {
      if (lessonPlanTopics[`module_` + currentLessonModule].topics.length > 0 || modules.length > 0) {
        return (
          <Col xs="12" sm="12" md="12" lg="12" className="px-0 d-flex" style={{ height: "calc(80vh - 45px)" }}>
            <Col xs="4" sm="4" md="4" lg="4" className={`${StylesA.left_lesson_plan_section} p-2`}>
              <p>Suggested Topics</p>
              {showLessonPlanModulesButtons()}
            </Col>
            <Col xs="8" sm="8" md="8" lg="8" className={`${StylesA.right_session_plan_section}`}>
              {showModulesButtons()}
              {loader && modulesNext !== null ? (
                <Col md="auto" className={`justify-content-center w-100 d-inline-flex`}>
                  <Button
                    disabled={modulesLoader}
                    variant="none"
                    onClick={(e) => {
                      e.preventDefault()
                      fetchNextTemplateModules(modulesNext)
                    }}
                    className={`${Styles.font_12} ${StylesA.border_on_hover} text-center px-3 py-1 d-flex`}
                    style={{ backgroundColor: `#303c54`, color: `#c6cff4`, borderRadius: `15px` }}
                  >
                    {modulesLoader ? <Spinner animation="border" variant="secondary" size="sm" className="mr-2" /> : ""}
                    See More
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Col>
          </Col>
        )
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <p style={{ fontSize: "15px", marginBotton: "5px", color: "#E0E2E5" }}>Please create lesson plans first and add them to session plan.</p>
            <Button className={`${StylesA.add_button} ${StylesA.border_on_hover}`} variant="none" onClick={() => props.setActiveSection("LessonPlan")}>
              Add Lesson Plan
            </Button>
          </Col>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  const updateLessonSessionUrl = () => {
    props.setActiveSection("Home")
    if (props.fetchRecentActivity) {
      props.fetchRecentActivity("true", "lesson_session")
    }
    window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}`)
  }

  return (
    <Col lg="12" md="12" sm="12" xs="12" className={`px-0 d-inline-flex`}>
      <Modal show={dmodal} onHide={handleClose} centered className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal`}>
        <div className={`${StylesA.delete_modal_header}`}>
          <img
            onClick={handleClose}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{ objectFit: `contain`, height: `13px`, position: "absolute", top: "7px", right: "5px" }}
          />
          <img src={TrashCan} alt={`delete modal delete icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `37px` }} />
          <div>
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>Delete</p>
            <p>
              {selectedDeletingTopic && selectedDeletingTopic.name !== undefined
                ? "Item will be moved back to lesson plan"
                : "Item will be deleted permanentley!"}
            </p>
          </div>
        </div>
        <Modal.Body className="text-center" style={{ backgroundColor: "#121b2b", borderRadius: "0px 0px 8px 8px" }}>
          <p style={{ color: "#E0E2E5", fontSize: "15px", marginBottom: "10px" }}>
            {" "}
            Are you sure you want to {selectedDeletingTopic && selectedDeletingTopic.name !== undefined ? "remove" : "delete"}{" "}
            {selectedDeletingModule && selectedDeletingModule.name !== undefined ? `session ` + selectedDeletingModule.name : ""}{" "}
            {selectedDeletingTopic && selectedDeletingTopic.name !== undefined && selectedDeletingTopic.visible_as_resource === false
              ? `topic ` + selectedDeletingTopic.name
              : ""}{" "}
            ?{" "}
          </p>
          <div className="d-flex justify-content-center">
            {selectedDeletingModule && selectedDeletingModule.name !== undefined ? (
              <Button
                className={`${StylesA.add_button_large} ${StylesA.border_on_hover} mr-3`}
                variant="secondary"
                onClick={deleteModule}
                style={{ width: "75px", border: "1px solid transparent" }}
              >
                Yes
              </Button>
            ) : (
              ``
            )}
            {selectedDeletingTopic && selectedDeletingTopic.name !== undefined ? (
              <Button
                className={`${StylesA.add_button_large} ${StylesA.border_on_hover} mr-3`}
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault()
                  deleteTopic()
                }}
                style={{ width: "75px", border: "1px solid transparent" }}
              >
                Yes
              </Button>
            ) : (
              ``
            )}
            <Button
              className={`${StylesA.add_button_large} ${StylesA.border_on_hover}`}
              variant="secondary"
              onClick={handleClose}
              style={{ width: "75px", border: "1px solid transparent" }}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Col lg="12" md="12" sm="12" xs="12" className={`${StylesA.breakout_main_section} px-0`}>
        <div className={`${StylesA.breakout_header} d-flex p-2`}>
          <p>Class Content</p>
          <img
            src={CancelWhite}
            onClick={() => {
              updateLessonSessionUrl()
              props.setCurrentNavTab("")
            }}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{ objectFit: `contain`, height: `13px` }}
          />
        </div>
        {showMainContent()}
      </Col>
    </Col>
  )
}

export default SessionPlan
