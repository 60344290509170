import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import Col from "react-bootstrap/Col"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"
import Dropdown from "react-bootstrap/Dropdown"

import SEO from "../../components/seo"
import authClient from "../../services/auth"
import utcDateTime from "../../services/utcdatetime"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRss, faChevronDown, faEdit, faTrash, faPlus, faEye, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { HasCreatePermission, HasDeletePermission, HasEditPermission, HasReadPermission } from "../../utils/PermissionDataAccess"
import { CLASSES, STUDENT } from "../../constants/permissionsConstant"
import NoPermissionError from "../no-permission-error"
import { getRequest, postRequest, putRequest } from "../../services/rest_service"

const ViewCourse = (location, data) => {
  const [course, setCourse] = useState({})

  const [trainingLoader, setTrainingLoader] = useState(false)
  const [trainings, setTrainings] = useState([])

  const [training, setTraining] = useState([])

  const [student_email, setStudentEmail] = useState("")
  const [student_name, setStudentName] = useState("")
  const [participants, setParticipants] = useState([])
  const [stages, setStages] = useState([])

  const [show, setShow] = useState(false)

  const [showView, setShowView] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = (training) => {
    setTraining(training)
    setShow(true)
  }
  const handleCloseView = () => setShowView(false)
  const handleShowView = async (training) => {
    setTraining(training)
    let res = await getRequest("/trainings/" + training.id + "/students/")
    if (res.success) {
      setParticipants(res.data)
      setShowView(true)
    }
  }

  const fetchStudents = async () => {
    let res = await getRequest("/trainings/" + training.id + "/students/")
    if (res.success) {
      setParticipants(res.data)
      setShowView(true)
    }
  }
  useEffect(() => {
    fetchCompany()
    fetchTrainings()
    fetchStages()
  }, [])

  async function fetchCompany() {
    let res = await getRequest(`/courses/${location.id}/`)
    if (res.success) {
      setCourse(res.data)
    }
  }

  async function fetchStages() {
    let res = await getRequest(`/default-course-templates/fetch_or_create/${location.id}/`)
    if (res.success) {
      setStages(res.data.stages)
    }
  }

  async function fetchTrainings() {
    setTrainingLoader(false)
    let res = await getRequest(`/trainings/?course=${location.id}`)
    if (res.success) {
      setTrainings(res.data.results)
      setTrainingLoader(true)
    }
  }

  function handleChange(event) {
    event.preventDefault()
    if (event.target.name === "name") {
      setStudentName(event.target.value)
    } else if (event.target.name === "email") {
      setStudentEmail(event.target.value)
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    var formData = new FormData()
    // for(var k in course) {
    formData.append("student_email", student_email)
    formData.append("first_name", student_name)
    // }
    var current_course_id = training.id
    var index = trainings.findIndex((res) => res.id === current_course_id)
    if (student_email && student_email != undefined) {
      let res = await postRequest(`/trainings/${training.id}/students/create/`, formData)
      if (res.success) {
        training.participants = training.participants + 1
        trainings.splice(index, 1)
        trainings.splice(index, 0, training)
        setTrainings(trainings)
      }
    }
  }

  function displayCompanyData() {
    let data = trainings
    var isLoaded = trainingLoader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0]),
          parsed_data = []
        parsed_data.push(
          <div className={`d-flex p-3`}>
            <div className={`pl-4 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Class
              </p>
            </div>
            <div className={`pl-3 ml-auto mr-3 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Participants&nbsp; <FontAwesomeIcon icon={faChevronDown} size="sm" />
              </p>
            </div>
          </div>
        )
        data.map((datum, index) => {
          parsed_data.push(
            <div className={`d-flex mb-1 py-2`} key={index + `_training`} style={{ backgroundColor: `#46537E` }}>
              <div className={`pl-3 align-self-center`}>
                <FontAwesomeIcon icon={faRss} style={{ color: `#ebc280` }} size="lg" />
              </div>
              <div className={`pl-3 align-self-center`}>
                <p className={`m-0 font-weight-bold text-white`}>{datum.name}</p>
                <p className={`m-0`} style={{ color: `#27E0C3`, fontSize: "12px" }}>
                  <span>Published on </span>{" "}
                  <span>
                    {" "}
                    {`${utcDateTime.utcDateTime(datum.created, "date")}-${utcDateTime.utcDateTime(datum.created, "short_month_name")}-${utcDateTime.utcDateTime(
                      datum.created,
                      "year"
                    )}`}{" "}
                  </span>{" "}
                </p>
                {HasCreatePermission(STUDENT) && (
                  <Button
                    style={{ fontSize: "12px", color: "#7bf8e5" }}
                    className={`ml-auto font-12 mr-2 bg-transparent border-none shadow-none p-0`}
                    onClick={() => handleShow(datum)}
                  >
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                    &nbsp; Add Students
                  </Button>
                )}
                {HasReadPermission(STUDENT) && (
                  <Button
                    style={{ fontSize: "12px", color: "#7bf8e5" }}
                    className={`ml-2 font-12 bg-transparent border-none shadow-none p-0`}
                    onClick={() => handleShowView(datum)}
                  >
                    <FontAwesomeIcon icon={faEye} size="sm" />
                    &nbsp; View Participants
                  </Button>
                )}
                <Col className="p-0">
                  {HasEditPermission(CLASSES) && (
                    <Link className={`mr-2`} to={`/app/companies/${location.company_id}/courses/${location.id}/trainings/edit/` + datum.id}>
                      {" "}
                      <FontAwesomeIcon icon={faEdit} size="sm" />{" "}
                    </Link>
                  )}
                  {HasDeletePermission(CLASSES) && (
                    <Link to={`/app/companies/${location.company_id}/courses/${location.id}/trainings/view/` + datum.id}>
                      {" "}
                      <FontAwesomeIcon icon={faTrash} size="sm" />{" "}
                    </Link>
                  )}
                </Col>
              </div>
              {HasReadPermission(STUDENT) && (
                <div style={{ cursor: "pointer" }} onClick={() => handleShowView(datum)} className={`pl-3 ml-auto mr-3 align-self-center`}>
                  <p className={`mb-0 font-weight-bold pr-5`} style={{ color: `#C6CFF4` }}>
                    {datum.participants}
                  </p>
                </div>
              )}
            </div>
          )
        })
        return <div>{parsed_data}</div>
      } else {
        return "No Classes Found"
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  const selectItem = async (e, stage, participant) => {
    e.preventDefault()
    var formData = new FormData()
    formData.append("stage", stage.id)
    if (stage && stage != undefined) {
      let res = await putRequest(`/training-tracks/${participant.id}/edit/`, formData)
      if (res.success) {
        fetchStudents()
      }
    }
  }

  function showCompanies() {
    if (authClient.isAuthenticated()) {
      return <div>{displayCompanyData()}</div>
    } else {
      return ""
    }
  }

  return (
    <div>
      <SEO title="Company Creation" />
      <Link className={`pointer text-white`} to={`/app/dashboard`}>
        {" "}
        <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
      </Link>
      <Modal show={show} onHide={handleClose} style={{ marginTop: "100px" }}>
        <Modal.Header className="p-0 participant-modal" style={{ background: "#12182c" }} closeButton>
          <div className={`d-flex p-3 w-100`}>
            <div className={`pl-4 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Add student
              </p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0" style={{ background: "#46537E" }}>
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col xs="12" sm="6" md="12" className="float-left pl-0 pr-0">
              <Col xs="12" sm="6" md="12" className="float-left pt-4 pb-4 pl-2 pr-2 border-bottom">
                <FormGroup className="d-flex-align-center m-0">
                  <label className="video-modal m-0">
                    <input
                      style={{ background: "inherit", color: "#fff" }}
                      className="video-modal blue-bg"
                      type="text"
                      name="name"
                      defaultValue={student_name}
                      onChange={handleChange}
                      id="name"
                      placeholder=" "
                      required
                    />
                    <span style={{ color: "#fff" }} className="video-modal">
                      Enter student name
                    </span>
                  </label>
                </FormGroup>
              </Col>
              <Col xs="12" sm="6" md="12" className="float-left pt-4 pb-4 pl-2 pr-2">
                <FormGroup className="d-flex-align-center m-0">
                  <label className="video-modal m-0">
                    <input
                      style={{ background: "inherit", color: "#fff" }}
                      className="video-modal blue-bg"
                      type="email"
                      name="email"
                      defaultValue={student_email}
                      onChange={handleChange}
                      id="email"
                      placeholder=" "
                      required
                    />
                    <span style={{ color: "#fff" }} className="video-modal">
                      Enter student email
                    </span>
                  </label>
                </FormGroup>
              </Col>
            </Col>
            <Modal.Footer>
              <Button className="float-right" style={{ border: 0 }} type="submit" variant="info">
                Proceed
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showView} onHide={handleCloseView} style={{ marginTop: "100px" }} dialogClassName="participants-dialog">
        <Modal.Header className="p-0 participant-modal" style={{ background: "#12182c" }} closeButton>
          <div className={`d-flex p-3 w-100`}>
            <div className={`pl-4 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Participants
              </p>
            </div>
            <div className={`pl-3 ml-auto mr-3 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Stage&nbsp; <FontAwesomeIcon icon={faChevronDown} size="sm" />
              </p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0" style={{ background: "#12182c", "max-height": "calc(100vh - 210px)", "overflow-y": "auto" }}>
          {participants && participants.length > 0 ? (
            participants.map((participant, index2) => (
              <div className={`d-flex mb-1 py-2`} key={index2 + `_training`} style={{ backgroundColor: `#46537E` }}>
                <div className={`pl-3 align-self-center`}>
                  <FontAwesomeIcon icon={faRss} style={{ color: `#ebc280` }} size="lg" />
                </div>
                <div className={`pl-3 align-self-center`}>
                  <p className={`m-0 font-weight-bold text-white`}>{participant.owner__email}</p>
                </div>
                <div className={`pl-3 ml-auto mr-3 align-self-center`} style={{ minWidth: "250px" }}>
                  <FormGroup className={`col-10`}>
                    <label className={`font-weight-bold text-white`} htmlFor="course_template">
                      Choose Stage
                    </label>
                    <Dropdown>
                      <Dropdown.Toggle variant="info" id="dropdown-basic">
                        {participant.stage__name !== null ? participant.stage__name : "Select Stage"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {stages.map((stage, index) => (
                          <Dropdown.Item key={index.toString() + "Template"} name="course_template" onClick={(e) => selectItem(e, stage, participant)}>
                            {stage.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </FormGroup>
                </div>
              </div>
            ))
          ) : (
            <Col>
              <p className="text-white p-2">No Participants Added</p>
            </Col>
          )}
        </Modal.Body>
      </Modal>
      {HasReadPermission(CLASSES) ? (
        <Col lg="12" md="12" sm="12" xs="12">
          <h4 className={`mt-3`}>
            All Classes
            <Button
              onClick={() => navigate(`/app/companies/${location.company_id}/courses/${course.course_template.id}/trainings/create`)}
              style={{ background: "#485b9e", border: "none", fontSize: "14px", float: "right" }}
            >
              + Add Class
            </Button>
          </h4>
          <Col className="p-0 mt-4">
            <div>
              <div className={`bg-card mb-5`} style={{ overflow: "scroll" }}>
                {showCompanies()}
              </div>
            </div>
          </Col>
        </Col>
      ) : (
        <NoPermissionError />
      )}
    </div>
  )
}

export default ViewCourse
