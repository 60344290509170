/* eslint-disable */

import React, { useEffect, useState } from "react"
import _ from "lodash"

import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Image from "../custom/Image"
import Dropdown from "react-bootstrap/Dropdown"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkSquareAlt, faChevronLeft, faChevronRight, faEllipsisV } from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"
import Close from "../../images/svgs/close.svg"

import StylesB from "./casestudy.module.scss"

var FromTrainingOrClassCreation = {}
const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

const CreateCaseStudy = (location) => {
  const [casestudy, setCaseStudy] = useState({
    name: null,
    time: "30",
  })

  const [hovered, setHovered] = useState(false)
  const [activestate, setActiveState] = useState("add_detail")
  const [addToResLib, setAddToResLib] = useState(false)
  const [dispAddToResLib, setDispAddToResLib] = useState(false)
  useEffect(() => {
    setAddToResLib(false)
  }, [])

  useEffect(() => {
    if (isBrowser()) {
      if (window.opener !== null && window.name === "casestudy12") {
        window.opener.postMessage({ instruction: "ready", type: "casestudy" }, window.location.origin)
      }
      if (window.FromTrainingOrClassCreation) {
        FromTrainingOrClassCreation = window.FromTrainingOrClassCreation
      }
      window.addEventListener("message", handleCrossWindow, false)
      focusOnData("CaseStudyName")
      return () => {
        window.removeEventListener("message", handleCrossWindow)
      }
    }
  }, [])

  function handleCrossWindow(event) {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      setAddToResLib(true)
      setDispAddToResLib(true)
      window.FromTrainingOrClassCreation = FromTrainingOrClassCreation = { ...event.data.instruction }
    }
  }

  const handleChange = (event) => {
    event.preventDefault()
    let current_case_study = { ...casestudy }
    current_case_study[event.target.name] = event.target.value
    setCaseStudy(current_case_study)
  }

  const focusOnData = (id) => {
    let data = document.getElementById(id)
    if (data) {
      data.focus()
    }
  }

  const findScrollableOrNot = () => {
    let ele = document.getElementById("sections")

    if (ele !== null && ele !== undefined) {
      if (ele.scrollWidth > ele.clientWidth) {
        return true
      } else {
        return false
      }
    }
  }

  const updateActiveState = (e) => {
    setActiveState(e.target.id)
  }

  const calculateWidth = () => {
    let ele = document.getElementById("casestudyname")
    if (ele !== null) {
      let data = 1100 - 142 - 50
      return { width: data + "px", overflowX: "scroll" }
    }
  }

  const scrollRight = () => {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft += 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  const createCaseStudyData = (event) => {
    event.preventDefault()
    if (casestudy.name && casestudy.time) {
      var formData = new FormData()
      formData.append("name", casestudy.name)
      formData.append("time", casestudy.time)
      formData.append("is_added_to_library", addToResLib)

      if (FromTrainingOrClassCreation && FromTrainingOrClassCreation.sequence) {
        for (let p in FromTrainingOrClassCreation) {
          formData.append(p, FromTrainingOrClassCreation[p])
        }
        //formData.append('topic'     , FromTrainingOrClassCreation.topic_id)
        //formData.append('sequence'  , parseFloat(FromTrainingOrClassCreation.seq) + 10)
      }

      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/case-study/", formData, { headers: login_credentials })
        .then((res) => {
          navigate(`/app/case-study/edit/${res.data.id}`, { state: { addToResLib } })
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()

            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const showLinksData = () => {
    return (
      <Col
        className="px-0 border_on_hover pl-0"
        style={{
          backgroundColor: "#303C54",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "9px",
          border: "1px solid #303C54",
          marginBottom: "12px",
          marginTop: "12px",
        }}
      >
        <p
          className="mb-0 py-2 px-3"
          id="coursename"
          style={{ borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "18px", lineHeight: "1", minWidth: "max-content" }}
        >
          Add Instructions or guidelines
        </p>
        <InputGroup className="py-0 d-block " style={{ borderRadius: "9px", border: "1px solid transparent" }}>
          <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
            <InputGroup.Text
              className="border-0 d-flex justify-content-center pb-0 mb-2"
              style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
              id="basic-addon1"
            >
              {"Drag and drop resources here to add"}
            </InputGroup.Text>
          </InputGroup.Prepend>

          <InputGroup.Append className={`p-2 mt-3`}>
            <u className="pointer" style={{ fontSize: "14px", color: "#E0E2E5" }}>
              Add External Link
            </u>
          </InputGroup.Append>
        </InputGroup>
      </Col>
    )
  }

  const getFormData = () => {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-0">
        <Col className="px-0 d-flex my-0" style={{ maxWidth: "100%" }}>
          <div className="px-0" style={{ width: "calc(100%)" }}>
            <Col
              lg="12"
              md="12"
              sm="12"
              xs="12"
              className="border_on_hover pl-0"
              style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54", marginBottom: "12px" }}
            >
              <InputGroup onBlur={createCaseStudyData} className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                <InputGroup.Prepend className="border-0 bg-303C54 br-9009px">
                  <InputGroup.Text
                    className="border-0 d-flex justify-content-center bg-303C54 br-9009px text-E0E2E5 opacity-41 fs-16px"
                    id="basic-addon1"
                    onClick={(e) => focusOnData("CaseStudyName")}
                  >
                    Case Study Name:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  className={`border-0 d-flex justify-content-center shadow-none ${StylesB.cs_create} text-white`}
                  style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px" }}
                  type="text"
                  name="name"
                  defaultValue={casestudy.name}
                  onChange={handleChange}
                  id="CaseStudyName"
                  required
                />
              </InputGroup>
            </Col>
          </div>
        </Col>
        <React.Fragment>
          <Col
            className="mb-0 border_on_hover pl-0"
            style={{
              backgroundColor: "#303C54",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "9px",
              border: "1px solid #303C54",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            <InputGroup className="py-1 align-items-center" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
              <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                <InputGroup.Text
                  className="border-0 d-flex justify-content-center"
                  style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                  id="basic-addon1"
                  onClick={(e) => focusOnData(e, "duration")}
                >
                  Duration:
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className={`border-0 d-flex justify-content-center shadow-none pr-1 ${StylesB.cs_create} text-white`}
                style={{ maxWidth: "40px", backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px" }}
                type="number"
                name="time"
                defaultValue={casestudy.time}
                onChange={handleChange}
                id="minutes"
                required
              />
              <p className="mb-0" style={{ color: "#E0E2E5", opacity: 0.4 }}>
                minutes
              </p>
            </InputGroup>
          </Col>
        </React.Fragment>
        {/* {showLinksData()} */}
      </Col>
    )
  }

  const getCaseStudyData = () => {
    return (
      <>
        <Col lg="12" md="12" sm="12" xs="12" className="px-0">
          {getFormData()}
        </Col>
        <Col xs="12" className="mt-3 d-none">
          <Button className="float-right" id={`form-proceed`} style={{ background: "#576186", border: 0 }} type="submit" color="warning">
            Proceed
          </Button>{" "}
        </Col>
      </>
    )
  }

  return (
    <div>
      <SEO title="Case Study" />
      <Col xs="12" sm="12" md="12" lg="12" className="pt-2 d-flex" style={{ alignItems: "center", flexDirection: "column" }}>
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          onMouseOver={(e) => {
            e.stopPropagation()
            e.preventDefault()
            let ele = document.getElementById("sections")
            if (ele !== null && ele !== undefined) {
              if (ele.scrollWidth > ele.clientWidth) {
                setHovered(true)
              }
            }
          }}
          className={`${StylesB.cs_creation_header} d-flex px-0`}
        >
          <p
            className="mb-0 py-2 px-3 font-weight-bold"
            id="casestudyname"
            style={{ borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "18px", lineHeight: "1", minWidth: "max-content" }}
          >
            New Case Study
          </p>
          {findScrollableOrNot() && hovered ? (
            <FontAwesomeIcon
              onClick={() => {
                scrollLeft()
              }}
              className="pointer"
              icon={faChevronLeft}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551` }}
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => {
                scrollLeft()
              }}
              className="pointer"
              icon={faChevronLeft}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551`, visibility: "hidden" }}
            />
          )}

          <div id="sections" className="d-flex align-items-center" style={calculateWidth()}>
            <p
              className={`${StylesB.backgroundonhover} ${
                activestate === "add_detail" ? StylesB.cs_nav_item_active : ""
              } mb-0 pointer py-2 px-3 fs-16px font-weight-bold`}
              style={{ color: "#E0E2E5", border: "1px solid tranparent", minWidth: "max-content" }}
              id="add_detail"
            >
              Add Details
            </p>
            <p
              className={`${StylesB.backgroundonhover} ${
                activestate === "publish_course" ? StylesB.cs_nav_item_active : ""
              } mb-0 pointer py-2 pl-14px pr-14px fs-16px ml-10px `}
              style={{ color: "#E0E2E5", border: "1px solid tranparent", minWidth: "max-content" }}
              id="publish_course"
            >
              Save & Publish
            </p>
            {/* <div className='float-right ml-auto'>
              <button className='border_on_hover d-flex' style={{ backgroundColor: '#586886', border: '1px solid #586886', padding: '5px 15px 7px 15px', boxShadow: '0px 3px 6px #00000029', borderRadius: '23px', color: '#E0E2E5', fontSize: '14px', minWidth: 'max-content' }}>
                <p style={{ marginBottom: '0px', lineHeight: 1, minWidth: 'max-content' }} >Preview students page</p> <FontAwesomeIcon className='ml-2' icon={faExternalLinkSquareAlt} />
              </button>
            </div> */}
          </div>
          {findScrollableOrNot() && hovered ? (
            <FontAwesomeIcon
              onClick={() => {
                scrollRight()
              }}
              className="pointer"
              icon={faChevronRight}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551` }}
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => {
                scrollRight()
              }}
              className="pointer"
              icon={faChevronRight}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551`, visibility: "hidden" }}
            />
          )}

          <Image
            src={Close}
            alt={`close`}
            width={20}
            height={20}
            onClick={() => {
              navigate("/app/dashboard")
            }}
            className={`pointer mr-3 ml-auto float-right align-self-center`}
          />
        </Col>

        <Col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          onMouseOver={() => {
            setHovered(false)
          }}
          className="px-0"
        >
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <div
              className="px-3"
              style={{
                maxHeight: "calc(100vh - 190px)",
                overflowY: "scroll",
                padding: "12px",
                backgroundColor: "#212C42",
                borderRadius: "9px 9px 0px 0px",
                boxShadow: "0px 3px 12px #00000099",
              }}
            >
              {getCaseStudyData()}
            </div>
            <Col
              className="px-0 pl-3 py-2"
              style={{ borderTop: "1px solid #63666f", backgroundColor: "#212C42", borderRadius: "0px 0px 9px 9px", boxShadow: "0px -3px 12px #00000099" }}
            >
              <div className="d-inline-block justify-content-start">
                {/* <button onClick={(e) => { e.preventDefault(); }} className='border_on_hover fs-14px' style={{ backgroundColor: '#586886', border: '1px solid #586886', height: '28px', paddingLeft: '30px', paddingRight: '30px', boxShadow: '0px 3px 6px #00000029', borderRadius: '23px', color: '#E0E2E5' }}>Add questions</button> */}
                <button
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                  className="border_on_hover fs-14px ml-2"
                  style={{
                    backgroundColor: "#586886",
                    border: "1px solid #586886",
                    height: "28px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "23px",
                    color: "#E0E2E5",
                  }}
                >
                  Next
                </button>
              </div>
              {dispAddToResLib ? (
                <div className="pr-2 d-inline-block float-right">
                  <Form.Check
                    className=""
                    style={{ fontSize: 14 }}
                    type={"checkbox"}
                    id={`default-checkbox`}
                    label={`Add this case-study to the resource library`}
                    checked={addToResLib}
                    onChange={() => setAddToResLib((p) => !p)}
                  />
                </div>
              ) : null}
            </Col>
          </Form>
        </Col>
      </Col>
    </div>
  )
}

export default CreateCaseStudy
