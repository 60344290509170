import React, { useEffect, useState, useContext } from "react"
import { navigate, Link } from "gatsby"
import SEO from "./seo"
import Image from "./custom/Image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { isBrowser } from "../services/developer_mode"
import { postRequest } from "../services/rest_service"
import TimerComponent from "./custom/TimerComponent"
import LoginFormError from "./LoginFormError"
import { GlobalContext } from "../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../stores/global_store/GlobalActions"
import LoginInputField from "./custom/Login-InputField"
import { checkPasswordValidation } from "../services/passwordValidation"
import WhatMakesAStrongPassword from "./password-validation/whatMakesAStrongPassword"
import PasswordValidatorInputField from "./password-validation/passwordValidatorInputField"

import { AUTH_TOKEN, USER_NAME } from "../constants/LocalStorageConstant"
import turientimg from "../images/turientimg.png"
import turientLogo from "../images/favicon.png"
import Edit from "../images/svgs/edit.svg"
import GreenSuccess from "../images/svgs/success_1699A2.svg"
import LoginCSS from "../modularscss/Login.module.scss"

// !definition of component
/**
 *
 * @description --> enables students to reset password
 * @returns Forgot Password component
 */
// ! component

const ResetPasswordWithOTP = (props) => {
  const redirection_url = "/"
  const [width, setWidth] = useState(0)
  const [globalState, dispatch] = useContext(GlobalContext)
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [showResend, setShowResend] = useState(false)
  const [otp, setOTP] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [loader, setLoader] = useState(false)
  const [isGenerated, setIsGenerated] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorPassword, setErrorPassword] = useState("")
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("")
  const [errorOTP, setErrorOTP] = useState("")
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [strongPasswordValidation, setStrongPasswordValidation] = useState({
    has_azAZ: false,
    has_09spcl: false,
    has_8char: false,
  })
  const [showOtp, setShowOtp] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [timerDate, setTimerDate] = useState(new Date())
  useEffect(() => {
    if (props.location.state != null) {
      let Email = props.location.state.email ?? localStorage.getItem(USER_NAME)
      setEmail(Email)
    }
  }, [])

  useEffect(() => {
    if (window) {
      handleWindowSizeChange()
      window.addEventListener("resize", handleWindowSizeChange)
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange)
      }
    }
  }, [window])

  useEffect(() => {
    if (localStorage.getItem(AUTH_TOKEN)) {
      navigate(redirection_url ? redirection_url : "/")
    }
  }, [localStorage.getItem(AUTH_TOKEN)])

  useEffect(() => {
    setErrorEmail(false)
  }, [email, otp])

  useEffect(() => {
    setDisableSubmit(false)
  }, [otp, password, confirmPassword])

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  const handleChange = (event) => {
    if (event.target) {
      if (event.target.name === `otp`) {
        setOTP(event.target.value)
        setErrorOTP(false)
      } else if (event.target.name === `email`) {
        setEmail(event.target.value)
      } else if (event.target.name === `password`) {
        setPassword(event.target.value)
        let passwordValidation = checkPasswordValidation(event.target.value, strongPasswordValidation)
        setStrongPasswordValidation(passwordValidation)
        setErrorPassword(false)
      } else if (event.target.name === `confirm_password`) {
        setConfirmPassword(event.target.value)
        setErrorConfirmPassword(false)
      }
    }
  }

  const handleNumberOnlyInput = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  }

  const togglePassword = (str) => {
    if (str === "otp") {
      setShowOtp((prev) => !prev)
    } else {
      setShowConfirmPassword((prev) => !prev)
    }
  }

  const validatePassword = async (passwordObj) => {
    setDisableSubmit(true)
    let response = await postRequest("/learner/learner_manager/reset_password_through_otp/", passwordObj, false)
    if (response.success) {
      setIsGenerated(null)
      setTimeout(() => {
        navigate(`/app/login?redirection_url=${redirection_url ? redirection_url : `/`}`)
      }, 2100)
    } else {
      let error = response?.data?.error
      if (error !== "") {
        let errorCaps = error.toUpperCase()
        if (errorCaps.includes("GENERATE THE OTP")) {
          setShowResend(true)
          setErrorOTP(error)
        } else if (errorCaps.includes("OTP")) {
          setErrorOTP(error)
        } else if (errorCaps.includes("PASSWORD")) {
          setErrorPassword(error)
          setErrorConfirmPassword(error)
        }
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error } })
        setDisableSubmit(false)
      }
    }
  }

  const generateOTP = async (user) => {
    try {
      setLoader(true)
      let response = await postRequest("/learner/learner_manager/generate_otp/", user, false)
      if (response.success) {
        setOTP("")
        setPassword("")
        setConfirmPassword("")
        setIsGenerated(true)
        setShowResend(false)
        setTimerDate(new Date())
        if (response?.data?.phone_number) {
          setMobile(response.data.phone_number)
        } else {
          setMobile("")
        }
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: response?.data?.error } })
      }
    } catch (error) {
      console.error(error)
    }
    setLoader(false)
  }

  const validateEmailGenerateOTP = (event) => {
    event.preventDefault()
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/
    if (email && email.length > 0) {
      if (email.match(mailformat)) {
        generateOTP({ email: email.toLowerCase(), otp_type: 4, subdomain: isBrowser() ? window.location.hostname.split(".")[0] : `` })
        localStorage.setItem("username", email)
        setTimerDate(new Date())
      } else {
        setErrorEmail(true)
      }
    } else {
      setErrorEmail(true)
    }
  }

  const validatePasswordOTP = (event) => {
    event.preventDefault()
    if (otp !== "" && password !== "" && confirmPassword !== "") {
      validatePassword({ otp: otp, email: email, password: password, confirm_password: confirmPassword })
    } else if (otp == "") {
      setErrorOTP("Invalid OTP")
    } else if (password == "") {
      setErrorPassword("Password can not be empty")
    } else if (confirmPassword == "") {
      setErrorConfirmPassword("Confirm Password can not be empty")
    }
  }

  return (
    <div className="w-100 h-100">
      <SEO title="Forgot Password" />
      <Row className="justify-content-md-center mx-auto w-100 pb-4 mt-4 ">
        {width > 980 && (
          <Col lg="3" md="4" sm="4" className="float-left mr-0 bg-121B2B border border-1 border-121B2B pl-20px">
            <Row className="text-center border-bottom-E0E2E5 ">
              <div className="m-0 pt-0 pb-9px mt-0 d-inline-flex align-items-center">
                <Image alt="Turient logo" src={turientLogo} className="d-inline-block align-center w-50px h-50px" />{" "}
                <Link to="/" className="ml-2 turient-body-text-color fs-32px text-decoration-none font-weight-bold">
                  {"Turient"}
                </Link>
              </div>{" "}
            </Row>
            <p className="poppins_font text_font_semibold turient-body-text-color fs-36px pt-18px mb-10px bold-500 ">
              Welcome to
              <br /> your Online School
            </p>
            <p className="poppins_font fs-21px">You are just a step away!</p>
            <Row>
              <Image src={turientimg} alt="turientmg" />
            </Row>
          </Col>
        )}
        <Col lg="4" md="6" sm="6" className={`pt-2 pb-2 w-100 ${width > 980 ? "pl-20px" : ""} d-flex bg-212C42`}>
          <div className="w-100">
            {isGenerated === null ? (
              <div className="w-100 color-1699A2 mt-160px ">
                <Image alt="Success" src={GreenSuccess} className="d-block mx-auto mb-3 w-50px h-50px" />
                <div className="text-center fs-18px poppins_font">You have successfully reset your password</div>
              </div>
            ) : (
              <form method="post" className={`mt-4 w-100`}>
                {width < 980 && (
                  <div className="signup-display-title">
                    <div className="m-0 pt-0 pb-9px mt-0 d-inline-flex align-items-center">
                      <Image alt="Turient logo" src={turientLogo} className="d-inline-block align-center w-50px h-50px" />{" "}
                      <Link to="/" className="ml-2 turient-body-text-color fs-32px text-decoration-none font-weight-bold">
                        {"Turient"}
                      </Link>
                    </div>{" "}
                  </div>
                )}
                <h2 className="poppins_font w-100 pl-2 mt-4 mb-1 pb-2 fs-24px bold-600 color-c6cff4">Reset your password</h2>
                {isGenerated === false ? (
                  <React.Fragment>
                    <div className={`mb-2 br-8px pb-2 pl-2 pr-1`}>
                      <LoginInputField
                        labelClass={`poppins_font fs-18px color-c6cff4`}
                        labelText={"Email"}
                        onChange={handleChange}
                        name="email"
                        value={email}
                        placeholder={`Enter your email`}
                        inputClass={`autofill_303C54 fs-18px bg-303C54 ${errorEmail ? "border-AA5746" : ""}`}
                        type="email"
                        showHideInput={false}
                        showErrorMsg={errorEmail}
                        errorMsg={"Please enter a valid email id"}
                      />
                    </div>
                    <div className={`text-center mb-4`}>
                      <button onClick={(e) => validateEmailGenerateOTP(e)} className={`border_on_hover ${LoginCSS.login_button} text-E0E2E5`} disabled={loader}>
                        Get OTP
                      </button>
                    </div>
                    <div className={`mb-4 fs-12px pl-2 pr-1 text-E0E2E5 text-center`}>
                      OTP will be sent to your registered <span className="">Email</span> and <span className="">Mobile number</span>
                    </div>
                    <div className={`mb-4 d-flex justify-content-center mt-260px`}>
                      <p className={`poppins_font mt-1 mx-4 fs-18px border_on_hover ${LoginCSS.login_button}`}>
                        <Link className="text-E0E2E5_hover white-text" to="/app/forgot-password" state={{ email: email }}>
                          Login using OTP
                        </Link>
                      </p>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className={`mb-4 d-flex flex-wrap fs-12px bold-600 pl-2 pr-1`}>
                      <div className={`mr-1`}>
                        OTP sent to your email <span className={`underline`}>{email}</span>
                        <span
                          onClick={(e) => {
                            setIsGenerated(false)
                          }}
                          className={`px-1 bg-4F6080 border-707070 circle pointer d-inline-flex justify-content-center align-items-center ml-1 w-22px h-22px`}
                        >
                          <Image width={12} height={12} src={Edit} alt={"edit"} />
                        </span>
                      </div>

                      {mobile ? (
                        <div>
                          And mobile number <span className={`underline`}>{mobile}</span>
                        </div>
                      ) : null}
                    </div>

                    <div className={`poppins_font fs-18px mb-1 br-8px pb-0 pl-2 pr-1`}>
                      <LoginInputField
                        labelClass={` color-c6cff4`}
                        labelText={"Enter OTP"}
                        onChange={handleChange}
                        onKeyPress={handleNumberOnlyInput}
                        name="otp"
                        value={otp}
                        placeholder={`Enter your OTP`}
                        inputClass={`bg-303C54 ${errorOTP ? `border-AA5746` : ``}`}
                        type={showOtp ? "text" : "password"}
                        showHideInput={true}
                        showHideInputValue={showOtp}
                        setShowHideInputValue={() => togglePassword("otp")}
                        showErrorMsg={false}
                        maxLength={6}
                      />
                    </div>
                    <div className={`d-flex mb-3 fs-12px justify-content-between pl-2 pr-1`}>
                      {errorOTP ? <LoginFormError errorText={errorOTP} /> : <div className={`pt-1 color-AA5746`}>Your OTP will expire in 15 mins.</div>}
                      <div className={`pt-1`}>
                        {showResend ? (
                          <span onClick={(e) => validateEmailGenerateOTP(e)} className={`underline pointer`}>
                            Resend OTP
                          </span>
                        ) : (
                          <span>
                            <span>Resend OTP in</span>
                            <span className={`bg-primary br-4px text-white ml-1 py-4px px-5px`}>
                              <TimerComponent
                                totalTimerDuration={0.5}
                                startedTime={timerDate}
                                onTimerAction={(ended) => {
                                  if (ended) {
                                    setShowResend(true)
                                  }
                                }}
                                timerType="decrement"
                              />
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={`poppins_font fs-18px br-8px pb-0 pl-2 pr-1`}>
                      <div className={`bf-hide mb-2 d-flex justify-content-between color-c6cff4`}>
                        <div>New Password</div>
                      </div>
                      {/* To show password validation popup */}
                      <PasswordValidatorInputField
                        value={password}
                        strongPasswordValidation={strongPasswordValidation}
                        onChange={handleChange}
                        error={!!errorPassword}
                      />
                      {errorPassword ? (
                        <div className={`d-flex mb-3`}>
                          <LoginFormError errorText={errorPassword} />
                          <div className={`ml-auto`}>
                            <WhatMakesAStrongPassword />
                          </div>
                        </div>
                      ) : (
                        <div className={`text-right mb-3`}>
                          <WhatMakesAStrongPassword />
                        </div>
                      )}
                    </div>
                    <div className={`poppins_font fs-18px mb-3 br-8px pb-2 pl-2 pr-1`}>
                      <LoginInputField
                        labelClass={` color-c6cff4`}
                        labelText={"Confirm New Password"}
                        onChange={handleChange}
                        name="confirm_password"
                        value={confirmPassword}
                        placeholder={`Confirm New Password`}
                        inputClass={`bg-303C54 ${errorConfirmPassword ? `border-AA5746` : ``}`}
                        type={showConfirmPassword ? "text" : "password"}
                        showHideInput={true}
                        showHideInputValue={showConfirmPassword}
                        setShowHideInputValue={() => togglePassword()}
                        showErrorMsg={!!errorConfirmPassword}
                        errorMsg={errorConfirmPassword}
                        minLength={8}
                      />
                    </div>
                    <div className={`text-center mt-4 mb-4`}>
                      <button
                        disabled={disableSubmit}
                        onClick={(e) => validatePasswordOTP(e)}
                        className={`border_on_hover ${LoginCSS.login_button} color-c6cff4`}
                      >
                        Reset Password
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </form>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ResetPasswordWithOTP
