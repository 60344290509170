import React, { useEffect, useState, useCallback, useContext } from "react"
import _ from "lodash"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Collapse from "react-bootstrap/Collapse"
import Spinner from "react-bootstrap/Spinner"
import { Rnd } from "react-rnd"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUpload,
  faCheck,
  faChevronCircleUp,
  faChevronCircleDown,
  faChevronLeft,
  faExclamationTriangle,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

import SEO from "../../components/seo"
import CropImage from "../../components/crop-image/crop-image"
import CourseImg from "../../images/svgs/courseImg.svg"
import authClient from "../../services/auth"
import SunEditor from "suneditor-react"
import CohortSchedule from "./cohort_schedule"
import { isBrowser } from "../../services/developer_mode"

import CancelIcon from "../../images/svgs/cancel-white-icon.svg"

import StylesB from "./course.module.scss"
import Notification from "../custom/Notification"
import MuralAccountsList from "../mural/muralAccountsList"
import AppIntegrationModal from "../app-integration"
import { HasEditPermission, HasReadPermission } from "../../utils/PermissionDataAccess"
import { COURSES } from "../../constants/permissionsConstant"
import AutoSaveLoader from "../autosave-loader"
import Image from "../custom/Image"
import { GlobalContext } from "../../stores/global_store/GlobalStore"

const EditCourse = (location, data) => {
  // const courseData=['Live Course','eLearning Course','Classroom','Cohort','Continous','Spread','Fast Track','Self Driven'];
  // const courseDescription=[
  //   'Teach with the help of digital learning content that can be accessed anytime, anywhere.',
  //   'Create digital learning contents including videos, articles, and other resources and enable your students to access them anytime.',
  //   'Teach interactive classes and engage with your students in real-time.',
  //   'Create specialized classes for specific few students and teach them simultaneously.',
  //   'Create courses that allow you to teach new skills on an on-going basis',
  //   'Create courses that allow you to teach new skills on an on-going basis',
  //   'Create courses that allow you to teach new skills on an on-going basis',
  //   'Create courses that allow you to teach new skills on an on-going basis'
  // ];
  const courseData = ["Live Course", "eLearning", "classroom", "Cohort"]
  const courseDescription = [
    "Create class schedules and teach video-based live classes where your learners can interact & engage in real-time.",
    "Create digital learning content using videos & other resources that your learners can access anytime.",
    "Create specialized classes for specific few students and teach them simultaneously.",
  ]
  const [globalState, globalStateDispatch] = useContext(GlobalContext)

  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [course, setCourse] = useState({})
  const [modalShow, setModalShow] = useState(false)
  const [cropData, setCropData] = useState("#")
  const [fileName, setFileName] = useState(null)
  const [activestate, setActiveState] = useState("course_detail")
  const [courseType, setCourseType] = useState(undefined)
  const [courseDatam, setCourseDatam] = useState({})
  const [courseDatamUpdated, setCourseDatamUpdated] = useState(false)
  const [showCourseTypes, setShowCourseTypes] = useState(false)
  const [width, setWidth] = useState(1000)

  const [clickedInstructions, setClickedInstructions] = useState(false)
  const [clickedRulesOfEngagement, setClickedRulesOfEngagement] = useState(false)
  const [courseDays, setCourseDays] = useState(0)
  const [courseHours, setCourseHours] = useState(0)
  const [courseName, setCourseName] = useState("")
  const [loader, setLoader] = useState(false)
  const [hovered, setHovered] = useState(false)
  const [displayCourseLengthError, setDisplayCourseLenghtError] = useState(false)
  const [courseInstructions, setCourseInstructions] = useState("")
  const [clickedDescription, setClickedDescription] = useState(false)
  const [description, setDescription] = useState("")
  const [courseRulesOfEngagement, setCourseRulesOfEngagement] = useState("")
  const [activeInputField, setActiveInputField] = useState(null)
  const [showCohortSchedule, setShowCohortSchedule] = useState(true)
  const [callHandleSubmit, setCallHandleSubmit] = useState(false)
  const [initiateScheduleValidation, setInitiateScheduleValidation] = useState(false)
  const [validCohortSchedule, setValidCohortSchedule] = useState(false)
  const [draggableHash, setDraggableHash] = useState({ x: 0, y: 0, width: 724, height: 486 })
  const [showRichTextModal, setShowRichTextModal] = useState(false)
  const [notificationData, setNotificationData] = useState(null)

  const isPermitted = HasEditPermission(COURSES) && HasReadPermission(COURSES)
  const isMuralEnabled = globalState.appData.data?.company?.mural_enabled
  useEffect(() => {
    if (validCohortSchedule) {
      document.getElementById("form-proceed").click()
    }
  }, [validCohortSchedule])

  useEffect(() => {
    if (isBrowser() && document.getElementsByClassName("parent") && document.getElementsByClassName("parent").length > 0) {
      let x = parseInt(document.getElementsByClassName("parent")[0].offsetWidth - 724 - 80) / 2
      let y = parseInt(document.getElementsByClassName("parent")[0].offsetHeight - 486) / 2
      setDraggableHash({ x: x, y: y, width: 724, height: 486 })
    }
  }, [showRichTextModal])

  useEffect(() => {
    fetchCourse()
  }, [])

  useEffect(() => {
    if (callHandleSubmit) {
      handleSubmit("none")
    }
  }, [callHandleSubmit])

  useEffect(() => {
    if (clickedInstructions) {
      setLoader(true)
      delayedQuery("instructions", courseInstructions)
    }
  }, [courseInstructions])

  useEffect(() => {
    if (clickedDescription) {
      setLoader(true)
      delayedQuery("description", description)
    }
  }, [description])

  useEffect(() => {
    if (clickedRulesOfEngagement) {
      setLoader(true)
      delayedQuery("additional_criteria", courseRulesOfEngagement)
    }
  }, [courseRulesOfEngagement])

  useEffect(() => {
    if (isBrowser()) {
      setWidth(window.innerWidth)
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
      })
      return () => {
        window.removeEventListener("resize", () => {})
      }
    }
  }, [])

  useEffect(() => {
    if (showCohortSchedule) {
      focusOnData(null, "duration")
    }
  }, [showCohortSchedule])

  useEffect(() => {
    setHovered(false)
  }, [width])

  useEffect(() => {
    if (Object.keys(courseDatam).length > 0 && courseDatamUpdated) {
      for (const [key, value] of Object.entries(courseDatam)) {
        if (key === "days") {
          course["duration"] = parseInt(value) * 24 + parseInt(courseHours)
        } else if (key === "hours") {
          let new_hours = parseInt(value)
          let new_days = courseDays
          if (value > 24) {
            new_days = Math.floor(courseDays + value / 24)
            new_hours = value % 24
            setCourseDays(new_days)
            setCourseHours(new_hours)
          }
          course["duration"] = parseInt(new_days * 24) + parseInt(new_hours)
        } else {
          course[key] = value
        }
      }
      handleSubmit("none")
    }
  }, [Object.keys(courseDatam).length, courseDatamUpdated])

  function sendQuery(name, value) {
    let someProperty = { ...courseDatam }
    someProperty[name] = value
    setCourseDatam(someProperty)
    setCourseDatamUpdated(true)
  }

  const delayedQuery = useCallback(
    _.debounce((name, value) => sendQuery(name, value), 1000),
    []
  )

  function onBlurData(name, value) {
    if (name !== null) {
      course[name] = value
      handleSubmit("none")
    }
  }

  async function fetchCourse() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/courses/${location.id}/`, { headers: login_credentials }).then((res) => {
      setCourseType()
      let course_duration = res.data.duration
      if (course_duration > 0) {
        let days = Math.floor(course_duration / 24)
        let hours = course_duration % 24
        setCourseDays(days)
        setCourseHours(hours)
      }
      setCourse(res.data)
      let course_data = res.data
      setCourseInstructions(course_data["instructions"])
      setCourseRulesOfEngagement(course_data["additional_criteria"])
      setDescription(course_data["description"])

      let str = res.data.name
      if (str.length > 23) {
        let res1 = str.substring(0, 22)
        let res2 = res1 + "..."
        setCourseName(res2)
      } else {
        setCourseName(str)
      }
      if (res.data.avatar !== null) {
        var avatar = res.data.avatar.replace("https://turient-static-bucket-dev.s3.amazonaws.com/courses/", "")
        avatar.replace("https://turient-static-bucket.s3.amazonaws.com/courses/", "")
        var res1 = avatar.split("/")
        var res2 = res1[1]
        var res3 = res2.split("?")
        setFileName(res3[0])
      } else {
        setFileName(null)
      }
    })
  }

  const convertBase64ToFile = (file) => {
    let block = file.split(";")
    let contentType = block[0].split(":")[1]
    let realData = block[1].split(",")[1]
    let blob = b64toBlob(realData, contentType)
    return blob
  }
  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || ""
    sliceSize = sliceSize || 512
    let byteCharacters = atob(b64Data)
    let byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize)
      let byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      let byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    let blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  useEffect(() => {
    if (cropData !== course.avatar && cropData !== "#") {
      course["avatar"] = convertBase64ToFile(cropData)
      handleSubmit(null)
    }
  }, [cropData])

  function handleSubmit(event, type = null, value = null) {
    if (event !== null && event !== "none") {
      event.preventDefault()
    }
    if (!course.duration && course.course_type === "0") {
      setDisplayCourseLenghtError(true)
      setLoader(false)
      setCourseDatam({})
      setCourseDatamUpdated(false)
      setCallHandleSubmit(false)
    } else {
      var formData = new FormData()
      for (var k in course) {
        if (course[k] !== null) {
          if (k === "avatar" && event === null) {
            formData.append(
              "avatar",
              course["avatar"],
              fileName && fileName !== null ? fileName : `default_image${course.id}` + course["avatar"]["type"].replace("image/", ".")
            )
          } else if (k === "course_template") {
            if (course.course_template !== null) {
              formData.append("course_template", JSON.stringify(course.course_template))
            }
          } else if (k === "cohort_schedule") {
            formData.append(k, JSON.stringify(course[k]))
          } else if (k === "mural_account") {
            if (course[k]?.id || Object.keys(course[k])?.length > 0) {
              formData.append("mural_account", course[k]?.id ? parseInt(course[k]?.id) : course[k])
            } else {
              formData.append("mural_account", "")
            }
          } else if (k !== "avatar") {
            formData.append(k, course[k])
          }
        }
      }
      if (type !== null) {
        formData.append(type, value)
      }
      if (location.id !== undefined) {
        var course_id = location.id
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + "/courses/" + course_id + "/", formData, { headers: login_credentials })
          .then((res) => {
            setDisplayCourseLenghtError(false)
            setLoader(false)
            setCourseDatam({})
            setCourseDatamUpdated(false)
            setCallHandleSubmit(false)
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()

              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    }
  }

  function getCropDataProps(cropData, fileName) {
    setCropData(cropData)
    setFileName(fileName)
  }

  function updateActiveState(e) {
    setActiveState(e.target.id)
  }
  function getNewCourseData() {
    return (
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        style={{ border: "1px solid #303C54", backgroundColor: "#303C54", borderRadius: "9px", boxShadow: "0px 3px 6px #00000099", marginBottom: "12px" }}
        className="px-0"
      >
        <Col lg="12" md="12" sm="12" xs="12" className="d-flex" style={{ justifyContent: "space-between", padding: "12px" }}>
          <p className="mb-0" style={{ fontSize: "16px", color: "#838B9E", lineHeight: 1 }}>
            Course Type{" "}
            <span className={`${!isPermitted && "disable-content"}`} style={{ color: "#E0E2E5", fontWeight: "bold" }}>
              {course.course_type ? `: ${courseData[course.course_type]}` : ""}
            </span>
          </p>
        </Col>
        <Collapse in={showCourseTypes}>
          <div id="example-collapse-text">
            <Col lg="12" md="12" sm="12" xs="12" className="px-3 pt-2" style={{ borderTop: "1px solid #838B9E" }}>
              <p style={{ fontSize: "16px", color: "#838B9E", margin: "0px" }}>Please select course type</p>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12" className="d-flex" style={{ flexWrap: "wrap", padding: "8px 8px" }}>
              {showCourseCards()}
            </Col>
          </div>
        </Collapse>
      </Col>
    )
  }
  function showCourseCards() {
    return courseData.map((course_name, index) => {
      if (course !== "classroom") {
        return (
          <Col
            key={course_name + "_" + index.toString()}
            onClick={(e) => {
              handleSubmit(e, "course_type", index.toString())
              setShowCourseTypes(false)
            }}
            lg="3"
            md="3"
            sm="3"
            xs="3"
            className="pointer"
            style={{ padding: "8px" }}
          >
            <div className={`${course.course_type === index.toString() ? StylesB.white_border : ""} ${StylesB.course_type_card} border_on_hover`}>
              <div className={`${StylesB.card_header} p-2`} style={{ backgroundImage: `url(${CourseImg})` }}>
                <p>{courseData[index]}</p>
              </div>
              <div className={`${StylesB.card_body} p-2`}>
                <p>{courseDescription[index]}</p>
              </div>
            </div>
          </Col>
        )
      }
    })
  }
  function focusOnData(e, id) {
    let data = document.getElementById(id)
    if (data) {
      data.focus()
    }
  }
  function showCohortScheduleSection() {
    if (course.course_type === "3") {
      let schedule = course.cohort_schedule
      return (
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={{
            border: "1px solid #303C54",
            backgroundColor: "#303C54",
            borderRadius: "9px",
            boxShadow: "0px 3px 12px #00000099",
            marginBottom: "12px",
            marginTop: "12px",
          }}
          className="px-0"
        >
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className="d-flex pointer"
            onClick={() => setShowCohortSchedule(!showCohortSchedule)}
            style={{ justifyContent: "space-between", padding: "12px" }}
          >
            <p className="mb-0" style={{ fontSize: "16px", color: "#838B9E", lineHeight: 1 }}>
              Course Schedule
            </p>
            {showCohortSchedule ? (
              <FontAwesomeIcon
                className="pointer"
                aria-controls="example-collapse-text"
                onClick={() => setShowCohortSchedule(!showCohortSchedule)}
                color="#E0E2E5"
                style={{ height: "16px" }}
                icon={faChevronCircleUp}
              />
            ) : (
              <FontAwesomeIcon
                className="pointer"
                aria-controls="example-collapse-text"
                onClick={() => setShowCohortSchedule(!showCohortSchedule)}
                color="#E0E2E5"
                style={{ height: "16px" }}
                icon={faChevronCircleDown}
              />
            )}
          </Col>
          <Collapse in={showCohortSchedule}>
            <div id="example-collapse-text">
              <Col lg="12" md="12" sm="12" xs="12" className="px-3 py-4 mx-auto" style={{ borderTop: "1px solid #838B9E" }}>
                <CohortSchedule
                  setInitiateScheduleValidation={setInitiateScheduleValidation}
                  setValidCohortSchedule={setValidCohortSchedule}
                  initiateScheduleValidation={initiateScheduleValidation}
                  course={course}
                  setCompany={(e) => setCourse(e)}
                  setCallHandleSubmit={(e) => setCallHandleSubmit(e)}
                  page_type={"edit"}
                />
              </Col>
            </div>
          </Collapse>
        </Col>
      )
    } else return null
  }

  function getFormData() {
    return (
      <Col xs="12" sm="12" md="12" lg="12" className="px-0">
        <Col lg="12" md="12" sm="12" xs="12" className="p-0" style={{ backgroundColor: "#212C42" }}>
          <Col className="px-0 d-flex my-0" style={{ maxWidth: "100%" }}>
            <CropImage
              show={modalShow}
              onHide={() => setModalShow(false)}
              getCropDataProps={getCropDataProps}
              fileName={fileName}
              avatar={course.avatar && course.avatar !== null ? course.avatar : null}
            />
            <div
              className="px-0 border_on_hover d-inline-flex text-center align-items-center"
              style={{
                backgroundColor: "#303C54",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "9px",
                border: "1px solid transparent",
                width: "183px",
                height: "183px",
              }}
            >
              <div className="my-auto mx-auto">
                {cropData === "#" ? (
                  course.avatar ? (
                    <img
                      className="mb-0"
                      onClick={(e) => {
                        e.preventDefault()
                        setModalShow(true)
                      }}
                      style={{ width: "70px", height: "70px" }}
                      src={course.avatar}
                      alt="Course logo"
                    />
                  ) : (
                    <button
                      onClick={(e) => {
                        if (isPermitted) {
                          e.preventDefault()
                          setModalShow(!modalShow)
                        }
                      }}
                      style={{
                        height: "100px",
                        width: "100px",
                        border: "1px solid #E0E2E5",
                        borderRadius: "9px",
                        textAlign: "center",
                        backgroundColor: "#303C54",
                        marginTop: "15%",
                      }}
                      className={`float-center ${!isPermitted && "disable-content"}`}
                    >
                      <FontAwesomeIcon icon={faUpload} color="#E0E2E5" />
                    </button>
                  )
                ) : (
                  <img className="mb-0" style={{ width: "70px", height: "70px" }} src={cropData} alt="Course logo" />
                )}
                <p
                  className={`pointer pb-0 mb-1 pt-2 ${!isPermitted && "disable-content"}`}
                  onClick={(e) => {
                    if (isPermitted) {
                      e.preventDefault()
                      setModalShow(true)
                    }
                  }}
                  style={{ color: "#838B9E", fontSize: "16px" }}
                >
                  {course.avatar ? "Change" : "Upload"} logo
                </p>
              </div>
            </div>
            <div className="px-0 ml-2" style={{ width: "calc(100% - 185px)" }}>
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className="border_on_hover pl-0"
                style={{
                  backgroundColor: "#303C54",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "9px",
                  border: "1px solid #303C54",
                  marginBottom: "12px",
                }}
              >
                <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className={`border-0 d-flex justify-content-center ${!isPermitted && "disable-content"}`}
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                      id="basic-addon1"
                      onClick={(e) => focusOnData(e, "name")}
                    >
                      Course Name:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 d-flex justify-content-center shadow-none ${StylesB.course_create} ${!isPermitted && "disable-content"} text-white`}
                    style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px" }}
                    type="text"
                    name="name"
                    defaultValue={course.name}
                    onChange={(e) => {
                      e.preventDefault()
                      if (!/^\d+$/.test(e.target.value)) {
                        setLoader(true)
                        delayedQuery(e.target.name, e.target.value)
                      }
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      if (!/^\d+$/.test(e.target.value)) {
                        onBlurData(e.target.value === course.name ? null : e.target.name, e.target.value)
                      } else {
                        setNotificationData({ type: "error", title: "Invalid course name" })
                      }
                    }}
                    id="name"
                    placeholder="Please add a name for your course"
                    readOnly={!isPermitted}
                    required
                  />
                </InputGroup>
              </Col>
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className="border_on_hover pl-0"
                style={{
                  backgroundColor: "#303C54",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "9px",
                  border: "1px solid #303C54",
                  marginBottom: "12px",
                }}
              >
                <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className="border-0 d-flex justify-content-center"
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                      id="basic-addon1"
                      onClick={(e) => focusOnData(e, "display_name")}
                    >
                      Course display name:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 d-flex justify-content-center shadow-none ${StylesB.course_create} ${!isPermitted && "disable-content"} text-white`}
                    style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px" }}
                    type="text"
                    name="display_name"
                    defaultValue={course.display_name}
                    onChange={(e) => {
                      e.preventDefault()
                      if (!/^\d+$/.test(e.target.value)) {
                        setLoader(true)
                        delayedQuery(e.target.name, e.target.value)
                      }
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      if (!/^\d+$/.test(e.target.value)) {
                        onBlurData(e.target.value === course.display_name ? null : e.target.name, e.target.value)
                      } else {
                        setNotificationData({ type: "error", title: "Invalid course name" })
                      }
                    }}
                    readOnly={!isPermitted}
                    id="display_name"
                    placeholder="Please add a display name for your course"
                    required
                  />
                </InputGroup>
              </Col>
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className="mb-0 border_on_hover pl-0"
                style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54" }}
              >
                <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className="border-0 d-flex justify-content-center"
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                      id="basic-addon1"
                      onClick={(e) => focusOnData(e, "tag_line")}
                    >
                      Course Tagline:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 d-flex justify-content-center shadow-none ${StylesB.course_create} ${!isPermitted && "disable-content"} text-white`}
                    style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px" }}
                    type="text"
                    name="tag_line"
                    defaultValue={course.tag_line}
                    onChange={(e) => {
                      e.preventDefault()
                      setLoader(true)
                      delayedQuery(e.target.name, e.target.value)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === course.tag_line ? null : e.target.name, e.target.value)
                    }}
                    id="tag_line"
                    placeholder="Please Enter a small tagline for your course"
                    readOnly={!isPermitted}
                    required
                  />
                </InputGroup>
              </Col>
            </div>
          </Col>
          {isMuralEnabled && (
            <div style={{ marginTop: "12px" }}>
              <MuralAccountsList
                onSelectMuralAccount={(muralAccount) => {
                  setCourse({ ...course, mural_account: muralAccount })
                  setCallHandleSubmit(true)
                }}
                mural_account={course.mural_account_data?.id ? course.mural_account_data : course.mural_account}
                showMuralAccounts={true}
                isPermitted={!isPermitted}
              />
            </div>
          )}
          {course.course_type === "0" ? (
            <React.Fragment>
              <Col
                className="mb-0 border_on_hover pl-0"
                style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54", marginTop: "12px" }}
              >
                <InputGroup className="py-2 align-items-center" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className="border-0 d-flex justify-content-center"
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                      id="basic-addon1"
                      onClick={(e) => focusOnData(e, "duration")}
                    >
                      Course Length:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 d-flex justify-content-center shadow-none pr-1 ${StylesB.course_create} ${
                      !isPermitted && "disable-content"
                    }  text-white`}
                    style={{ maxWidth: "55px", backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px" }}
                    type="number"
                    name="days"
                    value={courseDays}
                    onChange={(e) => {
                      e.preventDefault()
                      if (e.target.value >= 0 && e.target.value < 3650) {
                        setCourseDays(e.target.value)
                        setLoader(true)
                        delayedQuery(e.target.name, e.target.value)
                      }
                    }}
                    // onBlur={(e) => {e.preventDefault();onBlurData(e.target.value === course.duration.toString() ? null : e.target.name,e.target.value) } }
                    id="days"
                    readOnly={!isPermitted}
                    required
                  />
                  <p className="mb-0" style={{ color: "#E0E2E5", opacity: 0.4 }}>
                    days
                  </p>
                  <FormControl
                    className={`border-0 d-flex justify-content-center shadow-none pr-1 ${StylesB.course_create} ${
                      !isPermitted && "disable-content"
                    } text-white`}
                    style={{ maxWidth: "40px", backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px" }}
                    type="number"
                    name="hours"
                    value={courseHours}
                    onChange={(e) => {
                      e.preventDefault()
                      if (e.target.value >= 0 && e.target.value < 24) {
                        setCourseHours(e.target.value)
                        setLoader(true)
                        delayedQuery(e.target.name, e.target.value)
                      }
                    }}
                    id="hours"
                    readOnly={!isPermitted}
                  />
                  <p className="mb-0" style={{ color: "#E0E2E5", opacity: 0.4 }}>
                    hrs
                  </p>
                </InputGroup>
              </Col>
              {displayCourseLengthError ? (
                <Col id="error" xs="12" sm="12" md="12" lg="12" className={`mt-2 px-0`}>
                  <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
                    <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                    <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                      Please provide course length
                    </p>
                  </div>
                </Col>
              ) : null}
            </React.Fragment>
          ) : (
            ""
          )}
          <Col
            className="px-0 border_on_hover pl-0"
            style={{
              backgroundColor: "#303C54",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "9px",
              border: "1px solid #303C54",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            <InputGroup className="py-0 d-block " style={{ borderRadius: "9px", border: "1px solid transparent" }}>
              <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                <InputGroup.Text
                  className="border-0 d-flex justify-content-center pb-0"
                  style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                  id="basic-addon1"
                  onClick={(e) => {
                    focusOnData(e, "description")
                  }}
                >
                  Course Description
                </InputGroup.Text>
              </InputGroup.Prepend>
              <div className={`pl-0 mx-2 mb-2`} style={{}}>
                <div
                  onClick={() => {
                    if (isPermitted) {
                      setShowRichTextModal(true)
                      setActiveInputField("Description")
                    }
                  }}
                  dangerouslySetInnerHTML={{ __html: description }}
                  style={{ maxHeight: "100px", minHeight: "50px", overflowY: "scroll" }}
                  className={`${!isPermitted && "disable-content"}`}
                />
              </div>
            </InputGroup>
          </Col>

          <Col
            className="px-0 border_on_hover pl-0"
            style={{
              backgroundColor: "#303C54",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "9px",
              border: "1px solid #303C54",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            <InputGroup className="py-0 d-block" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
              <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                <InputGroup.Text
                  className={`border-0 d-flex justify-content-center ${!isPermitted && "disable-content"}`}
                  style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                  id="basic-addon1"
                  onClick={(e) => {
                    if (isPermitted) {
                      focusOnData(e, "additional_criteria")
                      setShowRichTextModal(true)
                      setActiveInputField("Rules Of Engagement")
                    }
                  }}
                >
                  Rules Of Engagement
                </InputGroup.Text>
              </InputGroup.Prepend>
              <div className={`pl-0 mx-2 mb-2`} style={{}}>
                <div
                  className={``}
                  onClick={() => {
                    if (isPermitted) {
                      setShowRichTextModal(true)
                      setActiveInputField("Rules Of Engagement")
                    }
                  }}
                  dangerouslySetInnerHTML={{ __html: courseRulesOfEngagement }}
                  style={{ maxHeight: "100px", minHeight: "50px", overflowY: "scroll" }}
                />
              </div>
            </InputGroup>
          </Col>

          <div className={`${clickedInstructions ? `` : `d-inline-flex`}`}>
            {clickedInstructions ? (
              <Col
                className="px-0 border_on_hover pl-0 mb-3"
                style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54", marginTop: "12px" }}
              >
                <InputGroup className="py-0 d-block" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className={`border-0 d-flex justify-content-center pb-0 ${!isPermitted && "disable-content"}`}
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                      id="basic-addon1"
                      onClick={(e) => {
                        if (isPermitted) {
                          focusOnData(e, "instructions")
                          setShowRichTextModal(true)
                          setActiveInputField("Instructions")
                        }
                      }}
                    >
                      Instructions
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <div className={`pl-0 mx-2 mb-2`} style={{}}>
                    <div
                      className={``}
                      onClick={() => {
                        if (isPermitted) {
                          setShowRichTextModal(true)
                          setActiveInputField("Instructions")
                        }
                      }}
                      dangerouslySetInnerHTML={{ __html: courseInstructions }}
                      style={{ maxHeight: "100px", minHeight: "50px", overflowY: "scroll" }}
                    />
                  </div>
                </InputGroup>
              </Col>
            ) : (
              <div className="px-0 ml-1 mr-3" style={{ marginBottom: "12px" }}>
                <p style={{ marginBottom: "0px", lineHeight: 0.7 }}>
                  <u
                    className={`pointer ${!isPermitted && "disable-content"}`}
                    style={{ fontSize: "14px", color: "#E0E2E5" }}
                    onClick={(e) => {
                      if (isPermitted) {
                        setClickedInstructions(true)
                        focusOnData(e, "instructions")
                        setShowRichTextModal(true)
                        setActiveInputField("Instructions")
                      }
                    }}
                  >
                    Add Instructions
                  </u>
                </p>
              </div>
            )}
          </div>
          {showCohortScheduleSection()}
        </Col>
      </Col>
    )
  }

  function getRichTextData() {
    if (activeInputField === "Rules Of Engagement") {
      return (
        <SunEditor
          setContents={courseRulesOfEngagement}
          onChange={(content) => {
            setClickedRulesOfEngagement(true)
            setCourseRulesOfEngagement(content)
          }}
          id="additional_criteria"
          placeholder="Add Rules Of Engagement"
          setOptions={{
            height: "auto",
            maxHeight: "400px",
            minHeight: "400px",
            buttonList: [
              ["undo", "redo", "font", "fontSize", "formatBlock"],
              ["bold", "underline", "italic", "strike", "subscript", "superscript", "removeFormat"],
              "/", // Line break
              ["fontColor", "hiliteColor", "outdent", "indent", "align", "horizontalRule", "list", "table"],
              ["link", "image", "video", "showBlocks", "codeView", "preview", "print", "save"],
            ],
          }}
        />
      )
    } else if (activeInputField === "Description") {
      return (
        <SunEditor
          setContents={description}
          onChange={(content) => {
            setClickedDescription(true)
            setDescription(content)
          }}
          id="description"
          placeholder="Add Description"
          setOptions={{
            height: "auto",
            maxHeight: "400px",
            minHeight: "400px",
            buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link"]],
          }}
        />
      )
    } else {
      return (
        <SunEditor
          setContents={courseInstructions}
          onChange={(content) => {
            setClickedInstructions(true)
            setCourseInstructions(content)
          }}
          onClick={() => {
            setShowRichTextModal(true)
          }}
          id="instructions"
          placeholder="Add Instructions"
          setOptions={{
            height: "auto",
            maxHeight: "400px",
            minHeight: "400px",
            buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link"]],
          }}
        />
      )
    }
  }

  function displayData() {
    return (
      <div
        id="mod"
        className={`box text-dark p-0 w-100 window_screen d-flex flex-column`}
        onScroll={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        style={{
          margin: 0,
          height: "100%",
          backgroundColor: "#212C42",
          border: "1px solid #E0E2E5",
          borderRadius: "11px",
          boxShadow: "0px 3px 12px #00000099",
          overflowY: "hidden",
        }}
      >
        <div className={`w-100 py-2  drag d-inline-flex align-items-center`} style={{ borderBottom: "1px solid #E0E2E5", cursor: "move" }}>
          <p className={`mb-0 text-center pl-2 w-100`} style={{ fontSize: "18px", fontWeight: "500", color: "#E0E2E5" }}>
            Add {activeInputField} Below
          </p>
          <img
            src={CancelIcon}
            className="mb-0 pointer ml-auto mr-2"
            style={{ fontSize: "16px", width: "16px" }}
            onClick={() => {
              setShowRichTextModal(false)
            }}
          />
        </div>
        <div className="bg-303C54 d-flex flex-column overflow-hidden">
          {getRichTextData()}
          <AutoSaveLoader
            isLoading={loader}
            onClick={() => {
              setShowRichTextModal(false)
            }}
          />
        </div>
      </div>
    )
  }

  function getCourseDetailsData() {
    if (course) {
      return (
        <>
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className="px-0 pb-0"
            style={{ padding: "12px", backgroundColor: "#212C42", borderRadius: "9px", boxShadow: "0px 3px 12px #00000099" }}
          >
            <Form className="form-horizontal mb-0" onSubmit={handleSubmit}>
              <div className="px-3" style={{ maxHeight: "calc(100vh - 190px)", overflowY: "scroll" }}>
                {getNewCourseData()}
                {getFormData()}
              </div>

              <Col className="px-0 pl-3 py-2 d-flex align-items-center" style={{ borderTop: "1px solid #63666f", boxShadow: "0px -3px 12px #00000099" }}>
                {course.course_template && course.course_template.id ? (
                  <Link to={displayCourseLengthError === true ? null : `/app/companies/${course.company}/courses/course-certification/${course.id}`}>
                    <button
                      onClick={() => {
                        let ele = document.getElementById("error")
                        if (ele !== null) {
                          ele.style.display = "none"
                          setTimeout(() => {
                            document.getElementById("error").style.display = "block"
                          }, 100)
                        }
                      }}
                      className="border_on_hover py-1 fs-14px"
                      style={{
                        backgroundColor: "#586886",
                        border: "1px solid #586886",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "23px",
                        color: "#E0E2E5",
                      }}
                    >
                      Next
                    </button>
                  </Link>
                ) : (
                  ""
                )}
                <p className="mb-0 mt-1 float-left mr-3" style={{ fontSize: "14px" }}>
                  {loader === true ? (
                    <svg
                      className="animate-spin my-auto ml-3 mr-1"
                      style={{ height: `14px`, width: `14px`, position: "relative", top: "-2px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <FontAwesomeIcon icon={faCheck} className="ml-3 mr-1" />
                  )}
                  All changes are saved
                </p>
              </Col>
            </Form>
          </Col>
          <Col xs="12" className="mt-3 d-none">
            <Button className="float-right" id={`form-proceed`} style={{ background: "#576186", border: 0 }} type="submit" color="warning">
              Proceed
            </Button>{" "}
          </Col>
        </>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function findScrollableOrNot() {
    let ele = document.getElementById("sections")

    if (ele !== null && ele !== undefined) {
      if (ele.scrollWidth > ele.clientWidth) {
        return true
      } else {
        return false
      }
    }
  }

  function scrollRight() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft += 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function scrollLeft() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft -= 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function calculateWidth() {
    let ele = document.getElementById("coursename")
    if (ele !== null) {
      let data = 1100 - parseInt(ele.offsetWidth) - 50
      return { width: data, overflowX: "scroll", flex: "auto" }
    }
  }
  function validateFormData() {
    if (course.course_type === "3") {
      if (validCohortSchedule) {
        document.getElementById("form-proceed").click()
      } else {
        setInitiateScheduleValidation(true)
      }
    } else {
      document.getElementById("form-proceed").click()
    }
  }

  return (
    <div>
      <SEO title="Company Creation" />
      <Col xs="12" sm="12" md="12" lg="12" className="pt-0 d-flex" style={{ alignItems: "center", flexDirection: "column" }}>
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          onMouseOver={(e) => {
            e.stopPropagation()
            e.preventDefault()
            let ele = document.getElementById("sections")
            if (ele !== null && ele !== undefined) {
              if (ele.scrollWidth > ele.clientWidth) {
                setHovered(true)
              }
            }
          }}
          className={`${StylesB.course_creation_header} d-flex px-0`}
        >
          <p
            className="mb-0 py-2 px-3 float-left font-weight-bold"
            id="coursename"
            style={{ borderRadius: "9px 0px 0px 9px", lineHeight: "1", minWidth: "max-content" }}
            title={course.name}
          >
            {courseName}
          </p>
          {findScrollableOrNot() && hovered ? (
            <FontAwesomeIcon
              onClick={() => {
                scrollLeft()
              }}
              className="pointer"
              icon={faChevronLeft}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551` }}
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => {
                scrollLeft()
              }}
              className="pointer"
              icon={faChevronLeft}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551`, visibility: "hidden" }}
            />
          )}
          <div id="sections" className="d-flex align-items-center hide_scrollbar" style={calculateWidth()}>
            <p
              onClick={updateActiveState}
              className={`${activestate === "course_detail" ? StylesB.course_nav_item_active : ""} mb-0 pointer py-2 px-2 fs-16px font-weight-bold`}
              style={{ color: "#E0E2E5", minWidth: "max-content" }}
              id="course_detail"
            >
              Course Details
            </p>
            <Link
              className={`pointer ${StylesB.backgroundonhover}`}
              style={{ color: "#E0E2E5" }}
              to={displayCourseLengthError === true ? null : `/app/companies/${course.company}/courses/course-certification/${course.id}`}
            >
              <p className="mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2 fs-16px" id="course_certification" style={{ minWidth: "max-content" }}>
                Certifications
              </p>
            </Link>
            <Link
              className={`pointer ${StylesB.backgroundonhover}`}
              style={{ color: "#E0E2E5" }}
              to={displayCourseLengthError === true ? null : `/app/companies/${course.company}/courses/course-content/${course.id}`}
            >
              <p className="mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2 fs-16px" id="course_detail" style={{ minWidth: "max-content" }}>
                Course Content
              </p>
            </Link>
            {!isPermitted && (
              <Link
                className={`pointer ${StylesB.backgroundonhover}`}
                style={{ color: "#E0E2E5" }}
                to={displayCourseLengthError === true ? null : `/app/companies/${course.company}/courses/course-publish/${course.id}`}
              >
                <p className="mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2 fs-16px" id="course_detail" style={{ minWidth: "max-content" }}>
                  Publish Course
                </p>
              </Link>
            )}
          </div>
          {findScrollableOrNot() && hovered ? (
            <FontAwesomeIcon
              onClick={() => {
                scrollRight()
              }}
              className="pointer"
              icon={faChevronRight}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551` }}
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => {
                scrollRight()
              }}
              className="pointer"
              icon={faChevronRight}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551`, visibility: "hidden" }}
            />
          )}
          <Image
            src={CancelIcon}
            alt={`close`}
            width={20}
            height={20}
            onClick={() => {
              navigate("/app/dashboard")
            }}
            className={`pointer mr-3 ml-auto float-right align-self-center`}
          />
        </Col>
        <Col
          onMouseOver={() => {
            setHovered(false)
          }}
          xs="12"
          sm="12"
          md="10"
          lg="10"
          className="px-0"
        >
          {getCourseDetailsData()}
        </Col>
        {!showRichTextModal ? null : (
          <div
            className={`w-100 parent h-100 position-fixed`}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowRichTextModal(false)
            }}
            style={{ inset: "0px", top: "0", bottom: "0", left: "0", right: "0", backgroundColor: "#000000b5" }}
          >
            {isBrowser() && document.getElementsByClassName("parent").length > 0 ? (
              <Rnd
                minWidth={324}
                minHeight={191}
                style={{ zIndex: 100 }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                bounds=".parent"
                disableDragging={false}
                dragHandleClassName={"drag"}
                default={{ x: `50%`, y: `50%`, width: 324, height: 191 }}
                position={{ x: draggableHash.x, y: draggableHash.y }}
                size={{ width: draggableHash.width, height: draggableHash.height }}
                onDragStop={(e, d) => {
                  setDraggableHash({ x: d.x, y: d.y, width: draggableHash.width, height: draggableHash.height })
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                  setDraggableHash({ width: ref.style.width, height: ref.style.height, x: position.x, y: position.y })
                }}
              >
                {displayData()}
              </Rnd>
            ) : (
              ``
            )}
          </div>
        )}
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
      <AppIntegrationModal />
    </div>
  )
}

export default EditCourse
