import React, { useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

const CreateCompany = ({ location }) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [company, setCompany] = useState({})

  console.log(location, "location.dilsjdio")
  // const { state = {} } = location

  function handleChange(event) {
    event.preventDefault()
    company[event.target.name] = event.target.value
  }
  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in company) {
      formData.append(k, company[k])
    }
    if (company.id != undefined) {
      console.log("edit")
      // var company_id = company.id
      // axios.put(process.env.GATSBY_REACT_APP_API_HOST+'/companies/' + company.id + '/' , formData,  { headers: login_credentials })
      // .then(res => {
      //   navigate('/app/dashboard/')
      // }).catch(error => {
      //   let error_messages = ''
      //   for (var key in error.response.data){
      //     error_messages += error.response.data[key] + "<br>"
      //   }
      //   if(error_messages === 'Invalid token.<br>'){authClient.signOut();

      //     localStorage.removeItem('authenticity_token');
      //     localStorage.removeItem('username');
      //     window.location.reload(true);
      //   }
      //   document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
      //     '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
      //       '<span aria-hidden="true">×</span></button>'+
      //       error_messages +
      //   '</div>')
      // });
    } else {
      var company_id = company.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/companies/create/", formData, { headers: login_credentials })
        .then((res) => {
          navigate("/app/dashboard/")
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
      // axios.post(process.env.GATSBY_REACT_APP_API_HOST+'/courses/', formData,  { headers: DefaultHeader })
      // .then(response => {
      //     // response.json().then(data =>{
      //       this.state.courses.push(response.data);
      //       this.setState({
      //         modal: !this.state.modal
      //       });
      //       // document.querySelector(".modal-header .close").click()
      //     // })
      // }).catch(error => {
      //   let error_messages = ''
      //   for (var key in error.response.data){
      //     error_messages += error.response.data[key] + "<br>"
      //   }
      //   if(error_messages === 'Invalid token.<br>'){authClient.signOut();

      //     localStorage.removeItem('authenticity_token');
      //     localStorage.removeItem('username');
      //     window.location.reload(true);
      //   }
      //   document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
      //     '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
      //       '<span aria-hidden="true">×</span></button>'+
      //       error_messages +
      //   '</div>')
      // });
    }
  }
  function toggle(e, type) {
    e.preventDefault()
  }

  return (
    <div>
      <SEO title="Company Creation" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>Add Company</h4>
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <input type="hidden" name="id" defaultValue={company.id} id="id" />
            <Col>
              <div>
                <Row>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" defaultValue={company.name} onChange={handleChange} id="name" placeholder="Enter company name" required />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="subdomain">Subdomain</label>
                      <input
                        type="text"
                        name="subdomain"
                        defaultValue={company.subdomain}
                        onChange={handleChange}
                        id="subdomain"
                        placeholder="Enter company subdomain"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="sequence">sequence</label>
                      <input
                        type="number"
                        name="sequence"
                        defaultValue={company.sequence}
                        onChange={handleChange}
                        id="sequence"
                        placeholder="Enter company sequence"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Col>
            <Button type="submit" color="warning">
              Save
            </Button>{" "}
          </Form>
          <Button onClick={() => navigate("/app/dashboard/")} variant="danger">
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CreateCompany
