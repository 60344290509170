import React, { useContext } from "react"

import { InsightsReportsContext } from "../../../stores/insights_reports/InsightsReportsStore"
import { SET_INCLASS_OR_OVERALL } from "../../../stores/insights_reports/InsightsReportsActions"
import Style from "../style.module.scss"

// !definition of component
/**
 *
 * @description --> It is a switch button for overall time and only class timings
 * @returns InClassOrOverallChoice component
 */
// ! component

const InClassOrOverallChoice = () => {
  const [state, dispatch] = useContext(InsightsReportsContext)

  return (
    <div
      className={`d-flex align-items-center text-white bold-400 pointer px-1 py-1 mr-3 br-36px ${
        state.inClassOrOverall === "In-Class" ? "bg-1699A2 flex-row-reverse" : "bg-5773AA"
      } `}
      onClick={() => {
        if (state.inClassOrOverall === "In-Class") {
          dispatch({ type: SET_INCLASS_OR_OVERALL, payload: "Overall" })
        } else {
          dispatch({ type: SET_INCLASS_OR_OVERALL, payload: "In-Class" })
        }
      }}
    >
      <div className={`circle bg-ECF0F1 ${Style.white_circle}`}></div>
      <p className="fs-14px mb-0 px-1 nowrap">{state.inClassOrOverall}</p>
    </div>
  )
}

export default InClassOrOverallChoice
