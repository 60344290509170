import React, { useEffect, useState, useRef } from "react"
import styles from "./style.module.scss"
import Checkbox from "../Checkbox"
import { getRequest } from "../../../services/rest_service"
import { trimText } from "../../utilities/utils"
import Loader from "../Loader"
import AvatarCircle from "../AvatarCircle"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const RoleDropdown = (props) => {
  const { roleData, training, setTraining } = props
  const [isOpen, setIsOpen] = useState(false)
  const [userDetail, setUserDetail] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])
  const dropdownRef = useRef(null)
  const [loader, setLoader] = useState(false)
  const [nameLength, setNameLength] = useState(12)
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (selectedOptions.length && selectedOptions.length < 5) {
      setNameLength(23)
    } else if (selectedOptions.length && selectedOptions.length > 4) {
      setNameLength(30)
    } else {
      setNameLength(11)
    }
  }, [selectedOptions])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  const getRoleManagementData = async (role_id) => {
    setLoader(true)
    let res = await getRequest(`/role-management/user-role-management/?role=${role_id}`)
    if (res.success) {
      setUserDetail(res.data.results)
      setLoader(false)
    }
  }

  useEffect(() => {
    if (training && training.training_staff) {
      const existingStaff = training.training_staff.find((staff) => staff.role.id === roleData.id)
      if (existingStaff) {
        const transformedData = existingStaff.user_role_mgmt.map((item) => ({
          id: item.id,
          user_detail: { ...item.user_detail },
        }))
        setSelectedOptions(transformedData)
      } else {
        setSelectedOptions([]) // Reset selectedOptions if the user is not in training_staff data
      }
    }
  }, [training, roleData])

  const handleDropdownToggle = () => {
    getRoleManagementData(roleData.id)
    if (props.selectStaffCollapsed) {
      props.setSelectStaffCollapsed(!props.selectStaffCollapsed)
    }
    setIsOpen(!isOpen)
  }

  const handleOptionSelect = (option) => {
    const isSelected = selectedOptions.some((item) => item.id === option.id)
    let updatedOptions
    if (isSelected) {
      updatedOptions = selectedOptions.filter((item) => item.id !== option.id)
    } else {
      updatedOptions = [
        ...selectedOptions,
        {
          id: option.id,
          user_detail: { ...option.user_detail },
        },
      ]
    }

    setSelectedOptions(updatedOptions)
    updateTrainingStaff(updatedOptions)
    if (props?.setTrainingDataUpdated) {
      props.setTrainingDataUpdated(true)
    }
  }

  const updateTrainingStaff = (updatedOptions) => {
    const updatedTrainingStaff = [...training.training_staff]

    // Find the existing staff with the same role
    const existingStaffIndex = updatedTrainingStaff.findIndex((staff) => staff.role.id === roleData.id)

    if (updatedOptions.length === 0) {
      if (existingStaffIndex !== -1) {
        updatedTrainingStaff.splice(existingStaffIndex, 1)
      }
    } else {
      if (existingStaffIndex !== -1) {
        updatedTrainingStaff[existingStaffIndex].user_role_mgmt = updatedOptions.map((item) => ({
          id: item.id,
          user_detail: { ...item.user_detail },
        }))
      } else {
        const newStaffEntry = {
          role: { id: roleData.id },
          user_role_mgmt: updatedOptions.map((item) => ({
            id: item.id,
            user_detail: { ...item.user_detail },
          })),
        }

        updatedTrainingStaff.push(newStaffEntry)
      }
    }

    setTraining({
      ...training,
      training_staff: updatedTrainingStaff,
    })
  }

  return (
    <div ref={dropdownRef} className={`position-relative dropdown-container bg-transparent br-9px mr-3 mb-2 `}>
      <div
        className={`dropdown-header bg-586886 br-9px d-flex min-w-8rem flex-row align-items-center px-2 h-2rem ${isOpen ? "border-white" : "border_on_hover"}`}
        onClick={handleDropdownToggle}
      >
        <div
          className={`mr-1 position-relative d-inline-block w-1rem h-1rem rounded-circle border border-1 border-E0E2E5 pointer  ${
            selectedOptions.length > 0 ? "bg-E0E2E5" : "bg-586886"
          }`}
        >
          <input type="radio" readOnly checked={selectedOptions.length > 0} className="position-absolute w-100 h-100 pointer opacity-0" />
          {selectedOptions.length > 0 && (
            <span className={` position-absolute top-50 left-50 transform-50 fs-14px color-212C42 ${selectedOptions.length > 0 ? "visible" : "invisible"}`}>
              ✓
            </span>
          )}
        </div>
        <label className="turient-header-text-color fs-14px mb-0  overflow-hidden">{roleData?.name}</label>
        {selectedOptions.length > 0 && (
          <div className={`position-relative ${selectedOptions.length > 4 ? " w-3rem" : `w-${selectedOptions.length * 1}rem `}  max-w-3rem rem mx-1`}>
            {selectedOptions.map(
              (option, index) =>
                index < 4 && (
                  <AvatarCircle
                    key={option.id}
                    size={"18px"}
                    name={option.user_detail.first_name}
                    avatar={option.user_detail.profile_pic}
                    backgroundColor={(index + 1) % 3 === 1 ? "#3BA8C1" : (index + 1) % 3 === 2 ? "#00988C" : "#D07766"}
                    className={`w-1rem br-9px border border-1 border-586886  ${index > 0 ? `position-absolute  top-0 ${`left-${index * 12}px`}` : ""}`}
                  />
                )
            )}
          </div>
        )}
        {selectedOptions.length > 4 && <p className="turient-header-text-color mb-0 fs-14px">{`+${selectedOptions.length - 4} more`}</p>}
      </div>
      {isOpen && !props.selectStaffCollapsed && (
        <div className="position-absolute top-100 z-index-5 w-100 h-auto max-h-15rem left-0 z-index-1 px-2 pt-3 bg-586886 mt-1 br-9px overflow-y-scroll ">
          {userDetail && !loader ? (
            userDetail.length ? (
              userDetail.map((option, index) => {
                const isChecked = selectedOptions.some((item) => item.id === option.id)
                return (
                  <div key={option.id} className="d-flex flex-row h-1_5rem mb-1">
                    <Checkbox
                      id={option.user_detail.id}
                      isChecked={isChecked}
                      onCheckboxChange={() => handleOptionSelect(option)}
                      checkmarkStyle={styles.checkmark}
                      tickMarkStyle={styles.checkboxTick}
                      custom_checkbox={styles.custom_checkbox}
                      custom_checkbox_customTickMark={styles.custom_checkbox_customTickMark}
                    />
                    <OverlayTrigger key={option.id} placement={`top`} overlay={<Tooltip id={`tooltip-${option.id}`}>{option.user_detail.first_name}</Tooltip>}>
                      <div className="d-flex flex-row">
                        <AvatarCircle
                          key={option.id}
                          size={"20px"}
                          name={option.user_detail.first_name}
                          avatar={option.user_detail.profile_pic}
                          className="mr-2 h-1rem br-9px"
                          backgroundColor={(index + 1) % 3 === 1 ? "#3BA8C1" : (index + 1) % 3 === 2 ? "#00988C" : "#D07766"}
                        />
                        <p className="fs-14px text-nowrap">{trimText(option.user_detail.first_name, nameLength)}</p>
                      </div>
                    </OverlayTrigger>
                  </div>
                )
              })
            ) : (
              <p className="fs-12px text-center px-2">No staff available</p>
            )
          ) : (
            <Loader />
          )}
        </div>
      )}
    </div>
  )
}

export default RoleDropdown
