import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"
import axios from "axios"
import Col from "react-bootstrap/Col"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
// import hideMessage from '../../services/hidemessage'
import Notification from "../custom/Notification"
import SunEditor from "suneditor-react"
import { isBrowser } from "../../services/developer_mode"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile, faChevronLeft, faLink } from "@fortawesome/free-solid-svg-icons"
import facheck from "../../images/svgs/facheck.svg"
import fatimes from "../../images/svgs/fatimes.svg"

const EditEskolaBlog = (location, data) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [eskola_blog, setEskolaBlog] = useState({})
  const [eskola_blog_description, setEskolaBlogDescription] = useState("")
  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    fetchEskolaBlog()
  }, [])

  async function fetchEskolaBlog() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/eskola-blogs/${location.id}/`, { headers: login_credentials })
      .then((res) => {
        setEskolaBlog(res.data)
        setEskolaBlogDescription(res.data.description)
      })
      .catch((error) => {
        let error_messages = "",
          error_key = ""
        for (let key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
          error_key = key
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function handleChange(event) {
    event.preventDefault()
    if (event.target) {
      let eskola_blog_data = { ...eskola_blog }
      if (event.target.name === "avatar") {
        eskola_blog_data["avatar"] = event.target.files[0]
      } else if (event.target.name === "slug") {
        let value = event.target.value.toLowerCase()
        value = value.split(" ").join("-")
        eskola_blog_data["slug"] = value
      } else {
        eskola_blog_data[event.target.name] = event.target.value
      }
      setEskolaBlog(eskola_blog_data)
    }
  }
  const updateIsDraftValue = (e, value) => {
    e.preventDefault()
    if (e.target) {
      let eskola_blog_data = { ...eskola_blog }
      eskola_blog_data["is_draft"] = value
      setEskolaBlog(eskola_blog_data)
    }
  }
  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in eskola_blog) {
      if (k === "description") {
        formData.append("description", eskola_blog_description)
      } else if (k !== "avatar") {
        formData.append(k, eskola_blog[k])
      }
    }
    if (document.querySelector(".form-control-file").value.length > 0) {
      formData.append("avatar", eskola_blog["avatar"])
    }
    if (location.id !== undefined) {
      axios
        .put(`${process.env.GATSBY_REACT_APP_API_HOST}/eskola-blogs/${location.id}/`, formData, { headers: login_credentials })
        .then((res) => {
          // hideMessage.displaySuccessMsg('Eskola blog successfully updated')
          setNotificationData({ type: "success", title: "Eskola blog successfully updated" })
          setTimeout(() => {
            navigate("/app/eskola-blogs")
          }, 2000)
        })
        .catch((error) => {
          let error_messages = "",
            error_key = ""
          for (let key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
            error_key = key
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          if (error_key === "slug") {
            if (error_messages === "This field is required") {
              // hideMessage.displayErrorMsg('Slug field is required')
              setNotificationData({ type: "error", title: "Slug field is required" })
            } else {
              // hideMessage.displayErrorMsg(error_messages)
              setNotificationData({ type: "error", title: error_messages })
            }
          } else {
            // hideMessage.displayErrorMsg(error_messages)
            setNotificationData({ type: "error", title: error_messages })
          }
        })
    }
  }

  return (
    <div>
      <SEO title="Eskola Blog Creation" />
      <Link className={`pointer text-white`} to={`/app/eskola-blogs`}>
        {" "}
        <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
      </Link>
      <Col
        lg="12"
        md="12"
        sm="12"
        className={`float-left add-links align-items-center justify-content-center text-white padding-mob`}
        style={{ padding: "1rem 7rem" }}
      >
        <Col sm="12" md="12" lg="12" className="float-left">
          <h4>Edit Eskola Blog</h4>
        </Col>
        <Form className="form-horizontal float-left" onSubmit={handleSubmit}>
          <Col sm="12" md="12" lg="12" className="float-left">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="title-for-this-eskola-blog">
                  <FontAwesomeIcon icon={faFile} style={{ color: "#a9adba", marginBottom: "10px", width: `20px`, height: `20px` }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Title for this eskola blog"
                aria-label="Eskola Blog Title"
                aria-describedby="title-for-this-eskola-blog"
                onChange={handleChange}
                defaultValue={eskola_blog.name}
                name="name"
                id="name"
                required
                style={{ color: "white" }}
              />
            </InputGroup>
          </Col>
          <Col sm="12" md="12" lg="12" className="float-left">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="slug-for-this-eskola-blog">
                  <FontAwesomeIcon icon={faLink} style={{ color: "#a9adba", marginBottom: "10px", width: `20px`, height: `20px` }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Slug for this eskola blog"
                aria-label="Eskola Blog Slug"
                aria-describedby="slug-for-this-eskola-blog"
                onChange={handleChange}
                defaultValue={eskola_blog.slug}
                name="slug"
                id="slug"
                style={{ color: "white" }}
              />
            </InputGroup>
          </Col>
          <Col sm="12" md="12" lg="12" className="float-left">
            <InputGroup className="mb-3">
              <InputGroup.Prepend style={{ background: "#171e34" }}>
                <InputGroup.Text id="seo-description-for-this-eskola-blog">
                  <FontAwesomeIcon icon={faFile} style={{ color: "#a9adba", marginBottom: "10px", width: `20px`, height: `20px` }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="textarea"
                rows={3}
                placeholder="Seo description for this eskola blog"
                aria-label="Eskola Blog Seo Description"
                aria-describedby="seo-description-for-this-eskola-blog"
                onChange={handleChange}
                defaultValue={eskola_blog.seo_description}
                name="seo_description"
                id="seo_description"
                style={{ height: "5rem", color: "white" }}
              />
            </InputGroup>
          </Col>
          <Col sm="12" md="12" lg="12" className="float-left">
            <InputGroup className="mb-3">
              <InputGroup.Prepend style={{ background: "#171e34" }}>
                <InputGroup.Text id="seo-schema-for-this-eskola-blog">
                  <FontAwesomeIcon icon={faFile} style={{ color: "#a9adba", marginBottom: "10px", width: `20px`, height: `20px` }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="textarea"
                rows={5}
                placeholder="Seo schema for this eskola blog"
                aria-label="Eskola Blog Seo Schema"
                aria-describedby="seo-schema-for-this-eskola-blog"
                onChange={handleChange}
                defaultValue={eskola_blog.seo_schema}
                name="seo_schema"
                id="seo_schema"
                style={{ height: "10rem", color: "white" }}
              />
            </InputGroup>
          </Col>
          <input type="hidden" name="sequence" defaultValue={1} id="sequence" placeholder="Enter Eskola Blog sequence" required />
          <Col lg="12" sm="12" md="12" className="float-left mb-3">
            <SunEditor
              setContents={eskola_blog.description}
              onChange={setEskolaBlogDescription}
              setOptions={{
                height: "auto",
                buttonList: [
                  ["undo", "redo", "font", "fontSize", "formatBlock"],
                  ["bold", "underline", "italic", "strike", "subscript", "superscript", "removeFormat"],
                  "/", // Line break
                  ["fontColor", "hiliteColor", "outdent", "indent", "align", "horizontalRule", "list", "table"],
                  ["link", "image", "video", "fullScreen", "showBlocks", "codeView", "preview", "print", "save"],
                ],
              }}
            />
          </Col>
          <Col sm="12" md="12" lg="12" className="float-left mb-3">
            <div className="float-left w-100 py-3" style={{ border: "1px dashed" }}>
              <Col sm="4" md="4" lg="4" className="float-left">
                <Form.Group>
                  <Form.File id="avatar" name="avatar" label="Avatar" onChange={handleChange} />
                  {(() => {
                    if (eskola_blog.avatar && eskola_blog.avatar.length > 0 && eskola_blog.avatar.indexOf("pdf") < 0 && eskola_blog.avatar.indexOf("PDF") < 0) {
                      return <LazyLoadImage src={eskola_blog.avatar} width="200" alt={eskola_blog.name} />
                    }
                  })()}
                </Form.Group>
              </Col>
              <Col sm="4" md="4" lg="4" className="float-left d-flex align-items-center">
                <p className="float-left mb-0 mr-2">Draft</p>
                <div
                  className={`d-inline-flex mr-2 pointer`}
                  style={{ backgroundColor: eskola_blog.is_draft ? `#578684` : `#9AA7DB`, verticalAlign: `middle`, height: `24px` }}
                  onClick={(e) => {
                    updateIsDraftValue(e, true)
                  }}
                >
                  <img
                    src={facheck}
                    alt={"check"}
                    className={`m-auto px-2`}
                    style={{ objectFit: `contain`, height: `15px`, opacity: eskola_blog.is_draft ? `1` : `0.14` }}
                  />
                </div>
                <div
                  className={`d-inline-flex ml-1 pointer`}
                  style={{ backgroundColor: eskola_blog.is_draft ? `#9AA7DB` : `#904744`, verticalAlign: `middle`, height: `24px` }}
                  onClick={(e) => {
                    updateIsDraftValue(e, false)
                  }}
                >
                  <img
                    src={fatimes}
                    alt={"close"}
                    className={`m-auto px-2`}
                    style={{ objectFit: `contain`, height: `20px`, opacity: eskola_blog.is_draft ? `0.14` : `1` }}
                  />
                </div>
              </Col>
            </div>
          </Col>
          <Col sm="12" md="12" lg="12" className="float-left">
            <Button
              type="button"
              className="bg-white"
              style={{ color: "#485b9e", border: "none", fontSize: "15px", float: "right" }}
              onClick={() => navigate("/app/eskola-blogs")}
            >
              Cancel
            </Button>
            <Button type="submit" className="mr-2" style={{ background: "#485b9e", border: "none", fontSize: "15px", float: "right" }}>
              Update
            </Button>
          </Col>
        </Form>
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default EditEskolaBlog
