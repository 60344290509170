import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"
import authClient from "../../../../../services/auth"
import { isBrowser } from "../../../../../services/developer_mode"

const ViewModuleResources = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [moduleResources, setModuleResources] = useState({})
  const [loader, setLoader] = useState(false)
  const [dmodal, setDmodal] = useState(false)
  const [currentModuleResource, setCurrentModuleResource] = useState("")

  const handleClose = () => {
    setDmodal(false)
    setCurrentModuleResource("")
  }

  // console.log(location,course, "location.dilsjdio")
  // const { state = {} } = location

  useEffect(() => {
    fetchModuleResources()
  }, [])

  async function fetchModuleResources() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/modules/${location.module_id}/`, { headers: login_credentials }).then((res) => {
      setModuleResources(res.data.resources)
      setLoader(true)
    })
  }

  function deleteModuleResource() {
    var current_resource_id = currentModuleResource.id
    var index = moduleResources.findIndex((res) => res.id == current_resource_id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + current_resource_id + "/", { headers: DefaultHeader, body: currentModuleResource })
      .then((res) => {
        moduleResources.splice(index, 1)
        setCurrentModuleResource("")
        setDmodal(!dmodal)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function displayModuleResourceHeader() {
    let data = moduleResources
    var isLoaded = loader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0])
        let heading = []
        heading.push(<td>&nbsp;</td>)
        for (let key in keys) {
          /*if (keys[key].toLowerCase() !== 'videos'){*/
          heading.push(<th style={{ textTransform: "uppercase" }}>{keys[key].replace(/_/g, " ")}</th>)
          /*}*/
        }
        return <tr>{heading}</tr>
      } else {
        return ""
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function handleSave(event, currentModuleResource) {
    event.preventDefault()
    if (currentModuleResource) {
      var formData = new FormData()
      formData.append(event.target.name, event.target.value)
      formData.append("resource_type", currentModuleResource.resource_type)
      formData.append("resource_id", currentModuleResource.resource_id)

      var current_resource_id = currentModuleResource.id
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + current_resource_id + "/", formData, { headers: DefaultHeader })
        .then((res) => {})
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
    }
  }

  function displayModuleResourceData() {
    let data = moduleResources
    console.log("called one")
    console.log(data, "data")
    var isLoaded = loader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0]),
          parsed_data = []
        data.map((datum, index) => {
          let values = []
          values.push(
            <td>
              <Button
                onClick={(e) => {
                  setDmodal(true)
                  setCurrentModuleResource(datum)
                }}
                variant="secondary"
              >
                {" "}
                Delete
              </Button>
            </td>
          )
          for (let key in keys) {
            if (keys[key].toLowerCase() === "resource_data") {
              values.push(<td> {datum[keys[key]].name} </td>)
            } else if (keys[key].toLowerCase() === "sequence") {
              values.push(
                <td>
                  {" "}
                  <input
                    type="number"
                    name="sequence"
                    defaultValue={datum[keys[key]]}
                    onChange={(e) => {
                      handleSave(e, datum)
                    }}
                    id="sequence"
                    placeholder="Enter resource sequence"
                    required
                  />
                </td>
              )
            } else {
              values.push(<td> {datum[keys[key]]} </td>)
            }
          }
          parsed_data.push(<tr key={index}>{values}</tr>)
        })
        return <tbody>{parsed_data}</tbody>
      } else {
        return (
          <tr id="loader">
            <td colSpan="12" className="animated fadeIn pt-3 text-center">
              No Data found...
            </td>
          </tr>
        )
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function showModuleResources() {
    return (
      <table>
        {displayModuleResourceHeader()}
        {displayModuleResourceData()}
      </table>
    )
  }

  return (
    <div>
      <SEO title="Course Template Module Resources View and Edit" />
      <Row>
        <Modal show={dmodal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete Resource{" "}
            {currentModuleResource ? currentModuleResource.resource_data?.display_name ?? currentModuleResource.resource_data?.name : ""}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              cancel
            </Button>
            <Button variant="primary" onClick={deleteModuleResource}>
              delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>View module resources</h4>
          <Col>
            <div>
              <Row>
                <Col xs="12" sm="6" md="12">
                  {showModuleResources()}
                </Col>
              </Row>
            </div>
          </Col>
          <Button onClick={() => navigate(`/app/default-course-templates/${location.template_id}/stages/${location.stage_id}/modules/view/`)} variant="danger">
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default ViewModuleResources
