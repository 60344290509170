import React, { useContext, useEffect } from "react"

import Loader from "../../custom/Loader"
import Image from "../../custom/Image"

import NoDataFound from "../../../images/svgs/reports-no-data.svg"

import ClassHourWisechart from "../ClassHourWiseChart"
import ReportGraph from "../ReportGraph"
import SingleColorBarGraph from "../../InsightsReports/singleColorBarGraph"
import MultiColorBarGraph from "../../InsightsReports/multiColorBarGraph"
import StudentWiseEngagement from "../StudentWiseEngagement"
import { InsightsReportsContext } from "../../../stores/insights_reports/InsightsReportsStore"
import { getRequest } from "../../../services/rest_service"
import { SET_CLASS_LEVEL_REPORTS_DATA } from "../../../stores/insights_reports/InsightsReportsActions"

// !definition of component
/**
 *
 * @description --> Class Level Report for insights page
 * @returns ClassLevelReport component
 */
// ! component

const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

const ClassLevelReport = () => {
  const [state, dispatch] = useContext(InsightsReportsContext)
  const currentClassData = state.classLevelReportsData[`course_${state.activeCourseData?.id}_class_${state.activeClassData?.id}`]
  const currentClassDataWithFilter =
    state.classLevelReportsData[`course_${state.activeCourseData?.id}_class_${state.activeClassData?.id}_${state.inClassOrOverall}`]

  useEffect(() => {
    if (!currentClassDataWithFilter?.stage_wise_engagement && state.activeClassData?.id) {
      fetchStageWiseEngagement(state.activeClassData?.id)
    }
    if (!currentClassDataWithFilter?.hour_wise_engagement && state.activeClassData?.id) {
      fetchHourWiseEngagement(state.activeClassData?.id)
    }
  }, [state.activeClassData?.id, state.inClassOrOverall])

  useEffect(() => {
    if (!currentClassData?.resource_wise_engagement && state.activeClassData?.id) {
      fetchResourceWiseEngagement(state.activeClassData?.id)
    }
    if (!currentClassData?.session_wise_engagement && state.activeClassData?.id) {
      fetchSessionWiseEngagement(state.activeClassData?.id)
    }
    if (!currentClassData?.topic_wise_engagement && state.activeClassData?.id) {
      fetchTopicWiseEngagement(state.activeClassData?.id)
    }
  }, [state.activeClassData?.id])

  const fetchStageWiseEngagement = async (training_id) => {
    try {
      const { data, success } = await getRequest(
        `/engagement-score-reports/stage_wise_engagement/training/${training_id}/?only_live_class=${state.inClassOrOverall === "In-Class"}`
      )
      if (success) {
        dispatch({
          type: SET_CLASS_LEVEL_REPORTS_DATA,
          payload: {
            key: `course_${state.activeCourseData.id}_class_${state.activeClassData.id}_${state.inClassOrOverall}`,
            report_type: `stage_wise_engagement`,
            value: data,
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchResourceWiseEngagement = async (training_id) => {
    try {
      const { data, success } = await getRequest(`/engagement-score-reports/resource_wise_engagement/training/${training_id}/`)
      if (success) {
        dispatch({
          type: SET_CLASS_LEVEL_REPORTS_DATA,
          payload: {
            key: `course_${state.activeCourseData.id}_class_${state.activeClassData.id}`,
            report_type: `resource_wise_engagement`,
            value: data,
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchSessionWiseEngagement = async (training_id) => {
    try {
      const { data, success } = await getRequest(`/engagement-score-reports/module_wise_engagement/training/${training_id}/`)
      if (success) {
        dispatch({
          type: SET_CLASS_LEVEL_REPORTS_DATA,
          payload: {
            key: `course_${state.activeCourseData.id}_class_${state.activeClassData.id}`,
            report_type: `session_wise_engagement`,
            value: data,
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchHourWiseEngagement = async (training_id) => {
    try {
      const { data, success } = await getRequest(
        `/engagement-score-reports/hour_wise_engagement/training/${training_id}/?timezone=${TIMEZONE}&only_live_class=${state.inClassOrOverall === "In-Class"}`
      )
      if (success) {
        dispatch({
          type: SET_CLASS_LEVEL_REPORTS_DATA,
          payload: {
            key: `course_${state.activeCourseData.id}_class_${state.activeClassData.id}_${state.inClassOrOverall}`,
            report_type: `hour_wise_engagement`,
            value: data,
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchTopicWiseEngagement = async (training_id) => {
    try {
      const { data, success } = await getRequest(`/engagement-score-reports/topic_wise_engagement/training/${training_id}/`)
      if (success) {
        dispatch({
          type: SET_CLASS_LEVEL_REPORTS_DATA,
          payload: {
            key: `course_${state.activeCourseData.id}_class_${state.activeClassData.id}`,
            report_type: `topic_wise_engagement`,
            value: data,
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {!state.fetchedClassesData ? (
        <div className="w-100 d-flex justify-center align-items-center">
          <Loader class={`text-white mx-auto`} size={30} />
        </div>
      ) : state.fetchedClassesData && state.allClassesData[`course_${state.activeCourseData?.id}`]?.count === 0 ? (
        <div className="w-100 h-100 d-flex text-center">
          <div className="w-100 my-auto d-flex flex-column">
            <Image src={NoDataFound} className="mx-auto" alt={`Right Arrow`} />
            <p className="pt-1 pl-2 text-E0E2E5 bold-500">No data found!</p>
          </div>
        </div>
      ) : (
        <>
          <ReportGraph title="Stage Wise Engagement">
            <SingleColorBarGraph title="Stage wise Engagement" graphData={currentClassDataWithFilter?.stage_wise_engagement} />
          </ReportGraph>
          <ReportGraph title="Resources with Highest Engagement">
            <SingleColorBarGraph title="Resources with Highest Engagement" graphData={currentClassData?.resource_wise_engagement} />
          </ReportGraph>
          <ReportGraph title="Session Wise Engagement">
            <MultiColorBarGraph title="Session Wise Engagement" graphData={currentClassData?.session_wise_engagement} />
          </ReportGraph>
          <ReportGraph title="Student Wise Engagement">
            <StudentWiseEngagement />
          </ReportGraph>
          <ReportGraph title="Hour Wise Trend Chart">
            <ClassHourWisechart graphData={currentClassDataWithFilter?.hour_wise_engagement} />
          </ReportGraph>
          <ReportGraph title="Topic Wise Engagement">
            <SingleColorBarGraph title="Topic Wise Engagement" graphData={currentClassData?.topic_wise_engagement} />
          </ReportGraph>
        </>
      )}
    </>
  )
}

export default ClassLevelReport
