import React, { useEffect, useContext, useState } from "react"
import Loader from "../../custom/Loader"

import FacilitatorEngagement from "../FacilitatorEngagement"
import ReportGraph from "../ReportGraph"
import MultiColorBarGraph from "../../InsightsReports/multiColorBarGraph"
import { InsightsReportsContext } from "../../../stores/insights_reports/InsightsReportsStore"
import { getRequest } from "../../../services/rest_service"
import { SET_SCHOOL_LEVEL_REPORTS_DATA } from "../../../stores/insights_reports/InsightsReportsActions"
import { getFromDateAndToDate } from "../utilities"

// !definition of component
/**
 *
 * @description --> School Level Report for insights page
 * @returns SchoolLevelReport component
 */
// ! component

const SchoolLevelReport = () => {
  const [state, dispatch] = useContext(InsightsReportsContext)
  const currentSchoolData = state.schoolLevelReportsData[`school_${state.activeSchoolData?.id}_period_${state.schoolLevelTimePeriod}_${state.inClassOrOverall}`]

  const [fetchedFacilitatorsData, setfetchedFacilitatorsData] = useState(true)

  useEffect(() => {
    if (!currentSchoolData?.batch_wise_engagement && state.activeSchoolData?.id && state.schoolLevelTimePeriod) {
      fetchBatchWiseEngagement(state.activeSchoolData?.id)
    }
    if (!currentSchoolData?.class_time_wise_engagement && state.activeSchoolData?.id && state.schoolLevelTimePeriod) {
      fetchClassTimeWiseEngagement(state.activeSchoolData?.id)
    }
    if (!currentSchoolData?.facilitator_wise_engagement && state.activeSchoolData?.id && state.schoolLevelTimePeriod) {
      fetchFacilitatorWiseEngagement(state.activeSchoolData?.id)
    }
  }, [state.schoolLevelTimePeriod, state.activeSchoolData?.id, state.inClassOrOverall])

  const fetchBatchWiseEngagement = async (school_id) => {
    try {
      const { data, success } = await getRequest(
        `/engagement-score-school-reports/batch_size_wise_engagement/school/${school_id}/${getFromDateAndToDate(state.schoolLevelTimePeriod)}only_live_class=${
          state.inClassOrOverall === "In-Class"
        }`
      )
      if (success) {
        dispatch({
          type: SET_SCHOOL_LEVEL_REPORTS_DATA,
          payload: {
            key: `school_${state.activeSchoolData.id}_period_${state.schoolLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `batch_wise_engagement`,
            value: data,
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }
  const fetchClassTimeWiseEngagement = async (school_id) => {
    try {
      const { data, success } = await getRequest(
        `/engagement-score-school-reports/class_time_wise_engagement/school/${school_id}/${getFromDateAndToDate(state.schoolLevelTimePeriod)}`
      )
      if (success) {
        dispatch({
          type: SET_SCHOOL_LEVEL_REPORTS_DATA,
          payload: {
            key: `school_${state.activeSchoolData.id}_period_${state.schoolLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `class_time_wise_engagement`,
            value: data,
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchFacilitatorWiseEngagement = async (school_id) => {
    try {
      setfetchedFacilitatorsData(false)
      const { data, success } = await getRequest(
        `/engagement-score-school-reports/facilitator_wise_engagement/school/${school_id}/${getFromDateAndToDate(
          state.schoolLevelTimePeriod
        )}limit=10&only_live_class=${state.inClassOrOverall === "In-Class"}`
      )
      if (success) {
        setfetchedFacilitatorsData(true)
        dispatch({
          type: SET_SCHOOL_LEVEL_REPORTS_DATA,
          payload: {
            key: `school_${state.activeSchoolData.id}_period_${state.schoolLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `facilitator_wise_engagement`,
            value: data,
          },
        })
      } else {
        setfetchedFacilitatorsData(true)
      }
    } catch (error) {
      setfetchedFacilitatorsData(true)
      console.error(error)
    }
  }

  return (
    <div className="w-100">
      <ReportGraph title="Facilitator wise Engagement" background={`bg-1B2841`}>
        {!fetchedFacilitatorsData ? (
          <div className="w-100 d-flex h-32vh justify-center align-items-center">
            <Loader class={`text-white mx-auto`} />
          </div>
        ) : (
          <FacilitatorEngagement data={currentSchoolData?.facilitator_wise_engagement} />
        )}
      </ReportGraph>
      <ReportGraph title="Batch Size">
        <MultiColorBarGraph title="Batch Size" graphData={currentSchoolData?.batch_wise_engagement} />
      </ReportGraph>
      <ReportGraph title="Class Timings">
        <MultiColorBarGraph title="Class Timings" graphData={currentSchoolData?.class_time_wise_engagement} />
      </ReportGraph>
    </div>
  )
}

export default SchoolLevelReport
