import React, { useState, useEffect } from "react"
import Style from "./style.module.scss"
import PropTypes from "prop-types"

function Checkbox(props) {
  const [checked, setChecked] = useState(true)
  const toggleCheckbox = () => {
    const newChecked = !checked
    props.onCheckboxChange(props.id, newChecked) // call the onCheckboxChange callback function with the new state
  }
  useEffect(() => {
    setChecked(props.isChecked)
  }, [props.isChecked])

  return (
    <label
      className={`${
        props?.flag
          ? props?.custom_checkbox_customTickMark
            ? props?.custom_checkbox_customTickMark
            : Style.custom_checkbox_customTickMark
          : props?.custom_checkbox
          ? props?.custom_checkbox
          : Style.custom_checkbox
      }`}
    >
      <input type="checkbox" id={props?.id} checked={checked} onChange={toggleCheckbox} disabled={props?.disabled} className={Style.checkboxInput} />
      <span className={props?.checkmarkStyle ? props.checkmarkStyle : Style.checkmarkStyle}>
        {checked && <i className={props.tickMarkStyle ? props.tickMarkStyle : Style.tickMarkStyle}>✓</i>}
      </span>
    </label>
  )
}

Checkbox.defaultProps = {
  custom_checkbox: Style.custom_checkbox,
  custom_checkbox_customTickMark: Style.custom_checkbox_customTickMark,
  checkmarkStyle: Style.checkmark,
  tickMarkStyle: Style.tick,
  customTickMark: false,
  isChecked: false,
  disabled: false,
}
Checkbox.propTypes = {
  custom_checkbox: PropTypes.shape({
    display: PropTypes.string,
    position: PropTypes.string,
    paddingLeft: PropTypes.string,
    cursor: PropTypes.string,
    fontSize: PropTypes.string,
    userSelect: PropTypes.string,
  }),
  custom_checkbox_customTickMark: PropTypes.shape({
    display: PropTypes.string,
    position: PropTypes.string,
    paddingLeft: PropTypes.string,
    cursor: PropTypes.string,
    fontSize: PropTypes.string,
    userSelect: PropTypes.string,
  }),
  checkmarkStyle: PropTypes.shape({
    position: PropTypes.string,
    top: PropTypes.string,
    left: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    backgroundColor: PropTypes.string,
    border: PropTypes.shape({
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      style: PropTypes.string,
      color: PropTypes.string,
    }),
  }).isRequired,
  tickMarkStyle: PropTypes.shape({
    fontSize: PropTypes.string,
    position: PropTypes.string,
    top: PropTypes.string,
    left: PropTypes.string,
  }).isRequired,
  customTickMark: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.any,
}
export default Checkbox
