import React, { useEffect, useState } from "react"
import Style from "./style.module.scss"
import DefaultSchoolLogo from "../../../images/default_school.png"
import DefaultSchoolLogoTransparent from "../../../images/defaultSchoolImageTransparent.png"
import ProfileUpdateProgress from "../../ProfileProgressBar"
import { navigate } from "gatsby"
import PlusLogo from "../../../images/svgs/plus.svg"
import PropTypes from "prop-types"
import { HasEditPermission } from "../../../utils/PermissionDataAccess"
import { SCHOOL } from "../../../constants/permissionsConstant"

const SchoolCard = ({ school }) => {
  const [plusImgFlag, setPlusImgFlag] = useState(false)

  useEffect(() => {
    if (!school?.name) {
      setPlusImgFlag(true)
    }
  }, [])
  const handleAddSchool = (schoolDetails) => {
    navigate(`/app/school-setup/school-profile-setup/${schoolDetails?.id ? schoolDetails.id : "new"}`)
  }
  return (
    <div
      className={`d-flex-column ${school?.name ? (school?.owner_email === localStorage.getItem("username") ? "pointer" : "") : "pointer"} `}
      onClick={school?.name ? (school?.owner_email === localStorage.getItem("username") ? () => handleAddSchool(school) : null) : () => handleAddSchool(school)}
    >
      <div
        className={`${Style.main} w-15rem h-15rem bg-303C54 br-9px d-flex flex-column justify-content-center align-items-center position-relative border_on_hover`}
      >
        {/* <Image src={ThreeDots} alt="close" className={Style.threedots} /> */}
        <ProfileUpdateProgress
          percentage={school ? school?.progress_percentage : 0}
          image={school ? school?.avatar || DefaultSchoolLogo : DefaultSchoolLogoTransparent}
          footerImgFlag={plusImgFlag}
          footerImg={PlusLogo}
          mainDivStyle="mb-14px w-8rem h-8rem"
          footerImgStyle={Style.progressBarFooterImgStyle}
          footerImgName={"Add School Logo"}
          strokeWidth={3}
          imgName=""
        />
        {school?.name && <p className="text-left poppins_font fs-18px turient-header-text-color mb-1">{school?.name}</p>}
        {school?.name ? (
          HasEditPermission(SCHOOL) && (
            <button
              onClick={() => handleAddSchool(school)}
              className="w-70 h-12 bg-586886 border_on_hover turient-header-text-color br-23px text-center fs-14px poppins_font  "
            >
              {"Setup your School"}
            </button>
          )
        ) : (
          <button
            onClick={() => handleAddSchool(school)}
            className="w-70 h-12 bg-586886 border_on_hover turient-header-text-color br-23px text-center fs-14px poppins_font  "
          >
            {"Add School"}
          </button>
        )}
      </div>
    </div>
  )
}
const schoolShape = {
  schoolName: PropTypes.string.isRequired, //School Name
  buttonText: PropTypes.string.isRequired, //Text to be shown inside button
  logo: PropTypes.string, // School Logo
}

SchoolCard.propTypes = {
  schools: PropTypes.arrayOf(PropTypes.shape(schoolShape)),
}

export default SchoolCard
