import React, { useEffect, useState, useCallback } from "react"
import _ from "lodash"
import { navigate, Link } from "gatsby"
import axios from "axios"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import Dropdown from "react-bootstrap/Dropdown"
import { LazyLoadImage } from "react-lazy-load-image-component"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"
import pixelResize from ".././utilities/pixelresize"
import { NewCheckBox } from "./course-content"
import Image from "../custom/Image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEllipsisV,
  faChevronLeft,
  faChevronRight,
  faChevronCircleUp,
  faChevronCircleDown,
  faExclamationTriangle,
  faSearch,
  faExternalLinkSquareAlt,
} from "@fortawesome/free-solid-svg-icons"

import "react-datetime/css/react-datetime.css"
import CancelWhite from "../../images/svgs/close.svg"
import StylesM from "../upload-resource.module.scss"
import Styles from "./create.module.scss"
import StylesB from "../courses/course.module.scss"
import { ScholarHatLogo } from "../../constants/global"

import NotFoundPage from "../../pages/404"

const DuplicateTraining = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""
  const [searchValue, setSearchValue] = useState("")
  const [selectCourseCollapdsed, setSelectCourseCollapsed] = useState(false)
  const [clickedCourseCollapse, setClickedCourseCollapse] = useState(false)
  const [courses, setCourses] = useState([])
  const [coursesNext, setCoursesNext] = useState(null)
  const [currentCourse, setCurrentCourse] = useState(null)
  const [courseType, setCourseType] = useState("0")
  const [courseDays, setCourseDays] = useState(0)
  const [activestate, setActiveState] = useState("course_detail")
  const [copyCourse, setCopyCourse] = useState({})
  const delayedSearchQuery = useCallback(
    _.debounce((value, selectCourseType) => fetchCourses(value, selectCourseType), 1000),
    []
  )
  const [hovered, setHovered] = useState(false)
  const [courseName, setCourseName] = useState("")
  const [duplicateCourse, setDuplicateCourse] = useState(true)
  const [courseDisplayName, setCourseDisplayName] = useState("")
  const [courseTagLine, setCourseTagLine] = useState("")
  const [preworkshopSeleted, setPreworkshopSeleted] = useState(false)
  const [postworkshopSeleted, setPostworkshopSeleted] = useState(false)
  const [liveworkshopSelected, setLiveworkshopSelected] = useState(true)
  const [selectCourseType, setSelectCourseType] = useState("0")
  const [avatar, setAvatar] = useState("")
  const courseData = ["Live Course", "eLearning", "classroom", "Cohort"]
  const [showStage, setShowStage] = useState(false)
  const [new_temp_id, setNewTempID] = useState("")
  const [template_id, setTemplateID] = useState("")
  const [courseID, setCourseID] = useState("")
  const [showCourseModal, setShowCourseModal] = useState(false)

  let x = new Date()

  useEffect(() => {
    // searchTemplates()
    fetchCourses("", selectCourseType)
  }, [courseType])

  async function fetchCourses(value, selectCourseType) {
    let search = ""
    if (value) {
      search = `&search=${value}`
    }
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-default-course-templates-list/?course_type=${selectCourseType}${search}`, {
        headers: login_credentials,
      })
      .then((res) => {
        setCourses(res.data.results)
        setCoursesNext(res.data.next)
      })
  }

  function scrollRight() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft += 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  async function fetchNextCourses(next_url) {
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_course_data = courses
      if (res.data.results.length > 0) {
        for (var crs = 0; crs <= res.data.results.length - 1; crs++) {
          next_course_data.push(res.data.results[crs])
        }
        setCourses(next_course_data)
      }
      setCoursesNext(res.data.next)
    })
  }

  async function fetchCourse(location) {
    // setCompanyLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/courses/${location.template_course}/`, { headers: login_credentials }).then((res) => {
      let course_duration = res.data.duration
      if (course_duration > 0) {
        let days = Math.floor(course_duration / 24)
        setCourseDays(days)
      }
      setAvatar(res.data.avatar)
      setCopyCourse(res.data)
      let str = res.data.name
      if (str.length > 23) {
        let res1 = str.substring(0, 22)
        let res2 = res1 + "..."
        setCourseName(res2)
      } else {
        setCourseName(str)
      }
      setCourseDisplayName(res.data.display_name)
      setCourseTagLine(res.data.tag_line)
      setCourseType(courseData[res.data.course_type])
    })
  }

  function handleSubmit(event) {
    event.preventDefault()
    const oldcourseID = copyCourse["id"]

    delete copyCourse["id"]
    delete copyCourse["course_template"]
    delete copyCourse["draft_template_id"]
    delete copyCourse["certification"]
    let formData = new FormData()

    for (var k in copyCourse) {
      if (k == "cohort_schedule") {
        formData.append(k, JSON.stringify(copyCourse[k]))
      } else if (k == "avatar") {
        formData.append(k, "")
      } else if (k == "name") {
        formData.append(k, copyCourse[k] + " copy")
      } else if (k === "mural_account") {
        formData.append(k, copyCourse[k] ? copyCourse[k] : "")
      } else {
        formData.append(k, copyCourse[k])
      }
    }
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/courses/create/", formData, { headers: login_credentials })
      .then((res) => {
        setNewTempID(res.data.course_template.id)
        setCourseID(res.data.id)
        axios
          .get(process.env.GATSBY_REACT_APP_API_HOST + "/default-course-templates/fetch_or_create/" + oldcourseID + "/", { headers: login_credentials })
          .then((res) => {
            let stage = res.data.stages
            setTemplateID(res.data.id)

            for (k in stage) {
              if (stage[k].name == "Pre Workshop") setPreworkshopSeleted(true)
              if (stage[k].name == "Post Workshop") setPostworkshopSeleted(true)
              if (stage[k].name == "Workshop") setLiveworkshopSelected(true)
            }
          })
        setShowStage(true)
        setActiveState("course_content")
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function goToPublish() {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/course-duplication/template-replica/?template_id=${template_id}&new_temp_id=${new_temp_id}&workshop=${liveworkshopSelected}&pre=${preworkshopSeleted}&post=${postworkshopSeleted}`,
        { headers: login_credentials }
      )
      .then((res) => {
        if (res.data.data[0] == "Success") navigate(`/app/companies/${location.company_id}/courses/course-publish/${courseID}`)
        else return <NotFoundPage />
      })
  }

  function stageSelectView() {
    return (
      <Col xs="12" sm="12" md="12" lg="12" className={`py-0 d-flex flex-column px-4 bg-2 w-100 my-0 br-9px inner-element-box-shadow`}>
        <div className={`text-color-1 fs-16px font-weight-bold mb-3`}>Edit class structure</div>
        <NewCheckBox change_value="pre" checked={preworkshopSeleted} changeInParent={(value) => setPreworkshopSeleted(!preworkshopSeleted)}>
          <div className={`fs-20px font-weight-bold mb-1`}>Pre Worskhop</div>
          <div>
            Select this if there is any pre-workshop content for the students to go through. A simple learning layout will be provided for you to setup content
            that your students will go through and come prepared before the live workshop.
          </div>
        </NewCheckBox>
        <NewCheckBox change_value="pre" checked={liveworkshopSelected} changeInParent={(value) => {}}>
          <div className={`fs-20px font-weight-bold mb-1`}>Live Worskhop</div>
          <div>
            Workshop content for the students to go through. A simple learning layout will be provided for you to setup content that your students will go
            through during the live workshop.
          </div>
        </NewCheckBox>
        <NewCheckBox change_value="post" checked={postworkshopSeleted} changeInParent={(value) => setPostworkshopSeleted(!postworkshopSeleted)}>
          <div className={`fs-20px font-weight-bold mb-1`}>Post Workshop</div>
          <div>
            Select this if there is any post-workshop content for the students to go through. A simple learning layout will be provided for you to setup content
            that your students will go through after the live workshop.
          </div>
        </NewCheckBox>
        <div className={`mt-2`}>
          <Button
            className={`border_on_hover px-4 py-1 turient-body-text-color mb-0`}
            onClick={() => {
              setActiveState("course_publish")
              goToPublish()
            }}
            style={{
              backgroundColor: "#586886",
              borderRadius: "18px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              fontSize: "14px",
              border: "1px solid transparent",
            }}
          >
            {" "}
            Keep the same structure
          </Button>
        </div>
      </Col>
    )
  }

  function getSingleCourse(datum, index) {
    return (
      <Col
        key={`${datum.name.toLowerCase().split(" ").join("-")}-${index}`}
        name="course"
        id={datum.id}
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="border_on_hover d-inline-flex w-100 py-2 px-0 mb-2"
        style={{ backgroundColor: "#42506C", borderRadius: "9px", boxShadow: "0px 3px 6px #00000029", border: "1px solid transparent" }}
      >
        <div
          className="float-left align-self-center d-flex-align-center"
          style={{ width: "88%", color: "#E0E2E5" }}
          onClick={(e) => {
            setSelectCourseCollapsed(true)
            setCurrentCourse(datum.name)
            fetchCourse(datum)
          }}
        >
          <p className="d-inline-flex mb-0 pl-2 mr-3 font-weight-bold" style={{ fontSize: "16px" }}>
            {datum.name}
          </p>
        </div>
        <div className="float-right align-self-center d-flex" style={{ width: "12%" }}>
          <u
            className={`${Styles.onfocuswhite}`}
            style={{ fontSize: "14px", color: "#E0E2E5" }}
            onClick={(e) => {
              e.preventDefault()
              setShowCourseModal(true)
              fetchCourse(datum)
            }}
          >
            View Details
          </u>
        </div>
      </Col>
    )
  }

  function calculateWidth() {
    let ele = document.getElementById("coursename")
    if (ele !== null) {
      let data = 1100 - parseInt(ele.offsetWidth) - 50
      return { width: data, overflowX: "scroll", flex: "auto" }
    }
  }

  const showCourseDetailModal = () => {
    return (
      <Modal
        show={showCourseModal}
        onHide={() => {
          setShowCourseModal(false)
        }}
        centered
        size="md"
        scrollable={true}
        className={`${StylesM.classroom_delete_modal} classroom-resource-delete-modal`}
      >
        <Modal.Header className={`pb-0`} style={{ borderBottom: "none", background: "#212C42" }}>
          <div className="float-left w-100">
            <div className="float-left w-100 d-flex align-items-end" style={{ justifyContent: "end" }}>
              <img
                onClick={(e) => {
                  e.preventDefault()
                  setShowCourseModal(false)
                }}
                src={CancelWhite}
                alt={`Cancel`}
                className={`my-auto mr-1 pointer`}
                style={{ objectFit: `contain`, height: `15px` }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="align-items-center" style={{ backgroundColor: "#212C42" }}>
          <Col lg="12" md="12" sm="12" xs="12" className="px-0" style={{ color: "#818895", fontSize: "15px" }}>
            <div className="d-inline-flex align-items-center ml-2">
              <div>
                <div className="d-inline-flex w-100 align-items-center ml-2">
                  <p className={`mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                    Course Type:{" "}
                  </p>
                  <p className={`mb-0`} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                    {courseType}{" "}
                  </p>
                </div>
                <div className="d-inline-flex w-100 align-items-center mt-2 ml-2">
                  <p className={`mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                    Course Name:{" "}
                  </p>
                  <p className={`mb-0`} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                    {courseName}{" "}
                  </p>
                </div>
                <div className="d-inline-flex w-100 align-items-center mt-2 ml-2">
                  <p className={`mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                    Course Display Name:{" "}
                  </p>
                  <p className={`mb-0 `} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                    {courseDisplayName}{" "}
                  </p>
                </div>
                <div className="d-inline-flex w-100 align-items-center mt-2 ml-2">
                  <p className={`mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                    Course Length:{" "}
                  </p>
                  <p className={`mb-0 `} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                    {courseDays}{" "}
                  </p>
                </div>
                <div className="d-inline-flex w-100 align-items-center mt-2 ml-2">
                  <p className={`mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                    Course Tagline:{" "}
                  </p>
                  <p className={`mb-0 `} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                    {courseTagLine}{" "}
                  </p>
                </div>
              </div>
              <div>
                <div className={`mr-4 align-items-right`}>
                  <LazyLoadImage
                    src={avatar ? avatar : ScholarHatLogo}
                    alt={currentCourse}
                    effect="blur"
                    height={pixelResize(150, "height")}
                    width={pixelResize(150)}
                    style={{ margin: "0px", objectFit: `contain` }}
                  />
                  <p
                    className={`pt-9px turient-body-text-color turient-font-family-Medium `}
                    style={{ marginLeft: "30px", fontWeight: "500", fontSize: pixelResize(22) }}
                  >
                    {currentCourse}
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    )
  }

  function findScrollableOrNot() {
    let ele = document.getElementById("sections")

    if (ele !== null && ele !== undefined) {
      if (ele.scrollWidth > ele.clientWidth) {
        return true
      } else {
        return false
      }
    }
  }

  function scrollLeft() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft -= 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function handleCourseTypeToggle(type) {
    if (type !== selectCourseType) {
      fetchCourses("", type)
      setSelectCourseType(type)
      setSearchValue("")
    }
  }

  return (
    <div>
      <SEO title="Course Duplication" />
      <Col xs="12" sm="12" md="10" lg="10" className="d-inline-flex mt-5 mb-0" style={{ alignItems: "center", flexDirection: "column", marginLeft: "7%" }}>
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          onMouseOver={(e) => {
            e.stopPropagation()
            e.preventDefault()
            let ele = document.getElementById("sections")
            if (ele !== null && ele !== undefined) {
              if (ele.scrollWidth > ele.clientWidth) {
                setHovered(true)
              }
            }
          }}
          className={`${StylesB.course_creation_header} d-flex px-0`}
        >
          <p
            className="mb-0 py-2 px-3 float-left font-weight-bold"
            id="coursename"
            style={{ borderRadius: "9px 0px 0px 9px", lineHeight: "1", minWidth: "max-content" }}
          >
            New Duplicate Course
          </p>
          <div className={`float-left align-self-center text-right`} onClick={() => setDuplicateCourse(!duplicateCourse)}>
            <FontAwesomeIcon
              className="float-left pointer"
              icon={duplicateCourse ? faChevronCircleDown : faChevronCircleUp}
              style={{ width: `20px`, height: `20px`, color: `#E0E2E5` }}
            />
          </div>
          <div
            className={`float-left position-absolute ${duplicateCourse ? "d-none" : ""}`}
            style={{
              top: "45px",
              border: "1px solid #E0E2E5",
              backgroundColor: "#212C42",
              zIndex: "2",
              boxShadow: "0px 6px 12px #00000099",
              borderRadius: "9px",
            }}
          >
            <div
              className={`mb-0 py-1 px-2 ${Styles.duplicate_class} pointer`}
              style={{ borderBottom: "1px solid #707070", borderTopLeftRadius: "9px", borderTopRightRadius: "9px", fontSize: "14px" }}
            >
              <Link className={`${Styles.duplicate_class_text}`} to={`/app/companies/${location.company_id}/courses/course-information`}>
                Create a new course
              </Link>
            </div>
            <div
              className={`mb-0 py-1 px-2 ${Styles.duplicate_class} pointer`}
              style={{ borderBottomLeftRadius: "9px", borderBottomRightRadius: "9px", fontSize: "14px" }}
            >
              <Link to={`/app/companies/${location.company_id}/courses/new/courses/duplicate`} className={`${Styles.duplicate_class_text}`}>
                Duplicate an existing course
              </Link>
            </div>
          </div>
          {findScrollableOrNot() && hovered ? (
            <FontAwesomeIcon
              onClick={() => {
                scrollLeft()
              }}
              className="pointer"
              icon={faChevronLeft}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551` }}
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => {
                scrollLeft()
              }}
              className="pointer"
              icon={faChevronLeft}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551`, visibility: "hidden" }}
            />
          )}
          <div id="sections" className="d-flex align-items-center hide_scrollbar" style={calculateWidth()}>
            <p
              className={`${activestate === "course_detail" ? StylesB.course_nav_item_active : ""} mb-0 pointer py-2 px-2 fs-16px font-weight-bold`}
              style={{ color: "#E0E2E5", minWidth: "max-content" }}
              id="course_detail"
            >
              Course Details
            </p>
            <p
              className={`${activestate === "course_content" ? StylesB.course_nav_item_active : ""} mb-0 pointer py-2 px-2 fs-16px font-weight-bold`}
              style={{ color: "#E0E2E5", minWidth: "max-content" }}
              id="course_detail"
            >
              Course Content
            </p>
            <p
              className={`${activestate === "course_publish" ? StylesB.course_nav_item_active : ""} mb-0 pointer py-2 px-2 fs-16px font-weight-bold`}
              style={{ color: "#E0E2E5", minWidth: "max-content" }}
              id="course_detail"
            >
              Publish Course
            </p>
            {/* <div className='float-right ml-auto'>
              <button className='border_on_hover d-flex' style={{backgroundColor:'#586886',border:'1px solid #586886',padding:'5px 15px 7px 15px',boxShadow:'0px 3px 6px #00000029',borderRadius:'23px',color:'#E0E2E5',fontSize:'14px'}}>
                <p style={{marginBottom:'0px',lineHeight:1,minWidth:'max-content'}} >Preview students page</p> <FontAwesomeIcon className='ml-2' icon={faExternalLinkSquareAlt}/>
              </button>
            </div> */}
          </div>
          {findScrollableOrNot() && hovered ? (
            <FontAwesomeIcon
              onClick={() => {
                scrollRight()
              }}
              className="pointer"
              icon={faChevronRight}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551` }}
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => {
                scrollRight()
              }}
              className="pointer"
              icon={faChevronRight}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551`, visibility: "hidden" }}
            />
          )}
          <Image
            src={CancelWhite}
            alt={`close`}
            width={20}
            height={20}
            onClick={(e) => {
              e.preventDefault()
              navigate("/app/dashboard")
            }}
            className={`pointer mr-3 ml-auto float-right align-self-center`}
          />
        </Col>
      </Col>
      {!showStage ? (
        <Form className="form-horizontal px-3" onSubmit={handleSubmit}>
          <Col
            xs="12"
            sm="12"
            md="10"
            lg="10"
            className={`w-100 mr-0 pt-2 mb-0 pb-2`}
            style={{ marginLeft: "7%", borderRadius: "9px 9px 0px 0px", backgroundColor: "#212C42", boxShadow: "0px 3px 12px #00000099" }}
          >
            <Col
              lg="12"
              md="12"
              sm="12"
              xs="12"
              className={`pointer ${selectCourseCollapdsed ? `border_on_hover` : null} mb-2 w-100  px-0`}
              style={{
                textAlign: "left",
                backgroundColor: "#303C54",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "9px",
                border: !selectCourseCollapdsed ? "1px solid white" : "1px solid transparent",
              }}
            >
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                onClick={() => {
                  setSelectCourseCollapsed(!selectCourseCollapdsed)
                }}
                className={`pointer ${
                  (currentCourse === null || currentCourse === "") && selectCourseCollapdsed ? `py-3` : !selectCourseCollapdsed ? "py-2" : `py-3`
                }  d-inline-flex w-100`}
                style={{ borderBottom: !selectCourseCollapdsed ? "1px solid #63666f" : "1px solid transparent" }}
              >
                <div className="float-left align-self-center" style={{ width: "87%" }}>
                  {currentCourse !== null ? (
                    <div className="d-inline-flex align-items-center">
                      <p className={`d-inline-flex mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                        Course:{" "}
                      </p>
                      <p className={`d-inline-flex mb-0 `} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                        {currentCourse}{" "}
                      </p>
                      <p className={`d-inline-flex mb-0 `} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                        ({courseDays} Days)
                      </p>
                    </div>
                  ) : (
                    <p
                      className={`d-inline-flex float-left mb-0 d-flex justify-content-center`}
                      style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}
                    >
                      Select an existing course{" "}
                    </p>
                  )}
                </div>
                <div className={`d-inline float-right align-self-center text-right`} style={{ marginLeft: "10%", justifyContent: "space-between" }}>
                  <FontAwesomeIcon
                    onClick={() => {
                      setSelectCourseCollapsed(!selectCourseCollapdsed)
                    }}
                    className="float-right pointer"
                    icon={selectCourseCollapdsed ? faChevronCircleDown : faChevronCircleUp}
                    style={{ width: `20px`, height: `20px`, color: `#E0E2E5` }}
                  />
                </div>
                {/*{!selectCourseCollapdsed ?
              <Dropdown className='breakout-groups pointer ml-2 float-right'>
                <Dropdown.Toggle className="border-none p-0 shadow-none" style={{backgroundColor: 'transparent'}} id="dropdown-basic">
                  <div><FontAwesomeIcon icon={faEllipsisV} style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E5` }} /></div>
                </Dropdown.Toggle>
                <Dropdown.Menu className={`br-9px breakout-groups ${StylesB.min_fit_width}`} >
                  <Dropdown.Item onClick={() => {navigate('/app/dashboard')}} className={`${StylesB.dropitem} text-white fs-14px px-2 py-1 d-inline-flex`} style={{ minWidth: `max-content` }} >&nbsp;Close</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              :
              null
            }*/}
              </Col>
              {selectCourseCollapdsed ? null : (
                <Col
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  className={`pointer ${coursesNext ? `pb-1` : `pb-0`} pt-3 px-0 my-0 text-left`}
                  style={{ backgroundColor: "#303C54", borderRadius: "9px", maxHeight: "350px", overflowY: "scroll", overflowX: "hidden" }}
                >
                  <Col lg="12" md="12" sm="12" xs="12" className="mt-2 mb-1 justify-content-between">
                    <div className="float-left align-self-center d-flex col-12" style={{ backgroundColor: "#42506C", borderRadius: "9px" }}>
                      <FontAwesomeIcon color="#7D8289" className={`my-auto ml-2`} icon={faSearch} size="lg" />
                      <FormControl
                        autoFocus
                        className={`border-0 shadow-none py-0 form-control px-2 bg-cards`}
                        placeholder="Search Course"
                        onChange={(e) => {
                          e.preventDefault()
                          setSearchValue(e.target.value)
                          delayedSearchQuery(e.target.value, selectCourseType)
                        }}
                        value={searchValue}
                        style={{ backgroundColor: "#42506C", fontSize: "16px", color: "#E0E2E5" }}
                      />
                    </div>
                    <div className="mt-3 mr-1 d-inline-flex">
                      <div
                        className="float-left align-self-center ml-2"
                        style={{ background: "#42506C", border: "1px solid #E0E2E5", borderRadius: "9px", boxShadow: "0px 3px 6px #00000029" }}
                      >
                        <Button
                          onClick={() => {
                            handleCourseTypeToggle("0")
                          }}
                          className={`float-left px-4 py-1 ${selectCourseType === "0" ? `font-weight-bold` : ``} border_on_hover`}
                          style={{ background: selectCourseType === "0" ? "#4F6080" : "#42506C", border: "1px solid transparent", borderRadius: "9px" }}
                          color="warning"
                        >
                          <p className={`p-0 m-0`} style={{ fontSize: "16px" }}>
                            Live Courses
                          </p>
                        </Button>
                        <Button
                          onClick={() => {
                            handleCourseTypeToggle("3")
                          }}
                          className={`float-left px-4 py-1 ${selectCourseType === "3" ? `font-weight-bold` : ``} border_on_hover`}
                          style={{ background: selectCourseType === "3" ? "#4F6080" : "#42506C", border: "1px solid transparent", borderRadius: "9px" }}
                          color="warning"
                        >
                          <p className={`p-0 m-0`} style={{ fontSize: "16px" }}>
                            Cohort Courses
                          </p>
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12">
                    {courses.length > 0 ? (
                      courses.map((datum, index) => getSingleCourse(datum, index))
                    ) : (
                      <div className={`fs-16px my-2`}>
                        <div className={`mb-1`}>No course found.</div>
                        <div>
                          You can create a Course from here :{" "}
                          <span className={`underline bold-500`}>
                            <Link to={`/app/companies/${localStorage.getItem("company_id")}/courses/course-information/`} style={{ color: "#E0E2E5" }}>
                              Home / Create Course
                            </Link>
                          </span>
                        </div>
                      </div>
                    )}
                  </Col>
                  {coursesNext ? (
                    <Col xs="12" className={`mt-2 ml-3 mb-1 px-0 float-left d-inline-flex`}>
                      <u
                        onClick={() => {
                          fetchNextCourses(coursesNext)
                        }}
                        style={{ fontSize: "14px", color: "#E0E2E5" }}
                        className={`float-left ${Styles.onfocuswhite}`}
                        color="warning"
                      >
                        Show More
                      </u>{" "}
                    </Col>
                  ) : null}
                </Col>
              )}
            </Col>
            {currentCourse === null || currentCourse === "" ? (
              <Col xs="12" sm="12" md="12" lg="12" className={`mt-2 mb-2 px-0 ${clickedCourseCollapse ? null : `d-none`}`}>
                <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
                  <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                  <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                    Please select the course to proceed
                  </p>

                  {/* <Button className={`float-left px-4 py-1 ${giveCertification === 'yes' ? `font-weight-bold` : ``} border_on_hover`} style={{background: giveCertification === 'yes' ? '#4F6080' : '#42506C', border:'1px solid transparent',borderRadius:'9px'}} color="warning"><p className={`p-0 m-0`} style={{fontSize:'16px'}}>Yes</p></Button> */}
                </div>
              </Col>
            ) : (
              <div className="align-items-center mt-4 ml-2">
                <div className="align-items-center ml-2 mt-2">
                  <p className={`mb-0 ml-0`} style={{ fontSize: "16px", top: "33%", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                    Course Information{" "}
                  </p>
                </div>
                <div className="d-inline-flex w-100 align-items-center">
                  <div className="align-items-center">
                    <div className="d-inline-flex w-100 align-items-center ml-2">
                      <p className={`mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                        Course Type:{" "}
                      </p>
                      <p className={`mb-0`} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                        {courseType}{" "}
                      </p>
                    </div>
                    <div className="d-inline-flex w-100 align-items-center mt-2 ml-2">
                      <p className={`mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                        Course Name:{" "}
                      </p>
                      <p className={`mb-0`} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                        {currentCourse}{" "}
                      </p>
                    </div>
                    <div className="d-inline-flex w-100 align-items-center mt-2 ml-2">
                      <p className={`mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                        Course Display Name:{" "}
                      </p>
                      <p className={`mb-0 `} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                        {courseDisplayName}{" "}
                      </p>
                    </div>
                    <div className="d-inline-flex w-100 align-items-center mt-2 ml-2">
                      <p className={`mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                        Course Length:{" "}
                      </p>
                      <p className={`mb-0 `} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                        {courseDays}{" "}
                      </p>
                    </div>
                    <div className="d-inline-flex w-100 align-items-center mt-2 ml-2">
                      <p className={`mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                        Course Tagline:{" "}
                      </p>
                      <p className={`mb-0 `} style={{ fontSize: "16px", color: "#E0E2E5", paddingLeft: "13px" }}>
                        {courseTagLine}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="d-inline-flex ml-5 w-50">
                    <div className={`align-items-right`}>
                      <LazyLoadImage
                        src={avatar ? avatar : ScholarHatLogo}
                        alt={currentCourse}
                        effect="blur"
                        height={pixelResize(200, "height")}
                        width={pixelResize(200)}
                        style={{ objectFit: `contain` }}
                      />
                      <p
                        className={`turient-body-text-color turient-font-family-Medium `}
                        style={{ marginLeft: "58px", fontWeight: "500", fontSize: pixelResize(22) }}
                      >
                        {currentCourse}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Col>
          <Col
            xs="12"
            sm="12"
            md="10"
            lg="10"
            className={`w-100 d-inline-flex mr-0 pt-3 pb-3`}
            style={{
              marginLeft: "7%",
              backgroundColor: "#212C42",
              boxShadow: " 0px -3px 6px #00000029",
              borderRadius: "0px 0px 9px 9px",
              borderTop: "1px solid #63666f",
            }}
          >
            <button
              type="submit"
              className="border_on_hover"
              style={{
                backgroundColor: "#586886",
                border: "1px solid #586886",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "4px",
                paddingTop: "5px",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "23px",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              Next
            </button>
          </Col>
        </Form>
      ) : (
        <div
          className={`d-inline-flex mr-4 pt-3 pb-3`}
          style={{ marginLeft: "8%", backgroundColor: "#212C42", boxShadow: " 0px -3px 6px #00000029", borderRadius: "0px 0px 9px 9px", width: "81%" }}
        >
          {stageSelectView()}
        </div>
      )}
      {showCourseModal ? showCourseDetailModal() : null}
    </div>
  )
}

export default DuplicateTraining
