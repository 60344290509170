import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"

import queryString from "query-string"

import Layout from "../layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRss, faEdit, faTrash, faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const Blogs = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [blogLoader, setBlogLoader] = useState(false)
  const [blogs, setBlogs] = useState([])

  const [previous_url, setPreviousUrl] = useState()
  const [next_url, setNextUrl] = useState()
  const [total_pages, setTotalPages] = useState()

  useEffect(() => {
    fetchBlogs()
  }, [])

  async function fetchBlogs() {
    setBlogLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/trainings/${location.training_id}/trainers/`, { headers: login_credentials }).then((res) => {
      setBlogs(res.data)
      // setNextUrl(res.data.next);
      // setPreviousUrl(res.data.previous);
      // setTotalPages(parseInt(Number(res.data.count) / 5))
      setBlogLoader(true)
    })
  }

  function displayBlogData() {
    let data = blogs
    var isLoaded = blogLoader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0]),
          parsed_data = []
        parsed_data.push(
          <div className={`d-flex p-3`}>
            <div className={`pl-4 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Trainers
              </p>
            </div>
          </div>
        )
        data.map((datum, index) => {
          parsed_data.push(
            <div className={`d-flex mb-1 py-2`} key={index + `_training`} style={{ backgroundColor: `#46537E` }}>
              <div className={`pl-3 align-self-center`}>
                <FontAwesomeIcon icon={faRss} style={{ color: `#ebc280` }} size="lg" />
              </div>
              <div className={`pl-3 align-self-center`}>
                <p className={`m-0 font-weight-bold text-white`}>{datum.name}</p>
                <p className={`m-0`} style={{ color: `#27E0C3`, fontSize: "12px" }}>
                  <span>Profile Url </span> <span> {datum.linkedin_url} </span>{" "}
                </p>
                <Col className="p-0">
                  <Link className={`mr-2`} to={`/app/trainers/` + datum.id + `/edit?redirection_url=${page_uri}`}>
                    {" "}
                    <FontAwesomeIcon icon={faEdit} size="sm" />{" "}
                  </Link>
                  <Link to={`/app/trainers/` + datum.id + `/edit?redirection_url=${page_uri}`}>
                    {" "}
                    <FontAwesomeIcon icon={faTrash} size="sm" />{" "}
                  </Link>
                </Col>
              </div>
              <div className={`pl-3 ml-auto mr-3 align-self-center`}>
                <p className={`mb-0 font-weight-bold pr-5`} style={{ color: `#C6CFF4` }}>
                  {datum.points}
                </p>
              </div>
            </div>
          )
        })
        return <div>{parsed_data}</div>
      } else {
        return (
          <tr id="loader">
            <td colSpan="12" className="animated fadeIn pt-3 text-center">
              No Data found...
            </td>
          </tr>
        )
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function showBlogs() {
    if (authClient.isAuthenticated()) {
      return (
        <div>
          {/*displayBlogHeader()*/}
          {displayBlogData()}
        </div>
      )
    } else {
      return ""
    }
  }

  let page_uri = isBrowser() ? "/" + window.location.pathname.substr(1) : "false"
  if (page_uri === "/logout" || page_uri === "logout" || page_uri === "logout/" || page_uri === "/logout/") {
    page_uri = "/"
  }

  let window_location = window.location
  let { redirection_url } = queryString.parse(window_location.search)
  redirection_url = redirection_url !== undefined && redirection_url !== "undefined" ? `${redirection_url}` : "/app/dashboard"

  return (
    <div>
      <SEO title="Articles" />
      <Link className={`pointer text-white`} to={redirection_url}>
        {" "}
        <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
      </Link>
      <Col lg="12" md="12" sm="12" xs="12">
        <h4 className={`mt-3`}>All Trainers</h4>
        <div className={`bg-card ${Styles.b_radius_15} mb-5`} style={{ overflow: "scroll" }}>
          {showBlogs()}
        </div>
      </Col>
    </div>
  )
}

export default Blogs
