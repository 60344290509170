import React, { useState } from "react"
import Style from "./style.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"

// !definition of component
/**
 *
 * @param props --> name, required, label, icon, defaultValue, onChange and onBlur function
 * @description -->  name=> for id and name, required=> boolean, icon => object {name: faIcon}
 * @returns TextArea component
 */
// ! component
const CustomTextArea = (props) => {
  const [error, setError] = useState(null)

  const handleChange = (e) => {
    setError(null)
    if (props?.customValidation) {
      if (props?.customValidation(e.target.value) === "true") {
        if (props.onChange) {
          props.onChange(e, props.name)
        }
      }
    } else {
      if (props.onChange) {
        props.onChange(e, props.name)
      }
    }
  }
  const handleValidation = (e) => {
    if (props?.customValidation) {
      const validationResult = props.customValidation(e.target.value)
      console.log(validationResult, "validationresult")
      if (!validationResult) {
        e.preventDefault()
        e.target.focus()
        setError("true")
        return
      }
      setError(null)
    }
  }

  return (
    <div>
      <div
        style={props?.style}
        className={`${props?.className} ${Style.input_group} bg-303C54 br-9px border_on_hover px-5px mb-10px ${error && Style.red_border}`}
      >
        <div className="p-2 w-100 h-100 d-flex">
          {props.icon && (
            <div className={`p-1`}>
              <FontAwesomeIcon className={`${Style.icon} h-100 icon-color fs-18px`} icon={props?.icon?.name} />
            </div>
          )}
          <div className={`flex-grow-1 ${props?.icon ? "ml-2" : ""}`}>
            <textarea
              id={props?.name}
              name={props?.name}
              defaultValue={props.defaultValue ? props.defaultValue : ""}
              onChange={handleChange}
              className={`${
                Style.inputText
              } turient-header-text-color w-100 h-100 pt-2px mt-3 bg-transparent border-transparent text-left fs-18px poppins_font ${
                props.readOnly && "disable-content"
              }`}
              customvalidation={props?.customValidation}
              onInvalid={(e) => {
                e.preventDefault()
                e.target.focus()
                setError("true")
              }}
              maxLength={props?.maxLength}
              minLength={props?.minLength}
              rows={props.rows ? props.rows : "3"}
              onBlur={handleValidation}
              required={props?.required}
            />
            <span
              style={props?.icon && { left: "auto" }}
              className={`${Style.floating_label} position-absolute fs-16px turient-header-text-color opacity-40 d-flex-align-center`}
            >
              {props.label}
              {!props.required && <span className={`ml-1`}>(Optional)</span>}
            </span>{" "}
          </div>
        </div>
      </div>
    </div>
  )
}

CustomTextArea.defaultProps = {
  rows: "3",
  required: false,
  readOnly: false,
}

CustomTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  customValidation: PropTypes.func,
  onInvalid: PropTypes.func,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleValidation: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
}

export default CustomTextArea
