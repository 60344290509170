import React, { useState, useRef, useEffect, useContext, useCallback } from "react"
import PropTypes from "prop-types"
import _ from "lodash"

import Dropdown from "react-bootstrap/Dropdown"
import Loader from "../../custom/Loader"

import { getRequest, postRequest } from "../../../services/rest_service"

import AcivityWorkSpaceStyle from "../../../components/acitvity-workspace/acitivity-workspace.module.scss"
import muralIcon from "../../../images/svgs/mural-icon.svg"
import SearchIcon from "../../../images/svgs/search.svg"
import editSvg from "../../../images/svgs/edit.svg"
import deleteSvg from "../../../images/svgs/delete.svg"
import linkIcon from "../../../images/svgs/link.svg"
import arrowDown from "../../../images/svgs/arrow-dropdown-white-circle.svg"
import arrowRight from "../../../images/svgs/arrow-right-double.svg"
import validateMuralUrl from "../../../components/mural/validateMuralUrl"
import AppIntegrationModal from "../../app-integration/"
import config from "../config"
import { muralGetRequest } from "../mural-api-service"
import { GlobalContext } from "../../../stores/global_store/GlobalStore"
import { SET_APP_DATA, SET_NOTIFICATION_DATA } from "../../../stores/global_store/GlobalActions"
import Image from "../../custom/Image"
import CopyLink from "./CopyLink"
import { getRoomMurals } from "../mural-duplicate-room"

const TITLE = "Add Mural Workspace"

const AssesmentWorkSpace = (props) => {
  const [globalStore, disptachGlobalStore] = useContext(GlobalContext)

  const [activityWorkspaceData, setactivityWorkspaceData] = useState(props.activity_workspace)
  const [workspaceList, setWorkspaceList] = useState([])
  const [searchWorkspaceList, setSearchWorkspaceList] = useState([])
  const [searchText, setSearchText] = useState("")
  const [workspaceNextLink, setWorkspaceNextLink] = useState(null)
  const [loader, setLoader] = useState({ workspace: false, room: false, update: false })
  const [showDropdown, setShowDropdown] = useState(false)
  const [roomList, setRoomList] = useState([])
  const [roomNext, setRoomNext] = useState(null)
  const [dropdownState, setDropdownState] = useState(0)
  const [roomSearchText, setRoomSearchText] = useState("")
  const [muralList, setMuralList] = useState([])
  const [searchMuralList, setSearchMuralList] = useState([])
  const [searchMuralText, setSearchMuralText] = useState("")
  const [muralNextLink, setMuralNextLink] = useState(null)
  const isSearchData = useRef(false)
  /* 
  Dropdown States
  0 => No select (WS list appears)
  1 => Workspace selected (room list appears)
  2 => Workspace and room Selected
  3 => Workspace Selected and add room link
  4 => Workspace and room and mural Selected
  5 => Workspace and room and add mural link
  */

  useEffect(() => {
    let data = activityWorkspaceData?.activity_workspace?.id ? activityWorkspaceData.activity_workspace : props.activity_workspace
    if (data.id) {
      let dropdown_state = 3
      if (data.room) {
        dropdown_state = 2
        if (data?.mural?.id) {
          dropdown_state = 4
        }
      }

      setDropdownState(dropdown_state)
    }
  }, [showDropdown])

  const getDefaultWorkspace = async () => {
    if (!activityWorkspaceData?.activity_workspace?.room?.id) {
      if (!props.mural_account) {
        disptachGlobalStore({ type: SET_APP_DATA, payload: { showModal: true } })
      } else {
        let defaultValueSet = await setDefaultWorkSpace()
        if (!defaultValueSet) {
          let res = await getRequest(`/murals/certificate/get_default_mural_workspace/?course_id=${props.course.id}`)
          if (res.status) {
            if (res.data.id) {
              setShowDropdown(true)
              setactivityWorkspaceData({ activity_workspace: res.data })
              setDropdownState(2)
              updateCertificateAssessment(res.data.link, res.data.id, res.data.name, "mural_workspace", res.data.room, {}, res.data)
            } else {
              setShowDropdown(true)
              addMuralClickHandler(true)
            }
          } else {
            setShowDropdown(true)
            addMuralClickHandler(true)
          }
        } else {
          setShowDropdown(true)
        }
      }
    } else {
      setShowDropdown(true)
    }
  }

  const debouncedSearchMural = useCallback(
    _.debounce((url, isRoom) => fetchMuralData(url, isRoom), 1000),
    []
  )

  const updateCertificateAssessment = async (link, id, name, workspace_type, room, mural, workspace_details) => {
    try {
      setLoader((prev) => ({ ...prev, update: true }))
      let activity_data = workspace_details?.id ? workspace_details : activityWorkspaceData.activity_workspace
      let workspace_data = null
      if (room) {
        workspace_data = {
          link: activity_data.link,
          id: activity_data.id,
          workspace_type: activity_data.workspace_type,
          name: activity_data.name,
          room: room,
        }
        getRoomMuralData(room.id)
      } else if (mural) {
        workspace_data = {
          ...activity_data,
          mural: {
            link: link,
            id: id,
            workspace_type: workspace_type,
            name: name,
          },
        }
      } else {
        workspace_data = id
          ? {
              link: link,
              id: id,
              workspace_type: workspace_type,
              name: name,
            }
          : {}
      }

      certificateAssessment(id, workspace_data, true, room, mural)
    } catch (error) {
      console.error(error)
    }
  }

  const certificateAssessment = async (id, workspace_data, is_reset_dropdown = true, room = false, mural = false) => {
    let payload = {
      workspace_details: workspace_data,
      name: activityWorkspaceData.name,
      assessment: props.assessment.id,
    }
    let data = await postRequest(`/murals/certificate/set_assessment/`, payload)
    if (data.success) {
      props.onChangeMuralAssesment({ activity_workspace: workspace_data })
      setactivityWorkspaceData((prev) => ({ ...prev, activity_workspace: workspace_data }))
      if (is_reset_dropdown) {
        if (room) {
          setDropdownState(5)
        } else if (mural) {
          setDropdownState(4)
          setShowDropdown(false)
        } else {
          // list all rooms
          if (id) {
            setDropdownState(1)
            fetchMuralData(`/api/public/v1/workspaces/${id}/rooms`, true)
          } else {
            setDropdownState(0)
            addMuralClickHandler(true)
          }
        }
      } else {
        setDropdownState(0)
        setShowDropdown(false)
      }
    }

    setLoader((prev) => ({ ...prev, update: false }))
  }

  const fetchMuralData = async (url, isRoom = false) => {
    try {
      if (isRoom) {
        setLoader((prev) => ({ ...prev, room: true }))
      } else {
        setLoader((prev) => ({ ...prev, workspace: true }))
      }
      let response = await muralGetRequest(url, props.mural_account)
      if (response.type === "error") {
        throw response.message
      }
      if (isRoom) {
        setLoader((prev) => ({ ...prev, room: false, search: false }))
        if (url.includes("next=")) {
          setRoomList([...roomList, ...response.data.value])
        } else {
          setRoomList(response.data.value)
        }
        setRoomNext(response?.data?.next)
      } else {
        setLoader((prev) => ({ ...prev, workspace: false }))
        if (url.includes("next=")) {
          setWorkspaceList([...workspaceList, ...response.data.value])
        } else {
          setWorkspaceList(response.data.value)
        }

        setWorkspaceNextLink(response.data.next)
      }
    } catch (error) {
      setWorkspaceList([])
      setWorkspaceNextLink(null)
      disptachGlobalStore({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error } })
    }
  }

  const handleSeeMoreClick = (isRoom = false) => {
    if (!(loader.room || loader.workspace)) {
      if (!isRoom) {
        fetchMuralData(`api/public/v1/workspaces/${props?.activity_workspace?.activity_workspace?.id}/murals/?next=${workspaceNextLink}`)
      } else {
        if (roomSearchText.length >= 3) {
          fetchMuralData(`/api/public/v1/search/${props?.activity_workspace?.activity_workspace?.id}/rooms?next=${roomNext}`, true)
        } else {
          fetchMuralData(`/api/public/v1/workspaces/${props?.activity_workspace?.activity_workspace?.id}/rooms/?next=${roomNext}`, true)
        }
      }
    }
  }

  const workspaceClickHandler = (id, name, link, room = false, mural = false) => {
    if (!loader.update) {
      let link_type = "other"
      if (validateMuralUrl(link) || room) {
        link_type = "mural_workspace"
        updateCertificateAssessment(link, id, name, link_type, room, mural)
      } else if (mural) {
        link_type = "mural"
        updateCertificateAssessment(link, id, name, link_type, room, mural)
      } else {
        updateCertificateAssessment(link, "", name, link_type, room, mural)
      }
    }
  }

  const addMuralClickHandler = (show) => {
    if (show && !props?.activity_workspace?.activity_workspace?.id) {
      setSearchText("")
      if (props.mural_account) {
        fetchMuralData(`api/public/v1/workspaces/`)
      } else {
        disptachGlobalStore({ type: SET_APP_DATA, payload: { showModal: true } })
      }
    }
  }

  const handleEditMuralRoomClick = () => {
    setDropdownState(1)
    fetchMuralData(`/api/public/v1/workspaces/${props?.activity_workspace?.activity_workspace?.id}/rooms`, true)
  }

  const getRoomMuralData = async (roomId) => {
    let response = await getRoomMurals(roomId, props.mural_account)
    setMuralList(response.value)
    setMuralNextLink(response?.next)
  }

  const handleSearchWorkspace = (e) => {
    let searchText = e.target.value
    setSearchText(searchText)
    let data = workspaceList.filter((mural) => {
      let title = mural?.name?.toLowerCase()
      return title.includes(searchText.toLowerCase())
    })
    if (searchText) {
      setSearchWorkspaceList(data)
    }
  }

  const handleSearchMural = (e) => {
    let searchText = e.target.value
    setSearchMuralText(searchText)
    let data = muralList.filter((mural) => {
      let title = mural?.name?.toLowerCase()
      return title.includes(searchText.toLowerCase())
    })
    if (searchText) {
      setSearchMuralList(data)
    }
  }

  const handleRoomSearch = (e) => {
    let search = e.target.value
    setRoomSearchText(search)
    let url = `api/public/v1/workspaces/${props?.activity_workspace?.activity_workspace?.id}/rooms`
    if (search.length >= 3) {
      url = `api/public/v1/search/${props?.activity_workspace?.activity_workspace?.id}/rooms?title=${e.target.value}`
      setLoader((prev) => ({ ...prev, search: true }))
      isSearchData.current = true
      debouncedSearchMural(url, true)
    } else {
      if (isSearchData.current) {
        isSearchData.current = false
        debouncedSearchMural(url, true)
      }
    }
  }

  const setDefaultWorkSpace = async () => {
    let defaultValueSet = false
    let certificationData = JSON.parse(JSON.stringify(props.certificatesData))
    certificationData.reverse().forEach((certificate_obj) => {
      if (certificate_obj?.mural_assessment?.activity_workspace?.id) {
        let activity_workspace = certificate_obj.mural_assessment.activity_workspace
        if (activity_workspace?.mural?.id) {
          activity_workspace.mural = {}
        }
        setactivityWorkspaceData({ activity_workspace: activity_workspace })
        defaultValueSet = true
        return
      }
    })
    return defaultValueSet
  }

  return (
    <React.Fragment>
      <div className={`${AcivityWorkSpaceStyle.activity_room_workspace_container}`}>
        <Dropdown
          className={`${AcivityWorkSpaceStyle.dropdown_width}`}
          onToggle={() => {
            setShowDropdown(!showDropdown)
          }}
          show={showDropdown}
        >
          {!activityWorkspaceData?.activity_workspace?.mural?.link ? (
            <Dropdown.Toggle
              className={`${AcivityWorkSpaceStyle.dropdown_toggle} underline pointer fs-14px mr-5px`}
              id="dropdown-mural-workspace-list"
              onClick={() => {
                getDefaultWorkspace()
              }}
            >
              {TITLE}
            </Dropdown.Toggle>
          ) : (
            <div
              className={`border-0 px-1 py-1 d-flex align-items-center ${AcivityWorkSpaceStyle.btn_focus} ${AcivityWorkSpaceStyle.existing_activity_button} ${AcivityWorkSpaceStyle.existing_activity_box}`}
            >
              <div className="flex-shrink-0">
                <Image
                  src={
                    activityWorkspaceData?.activity_workspace?.link?.includes("app.mural") || activityWorkspaceData?.workspace_link?.includes("app.mural")
                      ? muralIcon
                      : linkIcon
                  }
                  alt={`Activity Workspace Icon`}
                  className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`}
                />
              </div>
              <div className={`px-1 ${AcivityWorkSpaceStyle.assesment_mural_title}`}>
                {activityWorkspaceData?.activity_workspace?.mural?.name ? activityWorkspaceData?.activity_workspace?.mural?.name : "Assesment Mural's"}
              </div>
              <div className={`px-1`}>:</div>

              <div className={`${AcivityWorkSpaceStyle.mural_workspace_name} ${AcivityWorkSpaceStyle.assesment_mural_link}`}>
                {activityWorkspaceData?.activity_workspace?.mural?.link}
              </div>
              <Dropdown.Toggle
                className={`${AcivityWorkSpaceStyle.dropdown_toggle} flex-shrink-0 ml-1`}
                id="dropdown-mural-exisiting"
                onClick={() => {
                  setShowDropdown(true)
                }}
              >
                <div className={`d-flex align-items-center`}>
                  <Image alt="edit" src={editSvg} className={`mb-0 ${AcivityWorkSpaceStyle.activity_workspace_edit_icon}`} />
                </div>
              </Dropdown.Toggle>
              <div className={`d-flex align-items-center`}>
                <Image
                  alt="delete"
                  src={deleteSvg}
                  className={`mb-0 ${AcivityWorkSpaceStyle.activity_workspace_edit_icon}`}
                  onClick={() => {
                    updateCertificateAssessment(null, null, null, null, false, false)
                  }}
                />
              </div>
            </div>
          )}
          <Dropdown.Menu className={`py-0 overflow-auto ${AcivityWorkSpaceStyle.add_activity_room_workspace} ${AcivityWorkSpaceStyle.min_width_700}`}>
            {dropdownState === 0 && (
              <>
                <div className={`col-12 d-inline-flex py-1 ${AcivityWorkSpaceStyle.activity_menu_devider}`}>
                  <Image src={SearchIcon} alt={`search button`} className={`my-auto ${AcivityWorkSpaceStyle.input_field_icon}`} />
                  <input
                    autoComplete="off"
                    type="text"
                    name="name"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onChange={handleSearchWorkspace}
                    placeholder={"Search Mural Workspace"}
                    className={`${AcivityWorkSpaceStyle.activity_search_input} form-control border-0`}
                  />
                </div>
                {(searchText ? searchWorkspaceList : workspaceList).map((mural, index) => {
                  let link = `${config.baseURL}/t/${mural?.id}/home`
                  let title = `${mural.name} Workspace`
                  let name = mural?.name
                  return (
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        workspaceClickHandler(mural.id, name, link)
                      }}
                      className={`col-12 d-inline-flex pointer d-flex align-items-center ${
                        index !== workspaceList.length - 1 && AcivityWorkSpaceStyle.activity_menu_devider
                      } ${AcivityWorkSpaceStyle.activity_menu_item}`}
                      key={mural?.id}
                    >
                      <div className="flex-shrink-0">
                        <Image src={muralIcon} alt={`Activity Workspace Icon`} className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`} />
                      </div>
                      <div className={`mx-2 d-flex flex-column ellipsis`}>
                        <div className={`font-weight-bold ellipsis`}>{title}</div>
                        <div className={`ellipsis`}>{link}</div>
                      </div>
                      <CopyLink text={link} />
                    </div>
                  )
                })}
                {workspaceNextLink && (
                  <div className={`underline pointer p-2`} onClick={handleSeeMoreClick}>
                    See more
                  </div>
                )}
              </>
            )}
            {dropdownState === 1 && (
              <>
                <div
                  className={`col-12 d-inline-flex ${AcivityWorkSpaceStyle.activity_menu_item} ${AcivityWorkSpaceStyle.selected_activity_workspace} ${AcivityWorkSpaceStyle.activity_menu_devider}`}
                >
                  <Image
                    src={
                      activityWorkspaceData?.activity_workspace?.link?.includes("app.mural") || activityWorkspaceData?.workspace_link?.includes("app.mural")
                        ? muralIcon
                        : linkIcon
                    }
                    alt={`Activity Workspace Icon`}
                    className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`}
                  />
                  <div className={`ml-2`}>{`${activityWorkspaceData?.activity_workspace?.name} WorkSpace`}</div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    className={`ml-2 border-0 ellipsis`}
                  >
                    {activityWorkspaceData?.workspace_link ? activityWorkspaceData?.workspace_link : activityWorkspaceData?.activity_workspace?.link}
                  </div>
                  <div className={`ml-auto flex-shrink-0 pl-2`}>
                    <Image
                      onClick={() => {
                        setDropdownState(0)
                      }}
                      src={arrowDown}
                      className="icon"
                    />
                  </div>
                </div>
                <div
                  className={`col-12 d-flex justify-content-between fs-13px ${AcivityWorkSpaceStyle.view_copy_link} ${AcivityWorkSpaceStyle.room_dropdown_header} ${AcivityWorkSpaceStyle.activity_menu_devider}`}
                >
                  <div>
                    <span className="font-weight-bold">Select room : </span>
                    <span> Select room if there multiple workspaces under the same name</span>
                  </div>
                  <div
                    onClick={(e) => {
                      setShowDropdown(false)
                      setDropdownState(3)
                    }}
                    className="font-weight-bold pointer"
                  >
                    <span className="underline mr-1">I don’t want to add room</span> <Image src={arrowRight} alt="arrow-right" />
                  </div>
                </div>
                <div className={`col-12 d-inline-flex py-1 ${AcivityWorkSpaceStyle.activity_menu_devider}`}>
                  <Image src={SearchIcon} alt={`search button`} className={`my-auto ${AcivityWorkSpaceStyle.input_field_icon}`} />
                  <input
                    autoComplete="off"
                    type="text"
                    name="name"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onChange={handleRoomSearch}
                    placeholder={"Search Workspace room (Min 3 characters)"}
                    className={`${AcivityWorkSpaceStyle.activity_search_input} form-control border-0`}
                  />
                  {loader.search && <Loader />}
                </div>
                <>
                  {roomList.map((mural) => {
                    let activity_data = activityWorkspaceData.activity_workspace
                    let link = `${config.baseURL}/t/${activity_data?.id}/r/${mural?.id}`
                    let name = `${mural.name}`
                    return (
                      <div
                        onClick={(e) => {
                          e.stopPropagation()
                          workspaceClickHandler(activity_data.id, activity_data.name, activity_data.link, { id: mural?.id, name, link })
                        }}
                        className={`col-12 d-inline-flex pointer d-flex align-items-center ${AcivityWorkSpaceStyle.activity_menu_devider} ${AcivityWorkSpaceStyle.activity_menu_item}`}
                        key={mural?.id}
                      >
                        <div className="flex-shrink-0">
                          <Image src={muralIcon} alt={`Activity Workspace Icon`} className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`} />
                        </div>
                        <div className={`mx-2 d-flex flex-column ellipsis`}>
                          <div className={`font-weight-bold ellipsis`}>{name}</div>
                          <div className={`ellipsis`}>{link}</div>
                        </div>
                        <CopyLink text={link} />
                      </div>
                    )
                  })}
                  {roomNext && (
                    <div
                      className={`link p-2 bg-42506C`}
                      onClick={() => {
                        handleSeeMoreClick(true)
                      }}
                    >
                      See more
                    </div>
                  )}
                </>
              </>
            )}
            {dropdownState === 2 && (
              <>
                <div className={`col-12 d-inline-flex py-2 ${AcivityWorkSpaceStyle.menu_item} ${AcivityWorkSpaceStyle.activity_menu_devider} `}>
                  <div className="flex-shrink-0">
                    <Image src={muralIcon} alt={`Activity Workspace Icon`} className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`} />
                  </div>
                  <div className={`d-flex flex-column align-items-center justify-content-between mx-2`}>
                    <div className={`${AcivityWorkSpaceStyle.grey_circle}`}></div>
                    <div className={`${AcivityWorkSpaceStyle.vertical_line} flex-grow-1`}></div>
                    <div className={`${AcivityWorkSpaceStyle.grey_circle}`}></div>
                  </div>
                  <div className="d-flex flex-column flex-grow-1">
                    <div className={`d-flex mb-1`}>
                      <div className={`font-weight-bold mr-2 flex-shrink-0`}>{activityWorkspaceData?.activity_workspace?.name} :</div>
                      <div className={`ellipsis ${AcivityWorkSpaceStyle.max_width_420px}`}>{activityWorkspaceData?.activity_workspace?.link}</div>
                      <CopyLink text={activityWorkspaceData?.activity_workspace?.link} />
                    </div>
                    <div className={`d-flex`}>
                      <div className={`font-weight-bold mr-2 underline flex-shrink-0`}>{activityWorkspaceData?.activity_workspace?.room?.name}</div>
                      <div className={`d-flex align-items-center`}>
                        <Image
                          alt="edit"
                          src={editSvg}
                          onClick={handleEditMuralRoomClick}
                          className={`mb-0 ${AcivityWorkSpaceStyle.activity_workspace_edit_icon}`}
                        />
                      </div>
                      <div className={`ellipsis ${AcivityWorkSpaceStyle.max_width_420px}`}>{activityWorkspaceData?.activity_workspace?.room?.link}</div>
                      <CopyLink text={activityWorkspaceData?.activity_workspace?.room?.link} />
                    </div>
                  </div>
                </div>
                <div
                  className={`col-12 d-inline-flex justify-content-between ${AcivityWorkSpaceStyle.activity_menu_item} ${AcivityWorkSpaceStyle.activity_last_menu}`}
                >
                  <div
                    className={`pointer ${AcivityWorkSpaceStyle.activity_workspace_title}`}
                    onClick={() => {
                      updateCertificateAssessment(
                        activityWorkspaceData?.activity_workspace?.link,
                        activityWorkspaceData?.activity_workspace?.id,
                        activityWorkspaceData?.activity_workspace?.name,
                        "other",
                        false,
                        false
                      )
                    }}
                  >
                    Remove Link
                  </div>
                  <div
                    onClick={() => {
                      setDropdownState(5)
                      getRoomMuralData(activityWorkspaceData?.activity_workspace.room.id)
                    }}
                    className={`link`}
                  >
                    + Add Mural
                  </div>
                </div>
              </>
            )}
            {dropdownState === 3 && (
              <>
                <div
                  className={`col-12 d-inline-flex ${AcivityWorkSpaceStyle.activity_menu_item} ${AcivityWorkSpaceStyle.selected_activity_workspace} ${AcivityWorkSpaceStyle.activity_menu_devider}`}
                >
                  <Image
                    src={
                      activityWorkspaceData?.activity_workspace?.link?.includes("app.mural") || activityWorkspaceData?.workspace_link?.includes("app.mural")
                        ? muralIcon
                        : linkIcon
                    }
                    alt={`Activity Workspace Icon`}
                    className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`}
                  />
                  <div className={`ml-2`}>{`${activityWorkspaceData?.activity_workspace?.name} WorkSpace`}</div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    className={`ml-2 border-0 ellipsis`}
                  >
                    {activityWorkspaceData?.workspace_link ? activityWorkspaceData?.workspace_link : activityWorkspaceData?.activity_workspace?.link}
                  </div>
                  <CopyLink text={activityWorkspaceData?.activity_workspace?.link} />
                </div>
                <div
                  className={`col-12 d-flex justify-content-between ${AcivityWorkSpaceStyle.activity_menu_item} ${AcivityWorkSpaceStyle.activity_last_menu}`}
                >
                  <div
                    className={`pointer ${AcivityWorkSpaceStyle.activity_workspace_title}`}
                    onClick={() => {
                      updateCertificateAssessment(null, null, null, null, false, false)
                    }}
                  >
                    Remove Link {loader.update && <Loader />}
                  </div>
                  <div onClick={handleEditMuralRoomClick} className={`link`}>
                    + Add Room
                  </div>
                </div>
              </>
            )}
            {[4, 5].includes(dropdownState) && (
              <>
                <div className={`col-12 d-inline-flex py-2 ${AcivityWorkSpaceStyle.menu_item} ${AcivityWorkSpaceStyle.activity_menu_devider} `}>
                  <div className="flex-shrink-0">
                    <Image src={muralIcon} alt={`Activity Workspace Icon`} className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`} />
                  </div>
                  <div className={`d-flex flex-column align-items-center justify-content-between mx-2`}>
                    <div className={`${AcivityWorkSpaceStyle.grey_circle}`}></div>
                    <div className={`${AcivityWorkSpaceStyle.vertical_line} flex-grow-1`}></div>
                    <div className={`${AcivityWorkSpaceStyle.grey_circle}`}></div>
                  </div>
                  <div className="d-flex flex-column flex-grow-1">
                    <div className={`d-flex mb-1`}>
                      <div className={`font-weight-bold mr-2 flex-shrink-0`}>{activityWorkspaceData?.activity_workspace?.name} :</div>
                      <div className={`ellipsis ${AcivityWorkSpaceStyle.max_width_420px}`}>{activityWorkspaceData?.activity_workspace?.link}</div>
                      <CopyLink text={activityWorkspaceData?.activity_workspace?.link} />
                    </div>

                    <div className={`d-flex`}>
                      <div className={`font-weight-bold mr-2 underline flex-shrink-0`}>{activityWorkspaceData?.activity_workspace?.room?.name}</div>
                      <div className={`d-flex align-items-center`}>
                        <Image
                          alt="edit"
                          src={editSvg}
                          onClick={handleEditMuralRoomClick}
                          className={`mb-0 ${AcivityWorkSpaceStyle.activity_workspace_edit_icon}`}
                        />
                      </div>
                      <div className={`ellipsis ${AcivityWorkSpaceStyle.max_width_420px}`}>{activityWorkspaceData?.activity_workspace?.room?.link}</div>
                      <CopyLink text={activityWorkspaceData?.activity_workspace?.room?.link} />
                    </div>

                    {dropdownState === 4 && (
                      <div className={`d-flex`}>
                        <div className={`font-weight-bold mr-2 underline flex-shrink-0`}>{activityWorkspaceData?.activity_workspace?.mural?.name}</div>
                        <div className={`d-flex align-items-center`}>
                          <Image
                            alt="edit"
                            src={editSvg}
                            onClick={() => {
                              setDropdownState(5)
                              getRoomMuralData(activityWorkspaceData?.activity_workspace?.room?.id)
                            }}
                            className={`mb-0 ${AcivityWorkSpaceStyle.activity_workspace_edit_icon}`}
                          />
                        </div>
                        <div className={`ellipsis ${AcivityWorkSpaceStyle.max_width_420px}`}>{activityWorkspaceData?.activity_workspace?.mural?.link}</div>
                        <CopyLink text={activityWorkspaceData?.activity_workspace?.mural?.link} />
                      </div>
                    )}
                  </div>
                </div>
                {dropdownState === 4 && (
                  <div className={`col-12 d-inline-flex ${AcivityWorkSpaceStyle.activity_menu_item} ${AcivityWorkSpaceStyle.activity_last_menu}`}>
                    <span
                      className={`pointer ${AcivityWorkSpaceStyle.activity_workspace_title}`}
                      onClick={() => {
                        updateCertificateAssessment(
                          activityWorkspaceData.activity_workspace.link,
                          activityWorkspaceData.activity_workspace.id,
                          activityWorkspaceData.activity_workspace.name,
                          "mural_room_workspace",
                          {
                            link: activityWorkspaceData.activity_workspace.room.link,
                            id: activityWorkspaceData.activity_workspace.room.id,
                            name: activityWorkspaceData.activity_workspace.room.name,
                          },
                          false
                        )
                      }}
                    >
                      Remove Link
                    </span>
                  </div>
                )}
                {dropdownState === 5 && (
                  <>
                    <div className={`col-12 d-inline-flex py-1 ${AcivityWorkSpaceStyle.activity_menu_devider}`}>
                      <Image src={SearchIcon} alt={`search button`} className={`my-auto ${AcivityWorkSpaceStyle.input_field_icon}`} />
                      <input
                        autoComplete="off"
                        type="text"
                        name="name"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        onChange={handleSearchMural}
                        placeholder={"Search Mural"}
                        className={`${AcivityWorkSpaceStyle.activity_search_input} form-control border-0`}
                      />
                    </div>
                    {(searchMuralText ? searchMuralList : muralList).map((mural, index) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation()
                            workspaceClickHandler(mural.id, mural.title ? mural.title : "Assesment Mural's", mural._canvasLink, false, true)
                          }}
                          className={`col-12 d-inline-flex pointer d-flex align-items-center ${
                            index !== workspaceList.length - 1 && AcivityWorkSpaceStyle.activity_menu_devider
                          } ${AcivityWorkSpaceStyle.activity_menu_item}`}
                          key={mural?.id}
                        >
                          <div className="flex-shrink-0">
                            <Image
                              src={muralIcon}
                              alt={`Activity Workspace Icon`}
                              className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`}
                            />
                          </div>
                          <div className={`mx-2 d-flex flex-column ellipsis`}>
                            <div className={`font-weight-bold ellipsis`}>{mural.title ? mural.title : "Assesment Mural's"}</div>
                            <div className={`ellipsis`}>{mural._canvasLink}</div>
                          </div>
                          <CopyLink text={mural._canvasLink} />
                        </div>
                      )
                    })}
                    {muralNextLink && (
                      <div className={`underline pointer p-2`} onClick={handleSeeMoreClick}>
                        See more
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {(loader.room || loader.workspace) && (
              <div className={`w-100 py-2 text-center`}>
                <Loader />
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <AppIntegrationModal />
    </React.Fragment>
  )
}

AssesmentWorkSpace.propTypes = {
  activity_workspace: PropTypes.object.isRequired,
  setCourseTemplate: PropTypes.func,
}

export default AssesmentWorkSpace
