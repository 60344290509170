import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Pagination from "react-bootstrap/Pagination"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Modal from "react-bootstrap/Modal"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Layout from "../layout"
import SEO from "../seo"
import authClient from "../../services/auth"
// import hideMessage from '../../services/hidemessage'
import TrainerProfilePic from "../../images/svgs/trainer-profile-pic.svg"

import blog from "../../images/svgs/blog.svg"
import TrashCan from "../../images/svgs/trash-can-transparent.svg"
import CancelWhite from "../../images/svgs/close.svg"

import StylesD from "../../modularscss/delete.module.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faChevronLeft, faTrash } from "@fortawesome/free-solid-svg-icons"

import Styles from "../../modularscss/styles.module.scss"

import Notification from "../custom/Notification"
import DeleteModal from "../custom/DeleteModal"
import { isBrowser } from "../../services/developer_mode"

const TrainersList = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [trainer_loader, setTrainerLoader] = useState(false)
  const [trainers, setTrainers] = useState([])
  const [previous_url, setPreviousUrl] = useState()
  const [next_url, setNextUrl] = useState()
  const [total_pages, setTotalPages] = useState()

  const [deleteObjectModal, setDeleteObjectModal] = useState([])
  const [notificationData, setNotificationData] = useState(null)

  const handleDeleteObjectModal = () => {
    setDeleteObjectModal([])
  }

  useEffect(() => {
    fetchTrainers()
  }, [])

  async function fetchTrainers() {
    setTrainerLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/trainers/list/all/`, {
        params: {
          company: localStorage.getItem("company_id"),
        },
        headers: login_credentials,
      })
      .then((res) => {
        console.log(res.data.results)
        setTrainers(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 8))
        setTrainerLoader(true)
      })
      .catch((error) => {
        let error_messages = "",
          error_key = ""
        for (let key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
          error_key = key
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function fetchPage(page) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/trainers/list/all/?page=" + page, { headers: DefaultHeader })
      .then((res) => {
        setTrainers(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 8))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function fetchNext() {
    axios
      .get(next_url, { headers: DefaultHeader })
      .then((res) => {
        setTrainers(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 8))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }
  function fetchPrevious() {
    axios
      .get(previous_url, { headers: DefaultHeader })
      .then((res) => {
        setTrainers(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 8))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  const renderPageLinks = () => {
    var rows = []
    if (previous_url) {
      rows.push(<Pagination.Prev className={`m-0 bg-transparent`} onClick={() => fetchPrevious()} />)
    }
    for (var i = 0; i < total_pages; i++) {
      let temp_var = i + 1
      rows.push(
        <Pagination.Item key={i + 1} className={`m-0 bg-transparent`} onClick={() => fetchPage(temp_var)}>
          {i + 1}
        </Pagination.Item>
      )
    }
    if (next_url) {
      rows.push(<Pagination.Next className={`m-0 bg-transparent`} onClick={() => fetchNext()} />)
    }
    return (
      <Pagination className={`bg-card mx-0 my-3`} style={{ width: `fit-content` }}>
        {rows}
      </Pagination>
    )
  }

  function showTrainers() {
    if (authClient.isAuthenticated()) {
      return displayTrainerData()
    } else {
      return ""
    }
  }
  const displayTrainerData = () => {
    if (trainer_loader) {
      if (trainers.length > 0) {
        let parsed_data = []
        parsed_data.push(
          <div key={`0-table`} className={`d-flex p-3`}>
            <div className={`pl-4 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Trainer
              </p>
            </div>
            <div className={`pl-3 ml-auto mr-3 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Avatar
              </p>
            </div>
          </div>
        )
        trainers.map((datum, index) => {
          parsed_data.push(
            <div className={`d-flex mb-1 py-2`} key={index + `_training`} style={{ backgroundColor: `#46537E` }}>
              <div className={`pl-3 align-self-center`}>
                <img src={TrainerProfilePic} alt={"Trainer profile pic"} className={`m-auto`} style={{ objectFit: `contain`, width: `24px` }} />
              </div>
              <div className={`pl-3 align-self-center`}>
                <p className={`m-0 font-weight-bold text-white`}>{datum.name}</p>
                <Col sm="12" md="12" lg="12" className="p-0 d-inline-flex">
                  <Link className={`mr-2`} to={isBrowser() ? `/app/companies/${localStorage.getItem("company_id")}/trainers/edit/${datum.id}` : ""}>
                    {" "}
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                  </Link>
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Delete Trainer</Tooltip>}>
                    <div
                      className={`mr-3 pointer`}
                      onClick={(e) => {
                        e.preventDefault()
                        setDeleteObjectModal(datum)
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faTrash} style={{ color: `#acacac` }} size="sm" />{" "}
                    </div>
                  </OverlayTrigger>
                </Col>
              </div>
              <div className={`pl-3 align-self-center ml-auto mr-3`}>
                {datum.avatar === null || datum.avatar === "" ? null : (
                  <LazyLoadImage
                    src={`${process.env.GATSBY_REACT_APP_IMAGE_API}${datum.avatar}`}
                    alt={datum.name}
                    className="mb-0"
                    width="100"
                    height="100"
                    style={{ borderRadius: "50%" }}
                  />
                )}
              </div>
            </div>
          )
        })
        return <div>{parsed_data}</div>
      } else {
        return (
          <div id="loader" className="float-left w-100">
            <div colSpan="12" className="animated fadeIn pt-3 text-center float-left w-100">
              No Data found...
            </div>
          </div>
        )
      }
    } else {
      return (
        <div id="loader" className="float-left w-100">
          <div colSpan="12" className="animated fadeIn pt-3 text-center float-left w-100">
            Loading...
          </div>
        </div>
      )
    }
  }

  function deleteData() {
    var index = trainers.findIndex((res) => res.id === deleteObjectModal.id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/trainers/" + deleteObjectModal.id + "/", { headers: DefaultHeader, body: deleteObjectModal })
      .then((res) => {
        trainers.splice(index, 1)
        setDeleteObjectModal([])
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  // function showDelModal(datum) {
  //   return (
  //     <Modal show={Object.keys(deleteObjectModal).length > 0} onHide={handleDeleteObjectModal} centered className={`${StylesD.delete_modal} classroom-resource-delete-modal`} >
  //       <div className={`${StylesD.delete_modal_header}`}>
  //       <img onClick={handleDeleteObjectModal} src={CancelWhite} alt={`Cancel`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, height: `20px`,position:'absolute',top:'10px',right:'5px' }}/>
  //         <img src={TrashCan} alt={`delete modal delete icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `20px` }}/>
  //         <div>
  //          <p style={{fontWeight:'bold',fontSize:'15px'}}>Delete</p>
  //         </div>
  //       </div>
  //       <Modal.Body className="text-center" style={{backgroundColor:'#121b2b',borderRadius: '0px 0px 8px 8px'}} >
  //         <p style={{color: '#E0E2E5',fontSize:'15px',marginBottom:'10px'}} > Are you sure to delete ? </p>
  //         <p style={{color: '#E0E2E5', fontWeight:'bold',fontSize:'17px'}}>{deleteObjectModal && deleteObjectModal.name ? deleteObjectModal.name : 'Class'}</p>
  //         <div className="d-flex justify-content-center" >
  //           <Button className={`${StylesD.add_button_large} border_on_hover mr-3`} variant="secondary" onClick={() => {deleteData(deleteObjectModal)}} style={{width:'75px',border:'1px solid transparent'}} >Yes</Button>
  //           <Button className={`${StylesD.add_button_large} border_on_hover`} variant="secondary" onClick={handleDeleteObjectModal} style={{width:'75px',border:'1px solid transparent'}} >No</Button>
  //         </div>
  //       </Modal.Body>
  //     </Modal>
  //   )
  // }

  return (
    <div>
      <SEO title="Turient Trainer" />
      <Col lg="12" md="12" sm="12" xs="12">
        <h4 className={`mt-3`}>
          <Link className={`pointer text-white mr-2`} to={`/app/dashboard`}>
            {" "}
            <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
          </Link>
          <span>All Trainers</span>
          <Button
            onClick={() => {
              navigate(isBrowser() ? `/app/companies/${localStorage.getItem("company_id")}/trainers/create` : "")
            }}
            style={{ background: "#485b9e", border: "none", fontSize: "14px", float: "right" }}
          >
            + Add Trainers
          </Button>
        </h4>
        {renderPageLinks()}
        <div className={`bg-card ${Styles.b_radius_15} mb-5`} style={{ overflow: "scroll" }}>
          {showTrainers()}
        </div>
      </Col>
      <DeleteModal
        show={Object.keys(deleteObjectModal).length > 0}
        onHide={handleDeleteObjectModal}
        itemName={deleteObjectModal?.name}
        action={() => {
          deleteData(deleteObjectModal)
        }}
      />
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default TrainersList
