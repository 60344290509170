import React, { useState } from "react"

// import { ResponsiveBar } from "@nivo/bar"

import Loader from "../custom/Loader"
import ToolTipForBarGraph from "./barGraphToolTip"

import { THEME, PERCENTAGE_COLOR_CODES } from "../insights/constants"

const ScatterCircle = ({ bars }) => {
  let filtredData = bars.filter((bar) => bar.key.includes(bars[bars.length - 1]?.data?.id))
  const groupedArrays = bars.reduce((result, item) => {
    const indexValue = item.data.indexValue

    if (!result[indexValue]) {
      result[indexValue] = []
    }

    result[indexValue].push(item)

    return result
  }, {})

  const yValues = Object.values(groupedArrays).map((sessionArray) => Math.min(...sessionArray.map((item) => item.y)))

  return (
    <>
      {filtredData.map((bar, index) => {
        let totalEngagementPercentage = 0
        const keys = Object.keys(bar.data?.data)
        for (const key of keys) {
          if (typeof bar.data?.data[key] === "number") {
            totalEngagementPercentage += bar.data?.data[key]
          }
        }

        totalEngagementPercentage = (totalEngagementPercentage + "").length > 3 ? totalEngagementPercentage.toFixed(2) : totalEngagementPercentage
        return (
          <svg key={`bar_${index}`}>
            <g>
              <rect x={bar.x} y={yValues[index] - 30} width={bar.width} height="25" fill={PERCENTAGE_COLOR_CODES[index % 3]}></rect>
              <title>
                {bar?.data?.data?.name}: {totalEngagementPercentage}%
              </title>
              <text //This x value calculation is for aligining the % text center - (100% or 90.34% or 20%)
                x={
                  (totalEngagementPercentage + "").length === 1
                    ? bar.width / 2 + bar.x - 7
                    : (totalEngagementPercentage + "").length > 3
                    ? bar.width / 2 + bar.x - 20
                    : bar.width / 2 + bar.x - 13
                }
                y={yValues[index] - 13}
                fontSize="11"
                fill="#E0E2E5"
              >
                {totalEngagementPercentage}%
              </text>
            </g>
          </svg>
        )
      })}
    </>
  )
}

const MultiColorBarGraph = (props) => {
  const [graphData, setGraphData] = useState([])
  const [fetchedGraphData, setFetchedGraphData] = useState(true)

  var graphSectionContent = null
  if (fetchedGraphData) {
    if (props.graphData?.graphs?.length > 0) {
      graphSectionContent = 0
      // graphSectionContent = (
      //   <ResponsiveBar
      //     data={props.graphData?.graphs}
      //     keys={[...props.graphData?.labels]}
      //     indexBy="name"
      //     margin={{ top: 20, right: 80, bottom: 30, left: 0 }}
      //     width={
      //       // This width  calculation is for making responisve width for the graphs based on the data
      //       props.graphData?.graphs?.length < 4
      //         ? window.innerWidth / 4
      //         : (props.graphData?.graphs?.length / 4 < 1 ? 1 : props.graphData?.graphs?.length / 3.5) *
      //           (window.innerWidth < 1200 ? window.innerWidth / 2.5 : window.innerWidth / 3.2)
      //     }
      //     padding={0.5}
      //     maxValue={120}
      //     minValue={0}
      //     colors={["#986CA4", "#97B597", "#4B9B8C", "#3BAFD9"]}
      //     label={() => ""}
      //     axisRight={null}
      //     axisBottom={{
      //       format: (v) => {
      //         return v.length > 10 ? v.substring(0, 10) + "..." : v
      //       },
      //       tickSize: 0,
      //       tickPadding: 10,
      //       tickRotation: 0,
      //     }}
      //     axisLeft={null}
      //     legends={[
      //       {
      //         dataFrom: "keys",
      //         anchor: "top-right",
      //         direction: "column",
      //         justify: false,
      //         translateX: 75,
      //         translateY: 0,
      //         itemWidth: 100,
      //         itemHeight: 20,
      //         itemsSpacing: 2,
      //         symbolSize: 10,
      //         itemDirection: "left-to-right",
      //       },
      //     ]}
      //     ariaLabel={props.title}
      //     theme={THEME}
      //     layers={["grid", "axes", "bars", "legends", ScatterCircle]}
      //     tooltip={(bar) => <ToolTipForBarGraph bar={bar} type={`multi-color`} />}
      //   />
      // )
    } else {
      graphSectionContent = (
        <div className="w-100 d-flex h-100 justify-center align-items-center">
          <p className="text-white mx-auto">Data not available</p>
        </div>
      )
    }
  }

  return (
    <div className="d-flex w-100 h-100 bg-19253B br-9px overflow-hidden">
      <div className="d-flex w-100 h-32vh">
        {props.graphData ? (
          <>
            <div className="w-57px">
              {/* {props.graphData?.graphs?.length > 0 && (
                <ResponsiveBar
                  data={props.graphData?.graphs}
                  keys={props.graphData?.labels}
                  indexBy="name"
                  margin={{ top: 20, right: 10, bottom: 30, left: 50 }}
                  padding={0.4}
                  maxValue={120}
                  width={50}
                  minValue={0}
                  axisRight={null}
                  axisBottom={null}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: props.graphData?.yAxisLegend,
                    legendPosition: "middle",
                    legendOffset: -40,
                    tickValues: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                  }}
                  theme={THEME}
                  layers={["axes"]}
                />
              )} */}
            </div>
            <div className="d-flex overflow-x-scroll w-100 h-100">
              {!fetchedGraphData && (
                <div className="w-100 d-flex h-100 justify-center align-items-center">
                  <Loader class={`text-white mx-auto`} />
                </div>
              )}
              {graphSectionContent}
            </div>
          </>
        ) : (
          <div className="w-100 d-flex h-100 justify-center align-items-center">
            <Loader class={`text-white mx-auto`} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MultiColorBarGraph
