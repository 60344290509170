import React, { useEffect, useState } from "react"
import { OverlayTrigger } from "react-bootstrap"
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"
import { roundNumber } from "../../../utils/utility"

import Style from "./style.module.scss"

const PercentageProgressBar = ({ type, value, data }) => {
  const [scoreBreakdown, setScoreBreakdown] = useState([
    {
      colorCode: "#986CA4",
      label: "Academic",
      percentage: 0,
    },
    {
      colorCode: "#97B597",
      label: "Cognitive",
      percentage: 0,
    },
    {
      colorCode: "#4B9B8C",
      label: "Behavioral",
      percentage: 0,
    },
    {
      colorCode: "#3BAFD9",
      label: "Emotional",
      percentage: 0,
    },
  ])

  const percentage = type === "student-list" ? value : data?.engagement_score || 0

  useEffect(() => {
    if (data) {
      let scoreData = [...scoreBreakdown]
      scoreData[0].percentage = data?.academic_engagement
      scoreData[1].percentage = data?.cognitive_engagement
      scoreData[2].percentage = data?.behavioural_engagement
      scoreData[3].percentage = data?.emotional_engagement
      setScoreBreakdown(scoreData)
    }
  }, [data])

  return (
    <OverlayTrigger
      key={`engagementscore-breakdown`}
      placement="bottom"
      overlay={
        <div
          className={`${Style.engagement_score_breakdown} bg-303C54 ${
            percentage > 0 && type !== "student-list" && type !== "topics-list" ? `d-flex` : `d-none`
          } flex-column`}
        >
          <div className="d-flex align-items-center px-2 pt-2">
            <div className={`${Style.progress_container} m-0 pr-2`}>
              <CircularProgressbarWithChildren
                value={roundNumber(percentage || 0)}
                styles={buildStyles({
                  textColor: "#000",
                  pathColor: "#E0E2E5",
                  trailColor: `rgba(88, 104, 134, 1)`,
                  border: "2px solid",
                })}
                strokeWidth={5}
                counterClockwise={false}
              >
                <div className="d-flex align-items-center">
                  <span className="fs-8px text-white">{roundNumber(percentage || 0)}%</span>
                </div>
              </CircularProgressbarWithChildren>
            </div>
            <p className="text-E0E2E5 fs-8px mb-0 pb-2 bold-500">Engagement Score</p>
          </div>
          <div className="d-flex flex-column">
            {scoreBreakdown &&
              scoreBreakdown?.map((engagement_score_obj, index) => {
                return (
                  <div key={`${engagement_score_obj.label}-score`} className={`d-flex flex-column ${index !== scoreBreakdown.length - 1 && "mb-1"}`}>
                    <div
                      className={`w-100 d-flex align-items-center text-E0E2E5 py-2 bg-1B2841 position-relative z-index-2 border-1B2841 ${
                        index === scoreBreakdown.length - 1 && Style.enagagement_last_element
                      }`}
                    >
                      <p className="fs-8px mb-0 px-2 z-index-3">{engagement_score_obj.label}</p>
                      <div className="d-flex align-items-center ml-auto z-index-3 pr-2">
                        <p className="fs-8px mb-0 pr-2">{engagement_score_obj?.percentage || 0}%</p>
                      </div>
                      <div
                        className="position-absolute z-index-1 h-100"
                        style={{
                          width: `${engagement_score_obj?.percentage || 0}%`,
                          backgroundColor: engagement_score_obj?.colorCode,
                          borderRadius: index === scoreBreakdown.length - 1 ? `0px 0px 0px 9px` : "",
                        }}
                      ></div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      }
    >
      <div className="w-100 h-100 d-flex flex-column justify-content-center">
        <div className="mb-0 fs-12px text-center bg-1699A2 mb-1">{percentage + "%"}</div>
        <div className="w-100 h-10 bg-475269 ">
          <div className="h-100 bg-1699A2" style={{ width: `${percentage}%` }}></div>
        </div>
      </div>
    </OverlayTrigger>
  )
}

export default PercentageProgressBar
