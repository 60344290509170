import React, { useContext, useEffect, useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import Image from "../custom/Image"
import Loader from "../custom/Loader"
import { getRequest } from "../../services/rest_service"
import HorizontalScroll from "../custom/HorizontalScroll"

import down_arrow_white from "../../images/svgs/down_arrow_white.svg"
import Avatar from "../../images/svgs/trainer-profile-pic.svg"
import LongRightArrow from "../../images/svgs/long-right-arrow.svg"

import { InsightsReportsContext } from "../../stores/insights_reports/InsightsReportsStore"

import Style from "./style.module.scss"
import {
  SET_ACTIVE_CLASS_DATA,
  SET_ACTIVE_COURSE_DATA,
  SET_ACTIVE_EDUCATOR_DATA,
  SET_ACTIVE_REPORT_TAB,
  SET_ALL_CLASSES_DATA,
  SET_ALL_COURSES_DATA,
} from "../../stores/insights_reports/InsightsReportsActions"
import InClassOrOverallChoice from "./InClassOrOverall"

function ClassLevelReportDropdowns() {
  const [state, dispatch] = useContext(InsightsReportsContext)
  const [showLoader, setShowLoader] = useState(false)
  const [showClassLoader, setShowClassLoader] = useState(false)
  const [showTrainerLoader, setShowTrainerLoader] = useState(false)

  useEffect(() => {
    if (state.activeClassData?.id && !state.activeClassData?.trainersList) {
      fetchClassTrainers()
    }
  }, [state.activeClassData?.id, state.activeClassData?.trainersList])

  const fetchClassTrainers = async () => {
    setShowTrainerLoader(true)
    let res = await getRequest(`/trainers/list/all/?type=all&company=${localStorage.getItem("company_id")}&training=${state.activeClassData.id}`)
    if (res.success) {
      let classCopy = { ...state.activeClassData }
      classCopy.trainersList = [...res.data]
      dispatch({ type: SET_ACTIVE_CLASS_DATA, payload: classCopy })
      let allClassesCopy = { ...state.allClassesData[`course_${state.activeCourseData.id}`] }
      let index = allClassesCopy.results.findIndex((data) => data.id === state.activeClassData.id)
      allClassesCopy.results[index] = classCopy
      dispatch({ type: SET_ALL_CLASSES_DATA, payload: { key: `course_${state.activeCourseData.id}`, value: allClassesCopy } })
      setShowTrainerLoader(false)
    } else {
      console.error(res.data?.msg)
    }
  }

  async function handlefetchMoreCourses(e) {
    e.stopPropagation()
    let div = e.target

    if (div.scrollTop + div.clientHeight >= div.scrollHeight - 20 && state.allCoursesData?.next && !showLoader) {
      const response = await getRequest(state.allCoursesData?.next)
      if (response.success) {
        setShowLoader(false)
        let coursesCopy = { ...state.allCoursesData }
        coursesCopy.results = [...coursesCopy.results, ...response.data.results]
        coursesCopy.next = response.data.next
        dispatch({
          type: SET_ALL_COURSES_DATA,
          payload: coursesCopy,
        })
      }
    }
  }

  async function handlefetchMoreCourses(e) {
    e.stopPropagation()
    let div = e.target

    if (div.scrollTop + div.clientHeight >= div.scrollHeight - 20 && state.allCoursesData?.next && !showLoader) {
      setShowLoader(true)
      const response = await getRequest(state.allCoursesData?.next)
      if (response.success) {
        setShowLoader(false)
        let coursesCopy = { ...state.allCoursesData }
        coursesCopy.results = [...state.allCoursesData.results, ...response.data.results]
        coursesCopy.next = response.data.next
        dispatch({
          type: SET_ALL_COURSES_DATA,
          payload: coursesCopy,
        })
      }
    }
  }

  async function handlefetchMoreClasses(e) {
    e.stopPropagation()
    let div = e.target

    if (div.scrollTop + div.clientHeight >= div.scrollHeight - 20 && state.allClassesData[`course_${state.activeCourseData.id}`]?.next && !showClassLoader) {
      setShowClassLoader(true)
      const response = await getRequest(state.allClassesData[`course_${state.activeCourseData.id}`]?.next)
      if (response.success) {
        setShowClassLoader(false)
        let classesCopy = { ...state.allClassesData }
        classesCopy[`course_${state.activeCourseData.id}`].results = [...classesCopy[`course_${state.activeCourseData.id}`].results, ...response.data.results]
        classesCopy[`course_${state.activeCourseData.id}`].next = response.data.next
        dispatch({
          type: SET_ALL_CLASSES_DATA,
          payload: { key: `course_${state.activeCourseData.id}`, value: classesCopy[`course_${state.activeCourseData.id}`] },
        })
      }
    }
  }

  return (
    <div className="d-flex align-items-center w-100 pr-2">
      <Dropdown className={`mr-3`}>
        <Dropdown.Toggle className={`bg-303C54 border-0 pr-3 caret-none d-flex align-items-center ${Style.primaryDropdownToggle}`}>
          {state.fetchedCoursesData ? (
            <>
              <p className="mb-0 mr-auto d-inline w-100 ellipsis pr-2 fs-14px" title={state.activeCourseData?.name}>
                {state.activeCourseData?.name}
              </p>
              <Image className={`ml-auto pointer icon}`} src={down_arrow_white} />
            </>
          ) : (
            <Loader />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${Style.primaryDropdownMenu}`} onScroll={handlefetchMoreCourses}>
          {state.allCoursesData?.results &&
            state.allCoursesData.results.map((course, index) => {
              return (
                <Dropdown.Item
                  key={`course-${course.id}`}
                  onClick={() => {
                    if (state.activeCourseData.id !== course.id) {
                      dispatch({ type: SET_ACTIVE_COURSE_DATA, payload: course })
                    }
                  }}
                  className={`${Style.dropdownItem} fs-14px`}
                >
                  {course.name}
                </Dropdown.Item>
              )
            })}
          {showLoader && (
            <div className="w-100 text-E0E2E5 text-center">
              <Loader />
            </div>
          )}
          {state.allCoursesData?.results?.length === 0 && <p className="fs-14px bold-500 w-100 text-center text-E0E2E5 pt-3 pb-2 mx-auto ">No Courses Found</p>}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className={`mr-3`}>
        <Dropdown.Toggle className={`bg-303C54 border-0 pr-3 caret-none d-flex align-items-center ${Style.primaryDropdownToggle}`}>
          {state.fetchedClassesData && state.activeClassData?.name ? (
            <>
              <p className="mb-0 mr-auto d-inline w-100 ellipsis pr-2 fs-14px" title={state.activeClassData?.name}>
                {state.activeClassData?.name}
              </p>
              <Image className={`ml-auto pointer icon}`} src={down_arrow_white} />
            </>
          ) : state.fetchedClassesData && Object.keys(state.activeClassData).length === 0 ? (
            <p className="fs-14px bold-500 mb-0 w-100 text-center text-E0E2E5 mx-auto">No Classes Found</p>
          ) : (
            <Loader />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${Style.primaryDropdownMenu}`} onScroll={handlefetchMoreClasses}>
          {state.allClassesData[`course_${state.activeCourseData?.id}`]?.results &&
            state.allClassesData[`course_${state.activeCourseData?.id}`]?.results.map((classData, index) => {
              return (
                <Dropdown.Item
                  key={`class-${classData.id}`}
                  onClick={() => {
                    if (state.activeClassData.id !== classData.id) {
                      dispatch({ type: SET_ACTIVE_CLASS_DATA, payload: classData })
                    }
                  }}
                  className={`${Style.dropdownItem} fs-14px`}
                >
                  {classData.name}
                </Dropdown.Item>
              )
            })}
          {showClassLoader && (
            <div className="w-100 text-E0E2E5 text-center">
              <Loader />
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <InClassOrOverallChoice />
      {state.activeClassData?.trainersList?.length > 0 && (
        <div className={`d-flex align-items-center flex-grow-1 overflow-x-scroll`}>
          <HorizontalScroll className={`d-inline-flex align-items-center w-100`} scrollId={`trainers-list`}>
            {showTrainerLoader || !state.activeClassData?.trainersList ? (
              <div className="w-100 text-E0E2E5 text-center">
                <Loader />
              </div>
            ) : (
              state.activeClassData.trainersList.map((trainer, index) => {
                return (
                  <div
                    className={`d-flex align-items-center pointer bg-303C54 br-10px mr-2 ${Style.trainerInfo}`}
                    key={`trainer_${trainer.id}`}
                    onClick={() => {
                      dispatch({ type: SET_ACTIVE_EDUCATOR_DATA, payload: trainer })
                      dispatch({ type: SET_ACTIVE_REPORT_TAB, payload: 2 })
                    }}
                  >
                    <Image src={trainer?.profile_pic || Avatar} alt={`trainer`} width={50} height={50} className={`circle ${Style.trainerImage}`} />
                    <div className="ml-3 w-70">
                      <p className="mb-0 fs-18px bold-500 ellipsis" title={trainer?.name}>
                        {trainer?.name}
                      </p>
                      <p className="mb-0 fs-14px ellipsis" title={trainer?.designation}>
                        {trainer?.designation}
                      </p>
                    </div>
                    <Image src={LongRightArrow} className="ml-auto" alt={`Right Arrow`} width={20} height={10} />
                  </div>
                )
              })
            )}
          </HorizontalScroll>
        </div>
      )}
    </div>
  )
}

export default ClassLevelReportDropdowns
