import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"
import authClient from "../../../../../services/auth"
import { isBrowser } from "../../../../../services/developer_mode"

const ViewTopic = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [topics, setTopics] = useState({})
  const [loader, setLoader] = useState(false)
  const [dmodal, setDmodal] = useState(false)
  const [currentTopic, setCurrentTopic] = useState([])

  const handleClose = () => {
    setDmodal(false)
    setCurrentTopic([])
  }

  // console.log(location,course, "location.dilsjdio")
  // const { state = {} } = location

  useEffect(() => {
    fetchTemplateTopics()
  }, [])

  async function fetchTemplateTopics() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/modules/${location.module_id}/`, { headers: login_credentials }).then((res) => {
      setTopics(res.data.topics)
      setLoader(true)
    })
  }

  function deleteTopic() {
    var current_topic_id = currentTopic.id
    var index = topics.findIndex((res) => res.id == current_topic_id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/topics/" + current_topic_id + "/", { headers: DefaultHeader, body: currentTopic })
      .then((res) => {
        topics.splice(index, 1)
        setCurrentTopic([])
        setDmodal(!dmodal)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function handleSave(event, currentTopic) {
    event.preventDefault()
    if (currentTopic.id) {
      currentTopic[event.target.name] = event.target.value
      var formData = new FormData()
      for (var k in currentTopic) {
        formData.append(k, currentTopic[k])
      }

      var current_topic_id = currentTopic.id
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/topics/" + current_topic_id + "/", formData, { headers: DefaultHeader })
        .then((res) => {})
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
    }
  }

  function displayTopicHeader() {
    let data = topics
    var isLoaded = loader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0])
        let heading = []
        heading.push(<td>&nbsp;</td>)
        for (let key in keys) {
          /*if (keys[key].toLowerCase() !== 'videos'){*/
          heading.push(<th style={{ textTransform: "uppercase" }}>{keys[key].replace(/_/g, " ")}</th>)
          /*}*/
        }
        return <tr>{heading}</tr>
      } else {
        return ""
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function displayTopicData() {
    let data = topics
    var isLoaded = loader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0]),
          parsed_data = []
        data.map((datum, index) => {
          let values = []
          values.push(
            <td key={index + "deletebtn"}>
              <Button
                onClick={(e) => {
                  setDmodal(true)
                  setCurrentTopic(datum)
                }}
                variant="secondary"
              >
                {" "}
                Delete
              </Button>
            </td>
          )
          for (let key in keys) {
            if (keys[key].toLowerCase() === "sequence" || keys[key].toLowerCase() === "name") {
              values.push(
                <td key={index + keys[key].toLowerCase()}>
                  {" "}
                  <input
                    type={keys[key].toLowerCase() !== "sequence" ? "text" : "number"}
                    name={keys[key].toLowerCase()}
                    defaultValue={datum[keys[key]]}
                    onChange={(e) => handleSave(e, datum)}
                    id={keys[key].toLowerCase()}
                    placeholder={"Enter topic " + keys[key].toLowerCase()}
                    required
                  />
                </td>
              )
            } else if (keys[key].toLowerCase() === "resources") {
              values.push(
                <td className={``}>
                  <Link
                    to={`/app/default-course-templates/${location.template_id}/stages/${location.stage_id}/modules/${location.module_id}/topics/${datum.id}/resources/create`}
                  >
                    {" "}
                    Add
                  </Link>
                  <Link
                    className={`ml-3`}
                    to={`/app/default-course-templates/${location.template_id}/stages/${location.stage_id}/modules/${location.module_id}/topics/${datum.id}/resources/view/`}
                  >
                    {" "}
                    View
                  </Link>
                </td>
              )
            } else {
              values.push(<td key={index + keys[key].toLowerCase()}> {datum[keys[key]]} </td>)
            }
          }
          parsed_data.push(<tr key={index}>{values}</tr>)
        })
        return <tbody>{parsed_data}</tbody>
      } else {
        return (
          <tr id="loader">
            <td colSpan="12" className="animated fadeIn pt-3 text-center">
              No Data found...
            </td>
          </tr>
        )
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function showTopics() {
    return (
      <table>
        {displayTopicHeader()}
        {displayTopicData()}
      </table>
    )
  }

  return (
    <div>
      <SEO title="CourseTemplate Stages Modules Topics View and Edit" />
      <Row>
        <Modal show={dmodal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete Topic {currentTopic ? currentTopic.name : ""}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              cancel
            </Button>
            <Button variant="primary" onClick={deleteTopic}>
              delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>CourseTemplate Stages Modules Topics View and Edit</h4>
          <Col>
            <div>
              <Row>
                <Col xs="12" sm="6" md="12">
                  {showTopics()}
                </Col>
              </Row>
            </div>
          </Col>
          <Button onClick={() => navigate(`/app/default-course-templates/${location.template_id}/stages/${location.stage_id}/modules/view/`)} variant="danger">
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default ViewTopic
