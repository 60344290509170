export const BAR_COLOR_CODES = ["#5E89DE", "#988E62", "#479D48", "#2587CE", "#219882", "#987C4F"]
export const PERCENTAGE_COLOR_CODES = ["#3D7DFC", "#937804", "#006C00"]
export const ENGAGEMENT_TYPE_COLOR_CODES = ["#986CA4", "#97B596", "#4B9B8C", "#3AAFD9"]

export const THEME = {
  background: "#19253B",
  axis: {
    domain: {
      line: {
        stroke: "#313E57",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: "#FFFFFF",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
    ticks: {
      text: {
        fontSize: 9,
        fill: "#FFFFFF",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
  },
  grid: {
    line: {
      stroke: "#313E57",
      strokeWidth: 0.5,
    },
  },
  legends: {
    text: {
      fontSize: 10,
      fill: "#E0E2E5",
      outlineWidth: 0,
      outlineColor: "transparent",
    },
  },
}

export const TimePeriod = ["Overall Period", "1-Month", "3-Month", "6-Month"]
