import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import FormControl from "react-bootstrap/FormControl"
import Modal from "react-bootstrap/Modal"
import Dropdown from "react-bootstrap/Dropdown"
import FormGroup from "react-bootstrap/FormGroup"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faPlus, faTrashAlt, faChevronLeft, faGripLines } from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import Notification from "../custom/Notification"
import { isBrowser } from "../../services/developer_mode"

import NavWithTriangles from "../../services/nav_with_triangle"

import Close from "../../images/svgs/close.svg"
import ChevronCircleUp from "../../images/svgs/ChevronCircleUp.svg"
import ChevronCircleDown from "../../images/svgs/ChevronCircleDown.svg"

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  outline: "none",
  padding: grid,
  margin: `0`,
  borderRadius: "20px",
  textAlign: "center",
  color: "#fff",

  // change background colour if dragging
  background: isDragging ? "transparent" : "#12172C",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "transparent" : "#12172c",
  padding: grid,
})

const ViewQuizQuestions = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [questions, setQuestions] = useState({})
  const [questionsNext, setQuestionsNext] = useState({})
  const [questionsFetched, setQuestionsFetched] = useState({})
  const [allQuestionsFetched, setAllQuestionsFetched] = useState(false)
  const [quizQuestionsFetched, setQuizQuestionsFetched] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  const [collapse, setCollapse] = useState(false)

  const [draggedData, setDraggedData] = useState(false)

  const [deleteResourceData, setDeleteResourceData] = useState({})

  const [questionCreated, setQuestionCreated] = useState(false)

  const [questionOptions, setQuestionOptions] = useState({})
  const [questionOptionsNext, setQuestionOptionsNext] = useState({})
  const [optionsFetched, setOptionsFetched] = useState(false)
  const [toggleQuestion, setToggleQuestion] = useState(false)
  const [questionOptionsFetched, setQuestionOptionsFetched] = useState({})

  const [currentQuestionId, setCurrentQuestionId] = useState("")

  const [toggledOption, setToggledOption] = useState(false)

  const [optionCreated, setOptionCreated] = useState(false)
  const [optionCreatedX, setOptionCreatedX] = useState(false)

  const [usingDropdown, setUsingDropdown] = useState(false)

  const [optionUpdated, setOptionUpdated] = useState(false)

  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    fetchAllQuestions(searchValue)
  }, [searchValue])

  async function fetchAllQuestions(searchValue) {
    questionsFetched["all"] = false
    setAllQuestionsFetched(false)
    let search = searchValue === "" ? "" : `&search=${searchValue}`
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-questions-list/?paginate_by=15${search}` + "&data_type=1", { headers: login_credentials })
      .then((res) => {
        questions["all"] = res.data.results
        questionsNext["all"] = res.data.next
        questionsFetched["all"] = true
        setAllQuestionsFetched(true)
      })
  }

  async function fetchNextAllQuestions(next_url) {
    if (next_url) {
      // questionsFetched['all'] = false
      setAllQuestionsFetched(false)
      axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
        var next_all_res = questions["all"]
        if (res.data.results.length > 0) {
          for (var rs = 0; rs <= res.data.results.length - 1; rs++) {
            next_all_res.push(res.data.results[rs])
          }
          questions["all"] = next_all_res
        }
        questionsNext["all"] = res.data.next
        // questionsFetched['all'] = true
        setAllQuestionsFetched(true)
      })
    }
  }

  async function fetchOptions(id, allowCreationOfOptions = false, currentQuestion = []) {
    if (questionOptions["question_" + id] === undefined || allowCreationOfOptions == "refetch") {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-question-options-list/${id}/?paginate_by=6`, { headers: login_credentials })
        .then((res) => {
          questionOptions["question_" + id] = res.data.results
          questionOptionsNext["question_" + id] = res.data.next
          questionOptionsFetched["question_" + id] = true
          setCurrentQuestionId(id)
          document.getElementById(id + `-question-input-id`).focus()
          if (allowCreationOfOptions === true) {
            setOptionCreated(false)
            createOption(questionOptions["question_" + id], currentQuestion, true)
          }
          if (allowCreationOfOptions == "refetch" && res.data.results.length > 0) {
            var index2 = questions["all"].findIndex((res) => res.id === id)
            let is_valid = questions["all"][index2].is_valid_question
            questions["all"][index2].is_valid_question = res.data.results[0].is_valid_question
            let is_valid2 = questions["all"][index2].is_valid_answers
            questions["all"][index2].is_valid_answers = res.data.results[0].is_valid_answers
            if (is_valid != res.data.results[0].is_valid_question || is_valid2 != res.data.results[0].is_valid_answers) {
              setOptionUpdated(true)
            }
          } else if (allowCreationOfOptions == "refetch" && res.data.results.length === 0) {
            var index2 = questions["all"].findIndex((res) => res.id === id)
            let is_valid2 = questions["all"][index2].is_valid_answers
            questions["all"][index2].is_valid_answers = "optionsDoesNotExist"
            if (is_valid2 != "optionsDoesNotExist") {
              setOptionUpdated(true)
            }
          }
          setOptionsFetched(true)
        })
        .catch((error) => {
          setOptionsFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    } else {
      // if (questionOptionsFetched['question_'+id] == true) {
      //   questionOptionsFetched['question_'+id] = false
      // } else if (questionOptionsFetched['question_'+id] == false) {
      //   questionOptionsFetched['question_'+id] = true
      // }
      if (currentQuestionId != id) {
        setCurrentQuestionId(id)
      } else {
        setCurrentQuestionId("")
      }
      setOptionsFetched(true)
      setToggleQuestion(!toggleQuestion)
    }
  }

  function openNav() {
    let side_div_width = document.getElementById("mySidepanel").offsetWidth
    if (side_div_width > 250) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }

  function deleteResource(id, type = "questions-resources", question_id = "") {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/` + id + "/", { headers: DefaultHeader })
      .then((res) => {
        setDraggedData(!draggedData)
        if (type === "options") {
          setOptionUpdated(false)
          fetchOptions(question_id, "refetch")
        }
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function handleClose() {
    setDeleteResourceData({})
  }

  function deleteQuestionModal() {
    if (deleteResourceData && Object.keys(deleteResourceData).length > 0) {
      return (
        <Modal show={deleteResourceData && Object.keys(deleteResourceData).length > 0} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this question "{deleteResourceData.question_data.title}"</Modal.Body>
          <Modal.Footer>
            <FontAwesomeIcon
              onClick={() => {
                const newState = questions["quiz"]
                newState.splice(deleteResourceData.index, 1)
                setDraggedData(!draggedData)
                deleteResource(deleteResourceData.id, "questions-resources")
                setDeleteResourceData({})
              }}
              icon={faTrashAlt}
              className={`pointer`}
              style={{ width: `20px`, height: `20px`, color: `black` }}
            />
          </Modal.Footer>
        </Modal>
      )
    }
  }

  function createQuestion(value) {
    let question = {}
    question["question_type"] = value
    question["title"] = "this is sample question " + (questions["all"].length + 1).toString()
    question.data_type = "1"
    var formData = new FormData()
    for (var k in question) {
      formData.append(k, question[k])
    }
    if (question.id != undefined) {
    } else {
      var question_id = question.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/questions/create/", formData, { headers: login_credentials })
        .then((res) => {
          let currentQuestion = {}
          currentQuestion.id = res.data.id
          currentQuestion.title = res.data.title
          currentQuestion.points = res.data.points
          currentQuestion.question_level = res.data.question_level
          currentQuestion.answermap_set = []
          /*if (questions['all'].length > 0) {
          currentQuestion.sequence = parseInt(questions['all'][0].sequence) - 10
        }else {
          currentQuestion.sequence = parseInt(0)
        }*/
          questions["all"].unshift(currentQuestion)
          // setDraggedData(!draggedData)
          setOptionsFetched(false)
          fetchOptions(res.data.id, true, currentQuestion)
          setQuestionCreated(true)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    }
  }

  function createOption(data, question, second = false) {
    var index = questions["all"].findIndex((res) => res.id === question.id)
    let question_id = question.id
    let option = {}
    if (question.answermap_set.length > 0) {
      option["answer_map_id"] = question.answermap_set[0]
    }
    option["name"] = `sample option ${data ? (data.length + 1).toString() : "1"} } `
    option["add_to_question"] = question_id

    var formData = new FormData()
    for (var k in option) {
      formData.append(k, option[k])
    }
    if (option.id != undefined) {
    } else {
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/options/create/", formData, { headers: login_credentials })
        .then((res) => {
          option.id = res.data.id
          option.answer_map_id = res.data.answer_map
          option.is_correct = false
          questionOptions["question_" + question_id] = questionOptions["question_" + question_id].concat(option)
          delete option["add_to_question"]
          setOptionCreated(true)
          setOptionCreatedX(true)
          if (second == true) {
            setOptionCreatedX(false)
            createOption(data, question)
          } else if (second == null) {
            document.getElementById(res.data.id + `-question-option-input-id`).focus()
          }
          let is_valid = questions["all"][index].is_valid_question
          questions["all"][index].is_valid_question = res.data.is_valid_question
          let is_valid2 = questions["all"][index].is_valid_answers
          questions["all"][index].is_valid_answers = res.data.is_valid_answers
          if (is_valid != res.data.is_valid_question || is_valid2 != res.data.is_valid_answers) {
            setOptionUpdated(!optionUpdated)
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    }
  }

  function handleUpdateQuestion(e, index, name = null, value = null) {
    setUsingDropdown(!usingDropdown)
    let question = questions["all"][index]
    if (name === null) {
      if (e.target.name === "title") {
        question.title = e.target.value
        question.display_name = e.target.value
      } else {
        question[e.target.name] = e.target.value
      }
    } else {
      question[name] = value
    }
    var formData = new FormData()
    for (var k in question) {
      formData.append(k, question[k])
    }
    if (question.id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/questions/" + question.id + "/", formData, { headers: login_credentials })
        .then((res) => {
          questions["all"][index] = question
          let is_valid = questions["all"][index].is_valid_question
          questions["all"][index].is_valid_question = res.data.is_valid_question
          let is_valid2 = questions["all"][index].is_valid_answers
          questions["all"][index].is_valid_answers = res.data.is_valid_answers
          if (is_valid != res.data.is_valid_question || is_valid2 != res.data.is_valid_answers) {
            setOptionUpdated(!optionUpdated)
          }
          setUsingDropdown(!usingDropdown)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    }
  }

  function handleUpdateAnswer(e, option, question_id) {
    var index = questions["all"].findIndex((res) => res.id === question_id)
    option.name = e.target.value
    option.display_name = e.target.value
    var formData = new FormData()
    for (var k in option) {
      formData.append(k, option[k])
    }
    if (option.id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/options/" + option.id + "/", formData, { headers: login_credentials })
        .then((res) => {
          let is_valid = questions["all"][index].is_valid_question
          questions["all"][index].is_valid_question = res.data.is_valid_question
          let is_valid2 = questions["all"][index].is_valid_answers
          questions["all"][index].is_valid_answers = res.data.is_valid_answers
          if (is_valid != res.data.is_valid_question || is_valid2 != res.data.is_valid_answers) {
            setOptionUpdated(true)
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    }
  }

  function handleUpdateOption(e, option, action, question_id, index) {
    let perform = false
    var formData = new FormData()
    if (action === "add" && option.is_correct === false) {
      perform = true
      formData.append("add_answer", option.id)
    } else if (action === "remove" && option.is_correct) {
      perform = true
      formData.append("remove_answer", option.id)
    }

    if (perform && option.answer_map_id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/answer-maps/" + option.answer_map_id + "/", formData, { headers: login_credentials })
        .then((res) => {
          if (action === "add" && option.is_correct === false) {
            option.is_correct = true
            questionOptions["question_" + question_id][index].is_correct = true
          } else if (action === "remove" && option.is_correct) {
            option.is_correct = false
            questionOptions["question_" + question_id][index].is_correct = false
          }
          var index2 = questions["all"].findIndex((res) => res.id === question_id)
          let is_valid2 = questions["all"][index2].is_valid_answers
          questions["all"][index2].is_valid_answers = res.data.is_valid_answers
          if (is_valid2 != res.data.is_valid_answers) {
            setOptionUpdated(true)
          }
          setToggledOption(true)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    }
  }

  function showQuizQuestions() {
    if (questionsFetched["all"] && questions["all"]) {
      return (
        <div
          className={`col-12`}
          style={{
            userSelect: "none",
            outline: "none",
            padding: grid,
            margin: `0`,
            borderRadius: "20px",
            textAlign: "center",
            color: "#fff",
            background: "#12172C",
          }}
        >
          {" "}
          {/*style={getListStyle(snapshot.isDraggingOver)}*/}
          {questions["all"].map((datum, index) => {
            let options_error_class = datum.is_valid_answers
            let question_error_class = datum.is_valid_question
            let toggle_class = questionOptions["question_" + datum.id] && currentQuestionId === datum.id
            return (
              <div key={datum.id + `-question-key-` + index} id={`${datum.id.toString()}-question-id-${index.toString()}`} index={index}>
                <div
                  className={``}
                  style={{
                    userSelect: "none",
                    outline: "none",
                    padding: grid,
                    margin: `0`,
                    borderRadius: "20px",
                    textAlign: "center",
                    color: "#fff",
                    background: "#12172C",
                  }}
                >
                  {" "}
                  {/**/}
                  <div
                    id={"quizQuestions_" + datum.id}
                    className={`${Styles.font_16} pr-3 shadow-none w-100 ${options_error_class} ${
                      question_error_class ? `` : `red-class`
                    } text-left d-inline-flex ${toggle_class ? `border-bottom-0` : ``}`}
                    style={{
                      backgroundColor: `#28324e`,
                      color: `#b2b6cb`,
                      borderRadius: `0px`,
                      opacity: 1,
                      border: `${question_error_class && options_error_class == "None" ? `0px` : `1px`} solid #904744`,
                    }}
                  >
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionsFetched(false)
                        fetchOptions(datum.id)
                      }}
                      className={`px-3 py-2`}
                      style={{ borderRight: `1px dotted #495269` }}
                    >
                      <FontAwesomeIcon icon={faGripLines} style={{ width: `20px`, height: `20px`, margin: `auto 0px`, verticalAlign: `middle` }} />
                    </div>
                    <img
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionsFetched(false)
                        fetchOptions(datum.id)
                      }}
                      src={toggle_class ? ChevronCircleUp : ChevronCircleDown}
                      alt={"arrow for collapse and expand"}
                      className="ml-2"
                      style={{ objectFit: `contain`, width: `20px`, height: `20px`, margin: `auto 0px`, verticalAlign: `middle` }}
                    />
                    <input
                      type="text"
                      name="title"
                      id={datum.id + `-question-input-id`}
                      defaultValue={datum.display_name}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onChange={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleUpdateQuestion(e, index)
                      }}
                      placeholder={"Enter Question Title"}
                      className={`form-control shadow-none border-0 px-3 my-auto ${Styles.font_16}`}
                      style={{ outline: `none`, backgroundColor: `transparent`, maxWidth: `fix-content`, color: `#b2b6cb`, verticalAlign: `middle` }}
                      required
                    />
                  </div>
                  {toggle_class ? (
                    <div className={`d-inline-flex w-100`}>
                      <div
                        className={`px-0 w-100 ${Styles.border_top}`}
                        style={{ backgroundColor: `#28324e`, border: `${question_error_class && options_error_class == "None" ? `0px` : `1px`} solid #904744` }}
                      >
                        <div className={`d-inline-flex w-100`} style={{ backgroundColor: `#4a526a` }}>
                          <div className={`d-inline-flex w-50 ml-auto px-3`} style={{ justifyContent: `flex-end`, color: `#bfc1d3` }}>
                            {/*<FormGroup className='m-0 d-flex align-items-center' >
                              <div style={{ fontSize: `13px`}} >
                                Points:
                              </div>
                              <input style={{background: 'inherit',color: 'inherit', fontSize: '16px', width: `71px`}} className='blue-bg video-modal number-input float-left font-weight-bold text-left' type="number" name="points" defaultValue={datum.points} onChange={(e) => {e.preventDefault();e.stopPropagation();setOptionUpdated(false);handleUpdateQuestion(e,index)}} id="points" placeholder=" " />
                            </FormGroup>*/}
                          </div>
                        </div>
                        {showOptionButtons(questionOptions["question_" + datum.id], datum)}
                      </div>
                    </div>
                  ) : (
                    ``
                  )}
                </div>
              </div>
            )
          })}
          {questionsFetched["all"] && questionsNext["all"] !== null ? (
            <Col md="auto" className={`d-inline-flex w-100 my-2`}>
              <Button
                variant="none"
                onClick={(e) => {
                  e.preventDefault()
                  fetchNextAllQuestions(questionsNext["all"])
                }}
                className={`${Styles.font_12} text-center px-3 py-2 mx-auto`}
                style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
              >
                See More
              </Button>
            </Col>
          ) : (
            ""
          )}
          {questions["all"] && questions["all"].length > 0 ? (
            <div className={`justify-content-center pt-4 pb-3 d-flexx bg-transparent d-none`} style={{ border: `1px dashed #C6CFF4` }}>
              <div className={`pl-3 text-center d-inline-flex`}>
                <p className={`${Styles.font_14}`} style={{ color: `#D4DCF7` }}>
                  Drag and drop questions here{" "}
                  <span className={`font-weight-bold mx-3`} style={{ fontSize: `24px` }}>
                    or
                  </span>{" "}
                </p>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    id="create-question"
                    className={`ml-3 text-center px-3 py-2 shadow-none ${Styles.create_question_dropdown_btn}`}
                    style={{ backgroundColor: `#4b5a9b`, color: `#D4DCF7`, borderRadius: `10px`, fontSize: "12px" }}
                  >
                    Create and Add
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey="1"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setQuestionCreated(false)
                        createQuestion("1")
                      }}
                    >
                      Choice
                    </Dropdown.Item>
                    {/*<Dropdown.Item eventKey="2" onClick={(e) => {e.preventDefault();e.stopPropagation();setQuestionCreated(false);createQuestion('2')}} >Descriptive</Dropdown.Item>
                    <Dropdown.Item eventKey="3" onClick={(e) => {e.preventDefault();e.stopPropagation();setQuestionCreated(false);createQuestion('3')}} >Boolean</Dropdown.Item>*/}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ) : (
            <div className={`justify-content-center pt-4 pb-3 d-flexx bg-transparent d-none`} style={{ border: `1px dashed #C6CFF4` }}>
              <div className={`pl-3 text-center`}>
                <span className={``} style={{ color: `#D4DCF7` }}>
                  Drag and drop questions here
                </span>
                <br />
                or
                <br />
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    id="create-question"
                    className={`mt-3 mb-3 ml-2 text-center px-3 py-2 mr-2 shadow-none ${Styles.create_question_dropdown_btn}`}
                    style={{ backgroundColor: `#4b5a9b`, color: `#D4DCF7`, borderRadius: `10px`, fontSize: "12px" }}
                  >
                    Create and Add
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey="1"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setQuestionCreated(false)
                        createQuestion("1")
                      }}
                    >
                      Choice
                    </Dropdown.Item>
                    {/*<Dropdown.Item eventKey="2" onClick={(e) => {e.preventDefault();e.stopPropagation();setQuestionCreated(false);createQuestion('2')}} >Descriptive</Dropdown.Item>
                    <Dropdown.Item eventKey="3" onClick={(e) => {e.preventDefault();e.stopPropagation();setQuestionCreated(false);createQuestion('3')}} >Boolean</Dropdown.Item>*/}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}
        </div>
      )
    }
  }

  function showOptionButtons(data, question) {
    let question_id = question.id
    if (data) {
      return (
        <div
          className={`col-12 bg-transparent`}
          style={{
            userSelect: "none",
            outline: "none",
            padding: grid,
            margin: `0`,
            borderRadius: "20px",
            textAlign: "center",
            color: "#fff",
            background: "#12172C",
          }}
        >
          {data.map((datum, index) => (
            <div
              key={datum.id + `-option-key-` + index + `-${question_id}`}
              id={`${datum.id.toString()}-question-id-${index.toString() + `-${question_id.toString()}`}`}
              index={index}
            >
              <div className={`p-2`}>
                <div
                  variant="none"
                  id={`quizOptions_${question_id.toString()}` + datum.id}
                  className={`${Styles.font_16} px-3 shadow-none w-100  text-left d-inline-flex`}
                  style={{ backgroundColor: `#3d4d7e`, color: `#b2b6cb`, borderRadius: `0px`, opacity: 1 }}
                >
                  <div className={`pr-2 py-2`} style={{ borderRight: `1px solid #ffffff` }}>
                    <FontAwesomeIcon icon={faGripLines} style={{ width: `20px`, height: `20px`, margin: `auto 0px`, verticalAlign: `middle` }} />
                  </div>
                  <input
                    type="text"
                    name="display_name"
                    id={datum.id + `-question-option-input-id`}
                    defaultValue={datum.display_name}
                    onChange={(e) => {
                      e.preventDefault()
                      setOptionUpdated(false)
                      handleUpdateAnswer(e, datum, question_id)
                    }}
                    placeholder={"Enter Option text"}
                    className={`form-control shadow-none border-0 px-3 w-50 my-auto ${Styles.font_16}`}
                    style={{ outline: `none`, backgroundColor: `transparent`, maxWidth: `fix-content`, color: `#b2b6cb`, verticalAlign: `middle` }}
                    required
                  />
                  <div className={`ml-auto d-inline-flex ${toggledOption}`}>
                    <div
                      className={`p-2`}
                      style={{ margin: `auto 0 auto auto` }}
                      onClick={(e) => {
                        e.preventDefault()
                        const newState = data
                        newState.splice(index, 1)
                        setDraggedData(!draggedData)
                        deleteResource(datum.id, "options", question_id)
                      }}
                    >
                      <FontAwesomeIcon className="pointer" icon={faTrashAlt} style={{ width: `20px`, height: `20px` }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className={`pt-2 pb-2 d-flex bg-transparent`}>
            <div className={`pl-3 text-center`}>
              <Button
                variant="none"
                id="create-question"
                className={`ml-2 text-center px-3 py-2 mr-2 shadow-none align-items-center d-inline-flex`}
                style={{ backgroundColor: `#465077`, color: `#D4DCF7`, borderRadius: `10px`, fontSize: "12px" }}
                onClick={(e) => {
                  e.preventDefault()
                  setOptionCreated(false)
                  createOption(data, question, null)
                }}
              >
                <FontAwesomeIcon icon={faPlus} style={{ width: `15px`, height: `15px` }} />
                &nbsp; Add Options
              </Button>
            </div>
          </div>
        </div>
      )
    }
  }

  const data_for_menu = [
    { id: 0, name: "Quiz Information", selected: true, href: `/app/quizzes/edit/${location.quiz_id}` },
    { id: 1, name: "Add Questions", selected: true, href: `/app/quizzes/${location.quiz_id}/questions` },
    {
      id: 2,
      name: "Save & Publish",
      selected: false,
      href: questions["quiz"] && questions["quiz"].length > 0 ? `/app/quizzes/${location.quiz_id}/publish` : `/app/quizzes/${location.quiz_id}/questions`,
    },
  ]

  return (
    <div>
      <SEO title="Quiz Questions" />
      <Col lg="12" md="12" sm="12" xs="12" className={`px-0 d-inline-flex float-left`}>
        <div id={`scrollable-div`} className={`px-0 float-left col-12`} style={{ height: `calc(100vh)`, overflowY: `scroll` }}>
          <div className={`sticky-top`}>
            <Col xs="12" sm="12" md="12" className={`px-0`} style={{ backgroundColor: `#12192c` }}>
              <h5 className={`${Styles.font_h3} border-bottom p-2 m-0`} style={{ color: `#C6CFF4`, fontFamily: `Helvetica Neue` }}>
                <Link className={`pointer text-white`} to={`/app/quizzes/edit/${location.quiz_id}`}>
                  {" "}
                  <FontAwesomeIcon icon={faChevronLeft} style={{ width: `25px`, height: `25px` }} />
                </Link>
                &nbsp;
                <span className={`text-white font-weight-bold`}>Poll Questions</span>
                <Link className={`pointer ml-auto float-right`} to={`/app/quizzes`} style={{ color: `#C6CFF4` }}>
                  <span style={{ fontWeight: "normal", fontSize: "16px" }} className={`small`}>
                    Exit Poll Questions &nbsp;
                    <img src={Close} alt={`Exit Course`} className={`my-auto`} style={{ objectFit: `contain`, height: `15px`, color: `white` }} />
                  </span>
                </Link>
              </h5>
            </Col>
          </div>
          <div className="d-inline-flex w-100" style={{ alignItems: `baseline` }}>
            <NavWithTriangles button_data={data_for_menu} classes={`mb-4 ml-2 mt-5 triangle-menu d-none`} />
            <Dropdown className={`ml-auto mr-3 mt-3`}>
              <Dropdown.Toggle
                variant="none"
                id="create-question"
                className={`ml-3 text-center px-3 py-2 shadow-none ${Styles.create_question_dropdown_btn}`}
                style={{ backgroundColor: `#4b5a9b`, color: `#D4DCF7`, borderRadius: `10px`, fontSize: "12px" }}
              >
                Create Question
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="1"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setQuestionCreated(false)
                    createQuestion("1")
                  }}
                >
                  Choice
                </Dropdown.Item>
                {/*<Dropdown.Item eventKey="2" onClick={(e) => {e.preventDefault();e.stopPropagation();setQuestionCreated(false);createQuestion('2')}} >Descriptive</Dropdown.Item>
                <Dropdown.Item eventKey="3" onClick={(e) => {e.preventDefault();e.stopPropagation();setQuestionCreated(false);createQuestion('3')}} >Boolean</Dropdown.Item>*/}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Col className={`col-12 p-0`}>
            <p className="ml-2 m-0">Poll Questions</p>
          </Col>
          {/* {deleteQuestionModal()} */}
          <div className={`col-12 py-2`}>
            <div className={`d-inline-flex col-12`} style={{ backgroundColor: `#414a6c` }}>
              <FontAwesomeIcon icon={faSearch} size="lg" style={{ margin: `auto 0px`, color: `#7c84a0` }} />
              <FormControl
                className="border-0 py-1 px-2 form-control px-0 shadow-none font-14"
                style={{ backgroundColor: `transparent`, color: `#b6bdd4` }}
                placeholder={`search Questions`}
                onChange={(e) => {
                  e.preventDefault()
                  setSearchValue(e.target.value)
                }}
                value={searchValue}
              />
            </div>
          </div>
          {showQuizQuestions()}
        </div>
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default ViewQuizQuestions
