import React, { useEffect, useState, useContext } from "react"
import { Modal } from "react-bootstrap"
import Col from "react-bootstrap/Col"

import Btn from "../custom/Button"
import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_CURRENT_NAV_TAB, SET_SELECTED_RESOURCE_TYPE, SET_REFETCH_RESOURCES } from "../../stores/sidebar_store/SidebarActions"

import { navigate } from "gatsby"

import SucessTick from "../../images/svgs/sucesstick.svg"

const SuccessModal = (props) => {
  const [sidebarState, dispatch] = useContext(SidebarContext)

  // To open left nav with mtf section.
  function openLeftNavMtf() {
    dispatch({ type: SET_CURRENT_NAV_TAB, payload: "Resource" })
    dispatch({ type: SET_REFETCH_RESOURCES, payload: true })
    dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "MTFs" })
    props.setShowSuccessModal(false)
  }

  return (
    <Modal
      show={props.showSuccessModal}
      onHide={() => {
        props.setShowSuccessModal(false)
      }}
      size="md"
      centered
      className="modal-content-bg-transparent"
    >
      <Modal.Body
        className="align-items-center d-inline-flex w-100 px-4"
        style={{ backgroundColor: "#212C42", border: "1px solid #E0E2E5", borderRadius: "5px" }}
      >
        <div className={`d-inline-flex text-center w-100 align-items-center`} style={{ maxHeight: "470px", overflowY: "scroll" }}>
          <Col xs="12" sm="12" md="12" lg="12" className={`mb-4 w-100 my-3 pt-2 mb-3 text-center align-items-center`}>
            <img src={SucessTick} alt="success" className={`mb-2`} />
            <p
              className="mb-4"
              style={{
                fontSize: "17px",
                color: "#E0E2E5",
                fontWeight: "400",
              }}
            >
              Match the following published successfully!
            </p>
            <p
              className="mb-0"
              style={{
                fontSize: "18px",
                color: "#E0E2E570",
              }}
            >
              You can find the matching under Resources section
            </p>
            <p
              className="mb-3"
              style={{
                fontSize: "17px",
                color: "#E0E2E5",
                fontWeight: "400",
              }}
            >
              <span
                className="underline text-white pointer"
                onClick={(e) => {
                  e.preventDefault()
                  openLeftNavMtf()
                }}
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {` Resources `}
              </span>
            </p>
            <Btn
              type="small"
              onClick={(e) => {
                e.preventDefault()
                openLeftNavMtf()
              }}
              className={`px-4`}
              style={{ backgroundColor: "#586886", fontSize: "14px" }}
            >
              Got it
            </Btn>
          </Col>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SuccessModal
