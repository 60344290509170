import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import Button from "react-bootstrap/Button"
import Dropdown from "react-bootstrap/Dropdown"

import Layout from "../layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../seo"
import authClient from "../../services/auth"
// import hideMessage from '../../services/hidemessage';
import Notification from "../custom/Notification"
import { isBrowser } from "../../services/developer_mode"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faGripLines, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

import ChevronCircleUp from "../../images/svgs/ChevronCircleUp.svg"
import ChevronCircleDown from "../../images/svgs/ChevronCircleDown.svg"

import UserIcon from "../../images/icon-user.svg"

const grid = 8

const ReportList = (location, props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [quizReports, setQuizReports] = useState([])
  const [quizReportFetched, setQuizReportFetched] = useState(false)

  const [selectedReport, setSelectedReport] = useState([])
  const [reportFetched, setReportFetched] = useState(false)

  const [questions, setQuestions] = useState([])
  const [questionsNext, setQuestionsNext] = useState(null)
  const [questionsFetched, setQuestionsFetched] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState(null)

  const [firstLetter, setFirstLetter] = useState("U")

  const [totalAttempts, setTotalAttempts] = useState(0)

  const [toggledQuestions, setToggledQuestions] = useState({})
  const [selectedReportName, setSelectedReportName] = useState("")
  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    fetchQuizReports()
  }, [])

  async function fetchQuizReports() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/student-quiz-reports/${location.training_id}/${location.student_id}/${location.quiz_id}/`, {
        headers: login_credentials,
      })
      .then((res) => {
        setQuizReports(res.data.results)
        setFirstLetter(res.data.firstLetter)
        setQuizReportFetched(true)
        if (res.data.results.length > 0) {
          let report = res.data.results[0]
          let number_of_attempts = res.data.results[0].reports.length
          if (number_of_attempts > 0) {
            fetchReportDetail(report.reports[0].id, number_of_attempts, `Attempt ${number_of_attempts}`)
          }
        }
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function fetchReportDetail(report_id, attempts, name) {
    setTotalAttempts(attempts)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/student-report-detail/${location.training_id}/${location.student_id}/${report_id}/`, {
        headers: login_credentials,
      })
      .then((res) => {
        setSelectedReport(res.data.results)
        setSelectedReportName(name)
        setReportFetched(true)
        fetchQuestions(report_id)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function fetchQuestions(report_id) {
    setQuestions([])
    setQuestionsFetched(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/student-questions-detail/${location.training_id}/${location.student_id}/${report_id}/`, {
        headers: login_credentials,
      })
      .then((res) => {
        setQuestions(res.data.results)
        setQuestionsNext(res.data.next)
        setQuestionsFetched(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  async function fetchNextQuestion(next_url) {
    // setCoursesNextFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_course_data = questions
      if (res.data.results.length > 0) {
        for (var crs = 0; crs <= res.data.results.length - 1; crs++) {
          next_course_data.push(res.data.results[crs])
        }
        setQuestions(next_course_data)
      }
      setQuestionsNext(res.data.next)
      // setCoursesNextFetched(true)
    })
  }

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  function selectQuestion(question_id) {
    if (selectedQuestion === question_id) setSelectedQuestion("")
    else setSelectedQuestion(question_id)
  }

  function toLetters(num) {
    var mod = num % 26,
      pow = (num / 26) | 0,
      out = mod ? String.fromCharCode(64 + mod) : (--pow, "Z")
    return pow ? toLetters(pow) + out : out
  }

  function showQuizDetails() {
    if (questionsFetched) {
      if (questions.length > 0) {
        return questions.map((datum, index) => {
          let toggle_class = toggledQuestions[datum.id.toString()] === undefined || toggledQuestions[datum.id.toString()]
          // console.log(toggle_class,datum.id,toggledQuestions[datum.id.toString()])
          return (
            <div
              onClick={(e) => {
                e.preventDefault()
                selectQuestion(datum.id)
                toggledQuestions[datum.id.toString()] = toggledQuestions[datum.id.toString()] === undefined ? false : !toggledQuestions[datum.id.toString()]
              }}
              className="mt-2"
              key={datum.id + `-question-key-` + index}
              id={`${datum.id.toString()}-question-id-${index.toString()}`}
              index={index}
            >
              <div
                className={``}
                style={{ userSelect: "none", outline: "none", margin: `0`, borderRadius: "20px", textAlign: "center", color: "#fff", background: "#12172C" }}
              >
                <div
                  id={"quizQuestions_" + datum.id}
                  className={`p-3 shadow-none w-100 text-left d-inline-flex pointer`}
                  style={{ backgroundColor: `#28324e`, color: `#b2b6cb`, borderRadius: `0px`, opacity: 1, border: "1px solid #707070" }}
                >
                  <img
                    src={toggle_class ? ChevronCircleUp : ChevronCircleDown}
                    alt={"arrow for collapse and expand"}
                    className="ml-2"
                    style={{ objectFit: `contain`, width: `20px`, height: `20px`, margin: `auto 0px`, verticalAlign: `middle` }}
                  />
                  <p id={datum.id + `-question-input-id`} className={`px-3 my-auto w-100`} style={{ color: `#b2b6cb`, verticalAlign: `middle` }}>
                    {" "}
                    {datum.display_name}{" "}
                    <span className="float-right" style={{ fontSize: `16px` }}>
                      {" "}
                      Score:{" "}
                      <span className="font-weight-bold" style={{ color: `#D3D5E2` }}>
                        {" "}
                        {datum.score} / {datum.total_score} Points{" "}
                      </span>{" "}
                    </span>{" "}
                  </p>
                </div>
                {toggle_class ? (
                  <div className={`d-inline-flex w-100`}>
                    <div className={`px-0 w-100`} style={{ backgroundColor: `#28324e` }}>
                      {showOptionButtons(datum.all_options)}
                    </div>
                  </div>
                ) : (
                  ``
                )}
              </div>
            </div>
          )
        })
      }
    } else {
      return <Spinner animation="border" size="lg" />
    }
  }

  function showOptionButtons(options) {
    if (options) {
      return (
        <div
          className={`col-12 bg-transparent`}
          style={{
            userSelect: "none",
            outline: "none",
            padding: grid,
            margin: `0`,
            borderRadius: "20px",
            textAlign: "center",
            color: "#fff",
            background: "#12172C",
          }}
        >
          {options.map((datum, index) => {
            let bg_color = `transparent`
            if (datum.is_correct_option && datum.is_correct_answer) {
              bg_color = `#3D747E`
            } else if (!datum.is_correct_option && datum.is_correct_answer) {
              bg_color = "#CF4245"
            } else if (datum.is_correct_option) {
              bg_color = `#3D747E`
            }
            return (
              <div key={datum.id + `-option-key-` + index + ``} index={index}>
                <div className={`p-2 float-left w-100`}>
                  <div style={{ minWidth: "70px" }} className="float-left">
                    {datum.is_correct_answer ? (
                      <div style={{ position: "relative" }}>
                        <img className={`m-0`} height="50px" src={UserIcon} />
                        <span className="user-letter" style={{ color: `#C6CFF4` }}>
                          {firstLetter}
                        </span>
                      </div>
                    ) : (
                      <p> &nbsp; </p>
                    )}
                  </div>
                  <div
                    variant="none"
                    id={`quizOptions_` + datum.id}
                    className={`shadow-none float-left  text-left d-inline-flex`}
                    style={{ width: "90%", backgroundColor: `#3d4d7e`, color: `#b2b6cb`, borderRadius: `0px`, opacity: 1 }}
                  >
                    {/*<div className={`pr-2 py-2`} style={{ borderRight: `1px solid #ffffff` }} ><FontAwesomeIcon icon={faGripLines} style={{ width: `20px`, height: `20px`, margin: `auto 0px`, verticalAlign: `middle` }} /></div>*/}
                    <p
                      id={datum.id + `-question-option-input-id`}
                      className={`shadow-none m-0 px-3 py-3 w-100 my-auto`}
                      style={{ backgroundColor: bg_color, color: `#b2b6cb`, verticalAlign: `middle`, lineHeight: 1.2 }}
                    >
                      {" "}
                      <span className="p-3" style={{ borderRight: "1px solid" }}>
                        {" "}
                        {toLetters(index + 1)}{" "}
                      </span>{" "}
                      <span className="ml-3"> {datum.display_name} </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  function showReport() {
    if (quizReports.length > 0 && quizReports[0].reports.length > 0) {
      return (
        <Col lg="12" md="12" sm="12" xs="12">
          <h4 className={`mt-3 float-left w-100`}>
            <Link className={`pointer text-white mr-2 float-left`} to={`/app/students/view/${location.student_id}/`}>
              {" "}
              <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
            </Link>
            <span className="float-left d-flex">
              <span className="mr-2">Detailed Report - </span>
              <Dropdown>
                <Dropdown.Toggle id="quiz-report-dropdown" style={{ background: "rgb(31, 38, 67)", border: "none" }}>
                  {selectedReportName}
                </Dropdown.Toggle>
                <Dropdown.Menu>{displayQuizReportAttempts()}</Dropdown.Menu>
              </Dropdown>
            </span>
          </h4>
          {displayReportDetail()}
          <Col lg="12" md="12" sm="12" xs="12" className="mt-4 float-left">
            <Col lg="12" md="12" sm="12" xs="12" className="float-left mb-3">
              <p className={`mt-3`}>
                <span style={{ fontSize: ".8rem", color: "rgb(198 207 244)" }}>Quiz Details</span>
              </p>
              {showQuizDetails()}
              {questionsFetched && questionsNext !== null ? (
                <Col md="auto" className={`d-inline-flex w-100 my-2`}>
                  <Button
                    variant="none"
                    onClick={(e) => {
                      e.preventDefault()
                      fetchNextQuestion(questionsNext)
                    }}
                    className={`${Styles.font_12} text-center px-3 py-2 mx-auto`}
                    style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                  >
                    See More
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Col>
          </Col>
        </Col>
      )
    }
  }
  const displayQuizReportAttempts = () => {
    let reports = [...quizReports[0].reports]
    return reports.reverse().map((report, index) => {
      return (
        <Dropdown.Item
          key={`${index + 1}-${`Attempt ${index + 1}`}`}
          onClick={() => fetchReportDetail(report.id, quizReports[0].reports.length, `Attempt ${index + 1}`)}
        >{`Attempt ${index + 1}`}</Dropdown.Item>
      )
    })
  }
  const displayReportDetail = () => {
    if (reportFetched) {
      if (selectedReport.length > 0 || Object.keys(selectedReport).length > 0) {
        return (
          <Row className="mx-auto w-100 mt-4 float-left">
            <Col
              lg="2"
              md="2"
              sm="3"
              xs="12"
              className="py-4 m-2 text-center float-left"
              style={{ fontSize: ".8rem", color: "rgb(198 207 244)", backgroundColor: "#1E2643", borderRadius: "7px" }}
            >
              <p>Total Attempts</p>
              <h5 className="text-white font-weight-bold font-18 py-1">{totalAttempts}</h5>
            </Col>
            <Col
              lg="2"
              md="2"
              sm="3"
              xs="12"
              className="py-4 m-2 text-center float-left"
              style={{ fontSize: ".8rem", color: "rgb(198 207 244)", backgroundColor: "#1E2643", borderRadius: "7px" }}
            >
              <p>Result</p>
              <h5 className="text-white font-weight-bold font-18 py-1">{capitalize(selectedReport.result)}</h5>
            </Col>
            <Col
              lg="2"
              md="2"
              sm="3"
              xs="12"
              className="py-4 m-2 text-center float-left"
              style={{ fontSize: ".8rem", color: "rgb(198 207 244)", backgroundColor: "#1E2643", borderRadius: "7px" }}
            >
              <p>Score</p>
              <h5 className="text-white font-weight-bold font-18 py-1">
                {selectedReport.score}/{selectedReport.total_score}
              </h5>
            </Col>
            <Col
              lg="2"
              md="2"
              sm="3"
              xs="12"
              className="py-4 m-2 text-center float-left"
              style={{ fontSize: ".8rem", color: "rgb(198 207 244)", backgroundColor: "#1E2643", borderRadius: "7px" }}
            >
              <p>Time</p>
              <h5 className="text-white font-weight-bold font-18 py-1">{selectedReport.time} Mins</h5>
            </Col>
            <Col
              lg="2"
              md="2"
              sm="3"
              xs="12"
              className="py-4 m-2 text-center float-left"
              style={{
                fontSize: ".8rem",
                color: "rgb(198 207 244)",
                backgroundColor: "#1E2643",
                borderRadius: "7px",
                border: selectedReport.rule_breaks >= quizReports[0].tolerance_limit ? "1px solid rgb(221, 17, 17)" : "",
              }}
            >
              <p>Rule Breaks</p>
              <h5 className="text-white font-weight-bold font-18 py-1">
                {selectedReport.rule_breaks >= quizReports[0].tolerance_limit ? quizReports[0].tolerance_limit : selectedReport.rule_breaks}
              </h5>
              {selectedReport.rule_breaks >= quizReports[0].tolerance_limit ? (
                <p className="mb-0 mt-2 float-left w-100">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                  Limit Reached
                </p>
              ) : null}
            </Col>
          </Row>
        )
      } else {
        return <p className="mb-0 py-3 text-center float-left w-100">No Report Found!!!</p>
      }
    } else {
      return <p className="mb-0 py-3 text-center float-left w-100">Loading...</p>
    }
  }

  return (
    <div>
      <SEO title="Student" />
      {showReport()}
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default ReportList
