import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"
import authClient from "../../../../../services/auth"
import { isBrowser } from "../../../../../services/developer_mode"

const CreateTopic = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [topic, setTopic] = useState({ add_to_module: location.module_id })
  const [module, setModule] = useState({})

  const [openResource, setOpenResource] = useState("videos")

  useEffect(() => {
    fetchModule()
  }, [])

  async function fetchModule() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/modules/${location.module_id}/`, { headers: login_credentials }).then((res) => {
      setModule(res.data)
      // setLoader(true)
    })
  }

  function handleChange(event) {
    event.preventDefault()
    topic[event.target.name] = event.target.value
  }
  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in topic) {
      formData.append(k, topic[k])
    }
    if (topic.id != undefined) {
    } else {
      var topic_id = topic.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/topics/create/", formData, { headers: login_credentials })
        .then((res) => {
          navigate(
            `/app/default-course-templates/${location.template_id}/stages/${location.stage_id}/modules/${location.module_id}/topics/${res.data.id}/create?resource_type=` +
              openResource
          )
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  return (
    <div>
      <SEO title="Add course templates stages modules topic" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>Add course templates stages modules topic</h4>
          <p> Courses / Online / Course Content / {module.name}/ Topics </p>
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col lg="12" md="12" sm="12" xs="12" className="float-left p-3" style={{ background: "#2E3346 0% 0% no-repeat padding-box" }}>
              <Col lg="6" md="6" sm="6" xs="12" className="float-left">
                <div className="text-input-css light-text">
                  <input
                    className="light-text no-style-input"
                    type="text"
                    name="name"
                    defaultValue={topic.name}
                    onChange={handleChange}
                    id="name"
                    placeholder="Topic name"
                    required
                  />
                </div>
                <div className="text-input-css light-text mt-1">
                  <input
                    className="light-text no-style-input"
                    type="text"
                    name="points"
                    defaultValue={topic.points}
                    onChange={handleChange}
                    id="points"
                    placeholder="Enter topic points"
                    required
                  />
                </div>
                <div className="text-input-css light-text mt-1">
                  <FormGroup>
                    <p className="light-text" htmlFor="description">
                      Topic Description
                    </p>
                    <textarea
                      style={{ background: "inherit" }}
                      className="blue-bg light-text"
                      type="text"
                      name="description"
                      defaultValue={topic.description}
                      onChange={handleChange}
                      id="description"
                      placeholder="Please add a description about the topic"
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" className="float-left">
                <Col style={{ borderRadius: "25px" }} xs="12" sm="12" md="12" className="text-center theme-color border p-5">
                  <Col xs="12" sm="6" md="12">
                    <Button onClick={() => setOpenResource("video")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                      {" "}
                      Add Videos
                    </Button>
                    <Button onClick={() => setOpenResource("blog")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                      {" "}
                      Add Blogs
                    </Button>
                    <Button onClick={() => setOpenResource("document")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                      {" "}
                      Add Documents
                    </Button>
                    <Button onClick={() => setOpenResource("image")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                      {" "}
                      Add Images
                    </Button>
                    <Button onClick={() => setOpenResource("link")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                      {" "}
                      Add Links
                    </Button>
                    <Button onClick={() => setOpenResource("quiz")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                      {" "}
                      Add Quizzes
                    </Button>
                  </Col>
                </Col>
              </Col>
            </Col>
            <Button type="submit" color="warning">
              Save
            </Button>{" "}
          </Form>
          <Button onClick={() => navigate(`/app/default-course-templates/${location.template_id}/stages/${location.stage_id}/modules/view/`)} variant="danger">
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CreateTopic
