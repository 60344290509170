import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import AvatarCircle from "../../custom/AvatarCircle"
import ToolTipForBarGraph from "../../InsightsReports/barGraphToolTip"
import EngagementScoresBar from "./engagementScoresBar"

const FacilitatorEngagementCard = ({ facilitator }) => {
  const getTotalScore = () => {
    const total_score = facilitator?.engagement_scores.reduce((total, element) => total + element.percentage, 0) || 0
    if ((total_score + "").includes(".")) {
      return total_score.toFixed(2)
    }
    return total_score
  }

  return (
    <div className={`d-inline-flex px-2 py-2 mb-1 br-9px align-items-center justify-content-between w-100`}>
      <div className="d-inline-flex align-items-center w-45per">
        <div className="position-relative pointer">
          <AvatarCircle avatar={facilitator.profile_pic} name={facilitator.full_name} size={`30px`} className={`mr-2`} />
        </div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip placement="top" className="classroom-tooltip" id={`"tooltip-${1}"`}>
              {facilitator.full_name}
            </Tooltip>
          }
        >
          <p className={`fs-14px pointer pb-1 mb-0 ellipse pr-2`}>{facilitator.full_name}</p>
        </OverlayTrigger>
      </div>

      <EngagementScoresBar engagement_scores={facilitator?.engagement_scores} className={`ml-auto w-40per`} />
      <span className="fs-12px ml-auto pl-2 text-right w-15per">{getTotalScore()}%</span>
    </div>
  )
}

export default FacilitatorEngagementCard
