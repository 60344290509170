import React, { useState } from "react"

// import { isBrowser } from "../../services/developer_mode"

// const kaltura = require('kaltura-client');

// const config = new kaltura.Configuration();
// const config = new kaltura.Configuration();
// const client = new kaltura.Client(config);

// const myExtScript = require('../../../express-recorder.js')

// const strm = {}

const MediaCreateVideo = (location) => {
  // let shouldStop = false;
  // let stopped = false;
  // const downloadLink = document.getElementById('download');
  // const stopButton = document.getElementById('stop');

  // let [options, setOptions] = useState({
  //   "ks": "djJ8MzAzMDI1MXzA5YS1Y80F57nbRqJEh5qREAHm4idAq1Bzz2fZDl73uCUih4dU-yuKFL8Z2OlJrPV6Jo_ADfyBtxoXifgFoOlQ",
  //   "serviceUrl": "https://www.kaltura.com",
  //   "playerUrl": "https://cdnapisec.kaltura.com",
  //   "app": "leanpitch",
  //   "conversionProfileId": null,
  //   "partnerId": "3030251",
  //   "entryName": "",
  //   "uiConfId": 46386761
  // })

  // if (isBrowser() && document.readyState === "complete"){

  let [devices, setDevices] = useState([])
  let blobs
  let rec
  let stream
  let preferredDevice = 0

  let videoElement = document.getElementById("videoElement")
  let download = document.getElementById("download")
  let captureBtn = document.getElementById("captureBtn")
  let toggleBtn = document.getElementById("toggleBtn")
  let startBtn = document.getElementById("startBtn")
  let stopBtn = document.getElementById("toggleBtn")

  fetchDevice()

  async function fetchDevice() {
    setDevices((await navigator.mediaDevices.enumerateDevices()).filter((device) => device.kind === "videoinput"))
  }

  let getStream = async () => {
    const videoConstraint = { width: 1280, height: 720, deviceId: devices[preferredDevice % devices.length].deviceId }
    return await navigator.mediaDevices.getUserMedia({ video: videoConstraint, audio: true })
  }

  if (captureBtn) {
    captureBtn.onclick = async () => {
      stream = await getStream()
      // strm = stream
      console.log(stream, "stream", videoElement)

      setTimeout(function () {
        videoElement.srcObject = stream
      }, 1000)

      blobs = []
      download.style.display = "none"

      rec = new MediaRecorder(stream, { mimeType: "video/webm; codecs=vp9,opus" })
      rec.ondataavailable = (e) => blobs.push(e.data)
      rec.onstop = async () => {
        let blob = new Blob(blobs, { type: "video/webm" })
        let url = window.URL.createObjectURL(blob)
        download.href = url
        download.download = "test.webm"
        download.style.display = "block"
      }

      startBtn.disabled = false
      captureBtn.disabled = true
    }
  }

  if (toggleBtn) {
    toggleBtn.onclick = async () => {
      preferredDevice++
      stream = await getStream()
      videoElement.srcObject = stream
    }
  }

  if (startBtn) {
    startBtn.onclick = () => {
      startBtn.disabled = true
      stopBtn.disabled = false
      rec.start()
    }
  }

  if (stopBtn) {
    stopBtn.onclick = () => {
      captureBtn.disabled = false
      startBtn.disabled = true
      stopBtn.disabled = true

      rec.stop()
      stream.getTracks().forEach((s) => s.stop())
      videoElement.srcObject = null
      stream = null
    }
  }
  return (
    <React.Fragment>
      <h1>Abhishek's Camera Recorder</h1>
      <video id="videoElement" autoplay style={{ width: 1280, height: 720 }}></video>
      <br />
      <button id="toggleBtn">Toggle Camera</button>
      <button id="captureBtn">Capture</button>
      <button id="startBtn" disabled>
        Start Recording
      </button>
      <button id="stopBtn" disabled>
        Stop Capture
      </button>
      <a id="download" href="#" style={{ display: "none" }}>
        Download
      </a>
    </React.Fragment>
  )
}

export default MediaCreateVideo
