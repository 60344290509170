import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { isBrowser } from "../../services/developer_mode"

const ViewTraining = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [training, setTraining] = useState({})

  // console.log(location,course, "location.dilsjdio")
  // const { state = {} } = location

  useEffect(() => {
    fetchTraining()
  }, [])

  async function fetchTraining() {
    // console.log("fetchGroups called")
    // setCompanyLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/trainings/${location.id}/`, { headers: login_credentials }).then((res) => {
      setTraining(res.data)
      // setCompanyLoader(true)
    })
  }

  return (
    <div>
      <SEO title="Training Creation" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>view training</h4>
          <Col>
            <div>
              <Row>
                <Col xs="12" sm="6" md="12">
                  <FormGroup>Name: {training.name}</FormGroup>
                </Col>
                <Col xs="12" sm="6" md="12">
                  <FormGroup>Start Date: {training.start_date}</FormGroup>
                </Col>
                <Col xs="12" sm="6" md="12">
                  <FormGroup>End Date: {training.end_date}</FormGroup>
                </Col>
                <Col xs="12" sm="6" md="12">
                  <FormGroup>price: {training.price}</FormGroup>
                </Col>
                <Col xs="12" sm="6" md="12">
                  <FormGroup>sequence: {training.sequence}</FormGroup>
                </Col>
                <Col xs="12" sm="6" md="12">
                  <FormGroup>Course Template: {training.default_course_template}</FormGroup>
                </Col>
              </Row>
            </div>
          </Col>
          <Button onClick={() => navigate(`/app/companies/${location.company_id}/courses/view/${location.course_id}`)} variant="danger">
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default ViewTraining
