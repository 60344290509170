import React, { useContext, useEffect, useState } from "react"
import { Button, Col, Dropdown, Form, FormControl, InputGroup, Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap"
import Styles from "../../../../../../../modularscss/upload.module.scss"
import StylesM from "../../../../../../../components/upload-resource.module.scss"
import CancelWhite from "../../.../../../../../../../images/svgs/close.svg"
import DeleteIcon from "../../.../../../../../../../images/svgs/delete.svg"
import CheckIcon from "/src/images/svgs/check-white.svg"
import UploadVideo from "/src/images/svgs/video-white-icon.svg"
import Elipsis from "/src/images/svgs/threedots.svg"
import LinkIcon from "/src/images/svgs/feather-link.svg"
import ArrowDropUp from "../../.../../../../../../../images/svgs/arrow-dropup-white-circle.svg"
import ArrowDown from "../../.../../../../../../../images/svgs/arrow-dropdown-white-circle.svg"
import Image from "../../../../../../custom/Image"
import Loader from "../../../../../../custom/Loader"
import axios from "axios"
import { getRequest } from "../../../../../../../services/rest_service"
import PropTypes from "prop-types"
import { GlobalContext } from "../../../../../../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../../../../../../stores/global_store/GlobalActions"

const UploadVideoModal = (props) => {
  const [notificationData, dispatch] = useContext(GlobalContext)

  const { showVideoModal, setShowVideoModal, files, setFiles, videos_data, setVideosData } = props
  const [showSection, setShowSection] = useState("upload_files")
  const [link_index, setLinkIndex] = useState(1)
  const [videoArray, setVideoArray] = useState({})
  const [video_loading, setVideoLoading] = useState([])
  const [selected_index, setSelectedIndex] = useState(-1)
  const [video, setVideo] = useState({})
  const [fileCreated, setFileCreated] = useState(false)
  const [loader, setLoader] = useState({})
  const [defaultLink, setDefaultLink] = useState(true)
  const [highlight, setHighlight] = useState("unhighlight")
  const [undefinedFiles, setUndefinedFiles] = useState(0)
  const [addMore, setAddMore] = useState(true)
  const [showDefineNow, setShowDefineNow] = useState(true)
  const [typingTimer, setTypingTimer] = useState("") //timer identifier
  const [currentLinkIndex, setCurrentLinkIndex] = useState(-1)
  const [doneTypingInterval, setDoneTypingInterval] = useState(1500) //time in ms, 5 second for example
  const [url, setUrl] = useState("")

  useEffect(() => {
    if (showDefineNow && files.length > 0) {
      setShowDefineNow(false)
    } else {
      if (!showDefineNow && Object.keys(videoArray).filter((key) => videoArray[key] === false).length === link_index) {
        setShowDefineNow(true)
      }
    }
  }, [files])

  const closeModal = () => {
    setShowVideoModal(false)
  }

  const videoLoadEnable = (index, value) => {
    let v_loading = { ...video_loading }
    v_loading[index] = value
    setVideoLoading(v_loading)
  }
  const parseVideo = (url) => {
    url.match(
      /(http:\/\/|https:\/\/|)?(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
    )
    //r.exec(url)

    if (RegExp.$3.indexOf("youtu") > -1) {
      var type = "youtube"
    } else if (RegExp.$3.indexOf("vimeo") > -1) {
      var type = "vimeo"
    } else {
      var type = "link"
    }

    return {
      type: type,
      id: RegExp.$6,
    }
  }
  const fetchVideoData = async (event) => {
    let parsed_video = parseVideo(url)
    if (showSection === "upload_links" && parsed_video.type === "youtube") {
      let res = await getRequest(`https://www.youtube.com/oembed?url=${url}&format=json`)
      if (res.success) {
        if (res.data.title !== undefined) {
          videos_data[currentLinkIndex] = res.data
          videos_data[currentLinkIndex]["url"] = url
          videos_data[currentLinkIndex]["channel"] = "youtube"
          videos_data[currentLinkIndex]["video_id"] = parsed_video.id
          videos_data[currentLinkIndex]["duration"] = res.data.duration ? res.data.duration : 0
          videos_data[currentLinkIndex]["name"] = res.data.title
          videos_data[currentLinkIndex]["display_name"] = res.data.title
          videos_data[currentLinkIndex]["id"] = Math.random()

          videoLoadEnable(currentLinkIndex, false)
          setFileCreated(true)
          let v_array = { ...videoArray }
          v_array[currentLinkIndex] = true
          setVideosData(videos_data)
          let videos_data_links = videos_data.filter((video, index) => {
            if (video === videos_data[currentLinkIndex]) {
              let videoWithId = { ...video, id: Math.random() }
              let videoBlob = new Blob([JSON.stringify(videoWithId)], { type: "application/json" })
              const fileVideo = new File([videoBlob], videoWithId.name, { type: "application/json" })
              return fileVideo
            }
          })
          const videoFile = new File([new Blob([JSON.stringify(videos_data_links[0])], { type: "application/json" })], videos_data_links[0].name, {
            type: "application/json",
          })
          setFiles([...files, videoFile])
          setVideoArray(v_array)
          setAddMore(false)
          setSelectedIndex(currentLinkIndex)
          if (showDefineNow) {
            setShowDefineNow(false)
          }
        } else {
          videoLoadEnable(currentLinkIndex, false)

          dispatch({
            type: SET_NOTIFICATION_DATA,
            payload: { type: "error", title: `We could not fetch the details. Please check your privacy settings for this video on youtube.` },
          })
        }
      } else {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "error", title: `Error while fetching video details.` },
        })
      }
    } else if (showSection === "upload_links" && parsed_video.type === "vimeo") {
      axios
        .get(`https://vimeo.com/api/oembed.json?url=${url}`, {
          "Access-Control-Allow-Origin": "*",
        })
        .then((res) => {
          if (res.data.title !== undefined) {
            videos_data[currentLinkIndex] = res.data
            videos_data[currentLinkIndex]["url"] = url
            videos_data[currentLinkIndex]["channel"] = "vimeo"
            videos_data[currentLinkIndex]["video_id"] = parsed_video.id
            videos_data[currentLinkIndex]["duration"] = res.data.duration ? res.data.duration : 0
            videos_data[currentLinkIndex]["name"] = res.data.title
            videos_data[currentLinkIndex]["display_name"] = res.data.title
            videos_data[currentLinkIndex]["id"] = Math.random()
            videoLoadEnable(currentLinkIndex, false)
            setFileCreated(true)
            let v_array = { ...videoArray }
            v_array[currentLinkIndex] = true
            setVideosData(videos_data)
            setVideoArray(v_array)
            setAddMore(false)
            setSelectedIndex(currentLinkIndex)
            let videos_data_links = videos_data.filter((video, index) => {
              if (video === videos_data[currentLinkIndex]) {
                let videoBlob = new Blob([JSON.stringify(video)], { type: "application/json" })
                const fileVideo = new File([videoBlob], "video.json", { type: "application/json" })
                return fileVideo
              }
            })
            const videoFile = new File([new Blob([JSON.stringify(videos_data_links[0])], { type: "application/json" })], videos_data_links[0].name, {
              type: "application/json",
            })
            setFiles([...files, videoFile])
            setVideoArray(v_array)
            setAddMore(false)
            setSelectedIndex(currentLinkIndex)
            if (showDefineNow) {
              setShowDefineNow(false)
            }
          } else {
            videoLoadEnable(currentLinkIndex, false)

            dispatch({
              type: SET_NOTIFICATION_DATA,
              payload: { type: "error", title: `We could not fetch the details. Please check your privacy settings for this video on vimeo` },
            })
          }
        })
    } else {
      dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "error", title: `We could not fetch the details for the type you selected.` },
      })
    }
  }
  const validateAndAdd = (event) => {
    event.preventDefault()
    event.stopPropagation()
    let new_files = event.target.files
    let filex = [],
      err = 0
    for (let i = 0; i < new_files.length; i++) {
      //Check if Right Type has the right files uploaded
      if (showSection === "video" && new_files[i].type.indexOf("video/") === -1) {
        err = 1
      }
      if (err) {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "error", title: new_files[i].name + ` - does not belong to type (${showSection}) you selected.` },
        })
      }
      if (!err && parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else if (!err) {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "error", title: new_files[i].name + " has size more than 200MB." },
        })
      }
    }
  }

  const deleteFiles = (file) => {
    let updatedFiles = files.filter((f) => {
      if (f.name !== (file.title || file.name)) {
        return
      }
    })
    setFiles(updatedFiles)
  }
  const removeVideo = (e, i, type) => {
    deleteFiles(videos_data[i])
    let updatedVideoData = videos_data.filter((video) => {
      if (video.id !== videos_data[i].id) {
        return video
      }
    })
    if (link_index !== 1) {
      setLinkIndex(link_index - 1)
    }
    setSelectedIndex(-1)
    setVideosData(updatedVideoData)
  }
  const showVideoLinkData = (i) => {
    let currentFile = videos_data[i]
    let imgObj = videos_data[i].thumbnail_url || videos_data[i].icon
    return (
      <div className="d-flex flex-row align-items-center text-left p-0 bg-4F6080 br-9px col-12 d-inline-flex w-100">
        <Image src={imgObj} className={` object-fit-contain mb-0 br-9px ${imgObj ? "h-auto" : "h-0"} w-3rem`} />
        <p className="fs-14px turient-header-text-color pl-4 mb-0">{currentFile.display_name}</p>
      </div>
    )
  }
  const addMoreLinks = (value) => {
    if (value) {
      setLinkIndex(link_index + 1)
      setAddMore(true)
      setDefaultLink(false)
      setSelectedIndex(-1)
    }
  }
  const videoLinkHeader = (i, type = null) => {
    let textBoxPH = "Add link here"
    return (
      <InputGroup key={`link_div-${i}`} id={`link_div-${i}`} className={`py-1 bg-42506C  align-items-center ${selected_index !== i ? "br-9px" : "br-9900px"}`}>
        <InputGroup.Prepend>
          <InputGroup.Text id={`basic-addon-${i}`} className={`bg-transparent border-0 pr-0`}>
            <Image src={LinkIcon} alt="link" className={`m-auto link-color mb-3 h-1rem`} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        {type === null ? (
          <FormControl
            placeholder={textBoxPH}
            aria-label="Username"
            aria-describedby={`basic-addon-${i}`}
            id={i}
            className={`bg-transparent border-0 pl-2 link-color shadow-none ${StylesM.upload_input} ${StylesM.font_14}`}
            onKeyUp={(e) => {
              clearTimeout(typingTimer)
              setCurrentLinkIndex(i)
              setUrl(e.target.value)
              videoLoadEnable(i, true)
              setTypingTimer(setTimeout(fetchVideoData, doneTypingInterval))
            }}
            onKeyDown={(e) => {
              clearTimeout(typingTimer)
              setUrl(e.target.value)
              videoLoadEnable(i, false)
            }}
            onChange={(e) => {
              clearTimeout(typingTimer)
              setCurrentLinkIndex(i)
              setUrl(e.target.value)
              videoLoadEnable(i, true)
              setTypingTimer(setTimeout(fetchVideoData, doneTypingInterval))
            }}
          />
        ) : (
          <p className={`bg-transparent border-0 link-color pl-2 shadow-none m-0 align-self-center ${StylesM.upload_input} ${StylesM.font_14}`}>
            {videos_data[i].name || videos_data[i].link}
          </p>
        )}
        <InputGroup.Append className={type === null ? `` : `ml-auto`}>
          {video_loading[i] ? (
            <InputGroup.Text className={`bg-transparent border-0`} id={`basic-addon${i}`}>
              <Spinner animation="border" variant="danger" size="sm" />
            </InputGroup.Text>
          ) : null}

          <Image
            src={selected_index !== i ? ArrowDown : ArrowDropUp}
            alt="arrow up"
            className="mr-3 align-self-center pointer w-1rem h-1rem turient-header-text-color"
            onClick={(e) => {
              e.preventDefault()
              setSelectedIndex(selected_index === i ? -1 : i)
              setVideo(videos_data[i])
            }}
          />
        </InputGroup.Append>
        {type === null ? (
          ""
        ) : (
          <Dropdown className="breakout-groups pointer float-right mr-3 ml-3">
            <Dropdown.Toggle className="border-none p-0 shadow-none bg-transparent" id="dropdown-basic">
              <Image src={Elipsis} alt="option" className="turient-header-text-color h-1rem object-fit-contain w-1" />
            </Dropdown.Toggle>
            <Dropdown.Menu className={`br-9px breakout-groups min-w-4rem ${Styles.min_fit_width}`}>
              <Dropdown.Item
                onClick={(e) => {
                  e.preventDefault()
                  removeVideo(e, i, videos_data[i].type)
                }}
                className={`${StylesM.dropitem} text-white fs-14px px-2 py-1 d-inline-flex`}
              >
                <Image src={DeleteIcon} alt={`topic-delete`} className={`my-auto object-fit-contain h-13px pr-3`} />
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </InputGroup>
    )
  }
  const uploadFiles = () => {
    let videoFiles = [...files],
      text = "Video files",
      accept = "video/*"
    videoFiles = videoFiles.filter((d) => {
      if (d.type !== "video") {
        return d
      }
    })
    return (
      <div
        id={`drop-area`}
        className={`${Styles.drop_area} m-0 px-0 py-0 bg-transparent ${highlight === "highlight" ? `` : `border-0`} text-center ${
          files.length === 0 ? `` : `border-0`
        }`}
      >
        <div className={`w-100 col-12 pb-2`}>
          {videoFiles && videoFiles.length === 0 ? (
            <Form className="my-form mt-3 mb-2">
              <React.Fragment>
                <p className={`fs-14px mb-0 `}>Upload files.</p>
                <p className="fs-14px mb-2">Files Supported: MP4/ .mov/ .avi</p>
              </React.Fragment>
              <input
                className={`d-none`}
                type="file"
                id="fileElem"
                multiple
                accept={accept}
                onChange={(e) => {
                  validateAndAdd(e)
                }}
              />
              <label
                className={`button box-shadow-00000029 btn border-0 px-5 bg-586886 br-36px turient-header-text-color border_on_hover ${StylesM.font_14}`}
                htmlFor="fileElem"
              >
                Browse {undefinedFiles > 0 ? ` more files` : ``}
              </label>
            </Form>
          ) : (
            <div className="col-12 d-inline-flex w-100 flex-wrap">
              <div id="gallery" className={`mt-2 w-100 ${StylesM.gallery}`}>
                {Object.keys(videoFiles).map((item, index) => {
                  if (videoFiles[item]?.type?.indexOf("video") > -1) {
                    return (
                      <div key={index} className="d-flex flex-row  align-items-center justify-content-between p-2 bg-42506C mb-2 br-9px">
                        <div className="d-flex flex-row align-items-center">
                          <Image src={videoFiles[item].thumbnail_url || UploadVideo} alt="Video Icon" className="mb-0 mr-0 w-2rem" />
                          <p className="fs-14px pl-4 mb-0">{videoFiles[item].name}</p>
                        </div>
                        <Dropdown className="breakout-groups pointer float-right ">
                          <Dropdown.Toggle className="border-none p-0 shadow-none bg-transparent" id="dropdown-basic">
                            <Image src={Elipsis} alt="option" className="turient-header-text-color h-1rem object-fit-contain w-1 mb-1 mr-1" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className={`br-9px breakout-groups min-w-4rem ${Styles.min_fit_width}`}>
                            <Dropdown.Item
                              onClick={(e) => {
                                e.preventDefault()
                                deleteFiles(videoFiles[item])
                              }}
                              className={`${StylesM.dropitem} text-white fs-14px px-2 py-1 d-inline-flex`}
                            >
                              <Image src={DeleteIcon} alt={`topic-delete`} className={`my-auto h-13px object-fit-contain w-auto`} />
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )
                  }
                })}
                <React.Fragment>
                  <p className={`fs-14px mb-0 `}>Upload files.</p>
                  <p className="fs-14px mb-2">Files Supported: MP4/ .mov/ .avi</p>
                </React.Fragment>
                <input
                  className={`d-none`}
                  type="file"
                  id="fileElem"
                  multiple
                  accept={accept}
                  onChange={(e) => {
                    validateAndAdd(e)
                  }}
                />
                <label
                  className={`button box-shadow-00000029 btn border-0 px-5 bg-586886 br-36px turient-header-text-color border_on_hover ${StylesM.font_14}`}
                  htmlFor="fileElem"
                >
                  Browse {undefinedFiles > 0 ? ` more files` : ``}
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
  const showInputs = () => {
    const buttons = []
    let textBoxPH = "Add Vimeo/YouTube Link"
    for (let i = 0; i < link_index; i++) {
      if (videos_data[i]) {
        let background_url = videos_data[i].thumbnail_url
        buttons.push(
          <Col xs="12" sm="12" md="12" lg="12" key={`link_div-${i}`} className={`p-0 mb-3 pointer position-relative br-9px bg-42506C`}>
            <div>{videoLinkHeader(i, "saved_link")}</div>

            <div className={`${selected_index === i ? `p-2 bg-42506C br-0099px` : `d-none`} `}>{video ? showVideoLinkData(i) : ``}</div>
          </Col>
        )
      } else if (defaultLink || videoArray[i] !== false) {
        buttons.push(
          <div className="mb-3 bg-42506C br-9px" key={`link_div-${i}`}>
            {videoLinkHeader(i)}
            <div className={`${selected_index === i ? `d-inline-flex w-100` : `d-none`} border-top-E0E2E5 h-8rem `}>
              <p className={`${StylesM.font_14} m-auto link-color`}>Details about the links appears here. You can edit the metadata before saving</p>
            </div>
          </div>
        )
      } else if (Object.keys(videoArray).filter((key) => videoArray[key] === false).length === link_index) {
        return (
          <InputGroup key={`link_div-${i}`} className="mb-3 bg-42506C" id={`link_div-${i}`}>
            <InputGroup.Prepend>
              <InputGroup.Text id={`basic-addon-${i}`} className={`bg-transparent border-0 pr-0`}>
                <Image src={LinkIcon} alt="link" className={`m-auto link-color mb-3 h-1rem`} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder={textBoxPH}
              aria-label="Username"
              aria-describedby={`basic-addon-${i}`}
              id={i}
              className={`bg-transparent border-0 link-color pl-2 shadow-none ${StylesM.upload_input}`}
              onKeyUp={(e) => {
                clearTimeout(typingTimer)
                setCurrentLinkIndex(i)
                setUrl(e.target.value)
                videoLoadEnable(i, true)
                setTypingTimer(setTimeout(fetchVideoData, doneTypingInterval))
              }}
              onKeyDown={(e) => {
                clearTimeout(typingTimer)
                setUrl(e.target.value)
                videoLoadEnable(i, false)
              }}
              onChange={(e) => {
                clearTimeout(typingTimer)
                setCurrentLinkIndex(i)
                setUrl(e.target.value)
                videoLoadEnable(i, true)
                setTypingTimer(setTimeout(fetchVideoData, doneTypingInterval))
              }}
            />
            {video_loading[i] ? (
              <InputGroup.Append>
                <InputGroup.Text className={`bg-transparent border-0`} id={`basic-addon${i}`}>
                  <Spinner animation="border" variant="danger" size="sm" />
                </InputGroup.Text>
              </InputGroup.Append>
            ) : null}
          </InputGroup>
        )
      }
    }
    return buttons
  }
  return (
    <Modal
      show={showVideoModal}
      centered
      size="lg"
      scrollable={true}
      className={`${StylesM.classroom_delete_modal} ${files.length > 0 ? `modal-content-bg-transparent` : `classroom-resource-delete-modal`} on-top`}
    >
      <Modal.Header className={`${StylesM.delete_modal_header} ${files.length > 0 ? `border-01` : ``} ${files.length > 0 ? StylesM.bg_303C54 : ``} w-100`}>
        {files.length > 0 ? (
          <div className="d-flex flex-row align-items-center justify-content-between w-100">
            <p className="turient-header-text-color fs-12px ">
              {files.length} file{files.length <= 1 ? "" : "s"} added
            </p>
            <Image
              onClick={(e) => {
                e.preventDefault()
                closeModal()
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`my-auto  pointer object-fit-contain h-1rem`}
            />
          </div>
        ) : (
          <div className="d-flex align-items-center flex-row justify-content-between w-100 ">
            <p className="turient-header-text-color fs-18px font-weight-bold">{"Upload/add videos content"}</p>
            <Image
              onClick={(e) => {
                e.preventDefault()
                closeModal()
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`my-auto mr-1 pointer h-13px`}
            />
          </div>
        )}
      </Modal.Header>
      <Modal.Body className={`align-items-center ${files.length ? "#303C54" : "#212C42"} br-0099px bg-212C42`}>
        <>
          <Col lg="12" md="12" sm="12" xs="12" className={`px-0 br-9px turient-header-text-color bg-303C54`}>
            <div
              onClick={() => {
                setShowSection(showSection === "upload_files" ? "" : "upload_files")
              }}
              className="py-2 px-3 d-inline-flex w-100 align-items-center pointer"
            >
              <p className="py-0 my-0 fs-16px">Browse Video to upload</p>
              <Image
                src={showSection !== "upload_files" ? ArrowDown : ArrowDropUp}
                alt="arrow down"
                className="ml-auto pointer w-2rem h-1rem turient-header-text-color"
              />
            </div>

            <div
              className={`text-center align-items-center border-top-6C7382 ${showSection !== "upload_files" ? "d-none" : ""} ${
                files.length > 0 ? `py-0` : `py-3 px-3`
              }`}
            >
              {uploadFiles()}
            </div>
          </Col>

          <>
            <Col className="my-2 d-grid px-0 bg-303C54 turient-header-text-color br-9px" lg="12" md="12" sm="12" xs="12">
              <div
                onClick={(e) => {
                  e.preventDefault()
                  setShowSection(showSection === "upload_links" ? "" : "upload_links")
                }}
                className="py-2 px-3 d-inline-flex w-100 align-items-center pointer"
              >
                <p className="py-0 my-0 fs-16px">Add external links</p>
                <Image
                  src={showSection !== "upload_links" ? ArrowDown : ArrowDropUp}
                  alt={showSection !== "upload_links" ? "arrow down" : "arrow up"}
                  className="ml-auto pointer w-2rem h-1rem turient-header-text-color"
                />
              </div>
              {showSection === "upload_links" ? (
                <div className={`p-3 border-top-6C7382`}>
                  <div id="add_links">{showInputs()}</div>
                  <div className={`text-center align-items-center`}>
                    {addMore ? (
                      <OverlayTrigger key={`top`} placement={`top`} overlay={<Tooltip>Disabled as you have one already</Tooltip>}>
                        <Button
                          onClick={(e) => {
                            e.preventDefault()
                            addMoreLinks(!addMore)
                            setLinkIndex(link_index)
                            setAddMore(true)
                            setDefaultLink(false)
                            setSelectedIndex(-1)
                          }}
                          className={`float-left bg-586886 border border-0 turient-header-text-color box-shadow-00000029 br-36px ${StylesM.font_14} ${
                            showDefineNow ? `` : `mb-3`
                          } ${addMore ? `border-0` : `border_on_hover`}`}
                        >
                          Add More Links
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      <Button
                        onClick={(e) => {
                          e.preventDefault()
                          setLinkIndex(link_index + 1)
                          setAddMore(true)
                          setDefaultLink(false)
                          setSelectedIndex(-1)
                        }}
                        className={`float-left box-shadow-00000029 br-36px turient-header-text-color ${StylesM.font_14} ${
                          addMore ? `border-0 bg-353B48` : `border_on_hover bg-586886`
                        } ${showDefineNow ? `` : `mb-3`}`}
                        disabled={addMore}
                      >
                        Add More Links
                      </Button>
                    )}
                  </div>
                  <div className="d-flex p-2 float-left align-items-center">
                    {loader[`type2`] ? (
                      <Loader class="ml-3 mr-1" />
                    ) : (
                      <Image src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer object-fit-contain w-13px`} />
                    )}
                    <p className="fs-12px m-0 turient-header-text-color">All changes are saved</p>
                  </div>
                </div>
              ) : null}
            </Col>
          </>
        </>
      </Modal.Body>
    </Modal>
  )
}
UploadVideoModal.propTypes = {
  showVideoModal: PropTypes.bool.isRequired,
  setShowVideoModal: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  videos_data: PropTypes.array.isRequired,
  setVideosData: PropTypes.func.isRequired,
}

export default UploadVideoModal
