import React, { useEffect, useState, useCallback, useRef, useContext } from "react"
import _ from "lodash"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Dropdown from "react-bootstrap/Dropdown"
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Spinner from "react-bootstrap/Spinner"
import UploadFiles from "./upload-files2"
import Blog from "../blogs/create-edit"
import Modal from "react-bootstrap/Modal"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faEquals,
  faUpload,
  faChevronCircleUp,
  faChevronCircleDown,
  faChevronRight,
  faChevronLeft,
  faEllipsisV,
  faImage,
  faLink,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"

import Sidebar from "../../components/Sidebar/template-sidebar"
import { LazyLoadImage } from "react-lazy-load-image-component"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CropImage from "../../components/crop-image/crop-image"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"
import LayoutHeader from "../layoutHeader"
import QuestionStore from "../../stores/question_store/QuestionStore"
import Image from "../custom/Image"

import nocertificate from "../../images/svgs/nocertificate.svg"
import doc from "../../images/svgs/doc.svg"
import excel from "../../images/svgs/excel.svg"
import ppt from "../../images/svgs/ppt.svg"
import pdf from "../../images/svgs/pdf.svg"
import DeleteIcon from "../../images/svgs/delete.svg"

import Editor from "../custom/SunEditor"

import TrashCan from "../../images/svgs/trash-can-transparent.svg"
import CancelWhite from "../../images/svgs/close.svg"
import poll from "../../images/svgs/poll.svg"
import popQuiz from "../../images/svgs/popquiz.svg"
import checklist from "../../images/svgs/checklist.svg"
import casestudy from "../../images/svgs/casestudy.svg"
import video from "../../images/svgs/video.svg"
import link from "../../images/svgs/link.svg"
import turientimage from "../../images/svgs/turientimage.svg"
import blog from "../../images/svgs/blog.svg"
import quiz from "../../images/svgs/quiz.svg"

import WhiteBlogIcon from "../../images/svgs/blog-white-icon.svg"
import WhiteQuizIcon from "../../images/svgs/quiz-white-icon.svg"
import documents from "../../images/svgs/documentresWhite.svg"

import StylesB from "./course.module.scss"
import Styles from "../../modularscss/styles.module.scss"
import StylesM from "../../components/upload-resource.module.scss"
import Notification from "../custom/Notification"
import AssesmentWorkSpace from "../mural/mural-room-workspace/assesmentWorkspace"
import { HasEditPermission, HasReadPermission } from "../../utils/PermissionDataAccess"
import { COURSES } from "../../constants/permissionsConstant"
import { GlobalContext } from "../../stores/global_store/GlobalStore"

const getListStyle = (isDraggingOver) => ({
  background: "#212C42",
  padding: "12px",
  borderRadius: "0px 0px 8px 8px",
  overflowY: "auto",
})
const getResourceItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})

const CourseCertification = (location, props) => {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""
  const [globalState, globalStateDispatch] = useContext(GlobalContext)

  const [giveCertification, setGiveCertification] = useState("")
  const [assessments, setAssessments] = useState("outsideturient")
  const [course, setCourse] = useState({})
  const [certificate, setCertificate] = useState({})
  const [modalShow, setModalShow] = useState(false)
  const [cropData, setCropData] = useState("#")
  const [fileName, setFileName] = useState(null)
  const [activestate, setActiveState] = useState("course_certification")
  const [width, setWidth] = useState(1000)

  const [clickedExperience, setClickedExperience] = useState(false)
  const [clickedGuidelines, setClickedGuidelines] = useState(false)
  const [expirydate, setExpiryDate] = useState()
  const [certificateId, setCertificateId] = useState(null)
  const [courseName, setCourseName] = useState("")
  const [loader, setLoader] = useState(false)
  const [dataEntered, setDataEntered] = useState(false)
  const [hovered, setHovered] = useState(false)

  const [attendanceData, setAttendanceData] = useState("")
  const [certificationEligibility, setCertificationEligibility] = useState("")

  const [experienceRequirements, setExperienceRequirements] = useState("")
  const [additionalGuidelines, setAdditionalGuidelines] = useState("")

  const [showRichTextModal, setShowRichTextModal] = useState(false)
  const [activeInputField, setActiveInputFieeld] = useState(null)
  const [certData, setCertData] = useState([])
  const [resourceList, setResourceList] = useState([])
  const [assesmentGuidelines, setAssesmentGuidelines] = useState("")
  const [activeAssessmentId, setActiveAssessmentId] = useState("")

  const [activeResourceType, setActiveResourceType] = useState("")
  const [leftNavOpen, setLeftNavOpen] = useState(false)
  const [activeNavItem, setActiveNavItem] = useState("")
  const [allResourcesChanged, setAllResourcesChanged] = useState(false)

  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(false)

  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("")
  const [currentNavTab, setCurrentNavTab] = useState("")

  const [uploadInTopic, setUploadInTopic] = useState(null)
  const [uploadDataSequence, setUploadDataSequence] = useState(null)
  const [showSection, setShowSection] = useState("video")
  const [addBlog, setAddBlog] = useState(false)
  const [collapse, setCollapse] = useState(false)

  const [files, setFiles] = useState([])
  const [highlight, setHighlight] = useState("unhighlight")
  const [dnd, setDnd] = useState(true)

  const [dmodal, setDmodal] = useState(false)
  const [selectedDeletingTopic, setSelectedDeletingTopic] = useState([])
  const [notificationData, setNotificationData] = useState(null)

  const [assessmentCreationLoader, setAssessmentCreationLoader] = useState(false)

  const quizWin = useRef(null)
  const checklistWin = useRef(null)
  const casestudyWin = useRef(null)
  const isPermitted = HasEditPermission(COURSES) && HasReadPermission(COURSES)
  const isMuralEnabled = globalState.appData.data?.company?.mural_enabled

  const [activeFieldData, setActiveFieldData] = useState({
    id: "",
    placeholder: "",
    content: "",
  })

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("message", handleNewWinMessages, false)
      return () => {
        window.removeEventListener("message", handleNewWinMessages)
      }
    }
  }, [window])

  useEffect(() => {
    if (
      currentSelectedResourcesType === "poll" ||
      currentSelectedResourcesType === "pop-quiz" ||
      currentSelectedResourcesType === "checklist" ||
      currentSelectedResourcesType === "video" ||
      currentSelectedResourcesType === "document" ||
      currentSelectedResourcesType === "turientimage" ||
      currentSelectedResourcesType === "blog"
    ) {
      setDnd(false)
    } else {
      setDnd(true)
    }
  }, [currentSelectedResourcesType])

  function openNavWithResourceSection(section, type) {
    if (!leftNavOpen) {
      setLeftNavOpen(true)
    }
    if (activeNavItem !== section) {
      setActiveNavItem(section)
    }
    if (activeResourceType !== type) {
      setActiveResourceType(type)
    }
  }
  function toggleLeftNav(value) {
    if (value !== leftNavOpen) {
      setLeftNavOpen(value)
    }
  }
  function getImageToBeRendered(item) {
    let imgObj = turientimage,
      typeId = 2

    let typeOfFile = item?.resource_data?.file_type || 0
    if (item.resource_data?.avatar) {
      imgObj = item.resource_data.avatar
    }

    if (item.resource_type === "video") {
      imgObj = video
    } else if (item.resource_type === "blog") {
      imgObj = blog
    } else if (item.resource_type === "link") {
      imgObj = link
    } else if (item.resource_type === "quiz") {
      imgObj = quiz
      let dataType = item?.resource_data?.data_type || 0
      if (dataType === "1") {
        imgObj = poll
      } else if (dataType === "2") {
        imgObj = popQuiz
      }
    } else if (item.resource_type === "turientimage") {
      imgObj = faImage
      typeId = 1
      if (typeOfFile === "2" || typeOfFile === "5") {
        imgObj = doc
        typeId = 2
      } else if (typeOfFile === "3" || typeOfFile === "7") {
        imgObj = excel
        typeId = 2
      } else if (typeOfFile === "4" || typeOfFile === "6") {
        imgObj = ppt
        typeId = 2
      } else if (typeOfFile === "1") {
        imgObj = pdf
        typeId = 2
      }
    } else if (item.resource_type === "checklist") {
      imgObj = checklist
    } else if (item.resource_type === "casestudy") {
      imgObj = casestudy
    }
    return { typeId, imgObj }
  }

  function deleteResource(id, index, certId) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        fetchCertificationResources(certId)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function deleteAssessment(id, index = null) {
    if (index === null) {
      index = certData.findIndex((cert) => cert.id === id)
    }
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/student-certification-test-details/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        let all_data = [...certData]
        all_data.splice(index, 1)
        setCertData(all_data)
        handleClose()
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function handleDrop(e) {
    var dt = e.dataTransfer
    let new_files = dt.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        setNotificationData({
          type: "error",
          title: new_files[i].name + " has size more than 200MB.",
        })
      }
    }
  }
  function validateAndAdd(event) {
    let new_files = event.target.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        setNotificationData({
          type: "error",
          title: new_files[i].name + " has size more than 200MB.",
        })
      }
    }
  }
  function onDragEnter(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragLeave(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
  }
  function onDrop(event, id, data) {
    event.preventDefault()
    event.stopPropagation()
    setUploadInTopic(id)
    setUploadDataSequence(data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2))
    setHighlight("unhighlight")
    handleDrop(event)
  }

  useEffect(() => {
    fetchCourse()
  }, [])

  useEffect(() => {
    if (isBrowser()) {
      setWidth(window.innerWidth)
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
      })
      return () => {
        window.removeEventListener("resize", () => {})
      }
    }
  }, [])

  useEffect(() => {
    setHovered(false)
  }, [width])

  function sendQuery(event, name, value, certificate, course) {
    certificate[name] = value
    handleSubmit("none", certificate, course)
  }
  function updateData(event, name, value, certificate) {
    certificate[name] = value
    setCertificate(certificate)
  }

  const delayedQuery = useCallback(
    _.debounce((event, name, value, certificate, course) => sendQuery(event, name, value, certificate, course), 1000),
    []
  )

  const delayedQueryAssessment = useCallback(
    _.debounce((event, name, value, assessment, certData) => UpdateCertificationAssessmentData(event, name, value, assessment, certData), 1000),
    []
  )

  const lowdelayedQuery = useCallback(
    _.debounce((event, name, value, certificate) => sendQuery(event, name, value, certificate), 100),
    []
  )

  function onBlurData(name, value, certificate) {
    if (name !== null) {
      certificate[name] = value
      handleSubmit("none", certificate, course)
    }
  }

  async function fetchCertification(id) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/certifications/${id}/`, {
        headers: login_credentials,
      })
      .then((res) => {
        let cert_data = res.data
        setAttendanceData(cert_data["attendance_requirement"])
        setCertificationEligibility(cert_data["certification_eligibility"])
        setExperienceRequirements(cert_data["experience_requirements"])
        setAdditionalGuidelines(cert_data["additional_guidelines"])
        if (cert_data["assessment_outside_turient"] === false) {
          setAssessments("onturient")
        } else {
          setAssessments("outsideturient")
        }
        if (cert_data["test_site"] === "" || cert_data["test_site"] === null) {
          cert_data["test_site"] = ""
        }
        setCertificate(cert_data)
        setLoader(false)
        if (res.data.certificate_validity_choice === "0") {
          setExpiryDate("Years")
        } else if (res.data.certificate_validity_choice === "1") {
          setExpiryDate("Months")
        } else if (res.data.certificate_validity_choice === "2") {
          setExpiryDate("Weeks")
        } else if (res.data.certificate_validity_choice === "3") {
          setExpiryDate("Days")
        } else if (res.data.certificate_validity_choice === "4") {
          setExpiryDate("Lifetime")
        }
        if (res.data.avatar !== null) {
          var avatar = res.data.avatar.replace("https://turient-static-bucket-dev.s3.amazonaws.com/certifications/", "")
          avatar.replace("https://turient-static-bucket.s3.amazonaws.com/certifications/", "")
          var res1 = avatar.split("/")
          var res2 = res1[1]
          var res3 = res2.split("?")
          setFileName(res3[0])
        } else {
          setFileName(null)
        }
      })
  }
  useEffect(() => {
    if (certificate.id !== undefined) {
      fetchCertificationAssessmentData(certificate.id)
    }
  }, [certificate])
  useEffect(() => {
    if (certData && certData.id !== undefined) {
      fetchCertificationResources(certData.id)
    }
  }, [certData])
  async function fetchCertificationAssessmentData(id) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/student-certification-test-details/?certification=${id}`, { headers: login_credentials })
      .then((res) => {
        setCertData(res.data.results)
      })
  }
  async function UpdateCertificationAssessmentData(event, name, value, assessment, certData) {
    let formData = new FormData()
    for (let k in assessment) {
      if (k === name) {
        formData.append(k, value)
      } else {
        formData.append(k, assessment[k])
      }
    }
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/student-certification-test-details/${assessment.id}/`, formData, { headers: login_credentials })
      .then((res) => {
        let index = certData.findIndex((data) => data.id === assessment.id)
        let all_data = [...certData]
        if (index === -1) {
          all_data.push(res.data)
        } else {
          all_data[index] = res.data
        }
        setCertData(all_data)
        setLoader(false)
      })
  }
  async function CreateCertificationAssessmentData(certificate_id = null) {
    let id = certificate.id
    if (certificate_id != null) {
      id = certificate_id
    }
    setLoader(true)
    setAssessmentCreationLoader(true)
    let formData = new FormData()
    formData.append("title", "New Assessment")
    formData.append("certification", id)
    axios.post(process.env.GATSBY_REACT_APP_API_HOST + `/student-certification-test-details/`, formData, { headers: login_credentials }).then((res) => {
      setAssessmentCreationLoader(false)
      setActiveAssessmentId(res.data)
      fetchCertificationResources(res.data?.id)
      let all_data = [...certData]
      all_data.push(res.data)
      setCertData(all_data)
      setLoader(false)
    })
  }

  async function fetchCertificationResources(id) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/resources/?certification_assessment=${id}&paginate_by=15`, { headers: login_credentials })
      .then((res) => {
        let data = { ...resourceList }
        data[`resource_` + id] = res.data.results
        setResourceList(data)
      })
  }

  function handleChange(event) {
    event.preventDefault()
    certificate[event.target.name] = event.target.value
    let data = enteredFormData()
    if (data === true) {
      setDataEntered(true)
    } else {
      setDataEntered(false)
    }
  }

  function isEmpty(data) {
    if (data === "" || data === null || data === undefined) {
      return true
    }
    return false
  }

  function enteredFormData() {
    if (
      !isEmpty(certificate.certfication_body_name) ||
      !isEmpty(certificate.certfication_body_site) ||
      !isEmpty(certificate.name_of_the_certificate) ||
      !isEmpty(certificate.avatar) ||
      !isEmpty(certificate.certification_eligibility) ||
      !isEmpty(certificate.attendance_requirement) ||
      !isEmpty(certificate.experience_requirements) ||
      !isEmpty(certificate.additional_guidelines)
    ) {
      return true
    }
    return false
  }

  async function fetchCourse(fetch_certificate = true) {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/course-certification/${location.id}/`, { headers: login_credentials }).then((res) => {
      setCourse(res.data)
      let str = res.data.name
      if (str.length > 23) {
        let res1 = str.substring(0, 22)
        let res2 = res1 + "..."
        setCourseName(res2)
      } else {
        setCourseName(str)
      }
      if (res.data.is_certfication === true) {
        setGiveCertification("yes")
      } else {
        setGiveCertification("no")
      }
      setCertificateId(res.data.certification)
      if (fetch_certificate) {
        if (res.data.certification !== null) {
          fetchCertification(res.data.certification)
        } else {
          setCertificate({
            assessment_outside_turient: true,
            certification_validity_value: 10,
            certificate_validity_choice: "1",
            modules: null,
            course: location.id,
          })
          setExpiryDate("Months")
        }
      }
    })
  }

  const convertBase64ToFile = (file) => {
    let block = file.split(";")
    let contentType = block[0].split(":")[1]
    let realData = block[1].split(",")[1]
    let blob = b64toBlob(realData, contentType)
    return blob
  }
  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || ""
    sliceSize = sliceSize || 512
    let byteCharacters = atob(b64Data)
    let byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize)
      let byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      let byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    let blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  useEffect(() => {
    if (cropData !== certificate.avatar && cropData !== "#") {
      certificate["avatar"] = convertBase64ToFile(cropData)
      if (course.certification !== null) {
        handleSubmit(null, certificate, course)
      }
    }
  }, [cropData])

  useEffect(() => {
    if (course.certification !== null) {
      setLoader(true)
      delayedQuery(null, "attendance_requirement", attendanceData, certificate, course)
    }
  }, [attendanceData])
  useEffect(() => {
    if (course.certification !== null) {
      setLoader(true)
      delayedQuery(null, "certification_eligibility", certificationEligibility, certificate, course)
    }
  }, [certificationEligibility])

  useEffect(() => {
    if (course.certification !== null) {
      setLoader(true)
      delayedQuery(null, "experience_requirements", experienceRequirements, certificate, course)
    }
  }, [experienceRequirements])

  useEffect(() => {
    if (course.certification !== null) {
      setLoader(true)
      delayedQuery(null, "additional_guidelines", additionalGuidelines, certificate, course)
    }
  }, [additionalGuidelines])

  useEffect(() => {
    if (activeAssessmentId !== "") {
      setLoader(true)
      delayedQueryAssessment(null, "guidelines_for_assessment", assesmentGuidelines, activeAssessmentId, certData)
    }
  }, [assesmentGuidelines])

  function handleCreate(event, certificate) {
    if (event !== null && event !== "none") {
      event.preventDefault()
    }
    if (certificate.avatar == null && course.avatar == null && fileName == null) {
      setNotificationData({ type: "error", title: "Please fill all columns" })
    } else {
      if (course.certification === null && certificate.id === undefined) {
        let formData = new FormData()
        for (let k in certificate) {
          if (k === "avatar" && certificate.avatar !== null) {
            formData.append(
              "avatar",
              certificate["avatar"],
              fileName && fileName !== null
                ? fileName
                : `default_image_${certificate.name.toLowerCase().replaceAll(" ", "_")}` + certificate["avatar"]["type"].replace("image/", ".")
            )
          } else if (k === "assessment_outside_turient") {
            if (assessments === "onturient") {
              formData.append(k, false)
            } else {
              formData.append(k, true)
            }
          } else {
            formData.append(k, certificate[k])
          }
        }
        formData.append("attendance_requirement", attendanceData)
        formData.append("certification_eligibility", certificationEligibility)
        formData.append("experience_requirements", experienceRequirements)
        formData.append("additional_guidelines", additionalGuidelines)
        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/certification-body/create/", formData, { headers: login_credentials })
          .then((res) => {
            setCertificate(res.data)
            fetchCourse(false)
            CreateCertificationAssessmentData(res.data.id)
          })
          .catch((error) => {
            let error_messages = ""
            for (let key in error.response.data) {
              error_messages += error.response.data[key]
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()
              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            if (error_messages === "Enter a valid URL.") {
              setNotificationData({
                type: "error",
                title: "Please enter a valid certification body site",
              })
            } else {
              setNotificationData({ type: "error", title: error_messages })
            }
          })
      } else if (certificate?.id && certData.length === 0) {
        fetchCourse(false)
        CreateCertificationAssessmentData(certificate?.id)
      }
    }
  }
  function checkOnNext(event, certificate) {
    event.preventDefault()
    if (assessments === "outsideturient") {
      if (course.is_certfication) {
        handleSubmit(event, certificate, course)
        navigate(`/app/companies/${localStorage.company_id}/courses/course-content/${course.id}`, {
          state: { from_create: location.props.location.state.from_create },
        })
      } else if (course.is_certfication === false) {
        navigate(`/app/companies/${localStorage.company_id}/courses/course-content/${course.id}`, {
          state: { from_create: location.props.location.state.from_create },
        })
      }
    } else {
      let pass = false
      let text = "Please add Assessment"
      for (var i = 0; i < certData.length; i++) {
        if (certData[i].resource_count === 0) {
          if (resourceList["resource_" + certData[i]?.id]?.length > 0 || certData[i]?.mural_assessment?.activity_workspace?.mural?.id) {
            pass = true
            continue
          } else {
            pass = false
            text = "Please add resources to Assessment " + (i + 1)
            break
          }
        } else {
          pass = true
          continue
        }
      }
      if (certData.length > 0 && pass) {
        handleSubmit(event, certificate, course)
        navigate(`/app/companies/${localStorage.company_id}/courses/course-content/${course.id}`, {
          state: { from_create: location.props.location.state.from_create },
        })
      } else {
        setNotificationData({ type: "error", title: text })
      }
    }
  }

  function handleSubmit(event, certificate, course) {
    if (event !== null && event !== "none") {
      event.preventDefault()
    }
    if (course?.certification === null && ((certificate?.avatar && fileName !== null) || course?.avatar)) {
      let formData = new FormData()
      for (let k in certificate) {
        if (k === "avatar" && certificate["avatar"] instanceof Blob) {
          formData.append(
            "avatar",
            certificate["avatar"],
            fileName && fileName !== null
              ? fileName
              : `default_image_${certificate.name.toLowerCase().replaceAll(" ", "_")}` + certificate["avatar"]["type"].replace("image/", ".")
          )
        } else if (k === "assessment_outside_turient") {
          if (assessments === "onturient") {
            formData.append(k, false)
          } else {
            formData.append(k, true)
          }
        } else {
          formData.append(k, certificate[k])
        }
      }
      formData.append("attendance_requirement", attendanceData)
      formData.append("certification_eligibility", certificationEligibility)
      formData.append("experience_requirements", experienceRequirements)
      formData.append("additional_guidelines", additionalGuidelines)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/certification-body/create/", formData, { headers: login_credentials })
        .then((res) => {
          if (course.is_certfication !== false)
            navigate(`/app/companies/${localStorage.company_id}/courses/course-content/${course.id}`, {
              state: {
                from_create: location.props.location.state.from_create,
              },
            })
        })
        .catch((error) => {
          let error_messages = ""
          for (let key in error.response.data) {
            error_messages += error.response.data[key]
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          if (error_messages === "Enter a valid URL.") {
            setNotificationData({
              type: "error",
              title: "Please enter a valid certification body site",
            })
          } else {
            setNotificationData({ type: "error", title: error_messages })
          }
        })
    } else {
      let formData = new FormData()
      for (let k in certificate) {
        if (k === "avatar" && event === null && certificate["avatar"] instanceof Blob) {
          formData.append(
            "avatar",
            certificate["avatar"],
            fileName && fileName !== null ? fileName : `default_image${certificateId}` + certificate["avatar"]["type"].replace("image/", ".")
          )
        } else if (k !== "avatar") {
          formData.append(k, certificate[k])
        }
      }
      if (certificate.id && (certificate?.avatar || course?.avatar)) {
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + `/certifications/${certificate.id}/`, formData, { headers: login_credentials })
          .then((res) => {
            setLoader(false)
          })
          .catch((error) => {
            let error_messages = ""
            for (let key in error.response.data) {
              error_messages += error.response.data[key]
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()

              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            if (error_messages === "Enter a valid URL.") {
              setNotificationData({
                type: "error",
                title: "Please enter a valid certification body site",
              })
            } else {
              setNotificationData({ type: "error", title: error_messages })
            }
          })
      } else {
        if (certificate?.id || course.certification === null) {
          setNotificationData({ type: "error", title: "Please fill all columns" })
        }
      }
    }
  }

  function getCropDataProps(cropData, fileName) {
    setCropData(cropData)
    setFileName(fileName)
  }

  function updateActiveState(e) {
    setActiveState(e.target.id)
  }

  function uploadDocs(e, topic_id, data, upload, showSection) {
    e.preventDefault()
    e.stopPropagation()
    uploadDataProps(topic_id, certData, showSection)
    let domObj = document.getElementById("fileElem_" + topic_id)
    if (domObj && upload) {
      if (showSection === "turientimage") {
        domObj.accept = "image/*"
      }
      domObj.click()
    }
  }
  function uploadDataProps(topic_id, data, showSection = "") {
    setUploadInTopic(topic_id)
    let data_all = resourceList["resource_" + data?.id]
    if (data_all !== undefined) {
      setUploadDataSequence(data_all.length === 0 ? 0 : parseFloat(data_all[data_all.length - 1].sequence).toFixed(2))
    } else {
      setUploadDataSequence(0)
    }
    setShowSection(showSection)
  }
  function fileInputClicked(event, id, module_id, data) {
    setUploadInTopic(id)
    let data_all = resourceList["resource_" + data?.id]
    if (data_all !== undefined) {
      setUploadDataSequence(data_all.length === 0 ? 0 : parseFloat(data_all[data_all.length - 1].sequence).toFixed(2))
    } else {
      setUploadDataSequence(0)
    }
    window.removeEventListener("focus", handleFocusBack)
  }

  function handleFocusBack() {
    setUploadInTopic(null)
    setUploadDataSequence(null)
    window.removeEventListener("focus", handleFocusBack)
  }

  function clickedFileInput() {
    window.addEventListener("focus", handleFocusBack)
  }
  function openNewWin(section, type, topic_id, data) {
    let wnd
    let data_all = resourceList["resource_" + data?.id]
    let seq = 0
    if (data_all !== undefined) {
      seq = data_all.length === 0 ? 0 : parseFloat(data_all[data_all.length - 1].sequence).toFixed(2)
    }
    uploadDataProps(topic_id, data)

    if (type === "quiz") {
      wnd = window.open("/app/quizzes/create", type + "12")
      quizWin.current = { wnd, topic_id, seq, section }
    } else if (type === "casestudy") {
      wnd = window.open("/app/case-study/create", type + "12")
      casestudyWin.current = { wnd, topic_id, seq, section }
    } else if (type === "checklist") {
      wnd = window.open("/app/checklist/create", type + "12")
      checklistWin.current = { wnd, topic_id, seq, section }
    }
  }
  function getNewWinData(type) {
    let win = null
    if (type === "quiz") {
      win = quizWin.current
    } else if (type === "casestudy") {
      win = casestudyWin.current
    } else if (type === "checklist") {
      win = checklistWin.current
    }
    return win
  }

  function handleNewWinMessages(event) {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      if (event.data.instruction === "ready") {
        let win = getNewWinData(event.data.type)
        if (win && win.wnd) {
          //"Post",win.topic_id)
          win.wnd.postMessage(
            {
              instruction: {
                add_to_certificate: win.topic_id,
                sequence: win.seq,
              },
            },
            window.location.origin
          )
        }
      } else if (event.data.instruction === "payload") {
        let win = getNewWinData(event.data.type)
        if (win) {
          setTimeout(() => {
            fetchCertificationResources(win.topic_id)
          }, 1500) //Delay because the python backend thread takes time
          const evt = new CustomEvent("my-refresh-resources", {
            bubbles: true,
            detail: event.data.type,
          })
          document.dispatchEvent(evt)
        }
      }
    }
  }

  function refetchLeftSideResourcesBar(pActiveResType = "") {
    const evt = new CustomEvent("my-refresh-resources", {
      bubbles: true,
      detail: pActiveResType,
    })
    document.dispatchEvent(evt)
  }
  function showResources(datum) {
    let data = resourceList["resource_" + datum?.id]
    let certObj = certData[certData.findIndex((res) => res.id === datum?.id)]
    if (data) {
      return (
        <Droppable droppableId={`${datum?.id === null ? `` : datum?.id + `_`}certificate`} type={`resourceDraggable`}>
          {(provided, snapshot) => (
            <div
              id={`drop-area`}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDrop={(e) => onDrop(e, datum?.id, datum)}
              className={`bg-transparent px-4 ${collapse ? `${Styles.opacity_0} p-0` : `${Styles.opacity_1}`}`}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {data.map((item, index) => {
                let { typeId, imgObj } = getImageToBeRendered(item)
                return (
                  <>
                    <Draggable
                      key={item.id + `_topic_name_` + index}
                      draggableId={item.id.toString() + `-topic` + "_" + index.toString()}
                      index={index}
                      className={item.resource_data !== null ? "" : "d-none"}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={item.resource_data !== null ? "resource-item" : "d-none"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div
                            className={`pr-2 d-flex`}
                            style={{
                              color: `#E0E2E5`,
                              background: "#4F6080",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="d-flex" style={{ alignItems: "center" }}>
                              {item.resource_data ? (
                                item.resource_data.thumbnail_url ? (
                                  <LazyLoadImage
                                    className={`my-auto`}
                                    src={item.resource_data.thumbnail_url}
                                    alt={item.name}
                                    style={{
                                      width: `28px`,
                                      height: `20px`,
                                      fontSize: `12px`,
                                    }}
                                  />
                                ) : (
                                  <>
                                    {typeId === 1 ? (
                                      <FontAwesomeIcon
                                        icon={imgObj}
                                        className={`my-0`}
                                        style={{
                                          color: "#67edfd",
                                          marginBottom: 0,
                                          width: `28px`,
                                          height: `20px`,
                                        }}
                                      />
                                    ) : (
                                      <LazyLoadImage
                                        className={`my-auto`}
                                        src={imgObj}
                                        alt={item.name}
                                        style={{
                                          width: `28px`,
                                          height: `20px`,
                                          fontSize: `12px`,
                                        }}
                                      />
                                    )}
                                  </>
                                )
                              ) : (
                                ``
                              )}
                              <p
                                style={{
                                  fontSize: "14px",
                                  margin: "0px 0px 0px 10px",
                                }}
                              >
                                {item.resource_data?.display_name ?? item.resource_data?.name ?? item.display_name ?? item.name}
                              </p>
                            </div>
                            <div>
                              <img
                                src={DeleteIcon}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  deleteResource(item.id, index, datum?.id)
                                }}
                                alt={"resource delete icon"}
                                className={`my-auto pointer`}
                                style={{ objectFit: `contain`, height: `13px` }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </>
                )
              })}
              {provided.placeholder}
              <React.Fragment>
                <div className={`justify-content-center pt-4 pb-3 d-flex bg-transparent`}>
                  <div className={`${Styles.drop_area} m-0 px-0 py-0 bg-transparent  text-center ${files.length === 0 ? `` : `border-0`}`}>
                    <div className={`w-100 col-12`}>
                      <Form className="my-form mb-0">
                        <div>
                          <p className={`${StylesM.font_14} mb-2`}>Drag and drop media/document files to add content</p>
                        </div>
                        {files.length === 0 ? (
                          <input
                            className={`d-none`}
                            type="file"
                            id={"fileElem_" + datum.id}
                            multiple
                            accept="image/*,application/pdf,video/*,.doc,.docx,.ppt,.pptx,.xlsx,.xls"
                            onClick={(e) => {
                              clickedFileInput(e)
                            }}
                            onChange={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              fileInputClicked(e, datum.id, datum)
                              validateAndAdd(e)
                            }}
                          />
                        ) : null}
                      </Form>
                    </div>
                    {uploadInTopic !== null && uploadDataSequence !== null && uploadInTopic === datum?.id ? (
                      <UploadFiles
                        files={files}
                        setFiles={(value) => setFiles(value)}
                        fetchTopicResources={(id) => fetchCertificationResources(id)}
                        showSection={showSection}
                        setShowSection={setShowSection}
                        refetchLeftSideResourcesBar={refetchLeftSideResourcesBar}
                        properties={{
                          module: certObj.name,
                          add_to_certificate: certObj.id,
                          mainId: certObj.id,
                          itemName: "",
                          sequence: uploadDataSequence,
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            </div>
          )}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function singleAssessment(datum) {
    let topic_id = datum.id
    if (topic_id !== null) {
      var index = null
      if (certData && certData.length > 0) {
        index = certData.findIndex((res) => res.id === topic_id)
      }
      if (certData[index] && resourceList["resource_" + certData[index]?.id]) {
        return (
          <div
            classsName="col-12 float-left"
            style={{
              padding: "12px",
              backgroundColor: "transparent",
              borderRadius: "9px 9px 0px 0px",
            }}
          >
            <div
              key={"div2" + topic_id}
              className={`px-0 col-12 float-left my-2`}
              style={{
                backgroundColor: "#42506C",
                borderRadius: "8px",
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              <div
                classsName="col-12 float-left"
                style={{
                  borderRadius: "8px 8px 0px 0px",
                  borderBottom: `1px dashed #838b9e`,
                  padding: "8px",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    color: "#E0E2E5",
                    lineHeight: 1,
                    opacity: 0.6,
                  }}
                >
                  Resources
                </p>
              </div>
              {showResources(datum)}
              {isMuralEnabled && (
                <div className="d-flex p-1 col-12 float-left">
                  <AssesmentWorkSpace
                    assessment={datum}
                    activity_workspace={datum.mural_assessment}
                    onChangeMuralAssesment={(workspace_data) => {
                      let tempCertificateData = [...certData]
                      tempCertificateData[index].mural_assessment = workspace_data
                      setCertData(tempCertificateData)
                    }}
                    certificatesData={certData}
                    course={course}
                    mural_account={course?.mural_account}
                  />
                </div>
              )}

              <div
                className="d-flex p-1 col-12 float-left"
                style={{
                  borderTop: `1px dashed #838b9e`,
                  borderRadius: "0px 0px 8px 8px",
                }}
              >
                <OverlayTrigger overlay={<Tooltip>Add video</Tooltip>}>
                  <FontAwesomeIcon
                    icon={faVideo}
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "video")
                      uploadDocs(e, datum.id, datum, false, "video")
                    }}
                    title={`Add Videos`}
                    alt={`Add Videos`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add blog</Tooltip>}>
                  <img
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "blog")
                      uploadDataProps(datum.id, datum, "")
                      setAddBlog(true)
                    }}
                    src={WhiteBlogIcon}
                    title={`Add Blog`}
                    alt={`add blog button`}
                    className={`${
                      leftNavOpen && activeResourceType === "blog" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                    } my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add documents</Tooltip>}>
                  <img
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "document")
                      uploadDocs(e, datum.id, datum, false, "document")
                    }}
                    src={documents}
                    alt={`Add Documents`}
                    title={`Add Documents(Doc, Excel, PPT, PDF, Google Docs Link)`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add images</Tooltip>}>
                  <FontAwesomeIcon
                    icon={faImage}
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "turientimage")
                      uploadDocs(e, datum.id, datum, true, "turientimage")
                    }}
                    title={`Add Images`}
                    alt={`Add Images`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add links</Tooltip>}>
                  <FontAwesomeIcon
                    icon={faLink}
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "link")
                      uploadDocs(e, datum.id, datum, false, "link")
                    }}
                    title={`Add Links`}
                    alt={`Add Links`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add quiz </Tooltip>}>
                  <img
                    onClick={(e) => {
                      e.stopPropagation()
                      openNavWithResourceSection("Poll", "quiz")
                      openNewWin("Poll", "quiz", datum.id, datum)
                    }}
                    src={WhiteQuizIcon}
                    title={`Add Quiz`}
                    alt={`add quiz button`}
                    className={`${
                      leftNavOpen && activeResourceType === "quiz" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                    } my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add case-study</Tooltip>}>
                  <img
                    onClick={(e) => {
                      e.stopPropagation()
                      openNavWithResourceSection("Poll", "casestudy")
                      openNewWin("Poll", "casestudy", datum.id, datum)
                    }}
                    src={casestudy}
                    title={`Add Case-Study`}
                    alt={`add case study button`}
                    className={`${
                      leftNavOpen && (activeResourceType === "poll" || activeResourceType === "casestudy")
                        ? StylesB.resource_select_icon_active
                        : StylesB.resource_select_icon
                    } my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                    }}
                  />
                </OverlayTrigger>
              </div>
            </div>
          </div>
        )
      } else {
        return null
      }
    } else return null
  }
  function showLoader() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
        <Spinner animation="border" variant="secondary" size="sm" />
      </Col>
    )
  }

  function showErrorMessage(error) {
    let error_messages = ""
    for (var key in error.response.data) {
      error_messages += error.response.data[key] + "<br>"
    }
    if (error_messages === "Invalid token.<br>") {
      authClient.signOut()
      localStorage.removeItem("authenticity_token")
      localStorage.removeItem("username")
      window.location.reload(true)
    }
    document.getElementById("fixed-error-div").innerHTML =
      '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
      '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
      '<span aria-hidden="true">×</span></button>' +
      error_messages +
      "</div>"
  }

  function getAssessmentsData() {
    return (
      <div className={`pb-3 text-left  ${course.is_certfication === false ? `d-none` : ``}`}>
        <Col xs="12" sm="12" md="12" lg="12" className="px-3 mt-3 d-inline-flex position-relative" style={{ marginBottom: "12px" }}>
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className="p-3 align-items-center border_on_hover"
            style={{
              backgroundColor: "#303C54",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "9px",
              border: "1px solid #303C54",
            }}
          >
            <p className={`mb-0 pt-1 float-left `} style={{ color: "#E0E2E5", fontSize: "18px" }}>
              Assessments to reward Certificate
            </p>
            <div
              className="float-right align-self-center ml-2"
              style={{
                background: "#42506C",
                border: "1px solid #E0E2E5",
                borderRadius: "9px",
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              <Button
                onClick={(e) => {
                  setAssessments("onturient")
                  setLoader(true)
                  delayedQuery(e, "assessment_outside_turient", false, certificate, course)
                  handleCreate(e, certificate)
                }}
                disabled={!isPermitted}
                className={`float-left px-4 py-1 border_on_hover ${assessments === "onturient" ? "font-weight-bold" : ``} `}
                style={{
                  background: assessments === "onturient" ? "#4F6080" : "#42506C",
                  border: "1px solid transparent",
                  borderRadius: "9px",
                }}
                color="warning"
              >
                <p className={`p-0 m-0`} style={{ fontSize: "16px" }}>
                  On Turient
                </p>
              </Button>
              <Button
                onClick={(e) => {
                  setAssessments("outsideturient")
                  setLoader(true)
                  delayedQuery(e, "assessment_outside_turient", true, certificate, course)
                }}
                disabled={!isPermitted}
                className={`float-left py-1 border_on_hover ${assessments === "outsideturient" ? "font-weight-bold" : ``} `}
                style={{
                  background: assessments === "outsideturient" ? "#4F6080" : "#42506C",
                  border: "1px solid transparent",
                  borderRadius: "9px",
                }}
                color="warning"
              >
                <p className={`p-0 m-0`} style={{ fontSize: "16px" }}>
                  Outside Turient
                </p>
              </Button>
            </div>
          </Col>
        </Col>
        {assessments === "onturient" ? (
          <React.Fragment>
            <Col xs="12" sm="12" md="12" lg="12" className="px-3" style={{ marginBottom: "12px" }}>
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className="p-2 d-inline-flex align-items-center border_on_hover"
                style={{
                  backgroundColor: "#303C54",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "9px",
                  border: "1px solid #303C54",
                }}
              >
                <p className="mb-0 ml-2" style={{ color: "#DADCDF", fontSize: "16px", opacity: "41%" }}>
                  Assessment Deadline:
                </p>
                <FormControl
                  className={`border-0 ml-2 pt-1 pt-0 d-flex align-items-center text-white shadow-none ${!isPermitted && "disable-content"}`}
                  style={{
                    backgroundColor: "#42506C",
                    paddingLeft: "15px",
                    borderRadius: "4px",
                    fontSize: "16px",
                    width: "50px",
                  }}
                  type="number"
                  name="assessment_deadline_value"
                  defaultValue={certificate.assessment_deadline_value === undefined ? "1" : certificate.assessment_deadline_value}
                  id="assessment_deadline_value"
                  placeholder=""
                  onChange={(e) => {
                    setLoader(true)
                    delayedQuery(e, e.target.name, e.target.value, certificate, course)
                  }}
                  readOnly={!isPermitted}
                />
                <Dropdown className="ml-2">
                  <Dropdown.Toggle
                    variant="success"
                    style={{
                      backgroundColor: "#42506C",
                      border: "1px solid transparent",
                      fontSize: "14px",
                    }}
                    id="dropdown-basic"
                    disabled={!isPermitted}
                  >
                    {certificate.assessment_deadline_choice === undefined ? "years" : ""}
                    {certificate.assessment_deadline_choice === "0" ? "years" : ""}
                    {certificate.assessment_deadline_choice === "1" ? "Months" : ""}
                    {certificate.assessment_deadline_choice === "2" ? "Weeks" : ""}
                    {certificate.assessment_deadline_choice === "3" ? "Days" : ""}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ backgroundColor: "#42506C" }}>
                    <Dropdown.Item
                      onClick={(e) => {
                        setLoader(true)
                        delayedQuery(e, "assessment_deadline_choice", 0, certificate, course)
                      }}
                      style={{
                        borderBottom: `1px solid #C6CFF`,
                        backgroundColor: "#42506C",
                        fontSize: "14px",
                      }}
                      className={`px-0 text-center text-white`}
                    >
                      years
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setLoader(true)
                        delayedQuery(e, "assessment_deadline_choice", 1, certificate, course)
                      }}
                      style={{
                        borderBottom: `1px solid #C6CFF`,
                        backgroundColor: "#42506C",
                        fontSize: "14px",
                      }}
                      className={`px-0 text-center text-white`}
                    >
                      Months
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setLoader(true)
                        delayedQuery(e, "assessment_deadline_choice", 2, certificate, course)
                      }}
                      style={{
                        borderBottom: `1px solid #C6CFF`,
                        backgroundColor: "#42506C",
                        fontSize: "14px",
                      }}
                      className={`text-center text-white px-0`}
                    >
                      Weeks
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setLoader(true)
                        delayedQuery(e, "assessment_deadline_choice", 3, certificate, course)
                      }}
                      style={{
                        borderBottom: `1px solid #C6CFF`,
                        backgroundColor: "#42506C",
                        fontSize: "14px",
                      }}
                      className={`px-0 text-center text-white`}
                    >
                      Days
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <p className="mb-0 ml-2" style={{ color: "#DADCDF", fontSize: "14px" }}>
                  From the date completing the course
                </p>
              </Col>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" className="px-3" style={{ marginBottom: "12px" }}>
              <p className="mb-2" style={{ color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}>
                Add assessments for the final certification
              </p>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" className="px-3 float-left" style={{ marginBottom: "12px", backgroundColor: "#212C42" }}>
              {certData &&
                certData.map((item, index) => {
                  return (
                    <div
                      key={"assessment_" + index + "_" + item.id}
                      className="col-12 float-left px-0 mb-2"
                      style={{
                        boxShadow: "0px 3px 6px #00000029",
                        backgroundColor: "#37455d",
                        borderRadius: `${item.id === activeAssessmentId.id ? "9px 9px 0px 0px" : "9px"}`,
                      }}
                    >
                      <div
                        className="p-2 align-items-center d-inline-flex col-12 float-left"
                        style={{
                          backgroundColor: "#42506C",
                          boxShadow: "0px 3px 6px #00000029",
                          borderRadius: `${item.id === activeAssessmentId.id ? "9px 9px 0px 0px" : "9px"}`,
                          border: "1px solid #303C54",
                        }}
                      >
                        <div className="float-left d-inline-flex align-items-center float-left col-9 pl-0">
                          <FontAwesomeIcon icon={faEquals} color="#E0E2E5" className="mr-2" />
                          <input
                            className="mb-0 ml-0 col-11"
                            onChange={(e) => {
                              setLoader(true)
                              delayedQueryAssessment(e, "title", e.target.value, item, certData)
                            }}
                            style={{
                              color: "#E0E2E5",
                              fontSize: "16px",
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                            type="text"
                            name="assessment_name"
                            defaultValue={item?.title}
                            placeholder=" "
                          />
                        </div>
                        <div className="float-left ml-auto d-inline-flex align-items-center col-3 px-0" style={{ justifyContent: "end" }}>
                          <p
                            className="mb-0 mr-3 pointer"
                            style={{ color: "#E0E2E5", fontSize: "14px" }}
                            onClick={() => {
                              handleContentPropsData()
                              setShowRichTextModal(true)
                              setActiveInputFieeld("guidelines_for_assessment")
                              setAssesmentGuidelines(item?.guidelines_for_assessment)
                              setActiveAssessmentId(item)
                            }}
                          >
                            <u>{item.guidelines_for_assessment !== "" ? "Edit" : "Add"} Assesment guidelines</u>
                          </p>
                          <FontAwesomeIcon
                            onClick={() => {
                              if (item.id !== activeAssessmentId.id) {
                                setActiveAssessmentId(item)
                              } else {
                                setActiveAssessmentId("")
                              }
                              fetchCertificationResources(item?.id)
                              if (activeNavItem === "" && activeResourceType === "") {
                                openNavWithResourceSection("Resource", "video")
                              }
                            }}
                            className="float-right pointer"
                            icon={item.id === activeAssessmentId.id ? faChevronCircleUp : faChevronCircleDown}
                            style={{
                              width: `20px`,
                              height: `20px`,
                              color: `#E0E2E5`,
                            }}
                          />
                          <div>
                            <img
                              src={DeleteIcon}
                              onClick={(e) => {
                                e.stopPropagation()
                                setDmodal(true)
                                setSelectedDeletingTopic(item)
                              }}
                              alt={"resource delete icon"}
                              className={`my-auto pointer  ml-2`}
                              style={{ objectFit: `contain`, height: `18px` }}
                            />
                          </div>
                        </div>
                      </div>
                      {item.id === activeAssessmentId.id ? singleAssessment(item) : ""}
                    </div>
                  )
                })}
            </Col>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Col lg="12" md="12" sm="12" xs="12" className="px-3">
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className="px-0 border_on_hover"
                style={{
                  backgroundColor: "#303C54",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "9px",
                  border: "1px solid #303C54",
                  marginBottom: "12px",
                }}
              >
                <InputGroup
                  className="py-1 pl-1"
                  style={{
                    borderRadius: "9px",
                    border: "1px solid transparent",
                  }}
                >
                  <InputGroup.Prepend
                    onClick={() => {
                      document.getElementById("test_site").focus()
                    }}
                    className="border-0"
                    style={{
                      backgroundColor: "#303C54",
                      borderRadius: "9px 0px 0px 9px",
                    }}
                  >
                    <InputGroup.Text
                      className="border-0 pl-2 d-flex justify-content-center"
                      style={{
                        backgroundColor: "#303C54",
                        borderRadius: "9px 0px 0px 9px",
                        color: "#E0E2E5",
                        opacity: "41%",
                        fontSize: "16px",
                      }}
                      id="basic-addon1"
                    >
                      Test site :
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 d-flex pl-0 justify-content-center shadow-none ${StylesB.course_create} ${
                      !isPermitted && "disable-content"
                    } text-white`}
                    style={{
                      backgroundColor: "#303C54",
                      borderRadius: "0px",
                      fontSize: "16px",
                    }}
                    type="text"
                    name="test_site"
                    id="test_site"
                    defaultValue={certificate.test_site}
                    placeholder="Enter the test site"
                    onChange={(e) => {
                      setLoader(true)
                      delayedQuery(e, e.target.name, e.target.value, certificate, course)
                    }}
                    readOnly={!isPermitted}
                    required
                  />
                </InputGroup>
              </Col>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12" className="px-3">
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className="px-0 mt-0 mr-2 mb-2 border_on_hover"
                style={{
                  backgroundColor: "#303C54",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "9px",
                  border: "1px solid #303C54",
                }}
              >
                <InputGroup
                  className="py-0 pt-1 d-block "
                  style={{
                    borderRadius: "9px",
                    border: "1px solid transparent",
                  }}
                >
                  <InputGroup.Prepend
                    onClick={() => {
                      document.getElementById("outside_eligibility").focus()
                    }}
                    className="border-0"
                    style={{
                      backgroundColor: "#303C54",
                      borderRadius: "9px 0px 0px 9px",
                    }}
                  >
                    <InputGroup.Text
                      className="border-0 pb-0 pt-0 d-flex justify-content-center"
                      style={{
                        backgroundColor: "#303C54",
                        borderRadius: "9px 0px 0px 9px",
                        color: "#E0E2E5",
                        opacity: "41%",
                        fontSize: "16px",
                      }}
                      id="basic-addon1"
                    >
                      Instructions to take test
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 pt-0 d-flex w-100 justify-content-center shadow-none text-white ${!isPermitted && "disable-content"}`}
                    style={{
                      backgroundColor: "#303C54",
                      borderRadius: "0px",
                      fontSize: "16px",
                    }}
                    as="textarea"
                    rows="3"
                    type="text"
                    name="instructions_for_outside_test"
                    id="outside_eligibility"
                    defaultValue={certificate.instructions_for_outside_test}
                    placeholder=""
                    readOnly={!isPermitted}
                    onChange={(e) => {
                      setLoader(true)
                      updateData(e, e.target.name, e.target.value, certificate)
                      delayedQuery(e, e.target.name, e.target.value, certificate, course)
                    }}
                  />
                </InputGroup>
              </Col>
            </Col>
          </React.Fragment>
        )}
      </div>
    )
  }

  function setvalidityChoice(type) {
    if (type === "years") {
      certificate.certificate_validity_choice = "0"
    } else if (type === "months") {
      certificate.certificate_validity_choice = "1"
    } else if (type === "weeks") {
      certificate.certificate_validity_choice = "2"
    } else if (type === "days") {
      certificate.certificate_validity_choice = "3"
    } else if (type === "lifetime") {
      certificate.certificate_validity_choice = "4"
    }
  }

  function getExpiaryValidityData() {
    if (certificate.certificate_validity_choice === "0") {
      return "Years"
    } else if (certificate.certificate_validity_choice === "1") {
      return "Months"
    } else if (certificate.certificate_validity_choice === "2") {
      return "Weeks"
    } else if (certificate.certificate_validity_choice === "3") {
      return "Days"
    } else if (certificate.certificate_validity_choice === "4") {
      return "Lifetime"
    }
  }

  function updateCertificateData(data) {
    let formData = new FormData()
    for (let k in course) {
      if (k === "is_certfication") {
        formData.append(k, data === "yes" ? true : false)
      } else if (k !== "certification") {
        if (k !== "avatar" && k !== "mural_account") {
          formData.append(k, course[k])
        }
        if (k === "mural_account" && course[k]) {
          formData.append(k, course[k])
        }
      }
    }

    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/course-certification/${location.id}/`, formData, { headers: login_credentials })
      .then((res) => {
        course.is_certfication = res.data.is_certfication
        setGiveCertification(res.data.is_certfication ? "yes" : "no")
        setLoader(false)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key]
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        if (error_messages === "Enter a valid URL.") {
          setNotificationData({
            type: "error",
            title: "Please enter a valid certification body site",
          })
        } else {
          setNotificationData({ type: "error", title: error_messages })
        }
      })
  }

  function showAutoSaveLoader() {
    if (course.certification !== null) {
      return (
        <p
          className="mb-0 position-absolute"
          style={{
            fontSize: "14px",
            color: "white",
            bottom: "10px",
            right: "10px",
          }}
        >
          {loader ? (
            <svg
              className="animate-spin my-auto ml-3 mr-1"
              style={{
                height: `14px`,
                width: `14px`,
                position: "relative",
                top: "-2px",
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          ) : (
            <FontAwesomeIcon icon={faCheck} className="ml-3 mr-1" />
          )}
          All changes are saved
        </p>
      )
    }
  }

  function getFormData() {
    return (
      <div className="px-0" style={{ maxHeight: "calc(100vh - 164px - 18px)", overflowY: "scroll" }}>
        <Col lg="12" md="12" sm="12" xs="12" className="py-1 text-right">
          {course.is_certfication && (
            <div className="mt-2 mr-1 d-inline-flex">
              <p className="pt-3" style={{ color: "#E0E2E5", opacity: "60%", fontSize: "16px" }}>
                Certificate on course completion
              </p>
              <div
                className="float-left align-self-center ml-2"
                style={{
                  background: "#42506C",
                  border: "1px solid #E0E2E5",
                  borderRadius: "9px",
                  boxShadow: "0px 3px 6px #00000029",
                }}
              >
                <Button
                  onClick={(e) => {
                    updateCertificateData("yes")
                    if (course.certification !== null) {
                      setLoader(true)
                    }
                  }}
                  disabled={!isPermitted}
                  className={`float-left px-4 py-1 ${giveCertification === "yes" ? `font-weight-bold` : ``} border_on_hover`}
                  style={{
                    background: giveCertification === "yes" ? "#4F6080" : "#42506C",
                    border: "1px solid transparent",
                    borderRadius: "9px",
                  }}
                  color="warning"
                >
                  <p className={`p-0 m-0`} style={{ fontSize: "16px" }}>
                    Yes
                  </p>
                </Button>
                <Button
                  onClick={(e) => {
                    updateCertificateData("no")
                    if (course.certification !== null) {
                      setLoader(true)
                    }
                  }}
                  disabled={!isPermitted}
                  className={`float-left px-4 py-1 ${giveCertification === "no" ? `font-weight-bold` : ``} border_on_hover`}
                  style={{
                    background: giveCertification === "no" ? "#4F6080" : "#42506C",
                    border: "1px solid transparent",
                    borderRadius: "9px",
                  }}
                  color="warning"
                >
                  <p className={`p-0 m-0`} style={{ fontSize: "16px" }}>
                    No
                  </p>
                </Button>
              </div>
            </div>
          )}
        </Col>
        {course.is_certfication === false ? (
          ""
        ) : (
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            className={`px-3 ${course.is_certfication === false ? `d-none` : ``}`}
            style={{ borderBottom: "1px dashed #63666f" }}
          >
            <Col lg="12" md="12" sm="12" xs="12" className="py-1 px-0">
              <Col className="px-0 d-flex mt-1 w-100" style={{ marginBottom: "12px" }}>
                <CropImage
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  getCropDataProps={getCropDataProps}
                  fileName={fileName}
                  avatar={certificate.avatar && certificate.avatar !== null ? certificate.avatar : null}
                />
                <div
                  className="px-0 border_on_hover d-inline-flex text-center align-items-center"
                  style={{
                    backgroundColor: "#303C54",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "9px",
                    border: "1px solid transparent",
                    width: "183px",
                    height: "183px",
                  }}
                >
                  <div className={`my-auto mx-auto ${!isPermitted && "disable-content"}`}>
                    {cropData === "#" ? (
                      certificate.avatar ? (
                        <img
                          className="mb-0"
                          onClick={(e) => {
                            e.preventDefault()
                            setModalShow(true)
                          }}
                          style={{ width: "70px", height: "70px" }}
                          src={certificate.avatar}
                          alt="Certificate logo"
                        />
                      ) : course.avatar ? (
                        <img
                          className="mb-0"
                          onClick={(e) => {
                            e.preventDefault()
                            setModalShow(true)
                          }}
                          style={{ width: "70px", height: "70px" }}
                          src={course.avatar}
                          alt="Certificate logo"
                        />
                      ) : (
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            setModalShow(!modalShow)
                          }}
                          style={{
                            height: "100px",
                            width: "100px",
                            border: "1px solid #E0E2E5",
                            borderRadius: "9px",
                            textAlign: "center",
                            backgroundColor: "#303C54",
                          }}
                          className="float-center mb-2"
                        >
                          <FontAwesomeIcon icon={faUpload} color="#E0E2E5" />
                        </button>
                      )
                    ) : (
                      <img className="mb-0" style={{ width: "70px", height: "70px" }} src={cropData} alt="Certificate logo" />
                    )}
                    <p
                      className="pointer pb-0 mb-0"
                      onClick={(e) => {
                        e.preventDefault()
                        setModalShow(true)
                      }}
                      style={{ color: "#E0E2E5", fontSize: "14px" }}
                    >
                      <u>Use a different logo</u>
                    </p>
                  </div>
                </div>
                <div className="px-0 ml-2" style={{ width: "calc(100% - 185px)" }}>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="border_on_hover"
                    style={{
                      backgroundColor: "#303C54",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      border: "1px solid #303C54",
                      marginBottom: "12px",
                    }}
                  >
                    <InputGroup
                      className="py-1"
                      style={{
                        borderRadius: "9px",
                        border: "1px solid transparent",
                      }}
                    >
                      <InputGroup.Prepend
                        onClick={() => {
                          document.getElementById("certfication_body_name").focus()
                        }}
                        className="border-0"
                        style={{
                          backgroundColor: "#303C54",
                          borderRadius: "9px 0px 0px 9px",
                        }}
                      >
                        <InputGroup.Text
                          className={`border-0 pl-0 d-flex justify-content-center ${!isPermitted && "disable-content"}`}
                          style={{
                            backgroundColor: "#303C54",
                            borderRadius: "9px 0px 0px 9px",
                            color: "#E0E2E5",
                            opacity: "41%",
                            fontSize: "16px",
                          }}
                          id="basic-addon1"
                        >
                          Name of the Certification Body:
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className={`border-0 d-flex pl-0 justify-content-center shadow-none ${StylesB.course_create} ${!isPermitted && "disable-content"}`}
                        style={{
                          backgroundColor: "#303C54",
                          color: "#E0E2E5",
                          borderRadius: "0px",
                          fontSize: "16px",
                        }}
                        type="text"
                        name="certfication_body_name"
                        id="certfication_body_name"
                        defaultValue={certificate.certfication_body_name}
                        onChange={
                          course.certification !== null
                            ? (e) => {
                                setLoader(true)
                                delayedQuery(e, e.target.name, e.target.value, certificate, course)
                              }
                            : handleChange
                        }
                        onBlur={
                          course.certification !== null
                            ? (e) => {
                                e.preventDefault()
                                onBlurData(e.target.value === course.name ? null : e.target.name, e.target.value, certificate)
                              }
                            : null
                        }
                        placeholder="Name of the Certification Body"
                        readOnly={!isPermitted}
                        required
                      />
                    </InputGroup>
                  </Col>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="border_on_hover"
                    style={{
                      backgroundColor: "#303C54",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      border: "1px solid #303C54",
                      marginBottom: "12px",
                    }}
                  >
                    <InputGroup
                      className="py-1"
                      style={{
                        borderRadius: "9px",
                        border: "1px solid transparent",
                      }}
                    >
                      <InputGroup.Prepend
                        onClick={() => {
                          document.getElementById("certfication_body_site").focus()
                        }}
                        className="border-0"
                        style={{
                          backgroundColor: "#303C54",
                          borderRadius: "9px 0px 0px 9px",
                        }}
                      >
                        <InputGroup.Text
                          className={`border-0 pl-0 d-flex justify-content-center ${!isPermitted && "disable-content"}`}
                          style={{
                            backgroundColor: "#303C54",
                            borderRadius: "9px 0px 0px 9px",
                            color: "#E0E2E5",
                            opacity: "41%",
                            fontSize: "16px",
                          }}
                          id="basic-addon1"
                        >
                          Certification Body site:
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className={`border-0 d-flex pl-0 justify-content-center shadow-none ${StylesB.course_create} ${!isPermitted && "disable-content"}`}
                        style={{
                          backgroundColor: "#303C54",
                          color: "#E0E2E5",
                          borderRadius: "0px",
                          fontSize: "16px",
                        }}
                        type="text"
                        name="certfication_body_site"
                        id="certfication_body_site"
                        defaultValue={certificate.certfication_body_site}
                        onChange={
                          course.certification !== null
                            ? (e) => {
                                setLoader(true)
                                delayedQuery(e, e.target.name, e.target.value, certificate, course)
                              }
                            : handleChange
                        }
                        onBlur={
                          course.certification !== null
                            ? (e) => {
                                e.preventDefault()
                                onBlurData(e.target.value === course.name ? null : e.target.name, e.target.value, certificate)
                              }
                            : null
                        }
                        placeholder="Enter site URL"
                        readOnly={!isPermitted}
                        required
                      />
                    </InputGroup>
                  </Col>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="mb-0 border_on_hover"
                    style={{
                      backgroundColor: "#303C54",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      border: "1px solid #303C54",
                    }}
                  >
                    <InputGroup
                      className="py-1"
                      style={{
                        borderRadius: "9px",
                        border: "1px solid transparent",
                      }}
                    >
                      <InputGroup.Prepend
                        onClick={() => {
                          document.getElementById("name_of_the_certificate").focus()
                        }}
                        className="border-0"
                        style={{
                          backgroundColor: "#303C54",
                          borderRadius: "9px 0px 0px 9px",
                        }}
                      >
                        <InputGroup.Text
                          className={`border-0 pl-0 d-flex justify-content-center ${!isPermitted && "disable-content"}`}
                          style={{
                            backgroundColor: "#303C54",
                            borderRadius: "9px 0px 0px 9px",
                            color: "#E0E2E5",
                            opacity: "41%",
                            fontSize: "16px",
                          }}
                          id="basic-addon1"
                        >
                          Name of the certificate:
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className={`border-0 pl-0 d-flex justify-content-center shadow-none ${StylesB.course_create} ${!isPermitted && "disable-content"}`}
                        style={{
                          backgroundColor: "#303C54",
                          color: "#E0E2E5",
                          borderRadius: "0px",
                          fontSize: "16px",
                        }}
                        type="text"
                        name="name_of_the_certificate"
                        defaultValue={certificate.name_of_the_certificate}
                        onChange={
                          course.certification !== null
                            ? (e) => {
                                setLoader(true)
                                delayedQuery(e, e.target.name, e.target.value, certificate, course)
                              }
                            : handleChange
                        }
                        onBlur={
                          course.certification !== null
                            ? (e) => {
                                e.preventDefault()
                                onBlurData(e.target.value === course.name ? null : e.target.name, e.target.value, certificate)
                              }
                            : null
                        }
                        id="name_of_the_certificate"
                        placeholder="Name of the certificate"
                        readOnly={!isPermitted}
                        required
                      />
                    </InputGroup>
                  </Col>
                </div>
              </Col>
              <div className="px-0 d-inline-flex align-items-center w-100" style={{ marginBottom: "12px" }}>
                <Col lg="6" md="6" sm="6" xs="6" className={`px-0`}>
                  <div
                    className="mr-1 mt-0 border_on_hover"
                    style={{
                      backgroundColor: "#303C54",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      border: "1px solid #303C54",
                    }}
                  >
                    <InputGroup
                      className="py-0 pt-1 d-block "
                      style={{
                        borderRadius: "9px",
                        border: "1px solid transparent",
                      }}
                    >
                      <InputGroup.Prepend
                        className="border-0"
                        style={{
                          backgroundColor: "#303C54",
                          borderRadius: "9px 0px 0px 9px",
                        }}
                      >
                        <InputGroup.Text
                          className={`border-0 pt-0 pb-0 d-flex justify-content-center ${!isPermitted && "disable-content"}`}
                          style={{
                            backgroundColor: "#303C54",
                            borderRadius: "9px 0px 0px 9px",
                            color: "#E0E2E5",
                            opacity: "41%",
                            fontSize: "16px",
                          }}
                          id="basic-addon1"
                          readOnly={!isPermitted}
                          onClick={() => {
                            if (isPermitted) {
                              handleContentPropsData()
                              setShowRichTextModal(true)
                              setActiveInputFieeld("Certification Eligibility")
                            }
                          }}
                        >
                          Certification Eligibility
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <div className={`pl-0 mx-2 mb-2`} style={{}}>
                        <div
                          className={`text-left pl-1`}
                          readOnly={!isPermitted}
                          onClick={() => {
                            if (isPermitted) {
                              handleContentPropsData()
                              setShowRichTextModal(true)
                              setActiveInputFieeld("Certification Eligibility")
                            }
                          }}
                          dangerouslySetInnerHTML={{
                            __html: certificationEligibility,
                          }}
                          style={{
                            maxHeight: "100px",
                            minHeight: "50px",
                            overflowY: "scroll",
                          }}
                        />
                      </div>
                    </InputGroup>
                  </div>
                </Col>
                <Col lg="6" md="6" sm="6" xs="6" className={`px-0`}>
                  <div
                    className="ml-1 mt-0 border_on_hover"
                    style={{
                      backgroundColor: "#303C54",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      border: "1px solid #303C54",
                    }}
                  >
                    <InputGroup
                      className="py-0 pt-1 d-block "
                      style={{
                        borderRadius: "9px",
                        border: "1px solid transparent",
                      }}
                    >
                      <InputGroup.Prepend
                        className="border-0"
                        style={{
                          backgroundColor: "#303C54",
                          borderRadius: "9px 0px 0px 9px",
                        }}
                      >
                        <InputGroup.Text
                          className={`border-0 pb-0 pt-0 d-flex justify-content-center ${!isPermitted && "disable-content"}`}
                          style={{
                            backgroundColor: "#303C54",
                            borderRadius: "9px 0px 0px 9px",
                            color: "#E0E2E5",
                            opacity: "41%",
                            fontSize: "16px",
                          }}
                          id="basic-addon1"
                          onClick={() => {
                            if (isPermitted) {
                              handleContentPropsData()
                              setShowRichTextModal(true)
                              setActiveInputFieeld("Attendance Requirement")
                            }
                          }}
                        >
                          Attendance Requirement
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <div className={`pl-0 mx-2 mb-2 ${!isPermitted && "disable-content"}`} style={{}}>
                        <div
                          className={`text-left pl-1`}
                          onClick={() => {
                            if (isPermitted) {
                              handleContentPropsData()
                              setShowRichTextModal(true)
                              setActiveInputFieeld("Attendance Requirement")
                            }
                          }}
                          dangerouslySetInnerHTML={{ __html: attendanceData }}
                          style={{
                            maxHeight: "100px",
                            minHeight: "50px",
                            overflowY: "scroll",
                          }}
                        />
                      </div>
                    </InputGroup>
                  </div>
                </Col>
              </div>
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className="px-2 py-2 d-inline-flex align-items-center border_on_hover"
                style={{
                  backgroundColor: "#303C54",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "9px",
                  border: "1px solid #303C54",
                  marginBottom: "12px",
                }}
              >
                <p className={`mb-0 ml-2 ${!isPermitted && "disable-content"}`} style={{ color: "#DADCDF", fontSize: "16px", opacity: "41%" }}>
                  Expiry and Validity:
                </p>
                {expirydate !== "Lifetime" && (
                  <FormControl
                    className={`border-0 ml-2 pt-1 pt-0 d-flex align-items-center text-white shadow-none ${!isPermitted && "disable-content"}`}
                    style={{
                      backgroundColor: "#42506C",
                      paddingLeft: "15px",
                      borderRadius: "4px",
                      fontSize: "16px",
                      width: "50px",
                    }}
                    type="number"
                    name="certification_validity_value"
                    defaultValue={certificate.certification_validity_value}
                    onChange={
                      course.certification !== null
                        ? (e) => {
                            setLoader(true)
                            delayedQuery(e, e.target.name, e.target.value, certificate, course)
                          }
                        : handleChange
                    }
                    onBlur={
                      course.certification !== null
                        ? (e) => {
                            e.preventDefault()
                            onBlurData(e.target.value === course.name ? null : e.target.name, e.target.value, certificate)
                          }
                        : null
                    }
                    id="certification_validity_value"
                    placeholder=""
                    readOnly={!isPermitted}
                  />
                )}

                <Dropdown className="ml-2">
                  <Dropdown.Toggle
                    variant="success"
                    style={{
                      backgroundColor: "#42506C",
                      border: "1px solid transparent",
                      fontSize: "14px",
                    }}
                    id="dropdown-basic"
                    disabled={!isPermitted}
                  >
                    {getExpiaryValidityData()}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="validity" style={{ backgroundColor: "#42506C" }}>
                    <Dropdown.Item
                      onClick={(e) => {
                        setExpiryDate("Months")
                        setvalidityChoice("months")
                        if (course.certification !== null) {
                          setLoader(true)
                          lowdelayedQuery(e, "certificate_validity_choice", "1", certificate)
                        }
                      }}
                      style={{
                        borderBottom: `1px solid #C6CFF`,
                        backgroundColor: "#42506C",
                        fontSize: "14px",
                      }}
                      className={`px-0 text-white text-center`}
                    >
                      Months
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setExpiryDate("Days")
                        setvalidityChoice("days")
                        if (course.certification !== null) {
                          setLoader(true)
                          lowdelayedQuery(e, "certificate_validity_choice", "3", certificate)
                        }
                      }}
                      style={{
                        borderBottom: `1px solid #C6CFF`,
                        backgroundColor: "#42506C",
                        fontSize: "14px",
                      }}
                      className={`px-0 text-white text-center`}
                    >
                      Days
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setExpiryDate("Weeks")
                        setvalidityChoice("weeks")
                        if (course.certification !== null) {
                          setLoader(true)
                          lowdelayedQuery(e, "certificate_validity_choice", "2", certificate)
                        }
                      }}
                      style={{
                        borderBottom: `1px solid #C6CFF`,
                        backgroundColor: "#42506C",
                        fontSize: "14px",
                      }}
                      className={`text-center text-white px-0`}
                    >
                      Weeks
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setExpiryDate("Years")
                        setvalidityChoice("years")
                        if (course.certification !== null) {
                          setLoader(true)
                          lowdelayedQuery(e, "certificate_validity_choice", "0", certificate)
                        }
                      }}
                      style={{
                        borderBottom: `1px solid #C6CFF`,
                        backgroundColor: "#42506C",
                        fontSize: "14px",
                      }}
                      className={`text-center text-white px-0`}
                    >
                      years
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setExpiryDate("Lifetime")
                        setvalidityChoice("lifetime")
                        if (course.certification !== null) {
                          setLoader(true)
                          lowdelayedQuery(e, "certificate_validity_choice", "4", certificate)
                        }
                      }}
                      style={{
                        borderBottom: `1px solid #C6CFF`,
                        backgroundColor: "#42506C",
                        fontSize: "14px",
                      }}
                      className={`text-center text-white px-0`}
                    >
                      Lifetime
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <p className="mb-0 ml-2" style={{ color: "#DADCDF", fontSize: "14px" }}>
                  From the date of issuing certificate
                </p>
              </Col>
              <div className="px-0 d-inline-flex text-left w-100" style={{ marginBottom: "12px" }}>
                {clickedExperience ? (
                  <Col lg="6" md="6" sm="6" xs="6" className={`px-0`}>
                    <div
                      className="px-0 mr-1 border_on_hover"
                      style={{
                        backgroundColor: "#303C54",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "9px",
                        border: "1px solid #303C54",
                      }}
                    >
                      <InputGroup
                        className="py-0 pt-1 d-block"
                        style={{
                          borderRadius: "9px",
                          border: "1px solid transparent",
                        }}
                      >
                        <InputGroup.Prepend
                          className="border-0"
                          style={{
                            backgroundColor: "#303C54",
                            borderRadius: "9px 0px 0px 9px",
                          }}
                        >
                          <InputGroup.Text
                            className="border-0 pt-0 pb-0 d-flex justify-content-center"
                            style={{
                              backgroundColor: "#303C54",
                              borderRadius: "9px 0px 0px 9px",
                              color: "#E0E2E5",
                              opacity: "41%",
                              fontSize: "16px",
                            }}
                            id="basic-addon1"
                            onClick={() => {
                              handleContentPropsData()
                              setShowRichTextModal(true)
                              setActiveInputFieeld("Experience Requirements")
                            }}
                          >
                            Experience Requirements
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <div className={`pl-0 mx-2 mb-2`} style={{}}>
                          <div
                            className={`pl-1`}
                            onClick={() => {
                              handleContentPropsData()
                              setShowRichTextModal(true)
                              setActiveInputFieeld("Experience Requirements")
                            }}
                            dangerouslySetInnerHTML={{
                              __html: experienceRequirements,
                            }}
                            style={{
                              maxHeight: "100px",
                              minHeight: "50px",
                              overflowY: "scroll",
                            }}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </Col>
                ) : (
                  <p className="mb-0">
                    <u
                      className={`pointer mr-3 ${!isPermitted && "disable-content"}`}
                      style={{ fontSize: "14px", color: "#E0E2E5" }}
                      onClick={() => {
                        if (isPermitted) {
                          setClickedExperience(true)
                        }
                      }}
                    >
                      Add Experience Requirements
                    </u>
                  </p>
                )}
                {clickedGuidelines ? (
                  <Col lg="6" md="6" sm="6" xs="6" className={`px-0`}>
                    <div
                      className="px-0 ml-1 border_on_hover"
                      style={{
                        backgroundColor: "#303C54",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "9px",
                        border: "1px solid #303C54",
                      }}
                    >
                      <InputGroup
                        className="py-0 pt-1 d-block"
                        style={{
                          borderRadius: "9px",
                          border: "1px solid transparent",
                        }}
                      >
                        <InputGroup.Prepend
                          className="border-0"
                          style={{
                            backgroundColor: "#303C54",
                            borderRadius: "9px 0px 0px 9px",
                          }}
                        >
                          <InputGroup.Text
                            className="border-0 pt-0 d-flex pb-0 justify-content-center"
                            style={{
                              backgroundColor: "#303C54",
                              borderRadius: "9px 0px 0px 9px",
                              color: "#E0E2E5",
                              opacity: "41%",
                              fontSize: "16px",
                            }}
                            id="basic-addon1"
                            onClick={() => {
                              handleContentPropsData()
                              setShowRichTextModal(true)
                              setActiveInputFieeld("Additional Guidelines")
                            }}
                          >
                            Additional Guidelines
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <div className={`pl-0 mx-2 mb-2`} style={{}}>
                          <div
                            className={`pl-1`}
                            onClick={() => {
                              handleContentPropsData()
                              setShowRichTextModal(true)
                              setActiveInputFieeld("Additional Guidelines")
                            }}
                            dangerouslySetInnerHTML={{
                              __html: additionalGuidelines,
                            }}
                            style={{
                              maxHeight: "100px",
                              minHeight: "50px",
                              overflowY: "scroll",
                            }}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </Col>
                ) : (
                  <p className="mb-0">
                    <u
                      className={`pointer ${!isPermitted && "disable-content"}`}
                      style={{ fontSize: "14px", color: "#E0E2E5" }}
                      onClick={() => {
                        if (isPermitted) {
                          setClickedGuidelines(true)
                        }
                      }}
                    >
                      Add Additional Guidelines
                    </u>
                  </p>
                )}
              </div>
            </Col>
          </Col>
        )}
        <Col xs="12" sm="12" md="12" lg="12" className={`px-3 py-5 my-3 text-center align-items-center ${course.is_certfication === false ? `` : `d-none`}`}>
          <img src={nocertificate} alt="nocertificate" />
          <p className="mb-1" style={{ fontSize: "19px", color: "#E0E2E5", fontWeight: "500" }}>
            Do you want to provide certificate on course completion.
          </p>
          <div
            className="align-self-center mx-auto"
            style={{
              background: "#42506C",
              border: "1px solid #E0E2E5",
              borderRadius: "9px",
              boxShadow: "0px 3px 6px #00000029",
              width: "max-content",
            }}
          >
            <Button
              onClick={(e) => {
                updateCertificateData("no")
                if (course.certification !== null) {
                  setLoader(true)
                }
              }}
              className={`px-4 py-1 ${giveCertification === "no" ? `font-weight-bold` : ``} border_on_hover`}
              style={{
                background: giveCertification === "no" ? "#4F6080" : "#42506C",
                border: "1px solid transparent",
                borderRadius: "9px",
              }}
              color="warning"
              disabled={!isPermitted}
            >
              <p className={`p-0 m-0`} style={{ fontSize: "16px" }}>
                No
              </p>
            </Button>
            <Button
              onClick={(e) => {
                updateCertificateData("yes")
                if (course.certification !== null) {
                  setLoader(true)
                }
              }}
              className={`px-4 py-1 ${giveCertification === "yes" ? `font-weight-bold` : ``} border_on_hover`}
              style={{
                background: giveCertification === "yes" ? "#4F6080" : "#42506C",
                border: "1px solid transparent",
                borderRadius: "9px",
              }}
              color="warning"
              disabled={!isPermitted}
            >
              <p className={`p-0 m-0`} style={{ fontSize: "16px" }}>
                Yes, add certification details
              </p>
            </Button>
          </div>
        </Col>
        {course.is_certfication === false ? "" : getAssessmentsData()}
      </div>
    )
  }

  function findScrollableOrNot() {
    let ele = document.getElementById("sections")

    if (ele !== null && ele !== undefined) {
      if (ele.scrollWidth > ele.clientWidth) {
        return true
      } else {
        return false
      }
    }
  }

  function scrollRight() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft += 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function scrollLeft() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      let slideVar = setInterval(function () {
        ele.scrollLeft -= 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function calculateWidth() {
    let ele = document.getElementById("coursename")
    if (ele !== null) {
      let data = 1100 - parseInt(ele.offsetWidth) - 50
      return { width: data, overflowX: "scroll", flex: "auto" }
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  function onDragEnd(result) {
    onCourseContentDragEnd(result)
  }
  function onCourseContentDragEnd(result) {
    let type = result.source.droppableId.split("_")[0].toString() + `_resourceDraggable`
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    const sourceParentId = result.source.droppableId
    const destParentId = result.destination.droppableId
    if (type === "resource_resourceDraggable") {
      const itemSubItemMap = allResources
      var sourceSubItems, destSubItems, topic_id

      if (sourceParentId.indexOf("resource_") > -1) {
        sourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
      }

      if (destParentId.indexOf("_certificate") > -1) {
        let datum = destParentId.split("_")
        topic_id = datum[0]
        destSubItems = resourceList["resource_" + topic_id]
      }

      /* In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(allResources[sourceParentId.replace("resource_", "")], sourceIndex, destIndex)
        allResources[sourceParentId.replace("resource_", "")] = items
        setAllResourcesChanged(!allResourcesChanged)
      } else {
        var newSourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        var newDestSubItems
        var formData = new FormData()

        newDestSubItems = resourceList["resource_" + topic_id]
        formData.append("add_to_certificate", topic_id)

        if (result.destination.index === 0 && destSubItems.length === 0) {
          draggedItem["sequence"] = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index === destSubItems.length) {
          draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem["sequence"] = (
            (parseFloat(destSubItems[result.destination.index].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        /*api for creating resource to topic or module*/

        formData.append("sequence", draggedItem.sequence)
        let sourcePIdType = sourceParentId.replace("resource_", "")
        if (sourcePIdType === "poll" || sourcePIdType === "pop-quiz") {
          formData.append("resource_type", "quiz")
        } else {
          if (sourcePIdType === "document") {
            formData.append("resource_type", "turientimage")
          } else {
            formData.append("resource_type", sourcePIdType)
          }
        }
        formData.append("resource_id", draggedItem.id)

        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
          .then((res) => {
            if (res.data) {
              draggedItem.id = res.data.id
              draggedItem.resource_data = res.data.resource_data
              draggedItem.resource_id = res.data.resource_id
              draggedItem.resource_type = res.data.resource_type
              draggedItem.sequence = res.data.sequence
              delete draggedItem["name"]
            }
          })
          .catch((error) => {
            showErrorMessage(error)
          })
        /*api end*/
        newDestSubItems.splice(destIndex, 0, draggedItem)
        setAllResourcesFetched(false)
        let temp_data = allResources
        temp_data[sourceParentId.replace("resource_", "")] = newSourceSubItems
        setAllResourcesChanged(!allResourcesChanged)
      }
    }
  }

  const handleClose = () => {
    setDmodal(false)
    setSelectedDeletingTopic([])
  }
  const handleRichTextEditor = (data) => {
    setShowRichTextModal(data)
  }
  const handleContentData = (content) => {
    if (activeInputField === "Certification Eligibility") {
      setCertificationEligibility(content)
    } else if (activeInputField === "Experience Requirements") {
      setExperienceRequirements(content)
    } else if (activeInputField === "Attendance Requirement") {
      setAttendanceData(content)
    } else if (activeInputField === "guidelines_for_assessment") {
      setAssesmentGuidelines(content)
    } else {
      setAdditionalGuidelines(content)
    }
  }
  const setFieldData = (id, placeholder, content) => {
    let field_data_copy = activeFieldData
    field_data_copy.id = id
    field_data_copy.placeholder = placeholder
    field_data_copy.content = content
    setActiveFieldData(field_data_copy)
  }
  const handleContentPropsData = () => {
    if (activeInputField === "Certification Eligibility") {
      setFieldData("certification_eligibility", "certification eligibility", certificationEligibility)
    } else if (activeInputField === "Experience Requirements") {
      setFieldData("experience_requirements", "Experience Requirements", experienceRequirements)
    } else if (activeInputField === "Attendance Requirement") {
      setFieldData("attendance_requirement", "attendance requirement", attendanceData)
    } else if (activeInputField === "guidelines_for_assessment") {
      setFieldData("assesment_guidelines", "assesment Guidelines", assesmentGuidelines)
    } else {
      setFieldData("additional_guidelines", "additional guidelines", additionalGuidelines)
    }
  }
  useEffect(() => {
    handleContentPropsData()
  }, [activeInputField])

  return (
    <div>
      <SEO title="Company Creation" />
      <DragDropContext onDragEnd={onDragEnd}>
        <Modal show={dmodal} onHide={handleClose} centered className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}>
          <div className={`${StylesB.delete_modal_header}`}>
            <img
              onClick={handleClose}
              src={CancelWhite}
              alt={`Cancel`}
              className={`my-auto mr-1 pointer`}
              style={{
                objectFit: `contain`,
                height: `13px`,
                position: "absolute",
                top: "7px",
                right: "5px",
              }}
            />
            <img src={TrashCan} alt={`delete modal delete icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `37px` }} />
            <div>
              <p style={{ fontWeight: "bold", fontSize: "15px" }}>Delete</p>
              <p>Item will be deleted permanently!</p>
            </div>
          </div>
          <Modal.Body
            className="text-center"
            style={{
              backgroundColor: "#121b2b",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <p
              style={{
                color: "#E0E2E5",
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              {" "}
              Are you sure you want to delete{" "}
              {selectedDeletingTopic && selectedDeletingTopic.title !== undefined ? `Assessment ` + selectedDeletingTopic.title : ""} ?{" "}
            </p>
            <div className="d-flex justify-content-center">
              <Button
                className={`${StylesB.add_button_large} border_on_hover mr-3`}
                variant="secondary"
                onClick={() => deleteAssessment(selectedDeletingTopic.id)}
                style={{ width: "75px", border: "1px solid transparent" }}
              >
                Yes
              </Button>
              <Button
                className={`${StylesB.add_button_large} border_on_hover`}
                variant="secondary"
                onClick={handleClose}
                style={{ width: "75px", border: "1px solid transparent" }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Sidebar
          activeResourceType={activeResourceType}
          setActiveResourceType={(value) => setActiveResourceType(value)}
          activeNavItem={activeNavItem}
          setActiveNavItem={(value) => setActiveNavItem(value)}
          leftNavOpen={leftNavOpen}
          toggleLeftNav={toggleLeftNav}
          enableDND={dnd}
          allResourcesChanged={allResourcesChanged}
          allResources={allResources}
          allResourcesNext={allResourcesNext}
          setAllResource={(value) => setAllResources(value)}
          currentSelectedResourcesType={currentSelectedResourcesType}
          setHomepageActiveResource={(value) => setCurrentSelectedResourcesType(value)}
          setHomepageAllResource={(value) => setAllResources(value)}
          currentNavTab={currentNavTab}
          setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
        />
        <LayoutHeader />
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          className="px-0 d-flex"
          style={{
            width: "calc(100vw - 60px)",
            justifyContent: leftNavOpen ? "flex-end" : "center",
            height: "calc(100vh - 60px )",
            overflowY: "hidden",
          }}
        >
          <div
            className={`${leftNavOpen ? "" : "col-10"} mt-0 d-flex px-2`}
            style={{
              alignItems: leftNavOpen ? "flex-end" : "center",
              flexDirection: "column",
              width: leftNavOpen ? "calc(100vw - 380px)" : "calc(100vw - 60px)",
              height: "fit-content",
            }}
          >
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              onMouseOver={(e) => {
                e.stopPropagation()
                e.preventDefault()
                let ele = document.getElementById("sections")
                if (ele !== null && ele !== undefined) {
                  if (ele.scrollWidth > ele.clientWidth) {
                    setHovered(true)
                  }
                }
              }}
              className={`${StylesB.course_creation_header} d-flex px-0`}
            >
              <p
                className="mb-0 py-2 px-3 font-weight-bold"
                id="coursename"
                style={{
                  borderRadius: "9px 0px 0px 9px",
                  lineHeight: 1,
                  minWidth: "max-content",
                  color: "#E0E2E5",
                }}
                title={course.name}
              >
                {courseName}
              </p>
              {findScrollableOrNot() && hovered ? (
                <FontAwesomeIcon
                  onClick={() => {
                    scrollLeft()
                  }}
                  className="pointer"
                  icon={faChevronLeft}
                  size="lg"
                  style={{
                    objectFit: `contain`,
                    height: `20px`,
                    color: `#E0E2E551`,
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  onClick={() => {
                    scrollLeft()
                  }}
                  className="pointer"
                  icon={faChevronLeft}
                  size="lg"
                  style={{
                    objectFit: `contain`,
                    height: `20px`,
                    color: `#E0E2E551`,
                    visibility: "hidden",
                  }}
                />
              )}
              <div id="sections" className="d-flex align-items-center hide_scrollbar" style={calculateWidth()}>
                <Link
                  className={`pointer ${StylesB.backgroundonhover}`}
                  style={{ color: "#c6cff4" }}
                  to={`/app/companies/${localStorage.company_id}/courses/edit/${course.id}`}
                >
                  <p
                    onClick={updateActiveState}
                    style={{ color: "#E0E2E5", minWidth: "max-content" }}
                    className={`${activestate === "course_detail" ? StylesB.course_nav_item_active : ""} mb-0 pointer py-2 px-2 fs-16px`}
                    id="course_detail"
                  >
                    Course Details
                  </p>
                </Link>
                <Link
                  className={`pointer ${StylesB.backgroundonhover}`}
                  style={{ color: "#c6cff4", backgroundColor: "#303C54" }}
                  to={`/app/companies/${localStorage.company_id}/courses/course-certification/${course.id}`}
                >
                  <p
                    style={{ color: "#E0E2E5", minWidth: "max-content" }}
                    className={` ${activestate === "course_certification" ? "font-weight-bold" : ``} ${
                      activestate === "course_certification" ? StylesB.course_nav_item_active : ""
                    } mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2 fs-16px`}
                    id="course_certification"
                  >
                    Certifications
                  </p>
                </Link>
                <Link
                  className={`pointer ${StylesB.backgroundonhover}`}
                  style={{ color: "#c6cff4" }}
                  to={
                    course.is_certfication === true && course.certification === null
                      ? null
                      : `/app/companies/${localStorage.company_id}/courses/course-content/${course.id}`
                  }
                  state={{
                    from_create: location.props.location.state ? location.props.location.state.from_create : false,
                  }}
                >
                  <p
                    style={{ color: "#E0E2E5", minWidth: "max-content" }}
                    className="mb-0 pl-14px pr-14px ml-10px pointer py-2 fs-16px"
                    id="course_detail"
                    onClick={(e) => {
                      if (course.is_certfication === true) {
                        let form = document.getElementById("certificate_form")
                        if (form && form?.reportValidity) {
                          form.reportValidity() && checkOnNext(e, certificate)
                        } else if (form) {
                          checkOnNext(e, certificate)
                        }
                      }
                    }}
                  >
                    Course Content
                  </p>
                </Link>
                <Link
                  className={`pointer ${StylesB.backgroundonhover}`}
                  style={{ color: "#c6cff4" }}
                  to={
                    course.is_certfication === true && course.certification === null
                      ? null
                      : `/app/companies/${localStorage.company_id}/courses/course-publish/${course.id}`
                  }
                >
                  <p style={{ color: "#E0E2E5", minWidth: "max-content" }} className="mb-0 pl-14px pr-14px ml-10px pointer py-2 fs-16px" id="course_detail">
                    Publish Course
                  </p>
                </Link>
              </div>
              {findScrollableOrNot() && hovered ? (
                <FontAwesomeIcon
                  onClick={() => {
                    scrollRight()
                  }}
                  className="pointer"
                  icon={faChevronRight}
                  size="lg"
                  style={{
                    objectFit: `contain`,
                    height: `20px`,
                    color: `#E0E2E551`,
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  onClick={() => {
                    scrollRight()
                  }}
                  className="pointer"
                  icon={faChevronRight}
                  size="lg"
                  style={{
                    objectFit: `contain`,
                    height: `20px`,
                    color: `#E0E2E551`,
                    visibility: "hidden",
                  }}
                />
              )}
              <Image
                src={CancelWhite}
                alt={`close`}
                width={20}
                height={20}
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/app/dashboard")
                }}
                className={`pointer mr-3 ml-auto float-right align-self-center`}
              />
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              onMouseOver={() => {
                setHovered(false)
              }}
              className={`d-inline-flex text-center px-0`}
            >
              <Form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  e.preventDefault()
                  checkOnNext(e, certificate)
                }}
                id={`certificate_form`}
                autoComplete={"off"}
              >
                <div
                  className="px-0"
                  style={{
                    backgroundColor: "#212C42",
                    borderRadius: "9px 9px 0px 0px",
                    boxShadow: "0px 3px 12px #00000099",
                  }}
                >
                  {getFormData()}
                </div>
                {assessments === "onturient" ? (
                  <div
                    className="px-0 align-items-center d-inline-flex w-100 mb-3"
                    style={{
                      borderTop: "1px solid #63666f",
                      boxShadow: "0px -3px 12px #00000099",
                      backgroundColor: "#212C42",
                      borderRadius: "0px 0px 9px 9px",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                    }}
                  >
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        if (course.certification === null || course.certification === undefined) {
                          handleCreate(e, certificate)
                        } else {
                          CreateCertificationAssessmentData()
                        }
                        if (activeNavItem === "" && activeResourceType === "") {
                          openNavWithResourceSection("Resource", "video")
                        }
                      }}
                      disabled={assessmentCreationLoader}
                      className={`border_on_hover float-left px-3 py-1 ml-3 ${course.is_certfication === false ? `d-none` : ``}`}
                      style={{
                        backgroundColor: "#586886",
                        border: "1px solid #586886",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "23px",
                        color: "#E0E2E5",
                        fontSize: "14px",
                      }}
                    >
                      Add Assessments
                    </button>
                    <button
                      type="submit"
                      id="submit"
                      className="border_on_hover float-left py-1 ml-2"
                      style={{
                        backgroundColor: "#586886",
                        border: "1px solid #586886",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "23px",
                        color: "#E0E2E5",
                        fontSize: "14px",
                      }}
                    >
                      Next
                    </button>
                    {course.certification === null ? null : (
                      <p className="mb-0 mt-1 float-left mr-3" style={{ fontSize: "14px" }}>
                        {loader === true ? (
                          <svg
                            className="animate-spin my-auto ml-3 mr-1"
                            style={{
                              height: `14px`,
                              width: `14px`,
                              position: "relative",
                              top: "-2px",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                          </svg>
                        ) : (
                          <FontAwesomeIcon icon={faCheck} className="ml-3 mr-1" />
                        )}
                        All changes are saved
                      </p>
                    )}
                  </div>
                ) : (
                  <div
                    className="px-0 align-items-center d-inline-flex w-100 mb-3"
                    style={{
                      borderTop: "1px solid #63666f",
                      boxShadow: "0px -3px 6px #00000099",
                      backgroundColor: "#212C42",
                      borderRadius: "0px 0px 9px 9px",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                    }}
                  >
                    <button
                      type="submit"
                      className="border_on_hover py-1 ml-3"
                      style={{
                        backgroundColor: "#586886",
                        border: "1px solid #586886",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "23px",
                        color: "#E0E2E5",
                        fontSize: "14px",
                      }}
                    >
                      Next
                    </button>
                    {course.certification === null ? null : (
                      <p className="mb-0 mt-1 ml-auto mr-3" style={{ fontSize: "14px" }}>
                        {" "}
                        <FontAwesomeIcon icon={faCheck} className="ml-3 " /> All changes are saved
                      </p>
                    )}
                  </div>
                )}
              </Form>
            </Col>
            {!showRichTextModal ? null : (
              <Editor
                showRichTextModal={showRichTextModal}
                activeInputField={activeInputField}
                minWidth={324}
                minHeight={191}
                id={activeFieldData.id}
                placeholder={activeFieldData.placeholder}
                editorMaxHeight={"400px"}
                editorMinHeight={"400px"}
                content={activeFieldData.content}
                onChange={handleContentData}
                loader={loader}
                handleRichTextEditor={handleRichTextEditor}
              />
            )}
          </div>
        </Col>
      </DragDropContext>
      <Blog
        showBlog={addBlog}
        setShowBlog={setAddBlog}
        add_to_certificate={uploadInTopic}
        sequenceId={uploadDataSequence}
        fetchTopicResources={(id) => fetchCertificationResources(id)}
        refetchLeftSideResourcesBar={() => refetchLeftSideResourcesBar("blog")}
        properties={{
          add_to_certificate: uploadInTopic,
          mainId: uploadInTopic,
        }}
      />
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default CourseCertification
