import React from "react"

import Style from "./trainer.module.scss"

const InputFieldWrap = (props) => {
  return (
    <div
      className={`${props.no_mb ? `mb-0` : `mb-2`} px-1 inner-element-box-shadow br-9px ${Style.bg_4} ${props?.error ? Style.red_border : Style.hover_border}`}
    >
      <div className="p-2" style={{ border: "1px solid transparent", background: "transparent", borderRadius: "0px", width: "100%" }}>
        {props.children}
      </div>
    </div>
  )
}

export default InputFieldWrap
