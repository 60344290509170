import React from "react"
import Col from "react-bootstrap/Col"
import BackArrow from "/src/images/svgs/back-arrow.svg"
import Close from "/src/images/svgs/close.svg"
import Image from "../custom/Image"

const HeaderTab = (props) => {
  return (
    <Col xs="12" sm="12" md="12" lg="12" className={`header-tab d-flex px-0`}>
      <div className="d-flex justify-content-space-around align-items-center text-color-1">
        {props.back === true ? <Image src={BackArrow} alt="option" className="ml-2  h-1rem w-1rem pointer" onClick={() => window.close()} /> : ""}
        <p className="mb-0 py-2 px-3 font-weight-bold poppins_font fs-18px br-9009px">{props.heading}</p>
        {props.list.map((menu, index) => (
          <p
            onClick={() => {
              !props.tabdisabled && props.setActive(index)
              {
                !props.tabdisabled && props.storeTabFlag && localStorage.setItem("activeTab", index)
              }
            }}
            key={index}
            className={`mb-0 pointer py-2 px-3 fs-16px poppins_font ${props.active == index ? "header-item-active" : ""} `}
          >
            {menu}
          </p>
        ))}
      </div>
      <Image
        src={Close}
        width={20}
        height={20}
        alt="close"
        className="pointer mr-3 float-right"
        onClick={() => {
          props?.handleClick ? props.handleClick() : window.history.back()
        }}
      />
    </Col>
  )
}

export default HeaderTab
