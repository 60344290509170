import React, { useEffect, useState } from "react"
import axios from "axios"
import Col from "react-bootstrap/Col"
import Table from "react-bootstrap/Table"
import Pagination from "react-bootstrap/Pagination"
import Layout from "../layout"
import SEO from "../seo"
import authClient from "../../services/auth"
import utcDateTime from "../../services/utcdatetime"
import { isBrowser } from "../../services/developer_mode"

import Styles from "../../modularscss/styles.module.scss"

const Enquiry = (props) => {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [enquiryLoader, setenquiryLoader] = useState(false)
  const [enquiries, setEnquiries] = useState([])
  const [previous_url, setPreviousUrl] = useState()
  const [next_url, setNextUrl] = useState()
  const [total_pages, setTotalPages] = useState()

  useEffect(() => {
    fetchEnquiries()
  }, [])

  async function fetchEnquiries() {
    setenquiryLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/enquiries/`, {
        headers: login_credentials,
      })
      .then((res) => {
        setEnquiries(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
        setenquiryLoader(true)
      })
  }

  function displayImageData() {
    let data = enquiries
    var isLoaded = enquiryLoader
    if (isLoaded) {
      if (data.length > 0) {
        return data.map((datum, index) => {
          return (
            <tr key={index}>
              <td>{`${utcDateTime.utcDateTime(datum.created, "date")}-${utcDateTime.utcDateTime(datum.created, "short_month_name")}-${utcDateTime.utcDateTime(
                datum.created,
                "year"
              )}`}</td>
              <td>{datum.name}</td>
              <td>{datum.email}</td>
              <td>{datum.description}</td>
              <td>{datum.specifications}</td>
              <td>{datum.domain}</td>
            </tr>
          )
        })
      } else {
        return (
          <tr id="loader">
            <td colSpan="12" className="animated fadeIn pt-3 text-center">
              No Data found...
            </td>
          </tr>
        )
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function showEnquiries() {
    if (authClient.isAuthenticated()) {
      return (
        <Table responsive borderless={false} variant="dark">
          <thead>
            <tr>
              <th>Date</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Description</th>
              <th>Specifications</th>
              <th>Domain</th>
            </tr>
          </thead>
          <tbody>{displayImageData()}</tbody>
        </Table>
      )
    } else {
      return ""
    }
  }

  function fetchPage(page) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/enquiries/?page=" + page, {
        headers: DefaultHeader,
      })
      .then((res) => {
        setEnquiries(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function fetchNext() {
    axios
      .get(next_url, { headers: DefaultHeader })
      .then((res) => {
        setEnquiries(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function fetchPrevious() {
    axios
      .get(previous_url, { headers: DefaultHeader })
      .then((res) => {
        setEnquiries(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function renderPageLinks() {
    var rows = []
    if (previous_url) {
      rows.push(<Pagination.Prev className={`m-0 bg-transparent`} onClick={() => fetchPrevious()} />)
    }
    for (var i = 0; i < total_pages; i++) {
      let temp_var = i + 1
      rows.push(
        <Pagination.Item key={i + 1} className={`m-0 bg-transparent`} onClick={() => fetchPage(temp_var)}>
          {i + 1}
        </Pagination.Item>
      )
    }
    if (next_url) {
      rows.push(<Pagination.Next className={`m-0 bg-transparent`} onClick={() => fetchNext()} />)
    }
    return (
      <Pagination className={`bg-card mx-0 my-3`} style={{ width: `fit-content` }}>
        {rows}
      </Pagination>
    )
  }

  return (
    <div>
      <SEO title="Enquiries" />
      <Col lg="12" md="12" sm="12" xs="12">
        <h4 className={`mt-3`}>All Enquiries</h4>
        {renderPageLinks()}
        <div className={`${Styles.b_radius_15} mb-5`} style={{ overflow: "scroll", backgroundColor: "#333b3f" }}>
          {showEnquiries()}
        </div>
      </Col>
    </div>
  )
}

export default Enquiry
