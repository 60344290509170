import React, { useContext, useEffect, useState } from "react"
import MuralAuthorization from "./authentication"

var axios = require("axios")

const Mural = (props) => {
  const [muralList, setMuralList] = useState([])
  const [templateList, setTemplateList] = useState([])
  const [muralUrl, setMuralUrl] = useState(null)
  const [muralCanvasUrl, setMuralCanvasList] = useState(null)
  const [muralWidgestList, setMuralWidgestList] = useState([])

  useEffect(() => {
    getMuralList()
    getTemplates()
  }, [])

  const getMuralList = async () => {
    await axios
      .request({
        url: "/api/public/v1/workspaces/sv3473/murals",
        method: "get",
        baseURL: "https://app.mural.co",
        headers: { Authorization: `Bearer ${localStorage.getItem("mural.access_token")}` },
      })
      .then((response) => {
        setMuralList(response.data.value)
      })
      .catch((error) => {
        console.log(error.respose)
      })
  }

  const getMuralWidgestList = async (muralId) => {
    await axios
      .request({
        url: `/api/public/v1/murals/${muralId}/widgets/`,
        method: "get",
        baseURL: "https://app.mural.co",
        headers: { Authorization: `Bearer ${localStorage.getItem("mural.access_token")}` },
      })
      .then((response) => {
        setMuralWidgestList(response.data.value)
      })
      .catch((error) => {
        console.log(error.respose)
      })
  }

  const getTemplates = async () => {
    await axios
      .request({
        url: "/api/public/v1/workspaces/sv3473/templates",
        method: "get",
        baseURL: "https://app.mural.co",
        headers: { Authorization: `Bearer ${localStorage.getItem("mural.access_token")}` },
        params: { withoutDefault: true },
      })
      .then((response) => {
        setTemplateList(response.data.value)
      })
      .catch((error) => {
        console.log(error.respose)
      })
  }

  const duplicateMural = async (item, index) => {
    let payload = {
      title: "Duplicate Mural - Ok",
      roomId: 1653368913479,
    }
    await axios
      .request({
        url: `/api/public/v1/murals/${item.id}/duplicate`,
        method: "post",
        baseURL: "https://app.mural.co",
        headers: { Authorization: `Bearer ${localStorage.getItem("mural.access_token")}` },
        data: payload,
      })
      .then((response) => {
        getMuralList()
      })
      .catch((error) => {
        console.log(error.respose)
      })
  }

  const duplicateTemplate = async (item, index) => {
    let payload = {
      title: "Duplicate Template - Ok",
      roomId: 1653368913479,
    }
    await axios
      .request({
        url: `/api/public/v1/templates/${item.id}/murals`,
        method: "post",
        baseURL: "https://app.mural.co",
        headers: { Authorization: `Bearer ${localStorage.getItem("mural.access_token")}` },
        data: payload,
      })
      .then((response) => {
        getMuralList()
      })
      .catch((error) => {
        console.log(error.respose)
      })
  }

  const listTemplateList = () => {
    if (templateList.length > 0) {
      return templateList.map((item, index) => {
        return (
          <React.Fragment>
            <li key={index + `_template`}>
              {item.name}{" "}
              <div
                onClick={() => {
                  duplicateTemplate(item, index)
                }}
              >
                Create Duplicate Template
              </div>
            </li>
          </React.Fragment>
        )
      })
    }
  }

  const showMuralWidget = (item) => {
    let muralCanvas = muralCanvasUrl.split("?")
    muralCanvas = `${muralCanvas[0]}?wid=${item.id}`
    setMuralUrl(
      `https://app.mural.co/signin-code/authenticate?redirectUrl=${muralCanvas}&authUrl=http://localhost:8001/app/mural&clientId=f95b5e5b-106f-4bbc-bc6c-c826c658f609&t=1658903152604`
    )
  }

  const showMuralWidgestList = () => {
    if (muralWidgestList.length > 0) {
      return muralWidgestList.map((item, index) => {
        return (
          <React.Fragment>
            {item.title ? (
              <li key={index + `_widgets`}>
                {item.title}{" "}
                <button
                  onClick={() => {
                    showMuralWidget(item, index)
                  }}
                >
                  show Widgest
                </button>
              </li>
            ) : (
              ""
            )}
          </React.Fragment>
        )
      })
    }
  }

  const showMural = (muralObj, index) => {
    setMuralCanvasList(muralObj._canvasLink)
    setMuralUrl(
      `https://app.mural.co/signin-code/authenticate?redirectUrl=${muralObj._canvasLink}&authUrl=http://localhost:8001/app/mural&clientId=f95b5e5b-106f-4bbc-bc6c-c826c658f609&t=1658903152604`
    )
    getMuralWidgestList(muralObj.id)
  }
  const list = () => {
    if (muralList.length > 0) {
      return muralList.map((item, index) => {
        return (
          <React.Fragment>
            <li key={index + `_mural`}>
              {item.title}{" "}
              <button
                onClick={() => {
                  duplicateMural(item, index)
                }}
              >
                Duplicate Mural
              </button>
              <button
                onClick={() => {
                  deleteMural(item, index)
                }}
              >
                Delete Mural
              </button>
              <button
                onClick={() => {
                  showMural(item, index)
                }}
              >
                Show Mural
              </button>
            </li>
          </React.Fragment>
        )
      })
    }
  }

  const deleteMural = async (item, index) => {
    await axios
      .request({
        url: `/api/public/v1/murals/${item.id}`,
        method: "delete",
        baseURL: "https://app.mural.co",
        headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}`, Accept: "application/json" },
      })
      .then((response) => {
        getMuralList()
      })
      .catch((error) => {
        console.log(error.respose)
      })
  }

  return (
    <React.Fragment>
      <MuralAuthorization />
      <div>{list()}</div>
      <div>
        Templates List
        <div>{listTemplateList()}</div>
      </div>
      {muralUrl ? (
        <div className="row">
          <div className="col-6">
            <iframe id="mural-convas" width={"100%"} height={300} src={muralUrl} />
          </div>
          <div className="col-6">{showMuralWidgestList()}</div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default Mural
