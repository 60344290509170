import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import ProgressBar from "react-bootstrap/ProgressBar"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import Modal from "react-bootstrap/Modal"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import Image from "../../components/image"
import { isBrowser } from "../../services/developer_mode"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faEdit, faTrashAlt, faExternalLinkSquareAlt, faChevronLeft, faChevronUp, faTimesCircle, faCheck } from "@fortawesome/free-solid-svg-icons"

import Rewards from "../../images/svgs/points.svg"
import ArrowWhite from "../../images/svgs/arrow-white.svg"
import Close from "../../images/svgs/close.svg"

import Styles from "../../modularscss/styles.module.scss"

const EditCourseTemplate = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [course_template, setCourseTemplate] = useState({})
  const [isReplica, setIsReplica] = useState(false)
  // const [allVideos, setAllVideos] = useState([])
  const [selectedVideosSet, setSelectedVideosSet] = useState([])

  // to delete modal of module
  const [dmodal, setDmodal] = useState(false)
  const [dIndexs, setDIndexs] = useState([])
  const [selectedDeletingModule, setSelectedDeletingModule] = useState([])

  const handleClose = () => {
    setDmodal(false)
    setSelectedDeletingModule([])
  }

  useEffect(() => {
    fetchTemplate()
    // searchVideos()
    course_template.videos = []
  }, [])

  async function fetchTemplate() {
    // console.log("fetchGroups called")
    // setCompanyLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/default-course-templates/${location.id}/`, { headers: login_credentials }).then((res) => {
      setCourseTemplate(res.data)
      setSelectedVideosSet(res.data.videos)
      // setCompanyLoader(true)
    })
  }

  /*function searchVideos(search = '') {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST+`/basic-videos-list/?search=`+search, {headers: login_credentials})
    .then(res => {
      setAllVideos(res.data.results);
    })
  }*/

  function handleChange(event) {
    event.preventDefault()
    course_template[event.target.name] = event.target.value
  }

  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in course_template) {
      if (k == "videos") {
        formData.append("videos", JSON.stringify(course_template.videos))
      } else if (k == "resources") {
        // formData.append('videos',JSON.stringify(course_template.videos))
      } else {
        formData.append(k, course_template[k])
      }
    }
    if (isReplica) {
      if (location.props.location.hash.includes("#type=course")) {
        course_template["template_type"] = "1"
      } else if (location.props.location.hash.includes("#type=training")) {
        course_template["template_type"] = "2"
      }
    }
    course_template["type_id"] = location.props.location.hash.split(",")[1]
    if (course_template.id != undefined) {
      var course_template_id = course_template.id
      let url = course_template_id + "/"
      if (isReplica) {
        url = "replica/" + course_template_id + "/" + location.props.location.hash.replace("#type=", "?").replace(",", "=")
      }
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/default-course-templates/" + url, formData, { headers: login_credentials })
        .then((res) => {
          if (isBrowser()) {
            window.history.back()
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function toggle(e, type) {
    e.preventDefault()
  }

  function addToTemplate(fieldData, field_name) {
    let data = course_template.field_name,
      ids = fieldData.map((item) => {
        if (item.id != undefined) {
          return item.id
        } else {
          return item
        }
      })
    data = ids
    course_template[field_name] = data
    setSelectedVideosSet([...data])
    console.log(selectedVideosSet)
  }

  async function deleteModule() {
    let current_id = selectedDeletingModule.id
    var index = course_template.stages[dIndexs[0]].modules.findIndex((res) => res.id === current_id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/modules/` + current_id + "/", { headers: DefaultHeader, body: selectedDeletingModule })
      .then((res) => {
        course_template.stages[dIndexs[0]].modules.splice(index, 1)
        if (course_template.stages[dIndexs[0]].modules.length === 0) {
          course_template.stages[dIndexs[0]].resources_exist = false
        }
        setDmodal(!dmodal)
        setDIndexs([])
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  const renderTooltip = (props) => (
    <Popover className="mw-100" id="progress-tooltip" {...props}>
      <div className={`w-100 text-right p-3`} style={{ color: `#5975D1`, marginLeft: "0px" }}>
        <FontAwesomeIcon onClick={(e) => document.body.click()} icon={faTimesCircle} style={{ width: `24px`, height: `24px` }} />
      </div>
      <div className={`w-100 px-3`} style={{ color: `#F2F3F5`, marginLeft: "60px" }}>
        <h6 className={`float-left`}>Your Progress</h6>
        <img src={Rewards} alt={`Rewards Program`} className={`my-auto ml-3 pl-2`} style={{ objectFit: `contain`, height: `24px` }} />
        <span className={`dummy-data`} style={{ color: `#C6CAD8`, fontSize: `12px` }}>
          &nbsp; 20 Points earned
        </span>
        <Col lg="12" md="12" sm="12" xs="12" className={`d-inline-flex`}>
          <div className={`mt-3 mb-5 float-left`}>
            <div className={`d-flex py-2`} style={{ backgroundColor: `#3F5396`, borderRadius: `9px`, width: `200px` }}>
              <span className={`font-weight-bold text-right align-self-center`} style={{ width: `20%`, fontSize: `40px` }}>
                1
              </span>
              <span className={`text-white align-self-center`} style={{ width: `60%`, lineHeight: `normal` }}>
                Course <br />
                Information
              </span>
              <span className={`align-items-center`} style={{ width: `20%` }}>
                <img src={Rewards} alt={`Rewards Program`} className={`m-0`} style={{ objectFit: `contain`, height: `24px` }} />
              </span>
            </div>
            <Link
              to={`/app/companies/${location.props.location.hash.split(",")[2]}/courses/edit/${location.props.location.hash.split(",")[1]}`}
              className={`d-flex mt-3 py-2 align-items-center`}
              style={{ backgroundColor: `#3F5396`, color: `white`, borderRadius: `9px`, width: `200px` }}
            >
              <FontAwesomeIcon icon={faCheck} style={{ width: `18px`, height: `18px`, marginLeft: `15px` }} />
              &emsp; Course Type
            </Link>
            <Link
              to={`/app/companies/${location.props.location.hash.split(",")[2]}/courses/edit/${location.props.location.hash.split(",")[1]}`}
              className={`d-flex mt-3 py-2 align-items-center`}
              style={{ backgroundColor: `#3F5396`, color: `white`, borderRadius: `9px`, width: `200px` }}
            >
              <FontAwesomeIcon icon={faCheck} style={{ width: `18px`, height: `18px`, marginLeft: `15px` }} />
              &emsp; Course Identity
            </Link>
            <Link
              to={`/app/companies/${location.props.location.hash.split(",")[2]}/courses/edit/${location.props.location.hash.split(",")[1]}`}
              className={`d-flex mt-3 py-2 align-items-center`}
              style={{ backgroundColor: `#3F5396`, color: `white`, borderRadius: `9px`, width: `200px` }}
            >
              <FontAwesomeIcon icon={faCheck} style={{ width: `18px`, height: `18px`, marginLeft: `15px` }} />
              &emsp; Course Benefits
            </Link>
            <Link
              to={`/app/companies/${location.props.location.hash.split(",")[2]}/courses/edit/${location.props.location.hash.split(",")[1]}`}
              className={`d-flex mt-3 py-2 align-items-center`}
              style={{ backgroundColor: `#3F5396`, color: `white`, borderRadius: `9px`, width: `200px` }}
            >
              <FontAwesomeIcon icon={faCheck} style={{ width: `18px`, height: `18px`, marginLeft: `15px` }} />
              &emsp; Target Audience
            </Link>
          </div>
          <div className={`float-left`}>
            <img src={ArrowWhite} alt={`Connection`} className={`my-auto`} style={{ objectFit: `contain`, width: `100%` }} />
          </div>
          <div className={`mt-3 mb-5 float-left`}>
            <div className={`d-flex py-2`} style={{ backgroundColor: `#3F5396`, borderRadius: `9px`, width: `200px` }}>
              <span className={`font-weight-bold text-right align-self-center`} style={{ width: `20%`, fontSize: `40px` }}>
                2
              </span>
              <span className={`text-white align-self-center pl-1`} style={{ width: `60%`, lineHeight: `normal` }}>
                Course <br />
                Content
              </span>
              <span className={`align-items-center`} style={{ width: `20%` }}>
                <img src={Rewards} alt={`Rewards Program`} className={`m-0`} style={{ objectFit: `contain`, height: `24px` }} />
              </span>
            </div>
            {course_template && course_template.stages
              ? course_template.stages.map((node, index) =>
                  node.resources_exist ? (
                    <Link
                      className={`pointer`}
                      to={`/app/default-course-templates/${course_template.id}/stages/${node.id}/modules/view/${location.props.location.hash}`}
                    >
                      <div
                        className={`d-flex mt-3 py-2 align-items-center`}
                        style={{ backgroundColor: `#3F5396`, color: `white`, borderRadius: `9px`, width: `200px` }}
                      >
                        <FontAwesomeIcon icon={faCheck} style={{ width: `18px`, height: `18px`, marginLeft: `15px` }} />
                        &emsp; {node.name}
                      </div>
                    </Link>
                  ) : (
                    <Link
                      className={`pointer`}
                      to={`/app/default-course-templates/${course_template.id}/stages/${node.id}/modules/view/${location.props.location.hash}`}
                    >
                      <div
                        className={`d-flex mt-2 align-items-center`}
                        style={{ backgroundColor: `transparent`, color: `#AAB6DE`, borderRadius: `9px`, width: `200px` }}
                      >
                        &emsp; {node.name}
                      </div>
                    </Link>
                  )
                )
              : ""}
          </div>
        </Col>
      </div>
    </Popover>
  )

  return (
    <div>
      <SEO title="CourseTemplate Creation" />
      {/* <h5 className={`border-bottom p-2 d-inline-flex w-100 align-items-center`}>
        <Link className={`pointer`} to={`/app/companies/${location.props.location.hash.split(",")[2]}/courses/edit/${location.props.location.hash.split(",")[1]}`} style={{ color: `#C6CFF4` }} > <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} /></Link>&nbsp;
        <span style={{ color: `#c6cff480` }} ><span className={`font-weight-bold`} style={{ fontSize: `30px` }} >2</span>/1 </span> &nbsp;Course Content
        <Link className={`pointer ml-auto`} to={`/app/dashboard`} style={{ color: `#C6CFF4` }} >
          <span style={{fontWeight: 'normal'}} className={`small`}>
            Exit Course &nbsp;<img src={Close} alt={`Exit Course`} className={`my-auto`} style={{ objectFit: `contain`, height: `23px`, color: `white` }}/>
          </span>
        </Link>
      </h5> */}
      <Col xs="12" sm="12" md="10" lg="10" className="w-100 mr-0 mb-0" style={{ marginLeft: "10%", marginTop: "4%", marginBottom: "20px", height: "54px" }}>
        <Container className={`px-0 mx-0`}>
          <Col xs="12" sm="12" md="12" lg="12" className={`pr-0 pl-0 py-2 float-left`}>
            <div
              className={`turient-body-text-color pl-1px d-flex`}
              style={{ backgroundColor: `#212c42`, borderRadius: "9px", boxShadow: "0px 3px 12px #00000099" }}
            >
              <p className="mb-0 pl-14px font-weight-bold pointer pt-2 pb-2" id="new_course" style={{ borderRadius: "9px 0px 0px 9px" }}>
                New Course
              </p>
              <Link
                className="pointer"
                style={{ color: "#c6cff4" }}
                to={`/app/companies/${location.props.location.hash.split(",")[2]}/courses/edit/${location.props.location.hash.split(",")[1]}`}
              >
                <p className="mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2" id="course_detail">
                  Course Details
                </p>
              </Link>
              <p className="mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2" id="course_content" style={{ backgroundColor: "#313d54" }}>
                Course Content
              </p>
              <p className="mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2" id="publish_course">
                Publish Course
              </p>
              {/* <button className='border_on_hover float-right mt-1 d-flex pt-1 ' style={{backgroundColor:'#586886',border:'1px solid #586886',height:'35px',paddingLeft:'15px',paddingRight:'15px',boxShadow:'0px 3px 6px #00000029',borderRadius:'23px',color:'#E0E2E5',fontSize:'16px',position:'absolute',right:"15px"}}><p>Preview students page</p> <FontAwesomeIcon className='mr-0 mt-1 ml-2' icon={faExternalLinkSquareAlt}  /></button> */}
            </div>
          </Col>
        </Container>
      </Col>
      <Col xs="12" sm="12" md="12" lg="12" className={`pl-4 pr-2 mb-4 pb-2 py-4`}>
        <Col lg="12" md="12" sm="12" xs="12">
          <Modal show={dmodal} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete Module {selectedDeletingModule ? selectedDeletingModule.name : ""}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                cancel
              </Button>
              <Button variant="primary" onClick={deleteModule}>
                delete
              </Button>
            </Modal.Footer>
          </Modal>
          <div style={{ alignItems: "center", justifyContent: "center" }}>
            <Container>
              <div className={`d-flex w-100`}>
                {course_template && course_template.stages
                  ? course_template.stages.map((node, index) => (
                      <Col xs="12" sm="4" md="4" lg="4" key={index + `_stages`} className="mt-2">
                        <h6 className={`text-white`}> {node.name} </h6>
                        <Col
                          xs="12"
                          sm="12"
                          md="12"
                          className={`light-div theme-color ${
                            node && node.modules && node.modules.length > 0 ? "p-0 text-center" : "justify-content-center align-content-center d-grid"
                          } ${
                            index === 0 || (node.modules && node.modules.length > 0) || (index > 0 && course_template.stages[index - 1].resources_exist)
                              ? ``
                              : `disabled`
                          }`}
                        >
                          {node && node.modules && node.modules.length > 0 ? (
                            node.modules.map((module, index2) => (
                              <div key={index2 + `_module`} className={`d-flex align-items-center`} style={{ borderBottom: "1px solid", padding: "15px" }}>
                                <h6 className={`m-0 text-left`}>
                                  {module.name}
                                  <br />
                                  <p style={{ fontSize: `12px`, color: `#F2F3F5` }} className={`mb-0 mt-1 font-italic`}>
                                    {module && module.resources_exist ? `` : `Please add content`}
                                  </p>
                                </h6>
                                <div className={`ml-auto`}>
                                  <Link
                                    to={`/app/default-course-templates/${course_template.id}/stages/${node.id}/modules/view/${location.props.location.hash},module=${module.id}`}
                                  >
                                    <Button
                                      variant="none"
                                      className={`bg-transparent shadow-none text-white pr-1`}
                                      onClick={(e) => {
                                        setDmodal(true)
                                        setSelectedDeletingModule(module)
                                        setDIndexs([index, index2])
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEdit} style={{ width: `16px`, height: `16px` }} />
                                    </Button>
                                  </Link>
                                  <Button
                                    variant="none"
                                    className={`bg-transparent shadow-none text-white pl-1`}
                                    onClick={(e) => {
                                      setDmodal(true)
                                      setSelectedDeletingModule(module)
                                      setDIndexs([index, index2])
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} style={{ width: `16px`, height: `16px` }} />
                                  </Button>
                                </div>
                              </div>
                            ))
                          ) : (
                            <React.Fragment>
                              <Image
                                style={{ width: "126px", height: "104px" }}
                                alt="Eskola logo"
                                src="stages.png"
                                className="d-inline-block align-center mobile-hide"
                              />
                              <FormGroup>
                                <label style={{ fontSize: "12px" }} htmlFor="course_template">
                                  Add Content for {node.name}
                                </label>
                              </FormGroup>
                            </React.Fragment>
                          )}
                          <Link
                            style={{ color: `#c6cff4` }}
                            className={`m-3 align-self-center d-inline-block`}
                            to={`/app/default-course-templates/${course_template.id}/stages/${node.id}/modules/view/${location.props.location.hash}`}
                          >
                            <p
                              className={`${Styles.font_12} text-center px-3 py-2 my-0 text-white`}
                              style={{ backgroundColor: `#5975D1`, borderRadius: `.25rem` }}
                            >
                              <FontAwesomeIcon icon={faPlus} size="sm" />
                              {` `}Add Content
                            </p>
                          </Link>
                        </Col>
                      </Col>
                    ))
                  : ""}
              </div>
            </Container>
          </div>
          {/*<Button onClick={()=>isBrowser() ? window.history.back() : ''} variant="danger" >Go Back</Button>*/}
        </Col>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className={`px-0 d-inline-flex pointer`}
          style={{ backgroundColor: `#28314E`, position: "fixed", bottom: "0px", right: "30px", width: "92%" }}
        >
          <OverlayTrigger id={`progress`} placement="top-start" flip={true} trigger="click" overlay={renderTooltip} arrowProps={""} rootClose>
            <Col lg="12" md="12" sm="12" xs="12" className={`p-3 align-items-center align-self-center d-inline-flex`}>
              <div className={`text-white font-italic float-left w-50`}>
                <p className={`m-0`} style={{ fontSize: `12px` }}>
                  3 of 4 Steps
                </p>
                <div className={`d-flex align-items-center`}>
                  <ProgressBar className={`float-left`} animated now={75} style={{ height: `12px`, width: `100%` }} />
                </div>
              </div>
              <span className={`float-left pl-2`} style={{ fontSize: `12px`, alignSelf: `flex-end` }}>
                All changes Saved
              </span>
              <FontAwesomeIcon icon={faChevronUp} style={{ width: `20px`, height: `20px`, float: `right`, marginLeft: `auto` }} />
            </Col>
          </OverlayTrigger>
        </Col>
      </Col>
    </div>
  )
}

export default EditCourseTemplate
