import React from "react"
import EngagementListCard from "../EngagementListCard"

const EducatorLevelEngagementList = (props) => {
  return (
    <div className="w-100 h-100 overflow-y-scroll">
      {props.data && props.data?.length > 0 ? (
        props.data.map((data) => {
          return <EngagementListCard icon={props.icon} type={props.type} calender={props.calender} data={data} />
        })
      ) : (
        <div className="w-100 h-100 d-flex justify-center align-items-center">
          <p className="fs-14px w-100 text-center">No data found.</p>
        </div>
      )}
    </div>
  )
}

export default EducatorLevelEngagementList
