import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Modal from "react-bootstrap/Modal"
import Pagination from "react-bootstrap/Pagination"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons"

import Layout from "../layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

const CourseTemplates = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [coursetemplateLoader, setCoursetemplateLoader] = useState(false)
  const [courseTemplates, setCourseTemplates] = useState([])

  const [previous_url, setPreviousUrl] = useState()
  const [next_url, setNextUrl] = useState()
  const [total_pages, setTotalPages] = useState()

  const [dmodal, setDmodal] = useState(false)
  const [currentTemplate, setCurrentTemplate] = useState([])

  useEffect(() => {
    fetchTemplates()
  }, [])

  async function fetchTemplates() {
    setCoursetemplateLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/default-course-templates/`, { headers: login_credentials }).then((res) => {
      setCourseTemplates(res.data.results)
      setNextUrl(res.data.next)
      setPreviousUrl(res.data.previous)
      setTotalPages(parseInt(Number(res.data.count) / 5))
      setCoursetemplateLoader(true)
    })
  }

  const handleClose = () => {
    setDmodal(false)
    setCurrentTemplate([])
  }

  function deleteTemplate() {
    var current_template_id = currentTemplate.id
    var index = courseTemplates.findIndex((res) => res.id === current_template_id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/default-course-templates/" + current_template_id + "/", {
        headers: DefaultHeader,
        body: currentTemplate,
      })
      .then((res) => {
        courseTemplates.splice(index, 1)
        setCurrentTemplate([])
        setDmodal(!dmodal)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  /*function displayTemplateHeader() {
    let data = courseTemplates;
    var isLoaded = coursetemplateLoader
    if (isLoaded) {
      if(data.length > 0){
        let keys = Object.keys(data[0])
        let heading = []
        heading.push(<td>&nbsp;</td>)
        heading.push(<td>&nbsp;</td>)
        for (let key in keys) {
          if (keys[key].toLowerCase() !== 'created' &&
                keys[key].toLowerCase() !== 'updated_at' &&
                keys[key].toLowerCase() !== 'videos'){
            heading.push(<th style={{textTransform: 'uppercase'}}>
            {keys[key].replace(/_/g, ' ')}
            </th>)
          }
        }
        return (
          <tr >
            {heading}
          </tr>
        );
      }else{
        return(
          ''
        )
      }
    }
    else {
      return (
        <tr id="loader"><td colSpan="12" className="animated fadeIn pt-3 text-center">Loading...</td></tr>
      )
    }
  }*/

  /*function displayTemplateData() {
    let data = courseTemplates;
    var isLoaded = coursetemplateLoader
    if (isLoaded) {
      if(data.length > 0){
        let keys = Object.keys(data[0]),
        parsed_data = []
        data.map( (datum, index) => {
          let values =[]
          values.push(<td><Link to={`/app/default-course-templates/edit/`+datum.id} > Edit</Link></td>)
          values.push(<td><Link to={`/app/default-course-templates/view/`+datum.id} > View</Link></td>)
          for (let key in keys) {
            if (keys[key].toLowerCase() !== 'created' &&
                keys[key].toLowerCase() !== 'updated_at' && keys[key].toLowerCase() !== 'stages'){
              values.push(<td> {datum[keys[key]]} </td>)
            } else if (keys[key].toLowerCase() === 'resources') {
              values.push(<div className={`d-flex justify-content-center`}>
                <td className={``}><Link to={`/app/default-course-templates/${datum.id}/resources/create/`} > Add</Link></td>
                <td className={`px-3`}><Link to={`/app/default-course-templates/${datum.id}/resources/view/`} > View</Link></td>
              </div>)
            } else if (keys[key].toLowerCase() === 'stages') {
              values.push(
                <td className={``}>
                  <Link to={`/app/default-course-templates/${datum.id}/stages/create/`} > Add</Link>
                  <Link className={`ml-3`} to={`/app/default-course-templates/${datum.id}/stages/view/`} > View</Link>
                </td>
              )
            }
          }
          parsed_data.push(<tr key={index} >{values}</tr>)
        })
        return(
          <tbody>
            {parsed_data}
          </tbody>
        )
      }else{
        return(
          <tr id="loader"><td colSpan="12" className="animated fadeIn pt-3 text-center">No Data found...</td></tr>
        )
      }
    }
    else {
      return (
        <tr id="loader"><td colSpan="12" className="animated fadeIn pt-3 text-center">Loading...</td></tr>
      )
    }
  }*/

  function showDmodal() {
    return (
      <Modal show={dmodal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {currentTemplate ? currentTemplate.name : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete Course {currentTemplate ? currentTemplate.name : ""}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="primary" onClick={deleteTemplate}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function showStages(template) {
    let stage_names = []
    template.stages.map((stage, index) => {
      stage_names.push(
        <div className={`py-2`}>
          <span className={`${Styles.elipsis_2}`}>
            Stage {index} : {stage.name}
          </span>
        </div>
      )
    })
    return stage_names
  }

  function displayTemplates() {
    let data = courseTemplates
    var isLoaded = coursetemplateLoader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0]),
          parsed_data = []
        data.map((datum, index) => {
          let stages = showStages(datum)
          parsed_data.push(
            <Col lg="4" md="6" sm="12" xs="12" key={index} className={`p-3 text-white`}>
              <Card className="" style={{ backgroundColor: `#28324e`, height: `100%`, borderRadius: `15px` }}>
                <Card.Header className={`text-right text-white border-0 bg-transparent p-0`}>
                  <Button
                    variant="none"
                    className={`bg-transparent shadow-none text-white`}
                    onClick={(e) => {
                      setDmodal(true)
                      setCurrentTemplate(datum)
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                  </Button>
                </Card.Header>
                <Card.Body className={`pt-0`}>
                  <Card.Title> {datum.name}</Card.Title>
                  <p className={`mb-0 font-wieght-bold`}>
                    Stages &nbsp;
                    {/*<Link className={`${Styles.font_12} text-white`} to={`/app/default-course-templates/view/${datum.id}/`} >
                      <FontAwesomeIcon icon={faEdit} size="lg" />
                    </Link>*/}
                  </p>
                  <Card.Text className={`mb-2 px-3`} style={{}}>
                    {stages}
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className={`d-flex`}>
                    <Link className={`${Styles.font_12} text-white`} to={`/app/default-course-templates/edit/${datum.id}/`}>
                      {" "}
                      Edit Template &nbsp;&nbsp;
                      <FontAwesomeIcon icon={faEdit} size="lg" />
                    </Link>
                    <Link className={`${Styles.font_12} ml-auto text-white`} to={`/app/default-course-templates/${datum.id}/stages/create/`}>
                      <FontAwesomeIcon icon={faPlus} size="lg" />
                      &nbsp;&nbsp; Add Stage
                    </Link>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          )
        })
        return parsed_data
      } else {
        return (
          <tr id="loader">
            <td colSpan="12" className="animated fadeIn pt-3 text-center">
              No Data found...
            </td>
          </tr>
        )
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function showTemplates() {
    if (authClient.isAuthenticated()) {
      return displayTemplates()
    } else {
      return ""
    }
  }

  function fetchPage(page) {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/default-course-templates/?page=" + page, { headers: DefaultHeader })
      .then((res) => {
        setCourseTemplates(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function fetchNext() {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(next_url, { headers: DefaultHeader })
      .then((res) => {
        setCourseTemplates(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }
  function fetchPrevious() {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(previous_url, { headers: DefaultHeader })
      .then((res) => {
        setCourseTemplates(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function renderPageLinks() {
    var rows = []
    if (previous_url) {
      rows.push(<Pagination.Prev onClick={() => fetchPrevious()} />)
    }
    for (var i = 0; i < total_pages; i++) {
      rows.push(<Pagination.Item onClick={() => fetchPage(i + 1)}>{i + 1}</Pagination.Item>)
    }
    if (next_url) {
      rows.push(<Pagination.Next onClick={() => fetchNext()} />)
    }
    return <Pagination>{rows}</Pagination>
  }

  return (
    <div>
      <SEO title="CourseTemplates" />
      <Col lg="12" md="12" sm="12" xs="12" className={`py-3`}>
        <h4>All Default Course Templates</h4>
        {showDmodal()}
        {renderPageLinks()}
        <Row>{showTemplates()}</Row>
        <Col lg="12" md="12" sm="12" xs="12" className={`mt-5 px-0`}>
          <Link to={`/app/default-course-templates/create`}>
            <Button variant="secondary" style={{ backgroundColor: `#28324e` }}>
              <FontAwesomeIcon icon={faPlus} size="lg" /> <br />
              Add Template
            </Button>
          </Link>
        </Col>
      </Col>
    </div>
  )
}

export default CourseTemplates
