import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" } }) {
        nodes {
          id
          name
          base
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  return data.allFile.nodes.map((image) => {
    if (props.src === image.base) {
      if (props.type === `fluid`) {
        return (
          <Img key={image.id} fluid={image.childImageSharp.fluid} alt={image.name} style={props.style} imgStyle={props.imgStyle} className={props.className} />
        )
      } else {
        return (
          <Img key={image.id} fixed={image.childImageSharp.fixed} alt={image.name} style={props.style} imgStyle={props.imgStyle} className={props.className} />
        )
      }
    } else {
      return null
    }
  })
}

export default Image
