import React from "react"
import Btn from "../custom/Button"
import CheckIcon from "../../images/svgs/check-white.svg"
import PropTypes from "prop-types"
import Loader from "../custom/Loader"

/**
 * Component for showing loader while auto saving.
 * @param props --> isLoading, showBtn, btnText, onClick
 * @returns {React.ReactElement} AutoSaveLoader component.
 */

const AutoSaveLoader = ({ isLoading, showBtn = true, btnText = "Done", onClick = () => {} }) => {
  return (
    <div className="mb-0 fs-14px px-3 py-2 text-white d-flex z-index-1 align-items-center justify-content-between">
      {showBtn && (
        <Btn disabled={isLoading} onClick={onClick}>
          {btnText}
        </Btn>
      )}
      <div className="d-flex align-items-center">
        {isLoading ? (
          <>
            <Loader class="mr-1" size="14px" />
            <span>Saving</span>
          </>
        ) : (
          <>
            <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer`} width="13px" />
            All changes are saved
          </>
        )}
      </div>
    </div>
  )
}

AutoSaveLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  showBtn: PropTypes.bool,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
}

export default AutoSaveLoader
