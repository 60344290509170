import React, { useEffect, useState } from "react"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Dropdown from "react-bootstrap/Dropdown"
import Spinner from "react-bootstrap/Spinner"

import StylesA from "../template-resources/breakout-session.module.scss"
import StylesB from "../courses/course.module.scss"
import authClient from "../../services/auth"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen, faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import eye from "../../images/classroom/eye-icon.png"
import DeleteIcon from "../../images/svgs/delete.svg"
import CheckIcon from "../../images/svgs/check-white.svg"
import TrashCan from "../../images/svgs/trash-can-transparent.svg"
import CancelWhite from "../../images/svgs/close.svg"
import BreakoutSessionsIcon from "../../images/svgs/breakoutsession.svg"
import YellowBreakoutSessionsIcon from "../../images/svgs/yellowbreakoutsession.svg"
import { isBrowser } from "../../services/developer_mode"
import { HasEditPermission, HasReadPermission } from "../../utils/PermissionDataAccess"
import { COURSES } from "../../constants/permissionsConstant"

const BreakoutSessionSection = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""
  const [showModal, setShowModal] = useState(false)
  const [sessionsFetched, setSessionsFetched] = useState(false)
  const [allSessions, setAllSessions] = useState([])
  const [availableSessions, setAvailableSessions] = useState([])
  const [breakoutDeleteWarningModal, setBreakoutDeleteWarningModal] = useState(false)
  const [selectedSessionForDelete, setSelectedSessionForDelete] = useState("")

  async function fetchBreakoutSessions() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/breakout-sessions/?basic-data=true&template=${props.template_id}&paginate_by=20`, {
        headers: login_credentials,
      })
      .then((res) => {
        setAllSessions(res.data.results)
        let current_sessions = res.data.results
        current_sessions = current_sessions.filter((session) => session.topic === null || session.topic === props.datum.id)
        setAvailableSessions(current_sessions)
        setSessionsFetched(true)
      })
  }
  function toggleItem(session) {
    let current_sessions = [...props.datum.breakout_sessions]
    let selected_session = current_sessions.filter((item) => item.id === session.id)
    if (selected_session.length === 1) {
      let item_index = current_sessions.indexOf(selected_session[0])
      current_sessions.splice(item_index, 1)
      session.topic = ""
      updateBreakoutSession(session)
    } else {
      session.topic = props.datum.id
      current_sessions.push(session)
      updateBreakoutSession(session)
    }
    props.setCurrentSessions(current_sessions)
  }
  function updateBreakoutSession(session) {
    var formData = new FormData()
    for (var k in session) {
      if (session[k] != null) {
        formData.append(k, session[k])
      }
    }
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/breakout-session/` + session.id + "/", formData, { headers: DefaultHeader })
      .then((res) => {
        let updated_session = {
          id: res.data.id,
          name: res.data.name,
          status: res.data.status,
          topic: res.data.topic,
        }
        let item_index = availableSessions.indexOf(updated_session)
        let current_sessions = [...availableSessions]
        current_sessions[item_index] = updated_session
        setAvailableSessions(current_sessions)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }
  async function deleteBreakoutSession() {
    let session = selectedSessionForDelete
    let value = ""
    if (props.draft_content && props.draft_content === "true") {
      value = `?draft_content=true`
    }
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/breakout-session/` + selectedSessionForDelete.id + `/${value}`, { headers: DefaultHeader })
      .then((res) => {
        let current_sessions = [...props.datum.breakout_sessions]
        let selected_session = current_sessions.filter((item) => item.id === session.id)
        if (selected_session.length === 1) {
          let item_index = current_sessions.indexOf(selected_session[0])
          current_sessions.splice(item_index, 1)
          props.setCurrentSessions(current_sessions)
        }
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function showBreakoutSessions() {
    if (availableSessions.length > 0) {
      return (
        <React.Fragment>
          {availableSessions.map((session, index) => {
            return (
              <div
                key={"breakout_" + session?.id}
                onClick={() => toggleItem(session)}
                className={`${StylesA.border_on_hover} d-flex pointer`}
                style={{
                  alignItems: "center",
                  backgroundColor: "#303C54",
                  padding: "8px",
                  borderRadius: "8px",
                  marginBottom: "8px",
                  border: "1px solid transparent",
                }}
              >
                <div
                  className="d-flex pointer mr-2"
                  style={{
                    backgroundColor: "#586886",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    justifyContent: "center",
                    border: "1px solid #E0E2E5",
                  }}
                >
                  <img
                    src={CheckIcon}
                    alt={session.id + "select_icon"}
                    className={`${session.topic === props.datum.id ? "" : "d-none"} my-auto`}
                    style={{ objectFit: `contain`, width: `13px` }}
                  />
                </div>
                <p style={{ fontSize: "14px", color: "#E0E2E5", marginBottom: "0px" }}>{session.name}</p>
              </div>
            )
          })}
          <Button
            onClick={() => {
              props.setCreateBreakoutSessionOpened(true)
              setShowModal(false)
              props.setSelectedTopicForBreakout(props.datum.id)
            }}
            className={`${StylesA.add_button} ${StylesA.border_on_hover} d-flex mx-auto`}
            variant="none"
          >
            Create Breakout Session
          </Button>
        </React.Fragment>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-3">
          <p style={{ color: "#E0E2E5", marginBottom: "10px" }}>{allSessions.length > 0 ? "All sessions got assigned" : "No breakout sessions created yet!"}</p>
          <Button
            onClick={() => {
              props.setCreateBreakoutSessionOpened(true)
              setShowModal(false)
              props.setSelectedTopicForBreakout(props.datum.id)
            }}
            className={`${StylesA.add_button} ${StylesA.border_on_hover} d-flex mx-auto`}
            variant="none"
          >
            Create Breakout Session
          </Button>
        </Col>
      )
    }
  }
  function showSelectedSessions() {
    if (props.datum.breakout_sessions.length > 0) {
      return props.datum.breakout_sessions.map((session, index) => {
        return (
          <div
            key={"session_" + session.id.toString()}
            className={`mt-2 d-flex align-items-center w-100`}
            style={{ backgroundColor: "#42506c", borderRadius: "8px", border: "1px dashed #B5B179" }}
          >
            <div className={`w-100 p-1 d-flex pointer align-items-center`}>
              <img
                src={YellowBreakoutSessionsIcon}
                alt={`Cancel`}
                className={`mx-2 mb-0 pointer`}
                style={{ objectFit: `contain`, height: `21px`, marginTop: "3px" }}
              />
              <p className={`mb-0`} style={{ color: "#A0A8B4", fontSize: "14px" }}>
                {session.name}
              </p>
            </div>
            <Dropdown className="breakout-groups pointer mr-3 ml-0 float-right">
              <Dropdown.Toggle className="border-none p-0 shadow-none" style={{ backgroundColor: "transparent" }} id="dropdown-basic">
                <div>
                  <FontAwesomeIcon icon={faEllipsisV} style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E5` }} />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className={`breakout-groups ${StylesB.min_fit_width}`} style={{ width: "112px" }}>
                {props?.isPermitted && (
                  <Dropdown.Item
                    onClick={() => {
                      props.setCreateBreakoutSessionOpened(true)
                      props.setSelectedBreakoutForEdit(session)
                      props.setSelectedModuleForBreakout && props.setSelectedModuleForBreakout(props.module_id)
                    }}
                    className={`${StylesB.dropitem} text-white fs-14px px-2 py-1 d-inline-flex align-items-center`}
                    style={{ minWidth: `max-content`, borderRadius: "0px" }}
                  >
                    <FontAwesomeIcon icon={faPen} className={`mb-0 mr-2`} /> &nbsp;Edit
                  </Dropdown.Item>
                )}
                {!props?.isPermitted && (
                  <Dropdown.Item
                    onClick={() => {
                      props.setCreateBreakoutSessionOpened(true)
                      props.setSelectedBreakoutForEdit(session)
                      props.setSelectedModuleForBreakout && props.setSelectedModuleForBreakout(props.module_id)
                    }}
                    className={`${StylesB.dropitem} text-white fs-14px px-2 py-1 d-inline-flex align-items-center`}
                    style={{ minWidth: `max-content`, borderRadius: "0px" }}
                  >
                    <img className={`mb-0 mr-1 w-1rem `} alt="View" src={eye} />
                    &nbsp;View
                  </Dropdown.Item>
                )}
                {props?.isPermitted && (
                  <Dropdown.Item
                    onClick={() => {
                      setBreakoutDeleteWarningModal(true)
                      setSelectedSessionForDelete(session)
                    }}
                    className={`${StylesB.dropitem} text-white fs-14px px-2 py-1 d-inline-flex align-items-center`}
                    style={{ minWidth: `max-content`, borderRadius: "0px" }}
                  >
                    {" "}
                    <img src={DeleteIcon} alt={`delete icon`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, height: `13px` }} />
                    &nbsp;Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )
      })
    } else return null
  }

  function resetState() {
    setShowModal(false)
    setSessionsFetched(false)
  }
  function showBreaoutSessionSelectionView() {
    if (sessionsFetched) {
      return showBreakoutSessions()
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function handleDeleteModalClose() {
    setBreakoutDeleteWarningModal(false)
    setSelectedSessionForDelete("")
  }
  return (
    <div className={`mt-2`}>
      <div className={`${props.topic_status === "Done" ? "pointer-events-disabled" : ""}   `}>{showSelectedSessions()}</div>
      {props?.isPermitted && (
        <div
          onClick={() => {
            setShowModal(true)
          }}
          style={{ backgroundColor: "#42506c", borderRadius: "8px" }}
          className={`w-100 p-1 mt-2 d-flex  align-items-center border_on_hover pointer  ${props.topic_status === "Done" ? "pointer-events-disabled" : ""}`}
        >
          <img src={BreakoutSessionsIcon} alt={`Cancel`} className={`mb-0 mx-2 pointer`} style={{ objectFit: `contain`, height: `21px`, marginTop: "3px" }} />
          <p className={`mb-0`} style={{ color: "#A0A8B4", fontSize: "14px" }}>
            Add breakout sessions
          </p>
        </div>
      )}
      <Modal
        onShow={() => fetchBreakoutSessions()}
        show={showModal}
        onHide={() => resetState()}
        centered
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal`}
      >
        <div className={`${StylesA.delete_modal_header}`}>
          <img
            onClick={() => resetState()}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{ objectFit: `contain`, height: `13px`, position: "absolute", top: "7px", right: "5px" }}
          />
          <p>Breakout Sessions</p>
        </div>
        <Modal.Body className="" style={{ backgroundColor: "#121b2b", borderRadius: "0px 0px 8px 8px", padding: "8px" }}>
          {showBreaoutSessionSelectionView()}
        </Modal.Body>
      </Modal>
      <Modal
        show={breakoutDeleteWarningModal}
        onHide={handleDeleteModalClose}
        centered
        className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
      >
        <div className={`${StylesB.delete_modal_header}`}>
          <img
            onClick={handleDeleteModalClose}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{ objectFit: `contain`, height: `13px`, position: "absolute", top: "7px", right: "5px" }}
          />
          <img src={TrashCan} alt={`delete modal delete icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `37px` }} />
          <div>
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>Delete</p>
          </div>
        </div>
        <Modal.Body className="text-center" style={{ backgroundColor: "#121b2b", borderRadius: "0px 0px 8px 8px" }}>
          <p style={{ color: "#E0E2E5", fontSize: "15px", marginBottom: "10px" }}> Do you want to delete the breakout session or remove it from this topic? </p>
          <div className="d-flex justify-content-center">
            <Button
              className={`${StylesB.add_button_large} border_on_hover mr-3`}
              variant="secondary"
              onClick={() => {
                deleteBreakoutSession()
                handleDeleteModalClose()
              }}
              style={{ width: "75px", border: "1px solid transparent" }}
            >
              Delete
            </Button>
            <Button
              className={`${StylesB.add_button_large} border_on_hover`}
              variant="secondary"
              onClick={() => {
                toggleItem(selectedSessionForDelete)
                handleDeleteModalClose()
              }}
              style={{ width: "75px", border: "1px solid transparent" }}
            >
              Remove
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default BreakoutSessionSection
