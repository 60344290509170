import React, { useEffect, useState } from "react"

import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpload } from "@fortawesome/free-solid-svg-icons"

import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"

import Styles from "../../modularscss/upload.module.scss"

const CropImage = (props) => {
  const defaultSrc =
    props.type === "company"
      ? `${process.env.GATSBY_REACT_APP_IMAGE_API}buckets/20210225092043/company.png`
      : `${process.env.GATSBY_REACT_APP_IMAGE_API}buckets/20210225092043/course.png`

  const [image, setImage] = useState(props.avatar && props.avatar !== null ? props.avatar : defaultSrc)
  const [file, setFile] = useState(props.fileName && props.fileName !== null ? props.fileName : null)
  const [cropData, setCropData] = useState("#")
  const [cropper, setCropper] = useState()

  function convertImgToBase64URL(url, callback, outputFormat) {
    var img = new Image()
    img.crossOrigin = "Anonymous"
    img.onload = function () {
      var canvas = document.createElement("CANVAS"),
        ctx = canvas.getContext("2d"),
        dataURL
      canvas.height = img.height
      canvas.width = img.width
      ctx.drawImage(img, 0, 0)
      dataURL = canvas.toDataURL(outputFormat)
      callback(dataURL)
      canvas = null
    }
    img.src = url
  }

  useEffect(() => {
    if (props.avatar && props.avatar !== null && props.show) {
      convertImgToBase64URL(props.avatar, function (base64Img) {
        setImage(base64Img)
      })
    } else {
      convertImgToBase64URL(defaultSrc, function (base64Img) {
        setImage(base64Img)
      })
    }
  }, [props.avatar, props.show])

  const onChange = (e) => {
    e.preventDefault()
    setFile(e.target.files[0].name)
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(files[0])
  }
  const getCropData = () => {
    if (typeof cropper !== "undefined" && cropper.getCroppedCanvas()) {
      setCropData(cropper?.getCroppedCanvas()?.toDataURL())
      props.getCropDataProps(cropper?.getCroppedCanvas()?.toDataURL(), file)
    }
  }
  const handleClose = () => {
    props.onHide()
    setImage(null)
  }

  return (
    <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className={`${Styles.content} py-0 border-0`} closeButton></Modal.Header>
      <Modal.Body className={`${Styles.content}`}>
        <div>
          <div className={`text-center float-left w-100`}>
            <FontAwesomeIcon icon={faUpload} className="color-A9ADBA mb-2 w-1rem h-1rem" />
            <p className={`fs-14px color-A9ADBA`}>
              Upload Images <br /> {`Files Supported: ${props.type === "signature" ? `Png` : `Png/ Jpeg/ Jpg/ Webp`}`}{" "}
            </p>
            <input className={`d-none`} id="file" type="file" onChange={onChange} accept={props.type === "signature" ? "image/png" : "image/*"} />
            <label className={`button btn btn-primary shadow-none border-0 px-3 mb-3 bg-485B9E`} htmlFor="file">
              Browse{" "}
            </label>
            <div className={`d-flex`}>
              <Cropper
                className={`float-left w-50 mt-2`}
                style={{ height: 300 }}
                aspectRatio={props.type !== "signature" ? 1 : NaN}
                preview=".img-preview"
                src={image}
                viewMode={1}
                guides={true}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance)
                }}
              />
              <div className={`float-left w-50 text-center h-18rem`}>
                <p className="color-A9ADBA">Preview</p>
                <div className="w-100 d-flex p-10px border-box">
                  <div className="img-preview mx-auto h-14rem float-left w-70" />
                </div>
                {/*<div style={{ width: "50%", float: "right", height: "300px", display: `inline-block`, padding: `10px`, boxSizing: `border-box` }} >
                  <h1>
                    <span>Crop</span>
                    <Button variant="success" style={{ float: "right" }} onClick={getCropData}>
                      Crop Image
                    </Button>
                  </h1>
                  <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className={`${Styles.content} pt-0 border-0`}>
        <Button
          variant="success"
          className={`text-white float-right`}
          onClick={(e) => {
            e.preventDefault()
            getCropData()
            props.onHide()
            setImage(null)
          }}
        >
          Save
        </Button>
        <Button className={`text-white`} onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CropImage
