import React, { useEffect, useState, useCallback, useContext } from "react"
import _ from "lodash"
import { navigate, Link } from "gatsby"
import axios from "axios"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
import Card from "react-bootstrap/Card"
import Spinner from "react-bootstrap/Spinner"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import Dropdown from "react-bootstrap/Dropdown"
import InputGroup from "react-bootstrap/InputGroup"
import { LazyLoadImage } from "react-lazy-load-image-component"
import SEO from "../../components/seo"
import AvatarCircle from "../custom/AvatarCircle"
import authClient from "../../services/auth"
import utcDateTime from "../../services/utcdatetime"
import { getRequest } from "../../services/rest_service"
import { isBrowser } from "../../services/developer_mode"
import CustomDateTimePicker from "../custom/CustomDateTimePicker"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEllipsisV,
  faChevronCircleUp,
  faChevronCircleDown,
  faExclamationTriangle,
  faSearch,
  faSpinner,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons"
import DragHandleIcon from "../../images/svgs/drag-handle-white.svg"
import CancelWhite from "../../images/svgs/close.svg"
import video from "../../images/svgs/video.svg"
import link from "../../images/svgs/link.svg"
import turientimage from "../../images/svgs/turientimage.svg"
import blog from "../../images/svgs/blog.svg"
import quiz from "../../images/svgs/quiz.svg"
import CalendarAlt from "../../images/svgs/calendaralt.svg"
import TemplateImg from "../../images/svgs/template.svg"
import Close from "../../images/svgs/close.svg"
import CircleUp from "../../images/svgs/arrow-dropup-white-circle.svg"
import CircleDown from "../../images/svgs/arrow-dropdown-white-circle.svg"
import StylesM from "../upload-resource.module.scss"
import Styles from "./create.module.scss"
import StylesB from "../courses/course.module.scss"
import Notification from "../custom/Notification"
import VideoCallLinkInput from "./_video-link-input"
import MuralAccountsList from "../mural/muralAccountsList"
import AppIntegrationModal from "../app-integration"
import { HasCreatePermission } from "../../utils/PermissionDataAccess"
import { CLASSES } from "../../constants/permissionsConstant"
import Staff from "./staff"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import Image from "../custom/Image"

const CreateTraining = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [globalState, globalStateDispatch] = useContext(GlobalContext)

  const [training, setTraining] = useState({
    company: location.company_id,
    course: location.course_id,
    sequence: 1,
    price: 0,
    training_zoom_link: "",
    trainers: [],
    training_staff: [],
    mural_account: {},
  })
  const [allTemplates, setAllTemplates] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [trainingCreated, setTrainingCreated] = useState(false)
  const [selectCourseCollapdsed, setSelectCourseCollapsed] = useState(false)
  const [selectTrainerCollapdsed, setSelectTrainerCollapsed] = useState(true)
  const [selectStaffCollapsed, setSelectStaffCollapsed] = useState(true)
  const [clickedPrice, setClickedPrice] = useState(false)
  const [clickedTrainerDetailsNext, setclickedTrainerDetailsNext] = useState(false)
  const [clickedCourseCollapse, setClickedCourseCollapse] = useState(false)
  const [courses, setCourses] = useState([])
  const [coursesNext, setCoursesNext] = useState(null)
  const [coursesNextFetched, setCoursesNextFetched] = useState(false)
  const [currentCourse, setCurrentCourse] = useState(null)
  const [courseLoader, setCourseLoader] = useState(false)
  const [courseType, setCourseType] = useState("0")
  const [modules, setModules] = useState({})
  const [modulesNext, setModulesNext] = useState(null)
  const [modulesLoader, setModulesLoader] = useState(false)
  const [course_template, setCourseTemplate] = useState("")
  const [showOnlyWorkshop, setShowOnlyWorkshop] = useState(false)
  const [selectedStage, setSelectedStage] = useState("")
  const [selectedStageName, setSelectedStageName] = useState("")
  // for slected module
  const [currentModuleFetched, setCurrentModuleFetched] = useState(false)
  // selceted module id
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedModuleToggle, setSelectedModuleToggle] = useState({})
  const [selectedModuleToggleChange, setSelectedModuleToggleChange] = useState(false)
  // each module full data in modules array
  const [modulesData, setModulesData] = useState({})
  // for selected module topics basic name list
  const [currentModuleTopicsFetched, setCurrentModuleTopicsFetched] = useState(false)
  // selceted topic id
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedTopicToggle, setSelectedTopicToggle] = useState({})
  const [selectedTopicToggleChange, setSelectedTopicToggleChange] = useState(false)
  // for topics and module resources and respective loader
  const [modulesResources, setModulesResources] = useState({})
  const [modulesResourcesNext, setModulesResourcesNext] = useState({})
  const [modulesResourcesFetched, setModulesResourcesFetched] = useState(false)
  const [topicsResources, setTopicsResources] = useState({})
  const [topicsResourcesNext, setTopicsResourcesNext] = useState({})
  const [topicsResourcesNextLoader, setTopicsResourcesNextLoader] = useState({})
  const [topicsResourcesFetched, setTopicsResourcesFetched] = useState(false)
  const [topicsResourcesFetchedex, setTopicsResourcesFetchedex] = useState({})

  const [topicsRefetched, setTopicsRefetched] = useState(false)
  const [bulkTopicsRefetched, setBulkTopicsRefetched] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const [show_module_modal, setShowModuleModal] = useState(false)
  const [selected_course_name, setSelectedCourseName] = useState("")
  const [data_fetched, setDataFetched] = useState(false)
  const [openedCourseSelction, setOpenedCourseSelection] = useState(false)
  const [trainers, setTrainers] = useState([])
  const [allTrainers, setAllTrainers] = useState()
  const [selectedTrainers, setSelectedTrainers] = useState(trainers.length === 1 ? trainers : [])
  const [trainingName, setTrainingName] = useState("")
  const [courseDays, setCourseDays] = useState(0)
  const [topicFetched, setTopicFetched] = useState(false)
  const [topicsData, setTopicsData] = useState({})
  const [enteredValidTime, setEnteredValidTime] = useState(true)
  const [viewDetailsData, setViewDetailData] = useState(null)
  const [showDisabledError, setShowDisabledError] = useState(false)

  const [selectedCourseDetail, setSelectedCourseDetail] = useState({})
  const [timeErrorMsg, setTimeErrorMsg] = useState("Please select the valid time. Start time must be less than end time ")
  const [courseLength, setCourseLength] = useState(null)
  const [teamsData, setTeamsData] = useState([])
  const [teamsErrMsg, setTeamsErrMsg] = useState(false)
  // for Date Time Picker
  const [training_start_date, setTrainingStartDate] = useState(null)
  const [training_end_date, setTrainingEndDate] = useState(null)
  const [showMuralAccounts, setShowMuralAccounts] = useState(false)

  const isMuralEnabled = globalState.appData.data?.company?.mural_enabled

  // duplicate Class state variable
  const [duplicateClass, setDuplicateClass] = useState(false)

  const delayedSearchQuery = useCallback(
    _.debounce((value) => fetchCourses(value), 1000),
    []
  )

  const [notificationData, setNotificationData] = useState(null)

  let x = new Date()
  useEffect(() => {
    fetchTrainers()
    getSchoolTeams()
  }, [])

  useEffect(() => {
    fetchCourses()
    if (training.course != "new") {
      getSelectedCourse()
    }
  }, [courseType])

  useEffect(() => {
    if (selectedModule != null) {
      fetchCurrentModule()
    }
  }, [selectedModule])
  useEffect(() => {
    if (selectedTopic != null) {
      fetchTopicResources()
      fetchSelectedTopic()
    }
  }, [selectedTopic])
  useEffect(() => {
    fetchTemplateModules()
  }, [selectedStage])
  useEffect(() => {
    let x = []
    trainers.map((item, indx) => {
      if (item.selected === true) {
        x.push({ id: item.id, name: item.name, profile_pic: item.profile_pic })
      }
    })
    setSelectedTrainers([...x])
  }, [trainers])

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("message", handleNewWinMessages, false)
      return () => {
        window.removeEventListener("message", handleNewWinMessages)
      }
    }
  }, [window])
  async function fetchTrainers() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/trainers/list/all/?type=all&company=${location.company_id}`, { headers: login_credentials })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let arr = []
          setAllTrainers(res.data)
          res.data.map((item, indx) => {
            arr[indx] = item
            arr[indx].selected = true ? parseInt(item?.user_id) === parseInt(localStorage.getItem("student_id")) : false
          })
          setTrainers([...arr])
          handleTrainers()
        }
      })
  }

  const getSchoolTeams = async () => {
    let res = await getRequest(`/role-management/school-teams/?company=${localStorage.getItem("company_id")}`)
    if (res.success) {
      setTeamsData({ data: res.data.results, has_next: res.data.next, count: res.data.count })
    } else {
      if (res?.status === 403) {
        setTeamsErrMsg(true)
      }
    }
  }

  async function fetchCourses(value) {
    setCourseLoader(false)
    let search = ""
    if (value) {
      search = `&search=${value}`
    }
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-default-course-templates-list/?course_type=${courseType}${search}&company=${location.company_id}`, {
        headers: login_credentials,
      })
      .then((res) => {
        setCourses(res.data.results)
        setCoursesNext(res.data.next)
        setCourseLoader(true)
      })
  }

  const getSelectedCourse = async () => {
    const response = await getRequest(`/basic-default-course-templates-list/?course_type=${courseType}&id=${training.course}`)
    if (response.success) {
      selectCourse(null, response.data.results[0])
      setTraining({ ...training, course: response.data.results[0].course_detail.id })
      setShowMuralAccounts(true)
    }
  }

  async function fetchNextCourses(next_url) {
    setCoursesNextFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_course_data = courses
      if (res.data.results.length > 0) {
        for (var crs = 0; crs <= res.data.results.length - 1; crs++) {
          next_course_data.push(res.data.results[crs])
        }
        setCourses(next_course_data)
      }
      setCoursesNext(res.data.next)
      setCoursesNextFetched(true)
    })
  }
  function fetchorCreateTemplate(stage_id) {
    setShowModuleModal(true)
    setShowOnlyWorkshop(true)
    setSelectedStage(stage_id)
    setSelectedStageName("Workshop")
  }
  async function fetchTemplateModules() {
    if (selectedStage && modules[selectedStage] === undefined) {
      setModulesLoader(true)
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-modules-list/?stage=${selectedStage}&paginate_by=15`, { headers: login_credentials })
        .then((res) => {
          let current_modules = { ...modules }
          current_modules[selectedStage] = {}
          current_modules[selectedStage].modules = res.data.results
          current_modules[selectedStage].loader = false
          current_modules[selectedStage].next_url = res.data.next
          if (res.data.next) {
          } else {
            current_modules[selectedStage].next_loader = false
          }
          setModules(current_modules)
          setModulesLoader(false)
        })
    }
  }
  async function fetchNextTemplateModules(next_url) {
    setModulesLoader(true)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_template_modules = modules
      if (res.data.results.length > 0) {
        for (var md = 0; md <= res.data.results.length - 1; md++) {
          next_template_modules.push(res.data.results[md])
        }
        setModules(next_template_modules)
      }
      setModulesNext(res.data.next)
      setModulesLoader(false)
    })
  }
  async function fetchSelectedTopic() {
    if (topicsData && topicsData["topic_" + selectedTopic] === undefined) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/topic-basic-detail/${selectedTopic}/`, { headers: login_credentials })
        .then((res) => {
          topicsData["topic_" + selectedTopic] = res.data
          setTopicFetched(true)
        })
        .catch((error) => {
          setTopicFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      setTopicFetched(true)
    }
  }
  async function fetchCurrentTopics(refetch = false, module_id = null, next_module_id = null) {
    let selected_module
    if (module_id === null) {
      selected_module = selectedModule
    } else {
      selected_module = module_id
    }
    if (refetch) {
      setCurrentModuleTopicsFetched(false)
    }
    if ((modulesData && modulesData[`module_` + selected_module] && modulesData[`module_` + selected_module].topics === undefined) || refetch) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-topics-list/?module=${selected_module}&paginate_by=15&is_plan=true`, {
          headers: login_credentials,
        })
        .then((res) => {
          if (modulesData[`module_` + selected_module] !== undefined) {
            modulesData[`module_` + selected_module].topics = res.data.results
            modulesData[`module_` + selected_module].topics_next = res.data.next
          }
          setSelectedTopic(null)
          setCurrentModuleTopicsFetched(true)
          if (next_module_id !== null && modulesData[`module_` + selected_module]) {
            fetchCurrentTopics(true, next_module_id)
          }
        })
        .catch((error) => {
          setCurrentModuleTopicsFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      setSelectedTopic(null)
      setCurrentModuleTopicsFetched(true)
    }
  }

  async function fetchCurrentModule() {
    if (modulesData && modulesData[`module_` + selectedModule] === undefined) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/module-basic-detail/${selectedModule}/`, { headers: login_credentials })
        .then((res) => {
          modulesData[`module_` + selectedModule] = res.data
          setCurrentModuleTopicsFetched(false)
          fetchCurrentTopics()
          setCurrentModuleFetched(true)
        })
        .catch((error) => {
          setCurrentModuleFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      setCurrentModuleFetched(true)
      setCurrentModuleTopicsFetched(false)
      fetchCurrentTopics()
    }
  }
  async function fetchNextModuleResources(next_url) {
    setModulesResourcesFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_module_resources = modulesResources[`module_` + selectedModule]
      if (res.data.results.length > 0) {
        for (var mrs = 0; mrs <= res.data.results.length - 1; mrs++) {
          next_module_resources.push(res.data.results[mrs])
        }
        modulesResources[`module_` + selectedModule] = next_module_resources
      }
      modulesResourcesNext[`module_` + selectedModule] = res.data.next === null ? {} : { next: res.data.next }
      setModulesResourcesFetched(true)
    })
  }
  async function fetchTopicResources(id = null, refetch = false, second = false) {
    let selected_topic
    if (refetch) {
      selected_topic = id
    } else {
      selected_topic = selectedTopic
    }
    if ((selected_topic !== null && topicsResources[`topic_` + selected_topic] === undefined) || refetch) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/resources-names-list/?topic=${selected_topic}&paginate_by=10`, { headers: login_credentials })
        .then((res) => {
          let datum = topicsResources
          datum[`topic_` + selected_topic] = res.data.results
          topicsResourcesFetchedex[`topic_` + selected_topic] = true
          setTopicsResourcesFetched(false)
          topicsResourcesNext[`topic_` + selected_topic] = res.data.next === null ? {} : { next: res.data.next }
          setTopicsResources(datum)
          setTopicsRefetched(true)
          if (second) {
            setBulkTopicsRefetched(true)
          }
          setDataFetched(!data_fetched)
        })
    }
  }

  async function fetchNextTopicResources(next_url) {
    setTopicsResourcesFetched(false)
    let resources_seemore_loader = { ...topicsResourcesNextLoader }
    resources_seemore_loader[`topic_` + selectedTopic] = true
    setTopicsResourcesNextLoader(resources_seemore_loader)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_topic_resources = topicsResources[`topic_` + selectedTopic]
      if (res.data.results.length > 0) {
        for (var tprs = 0; tprs <= res.data.results.length - 1; tprs++) {
          next_topic_resources.push(res.data.results[tprs])
        }
        topicsResources[`topic_` + selectedTopic] = next_topic_resources
      }
      topicsResourcesNext[`topic_` + selectedTopic] = res.data.next === null ? {} : { next: res.data.next }
      setTopicsResourcesFetched(true)
      let resources_seemore_loader = { ...topicsResourcesNextLoader }
      resources_seemore_loader[`topic_` + selectedTopic] = false
      setTopicsResourcesNextLoader(resources_seemore_loader)
    })
  }

  function searchTemplates(search = "") {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-default-course-templates-list/?search=` + search, { headers: login_credentials }).then((res) => {
      setAllTemplates(res.data.results)
    })
  }

  function selectItem(e, field, data) {
    e.preventDefault()
    training[field] = data.id
    setSelectedTemplate(data.name)
  }

  function handleChange(event, course = null) {
    event.preventDefault()
    if (course !== null) {
      training["course"] = course.template_course
      training["template_id"] = course.id
    } else {
      if (event.target.name === "end_date" || event.target.name === "start_date") {
        training[event.target.name] = new Date(event.target.value)
      } else if (event.target.name === "name") {
        setTrainingName(event.target.value)
        training[event.target.name] = event.target.value
      } else {
        training[event.target.name] = event.target.value
      }
    }
  }

  function handleTrainers() {
    let orginalData = []
    selectedTrainers.map((item, indx) => {
      orginalData.push({ id: item.id })
    })
    training.trainers = orginalData
  }

  function validateCourseLenght(date1, date2) {
    let duration = selectedCourseDetail.duration
    let duration_type = selectedCourseDetail.duration_type
    let n = 0
    if (duration_type === "0") {
      n = Math.ceil(duration / 24)
    }
    let start_day = date1.getTime()
    let end_day = date2.getTime()
    let res = end_day - start_day
    let total_days = res / (1000 * 60 * 60 * 24)
    if (total_days == n - 1) {
      return true
    } else if (total_days > n - 1) {
      return "greater than"
    } else {
      return "less than"
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()
    let date1 = new Date(training["start_date"])
    let date2 = new Date(training["end_date"])

    handleTrainers()
    let validation = true
    let d3 = new Date(training["start_date"])
    let d4 = new Date(training["end_date"])
    d3.setHours(0, 0, 0, 0)
    d4.setHours(0, 0, 0, 0)
    let h1 = date1.getHours()
    let h2 = date2.getHours()
    let m1 = date1.getMinutes()
    let m2 = date2.getMinutes()
    if (h1 === h2 && m1 === m2) {
      setEnteredValidTime(false)
      validation = false
    } else if (h1 === h2 && m1 > m2) {
      setEnteredValidTime(false)
      validation = false
    } else {
      if (d3.getTime() >= d4.getTime()) {
        if (d3.getTime() == d4.getTime() && h1 != h2) {
          setEnteredValidTime(true)
          validation = true
        } else if (d3.getTime() == d4.getTime() && h1 == h2 && m1 < m2) {
          setEnteredValidTime(true)
          validation = true
        } else {
          setEnteredValidTime(false)
          validation = false
        }
      } else {
        setEnteredValidTime(true)
        validation = true
      }
    }

    let flag = validateCourseLenght(d3, d4)
    if (flag == true && validation == true) {
      setEnteredValidTime(true)
      validation = true
      setTimeErrorMsg("Please select the valid time. Start time must be less than end time ")
    } else {
      if (validation) {
        setEnteredValidTime(false)
        validation = false
        setTimeErrorMsg(`The start and end date range is ${flag} course length`)
      }
    }

    if (training["course"] === "new") {
      setClickedCourseCollapse(true)
    } else if (training["trainers"].length === 0) {
      setclickedTrainerDetailsNext(true)
    } else if (training["price"] === "" || training["price"] === null || training["price"] === undefined) {
      setNotificationData({ type: "error", title: "Please add price for the Class" })
    }
    event.preventDefault()
    var formData = new FormData()
    for (var k in training) {
      if (k === "end_date" || k === "start_date") {
        formData.append(k, training[k].toISOString())
      } else if (k === "trainers") {
        formData.append("trainers", JSON.stringify(training[k]))
      } else if (k === "training_staff") {
        formData.append("training_staff", JSON.stringify(training[k]))
      } else if (k === "mural_account") {
        if (training[k]?.id) {
          formData.append("mural_account", training[k]?.id ? training[k]?.id : "")
        } else {
          formData.append("mural_account", "")
        }
      } else {
        formData.append(k, training[k])
      }
    }
    formData.append("duplicate", true)
    formData.append("company", localStorage.getItem("company_id"))
    if (training.id !== undefined) {
    } else if (!trainingCreated && training["trainers"].length > 0 && validation === true) {
      setTrainingCreated(true)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/trainings/create/", formData, { headers: login_credentials })
        .then(async (res) => {
          navigate(`/app/companies/${localStorage.getItem("company_id")}/courses/${training.course}/trainings/edit/${res.data.id}/#publish_class`)
        })
        .catch((error) => {
          setTrainingCreated(false)
        })
    }
  }
  function toggle(e, type) {
    e.preventDefault()
  }

  function updateEndDate(date, length) {
    if (training.end_date) {
      let dummy = { ...training }
      let start = new Date(date)
      let end_time = start.getTime() + (length - 1) * 24 * 60 * 60 * 1000
      let end_date = new Date(end_time)
      dummy["end_date"] = end_date
      setTrainingEndDate(end_date)
      setTraining(dummy)
    }
  }

  function handleDateChange(date, type) {
    let dummy = { ...training }
    if ((training["end_date"] === undefined && training["start_date"] === undefined && type === "start_date") || type === "start_date") {
      let end_time = date.getTime() + (courseLength - 1) * 24 * 60 * 60 * 1000
      let end_date = new Date(end_time)

      if (training["end_date"] === undefined && training["start_date"] === undefined && viewDetailsData) {
        date.setHours(
          utcDateTime.utcDateTime(viewDetailsData.course_detail.start_time).getHours(),
          utcDateTime.utcDateTime(viewDetailsData.course_detail.start_time).getMinutes()
        )
        end_date.setHours(
          utcDateTime.utcDateTime(viewDetailsData.course_detail.end_time).getHours(),
          utcDateTime.utcDateTime(viewDetailsData.course_detail.end_time).getMinutes()
        )
      }
      if (training["end_date"]) {
        let end = new Date(training["end_date"])
        end_date.setHours(end.getHours(), end.getMinutes())
      }
      dummy["start_date"] = date
      dummy["end_date"] = end_date
      setTrainingStartDate(date)
      setTrainingEndDate(end_date)
    } else {
      dummy["end_date"] = date
      setTrainingEndDate(date)
    }
    setTraining(dummy)
  }

  function updateCourseLength(data) {
    let duration = data.duration
    let duration_type = data.duration_type
    let n = 0
    if (duration_type === "0") {
      n = Math.ceil(duration / 24)
    }
    setCourseLength(n)
  }

  const selectCourse = (e, datum) => {
    updateEndDate(training.start_date, Math.ceil(datum?.course_detail.duration / 24))
    setSelectedCourseDetail(datum?.course_detail)
    updateCourseLength(datum?.course_detail)
    setSelectCourseCollapsed(true)
    setViewDetailData(datum)
    setCurrentCourse(datum?.name)
  }

  function getSingleCourse(datum, index) {
    return (
      <Col
        key={`${datum.name.toLowerCase().split(" ").join("-")}-${index}`}
        name="course"
        id={datum.id}
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="border_on_hover d-inline-flex w-100 py-2 px-0 mb-2 border border-1 border-transparent br-9px bg-42506C box-shadow-00000029"
      >
        <div
          className="float-left align-self-center d-flex-align-center turient-header-text-color w-88"
          onClick={(e) => {
            selectCourse(e, datum)
            handleChange(e, datum)
            setShowMuralAccounts(true)
          }}
        >
          {datum.template_type && datum.template_type == 0 ? (
            <span>
              &nbsp;
              <img src={TemplateImg} className={`my-auto mr-1 pointer ml-2 object-fit-contain h-2rem`} />
            </span>
          ) : null}
          <p className="d-inline-flex mb-0 pl-2 mr-3 font-weight-bold fs-16px">{datum.name}</p>
          {datum.duration > 0 ? <p className="mb-0 fs-14px">{displayCourseDuration(datum.duration)}</p> : null}
        </div>
        <div className="float-right align-self-center d-flex w-12">
          <u
            className={`${Styles.onfocuswhite} fs-14px turient-header-text-color`}
            onClick={() => {
              setSelectedCourseName(datum.name)
              setCourseDays(datum.duration > 0 ? Math.ceil(datum.duration / 24) : 0)
              fetchorCreateTemplate(datum.workshop_stage_id)
            }}
          >
            View Details
          </u>
        </div>
      </Col>
    )
  }
  const displayCourseDuration = (total_duration) => {
    if (total_duration < 24) {
      return `${total_duration} ${total_duration === 1 ? "hour" : "hours"}`
    } else {
      let day = Math.floor(total_duration / 24)
      let hour = total_duration - day * 24
      if (day > 0) {
        let hour_in_string = ""
        if (hour > 0) {
          hour_in_string = ` ${hour} ${hour === 1 ? "hour" : "hours"}`
        }
        return `${day} ${day === 1 ? "day" : "days"}${hour_in_string}`
      } else {
        return `${hour} ${hour === 1 ? "hour" : "hours"}`
      }
    }
  }
  const showModulesModal = () => {
    return (
      <Modal
        show={show_module_modal}
        onHide={() => {
          setShowModuleModal(false)
        }}
        centered
        size="lg"
        scrollable={true}
        className={`${StylesM.classroom_delete_modal} classroom-resource-delete-modal`}
      >
        <Modal.Header className={`pb-0 border-bottom-none bg-212C42`}>
          {modules[selectedStage] && modules[selectedStage].modules && modules[selectedStage].modules.length > 0 ? (
            <div className="float-left w-100">
              <div className="float-left w-100 d-flex align-items-baseline justify-content-between">
                <p className="mb-1 font-weight-bold turient-header-text-color fs-16px">{selected_course_name}</p>
                <img
                  onClick={(e) => {
                    e.preventDefault()
                    setShowModuleModal(false)
                  }}
                  src={CancelWhite}
                  alt={`Cancel`}
                  className={`my-auto mr-1 pointer object-fit-contain h-15px`}
                />
              </div>
            </div>
          ) : null}
        </Modal.Header>
        <Modal.Body className="align-items-center bg-212C42">
          <Col lg="12" md="12" sm="12" xs="12" className="px-0 turient-header-text-color">
            {showModulesButtons()}
          </Col>
        </Modal.Body>
      </Modal>
    )
  }

  function showModulesButtons() {
    let stage_data = modules[selectedStage]
    if (stage_data && stage_data.modules && stage_data.modules.length > 0) {
      return (
        <div id="scrollable-div" className={`${showOnlyWorkshop ? StylesB.border_radius_8 : ""} w-100`}>
          {stage_data.modules.map((datum, index) => (
            <div key={`${datum.name.toLowerCase().split(" ").join("-")}-${index}`} className={`border_on_hover mb-3 ${StylesB.border_radius_8}`}>
              {showDroppableData(datum, "module")}
            </div>
          ))}
        </div>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center pb-2">
          {modulesLoader && stage_data === undefined ? (
            <React.Fragment>
              <div className="d-inline-flex mr-1">
                <svg className="animate-spin my-auto ml-2 h-16px w-16px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <p className="pt-0 mb-0 ml-2 turient-header-text-color fs-16px">loading...</p>
              </div>
            </React.Fragment>
          ) : (
            `No Sessions Found`
          )}
        </Col>
      )
    }
  }
  function showModuleName(datum) {
    if (courses.course_type !== "1" && selectedStageName === "Workshop") {
      let start_date = datum.start_date ? utcDateTime.utcDateTime(datum.start_date, "time") : "00:00"
      let end_date = datum.end_date ? utcDateTime.utcDateTime(datum.end_date, "time") : "00:00"
      return `${datum.name} (${start_date} -  ${end_date}, Day ${datum.course_day})`
    } else {
      return `${datum.name}`
    }
  }
  function showDroppableData(datum, type, module_id = null) {
    if (type === "module") {
      return (
        <React.Fragment>
          <div
            variant="none"
            id={"modules_" + datum.id}
            className={`${Styles.font_16} ${selectedModuleToggle[datum.id]} px-2 shadow-none d-flex color-F2F4FF justify-content-between ${
              datum.module_type === "5" ? "bg-2D384C br-8px" : "bg-42506C br-8800px"
            }`}
          >
            <Col xs="10" sm="10" md="10" lg="10" className="d-flex pl-0">
              <img src={DragHandleIcon} alt={`drag-handle`} className={`my-auto mr-1 pointer object-fit-contain h-13px w-16px`} />
              {datum.module_type === "5" ? (
                <input
                  autoComplete="off"
                  type="text"
                  name="name"
                  id={datum.id + `_module_input_name`}
                  defaultValue={datum.name}
                  autoFocus
                  placeholder={"Enter Name"}
                  className={`${StylesB.session_input_field} form-control px-2 font-weight-bold ${Styles.font_16} d-inline-flex w-100`}
                  required
                  disabled
                />
              ) : (
                <p
                  className={`${StylesB.activity_input_field} ${Styles.module} d-inline-flex w-100 align-items-center font-weight-bold form-control border-0 px-2 my-auto ${Styles.font_16}`}
                >
                  {showModuleName(datum)}
                </p>
              )}
              {datum.module_type === "5" ? <SessionTimingField datum={datum} course={courses} selectedStageName={selectedStageName} /> : ""}
            </Col>
            <div className="d-flex">
              {datum.module_type !== "5" ? (
                <Button
                  variant="transparent"
                  id={`${selectedModuleToggle[datum.id] ? `disable` : ``}click_module${datum.id}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setSelectedModule(datum.id)
                    toggleModule(e, datum.id)
                  }}
                  className={`p-0 m-0 border-0 shadow-none text-white`}
                >
                  <FontAwesomeIcon
                    className={`mr-2 pointer h-16px w-16px ${Styles.btnIcon}`}
                    icon={selectedModuleToggle[datum.id] ? faChevronCircleUp : faChevronCircleDown}
                  />
                </Button>
              ) : null}
            </div>
          </div>
          {selectedModuleToggle[datum.id] ? (
            <Col xs="12" sm="12" md="12" className="p-0 bg-303C54 br-0088px">
              <div className="p-12px border-bottom-404A5D">
                <DescriptionInputField datum={datum} course={courses} selectedStageName={selectedStageName} />
                {selectedStageName === "Workshop" && courses.course_type !== "1" ? (
                  <div className="d-flex py-1 mt-12px active-session-bg br-8px">
                    <SessionTimingField datum={datum} course={courses} selectedStageName={selectedStageName} />
                    <div className={`d-flex align-items-center ${Styles.courseDay}`}>{showCourseDays(datum)}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {showCurrentModuleData(datum.id)}
            </Col>
          ) : (
            <React.Fragment>
              {datum.module_type !== "5" ? (
                <Col xs="12" sm="12" md="12" className="p-9px bg-303C54 br-0088px">
                  <p
                    className="pointer mb-0 fs-13px"
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setSelectedModule(datum.id)
                      toggleModule(e, datum.id)
                    }}
                  >
                    <u>
                      {datum.topic_count} {datum.topic_count > 1 ? "Topics" : "Topic"} added
                    </u>
                  </p>
                </Col>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )
    } else if (type === "topic") {
      return (
        <React.Fragment>
          <div
            variant="none"
            id={"topics_" + datum.id}
            className={`${Styles.font_16} px-2 shadow-none d-flex justify-content-between color-F2F4FF bg-42506C ${
              selectedTopicToggle[datum.id] ? "br-8800px" : "br-8px"
            }`}
          >
            <Col xs="11" sm="11" md="11" lg="11" className="d-flex pl-0 align-items-center">
              <img src={DragHandleIcon} alt={`topic-drag-handle`} className={`my-auto mr-1 pointer object-fit-contain h-13px w-16px`} />
              <p
                className={`${StylesB.activity_input_field} cursor-text d-inline-flex w-100 align-items-center font-weight-bold form-control border-0 px-2 my-auto ${Styles.font_16}`}
              >
                {showTopicName(datum)}
              </p>
            </Col>
            <div className="d-flex">
              <FontAwesomeIcon
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setSelectedTopic(datum.id)
                  toggleTopic(e, datum.id)
                }}
                className={`mr-2 pointer w-16px h-16px ${Styles.btnIcon}`}
                icon={selectedTopicToggle[datum.id] ? faChevronCircleUp : faChevronCircleDown}
              />
            </div>
          </div>
          {selectedTopicToggle[datum.id] ? showTopicsData(datum, false, module_id) : ``}
        </React.Fragment>
      )
    }
  }
  function showTopicName(datum) {
    if (courses.course_type !== "1" && selectedStageName === "Workshop") {
      return `${datum.name} (${datum.duration} Mins)`
    } else {
      return `${datum.name}`
    }
  }
  function showCourseDays(datum) {
    if (courseDays > 0) {
      let days_divs = []
      for (var i = 1; i <= courseDays; i++) {
        let current_day = i
        days_divs.push(
          <p
            key={"day_" + i.toString() + "_" + datum.id}
            className={`${current_day === datum.course_day ? StylesB.module_selected_day : ""} border_on_hover`}
            style={{
              boxShadow: "0px 3px 6px #00000029",
              opacity: 0.5,
              marginBottom: "0px",
              marginRight: "6px",
              marginLeft: "6px",
              fontSize: "13px",
              padding: "5px 10px",
              minWidth: "63px",
              textAlign: "center",
              borderRadius: "3px",
              backgroundColor: "#586886",
              height: "30px",
              color: "#D3D5E2",
            }}
          >
            <span>{`Day ${i}`}</span>
          </p>
        )
      }
      return days_divs
    } else {
      return null
    }
  }
  const SessionTimingField = (props) => {
    let props_start_date = props.datum.start_date ? new Date(props.datum.start_date) : ""
    let props_end_date = props.datum.end_date ? new Date(props.datum.end_date) : ""
    let start_date = props_start_date ? utcDateTime.utcDateTime(props_start_date, "time") : "00:00"
    let end_date = props_end_date ? utcDateTime.utcDateTime(props_end_date, "time") : "00:00"
    return (
      <div
        className={`px-0 ${props.datum.module_type === "5" ? `ml-auto font-weight-bold` : ``}`}
        style={{ position: "relative", minWidth: "fit-content", borderLeft: props.datum.module_type === "5" ? `1px solid #ffffff40` : `0px solid #ffffff40` }}
      >
        <div
          style={{ display: "flex", alignItems: "center", borderRadius: "8px", cursor: "text" }}
          className={`${StylesB.session_input_field} ${StylesB.session_number_field} ${props.datum.module_type !== "5" ? "border_on_hover" : ""} px-2 ${
            Styles.font_16
          }`}
        >
          {`${props.datum.module_type === "5" ? "" : "Session "}Timings: ${start_date} - ${end_date}`}
        </div>
      </div>
    )
  }
  const DescriptionInputField = (props) => {
    const [inputValue, setInputValue] = useState(props.datum.description)
    const [loader, setLoader] = useState(false)
    const [clickedDescriptionField, setClickedDescriptionField] = useState(false)

    useEffect(() => {
      if (props.moduleEdited) {
        setLoader(false)
      }
    }, [props.moduleEdited])

    if (clickedDescriptionField) {
      return (
        <div
          className="px-0 border_on_hover pl-0 br-9px"
          style={{ backgroundColor: "#42506C", boxShadow: "0px 3px 6px #00000029", border: "1px solid #303C54", position: "relative" }}
        >
          <InputGroup className="py-0 d-block border border-1 border-transparent br-9px">
            <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#42506C", borderRadius: "9px 9px 0px 0px" }}>
              <InputGroup.Text
                className="border-0 d-flex justify-content-center pb-0 turient-header-text-color fs-16px"
                style={{ backgroundColor: "#42506C", borderRadius: "9px 0px 0px 9px", opacity: "41%" }}
                id="basic-addon1"
                disabled
              >
                {props.selectedStageName === "Workshop" && props.course.course_type !== "1" ? "Session Objective" : "Module Description"}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              className={`border-0 d-flex pt-0 w-100 justify-content-center shadow-none  text-white`}
              style={{ backgroundColor: "#42506C", borderRadius: "0px", fontSize: "16px" }}
              as="textarea"
              type="text"
              name="description"
              value={inputValue}
              id="description"
              placeholder=""
              disabled
            />
            {loader ? (
              <svg
                className="animate-spin my-auto"
                style={{ height: `16px`, width: `16px`, position: "absolute", bottom: "5px", right: "5px", zIndex: 3 }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : null}
          </InputGroup>
        </div>
      )
    } else {
      return (
        <p
          className={`pl-2 mb-0 pointer fs-16px bold-500`}
          onClick={() => {
            setClickedDescriptionField(true)
          }}
        >
          <u>{props.selectedStageName === "Workshop" && props.course.course_type !== "1" ? "Add Session Objective" : "Add Module Description"}</u>
        </p>
      )
    }
  }
  function toggleModule(e = null, datum_id) {
    if (e) {
      e.preventDefault()
    }
    selectedModuleToggle[datum_id] = !selectedModuleToggle[datum_id]
    setSelectedModuleToggleChange(!selectedModuleToggleChange)
  }
  function toggleTopic(e, datum_id) {
    if (e) {
      e.preventDefault()
    }
    selectedTopicToggle[datum_id] = !selectedTopicToggle[datum_id]
    setSelectedTopicToggleChange(!selectedTopicToggleChange)
  }
  function showCurrentModuleData(module_id) {
    if (modulesData && modulesData[`module_` + module_id] !== undefined) {
      return (
        <Card className={`${StylesB.topic_card} w-100`}>
          <Card.Body className="p-12px">{showTopicsButtons(module_id)}</Card.Body>
        </Card>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  function showTopicsButtons(module_id) {
    if (modulesData && modulesData[`module_` + module_id] && modulesData[`module_` + module_id].topics) {
      if (modulesData[`module_` + module_id].topics.length > 0) {
        return (
          <div className={`bg-transparent`}>
            {modulesData[`module_` + module_id].topics.map((datum, index) => {
              return (
                <div
                  key={`${datum.name.toLowerCase().split(" ").join("-")}-${index}`}
                  className={`border_on_hover bg-transparent float-none mb-2 ${StylesB.border_radius_8}`}
                >
                  {showDroppableData(datum, "topic", module_id)}
                </div>
              )
            })}
          </div>
        )
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-4 justify-content-center turient-header-text-color">
            <p style={{ fontSize: "13px", margin: "0px", marginBottom: "5px" }}>
              No topics created for this {selectedStageName === "Workshop" && courses.course_type !== "1" ? "session" : "module"}!
            </p>
          </Col>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  function showTopicsData(datum, visible_as_resource = false, module_id = null) {
    let topic_id = datum.id
    if (topic_id !== null) {
      if (visible_as_resource) {
        return showResourcesButton(`topic`, topic_id, visible_as_resource, module_id)
      } else if (topic_id !== null && topicFetched && topicsData["topic_" + topic_id]) {
        return (
          <div style={{ backgroundColor: "#36435D", borderRadius: "0px 0px 8px 8px" }} className="pb-12px">
            {selectedStageName === "Workshop" && courses.course_type !== "1" ? (
              <div className="d-flex pb-12px">
                <DurationInputField datum={datum} course={courses} selectedStageName={selectedStageName} />
                <WeightageField datum={datum} course={courses} selectedStageName={selectedStageName} />
              </div>
            ) : (
              ""
            )}
            <div className={`px-0`} style={{ backgroundColor: "#42506C", borderRadius: "8px", boxShadow: "0px 3px 6px #00000029" }}>
              <div style={{ borderRadius: "8px 8px 0px 0px", borderBottom: `1px dashed #838b9e`, padding: "8px" }}>
                <p className="turient-header-text-color" style={{ fontSize: "14px", marginBottom: "0px", lineHeight: 1, opacity: 0.6 }}>
                  Resources
                </p>
              </div>
              {showResourcesButton(`topic`, topic_id, visible_as_resource, module_id)}
            </div>
          </div>
        )
      } else {
        return (
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className="text-center py-2"
            style={{ backgroundColor: "rgb(54, 67, 93)", borderRadius: "0 0 8px 8px", boxShadow: "0px 3px 6px #00000029" }}
          >
            <Spinner animation="border" variant="secondary" size="sm" />
          </Col>
        )
      }
    } else {
      return null
    }
  }
  const WeightageField = (props) => {
    return (
      <div className="px-0" style={{ position: "relative", boxShadow: "0px 3px 6px #00000029", borderRadius: "8px", background: "#42506C" }}>
        <div
          style={{ display: "flex", alignItems: "center", borderRadius: "8px", cursor: "text", pointerEvents: "none" }}
          className={`${StylesB.session_input_field} ${StylesB.session_number_field} border_on_hover px-2 ${Styles.font_16}`}
        >
          {`Weightage: ${props.datum.weightage}`}
        </div>
      </div>
    )
  }
  const DurationInputField = (props) => {
    return (
      <div className="px-0 mr-2" style={{ position: "relative", boxShadow: "0px 3px 6px #00000029", borderRadius: "8px", background: "#42506C" }}>
        <div
          style={{ display: "flex", alignItems: "center", borderRadius: "8px", cursor: "text" }}
          className={`${StylesB.session_input_field} ${StylesB.session_number_field} border_on_hover px-2 ${Styles.font_16}`}
        >
          {`Duration: ${props.datum.duration} ${"Mins"}`}
        </div>
      </div>
    )
  }
  function getSingleTrainer(item, index) {
    return (
      <Col
        name="trainer"
        id={item.id}
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="border_on_hover d-inline-flex w-100 py-2 px-1 mb-2 br-9px"
        style={{
          backgroundColor: "#42506C",
          boxShadow: "0px 3px 6px #00000029",
          border: item.selected === true ? "1px solid #E0E2E5" : "1px solid transparent",
        }}
        onClick={() => {
          trainers[index].selected = !trainers[index].selected
          setTrainers([...trainers])
          handleTrainers()
        }}
      >
        <AvatarCircle size={"30px"} name={item.name} avatar={item.profile_pic} />
        <div className="float-left d-inline-flex align-self-center" style={{ width: "88%" }}>
          <p className="d-inline-flex mb-0 pl-2 turient-header-text-color fs-16px bold-500">{item.name}</p>
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="lg"
            style={{ backgroundColor: "#E0E2E5", borderRadius: "50%", border: "1px solid #3FBDAC" }}
            color="#3FBDAC"
            className={`ml-2 ${item.selected !== true ? `d-none` : `d-flex`} `}
            alt="selected"
          />
        </div>
        <div className="float-right align-self-center" style={{ width: "12%" }}>
          <u
            className={`${Styles.onfocuswhite} turient-header-text-color fs-14px`}
            onClick={() => {
              navigateTrainer(item.id)
            }}
          >
            View Details
          </u>
        </div>
      </Col>
    )
  }
  const navigateTrainer = (trainer_id) => {
    navigate(
      `/app/school-setup/school-profile-setup/${localStorage.getItem("company_id")}/educator/educator-profile-setup/${trainer_id}?redirect=${
        window.location.href
      }`
    )
  }

  function showResourcesButton(type, id, visible_as_resource = false, module_id = null) {
    let data = []
    if (type === `topic`) {
      var index = null
      if (modulesData[`module_` + module_id] && modulesData[`module_` + module_id].topics && modulesData[`module_` + module_id].topics.length > 0) {
        index = modulesData[`module_` + module_id].topics.findIndex((res) => res.id === id)
      }
      data =
        visible_as_resource === false
          ? topicsResources[`topic_` + id]
          : index !== null && modulesData[`module_` + module_id].topics[index].visible_resources
          ? modulesData[`module_` + module_id].topics[index].visible_resources
          : topicsResources[`topic_` + id]
    } else if (type === `module`) {
      data = modulesResources[`module_` + id]
    }
    let next_url = null
    let see_more_loader = false
    if (type === "topic") {
      next_url = topicsResourcesNext[`topic_` + id]
      see_more_loader = topicsResourcesNextLoader[`topic_` + id]
    } else {
      next_url = modulesResourcesNext[`module_` + id]
    }
    if (data) {
      return (
        <div
          id={`drop-area`}
          className={`bg-transparent px-4 py-3 ${type} ${type === `resource` ? `resources` : ``} ${
            type === `resource` && collapse ? `${Styles.opacity_0} p-0` : `${Styles.opacity_1}`
          }`}
        >
          {data.map((item, index) => {
            return (
              <div key={`resource-item-${index}`} className={`${item.resource_data !== null ? "resource-item" : "d-none"} mb-2`}>
                <div className={`pr-2 d-flex`} style={{ color: `#E0E2E5`, background: "#4F6080", justifyContent: "space-between" }}>
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    <FontAwesomeIcon
                      className={`${modulesResourcesFetched} ${topicsResourcesFetched} ml-1 ${item.resource_type ? `d-none` : ``}`}
                      icon={faSpinner}
                      size="lg"
                    />
                    {item.resource_data ? (
                      item.resource_data.thumbnail_url ? (
                        <LazyLoadImage
                          className={`my-auto`}
                          src={item.resource_data.thumbnail_url}
                          alt={item.name}
                          style={{ width: `38px`, height: `30px`, fontSize: `12px` }}
                        />
                      ) : item.resource_data.avatar ? (
                        <LazyLoadImage
                          className={`my-auto`}
                          src={
                            item.resource_data.avatar && item.resource_data.avatar.indexOf(".pdf") < 1
                              ? item.resource_data.avatar
                              : item.resource_type === "video"
                              ? video
                              : turientimage
                          }
                          alt={item.name}
                          style={{ width: `38px`, height: `30px`, fontSize: `12px` }}
                        />
                      ) : (
                        <LazyLoadImage
                          className={`my-auto`}
                          src={
                            item.resource_type === "video"
                              ? video
                              : item.resource_type === "blog"
                              ? blog
                              : item.resource_type === "link"
                              ? link
                              : item.resource_type === "quiz"
                              ? quiz
                              : turientimage
                          }
                          alt={item.name}
                          style={{ width: `38px`, height: `30px`, fontSize: `12px` }}
                        />
                      )
                    ) : (
                      ``
                    )}
                    <p style={{ fontSize: "14px", margin: "0px 0px 0px 10px" }}>{item?.resource_data?.display_name ?? item.resource_data?.name ?? item.name}</p>
                  </div>
                </div>
              </div>
            )
          })}
          {type === "module" && next_url && Object.keys(next_url).length > 0 ? (
            <div className={`text-center`}>
              <Button
                variant="none"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  fetchNextModuleResources(next_url.next)
                }}
                className={`${Styles.font_12} border_on_hover text-center px-3 py-1 mt-2 bg-212C42`}
                style={{ color: `#c6cff4`, borderRadius: `15px` }}
              >
                See More
              </Button>
            </div>
          ) : type === "topic" && next_url && Object.keys(next_url).length > 0 ? (
            <div className={`text-center`}>
              <Button
                disabled={see_more_loader}
                variant="none"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  fetchNextTopicResources(next_url.next, id)
                }}
                className={`${Styles.font_12} border_on_hover text-center d-flex px-3 py-1 mt-2 mx-auto bg-212C42`}
                style={{ color: `#c6cff4`, borderRadius: `15px` }}
              >
                {see_more_loader ? <Spinner animation="border" variant="secondary" size="sm" className="mr-2" /> : ""}
                See More
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function openNewWindow(url) {
    let wnd = window.open(url, "create-class")
  }

  function handleNewWinMessages(event) {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      if (event.data.instruction === "payload") {
        fetchTrainers()
      }
    }
  }

  return (
    <div>
      <SEO title="Training Creation" />
      <Col xs="12" sm="12" md="10" lg="10" className="d-inline-flex" style={{ marginLeft: "7%", marginBottom: "12px" }}>
        <div className={`turient-body-text-color w-100 d-flex br-9px bg-212C42`} style={{ boxShadow: "0px 3px 12px #00000099" }}>
          <div
            className="d-flex"
            onClick={() => setDuplicateClass(true)}
            onMouseEnter={() => {
              setDuplicateClass(true)
            }}
            onMouseLeave={() => {
              setDuplicateClass(false)
            }}
          >
            <p
              className="mb-0 pl-14px pr-3 font-weight-bold pointer pt-1 pb-2 turient-header-text-color fs-20px"
              id="new_course"
              style={{ borderRadius: "9px 0px 0px 9px" }}
            >
              {trainingName === "" || trainingName === null || trainingName === undefined
                ? `New ${openedCourseSelction ? (courseType === "0" ? "Live" : "Cohort") : ""} Class`
                : trainingName}
            </p>
            <div className={`float-left align-self-center text-right`}>
              <Image className="float-left pointer" src={duplicateClass ? CircleUp : CircleDown} width={20} height={20} />
            </div>
          </div>
          {HasCreatePermission(CLASSES) && (
            <div
              className={`float-left bg-212C42 position-absolute br-9px ${duplicateClass ? "" : "d-none"}`}
              style={{
                top: "40px",
                border: "1px solid #E0E2E5",
                zIndex: "2",
                boxShadow: "0px 6px 12px #00000099",
              }}
              onClick={() => setDuplicateClass(false)}
              onMouseEnter={() => {
                setDuplicateClass(true)
              }}
              onMouseLeave={() => {
                setDuplicateClass(false)
              }}
            >
              <div
                className={`mb-0 py-1 px-2 ${Styles.duplicate_class} pointer`}
                style={{ borderBottom: "1px solid #707070", borderTopLeftRadius: "9px", borderTopRightRadius: "9px", fontSize: "14px" }}
              >
                <Link className={`${Styles.duplicate_class_text}`} to={`/app/companies/${location.company_id}/courses/new/trainings/create`}>
                  Create a new class
                </Link>
              </div>
              <div
                className={`mb-0 py-1 px-2 ${Styles.duplicate_class} pointer`}
                style={{ borderBottomLeftRadius: "9px", borderBottomRightRadius: "9px", fontSize: "14px" }}
              >
                <Link to={`/app/companies/${location.company_id}/courses/new/trainings/duplicate`} className={`${Styles.duplicate_class_text}`}>
                  Duplicate an existing class
                </Link>
              </div>
            </div>
          )}
          <Image
            src={Close}
            alt={`close`}
            width={20}
            height={20}
            onClick={() => {
              navigate("/app/dashboard")
            }}
            className={`pointer mr-3 ml-auto float-right align-self-center`}
          />
        </div>
      </Col>
      <Form className={`form-horizontal px-3 ${!HasCreatePermission(CLASSES) && "disable-content"}`} autoComplete="off" onSubmit={handleSubmit}>
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          className={`w-100 mr-0 pt-2 mb-0 bg-212C42`}
          style={{ marginLeft: "7%", borderRadius: "9px 9px 0px 0px", boxShadow: "0px 3px 12px #00000099" }}
        >
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className={`pointer ${selectCourseCollapdsed ? `border_on_hover` : null} mb-2 w-100 br-9px  px-0`}
            style={{
              textAlign: "left",
              backgroundColor: "#303C54",
              boxShadow: "0px 3px 6px #00000029",
              border: !selectCourseCollapdsed ? "1px solid white" : "1px solid transparent",
            }}
          >
            <Col
              lg="12"
              md="12"
              sm="12"
              xs="12"
              onClick={() => {
                setOpenedCourseSelection(true)
                setSelectCourseCollapsed(!selectCourseCollapdsed)
              }}
              className={`pointer ${
                (currentCourse === null || currentCourse === "") && selectCourseCollapdsed ? `py-3` : !selectCourseCollapdsed ? "py-2" : `py-3`
              }  d-inline-flex w-100`}
              style={{ borderBottom: !selectCourseCollapdsed ? "1px solid #63666f" : "1px solid transparent" }}
            >
              <div className="float-left align-self-center" style={{ width: "95%" }}>
                {currentCourse !== null ? (
                  <div className="d-inline-flex align-items-center">
                    <p className={`d-inline-flex mb-0`} style={{ fontSize: "16px", color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                      Course:{" "}
                    </p>
                    <p className={`d-inline-flex mb-0 turient-header-text-color fs-16px pl-14px`}>{currentCourse} </p>
                    <p className="d-inline-flex mb-0 ml-1 mr-3 fs-16px">{`(` + courseLength + `${courseLength > 1 ? ` days)` : ` day)`}`}</p>
                    <p
                      className={`d-inline-flex ml-3 mb-0 turient-header-text-color fs-14px ${Styles.onfocuswhite}`}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setSelectedCourseName(viewDetailsData.name)
                        setCourseDays(viewDetailsData.duration > 0 ? Math.ceil(viewDetailsData.duration / 24) : 0)
                        fetchorCreateTemplate(viewDetailsData?.workshop_stage_id)
                      }}
                    >
                      <u>View Details</u>
                    </p>
                  </div>
                ) : (
                  <p className={`d-inline-flex mb-0 d-flex justify-content-center fs-16px `} style={{ color: selectCourseCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                    Select Course{" "}
                  </p>
                )}
              </div>
              <div className={`float-right align-self-center text-right`} style={{ width: "5%" }}>
                <FontAwesomeIcon
                  onClick={() => {
                    setSelectCourseCollapsed(!selectCourseCollapdsed)
                  }}
                  className="float-right pointer turient-header-text-color h-20px w-20px"
                  icon={selectCourseCollapdsed ? faChevronCircleDown : faChevronCircleUp}
                />
              </div>
            </Col>
            {selectCourseCollapdsed ? null : (
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className={`pointer ${coursesNext ? `pb-1` : `pb-0`} pt-3 px-0 my-0 text-left br-9px`}
                style={{ backgroundColor: "#303C54", maxHeight: "350px", overflowY: "scroll", overflowX: "hidden" }}
              >
                <Col lg="12" md="12" sm="12" xs="12" className="mt-2 mb-3 d-inline-flex justify-content-between">
                  <div className="float-left align-self-center br-9px" style={{ width: "fit-content", background: "#586886" }}>
                    <Button
                      onClick={(e) => {
                        setCourseType("0")
                      }}
                      className="float-left border_on_hover px-4 py-2"
                      style={{
                        background: courseType === "0" ? "#586886" : "#42506C",
                        border: courseType === "0" ? "1px solid #E0E2E5" : "1px solid transparent",
                        fontWeight: courseType === "0" ? "bold" : "500",
                      }}
                    >
                      <p className={`p-0 m-0 turient-header-text-color fs-14px`}>Live Courses</p>
                    </Button>
                    {/*<Button onClick={(e)=>{setCourseType('3')}} className='float-left border_on_hover px-4 py-2' style={{background: courseType === '3' ? '#586886' : '#42506C', border:courseType === '3' ? '1px solid #E0E2E5' : '1px solid transparent',fontWeight:courseType === '3' ? 'bold' : '500'}} ><p className={`p-0 m-0`} style={{fontSize:'14px',color:'#E0E2E5'}}>Cohort Courses</p></Button>*/}
                    {/*<Button onClick={(e)=>{e.preventDefault();void(0)}} className='float-left border_on_hover px-4 py-2' style={{background: courseType === '3' ? '#586886' : '#42506C', border:courseType === '3' ? '1px solid #E0E2E5' : '1px solid transparent',fontWeight:courseType === '3' ? 'bold' : '500', opacity: '0.2'}} ><p className={`p-0 m-0`} style={{fontSize:'14px',color:'#E0E2E5'}}>Cohort Courses</p></Button>*/}
                  </div>
                  <div className="float-right align-self-center d-flex br-9px" style={{ backgroundColor: "#42506C" }}>
                    <FontAwesomeIcon color="#7D8289" className={`my-auto ml-2`} icon={faSearch} size="lg" />
                    <FormControl
                      autoFocus
                      className={`border-0 shadow-none py-0 form-control px-2 bg-cards turient-header-text-color fs-16px`}
                      placeholder="Search Course"
                      onChange={(e) => {
                        e.preventDefault()
                        setSearchValue(e.target.value)
                        delayedSearchQuery(e.target.value)
                      }}
                      value={searchValue}
                      style={{ backgroundColor: "#42506C" }}
                    />
                  </div>
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  {courses.map((datum, index) => getSingleCourse(datum, index))}
                </Col>
                {coursesNext ? (
                  <Col xs="12" className={`mt-2 ml-3 mb-1 px-0 float-left d-inline-flex`}>
                    <u
                      onClick={() => {
                        fetchNextCourses(coursesNext)
                      }}
                      className={`float-left turient-header-text-color fs-14px ${Styles.onfocuswhite}`}
                      color="warning"
                      disabled={trainingCreated}
                    >
                      Show More
                    </u>{" "}
                  </Col>
                ) : null}
              </Col>
            )}
          </Col>
          {currentCourse === null || currentCourse === "" ? (
            <Col xs="12" sm="12" md="12" lg="12" className={`mt-2 mb-2 px-0 ${clickedCourseCollapse ? null : `d-none`}`}>
              <div className="d-inline-flex px-3 py-0 br-23px">
                <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                  Please select the course to proceed
                </p>
              </div>
            </Col>
          ) : null}
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className={`pointer ${selectTrainerCollapdsed ? `border_on_hover` : null} mb-2 w-100 br-9px px-0`}
            style={{
              textAlign: "left",
              backgroundColor: "#303C54",
              position: "relative",
              boxShadow: "0px 3px 6px #00000029",
              border: !selectTrainerCollapdsed ? "1px solid white" : "1px solid transparent",
            }}
          >
            <Col
              lg="12"
              md="12"
              sm="12"
              xs="12"
              onClick={() => {
                setSelectTrainerCollapsed(!selectTrainerCollapdsed)
                handleTrainers()
              }}
              className={`pointer align-items-center ${selectedTrainers.length === 0 && selectTrainerCollapdsed ? `py-3` : `py-2`} d-inline-flex w-100`}
              style={{ borderBottom: !selectTrainerCollapdsed ? "1px solid #63666f" : "1px solid transparent" }}
            >
              <div className="float-left align-self-center" style={{ width: "95%" }}>
                {selectedTrainers.length > 0 ? (
                  <div className="d-inline-flex">
                    <p className={`d-inline-flex mb-0 pt-2 fs-16px`} style={{ paddingRight: "13px", color: selectTrainerCollapdsed ? "#838B9E" : "#C3C7CE" }}>
                      {selectedTrainers.length > 1 ? "Trainers:" : "Trainer:"}{" "}
                    </p>
                    {selectedTrainers.map((item, indx) => (
                      <div
                        key={indx}
                        className="px-2 py-2 mr-2 d-flex turient-header-text-color fs-16px bold-500 align-items-center bg-42506C"
                        style={{ boxShadow: "0px 3px 6px #00000029" }}
                      >
                        <AvatarCircle size={"30px"} name={item.name} avatar={item.profile_pic} />
                        <span className="ml-1">{item.name}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p
                    className={`d-inline-flex float-left mb-0 d-flex justify-content-center fs-16px`}
                    style={{ color: selectTrainerCollapdsed ? "#838B9E" : "#C3C7CE" }}
                  >
                    Select Trainers{" "}
                  </p>
                )}
              </div>
              <div className={`float-right align-self-center text-right`} style={{ width: "5%" }}>
                <FontAwesomeIcon
                  onClick={() => {
                    setSelectTrainerCollapsed(!selectTrainerCollapdsed)
                  }}
                  className="float-right pointer"
                  icon={selectTrainerCollapdsed ? faChevronCircleDown : faChevronCircleUp}
                  style={{ width: `20px`, height: `20px`, color: `#E0E2E5` }}
                />
              </div>
            </Col>
            {selectTrainerCollapdsed ? null : (
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className="pointer pt-1 px-0 my-0 text-left br-9px"
                style={{ backgroundColor: "#303C54", position: "relative", paddingBottom: "57px" }}
              >
                <Col lg="12" md="12" sm="12" xs="12" style={{ overflowY: "scroll", maxHeight: "250px" }}>
                  {trainers.length > 0 ? (
                    trainers.map((item, index) => getSingleTrainer(item, index))
                  ) : (
                    <div className={`align-items-center text-center w-100`}>
                      <p className={`mb-0 turient-header-text-color fs-16px bold-500`}>No trainers Added!</p>
                      <Link className="turient-header-text-color fs-16px bold-500" to={`/app/companies/${localStorage.getItem("company_id")}/trainers/create`}>
                        <u>Click here to add</u>
                      </Link>
                    </div>
                  )}
                </Col>
                <Col xs="12" style={{}} className="mt-0 mb-1 ml-3 px-0 d-inline-flex float-left align-items-center d-inline-flex">
                  <button
                    onClick={() => {
                      setSelectTrainerCollapsed(true)
                      handleTrainers()
                    }}
                    className="border_on_hover float-left px-3 turient-header-text-color fs-14px br-23px "
                    style={{
                      backgroundColor: "#586886",
                      border: "1px solid #586886",
                      boxShadow: "0px 3px 6px #00000029",
                    }}
                  >
                    Done
                  </button>
                  <p className="pt-3 ml-2 turient-header-text-color fs-14px">{selectedTrainers.length} Trainers Selected</p>
                  <div className="px-3 ml-auto mr-4">
                    <button
                      onClick={() => {
                        openNewWindow(
                          `/app/school-setup/school-profile-setup/${localStorage.getItem("company_id")}/educator/educator-profile-setup/new?redirect=${
                            window.location.href
                          }`
                        )
                      }}
                      className={`border_on_hover px-3 turient-header-text-color fs-14px br-23px`}
                      style={{
                        backgroundColor: "#586886",
                        border: "1px solid #586886",
                        boxShadow: "0px 3px 6px #00000029",
                      }}
                    >
                      Add New Trainer
                    </button>
                  </div>
                </Col>
              </Col>
            )}
          </Col>
          {selectedTrainers.length < 1 ? (
            <Col xs="12" sm="12" md="12" lg="12" className={`mt-2 px-0 ${clickedTrainerDetailsNext ? null : `d-none`}`}>
              <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
                <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                  Please select the trainers to proceed
                </p>
              </div>
            </Col>
          ) : null}
          <Staff teamsData={teamsData} training={training} setTraining={setTraining} teamsErrMsg={teamsErrMsg} />
          <Col lg="12" md="12" sm="12" xs="12" className="pointer mb-0 mt-2 px-0 pl-0 d-inline-flex text-left br-9px">
            <InputGroup className="border_on_hover py-1 br-9px border border-1 border-transparent bg-303C54">
              <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                <InputGroup.Text
                  className="border-0 d-flex justify-content-center turient-header-text-color fs-16px"
                  onClick={() => {
                    document.getElementById("name").focus()
                  }}
                  style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", opacity: "41%" }}
                  id="basic-addon1"
                >
                  Class Name:
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className={`border-0 pl-0 shadow-none d-flex justify-content-center turient-header-text-color fs-16px ${Styles.class_form} ${StylesM.course_create}`}
                style={{ backgroundColor: "#303C54", borderRadius: "0px" }}
                type="text"
                name="name"
                defaultValue={training.name}
                onChange={handleChange}
                id="name"
                placeholder="Name for your class"
                required
              />
            </InputGroup>
          </Col>
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            onMouseLeave={() => {
              setShowDisabledError(false)
            }}
            onMouseOver={() => {
              if (currentCourse) {
              } else {
                setShowDisabledError(true)
              }
            }}
            className={`border_on_hover align-items-center pointer br-9px mt-2 ${enteredValidTime ? `mb-2` : "mb-2"} py-1 px-0 d-inline-flex`}
            style={{ textAlign: "left", backgroundColor: "#303C54" }}
          >
            <div className={`d-flex ${Styles.label_datetime} ${viewDetailsData ? "" : "disabled-div"}`}>
              <label className={`${Styles.label_basic} ${Styles.label_datetime_start}`}>Start Date and Start Time:</label>
              <CustomDateTimePicker
                value={training_start_date}
                onChange={(val) => {
                  setEnteredValidTime(true)
                  handleDateChange(val, "start_date")
                }}
              />
            </div>
            <div className={`d-flex ${Styles.label_datetime}`}>
              <label className={`${Styles.label_basic} ${Styles.label_datetime_end}`}>End Date and End Time:</label>
              <CustomDateTimePicker
                value={training_end_date}
                onChange={(val) => {
                  setEnteredValidTime(true)
                  handleDateChange(val, "end_date")
                }}
              />
            </div>
            <img
              src={CalendarAlt}
              alt="calendar"
              onClick={() => {
                document.querySelectorAll(".react-datetime-picker__inputGroup__day").forEach((element) => {
                  element.focus()
                })
              }}
              className={`mb-0 pointer ${Styles.calendar_icon}`}
            />
          </Col>
          {isMuralEnabled && (
            <MuralAccountsList
              onSelectMuralAccount={(muralAccount) => {
                setTraining({ ...training, mural_account: muralAccount })
              }}
              course={training.course}
              showMuralAccounts={showMuralAccounts}
            />
          )}

          <VideoCallLinkInput onChange={handleChange} />
          {showDisabledError ? (
            <Col xs="12" sm="12" md="12" lg="12" className={`pb-3 px-0`}>
              <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
                <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                  Please select any course to enter date/time
                </p>
              </div>
            </Col>
          ) : null}
          {enteredValidTime === false ? (
            <Col xs="12" sm="12" md="12" lg="12" className={`pb-3 px-0`}>
              <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
                <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                  {timeErrorMsg}
                </p>
              </div>
            </Col>
          ) : null}
          <Col lg="6" md="6" sm="12" xs="12" className={`pointer my-2 px-0 ${true ? `d-none` : `d-inline-flex`}`} style={{ textAlign: "left" }}>
            {clickedPrice ? (
              <Col lg="12" md="12" sm="12" xs="12" className="pointer pl-0 d-inline-flex mb-2 br-9px" style={{ textAlign: "left" }}>
                <InputGroup className="border_on_hover br-9px">
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className="border-0 d-flex justify-content-center turient-header-text-color fs-16px"
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", opacity: "41%" }}
                      id="basic-addon1"
                    >
                      Price:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 d-flex justify-content-center turient-header-text-color fs-16px ${StylesM.course_create}`}
                    style={{ backgroundColor: "#303C54", borderRadius: "0px" }}
                    type="number"
                    name="price"
                    defaultValue={training.price}
                    onChange={handleChange}
                    id="price"
                    placeholder="Please add a price for your class"
                    required
                  />
                </InputGroup>
              </Col>
            ) : (
              <div
                className="pointer mr-2 mb-2 px-1 turient-header-text-color fs-12px"
                onClick={() => {
                  setClickedPrice(true)
                }}
                style={{ borderBottom: "1px solid #E0E2E5", width: "fit-content" }}
              >
                Add Price
              </div>
            )}
          </Col>
        </Col>
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          className={`w-100 d-inline-flex mr-0 pt-3 pb-3 bg-212C42`}
          style={{
            marginLeft: "7%",
            boxShadow: " 0px -3px 6px #00000029",
            borderRadius: "0px 0px 9px 9px",
            borderTop: "1px solid #63666f",
          }}
        >
          <button type="submit" className={`border_on_hover ${Styles.publish_btn}`}>
            Publish Class
          </button>
        </Col>
      </Form>
      {show_module_modal ? showModulesModal() : null}
      <Notification data={notificationData} setData={setNotificationData} />
      <AppIntegrationModal />
    </div>
  )
}

export default CreateTraining
