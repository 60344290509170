import React from "react"
import Dropdown from "react-bootstrap/Dropdown"
import PasswordValidation from "./passwordValidation.module.scss"
import PropTypes from "prop-types"

const WhatMakesAStrongPassword = (props) => {
  return (
    <Dropdown drop={props.drop}>
      <Dropdown.Toggle className={`text-right underline pointer shadow-none caret-none p-0 fs-12px text-white`} variant={`none`}>
        What makes a strong password?
      </Dropdown.Toggle>
      <Dropdown.Menu align="right" className={`mx-2 py-2 bg-0D131F`}>
        <div className={`px-3 text-E0E2E5 ${PasswordValidation.dropdown_menu_strongpwd}`}>
          <p className={`fs-15px bold-600 mb-2`}>Choose a strong password to protect your account</p>
          <p className={`fs-12px mb-1`}>It should be a mix of letters, numbers and special characters</p>
          <p className={`fs-12px mb-1`}>It should be atleast 8 characters long</p>
          <p className={`fs-12px mb-1`}>It should not contain your name, phone number or email address</p>
          <p className={`fs-12px mb-1`}>It should not contain white space</p>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

WhatMakesAStrongPassword.defaultProps = {
  drop: "down",
}
WhatMakesAStrongPassword.propTypes = {
  drop: PropTypes.string.isRequired,
}
export default WhatMakesAStrongPassword
