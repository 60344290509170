import React, { useEffect, useState, useCallback, useContext } from "react"
import { navigate, Link } from "gatsby"
import debounce from "lodash/debounce"

import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import FormControl from "react-bootstrap/FormControl"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Modal from "react-bootstrap/Modal"
import Dropdown from "react-bootstrap/Dropdown"
import Spinner from "react-bootstrap/Spinner"
import InputGroup from "react-bootstrap/InputGroup"
import Image from "../custom/Image"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import SEO from "../../components/seo"
import HorizontalScroll from "../custom/HorizontalScroll"
import DeleteModal from "../custom/DeleteModal"
import { isBrowser } from "../../services/developer_mode"
import { getRequest, postRequest, deleteRequest, putRequest } from "../../services/rest_service"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../stores/global_store/GlobalActions"

import ExclamationTriangleWhite from "../../images/svgs/exclamation-triangle-white.svg"
import AngleDown from "../../images/svgs/down_arrow_white.svg"
import EllipsisV from "../../images/svgs/threedots.svg"
import search from "../../images/svgs/search.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import CancelWhite from "../../images/svgs/close.svg"
import ChevronCircleUp from "../../images/svgs/arrow-dropup-white-circle.svg"
import ChevronCircleDown from "../../images/svgs/arrow-dropdown-white-circle.svg"
import CheckIcon from "../../images/svgs/check-white.svg"
import CancelIcon from "../../images/svgs/cancel-white-icon.svg"
import DragHandleIcon from "../../images/svgs/drag-handle-white.svg"

import { Rnd } from "react-rnd"
import SunEditor from "suneditor-react"

import Styles from "../../modularscss/styles.module.scss"
import StylesA from "./quiz.module.scss"
import StylesB from "../courses/course.module.scss"

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  outline: "none",
  margin: `0`,
  borderRadius: "20px",
  textAlign: "center",
  color: "#fff",
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  padding: grid,
})

const ViewQuizQuestions = (location) => {
  const [questions, setQuestions] = useState([])
  const [questionsNext, setQuestionsNext] = useState("")
  const [allQuestionsFetched, setAllQuestionsFetched] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  const [collapse, setCollapse] = useState(false)

  const [questionCreated, setQuestionCreated] = useState(true)

  const [questionOptions, setQuestionOptions] = useState({})
  const [optionsFetched, setOptionsFetched] = useState(false)

  const [optionCreated, setOptionCreated] = useState(true)

  const [quiz, setQuiz] = useState({})
  // New fields
  const [hovered, setHovered] = useState(false)
  const [width, setWidth] = useState(1000)
  const [dmodal, setDmodal] = useState(false)
  const [questionToggle, setQuestionToggle] = useState({})
  const [itemToDelete, setItemToDelete] = useState("")
  const [showAddDescriptionmodal, setShowAddDescriptionModal] = useState(false)
  const [activeQuestion, setActiveQuestion] = useState("")

  const [quizQuestions, setQuizQuestions] = useState([])
  const [createdQuestion, setCreatedQuestion] = useState("")
  const [nextQuizQuestions, setNextQuizQuestions] = useState("")
  const [quizQuestionsFetched, setQuizQuestionsFetched] = useState(false)
  const [nextQuizQuestionsFetched, setNextQuizQuestionsFetched] = useState(false)
  const [editLoader, setEditLoader] = useState(false)

  const [showRichTextModal, setShowRichTextModal] = useState(false)
  const [activeSubjectiveQuetion, setActiveSubjectiveQuetion] = useState(null)
  const [showQuestionsExceededModal, setShowQuestionsExceededModal] = useState(false)
  const [draggedQuestionData, setDraggedQuestionData] = useState(null)
  const [draggableHash, setDraggableHash] = useState({ x: 0, y: 0, width: 724, height: 486 })
  const [totalQuizQuestions, setTotalQuizQuestions] = useState(0)
  const [globalState, dispatch] = useContext(GlobalContext)

  const questionLevelType = ["Easy", "Medium", "Difficult"]
  const question_type_data = ["", "Objective", "Subjective"]

  useEffect(() => {
    if (isBrowser() && document.getElementsByClassName("parent") && document.getElementsByClassName("parent").length > 0) {
      let x = parseInt(document.getElementsByClassName("parent")[0].offsetWidth - 724 - 80) / 2
      let y = parseInt(document.getElementsByClassName("parent")[0].offsetHeight - 486) / 2
      setDraggableHash({ x: x, y: y, width: 724, height: 486 })
    }
  }, [showRichTextModal])

  useEffect(() => {
    if (isBrowser()) {
      setWidth(window.innerWidth)
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
      })
      return () => {
        window.removeEventListener("resize", () => {})
      }
    }
  }, [])

  useEffect(() => {
    setHovered(false)
  }, [width])

  useEffect(() => {
    fetchAllQuestions(searchValue ? searchValue : "")
  }, [searchValue])

  useEffect(() => {
    fetchQuiz()
    fetchQuizQuestions()
  }, [])

  useEffect(() => {
    if (questionCreated && createdQuestion && createdQuestion.question_type === "1") {
      fetchOptions(createdQuestion.id)
      setCreatedQuestion("")
    }
  }, [questionCreated])

  async function fetchQuiz() {
    let res = await getRequest(`/quiz/${location.quiz_id}/`)
    if (res.success) {
      setQuiz(res.data)
      fetchAllQuestions(searchValue ? searchValue : "", res.data.quiz_level)
    }
  }

  async function updateQuizDetails(name, value, create_question_function = null, question_type_data = null) {
    let formData = new FormData()
    formData.append(name, value)
    let res = await putRequest(`/quiz/${location.quiz_id}/`, formData)
    if (res.success) {
      if (create_question_function && question_type_data) {
        create_question_function(question_type_data)
      }
      setQuiz(res.data)
    }
  }

  async function fetchAllQuestions(searchValue, quiz_level) {
    let current_quiz_level = quiz_level ? quiz_level : quiz.quiz_level
    if (current_quiz_level) {
      let search = searchValue === "" ? "" : `&search=${searchValue}`
      let filter = current_quiz_level !== "3" ? `&question_level=${current_quiz_level}` : ``
      let res = await getRequest(`/quiz-question/question_bank_questions/?limit=20&order=new${search}${filter}`)
      if (res.success) {
        setQuestions(res.data.results)
        setQuestionsNext(res.data.next)
        setAllQuestionsFetched(true)
      }
    }
  }

  async function fetchNextAllQuestions(next_url) {
    if (next_url) {
      let res = await getRequest(next_url)
      if (res.success) {
        let questions_data = [...questions]
        questions_data = questions_data.concat(res.data.results)
        setQuestions(questions_data)
        setQuestionsNext(res.data.next)
      }
    }
  }

  async function fetchQuizQuestions(refetch = false) {
    setQuizQuestionsFetched(false)
    let res = await getRequest(`/quiz-question/?limit=20&quiz=${location.quiz_id}`)
    if (res.success) {
      setTotalQuizQuestions(res.data.count)
      setQuizQuestions(res.data.results)
      setNextQuizQuestions(res.data.next)
      setQuizQuestionsFetched(true)
    }
  }

  async function fetchNextQuizQuestions(next_url) {
    setNextQuizQuestionsFetched(false)
    let res = await getRequest(next_url)
    if (res.success) {
      let questions_data = [...quizQuestions]
      questions_data = questions_data.concat(res.data.results)
      setTotalQuizQuestions(res.data.count)
      setQuizQuestions(questions_data)
      setNextQuizQuestions(res.data.next)
      setNextQuizQuestionsFetched(true)
    }
  }

  async function fetchOptions(id, fetch = false, question_resource_id = "") {
    if (questionOptions["question_" + id] === undefined || fetch) {
      let res = await getRequest(`/question-option/?quiz_question=${id}`)
      if (res.success) {
        let options_data = { ...questionOptions }
        options_data["question_" + id] = res.data.results
        setQuestionOptions(options_data)
        setOptionsFetched(true)
        handleQuestionIsValid(id, options_data, [...quizQuestions])
        let objDiv = document.getElementById("quiz-questions-div")
        objDiv.scrollTop = objDiv.scrollHeight
        //Set Focus on the Question
        setTimeout(() => {
          let createdQuestionInput = document?.getElementById(`${id}-question-input-id`)
          if (createdQuestionInput) {
            createdQuestionInput.focus()
          }
        }, 200)
      } else {
        setOptionsFetched(false)
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  function openNav() {
    setCollapse(!collapse)
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    const sourceParentId = result.source.droppableId
    const destParentId = result.destination.droppableId
    // When a question is draged and droped for creating a new question or reordering questions.
    if (result.type === "questionsDraggable") {
      const sourceSubItems = [...questions]
      const destSubItems = [...quizQuestions]

      if (destParentId === "quiz") {
        // Question reorder by drag and drop
        if (sourceParentId === destParentId) {
          if (sourceIndex !== destIndex) {
            // let draggable_id = result.draggableId.split('-')[0]
            const reorderedSubItems = reorder(destSubItems, sourceIndex, destIndex)

            let questionsData = reorderedSubItems[destIndex]
            if (destIndex === 0) {
              questionsData.sequence = (parseFloat(quizQuestions[0].sequence) - 10).toFixed(20)
            } else if (destIndex === quizQuestions.length - 1) {
              questionsData.sequence = (parseFloat(quizQuestions[quizQuestions.length - 1].sequence) + 10).toFixed(20)
            } else {
              if (result.source.index > destIndex) {
                questionsData.sequence = ((parseFloat(quizQuestions[destIndex - 1].sequence) + parseFloat(quizQuestions[destIndex].sequence)) / 2).toFixed(20)
              } else if (result.source.index < destIndex) {
                questionsData.sequence = ((parseFloat(quizQuestions[destIndex].sequence) + parseFloat(quizQuestions[destIndex + 1].sequence)) / 2).toFixed(20)
              }
            }
            updateQuestion("sequence", questionsData.sequence, questionsData.id)
            setQuizQuestions(reorderedSubItems)
          }
        } else {
          //When a question is draged and droped to quiz question section from question bank.
          //Create a new duplicate question based on the droped question with proper sequence.

          if (totalQuizQuestions < quiz.no_of_questions) {
            setDraggedQuestionData(null)
            const [draggedItem] = sourceSubItems.splice(sourceIndex, 1)
            draggedItem.sequence = destIndex
            if (destIndex === 0 && destSubItems.length === 0) {
              draggedItem.sequence = destIndex
            } else if (destIndex === 0) {
              if (draggedItem) {
                draggedItem.sequence = (parseFloat(destSubItems[destIndex].sequence) - 10).toFixed(20)
              }
            } else if (destIndex === destSubItems.length) {
              draggedItem.sequence = (parseFloat(destSubItems[destIndex - 1].sequence) + 10).toFixed(20)
            } else {
              draggedItem.sequence = (
                (parseFloat(destSubItems[destIndex].sequence) + parseFloat(destSubItems[parseFloat(destIndex) - 1].sequence)) /
                2
              ).toFixed(20)
            }
            duplicateQuestion(draggedItem.id, draggedItem.sequence, destIndex)
            setQuestions(sourceSubItems)
          } else if (totalQuizQuestions >= quiz.no_of_questions) {
            setDraggedQuestionData(result)
          }
        }
      }
    } else {
      // When an option is draged and droped for reordering options within a question.
      if (sourceParentId === destParentId && sourceIndex !== destIndex) {
        // let option_id = result.draggableId.split('-')[0]
        let question_id = result.draggableId.split("-")[3]
        let options_data = { ...questionOptions }
        let current_options = options_data["question_" + question_id]
        const reorderedSubItems = reorder(current_options, sourceIndex, destIndex)
        let question_options = questionOptions["question_" + question_id]

        let optionData = reorderedSubItems[destIndex]
        if (destIndex === 0) {
          optionData.sequence = (parseFloat(question_options[0].sequence) - 10).toFixed(20)
        } else if (destIndex === question_options.length - 1) {
          optionData.sequence = (parseFloat(question_options[question_options.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (result.source.index > destIndex) {
            optionData.sequence = ((parseFloat(question_options[destIndex - 1].sequence) + parseFloat(question_options[destIndex].sequence)) / 2).toFixed(20)
          } else if (result.source.index < destIndex) {
            optionData.sequence = ((parseFloat(question_options[destIndex].sequence) + parseFloat(question_options[destIndex + 1].sequence)) / 2).toFixed(20)
          }
        }
        options_data["question_" + question_id] = reorderedSubItems
        updateOption("sequence", optionData.sequence, optionData.id)
        setQuestionOptions(options_data)
      }
    }
  }

  useEffect(() => {
    if (draggedQuestionData) {
      setShowQuestionsExceededModal(true)
    }
  }, [draggedQuestionData])

  function showQuestions() {
    let data = []
    data = questions
    data = data ? data : []
    let next_url = questionsNext
    return (
      <Droppable droppableId={`all`} type={`questionsDraggable`}>
        {(provided, snapshot) => (
          <div
            className={`bg-transparent px-3 ${collapse ? `${Styles.publish_side_panel_collapsed} p-0` : ``}`}
            ref={provided.innerRef}
            style={{ overflowY: "auto", height: "calc(100vh - 119px)" }}
          >
            {data.map((item, index) => (
              <Draggable key={item.id + `-question-key` + index} draggableId={`${item.id.toString()}-question-id-${index.toString()}`} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`col-lg-12 mb-14px pl-0 pr-0 bg-transparent py-0 `}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <div
                      className={`resource-card-bg d-flex align-items-center`}
                      style={{ color: `#C6CFF4`, position: `relative`, background: "#303C54", height: "35px", borderRadius: "5px" }}
                    >
                      <div>
                        <div className={`${item.thumbnail_url ? Styles.img_bg : ``}`}></div>
                        {item.title && item.title.length > 35 ? (
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip className="event-tooltip" id={`tooltip-${item.title}`}>
                                {item.title}
                              </Tooltip>
                            }
                          >
                            <p
                              className={`elipsis-2 text-center w-100 px-2 pb-1 mt-auto ${Styles.b_0}`}
                              style={{ paddingTop: `5px`, margin: `0px`, WebkitBoxOrient: "vertical", fontSize: "14px" }}
                            >
                              {item.title}
                            </p>
                          </OverlayTrigger>
                        ) : (
                          <p
                            className={`elipsis-2 text-center w-100 px-2 pb-1 mt-auto ${Styles.b_0}`}
                            style={{ paddingTop: `5px`, margin: `0px`, WebkitBoxOrient: "vertical", fontSize: "14px" }}
                          >
                            {item.title}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {next_url ? (
              <Col md="auto" className={`align-self-center text-center d-inline-flex w-100 justify-content-center`}>
                <Button
                  variant="none"
                  onClick={(e) => {
                    fetchNextAllQuestions(next_url)
                  }}
                  className={`${StylesB.add_button} mt-2`}
                >
                  See More
                </Button>
              </Col>
            ) : (
              ``
            )}
          </div>
        )}
      </Droppable>
    )
  }

  async function duplicateQuestion(question_id, sequence, destination_index) {
    setQuestionCreated(false)
    let formData = new FormData()
    formData.append("quiz_id", location.quiz_id)
    formData.append("sequence", sequence)
    let res = await postRequest(`/quiz-question/${question_id}/attach_question_to_quiz/`, formData)
    if (res.success) {
      let questions_data = [...quizQuestions]
      questions_data.splice(destination_index, 0, res.data)
      setQuizQuestions(questions_data)
      setCreatedQuestion(res.data)
      setQuestionCreated(true)
      handleQuestionToggle(res.data.id, res.data.question_type, false)
      setTotalQuizQuestions((prev) => ++prev)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function createQuestion(value = "1") {
    setQuestionCreated(false)
    setEditLoader(true)

    let formData = new FormData()
    formData.append("question_type", value)
    formData.append("title", "sample question console")
    formData.append("quiz", location.quiz_id)
    let question_level = quiz.quiz_level !== "3" ? quiz.quiz_level : "0"
    formData.append("question_level", question_level)
    let res = await postRequest("/quiz-question/", formData)

    if (res.success) {
      setQuizQuestions([...quizQuestions, res.data])
      setCreatedQuestion(res.data)
      setQuestionCreated(true)
      setEditLoader(false)
      handleQuestionToggle(res.data.id, res.data.question_type, false)
      setTotalQuizQuestions((prev) => ++prev)
    } else {
      setEditLoader(false)
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function deleteQuestion(question_id) {
    setEditLoader(true)
    let res = await deleteRequest(`/quiz-question/${question_id}/`)
    if (res.success) {
      setDmodal(false)
      setItemToDelete("")
      let questions_data = [...quizQuestions]
      let deleted_question_index = questions_data.findIndex((element) => element.id === question_id)
      questions_data.splice(deleted_question_index, 1)
      setQuizQuestions(questions_data)
      setTotalQuizQuestions((prev) => --prev)
      setEditLoader(false)
    } else {
      setEditLoader(false)
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function deleteOption(option_id, question_id, index) {
    setEditLoader(true)
    let res = await deleteRequest(`/question-option/${option_id}/`)
    if (res.success) {
      setDmodal(false)
      setItemToDelete("")
      let options_data = { ...questionOptions },
        quiz_question_data = [...quizQuestions]
      options_data["question_" + question_id].splice(index, 1)
      setQuestionOptions(options_data)
      setEditLoader(false)
      handleQuestionIsValid(question_id, options_data, quiz_question_data)
    } else {
      setEditLoader(false)
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function createOption(question_id, second = false) {
    setOptionCreated(false)
    setEditLoader(true)

    let option_name = `sample option console`
    let formData = new FormData()
    formData.append("quiz_question", question_id)
    formData.append("name", option_name)
    let res = await postRequest("/question-option/", formData)
    if (res.success) {
      setQuestionOptions((prev) => {
        let options_data = { ...prev },
          index = "question_" + question_id
        if (!options_data[index]) {
          options_data[index] = []
        }
        let data = { ...res.data }
        data.autoFocusId = res.data.id
        options_data[index] = [...options_data[index], data]
        return options_data
      })
      setOptionCreated(true)
      setEditLoader(false)
    } else {
      setEditLoader(false)
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  function sendQuery(name, value, item_id, type, questionsData, optionsData, index, question_id) {
    if (type === "question") {
      updateQuestion(name, value, item_id, questionsData, optionsData, index)
    } else {
      updateOption(name, value, item_id, questionsData, optionsData, index, question_id)
    }
  }

  const delayedQuery = useCallback(
    debounce(
      (name, value, item_id, type, questionsData, optionsData, index, question_id) =>
        sendQuery(name, value, item_id, type, questionsData, optionsData, index, question_id),
      1000
    ),
    []
  )

  function handleUpdateQuestion(index, name = null, value = null, question_id, on_blur = null) {
    if (name === "title") {
      if (on_blur === null) {
        delayedQuery(name, value, question_id, "question", quizQuestions, questionOptions, index)
      } else {
        updateQuestion(name, value, question_id, quizQuestions, questionOptions, index)
      }
    } else {
      updateQuestion(name, value, question_id, quizQuestions, questionOptions, index)
    }
    if (name === "question_type" && value === "1") {
      fetchOptions(question_id, true)
    }
  }

  async function updateQuestion(name = null, value = null, question_id, questionsData, optionsData, index = null) {
    if (name === "title") {
      value = value.trim()
      if (value === "") value = "sample question console"
    }
    setEditLoader(true)

    let formData = new FormData()
    formData.append(name, value)
    if (question_id !== undefined) {
      let res = await putRequest(`/quiz-question/${question_id}/`, formData)
      if (res.success) {
        if (questionsData && index >= 0) {
          let questions_data = [...questionsData],
            isValid = questions_data[index].is_valid_question
          questions_data[index][name] = value
          if (["title", "question_type"].includes(name)) {
            let data = optionsData["question_" + question_id]
            if (data !== undefined && questions_data[index].question_type === "1") {
              let rc = handleQuestionIsValid(question_id, optionsData, questions_data, 0)
              if (rc === -1) {
                setQuizQuestions(questions_data)
                return
              }
              isValid = rc
            } else {
              if (value !== "" && value !== "sample question console" && value !== "0") {
                isValid = true
              } else {
                isValid = false
              }
            }
            questions_data[index]["is_valid_question"] = isValid
          }
          setQuizQuestions(questions_data)
        }
        setEditLoader(false)
      } else {
        setEditLoader(false)
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  function handleQuestionIsValid(question_id, options_data, questions_data, updateQuestion = 1, filtered_data = -1) {
    let data = options_data["question_" + question_id]
    if (filtered_data < 0) {
      filtered_data = questions_data.findIndex((ques) => ques.id === question_id)
    }
    if (!data || filtered_data < 0) return -1

    let correct_option_count = data.filter((option) => option.is_correct === true && option.name !== "" && option.name !== "sample option console")
    let optionIsNotEmpty = data.filter((option) => option.name !== "" && option.name !== "sample option console")
    let question = questions_data[filtered_data]
    let isValid = false
    if (
      (options_data["question_" + question_id].length >= 2 &&
        correct_option_count.length >= 1 &&
        data.length !== correct_option_count.length &&
        optionIsNotEmpty.length >= 2 &&
        question.title &&
        question.title !== "" &&
        question.title !== "sample question console") ||
      question.question_type === "2"
    ) {
      isValid = true
    }

    if (updateQuestion === 1) {
      let dummy = [...questions_data]
      dummy[filtered_data].is_valid_question = isValid
      setQuizQuestions(dummy)
    }

    return isValid
  }

  function handleUpdateOption(index, name, value, option_id, question_id, blur_type = null) {
    let options_data = { ...questionOptions }
    if (name !== "name") {
      let option = options_data["question_" + question_id][index]
      option[name] = value
      setQuestionOptions(options_data)
    }

    if (name === "name") {
      if (blur_type === null) {
        delayedQuery(name, value, option_id, "option", quizQuestions, options_data, index, question_id)
      } else {
        updateOption(name, value, option_id, quizQuestions, options_data, index, question_id)
      }
    } else {
      updateOption(name, value, option_id, quizQuestions, options_data, index, question_id)
    }
  }

  function checkOptionCount(index, datum, question_id) {
    let all_option = questionOptions["question_" + question_id]
    let correct_option_count = all_option.filter((option_update) => option_update.is_correct === true)
    if (all_option.length - 1 === correct_option_count.length && !datum.is_correct === true) {
      let error_messages = "Total options and correct answer options cannot be same"
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error_messages } })
    } else {
      setEditLoader(true)
      handleUpdateOption(index, "is_correct", !datum.is_correct, datum.id, question_id)
    }
  }

  async function updateOption(name, value, option_id, questionsData, optionsData, index = null, question_id = null) {
    setEditLoader(true)
    if (name === "name") {
      value = value.trim()
      if (value === "") value = "sample option console"
    }

    let formData = new FormData()
    formData.append(name, value)
    if (option_id !== undefined) {
      let res = await putRequest("/question-option/" + option_id + "/", formData)
      if (res.success) {
        if (optionsData && questionsData && index >= 0 && question_id) {
          setQuestionOptions((prev) => {
            let options_data = { ...prev },
              index2 = "question_" + question_id
            if (!options_data[index2]) {
              options_data[index2] = []
            }
            let questions_data = [...questionsData]
            let option = options_data[index2][index]
            option[name] = value
            if (name === "is_correct" || name === "name") {
              handleQuestionIsValid(question_id, options_data, questions_data)
            }
            return options_data
          })
          setEditLoader(false)
        }
      } else {
        setEditLoader(false)
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  function handleQuestionToggle(question_id, type, fetch_options = true) {
    if (type === "1" && fetch_options) {
      fetchOptions(question_id)
    }
    let toggle_data = { ...questionToggle }
    toggle_data[question_id] = !toggle_data[question_id]
    setQuestionToggle(toggle_data)
  }

  function showQuizQuestions() {
    if (quizQuestionsFetched) {
      return (
        <Droppable droppableId={`quiz`} type={`questionsDraggable`}>
          {(provided, snapshot) => (
            <div className={`col-12`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {quizQuestions.map((datum, index) => {
                let question_error_class = datum.is_valid_question
                let toggle_class = questionToggle[datum.id]
                return (
                  <Draggable key={datum.id + `-question-key-drag-` + index} draggableId={datum.id + `-question-key-drag-` + index} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`tab-ignore`}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div
                          key={datum.id + `-question-key-` + index}
                          className={`br-8px ${StylesA.question_div} ${question_error_class ? "border_on_hover" : StylesA.question_error_div} mb-2`}
                        >
                          <div className={`${StylesA.question_name_div} w-100 ${toggle_class ? `border-bottom-0` : ``} align-items-center`}>
                            <div
                              className={`${StylesA.drag_handle_div} px-2 py-1 align-items-center`}
                              onClick={(e) => handleQuestionToggle(datum.id, datum.question_type)}
                            >
                              <img src={DragHandleIcon} alt={`question-drag-handle_${datum.id.toString()}`} className={`my-auto`} />
                              <p className={`mb-0 pl-2 fs-20px white-text`}>{index + 1}</p>
                            </div>
                            <input
                              type="text"
                              name="title"
                              id={datum.id + `-question-input-id`}
                              defaultValue={datum ? (datum.title === "sample question console" ? `` : datum.title) : ""}
                              placeholder={"Enter Question Title"}
                              onChange={(e) => {
                                handleUpdateQuestion(index, e.target.name, e.target.value, datum.id)
                              }}
                              onBlur={(e) => {
                                e.preventDefault()
                                if (datum.title !== e.target.value) {
                                  handleUpdateQuestion(index, e.target.name, e.target.value, datum.id, "on_blur")
                                }
                              }}
                              className={`${StylesA.question_title_input} form-control px-3`}
                              required
                            />
                            <div className="d-flex align-items-center">
                              <img
                                onClick={(e) => handleQuestionToggle(datum.id, datum.question_type)}
                                src={toggle_class ? ChevronCircleUp : ChevronCircleDown}
                                alt={"arrow for collapse and expand"}
                                className={`${StylesA.arrow_circle} mr-2`}
                              />
                              <img
                                src={DeleteIcon}
                                onClick={(e) => {
                                  setItemToDelete({ type: "question", datum: datum })
                                  setDmodal(true)
                                }}
                                alt={`question delete icon`}
                                className={`my-auto mr-3 pointer object-fit-contain h-14px`}
                              />
                            </div>
                          </div>
                          {toggle_class ? (
                            <div className={`${StylesA.options_section} py-3 pl-4 pr-3`}>
                              {datum.question_type === 2 || datum.question_type === "2" ? (
                                <u
                                  className="pointer fs-14px white-text"
                                  onClick={() => {
                                    setShowRichTextModal(true)
                                    setActiveSubjectiveQuetion(datum)
                                  }}
                                >
                                  {datum.description.length === 0 || datum.description === "<p><br></p>" ? `Add Subjective Text` : `Edit Subjective Text`}
                                </u>
                              ) : (
                                showOptionButtons(questionOptions["question_" + datum.id], datum)
                              )}
                              <div className={`${StylesA.question_footer} mt-3`}>
                                <div className="d-flex align-items-center">
                                  {datum.question_type === 2 || datum.question_type === "2" ? null : (
                                    <React.Fragment>
                                      <button
                                        disabled={!optionCreated}
                                        onClick={(e) => {
                                          createOption(datum.id)
                                        }}
                                        className={`${StylesB.add_button} border_on_hover mr-2`}
                                      >
                                        {optionCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                                        Add Option
                                      </button>
                                      <button
                                        onClick={() => {
                                          setActiveQuestion({ question: datum, index: index })
                                          setShowAddDescriptionModal(true)
                                        }}
                                        className={`${StylesB.add_button} border_on_hover mr-2 pointer`}
                                      >
                                        {datum.explanation ? "Edit Explanation" : "Add explanation"}
                                      </button>
                                    </React.Fragment>
                                  )}
                                  <Dropdown className={`breakout-groups mr-2`}>
                                    <Dropdown.Toggle
                                      className={`border-none d-inline-flex align-items-center px-2 shadow-none br-23px p-1 bg-586985`}
                                      id="dropdown-basic"
                                    >
                                      <div className={`mr-1 fs-12px`}>{questionLevelType[datum.question_level]}</div>
                                      <Image src={AngleDown} className={`w-9px h-9px`} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width} fs-12px white-text`}>
                                      <Dropdown.Item
                                        className="white-text"
                                        onClick={(e) => {
                                          setEditLoader(true)
                                          handleUpdateQuestion(index, "question_level", "0", datum.id)
                                        }}
                                      >
                                        Easy
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="white-text"
                                        onClick={(e) => {
                                          setEditLoader(true)
                                          handleUpdateQuestion(index, "question_level", "1", datum.id)
                                        }}
                                      >
                                        Medium
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="white-text"
                                        onClick={(e) => {
                                          setEditLoader(true)
                                          handleUpdateQuestion(index, "question_level", "2", datum.id)
                                        }}
                                      >
                                        Hard
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  <Dropdown className={`breakout-groups mr-0`}>
                                    <Dropdown.Toggle
                                      className={`border-none d-inline-flex align-items-center px-2 shadow-none br-23px p-1 bg-586985`}
                                      id="dropdown-basic"
                                    >
                                      <div className={`mr-1 fs-12px`}>{question_type_data[datum.question_type]}</div>
                                      <Image src={AngleDown} className={`w-9px h-9px`} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width} fs-12px white-text`}>
                                      <Dropdown.Item
                                        className="white-text"
                                        onClick={(e) => {
                                          if (datum.question_type !== "1") {
                                            setEditLoader(true)
                                            handleUpdateQuestion(index, "question_type", "1", datum.id)
                                          }
                                        }}
                                      >
                                        Objective
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="white-text"
                                        onClick={(e) => {
                                          if (datum.question_type !== "2") {
                                            setEditLoader(true)
                                            handleUpdateQuestion(index, "question_type", "2", datum.id)
                                          }
                                        }}
                                      >
                                        Subjective
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                <div className={`${StylesA.points_input_div}`}>
                                  <p>Points:</p>
                                  <input
                                    type="number"
                                    min={1}
                                    name="score"
                                    defaultValue={parseInt(datum.score)}
                                    placeholder={"0"}
                                    onChange={(e) => {
                                      setEditLoader(true)
                                      handleUpdateQuestion(index, e.target.name, e.target.value, datum.id)
                                    }}
                                    className={`${StylesA.question_point_input} form-control pl-2 pr-0 py-0`}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ``
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
              {nextQuizQuestions ? (
                <Col md="auto" className={`d-inline-flex w-100 my-2`}>
                  <Button
                    variant="none"
                    onClick={(e) => {
                      fetchNextQuizQuestions(nextQuizQuestions)
                    }}
                    className={`${StylesB.add_button} mx-auto`}
                  >
                    See More
                  </Button>
                </Col>
              ) : (
                ""
              )}
              <div className={`justify-content-center py-4 d-flex bg-transparent`} style={{ borderRadius: "9px" }}>
                <div className={`pl-3 text-center d-inline-flex align-items-center`}>
                  <p className={`${Styles.font_14} m-0`} style={{ color: `#D4DCF7` }}>
                    Drag and drop questions here{" "}
                    <span className={`font-weight-bold mx-3`} style={{ fontSize: `16px` }}>
                      or
                    </span>{" "}
                  </p>
                  <button
                    disabled={!questionCreated}
                    onClick={() => {
                      handleValidateNoOfQuestions()
                    }}
                    className={`${StylesB.add_button} border_on_hover fs-14px`}
                  >
                    {questionCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                    Add question
                  </button>
                </div>
              </div>
            </div>
          )}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function handleValidateNoOfQuestions() {
    if (totalQuizQuestions < quiz.no_of_questions) {
      createQuestion("1")
    } else if (totalQuizQuestions >= quiz.no_of_questions) {
      setShowQuestionsExceededModal(true)
    }
  }

  function showOptionButtons(options, question) {
    let question_id = question.id
    if (options) {
      if (options.length > 0) {
        return (
          <Droppable droppableId={`options`} type={`optionsDraggable_` + question_id}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {options.map((datum, index) => {
                  let datum_id = datum.id.toString()
                  return (
                    <Draggable
                      key={`${datum_id}-option-key-` + index + `-${question_id}`}
                      draggableId={`${datum_id}-option-${index.toString() + `-${question_id.toString()}`}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Col
                          lg="12"
                          md="12"
                          sm="12"
                          xs="12"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          key={`${datum_id}-option-key1-` + index + `-${question_id}`}
                          className="px-0 d-flex mb-2 tab-ignore align-items-center"
                        >
                          <div className={`${StylesA.option_div} border_on_hover`}>
                            <div className={`${StylesA.drag_handle_div} px-2 py-1`}>
                              <img src={DragHandleIcon} alt={`option-drag-handle_${datum_id}`} className={`my-auto`} />
                            </div>
                            <input
                              type="text"
                              name="name"
                              id={datum.id + `-question-option-input-id`}
                              defaultValue={datum.name === "sample option console" ? `` : datum.name}
                              autoFocus={datum?.autoFocusId === datum.id}
                              onChange={(e) => {
                                handleUpdateOption(index, e.target.name, e.target.value, datum.id, question_id)
                              }}
                              onBlur={(e) => {
                                e.preventDefault()
                                if (e.target.value !== datum.name) {
                                  handleUpdateOption(index, e.target.name, e.target.value, datum.id, question_id, "on_blur")
                                }
                              }}
                              placeholder={"Enter Option text"}
                              className={`${StylesA.question_title_input} form-control px-3`}
                              required
                            />
                            <div
                              className={`${datum.is_correct ? StylesA.correct_answer_check : StylesA.wrong_answer_check} pointer`}
                              onClick={(e) => {
                                checkOptionCount(index, datum, question_id)
                              }}
                            >
                              <img src={CheckIcon} alt={`check_${datum_id}`} className={`m-auto px-2`} />
                            </div>
                          </div>
                          <div
                            className={`${StylesA.question_delete_div} pointer ml-3`}
                            onClick={(e) => {
                              setItemToDelete({ type: "option", datum: datum, index: index })
                              setDmodal(true)
                            }}
                          >
                            <img alt={`delete_option_${datum_id}`} src={DeleteIcon} className="mb-0 w-15px h-15px" />
                          </div>
                        </Col>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <p style={{ fontSize: "13px", margin: "0px", color: "#E0E2E5" }}>No options created yet!</p>
          </Col>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function handleDelete() {
    if (itemToDelete) {
      if (itemToDelete.type === "question") {
        deleteQuestion(itemToDelete.datum.id)
      } else {
        deleteOption(itemToDelete.datum.id, itemToDelete.datum.quiz_question, itemToDelete.index)
      }
    }
  }

  const getRichTextData = () => {
    let index = quizQuestions.findIndex((res) => res.id === activeSubjectiveQuetion.id)
    return (
      <SunEditor
        setContents={quizQuestions[index].description}
        onChange={(text) =>
          setQuizQuestions((prev) => {
            let prevData = prev
            prevData[index].description = text
            return prevData
          })
        }
        id="description"
        placeholder="Add description"
        setOptions={{
          height: "auto",
          maxHeight: "400px",
          minHeight: "400px",
          buttonList: [
            ["undo", "redo", "font", "fontSize", "formatBlock"],
            ["bold", "underline", "italic", "link", "image", "codeView", "outdent", "indent", "align", "horizontalRule", "list", "table"],
          ],
        }}
      />
    )
  }

  const updateInfo = () => {
    const index = quizQuestions.findIndex((res) => res.id === activeSubjectiveQuetion.id)
    updateQuestion("description", quizQuestions[index].description, activeSubjectiveQuetion.id)
  }

  const displayData = () => {
    return (
      <div
        id="mod"
        className={`box text-dark p-0 w-100 window_screen`}
        onScroll={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        style={{
          margin: 0,
          height: "100%",
          backgroundColor: "#212C42",
          border: "1px solid #E0E2E5",
          borderRadius: "11px",
          boxShadow: "0px 3px 12px #00000099",
          overflowY: "hidden",
        }}
      >
        <div className={`w-100 py-2 drag d-inline-flex align-items-center`} style={{ borderBottom: "1px solid #E0E2E5", cursor: "move" }}>
          <p className={`mb-0 text-center pl-2 w-100`} style={{ fontSize: "18px", fontWeight: "500", color: "#E0E2E5" }}>
            Add Description Below
          </p>
          <img
            src={CancelIcon}
            className="mb-0 pointer ml-auto mr-2"
            style={{ fontSize: "16px", width: "16px" }}
            onClick={() => {
              setShowRichTextModal(false)
              updateInfo()
            }}
          />
        </div>
        {getRichTextData()}
      </div>
    )
  }

  function showDescriptionInputModal() {
    if (activeQuestion) {
      return (
        <Modal
          show={showAddDescriptionmodal}
          size="lg"
          onHide={() => {
            setShowAddDescriptionModal(false)
            setActiveQuestion("")
          }}
          centered
          className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
        >
          <Modal.Body className="text-center p-0" style={{ backgroundColor: "#212C42", borderRadius: "8px", border: "1px solid #707070", color: "#E0E2E5" }}>
            <Col className={`w-100 align-items-center d-inline-flex`}>
              <div className={`w-100 py-2`}>
                <div className={`w-100 mb-2 d-inline-flex align-items-center`}>
                  <p className={`mb-0`} style={{ color: "#E0E2E5", fontSize: "16px", fontWeight: "500" }}>
                    Add Explanation
                  </p>
                  <img
                    onClick={() => {
                      setShowAddDescriptionModal(false)
                      setActiveQuestion("")
                    }}
                    src={CancelWhite}
                    alt={`Cancel`}
                    className={`ml-auto mb-0 pointer`}
                    style={{ objectFit: `contain`, height: `13px` }}
                  />
                </div>
                <div className={`${StylesB.description_input} px-0 border_on_hover`} style={{ borderRadius: "9px" }}>
                  <InputGroup className="py-0 d-block" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                    <FormControl
                      className={`${StylesB.description_input_field} border-0 d-flex w-100 justify-content-center shadow-none text-white`}
                      style={{ borderRadius: "0px", fontSize: "16px" }}
                      as="textarea"
                      rows="7"
                      type="text"
                      name="explanation"
                      value={activeQuestion.question.explanation}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onChange={(e) => {
                        setEditLoader(true)
                        handleUpdateQuestion(activeQuestion.index, e.target.name, e.target.value, activeQuestion.question.id)
                      }}
                      placeholder=""
                    />
                    {/*{editLoader ?
                    <svg className="animate-spin my-auto" style={{ height: `16px`, width: `16px`,position:'absolute',bottom:'5px',right:'5px',zIndex:3 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg> : null}*/}
                  </InputGroup>
                </div>
              </div>
            </Col>
          </Modal.Body>
        </Modal>
      )
    } else {
      return null
    }
  }
  function goToPublish() {
    let check_error = quizQuestions.filter((ques) => ques.is_valid_question === false)

    if (check_error.length > 0) {
      let questions_data = [...quizQuestions]
      for (const key in check_error) {
        let single_question = check_error[key]
        let filtered_data = questions_data.findIndex((ques) => ques.id === single_question.id)
        let rc = handleQuestionIsValid(single_question.id, questionOptions, questions_data, 0, filtered_data)
        if (rc !== -1) {
          questions_data[filtered_data].is_valid_question = rc
        }
      }
      setQuizQuestions(questions_data)
      check_error = questions_data.filter((ques) => ques.is_valid_question === false)
    }

    if (quiz.no_of_questions === totalQuizQuestions && check_error.length === 0) {
      navigate(`/app/quizzes/${quiz.id}/publish`)
    } else {
      let error_messages = "Total questions and added questions are not equal"
      if (check_error.length > 0) {
        error_messages = "Your questions are not valid"
      }
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error_messages } })
    }
  }

  function handleAddingQuestion() {
    let params = ["no_of_questions", quiz.no_of_questions + 1, createQuestion, "1"]
    if (draggedQuestionData) {
      params = ["no_of_questions", quiz.no_of_questions + 1]
    }
    setShowQuestionsExceededModal(false)
    setQuestionCreated(false)
    updateQuizDetails(...params)
  }
  useEffect(() => {
    if (draggedQuestionData) {
      onDragEnd(draggedQuestionData)
    }
  }, [quiz.no_of_questions])

  return (
    <div className={`position-absolute ${StylesA.questions_list_top_div}`}>
      <SEO title="Quiz Questions" />
      <DeleteModal show={dmodal} onHide={() => setDmodal(false)} itemName={itemToDelete?.type} action={() => handleDelete()} />
      <Modal
        show={showQuestionsExceededModal}
        onHide={() => setShowQuestionsExceededModal(false)}
        centered
        className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
      >
        <div className={`${StylesB.delete_modal_header} align-items-center`}>
          <img
            onClick={() => setShowQuestionsExceededModal(false)}
            src={CancelWhite}
            alt={`Cancel`}
            className={`${StylesB.cancel_icon} my-auto mr-1 pointer`}
          />
          <Image className={`mb-0 w-24px`} src={ExclamationTriangleWhite} />
          <div className={`pl-3`}>
            <p className="font-weight-bold fs-15px">Alert</p>
            <p>You gave only {quiz.no_of_questions} questions for this quiz !</p>
          </div>
        </div>
        <Modal.Body className={`${StylesB.delete_modal_body}`}>
          <p> Are you sure you want to add one more Question ? </p>
          <div className="d-flex justify-content-center">
            <Button
              className={`${StylesB.add_button_large} border_on_hover mr-3`}
              variant="secondary"
              style={{ width: "75px" }}
              onClick={() => {
                handleAddingQuestion()
              }}
            >
              Yes
            </Button>
            <Button
              className={`${StylesB.add_button_large} border_on_hover`}
              variant="secondary"
              onClick={() => {
                setShowQuestionsExceededModal(false)
              }}
              style={{ width: "75px" }}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <div id="error-div-for-option" className="position-absolute text-white" style={{ top: "0px", right: "0px", zIndex: "2" }}></div>
      {showDescriptionInputModal()}
      <DragDropContext onDragEnd={onDragEnd}>
        <Col xs="12" sm="12" md="12" lg="12" className="px-0 d-flex" style={{ marginLeft: "-30px" }}>
          <Col xs={collapse ? "1" : "3"} sm={collapse ? "1" : "3"} md={collapse ? "1" : "3"} lg={collapse ? "1" : "3"} className={`d-flex px-0 ml-4`}>
            <div
              className={`${collapse ? `${Styles.publish_side_panel_collapsed}` : `${Styles.publish_side_panel_opened}`}`}
              id={`mySidepanel`}
              style={{ backgroundColor: `#212C42` }}
            >
              <div className="p-3">
                <div
                  className={`col-12  align-items-center`}
                  style={{
                    backgroundColor: `#303C54`,
                    borderRadius: `24px`,
                    boxShadow: "0px 3px 6px #00000029",
                    display: `${collapse ? "none" : "inline-flex"}`,
                  }}
                >
                  <img className="my-auto pointer mb-0" style={{ width: 20, height: 20 }} alt="search details" src={search} />
                  <FormControl
                    autoFocus
                    className="border-0 py-0 px-2 form-control shadow-none font-14"
                    style={{ backgroundColor: `#303C54`, color: `#E0E2E5`, fontSize: "16px", height: "32px" }}
                    placeholder={`Search Questions`}
                    onChange={(e) => {
                      e.preventDefault()
                      setSearchValue(e.target.value)
                    }}
                    value={searchValue}
                  />
                </div>
              </div>
              {collapse ? "" : allQuestionsFetched ? showQuestions() : ``}
              {collapse ? (
                ""
              ) : (
                <div
                  onClick={() => navigate(`/app/questionbank/`)}
                  className={`d-flex pointer align-items-center text-center border_on_hover fs-14px my-2`}
                  style={{
                    color: `#E0E2E5`,
                    position: `relative`,
                    background: "#586886",
                    height: "28px",
                    margin: "auto",
                    width: "fit-content",
                    padding: `15px 30px`,
                    borderRadius: "23px",
                    boxShadow: "0px 3px 6px #00000029",
                  }}
                >
                  <Image className="pointer" style={{ width: 20, height: 20 }} alt="search details" src={search} />
                  {collapse ? (
                    ``
                  ) : (
                    <span className={`pl-1`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                      Upload Questions
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className={`d-inline-flex pointer`} onClick={() => openNav()}>
              <span className={`my-auto px-1 pointer fs-16px bg-2e3546 br-0880px w-24px h-24px`}>{collapse ? <>&#10095;</> : <>&#10094;</>}</span>
            </div>
          </Col>
          <Col xs={collapse ? "10" : "9"} sm={collapse ? "10" : "9"} md={collapse ? "10" : "9"} lg={collapse ? "10" : "9"} className="mt-5">
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              onMouseOver={(e) => {
                e.stopPropagation()
                e.preventDefault()
                let ele = document.getElementById("sections")
                if (ele !== null && ele !== undefined) {
                  if (ele.scrollWidth > ele.clientWidth) {
                    setHovered(true)
                  }
                }
              }}
              className={`${StylesB.course_creation_header} d-flex px-0 mt-2`}
            >
              <p
                className="mb-0 py-2 px-3 font-weight-bold"
                id="coursename"
                style={{ borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "18px", lineHeight: "1", minWidth: "max-content" }}
              >
                {quiz ? quiz.name : ""}
              </p>
              <div className="mr-auto">
                <HorizontalScroll className={`d-inline-flex w-100`} scrollId={"top-scroll-id"}>
                  <div id="sections" className="d-flex overflow-x-scroll">
                    {quiz && quiz?.id > 0 ? (
                      <Link to={`/app/quizzes/edit/${quiz?.id}`} className={`pointer ${StylesB.backgroundonhover} white-text`}>
                        <p className={`mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2 fs-16px ${StylesB.backgroundonhover} min-w-max-content`} id="quiz_detail">
                          Quiz Details
                        </p>
                      </Link>
                    ) : (
                      ""
                    )}
                    <p
                      className={`mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2 fs-16px ${StylesB.backgroundonhover} ${StylesB.course_nav_item_active} min-w-max-content`}
                      id="add_question"
                    >
                      Add Questions
                    </p>
                    <div
                      onClick={() => {
                        goToPublish()
                      }}
                      className={`pointer ${StylesB.backgroundonhover} white-text`}
                    >
                      <p
                        className={`mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2 fs-16px ${StylesB.backgroundonhover} min-w-max-content mr-auto`}
                        id="save_and_publish"
                      >
                        Publish
                      </p>
                    </div>
                  </div>
                </HorizontalScroll>
              </div>
              <Image
                src={CancelWhite}
                alt={`close`}
                width={20}
                height={20}
                onClick={() => {
                  navigate("/app/dashboard")
                }}
                className={`pointer mr-3 ml-auto float-right align-self-center`}
              />
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              onMouseOver={() => {
                setHovered(false)
              }}
              className="px-0"
            >
              <Col lg="12" md="12" sm="12" xs="12" className={`px-0`}>
                {/*<div id={`scrollable-div`} className={`px-0 float-left position-fixed ${collapse ? `${Styles.new_scrollable_div_collapsed}` : `${Styles.scrollable_div_opened}`}`} style={{ height: `calc(100vh - 70px)`, overflowY: `scroll` }} >*/}
                <div
                  id="quiz-questions-div"
                  className="w-100"
                  style={{
                    maxHeight: "calc(100vh - 190px)",
                    overflowY: "scroll",
                    backgroundColor: "#212C42",
                    borderRadius: "9px 9px 0px 0px",
                    boxShadow: "0px 3px 12px #00000099",
                    scrollBehavior: "smooth",
                  }}
                >
                  <div className={`d-inline-flex align-items-center mt-2 w-100 mb-2`} style={{}}>
                    <p
                      className={`mb-0 px-2 py-1 ml-auto mr-2`}
                      style={{ color: "#E0E2E570", backgroundColor: "#303C54", borderRadius: "4px", boxShadow: "0px 3px 6px #00000029", fontSize: "16px" }}
                    >
                      No of questions:
                      <span className={`pl-2`} style={{ color: "#E0E2E5" }}>
                        {quiz.no_of_questions}
                      </span>
                    </p>
                  </div>
                  {showQuizQuestions()}
                </div>
                <Col
                  className="px-3 py-2 d-flex align-items-center justify-content-between"
                  style={{ borderTop: "1px solid #63666f", backgroundColor: "#212C42", borderRadius: "0px 0px 9px 9px", boxShadow: "0px -3px 12px #00000099" }}
                >
                  <button
                    onClick={() => {
                      goToPublish()
                    }}
                    className="border_on_hover fs-14px"
                    style={{
                      backgroundColor: "#586886",
                      border: "1px solid #586886",
                      height: "28px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "23px",
                      color: "#E0E2E5",
                    }}
                  >
                    Next
                  </button>
                  <div className="mb-0 fs-14px">
                    {editLoader ? (
                      <span className={`bg-transparent border-0`} id={`basic-addon`}>
                        <Spinner animation="border" variant="secondary" size="sm" className="mr-1" />
                      </span>
                    ) : (
                      <img src={CheckIcon} alt={`changes_saved_check`} className={`ml-auto mb-0 mr-1 object-fit-contain h-12px`} />
                    )}
                    All changes are saved
                  </div>
                </Col>
              </Col>
            </Col>
          </Col>
        </Col>
      </DragDropContext>
      {!showRichTextModal ? null : (
        <div
          className={`w-100 parent h-100 position-fixed`}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setShowRichTextModal(false)
            updateInfo()
          }}
          style={{ inset: "0px", top: "0", bottom: "0", left: "0", right: "0", backgroundColor: "#000000b5" }}
        >
          {isBrowser() && document.getElementsByClassName("parent").length > 0 ? (
            <Rnd
              disableDragging={false}
              dragHandleClassName={"drag"}
              minWidth={324}
              minHeight={191}
              style={{ zIndex: 100 }}
              onClick={(e) => {
                e.stopPropagation()
              }}
              bounds=".parent"
              default={{ x: `50%`, y: `50%`, width: 324, height: 191 }}
              position={{ x: draggableHash.x, y: draggableHash.y }}
              size={{ width: draggableHash.width, height: draggableHash.height }}
              onDragStop={(e, d) => {
                setDraggableHash({ x: d.x, y: d.y, width: draggableHash.width, height: draggableHash.height })
              }}
              onResizeStop={(e, direction, ref, delta, position) => {
                setDraggableHash({ width: ref.style.width, height: ref.style.height, x: position.x, y: position.y })
              }}
            >
              {displayData()}
            </Rnd>
          ) : (
            ``
          )}
        </div>
      )}
    </div>
  )
}

export default ViewQuizQuestions
