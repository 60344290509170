import { faChevronCircleDown, faChevronCircleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Col } from "react-bootstrap"
import RoleDropdown from "../custom/RoleDropdown"

const Staff = (props) => {
  const { teamsData, training, setTraining, teamsErrMsg } = props
  const [selectStaffCollapsed, setSelectStaffCollapsed] = useState(true)

  return (
    <Col
      lg="12"
      md="12"
      sm="12"
      xs="12"
      className={`pointer text-left bg-303C54 position-relative 303C54 ${selectStaffCollapsed ? `border_on_hover` : `border-white`} w-100 br-9px px-0`}
    >
      <div className="d-flex flex-column">
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          onClick={() => {
            setSelectStaffCollapsed(!selectStaffCollapsed)
          }}
          className={`pointer align-items-center d-inline-flex mt-2  w-100 ${!selectStaffCollapsed ? "border-bottom-63666f" : "border-bottom-transparent"}`}
        >
          <div className="float-left align-self-center w-100">
            <p className={`d-inline-flex  fs-16px pr-14px ${selectStaffCollapsed ? "icon-color mb-0" : "color-C3C7CE mb-2"}`}>Select Roles and Staff:</p>
          </div>
          <div className={`float-right align-self-center text-right`}>
            <FontAwesomeIcon
              onClick={() => {
                {
                  !teamsErrMsg && setSelectStaffCollapsed(!selectStaffCollapsed)
                }
              }}
              className="float-right pointer w-20px h-20px E0E2E5"
              icon={selectStaffCollapsed ? faChevronCircleDown : faChevronCircleUp}
            />
          </div>
        </Col>
        {!teamsErrMsg ? (
          <div className="float-left align-self-center w-100">
            <Col lg="12" md="12" sm="12" xs="12" className={`d-inline-flex flex-wrap mt-2 `}>
              {teamsData.data?.length &&
                teamsData.data
                  .filter((team) => {
                    if (team.name !== "Educators" && team.name !== "Super Admin") {
                      return team
                    }
                  })
                  .map((team) => {
                    return (
                      <RoleDropdown
                        roleData={team}
                        setSelectStaffCollapsed={setSelectStaffCollapsed}
                        selectStaffCollapsed={selectStaffCollapsed}
                        training={training}
                        setTraining={setTraining}
                        setTrainingDataUpdated={props?.setTrainingDataUpdated}
                      />
                    )
                  })}
            </Col>
            {!selectStaffCollapsed && (
              <Col xs="12" style={{}} className="mt-0 mb-2 ml-3 px-0 d-inline-flex float-left align-items-center d-inline-flex">
                <button
                  onClick={() => {
                    setSelectStaffCollapsed(true)
                  }}
                  className="border_on_hover box-shadow-00000029 border border-1 border-586886 bg-586886  float-left px-3 turient-header-text-color fs-14px br-23px "
                >
                  Done
                </button>
              </Col>
            )}
          </div>
        ) : (
          <p className="fs-14px opacity-60 pl-3">You don't have required permission. Please reach out to your Super Admin for the required permission!</p>
        )}
      </div>
    </Col>
  )
}

export default Staff
