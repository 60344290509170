import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import Danger from "../../images/svgs/danger.svg"
import CancelWhite from "../../images/svgs/close.svg"
import Btn from "../custom/Button"
import StylesA from "./matching.module.scss"

const DeleteModal = (props) => {
  const [selectedDontShowItAgain, setSelectedDontShowItAgain] = useState(false)

  return (
    <Modal
      show={props.showDeleteModal}
      onHide={() => {
        props.setShowDeleteModal(false)
      }}
      centered
      size="md"
      className="modal-content-bg-transparent"
    >
      <Modal.Body className={`${StylesA.delete_modal_body}`}>
        <div className="d-flex" style={{ height: "15px" }}>
          <img
            onClick={() => {
              props.setShowDeleteModal(false)
            }}
            src={CancelWhite}
            className={`ml-auto pointer`}
            style={{ objectFit: `contain`, width: `15px`, height: `15px` }}
          ></img>
        </div>
        <div className="d-inline-flex">
          <div style={{ width: "5%" }}>
            <img src={Danger} className={`mt-2`} style={{ objectFit: `contain`, width: `25px`, height: `25px` }}></img>
          </div>
          <div className="pl-3" style={{ width: "95%" }}>
            <p className="mb-0" style={{ fontSize: "21px", color: "#ADB7C9" }}>
              {props.type === "category_warning"
                ? `Are you sure you want to change the category`
                : `Entire ${props.type === "row" ? `row` : `column`} will be deleted !`}
            </p>

            {props.type === "category_warning" ? (
              <p className="text-center pt-1" style={{ fontSize: "15px", color: "#ADB7C9" }}>
                First option from{` `}
                {props.mtfcolumn_set.map((column, index) => (
                  <span className="mr-2" style={{ fontWeight: "500" }}>
                    {column.column}
                    {index < props.mtfcolumn_set.length - 2 ? `,` : index < props.mtfcolumn_set.length - 1 ? ` and` : ``}
                  </span>
                ))}
                will be retained.
              </p>
            ) : (
              <p className="" style={{ fontSize: "17px", color: "#ADB7C9" }}>
                Are you sure you want to delete {props.type === "row" ? `row` : props.activeDeleteColumnData?.column}
              </p>
            )}

            <Btn
              type="medium"
              onClick={(e) => {
                e.preventDefault()
                if (props.type === "row") {
                  props.handleDeleteRow(props.activeDeleteRowData?.id)
                } else if (props.type === "column") {
                  props.handleDeleteColumn(props.activeDeleteColumnData?.id)
                } else {
                  props.handleChangeCategory()
                }
              }}
              className={`px-4 py-1`}
              style={{ fontSize: "18px" }}
            >
              {props.type === "category_warning" ? `Yes, change it` : `Yes, delete it`}
            </Btn>
            <Btn
              onClick={() => {
                props.setShowDeleteModal(false)
              }}
              type="medium"
              className="ml-2 px-4 py-1"
              style={{ fontSize: "18px" }}
            >
              {props.type === "category_warning" ? `No, don't change` : `No, don't delete`}
            </Btn>
            {props.type !== "category_warning" && (
              <div className="my-3 ml-2">
                <input
                  type="checkbox"
                  checked={selectedDontShowItAgain}
                  onChange={(e) => {
                    setSelectedDontShowItAgain(!selectedDontShowItAgain)
                    if (e.target.checked) {
                      if (props.type === "column") {
                        localStorage.setItem("dontShowDeleteColumn", true)
                      } else {
                        localStorage.setItem("dontShowDeleteRow", true)
                      }
                    } else {
                      if (props.type === "column") {
                        localStorage.setItem("dontShowDeleteColumn", false)
                      } else {
                        localStorage.setItem("dontShowDeleteRow", false)
                      }
                    }
                  }}
                ></input>
                <span className=" ml-2" style={{ color: "#E0E2E5", fontSize: "14px" }}>
                  Don’t show this message again
                </span>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default DeleteModal
