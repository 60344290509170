import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const browser = typeof window !== "undefined" && window

const NotFoundPage = () => {
  return (
    browser && (
      <div>
        <SEO title="404: Not found" />
        <h1 className="ml-2">NOT FOUND </h1>
        <p className="ml-2">You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    )
  )
}

export default NotFoundPage
