import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import AvatarCircle from "../../custom/AvatarCircle"
import PercentageProgressBar from "../../custom/PercentageProgressBar"
import GiveABadge from "../../template-resources/classroom-participants-section/giveABadge"
import { roundNumber } from "../../../utils/utility"

const StudentWiseEngagementList = (props) => {
  const { participantsList, setParticipantsList, participant, training_id } = props
  return (
    <div className=" d-flex flex-row justify-content-between">
      <div key={Math.random()} className={`d-inline-flex px-2 py-1 mb-2 align-items-center bg-transparent border  border-4D88D9 w-75  br-9px`}>
        <div className="d-inline-flex align-items-center w-100">
          <div
            className="position-relative pointer"
            //   onClick={() => {
            //     assessmentMuralDispatch(setShowParticipantDetailsModal(true))
            //     props.setParticipantData(value)
            //   }}
          >
            <AvatarCircle name={participant.owner__first_name} size={`30px`} className={`mr-2`} />
          </div>
          {/* <div className={``}> */}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip placement="top" className="classroom-tooltip" id={`"tooltip-"`}>
                {participant.owner__first_name}
              </Tooltip>
            }
          >
            <div className={`d-flex flex-column`}>
              <p
                className={` fs-12px pointer pb-1 mb-0`}
                // onClick={() => {
                //   assessmentMuralDispatch(setShowParticipantDetailsModal(true))
                //   props.setParticipantData(value)
                // }}
              >
                {participant.owner__first_name}
              </p>
            </div>
          </OverlayTrigger>
          {/* </div> */}

          <div className={`d-inline-flex align-items-center ml-auto `}>
            <GiveABadge
              participantsList={participantsList}
              setParticipantsList={setParticipantsList}
              value={participant}
              training_id={training_id}
              location={`class-level-report`}
            />
          </div>
        </div>
      </div>
      <div className="w-20">
        <PercentageProgressBar
          type={`student-list`}
          value={participant?.engagement_score?.engagement_score ? roundNumber(participant?.engagement_score?.engagement_score * 100) : 0}
        />
      </div>
    </div>
  )
}

export default StudentWiseEngagementList
