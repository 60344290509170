import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
// import {SketchField, Tools} from "react-sketch-whiteboard"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import Image from "../../components/image"
import { DragDropContext } from "react-beautiful-dnd"
import ClassRoomLeftNav from "../template-resources/classroom-left-nav"
import LessonBoard from "../class-room-training/lesson-board"
import ParticipantDetail from "../template-resources/participant-detail"
import LiveDiscussionBoard from "../live-discussion-board/live-discussion-board"
import SharedArtifact from "../shared-artifact"
// import hideMessage from '../../services/hidemessage'
import TestBreakoutSession from "../template-resources/breakout-session"
import LessonPlan from "../template-resources/lesson-plan"
import SessionPlan from "../template-resources/session-plan"
import BreakoutGroups from "../template-resources/breakout-groups/breakout-groups"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCircle, faCommentAlt, faChevronLeft, faExpand, faCompressArrowsAlt } from "@fortawesome/free-solid-svg-icons"
// import BlueSearch from '../../images/svgs/blue-search-icon.svg'
import LikeImg from "../../images/svgs/likeImg.svg"
import ChatImg from "../../images/svgs/chatImg.svg"
import dropRightArrow from "../../images/svgs/dropRightArrow.svg"
import BlueVideoSlash from "../../images/svgs/dark-blue-video-slash.svg"
import BlueMicrophone from "../../images/svgs/dark-blue-microphone.svg"
import share from "../../images/share.png"

import CancelWhite from "../../images/svgs/close.svg"
import RectangleMax from "../../images/svgs/rectangleMaximize.svg"
import RectangleMin from "../../images/svgs/RectangleMinimize.svg"
import MuteAll from "../../images/svgs/MuteAll.svg"
import Camera from "../../images/svgs/Camera.svg"
import CameraSlash from "../../images/svgs/CameraSlash.svg"
// import HangUp from '../../images/svgs/HangUp.svg'
import Mic from "../../images/svgs/Mic.svg"
import MicSlash from "../../images/svgs/MicSlash.svg"
import ScreenShare from "../../images/svgs/ScreenShare.svg"
import SpeakerView from "../../images/svgs/SpeakerView.svg"
import TileView from "../../images/svgs/TileView.svg"
import Reload from "../../images/svgs/Reload.svg"

// import { black } from "chalk";

import StylesA from "../../components/template-resources/classroom-home-page.module.scss"
import Style from "./class-room.module.scss"
import { WsConnect } from "../../services/socket"

import TechnicalNetworkError from "../network-technical-error"
import { enableDeveloperMode, isBrowser } from "../../services/developer_mode"
import SessionBreak from "../template-resources/session-break/session-break.js"
import ActivityWarning from "./classroom-components/activity-warning"
import { getRequest } from "../../services/rest_service"

enableDeveloperMode()

const VideoCall = (props) => {
  const [isMeet, setIsMeet] = useState(true)
  const [fullScr, setFullScr] = useState(false)
  const [speaker, setSpeaker] = useState(null)
  const [camH, setCamH] = useState(370)
  const [hideSelf, setHideSelf] = useState(false)

  const [audioIcon, setAudioIcon] = useState(MicSlash)
  const [videoIcon, setVideoIcon] = useState(CameraSlash)
  const [viewIcon, setViewIcon] = useState(SpeakerView)

  const [isCamera, setIsCamera] = useState(false)
  const [isMic, setIsMic] = useState(false)
  const [isTile, setIsTile] = useState(false)
  const [isScreen, setIsScreen] = useState(false)
  const [isCanvas, setIsCanvas] = useState(false)

  const [screenEvt, setScreenEvt] = useState(false)
  const [videoEvt, setVideoEvt] = useState(false)
  const [audioEvt, setAudioEvt] = useState(false)
  const [viewEvt, setViewEvt] = useState(false)

  const jitsiContainerId = "jitsi-container-id"

  const [jitsi, setJitsi] = React.useState({})

  const loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve
    })

    const script = document.createElement("script")
    script.src = "https://meet.jit.si/external_api.js"
    script.async = true
    script.onload = resolveLoadJitsiScriptPromise
    document.body.appendChild(script)

    return loadJitsiScriptPromise
  }

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript()
    }

    const options = {
      roomName: `turient_meeting${props.meetid}`,
      width: "100%",
      height: "100%",
      userInfo: {
        email: localStorage.getItem("username"),
        displayName: localStorage.getItem("student_first_name"),
      },
      configOverwrite: {
        enableWelcomePage: false,
        requireDisplayName: false,
        prejoinPageEnabled: false,
        toolbarButtons: [],
        hideLobbyButton: true,
        hideConferenceSubject: true,
        hideConferenceTimer: true,
        hideParticipantsStats: true,
        startWithAudioMuted: true,
        startWithVideoMuted: true,
        notifications: [],
      },
      interfaceConfigOverwrite: {
        SHOW_CHROME_EXTENSION_BANNER: false,
        TILE_VIEW_MAX_COLUMNS: 3,
        DEFAULT_BACKGROUND: "#303C54",
        MAXIMUM_ZOOMING_COEFFICIENT: 1,
      },
      parentNode: document.getElementById(jitsiContainerId),
    }

    const _jitsi = new window.JitsiMeetExternalAPI("meet.jit.si", options)

    setJitsi(_jitsi)
  }

  React.useEffect(() => {
    initialiseJitsi()

    return () => jitsi?.dispose?.()
  }, [])

  function fullscreenToggle() {
    /*const videoBox = document.getElementById('callBox');
		//fullScr ? document.exitFullscreen() : videoBox.requestFullscreen()
		if(videoBox.webkitRequestFullscreen){
			if(fullScr){
				document.webkitCancelFullScreen()
				videoBox.style.height = '420px'
				videoBox.style.width = '80%'
			}
			else{
				videoBox.webkitRequestFullscreen()
				videoBox.style.height = '100vh'
				videoBox.style.width = '100%'
			}
		}else if(videoBox.mozRequestFullScreen){
			fullScr ? document.mozCancelFullScreen() : videoBox.mozRequestFullScreen()
		}*/

    setFullScr(!fullScr)
  }

  function leaveMeeting() {
    jitsi.dispose()
    jitsi.executeCommand("hangup")
    setIsMeet(false)
  }

  function screenShareToggle() {
    if (!screenEvt) {
      jitsi.addListener("screenSharingStatusChanged", (data) => {
        data.on ? setIsScreen(true) : setIsScreen(false)
      })
      setScreenEvt(true)
    }
    jitsi.executeCommand("toggleShareScreen")
  }

  function videoToggle() {
    if (!videoEvt) {
      jitsi.addListener("videoMuteStatusChanged", (data) => {
        if (data.muted) {
          setIsCamera(false)
          setVideoIcon(CameraSlash)
          //stopVideo()
        } else {
          setIsCamera(true)
          setVideoIcon(Camera)
          //getVideo()
        }
      })
      setVideoEvt(true)
    }
    jitsi.executeCommand("toggleVideo")
  }

  function audioToggle() {
    if (!audioEvt) {
      jitsi.addListener("audioMuteStatusChanged", (data) => {
        if (data.muted) {
          setIsMic(false)
          setAudioIcon(MicSlash)
        } else {
          setIsMic(true)
          setAudioIcon(Mic)
        }
      })
      jitsi.addListener("dominantSpeakerChanged", (data) => {
        let info = jitsi.getParticipantsInfo()
        info = info.find((user) => user.formattedDisplayName.indexOf("(me)") > -1)
        setSpeaker(info)
        if (data.id === info.participantId) {
          setHideSelf(true)
        } else {
          setHideSelf(false)
        }
      })
      setAudioEvt(true)
    }
    jitsi.executeCommand("toggleAudio")
  }

  function muteEveryone() {
    jitsi.executeCommand("muteEveryone")
  }

  function viewToggle() {
    if (!viewEvt) {
      jitsi.addListener("tileViewChanged", (data) => {
        if (data.enabled) {
          setIsTile(true)
          setViewIcon(TileView)
        } else {
          setIsTile(false)
          setViewIcon(SpeakerView)
        }
      })
      setViewEvt(true)
    }
    jitsi.executeCommand("toggleTileView")
  }

  function reloadMeeting() {
    leaveMeeting()
    initialiseJitsi()
    setIsMeet(true)
    setIsCamera(false)
    setVideoIcon(CameraSlash)
    setIsMic(false)
    setAudioIcon(MicSlash)
    setViewEvt(false)
    setViewIcon(SpeakerView)
    setVideoEvt(false)
    setAudioEvt(false)
    setScreenEvt(false)
  }

  const videoRef = useRef(null)

  useEffect(() => {
    getVideo()
    return () => stopVideo()
  }, [videoRef])

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { height: camH } })
      .then((stream) => {
        let video = videoRef.current
        if (video) {
          video.srcObject = stream
          video.play()
        }
      })
      .catch((err) => {
        console.error("error:", err)
      })
  }

  const stopVideo = () => {
    let video = videoRef.current
    video.srcObject = null
  }

  useEffect(() => {
    if (props.actions.muteAllTrigger) {
      muteEveryone()
    }
    props.actions.setMuteAllTrigger(false)
  }, [props.actions.muteAllTrigger])

  return (
    <>
      {isMeet ? (
        <>
          <div id="callBox" className={`d-flex ${props.minScroll ? Style.video_min : fullScr ? Style.jitsi_full : Style.jitsi_normal} ${Style.jitsi_box}`}>
            <div
              className="d-flex br-9px px-2 py-1"
              style={{
                position: "absolute",
                top: "4px",
                right: "4px",
                backgroundColor: "#2f3c54",
                zIndex: "999",
              }}
            >
              {!fullScr && (
                <img
                  className="mr-2 mb-0 pointer"
                  onClick={(e) => {
                    props.minScroll ? document.getElementById("scrollable-div").scrollBy(0, -110) : document.getElementById("scrollable-div").scrollBy(0, 110)
                  }}
                  src={props.minScroll ? RectangleMax : RectangleMin}
                />
              )}
              {!props.minScroll && (
                <FontAwesomeIcon
                  className="pointer"
                  style={{ color: "white" }}
                  onClick={() => {
                    fullscreenToggle()
                  }}
                  icon={fullScr ? faCompressArrowsAlt : faExpand}
                />
              )}
            </div>
            <div className={`col-md-6 ${props.minScroll || hideSelf || fullScr ? "d-none" : ""} align-self-center`}>
              <video style={{ height: "100%" }} ref={videoRef} />
            </div>
            <div className={`${props.minScroll || hideSelf || fullScr ? "col-md-12" : "col-md-6"}`} style={{ height: "initial" }} id={jitsiContainerId}></div>
          </div>

          {isScreen && <div className={`${Style.shareAlert} px-4 py-2 ${Style.blink_border}`}>Screen Sharing</div>}

          {/*{isCanvas && (
					<div style={{position: 'absolute', height: '80vh', width: '90vw', margin: '20px', backgroundColor: 'white', zIndex: '1'}}>
						<SketchField width='100%' height='100%' tool={Tools.Pencil} lineColor='black' lineWidth={3}/>
					</div>
				)}*/}
          <div
            className={`d-flex br-9px justify-content-around p-2 ${Style.bottom_fixed_toolbar}`}
            style={{
              position: "fixed",
              bottom: "0",
              marginLeft: "28vw",
              zIndex: "1000",
            }}
          >
            <OverlayTrigger key={`0`} placement={`top`} overlay={<Tooltip id={`tooltip-0`}>Camera</Tooltip>}>
              <div
                id="videoToggle"
                className={`p-2 align-self-center text-center ${Style.circle_btn} ${isCamera ? Style.green_bg : ""}`}
                onClick={(e) => {
                  videoToggle()
                }}
              >
                <img className="align-bottom mb-0" src={videoIcon} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger key={`1`} placement={`top`} overlay={<Tooltip id={`tooltip-1`}>Microphone</Tooltip>}>
              <div
                id="audioToggle"
                className={`p-2 align-self-center text-center ${Style.circle_btn} ${isMic ? Style.green_bg : ""}`}
                onClick={(e) => {
                  audioToggle()
                }}
              >
                <img className="align-bottom mb-0" src={audioIcon} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger key={`2`} placement={`top`} overlay={<Tooltip id={`tooltip-2`}>Share Screen</Tooltip>}>
              <div
                className={`p-2 align-self-center text-center ${Style.circle_btn} ${isScreen ? Style.green_bg : ""}`}
                onClick={(e) => {
                  screenShareToggle()
                }}
              >
                <img className="align-bottom mb-0" src={ScreenShare} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger key={`3`} placement={`top`} overlay={<Tooltip id={`tooltip-3`}>Mute All Participants</Tooltip>}>
              <div
                className={`p-2 align-self-center text-center ${Style.circle_btn}`}
                onClick={(e) => {
                  muteEveryone()
                }}
              >
                <img className="align-bottom mb-0" src={MuteAll} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger key={`4`} placement={`top`} overlay={<Tooltip id={`tooltip-4`}>{viewIcon === SpeakerView ? "Tile View" : "Speaker View"}</Tooltip>}>
              <div
                id="viewToggle"
                className={`p-2 align-self-center text-center ${Style.circle_btn}`}
                onClick={(e) => {
                  viewToggle()
                }}
              >
                <img className="align-bottom mb-0" src={viewIcon} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger key={`5`} placement={`top`} overlay={<Tooltip id={`tooltip-5`}>Reload Meeting</Tooltip>}>
              <div
                className={`p-2 align-self-center text-center ${Style.circle_btn}`}
                onClick={(e) => {
                  reloadMeeting()
                }}
              >
                <img className="align-bottom mb-0" src={Reload} />
              </div>
            </OverlayTrigger>
            {/*<OverlayTrigger*/}
            {/*  key={`6`}*/}
            {/*  placement={`top`}*/}
            {/*  overlay={*/}
            {/*    <Tooltip id={`tooltip-6`}>*/}
            {/*      Whiteboard*/}
            {/*    </Tooltip>*/}
            {/*  }*/}
            {/*>  */}
            {/*  <div className={`p-2 align-self-center text-center ${Style.circle_btn}`} onClick={(e)=>{setIsCanvas(!isCanvas)}}>*/}
            {/*    <img className='align-bottom mb-0' src={Reload}/>*/}
            {/*  </div>*/}
            {/*</OverlayTrigger>*/}
          </div>
        </>
      ) : (
        <div className="btn btn-primary">Join Live Class Again</div>
      )}
    </>
  )
}

const ClassRoom = (location, props) => {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [currentNavTab, setCurrentNavTab] = useState("")
  const [leftNavOpen, setLeftNavOpen] = useState(false)
  const [activeSection, setActiveSection] = useState("LessonBoard")
  const [participant, setParticipant] = useState(null)
  const [participantsList, setParticipantsList] = useState([])
  const [selectedComponent, setSelectedComponent] = useState("participants")
  const [seeMoreVisible, setSeeMoreVisible] = useState(true)
  const [next_participant_url, setNextParticipantUrl] = useState(null)
  const [allParticipantsCount, setAllParticipantsCount] = useState(0)
  const [trainingDetail, setTrainingDetail] = useState("")
  const [trainingDetailFetched, setTrainingDetailFetched] = useState(false)

  const [allResourcesChanged, setAllResourcesChanged] = useState(false)
  const [allResources, setAllResources] = useState({})
  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("")

  const [dragEnded, setDragEnded] = useState(false)
  const [breakoutSessionDragEnded, setBreakoutSessionDragEnded] = useState(false)
  const [draggedItem, setDraggedItem] = useState("")

  const [commonResourceDragEnded, setCommonResourceDragEnded] = useState(false)
  const [commonResourceDraggedItem, setCommonResourceDraggedItem] = useState("")

  const [isVCMin, setIsVCMin] = useState(false)
  const [publishedResource, setPublishedResource] = useState("")

  const [showBgModal, setShowBgModal] = useState(false)
  const [bgModalClick, setBgModalClick] = useState(0)

  const [disableScroll, setDisableScroll] = useState(false)
  const [disableLessonBoardDnd, setDisableLessonBoardDnd] = useState(false)
  const [showAllResources, setShowAllResources] = useState(false)

  const [triggerSession, setTriggerSession] = useState(null)

  const [muteAllTrigger, setMuteAllTrigger] = useState(false)

  //Optional Video Call
  const [videoCall, setVideoCall] = useState(null)

  const [showLiveDiscussionModal, setShowLiveDiscussionModal] = useState(false)
  const [liveDiscussionId, setLiveDiscussionId] = useState(null)
  const [liveDiscussionCount, setLiveDiscussionCount] = useState(0)
  const [showBreakModal, setShowBreakModal] = useState(false)

  const [showSharedArtifact, setShowSharedArtifact] = useState(false)
  const [trainingWsConnection, setTrainigWsConnection] = useState(null)
  const [traingWsConnected, setTraingWsConnected] = useState(null)
  const [trainingBreak, setTrainingBreak] = useState(null)
  const [activeActivityData, setActiveActivityData] = useState({})
  const [showActiveActivity, setShowActiveActivity] = useState(false)

  const trainingActivityWs = useRef(null)

  useEffect(() => {
    if (isBrowser()) {
      let location_hash = window.location.hash.replace("#", "")
      if (location_hash === "type=lesson-board") {
        setActiveSection("LessonBoard")
      } else {
        setActiveSection("LessonBoard")
      }
      connectTrainingWS()
      window.addEventListener("unload", function () {
        closeTrainingWs()
      })
    }
    return () => {
      if (trainingActivityWs && trainingActivityWs.current) {
        trainingActivityWs.current.close()
      }
    }
  }, [isBrowser()])

  const connectTrainingWS = () => {
    let updateSessionSocket = WsConnect(`/ws/training-activity/${location.training_id}/`)
    updateSessionSocket.onclose = function (e) {
      setTraingWsConnected(false)
      if (!e.wasClean) {
        setTimeout(connectTrainingWS(), 5000)
      }
    }
    updateSessionSocket.onopen = function (e) {
      setTraingWsConnected(true)
      setTrainigWsConnection(updateSessionSocket)
      trainingActivityWs.current = updateSessionSocket
    }
  }

  const closeTrainingWs = () => {
    if (trainingWsConnection.readyState == WebSocket.OPEN) {
      trainingWsConnection.close()
    }
  }

  function handleScroll(e) {
    if (videoCall === "jitsi") {
      if (e.target.scrollTop > 100) {
        if (!isVCMin) {
          setIsVCMin(true)
        }
      } else {
        if (isVCMin) {
          setIsVCMin(false)
        }
      }
    }
  }

  useEffect(() => {
    if (isBrowser() && document.getElementsByClassName("watermark").length > 0) {
      document.getElementsByClassName("watermark")[0].style.background = `red`
    }
  }, [document.getElementsByClassName("watermark")])

  useEffect(() => {
    // fetchParticipants()
    fetchTrainingDetail()
    fetchPreviousDiscussionCount()
  }, [])

  useEffect(() => {
    if (leftNavOpen && currentNavTab == "Resource") {
      if (isBrowser()) {
        document.getElementById("scrollable-div").scrollBy(0, 110)
      }
    }
    if (currentNavTab === "Break") {
      setActiveSection(currentNavTab)
    }
  }, [leftNavOpen, currentNavTab])

  useEffect(() => {}, [allResources])

  useEffect(() => {
    if (activeSection === "BreakoutSession") {
      setDisableScroll(true)
      setDisableLessonBoardDnd(true)
      // setShowAllResources(true)
    }
    if (liveDiscussionId !== null) {
      setActiveSection("DiscussionBoard")
    }
    if (activeSection === "Break") {
      setShowBreakModal(true)
    }
  }, [activeSection])

  function openNavWithResourceSection(nav_tab, resource_type) {
    setLeftNavOpen(true)
    setCurrentNavTab(nav_tab)
    setCurrentSelectedResourcesType(resource_type)
  }
  function onDragEnd(result) {
    // if (activeSection === 'SessionPlan' || activeSection === 'LessonPlan' || activeSection === 'BreakoutSession') {}
    if (result) {
      if (activeSection === "BreakoutSession") {
        setBreakoutSessionDragEnded(true)
      } else if (activeSection === "Resource") {
        setCommonResourceDragEnded(true)
      } else {
        setDragEnded(true)
      }
      if (activeSection === "Resource") {
        setCommonResourceDraggedItem(result)
      } else {
        setDraggedItem(result)
      }
    } else {
      return null
    }
  }

  async function fetchPreviousDiscussionCount() {
    let res = await getRequest(`/live-board/live-discussion-board/get_only_count/?training=${location.training_id}&session_status=2`)
    if (res.success) {
      setLiveDiscussionCount(res.data.count)
    }
  }

  useEffect(() => {
    if (liveDiscussionId !== null) {
      setActiveSection("DiscussionBoard")
      setTriggerSession({})
    }
  }, [liveDiscussionId])

  async function fetchTrainingDetail() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/my-training-desc/${location.training_id}/`, { headers: login_credentials }).then((res) => {
      setTrainingDetail(res.data[0])
      if (res.data[0].jitsy_enabled) {
        setVideoCall("jitsi")
      }
      setTrainingDetailFetched(true)
    })
  }

  // function fetchParticipants() {
  //   axios.get(`${process.env.GATSBY_REACT_APP_API_HOST}/training-participants/${location.training_id}/?paginate_by=6`, {headers: login_credentials})
  //   .then(res => {
  //     setParticipantsList(res.data.results)
  // 		setAllParticipantsCount(res.data.count)
  // 		if(res.data.next){
  // 			setSeeMoreVisible(true)
  // 			setNextParticipantUrl(res.data.next)
  // 		}
  // 		else{
  // 			setSeeMoreVisible(false)
  // 		}
  //   } ).catch(error => {setParticipantsList([])})
  // }

  function fetchNextParticipants() {
    axios.get(next_participant_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_pax_data = participantsList
      if (res.data.results.length > 0) {
        for (var md = 0; md <= res.data.results.length - 1; md++) {
          next_pax_data.push(res.data.results[md])
        }
        setParticipantsList(next_pax_data)
      }
      setNextParticipantUrl(res.data.next)
      if (res.data.next == null) {
        setSeeMoreVisible(false)
      }
    })
  }

  async function changeTrainingStatus() {
    let class_status = "3"
    let end_dt = new Date(trainingDetail.end_date)
    let current_dt = new Date()
    let currDate = current_dt.toISOString().slice(0, 10)

    if (current_dt.getTime() < end_dt.getTime()) {
      class_status = "2"
    }

    var formData = new FormData()
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/change-training-state/${location.training_id}/?status=${class_status}&date=${currDate}`, formData, {
        headers: DefaultHeader,
      })
      .then((res) => {
        navigate(`/main/class-dashboard/${location.template_id}/${location.training_id}/`)
      })
  }

  function removeParticipants() {
    document.getElementById("partcipants").style.display = "none"
  }

  function updateComponentColor(e) {
    setSelectedComponent(e.target.id)
    document.getElementById("participants").style.backgroundColor = "transparent"
    document.getElementById("chat").style.backgroundColor = "transparent"
    document.getElementById("leadboard").style.backgroundColor = "transparent"
    document.getElementById(e.target.id).style.backgroundColor = "#303C54"
  }

  function getSingleParticipant(value, indx) {
    return (
      <div className="d-flex px-3 my-2 align-items-center" style={{ justifyContent: "space-between" }}>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon className="mr-2" icon={faUserCircle} style={{ width: `30px`, height: `30px` }} />
          <p
            style={{
              margin: "0px",
              fontSize: "14px",
              color: "#A1AACD",
              lineHeight: 1,
            }}
          >
            {value.owner__first_name}
          </p>
        </div>
        <div className="d-flex align-items-center">
          <img src={BlueMicrophone} alt={`Microphone`} className={`my-auto mr-2`} style={{ objectFit: `contain`, height: `15px`, color: `white` }} />
          <img src={BlueVideoSlash} alt={`Video slash`} className={`my-auto`} style={{ objectFit: `contain`, height: `15px`, color: `white` }} />
          {/* <img src={ChatImg} alt={`chat`} className={`my-auto`} style={{ objectFit: `contain`, height: `15px`,marginLeft:'10px', color: `white` }}/> */}
          <FontAwesomeIcon
            className="mr-2"
            icon={faCommentAlt}
            style={{
              objectFit: `contain`,
              height: `15px`,
              marginLeft: "10px",
              color: `#E0E2E5`,
            }}
          />
          <img
            src={dropRightArrow}
            alt={`rightarrow`}
            className={`my-auto`}
            style={{
              objectFit: `contain`,
              height: `15px`,
              marginLeft: "0px",
              color: `white`,
            }}
          />
        </div>
      </div>
    )
  }

  function getSingleParticipantLeadboard(value, indx) {
    return (
      <div className="d-flex px-3 my-2 align-items-center" style={{ justifyContent: "space-between" }}>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon className="mr-2" icon={faUserCircle} style={{ width: `30px`, height: `30px` }} />
          <p
            style={{
              margin: "0px",
              fontSize: "14px",
              color: "#A1AACD",
              lineHeight: 1,
            }}
          >
            {value.owner__first_name}
          </p>
        </div>
        <div className="d-flex align-items-center">
          <img
            src={LikeImg}
            alt={`Like`}
            className={`my-auto mr-2`}
            style={{
              objectFit: `contain`,
              height: `27px`,
              marginLeft: "0px",
              color: `white`,
            }}
          />
          <img
            src={ChatImg}
            alt={`chat`}
            className={`my-auto`}
            style={{
              objectFit: `contain`,
              height: `15px`,
              marginLeft: "5px",
              color: `white`,
            }}
          />
          <img
            src={dropRightArrow}
            alt={`rightarrow`}
            className={`my-auto`}
            style={{
              objectFit: `contain`,
              height: `15px`,
              marginLeft: "15px",
              color: `white`,
            }}
          />
        </div>
      </div>
    )
  }

  function getParticipants() {
    return (
      <div>
        {participantsList.map((value, indx) => {
          return getSingleParticipant(value, indx)
        })}
      </div>
    )
  }

  function getParticipantsLeadboard() {
    return (
      <div>
        {participantsList.map((value, indx) => {
          return getSingleParticipantLeadboard(value, indx)
        })}
      </div>
    )
  }

  function getOriginalData() {
    if (selectedComponent == "participants") {
      return getParticipants()
    } else if (selectedComponent == "chat") {
      return (
        <div style={{ textAlign: "center", marginTop: "30%", marginBottom: "30%" }}>
          <p>Chats need to be designed</p>
        </div>
      )
    } else {
      return getParticipantsLeadboard()
    }
  }

  function handleCloseBreakoutOverlay() {
    setDisableScroll(false)
    setActiveSection("LessonBoard")
    setCurrentNavTab("")
    setLeftNavOpen(false)
    setDisableLessonBoardDnd(false)
    // setShowAllResources(false)
  }

  function showMainContent() {
    if (activeSection === "Home") {
      return (
        <Col xs="12" sm="12" md="12" lg="12">
          {/* <Col xs="12" sm="12" md="7" lg="7">
						<div style={{backgroundColor:'#12172C',borderRadius:'8px',minHeight:'500px',marginLeft:"100px"}} >
							<div style={{backgroundColor:'#ffffff80',color:'black',borderRadius:'8px 8px 0px 0px',padding:'2px 10px'}} >Trainer</div>
						</div>
					</Col> */}
          {/* <Col xs="12" sm="12" md="5" lg="5" >
						<div className="pt-3 pb-5" id='partcipants' style={{backgroundColor:'#212C42',borderRadius:'8px',boxShadow:"0px 3px 12px #00000099",position:'relative',height:'500px',marginRight:'80px',overflowY:'scroll'}}>
							<div onClick={removeParticipants} style={{height:'25px',borderBottom:'1px solid #E0E2E5',position:"relative",right:'0px',top:'-9px',display:'block'}}>
							<img src={CancelWhite} alt={`Cancel`} className={`my-auto mr-2 float-right`} style={{ objectFit: `contain`, height: `18px` }}/>
							</div>
							<div style={{display:'grid',gridTemplateColumns:'auto auto auto',borderBottom:'1px solid #E0E2E5',position:'relative',top:'-8px'}}>
								<div id='participants' onClick={updateComponentColor}  style={{fontSize:'16px',padding:'5px 0px 10px 10px',backgroundColor:'#303C54',marginTop:'0px'}}>
									All Participants ({allParticipantsCount})
								</div>
								<div id='leadboard' onClick={updateComponentColor} style={{fontSize:'16px',padding:'5px 0px 10px 20px',backgroundColor:'transparent'}}>
								 Leaderboard
								</div>
								<div id='chat' onClick={updateComponentColor} style={{fontSize:'16px',padding:'5px 0px 10px 20px',backgroundColor:'transparent'}}>
								 Chats(47)
								</div>
							</div>
							<div style={{overflow:'scroll'}}>
								{getOriginalData()}
								{seeMoreVisible === true ? <div onClick={fetchNextParticipants} style={{textAlign:'center'}}><span style={{border:'1px solid #929BBD',paddingLeft:'10px',paddingRight:'10px',borderRadius:"10px"}}>See More</span></div> : ''}
							</div>
							<div className='pt-1'>
							 <p className="text-white px-3 mb-2" >All Participants (25)</p>
							</div>
							<div className={`d-inline-flex px-2`} style={{ backgroundColor: `#12172C`,width:'100%' }} >
								<img src={BlueSearch} alt={`Search`} className={`my-auto`} style={{ objectFit: `contain`, height: `15px`, color: `white` }}/>
								<FormControl
									className="border-0 pl-2 form-control shadow-none"
									style={{ backgroundColor: `transparent` ,color: `#C6CFF4`,fontSize:'.9rem',height:'35px' }}
									placeholder={`Search students`}
								/>
							</div>
							<div className="d-flex px-3 my-2 align-items-center" style={{justifyContent:'space-between'}} >
								<div className="d-flex align-items-center" >
									<FontAwesomeIcon className='mr-2' icon={faUserCircle} style={{ width: `30px`, height: `30px` }} />
									<p style={{margin:'0px',fontSize:'14px',color:'#A1AACD',lineHeight:1}} >Sergio Ramoz</p>
								</div>
								<div className="d-flex align-items-center" >
									<img src={BlueMicrophone} alt={`Microphone`} className={`my-auto mr-2`} style={{ objectFit: `contain`, height: `15px`, color: `white` }}/>
									<img src={BlueVideoSlash} alt={`Video slash`} className={`my-auto`} style={{ objectFit: `contain`, height: `15px`, color: `white` }}/>
								</div>
							</div>
							<div className="d-flex px-3 my-2 align-items-center" style={{justifyContent:'space-between'}} >
								<div className="d-flex align-items-center" >
									<FontAwesomeIcon className='mr-2' icon={faUserCircle} style={{ width: `30px`, height: `30px` }} />
									<p style={{margin:'0px',fontSize:'14px',color:'#A1AACD',lineHeight:1}} >Sergio Ramoz</p>
								</div>
								<div className="d-flex align-items-center" >
									<img src={BlueMicrophone} alt={`Microphone`} className={`my-auto mr-2`} style={{ objectFit: `contain`, height: `15px`, color: `white` }}/>
									<img src={BlueVideoSlash} alt={`Video slash`} className={`my-auto`} style={{ objectFit: `contain`, height: `15px`, color: `white` }}/>
								</div>
							</div>
							<div className="d-flex px-3 my-2 align-items-center" style={{justifyContent:'space-between'}} >
								<div className="d-flex align-items-center" >
									<FontAwesomeIcon className='mr-2' icon={faUserCircle} style={{ width: `30px`, height: `30px` }} />
									<p style={{margin:'0px',fontSize:'14px',color:'#A1AACD',lineHeight:1}} >Sergio Ramoz</p>
								</div>
								<div className="d-flex align-items-center" >
									<img src={BlueMicrophone} alt={`Microphone`} className={`my-auto mr-2`} style={{ objectFit: `contain`, height: `15px`, color: `white` }}/>
									<img src={BlueVideoSlash} alt={`Video slash`} className={`my-auto`} style={{ objectFit: `contain`, height: `15px`, color: `white` }}/>
								</div>
							</div>
							<div className="px-3 py-2" style={{backgroundColor:'#12172C',position:'absolute',bottom:'0px',width:'100%',borderRadius:'0px 0px 15px 15px'}} >
								<div className="d-flex align-items-center" style={{fontSize:'12px'}} >
									<img src={BlueMicrophone} alt={`Microphone`} className={`my-auto mr-2`} style={{ objectFit: `contain`, height: `15px`, color: `white` }}/>
									Mute All
								</div>
							</div>
						</div>
					</Col>*/}
        </Col>
      )
    } else if (
      activeSection === "LessonBoard" ||
      activeSection === "BreakoutGroup" ||
      activeSection === "BreakoutSession" ||
      activeSection === "QuestionReply" ||
      activeSection === "Resource" ||
      activeSection === "Break"
    ) {
      return (
        <React.Fragment>
          {videoCall === "jitsi" && (
            <VideoCall
              actions={{ muteAllTrigger, setMuteAllTrigger }}
              minScroll={isVCMin}
              setMinScroll={setIsVCMin}
              meetid={`${trainingDetail.training_activity_template_id}_${trainingDetail.id}`}
            />
          )}
          {traingWsConnected ? (
            <LessonBoard
              videoCall={videoCall}
              videoCallActions={{ setMuteAllTrigger }}
              triggerSession={triggerSession}
              setTriggerSession={setTriggerSession}
              resourceTrigger={publishedResource}
              minScroll={isVCMin}
              leftNavOpen={leftNavOpen}
              setLeftNavOpen={(value) => setLeftNavOpen(value)}
              currentNavTab={currentNavTab}
              location={location}
              setActiveSection={(item) => {
                setActiveSection(item)
                if (item === "BreakoutGroup") {
                  setShowBgModal(true)
                }
                setBgModalClick(bgModalClick + 1)
              }}
              setDisableScroll={(value) => setDisableScroll(value)}
              disableLessonBoardDnd={disableLessonBoardDnd}
              setShowAllResources={(value) => setShowAllResources(value)}
              resetDragState={() => {
                setDraggedItem("")
                setDragEnded(false)
              }}
              dragEnded={dragEnded}
              draggedItem={draggedItem}
              currentSelectedResourcesType={currentSelectedResourcesType}
              allResources={allResources}
              setHomepageAllResource={(value) => setAllResources(value)}
              setLiveDiscussionModal={setShowLiveDiscussionModal}
              showLiveDiscussionModal={showLiveDiscussionModal}
              setDiscussionBoard={setLiveDiscussionId}
              liveboard_count={liveDiscussionCount}
              setLiveboardCount={setLiveDiscussionCount}
              openNavWithResourceSection={openNavWithResourceSection}
              setTrainingBreak={setTrainingBreak}
              trainingWsConnection={trainingWsConnection}
              trainingBreak={trainingBreak}
              activeActivityData={activeActivityData}
              setActiveActivityData={setActiveActivityData}
              setShowActiveActivity={setShowActiveActivity}
            />
          ) : null}
          {activeSection === "BreakoutGroup" || activeSection === "Resource" ? (
            <BreakoutGroups
              location={location}
              showBgModal={showBgModal}
              setShowBgModal={setShowBgModal}
              bgModalClick={bgModalClick}
              setLeftNavOpen={(value) => setLeftNavOpen(value)}
              setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
              leftNavOpen={leftNavOpen}
              allResourcesChanged={allResourcesChanged}
              allResources={allResources}
              setHomepageAllResource={(value) => setAllResources(value)}
              openNavWithResourceSection={openNavWithResourceSection}
              currentSelectedResourcesType={currentSelectedResourcesType}
              resetDragState={() => {
                setCommonResourceDraggedItem("")
                setCommonResourceDragEnded(false)
              }}
              dragEnded={commonResourceDragEnded}
              draggedItem={commonResourceDraggedItem}
              activeSection={activeSection}
              setActiveSection={(item) => {
                setActiveSection(item)
                if (item === "BreakoutGroup") {
                  setShowBgModal(true)
                }
                setBgModalClick(bgModalClick + 1)
              }}
              setDisableScroll={(value) => setDisableScroll(value)}
            />
          ) : null}

          {
            <SessionBreak
              showSessionBreak={showBreakModal}
              setShowSessionBreak={setShowBreakModal}
              setActiveSection={setActiveSection}
              setCurrentNavTab={setCurrentNavTab}
              trainingBreak={trainingBreak}
              setTrainingBreak={setTrainingBreak}
              courseTemplateId={location.template_id}
              trainingWsConnection={trainingWsConnection}
              setActiveActivityData={setActiveActivityData}
              setShowActiveActivity={setShowActiveActivity}
            />
          }

          {activeSection === "BreakoutSession" ? (
            <div className={`${leftNavOpen ? StylesA.breakout_session_detail_open : StylesA.breakout_session_detail_closed}`}>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                className="d-flex"
                style={{
                  justifyContent: "center",
                  maxHeight: "90vh",
                  overflowY: "auto",
                }}
              >
                <Col
                  xs="12"
                  sm="12"
                  md="8"
                  className={`${leftNavOpen ? "col-lg-11" : "col-lg-8"} px-0`}
                  onScroll={(e) => {
                    e.stopPropagation()
                  }}
                  style={{ maxHeight: "80vh", overflowY: "auto" }}
                >
                  <TestBreakoutSession
                    triggerSession={triggerSession}
                    setTriggerSession={setTriggerSession}
                    parent_page={"live_class"}
                    setLeftNavOpen={(value) => setLeftNavOpen(value)}
                    setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
                    leftNavOpen={leftNavOpen}
                    allResourcesChanged={allResourcesChanged}
                    allResources={allResources}
                    setHomepageAllResource={(value) => setAllResources(value)}
                    openNavWithResourceSection={openNavWithResourceSection}
                    currentSelectedResourcesType={currentSelectedResourcesType}
                    resetDragState={() => {
                      setDraggedItem("")
                      setBreakoutSessionDragEnded(false)
                    }}
                    dragEnded={breakoutSessionDragEnded}
                    draggedItem={draggedItem}
                    template_id={location.template_id}
                    training_id={location.training_id}
                    activeSection={activeSection}
                    setActiveSection={(item) => setActiveSection(item)}
                    handleCloseBreakoutOverlay={handleCloseBreakoutOverlay}
                    trainingActivityWs={trainingWsConnection}
                  />
                </Col>
              </Col>
            </div>
          ) : (
            ""
          )}
        </React.Fragment>
      )
    } else if (activeSection === "LessonPlan") {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className="d-flex" style={{ justifyContent: leftNavOpen ? "flex-end" : "center" }}>
          <Col xs="12" sm="12" md="8" lg="9">
            <LessonPlan
              leftNavOpen={leftNavOpen}
              allResourcesChanged={allResourcesChanged}
              setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
              allResources={allResources}
              setHomepageAllResource={(value) => setAllResources(value)}
              openNavWithResourceSection={openNavWithResourceSection}
              currentSelectedResourcesType={currentSelectedResourcesType}
              resetDragState={() => {
                setDraggedItem("")
                setDragEnded(false)
              }}
              dragEnded={dragEnded}
              draggedItem={draggedItem}
              template_id={location.template_id}
              activeSection={activeSection}
              setActiveSection={(item) => setActiveSection(item)}
            />
          </Col>
        </Col>
      )
    } else if (activeSection === "SessionPlan") {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className="d-flex">
          <Col xs="12" sm="12" md="12" lg="12">
            <SessionPlan
              leftNavOpen={leftNavOpen}
              allResourcesChanged={allResourcesChanged}
              setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
              allResources={allResources}
              setHomepageAllResource={(value) => setAllResources(value)}
              openNavWithResourceSection={openNavWithResourceSection}
              currentSelectedResourcesType={currentSelectedResourcesType}
              resetDragState={() => {
                setDraggedItem("")
                setDragEnded(false)
              }}
              dragEnded={dragEnded}
              draggedItem={draggedItem}
              template_id={location.template_id}
              activeSection={activeSection}
              setActiveSection={(item) => setActiveSection(item)}
            />
          </Col>
        </Col>
      )
    } else if (activeSection === "DiscussionBoard") {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className="d-flex">
          <Col xs="12" sm="12" md="12" lg="12">
            {traingWsConnected ? (
              <LiveDiscussionBoard
                setActiveSection={setActiveSection}
                setDiscussionBoard={setLiveDiscussionId}
                boardId={liveDiscussionId}
                location={location}
                trdWsConnection={trainingWsConnection}
              />
            ) : null}
          </Col>
        </Col>
      )
    } else if (activeSection === "Participants") {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className="d-flex" style={{ justifyContent: leftNavOpen ? "flex-end" : "center" }}>
          <Col xs="12" sm="12" md="8" lg="9">
            <ParticipantDetail
              type="live-class"
              setLeftNavOpen={(value) => setLeftNavOpen(value)}
              setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
              leftNavOpen={leftNavOpen}
              allResourcesChanged={allResourcesChanged}
              allResources={allResources}
              setHomepageAllResource={(value) => setAllResources(value)}
              openNavWithResourceSection={openNavWithResourceSection}
              currentSelectedResourcesType={currentSelectedResourcesType}
              resetDragState={() => {
                setDraggedItem("")
                setDragEnded(false)
              }}
              dragEnded={dragEnded}
              draggedItem={draggedItem}
              template_id={location.template_id}
              training_id={location.training_id}
              activeSection={activeSection}
              setActiveSection={(item) => setActiveSection(item)}
              participant={participant}
            />
          </Col>
        </Col>
      )
    }
  }
  const goBackToHome = () => {
    window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}`)
    setActiveSection("Home")
  }

  if (trainingDetailFetched) {
    if (trainingDetail.class_status === "1") {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            className="pr-0"
            style={{
              backgroundColor: "#19253B",
              paddingLeft: "60px",
              height: "100vh",
            }}
          >
            <ClassRoomLeftNav
              setActiveSection={(item) => {
                setActiveSection(item)
                setBgModalClick(bgModalClick + 1)
                if (item === "BreakoutGroup") {
                  setShowBgModal(true)
                }
              }}
              currentSelectedResourcesType={currentSelectedResourcesType}
              setHomepageActiveResource={(value) => setCurrentSelectedResourcesType(value)}
              allResourcesChanged={allResourcesChanged}
              allResources={allResources}
              setHomepageAllResource={(value) => setAllResources(value)}
              activeSection={activeSection}
              currentNavTab={currentNavTab}
              setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
              leftNavOpen={leftNavOpen}
              setLeftNavOpen={(value) => setLeftNavOpen(value)}
              template_id={location.template_id}
              training_id={location.training_id}
              type="classroom"
              setPublishedResource={(value) => setPublishedResource(value)}
              showAllResources={showAllResources}
              disableScroll={disableScroll}
              setLiveDiscussionModal={setShowLiveDiscussionModal}
              trainingWsConnection={trainingWsConnection}
              updateParticipantId={(val) => {
                setParticipant(val)
              }}
              activeActivityData={activeActivityData}
              setActiveActivityData={setActiveActivityData}
              setShowActiveActivity={setShowActiveActivity}
            />
            <div style={{ height: "100vh", overflowY: "auto" }}>
              <Col xs="12" sm="12" md="12" lg="12" className={`${StylesA.classroom_header} p-3 d-flex`} style={{ justifyContent: "space-between" }}>
                <div className="float-left">
                  {activeSection === "LessonBoard" || activeSection === "BreakoutGroup" ? (
                    <a
                      className="float-left pointer mr-2"
                      rel="noreferrer"
                      href={`/main/class-dashboard/${trainingDetail.training_activity_template_id}/${trainingDetail.id}/`}
                    >
                      <FontAwesomeIcon className={`text-white`} icon={faChevronLeft} width={20} height={20} />
                    </a>
                  ) : null}
                  <div className={`${StylesA.header_left_section} d-flex align-items-center`}>
                    <h5>{trainingDetail.course}</h5>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        color: "white",
                        fontSize: "12px",
                        marginLeft: "10px",
                        padding: "0px 5px",
                        borderRadius: "2px",
                        backgroundColor: "#D52B0F",
                      }}
                    >
                      <Image
                        style={{
                          width: "13px",
                          height: "9px",
                          marginRight: "5px",
                        }}
                        alt="exit course"
                        src="eye-icon.png"
                      />
                      Live
                    </div>
                  </div>
                </div>
                <div className={`${StylesA.header_right_section}`}>
                  <Button
                    onClick={() => setShowSharedArtifact(true)}
                    variant="none"
                    className={`border_on_hover mr-3 text-center align-items-center d-inline-flex text-white`}
                    style={{
                      backgroundColor: `#303C54`,
                      borderRadius: "15px",
                      padding: "4px 7px",
                    }}
                  >
                    <img src={share} alt={`Share Course`} className={`my-auto mr-1`} style={{ objectFit: `contain`, height: `14px` }} />
                    <p style={{ fontSize: "14px", margin: "0px" }}>Share Class room</p>
                  </Button>
                  {/*
                  <Button variant="none" className={`mr-3 text-center align-items-center d-inline-flex text-white`} style={{ backgroundColor: `#303C54`, borderRadius: '15px',padding:'4px 7px' }} >
                    <img src={BellWhite} alt={`Notification`} className={`my-auto mr-1`} style={{ objectFit: `contain`, height: `14px` }}/>
                    <p style={{fontSize:'12px',margin:'0px'}} > +12</p>
                  </Button>
                  */}

                  <Button
                    onClick={() => changeTrainingStatus()}
                    variant="none"
                    className={`border_on_hover text-center align-items-center d-inline-flex text-white`}
                    style={{
                      backgroundColor: `#303C54`,
                      borderRadius: "15px",
                      padding: "4px 7px",
                    }}
                  >
                    <img src={CancelWhite} alt={`Cancel`} className={`my-auto mr-1`} style={{ objectFit: `contain`, height: `13px` }} />
                    <p style={{ margin: "0px", fontSize: "14px" }}>End Class</p>
                  </Button>
                </div>
              </Col>
              <Col
                id="scrollable-div"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                className="py-3 pl-0 d-flex"
                onScroll={(e) => {
                  handleScroll(e)
                }}
                style={{
                  height: "calc(100vh - 68px)",
                  flexWrap: "wrap",
                  overflowY: "auto",
                }}
              >
                {showMainContent()}
                <SharedArtifact show={showSharedArtifact} hide={() => setShowSharedArtifact(false)} trainingId={location.training_id} />
                <TechnicalNetworkError connectionLost={!traingWsConnected}></TechnicalNetworkError>
                <ActivityWarning show={showActiveActivity} setShowActiveActivity={setShowActiveActivity} activeActivityData={activeActivityData} />
              </Col>
            </div>
          </Col>
        </DragDropContext>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-3">
          <p>This class is not live now.</p>
        </Col>
      )
    }
  } else {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
        <Spinner animation="border" variant="secondary" />
      </Col>
    )
  }
}

export default ClassRoom
