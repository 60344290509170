import React, { useEffect, useState, useContext } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import { LazyLoadImage } from "react-lazy-load-image-component"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_REFETCH_RESOURCES } from "../../stores/sidebar_store/SidebarActions"

const EditImage = (location, data) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [image, setImage] = useState({})

  const [sidebarStore, dispatch] = useContext(SidebarContext)
  // const { company_id } = location.id

  useEffect(() => {
    fetchImage()
  }, [location.id])

  async function fetchImage() {
    // console.log("fetchGroups called")
    // setCompanyLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/images/${location.id}/`, { headers: login_credentials }).then((res) => {
      setImage(res.data)
      // setCompanyLoader(true)
    })
  }

  function handleChange(event) {
    event.preventDefault()
    image[event.target.name] = event.target.value
  }

  function handleselectedFile(event) {
    event.preventDefault()
    image[event.target.name] = event.target.files[0]
  }

  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in image) {
      if (k != "avatar") formData.append(k, image[k])
    }
    if (document.querySelector(".form-control-file").value.length > 0) {
      formData.append("avatar", image["avatar"])
    }
    if (location.id != undefined) {
      var company_id = location.id
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/images/" + company_id + "/", formData, { headers: login_credentials })
        .then((res) => {
          navigate("/app/files")
          dispatch({ type: SET_REFETCH_RESOURCES, payload: true })
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }
  function toggle(e, type) {
    e.preventDefault()
  }

  return (
    <div>
      <SEO title="Edit File" />
      <h5 className="border-bottom p-2">
        Edit File
        <Link className={`pointer text-white`} to={`/app/files`}>
          <span style={{ fontWeight: "normal" }} className="float-right small">
            {" "}
            Exit Edit{" "}
          </span>
        </Link>{" "}
      </h5>
      <Col lg="12" md="12" sm="12" xs="12" className={`d-inline-flex justify-content-center pt-3`}>
        <Col lg="6" md="6" sm="6" xs="6">
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col xs="12" sm="12" md="12" className="light-div p-0 theme-color float-left" style={{ minHeight: `fit-content` }}>
              <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom">
                <FormGroup className="m-0">
                  <div>File Name</div>
                  <input
                    style={{ background: "inherit", color: "inherit", fontSize: "12px" }}
                    className="blue-bg video-modal p-0"
                    type="text"
                    name="display_name"
                    defaultValue={image.display_name}
                    onChange={handleChange}
                    id="name"
                    placeholder="Enter File Name"
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom">
                <FormGroup>
                  <Form.Label htmlFor="avatar">Upload File</Form.Label>
                  <Form.File
                    name="avatar"
                    className="form-control-file-avatar"
                    onChange={handleselectedFile}
                    required={image.id === undefined ? true : false}
                    accept="image/*,application/pdf,video/*,.doc,.docx,.ppt,.pptx,.xlsx,.xls"
                  />
                  {(() => {
                    if (image.avatar && image.avatar.length > 0 && image.avatar.indexOf("pdf") < 0 && image.avatar.indexOf("PDF") < 0) {
                      return <LazyLoadImage src={image.avatar} width="200" alt={image.name} />
                    }
                  })()}
                </FormGroup>
              </Col>
              <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2">
                <FormGroup className="m-0">
                  <div>File Points</div>
                  <input
                    style={{ background: "inherit", color: "inherit", fontSize: "12px" }}
                    className="blue-bg video-modal p-0"
                    type="number"
                    name="points"
                    defaultValue={image.points}
                    onChange={handleChange}
                    id="points"
                    placeholder="Enter File points"
                    required
                  />
                </FormGroup>
              </Col>
            </Col>
            <Col xs="12" className="mt-3 float-left px-0">
              <Button className="float-right" style={{ background: "#576186", border: 0 }} type="submit" color="warning">
                Update
              </Button>{" "}
            </Col>
          </Form>
        </Col>
      </Col>
    </div>
  )
}

export default EditImage
