import React, { useContext, useEffect, useState } from "react"
import { getRequest } from "../../services/rest_service"

import SEO from "../seo"
import ClassLevelReport from "./ClassLevelReport"
import SchoolLevelReport from "./SchoolLevelReport"
import EducatorLevelReport from "./EducatorLevelReport"
import ClassLevelReportDropdowns from "./classLevelReportDropdowns"
import SchoolLevelReportDropdowns from "./schoolLevelReportDropdowns"
import EducatorLevelReportDropdowns from "./educatorLevelReportDropdowns"
import InsightsReportsStore from "../../stores/insights_reports/InsightsReportsStore"

import CalendarIcon from "../../images/svgs/calendar.svg"

import Close from "../../images/svgs/close.svg"
import Error from "../../images/svgs/error.svg"

import { InsightsReportsContext } from "../../stores/insights_reports/InsightsReportsStore"
import {
  SET_ACTIVE_REPORT_TAB,
  SET_ALL_CLASSES_DATA,
  SET_ALL_COURSES_DATA,
  SET_ACTIVE_COURSE_DATA,
  SET_ACTIVE_CLASS_DATA,
  SET_FETCHED_COURSES_DATA,
  SET_FETCHED_CLASSES_DATA,
  SET_ALL_SCHOOLS_DATA,
  SET_FETCHED_SCHOOLS_DATA,
  SET_ACTIVE_SCHOOL_DATA,
  SET_ALL_EDUCATORS_DATA,
  SET_FETCHED_EDUCATORS_DATA,
  SET_ACTIVE_EDUCATOR_DATA,
  RESET_ALL_REPORTS_DATA,
  SET_SCHOOL_LEVEL_TIME_PERIOD,
  SET_EDUCATOR_LEVEL_TIME_PERIOD,
} from "../../stores/insights_reports/InsightsReportsActions"

import Style from "./style.module.scss"
import Image from "../custom/Image"
import CustomDateTimePicker from "../custom/CustomDateTimePicker"
import Json from "../utilities/json"

// !definition of component
/**
 *
 * @description --> provides users with insights of their product use.
 * @returns Insights page
 */
// ! component

const InsightsChildren = ({ props }) => {
  const [state, dispatch] = useContext(InsightsReportsContext)

  const activeTab = state.activeTab
  const setActiveTab = (data) => {
    dispatch({ type: SET_ACTIVE_REPORT_TAB, payload: data })
  }

  const [secondaryDropdownValue, setSecondaryDropdownValue] = useState(null)

  const [showDateTimeModal, setShowDateTimeModal] = useState(false)
  const [customStartDate, setCustomStartDate] = useState(new Date())
  const [customEndDate, setCustomEndDate] = useState(new Date())
  const [dateError, setDateError] = useState(false)
  const [resetedData, setResetedData] = useState(false)

  const tabs = ["Class Level Report", "School Level Report", "Educator level Report"]

  useEffect(() => {
    if (!resetedData) {
      dispatch({ type: RESET_ALL_REPORTS_DATA, payload: "reset-data" })
      setResetedData(true)
    }
  }, [])

  useEffect(() => {
    if (resetedData) {
      if (activeTab === 0) {
        if (!state.allCoursesData) {
          fetchCourses()
        }
      } else if (activeTab === 1) {
        if (!state.allSchoolsData) {
          fetchSchools()
        }
      } else {
        if (!state.allEducatorsData) {
          fetchEducators()
        }
      }
    }
  }, [activeTab, resetedData])

  useEffect(() => {
    if (state.activeCourseData && !state.allClassesData[`course_${state.activeCourseData.id}`]?.results) {
      dispatch({ type: SET_FETCHED_CLASSES_DATA, payload: false })
      fetchClasses()
    } else if (state.allClassesData[`course_${state.activeCourseData?.id}`]?.results) {
      dispatch({ type: SET_FETCHED_CLASSES_DATA, payload: true })
      dispatch({ type: SET_ACTIVE_CLASS_DATA, payload: state.allClassesData[`course_${state.activeCourseData.id}`].results[0] })
    }
  }, [state.activeCourseData])

  const fetchCourses = async () => {
    const response = await getRequest(`/basic-courses-list/?company=${localStorage.getItem("company_id")}&course_type=0`)
    if (response.success) {
      dispatch({
        type: SET_ALL_COURSES_DATA,
        payload: response.data,
      })
      dispatch({ type: SET_FETCHED_COURSES_DATA, payload: true })
      dispatch({ type: SET_ACTIVE_COURSE_DATA, payload: response.data.results[0] })
    } else {
      dispatch({ type: SET_FETCHED_COURSES_DATA, payload: true })
    }
  }

  const fetchClasses = async () => {
    const response = await getRequest(
      `/basic-trainings-list/?course__company=${localStorage.getItem("company_id")}&type=all&course=${state.activeCourseData.id}`
    )
    if (response.success) {
      dispatch({ type: SET_ALL_CLASSES_DATA, payload: { key: `course_${state.activeCourseData.id}`, value: response.data } })
      dispatch({ type: SET_ACTIVE_CLASS_DATA, payload: response.data.results[0] })
      dispatch({ type: SET_FETCHED_CLASSES_DATA, payload: true })
    } else {
      dispatch({ type: SET_FETCHED_CLASSES_DATA, payload: true })
    }
  }

  const fetchSchools = async () => {
    let res = await getRequest(`/basic-company-data/?organisation=${Json.toJson(localStorage.getItem("organisation")).id}`)
    if (res.success) {
      dispatch({
        type: SET_ALL_SCHOOLS_DATA,
        payload: res.data,
      })
      dispatch({ type: SET_FETCHED_SCHOOLS_DATA, payload: true })
      dispatch({ type: SET_ACTIVE_SCHOOL_DATA, payload: res.data.results[0] })
    } else {
      console.error(res.data?.msg)
    }
  }

  const fetchEducators = async () => {
    let res = await getRequest(`/trainers/list/all/?type=all&company=${localStorage.getItem("company_id")}`)
    if (res.success) {
      dispatch({
        type: SET_ALL_EDUCATORS_DATA,
        payload: res.data,
      })
      dispatch({ type: SET_FETCHED_EDUCATORS_DATA, payload: true })
      if (!state.activeEducatorData) {
        dispatch({ type: SET_ACTIVE_EDUCATOR_DATA, payload: res.data[0] })
      }
    } else {
      console.error(res.data?.msg)
    }
  }

  const CustomDateTimeModal = () => {
    if (showDateTimeModal) {
      return (
        <div className={`position-fixed w-100 h-100 top-0 left-0 z-index-10 d-flex justify-content-center align-items-center ${Style.date_time_modal_holder}`}>
          <div className={`bg-121B2B br-10px border-white ${Style.date_time_modal}`}>
            <div className={`d-flex justify-content-between align-items-center p-2 ${Style.date_time_modal_header}`}>
              <p className="mb-0">Pick a date range</p>
              <Image
                src={Close}
                alt={`close`}
                width={20}
                height={20}
                className={`pointer`}
                onClick={() => {
                  setShowDateTimeModal(false)
                }}
              />
            </div>
            <div className={`d-flex flex-column justify-content-center align-items-center p-3`}>
              {dateError && (
                <div className="d-flex align-items-center mb-1">
                  <Image src={Error} alt={`error`} width={20} height={20} />
                  <p className="text-AA5746 mb-0 ml-2">End date must be greater than Start Date</p>
                </div>
              )}
              <p className="mb-1">From Date</p>
              <div className={`bg-303C54 px-2 py-1 border-white br-10px`}>
                <Image src={CalendarIcon} alt={`calendar`} width={30} height={30} />
                <CustomDateTimePicker
                  format="dd-MM-y"
                  minDate={new Date(`2022-12-31`)}
                  value={customStartDate}
                  onChange={(val) => {
                    setCustomStartDate(val)
                  }}
                />
              </div>
              <p className="my-1">To Date</p>
              <div className={`bg-303C54 px-2 py-1 border-white br-10px`}>
                <Image src={CalendarIcon} alt={`calendar`} width={30} height={30} />
                <CustomDateTimePicker
                  format="dd-MM-y"
                  minDate={new Date(`2022-12-31`)}
                  value={customEndDate}
                  onChange={(val) => {
                    setCustomEndDate(val)
                  }}
                />
              </div>
              <div
                className="bg-303C54 br-14px px-3 py-2 border-white mt-3 pointer"
                onClick={() => {
                  if (customStartDate > customEndDate) {
                    setDateError(true)
                    return
                  }
                  let period = `${customStartDate.getDate()}-${customStartDate.getMonth() + 1}-${customStartDate.getFullYear()} to ${customEndDate.getDate()}-${
                    customEndDate.getMonth() + 1
                  }-${customEndDate.getFullYear()}`
                  if (activeTab === 1) {
                    dispatch({ type: SET_SCHOOL_LEVEL_TIME_PERIOD, payload: period })
                  } else if (activeTab === 2) {
                    dispatch({ type: SET_EDUCATOR_LEVEL_TIME_PERIOD, payload: period })
                  }
                  setShowDateTimeModal(false)
                  setDateError(false)
                }}
              >
                Done
              </div>
            </div>
          </div>
        </div>
      )
    } else return <></>
  }

  return (
    <div>
      <SEO title="Insights" />
      <div className={`pb-4 px-3 ${Style.insights}`}>
        <nav className={`d-flex ml-2 mb-3 bg-12182c position-fixed z-index-5 ${Style.nav}`}>
          {tabs.map((tab, index) => {
            return (
              <p
                key={`insight-tab-${index}`}
                className={`mb-0 pt-3 pb-3 mr-4 pointer ${activeTab === index ? `text-3EF7D3 bold-600 ${Style.selected_tab}` : `text-E0E2E5`}`}
                onClick={() => {
                  setActiveTab(index)
                }}
              >
                {tab}
              </p>
            )
          })}
        </nav>
        <div className={`${Style.menu}`}>
          <div className={`d-flex align-items-center mb-3 ml-2`}>
            {activeTab === 0 && <ClassLevelReportDropdowns />}
            {activeTab === 1 && (
              <SchoolLevelReportDropdowns
                customOnClick={() => {
                  setShowDateTimeModal(true)
                }}
              />
            )}
            {activeTab === 2 && (
              <EducatorLevelReportDropdowns
                customOnClick={() => {
                  setShowDateTimeModal(true)
                }}
              />
            )}
          </div>
          <div className="d-flex flex-wrap w-100 overflow-y-scroll pb-5 h-80vh">
            {activeTab === 0 ? <ClassLevelReport /> : activeTab === 1 ? <SchoolLevelReport /> : <EducatorLevelReport />}
          </div>
        </div>
      </div>

      <CustomDateTimeModal />
    </div>
  )
}

function Insights(props) {
  return (
    <InsightsReportsStore>
      <InsightsChildren {...props} />
    </InsightsReportsStore>
  )
}

export default Insights
