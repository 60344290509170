import React, { useContext, useEffect, useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import Image from "../custom/Image"
import Loader from "../custom/Loader"

import down_arrow_white from "../../images/svgs/down_arrow_white.svg"
import Avatar from "../../images/svgs/trainer-profile-pic.svg"
import CalendarIcon from "../../images/svgs/calendar.svg"

import { InsightsReportsContext } from "../../stores/insights_reports/InsightsReportsStore"
import { TimePeriod } from "./constants"
import InClassOrOverallChoice from "./InClassOrOverall"

import Style from "./style.module.scss"
import { SET_ACTIVE_EDUCATOR_DATA, SET_EDUCATOR_LEVEL_REPORTS_DATA, SET_EDUCATOR_LEVEL_TIME_PERIOD } from "../../stores/insights_reports/InsightsReportsActions"
import { getRequest } from "../../services/rest_service"
import { getFromDateAndToDate } from "./utilities"
import EngagementScoresBar from "./FacilitatorEngagement/engagementScoresBar"

function EducatorLevelReportDropdowns(props) {
  const [state, dispatch] = useContext(InsightsReportsContext)
  const currentEducatorData =
    state.educatorLevelReportsData[`educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}_${state.inClassOrOverall}`]
  const educatorSummary = currentEducatorData?.educator_summary

  const [fetchedEducatorSummary, setfetchedEducatorSummary] = useState(true)

  useEffect(() => {
    if (state.activeEducatorData?.id && !currentEducatorData?.educator_summary) {
      setfetchedEducatorSummary(false)
      fetchEducatorSummary(state.activeEducatorData?.id)
    }
  }, [state.activeEducatorData?.id, state.educatorLevelTimePeriod, state.inClassOrOverall])

  const fetchEducatorSummary = async (educator_id) => {
    try {
      const { data, success } = await getRequest(
        `/engagement-score-educator-reports/educator_summary/educator/${educator_id}/${getFromDateAndToDate(state.educatorLevelTimePeriod)}only_live_class=${
          state.inClassOrOverall === "In-Class"
        }`
      )
      if (success) {
        setfetchedEducatorSummary(true)
        dispatch({
          type: SET_EDUCATOR_LEVEL_REPORTS_DATA,
          payload: {
            key: `educator_${state.activeEducatorData?.id}_period_${state.educatorLevelTimePeriod}_${state.inClassOrOverall}`,
            report_type: `educator_summary`,
            value: data,
          },
        })
      } else {
        setfetchedEducatorSummary(true)
      }
    } catch (error) {
      setfetchedEducatorSummary(true)
      console.error(error)
    }
  }

  const getTotalScore = () => {
    if (educatorSummary?.engagement_scores) {
      const total_score = educatorSummary?.engagement_scores?.reduce((total, element) => total + element.percentage, 0) || 0
      if ((total_score + "").includes(".")) {
        return total_score.toFixed(2)
      }
      return total_score
    } else return 0
  }

  return (
    <div className="d-flex align-items-center">
      <Dropdown className={`mr-3`}>
        <Dropdown.Toggle className={`bg-303C54 border-0 pr-3 caret-none d-flex align-items-center ${Style.primaryDropdownToggle}`}>
          {state.fetchedEducatorsData ? (
            <>
              <p className="mb-0 mr-auto d-inline w-100 ellipsis pr-2 fs-14px">{state.activeEducatorData?.name}</p>
              <Image className={`ml-auto pointer icon}`} src={down_arrow_white} />
            </>
          ) : (
            <Loader />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${Style.primaryDropdownMenu}`}>
          {state.allEducatorsData &&
            state.allEducatorsData.map((educator, index) => {
              return (
                <Dropdown.Item
                  key={`educator-${educator.id}`}
                  onClick={() => {
                    if (state.activeEducatorData.id !== educator.id) {
                      dispatch({ type: SET_ACTIVE_EDUCATOR_DATA, payload: educator })
                    }
                  }}
                  className={`${Style.dropdownItem} fs-14px`}
                >
                  {educator.name}
                </Dropdown.Item>
              )
            })}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className={`mr-3`}>
        <Dropdown.Toggle className={`bg-303C54 border-0 px-3 caret-none d-flex align-items-center ${Style.primaryDropdownToggle} w-fit-content`}>
          <p className="mb-0 mr-auto d-inline w-100 pr-4 fs-14px">{state.educatorLevelTimePeriod}</p>
          <Image className={`ml-auto pointer icon}`} src={down_arrow_white} />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${Style.primaryDropdownMenu} w-fit-content`}>
          {TimePeriod.map((period, index) => {
            return (
              <Dropdown.Item
                key={`${period}`}
                onClick={() => {
                  if (state.educatorLevelTimePeriod !== period) {
                    dispatch({ type: SET_EDUCATOR_LEVEL_TIME_PERIOD, payload: period })
                  }
                }}
                className={`${Style.dropdownItem} fs-14px`}
              >
                {period}
              </Dropdown.Item>
            )
          })}
          <Dropdown.Item className={`${Style.dropdownItem}`} onClick={props.customOnClick}>
            <div className="d-flex align-items-center">
              <Image src={CalendarIcon} alt={`custom`} width={25} height={25} />
              <p className="mb-0 d-inline fs-14px pb-1">Custom</p>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <InClassOrOverallChoice />
      <div className={`d-flex align-items-center bg-303C54 br-10px ${Style.trainerDetails}`}>
        {fetchedEducatorSummary ? (
          <>
            <Image src={educatorSummary?.profile_pic || Avatar} alt={`trainer`} width={40} height={40} className={`circle ${Style.trainerImage}`} />
            <div className={`mr-3 pl-14px`}>
              <p className="mb-0 bold-500 fs-14px">{educatorSummary?.full_name}</p>
              <p className="mb-0 fs-12px">{educatorSummary?.designation}</p>
            </div>
            <div className={`px-2 py-1 br-10px text-center mr-3 align-self-stretch ${Style.trainerDetails}`}>
              <p className="mb-0 fs-14px bold-500">Classes Taken</p>
              <p className="mb-0 bold-600 fs-20px">{educatorSummary?.classes_taken}</p>
            </div>
            <div className={`px-2 py-1 br-10px d-flex flex-column text-center align-self-stretch ${Style.trainerDetails}`}>
              <p className="mb-2 fs-14px bold-500">Average Engagment Score</p>
              <div className="d-flex align-items-center w-100 justify-content-center">
                <EngagementScoresBar engagement_scores={educatorSummary?.engagement_scores} className={`w-60per`} />
                <p className="mb-0 bold-600 fs-14px pl-2">{getTotalScore()}%</p>
              </div>
            </div>
          </>
        ) : (
          <div className="w-100 d-flex h-100 justify-center align-items-center">
            <Loader class={`text-white mx-auto`} />
          </div>
        )}
      </div>
    </div>
  )
}

export default EducatorLevelReportDropdowns
