import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Pagination from "react-bootstrap/Pagination"

import Layout from "../layout"
import SEO from "../seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

const ValuePacks = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [valuepackLoader, setValuePackLoader] = useState(false)
  const [valuepacks, setValuePacks] = useState([])

  const [previous_url, setPreviousUrl] = useState()
  const [next_url, setNextUrl] = useState()
  const [total_pages, setTotalPages] = useState()

  useEffect(() => {
    fetchCompanies()
  }, [])

  async function fetchCompanies() {
    setValuePackLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/valuepacks/`, { headers: login_credentials }).then((res) => {
      setValuePacks(res.data.results)
      setNextUrl(res.data.next)
      setPreviousUrl(res.data.previous)
      setTotalPages(parseInt(Number(res.data.count) / 5))
      setValuePackLoader(true)
    })
  }

  function displayCompanyHeader() {
    let data = valuepacks
    var isLoaded = valuepackLoader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0])
        let heading = []
        heading.push(<td>&nbsp;</td>)
        heading.push(<td>&nbsp;</td>)
        for (let key in keys) {
          if (keys[key].toLowerCase() !== "created" && keys[key].toLowerCase() !== "updated_at") {
            heading.push(<th style={{ textTransform: "uppercase" }}>{keys[key].replace(/_/g, " ")}</th>)
          }
        }
        return <tr>{heading}</tr>
      } else {
        return ""
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function displayCompanyData() {
    let data = valuepacks
    var isLoaded = valuepackLoader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0]),
          parsed_data = []
        data.map((datum, index) => {
          let values = []
          values.push(
            <td>
              <Link to={`/app/valuepacks/edit/` + datum.id}> Edit</Link>
            </td>
          )
          values.push(
            <td>
              <Link to={`/app/valuepacks/view/` + datum.id}> View</Link>
            </td>
          )
          for (let key in keys) {
            if (keys[key].toLowerCase() !== "created" && keys[key].toLowerCase() !== "updated_at") {
              values.push(<td> {datum[keys[key]]} </td>)
            }
          }
          parsed_data.push(<tr key={index}>{values}</tr>)
        })
        return <tbody>{parsed_data}</tbody>
      } else {
        return (
          <tr id="loader">
            <td colSpan="12" className="animated fadeIn pt-3 text-center">
              No Data found...
            </td>
          </tr>
        )
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function showCompanies() {
    if (authClient.isAuthenticated()) {
      return (
        <table>
          {displayCompanyHeader()}
          {displayCompanyData()}
        </table>
      )
    } else {
      return ""
    }
  }

  function fetchPage(page) {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/valuepacks/?page=" + page, { headers: DefaultHeader })
      .then((res) => {
        setValuePacks(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function fetchNext() {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(next_url, { headers: DefaultHeader })
      .then((res) => {
        setValuePacks(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }
  function fetchPrevious() {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(previous_url, { headers: DefaultHeader })
      .then((res) => {
        setValuePacks(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function renderPageLinks() {
    var rows = []
    if (previous_url) {
      rows.push(<Pagination.Prev onClick={() => fetchPrevious()} />)
    }
    for (var i = 0; i < total_pages; i++) {
      rows.push(<Pagination.Item onClick={() => fetchPage(i + 1)}>{i + 1}</Pagination.Item>)
    }
    if (next_url) {
      rows.push(<Pagination.Next onClick={() => fetchNext()} />)
    }
    return <Pagination>{rows}</Pagination>
  }

  return (
    <div>
      <SEO title="ValuePacks" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>All ValuePacks</h4>
          {renderPageLinks()}
          <Row style={{ overflow: "scroll" }}>{showCompanies()}</Row>
          <Link to={"/app/valuepacks/create"}>Add ValuePack</Link>
        </Col>
      </Row>
    </div>
  )
}

export default ValuePacks
