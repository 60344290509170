import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"
// import hideMessage from '../services/hidemessage';
import Notification from "./custom/Notification"

import axios from "axios"
import Col from "react-bootstrap/Col"
import InputGroup from "react-bootstrap/InputGroup"
import FormGroup from "react-bootstrap/FormGroup"
import FormControl from "react-bootstrap/FormControl"
import Button from "react-bootstrap/Button"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import Form from "react-bootstrap/Form"
import Spinner from "react-bootstrap/Spinner"
import ProgressBar from "react-bootstrap/ProgressBar"
import Modal from "react-bootstrap/Modal"

import { LazyLoadImage } from "react-lazy-load-image-component"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Styles from "../modularscss/upload.module.scss"
import authClient from "../services/auth"
import { isBrowser } from "../services/developer_mode"

import queryString from "query-string"

import SunEditor from "suneditor-react"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle, faTrash, faUpload, faLink, faFile, faChevronLeft } from "@fortawesome/free-solid-svg-icons"

import videoImage from "../images/svgs/video.svg"

const Upload = (props) => {
  let dropArea = isBrowser() ? document.getElementById("drop-area") : null

  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [video, setVideo] = useState({})
  const [upload_files, setUploadFiles] = useState(true)
  const [uploadedVideos, setUploadedVideos] = useState([])

  const [upload_videos, setUploadVideos] = useState(false)
  const [videoArray, setVideoArray] = useState([])
  const [videos_data, setVideosData] = useState({})
  const [video_loading, setVideoLoading] = useState([])
  const [link_index, setLinkIndex] = useState(1)
  const [url, setUrl] = useState("")
  const [typingTimer, setTypingTimer] = useState("") //timer identifier
  const [doneTypingInterval, setDoneTypingInterval] = useState(1500) //time in ms, 5 second for example

  const [selected_index, setSelectedIndex] = useState(1)

  const [blog, setBlog] = useState({ description: "Add your description here" })
  const [upload_blogs, setUploadBlogs] = useState(false)
  const [blog_description, setBlogDescription] = useState("")

  const [highlight, setHighlight] = useState("unhighlight")
  const [progressBar, setProgressBar] = useState(0)
  const [files, setFiles] = useState([])
  const [currentImage, setCurrentImage] = useState({})
  const [currentImageName, setCurrentImageName] = useState("")

  const [hashValue, setHashValue] = useState(props.props.location.hash.replace(`#`, ""))

  const [dmodal, setDmodal] = useState(false)
  const [currentVideo, setCurrentVideo] = useState({})

  const [notificationData, setNotificationData] = useState(null)

  const handleClose = () => {
    setDmodal(false)
    setCurrentVideo({})
  }

  useEffect(() => {
    if (hashValue === "videos") {
      setUploadFiles(faLink)
      setUploadVideos(true)
      setUploadBlogs(false)
    } else if (hashValue === "blogs") {
      setUploadFiles(false)
      setUploadVideos(false)
      setUploadBlogs(true)
    } else if (hashValue === "files") {
      setUploadFiles(true)
      setUploadVideos(false)
      setUploadBlogs(false)
    } else {
      setHashValue("")
    }
  }, [hashValue])

  // function fetchVideoData(e) {
  //   console.log(e.target.value)
  // }

  // document.body.on('click', function (e) {
  //     //did not click a popover toggle or popover
  //     if ($(e.target).data('toggle') !== 'popover'
  //         && $(e.target).parents('.popover.in').length === 0) {
  //         $('[data-toggle="popover"]').popover('hide');
  //     }
  // });

  function parseVideo(url) {
    // - Supported YouTube URL formats:
    //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
    //   - http://youtu.be/My2FRPA3Gf8
    //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
    // - Supported Vimeo URL formats:
    //   - http://vimeo.com/25451551
    //   - http://player.vimeo.com/video/25451551
    // - Also supports relative URLs:
    //   - //player.vimeo.com/video/25451551

    url.match(
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
    )

    if (RegExp.$3.indexOf("youtu") > -1) {
      var type = "youtube"
    } else if (RegExp.$3.indexOf("vimeo") > -1) {
      var type = "vimeo"
    }

    return {
      type: type,
      id: RegExp.$6,
    }
  }

  function secondsToHms(d) {
    if (d) {
      d = Number(d)
      var h = Math.floor(d / 3600)
      var m = Math.floor((d % 3600) / 60)
      var s = Math.floor((d % 3600) % 60)

      var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : ""
      var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : ""
      var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : ""
      return hDisplay + mDisplay + sDisplay
    } else {
      return "Duration cannot be found for this video"
    }
  }
  function calculatePoints(d) {
    if (d) {
      d = Number(d)
      return Math.ceil(d / 500)
    } else {
      return "Points cannot be calculated for this video"
    }
  }
  function calculatePointsToSave(d) {
    if (d) {
      d = Number(d)
      return Math.ceil(d / 500)
    } else {
      return 0
    }
  }
  function fetchVideoData(event) {
    let parsed_video = parseVideo(url)
    if (parsed_video.type === "youtube") {
      axios.get(`https://www.youtube.com/oembed?url=${url}&format=json`, { headers: login_credentials }).then((res) => {
        if (res.data.title !== undefined) {
          videos_data[selected_index] = res.data
          videos_data[selected_index]["url"] = url
          videos_data[selected_index]["channel"] = "youtube"
          videos_data[selected_index]["sequence"] = 1
          videos_data[selected_index]["video_id"] = parsed_video.id
          videos_data[selected_index]["duration"] = res.data.duration ? res.data.duration : 0
          videos_data[selected_index]["points"] = calculatePointsToSave(res.data.duration)
          videoLoadEnable(selected_index, false)
          setVideo(videos_data[selected_index])
          handleSubmit(videos_data[selected_index])
          let v_array = { ...videoArray }
          v_array[selected_index] = true
          setVideosData(videos_data)
          setVideoArray(v_array)
        } else {
          videoLoadEnable(selected_index, false)
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>We could not fetch the details. Please check your privacy settings for this video on youtube</div>'
        }
      })
    } else if (parsed_video.type === "vimeo") {
      axios.get(`https://vimeo.com/api/oembed.json?url=${url}`, { "Access-Control-Allow-Origin": "*" }).then((res) => {
        if (res.data.title !== undefined) {
          videos_data[selected_index] = res.data
          videos_data[selected_index]["url"] = url
          videos_data[selected_index]["channel"] = "vimeo"
          videos_data[selected_index]["sequence"] = 1
          videos_data[selected_index]["duration"] = res.data.duration ? res.data.duration : 0
          videos_data[selected_index]["points"] = calculatePointsToSave(res.data.duration)
          videoLoadEnable(selected_index, false)
          setVideo(videos_data[selected_index])
          handleSubmit(videos_data[selected_index])
          let v_array = { ...videoArray }
          v_array[selected_index] = true
          setVideosData(videos_data)
          setVideoArray(v_array)
        } else {
          videoLoadEnable(selected_index, false)
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>We could not fetch the details. Please check your privacy settings for this video on vimeo</div>'
        }
      })
    } else {
      setVideoLoading(selected_index, false)
    }
  }

  function handleChange(event) {
    event.preventDefault()
    if (event.target.name === "end_date" || event.target.name === "start_date") {
      video[event.target.name] = new Date(event.target.value)
    } else video[event.target.name] = event.target.value
  }
  function handleSubmit(event) {
    // event.preventDefault();
    // console.log(fetched_video, "dssdsdsas", video, videos_data)
    // if (fetched_video ){
    //   video = fetched_video
    // }
    let video = videos_data[selected_index]
    let keys = ["name", "title", "channel", "url", "sequence", "description", "video_id", "thumbnail_url", "duration", "points"]
    var formData = new FormData()
    for (var k in video) {
      if (keys.indexOf(k) != -1) {
        if (k === "name" || k === "title") {
          formData.append("name", video[k])
        } else formData.append(k, video[k])
      }
    }
    formData.append("is_added_to_library", true)

    let v_array = { ...videoArray }
    v_array[selected_index] = false
    if (video.id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/videos/" + video.id + "/", formData, { headers: login_credentials })
        .then((res) => {
          v_array[selected_index] = true
          setVideoArray(v_array)
          // hideMessage.displaySuccessMsg('Video has been updated successfully')
          setNotificationData({ type: "success", title: "Video has been updated successfully" })
          document.body.click()
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      var video_id = video.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/videos/create/", formData, { headers: login_credentials })
        .then((res) => {
          videos_data[selected_index]["id"] = res.data.id
          setVideosData(videos_data)
          // hideMessage.displaySuccessMsg('Video has been successfully added')
          setNotificationData({ type: "success", title: "Video has been successfully added" })
          document.body.click()
          // navigate('/app/videos')
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()

            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            // window.location.reload(true);
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function handleSubmitVideo(video, i) {
    video.title = video.name
    let keys = ["name", "title", "channel", "url", "sequence", "description", "video_id", "thumbnail_url", "duration", "points"]
    var formData = new FormData()
    formData.append("is_added_to_library", true)

    for (var k in video) {
      if (keys.indexOf(k) != -1) {
        if (k === "name" || k === "title") {
          formData.append("name", video[k])
        } else {
          formData.append(k, video[k])
        }
      } else if (k === "video_file" && video.id === undefined) {
        formData.append("video_file", video[k])
      }
    }
    // let v_array = {...videoArray}
    // v_array[selected_index]=false;
    if (video.id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/videos/" + video.id + "/", formData, { headers: login_credentials })
        .then((res) => {
          let newFile = uploadedVideos[i]
          uploadedVideos[i] = newFile

          // let new_file = files[i]
          // files.splice(i,)
          // new_file.name = res.data.name

          // files[i] = new_file
          newFile = files[i]
          newFile.new_name = res.data.name
          files[i] = newFile

          // newFile = uploadedVideos[i]

          setVideo("")

          // hideMessage.displaySuccessMsg('Video has been updated successfully')
          setNotificationData({ type: "success", title: "Video has been updated successfully" })
          document.body.click()
        })
        .catch((error) => {
          console.log(error)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      var video_id = video.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/videos/create/", formData, { headers: login_credentials })
        .then((res) => {
          // videos_data[selected_index]['id'] = res.data.id;
          // setVideosData(videos_data);
          video.id = res.data.id
          video.created = res.data.created
          updateProgress(i, 100)
          video.save = true
          let new_file = files[i]
          new_file.id = res.data.id
          new_file.created = res.data.created
          new_file.save = true
          files[i] = new_file
          uploadedVideos[i] = video
          // hideMessage.displaySuccessMsg('Video has been successfully added')
          setNotificationData({ type: "success", title: "Video has been successfully added" })
          document.body.click()
          // navigate('/app/videos')
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()

            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            // window.location.reload(true);
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function handleBlogChange(event) {
    event.preventDefault()
    blog[event.target.name] = event.target.value
  }
  function handleBlogSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in blog) {
      if (k === "description") {
        formData.append("description", blog_description)
      } else formData.append(k, blog[k])
    }
    formData.append("is_added_to_library", true)

    if (blog.id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/blogs/" + blog.id + "/", formData, { headers: login_credentials })
        .then((res) => {
          // hideMessage.displaySuccessMsg('Content has been successfully updated')
          setNotificationData({ type: "success", title: "Content has been successfully updated" })
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/blogs/create/", formData, { headers: login_credentials })
        .then((res) => {
          setBlog(res.data)
          // navigate('/app/blogs')
          // hideMessage.displaySuccessMsg('Content has been successfully added')
          setNotificationData({ type: "success", title: "Content has been successfully added" })
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  const renderTooltip = (props) => (
    <Popover className="pt-4 pb-4 pl-0 pr-0" id="video-tooltip" {...props}>
      <Col xs="6" sm="6" md="6" className="float-left">
        <Form
          className="form-horizontal"
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmit()
          }}
        >
          <Col xs="12" sm="6" md="12" className="p-0">
            <label className="video-modal">
              <input className="video-modal" type="text" name="name" defaultValue={video.title} onChange={handleChange} id="name" placeholder=" " required />
              <span className="video-modal">Name</span>
            </label>
          </Col>
          <Col xs="12" sm="6" md="12" className="p-0">
            <label className="video-modal">
              <input
                className="video-modal"
                type="number"
                name="duration"
                defaultValue={video.duration}
                onChange={handleChange}
                id="duration"
                placeholder=" "
                required
              />
              <span className="video-modal">Duration(In seconds)</span>
            </label>
          </Col>
          <Col xs="12" sm="6" md="12" className="p-0">
            <label className="video-modal">
              <input
                className="video-modal"
                type="number"
                name="points"
                defaultValue={video.points}
                onChange={handleChange}
                id="points"
                placeholder=" "
                required
              />
              <span className="video-modal">Points</span>
            </label>
          </Col>
          <Col xs="12" sm="6" md="12" className="p-0">
            <label className="video-modal">
              <textarea
                className="video-modal"
                type="text"
                name="description"
                defaultValue={video.description}
                onChange={handleChange}
                id="description"
                placeholder=" "
                required
              />
              <span className="video-modal">Description</span>
            </label>
          </Col>
          <input type="hidden" name="sequence" defaultValue={1} id="sequence" placeholder="Enter video sequence" required />
          <Button type="submit" className="box-shadow pl-4 pr-4" style={{ background: "#485b9e", border: "none" }}>
            {" "}
            Save{" "}
          </Button>
        </Form>
      </Col>
      <Col xs="6" sm="6" md="6" className="float-left p-4" style={{ fontSize: "12px", color: "#a2a9c5" }}>
        Uploaded on {new Date().toLocaleString()}
        <p className="mb-0"> Uploaded By: {localStorage.getItem("student_first_name")} </p>
        <p className="mb-0"> Video url: {url} </p>
        <p className="mb-0"> File type: Video</p>
      </Col>
    </Popover>
  )

  const renderTooltipVideo = (index) => (
    // console.log(video, "saasaas"),
    <Popover className="pt-4 pb-4 pl-0 pr-0" id="video-tooltip">
      <Col xs="6" sm="6" md="6" className="float-left">
        <Form
          className="form-horizontal"
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmitVideo(video, index)
          }}
        >
          <Col xs="12" sm="6" md="12" className="p-0">
            <label className="video-modal">
              <input className="video-modal" type="text" name="name" defaultValue={video.title} onChange={handleChange} id="name" placeholder=" " required />
              <span className="video-modal">Name</span>
            </label>
          </Col>
          <Col xs="12" sm="6" md="12" className="p-0">
            <label className="video-modal">
              <input
                className="video-modal"
                type="number"
                name="duration"
                defaultValue={video.duration}
                onChange={handleChange}
                id="duration"
                placeholder=" "
                required
              />
              <span className="video-modal">Duration(In seconds)</span>
            </label>
          </Col>
          <Col xs="12" sm="6" md="12" className="p-0">
            <label className="video-modal">
              <input
                className="video-modal"
                type="number"
                name="points"
                defaultValue={video.points}
                onChange={handleChange}
                id="points"
                placeholder=" "
                required
              />
              <span className="video-modal">Points</span>
            </label>
          </Col>
          <Col xs="12" sm="6" md="12" className="p-0">
            <label className="video-modal">
              <textarea
                className="video-modal"
                type="text"
                name="description"
                defaultValue={video.description}
                onChange={handleChange}
                id="description"
                placeholder=" "
                required
              />
              <span className="video-modal">Description</span>
            </label>
          </Col>
          <input type="hidden" name="sequence" defaultValue={1} id="sequence" placeholder="Enter video sequence" required />
          <Button type="submit" className="box-shadow pl-4 pr-4" style={{ background: "#485b9e", border: "none" }}>
            {" "}
            Save{" "}
          </Button>
        </Form>
      </Col>
      <Col xs="6" sm="6" md="6" className="float-left p-4" style={{ fontSize: "12px", color: "#a2a9c5" }}>
        Uploaded on {new Date().toLocaleString()}
        <p className="mb-0"> Uploaded By: {localStorage.getItem("student_first_name")} </p>
        <p className="mb-0"> Video url: {url} </p>
        <p className="mb-0"> File type: Video</p>
      </Col>
    </Popover>
  )

  function removeVideo(index) {
    videos_data[index] = null
    let v_array = { ...videoArray }
    v_array[index] = false
    setVideosData(videos_data)
    setVideoArray(v_array)
  }

  function showInputs() {
    const buttons = []
    for (let i = 0; i < link_index; i++) {
      if (videoArray[i]) {
        let background_url = videos_data[i].thumbnail_url
        buttons.push(
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            className={`p-0 mb-3 pointer d-flex-align-center`}
            style={{ background: "#f7f7f7", position: "relative", borderRadius: "10px" }}
          >
            <Col xs="2" sm="2" md="2" lg="2" className="px-0 text-center d-flex-align-center" style={{ height: "120px" }}>
              <img
                style={{ height: "120px", marginBottom: 0, borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}
                src={background_url}
                alt="video_background_image"
                className={``}
                layout="fill"
                objectFit={`contain`}
              />
            </Col>
            <Col xs="8" sm="8" md="8" lg="8" className="">
              <h6 style={{ color: "#828386" }} className={`m-0`}>
                {videos_data[i].title}
              </h6>
              <p style={{ color: "#828386", fontSize: "12px" }} className={`m-0`}>
                {secondsToHms(videos_data[i].duration)}
              </p>
              <p style={{ color: "#828386", fontSize: "12px" }} className={`m-0`}>
                {" "}
                Points: {calculatePoints(videos_data[i].duration)}
              </p>
            </Col>

            <FontAwesomeIcon
              onClick={() => {
                removeVideo(i)
              }}
              className="add-button-video-2"
              icon={faTrash}
              size="lg"
              style={{ color: "#000", marginBottom: "10px" }}
            />
            <OverlayTrigger
              id={`classroom-${videos_data[i].name}`}
              placement="bottom-end"
              flip={true}
              trigger="click"
              overlay={renderTooltip}
              arrowProps={""}
              rootClose
            >
              <FontAwesomeIcon
                onClick={() => {
                  setSelectedIndex(i)
                  setVideo(videos_data[i])
                }}
                className="add-button-video"
                icon={faPlusCircle}
                size="lg"
                style={{ color: "#000", marginBottom: "10px" }}
              />
            </OverlayTrigger>
          </Col>
        )
        // buttons.push(videos_data[i].title);
      } else {
        buttons.push(
          <InputGroup className="mb-3" id={`link_div-${i}`}>
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faLink} style={{ color: "#a9adba", marginBottom: "10px", width: `20px`, height: `20px` }} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Add Vimeo/ YouTube Link"
              aria-label="Username"
              aria-describedby="basic-addon1"
              id={i}
              // value={set}
              onKeyUp={(e) => {
                clearTimeout(typingTimer)
                setSelectedIndex(i)
                setUrl(e.target.value)
                videoLoadEnable(i, true)
                setTypingTimer(setTimeout(fetchVideoData, doneTypingInterval))
              }}
              onKeyDown={(e) => {
                clearTimeout(typingTimer)
                setUrl(e.target.value)
                videoLoadEnable(i, false)
              }}
              onChange={(e) => {
                clearTimeout(typingTimer)
                setSelectedIndex(i)
                setUrl(e.target.value)
                videoLoadEnable(i, true)
                setTypingTimer(setTimeout(fetchVideoData, doneTypingInterval))
              }}
            />
            <Spinner animation="border" variant="danger" className={` ${video_loading[i] ? "spinner-video" : "d-none"} `} />
          </InputGroup>
        )
      }
    }
    return <ul> {buttons} </ul>
  }

  function videoLoadEnable(index, value) {
    let v_loading = { ...video_loading }
    v_loading[index] = value
    setVideoLoading(v_loading)
  }

  useEffect(() => {
    handleFiles(files)
  }, [files])

  function handleDrop(e) {
    var dt = e.dataTransfer
    var filesdata = dt.files
    setFiles([...files, ...filesdata])
    // handleFiles(files)
  }

  let uploadProgress = []
  // let progressBar = isBrowser() ? document.getElementById('progress-bar') : null

  function initializeProgress(numFiles) {
    // if (progressBar !== null) {
    // progressBar.value = 0
    setProgressBar(0)
    uploadProgress = []

    for (let i = numFiles; i > 0; i--) {
      uploadProgress.push(0)
    }
    // }
  }

  function updateProgress(fileNumber, percent) {
    uploadProgress[fileNumber] = percent
    let total = uploadProgress.reduce((tot, curr) => tot + curr, 0) / uploadProgress.length
    // console.debug('update', fileNumber, percent, total)
    // progressBar.value = total
    setProgressBar(total)
  }

  function handleFiles(files) {
    files = [...files]
    initializeProgress(files.length)
    files.forEach(uploadImages)
    // files.forEach(previewFile)
  }

  function previewFile(file) {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function () {
      let img = document.createElement("img")
      img.src = reader.result
      // document.getElementById('gallery').appendChild(img)
    }
  }

  // function convertImgToBase64URL(canvas){
  //   var img = new Image();
  //   img.crossOrigin = 'Anonymous';
  //   img.onload = function(){
  //       let dataURL = canvas.toDataURL("image/png");
  //       canvas = null;
  //   };
  //   // img.src = url;
  // }

  function createBlob(dataURL) {
    var BASE64_MARKER = ";base64,"
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(",")
      var contentType = parts[0].split(":")[1]
      var raw = decodeURIComponent(parts[1])
      return new Blob([raw], { type: contentType })
    }
    var parts = dataURL.split(BASE64_MARKER)
    var contentType = parts[0].split(":")[1]
    var raw = window.atob(parts[1])
    var rawLength = raw.length

    var uInt8Array = new Uint8Array(rawLength)

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i)
    }

    return new Blob([uInt8Array], { type: contentType })
  }

  function uploadImages(file, i) {
    // if (parseInt(file.size) < 100000000){
    if (file.save && file.save === true) {
      updateProgress(i, 100)
    } else {
      // var url = 'https://api.cloudinary.com/v1_1/joezimim007/image/upload'
      var xhr = new XMLHttpRequest()
      var formData = new FormData()
      // xhr.open('POST', url, true)
      // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

      // Update progress (can be used to show progress indicator)
      xhr.upload.addEventListener("progress", function (e) {
        updateProgress(i, (e.loaded * 100.0) / e.total || 100)
      })

      // xhr.addEventListener('readystatechange', function(e) {
      //   if (xhr.readyState == 4 && xhr.status == 200) {
      //     updateProgress(i, 100) // <- Add this
      //   }
      //   else if (xhr.readyState == 4 && xhr.status != 200) {
      //     // Error. Inform the user
      //   }
      // })
      let type = "images"
      if (file.type == "video/mp4") {
        type = "videos"
        let myVideoPlayer = document.getElementById(`video-element-${i}`)
        // console.log(myVideoPlayer, "myVideoPlayer")
        if (myVideoPlayer) {
          // console.log("came for listeners", file.id)
          myVideoPlayer.addEventListener("loadedmetadata", function () {
            formData.append("channel", "turient")
            formData.append("duration", parseInt(myVideoPlayer.duration))
            formData.append("points", calculatePointsToSave(myVideoPlayer.duration))
            formData.append("name", file.name)
            formData.append("video_file", file)
            formData.append("is_added_to_library", true)

            if (file.id != undefined) {
            } else if (file.save !== `loading`) {
              axios
                .post(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/create/`, formData, { headers: login_credentials })
                .then((res) => {
                  file.id = res.data.id
                  file.duration = res.data.duration
                  file.points = res.data.points
                  file.description = res.description
                  file.title = res.data.name
                  // console.log("DSsdd")
                  file.created = res.data.created
                  updateProgress(i, 100)
                  file.save = true
                  file.avatar = res.data.avatar
                  let uVideo = res.data
                  uVideo.title = res.data.name
                  uploadedVideos[i] = uVideo
                  setVideo(uVideo)
                })
                .catch((error) => {
                  file.save = false
                  let error_messages = ""
                  for (var key in error.response.data) {
                    error_messages += error.response.data[key] + "<br>"
                  }
                  if (error_messages === "Invalid token.<br>") {
                    authClient.signOut()

                    localStorage.removeItem("authenticity_token")
                    localStorage.removeItem("username")
                    window.location.reload(true)
                  }
                  document.getElementById("fixed-error-div").innerHTML =
                    '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
                    '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
                    '<span aria-hidden="true">×</span></button>' +
                    error_messages +
                    "</div>"
                })
            }
          })
        }
      } else {
        formData.append("avatar", file)
        formData.append("name", file.name)
        formData.append("is_added_to_library", true)

        if (currentImage.id != undefined) {
        } else if (file.save !== `loading`) {
          var image_id = currentImage.id
          file.save = `loading`
          axios
            .post(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/create/`, formData, { headers: login_credentials })
            .then((res) => {
              file.id = res.data.id
              file.created = res.data.created
              updateProgress(i, 100)
              file.save = true
              // files.splice(index, 1)
              // let filexs = files
              // setFiles([...filexs])
              // setCurrentImageName('')
              // document.body.click()
            })
            .catch((error) => {
              file.save = false
              let error_messages = ""
              for (var key in error.response.data) {
                error_messages += error.response.data[key] + "<br>"
              }
              if (error_messages === "Invalid token.<br>") {
                authClient.signOut()

                localStorage.removeItem("authenticity_token")
                localStorage.removeItem("username")
                window.location.reload(true)
              }
              document.getElementById("fixed-error-div").innerHTML =
                '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
                '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
                '<span aria-hidden="true">×</span></button>' +
                error_messages +
                "</div>"
            })
        }
      }

      // var index = files.findIndex(res=> res.name === currentImage.name);
    }
  }

  function onDragEnter(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragLeave(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
  }

  function onDrop(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
    handleDrop(event)
  }

  function handleChangeData(event, type) {
    event.preventDefault()
    if (type === "image") {
      setCurrentImageName(event.target.value)
    }
  }

  /*function handleSubmitData(event,type) {
    event.preventDefault();
    var formData = new FormData();
    formData.append('avatar',currentImage)
    formData.append('name',currentImageName)
    var index = files.findIndex(res=> res.name === currentImage.name);
    if ( currentImage.id != undefined ){
    }else {
      var image_id = currentImage.id
      axios.post(process.env.GATSBY_REACT_APP_API_HOST+'/images/create/', formData,  { headers: login_credentials })
      .then(res => {
          files.splice(index, 1)
          let filexs = files
          setFiles([...filexs])
          setCurrentImageName('')
          document.body.click()
      }).catch(error => {
        let error_messages = ''
        for (var key in error.response.data){
          error_messages += error.response.data[key] + "<br>"
        }
        if(error_messages === 'Invalid token.<br>'){
          authClient.signOut();
        }
        document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
            '<span aria-hidden="true">×</span></button>'+
            error_messages +
        '</div>')
      });
    }
  }*/

  function handleSubmitData(event, type) {
    event.preventDefault()
    var formData = new FormData()
    formData.append("name", currentImageName)
    var index = files.findIndex((res) => res.name === currentImage.name)
    if (currentImage.id != undefined) {
      var image_id = currentImage.id
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/images/" + image_id + "/", formData, { headers: login_credentials })
        .then((res) => {
          let file = files[index]
          var blob = file.slice(0, file.size, file.type)
          let newFile = new File([blob], currentImageName, { type: file.type })
          newFile.id = file.id
          newFile.created = file.created
          newFile.save = file.save
          files[index] = newFile
          setCurrentImageName("")
          document.body.click()
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function deleteFiles(event, id) {
    event.preventDefault()
    var index = files.findIndex((res) => res.id === id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/images/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        files.splice(index, 1)
        let filexs = files
        setFiles([...filexs])
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function deleteVideo(event, id) {
    event.preventDefault()
    var index = files.findIndex((res) => res.id === id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/videos/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        files.splice(index, 1)
        let filexs = files
        setFiles([...filexs])
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  const renderTooltipImage = (props) => (
    <Popover className="pt-4 pb-4 pl-0 pr-0" id="image-tooltip" {...props}>
      <Col xs="6" sm="6" md="6" className="float-left">
        <Form className="form-horizontal" onSubmit={(event) => handleSubmitData(event, "image")}>
          {/*<Col xs="12" sm="6" md="12">
            <FormGroup>
              <Form.Label htmlFor="name">Name</Form.Label>
              <input type="text" name="title" defaultValue={currentImageName === `` ? currentImage.name : currentImageName} onChange={(event) => handleChangeData(event,"image")} id="title" placeholder="Enter image name" required />
            </FormGroup>
          </Col>*/}
          <Col xs="12" sm="6" md="12" className="p-0">
            <label className="video-modal">
              <input
                className="video-modal"
                type="text"
                name="title"
                defaultValue={currentImage.name}
                onChange={(event) => handleChangeData(event, "image")}
                id="title"
                placeholder=""
                required
              />
              <span className="video-modal">Name</span>
            </label>
          </Col>
          <Button type="submit" className="box-shadow pl-4 pr-4" style={{ background: "#485b9e", border: "none" }}>
            {" "}
            Save{" "}
          </Button>
        </Form>
      </Col>
      <Col xs="6" sm="6" md="6" className="float-left p-4" style={{ fontSize: "12px", color: "#a2a9c5" }}>
        Uploaded on {currentImage && currentImage.created ? currentImage.created.toString() : ``}
        <p className="mb-0"> Uploaded By: {localStorage.getItem("student_first_name")} </p>
        <p className="mb-0"> File name: {currentImage.name} </p>
        <p className="mb-0"> File type: {currentImage.type}</p>
      </Col>
    </Popover>
  )

  // function capture(index){

  // }

  function validateAndAdd(event) {
    let new_files = event.target.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 100000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        // hideMessage.displayErrorMsg( new_files[i].name + ' has size more than 100MB.')
        setNotificationData({ type: "error", title: new_files[i].name + " has size more than 100MB." })
      }
    }
  }

  function showMainDiv() {
    if (upload_files === true) {
      let data = [...files]
      return (
        <div
          id={`drop-area`}
          className={`${Styles.drop_area} ${highlight === "highlight" ? Styles[highlight] : ``} text-center ${files.length === 0 ? `` : `border-0`}`}
          onDragEnter={onDragEnter}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="col-12 float-left">
            <div id="gallery" className={`mt-2 ${Styles.gallery}`}>
              {data && data.length > 0
                ? Object.keys(data).map((item, index) => {
                    if (data[item].type == "video/mp4") {
                      return null
                    } else {
                      return (
                        <div className={`float-left m-2 p-3`} key={index.toString() + `_image`} style={{ backgroundColor: `#fff7e6`, position: `relative` }}>
                          <img
                            src={
                              data[item].name.indexOf("pdf") > -1 || data[item].name.indexOf("PDF") > -1
                                ? `https://turient-static-bucket-dev.s3.amazonaws.com/buckets/20210205065006/turientimage.svg`
                                : URL.createObjectURL(data[item])
                            }
                            style={{ width: `150px`, height: `150px`, objectFit: `contain`, marginBottom: 0 }}
                          />
                          {(data[item] && data[item].save) || progressBar === 100 ? (
                            <OverlayTrigger
                              id={`images-${data[item].name}`}
                              placement="bottom-end"
                              flip={true}
                              trigger="click"
                              overlay={renderTooltipImage}
                              arrowProps={""}
                              rootClose
                            >
                              <FontAwesomeIcon
                                onClick={() => {
                                  setCurrentImage(data[item])
                                }}
                                className="pointer"
                                icon={faPlusCircle}
                                size="lg"
                                style={{
                                  marginBottom: "10px",
                                  color: `#161D34`,
                                  backgroundColor: `#8791B5`,
                                  position: `absolute`,
                                  bottom: 0,
                                  left: `70%`,
                                  borderRadius: `50%`,
                                  zIndex: 2,
                                }}
                              />
                            </OverlayTrigger>
                          ) : (
                            ``
                          )}
                          <p className={`m-0`} style={{ color: `#161D34`, fontSize: `11px` }}>
                            {data[item].save || progressBar === 100 ? `saved` : `saving...`}
                          </p>
                          {data[item].save || progressBar === 100 ? (
                            <FontAwesomeIcon
                              onClick={(e) => {
                                deleteFiles(e, data[item].id)
                              }}
                              className="pointer"
                              icon={faTrash}
                              size="lg"
                              style={{ marginBottom: "10px", color: `#161D34`, position: `absolute`, bottom: 0, left: `85%`, zIndex: 2 }}
                            />
                          ) : (
                            ``
                          )}
                        </div>
                      )
                    }
                  })
                : ``}
            </div>
          </div>
          <div className={`w-100 col-12`}>
            {progressBar > 0 || true ? (
              <ProgressBar
                striped
                variant="info"
                now={progressBar}
                label={`${progressBar}%`}
                className={`w-50 mx-auto`}
                style={{ height: `12px`, fontSize: `8px` }}
              />
            ) : (
              ""
            )}
            <Form className="my-form mt-3 mb-2">
              <FontAwesomeIcon icon={faUpload} style={{ color: "#a9adba", marginBottom: "10px", width: `20px`, height: `20px` }} />
              <p className={`${Styles.font_14}`} style={{ color: `#a9adba` }}>
                Upload multiple images with the Browse Button <br /> or <br /> by dragging and dropping images onto the dashed region{" "}
              </p>
              <input
                className={`d-none`}
                type="file"
                id="fileElem"
                multiple
                accept="image/*,application/pdf"
                onChange={(e) => {
                  e.preventDefault()
                  setFiles([...files, ...e.target.files])
                }}
              />
              <label className={`button btn btn-primary shadow-none border-0 px-3`} style={{ backgroundColor: `#485b9e` }} htmlFor="fileElem">
                Browse{" "}
              </label>
            </Form>
          </div>
          {/*<progress id="progress-bar" max="100" value="0"></progress>*/}
        </div>
      )
    } else if (upload_videos === true) {
      let data = [...files]
      return (
        <React.Fragment>
          <div id="add_links">{showInputs()}</div>
          <Col>
            <Button
              onClick={() => {
                setLinkIndex(link_index + 1)
              }}
              style={{ background: "#485b9e", border: "none", fontSize: "10px" }}
            >
              + Add More
            </Button>
          </Col>
          <h2
            style={{
              width: "100%",
              textAlign: "center",
              borderBottom: "1px dashed",
              lineHeight: "0.1em",
              margin: "50px 0 20px",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <span style={{ background: "#12182c", padding: "0 10px", color: "#fff" }}>Or Upload Video</span>
          </h2>
          <canvas id="canvas" className="d-none"></canvas>
          <div
            id={`drop-area`}
            className={`${Styles.drop_area} ${highlight === "highlight" ? Styles[highlight] : ``} text-center float-left w-100 ${
              files.length === 0 ? `` : `border-0`
            }`}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          >
            <div className="col-12 float-left">
              <div id="gallery" className={`mt-2 ${Styles.gallery}`}>
                {data && data.length > 0
                  ? Object.keys(data).map((item, index) => {
                      if (data[item].type == "video/mp4") {
                        return (
                          <div
                            className={`float-left m-2 p-3 ${data[item].save || progressBar === 100 ? `saved` : `disabled`}`}
                            key={index.toString() + `_image`}
                            style={{ backgroundColor: `#fff7e6`, position: `relative` }}
                          >
                            {data.length - 1 == index ? (
                              data[item].save && progressBar === 100 ? (
                                <div
                                  className="pointer"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setDmodal(true)
                                    setCurrentVideo(data[item])
                                  }}
                                  style={{ backgroundColor: "rgb(60, 100, 129)", borderRadius: "10px", width: "200px", height: "150px", marginBottom: "20px" }}
                                >
                                  <LazyLoadImage
                                    className={`ml-1`}
                                    src={videoImage}
                                    alt={item.name}
                                    style={{ position: `absolute`, left: 20, width: `30px`, height: `30px`, fontSize: `12px` }}
                                  />
                                  <span
                                    className={`float-right px-2 mt-2 mr-2 position-absolute`}
                                    style={{
                                      backgroundColor: `#00000080`,
                                      boxShadow: `0px 3px 6px #00000029`,
                                      borderRadius: `13px`,
                                      fontSize: `12px`,
                                      right: 15,
                                    }}
                                  >
                                    Video
                                  </span>
                                  <div
                                    className="d-flex justify-content-center"
                                    style={{
                                      alignItems: "flex-end",
                                      color: `#C6CFF4`,
                                      background: "#28314e",
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <p className={`elipsis-2 text-center px-2 pb-1 mt-auto position-absolute`}>
                                      {" "}
                                      &emsp;{data[item].new_name || data[item].name} {/*456*/}{" "}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <React.Fragment>
                                  <video className="d-none" style={{ width: "350px", height: "250px" }} id={`video-element-${index}`} controls>
                                    <source src={URL.createObjectURL(data[item])} type="video/mp4" />
                                  </video>
                                  <div
                                    className="pointer"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      setDmodal(true)
                                      setCurrentVideo(data[item])
                                    }}
                                    style={{
                                      backgroundColor: "rgb(60, 100, 129)",
                                      borderRadius: "10px",
                                      width: "200px",
                                      height: "150px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <LazyLoadImage
                                      className={`ml-1`}
                                      src={videoImage}
                                      alt={item.name}
                                      style={{ position: `absolute`, left: 20, width: `30px`, height: `30px`, fontSize: `12px` }}
                                    />
                                    <span
                                      className={`float-right px-2 mt-2 mr-2 position-absolute`}
                                      style={{
                                        backgroundColor: `#00000080`,
                                        boxShadow: `0px 3px 6px #00000029`,
                                        borderRadius: `13px`,
                                        fontSize: `12px`,
                                        right: 15,
                                      }}
                                    >
                                      Video
                                    </span>
                                    <div
                                      className="d-flex justify-content-center"
                                      style={{
                                        alignItems: "flex-end",
                                        color: `#C6CFF4`,
                                        background: "#28314e",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <p className={`elipsis-2 text-center px-2 pb-1 mt-auto position-absolute`}>
                                        {" "}
                                        &emsp;{data[item].new_name || data[item].name} {/*456*/}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )
                            ) : data[item].save || progressBar === 100 ? (
                              <div
                                className="pointer"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setDmodal(true)
                                  setCurrentVideo(data[item])
                                }}
                                style={{ backgroundColor: "rgb(60, 100, 129)", borderRadius: "10px", width: "200px", height: "150px", marginBottom: "20px" }}
                              >
                                <LazyLoadImage
                                  className={`ml-1`}
                                  src={videoImage}
                                  alt={item.name}
                                  style={{ position: `absolute`, left: 20, width: `30px`, height: `30px`, fontSize: `12px` }}
                                />
                                <span
                                  className={`float-right px-2 mt-2 mr-2 position-absolute`}
                                  style={{
                                    backgroundColor: `#00000080`,
                                    boxShadow: `0px 3px 6px #00000029`,
                                    borderRadius: `13px`,
                                    fontSize: `12px`,
                                    right: 15,
                                  }}
                                >
                                  Video
                                </span>
                                <div
                                  className="d-flex justify-content-center"
                                  style={{
                                    alignItems: "flex-end",
                                    color: `#C6CFF4`,
                                    background: "#28314e",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <p className={`elipsis-2 text-center px-2 pb-1 mt-auto position-absolute`}>
                                    {" "}
                                    &emsp;{data[item].new_name || data[item].name} {/*456*/}{" "}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <React.Fragment>
                                <video className="d-none" style={{ width: "350px", height: "250px" }} id={`video-element-${index}`} controls>
                                  <source src={URL.createObjectURL(data[item])} type="video/mp4" />
                                </video>
                                <div
                                  className="pointer"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setDmodal(true)
                                    setCurrentVideo(data[item])
                                  }}
                                  style={{ backgroundColor: "rgb(60, 100, 129)", borderRadius: "10px", width: "200px", height: "150px", marginBottom: "20px" }}
                                >
                                  <LazyLoadImage
                                    className={`ml-1`}
                                    src={videoImage}
                                    alt={item.name}
                                    style={{ position: `absolute`, left: 20, width: `30px`, height: `30px`, fontSize: `12px` }}
                                  />
                                  <span
                                    className={`float-right px-2 mt-2 mr-2 position-absolute`}
                                    style={{
                                      backgroundColor: `#00000080`,
                                      boxShadow: `0px 3px 6px #00000029`,
                                      borderRadius: `13px`,
                                      fontSize: `12px`,
                                      right: 15,
                                    }}
                                  >
                                    Video
                                  </span>
                                  <div
                                    className="d-flex justify-content-center"
                                    style={{
                                      alignItems: "flex-end",
                                      color: `#C6CFF4`,
                                      background: "#28314e",
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <p className={`elipsis-2 text-center px-2 pb-1 mt-auto position-absolute`}>
                                      {" "}
                                      &emsp;{data[item].name} {/*456*/}{" "}
                                    </p>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                            {data.length - 1 == index ? (
                              <React.Fragment>
                                {data[item] && data[item].save && progressBar === 100 ? (
                                  <OverlayTrigger
                                    id={`images-${data[item].name}`}
                                    placement="bottom-end"
                                    flip={true}
                                    index={index}
                                    trigger="click"
                                    overlay={renderTooltipVideo(index)}
                                    arrowProps={""}
                                    rootClose
                                  >
                                    <FontAwesomeIcon
                                      onClick={() => {
                                        setVideo(uploadedVideos[index])
                                      }}
                                      className="pointer"
                                      icon={faPlusCircle}
                                      size="lg"
                                      style={{
                                        marginBottom: "10px",
                                        color: `#161D34`,
                                        backgroundColor: `#8791B5`,
                                        position: `absolute`,
                                        bottom: 0,
                                        left: `70%`,
                                        borderRadius: `50%`,
                                        zIndex: 2,
                                      }}
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  ``
                                )}
                                <p className={`m-0`} style={{ color: `#161D34`, fontSize: `11px` }}>
                                  {data[item].save && progressBar === 100 ? `saved` : `saving...`}
                                </p>
                                {data[item].save && progressBar === 100 ? (
                                  <FontAwesomeIcon
                                    onClick={(e) => {
                                      deleteVideo(e, data[item].id)
                                    }}
                                    className="pointer"
                                    icon={faTrash}
                                    size="lg"
                                    style={{ marginBottom: "10px", color: `#161D34`, position: `absolute`, bottom: 0, left: `85%`, zIndex: 2 }}
                                  />
                                ) : (
                                  ``
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {(data[item] && data[item].save) || progressBar === 100 ? (
                                  <OverlayTrigger
                                    id={`images-${data[item].name}`}
                                    placement="bottom-end"
                                    flip={true}
                                    index={index}
                                    trigger="click"
                                    overlay={renderTooltipVideo(index)}
                                    arrowProps={""}
                                    rootClose
                                  >
                                    <FontAwesomeIcon
                                      onClick={() => {
                                        setVideo(uploadedVideos[index])
                                      }}
                                      className="pointer"
                                      icon={faPlusCircle}
                                      size="lg"
                                      style={{
                                        marginBottom: "10px",
                                        color: `#161D34`,
                                        backgroundColor: `#8791B5`,
                                        position: `absolute`,
                                        bottom: 0,
                                        left: `70%`,
                                        borderRadius: `50%`,
                                        zIndex: 2,
                                      }}
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  ``
                                )}
                                <p className={`m-0`} style={{ color: `#161D34`, fontSize: `11px` }}>
                                  {data[item].save || progressBar === 100 ? `saved` : `saving...`}
                                </p>
                                {data[item].save || progressBar === 100 ? (
                                  <FontAwesomeIcon
                                    onClick={(e) => {
                                      deleteVideo(e, data[item].id)
                                    }}
                                    className="pointer"
                                    icon={faTrash}
                                    size="lg"
                                    style={{ marginBottom: "10px", color: `#161D34`, position: `absolute`, bottom: 0, left: `85%`, zIndex: 2 }}
                                  />
                                ) : (
                                  ``
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        )
                      } else {
                        return null
                      }
                    })
                  : ``}
              </div>
            </div>
            <div className={`w-100 col-12 float-left`}>
              {progressBar > 0 || true ? (
                <ProgressBar
                  striped
                  variant="info"
                  now={progressBar}
                  label={`${progressBar}%`}
                  className={`w-50 mx-auto`}
                  style={{ height: `12px`, fontSize: `8px` }}
                />
              ) : (
                ""
              )}
              <Form className="my-form mt-3 mb-2">
                <FontAwesomeIcon icon={faUpload} style={{ color: "#a9adba", marginBottom: "10px", width: `20px`, height: `20px` }} />
                <p className={`${Styles.font_14}`} style={{ color: `#a9adba` }}>
                  Upload multiple videos with the Browse Button <br /> or <br /> by dragging and dropping videos onto the dashed region{" "}
                </p>
                <input
                  maxSize="20000"
                  className={`d-none`}
                  type="file"
                  id="fileElem"
                  multiple
                  accept="video/*"
                  onChange={(e) => {
                    e.preventDefault()
                    validateAndAdd(e)
                  }}
                />
                <label className={`button btn btn-primary shadow-none border-0 px-3`} style={{ backgroundColor: `#485b9e` }} htmlFor="fileElem">
                  Browse{" "}
                </label>
              </Form>
            </div>
            {/*<progress id="progress-bar" max="100" value="0"></progress>*/}
          </div>
        </React.Fragment>
      )
    } else if (upload_blogs === true) {
      return (
        <React.Fragment>
          <Form className="form-horizontal" onSubmit={handleBlogSubmit}>
            <Col xs="12" sm="6" md="12">
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faFile} style={{ color: "#a9adba", marginBottom: "10px", width: `20px`, height: `20px` }} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Title for this content"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={handleBlogChange}
                  defaultValue={blog.name}
                  name="name"
                  id="name"
                />
              </InputGroup>
            </Col>
            <input type="hidden" name="sequence" defaultValue={1} id="sequence" placeholder="Enter Blog sequence" required />
            <Col xs="12" sm="6" md="12" className="mb-3">
              <SunEditor
                setContents={blog.description}
                onChange={setBlogDescription}
                setOptions={{
                  height: "auto",
                  buttonList: [
                    ["undo", "redo", "font", "fontSize", "formatBlock"],
                    ["bold", "underline", "italic", "strike", "subscript", "superscript", "removeFormat"],
                    "/", // Line break
                    ["fontColor", "hiliteColor", "outdent", "indent", "align", "horizontalRule", "list", "table"],
                    ["link", "image", "video", "fullScreen", "showBlocks", "codeView", "preview", "print", "save"],
                  ],
                }}
              />
            </Col>
            <Col>
              <Button type="submit" style={{ background: "#485b9e", border: "none", fontSize: "10px", float: "right" }}>
                Save
              </Button>
              <Button
                onClick={() => {
                  setBlog({ description: "Add description Here", name: "" })
                }}
                style={{ background: "#485b9e", border: "none", fontSize: "10px" }}
              >
                + Add More
              </Button>
            </Col>
          </Form>
        </React.Fragment>
      )
    }
  }

  let location = window.location
  let { redirection_url } = queryString.parse(location.search)
  redirection_url =
    redirection_url !== undefined && redirection_url !== "undefined" ? `${redirection_url}` : hashValue === "" ? "/app/dashboard" : `/app/${hashValue}`
  return (
    <div>
      <SEO title="Resource Creation" />
      <div className={`col-12 p-3 position-fixed`}>
        <Link className={`pointer text-white`} to={redirection_url}>
          {" "}
          <FontAwesomeIcon icon={faChevronLeft} style={{ width: `24px`, height: `24px` }} />
        </Link>
      </div>
      <div className="justify-content-center text-white padding-mob" style={{ alignItems: "center", padding: "1rem 7rem 1rem 7rem" }}>
        <Col lg="12" md="12" sm="12" xs="12">
          <Button
            className={`shadow-none bg-transparent btn-without-border ${upload_files === true ? "btn-border-bottom" : ""} ${
              hashValue === "" || hashValue === "files" ? `` : `d-none`
            }`}
            onClick={() => {
              setProgressBar(0)
              setFiles([])
              setUploadFiles(true)
              setUploadVideos(false)
              setUploadBlogs(false)
            }}
          >
            Upload Files
          </Button>
          <Button
            className={`shadow-none bg-transparent btn-without-border ${upload_videos === true ? "btn-border-bottom" : ""} ${
              hashValue === "" || hashValue === "videos" ? `` : `d-none`
            }`}
            onClick={() => {
              setProgressBar(0)
              setFiles([])
              setUploadVideos(true)
              setUploadFiles(false)
              setUploadBlogs(false)
            }}
          >
            Add Videos
          </Button>
          <Button
            className={`shadow-none bg-transparent btn-without-border ${upload_blogs === true ? "btn-border-bottom" : ""} ${
              hashValue === "" || hashValue === "blogs" ? `` : `d-none`
            }`}
            onClick={() => {
              setProgressBar(0)
              setFiles([])
              setUploadBlogs(true)
              setUploadFiles(false)
              setUploadVideos(false)
            }}
          >
            Add Content
          </Button>
        </Col>
        <Col lg="12" md="12" sm="12" xs="12" className="mt-3 add-links">
          {showMainDiv()}
        </Col>
        {Object.keys(currentVideo).length > 0 ? (
          <Modal dialogClassName={`${Styles.video_modal}`} show={dmodal} onHide={handleClose} centered>
            <Modal.Header className="border-none" closeButton>
              <Modal.Title style={{ fontSize: "16px" }}>{currentVideo.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`text-center`}>
              <video style={{ maxHeight: "400px" }} className="video-class w-100" id={`video-element-player`} controls>
                <source src={URL.createObjectURL(currentVideo)} type="video/mp4" />
              </video>
            </Modal.Body>
          </Modal>
        ) : (
          ``
        )}
      </div>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default Upload
