import React, { useEffect, useState, useCallback } from "react"
import _ from "lodash"

import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Collapse from "react-bootstrap/Collapse"
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"
import InputFieldWrap from "../trainer/InputFieldWrap"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleDown, faCheckCircle, faChevronCircleUp, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

import CourseImg from "../../images/svgs/courseImg.svg"
import CancelWhite from "../../images/svgs/close.svg"

import StylesA from "./checklist.module.scss"
import StylesB from "../courses/course.module.scss"

const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""
var FromTrainingOrClassCreation = {}

const CreateChecklist = (props, location) => {
  const checklistTypes = ["Self Assessment (Basic)", "Self Assessment (Advanced)"]
  const checklistTypeNames = ["self-assessment-simple", "self-assessment-adv1", "self-assessment-adv2"]
  const checklistTypeValues = {
    "self-assessment-simple": "Self Assessment (Basic)",
    "self-assessment-adv1": "Self Assessment (Advanced)",
    "self-assessment-adv2": "Self Assessment (Advanced)",
  }
  const checklistDescription = [
    "Create a simple checklist for learners to evaluate process, skills or self etc.",
    " Create a checklist with questions grouped under focus areas for learners to assess the work, process or self.",
    // 'Create a checklist for all your pending work. You can create, plan, schedule, and complete different tasks as per your preference.'
  ]

  const [checklist, setChecklist] = useState({})
  const [showChecklistTypes, setShowChecklistTypes] = useState(true)
  const [showDefineChecklistSection, setShowDefineChecklistSection] = useState(false)
  const [showDefineResponsesSection, setShowDefineResponsesSection] = useState(false)
  const [showDefineRulesSection, setShowDefineRulesSection] = useState(false)

  const [showCheckListLayoutModal, setShowCheckListLayoutModal] = useState(false)
  const [selectedSlefAssessmentType, setSelectedSlefAssessmentType] = useState(null)

  const [checklistErrors, setChecklistErrors] = useState({
    checklist_name_error: "",
  })
  const [addToResLib, setAddToResLib] = useState(true)
  const [dispAddToResLib, setDispAddToResLib] = useState(false)
  useEffect(() => {
    setAddToResLib(true)
    let data = document && document.getElementById("ChecklistName")
    data && data.focus()
  }, [])

  useEffect(() => {
    if (checklist.checklist_type && checklist.id === undefined) {
      createChecklist()
    }
  }, [checklist.checklist_type])

  useEffect(() => {
    if (isBrowser()) {
      if (window.opener !== null && window.name === "checklist12") {
        window.opener.postMessage({ instruction: "ready", type: "checklist" }, window.location.origin)
        //console.log('Ready')
      }
      if (window.FromTrainingOrClassCreation) {
        FromTrainingOrClassCreation = window.FromTrainingOrClassCreation
      }
      //console.log('Ready')

      window.addEventListener("message", handleCrossWindow, false)
      return () => {
        window.removeEventListener("message", handleCrossWindow)
      }
    }
  }, [window])

  function handleCrossWindow(event) {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      //console.log("Helloooo 1")
      setAddToResLib(true)
      setDispAddToResLib(true)
      window.FromTrainingOrClassCreation = FromTrainingOrClassCreation = { ...event.data.instruction }
      //console.log("ins", FromTrainingOrClassCreation, event.data.instruction)
    }
  }

  const delayedQuery = useCallback(
    _.debounce((name) => sendQuery(name), 500),
    []
  )

  function sendQuery(name) {
    checkChecklistNameExists(name)
  }

  function checkChecklistNameExists(name) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/?name=${name}`, { headers: login_credentials })
      .then((res) => {
        let errors = { ...checklistErrors }
        if (res.data.count > 0) {
          errors["checklist_name_error"] = `Checklist with name '${name}' exists.`
        } else {
          errors["checklist_name_error"] = ""
        }
        setChecklistErrors(errors)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  function createChecklist() {
    var formData = new FormData()
    for (var k in checklist) {
      formData.append(k, checklist[k])
    }
    formData.append("is_added_to_library", addToResLib)

    if (FromTrainingOrClassCreation && FromTrainingOrClassCreation.sequence) {
      for (let p in FromTrainingOrClassCreation) {
        formData.append(p, FromTrainingOrClassCreation[p])
      }
      //formData.append('topic'     , FromTrainingOrClassCreation.topic_id)
      //formData.append('sequence'  , parseFloat(FromTrainingOrClassCreation.seq) + 10)
    }
    //else if (FromTrainingOrClassCreation && FromTrainingOrClassCreation.boActivityId) {
    //  formData.append('breakoutActivity'     , FromTrainingOrClassCreation.boActivityId)
    //  formData.append('sequence'  , parseFloat(FromTrainingOrClassCreation.seq) + 10)
    //}

    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/checklist/", formData, { headers: login_credentials })
      .then((res) => {
        let data = res.data
        navigate(`/app/checklist/edit/${data.id}`, { state: { checklist: data } }, { replace: true })
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function showErrorMessage(error) {
    let error_messages = ""
    for (var key in error.response.data) {
      error_messages += error.response.data[key] + "<br>"
    }
    if (error_messages === "Invalid token.<br>") {
      authClient.signOut()
      localStorage.removeItem("authenticity_token")
      localStorage.removeItem("username")
      window.location.reload(true)
    }
    document.getElementById("fixed-error-div").innerHTML =
      '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
      '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
      '<span aria-hidden="true">×</span></button>' +
      error_messages +
      "</div>"
  }

  function handleChange(event) {
    let current_checklist = { ...checklist }
    current_checklist[event.target.name] = event.target.value
    setChecklist(current_checklist)
  }

  function setCourseType(checklist_type) {
    let current_checklist = { ...checklist }
    current_checklist["checklist_type"] = checklist_type
    setChecklist(current_checklist)
    setShowChecklistTypes(false)
  }

  function showChecklistTypeCards() {
    return checklistTypes.map((checklist_type, index) => {
      return (
        <Col
          key={checklistTypes[index]}
          onClick={() => {
            if (checklist_type === "Self Assessment (Advanced)") {
              setShowCheckListLayoutModal(true)
            } else {
              setCourseType(checklistTypeNames[index])
            }
          }}
          lg="3"
          md="3"
          sm="3"
          xs="3"
          className="pointer"
          style={{ padding: "8px" }}
        >
          <div
            className={`${checklistTypeValues[checklist.checklist_type] === checklistTypes[index] ? StylesB.white_border : ""} ${
              StylesB.course_type_card
            } card_border_on_hover`}
          >
            <div className={`${StylesB.card_header} p-2`} style={{ backgroundImage: `url(${CourseImg})` }}>
              <p style={{ maxWidth: "53%" }}>{checklistTypes[index] === "Self Assessment (Basic)" ? "Basic Checklist" : "Advanced Checklist"}</p>
            </div>
            <div className={`${StylesB.card_body} p-2`}>
              <p>{checklistDescription[index]}</p>
            </div>
          </div>
        </Col>
      )
    })
  }

  function checklistTypeSelectionSection() {
    return (
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        style={{ border: "1px solid #303C54", backgroundColor: "#303C54", borderRadius: "9px", boxShadow: "0px 3px 12px #00000099", marginBottom: "12px" }}
        className={`${checklist.name ? "" : "disabled-div"} px-0`}
      >
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="d-flex pointer"
          onClick={() => setShowChecklistTypes(!showChecklistTypes)}
          style={{ justifyContent: "space-between", padding: "12px" }}
        >
          <p className="mb-0" style={{ fontSize: "16px", color: "#838B9E", lineHeight: 1 }}>
            Checklist Type{" "}
            <span style={{ color: "#E0E2E5", fontWeight: "bold" }}>{checklist.checklist_type ? `: ${checklistTypeValues[checklist.checklist_type]}` : ""}</span>
          </p>
          {showChecklistTypes ? (
            <FontAwesomeIcon
              className="pointer"
              aria-controls="example-collapse-text"
              onClick={() => setShowChecklistTypes(!showChecklistTypes)}
              color="#E0E2E5"
              style={{ height: "16px" }}
              icon={faChevronCircleUp}
            />
          ) : (
            <FontAwesomeIcon
              className="pointer"
              aria-controls="example-collapse-text"
              onClick={() => setShowChecklistTypes(!showChecklistTypes)}
              color="#E0E2E5"
              style={{ height: "16px" }}
              icon={faChevronCircleDown}
            />
          )}
        </Col>
        <Collapse in={showChecklistTypes}>
          <div id="example-collapse-text">
            <Col lg="12" md="12" sm="12" xs="12" className="px-3 pt-2" style={{ borderTop: "1px solid #838B9E" }}>
              <p style={{ fontSize: "16px", color: "#838B9E", margin: "0px" }}>Please select checklist type</p>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12" className="d-flex" style={{ flexWrap: "wrap", padding: "8px 8px" }}>
              {showChecklistTypeCards()}
            </Col>
          </div>
        </Collapse>
      </Col>
    )
  }

  function defineChecklistSection() {
    return (
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        style={{ border: "1px solid #303C54", backgroundColor: "#303C54", borderRadius: "9px", boxShadow: "0px 3px 12px #00000099", marginBottom: "12px" }}
        className={`${checklist.checklist_type ? "" : "disabled-div"} px-0`}
      >
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="d-flex pointer"
          onClick={() => setShowDefineChecklistSection(!showDefineChecklistSection)}
          style={{ justifyContent: "space-between", padding: "12px" }}
        >
          <p className="mb-0" style={{ fontSize: "16px", color: "#838B9E", lineHeight: 1 }}>
            Define Checklist
          </p>
          {showDefineChecklistSection ? (
            <FontAwesomeIcon
              className="pointer"
              aria-controls="example-collapse-text"
              onClick={() => setShowDefineChecklistSection(!showDefineChecklistSection)}
              color="#E0E2E5"
              style={{ height: "16px" }}
              icon={faChevronCircleUp}
            />
          ) : (
            <FontAwesomeIcon
              className="pointer"
              aria-controls="example-collapse-text"
              onClick={() => setShowDefineChecklistSection(!showDefineChecklistSection)}
              color="#E0E2E5"
              style={{ height: "16px" }}
              icon={faChevronCircleDown}
            />
          )}
        </Col>
        <Collapse in={showDefineChecklistSection}>
          <div id="example-collapse-text"></div>
        </Collapse>
      </Col>
    )
  }

  function showNameFields() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-0">
        <InputFieldWrap>
          <input
            id="ChecklistName"
            name="name"
            onChange={(e) => {
              delayedQuery(e.target.value)
              handleChange(e)
            }}
            type="text"
            className={`${StylesA.inputText} fs-16px pt-1`}
            style={{ background: "transparent", borderColor: "transparent" }}
            autoComplete="off"
            required
          />
          <span className={`${StylesA.floating_label} text-color-1 d-flex-align-center`}>
            <span>Checklist Name</span>
          </span>
        </InputFieldWrap>
        {showError(`checklist_name_error`)}
      </Col>
    )
  }
  function showError(error_type) {
    if (checklistErrors[error_type]) {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className={`text-left px-0 d-flex align-items-baseline mb-2`}>
          <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
          <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
            {checklistErrors[error_type]}
          </p>
        </Col>
      )
    } else return null
  }
  function defineRulesSection() {
    return (
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        style={{ border: "1px solid #303C54", backgroundColor: "#303C54", borderRadius: "9px", boxShadow: "0px 3px 12px #00000099", marginBottom: "12px" }}
        className={`${checklist.checklist_type ? "" : "disabled-div"} px-0`}
      >
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="d-flex pointer"
          onClick={() => setShowDefineRulesSection(!showDefineRulesSection)}
          style={{ justifyContent: "space-between", padding: "12px" }}
        >
          <p className="mb-0" style={{ fontSize: "16px", color: "#838B9E", lineHeight: 1 }}>
            Define Rules
          </p>
          {showDefineRulesSection ? (
            <FontAwesomeIcon
              className="pointer"
              aria-controls="checklist-rules-collapse"
              onClick={() => setShowDefineRulesSection(!showDefineRulesSection)}
              color="#E0E2E5"
              style={{ height: "16px" }}
              icon={faChevronCircleUp}
            />
          ) : (
            <FontAwesomeIcon
              className="pointer"
              aria-controls="checklist-rules-collapse"
              onClick={() => setShowDefineRulesSection(!showDefineRulesSection)}
              color="#E0E2E5"
              style={{ height: "16px" }}
              icon={faChevronCircleDown}
            />
          )}
        </Col>
        <Collapse in={showDefineRulesSection}>
          <div id="checklist-rules-collapse"></div>
        </Collapse>
      </Col>
    )
  }
  function defineResponsesSection() {
    return (
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        style={{ border: "1px solid #303C54", backgroundColor: "#303C54", borderRadius: "9px", boxShadow: "0px 3px 12px #00000099", marginBottom: "12px" }}
        className={`${checklist.checklist_type ? "" : "disabled-div"} px-0`}
      >
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="d-flex pointer"
          onClick={() => setShowDefineResponsesSection(!showDefineResponsesSection)}
          style={{ justifyContent: "space-between", padding: "12px" }}
        >
          <p className="mb-0" style={{ fontSize: "16px", color: "#838B9E", lineHeight: 1 }}>
            Define Response {showDefineResponsesSection ? " - select any or define your own" : ""}
          </p>
          {showDefineResponsesSection ? (
            <FontAwesomeIcon
              className="pointer"
              aria-controls="response-type-collapse"
              onClick={() => setShowDefineResponsesSection(!showDefineResponsesSection)}
              color="#E0E2E5"
              style={{ height: "16px" }}
              icon={faChevronCircleUp}
            />
          ) : (
            <FontAwesomeIcon
              className="pointer"
              aria-controls="response-type-collapse"
              onClick={() => setShowDefineResponsesSection(!showDefineResponsesSection)}
              color="#E0E2E5"
              style={{ height: "16px" }}
              icon={faChevronCircleDown}
            />
          )}
        </Col>
        <Collapse in={showDefineResponsesSection}>
          <div id="response-type-collapse"></div>
        </Collapse>
      </Col>
    )
  }

  return (
    <div>
      <SEO title="Checklist Creation" />
      <Modal
        show={showCheckListLayoutModal}
        size="lg"
        centered
        onHide={() => {
          setShowCheckListLayoutModal(false)
        }}
        className={`${StylesA.create_response_modal} classroom-resource-delete-modal`}
      >
        <Modal.Header className={`${StylesA.delete_modal_header}`}>
          <p style={{ color: "#E0E2E5", fontWeight: "18px" }}>Self Assessment (Advanced)</p>
          <img
            onClick={(e) => {
              e.preventDefault()
              setShowCheckListLayoutModal(false)
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{ objectFit: `contain`, height: `15px`, position: "absolute", top: "10px", right: "5px" }}
          />
        </Modal.Header>
        <Modal.Body className="text-left" style={{ backgroundColor: "#303C54", borderRadius: "0px 0px 8px 8px" }}>
          <p style={{ color: "#E0E2E5", fontSize: "16px", marginBottom: "10px" }}>Please select a checklist layout</p>
          <div className={`d-flex-centered align-items-baseline w-100 px-0`} style={{}}>
            <div
              className={`${StylesA.whole_checklist} ${
                checklist.checklist_type === "self-assessment-adv1" ? StylesA.advance_type_selected : StylesA.advance_type
              } px-3 mr-4 pt-3 pb-4`}
              onClick={(e) => setCourseType("self-assessment-adv1")}
            >
              {checklist.checklist_type === "self-assessment-adv1" ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#E0E2E5"
                  style={{ height: "15px", width: "15px", position: "absolute", top: "-7px", right: "-7px" }}
                />
              ) : null}
              <p className={`mb-2`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
                Checklist with groups
              </p>
              <div className={`${StylesA.checklist_groups} pt-2 pl-2`} style={{}}>
                <p className={`mb-2`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
                  Group
                </p>
                <div className={`px-5 pb-4`}>
                  <div className={`${StylesA.sub_groups} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                    item
                  </div>
                  <div className={`${StylesA.sub_groups} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                    item
                  </div>
                  <div className={`${StylesA.sub_groups} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                    item
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${StylesA.whole_checklist} ${
                checklist.checklist_type === "self-assessment-adv2" ? StylesA.advance_type_selected : StylesA.advance_type
              } p-3`}
              onClick={(e) => setCourseType("self-assessment-adv2")}
            >
              {checklist.checklist_type === "self-assessment-adv2" ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#E0E2E5"
                  style={{ height: "15px", width: "15px", position: "absolute", top: "-7px", right: "-7px" }}
                />
              ) : null}
              <p className={`mb-2`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
                Checklist with groups & subgroups
              </p>
              <div className={`${StylesA.checklist_groups} pt-2 pl-2 pb-4`}>
                <p className={`mb-2`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
                  Group
                </p>
                <div className={`${StylesA.sub_groups} mx-4 mb-2`} style={{}}>
                  <p className={`pl-2 py-2 mb-0`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
                    Sub-group
                  </p>
                  <div className={`px-5 pb-4`}>
                    <div className={`${StylesA.sub_groups_item} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                      item
                    </div>
                    <div className={`${StylesA.sub_groups_item} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                      item
                    </div>
                    <div className={`${StylesA.sub_groups_item} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                      item
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Col xs="12" sm="12" md="12" lg="12" className="pt-0 d-flex" style={{ alignItems: "center", flexDirection: "column" }}>
        <Col xs="12" sm="12" md="10" lg="10" className={`${StylesB.course_creation_header} px-0`}>
          <p
            className="mb-0 py-2 px-3 font-weight-bold"
            id="coursename"
            style={{ borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "18px", lineHeight: "1", minWidth: "max-content" }}
          >
            {checklist.name || "New Checklist"}
          </p>
        </Col>
        <Col xs="12" sm="12" md="10" lg="10" className="px-0">
          <div
            className="px-3"
            style={{
              maxHeight: "calc(100vh - 190px)",
              overflowY: "scroll",
              padding: "12px",
              backgroundColor: "#212C42",
              borderRadius: "9px 9px 0px 0px",
              boxShadow: "0px 3px 12px #00000099",
            }}
          >
            {showNameFields()}
            {checklistTypeSelectionSection()}
            {defineChecklistSection()}
            {defineResponsesSection()}
            {defineRulesSection()}
          </div>
          <Col
            className="px-0 pl-3 py-2"
            style={{ borderTop: "1px solid #63666f", backgroundColor: "#212C42", borderRadius: "0px 0px 9px 9px", boxShadow: "0px -3px 12px #00000099" }}
          >
            <div className="d-inline-block justify-content-start">
              <button
                className="border_on_hover fs-14px"
                style={{
                  backgroundColor: "#586886",
                  border: "1px solid #586886",
                  height: "28px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "23px",
                  color: "#E0E2E5",
                }}
              >
                Cancel
              </button>
            </div>
            {dispAddToResLib ? (
              <div className="pr-2 d-inline-block float-right">
                <Form.Check
                  className=""
                  style={{ fontSize: 14 }}
                  type={"checkbox"}
                  id={`default-checkbox`}
                  label={`Add this checklist to the resource library`}
                  checked={addToResLib}
                  onChange={() => setAddToResLib((p) => !p)}
                />
              </div>
            ) : null}
          </Col>
        </Col>
      </Col>
    </div>
  )
}

export default CreateChecklist
