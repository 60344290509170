import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "../../components/image"
import { isBrowser } from "../../services/developer_mode"

const ViewCourseTemplate = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [course_template, setCourseTemplate] = useState({})
  const [course, setCourse] = useState({})

  const [allTemplates, setAllTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  // console.log(location,course, "location.dilsjdio")
  // const { state = {} } = location

  useEffect(() => {
    fetchTemplate()
    searchTemplates()
  }, [])

  function searchTemplates(search = "") {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-course-templates-list/?search=` + search, { headers: login_credentials }).then((res) => {
      setAllTemplates(res.data.results)
    })
  }

  function selectItem(e, field, data) {
    e.preventDefault()
    course[field] = [data.id]
    setSelectedTemplate(data.name)
  }

  async function fetchTemplate() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/default-course-templates/${props.id}/`, { headers: login_credentials }).then((res) => {
      setCourseTemplate(res.data)
    })
  }

  return (
    <div>
      <SEO title="CourseTemplate View" />
      <Col lg="12" md="12" sm="12" xs="12">
        <h4 className={`m-0`}>View Default Course Template {course_template.name}</h4>
        <Row className={`mb-3 container`}>
          {course_template && course_template.stages
            ? course_template.stages.map((node, index) => (
                <Col xs="12" sm="4" md="4" className="mt-3">
                  <h6> {node.name} </h6>
                  <Col xs="12" sm="12" md="12" className="text-center light-div theme-color p-5">
                    <Image style={{ width: "126px", height: "104px" }} alt="Eskola logo" src="stages.png" className="d-inline-block align-center mobile-hide" />
                    {node && node.modules
                      ? node.modules.map((module, index) => <h6 style={{ borderBottom: "1px solid", padding: "5px" }}> {module.name} </h6>)
                      : ""}
                    <Col xs="12" sm="6" md="12" style={{ display: `grid` }}>
                      <Button
                        className="mt-2"
                        onClick={() => navigate(`/app/default-course-templates/${props.id}/stages/${node.id}/modules/create`)}
                        variant="primary"
                      >
                        Create module
                      </Button>
                      <Link className={`mt-2 text-white`} to={`/app/default-course-templates/${props.id}/stages/view/`}>
                        {" "}
                        Edit / View Stage
                      </Link>
                    </Col>
                  </Col>
                </Col>
              ))
            : ""}
        </Row>
        <Button onClick={() => navigate(`/app/default-course-templates`)} variant="danger">
          Go Back
        </Button>
      </Col>
    </div>
  )
}

export default ViewCourseTemplate
