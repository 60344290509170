const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const getFromDateAndToDate = (time_period) => {
  let fromDate = null
  let toDate = new Date()
  if (time_period === "Overall Period") {
    return `?`
  } else if (time_period === "1-Month") {
    fromDate = new Date(toDate.setMonth(toDate.getMonth() - 1))
    toDate = new Date()
  } else if (time_period === "3-Month") {
    fromDate = new Date(toDate.setMonth(toDate.getMonth() - 3))
    toDate = new Date()
  } else if (time_period === "6-Month") {
    fromDate = new Date(toDate.setMonth(toDate.getMonth() - 6))
    toDate = new Date()
  } else {
    let dates = time_period.split(" to ")
    let start_date_arr = dates[0].split("-")
    let end_date_arr = dates[1].split("-")
    fromDate = new Date(`${start_date_arr[2]}-${start_date_arr[1]}-${start_date_arr[0]}`)
    toDate = new Date(`${end_date_arr[2]}-${end_date_arr[1]}-${end_date_arr[0]}`)
  }
  fromDate = fromDate.toISOString()
  toDate = toDate.toISOString()
  return `?from_date=${fromDate}&to_date=${toDate}&timezone=${TIMEZONE}&`
}
