import React, { useCallback, useEffect, useState } from "react"
import _ from "lodash"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Button from "react-bootstrap/Button"
import PropTypes from "prop-types"
import Styles from "./muralAccounts.module.scss"
import AcivityWorkSpaceStyle from "../acitvity-workspace/acitivity-workspace.module.scss"
import muralIcon from "../../images/svgs/mural-icon.svg"
import WarningIcon from "../../images/svgs/warning_icon.svg"
import muralAccountsData from "./muralAccounts"
import { Col, FormControl, Tooltip } from "react-bootstrap"
import Image from "../custom/Image"
import edit from "../../images/svgs/edit.svg"
import deleteimg from "../../images/svgs/delete.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleUp, faChevronCircleDown, faSearch } from "@fortawesome/free-solid-svg-icons"
import { navigate } from "gatsby"
import Btn from "../custom/Button"

const MuralAccountsList = (props) => {
  const [muralSearchValue, setMuralSearchValue] = useState("")

  const [muralData, setMuralData] = useState([])
  const [selectMuralCollapsed, setSelectMuralCollapsed] = useState(true)
  const [muralAccount, setMuralAccount] = useState({})

  const delayedMuralSearchQuery = useCallback(
    _.debounce((searchAccount) => getMuralAccountsData(searchAccount), 1000),
    []
  )

  useEffect(() => {
    if (props.showMuralAccounts) {
      getMuralAccountsData()
    }
  }, [props.showMuralAccounts])

  useEffect(() => {
    setMuralAccount(props.mural_account)
  }, [props.mural_account])

  const getMuralAccountsData = async (searchAccount = null) => {
    let res = await muralAccountsData(searchAccount, props.course)
    if (res) {
      setMuralData(res.data)
      if (res.suggested_account?.id) {
        setMuralAccount(res.suggested_account)
        props.onSelectMuralAccount(res.suggested_account)
      }
    }
  }

  const muralListItem = (item, index) => {
    return (
      <Col
        class="mural"
        id={item.id}
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className={`border_on_hover d-inline-flex w-100 py-2 px-1 ${Styles.muralList} box-shadow-00000029`}
        onClick={() => {
          if (item.activated) {
            setMuralAccount(item)
            props.onSelectMuralAccount(item)
            setSelectMuralCollapsed(true)
          }
        }}
      >
        <div
          className="float-left d-inline-flex align-self-center mr-auto"
          onClick={() => {
            setMuralAccount(item)
            props.onSelectMuralAccount(item)
            setSelectMuralCollapsed(true)
          }}
        >
          <div className="position-relative w-2rem">
            <Image src={muralIcon} alt="mural" className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`} />
            {!item.activated && (
              <Image src={WarningIcon} alt="mural-warning-icon" className={`my-auto position-absolute top-0 right-0 h-1rem w-1rem object-fit-content `} />
            )}
          </div>
          <p className="d-inline-flex mb-0 pl-2 turient-header-text-color fs-16px bold-500">{item.name}</p>
        </div>
        {!item.activated && (
          <div className="float-right align-self-center">
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip className="event-tooltip fs-12px w-23rem" id={`tooltip-${item.name}`}>
                  Some issues detected with the account . Reauthenticate to resolve
                </Tooltip>
              }
            >
              <Button
                className={`border_on_hover position-relative ${Styles.btn} text-center px-3 py-1 bg-AA5746 fs-14px turient-header-text-color br-20px z-index-1`}
                onClick={() => {
                  navigate(`/app/mural?redirect_url=${window.location.href}&muralAuthId=${item.id}`)
                }}
              >
                Re-authenticate
              </Button>
            </OverlayTrigger>
          </div>
        )}
      </Col>
    )
  }
  return (
    <>
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className={`bg-303C54 text-left position-relative box-shadow-00000029 pointer border_on_hover w-100  px-0 mb-1 br-9px ${
          props.isPermitted && "disable-content"
        } `}
      >
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          onClick={() => {
            if (!props?.isPermitted) {
              setSelectMuralCollapsed(!selectMuralCollapsed)
            }
          }}
          className={`pointer align-items-center border-bottom-transparent py-2 d-inline-flex w-100 justify-content-between`}
        >
          <div className="float-left align-self-center">
            {muralAccount?.id ? (
              <div>
                <p className="fs-12px opacity-60 mb-1">Mural Account</p>
                <div className="d-inline-flex">
                  <Image src={muralIcon} alt="mural" className={` ${AcivityWorkSpaceStyle.existing_activity_button_icon}`} />

                  <p className={`d-inline-flex mb-0 d-flex justify-content-center ml-2 fs-16px ${selectMuralCollapsed ? "icon-color" : "color-C3C7CE"} `}>
                    {muralAccount.name}
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <Image src={muralIcon} alt="mural" className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`} />

                <p className={`fs-16px ${selectMuralCollapsed ? "icon-color" : "color-C3C7CE"} d-inline-flex mb-0 d-flex justify-content-center ml-2`}>
                  Select Mural Account
                </p>
              </div>
            )}
          </div>
          <div className={`float-right align-self-center text-right`}>
            {muralAccount?.id ? (
              <div className="d-flex flex-row align-items-center justify-content-between w-4rem">
                {!props.isPermitted && (
                  <>
                    <Btn className={`d-flex align-items-center justify-content-center`} type={`round`}>
                      <Image
                        className="float-right pointer w-1rem h-1rem text-center"
                        onClick={() => {
                          setMuralAccount({})
                          props.onSelectMuralAccount({})
                        }}
                        alt="delete"
                        src={deleteimg}
                      />
                    </Btn>
                    <Btn className={`d-flex align-items-center justify-content-center`} type={`round`}>
                      <Image
                        className="float-right pointer w-20px h-20px text-center"
                        onClick={() => {
                          setSelectMuralCollapsed(!selectMuralCollapsed)
                        }}
                        alt="edit"
                        src={edit}
                      />
                    </Btn>
                  </>
                )}
              </div>
            ) : (
              <FontAwesomeIcon
                onClick={() => {
                  setSelectMuralCollapsed(!selectMuralCollapsed)
                }}
                className="float-right pointer w-20px h-20px turient-header-text-color"
                icon={selectMuralCollapsed ? faChevronCircleDown : faChevronCircleUp}
              />
            )}
          </div>
        </Col>
      </Col>
      {selectMuralCollapsed ? null : (
        <Col lg="12" md="12" sm="12" xs="12" className={`pointer align-items-center py-1 px-0 d-inline-flex w-100 border-bottom-transparent`}>
          <Col lg="12" md="12" sm="12" xs="12" className="pointer pt-1 px-0 my-0 text-left position-relative bg-303C54 br-9px  border-E0E2E5">
            <Col lg="12" md="12" sm="12" xs="12" className="px-0 overflow-y-scroll max-h-280px">
              <div className=" align-self-center d-flex bg-transparent br-9px">
                <FontAwesomeIcon color="#7D8289" className={`my-auto ml-2`} icon={faSearch} size="md" />
                <FormControl
                  autoFocus
                  className={`border-0 shadow-none py-0 form-control px-2 bg-transparent turient-header-text-color fs-16px`}
                  placeholder="Search account"
                  onChange={(e) => {
                    e.preventDefault()
                    setMuralSearchValue(e.target.value)
                    delayedMuralSearchQuery(e.target.value)
                  }}
                  value={muralSearchValue}
                />
              </div>
              {muralData?.length > 0 ? (
                muralData.map((item, index) => muralListItem(item, index))
              ) : (
                <div className={`align-items-center text-center w-100`}>
                  <p className={`mb-0 turient-header-text-color fs-16px bold-500`}>No Mural Accounts Configured!</p>
                </div>
              )}
            </Col>
          </Col>
        </Col>
      )}
    </>
  )
}

MuralAccountsList.defaultProptypes = {
  isPermitted: true,
}

MuralAccountsList.propTypes = {
  mural_account: PropTypes.object,
  onSelectMuralAccount: PropTypes.func.isRequired,
  isPermitted: PropTypes.bool,
}

export default MuralAccountsList
