import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import CropImage from "../../crop-image/crop-image"
import CustomInputField from "../../custom/CustomInputFiled"
import CustomTextArea from "../../custom/CustomTextArea"
import SEO from "../../seo"
import HeaderTab from "../../trainer/HeaderTab"
import Style from "./style.module.scss"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"
import fb from "../../../images/svgs/fbicon.svg"
import linkedin from "../../../images/svgs/linkedinicon.svg"
import twitter from "../../../images/svgs/twittericon.svg"
import DefaultSchoolLogo from "../../../images/default_school.png"
import warningIcon from "../../../images/svgs/warning_icon.svg"
import ProfileUpdateProgress from "../../ProfileProgressBar"
import Camera from "../../../images/svgs/camera_logo.svg"
import { navigate } from "gatsby"
import MembersPermission from "./MembersPermission"
import Loader from "../../custom/Loader"
import { b64toBlob } from "../../utilities/b64toBlob"
import { convertBase64ToFile } from "../../utilities/convertBase64ToFile"
import { validateEmail, validateMobileNumber, validateUrl } from "../../../components/utilities/utils"
import { getRequest, postRequest, putRequest } from "../../../services/rest_service"
import Json from "../../utilities/json"
import { useLocation } from "@reach/router"
import Image from "../../custom/Image"
import { validateSocialMediaLink } from "../../utilities/validateSocialMediaLink"
import { SET_COMPANY_DETAILS } from "../../../stores/sidebar_store/SidebarActions"
import { SidebarContext } from "../../../stores/sidebar_store/SidebarStore"
import { HasCreatePermission, HasEditPermission } from "../../../utils/PermissionDataAccess"
import { SCHOOL } from "../../../constants/permissionsConstant"
import PhoneInput from "react-phone-number-input"
import { parsePhoneNumber } from "react-phone-number-input"
import { isValidPhoneNumber } from "react-phone-number-input"
import { SET_NOTIFICATION_DATA } from "../../../stores/global_store/GlobalActions"
import { GlobalContext } from "../../../stores/global_store/GlobalStore"

function SchoolProfileSetup() {
  const [sidebarState, dispatch] = useContext(SidebarContext)
  const [notificationData, dispatchData] = useContext(GlobalContext)

  //state variables
  const [activeTab, setActiveTab] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [cropData, setCropData] = useState("#")
  const [fileName, setFileName] = useState(null)
  const [socialLinks, setSocialLinks] = useState([
    { name: "facebook", icon: fb, color: "#4261A2", show: false },
    { name: "linkedin", icon: linkedin, color: "#007BB5", show: false },
    { name: "twitter", icon: twitter, color: "#1DA1F2", show: false },
  ])
  const [socialMedia, setSocialMedia] = useState({})
  const [marginSocial, setMarginSocial] = useState(false)
  const [loader, setLoader] = useState(false)
  const [schoolDetail, setSchoolDetail] = useState({})
  const [schoolDetailError, setSchoolDetailError] = useState({
    addressError: false,
    nameError: false,
    websiteError: false,
    emailError: false,
    descriptionError: false,
    phoneError: false,
    addressErrorMsg: "",
    nameErrorMsg: "",
    emailErrorMsg: "",
    descriptionErrorMsg: "",
    phoneErrorMsg: "",
    websiteErrorMsg: "",
  })
  const [socialLinksError, setSocialLinksError] = useState({
    facebook_url: false,
    facebook_url_error: "",
    linkedin_url: false,
    linkedin_url_error: "",
    twitter_url: false,
    twitter_url_error: "",
  })
  let organisation = Json.toJson(localStorage.getItem("organisation"))
  const location = useLocation()
  const isNew = location.pathname.includes("new")
  const [updatedFields, setUpdatedFields] = useState(["sub_folder", "organisation"])
  const [tabdisabled, setTabDisabled] = useState(true)
  const tabArray = ["School Details", "Members & Permissions"]
  let updatedData = {}

  let company_id = location.pathname.split("/").pop()
  useEffect(() => {
    let tabDisableFlag = isSchoolDetailExist()
    if (tabDisableFlag) {
      setTabDisabled(false)
    }
  }, [activeTab, schoolDetail])

  useEffect(() => {
    if (cropData !== schoolDetail.avatar && cropData !== "#") {
      const avatarChanged = updatedFields.indexOf("avatar") === -1
      setUpdatedFields(avatarChanged ? [...updatedFields, "avatar", "progress_percentage"] : updatedFields)
      schoolDetail["avatar"] = convertBase64ToFile(cropData, b64toBlob)
    }
  }, [cropData])

  useEffect(() => {
    if (!localStorage.getItem("activeTab")) {
      setActiveTab(0)
      localStorage.setItem("activeTab", 0)
    } else {
      setActiveTab(localStorage.getItem("activeTab"))
    }
  }, [])

  useEffect(() => {
    let x = false
    socialLinks.forEach((data) => {
      if (data.show) {
        x = true
      }
    })
    setMarginSocial(x)
  }, [socialLinks])

  useEffect(() => {
    if (isNew) {
      setSchoolDetail({})
    } else {
      getCompanyDetails(company_id)
    }
  }, [])

  const isSchoolDetailExist = () => {
    if (
      schoolDetail?.name?.length &&
      schoolDetail?.website?.length &&
      schoolDetail?.email?.length &&
      schoolDetail?.phone?.length &&
      schoolDetail?.description?.length &&
      schoolDetail?.address?.length
    ) {
      return true
    } else return false
  }

  const isPermitted = (isNew && !HasCreatePermission(SCHOOL)) || (!isNew && !HasEditPermission(SCHOOL))

  //api functions
  const getCompanyDetails = async (company_id) => {
    setLoader(true)
    let res = await getRequest(`/companies/${company_id}/`)
    if (res.success) {
      if (res.data.phone?.length === 10) {
        res.data.phone = `+91${res.data.phone}`
      }
      setSchoolDetail(res.data)
      if (Object.keys(res?.data?.social_media ? res?.data?.social_media : {})?.length) {
        let updatedSocialLinks = [...socialLinks]
        Object.keys(res.data?.social_media).map((media) => {
          updatedSocialLinks = updatedSocialLinks.map((link) => {
            if (media.includes(link.name) && res.data.social_media[media].length) {
              return { ...link, show: true }
            } else {
              return link
            }
          })
        })
        setSocialLinks(updatedSocialLinks)
      }
      setLoader(false)
    } else {
      setLoader(false)
    }
  }

  const updateCompanyDetails = async (company_id, companyDetails) => {
    setLoader(true)
    let res = await putRequest(`/companies/${company_id}/`, companyDetails)
    if (res.success) {
      setLoader(false)
      dispatchData({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "success", title: "School setup updated Sucesssfully!" },
      })

      if (localStorage.getItem("school_setup")) {
        localStorage.removeItem("school_setup")
      }
      localStorage.setItem("company_name", res.data?.name)
      localStorage.setItem("company_logo", res.data.avatar)
      dispatch({ type: SET_COMPANY_DETAILS, payload: { name: res.data.name, logo: res.data.avatar } })
      setActiveTab(1)
      localStorage.setItem("activeTab", 1)
      setTabDisabled(false)
    } else {
      dispatchData({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "error", title: res.data?.website[0] || res?.msg },
      })

      if (res.data?.website[0]) {
        setSchoolDetailError({ ...schoolDetailError, websiteError: true, websiteErrorMsg: res.data?.website[0] })
      }
      setLoader(false)
    }
  }

  const setCompanyDetails = async (company_id, companyDetails) => {
    setLoader(true)
    let res = await postRequest("/companies/create/", companyDetails)
    if (res.success) {
      setLoader(false)
      dispatchData({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "success", title: "School setup created Sucesssfully!" },
      })

      setActiveTab(0)
      localStorage.setItem("activeTab", 0)
      setTabDisabled(false)
      navigate(`/app/school-setup`)
    } else {
      displaySocialMediaLinkError(res.msg)
      if (res?.data?.website[0]) {
        setSchoolDetailError({ ...schoolDetailError, websiteError: true, websiteErrorMsg: res.data?.website[0] })
      }
      dispatchData({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "error", title: res.data?.website[0] || res?.msg },
      })
      setLoader(false)
    }
  }
  //validation functions
  const handleWebsiteValidation = (value) => {
    return validateUrl(value)
  }
  const handlePhoneNumberValidation = (value) => {
    return validateMobileNumber(value)
  }
  const handleEmailValidation = (value) => {
    return validateEmail(value)
  }
  const calculateProgressPercentage = (data) => {
    const { address, name, website, avatar, areas_of_expertise, social_media, tag_line, email, description, phone } = data
    const totalFields = 10 // total number of fields to be considered
    let completedFields = 0

    if (address && address?.length) completedFields++
    if (name && name?.length) completedFields++
    if (website && website?.length) completedFields++
    if (avatar) completedFields++
    if (areas_of_expertise && areas_of_expertise?.length) completedFields++
    if (social_media) completedFields++
    if (tag_line && tag_line?.length) completedFields++
    if (email) completedFields++
    if (description && description?.length) completedFields++
    if (phone) completedFields++
    const progressPercentage = (completedFields / totalFields) * 100
    return progressPercentage
  }
  let school_detail = { ...schoolDetail }

  const handlePhoneInput = (value) => {
    if (value?.length) {
      setSchoolDetail({
        ...schoolDetail,
        phone: parsePhoneNumber(value)?.number,
      })
      setUpdatedFields([...updatedFields, "phone", "progress_percentage"])
      if (isValidPhoneNumber(value)) {
        setSchoolDetailError({ ...schoolDetailError, phoneError: false, phoneErrorMsg: "" })
      } else {
        setSchoolDetailError({ ...schoolDetailError, phoneError: true, phoneErrorMsg: "Please Enter Correct Phone Number!" })
      }
      setUpdatedFields([...updatedFields, "phone", "progress_percentage"])
    }
  }

  //event handlers
  const handleChange = (event) => {
    if (event.target) {
      if (event.target.name === "email") {
        setSchoolDetail({ ...schoolDetail, email: event.target.value })
        if (validateEmail(event.target.value)) {
          setSchoolDetailError({ ...schoolDetailError, emailError: false, emailErrorMsg: "" })
        } else {
          setSchoolDetailError({ ...schoolDetailError, emailError: true, emailErrorMsg: "Please Enter Correct Email!" })
        }
        setUpdatedFields([...updatedFields, "email", "progress_percentage"])
      }
      if (event.target.name === "website") {
        setSchoolDetail({ ...schoolDetail, website: event.target.value })
        if (validateUrl(event.target.value)) {
          setSchoolDetailError({ ...schoolDetailError, websiteError: false, websiteErrorMsg: "" })
        } else {
          setSchoolDetailError({ ...schoolDetailError, websiteError: true, websiteErrorMsg: "Please Enter Correct URL!" })
        }
        setUpdatedFields([...updatedFields, "website", "progress_percentage"])
      }
      if (event.target.name.includes("_url")) {
        let isValidLink = validateSocialMediaLink(event.target.name, event.target.value)
        if (isValidLink) {
          setSocialLinksError({ ...socialLinksError, [event.target.name]: false, [event.target.name + "_error"]: "" })
          setSocialMedia({ ...socialMedia, [event.target.name]: event.target.value })
          school_detail.social_media = { ...school_detail.social_media, [event.target.name]: event.target.value }
          setSchoolDetail({
            ...school_detail,
            progress_percentage: calculateProgressPercentage({
              ...school_detail,
              social_media: { ...school_detail.social_media, [event.target?.name]: event.target?.value },
            }),
          })
          setUpdatedFields([...updatedFields, "social_media", "progress_percentage"])
        } else {
          setSocialLinksError({ ...socialLinksError, [event.target.name]: true, [event.target.name + "_error"]: "Link not valid" })
        }
      } else {
        school_detail[event.target.name] = event.target.value
        setSchoolDetail({ ...school_detail, progress_percentage: calculateProgressPercentage({ ...school_detail, [event.target?.name]: event.target?.value }) })
        setUpdatedFields([...updatedFields, event.target.name, "progress_percentage"])
      }
    }
  }

  const updateSocialLinks = (index, toggle) => {
    let social_links = [...socialLinks]
    social_links[index].show = toggle
    setSocialLinks(social_links)
  }
  const resetFlags = () => {
    setActiveTab(1)
    localStorage.setItem("activeTab", 1)
    setTabDisabled(false)
    setLoader(false)
  }
  const handleNextSubmit = (event) => {
    if (
      schoolDetailError.nameError ||
      schoolDetailError.addressError ||
      schoolDetailError.phoneError ||
      schoolDetailError.descriptionError ||
      schoolDetailError.emailError ||
      schoolDetailError.websiteError
    ) {
      event.preventDefault()
      return // Exit the function without making an API call
    } else {
      let socialMediaFlag = socialLinksError.facebook_url || socialLinksError.twitter_url || socialLinksError.linkedin_url
      if (!socialMediaFlag) {
        for (let field of updatedFields) {
          if (field === "social_media") {
            updatedData[field] = Json.toString(school_detail.social_media)
          } else {
            updatedData[field] = school_detail[field]
          }
        }
        setLoader(true)
        setSocialLinksError({
          facebook_url: false,
          facebook_url_error: "",
          linkedin_url: false,
          linkedin_url_error: "",
          twitter_url: false,
          twitter_url_error: "",
        })
        let formData = new FormData(event.target)
        formData.delete("phone")
        formData.append("phone", schoolDetail.phone)
        let final_school_detail
        if (isNew) {
          final_school_detail = {
            ...schoolDetail,
            organisation: organisation.id,
            sub_folder: schoolDetail.name.trim().split(" ").join(""),
            subdomain: schoolDetail.name.toLowerCase().trim().split(" ").join(""),
          }
        } else {
          final_school_detail = {
            ...updatedData,
            sub_folder: updatedData?.name ? updatedData.name.trim().split(" ").join("") : updatedData.sub_folder,
            subdomain: updatedData?.name ? updatedData.name.toLowerCase().trim().split(" ").join("") : updatedData.sub_folder,
          }
        }
        Object.keys(final_school_detail).map((detail) => {
          if (detail === "email") {
            formData.set(detail, final_school_detail[detail].toLowerCase())
          } else if (detail === "phone") {
            formData.set(detail, final_school_detail[detail])
          } else {
            formData.append(detail, final_school_detail[detail])
          }
        })
        {
          isNew ? setCompanyDetails(company_id, formData) : formData.get("progress_percentage") ? updateCompanyDetails(company_id, formData) : resetFlags()
        }
      }
      event.preventDefault()
    }
  }
  const displaySocialMediaLinkError = (error_data) => {
    let socialPlatformUrlError = { ...socialLinksError }
    let social_medial_url_key = ["facebook", "linkedin", "twitter"]
    for (let index = 0; index < social_medial_url_key?.length; index++) {
      let key = social_medial_url_key[index]
      if (error_data[`${key}_url`]) {
        socialPlatformUrlError[`${key}_url`] = true
        socialPlatformUrlError[`${key}_url_error`] = error_data[`${key}_url`][0]
        displaySocialMediaLinkDiv(key)
      } else {
        socialPlatformUrlError[`${key}_url`] = false
        socialPlatformUrlError[`${key}_url_error`] = ""
      }
    }
    setSocialLinksError(socialPlatformUrlError)
  }
  const displaySocialMediaLinkDiv = (key_name) => {
    let social_media_link = [...socialLinks]
    let index = social_media_link.findIndex((media) => media.name === key_name)
    if (index > -1) {
      social_media_link[index]["show"] = true
    }
    setSocialLinks(social_media_link)
  }

  function getCropDataProps(cropData, fileName) {
    setCropData(cropData)
    setFileName(fileName)
    setSchoolDetail({ ...schoolDetail, progress_percentage: calculateProgressPercentage({ ...schoolDetail, avatar: cropData }) })
  }
  const handleClose = () => {
    localStorage.removeItem("activeTab")
    navigate("/app/school-setup")
  }
  return (
    <div>
      <SEO title="School Profile Setup" />
      <Col xs="12" sm="12" md="12" lg="12" className="pt-3 d-flex flex-column align-items-start">
        {(isNew || schoolDetail?.id) && !loader ? (
          <>
            <HeaderTab
              heading="Admin Panel"
              active={activeTab}
              setActive={setActiveTab}
              list={isNew ? tabArray.slice(0, 1) : tabArray}
              back={false}
              close={true}
              handleClick={handleClose}
              tabdisabled={tabdisabled}
              setTabDisabled={setTabDisabled}
              storeTabFlag={true}
            />
            {activeTab == 0 ? (
              <Col lg="12" md="12" sm="12" xs="12" className={`px-0 pt-2 mx-0 br-9px mb-3 bg-2`}>
                <form className={`mb-0  `} id="trainform" onSubmit={handleNextSubmit}>
                  <div id="school-box" className={`d-flex mt-2 mb-3 px-3 ${Style.overflow}`}>
                    <CropImage
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      getCropDataProps={getCropDataProps}
                      fileName={fileName}
                      avatar={cropData !== "#" ? cropData : null}
                    />
                    <Col lg="2" md="2" sm="3" xs="3" className={`float-left px-0 pointer`}>
                      {cropData !== "#" ? (
                        <ProfileUpdateProgress
                          percentage={schoolDetail.progress_percentage}
                          image={cropData}
                          footerImgFlag={false}
                          handleClick={(event) => {
                            event.preventDefault()
                            setModalShow(true)
                          }}
                          mainDivStyle={`w-8rem h-8rem ${isPermitted && "disable-content"}`}
                          imgStyle="w-90 h-90 rounded-circle"
                          strokeWidth={3}
                          imgName="School Logo"
                        />
                      ) : (
                        <ProfileUpdateProgress
                          percentage={schoolDetail.progress_percentage}
                          image={schoolDetail.avatar || DefaultSchoolLogo}
                          footerImg={Camera}
                          footerImgFlag={schoolDetail.avatar ? false : true}
                          handleClick={(event) => {
                            event.preventDefault()
                            setModalShow(true)
                          }}
                          mainDivStyle={`w-8rem h-8rem ${isPermitted && "disable-content"}`}
                          imgStyle="w-90 h-90 rounded-circle"
                          footerImgStyle={Style.progressBarFooterImgStyle}
                          strokeWidth={3}
                          imgName="Default School Logo"
                          footerImgName="Camera Logo"
                        />
                      )}
                    </Col>
                    <Col lg="10" md="10" sm="9" xs="9" className="float-left">
                      <Row className="pl-2">
                        <Col lg="6" md="6" sm="12" xs="12" className="px-1 poppins_font">
                          <CustomInputField
                            type="text"
                            name="name"
                            onChange={handleChange}
                            label={"School Name"}
                            value={schoolDetail.name}
                            required
                            readOnly={isPermitted}
                          />
                          {schoolDetailError.nameError && schoolDetail.name !== "" && <p>{schoolDetailError.nameErrorMsg}</p>}
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12" className="px-1 poppins_font">
                          <CustomInputField
                            type="text"
                            name="website"
                            customValidation={handleWebsiteValidation}
                            onChange={handleChange}
                            label={"Website"}
                            required
                            value={schoolDetail.website}
                            readOnly={isPermitted}
                          />
                          {schoolDetailError.websiteError && schoolDetail.website !== "" && (
                            <div className="d-flex align-items-baseline px-1 py-0 br-23px mb-2">
                              <Image className="w-1rem mr-1 " src={warningIcon} alt="warninig" />
                              <p className="mb-0 fs-14px social-link-error">{schoolDetailError.websiteErrorMsg}</p>
                            </div>
                          )}
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12" className="px-1 poppins_font">
                          <CustomInputField
                            type="text"
                            name="email"
                            onChange={handleChange}
                            customValidation={handleEmailValidation}
                            label={"Email"}
                            value={schoolDetail.email}
                            required
                            readOnly={isPermitted}
                          />
                          {schoolDetailError.emailError && schoolDetail.email !== "" && (
                            <div className="d-flex align-items-baseline px-1 py-0 br-23px mb-2">
                              <Image className="w-1rem mr-1 " src={warningIcon} alt="warninig" />
                              <p className="mb-0 fs-14px social-link-error">{schoolDetailError.emailErrorMsg}</p>
                            </div>
                          )}
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12" className="px-1 poppins_font">
                          <div className="bg-303C54 br-9px p-2 fs-14px mb-2 border_on_hover">
                            <span className="pl-2 opacity-40">Phone Number</span>
                            <PhoneInput
                              className={` w-100  border-0 ${Style.phoneInputfieldStyle} px-2 py-1 `}
                              name="phone"
                              type="text"
                              maxLength={17}
                              defaultCountry="IN"
                              value={schoolDetail.phone}
                              onChange={handlePhoneInput}
                              placeholder={`Enter phone Number`}
                              autoComplete="off"
                            />
                          </div>
                          {schoolDetailError.phoneError && schoolDetail.phone !== "" && (
                            <div className="d-flex align-items-baseline px-1 py-0 br-23px mb-2">
                              <Image className="w-1rem mr-1 " src={warningIcon} alt="warninig" />
                              <p className="mb-0 fs-14px social-link-error">{schoolDetailError.phoneErrorMsg}</p>
                            </div>
                          )}
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12" className="px-1 poppins_font">
                          <CustomTextArea
                            name="description"
                            label="Describe your school"
                            onChange={handleChange}
                            defaultValue={schoolDetail.description}
                            required
                            readOnly={isPermitted}
                          />
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12" className="px-1 poppins_font">
                          <CustomTextArea
                            name="address"
                            label="Add Address"
                            onChange={handleChange}
                            defaultValue={schoolDetail.address}
                            required
                            readOnly={isPermitted}
                          />
                        </Col>
                        <Col lg="12" md="12" sm="12" xs="12" className="px-1 poppins_font">
                          <CustomInputField
                            type="text"
                            name="tag_line"
                            onChange={handleChange}
                            label={"Add a Tagline"}
                            icon={{ name: faQuoteLeft }}
                            value={schoolDetail.tag_line}
                            readOnly={isPermitted}
                          />
                        </Col>
                        <Col lg="12" md="12" sm="12" xs="12" className="px-1 poppins_font">
                          <CustomInputField
                            type="text"
                            name="areas_of_expertise"
                            onChange={handleChange}
                            label={"Areas of expertise"}
                            icon={{ name: faQuoteLeft }}
                            value={schoolDetail.areas_of_expertise}
                            readOnly={isPermitted}
                          />
                        </Col>

                        <div className={`d-flex w-100 flex-column align-items-start px-1 mt-3`}>
                          <div className={`fs-16px mb-1 mr-3 poppins_font icon-color`}>Connect to Social media</div>
                          <div className={`text-center d-flex flex-wrap w-100`}>
                            {socialLinks.map((media, index) =>
                              media.show === true ? (
                                <div key={index} className="px-1 w-100 ">
                                  <div
                                    className={`${
                                      !socialLinksError[`${media.name}_url`] ? "mb-2 border_on_hover" : "mb-1 error-input"
                                    } px-1 inner-element-box-shadow br-9px bg-303C54`}
                                  >
                                    <div className={`p-2 d-flex w-100 br-0px border border-1 border-transparent bg-transparent `}>
                                      <div
                                        className={`mr-2 w-40px h-40px p-1 circle ${isPermitted && "disable-content"}`}
                                        onClick={(e) => {
                                          updateSocialLinks(index, false)
                                        }}
                                        style={{
                                          backgroundColor: media.color,
                                        }}
                                      >
                                        <Image className={`mb-0 pointer`} src={media.icon} alt={`${media.name}_icon`} />
                                      </div>
                                      <div className={`ml-2 flex-grow-1`}>
                                        <input
                                          name={`${media.name}_url`}
                                          placeholder={`Add your ${media.name} link`}
                                          onChange={handleChange}
                                          type="text"
                                          defaultValue={schoolDetail?.social_media?.[`${media.name}_url`]}
                                          className={` w-100 fs-16px mt-2 bg-transparent border-transparent turient-header-text-color ${
                                            isPermitted && "disable-content"
                                          }`}
                                          autoComplete="off"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {!socialLinksError[`${media.name}_url`] ? null : (
                                    <Col xs="12" sm="12" md="12" lg="12" className={`mb-3 text-left px-0`}>
                                      <div className="d-flex align-items-baseline px-1 py-0 br-23px">
                                        <Image className="w-1rem mr-1 " src={warningIcon} alt="warninig" />
                                        <p className="mb-0 fs-14px social-link-error">{socialLinksError[`${media.name}_url_error`]}</p>
                                      </div>
                                    </Col>
                                  )}
                                </div>
                              ) : (
                                <div
                                  key={media.name}
                                  onClick={(e) => {
                                    updateSocialLinks(index, true)
                                  }}
                                  className={`p-1 w-40px h-40px ${marginSocial ? "ml-3" : index !== 0 ? "ml-3" : ""} mb-2 circle ${
                                    isPermitted && "disable-content"
                                  }`}
                                  style={{
                                    backgroundColor: media.color,
                                  }}
                                >
                                  <Image className={`mb-0 pointer`} src={media.icon} alt={`${media.name}_icon`} />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </div>
                  <div className={`d-flex px-3 py-2 ${Style.next_btn_div}`}>
                    <button
                      type={"submit"}
                      disabled={loader}
                      className={`border_on_hover fs-14px mr-2 bg-586886 border border-1 border-586886 br-23px px-30px h-2rem turient-header-text-color`}
                    >
                      Next
                    </button>
                    {loader && <Loader class="ml-3 mr-1" />}
                  </div>
                </form>
              </Col>
            ) : (
              <MembersPermission loader={loader} />
            )}
          </>
        ) : loader ? (
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
            <Loader class="ml-3 mr-1 mb-2" />
            School setup in progress...
          </div>
        ) : (
          <div>You don't have permission to visit this page</div>
        )}
      </Col>
    </div>
  )
}

export default SchoolProfileSetup
