import React from "react"
import PropTypes from "prop-types"

import DateTimePicker from "react-datetime-picker"

// !definition of component
/**
 *
 * @param props --> className, required, minDate, maxDate, format
 *                  dayPlaceholder, monthPlaceholder, yearPlaceholder,
 *                  hourPlaceholder, minutePlaceholder, secondPlaceholder
 *                  value and onClick function
 * @description --> Pass a state variable as value prop
 *                  and update the state variable in custom on Change function
 * @description --> To make style changes, edit in src/modularscss/custom_datetime_picker.scss
 * @returns DateTimePicker component
 */
// ! component

const CustomDateTimePicker = (props) => {
  const getMinDate = () => {
    let minDate = new Date()
    minDate.setHours(0, 0, 0, 0)
    return minDate
  }
  return (
    <DateTimePicker
      className={`${props.className}`}
      value={props.value}
      onChange={(e) => {
        props.onChange(new Date(e))
      }}
      minDate={props.minDate ?? getMinDate()}
      maxDate={props.maxDate}
      format={props.format}
      dayAriaLabel="Day"
      dayPlaceholder={props.dayPlaceholder}
      monthAriaLabel="Month"
      monthPlaceholder={props.monthPlaceholder}
      yearAriaLabel="Year"
      yearPlaceholder={props.yearPlaceholder}
      hourAriaLabel="Hours"
      hourPlaceholder={props.hourPlaceholder}
      minuteAriaLabel="Minutes"
      minutePlaceholder={props.minutePlaceholder}
      secondAriaLabel="Seconds"
      secondPlaceholder={props.secondPlaceholder}
      amPmAriaLabel="Select AM/PM"
      showLeadingZeros={false}
      clearIcon={null}
      calendarIcon={null}
      disableClock={true}
      required={props.required}
      onCalendarOpen={() => {
        //fix for calendar breaking in safari browser
        document.querySelectorAll(".react-datetime-picker__calendar").forEach((element) => {
          element.setAttribute("tabindex", -1)
        })
      }}
    />
  )
}

CustomDateTimePicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  format: PropTypes.string,
  dayPlaceholder: PropTypes.string,
  monthPlaceholder: PropTypes.string,
  yearPlaceholder: PropTypes.string,
  hourPlaceholder: PropTypes.string,
  minutePlaceholder: PropTypes.string,
  secondPlaceholder: PropTypes.string,
  required: PropTypes.bool,
}

CustomDateTimePicker.defaultProps = {
  className: "custom_datetime_picker",
  maxDate: new Date("9999-12-31"),
  format: "dd-MM-y h:mm a",
  dayPlaceholder: "DD",
  monthPlaceholder: "MM",
  yearPlaceholder: "YY",
  hourPlaceholder: "H",
  minutePlaceholder: "M",
  secondPlaceholder: "S",
  required: true,
}

export default CustomDateTimePicker
