import React, { useEffect, useState, useCallback, useContext } from "react"
import { navigate } from "gatsby"
import debounce from "lodash/debounce"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import Dropdown from "react-bootstrap/Dropdown"
import FormGroup from "react-bootstrap/FormGroup"
import Spinner from "react-bootstrap/Spinner"
import { getRequest, postRequest, deleteRequest, putRequest } from "../../services/rest_service"

import StylesA from "../quizzes/quiz.module.scss"
import Styles from "../../modularscss/styles.module.scss"
import StylesB from "../courses/course.module.scss"
import StylesC from "../case-study/casestudy.module.scss"
import SEO from "../../components/seo"
import Image from "../custom/Image"

import DeleteModal from "../custom/DeleteModal"
import { isBrowser } from "../../services/developer_mode"

import EllipsisV from "../../images/svgs/threedots.svg"
import ExclamationTriangleYellow from "../../images/svgs/exclamation-triangle-yellow.svg"
import ChevronCircleUp from "../../images/svgs/arrow-dropup-white-circle.svg"
import ChevronCircleDown from "../../images/svgs/arrow-dropdown-white-circle.svg"
import DragHandleIcon from "../../images/svgs/drag-handle-white.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import CheckIcon from "../../images/svgs/check-white.svg"
import CheckSquare from "../../images/svgs/check-square.svg"
import Square from "../../images/svgs/square.svg"

import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../stores/global_store/GlobalActions"

import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_REFETCH_RESOURCES, SET_SELECTED_RESOURCE_TYPE } from "../../stores/sidebar_store/SidebarActions"

const PublishPoll = (location) => {
  const [questions, setQuestions] = useState([])
  const [questionsNext, setQuestionsNext] = useState(null)
  const [questionsFetched, setQuestionsFetched] = useState(false)
  const [collapse, setCollapse] = useState(false)

  const [draggedData, setDraggedData] = useState(false)

  const [deleteResourceData, setDeleteResourceData] = useState({})

  const [questionCreated, setQuestionCreated] = useState(true)

  const [questionOptions, setQuestionOptions] = useState({})
  const [questionToggle, setQuestionToggle] = useState({})
  const [optionsFetched, setOptionsFetched] = useState(false)
  const [toggleQuestion, setToggleQuestion] = useState(false)
  const [questionOptionsFetched, setQuestionOptionsFetched] = useState({})

  const [currentQuestionId, setCurrentQuestionId] = useState("")

  const [optionCreated, setOptionCreated] = useState(true)

  const [usingDropdown, setUsingDropdown] = useState(false)

  const [quiz, setQuiz] = useState({})
  const [savedContent, setSavedContent] = useState(true)
  const [clickedPublish, setClickedPublish] = useState(false)

  const [questionsCount, setQuestionsCount] = useState(false)

  const [sidebarState, sidebarDispatch] = useContext(SidebarContext)
  const [globalState, dispatch] = useContext(GlobalContext)

  function sendQuery(name, value, item_id, type, questionsData, optionsData, index, question_id) {
    if (type === "quiz") {
      handleQuizName(name, value)
    } else if (type === "question") {
      updateQuestion(name, value, item_id, questionsData, index, optionsData)
    } else {
      updateOption(name, value, item_id, questionsData, optionsData, index, question_id)
    }
  }

  const delayedQuery = useCallback(
    debounce(
      (name, value, item_id, type, questionsData, optionsData, index, question_id) =>
        sendQuery(name, value, item_id, type, questionsData, optionsData, index, question_id),
      1000
    ),
    []
  )

  useEffect(() => {
    fetchQuizQuestions()
    fetchQuiz()
  }, [location.quiz_id])

  useEffect(() => {
    let tabignore_divs = document.getElementsByClassName("tab-ignore")
    for (let i = 0; i < tabignore_divs.length; i++) {
      tabignore_divs[i].tabIndex = "-1"
    }
  }, [optionsFetched, currentQuestionId, document.getElementsByClassName("tab-ignore").length])

  async function fetchQuiz() {
    let res = await getRequest(`/quiz/${location.quiz_id}/`)
    if (res.success) {
      setQuiz(res.data)
      focusOnData("QuizName")
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function fetchQuizQuestions(refetch = false) {
    if (!refetch) {
      setQuestionsFetched(false)
    }
    let res = await getRequest(`/quiz-question/?limit=20&quiz=${location.quiz_id}`)
    if (res.success) {
      setQuestionsCount(res.data.count)
      setQuestions(res.data.results)
      setQuestionsNext(res.data.next)
      setQuestionsFetched(true)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function fetchNextQuizQuestions(next_url) {
    let res = await getRequest(next_url)
    if (res.success) {
      let all_questions = [...questions]
      all_questions.concat(res.data.results)
      setQuestions(all_questions)
      setQuestionsNext(res.data.next)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function fetchOptions(id, fetch = false, question_resource_id = "") {
    if (questionOptions["question_" + id] === undefined || fetch) {
      let res = await getRequest(`/question-option/?quiz_question=${id}`)
      if (res.success) {
        let all_options = { ...questionOptions }
        let option_loader = { ...questionOptionsFetched }
        all_options["question_" + id] = res.data.results
        setQuestionOptions(all_options)
        option_loader["question_" + id] = true
        setQuestionOptionsFetched(option_loader)
        setOptionsFetched(true)
      } else {
        setOptionsFetched(false)
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      if (currentQuestionId != id) {
        setCurrentQuestionId(id)
      } else {
        setCurrentQuestionId("")
      }
      setOptionsFetched(true)
      setToggleQuestion(!toggleQuestion)
    }
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  async function deleteResource(item) {
    if (item) {
      let url = item.quiz_question ? "question-option" : "quiz-question"
      let res = await deleteRequest(`/${url}/${item.id}/`)
      if (res.success) {
        if (url === "quiz-question") {
          let questions_data = [...questions]
          let deleted_question_index = questions_data.findIndex((element) => element.id === item.id)
          questions_data.splice(deleted_question_index, 1)
          setQuestions(questions_data)
          setQuestionsCount(questionsCount - 1)
        } else {
          let questions_data = [...questions]
          let options_data = { ...questionOptions }
          let deleted_option_index = options_data["question_" + item.quiz_question].findIndex((element) => element.id === item.id)
          options_data["question_" + item.quiz_question].splice(deleted_option_index, 1)
          setQuestionOptions(options_data)
          handleQuestionIsValid(item.quiz_question, options_data, questions_data)
        }
        setDeleteResourceData({})
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  function handleClose() {
    setDeleteResourceData({})
  }

  async function createQuestion(value) {
    let formData = new FormData()
    formData.append("title", "sample question console")
    formData.append("quiz", location.quiz_id)
    formData.append("data_type", "1")
    formData.append("question_type", value)
    let res = await postRequest("/quiz-question/", formData)
    if (res.success) {
      setQuestions((prev) => {
        let all_questions = [...prev]
        let dummy = { ...res.data }
        dummy.is_valid_question = false
        all_questions = all_questions.concat(dummy)
        return all_questions
      })
      setOptionsFetched(false)
      setQuestionCreated(true)
      fetchOptions(res.data.id)
      handleQuestionToggle(res.data.id)
      let input = document.getElementById(res.data.id + `-question-input-id`)
      input && input.focus()
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  const getErrorMessage = (question) => {
    let isQuestionBlank = question.title === "sample question console" || !question.title?.trim()
    let isOptionBlank = questionOptions[`question_${question.id}`]?.some((option) => option.name === "sample option console" || !option.name?.trim())
    return isQuestionBlank ? "Question cannot be blank" : isOptionBlank ? "Options cannot be blank" : "Above question is invalid"
  }

  async function createOption(question_data) {
    let question_id = question_data.id
    let option_name = `sample option console`
    let formData = new FormData()
    formData.append("quiz_question", question_id)
    formData.append("name", option_name)
    let res = await postRequest("/question-option/", formData)
    if (res.success) {
      setQuestionOptions((prev) => {
        let options_data = { ...prev },
          index = "question_" + question_id
        if (!options_data[index]) {
          options_data[index] = []
        }
        options_data[index] = [...options_data[index], res.data]
        return options_data
      })
      setOptionCreated(true)
      let input = document.getElementById(res.data.id + `-question-option-input-id`)
      input && input.focus()
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }
  function handleQuestionIsValid(question_id, options_data, questions_data, updateQuestion = 1, filtered_data = -1, value = -1) {
    if (filtered_data < 0) {
      filtered_data = questions_data.findIndex((ques) => ques.id === question_id)
    }
    let isValid = false
    if (value !== -1) {
      isValid = value !== "" && value !== "sample question console" && value !== "0"
    } else {
      let data = options_data["question_" + question_id]
      if (!data || filtered_data < 0) return -1

      let question = questions_data[filtered_data]
      let optionIsNotEmpty = data.filter((option) => option.name !== "" && option.name !== "sample option console")
      if (
        options_data["question_" + question_id].length >= 2 &&
        optionIsNotEmpty.length >= 2 &&
        question.title &&
        question.title !== "" &&
        question.title !== "sample question console"
      ) {
        isValid = true
      }
    }
    if (updateQuestion === 1) {
      let dummy = [...questions_data]
      dummy[filtered_data].is_valid_question = isValid
      setQuestions(dummy)
    }
    return isValid
  }

  async function updateQuestion(name = null, value = null, question_id, questionsData, index = null, optionsData = null) {
    if (name === "title") {
      value = value.trim()
      if (value === "") value = "sample question console"
    }

    let formData = new FormData()
    formData.append(name, value)
    if (question_id !== undefined && value) {
      let res = await putRequest("/quiz-question/" + question_id + "/", formData)
      if (res.success) {
        setSavedContent(true)
        if (questionsData && index >= 0) {
          setQuestions((prev) => {
            let questions_data = [...prev],
              isValid = res.data.is_valid_question
            questions_data[index][name] = value
            if (["title"].includes(name)) {
              if (optionsData === null) {
                optionsData = { ...questionOptions }
              }
              if (optionsData["question_" + question_id] !== undefined) {
                value = -1
              }
              isValid = handleQuestionIsValid(question_id, optionsData, questions_data, 0, index, value)
              if (isValid === -1) {
                return questions_data
              }
            }
            questions_data[index]["is_valid_question"] = isValid
            return questions_data
          })
        }
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      setSavedContent(true)
    }
  }

  function handleUpdateQuestion(index, name = null, value = null, question_id) {
    delayedQuery(name, value, question_id, "question", questions, questionOptions, index)
  }
  function handleUpdateOption(index, name, value, option_id, question_id) {
    if (name === "name") {
      delayedQuery(name, value, option_id, "option", questions, questionOptions, index, question_id)
    }
  }

  async function updateOption(name, value, option_id, questionsData, optionsData, index = null, question_id = null) {
    if (name === "name") {
      value = value.trim()
      if (value === "") value = "sample option console"
    }

    let formData = new FormData()
    formData.append(name, value)
    if (option_id !== undefined && value !== undefined && value !== "") {
      let res = await putRequest("/question-option/" + option_id + "/", formData)
      if (res.success) {
        setSavedContent(true)
        if (optionsData && questionsData && index >= 0 && question_id) {
          setQuestionOptions((prev) => {
            let options_data = { ...prev },
              question_index = "question_" + question_id
            if (!options_data[question_index]) {
              options_data[question_index] = []
            }
            let option = options_data[question_index][index]
            option[name] = value
            if (name === "name") {
              let questions_data = [...questionsData]
              handleQuestionIsValid(question_id, options_data, questions_data)
            }
            return options_data
          })
        }
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      setSavedContent(true)
    }
  }

  function handleQuestionToggle(question_id, type, fetch_options = true) {
    if (type === "1" || (type === "0" && fetch_options)) {
      fetchOptions(question_id)
    }
    let toggle_data = { ...questionToggle }
    toggle_data[question_id] = !toggle_data[question_id]
    setQuestionToggle(toggle_data)
  }

  function showQuizQuestions() {
    if (questionsFetched && questions.length > 0) {
      return (
        <div className={`col-12 px-0`}>
          {questions.map((datum, index) => {
            let question_error_class = datum?.is_valid_question
            let toggle_class = questionToggle[datum.id]
            return (
              <React.Fragment key={datum.id + `-quiz-rc-question-key-` + index}>
                <div
                  key={datum.id + `-quiz-question-key-` + index}
                  className={`br-8px ${StylesA.question_div} ${question_error_class == true ? `border_on_hover` : `border_1px_904744`} mb-2`}
                >
                  <div className={`${StylesA.question_name_div} w-100 py-1 ${toggle_class ? `border-bottom-0` : ``}`}>
                    <div
                      className={`${StylesA.drag_handle_div} px-2 py-1 align-items-center`}
                      onClick={(e) => handleQuestionToggle(datum.id, datum.question_type)}
                    >
                      <img src={DragHandleIcon} alt={`question-drag-handle_${datum.id.toString()}`} className={`my-auto`} />
                      <p className={`mb-0 pl-2 fs-20px white-text`}>{index + 1}</p>
                    </div>
                    <input
                      type="text"
                      name="title"
                      id={datum.id + `-question-input-id`}
                      defaultValue={datum ? (datum.title === "sample question console" ? `` : datum.title) : ""}
                      placeholder={"Enter Question Title"}
                      onChange={(e) => {
                        setSavedContent(false)
                        handleUpdateQuestion(index, e.target.name, e.target.value, datum.id)
                      }}
                      onBlur={(e) => {
                        e.preventDefault()
                        if (datum.title !== e.target.value) {
                          updateQuestion(e.target.name, e.target.value, datum.id, questions, index)
                        }
                      }}
                      className={`${StylesA.question_title_input} form-control px-3`}
                      required
                    />
                    <div className="d-flex align-items-center">
                      <img
                        onClick={(e) => handleQuestionToggle(datum.id, datum.question_type)}
                        src={toggle_class ? ChevronCircleUp : ChevronCircleDown}
                        alt={"arrow for collapse and expand"}
                        className={`${StylesA.arrow_circle} mr-2`}
                      />
                      <img
                        src={DeleteIcon}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          datum.index = index
                          setDeleteResourceData(datum)
                        }}
                        alt={`question delete icon`}
                        className={`my-auto mr-3 pointer object-fit-contain h-18px`}
                      />
                    </div>
                  </div>
                  {toggle_class ? (
                    <div className={`${StylesA.options_section} py-3 pl-4 pr-3`}>
                      {showOptionButtons(questionOptions["question_" + datum.id], datum, datum.id, index)}
                    </div>
                  ) : (
                    ``
                  )}
                </div>
                {!question_error_class && clickedPublish ? (
                  <Col xs="12" sm="12" md="12" lg="12" className={`mt-2 mb-2 px-0 `}>
                    <div className="d-inline-flex px-3 py-0 br-23px">
                      <Image className="mt-1 mr-1 h-14px" src={ExclamationTriangleYellow} />
                      <p className="mb-0 fs-14px color-F4F411">{getErrorMessage(datum)}</p>
                    </div>
                  </Col>
                ) : null}
              </React.Fragment>
            )
          })}
        </div>
      )
    } else {
      return <p className="fs-13px m-0 white-text mb-1 text-center">No questions created yet!</p>
    }
  }

  function toLetters(num) {
    let mod = num % 26,
      pow = (num / 26) | 0,
      out = mod ? String.fromCharCode(64 + mod) : (--pow, "Z")
    return pow ? toLetters(pow) + out : out
  }

  function showOptionButtons(data, question_data, question_resource_id, question_index) {
    let question_id = question_data.id
    if (data) {
      return (
        <React.Fragment>
          <div className={`col-12 bg-transparent pt-2`}>
            {data.map((datum, index) => (
              <Col lg="12" md="12" sm="12" xs="12" key={datum.id + `-option-key-` + index + `-${question_id}`} className="px-0 d-flex mb-2 tab-ignore">
                <div className={`${StylesA.option_div} border_on_hover`}>
                  <div className={`${StylesA.drag_handle_div} px-2 py-1`}>
                    <img src={DragHandleIcon} alt={`option-drag-handle_${datum.id.toString()}`} className={`my-auto`} />
                  </div>
                  <input
                    type="text"
                    name="name"
                    id={datum.id + `-question-option-input-id`}
                    defaultValue={datum.name === "sample option console" ? `` : datum.name}
                    onChange={(e) => {
                      e.preventDefault()
                      setSavedContent(false)
                      handleUpdateOption(index, e.target.name, e.target.value, datum.id, question_id)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      if (datum.name !== e.target.value) {
                        updateOption(e.target.name, e.target.value, datum.id, questions, questionOptions, index, question_id)
                      }
                    }}
                    placeholder={"Enter Option text"}
                    className={`${StylesA.question_title_input} form-control px-3`}
                    required
                  />
                </div>
                <div
                  className={`${StylesA.question_delete_div} pointer ml-3`}
                  onClick={(e) => {
                    e.preventDefault()
                    setDraggedData(!draggedData)
                    setDeleteResourceData(datum)
                  }}
                >
                  <img style={{ width: "15px", height: "15px" }} alt={`delete_option_${datum.id.toString()}`} src={DeleteIcon} className="mb-0" />
                </div>
              </Col>
            ))}
            <div className={`py-2 d-flex bg-transparent`}>
              <div className={`d-inline-flex w-100 align-items-center text-center`} style={{}}>
                <button
                  disabled={!optionCreated}
                  onClick={(e) => {
                    e.preventDefault()
                    setOptionCreated(false)
                    createOption(question_data)
                  }}
                  className={`${StylesB.add_button} border_on_hover`}
                >
                  {optionCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                  Add Option
                </button>
                <div
                  className={`d-inline-flex align-items-center ml-auto mr-5 pointer white-text`}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    updateQuestion("question_type", question_data.question_type === "0" ? "1" : "0", question_data.id, questions, question_index)
                  }}
                >
                  {question_data.question_type === "0" ? <Image src={Square} className="w-18px" /> : <Image src={CheckSquare} className="w-18px" />}
                  <p className={`mb-0 pl-2 fs-14px bold-500`}>Allow users to select multiple options</p>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  function handleChangeQuiz(e, name = null, value = null, type = "") {
    let current_quiz = { ...quiz }
    if (name != null) {
      value = value
      current_quiz[name] = value
    }
    setQuiz(current_quiz)
    delayedQuery(name, value, location.quiz_id, "quiz")
  }

  async function handleQuizName(name, value) {
    let formData = new FormData()
    formData.append(name, value)
    if (location.quiz_id !== undefined && value !== "") {
      let quiz_id = location.quiz_id
      let res = await putRequest("/quiz/" + quiz_id + "/", formData)
      if (res.success) {
        setSavedContent(true)
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      setSavedContent(true)
    }
  }

  async function handleSubmitQuiz(event) {
    event.preventDefault()
    let check_error = questions.filter((ques) => ques.is_valid_question === false)

    if (location.quiz_id != undefined && check_error.length == 0 && quiz.name?.trim() != "" && quiz.name !== "sample quiz console") {
      let quiz_id = location.quiz_id
      sidebarDispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Polls" })
      let res = await postRequest("/quiz/" + quiz_id + "/publish_quiz/", {})
      if (res.success) {
        if (res.data.error) {
          dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.data.error } })
        }
        if (res.data.success) {
          setUsingDropdown(!usingDropdown)
          fetchQuizQuestions(true)
          setQuestionToggle({})
          setQuestionOptions({})
          dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "success", title: "Yay! Your Poll got published" } })
          sidebarDispatch({ type: SET_REFETCH_RESOURCES, payload: true })
          if (window.opener) {
            const evt = new CustomEvent("my-refresh-resources", {
              bubbles: true,
              detail: "poll",
            })
            window.opener?.document && window.opener.document.dispatchEvent(evt)
          }
        }
        setUsingDropdown(!usingDropdown)
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      if (quiz.name?.trim() == "" || quiz.name === "sample quiz console") {
        let error_messages = "please provide valid poll name"
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error_messages } })
      } else {
        let error_messages = "One or More questions are not valid"
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error_messages } })
      }
    }
  }

  function showQuizDetails() {
    return (
      <Row className="m-0 mb-5">
        <p className={`p-2 w-100 m-2`}>Poll Overview </p>
        <Col xs="12" sm="5" md="5">
          <Col xs="12" sm="12" md="12" className="light-div p-0 theme-color float-left" style={{ minHeight: "auto", borderRadius: "10px" }}>
            <Col xs="12" sm="6" md="12" className="float-left border-bottom">
              <FormGroup className="m-0 d-flex align-items-center">
                <div style={{ fontSize: `13px`, minWidth: "150px" }}>No of Questions</div>
                <input
                  style={{ background: "inherit", color: "inherit", fontSize: "16px" }}
                  className="blue-bg video-modal number-input text-white font-weight-bold text-right"
                  type="number"
                  name="no_of_questions"
                  defaultValue={quiz.no_of_questions}
                  onChange={handleChangeQuiz}
                  id="no_of_questions"
                  required
                />
              </FormGroup>
            </Col>
            <Col xs="12" sm="6" md="12" className="float-left">
              <FormGroup className="m-0 d-flex align-items-center">
                <div style={{ fontSize: `13px`, minWidth: "150px" }}>Total time</div>
                <input
                  style={{ background: "inherit", color: "inherit", fontSize: "16px" }}
                  className="blue-bg video-modal number-input float-left text-white font-weight-bold text-right"
                  type="number"
                  name="quiz_time"
                  defaultValue={quiz.quiz_time}
                  onChange={handleChangeQuiz}
                  id="quiz_time"
                  placeholder=" "
                  required
                />
                <div className={`font-weight-bold text-white`} style={{ fontSize: `13px` }}>
                  Mins
                </div>
              </FormGroup>
            </Col>
          </Col>
        </Col>
      </Row>
    )
  }

  function checkNameValidation() {
    if ((quiz.name = "sample quiz console")) {
      if (isBrowser() && document.getElementById(`quiz-title`)) {
        let topic_div = document.getElementById(`quiz-title`).offsetTop
        setTimeout(function () {
          document.getElementById("scrollable-div").scroll({
            behavior: "smooth",
            left: 0,
            top: topic_div + 60 + 63 + 10,
          })
        }, 200)
      }
    }
  }

  async function deletePoll(id) {
    let res = await deleteRequest(`/quiz/` + id + "/")
    if (res.success) {
      navigate(`/app/polls/`)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  const focusOnData = (id) => {
    let data = document.getElementById(id)
    data && data.focus()
  }

  return (
    <div>
      <SEO title="Poll Publish" />
      <Col lg="12" md="12" sm="12" xs="12" className={`px-4 d-inline-flex float-left`}>
        <div id="error-div-for-option" className="position-absolute text-white" style={{ top: "0px", right: "0px", zIndex: "2" }}></div>
        <div id={`scrollable-div`} className={`px-4 pt-0 float-left w-100`}>
          <div className={`w-100 d-inline-flex align-items-center mb-2 py-2 ${StylesB.course_creation_header}`}>
            <p className="mb-0 py-2 px-3 font-weight-bold br-9009px white-text fs-18px min-w-max-content line-height-1" id="coursename">
              {(quiz && quiz?.name?.length === 0) || quiz?.name === "sample quiz console" ? "Creating Poll" : quiz?.name}
            </p>
            <Dropdown className="breakout-groups pointer mr-3 ml-auto float-right">
              <Dropdown.Toggle className="border-none p-0 shadow-none bg-transparent" id="dropdown-basic">
                <div>
                  <Image src={EllipsisV} className={`white-text object-fit-contain h-20px`} />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className={`br-9px breakout-groups ${StylesB.min_fit_width}`}>
                <Dropdown.Item
                  onClick={(e) => {
                    e.preventDefault()
                    deletePoll(quiz.id)
                  }}
                  className={`${StylesB.dropitem} text-white fs-14px px-2 py-1 d-inline-flex min-w-max-content`}
                >
                  &nbsp;Cancel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* {deleteQuestionModal()} */}
          <div className={`w-100 float-left mb-5`} style={{ backgroundColor: `#212c42`, borderRadius: `10px`, color: "#e0e2e5" }}>
            <Col xs="12" sm="12" md="12" className={`px-0 pt-3`}>
              <div className={`w-100 px-0`}>
                <div className="w-100 px-3" style={{ maxHeight: "calc(100vh - 250px)", overflowY: "scroll", backgroundColor: "#212C42" }}>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="border_on_hover pl-0"
                    style={{
                      backgroundColor: "#303C54",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      border: "1px solid #303C54",
                      marginBottom: "12px",
                    }}
                  >
                    <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                      <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                        <InputGroup.Text
                          className="border-0 d-flex justify-content-center"
                          style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                          id="basic-addon1"
                          onClick={(e) => focusOnData("QuizName")}
                        >
                          Poll Name:
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className={`border-0 d-flex justify-content-center shadow-none ${StylesC.cs_create} text-white`}
                        style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px" }}
                        type="text"
                        name="name"
                        defaultValue={quiz.name === "sample quiz console" ? `` : quiz.name}
                        onChange={(e) => {
                          setSavedContent(false)
                          handleChangeQuiz(e, e.target.name, e.target.value)
                        }}
                        onBlur={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          handleQuizName(e.target.name, e.target.value)
                        }}
                        id="QuizName"
                        placeholder={"Enter Poll Title"}
                        required
                      />
                    </InputGroup>
                  </Col>
                  {showQuizQuestions()}
                  {questionsFetched && questionsNext !== null ? (
                    <Col md="auto" className={`d-inline-flex w-100 my-2`}>
                      <Button
                        variant="none"
                        onClick={(e) => {
                          e.preventDefault()
                          fetchNextQuizQuestions(questionsNext)
                        }}
                        className={`${Styles.font_12} text-center px-3 py-2 mx-auto bg-4b5a9b color-c6cff4 br-14px`}
                      >
                        See More
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                  <div className={`justify-content-center pt-2 pb-4 d-flex bg-transparent br-9px`}>
                    <div className={` text-center d-inline-flex align-items-center`}>
                      <button
                        disabled={!questionCreated}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setQuestionCreated(false)
                          createQuestion("0")
                        }}
                        className={`${StylesB.add_button} border_on_hover fs-14px`}
                      >
                        {questionCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                        Add question
                      </button>
                    </div>
                  </div>
                </div>
                <Col
                  className="px-3 py-2 d-flex align-items-center justify-content-between"
                  style={{ borderTop: "1px solid #63666f", backgroundColor: "#212C42", borderRadius: "0px 0px 9px 9px", boxShadow: "0px -3px 12px #00000099" }}
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      setClickedPublish(true)
                      handleSubmitQuiz(e)
                    }}
                    className="border_on_hover fs-14px"
                    style={{
                      backgroundColor: "#586886",
                      border: "1px solid #586886",
                      height: "28px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "23px",
                      color: "#E0E2E5",
                    }}
                  >
                    Publish
                  </button>
                  <div className="mb-0 fs-14px">
                    {!savedContent ? (
                      <span className={`bg-transparent border-0`} id={`basic-addon`}>
                        <Spinner animation="border" variant="secondary" size="sm" className="mr-1" />
                      </span>
                    ) : (
                      <Image src={CheckIcon} alt={`changes_saved_check`} className={`ml-auto mb-0 mr-1 object-fit-contain h-12px`} />
                    )}
                    All changes are saved
                  </div>
                </Col>
              </div>
            </Col>
          </div>
        </div>
      </Col>
      <DeleteModal
        show={Object.keys(deleteResourceData).length > 0}
        onHide={handleClose}
        itemName={deleteResourceData?.name}
        action={() => {
          deleteResource(deleteResourceData)
        }}
      />
    </div>
  )
}

export default PublishPoll
