import React, { useState } from "react"
import Style from "./style.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import PropTypes from "prop-types"

// !definition of component
/**
 *
 * @param props --> type , name,value, required, label, icon, onChange, onDelete and onBlur function
 * @description -->  type=> input type, name=> for id and name, required=> boolean, icon => object {name: faIcon}
 * @returns Input Field component
 */
// ! component
const CustomInputField = (props) => {
  const [error, setError] = useState(null)

  const handleChange = (e) => {
    setError(null)
    if (props.onChange) {
      props.onChange(e)
    }
  }
  const handleValidation = (e) => {
    if (props.customValidation) {
      const validationResult = props.customValidation(e.target.value)
      if (!validationResult) {
        e.preventDefault()
        setError("true")
        return
      } else {
        setError(null)
      }
    }
  }
  return (
    <div>
      <div
        style={props?.style}
        className={`${props?.className} ${Style.input_group} bg-303C54 br-9px border_on_hover px-5px mb-10px ${error && Style.red_border}`}
      >
        {props?.type === "number" ? (
          <div className="d-flex align-items-center px-2 py-2" Style={props?.inputdiv}>
            <input
              type="number"
              id={props?.name}
              name={props?.name}
              placeholder={props.placeholder ? props.placeholder : ""}
              onChange={handleChange}
              className={`${Style.inputText} turient-header-text-color w-100 pt-2px mt-1 bg-transparent border-transparent  fs-14px   mt-3 ${
                props.readOnly && "disable-content"
              }`}
              onInvalid={(e) => {
                e.preventDefault()
                e.target.focus()
                setError("true")
              }}
              customvalidation={props?.customValidation}
              onBlur={handleValidation}
              value={props?.value}
              required={props?.required}
              min={props.min ? props.min : 0}
              max={props.max ? props.max : 999999}
              autoComplete="off"
            />
            <span
              style={props?.icon && { left: "auto" }}
              className={`${Style.floating_label} position-absolute fs-16px turient-header-text-color opacity-40 d-flex-align-center`}
            >
              {props.label}
              {!props.required && <span className={`ml-1`}>(Optional)</span>}
            </span>
            <div>{props.children}</div>
          </div>
        ) : props.type == "tel" ? (
          <div className="p-2 w-100  h-100 d-flex">
            <div className={`flex-grow-1 ${props?.icon ? "ml-2" : ""}`}>
              <input
                type="tel"
                onChange={handleChange}
                id={props?.name}
                name={props?.name}
                pattern="^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$"
                className={`${Style.inputText} turient-header-text-color w-100 pt-2px mt-1 bg-transparent border-transparent  fs-18px  mt-3 ${
                  props.readOnly && "disable-content"
                }`}
                onInvalid={(e) => {
                  e.preventDefault()
                  e.target.focus()
                  setError("true")
                }}
                onBlur={handleValidation}
                customvalidation={props?.customValidation}
                required={props?.required}
                maxLength={props.maxLength}
                style={props?.inputTextStyle}
                placeholder={props?.placeholder}
                value={props?.value}
                autoComplete="off"
              />
              <span
                style={props?.icon && { left: "auto" }}
                className={`${Style.floating_label} position-absolute fs-16px turient-header-text-color opacity-40 d-flex-align-center`}
              >
                {props.label}
                {!props.required && <span className={`ml-1`}>(Optional)</span>}
              </span>
            </div>
          </div>
        ) : (
          <div className="p-2 w-100 h-100 d-flex">
            {props.icon && (
              <div className={`p-1`}>
                <FontAwesomeIcon className="h-100 fs-18px icon-color w-1_5rem" icon={props?.icon?.name} />
              </div>
            )}

            <div className={`flex-grow-1 ${props?.icon ? "ml-2" : ""}`}>
              <input
                id={props?.name}
                name={props?.name}
                onChange={handleChange}
                type={props?.type}
                className={`${Style.inputText} turient-header-text-color w-100 pt-2px mt-1 bg-transparent border-transparent  fs-18px  mt-3 ${
                  props.readOnly && "disable-content"
                }`}
                onInvalid={(e) => {
                  e.preventDefault()
                  e.target.focus()
                  setError("true")
                }}
                style={props?.inputTextStyle}
                customvalidation={props?.customValidation}
                onBlur={handleValidation}
                required={props?.required}
                value={props?.value}
                autoComplete="off"
              />
              <span
                style={props?.icon && { left: "auto" }}
                className={`${Style.floating_label} position-absolute fs-16px turient-header-text-color opacity-40 d-flex-align-center`}
              >
                {props.label}
                {!props.required && <span className={`ml-1`}>(Optional)</span>}
              </span>
            </div>
            {props?.onDelete && (
              <FontAwesomeIcon
                onClick={(e) => {
                  props.onDelete()
                }}
                className="align-self-center pointer"
                icon={faTrash}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
CustomInputField.defaultProps = {
  required: false,
  type: "text",
  readOnly: false,
}

CustomInputField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  customValidation: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  handleChange: PropTypes.func,
  handleValidation: PropTypes.func,
  type: PropTypes.string.isRequired,
  inputTextStyle: PropTypes.object,
  readOnly: PropTypes.bool,
}

export default CustomInputField
