import React, { useEffect } from "react"
import { ZOOM_BASE_URL, ZOOM_CLIENT_ID, ZOOM_REDIRECT_KEY, ZOOM_REDIRECT_URL } from "./constants"

const ZoomAuthentication = (location, props) => {
  let redirectUrl = new URLSearchParams(location.props.location.search).get("redirect")
  const goToZoomAuthentication = () => {
    localStorage.setItem(ZOOM_REDIRECT_KEY, redirectUrl)
    console.log(ZOOM_REDIRECT_URL, "ZOOM_REDIRECT_URL")
    window.location.href = `${ZOOM_BASE_URL}/oauth/authorize?response_type=code&client_id=${ZOOM_CLIENT_ID}&redirect_uri=${ZOOM_REDIRECT_URL}`
  }

  useEffect(() => {
    goToZoomAuthentication()
  }, [])

  return <div onClick={goToZoomAuthentication}>Login To Zoom</div>
}

export default ZoomAuthentication
