import React from "react"

import Style from "./trainer.module.scss"

const TextAreaWrap = (props) => {
  return (
    <div className={`mb-2 px-1 border_on_hover inner-element-box-shadow br-9px ${Style.bg_3} ${Style.hover_border}`}>
      <div className="p-2" style={{ border: "1px solid transparent", background: "transparent", borderRadius: "0px", width: "100%" }}>
        {props.children}
      </div>
    </div>
  )
}

export default TextAreaWrap
