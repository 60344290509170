import { muralPostRequest, muralGetRequest } from "../mural-api-service"

export const getRoomDetails = async (roomId) => {
  try {
    let data = await muralGetRequest(`/api/public/v1/rooms/${roomId}`)
    await getRoomMurals(roomId)
  } catch (error) {
    await getRoomDetails(roomId)
  }
}

export const createFolder = async (roomId, payload) => {
  try {
    let data = await muralPostRequest(`/api/public/v1/${roomId}/folders`, payload)
  } catch (error) {
    await createFolder(roomId, payload)
  }
}

export const getRoomMurals = async (roomId, account_id) => {
  try {
    let data = await muralGetRequest(`/api/public/v1/rooms/${roomId}/murals`, account_id)
    return data.data
  } catch (error) {
    await getRoomMurals(roomId)
  }
}

export const duplicateMural = async (muralId, payload) => {
  try {
    let data = await muralPostRequest(`/api/public/v1/murals/${muralId}/duplicate`, payload)
  } catch (error) {
    await duplicateMural(muralId, payload)
  }
}
