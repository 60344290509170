import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import PercentageProgressBar from "../../custom/PercentageProgressBar"
import { ScholarHatLogo } from "../../../constants/global"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import utcDateTime from "../../../services/utcdatetime"

import CalanderImg from "../../../images/svgs/calander.svg"
import Style from "../style.module.scss"

const EngagementListCard = (props) => {
  return (
    <div className="w-100  d-flex flex-row justify-content-between mb-3">
      <div className="w-80 br-9px bg-586886 p-2 d-flex flex-row justify-content-between position-relative">
        {(props.type === "sessions-list" || props.type === "topics-list") && props.data.training_name ? (
          <div className={`${Style.class_tag} d-flex bg-4E515F`}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip placement="top" className="classroom-tooltip fs-12px">
                  {props.data.training_name}
                </Tooltip>
              }
            >
              <p className="fs-8px mb-0 ellipse px-1">{props.data?.training_name}</p>
            </OverlayTrigger>
          </div>
        ) : null}
        <div className={`${props.calender ? "w-50" : "w-100"} d-flex flex-row align-items-center`}>
          {props.icon && (
            <LazyLoadImage
              className={`m-0 pr-2`}
              height={"30px"}
              src={props.data?.avatar && props.data?.avatar?.length > 0 ? props.data?.avatar : ScholarHatLogo}
              alt={"name"}
              effect="blur"
            />
          )}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip placement="top" className="classroom-tooltip">
                {props.data.name}
              </Tooltip>
            }
          >
            <p className={`w-100 fs-12px ellipse pointer mb-0 py-1`}>
              {props.type === "sessions-list" ? "Session: " : ""}
              {props.data.name}
            </p>
          </OverlayTrigger>
        </div>
        {props.calender && (
          <div className=" d-flex flex-row align-items-center">
            <LazyLoadImage className={`m-0`} height={"25px"} src={CalanderImg} alt={"Calander"} effect="blur" />
            <div className="d-flex flex-column">
              <p className="fs-10px mb-1">{utcDateTime.displayTrainingDate(props.data.start_date, props.data.end_date)}</p>
              <p className="fs-10px mb-0">{utcDateTime.displayTimeDetail(props.data.start_date, props.data.end_date)}</p>
            </div>
          </div>
        )}
      </div>

      <div className="w-15">
        <PercentageProgressBar data={props.data} type={props.type} />
      </div>
    </div>
  )
}

export default EngagementListCard
