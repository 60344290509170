import React, { useContext, useEffect, useState } from "react"
import { AuthCodeProvider } from "react-oauth2-authcode-provider"
import { navigate, Link } from "gatsby"
import config from "./config"
import refreshToken from "./refreshToken"

const MuralAuthorization = (location, props) => {
  useEffect(() => {
    navigate(`/app/mural/auth`)
  }, [])

  return <></>
}

export default MuralAuthorization
