import React, { useEffect, useState, useCallback } from "react"
import { navigate, Link } from "gatsby"
import _ from "lodash"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Dropdown from "react-bootstrap/Dropdown"
import { NewCheckBox } from "./course-content"
import { DragDropContext } from "react-beautiful-dnd"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEllipsisV,
  faChevronCircleUp,
  faChevronDown,
  faChevronRight,
  faInfoCircle,
  faChevronLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons"

import GreenTick from "../../images/svgs/greentick.svg"
import PublishTick from "../../images/svgs/publishcheck.svg"
import Close from "../../images/svgs/close.svg"
import PublishTemplateIcon from "../../images/svgs/publish-template.svg"

import Styles from "../../modularscss/styles.module.scss"
import StylesB from "./course.module.scss"
import { HasReadPermission, HasEditPermission } from "../../utils/PermissionDataAccess"
import { COURSES } from "../../constants/permissionsConstant"
import Image from "../custom/Image"

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  border: "1px solid transparent",
  borderRadius: "8px",
  color: "#E0E2E5",
  background: "#303C54",
  boxShadow: "0px 3px 6px #00000029",

  // styles we need to apply on draggables
  ...draggableStyle,
})
const getTopicItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  background: "#42506C",
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})
const getResourceItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  // background: '#42506C',
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#000" : "#12172c",
  background: "#212C42",
  padding: "8px 8px 0px",
})
const getTopicListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#000" : "#12172c",
  // background: '#212C42',
  // padding: grid,
})

const CoursePublish = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [course, setCourse] = useState({ company: location.company_id })
  const [courseName, setCourseName] = useState("")
  const [width, setWidth] = useState(1000)

  const [showPublishNow, setShowPublishNow] = useState(false)
  const [showPublishLater, setShowPublishLater] = useState(false)

  const [courseTypeA, setCourseTypeA] = useState(0)

  const [isSuccess, setIsSuccess] = useState(false)
  const [hovered, setHovered] = useState(false)

  const [publishTo, setPublishTo] = useState("")
  const [updateTemplates, setUpdateTemplates] = useState("")
  const [updateTemplatesList, setUpdateTemplatesList] = useState([])
  const [createTemplate, setCreateTemplate] = useState("")
  const [searchTemplate, setSearchTemplate] = useState("")
  const [allTemplates, setAllTemplates] = useState([])
  const [allTemplatesCount, setAllTemplatesCount] = useState(-1)
  const [allTemplatesNext, setAllTemplatesNext] = useState(null)
  const [allTemplatesFetched, setAllTemplatesFetched] = useState(false)
  const isPermitted = HasEditPermission(COURSES) && HasReadPermission(COURSES)

  useEffect(() => {
    fetchCourse()
    fetchTemplates("")
    setShowPublishNow(true)
  }, [])

  function fetchTemplates(value) {
    let search = "&search=" + value
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-default-course-templates-list/?parent_course_id=${location.id}&paginate_by=8` + search, {
        headers: login_credentials,
      })
      .then((res) => {
        setAllTemplates(res.data.results)
        setAllTemplatesNext(res.data.next)
        if (allTemplatesCount < 0) {
          setAllTemplatesCount(res.data.count)
        }
      })
  }

  async function fetchNextTemplates(next_url) {
    if (next_url !== null) {
      axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
        var next_template = allTemplates
        if (res.data.results.length > 0) {
          for (var template = 0; template <= res.data.results.length - 1; template++) {
            next_template.push(res.data.results[template])
          }
          setAllTemplates(next_template)
        }
        setAllTemplatesNext(res.data.next)
      })
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      setWidth(window.innerWidth)
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
      })
      return () => {
        window.removeEventListener("resize", () => {})
      }
    }
  }, [])

  useEffect(() => {
    setHovered(false)
  }, [width])

  async function fetchCourse() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/courses/${location.id}/?publish_data=true`, { headers: login_credentials }).then((res) => {
      setCourse(res.data)
      let str = res.data.name
      if (str.length > 23) {
        let res1 = str.substring(0, 22)
        let res2 = res1 + "..."
        setCourseName(res2)
      } else {
        setCourseName(str)
      }
      if (res.data.course_type) {
        setCourseTypeA(res.data.course_type)
      }
    })
  }

  function publishLaterBox() {
    return (
      <React.Fragment>
        {showPublishLater ? (
          <div
            onClick={(e) => {
              e.preventDefault()
              setShowPublishLater(true)
            }}
            className={`mt-3 text-color-1 ${StylesB.publish_card}`}
          >
            <div className={`px-3 d-flex justify-content-between pt-3 pb-1`}>
              <div className={`${StylesB.publish_card_title}`}>Publish Later</div>
              <div className={`mr-3 text-color-1`}>
                <FontAwesomeIcon
                  className="pointer"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setShowPublishLater(false)
                  }}
                  aria-controls="publish-later-box"
                  style={{ height: "24px", width: "24px" }}
                  icon={faChevronCircleUp}
                />
              </div>
            </div>
            <div className={`d-flex ${StylesB.border_top} px-3 py-2 pb-3 justify-content-between`}>
              <div className={`d-flex flex-column px-5 py-3`}>
                <div className={`fs-15px mr-5`}>
                  Your course content is now saved successfully. Choose this option if you want to save it as a draft. This can be later accessed under
                  Dashboard/Courses/Drafts.
                </div>
                <div className={`mt-4 align-self-center`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      navigate("/app/dashboard")
                    }}
                    className={`border_on_hover fs-16px ${!isPermitted && "disable-content"}`}
                    style={{
                      backgroundColor: "#586886",
                      border: "1px solid #586886",
                      height: "32px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "23px",
                      color: "#E0E2E5",
                    }}
                    disabled={!isPermitted}
                  >
                    I am done
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            onClick={(e) => {
              e.preventDefault()
              setShowPublishLater(true)
            }}
            className={`mt-3 text-color-1 pointer justify-content-between d-flex ${StylesB.publish_card}`}
          >
            <div className={`p-3`}>
              <div className={`${StylesB.publish_card_title} mb-2`}>Publish Later</div>
              <div
                onClick={(e) => {
                  e.preventDefault()
                  setShowPublishLater(true)
                }}
                className={`fs-14px mr-5`}
              >
                Your course content is now saved successfully. Choose this option if you want to save it as a draft. This can be later accessed under
                Dashboard/Courses/Drafts.
              </div>
            </div>
            <div className={`align-self-center`}>
              <div className="mr-4 text-color-1">
                <FontAwesomeIcon
                  className="pointer"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setShowPublishLater(true)
                  }}
                  aria-controls="publish-later-box"
                  style={{ height: "24px", width: "24px" }}
                  icon={faChevronCircleRight}
                />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }

  async function publishData() {
    let value = publishTo !== "" ? `&publishTo=${publishTo}` : ""
    var formData = new FormData()
    formData.append("course", location.id)
    if (value !== "") {
      formData.append("publishTo", publishTo)
    }
    if (createTemplate !== "") {
      formData.append("createTemplate", createTemplate)
    }
    if (updateTemplates === "all") {
      formData.append("updateTemplates", updateTemplates)
      formData.append("updateData", "true")
    } else if (updateTemplatesList.length > 0) {
      formData.append("updateTemplatesList", JSON.stringify(updateTemplatesList))
      formData.append("updateData", "true")
    }
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/publish-and-save-template/`, formData, { headers: login_credentials })
      .then((res) => {})
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function addOrRemove(template) {
    let index = updateTemplatesList.indexOf(template.id)
    if (index > -1) {
      updateTemplatesList.splice(index, 1)
    } else {
      updateTemplatesList.push(template.id)
    }
    setAllTemplatesFetched(!allTemplatesFetched)
  }
  const delayedQuery = useCallback(
    _.debounce((value) => sendQuery(value), 800),
    []
  )

  function sendQuery(value) {
    fetchTemplates(value)
  }

  function publishCourse() {
    return (
      <Col xs="12" sm="12" md="12" lg="12" className="px-0">
        <Col lg="12" md="12" sm="12" xs="12" className="p-3" style={{ backgroundColor: "#212C42", borderRadius: "9px", boxShadow: "0px 3px 12px #00000099" }}>
          <div className={`d-flex flex-column`}>
            <div className="fs-18px">
              Your course has been saved to <span className={`font-weight-bold underline`}>drafts.</span>
            </div>

            {showPublishNow ? (
              <React.Fragment>
                {/* except elearning Course */}
                {(courseTypeA == "0" || courseTypeA == "3") && (
                  <div
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setShowPublishNow(true)
                    }}
                    className={`mt-2 ${StylesB.publish_card}`}
                  >
                    <div className={`px-3 d-flex justify-content-between pt-3 pb-1 text-color-1`}>
                      <div className={`${StylesB.publish_card_title}`}>Publish Now</div>
                      <div className={`mr-3`}>
                        <FontAwesomeIcon
                          className="pointer"
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setShowPublishNow(false)
                          }}
                          aria-controls="publish-later-box"
                          style={{ height: "24px", width: "24px" }}
                          icon={faChevronCircleUp}
                        />
                      </div>
                    </div>
                    <div className={`d-flex px-4 py-3 text-color-1`}>
                      <div style={{ width: "2px", backgroundColor: "#586886" }}></div>
                      <div className={`d-flex px-4 flex-column w-100`}>
                        <div className={`d-flex mb-3 flex-column`} style={{ position: "relative" }}>
                          <div className={`${StylesB.circle}`} />
                          <div className={`fs-18px mb-1 font-weight-bolder`} style={{ color: "#E0E2E5" }}>
                            How do you want to Save & Publish
                          </div>
                          <div className={`${!isPermitted && "disable-content"}`}>
                            <p className={`fs-14px mb-1 ${course.all_trainings === 0 ? `d-none` : ``}`}>Classes</p>
                            <div
                              className={`${course.all_trainings === 0 || course.all_trainings === course.future_trainings ? `d-none` : `d-flex`} ${
                                course.future_trainings === 0 ? `` : `float-left`
                              } mb-1 br-9px p-2 mr-2 inner-element-box-shadow pointer border_on_hover`}
                              onClick={(e) => {
                                e.preventDefault()
                                setPublishTo(publishTo === "all" ? "" : "all")
                              }}
                              style={{ backgroundColor: "#586886", color: "#E0E2E5", border: "1px solid transparent", width: `max-content` }}
                            >
                              {publishTo === "all" ? (
                                <div
                                  className={`text-center align-items-center justify-content-center my-1`}
                                  style={{ backgroundColor: "#586886", borderRadius: "50%", width: "30px", height: "30px" }}
                                >
                                  <img className={`mb-0`} src={PublishTick} />
                                </div>
                              ) : (
                                <div
                                  className={`text-center align-items-center justify-content-center my-1 d-inline-flex`}
                                  style={{ backgroundColor: "#586886", borderRadius: "50%", width: "30px", height: "30px" }}
                                >
                                  <div style={{ border: `1px solid #E0E2E5`, height: `21px`, width: `21px`, borderRadius: `50%` }}></div>
                                </div>
                              )}
                              <div className={`mx-2 align-self-center fs-14px `}>Publish to all classes</div>
                            </div>
                            <div
                              className={`${
                                course.future_trainings === 0 ? `d-none` : `d-flex`
                              } mb-1 br-9px p-2 mr-2 inner-element-box-shadow pointer border_on_hover`}
                              onClick={(e) => {
                                e.preventDefault()
                                setPublishTo(publishTo === "future" ? "" : "future")
                              }}
                              style={{ backgroundColor: "#586886", color: "#E0E2E5", border: "1px solid transparent", width: `max-content` }}
                            >
                              {publishTo === "future" ? (
                                <div
                                  className={`text-center align-items-center justify-content-center my-1`}
                                  style={{ backgroundColor: "#586886", borderRadius: "50%", width: "30px", height: "30px" }}
                                >
                                  <img className={`mb-0`} src={PublishTick} />
                                </div>
                              ) : (
                                <div
                                  className={`text-center align-items-center justify-content-center my-1 d-inline-flex`}
                                  style={{ backgroundColor: "#586886", borderRadius: "50%", width: "30px", height: "30px" }}
                                >
                                  <div style={{ border: `1px solid #E0E2E5`, height: `21px`, width: `21px`, borderRadius: `50%` }}></div>
                                </div>
                              )}
                              <div className="mx-2 align-self-center fs-14px">Publish to all future classes</div>
                            </div>
                            <p className={`fs-14px mb-1 mt-2 pt-1`}>Templates</p>
                            <div
                              className={`${
                                allTemplatesCount > 0 ? `d-flex` : `d-none`
                              } float-left br-9px p-2 mr-2 inner-element-box-shadow pointer border_on_hover`}
                              onClick={(e) => {
                                e.preventDefault()
                                setUpdateTemplates(updateTemplates === "all" ? "" : "all")
                              }}
                              style={{ backgroundColor: "#586886", color: "#E0E2E5", border: "1px solid transparent", width: `max-content` }}
                            >
                              {updateTemplates === "all" ? (
                                <div
                                  className={`text-center align-items-center justify-content-center my-1`}
                                  style={{ backgroundColor: "#586886", borderRadius: "50%", width: "30px", height: "30px" }}
                                >
                                  <img className={`mb-0`} src={PublishTick} />
                                </div>
                              ) : (
                                <div
                                  className={`text-center align-items-center justify-content-center my-1 d-inline-flex`}
                                  style={{ backgroundColor: "#586886", borderRadius: "50%", width: "30px", height: "30px" }}
                                >
                                  <div style={{ border: `1px solid #E0E2E5`, height: `21px`, width: `21px`, borderRadius: `50%` }}></div>
                                </div>
                              )}
                              <div className="mx-2 align-self-center fs-14px">Update all templates</div>
                            </div>
                            <div
                              className={`${allTemplatesCount > 0 ? `d-flex` : `d-none`} float-left mr-1`}
                              onClick={(e) => {
                                e.preventDefault()
                                setUpdateTemplates("")
                              }}
                            >
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-create-template" className={`p-0 border-0 bg-transparent d-flex caret-none`}>
                                  <div
                                    className="d-flex br-9px p-2 inner-element-box-shadow pointer border_on_hover"
                                    style={{
                                      backgroundColor: "#586886",
                                      color: "#E0E2E5",
                                      border: "1px solid transparent",
                                      width: `max-content`,
                                      maxWidth: `300px`,
                                    }}
                                  >
                                    {updateTemplates === "" && updateTemplatesList.length > 0 ? (
                                      <React.Fragment>
                                        <div
                                          className={`text-center align-items-center justify-content-center my-1`}
                                          style={{ backgroundColor: "#586886", borderRadius: "50%", width: "30px", height: "30px" }}
                                        >
                                          <img className={`mb-0`} src={PublishTick} />
                                        </div>
                                        <div className="mx-2 align-self-center fs-14px">{updateTemplatesList.length} templates selected</div>
                                      </React.Fragment>
                                    ) : (
                                      <div className="mx-2 align-self-center fs-14px">Select templates to Update</div>
                                    )}
                                    <div className={`d-inline-flex my-1`} style={{ width: "30px", height: "30px" }}>
                                      <FontAwesomeIcon
                                        className="pointer m-auto"
                                        aria-controls="update-templates"
                                        style={{ height: "22px", width: "22px", color: `#FFF7F7` }}
                                        icon={faChevronDown}
                                      />
                                    </div>
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className={`p-2`}
                                  style={{
                                    minWidth: `300px`,
                                    backgroundColor: `#303C54`,
                                    boxShadow: `0px 3px 6px #00000029`,
                                    border: `1px solid #FFFFFF72`,
                                    borderRadius: `9px`,
                                  }}
                                >
                                  <p className={`fs-12px mb-2 float-left`} style={{ color: `#E0E2E5` }}>
                                    Select Templates
                                  </p>
                                  <p
                                    className={`fs-12px mb-2 float-right pointer`}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      setUpdateTemplates("all")
                                      document.body.click()
                                    }}
                                    style={{ color: `#E0E2E5`, textDecoration: `underline` }}
                                  >
                                    Apply to all
                                  </p>
                                  <Form.Control
                                    autoComplete="off"
                                    type="text"
                                    name="createTemplate"
                                    autoFocus
                                    value={searchTemplate}
                                    onChange={(e) => {
                                      e.preventDefault()
                                      setSearchTemplate(e.target.value)
                                      delayedQuery(e.target.value)
                                    }}
                                    placeholder={"Search Templates"}
                                    className={`${Styles.font_12} px-2 w-100 shadow-none text-white`}
                                    style={{ backgroundColor: `#4A5771` }}
                                  />
                                  <div style={{ maxHeight: `200px`, overflow: `auto` }}>
                                    {allTemplates.map((template, index) => (
                                      <div
                                        key={`template-${template.id}-${index}`}
                                        className={`text-white px-2 mt-2 d-inline-flex w-100 align-items-center fs-12px elipsis-1 pointer`}
                                        style={{ backgroundColor: `#4A5771`, borderRadius: `9px` }}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          allTemplates[index].selected = template.selected !== undefined ? !template.selected : true
                                          addOrRemove(template)
                                        }}
                                      >
                                        {updateTemplatesList.indexOf(template.id) > -1 ? (
                                          <div
                                            className={`text-center align-items-center justify-content-center my-1 d-inline-flex`}
                                            style={{ borderRadius: "50%", width: "30px", height: "30px" }}
                                          >
                                            <img className={`mb-0`} src={PublishTick} />
                                          </div>
                                        ) : (
                                          <div
                                            className={`text-center align-items-center justify-content-center my-1 d-inline-flex`}
                                            style={{ borderRadius: "50%", width: "30px", height: "30px" }}
                                          >
                                            <div style={{ border: `1px solid #E0E2E5`, height: `21px`, width: `21px`, borderRadius: `50%` }}></div>
                                          </div>
                                        )}
                                        <span className={`ml-2 w-100`}>{template.name}</span>
                                        <div
                                          className={`text-center align-items-center justify-content-center my-1 d-inline-flex`}
                                          onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                          }}
                                          style={{ borderRadius: "50%", width: "30px", height: "30px" }}
                                        >
                                          <a
                                            href={`/app/companies/${location.company_id}/templates/template-content/${course.id}/${template.id}`}
                                            target="_blank"
                                          >
                                            <FontAwesomeIcon
                                              className="pointer m-auto"
                                              aria-controls="update-templates"
                                              style={{ color: `#FFF7F7`, width: "18px", height: "18px" }}
                                              icon={faInfoCircle}
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault()
                                      fetchNextTemplates(allTemplatesNext)
                                    }}
                                    className={`border_on_hover text-color-1 fs-12px py-0 px-2 mt-2 ${allTemplatesNext === null ? `d-none` : ``}`}
                                    style={{
                                      backgroundColor: "#586886",
                                      border: "1px solid #586886",
                                      boxShadow: "0px 3px 6px #00000029",
                                      borderRadius: "23px",
                                      color: `#E0E2E5`,
                                    }}
                                  >
                                    See More
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault()
                                      document.body.click()
                                    }}
                                    className={`border_on_hover text-color-1 fs-12px py-0 px-2 mt-2 ${allTemplatesNext === null ? `` : `float-right`}`}
                                    style={{
                                      backgroundColor: "#586886",
                                      border: "1px solid #586886",
                                      boxShadow: "0px 3px 6px #00000029",
                                      borderRadius: "23px",
                                      color: `#E0E2E5`,
                                    }}
                                  >
                                    Done
                                  </button>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className={`d-flex ${!isPermitted && "disable-content"}`}>
                              <Dropdown>
                                <Dropdown.Toggle
                                  disabled={!isPermitted}
                                  id="dropdown-create-template"
                                  className={`p-0 border-0 bg-transparent d-flex caret-none`}
                                >
                                  <div
                                    className="d-flex br-9px p-2 inner-element-box-shadow pointer border_on_hover"
                                    style={{
                                      backgroundColor: "#586886",
                                      color: "#E0E2E5",
                                      border: "1px solid transparent",
                                      width: `max-content`,
                                      maxWidth: `300px`,
                                    }}
                                  >
                                    {createTemplate !== "" ? (
                                      <React.Fragment>
                                        <div
                                          className={`text-center align-items-center justify-content-center my-1`}
                                          style={{ backgroundColor: "#586886", borderRadius: "50%", width: "30px", height: "30px" }}
                                        >
                                          <img className={`mb-0`} src={PublishTick} />
                                        </div>
                                        <div className="mx-2 align-self-center fs-14px">
                                          <span style={{ color: `#aab1bf` }}>Save as a new template</span>
                                          <br />
                                          <span className={`text-white`}>
                                            {createTemplate.slice(0, 29)}
                                            {createTemplate.length > 29 ? `...` : ``}
                                          </span>
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      <div className="mx-2 align-self-center fs-14px" style={{ textDecoration: `underline` }}>
                                        Save as a new template
                                      </div>
                                    )}
                                    <div
                                      className={`text-center align-items-center justify-content-center my-1`}
                                      style={{ backgroundColor: "#586886", borderRadius: "50%", width: "30px", height: "30px" }}
                                    >
                                      <img className={`mb-0`} src={PublishTemplateIcon} />
                                    </div>
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className={`p-2`}
                                  style={{
                                    minWidth: `300px`,
                                    backgroundColor: `#303C54`,
                                    boxShadow: `0px 3px 6px #00000029`,
                                    border: `1px solid #FFFFFF72`,
                                    borderRadius: `9px`,
                                  }}
                                >
                                  <Form.Control
                                    autoComplete="off"
                                    type="text"
                                    name="createTemplate"
                                    autoFocus
                                    value={createTemplate}
                                    onChange={(e) => {
                                      e.preventDefault()
                                      setCreateTemplate(e.target.value)
                                    }}
                                    placeholder={"Name of the template"}
                                    className={`${Styles.font_12} px-2 w-100 shadow-none`}
                                    style={{ backgroundColor: `#4A5771`, borderRadius: `3px`, color: `#ffffff60` }}
                                  />
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div className={`d-flex mb-3 flex-column`} style={{ position: "relative" }}>
                          <div className={`${StylesB.circle}`} />
                          <div className={`fs-18px mb-1 font-weight-bolder`}>Publish Course</div>
                          <div>
                            <button
                              onClick={(e) => {
                                e.preventDefault()
                                setIsSuccess(true)
                                publishData()
                              }}
                              className={`btn ${StylesB.green_btn} ${!isPermitted && "disable-content"}`}
                              disabled={!isPermitted}
                            >
                              I want to publish now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* E learning */}
                {courseTypeA == "1" && (
                  <div className={`mt-2 ${StylesB.publish_card}`}>
                    <div className={`px-3 d-flex justify-content-between pt-3 pb-1 text-color-1`}>
                      <div className={`${StylesB.publish_card_title}`}>Publish Now</div>
                      <div className={`mr-3`}>
                        <FontAwesomeIcon
                          className="pointer"
                          onClick={(e) => {
                            e.preventDefault()
                            setShowPublishNow(false)
                          }}
                          aria-controls="publish-later-box"
                          style={{ height: "24px", width: "24px" }}
                          icon={faChevronCircleUp}
                        />
                      </div>
                    </div>
                    <div className={`d-flex text-center mx-5 px-4 my-4 flex-column text-color-1`}>
                      <div className={`font-weight-bold fs-18px mb-3`} style={{ opacity: "0.56" }}>
                        Before you Publish
                      </div>
                      <div className={`fs-14px`}>
                        The changes you made to your eLearning course contents have been successfully saved in your drafts.
                        {/*You can see the changes you made by viewing the preview of the student's page.*/}
                      </div>
                      <div className={`mt-3 mb-4 fs-14px`}>
                        If you want to publish your updated course now, then click on the publish now button. Please note that if you click on the 'I want to
                        publish now', your course will be automatically published and the new changes will be made live to your students.
                      </div>
                      <div>
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            setIsSuccess(true)
                            publishData()
                          }}
                          className={`btn ${StylesB.green_btn}`}
                        >
                          I want to publish now
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setShowPublishNow(true)
                }}
                className={`mt-2 text-color-1 justify-content-between pointer d-flex ${StylesB.publish_card}`}
              >
                <div className={`p-3`}>
                  <div className={`${StylesB.publish_card_title} mb-2`}>Publish Now</div>
                  <div
                    onClick={(e) => {
                      e.preventDefault()
                      setShowPublishNow(true)
                    }}
                    className={`fs-14px mr-5`}
                  >
                    Your course is now ready to be published. Choose this option to make the course online.
                  </div>
                </div>
                <div className={`align-self-center`}>
                  <div className="mr-4 text-color-1">
                    <FontAwesomeIcon
                      className="pointer"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setShowPublishNow(true)
                      }}
                      aria-controls="publish-later-box"
                      style={{ height: "24px", width: "24px" }}
                      icon={faChevronCircleRight}
                    />
                  </div>
                </div>
              </div>
            )}
            {publishLaterBox()}
          </div>
        </Col>
      </Col>
    )
  }

  function congrats() {
    return (
      <Col xs="12" sm="12" md="12" lg="12" className="px-0">
        <Col lg="12" md="12" sm="12" xs="12" className="p-3" style={{ backgroundColor: "#212C42", borderRadius: "9px", boxShadow: "0px 3px 12px #00000099" }}>
          <div className={`m-5 p-3 d-flex flex-column text-color-1`}>
            <div>
              <img className={`mb-0`} src={GreenTick} />
            </div>
            <div className={`fs-18px font-weight-bolder mb-3 mt-1`}>Congrats!</div>
            <div className={`fs-16px bold-500`}>Your Course: {course.name} has been published successfully</div>
            <div className={`fs-16px`}>
              You can find all your course here:{" "}
              <span className={`underline bold-500`}>
                <Link to="/app/dashboard" style={{ color: "#E0E2E5" }}>
                  Home/Courses/published
                </Link>
              </span>
            </div>
            <div className={`mt-4`}>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/app/dashboard")
                }}
                className={`border_on_hover text-color-1 fs-16px ${!isPermitted && "disable-content"}`}
                style={{
                  backgroundColor: "#586886",
                  border: "1px solid #586886",
                  height: "32px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "23px",
                }}
                disabled={!isPermitted}
              >
                I am done
              </button>
            </div>
          </div>
        </Col>
      </Col>
    )
  }

  function findScrollableOrNot() {
    let ele = document.getElementById("sections")

    if (ele !== null && ele !== undefined) {
      if (ele.scrollWidth > ele.clientWidth) {
        return true
      } else {
        return false
      }
    }
  }

  function scrollRight() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft += 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function scrollLeft() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft -= 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function calculateWidth() {
    let ele = document.getElementById("coursename")
    if (ele !== null) {
      let data = 1100 - parseInt(ele.offsetWidth) - 50
      return { width: data, overflowX: "scroll", flex: "auto" }
    }
  }

  return (
    <div>
      <SEO title="Course Creation" />
      <DragDropContext>
        <Col xs="12" sm="12" md="12" lg="12" className={`pt-0 d-flex`} style={{ alignItems: "center", flexDirection: "column" }}>
          <Col
            xs="12"
            sm="12"
            md="10"
            lg="10"
            onMouseOver={(e) => {
              e.stopPropagation()
              e.preventDefault()
              let ele = document.getElementById("sections")
              if (ele !== null && ele !== undefined) {
                if (ele.scrollWidth > ele.clientWidth) {
                  setHovered(true)
                }
              }
            }}
            className={`${StylesB.course_creation_header} d-flex px-0`}
          >
            <p
              className="mb-0 py-2 px-3 float-left font-weight-bold"
              id="coursename"
              style={{ borderRadius: "9px 0px 0px 9px", lineHeight: "1", minWidth: "max-content" }}
              title={course.name}
            >
              {courseName}
            </p>
            {findScrollableOrNot() && hovered ? (
              <FontAwesomeIcon
                onClick={() => {
                  scrollLeft()
                }}
                className="pointer"
                icon={faChevronLeft}
                size="lg"
                style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551` }}
              />
            ) : (
              <FontAwesomeIcon
                onClick={() => {
                  scrollLeft()
                }}
                className="pointer"
                icon={faChevronLeft}
                size="lg"
                style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551`, visibility: "hidden" }}
              />
            )}
            <div id="sections" className="d-flex align-items-center hide_scrollbar" style={calculateWidth()}>
              <Link
                className={`pointer ${StylesB.backgroundonhover}`}
                style={{ color: "#E0E2E5" }}
                to={`/app/companies/${course.company}/courses/edit/${course.id}`}
              >
                <p className="mb-0 pointer py-2 px-3 fs-16px" id="course_detail" style={{ color: "#E0E2E5", minWidth: "max-content" }}>
                  Course Details
                </p>
              </Link>
              <Link
                className={`pointer ${StylesB.backgroundonhover}`}
                style={{ color: "#E0E2E5" }}
                to={`/app/companies/${course.company}/courses/course-certification/${course.id}`}
              >
                <p style={{ minWidth: "max-content" }} className="mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2 fs-16px" id="course_certification">
                  Certifications
                </p>
              </Link>
              <Link
                className={`pointer ${StylesB.backgroundonhover}`}
                style={{ color: "#E0E2E5" }}
                to={`/app/companies/${course.company}/courses/course-content/${course.id}`}
              >
                <p className="mb-0 pointer py-2 pl-14px pr-14px fs-16px ml-10px" id="course_detail" style={{ color: "#E0E2E5", minWidth: "max-content" }}>
                  Course Content
                </p>
              </Link>
              <p
                className="mb-0 pointer py-2 pl-14px pr-14px fs-16px ml-0 font-weight-bold"
                id="publish_course"
                style={{ backgroundColor: "#313d54", color: "#E0E2E5", minWidth: "max-content" }}
              >
                Publish Course
              </p>
              {/* <div className='float-right ml-auto'>
              <button className='border_on_hover d-flex' style={{backgroundColor:'#586886',border:'1px solid #586886',padding:'5px 15px 7px 15px',boxShadow:'0px 3px 6px #00000029',borderRadius:'23px',color:'#E0E2E5',fontSize:'14px'}}>
                <p style={{marginBottom:'0px',lineHeight:1,minWidth:'max-content'}} >Preview students page</p> <FontAwesomeIcon className='ml-2' icon={faExternalLinkSquareAlt}/>
              </button>
            </div> */}
            </div>
            {findScrollableOrNot() && hovered ? (
              <FontAwesomeIcon
                onClick={() => {
                  scrollRight()
                }}
                className="pointer"
                icon={faChevronRight}
                size="lg"
                style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551` }}
              />
            ) : (
              <FontAwesomeIcon
                onClick={() => {
                  scrollRight()
                }}
                className="pointer"
                icon={faChevronRight}
                size="lg"
                style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551`, visibility: "hidden" }}
              />
            )}
            <Image
              src={Close}
              alt={`close`}
              width={20}
              height={20}
              onClick={(e) => {
                e.preventDefault()
                navigate("/app/dashboard")
              }}
              className={`pointer mr-3 ml-auto float-right align-self-center`}
            />
          </Col>
          <Col
            xs="12"
            sm="12"
            md="10"
            lg="10"
            onMouseOver={() => {
              setHovered(false)
            }}
            className="px-0 mb-0"
            style={{ maxHeight: "calc(100vh - 125px)", borderRadius: "9px", overflowY: "scroll" }}
          >
            {isSuccess ? congrats() : publishCourse()}
          </Col>
        </Col>
      </DragDropContext>
    </div>
  )
}

export default CoursePublish
