import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { isBrowser } from "../../services/developer_mode"

import Rewards from "../../images/svgs/points.svg"
import ArrowBlue from "../../images/svgs/arrow-blue.svg"
import Close from "../../images/svgs/close.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const CourseIdentityEdit = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [course, setCourse] = useState({})

  console.log(location, course, "location.dilsjdio")
  // const { state = {} } = location

  useEffect(() => {
    fetchCourse()
  }, [])

  async function fetchCourse() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/courses/${location.id}/`, { headers: login_credentials }).then((res) => {
      setCourse(res.data)
    })
  }

  let course_template = course.course_template

  return (
    <div>
      <SEO title="Course Creation" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h5 className={`border-bottom p-2 d-inline-flex w-100 align-items-center`}>
            &nbsp;Edit {course.name} &emsp;
            <img src={Rewards} alt={`Rewards Program`} className={`my-auto`} style={{ objectFit: `contain`, height: `40px` }} />
            <Link className={`pointer ml-auto`} to={`/app/dashboard`} style={{ color: `#C6CFF4` }}>
              <span style={{ fontWeight: "normal" }} className={`small`}>
                Exit Course &nbsp;
                <img src={Close} alt={`Exit Course`} className={`my-auto`} style={{ objectFit: `contain`, height: `23px`, color: `white` }} />
              </span>
            </Link>
          </h5>
          <Form className="form-horizontal">
            <Col>
              <div>
                <Row className="mt-5 ml-5" style={{ height: "60vh", alignItems: "center", justifyContent: "center" }}>
                  <Col xs="4" className={`pr-0`}>
                    <Col xs="12" className={`pr-0`}>
                      <Link to={`/app/companies/${location.company_id}/courses/edit/${location.id}`}>
                        <div class="card border-none course-creation-card">
                          <div class="card-header theme-bg-light text-white border-none">
                            Course Information{" "}
                            <img src={Rewards} alt={`Rewards Program`} className={`my-auto float-right`} style={{ objectFit: `contain`, height: `27px` }} />
                          </div>
                          <Row className="theme-bg-dark w-100 m-0" style={{ alignItems: "center", height: "170px" }}>
                            <Col className="float-left overflow-hidden" xs="4" lg="4" md="4">
                              <h1
                                style={{
                                  color: "#5b6896",
                                  fontSize: "10rem",
                                  fontWeight: 900,
                                  fontFamily: "Helvetica Neue",
                                  lineHeight: 0.77,
                                  margin: 0,
                                  margin: `0px 0px -14px 0px`,
                                }}
                              >
                                1
                              </h1>
                            </Col>
                            <Col className="float-left" xs="8" lg="8" md="8">
                              <ul class="list-group list-group-flush m-0 course-creation-list small">
                                <li class="list-group-item">Course Type</li>
                                <li class="list-group-item">Course Identity & Details</li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </Link>
                    </Col>
                  </Col>
                  <div className={`float-left`}>
                    <img src={ArrowBlue} alt={`Connection`} className={`my-auto`} style={{ objectFit: `contain`, width: `100%` }} />
                  </div>
                  <Col xs="4" className={`pl-0`}>
                    {course_template ? (
                      <Link to={`/app/default-course-templates/edit/${course_template.id}/#type=course,${course.id},${location.company_id}`}>
                        <Col xs="12" className={`pl-0`}>
                          <div class="card border-none">
                            <div class="card-header theme-bg-light text-white border-none">
                              Course Content{" "}
                              <img src={Rewards} alt={`Rewards Program`} className={`my-auto float-right`} style={{ objectFit: `contain`, height: `27px` }} />
                            </div>
                            <Row className="theme-bg-dark w-100 m-0 p-3" style={{ alignItems: "center" }}>
                              <Col className="float-left overflow-hidden" xs="4" lg="4" md="4">
                                <h1
                                  style={{
                                    color: "#737680",
                                    fontSize: "10rem",
                                    fontWeight: 900,
                                    fontFamily: "Helvetica Neue",
                                    lineHeight: 0.77,
                                    margin: 0,
                                    position: "relative",
                                    left: "-14px",
                                  }}
                                >
                                  2
                                </h1>
                              </Col>
                              <Col className="float-left" xs="8" lg="8" md="8">
                                {course_template && course_template.stages
                                  ? course_template.stages.map((node, index) =>
                                      node.resources_exist ? (
                                        <Link
                                          className={`pointer`}
                                          to={`/app/default-course-templates/${course_template.id}/stages/${node.id}/modules/view/#type=course,${course.id},${location.company_id}`}
                                        >
                                          <div
                                            className={`d-flex mt-3 py-2 align-items-center`}
                                            style={{ backgroundColor: `#3F5396`, color: `white`, borderRadius: `9px`, width: `200px` }}
                                          >
                                            <FontAwesomeIcon icon={faCheck} style={{ width: `18px`, height: `18px`, marginLeft: `15px` }} />
                                            &emsp; {node.name}
                                          </div>
                                        </Link>
                                      ) : (
                                        <Link
                                          className={`pointer`}
                                          to={`/app/default-course-templates/${course_template.id}/stages/${node.id}/modules/view/#type=course,${course.id},${location.company_id}`}
                                        >
                                          <div
                                            className={`d-flex mt-3 py-2 align-items-center text-white`}
                                            style={{ backgroundColor: `rgb(115, 118, 128)`, borderRadius: `9px`, width: `200px` }}
                                          >
                                            &emsp; {node.name}
                                          </div>
                                        </Link>
                                      )
                                    )
                                  : ""}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Link>
                    ) : (
                      ""
                    )}
                  </Col>
                  {/*<Col xs="4">
                                      <Col xs="12">
                                        <div class="card">
                                          <div class="card-header">
                                            Course Structure
                                          </div>
                                          <ul class="list-group list-group-flush m-0">
                                            <li class="list-group-item">Timing & Schedule</li>
                                            <li class="list-group-item">Certification Path</li>
                                          </ul>
                                        </div>
                                      </Col>
                                    </Col>*/}

                  {/*<Col xs="3">
                                      <Col xs="12">
                                        <div class="card">
                                          <div class="card-header">
                                            Course Offerings
                                          </div>
                                          <ul class="list-group list-group-flush m-0">
                                            <li class="list-group-item">Pricing</li>
                                            <li class="list-group-item">Career Path</li>
                                          </ul>
                                        </div>
                                      </Col>
                                    </Col>
                                    <Col xs="3" className='mt-5'>
                                      <Col xs="12">
                                        <div class="card">
                                          <div class="card-header">
                                            Engage
                                          </div>
                                          <ul class="list-group list-group-flush m-0">
                                            <li class="list-group-item">Forums</li>
                                            <li class="list-group-item">Discussion</li>
                                          </ul>
                                        </div>
                                      </Col>
                                    </Col>*/}
                </Row>
              </div>
            </Col>
          </Form>
          {/*<Button onClick={()=>navigate('/app/dashboard/')} variant="danger" >Go Back</Button>*/}
        </Col>
      </Row>
    </div>
  )
}

export default CourseIdentityEdit
