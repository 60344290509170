import React, { useEffect, useState } from "react"
import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import FormControl from "react-bootstrap/FormControl"
import Dropdown from "react-bootstrap/Dropdown"
import Spinner from "react-bootstrap/Spinner"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faUserCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { faCircle } from "@fortawesome/free-regular-svg-icons"

import UploadParticipantsModal from "../custom/UploadParticipantsModal"

import SearchIcon from "../../images/svgs/search.svg"
import DeleteResource from "../../images/svgs/delete.svg"
import FavouriteIcon from "../../images/svgs/Bookmarkicon.svg"
import UploadIcon from "../../images/svgs/feather-upload-white.svg"
import EllipsisWhite from "../../images/svgs/ellipsis-white.svg"
import EditIcon from "../../images/svgs/edit.svg"
import WhitePollIcon from "../../images/svgs/polls-white-icon.svg"
import Badge1 from "../../images/svgs/badge1.svg"
import Badge2 from "../../images/svgs/badge2.svg"
import Badge3 from "../../images/svgs/badge3.svg"

import DeleteParticipant from "./_delete-participant"
import Modal from "react-bootstrap/Modal"
import { postRequest } from "../../services/rest_service"
import Notification from "../custom/Notification"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

import Styles from "../../modularscss/styles.module.scss"
import StylesB from "./classroom-resource-section.module.scss"

const ClassParticipantsSection = (props) => {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("participants")

  const [searchValue, setSearchValue] = useState("")
  const [searchData, setSearchData] = useState({})

  const [participantsList, setParticipantsList] = useState(null)
  const [selectedComponent, setSelectedComponent] = useState("participants")
  const [seeMoreVisible, setSeeMoreVisible] = useState(true)
  const [next_participant_url, setNextParticipantUrl] = useState(null)
  const [allParticipantsCount, setAllParticipantsCount] = useState(0)
  const [participantsDataFetched, setParticipantsDataFetched] = useState(false)
  const [activeParticipant, setActiveParticipant] = useState(null)
  const [activeParticipantBadges, setActiveParticipantBadges] = useState([])
  const [badgesDataFetched, setBadgesDataFetched] = useState(false)
  const [badgeFilter, setBadgeFilter] = useState(null)
  const [allBadges, setAllBadges] = useState([])
  const [badgeData, setBadgeData] = useState(null)
  const [notificationData, setNotificationData] = useState(null)

  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false)
  const [showDeleteParticipantModal, setShowDeleteParticipantModal] = useState(false)
  const [participantData, setParticipantData] = useState(null)

  useEffect(() => {
    if (props.training_id && participantsDataFetched === false) {
      fetchParticipants()
      fetchTrainingBadges()
    }
  }, [props.training_id])

  useEffect(() => {
    if (Object.keys(props.trainingWsConnection).length > 0) {
      connectSessionSocket()
    }
  }, [props.trainingWsConnection])

  useEffect(() => {
    if (badgeData) {
      fetchParticipants("form_websocket")
    }
  }, [badgeData])

  function connectSessionSocket() {
    if (authClient.isAuthenticated()) {
      props.trainingWsConnection.onmessage = function (e) {
        let responseData = JSON.parse(JSON.parse(e.data).value)
        if (responseData.event_type === "user_badges") {
          setBadgeData(responseData)
        }
      }
    }
  }

  useEffect(() => {
    fetchParticipants()
  }, [searchValue])

  useEffect(() => {
    if (badgeFilter !== null) {
      fetchParticipants()
    }
  }, [badgeFilter])

  function fetchParticipants(type = null) {
    axios
      .get(
        `${process.env.GATSBY_REACT_APP_API_HOST}/training-participants/${props.training_id}/?badge_data=true&paginate_by=8&search=${searchValue}&badge=${
          badgeFilter ? badgeFilter : ""
        }`,
        { headers: login_credentials }
      )
      .then((res) => {
        setParticipantsList(res.data.results)
        setAllParticipantsCount(res.data.count)
        setParticipantsDataFetched(true)
        if (res.data.next) {
          setSeeMoreVisible(true)
          setNextParticipantUrl(res.data.next)
        } else {
          setSeeMoreVisible(false)
        }
        if (type === "form_websocket") {
          setBadgeData(null)
        }
      })
      .catch((error) => {
        setParticipantsList([])
      })
  }

  async function deleteParticipant(student_id) {
    const { data, status, success } = await postRequest(`/trainings/${props.training_id}/students/${student_id}/remove/`)
    if (success) {
      var updatedParticipantsList = [...participantsList]
      var index = updatedParticipantsList.findIndex((student) => student.owner__id === student_id)
      updatedParticipantsList.splice(index, 1)
      setParticipantsList(updatedParticipantsList)
      setNotificationData({ type: "success", title: "Participant deleted successfully" })
    }
  }

  function fetchNextParticipants() {
    axios.get(next_participant_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_pax_data = participantsList
      if (res.data.results.length > 0) {
        for (var md = 0; md <= res.data.results.length - 1; md++) {
          next_pax_data.push(res.data.results[md])
        }
        setParticipantsList(next_pax_data)
      }
      setNextParticipantUrl(res.data.next)
      if (res.data.next == null) {
        setSeeMoreVisible(false)
      }
    })
  }

  useEffect(() => {
    if (activeParticipant) {
      setBadgesDataFetched(false)
      fetchStudentGivenBadges()
    }
  }, [activeParticipant])

  // async function fetchParticipantBadges() {
  //   axios.get(`${process.env.GATSBY_REACT_APP_API_HOST}/badges/user_badges/user/${selected_participant_id}/training/${training_id}/`, {headers: login_credentials})
  //   .then(res => {
  //     setCurrentStudentBadges(res.data)
  //   }).catch(error => {})
  // }

  async function fetchTrainingBadges() {
    axios
      .get(`${process.env.GATSBY_REACT_APP_API_HOST}/badges/`, {
        headers: login_credentials,
      })
      .then((res) => {
        setAllBadges(res.data.results)
      })
      .catch((error) => {})
  }

  async function fetchStudentGivenBadges() {
    axios
      .get(`${process.env.GATSBY_REACT_APP_API_HOST}/badges/user_badges_given/user/${activeParticipant.owner__id}/training/${props.training_id}/`, {
        headers: login_credentials,
      })
      .then((res) => {
        setActiveParticipantBadges(res.data)
        setBadgesDataFetched(true)
      })
      .catch((error) => {})
  }

  async function givebadgeToStudent(badge, badge_id, bagde_indx, p_index) {
    let formData = new FormData()
    formData.append("earner", activeParticipant.owner__id)
    formData.append("training", props.training_id)
    axios
      .post(`${process.env.GATSBY_REACT_APP_API_HOST}/badges/${badge_id}/toggle_badge_assignment/`, formData, { headers: login_credentials })
      .then((res) => {
        let current_badge_status = [...activeParticipantBadges]
        current_badge_status[bagde_indx].assigned = !current_badge_status[bagde_indx].assigned
        setActiveParticipantBadges(current_badge_status)
        let current_student_badges = [...participantsList]
        let selected_badges = current_student_badges[p_index].badges.filter((current_badge) => current_badge.id === badge_id)
        if (selected_badges.length > 0) {
          if (badge.assigned) {
            selected_badges[0].count += 1
          } else {
            selected_badges[0].count -= 1
          }
        } else {
          badge["count"] = 1
          current_student_badges[p_index].badges.push(badge)
        }
        setParticipantsList(current_student_badges)
      })
      .catch((error) => {})
  }

  function showAddContentButton() {
    let button_text = "Upload Participants"
    return (
      <div>
        <Button
          variant="none"
          className={`align-items-center d-flex text-white border_on_hover`}
          onClick={() => {
            setShowAddStudentsModal(true)
          }}
          style={{
            backgroundColor: "#475269",
            boxShadow: "0px 3px 6px #00000029",
            color: "#FFFFFF",
            minWidth: "320px",
            height: "50px",
            borderRadius: "0px",
            justifyContent: "center",
          }}
        >
          <img src={UploadIcon} alt={`upload button`} className={`my-auto`} style={{ objectFit: `contain`, width: `24px`, height: `24px` }} />
          <p className="mb-0" style={{ fontSize: "14px", color: "#E0E2E5" }}>
            {button_text}
          </p>
        </Button>
      </div>
    )
  }

  function getCountOfBadges(badges) {
    let count = 0
    for (let i = 0; i < badges?.length; i++) {
      count = count + badges[i].count
    }
    return count - 3
  }

  function getParticipants() {
    return (
      <div>
        <Dropdown className={`breakout-groups pr-3 pb-2`}>
          <Dropdown.Toggle
            className="border-none d-inline-flex align-items-center p-0 shadow-none"
            style={{ backgroundColor: "transparent" }}
            id="dropdown-basic"
          >
            <div className={`mr-1`} style={{ fontSize: "14px", fontWeight: "500" }}>
              {badgeFilter ? badgeFilter : "Filter By Badge"}
            </div>
            <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
          </Dropdown.Toggle>
          <Dropdown.Menu className={`br-9px breakout-groups`} style={{ fontSize: "12px", color: "#E0E2E5", maxWidth: "200px" }}>
            <Dropdown.Item
              className={`px-2 w-100 d-inline-flex align-items-center`}
              style={{ color: "#E0E2E5" }}
              onClick={() => {
                setBadgeFilter("")
              }}
            >
              <p className={`mb-0 py-2`} style={{ fontSize: "14px", fontWeight: "500" }}>
                All badges
              </p>
            </Dropdown.Item>
            {allBadges && allBadges.length > 0 ? (
              allBadges.map((badge, ind) => {
                return (
                  <Dropdown.Item
                    className={`px-2 w-100 d-inline-flex align-items-center`}
                    style={{ color: "#E0E2E5" }}
                    onClick={() => {
                      setBadgeFilter(badge.name)
                    }}
                  >
                    <p className={`mb-0`} style={{ fontSize: "14px", fontWeight: "500" }}>
                      {badge.name}
                    </p>
                    <img
                      src={badge.icon}
                      alt={`badge`}
                      className={`ml-auto mb-0 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `35px`,
                        height: `35px`,
                        position: "relative",
                        left: `0px`,
                      }}
                    />
                  </Dropdown.Item>
                )
              })
            ) : (
              <p className={`mb-0 py-2 w-100 text-center`} style={{ fontSize: "14px" }}>
                No badges created yet!
              </p>
            )}
          </Dropdown.Menu>
        </Dropdown>
        {participantsList.length > 0 ? (
          participantsList.map((value, p_indx) => {
            return (
              <div
                className="d-inline-flex px-2 py-2 mb-2 align-items-center w-100"
                style={{ justifyContent: "space-between", backgroundColor: "#303C54", borderRadius: "9px" }}
              >
                <div className="d-inline-flex align-items-center w-100">
                  <FontAwesomeIcon className="mr-2" icon={faUserCircle} style={{ width: `30px`, height: `30px` }} />
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip placement="top" className="classroom-tooltip" id={`"tooltip-${value.id}"`}>
                        {value.owner__first_name}
                      </Tooltip>
                    }
                  >
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "14px",
                        color: "#E0E2E5",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        Width: "85px",
                      }}
                    >
                      {value.owner__first_name}
                    </p>
                  </OverlayTrigger>
                  <div className={`ml-auto d-inline-flex align-items-center`}>
                    <Dropdown
                      onClick={(e) => {
                        e.preventDefault()
                        setActiveParticipant(value)
                      }}
                      className={`breakout-groups`}
                    >
                      <Dropdown.Toggle
                        size="lg"
                        className={`border-0 px-1 py-1 d-inline-flex align-items-center `}
                        id="dropdown_list"
                        style={{
                          fontSize: "14px",
                          background:
                            value?.badges?.length > 0 && getCountOfBadges(value.badges) + 3 > 0
                              ? "transparent"
                              : "linear-gradient(90deg, #7F4F6D 0%, #608FD9 100%) 0% 0%",
                          borderRadius: "4px",
                        }}
                      >
                        {value?.badges?.length > 0 && getCountOfBadges(value.badges) + 3 > 0 ? (
                          <div className={`d-inline-flex w-100 align-items-center`}>
                            <div
                              className={`d-inline-flex align-items-center position-relative ml-2`}
                              style={{
                                width: "110px",
                                flexFlow: "row-reverse",
                              }}
                            >
                              {value.badges.map((p_badge, indx) => {
                                if (indx <= 2 && p_badge.count > 0) {
                                  return (
                                    <img
                                      src={p_badge.icon}
                                      alt={`badges`}
                                      className={`my-auto pointer ${StylesB.shadow_img}`}
                                      style={{
                                        objectFit: `contain`,
                                        width: `40px`,
                                        height: `40px`,
                                        position: indx <= 0 ? "relative" : "absolute",
                                        right: `${indx * 20}px`,
                                      }}
                                    />
                                  )
                                }
                              })}
                            </div>
                            {getCountOfBadges(value.badges) > 0 ? (
                              <p className={`mb-0 ml-auto pl-1`} style={{ fontSize: "14px", color: "#E0E2E570" }}>
                                +{getCountOfBadges(value.badges)}
                              </p>
                            ) : null}
                          </div>
                        ) : (
                          <div className={`pr-2`} style={{ fontSize: "12px", width: "fit-content" }}>
                            Give a Badge
                          </div>
                        )}
                        <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className={`bg py-0 ${Styles.font_12}`}
                        style={{
                          background: "linear-gradient(90deg, #7F4F6D 0%, #608FD9 100%) 0% 0%",
                        }}
                      >
                        {badgesDataFetched ? (
                          activeParticipantBadges.length > 0 ? (
                            activeParticipantBadges.map((badge, b_index) => {
                              return (
                                <Dropdown.Item
                                  className="px-2 py-1 d-inline-flex align-items-center"
                                  style={{
                                    borderTop: "1px solid rgb(224, 226, 229)",
                                    color: "rgb(224, 226, 229)",
                                    borderRadius: "0px 0px 8px 8px",
                                    background: "linear-gradient(90deg, #7F4F6D 0%, #608FD9 100%) 0% 0%",
                                  }}
                                >
                                  <div
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      givebadgeToStudent(badge, badge.id, b_index, p_indx)
                                    }}
                                    className={`d-inline-flex w-100 align-items-center`}
                                  >
                                    <img
                                      src={badge.icon}
                                      alt={`badge`}
                                      className={`my-auto mr-2 pointer`}
                                      style={{
                                        objectFit: `contain`,
                                        width: `35px`,
                                        height: `35px`,
                                        position: "relative",
                                        left: `0px`,
                                      }}
                                    />
                                    {badge.name}
                                    <FontAwesomeIcon
                                      className={`mb-0 ml-auto`}
                                      style={{ fontSize: "15px" }}
                                      icon={badge.assigned ? faCheckCircle : faCircle}
                                      color="#FFFFFF45"
                                    />
                                  </div>
                                </Dropdown.Item>
                              )
                            })
                          ) : (
                            <p className={`mb-0 py-2 w-100 text-center`} style={{ fontSize: "14px" }}>
                              No badges created yet!
                            </p>
                          )
                        ) : (
                          <p className={`mb-0 py-2 w-100 text-center`} style={{ fontSize: "14px" }}>
                            loading..
                          </p>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown style={{ lineHeight: 1 }} className={``}>
                      <Dropdown.Toggle as="div" variant="none" id={`resource-option-menu-${value.id}`} className={`shadow-none p-0 ${Styles.no_caret}`}>
                        <img
                          src={EllipsisWhite}
                          alt={`ellipsis_icon_${value.id.toString()}`}
                          className={`my-auto pointer`}
                          style={{
                            objectFit: `contain`,
                            width: `20px`,
                            height: `16px`,
                            marginLeft: "3px",
                          }}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className={`${StylesB.resource_option_menu}`}>
                        {/* <div className={`d-flex pointer ${StylesB.resource_option_hover}`} style={{borderTop: '1px solid #929bbd'}}>
                          <img src={EditIcon} alt={`resource_result_${value.id.toString()}`} className={`my-auto mr-1`} style={{ objectFit: `contain`, width: `13px`, height: `13px` }}/>
                          <p>Edit</p>
                        </div> */}
                        <div
                          className={`d-flex pointer ${StylesB.resource_option_hover}`}
                          onClick={() => {
                            props.updateParticipantView(true, value)
                          }}
                          style={{ borderTop: "1px solid #929bbd" }}
                        >
                          <img
                            src={WhitePollIcon}
                            alt={`resource_result_${value.id.toString()}`}
                            className={`my-auto mr-1`}
                            style={{
                              objectFit: `contain`,
                              width: `13px`,
                              height: `13px`,
                            }}
                          />
                          <p>View Details </p>
                        </div>
                        <div
                          className={`d-flex pointer ${StylesB.resource_option_hover}`}
                          style={{ borderTop: "1px solid #929bbd" }}
                          onClick={() => {
                            setShowDeleteParticipantModal(true)
                            setParticipantData(value)
                          }}
                        >
                          <img
                            src={DeleteResource}
                            alt={`resource_result_${value.id.toString()}`}
                            className={`my-auto mr-1`}
                            style={{ objectFit: `contain`, width: `13px`, height: `13px` }}
                          />
                          <p>Delete</p>
                        </div>
                        {/* <div className={`d-flex pointer ${StylesB.resource_option_hover}`} style={{borderTop: '1px solid #929bbd'}}>
                          <img src={FavouriteIcon} alt={`resource_result_${value.id.toString()}`} className={`my-auto mr-1`} style={{ objectFit: `contain`, width: `13px`, height: `13px` }}/>
                          <p>Favorite</p>
                        </div> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <p className={`w-100 text-center`} style={{ fontSize: "16px" }}>
            No participants found
          </p>
        )}
      </div>
    )
  }

  function getParticipantsLeadboard() {
    return (
      <div>
        <Dropdown className={`breakout-groups pr-3 pb-2`}>
          <Dropdown.Toggle
            className="border-none d-inline-flex align-items-center p-0 shadow-none"
            style={{ backgroundColor: "transparent" }}
            id="dropdown-basic"
          >
            <div className={`mr-1`} style={{ fontSize: "14px", fontWeight: "500" }}>
              {badgeFilter ? badgeFilter : "Filter By Badge"}
            </div>
            <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
          </Dropdown.Toggle>
          <Dropdown.Menu className={`br-9px breakout-groups`} style={{ fontSize: "12px", color: "#E0E2E5", maxWidth: "200px" }}>
            <Dropdown.Item
              className={`px-2 w-100 d-inline-flex align-items-center`}
              style={{ color: "#E0E2E5" }}
              onClick={() => {
                setBadgeFilter("")
              }}
            >
              <p className={`mb-0 py-2`} style={{ fontSize: "14px", fontWeight: "500" }}>
                All badges
              </p>
            </Dropdown.Item>
            {allBadges && allBadges.length > 0 ? (
              allBadges.map((badge, ind) => {
                return (
                  <Dropdown.Item
                    className={`px-2 w-100 d-inline-flex align-items-center`}
                    style={{ color: "#E0E2E5" }}
                    onClick={() => {
                      setBadgeFilter(badge.name)
                    }}
                  >
                    <p className={`mb-0`} style={{ fontSize: "14px", fontWeight: "500" }}>
                      {badge.name}
                    </p>
                    <img
                      src={badge.icon}
                      alt={`badge`}
                      className={`ml-auto mb-0 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `35px`,
                        height: `35px`,
                        position: "relative",
                        left: `0px`,
                      }}
                    />
                  </Dropdown.Item>
                )
              })
            ) : (
              <p className={`mb-0 py-2 w-100 text-center`} style={{ fontSize: "14px" }}>
                No badges created yet!
              </p>
            )}
          </Dropdown.Menu>
        </Dropdown>
        {participantsList.length > 0 ? (
          participantsList.map((value, p_indx) => {
            return (
              <div
                className="d-inline-flex px-2 py-2 mb-2 align-items-center w-100"
                style={{ justifyContent: "space-between", backgroundColor: "#303C54", borderRadius: "9px" }}
              >
                <div className="d-inline-flex align-items-center w-100">
                  <p
                    className={`mb-0 pr-2`}
                    style={{
                      color: "#E0E2E540",
                      fontSize: "30px",
                      fontWeight: "bold",
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    {p_indx + 1}
                  </p>
                  <FontAwesomeIcon className="mr-2" icon={faUserCircle} style={{ width: `30px`, height: `30px` }} />
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip placement="top" className="classroom-tooltip" id={`"tooltip-${value.id}"`}>
                        {value.owner__first_name}
                      </Tooltip>
                    }
                  >
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "14px",
                        color: "#E0E2E5",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        Width: "85px",
                      }}
                    >
                      {value.owner__first_name}
                    </p>
                  </OverlayTrigger>
                  <div className={`ml-auto d-inline-flex align-items-center`}>
                    <Dropdown
                      onClick={(e) => {
                        e.preventDefault()
                        setActiveParticipant(value)
                      }}
                      className={`breakout-groups`}
                    >
                      <Dropdown.Toggle
                        size="lg"
                        className={`border-0 px-1 py-1 d-inline-flex align-items-center `}
                        id="dropdown_list"
                        style={{
                          fontSize: "14px",
                          background:
                            value?.badges?.length > 0 && getCountOfBadges(value.badges) + 3 > 0
                              ? "transparent"
                              : "linear-gradient(90deg, #7F4F6D 0%, #608FD9 100%) 0% 0%",
                          borderRadius: "4px",
                        }}
                      >
                        {value?.badges?.length > 0 && getCountOfBadges(value.badges) + 3 > 0 ? (
                          <div className={`d-inline-flex w-100 align-items-center`}>
                            <div
                              className={`d-inline-flex align-items-center position-relative ml-2`}
                              style={{
                                width: "110px",
                                flexFlow: "row-reverse",
                              }}
                            >
                              {value.badges.map((p_badge, indx) => {
                                if (indx <= 2 && p_badge.count > 0) {
                                  return (
                                    <img
                                      src={p_badge.icon}
                                      alt={`badges`}
                                      className={`my-auto pointer ${StylesB.shadow_img}`}
                                      style={{
                                        objectFit: `contain`,
                                        width: `40px`,
                                        height: `40px`,
                                        position: indx <= 0 ? "relative" : "absolute",
                                        right: `${indx * 20}px`,
                                      }}
                                    />
                                  )
                                }
                              })}
                              {/* <img src={Badge1} alt={`badges`} className={`my-auto pointer`} style={{ objectFit: `contain`, width: `35px`, height: `35px`, position:'relative',left:`0px` }}/>
                            <img src={Badge2} alt={`badges`} className={`my-auto pointer`} style={{ objectFit: `contain`, width: `35px`, height: `35px`, position:'absolute',left:`20px` }}/>
                            <img src={Badge3} alt={`badges`} className={`my-auto pointer`} style={{ objectFit: `contain`, width: `35px`, height: `35px`, position:'absolute',left:`40px` }}/> */}
                            </div>
                            {getCountOfBadges(value.badges) > 0 ? (
                              <p className={`mb-0 ml-auto pl-1`} style={{ fontSize: "14px", color: "#E0E2E570" }}>
                                +{getCountOfBadges(value.badges)}
                              </p>
                            ) : null}
                          </div>
                        ) : (
                          <div className={`pr-2`} style={{ fontSize: "12px", width: "fit-content" }}>
                            Give a Badge
                          </div>
                        )}
                        <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className={`bg py-0 ${Styles.font_12}`}
                        style={{
                          background: "linear-gradient(90deg, #7F4F6D 0%, #608FD9 100%) 0% 0%",
                        }}
                      >
                        {badgesDataFetched ? (
                          activeParticipantBadges.length > 0 ? (
                            activeParticipantBadges.map((badge, b_index) => {
                              return (
                                <Dropdown.Item
                                  className="px-2 py-1 d-inline-flex align-items-center"
                                  style={{
                                    borderTop: "1px solid rgb(224, 226, 229)",
                                    color: "rgb(224, 226, 229)",
                                    borderRadius: "0px 0px 8px 8px",
                                    background: "linear-gradient(90deg, #7F4F6D 0%, #608FD9 100%) 0% 0%",
                                  }}
                                >
                                  <div
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      givebadgeToStudent(badge, badge.id, b_index, p_indx)
                                    }}
                                    className={`d-inline-flex w-100 align-items-center`}
                                  >
                                    <img
                                      src={badge.icon}
                                      alt={`badge`}
                                      className={`my-auto mr-2 pointer`}
                                      style={{
                                        objectFit: `contain`,
                                        width: `35px`,
                                        height: `35px`,
                                        position: "relative",
                                        left: `0px`,
                                      }}
                                    />
                                    {badge.name}
                                    <FontAwesomeIcon
                                      className={`mb-0 ml-auto`}
                                      style={{ fontSize: "15px" }}
                                      icon={badge.assigned ? faCheckCircle : faCircle}
                                      color="#FFFFFF45"
                                    />
                                  </div>
                                </Dropdown.Item>
                              )
                            })
                          ) : (
                            <p className={`mb-0 py-2 w-100 text-center`} style={{ fontSize: "14px" }}>
                              No badges created yet!
                            </p>
                          )
                        ) : (
                          <p className={`mb-0 py-2 w-100 text-center`} style={{ fontSize: "14px" }}>
                            loading..
                          </p>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown style={{ lineHeight: 1 }} className={``}>
                      <Dropdown.Toggle as="div" variant="none" id={`resource-option-menu-${value.id}`} className={`shadow-none p-0 ${Styles.no_caret}`}>
                        <img
                          src={EllipsisWhite}
                          alt={`ellipsis_icon_${value.id.toString()}`}
                          className={`my-auto pointer`}
                          style={{
                            objectFit: `contain`,
                            width: `20px`,
                            height: `16px`,
                            marginLeft: "3px",
                          }}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className={`${StylesB.resource_option_menu}`}>
                        {/* <div className={`d-flex pointer ${StylesB.resource_option_hover}`} style={{borderTop: '1px solid #929bbd'}}>
                          <img src={EditIcon} alt={`resource_result_${value.id.toString()}`} className={`my-auto mr-1`} style={{ objectFit: `contain`, width: `13px`, height: `13px` }}/>
                          <p>Edit</p>
                        </div> */}
                        <div
                          className={`d-flex pointer ${StylesB.resource_option_hover}`}
                          onClick={() => {
                            props.updateParticipantView(true, value)
                          }}
                          style={{ borderTop: "1px solid #929bbd" }}
                        >
                          <img
                            src={WhitePollIcon}
                            alt={`resource_result_${value.id.toString()}`}
                            className={`my-auto mr-1`}
                            style={{
                              objectFit: `contain`,
                              width: `13px`,
                              height: `13px`,
                            }}
                          />
                          <p>View Details</p>
                        </div>
                        <div
                          className={`d-flex pointer ${StylesB.resource_option_hover}`}
                          style={{ borderTop: "1px solid #929bbd" }}
                          onClick={() => {
                            setShowDeleteParticipantModal(true)
                            setParticipantData(value)
                          }}
                        >
                          <img
                            src={DeleteResource}
                            alt={`resource_result_${value.id.toString()}`}
                            className={`my-auto mr-1`}
                            style={{ objectFit: `contain`, width: `13px`, height: `13px` }}
                          />
                          <p>Delete</p>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <p className={`w-100 text-center`} style={{ fontSize: "16px" }}>
            No participants found
          </p>
        )}
      </div>
    )
  }

  function getOriginalData() {
    if (currentSelectedResourcesType === "participants") {
      return getParticipants()
    } else if (currentSelectedResourcesType === "chat") {
      return (
        <div style={{ textAlign: "center", marginTop: "30%", marginBottom: "30%" }}>
          <p>Chats need to be designed</p>
        </div>
      )
    } else {
      return getParticipantsLeadboard()
    }
  }

  function showParticipantsSection() {
    if (participantsDataFetched) {
      return (
        <div className={`d-inline-flex w-100 px-2`}>
          <div className={`w-100`}>
            {getOriginalData()}
            {seeMoreVisible === true && currentSelectedResourcesType !== "chat" ? (
              <div
                className={`pointer`}
                onClick={fetchNextParticipants}
                style={{
                  textAlign: "left",
                  color: "#E0E2E5",
                  fontSize: "14px",
                }}
              >
                <u>See More</u>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function showResourceTopNav() {
    return (
      <React.Fragment>
        <div
          key={1}
          className={`pointer ${currentSelectedResourcesType === "participants" ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item}`}
          onClick={() => {
            setCurrentSelectedResourcesType("participants")
          }}
        >
          <p>All Participants</p>
        </div>
        <div
          key={2}
          className={`pointer ${currentSelectedResourcesType === "leaderboard" ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item}`}
          onClick={() => {
            setCurrentSelectedResourcesType("leaderboard")
          }}
        >
          <p>Leaderboard</p>
        </div>
        {/* <div key={3} className={`pointer ${currentSelectedResourcesType === 'chat' ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item}`}
          onClick={() => {setCurrentSelectedResourcesType('chat');}}  >
          <p>Chats</p>
        </div> */}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="px-0 pb-5 flex-grow-1" style={{ overflowY: "auto" }}>
        <div className="p-3">
          <div className={`col-12 d-inline-flex ${StylesB.search_section}`}>
            <img src={SearchIcon} alt={`search button`} className={`my-auto`} style={{ objectFit: `contain`, width: `16px`, height: `16px` }} />
            <FormControl
              autoFocus
              className="border-0 py-0 px-2 form-control shadow-none font-14"
              style={{
                backgroundColor: `#303C54`,
                color: `#E0E2E5`,
                fontSize: "16px",
                height: "32px",
              }}
              placeholder={`Search participants`}
              onChange={(e) => {
                e.preventDefault()
                setSearchValue(e.target.value)
              }}
              value={searchValue}
            />
          </div>
        </div>
        <div className={`${StylesB.resource_nav_section}`} style={{ justifyContent: "space-around" }}>
          {showResourceTopNav()}
        </div>
        <div className={`${StylesB.resource_item_section} px-0`}>{showParticipantsSection()}</div>
      </div>
      {showAddContentButton()}
      <DeleteParticipant
        participantData={participantData}
        showModal={showDeleteParticipantModal}
        setShowModal={setShowDeleteParticipantModal}
        deleteParticipant={(student_id) => {
          deleteParticipant(student_id)
        }}
        getCountOfBadges={getCountOfBadges}
      />
      <Notification data={notificationData} setData={setNotificationData} />
      <UploadParticipantsModal
        show={showAddStudentsModal}
        setShow={(arg) => {
          setShowAddStudentsModal(arg)
        }}
        training_id={props.training_id}
        refetchParticipants={() => {
          fetchParticipants()
        }}
      />
    </React.Fragment>
  )
}

export default ClassParticipantsSection
