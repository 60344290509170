import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import FormControl from "react-bootstrap/FormControl"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Dropdown from "react-bootstrap/Dropdown"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { LazyLoadImage } from "react-lazy-load-image-component"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faEye,
  faFile,
  faFileAlt,
  faHome,
  faVideo,
  faLink,
  faPoll,
  faSearch,
  faChevronRight,
  faUpload,
  faGripLines,
  faEllipsisV,
  faTrashAlt,
  faCheck,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import Image from "../../components/image"
import { isBrowser } from "../../services/developer_mode"

import quizicon from "../../images/svgs/quizicon.svg"
import video from "../../images/svgs/video.svg"
import link from "../../images/svgs/link.svg"
import turientimage from "../../images/svgs/turientimage.svg"
import blog from "../../images/svgs/blog.svg"
import quiz from "../../images/svgs/quiz.svg"
import Close from "../../images/svgs/close.svg"

import Styles from "../../modularscss/styles.module.scss"

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  outline: "none",
  padding: grid,
  margin: `0`,
  borderRadius: "20px",
  textAlign: "center",
  color: "#fff",

  // change background colour if dragging
  background: isDragging ? "transparent" : "#12172C",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "transparent" : "#12172c",
  padding: grid,
})

const ViewResources = (location, data) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [searchValue, setSearchValue] = useState("")
  const [collapse, setCollapse] = useState(false)

  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("video")

  // each resource type full data in allResources array
  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(false)

  // each resource type full data in allResources array
  const [classroomResources, setClassroomResources] = useState([])
  const [classroomResourcesNext, setClassroomResourcesNext] = useState(null)
  const [classroomResourcesFetched, setClassroomResourcesFetched] = useState(false)

  const [draggedData, setDraggedData] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [intialFetch, setIntialFetch] = useState(false)

  useEffect(() => {
    fetchAllResources()
  }, [currentSelectedResourcesType])

  useEffect(() => {
    if (allResources[currentSelectedResourcesType] !== undefined) {
      fetchAllResourcesOnSearch()
    }
  }, [searchValue])

  useEffect(() => {
    fetchClassroomResources()
  }, [])

  async function fetchAllResources() {
    if (allResources[currentSelectedResourcesType] === undefined) {
      setAllResourcesFetched(false)
      let data_type = ""
      let type = currentSelectedResourcesType + "s"
      if (currentSelectedResourcesType === "turientimage") {
        type = "images"
      } else if (currentSelectedResourcesType === "quiz") {
        type = "quizzes"
        data_type = "&data_type=0&published=true"
      } else if (currentSelectedResourcesType === "poll") {
        type = "quizzes"
        data_type = "&data_type=1&published=true"
      } else if (currentSelectedResourcesType === "pop-quiz") {
        type = "quizzes"
        data_type = "&data_type=2&published=true"
      }
      let search = searchValue === "" ? "" : `&search=${searchValue}`
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-${type}-list/?paginate_by=15${search}&adminsite=true${data_type}`, { headers: login_credentials })
        .then((res) => {
          allResources[currentSelectedResourcesType] = res.data.results
          allResourcesNext[currentSelectedResourcesType] = res.data.next === null ? {} : { next: res.data.next }
          setAllResourcesFetched(true)
        })
    }
  }

  async function fetchAllResourcesOnSearch() {
    setAllResourcesFetched(false)
    let data_type = ""
    let type = currentSelectedResourcesType + "s"
    if (currentSelectedResourcesType === "turientimage") {
      type = "images"
    } else if (currentSelectedResourcesType === "quiz") {
      type = "quizzes"
      data_type = "&data_type=0&published=true"
    } else if (currentSelectedResourcesType === "poll") {
      type = "quizzes"
      data_type = "&data_type=1&published=true"
    } else if (currentSelectedResourcesType === "pop-quiz") {
      type = "quizzes"
      data_type = "&data_type=2&published=true"
    }
    let search = searchValue === "" ? "" : `&search=${searchValue}`
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-${type}-list/?paginate_by=15${search}&adminsite=true${data_type}`, { headers: login_credentials })
      .then((res) => {
        allResources[currentSelectedResourcesType] = res.data.results
        allResourcesNext[currentSelectedResourcesType] = res.data.next === null ? {} : { next: res.data.next }
        setAllResourcesFetched(true)
      })
  }

  async function fetchNextAllResources(next_url) {
    if (next_url) {
      setAllResourcesFetched(false)
      axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
        var next_all_res = allResources[currentSelectedResourcesType]
        if (res.data.results.length > 0) {
          for (var rs = 0; rs <= res.data.results.length - 1; rs++) {
            next_all_res.push(res.data.results[rs])
          }
          allResources[currentSelectedResourcesType] = next_all_res
        }
        allResourcesNext[currentSelectedResourcesType] = res.data.next === null ? {} : { next: res.data.next }
        setAllResourcesFetched(true)
      })
    }
  }

  async function fetchClassroomResources() {
    if (classroomResources.length === 0) {
      setClassroomResourcesFetched(false)
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-resources-list/?paginate_by=15&defaultcoursetemplate=${location.template_id}`, {
          headers: login_credentials,
        })
        .then((res) => {
          setClassroomResources(res.data.results)
          setClassroomResourcesNext(res.data.next)
          setClassroomResourcesFetched(true)
        })
    }
  }

  async function fetchNextClassroomResources(next_url) {
    if (next_url) {
      setClassroomResourcesFetched(false)
      axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
        var next_all_res = allResources[currentSelectedResourcesType]
        if (res.data.results.length > 0) {
          for (var rs = 0; rs <= res.data.results.length - 1; rs++) {
            next_all_res.push(res.data.results[rs])
          }
          setClassroomResources(next_all_res)
        }
        setClassroomResourcesNext(res.data.next)
        setClassroomResourcesFetched(true)
      })
    }
  }

  function openNav() {
    let side_div_width = document.getElementById("mySidepanel").offsetWidth
    if (side_div_width > 250) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  function handleSave(currentItem, type, value, show_results) {
    if (currentItem) {
      var index = classroomResources.findIndex((res) => res.id === currentItem.id)
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      formData.append("resource_id", currentItem.resource_id)
      formData.append("resource_type", currentItem.resource_type)
      formData.append("template", location.template_id)
      formData.append("is_triggered", value)
      if (!show_results) {
        formData.append("triggered_count", parseInt(currentItem.triggered_count) + 1)
      }
      formData.append("show_results", show_results)
      if (value || show_results) {
        formData.append("created", new Date().toISOString())
        if (!show_results) {
          classroomResources[index].is_triggered = "loading"
        } else {
          classroomResources[index].show_results = "loading"
        }
        if (!intialFetch) {
          setIntialFetch(!intialFetch)
        }
      }
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/resources/` + currentItem.id + "/", formData, { headers: DefaultHeader })
        .then((res) => {
          setTimeout(function () {
            classroomResources[index].is_triggered = value
            if (!show_results) {
              classroomResources[index].triggered_count = parseInt(currentItem.triggered_count) + 1
            }
            classroomResources[index].show_results = show_results
            setIsUpdated(true)
          }, 300)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
    }
  }

  function onDragEnd(result) {
    // dropped outside the lis
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    if (result.type === "resourceDraggable") {
      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      const sourceSubItems = sourceParentId === "all" ? allResources[currentSelectedResourcesType] : classroomResources
      const destSubItems = destParentId === "all" ? allResources[currentSelectedResourcesType] : classroomResources

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(sourceSubItems, sourceIndex, destIndex)

        let resourcessData = reorderedSubItems[destIndex]
        let currentData = sourceSubItems
        if (destParentId === "classroom") {
          /*if (destIndex === 0) {
            resourcessData.sequence = (parseFloat(currentData[0].sequence) -  10).toFixed(20)
          } else if (destIndex === currentData.length - 1) {
            resourcessData.sequence = (parseFloat(currentData[currentData.length - 1].sequence) +  10).toFixed(20)
          } else {
            if (result.source.index > destIndex) {
              resourcessData.sequence = ( (parseFloat(currentData[destIndex - 1].sequence) + parseFloat(currentData[destIndex].sequence)) / 2 ).toFixed(20)
            }else if (result.source.index < destIndex){
              resourcessData.sequence = ( (parseFloat(currentData[destIndex].sequence) + parseFloat(currentData[destIndex + 1].sequence)) / 2 ).toFixed(20)
            }
          }*/
        }
        setDraggedData(!draggedData)
        if (destParentId === "classroom") {
          /*handleDraggedItemSequence(resourcessData,"update")*/
        }
        if (destParentId === "classroom") {
          setClassroomResources(reorderedSubItems)
        } else {
          allResources[currentSelectedResourcesType] = reorderedSubItems
        }
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)
        draggedItem.sequence = destIndex
        if (destParentId === "classroom") {
          /*if (destIndex === 0 && destSubItems.length === 0) {
            draggedItem.sequence = destIndex
          }else if (destIndex === 0) {
            if (draggedItem) {
              draggedItem.sequence = (parseFloat(destSubItems[destIndex].sequence) - 10).toFixed(20)
            }
          } else if (destIndex === destSubItems.length) {
            draggedItem.sequence = (parseFloat(destSubItems[destIndex - 1].sequence) +  10).toFixed(20)
          }else {
            draggedItem.sequence =
              (
                (
                  parseFloat(destSubItems[destIndex].sequence)
                  +
                  parseFloat(destSubItems[parseFloat(destIndex) - 1].sequence)
                ) / 2
              ).toFixed(20)
          }*/
          if (destSubItems.length === 0) {
            draggedItem.sequence = 0
          } else {
            draggedItem.sequence = (parseFloat(destSubItems[destSubItems.length - 1].sequence) + 10).toFixed(20)
          }

          var formData = new FormData()
          formData.append("sequence", draggedItem.sequence)
          if (currentSelectedResourcesType == "poll" || currentSelectedResourcesType == "pop-quiz") {
            formData.append("resource_type", "quiz")
          } else {
            formData.append("resource_type", currentSelectedResourcesType)
          }
          formData.append("resource_id", draggedItem.id)
          formData.append("add_to_template", location.template_id)
          axios
            .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
            .then((res) => {
              if (res.data) {
                draggedItem.id = res.data.id
                draggedItem.resource_data = res.data.resource_data
                draggedItem.resource_id = res.data.resource_id
                draggedItem.resource_type = res.data.resource_type
                draggedItem.sequence = res.data.sequence
                draggedItem.is_triggered = res.data.is_triggered
                draggedItem.triggered_count = res.data.triggered_count
                delete draggedItem["name"]
              }
              setDraggedData(!draggedData)
            })
            .catch((error) => {
              let error_messages = ""
              for (var key in error.response.data) {
                error_messages += error.response.data[key] + "<br>"
              }
              if (error_messages === "Invalid token.<br>") {
                authClient.signOut()
                localStorage.removeItem("authenticity_token")
                localStorage.removeItem("username")
                window.location.reload(true)
              }
              document.getElementById("fixed-error-div").innerHTML =
                '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
                '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
                '<span aria-hidden="true">×</span></button>' +
                error_messages +
                "</div>"
            })
        } else {
          draggedItem.dragged_id = draggedItem.id
          draggedItem.id = draggedItem.resource_data.id
          draggedItem.thumbnail_url = draggedItem.resource_data.thumbnail_url
          draggedItem.name = draggedItem.resource_data?.display_name ?? draggedItem.resource_data?.name
          delete draggedItem["resource_data"]
          delete draggedItem["resource_id"]
          delete draggedItem["resource_type"]
          deleteResource(draggedItem.dragged_id)
        }

        let newDestSubItems = destSubItems
        newDestSubItems.splice(destIndex, 0, draggedItem)
        if (destParentId === "classroom") {
          allResources[currentSelectedResourcesType] = newSourceSubItems
          setClassroomResources(newDestSubItems)
        } else {
          setClassroomResources(newSourceSubItems)
          allResources[currentSelectedResourcesType] = newDestSubItems
        }
      }
    }
  }

  let page_uri = isBrowser() ? "/" + window.location.pathname.substr(1) : "false"
  if (page_uri === "/logout" || page_uri === "logout" || page_uri === "logout/" || page_uri === "/logout/") {
    page_uri = "/"
  }

  function showResourcesButton() {
    let logo = video
    if (currentSelectedResourcesType === "blog") {
      logo = blog
    } else if (currentSelectedResourcesType === "video") {
      logo = video
    } else if (currentSelectedResourcesType === "link") {
      logo = link
    } else if (currentSelectedResourcesType === "quiz") {
      logo = quiz
    } else if (currentSelectedResourcesType === "poll") {
      logo = blog
    } else {
      logo = turientimage
    }
    let data = allResources[currentSelectedResourcesType]
    data = data ? data : []
    let type = "resource"
    let next_url = allResourcesNext[currentSelectedResourcesType]
    return (
      <Droppable droppableId={`all`} type={`resourceDraggable`}>
        {(provided, snapshot) => (
          <div
            className={`bg-transparent resources ${collapse ? `${Styles.opacity_0} p-0` : `${Styles.opacity_1}`}`}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {data.map((item, index) => (
              <Draggable key={item.id + `_${type}_name_` + index} draggableId={item.id.toString() + `-${type}` + "_" + index.toString()} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={type === "resource" ? `w-50 mt-3 px-1 bg-transparent py-0 float-left` : `p-0 float-none`}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <div
                      className={`${type === "resource" ? `resource-card-bg d-flex` : `p-2 mb-2`}`}
                      style={{ color: `#C6CFF4`, position: `relative`, background: "#28314e" }}
                    >
                      <div>
                        {item.thumbnail_url ? (
                          <LazyLoadImage
                            src={item.thumbnail_url || item.avatar}
                            alt={item.name}
                            style={{ borderRadius: `10px`, width: `100%`, height: `100%`, fontSize: `12px` }}
                          />
                        ) : (
                          <div
                            className={`position-absolute`}
                            style={{
                              backgroundColor:
                                currentSelectedResourcesType === "video"
                                  ? `#3C6481`
                                  : currentSelectedResourcesType === "blog"
                                  ? `#619176`
                                  : currentSelectedResourcesType === "link"
                                  ? `#A3801F`
                                  : currentSelectedResourcesType === "quiz"
                                  ? `#6D77D9`
                                  : `#A86E84`,
                              borderRadius: `10px`,
                              width: `100%`,
                              height: `100%`,
                            }}
                          >
                            <div className={`d-inline-flex align-items-center`} style={{ verticalAlign: `middle` }}>
                              <img
                                src={item.avatar && item.avatar.indexOf(".pdf") < 1 ? item.avatar : logo}
                                alt={currentSelectedResourcesType}
                                className={`mb-0 mt-2 ml-2 float-left position-absolute`}
                                style={{ objectFit: `contain`, height: item.avatar && item.avatar.indexOf(".pdf") < 1 ? `40px` : `24px`, left: 0, top: 0 }}
                              />
                              <span
                                className={`float-right px-2 mt-2 mr-2 position-absolute`}
                                style={{ backgroundColor: `#00000080`, boxShadow: `0px 3px 6px #00000029`, borderRadius: `13px`, fontSize: `12px`, right: 0 }}
                              >
                                {currentSelectedResourcesType === "turientimage" ? `file` : currentSelectedResourcesType}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className={`${item.thumbnail_url ? Styles.img_bg : ``}`}></div>
                        {item.name && item.name.length > 35 ? (
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip className="event-tooltip" id={`tooltip-${item.name}`}>
                                {item.name}
                              </Tooltip>
                            }
                          >
                            <p
                              className={`elipsis-2 text-center w-100 px-2 pb-1 mt-auto position-absolute ${Styles.font_12} ${Styles.b_0}`}
                              style={{ paddingTop: `5px`, margin: `0px`, fontWeight: "bold", maxHeight: `42px` }}
                            >
                              {item.name}
                            </p>
                          </OverlayTrigger>
                        ) : (
                          <p
                            className={`elipsis-2 text-center w-100 px-2 pb-1 mt-auto position-absolute ${Styles.font_12} ${Styles.b_0}`}
                            style={{ paddingTop: `5px`, margin: `0px`, fontWeight: "bold", WebkitBoxOrient: "vertical" }}
                          >
                            {item.name}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {next_url && Object.keys(next_url).length > 0 ? (
              <Col md="auto" className={`align-self-center text-center d-inline-flex w-100 justify-content-center`}>
                <Button
                  variant="none"
                  onClick={(e) => {
                    e.preventDefault()
                    fetchNextAllResources(next_url.next)
                  }}
                  className={`${Styles.font_12} text-center px-3 py-2 mt-2`}
                  style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                >
                  See More
                </Button>
              </Col>
            ) : (
              ``
            )}
          </div>
        )}
      </Droppable>
    )
  }

  function deleteResource(id) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        setDraggedData(!draggedData)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  async function triggerResource() {
    setTimeout(function () {
      axios
        .get(
          `${process.env.GATSBY_REACT_APP_API_HOST}/basic-resources-list/?paginate_by=1&is_triggered=true&is_poll=false&template_id=${location.template_id}`,
          { headers: login_credentials }
        )
        .then((res) => {
          // console.log('triggered')
        })
        .catch((error) => {})
    }, 300)
  }

  function showClassroomResources() {
    if (classroomResourcesFetched && classroomResources) {
      return (
        <Droppable droppableId={`classroom`} type={`resourceDraggable`}>
          {(provided, snapshot) => (
            <div className={`col-12`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {classroomResources.map((datum, index) => (
                <Draggable key={datum.id + `-claaroom-key-` + index} draggableId={`${datum.id.toString()}-classroom-id-${index.toString()}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`tab-ignore`}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <div
                        id={"quizQuestions_" + datum.id}
                        className={`${Styles.font_16} shadow-none w-100 text-left d-inline-flex`}
                        style={{ backgroundColor: `#28324e`, color: `#b2b6cb`, borderRadius: `0px`, opacity: 1 }}
                      >
                        {/*<div><h2 className={`px-3 m-0 h-100`} style={{ color: `#9ba1c4`, backgroundColor: `#12182c` }} >{index+1}</h2></div>*/}
                        <div className={`d-inline-flex w-100`}>
                          <div className={`px-3 py-2 d-inline-flex`} style={{ borderRight: `1px dotted #495269` }}>
                            <FontAwesomeIcon icon={faGripLines} style={{ width: `20px`, height: `20px`, margin: `auto 0px`, verticalAlign: `middle` }} />
                          </div>
                          <p
                            id={datum.id + `-classroom-resource-name`}
                            className={`border-0 px-3 elipsis-2 ${
                              datum.resource_type === "quiz" && datum.resource_data.data_type === "1" ? `w-50` : `w-75`
                            } my-auto ${Styles.font_16}`}
                            style={{ backgroundColor: `transparent`, maxWidth: `fix-content`, color: `#b2b6cb`, verticalAlign: `middle` }}
                          >
                            {datum.resource_data?.display_name ?? datum.resource_data?.name ?? datum.name}
                          </p>
                          <div className={`ml-auto d-inline-flex`}>
                            {false ? (
                              <React.Fragment>
                                <Button
                                  variant="none"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setIsUpdated(false)
                                    handleSave(datum, "update", true, false)
                                    triggerResource()
                                  }}
                                  className={`${Styles.font_14} text-center px-3 py-3 h-100 align-items-center d-inline-flex text-white`}
                                  style={{ backgroundColor: `#576186`, borderRadius: 0 }}
                                >
                                  <FontAwesomeIcon
                                    icon={datum.is_triggered ? (datum.is_triggered === "loading" ? faSpinner : faCheck) : faPlus}
                                    style={{ width: `18px`, height: `18px` }}
                                  />
                                  &ensp; {datum.is_triggered ? `ReTrigger` : "Trigger"}
                                </Button>
                                {datum.resource_type === "quiz" && datum.resource_data.data_type === "1" && false ? (
                                  <Button
                                    variant="none"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      setIsUpdated(false)
                                      handleSave(datum, "update", true, true)
                                      triggerResource()
                                    }}
                                    className={`${Styles.font_14} text-center px-3 py-3 h-100 align-items-center d-inline-flex text-white ml-2`}
                                    style={{ backgroundColor: `#576186`, borderRadius: 0 }}
                                  >
                                    <FontAwesomeIcon
                                      icon={datum.show_results ? (datum.show_results === "loading" ? faSpinner : faCheck) : faPlus}
                                      style={{ width: `18px`, height: `18px` }}
                                    />
                                    &ensp; Show Results
                                  </Button>
                                ) : (
                                  ``
                                )}
                                {datum.resource_type === "quiz" && datum.resource_data.data_type === "1" && datum.is_triggered ? (
                                  <Button
                                    variant="none"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      navigate(`/app/template/${location.template_id}/polls/${datum.id}/${datum.resource_id}/results`)
                                    }}
                                    className={`${Styles.font_14} text-center px-3 py-3 h-100 align-items-center d-inline-flex text-white ml-2`}
                                    style={{ backgroundColor: `#576186`, borderRadius: 0 }}
                                  >
                                    <FontAwesomeIcon
                                      icon={datum.show_results ? (datum.show_results === "loading" ? faSpinner : faEye) : faEye}
                                      style={{ width: `18px`, height: `18px` }}
                                    />
                                    &ensp; View Results
                                  </Button>
                                ) : (
                                  ``
                                )}
                              </React.Fragment>
                            ) : (
                              ``
                            )}
                            <Dropdown className={`py-2 pl-2 pr-4 shadow-none my-auto`}>
                              <Dropdown.Toggle variant="none" id={`question-delete-menu-${datum.id}`} className={`shadow-none p-0 ${Styles.no_caret}`}>
                                <FontAwesomeIcon className="pointer" icon={faEllipsisV} style={{ width: `20px`, height: `20px`, color: `#9AA7DB` }} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className={`${Styles.dropdown_bg}`}>
                                <Dropdown.Item
                                  eventKey="0"
                                  className={`text-white`}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    const newState = classroomResources
                                    newState.splice(index, 1)
                                    setClassroomResources(newState)
                                    deleteResource(datum.id)
                                  }}
                                >
                                  <FontAwesomeIcon className="pointer" icon={faTrashAlt} style={{ width: `20px`, height: `20px` }} /> Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {classroomResourcesNext !== null ? (
                <Col md="auto" className={`d-inline-flex w-100 my-2`}>
                  <Button
                    variant="none"
                    onClick={(e) => {
                      e.preventDefault()
                      fetchNextClassroomResources(classroomResourcesNext)
                    }}
                    className={`${Styles.font_12} text-center px-3 py-2 mx-auto`}
                    style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                  >
                    See More
                  </Button>
                </Col>
              ) : (
                ""
              )}
              {classroomResources && classroomResources.length > 0 ? (
                <div className={`justify-content-center py-2 d-flex bg-transparent mx-2 `} style={{ border: `1px dashed white` }}>
                  <p className={`m-0`}>
                    <span className={`${Styles.font_14}`} style={{ color: `#C6CFF4` }}>
                      Drag and drop media and files to add content.
                    </span>
                  </p>
                </div>
              ) : (
                <div className={`justify-content-center pt-4 pb-3 mt-3 d-flex bg-transparent`} style={{ border: `1px dashed white` }}>
                  <Image style={{ width: "100px", height: "80px" }} alt="add resources here" src="stages.png" className="d-inline-block" />
                  <p className={`pl-3`}>
                    <span className={`font-weight-bold`} style={{ color: `#D4DCF7` }}>
                      Add Content
                    </span>
                    <br />
                    <span className={`${Styles.font_14}`} style={{ color: `#C6CFF4` }}>
                      Drag and drop media and files to add content. <br /> As easy as that
                    </span>
                  </p>
                </div>
              )}
            </div>
          )}
        </Droppable>
      )
    }
  }

  return (
    <div>
      <SEO title="Training Resources" />
      <DragDropContext onDragEnd={onDragEnd}>
        <Col lg="12" md="12" sm="12" xs="12" className={`px-0 d-inline-flex float-left`}>
          <div className={`float-left position-absolute`} style={{ width: `30%` }}>
            <div className={"float-left"} style={{ width: `88px`, borderRight: `1px solid #2e3546`, height: `100vh` }}>
              <div>
                <Button
                  key={0}
                  variant="none"
                  className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(`/app/dashboard/`)
                  }}
                  style={{ backgroundColor: `transparent`, color: `#c6cff4`, borderRadius: `0px`, minWidth: `fit-content`, opacity: 1 }}
                >
                  <FontAwesomeIcon icon={faHome} style={{ width: `20px`, height: `20px` }} />
                  Home
                </Button>
                <Button
                  key={1}
                  variant="none"
                  className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSelectedResourcesType("video")
                    setCollapse(false)
                  }}
                  style={{
                    backgroundColor: currentSelectedResourcesType === "video" ? `#576186` : `transparent`,
                    color: `#c6cff4`,
                    borderRadius: `0px`,
                    minWidth: `fit-content`,
                    opacity: 1,
                  }}
                >
                  <FontAwesomeIcon icon={faVideo} style={{ width: `20px`, height: `20px` }} />
                  Videos
                </Button>
                <Button
                  key={2}
                  variant="none"
                  className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSelectedResourcesType("blog")
                    setCollapse(false)
                  }}
                  style={{
                    backgroundColor: currentSelectedResourcesType === "blog" ? `#576186` : `transparent`,
                    color: `#c6cff4`,
                    borderRadius: `0px`,
                    minWidth: `fit-content`,
                    opacity: 1,
                  }}
                >
                  <FontAwesomeIcon icon={faFileAlt} style={{ width: `20px`, height: `20px` }} />
                  Blogs
                </Button>
                <Button
                  key={3}
                  variant="none"
                  className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSelectedResourcesType("turientimage")
                    setCollapse(false)
                  }}
                  style={{
                    backgroundColor: currentSelectedResourcesType === "turientimage" ? `#576186` : `transparent`,
                    color: `#c6cff4`,
                    borderRadius: `0px`,
                    minWidth: `fit-content`,
                    opacity: 1,
                  }}
                >
                  <FontAwesomeIcon icon={faFile} style={{ width: `20px`, height: `20px` }} />
                  Files
                </Button>
                <Button
                  key={4}
                  variant="none"
                  className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSelectedResourcesType("link")
                    setCollapse(false)
                  }}
                  style={{
                    backgroundColor: currentSelectedResourcesType === "link" ? `#576186` : `transparent`,
                    color: `#c6cff4`,
                    borderRadius: `0px`,
                    minWidth: `fit-content`,
                    opacity: 1,
                  }}
                >
                  <FontAwesomeIcon icon={faLink} style={{ width: `20px`, height: `20px` }} />
                  Links
                </Button>
                <Button
                  key={5}
                  variant="none"
                  className={`${Styles.font_14} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSelectedResourcesType("quiz")
                    setCollapse(false)
                  }}
                  style={{
                    backgroundColor: currentSelectedResourcesType === "quiz" ? `#576186` : `transparent`,
                    color: `#c6cff4`,
                    borderRadius: `0px`,
                    minWidth: `fit-content`,
                    opacity: 1,
                  }}
                >
                  <img src={quizicon} alt={`quiz button`} className={`my-auto`} style={{ objectFit: `contain`, width: `20px`, height: `20px` }} />
                  Quizzes
                </Button>
                <Button
                  key={6}
                  variant="none"
                  className={`${Styles.font_14} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSelectedResourcesType("poll")
                    setCollapse(false)
                  }}
                  style={{
                    backgroundColor: currentSelectedResourcesType === "poll" ? `#576186` : `transparent`,
                    color: `#c6cff4`,
                    borderRadius: `0px`,
                    minWidth: `fit-content`,
                    opacity: 1,
                  }}
                >
                  <FontAwesomeIcon icon={faPoll} style={{ width: `20px`, height: `20px` }} />
                  Polls
                </Button>
                <Button
                  key={7}
                  variant="none"
                  className={`${Styles.font_14} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSelectedResourcesType("pop-quiz")
                    setCollapse(false)
                  }}
                  style={{
                    backgroundColor: currentSelectedResourcesType === "pop-quiz" ? `#576186` : `transparent`,
                    color: `#c6cff4`,
                    borderRadius: `0px`,
                    minWidth: `fit-content`,
                    opacity: 1,
                  }}
                >
                  <img src={quizicon} alt={`pop quiz button`} className={`my-auto`} style={{ objectFit: `contain`, width: `20px`, height: `20px` }} />
                  <p className="m-0" style={{ lineHeight: "1.3" }}>
                    Pop
                    <br />
                    Quizzes
                  </p>
                </Button>
              </div>
            </div>
            <div
              className={`float-left ${collapse ? `${Styles.side_panel_collapsed}` : `${Styles.side_panel_opened}`}`}
              id={`mySidepanel`}
              style={{ backgroundColor: `#2e3446` }}
            >
              <div style={{ padding: `15px 15px 0px 15px` }}>
                <div className={`col-12 d-inline-flex ${collapse ? Styles.opacity_0 : Styles.opacity_1}`} style={{ backgroundColor: `#414a6c` }}>
                  <FontAwesomeIcon icon={faSearch} size="lg" style={{ margin: `auto 0px`, color: `#7c84a0` }} />
                  <FormControl
                    autoFocus
                    className="border-0 py-1 px-2 form-control px-0 shadow-none font-14"
                    style={{ backgroundColor: `transparent`, color: `#b6bdd4` }}
                    placeholder={`search ${currentSelectedResourcesType}`}
                    onChange={(e) => {
                      e.preventDefault()
                      setSearchValue(e.target.value)
                      setAllResourcesFetched(false)
                    }}
                    value={searchValue}
                  />
                </div>
              </div>
              {showResourcesButton(`resource`, currentSelectedResourcesType)}
              <Col
                onClick={() => navigate(`/app/upload?redirection_url=${page_uri}`)}
                className={`${Styles.fixed_button} ${collapse ? `${Styles.fixed_button_0}` : `${Styles.fixed_button_1}`} pointer `}
              >
                {" "}
                <FontAwesomeIcon icon={faUpload} style={{ width: `24px`, height: `24px` }} />{" "}
                {collapse ? `` : <span className={`pl-3`}>Upload More Content</span>}{" "}
              </Col>
            </div>
            <div className={`d-inline-flex`} style={{ height: `100vh` }}>
              <FontAwesomeIcon
                icon={collapse ? faChevronRight : faChevronLeft}
                onClick={() => openNav()}
                className={`my-auto py-1 pointer`}
                style={{ width: `24px`, height: `24px`, backgroundColor: `#2e3546`, borderRadius: collapse ? `0px 8px 8px 0px` : `0px 8px 8px 0px` }}
              />
            </div>
          </div>
          <div
            id={`scrollable-div`}
            className={`px-0 float-left position-fixed ${collapse ? `${Styles.scrollable_div_collapsed}` : `${Styles.scrollable_div_opened}`}`}
            style={{ height: `calc(100vh - 70px)`, overflowY: `scroll` }}
          >
            <div className={`sticky-top`}>
              <Col xs="12" sm="12" md="12" className={`px-0`} style={{ backgroundColor: `#12192c` }}>
                <h5 className={`${Styles.font_h3} border-bottom p-2 m-0`} style={{ color: `#C6CFF4`, fontFamily: `Helvetica Neue` }}>
                  <Link className={`pointer text-white`} to={`/app/dashboard/`}>
                    {" "}
                    <FontAwesomeIcon icon={faChevronLeft} style={{ width: `25px`, height: `25px` }} />
                  </Link>
                  &nbsp;
                  <span className={`text-white font-weight-bold`}>View Activities</span>
                  <Link className={`pointer ml-auto float-right`} to={`/app/dashboard`} style={{ color: `#C6CFF4` }}>
                    <span style={{ fontWeight: "normal", fontSize: "16px" }} className={`small`}>
                      Exit &nbsp;
                      <img src={Close} alt={`Exit Course`} className={`my-auto`} style={{ objectFit: `contain`, height: `15px`, color: `white` }} />
                    </span>
                  </Link>
                </h5>
              </Col>
            </div>
            {showClassroomResources()}
          </div>
        </Col>
      </DragDropContext>
    </div>
  )
}

export default ViewResources
