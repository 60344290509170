import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import Style from "./style.module.scss"
import Checkbox from "../../../../custom/Checkbox"
import { postRequest } from "../../../../../services/rest_service"
import { HasCreatePermission, HasEditPermission } from "../../../../../utils/PermissionDataAccess"
import { ACCESS_CONTROL } from "../../../../../constants/permissionsConstant"
import { GlobalContext } from "../../../../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../../../../stores/global_store/GlobalActions"

const PERMISSION_KEYS = ["create", "write", "read", "delete"]

const AccessControl = (props) => {
  const { selectedTeam, setSelectedTeam } = props
  const [permissionData, setPermissionData] = useState({
    permission: selectedTeam?.permission.map((perm) => {
      return {
        permission: {
          ...perm.permission,
        },
        permission_config: perm.id,
      }
    }),
    role: selectedTeam?.id,
  })
  const [notificationData, dispatch] = useContext(GlobalContext)

  const getGroupPermission = async (groupPermissionData) => {
    let res = await postRequest(`/role-management/role-permission-management/set_permission_data/`, groupPermissionData)
    if (res.success) {
      dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "success", title: res.data.msg },
      })
    } else {
      dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "error", title: res.data.detail },
      })
    }
  }

  const handleCheckbox = (permission_key, checkedFlag, index) => {
    let tempSelectedTeam = { ...selectedTeam }
    tempSelectedTeam.permission[index].permission[permission_key] = checkedFlag

    if (permission_key === "create" && checkedFlag) {
      // Check all other checkboxes if "create" is checked
      PERMISSION_KEYS.filter((key) => key !== "create").forEach((key) => {
        tempSelectedTeam.permission[index].permission[key] = true
      })
    } else if (permission_key === "write" && checkedFlag) {
      // Check "read" if "write" is checked
      tempSelectedTeam.permission[index].permission.read = true
    } else if (permission_key === "read" && !checkedFlag) {
      // Uncheck "write","delete" and "create" if "read" is unchecked
      tempSelectedTeam.permission[index].permission.write = false
      tempSelectedTeam.permission[index].permission.create = false
      tempSelectedTeam.permission[index].permission.delete = false
    }

    setSelectedTeam(tempSelectedTeam)

    let currentPermissionData = { ...permissionData }
    let permissionConfigToUpdate = tempSelectedTeam.permission[index].id
    let updatedPermission = tempSelectedTeam.permission[index].permission

    // Find the object within the permission array based on the permission_config value
    let permissionObjectToUpdate = currentPermissionData.permission.find((perm) => perm.permission_config === permissionConfigToUpdate)

    // If the object is found, update the permission flag
    if (permissionObjectToUpdate) {
      permissionObjectToUpdate.permission = updatedPermission
    }

    setPermissionData(currentPermissionData)
  }

  const updateGroupPermission = () => {
    getGroupPermission(permissionData)
  }

  return (
    <div className="d-flex flex-column w-100 h-100">
      <div className={`w-100 h-70 mb-4 ${Style.mainDiv}`}>
        <div className="d-flex flex-column">
          <div className={`${Style.row} opacity-60  d-flex align-items-center justify-content-center turient-header-text-color w-100 `}>All Pages</div>
          {selectedTeam?.permission?.map(({ name }) => (
            <div className={`${Style.row}  d-flex align-items-center justify-content-center turient-header-text-color w-100`} key={name}>
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </div>
          ))}
        </div>
        <div className="d-flex flex-column">
          <div className={`${Style.rowContent} opacity-60 w-100 h-3rem align-items-center text-center`}>
            {PERMISSION_KEYS.map((permission_key, index) => {
              return (
                <span className="d-flex align-items-center justify-content-center " key={`permission-key-${permission_key}`}>
                  {permission_key.charAt(0).toUpperCase() + permission_key.slice(1)}
                </span>
              )
            })}
            <span></span>
          </div>
          {/* Map over the groups to generate the checkboxes */}
          {selectedTeam?.permission?.map(({ id, permission }, index) => (
            <div className={`${Style.rowContent} w-100 h-3rem  text-center`} key={`permission-wrapper-${id}`}>
              {PERMISSION_KEYS.map((permission_key) => (
                <div className="mt-3" key={`permission-checkbox-wrapper-${id}-${permission_key}`}>
                  <Checkbox
                    key={`permission-checkbox-${id}-${permission_key}`}
                    isChecked={permission[permission_key]}
                    checkmarkStyle={"d-flex justify-content-center align-items-center pt-12px"}
                    id={`${permission_key}`}
                    onCheckboxChange={(permission_key, checkedFlag) => handleCheckbox(permission_key, checkedFlag, index)}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {(HasCreatePermission(ACCESS_CONTROL) || HasEditPermission(ACCESS_CONTROL)) && (
        <div className="d-flex justify-content-end">
          <button
            className={`${Style.button} br-9px turient-header-text-color border_on_hover d-flex flex-row align-items-center  bg-586886 `}
            onClick={updateGroupPermission}
          >
            <p className="fs-14px poppins_font mb-0 p-2">{"Save"}</p>
          </button>
        </div>
      )}
    </div>
  )
}

AccessControl.propTypes = {
  selectedTeam: PropTypes.object.isRequired,
  setSelectedTeam: PropTypes.func.isRequired,
}

export default AccessControl
