import React, { useEffect, useState } from "react"

import Loader from "../../custom/Loader"
import ToolTipForBarGraph from "../../InsightsReports/barGraphToolTip"

// import { ResponsiveLine } from "@nivo/line"
import { THEME } from "../constants"

const ClassHourWisechart = (props) => {
  const [fetchedGraphData, setFetchedGraphData] = useState(true)
  const [graphData, setGraphsData] = useState(null)

  useEffect(() => {
    if (props.graphData?.graphs[0]?.data?.length > 1) {
      let copy = { ...props.graphData }
      copy.graphs[0].data.unshift({ x: "", y: 0 })
      const dataWithUniqueX = copy.graphs[0]?.data.map((item, index) => {
        return { ...item, x: `${item.x}-${index}` }
      })
      copy.graphs[0].data = [...dataWithUniqueX]
      setGraphsData(copy)
    } else if (props.graphData?.graphs[0]?.data?.length === 1) {
      let copy = { ...props.graphData }
      copy.graphs[0].data[0] = { x: copy?.graphs[0]?.data[0]?.x + "-0", y: copy?.graphs[0]?.data[0]?.y }
      setGraphsData(copy)
    } else {
      setGraphsData({ ...props.graphData })
    }
  }, [props.graphData])

  var graphSectionContent = null
  if (fetchedGraphData) {
    if (graphData && Object.keys(graphData).length && graphData?.graphs[0]?.data?.length > 0) {
      graphSectionContent = (
        <div className="w-100 h-100">
          {/* <ResponsiveLine
            data={graphData?.graphs}
            margin={{ top: 30, right: 20, bottom: 30, left: 0 }}
            colors={[graphData?.graphs[0]?.colorCode]}
            xScale={{ type: "point" }}
            width={
              graphData.graphs[0].data.length <= 6
                ? window.innerWidth / 4
                : (graphData.graphs[0].data.length / 10 < 1 ? 1 : graphData.graphs[0].data.length / 10) * (window.innerWidth / 3.5)
            }
            yScale={{
              type: "linear",
              min: 0,
              max: 100,
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Time",
              legendOffset: 36,
              legendPosition: "middle",
              format: (v) => {
                let x = v.split("-")[0]
                return x
              },
            }}
            axisLeft={null}
            pointSize={8}
            pointBorderWidth={2}
            pointColor={"white"}
            pointBorderColor={"#D07530"}
            useMesh={true}
            enableSlices="x"
            sliceTooltip={({ slice }) => {
              return <ToolTipForBarGraph slice={slice} type={`line-graph`} bgColor={`#D07530`} />
            }}
            layers={["grid", "markers", "axes", "lines", "areas", "slices", "points", "legends"]} // Define the layers
            theme={THEME}
          /> */}
        </div>
      )
    } else {
      graphSectionContent = (
        <div className="w-100 d-flex h-100 justify-center align-items-center">
          <p className="text-white mx-auto">Data not available</p>
        </div>
      )
    }
  }

  return (
    <div className="d-flex w-100 h-32vh">
      {graphData ? (
        <>
          <div className="w-57px">
            {/* {Object.keys(graphData).length && graphData?.graphs[0]?.data?.length > 0 && (
              <ResponsiveLine
                data={graphData?.graphs}
                margin={{ top: 30, right: 10, bottom: 30, left: 50 }}
                colors={[graphData?.graphs[0]?.colorCode]}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: 0,
                  max: 100,
                  stacked: true,
                  reverse: false,
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Engagement",
                  legendOffset: -40,
                  legendPosition: "middle",
                }}
                layers={["axes"]} // Define the layers
                theme={THEME}
              />
            )} */}
          </div>
          <div className="d-flex overflow-x-scroll w-100 h-100">
            {!fetchedGraphData && (
              <div className="w-100 d-flex h-100 justify-center align-items-center">
                <Loader class={`text-white mx-auto`} />
              </div>
            )}
            {graphSectionContent}
          </div>
        </>
      ) : (
        <div className="w-100 d-flex h-100 justify-center align-items-center">
          <Loader class={`text-white mx-auto`} />
        </div>
      )}
    </div>
  )
}

export default ClassHourWisechart
