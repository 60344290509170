import React, { useEffect, useState } from "react"
import Loader from "../custom/Loader"

// import { ResponsiveBar } from "@nivo/bar"
import ToolTipForBarGraph from "./barGraphToolTip"

import { THEME, BAR_COLOR_CODES } from "../insights/constants"

const ScatterCircle = ({ bars }) => {
  return (
    <>
      {bars.map((bar, index) => (
        <svg key={`bar_${index}`}>
          <g>
            <rect x={bar.x} y={bar.y - 30} width={bar.width} height="25" fill={bar.data.data.valueColor}></rect>
            <title>
              {bar.data.data.name}: {bar.data.data.value || 0}%
            </title>
            <text //This x value calculation is for aligining the % text center - (100% or 90.34% or 20%)
              x={
                ((bar.data.data.value || 0) + "").length === 1
                  ? bar.width / 2 + bar.x - 7
                  : ((bar.data.data.value || 0) + "").length > 3
                  ? bar.width / 2 + bar.x - 20
                  : bar.width / 2 + bar.x - 13
              }
              y={bar.y - 13}
              fontSize="12"
              fill="#E0E2E5"
            >
              {bar.data.data.value || 0}%
            </text>
          </g>
        </svg>
      ))}
    </>
  )
}

const SingleColorBarGraph = (props) => {
  const [graphData, setGraphData] = useState({})
  const [fetchedGraphData, setFetchedGraphData] = useState(true)

  useEffect(() => {
    if (props.graphData?.graphs?.length > 0) {
      let copy = { ...props.graphData }

      const dataWithColors = copy.graphs.map((item, index) => {
        const color = BAR_COLOR_CODES[index % BAR_COLOR_CODES.length]

        return { ...item, valueColor: color, id: `${item.name}-${index}` }
      })
      copy.graphs = [...dataWithColors]
      setGraphData(copy)
    }
  }, [props.graphData])

  var graphSectionContent = null
  if (fetchedGraphData) {
    if (graphData?.graphs?.length > 0) {
      graphSectionContent = ""
      // graphSectionContent = (
      //   <ResponsiveBar
      //     data={graphData?.graphs}
      //     keys={graphData?.labels}
      //     indexBy="id"
      //     margin={{ top: 20, right: 10, bottom: 30, left: 0 }}
      //     padding={0.4}
      //     maxValue={120}
      //     barRation={0.5}
      //     width={
      //       // This width  calculation is for making responisve width for the graphs based on the data
      //       graphData?.graphs?.length < 5
      //         ? window.innerWidth / 4
      //         : (graphData?.graphs?.length / 5 < 1 ? 1 : graphData?.graphs?.length / 5) *
      //           (window.innerWidth < 1200 ? window.innerWidth / 3 : window.innerWidth / 3.5)
      //     }
      //     minValue={0}
      //     colors={(bar) => bar.data.valueColor}
      //     label={() => ""}
      //     axisRight={null}
      //     axisBottom={{
      //       format: (v) => {
      //         let name = v.slice(0, -2)
      //         return name.length > 10 ? name.substring(0, 10) + "..." : name
      //       },
      //       tickSize: 0,
      //       tickPadding: 10,
      //       tickRotation: 0,
      //     }}
      //     axisLeft={null}
      //     ariaLabel={props.title}
      //     theme={THEME}
      //     layers={["grid", "axes", "bars", "legends", ScatterCircle]}
      //     tooltip={(bar) => <ToolTipForBarGraph bar={bar} type={`single-color`} />}
      //   />
      // )
    } else {
      graphSectionContent = (
        <div className="w-100 d-flex h-100 justify-center align-items-center">
          <p className="text-white mx-auto">Data not available</p>
        </div>
      )
    }
  }

  return (
    <div className="d-flex w-100  bg-19253B h-100 br-9px overflow-hidden">
      <div className="d-flex w-100 h-32vh">
        {props.graphData ? (
          <>
            {" "}
            <div className="w-57px">
              {/* {graphData?.graphs?.length > 0 && (
                <ResponsiveBar
                  data={graphData?.graphs}
                  keys={graphData?.labels}
                  indexBy="id"
                  margin={{ top: 20, right: 10, bottom: 30, left: 50 }}
                  padding={0.4}
                  maxValue={120}
                  width={50}
                  minValue={0}
                  axisRight={null}
                  axisBottom={null}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: graphData?.yAxisLegend,
                    legendPosition: "middle",
                    legendOffset: -40,
                    tickValues: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                  }}
                  theme={THEME}
                  layers={["axes"]}
                />
              )} */}
            </div>
            <div className="d-flex overflow-x-scroll w-100 h-100">
              {!fetchedGraphData && (
                <div className="w-100 d-flex h-100 justify-center align-items-center">
                  <Loader class={`text-white mx-auto`} />
                </div>
              )}
              {graphSectionContent}
            </div>
          </>
        ) : (
          <div className="w-100 d-flex h-100 justify-center align-items-center">
            <Loader class={`text-white mx-auto`} />
          </div>
        )}
      </div>
    </div>
  )
}

export default SingleColorBarGraph
