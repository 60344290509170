import React, { useContext, useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import CropImage from "../../../../../crop-image/crop-image"
import Loader from "../../../../../custom/Loader"
import ProfileUpdateProgress from "../../../../../ProfileProgressBar"
import SEO from "../../../../../seo"
import HeaderTab from "../../../../../trainer/HeaderTab"
import warningIcon from "../../../../../../images/svgs/warning_icon.svg"
import Style from "../EducatorProfileSetup/style.module.scss"
import fb from "../../../../../../images/svgs/fbicon.svg"
import linkedin from "../../../../../../images/svgs/linkedinicon.svg"
import twitter from "../../../../../../images/svgs/twittericon.svg"
import Camera from "../../../../../../images/svgs/camera_logo.svg"
import EducatorProfilePic from "../../../../../../images/svgs/trainer-profile-pic.svg"
import CustomInputField from "../../../../../custom/CustomInputFiled"
import { validateEmail, validateMobileNumber } from "../../../../../utilities/utils"
import { validateSocialMediaLink } from "../../../../../utilities/validateSocialMediaLink"
import Image from "../../../../../custom/Image"
import { getRequest, putRequest } from "../../../../../../services/rest_service"
import Json from "../../../../../utilities/json"
import { GlobalContext } from "../../../../../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../../../../../stores/global_store/GlobalActions"
import { imageUrlToBase64 } from "../../../../../utilities/convertBase64ToFile"
import { b64toBlob } from "../../../../../utilities/b64toBlob"
import CustomTextArea from "../../../../../custom/CustomTextArea"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { parsePhoneNumber } from "react-phone-number-input"
import { isValidPhoneNumber } from "react-phone-number-input"

const UserProfileSetup = (location) => {
  const [notificationData, dispatch] = useContext(GlobalContext)
  const [modalShow, setModalShow] = useState(false)
  const [cropData, setCropData] = useState("#")
  const [fileName, setFileName] = useState(null)
  const bottomDiv = useRef(null)
  const [userDetail, setUserDetail] = useState({})
  const [isDisabled, setIsDisabled] = useState(false)
  const [updatedFields, setUpdatedFields] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [marginSocial, setMarginSocial] = useState(false)
  const [userRole, setUserRole] = useState("")
  const [phoneNumberInput, setPhoneNumberInput] = useState({})
  let userId = location.id
  let company_id = location.compnay_id
  const pathSegments = location.uri.split("/")

  // Find the index of the desired ID parameter
  const userRoleId = pathSegments[pathSegments.length - 2]

  const [socialLinks, setSocialLinks] = useState([
    { name: "facebook", icon: fb, color: "#4261A2", show: false, key: "facebook" },
    { name: "linkedin", icon: linkedin, color: "#007BB5", show: false, key: "linkedin" },
    { name: "twitter", icon: twitter, color: "#1DA1F2", show: false, key: "twitter" },
  ])
  const [socialMedia, setSocialMedia] = useState({})
  const [educatorDetailError, setEducatorDetailError] = useState({
    emailError: false,
    phoneError: false,
    emailErrorMsg: "",
    phoneErrorMsg: "",
  })
  const [socialLinksError, setSocialLinksError] = useState({
    facebook_url: false,
    facebook_url_error: "",
    linkedin_url: false,
    linkedin_url_error: "",
    twitter_url: false,
    twitter_url_error: "",
  })
  const [loader, setLoader] = useState(false)

  const getCropDataProps = (cropData, fileName) => {
    setCropData(cropData)
    setFileName(fileName)
    setUserDetail({
      ...user_detail,
      userprofile: {
        ...user_detail.userprofile,
        progress_percentage: calculateProgressPercentage({
          ...user_detail,
          profile_pic: cropData,
        }),
      },
    })
  }
  const calculateProgressPercentage = (data) => {
    const { first_name, profile_pic, email, phone_number } = data
    const totalFields = 8 // total number of fields to be considered
    let completedFields = 1

    if (first_name && first_name?.length) {
      completedFields++
    }
    if (data.userprofile.designation && data.userprofile.designation?.length) {
      completedFields++
    }
    if (data.userprofile.bio && data.userprofile.bio?.length) {
      completedFields++
    }
    if (profile_pic) {
      completedFields++
    }

    if (email && email?.length) {
      completedFields++
    }
    if (phone_number) {
      completedFields++
    }

    if (data.userprofile.social_media_profiles && Object.keys(data.userprofile.social_media_profiles).length > 0) {
      let flag = 0
      for (let key in data.userprofile.social_media_profiles) {
        if (data.userprofile.social_media_profiles.hasOwnProperty(key) && data.userprofile.social_media_profiles[key] !== "") {
          flag = flag + 1
        }
      }
      {
        flag && completedFields++
      }
    }

    const progressPercentage = (completedFields / totalFields) * 100
    return progressPercentage
  }
  let user_detail = { ...userDetail }

  const getSchoolTeams = async () => {
    let res = await getRequest(`/role-management/school-teams/?company=${company_id}`)
    if (res.success) {
      let userData = res.data.results.filter((data) => {
        if (data.id == userRoleId) {
          return data
        }
      })
      setUserRole(userData[0].name)
    }
  }

  const handleEmailValidation = (value) => {
    return validateEmail(value)
  }
  const handlePhoneInput = (value) => {
    if (value?.length) {
      setUserDetail({
        ...userDetail,
        country_code: parsePhoneNumber(value)?.countryCallingCode,
        phone_number: parsePhoneNumber(value)?.nationalNumber,
        userprofile: {
          ...userDetail.userprofile,
          progress_percentage: calculateProgressPercentage({
            ...user_detail,
            phone_number: value,
          }),
        },
      })
      if (isValidPhoneNumber(value)) {
        setEducatorDetailError({ ...educatorDetailError, phoneError: false, phoneErrorMsg: "" })
      } else {
        setEducatorDetailError({ ...educatorDetailError, phoneError: true, phoneErrorMsg: "Please Enter Correct Phone Number!" })
      }
    }
  }

  const handleChange = (event) => {
    console.log(event, "event target")
    if (event.target) {
      if (event.target.name === "phone_number") {
        setUserDetail({ ...userDetail, phone_number: event.target.value })
        if (validateMobileNumber(event.target.value)) {
          setEducatorDetailError({ ...educatorDetailError, phoneError: false, phoneErrorMsg: "" })
        } else {
          setEducatorDetailError({ ...educatorDetailError, phoneError: true, phoneErrorMsg: "Please Enter Correct phone_number Number!" })
        }
      } else if (event.target?.name?.includes("_url")) {
        let isValidLink = validateSocialMediaLink(event.target.name, event.target.value)

        if (isValidLink) {
          setSocialLinksError({ ...socialLinksError, [event.target.name]: false, [event.target.name + "_error"]: "" })
          setSocialMedia({ ...socialMedia, [event.target.name.split("_url")[0]]: event.target.value })
          user_detail.userprofile.social_media_profiles = {
            ...user_detail.userprofile.social_media_profiles,
            [event.target.name.split("_url")[0]]: event.target.value,
          }
          setUserDetail({
            ...user_detail,
            userprofile: {
              ...user_detail.userprofile,
              progress_percentage: calculateProgressPercentage({
                ...user_detail,
                userprofile: {
                  ...user_detail.userprofile,
                  social_media_profiles: { ...user_detail.userprofile.social_media_profiles },
                },
              }),
            },
          })
        } else {
          setSocialLinksError({ ...socialLinksError, [event.target.name]: true, [event.target.name + "_error"]: "Link not valid" })
        }
      } else if (event.target.name === "designation") {
        setUserDetail({
          ...user_detail,
          userprofile: {
            ...user_detail.userprofile,
            designation: event.target.value,
            progress_percentage: calculateProgressPercentage({
              ...user_detail,
              userprofile: {
                ...user_detail.userprofile,
                [event.target?.name]: event.target?.value,
              },
            }),
          },
        })
      } else if (event.target.name === "bio") {
        setUserDetail({
          ...user_detail,
          userprofile: {
            ...user_detail.userprofile,
            bio: event.target.value,
            progress_percentage: calculateProgressPercentage({
              ...user_detail,
              userprofile: {
                ...user_detail.userprofile,
                bio: event.target?.value,
              },
            }),
          },
        })
      } else {
        user_detail[event.target.name] = event.target.value
        setUserDetail({
          ...user_detail,
          userprofile: {
            ...user_detail.userprofile,
            progress_percentage: calculateProgressPercentage({
              ...user_detail,
              [event.target?.name]: event.target?.value,
            }),
          },
        })
      }
    }
  }
  const updateSocialLinks = (index, toggle) => {
    let social_links = [...socialLinks]
    social_links[index].show = toggle
    setSocialLinks(social_links)
  }

  const getEducatorDetails = async (educator_id) => {
    setLoader(true)
    let res = await getRequest(`/user/user-profile/${educator_id}/`)
    if (res.success) {
      setUserDetail(res.data)
      if (Object.keys(res.data?.userprofile?.social_media_profiles ? res.data?.userprofile?.social_media_profiles : {})?.length) {
        let updatedSocialLinks = [...socialLinks]
        Object.keys(res.data?.userprofile?.social_media_profiles).map((media) => {
          updatedSocialLinks = updatedSocialLinks.map((link) => {
            if (link.name === media && res.data.userprofile?.social_media_profiles[media].length) {
              return { ...link, show: true }
            } else {
              return link
            }
          })
        })
        setSocialLinks(updatedSocialLinks)
      }
      setLoader(false)
    } else {
      setLoader(false)
    }
  }

  useEffect(() => {
    getSchoolTeams()
    getEducatorDetails(userId)
  }, [])
  useEffect(() => {
    if (cropData !== userDetail.profile_pic && cropData !== "#") {
      const avatarChanged = updatedFields.indexOf("profile_pic") === -1
      setUpdatedFields(avatarChanged ? [...updatedFields, "profile_pic"] : updatedFields)
      setUserDetail({
        ...user_detail,
        userprofile: {
          ...user_detail.userprofile,
          progress_percentage: calculateProgressPercentage({
            ...user_detail,
            profile_pic: "uploaded",
          }),
        },
        profile_pic: cropData,
      })
    }
  }, [cropData])

  useEffect(() => {
    let x = false
    socialLinks.forEach((data) => {
      if (data.show) {
        x = true
      }
    })
    setMarginSocial(x)
  }, [socialLinks])
  const updateUserDetails = async (user_id, userDetail) => {
    setLoader(true)
    setIsDisabled(true)

    let res = await putRequest(`/user/user-profile/${user_id}/`, userDetail)
    if (res.success) {
      setIsDisabled(false)
      dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "success", title: `${userRole} profile updated Sucesssfully!` },
      })
      setUpdatedFields([])
      window.history.back()
    } else {
      dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "error", title: res.data.msg ? res.data.msg : "Something went wrong!" },
      })
      setLoader(false)
      setIsDisabled(false)
    }
  }

  const handleSubmit = async (event) => {
    let socialMediaFlag = socialLinksError.facebook_url || socialLinksError.twitter_url || socialLinksError.linkedin_url
    if (
      educatorDetailError.nameError ||
      educatorDetailError.addressError ||
      educatorDetailError.phoneError ||
      educatorDetailError.descriptionError ||
      educatorDetailError.emailError ||
      educatorDetailError.websiteError
    ) {
      event.preventDefault()
      return // Exit the function without making an API call
    } else {
      if (!socialMediaFlag) {
        event.preventDefault()
        setLoader(true)
        setSocialLinksError({
          facebook_url: false,
          facebook_url_error: "",
          linkedin_url: false,
          linkedin_url_error: "",
          twitter_url: false,
          twitter_url_error: "",
        })
        let formData = new FormData()
        for (const key in userDetail) {
          if (key === "userprofile") {
            formData.append(key, JSON.stringify(userDetail[key]))
          } else if (key === "profile_pic") {
            if (cropData !== "#" && cropData !== null) {
              console.log(cropData, "cropdata")
              formData.append(key, userDetail[key])
            }
          } else if (key === "country_code" && !userDetail["country_code"] && userDetail["phone_number"]) {
            formData.append("country_code", "91")
          } else {
            formData.append(key, userDetail[key])
          }
        }

        updateUserDetails(userId, formData)
      }
    }
    event.preventDefault()
    // setIsDisabled(false)
  }

  return (
    <div>
      <SEO title="User Profile Setup" />
      <Col xs="12" sm="12" md="12" lg="12" className="pt-5 d-flex flex-column align-items-start">
        <HeaderTab heading={`${userDetail.first_name}'s Profile`} active={activeTab} setActive={setActiveTab} list={["Personal Details"]} back={false} />
        <Col lg="12" md="12" sm="12" xs="12" className="px-0 pt-2 mx-0 mb-3 br-9px bg-212C42 inner-element-box-shadow">
          <form className="mb-0 d-flex flex-column" id="educatorform" onSubmit={handleSubmit}>
            <div id="educator-box" className={`${Style.overflow} vh-100 d-flex mt-2 mb-3 px-3`}>
              <CropImage
                show={modalShow}
                onHide={() => setModalShow(false)}
                getCropDataProps={getCropDataProps}
                fileName={fileName}
                avatar={cropData !== "#" ? cropData : null}
              />
              <Col lg="2" md="2" sm="3" xs="3" className={`float-left px-0 pointer `}>
                {cropData !== "#" ? (
                  <ProfileUpdateProgress
                    percentage={userDetail?.userprofile?.progress_percentage}
                    image={cropData}
                    footerImgFlag={false}
                    handleClick={(event) => {
                      event.preventDefault()
                      setModalShow(true)
                    }}
                    mainDivStyle="w-8rem h-8rem"
                    imgStyle={Style.imgStyle}
                    strokeWidth={3}
                    imgName="User Logo"
                  />
                ) : (
                  <ProfileUpdateProgress
                    percentage={userDetail?.userprofile?.progress_percentage}
                    image={userDetail.profile_pic || EducatorProfilePic}
                    footerImg={Camera}
                    footerImgFlag={userDetail.profile_pic ? false : true}
                    handleClick={(event) => {
                      event.preventDefault()
                      setModalShow(true)
                    }}
                    mainDivStyle="w-8rem h-8rem"
                    imgStyle={Style.imgStyle}
                    footerImgStyle={Style.progressBarFooterImgStyle}
                    strokeWidth={3}
                    imgName="Default User Logo"
                    footerImgName="Camera Logo"
                  />
                )}
              </Col>

              <Col lg="10" md="10" sm="9" xs="9" className="float-left">
                <Row className="pl-2">
                  <Col lg="6" md="6" sm="12" xs="12" className="px-1">
                    <CustomInputField type="text" name="first_name" onChange={handleChange} label={"Full Name"} value={userDetail.first_name} required />
                  </Col>

                  <Col lg="6" md="6" sm="12" xs="12" className="px-1">
                    <CustomInputField
                      type="text"
                      name="designation"
                      onChange={handleChange}
                      label={"Designation"}
                      value={userDetail?.userprofile?.designation}
                      required
                    />
                  </Col>
                  <Col lg="6" md="6" sm="12" xs="12" className="px-1 opacity-60">
                    <CustomInputField type="text" name="role" label={"Role"} value={userRole} disabled required />
                  </Col>
                  <Col lg="6" md="6" sm="12" xs="12" className="px-1 poppins_font opacity-60">
                    <CustomInputField
                      type="text"
                      name="email"
                      customValidation={handleEmailValidation}
                      label={"Email"}
                      value={userDetail.email}
                      required
                      disabled
                    />
                  </Col>

                  <Col lg="6" md="6" sm="12" xs="12" className="px-1 poppins_font">
                    <div className="bg-303C54 br-9px p-2 fs-14px mb-2 border-on-hover">
                      <span className="pl-2">Phone Number</span>
                      <PhoneInput
                        className={` w-100 border-on-hover  ${Style.phoneInputfieldStyle} px-2 py-1 `}
                        name="phone_number"
                        defaultCountry="IN"
                        type="text"
                        value={`${userDetail.country_code ? "+" + userDetail.country_code : "+91"} ${userDetail.phone_number}`}
                        onChange={handlePhoneInput}
                        placeholder={`Enter phone Number`}
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="6" sm="12" xs="12" className="px-1"></Col>
                  <Col lg="6" md="6" sm="12" xs="12" className="px-1">
                    <CustomTextArea name="bio" label="Bio" onChange={handleChange} defaultValue={userDetail.userprofile?.bio} />
                  </Col>
                </Row>
                <div className={`d-flex w-100 flex-column mt-3`}>
                  <div className={`fs-16px mb-1 icon-color`}>Connect to Social media</div>
                  <div className={`text-center d-flex flex-wrap`}>
                    {socialLinks.map((media, index) =>
                      media.show === true ? (
                        <div key={index} className="px-1 w-100 ">
                          <div
                            className={`${
                              !socialLinksError[`${media.name}_url`] ? `mb-2 ${Style.hover_border} border_on_hover` : "mb-1 error-input"
                            } px-1 inner-element-box-shadow br-9px bg-303C54 `}
                          >
                            <div className="p-2 d-flex w-100 border border-1 border-transparent bg-transparent br-0">
                              <div
                                className={`mr-2 p-1 circle w-40px h-40px`}
                                onClick={(e) => {
                                  updateSocialLinks(index, false)
                                }}
                                style={{
                                  backgroundColor: media.color,
                                }}
                              >
                                <Image className={`mb-0 pointer`} src={media.icon} alt={`${media.name}_icon`} />
                              </div>
                              <div className={`ml-2 flex-grow-1`}>
                                <input
                                  name={`${media.key}_url`}
                                  placeholder={`Add your ${media.name} link`}
                                  onChange={handleChange}
                                  type="text"
                                  defaultValue={userDetail?.userprofile?.social_media_profiles?.[`${media.key}`]}
                                  className={`mt-2 turient-header-text-color w-100 fs-16px bg-transparent border-transparent`}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                          {!socialLinksError[`${media.name}_url`] ? null : (
                            <Col xs="12" sm="12" md="12" lg="12" className={`mb-3 text-left px-0`}>
                              <div className="d-flex align-items-baseline px-1 py-0 br-23px">
                                <Image className="w-1rem mr-1 " src={warningIcon} alt="warninig" />
                                <p className="mb-0 fs-14px social-link-error">{socialLinksError[`${media.name}_url_error`]}</p>
                              </div>
                            </Col>
                          )}
                        </div>
                      ) : (
                        <div
                          key={media.name}
                          onClick={(e) => {
                            updateSocialLinks(index, true)
                          }}
                          className={`p-1 ${marginSocial ? "ml-3" : index !== 0 ? "ml-3" : ""} mb-2 circle w-40px h-40px`}
                          style={{
                            backgroundColor: media.color,
                          }}
                        >
                          <Image className={`mb-0 pointer`} src={media.icon} alt={`${media.name}_icon`} />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="w-100 h-10px" ref={bottomDiv}></div>
              </Col>
            </div>
            <div className={`${Style.btnDiv} d-flex px-3 py-2`}>
              <button
                type={"submit"}
                disabled={isDisabled}
                className="border_on_hover box-shadow-00000029 br-23px turient-header-text-color fs-14px mr-2 bg-586886 border border-1 border-586886 h-2rem px-30px "
              >
                Done
              </button>
              {loader && <Loader class="ml-3 mr-1" />}
            </div>
          </form>
        </Col>
      </Col>
    </div>
  )
}

export default UserProfileSetup
