import React, { useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import { LazyLoadImage } from "react-lazy-load-image-component"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

const CreateImage = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [image, setImage] = useState({})

  // console.log(location,course, "location.dilsjdio")
  // const { state = {} } = location

  function handleChange(event) {
    event.preventDefault()
    image[event.target.name] = event.target.value
  }

  function handleselectedFile(event) {
    event.preventDefault()
    image[event.target.name] = event.target.files[0]
  }

  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in image) {
      formData.append(k, image[k])
    }
    formData.append("add_to_template", true)

    if (image.id != undefined) {
    } else {
      var image_id = image.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/images/create/", formData, { headers: login_credentials })
        .then((res) => {
          navigate("/app/files")
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  return (
    <div>
      <SEO title="File Creation" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>Add image</h4>
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col>
              <div>
                <Row>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <Form.Label htmlFor="name">Name</Form.Label>
                      <input type="text" name="name" defaultValue={image.name} onChange={handleChange} id="name" placeholder="Enter image name" required />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <Form.Label htmlFor="sequence">sequence</Form.Label>
                      <input
                        type="number"
                        name="sequence"
                        defaultValue={image.sequence}
                        onChange={handleChange}
                        id="sequence"
                        placeholder="Enter image sequence"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <Form.Label htmlFor="avatar">Avatar</Form.Label>
                      <Form.File
                        name="avatar"
                        className="form-control-file-avatar"
                        onChange={handleselectedFile}
                        required={image.id == undefined ? true : false}
                      />
                      {(() => {
                        if (image.avatar && image.avatar.length > 0) {
                          return <LazyLoadImage src={image.avatar} width="200" alt={image.name} />
                        }
                      })()}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2">
                    <FormGroup className="m-0">
                      <div>File Points</div>
                      <input
                        style={{ background: "inherit", color: "inherit", fontSize: "12px" }}
                        className="blue-bg video-modal p-0"
                        type="number"
                        name="points"
                        defaultValue={image.points}
                        onChange={handleChange}
                        id="points"
                        placeholder="Enter File points"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Col>
            <Button type="submit" color="warning">
              Save
            </Button>{" "}
          </Form>
          <Button onClick={() => navigate(`/app/files`)} variant="danger">
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CreateImage
