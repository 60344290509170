import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import StylesA from "./classroom-left-nav.module.scss"
import Image from "../../components/image"
import { isBrowser } from "../../services/developer_mode"

import Button from "react-bootstrap/Button"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import PollIcon from "../../images/svgs/classroom/polls-nav-icon.svg"
import ResourcesIcon from "../../images/svgs/classroom/resource-nav-icon.svg"
import LessonPlanIcon from "../../images/svgs/classroom/lessonplan-nav-icon.svg"
import BreakoutSessionIcon from "../../images/svgs/classroom/breakout-session_nav-icon.svg"
import BreakoutGroupsIcon from "../../images/svgs/classroom/breakout-groups-nav-icon.svg"
import ParticipantsIcon from "../../images/svgs/classroom/participants-nav-icon.svg"
import DiscussionIcon from "../../images/svgs/classroom/discussionnav.svg"
import TeaCup from "../../images/svgs/classroom/tea_cup.svg"
import ReportIssueIcon from "../../images/report_an_issue.svg"

import ClassroomResourceSection from "./classroom-resource-section"
import ClassroomParticipantsSection from "./classroom-participants-section"

const LeftNavItems = [
  "LessonSessionPlan",
  "Resource",
  "BreakoutGroup",
  "BreakoutSession",
  "Poll",
  "DiscussionBoard",
  "Participants",
  "Break",
  "Report An Issue",
]
const LeftNavTooltips = [
  "Class Content",
  "Resources",
  "Breakout Groups",
  "Breakout Sessions",
  "Assessment Toolkit",
  "Live Discussion Board",
  "Participants",
  "Break",
  "Report An Issue",
]
const LeftNavIcons = [
  LessonPlanIcon,
  ResourcesIcon,
  BreakoutGroupsIcon,
  BreakoutSessionIcon,
  PollIcon,
  DiscussionIcon,
  ParticipantsIcon,
  TeaCup,
  ReportIssueIcon,
]

const ClassRoomLeftNav = (props, location, data) => {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [showLessonSessionNavDiv, setShowLessonSessionNavDiv] = useState(false)
  const [showParticipantDetail, setShowParticipantDetail] = useState(false)
  const [activeParticipant, setActiveParticipant] = useState(null)

  useEffect(() => {
    if (showParticipantDetail && activeParticipant) {
      window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}#type=participant/${activeParticipant.id}`)
      props.setActiveSection("Participants")
      props.updateParticipantId(activeParticipant)
    }
  }, [activeParticipant, showParticipantDetail])

  useEffect(() => {
    setShowLessonSessionNavDiv(props.showLessonSessionNavDiv)
  }, [props.showLessonSessionNavDiv])

  useEffect(() => {
    if (localStorage.getItem("BreakoutSession")) {
      handleLeftNavClick("BreakoutSession")
      localStorage.removeItem("BreakoutSession")
    }
    if (localStorage.getItem("BreakoutGroup")) {
      handleLeftNavClick("BreakoutGroup")
      localStorage.removeItem("BreakoutGroup")
    }
  }, [])

  let page_uri = isBrowser() ? "/" + window.location.pathname.substr(1) : "false"
  if (page_uri === "/logout" || page_uri === "logout" || page_uri === "logout/" || page_uri === "/logout/") {
    page_uri = "/"
  }
  function displayNavTabs() {
    return LeftNavItems.map((item, index) => {
      let tab_icon = LeftNavIcons[index]
      return (
        <React.Fragment key={index.toString()}>
          <div
            key={index.toString()}
            style={{
              display: props.type === "class-dashboard" && (item === "DiscussionBoard" || item === "Break") ? "none" : "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => handleLeftNavClick(item)}
            className={`py-2 pointer ${props.currentNavTab === item ? StylesA.left_nav_tab_active : StylesA.left_nav_tab_inactive} text-center ${
              StylesA.left_nav_button
            } my-2`}
          >
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip placement="right" className="classroom-tooltip" id={`"tooltip-${item}"`}>
                  {LeftNavTooltips[index]}
                </Tooltip>
              }
            >
              <img src={tab_icon} alt={item} className={`my-auto`} style={{ objectFit: `contain` }} />
            </OverlayTrigger>
          </div>
        </React.Fragment>
      )
    })
  }
  const updateLessonSessionUrl = (url_type) => {
    props.setActiveSection(url_type)
    if (url_type === "LessonPlan") {
      window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}#type=lesson-plan`)
    } else if (url_type === "SessionPlan") {
      window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}#type=session-plan`)
    }
    setShowLessonSessionNavDiv(false)
  }

  function handleLeftNavClick(tab_name) {
    if (props.currentNavTab === tab_name) {
      // if (tab_name === 'LessonSessionPlan' && props.type !== 'classroom') {
      //   setShowLessonSessionNavDiv(!showLessonSessionNavDiv)
      // }
      if (tab_name === "Resource" || tab_name === "Poll" || tab_name === "LessonSessionPlan" || tab_name === "Participants") {
        props.setLeftNavOpen(!props.leftNavOpen)
        if (props.leftNavOpen) {
          props.setHomepageActiveResource("")
        }
        if (tab_name === "Resource" || tab_name === "Poll") {
          if (props.type !== "classroom") {
            props.setCurrentNavTab("")
          }
        } else if (
          tab_name === "LessonSessionPlan" &&
          props.activeSection !== "LessonPlan" &&
          props.activeSection !== "SessionPlan" &&
          props.activeSection !== "LessonBoard"
        ) {
          props.setCurrentNavTab("")
        }
        // if (tab_name !== 'LessonSessionPlan') {
        //   props.setActiveSection(tab_name)
        // }
      }
      if (tab_name === "BreakoutGroup") {
        props.setLeftNavOpen(false)
        setShowLessonSessionNavDiv(false)
        props.setActiveSection(tab_name)
      }
      if (tab_name === "DiscussionBoard") {
        if (props.type !== "class-dashboard") {
          props.setLiveDiscussionModal(true)
        }
      }
      if (tab_name === "Break") {
        props.setLeftNavOpen(false)
        props.setActiveSection(tab_name)
      }
    } else {
      props.setCurrentNavTab(tab_name)
      if (tab_name === "BreakoutSession") {
        if (props.activeSection !== "DiscussionBoard") {
          if (props.breakoutSessionCount === 0) {
            props.setShowSessionModal(true)
          } else {
            window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}#type=breakout-session`)
          }
        }
      } else {
        window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}`)
      }
      if (tab_name === "Resource" || tab_name === "Poll" || tab_name === "LessonSessionPlan" || tab_name === "Participants") {
        props.setLeftNavOpen(true)
        /*if (props.type !== 'classroom') {
          setShowLessonSessionNavDiv(false)
        }*/
      } else {
        props.setLeftNavOpen(false)
        if (tab_name === "BreakoutGroup") {
          // setShowLessonSessionNavDiv(false)
          if (props.activeSection !== "DiscussionBoard") {
            props.setActiveSection(tab_name)
          }
        }
        if (tab_name === "DiscussionBoard") {
          if (props.type !== "class-dashboard") {
            props.setLiveDiscussionModal(true)
          }
        }
        /*if (tab_name === 'LessonSessionPlan') {
          if (props.type !== 'classroom') {
            setShowLessonSessionNavDiv(!showLessonSessionNavDiv)
          }else{
            props.setActiveSection(tab_name)
            window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}#type=lesson-board`)
          }
        } else {
          setShowLessonSessionNavDiv(false)
        }*/
        if (tab_name === "BreakoutSession" && props.breakoutSessionCount !== 0 && props.activeSection !== "DiscussionBoard") {
          props.setActiveSection(tab_name)
        }
      }
    }
  }
  const parentFetchRecentActivity = (value, type) => {
    if (props.fetchRecentActivity) {
      setTimeout(() => {
        props.fetchRecentActivity(value, type)
      }, 300)
    }
  }
  function showSessionLessionLeftNav() {
    return (
      <div
        style={{
          padding: "50px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <a
          onClick={() => props.setLeftNavOpen(false)}
          href={`/app/trainings/training-content/${props.training_id}`}
          target={props.type == "classroom" ? `courseContent${props.training_id}` : "_self"}
          style={{ color: "#E0E2E5", borderBottom: "1px solid white" }}
        >
          {" "}
          Class Content
        </a>
        {/* </span> */}
        {props.type === "classroom" ? (
          <Button
            disabled={props.type === "classroom" ? false : true}
            className={`${StylesA.lesson_plan_left_nav_link} py-0`}
            onClick={() => {
              window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}#type=lesson-board`)
              props.setActiveSection("LessonBoard")
              props.setLeftNavOpen(false)
            }}
            variant="none"
            style={{
              borderBottom: props.activeSection === "LessonBoard" ? "1px solid" : "none",
            }}
          >
            Lesson Board
          </Button>
        ) : (
          ""
        )}
      </div>
    )
  }

  /*function showFavouriteLeftNav() {
    return(
      <div style={{padding:'50px',textAlign:'center',display:'flex',alignItems:'center',flexDirection:'column'}} >
        {props.type === 'classroom' ?
          <Button disabled={props.type === 'classroom' ? false : true} className={`${StylesA.lesson_plan_left_nav_link} py-0`} onClick={()=> {props.setLiveDiscussionModal(true);props.setLeftNavOpen(false)}}
                  variant="none" style={{borderBottom: props.activeSection === 'LessonBoard' ? '1px solid' : 'none'}} >
            Live Discussion Board
          </Button> : ''
        }
      </div>
    )
  }*/

  function showContent() {
    if (props.currentNavTab === "Resource" || props.currentNavTab === "Poll") {
      return (
        <ClassroomResourceSection
          currentNavTab={props.currentNavTab}
          setCurrentNavTab={props.setCurrentNavTab}
          currentSelectedResourcesType={props.currentSelectedResourcesType}
          setHomepageActiveResource={props.setHomepageActiveResource}
          allResourcesChanged={props.allResourcesChanged}
          allResources={props.allResources}
          setHomepageAllResource={props.setHomepageAllResource}
          activeSection={props.activeSection}
          template_id={props.template_id}
          training_id={props.training_id}
          type={props.type}
          fetchRecentActivity={parentFetchRecentActivity}
          setPublishedResource={props.setPublishedResource}
          showAllResources={props.showAllResources}
          disableScroll={props.disableScroll}
          setShowPollModal={props.setShowPollModal}
          setShowPopquizModal={props.setShowPopquizModal}
          showPollModal={props.setShowPollModal}
          showPopQuizModal={props.setShowPopquizModal}
          trainingWsConnection={props.trainingWsConnection}
          setLeftNavOpen={props.setLeftNavOpen}
          leftNavOpen={props.leftNavOpen}
          activeActivityData={props.activeActivityData}
          setActiveActivityData={props.setActiveActivityData}
          setShowActiveActivity={props.setShowActiveActivity}
        />
      )
    } else if (props.currentNavTab === "LessonSessionPlan") {
      return showSessionLessionLeftNav()
    } else if (props.currentNavTab === "Participants") {
      return (
        // showFavouriteLeftNav()
        <ClassroomParticipantsSection
          training_id={props.training_id}
          updateParticipantView={(arg, value) => {
            setShowParticipantDetail(arg)
            setActiveParticipant(value)
          }}
          participantView={showParticipantDetail}
          trainingWsConnection={props.trainingWsConnection}
        />
        // <div className={`d-inline-flex w-100 align-item-center`}>
        //     <div className="pt-3 pb-5" id='partcipants' style={{backgroundColor:'#212C42',borderRadius:'8px',boxShadow:"0px 3px 12px #00000099",position:'relative',height:'500px',overflowY:'scroll'}}>
        //       {/* <div onClick={removeParticipants} style={{height:'25px',borderBottom:'1px solid #E0E2E5',position:"relative",right:'0px',top:'-9px',display:'block'}}>
        // 			  <img src={CancelWhite} alt={`Cancel`} className={`my-auto mr-2 float-right`} style={{ objectFit: `contain`, height: `18px` }}/>
        //       </div> */}
        //       <div style={{display:'grid',gridTemplateColumns:'auto auto auto',borderBottom:'1px solid #E0E2E5',position:'relative',top:'-8px'}}>
        //         <div id='participants' onClick={updateComponentColor}  style={{fontSize:'16px',padding:'5px 0px 10px 10px',backgroundColor:'#303C54',marginTop:'0px'}}>
        //           All Participants ({allParticipantsCount})
        //         </div>
        //         <div id='leadboard' onClick={updateComponentColor} style={{fontSize:'16px',padding:'5px 0px 10px 20px',backgroundColor:'transparent'}}>
        //          Leaderboard
        //         </div>
        //         <div id='chat' onClick={updateComponentColor} style={{fontSize:'16px',padding:'5px 0px 10px 20px',backgroundColor:'transparent'}}>
        //          Chats(47)
        //         </div>
        //       </div>
        //       <div style={{overflow:'scroll'}}>
        //         {participantsList?.length > 0 ?
        //         getOriginalData()
        //         :
        //         null
        //         }
        // 				{seeMoreVisible === true ? <div onClick={fetchNextParticipants} style={{textAlign:'center'}}><span style={{border:'1px solid #929BBD',paddingLeft:'10px',paddingRight:'10px',borderRadius:"10px"}}>See More</span></div> : ''}
        //       </div>
        //     </div>
        // </div>
      )
    } else {
      return null
    }
  }

  return (
    <div
      className="d-flex"
      style={{
        left: "0px",
        zIndex: 200,
        position: "absolute",
        height: "100vh",
        boxShadow: "0px 3px 6px #00000029",
      }}
    >
      <div
        style={{
          width: "60px",
          backgroundColor: "#121B2B",
          padding: "20px 0px 0px 0px",
        }}
      >
        <Link to="/">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              backgroundColor: "#303c54",
              borderRadius: "50%",
              height: "40px",
              margin: "0px 10px",
            }}
          >
            <Image className="mb-0" alt="Turient logo staging" src="logo.png" style={{ width: "30px", height: "30px" }} />
          </div>
        </Link>
        <div
          className="d-flex pt-3"
          style={{
            flexDirection: "column",
            height: "calc(100vh - 60px)",
            overflowY: "auto",
          }}
        >
          {displayNavTabs()}
        </div>
      </div>
      <div
        className={`${
          props.leftNavOpen ? `${StylesA.left_nav_opened}` : `${StylesA.left_nav_collapsed}`
        } mb-3 h-100 d-flex flex-column justify-content-between`}
        id={`mySidepanel`}
        style={{
          backgroundColor: `#212C42`,
          boxShadow: "0px 3px 12px #00000098",
          overflowX: "hidden",
        }}
      >
        {showContent()}
      </div>
    </div>
  )
}

export default ClassRoomLeftNav
