import React, { useEffect, useState } from "react"
import _ from "lodash"

import { navigate } from "gatsby"

import Col from "react-bootstrap/Col"
import TextArea from "../custom/TextArea"
import Btn from "../custom/Button"
import SuccessModal from "./_success-modal"
import DeleteModal from "./_delete-modal"
import NameField from "./_name_field"
import InstructionsField from "./_instructions_field"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { isBrowser } from "../../services/developer_mode"

import Bulb from "../../images/svgs/bulb.svg"

import { createMatching } from "./_matching_apis"
import SelectCategoryField from "./_select_category_field"
import RowColumnTable from "./_row_column_table"

import StylesA from "./matching.module.scss"
import StylesB from "../courses/course.module.scss"

const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

var FromTrainingOrClassCreation = {}

const CreateMatchTheFollowing = (props, location) => {
  const [mftErrors, setMftErrors] = useState({
    mft_name_error: false,
  })
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const [matchingData, setMatchingData] = useState({
    id: 1,
    mtf_type: "0",
    name: "",
    duration: "0",
    instructions: "",
    question_column_name: "Column1",
    mtfcolumn_set: [{ id: 1, name: "Column2" }],
    rows: [
      {
        question_data: {
          id: 1,
          question: "",
        },
        answers_list: [[{ id: 1, answer: "", qid: 92 }]],
      },
      {
        question_data: { id: 2, question: "" },
        answers_list: [[{ id: 1, answer: "", qid: 92 }]],
      },
      {
        question_data: { id: 3, question: "" },
        answers_list: [[{ id: 1, answer: "", qid: 92 }]],
      },
      {
        question_data: { id: 3, question: "" },
        answers_list: [[{ id: 1, answer: "", qid: 92 }]],
      },
    ],
  })

  //This code is for adding MTF into course-content/training-content/common-resources list
  useEffect(() => {
    if (isBrowser()) {
      if (window.opener !== null && window.name === "mtf12") {
        window.opener.postMessage({ instruction: "ready", type: "mtf" }, window.location.origin)
      }
      if (window.FromTrainingOrClassCreation) {
        FromTrainingOrClassCreation = window.FromTrainingOrClassCreation
      }
      window.addEventListener("message", handleCrossWindow, false)
      return () => {
        window.removeEventListener("message", handleCrossWindow)
      }
    }
  }, [])

  function handleCrossWindow(event) {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      window.FromTrainingOrClassCreation = FromTrainingOrClassCreation = { ...event.data.instruction }
    }
  }

  const createMatchTheFollowing = async () => {
    let formData = new FormData()
    formData.append("name", matchingData.name)
    formData.append("duration", matchingData.duration)
    formData.append("instructions", matchingData.instructions)
    formData.append("is_added_to_library", true)
    if (matchingData.mtf_type === "0") {
      formData.append("mtf_type", "0")
    } else {
      formData.append("mtf_type", "1")
    }

    //This condition is for adding MFT to a course-content resources list
    if (FromTrainingOrClassCreation && FromTrainingOrClassCreation.sequence) {
      for (let p in FromTrainingOrClassCreation) {
        formData.append(p, FromTrainingOrClassCreation[p])
      }
    }

    if (matchingData.name.length > 0) {
      const res = await createMatching(formData)
      if (res.success) {
        let data = res.data
        navigate(`/app/match-the-following/edit/${data.id}`, { state: { matching: data } }, { replace: true })
      }
    }
  }

  function showError(type) {
    let dummy = { ...mftErrors }
    if (type === "name") {
      dummy.mft_name_error = true
    }
    setMftErrors(dummy)
  }

  function handleChange(e, type) {
    let dummy = { ...matchingData }
    if (type === "name" || type === "duration" || type === "instructions") {
      dummy[type] = e.target.value
    }
    setMatchingData(dummy)
  }

  function handleAddRow() {
    let dummy = { ...matchingData }
    let new_row = {}
    new_row.question_data = { id: "1", question: "new Question" }
    new_row.answers_list = []
    for (let i = 0; i < dummy.columnNames.length - 1; i++) {
      new_row.answers_list.push([{ id: 1, answer: "" }])
    }
    dummy.rows.push(new_row)
    setMatchingData(dummy)
  }

  function handleDeleteRow(index) {
    let dummy = { ...matchingData }
    dummy.rows.splice(index, 1)
    setMatchingData(dummy)
  }

  function handleAddCol() {
    let dummy = { ...matchingData }
    dummy.columnNames.push({ id: "1", name: `column ${dummy.columnNames.length + 1}` })
    dummy.rows.map((row, index) => {
      dummy.rows[index].answers_list.push([{ id: 1, answer: "", qid: 92 }])
    })
    setMatchingData(dummy)
  }

  function handleAddMultiAnswer(row_index, col_index) {
    let dummy = { ...matchingData }
    dummy.rows[row_index].answers_list[col_index].push({ id: 1, answer: "", qid: 92 })
    setMatchingData(dummy)
  }

  function handleDeleteAnswerField(row_index, col_index, col_child_index) {
    let dummy = { ...matchingData }
    dummy.rows[row_index].answers_list[col_index].splice(col_child_index, 1)
    setMatchingData(dummy)
  }

  function handleChangeCategory(type) {
    if (type !== matchingData.mtf_type) {
      if (type === "0") {
        let dummy = { ...matchingData }
        dummy.mtf_type = "0"
        setMatchingData(dummy)
      } else {
        let dummy = { ...matchingData }
        dummy.mtf_type = "1"
        setMatchingData(dummy)
      }
    }
  }

  function showNameFields() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-2 mb-2 d-inline-flex align-baseline w-100">
        <NameField
          type={`create`}
          mftErrors={mftErrors}
          matchingData={matchingData}
          handleChange={handleChange}
          createMatchTheFollowing={createMatchTheFollowing}
        />
        <SelectCategoryField mtf_type={matchingData.mtf_type} handleChangeCategory={handleChangeCategory} />
        {/* <div className="col-3 pl-1 pr-0">
          <div
            className="d-inline-flex align-items-center w-100 px-2"
            style={{ backgroundColor: "#42506C", borderRadius: "6px", boxShadow: "0px 3px 6px #00000029" }}
          >
            <p className="mb-0 " style={{ fontSize: "18px", color: "#E0E2E570" }}>
              Assign Time
            </p>
            <input
              className={`${StylesA.time_filed} ml-2 pt-1 pt-0 d-flex align-items-center text-white shadow-none`}
              type="number"
              onChange={(e) => {
                e.preventDefault()
                handleChange(e, "duration")
              }}
              min={0}
              name="duration"
              id="duration"
              placeholder="00"
            />
            <p className="mb-0 ml-2" style={{ fontSize: "18px", color: "#E0E2E570" }}>
              Mins
            </p>
          </div>
        </div> */}
      </Col>
    )
  }

  function showInstructionsField() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-2" style={{ borderBottom: "1px dashed #E0E2E570" }}>
        <TextArea
          style={{ background: "#42506C" }}
          name="instructions"
          rows="2"
          label="Type Instructions here"
          onChange={handleChange}
          value={matchingData.instructions}
          required
        />
        <div className="d-inline-flex w-100 align-items-stretch mb-3">
          <img src={Bulb} alt={`idea`} className={`mr-2 mb-0`} style={{ objectFit: `contain`, height: `20px` }} />
          <p className="mb-0" style={{ fontSize: "14px", color: "#E0E2E560" }}>
            Rows in each column are matched against each other. Please ensure the correct match is in same row for both the columns. The options will be auto
            randomised for students
          </p>
        </div>
      </Col>
    )
  }

  return (
    <div>
      <div>
        <SEO title="Match-the-following Creation" />
        <Col xs="12" sm="12" md="12" lg="12" className="pt-0 pl-4 d-flex align-items-center" style={{ flexDirection: "column" }}>
          <Col xs="12" sm="12" md="12" lg="12" className={`${StylesB.course_creation_header} px-0`}>
            <p className="mb-0 py-2 px-3 font-weight-bold" id="coursename">
              {matchingData.name || " Create Match the following"}
            </p>
          </Col>
          <Col xs="12" sm="12" md="12" lg="12" className="px-0">
            <div className={`px-0 py-2 ${StylesA.create_matching_div}`}>
              {showNameFields()}
              <InstructionsField type="create" matchingData={matchingData} handleChange={handleChange} />
              <RowColumnTable
                type={`create`}
                matchingData={matchingData}
                handleChange={handleChange}
                handleAddCol={handleAddCol}
                handleAddRow={handleAddRow}
                handleDeleteRow={handleDeleteRow}
                handleAddMultiAnswer={handleAddMultiAnswer}
                setShowDeleteModal={setShowDeleteModal}
                handleDeleteAnswerField={handleDeleteAnswerField}
                showError={showError}
              />
            </div>
            <Col className={`px-0 pl-3 py-2 ${StylesA.create_matching_fotter}`}>
              <Btn
                type="small"
                onClick={(e) => {
                  e.preventDefault()
                  if (matchingData.name.length === 0) {
                    let dummy = { ...mftErrors }
                    dummy[`mft_name_error`] = true
                    setMftErrors(dummy)
                  }
                }}
                style={{ backgroundColor: "#586886" }}
              >
                I am done
              </Btn>
            </Col>
          </Col>
        </Col>
      </div>
      <DeleteModal type={"column"} showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} />
      <SuccessModal showSuccessModal={showSuccessModal} setShowSuccessModal={setShowSuccessModal} />
    </div>
  )
}

export default CreateMatchTheFollowing
