import React, { useEffect, useState, useCallback, useContext } from "react"
import { navigate } from "gatsby"
import debounce from "lodash/debounce"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Dropdown from "react-bootstrap/Dropdown"
import Collapse from "react-bootstrap/Collapse"
import Spinner from "react-bootstrap/Spinner"
import Modal from "react-bootstrap/Modal"
import InputFieldWrap from "../trainer/InputFieldWrap"

import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd"
import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_SELECTED_RESOURCE_TYPE, SET_REFETCH_RESOURCES } from "../../stores/sidebar_store/SidebarActions"

import SEO from "../../components/seo"
import authClient from "../../services/auth"
import Notification from "../custom/Notification"
import DeleteModal from "../custom/DeleteModal"
import { isBrowser } from "../../services/developer_mode"
import Image from "../custom/Image"

import ChevronLeft from "../../images/svgs/back-arrow.svg"
import ChevronRight from "../../images/svgs/forward-arrow.svg"
import Check from "../../images/svgs/icon-material-done.svg"
import ChevronCircleUp from "../../images/svgs/ChevronCircleUp.svg"
import ChevronCircleDown from "../../images/svgs/ChevronCircleDown.svg"
import Plus from "../../images/svgs/plus.svg"
import ExclamationTriangle from "../../images/svgs/exclamation-triangle.svg"
import EllipsisV from "../../images/svgs/threedots.svg"

import CourseImg from "../../images/svgs/courseImg.svg"
import DragHandleIcon from "../../images/svgs/drag-handle-gray.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import TrashCan from "../../images/svgs/trash-can-transparent.svg"
import CancelWhite from "../../images/svgs/close.svg"
import EditIcon from "../../images/svgs/edit.svg"
import deleteimg from "../../images/svgs/delete.svg"

import StylesA from "./checklist.module.scss"
import StylesB from "../courses/course.module.scss"
import Styles from "../../modularscss/styles.module.scss"

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 12px 0px",
  border: "1px solid transparent",
  borderRadius: "8px",
  color: "#E0E2E5",
  background: "#42506C",
  boxShadow: "0px 3px 6px #00000029",

  // styles we need to apply on draggables
  ...draggableStyle,
})
const getSubFocusAreaStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 12px 0px",
  border: "1px solid transparent",
  borderRadius: "8px",
  color: "#E0E2E5",
  background: "#303C54",
  boxShadow: "0px 3px 6px #00000029",

  // styles we need to apply on draggables
  ...draggableStyle,
})
const getListStyle = (isDraggingOver) => ({
  overflowY: "auto",
})
const getResourceItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})

const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

const ScrollArrows = (props) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(true)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    let scroll_div = document.getElementById(props.scroll_div)
    if (scroll_div.scrollLeft > 0) {
      setShowLeftArrow(true)
    } else {
      setShowLeftArrow(false)
    }
    if (scroll_div.scrollWidth === scroll_div.scrollLeft + scroll_div.offsetWidth) {
      setShowRightArrow(false)
    } else {
      setShowRightArrow(true)
    }
  }, [props.items])

  function scrollDiv(direction) {
    let scroll_div = document.getElementById(props.scroll_div)
    let scroll_amount = 0
    if (direction === "right") {
      scroll_amount = scroll_div.scrollLeft + (scroll_div.scrollWidth / scroll_div.childElementCount) * 2
    } else {
      scroll_amount = scroll_div.scrollLeft - (scroll_div.scrollWidth / scroll_div.childElementCount) * 2
    }
    scroll_div.scroll({
      behavior: "smooth",
      left: scroll_amount,
    })
    if (scroll_amount > 0) {
      setShowLeftArrow(true)
    } else {
      setShowLeftArrow(false)
    }
    if (scroll_div.scrollWidth <= scroll_amount + scroll_div.offsetWidth) {
      setShowRightArrow(false)
    } else {
      setShowRightArrow(true)
    }
  }
  return (
    <React.Fragment>
      <div className={`${StylesA.scroll_left_arrow_div} ${showLeftArrow ? "" : "d-none"}`} onClick={() => scrollDiv("left")}>
        <Image className="h-14px" src={ChevronLeft} />
      </div>
      <div className={`${StylesA.scroll_right_arrow_div} ${showRightArrow ? "" : "d-none"}`} onClick={() => scrollDiv("right")}>
        <Image className="h-14px" src={ChevronRight} />
      </div>
    </React.Fragment>
  )
}
const NameInputField = (props) => {
  const [inputValue, setInputValue] = useState(props.name)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (props.edited) {
      setLoader(false)
    }
  }, [props.edited])

  function sendQuery(name, value, datum, type, group_index, sub_group_index, que_index) {
    props.handleSave({ name: name, value: value, item_id: datum, type: type, group_index: group_index, sub_group_index: sub_group_index, que_index: que_index })
  }

  const delayedQuery = useCallback(
    debounce(
      (name, value, datum, type, group_index, sub_group_index, que_index) => sendQuery(name, value, datum, type, group_index, sub_group_index, que_index),
      1000
    ),
    []
  )
  return (
    <div style={{ width: "100%", position: "relative" }}>
      {props.type === "question" ? (
        <textarea
          autoComplete="off"
          type="text"
          name={props.field_name || "item_name"}
          defaultValue={props.name}
          placeholder={"Enter Name"}
          onChange={(e) => {
            e.preventDefault()
            setInputValue(e.target.value)
            setLoader(true)
            delayedQuery(e.target.name, e.target.value, props.datum.id, props.type, props.group_index, props.sub_group_index, props.que_index)
          }}
          className={`${StylesB.session_input_field} form-control px-2 font-weight-bold ${inputValue.length > 85 ? `h-100` : ``} ${Styles.font_16} w-100`}
          required
          style={{ height: "200px" }}
        />
      ) : (
        <input
          autoComplete="off"
          type="text"
          name={props.field_name || "item_name"}
          defaultValue={props.name}
          placeholder={"Enter Name"}
          onChange={(e) => {
            e.preventDefault()
            setInputValue(e.target.value)
            setLoader(true)
            delayedQuery(e.target.name, e.target.value, props.datum.id, props.type, props.group_index, props.sub_group_index, props.que_index)
          }}
          className={`${StylesB.session_input_field} form-control px-2 font-weight-bold ${Styles.font_16} d-inline-flex w-100`}
          required
        />
      )}
    </div>
  )
}
const WeightageField = (props) => {
  const [inputValue, setInputValue] = useState(props.value)
  const [loader, setLoader] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (inputValue < 1) {
      setErrorMessage("Weightage should be greater than zero")
    } else if (inputValue > 100) {
      setErrorMessage("Weightage should not exceed 100")
    } else {
      setErrorMessage("")
    }
  }, [inputValue])

  function sendQuery(name, value, datum, type, group_index, sub_group_index, que_index) {
    if (value > 0 && value < 101) {
      props.handleSave({
        name: name,
        value: value,
        item_id: datum,
        type: type,
        group_index: group_index,
        sub_group_index: sub_group_index,
        que_index: que_index,
      })
    }
  }

  const delayedQuery = useCallback(
    debounce(
      (name, value, datum, type, group_index, sub_group_index, que_index) => sendQuery(name, value, datum, type, group_index, sub_group_index, que_index),
      1000
    ),
    []
  )

  function showError() {
    if (errorMessage) {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className={`text-left px-0 d-flex align-items-baseline`}>
          <Image className="mt-1 mr-1 h-9px" src={ExclamationTriangle} />
          <p className="mb-0 fs-12px color-AA5746">{errorMessage}</p>
        </Col>
      )
    } else return null
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-end">
        <p className="fs-12px m-0 white-text">Weightage: </p>
        {showInput ? (
          <input
            autoComplete="off"
            type="number"
            autoFocus={true}
            name={props.field_name || "item_weightage"}
            defaultValue={parseFloat(props.value).toFixed(2)}
            placeholder={"00"}
            onChange={(e) => {
              setInputValue(e.target.value)
              setLoader(true)
              delayedQuery(e.target.name, e.target.value, props.datum.id, props.type, props.group_index, props.sub_group_index, props.que_index)
            }}
            className={`${StylesA.weightage_input}`}
            onBlur={() => setShowInput(false)}
            required
            min={0}
            max={100}
          />
        ) : (
          <div onClick={() => setShowInput(true)} className={`${StylesA.weightage_text}`}>
            {parseFloat(props.value).toFixed(2)}%
          </div>
        )}
      </div>
      {showError()}
    </div>
  )
}
const ChecklistRule = (props) => {
  const [rule, setRule] = useState(props.datum)
  const [loader, setLoader] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  function updateChecklistRule(name, value) {
    var formData = new FormData()
    formData.append(name, value)
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/rule/${rule.id}/`, formData, { headers: login_credentials })
      .then((res) => {})
      .catch((error) => {
        props.showErrorMessage(error)
      })
  }

  function sendQuery(name, value) {
    if ((name === "label" || name === "message") && value) {
      updateChecklistRule(name, value)
    } else if (
      (name === "score_range_to" && parseInt(value) < 101 && parseInt(value) > 0) ||
      (name === "score_range_from" && parseInt(value) > -1 && parseInt(value) < 100)
    ) {
      updateChecklistRule(name, parseInt(value))
    }
  }

  const delayedResponseQuery = useCallback(
    debounce((name, value) => sendQuery(name, value), 1000),
    []
  )

  function showError(error) {
    if (error) {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className={`text-left px-0 d-flex align-items-baseline`}>
          <Image className="mt-1 mr-1 h-9px" src={ExclamationTriangle} />
          <p className="mb-0 fs-12px color-AA5746">{error}</p>
        </Col>
      )
    } else return null
  }
  function ruleTextField(name, value) {
    return (
      <input
        name={name}
        defaultValue={value}
        autoComplete="off"
        type="text"
        className={`${StylesA.response_group_name_input}`}
        onChange={(e) => {
          handleRuleChange(e.target.name, e.target.value)
          delayedResponseQuery(e.target.name, e.target.value)
        }}
        placeholder={name}
        style={{ background: "transparent", borderColor: "transparent" }}
        required
      />
    )
  }
  function ruleNumberField(name, value) {
    return (
      <div className="d-flex align-items-center">
        <input
          name={name}
          defaultValue={value}
          autoComplete="off"
          className={`${StylesA.response_group_name_input} ${name === "score_range_to" ? "ml-2" : ""}`}
          onChange={(e) => {
            handleRuleChange(e.target.name, e.target.value)
            delayedResponseQuery(e.target.name, e.target.value)
          }}
          type="number"
          placeholder="0"
          min={0}
          max={100}
          style={{ background: "transparent", borderColor: "transparent" }}
          required
        />
        <div style={{ fontSize: "12px", marginRight: "5px" }}>%</div>
      </div>
    )
  }
  function handleRuleChange(name, value) {
    let int_value = parseInt(value)
    let current_rule = { ...rule }
    current_rule[name] = int_value
    if (name === "score_range_from") {
      if (int_value < 0 || int_value > 99 || !int_value) {
        setErrorMessage("Enter valid number")
      } else if (int_value > parseInt(rule.score_range_to)) {
        setErrorMessage("Should be less than end value")
      } else {
        setErrorMessage("")
      }
      props.reorderRules(current_rule, props.index)
    } else if (name === "score_range_to") {
      if (int_value > 100 || int_value < 1 || !int_value) {
        setErrorMessage("Enter valid number")
      } else if (int_value < parseInt(rule.score_range_from)) {
        setErrorMessage("Should be greater than start value")
      } else {
        setErrorMessage("")
      }
    } else {
      current_rule[name] = value
    }
    setRule(current_rule)
  }
  return (
    <div key={"checklist_rule_" + rule.id.toString()} className={`${StylesA.checklist_rule_card} border_on_hover mt-2`}>
      <div className={`${StylesA.section_1} py-2 px-3`}>
        <div className=" d-flex align-items-baseline">
          {ruleNumberField("score_range_from", Math.round(rule.score_range_from))}-{ruleNumberField("score_range_to", Math.round(rule.score_range_to))}
        </div>
        {showError(errorMessage)}
      </div>
      <div className={`${StylesA.section_1} py-2 px-3`}>
        {ruleTextField("label", rule.label)}
        {rule.label ? "" : showError("Label is required")}
      </div>
      <div className={`${StylesA.section_2} py-2 px-3`}>
        {ruleTextField("message", rule.message)}
        {rule.message ? "" : showError("Message is required")}
      </div>
      <img
        src={DeleteIcon}
        onClick={() => props.deleteChecklistRules(rule.id, props.index)}
        alt={`rule_delete_icon_${props.index}`}
        className={`my-auto mr-2 pointer`}
        style={{ objectFit: `contain`, height: `13px` }}
      />
    </div>
  )
}

const Question = (props) => {
  const [dmodal, setDmodal] = useState(false)
  const [questionEdited, setQuestionEdited] = useState(true)

  function deleteQuestion() {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/item/${props.question.id}/`, { headers: login_credentials })
      .then((res) => {
        props.removeQuestion(props.parent_type, props.index, props.parent_index, props.parent_parent_index)
      })
      .catch((error) => {
        props.showErrorMessage(error)
      })
  }

  function showQuestionData() {
    let current_group_index
    if (props.checklist.checklist_type === "self-assessment-adv1") {
      current_group_index = props.parent_index
    } else {
      current_group_index = props.parent_parent_index
    }
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-0 d-inline-flex w-100 align-items-center">
        <div
          variant="none"
          id={"modules_" + props.question.id}
          className={`${Styles.font_16} w-100 shadow-none`}
          style={{ color: `#F2F4FF`, backgroundColor: props.checklist.checklist_type === "self-assessment-adv1" ? "#303c54" : "#42506C" }}
        >
          <Col xs="12" sm="12" md="12" lg="12" className="d-flex pl-0 align-items-center w-100">
            <div className={`d-inline-flex h-100 pl-2 pr-3 py-1 align-items-baseline`} style={{ borderRight: "1px solid #838B9E" }}>
              <img
                src={DragHandleIcon}
                alt={`drag-handle`}
                className={`mt-1 mb-0 mr-1 pointer`}
                style={{ objectFit: `contain`, height: `13px`, width: "16px" }}
              />
              <p className={`mb-0 pl-2`} style={{ fontSize: "19px", fontWeight: "500", color: "#FFFFFF70" }}>
                {props.index + 1}
              </p>
            </div>
            <NameInputField
              datum={props.question}
              name={props.question.item_name}
              field_name="item_name"
              handleSave={props.setEditedItem}
              edited={questionEdited}
              type="checklist-item"
              group_index={current_group_index}
              sub_group_index={props.parent_index}
              que_index={props.index}
            />
          </Col>
        </div>
        <div
          className={`${StylesA.question_delete_div} pointer ml-3`}
          onClick={(e) => {
            e.preventDefault()
            setDmodal(true)
          }}
        >
          <img style={{ width: "15px", height: "15px" }} alt="delete course" src={deleteimg} className="mb-0" />
        </div>
      </Col>
    )
  }
  return (
    <React.Fragment>
      <Draggable key={`_topic_name_` + props.index} index={props.index} draggableId={props.question.id.toString() + `-topic` + "_" + props.index.toString()}>
        {(provided, snapshot) => (
          <div
            className={`shadow-none`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={
              props.checklist.checklist_type === "self-assessment-adv1"
                ? getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)
                : getSubFocusAreaStyle(snapshot.isDragging, provided.draggableProps.style)
            }
          >
            {showQuestionData()}
          </div>
        )}
      </Draggable>
      <DeleteModal show={dmodal} onHide={() => setDmodal(false)} itemName={props.question?.item_name} action={() => deleteQuestion()} />
    </React.Fragment>
  )
}

const SubFocusArea = (props) => {
  const [openSubFocusArea, setOpenSubFocusArea] = useState(props.sub_focus_area.id === props.createdSubFocusArea ? true : false)
  const [dmodal, setDmodal] = useState(false)
  const [subFocusAreaEdited, setSubFocusAreaEdited] = useState(true)

  useEffect(() => {
    if (openSubFocusArea && !props.sub_focus_area.questions) {
      props.fetchItems(props.checklist.checklist_type, "checklist-subgroup", props.index, props.parent_index)
    }
  }, [openSubFocusArea])

  function deleteSubFocusArea() {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/item/${props.sub_focus_area.id}/`, { headers: login_credentials })
      .then((res) => {
        setDmodal(false)
        props.removeSubFocusArea(props.index, res.data, props.sub_focus_area.auto_split_weightage, props.parent_index)
      })
      .catch((error) => {
        props.showErrorMessage(error)
      })
  }

  function showQuestionsFooter() {
    return (
      <div className="mt-2 d-flex align-items-baseline justify-content-between">
        <Button
          disabled={props.sub_focus_area.child_create_loader}
          className={`${StylesB.add_button} border_on_hover d-flex`}
          variant="none"
          onClick={(e) => props.createItem("checklist-subgroup", props.index, props.parent_index)}
        >
          {!props.sub_focus_area.child_create_loader ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
          Add Question
        </Button>
        <WeightageField
          datum={props.sub_focus_area}
          value={props.sub_focus_area.item_weightage}
          handleSave={props.setEditedItem}
          edited={subFocusAreaEdited}
          type="checklist-subgroup"
          group_index={props.parent_index}
          sub_group_index={props.index}
        />
      </div>
    )
  }

  function showQuestions() {
    if (props.sub_focus_area.questions) {
      if (props.sub_focus_area.questions.length > 0) {
        return props.sub_focus_area.questions.map((question, index) => {
          return (
            <Question
              key={`${question.id}_${index}`}
              question={question}
              index={index}
              checklist={props.checklist}
              removeQuestion={props.removeQuestion}
              showErrorMessage={props.showErrorMessage}
              parent_index={props.index}
              parent_parent_index={props.parent_index}
              parent_type={props.sub_focus_area.item_type}
              setEditedItem={props.setEditedItem}
            />
          )
        })
      } else {
        return <p className={`${StylesA.empty_content_text}`}>No questions created yet!</p>
      }
    } else {
      return showLoader()
    }
  }
  function showLoader() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
        <Spinner animation="border" variant="secondary" size="sm" />
      </Col>
    )
  }

  function showSubFocusAreaData() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-0">
        <div
          variant="none"
          className={`${Styles.font_16} pr-2 shadow-none d-flex`}
          style={{ color: `#F2F4FF`, justifyContent: "space-between", borderRadius: openSubFocusArea ? "8px 8px 0px 0px" : "8px" }}
        >
          <Col xs="10" sm="10" md="10" lg="10" className="d-flex pl-0 align-items-center">
            <div
              className={`d-inline-flex pl-2 pr-3 py-1 align-items-center`}
              style={{ borderRadius: openSubFocusArea ? "9px 0px 0px 0px" : "9px 0px 0px 9px", borderRight: "1px solid #838B9E" }}
            >
              <img
                src={DragHandleIcon}
                alt={`drag-handle`}
                className={`my-auto mr-1 pointer`}
                style={{ objectFit: `contain`, height: `13px`, width: "16px" }}
              />
              <p className={`mb-0 pl-2`} style={{ fontSize: "19px", fontWeight: "500", color: "#FFFFFF70" }}>
                {props.index + 1}
              </p>
            </div>
            <NameInputField
              datum={props.sub_focus_area}
              name={props.sub_focus_area.item_name}
              handleSave={props.setEditedItem}
              edited={subFocusAreaEdited}
              type="checklist-subgroup"
              group_index={props.parent_index}
              sub_group_index={props.index}
            />
          </Col>
          <div className="d-flex">
            <Image
              className="mr-2 pointer h-20px mx-0 my-auto"
              onClick={(e) => setOpenSubFocusArea(!openSubFocusArea)}
              src={openSubFocusArea ? ChevronCircleUp : ChevronCircleDown}
            />
            <img
              src={DeleteIcon}
              onClick={(e) => {
                e.preventDefault()
                setDmodal(true)
              }}
              alt={`delete icon`}
              className={`my-auto mr-1 pointer`}
              style={{ objectFit: `contain`, height: `13px` }}
            />
          </div>
        </div>
        <Collapse style={{ padding: "0px", borderRadius: "0px 0px 8px 8px", backgroundColor: "inherit" }} in={openSubFocusArea}>
          <div id="sub-focus-area-collapse">
            <Col lg="12" md="12" sm="12" xs="12" className="px-0" style={{ borderTop: "1px solid #838B9E" }}>
              {showQuestionsSection()}
            </Col>
          </div>
        </Collapse>
      </Col>
    )
  }
  function showQuestionsSection() {
    return (
      <Droppable droppableId={`${props.sub_focus_area.id}_${props.index}_${props.parent_index}`} type={`resourceDraggable`}>
        {(provided, snapshot) => (
          <div id={`drop-area`} className={`bg-transparent px-4 py-3`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {showQuestions()}
            {provided.placeholder}
            {showQuestionsFooter()}
          </div>
        )}
      </Droppable>
    )
  }

  return (
    <React.Fragment>
      <Modal show={dmodal} onHide={() => setDmodal(false)} centered className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}>
        <div className={`${StylesB.delete_modal_header}`}>
          <img onClick={() => setDmodal(false)} src={CancelWhite} alt={`Cancel`} className={`${StylesB.cancel_icon} my-auto mr-1`} />
          <img src={TrashCan} alt={`delete modal delete icon`} className={`${StylesB.trash_can_icon} my-auto mr-2`} />
          <div>
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>Delete</p>
            <p>Item will be deleted permanentley!</p>
          </div>
        </div>
        <Modal.Body className={`${StylesB.delete_modal_body}`}>
          <p> Are you sure you want to delete {props.sub_focus_area.item_name} ? </p>
          <div className="d-flex justify-content-center">
            <Button className={`${StylesB.add_button_large} border_on_hover mr-3`} variant="secondary" onClick={deleteSubFocusArea} style={{ width: "75px" }}>
              Yes
            </Button>
            <Button className={`${StylesB.add_button_large} border_on_hover`} variant="secondary" onClick={() => setDmodal(false)} style={{ width: "75px" }}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Draggable
        key={props.sub_focus_area.id + `_topic_name_` + props.index}
        index={props.index}
        draggableId={props.sub_focus_area.id.toString() + "-topic" + "_" + props.index.toString()}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`border_on_hover`}
            style={getSubFocusAreaStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            {showSubFocusAreaData()}
          </div>
        )}
      </Draggable>
    </React.Fragment>
  )
}

const FocusArea = (props) => {
  const [openFocusArea, setOpenFocusArea] = useState(props.focus_area.id === props.createdFocusArea ? true : false)
  const [dmodal, setDmodal] = useState(false)
  const [focusAreaEdited, setFocusAreaEdited] = useState(true)

  useEffect(() => {
    if (openFocusArea && !props.focus_area.sub_focus_areas && !props.focus_area.questions) {
      props.fetchItems(props.checklist.checklist_type, "checklist-group", props.index)
    }
  }, [openFocusArea])

  function deleteFocusArea() {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/item/${props.focus_area.id}/`, { headers: login_credentials })
      .then((res) => {
        setDmodal(false)
        props.removeFocusArea(props.index, res.data, props.focus_area.auto_split_weightage)
      })
      .catch((error) => {
        props.showErrorMessage(error)
      })
  }

  function showSubFocusAreaFooter() {
    return (
      <div className="d-flex mt-2 justify-content-between align-items-baseline">
        <Button
          disabled={props.focus_area.child_create_loader}
          className={`${StylesB.add_button} border_on_hover d-flex`}
          variant="none"
          onClick={(e) => props.createItem("checklist-group", props.index)}
        >
          {!props.focus_area.child_create_loader ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
          Add Sub Group
        </Button>
        <WeightageField
          datum={props.focus_area}
          value={props.focus_area.item_weightage}
          handleSave={props.setEditedItem}
          edited={focusAreaEdited}
          type="checklist-group"
          group_index={props.index}
        />
      </div>
    )
  }

  function showSubFocusAreas() {
    if (props.focus_area.sub_focus_areas) {
      if (props.focus_area.sub_focus_areas.length > 0) {
        return props.focus_area.sub_focus_areas.map((sub_focus_area, index) => {
          return (
            <SubFocusArea
              key={sub_focus_area.id.toString()}
              sub_focus_area={sub_focus_area}
              index={index}
              checklist={props.checklist}
              removeSubFocusArea={props.removeSubFocusArea}
              showErrorMessage={props.showErrorMessage}
              createdSubFocusArea={props.createdSubFocusArea}
              parent_index={props.index}
              fetchItems={props.fetchItems}
              createItem={props.createItem}
              removeQuestion={props.removeQuestion}
              setEditedItem={props.setEditedItem}
            />
          )
        })
      } else {
        return <p className={`${StylesA.empty_content_text}`}>No sub groups created yet!</p>
      }
    } else {
      return showLoader()
    }
  }
  function showLoader() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
        <Spinner animation="border" variant="secondary" size="sm" />
      </Col>
    )
  }

  function showFocusAreaData() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-0">
        <div
          variant="none"
          id={"modules_" + props.focus_area.id}
          className={`${Styles.font_16} pr-2 shadow-none d-flex`}
          style={{ color: `#F2F4FF`, justifyContent: "space-between", borderRadius: openFocusArea ? "8px 8px 0px 0px" : "8px" }}
        >
          <Col xs="11" sm="11" md="11" lg="11" className="d-flex pl-0 align-items-center">
            <div
              className={`d-flex px-2 align-items-center`}
              style={{ borderRadius: openFocusArea ? "9px 0px 0px 0px" : "9px 0px 0px 9px", borderRight: "1px solid #838B9E", height: "100%" }}
            >
              <img src={DragHandleIcon} alt={`focusarea-drag-handle`} className={`mb-0 pointer`} style={{ height: `13px`, width: "16px" }} />
              {/*<p className={`mb-0 pl-2`} style={{fontSize:'19px',fontWeight:'500',color:'#FFFFFF70'}}>{props.index+1}</p>*/}
            </div>
            <NameInputField
              datum={props.focus_area}
              name={props.focus_area.item_name}
              handleSave={props.setEditedItem}
              edited={focusAreaEdited}
              type="checklist-group"
              group_index={props.index}
            />
          </Col>
          <div className="d-flex">
            <Image
              className="mr-2 pointer h-20px mx-0 my-auto"
              onClick={(e) => setOpenFocusArea(!openFocusArea)}
              src={openFocusArea ? ChevronCircleUp : ChevronCircleDown}
            />
            <img
              src={DeleteIcon}
              onClick={(e) => {
                e.preventDefault()
                setDmodal(true)
              }}
              alt={`focus area delete icon`}
              className={`my-auto mr-1 pointer`}
              style={{ objectFit: `contain`, height: `13px` }}
            />
          </div>
        </div>
        <Collapse style={{ padding: "0px", borderRadius: "0px 0px 8px 8px", backgroundColor: "inherit" }} in={openFocusArea}>
          <div id="focus-area-collapse">
            <Col lg="12" md="12" sm="12" xs="12" style={{ padding: "12px", borderTop: "1px solid #838B9E" }}>
              {props.checklist.checklist_type === "self-assessment-adv1" ? showQuestionsSection() : showSubGroupSection()}
            </Col>
          </div>
        </Collapse>
      </Col>
    )
  }
  function showQuestionsSection() {
    return (
      <Droppable type={`resourceDraggable`} droppableId={`${props.focus_area.id}_${props.index}`}>
        {(provided, snapshot) => (
          <div id={`drop-area`} className={`bg-transparent px-4 py-3`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {showQuestions()}
            {provided.placeholder}
            {showQuestionsFooter()}
          </div>
        )}
      </Droppable>
    )
  }
  function showQuestions() {
    if (props.focus_area.questions) {
      if (props.focus_area.questions.length > 0) {
        return props.focus_area.questions.map((question, index) => {
          return (
            <Question
              createItem={props.createItem}
              key={question.id.toString()}
              question={question}
              index={index}
              checklist={props.checklist}
              removeQuestion={props.removeQuestion}
              parent_type={props.focus_area.item_type}
              showErrorMessage={props.showErrorMessage}
              setEditedItem={props.setEditedItem}
              parent_index={props.index}
            />
          )
        })
      } else {
        return <p className={`${StylesA.empty_content_text}`}>No questions created yet!</p>
      }
    } else {
      return showLoader()
    }
  }
  function showQuestionsFooter() {
    return (
      <div className="mt-3 d-flex-space-between">
        <Button
          disabled={props.focus_area.child_create_loader}
          className={`${StylesB.add_button} border_on_hover d-flex`}
          variant="none"
          onClick={() => props.createItem("checklist-group", props.index)}
        >
          {!props.focus_area.child_create_loader ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
          Add Question
        </Button>
        <WeightageField
          datum={props.focus_area}
          value={props.focus_area.item_weightage}
          handleSave={props.setEditedItem}
          edited={focusAreaEdited}
          type="checklist-group"
          group_index={props.index}
        />
      </div>
    )
  }
  function showSubGroupSection() {
    return (
      <Droppable droppableId={`${props.focus_area.id}_${props.index}`} type={`topicDraggable`}>
        {(provided, snapshot) => (
          <React.Fragment>
            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {showSubFocusAreas()}
              {provided.placeholder}
            </div>
            {showSubFocusAreaFooter()}
          </React.Fragment>
        )}
      </Droppable>
    )
  }
  return (
    <React.Fragment>
      <Modal show={dmodal} onHide={() => setDmodal(false)} centered className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}>
        <div className={`${StylesB.delete_modal_header}`}>
          <img onClick={() => setDmodal(false)} src={CancelWhite} alt={`Cancel`} className={`${StylesB.cancel_icon} my-auto mr-1`} />
          <img src={TrashCan} alt={`delete modal delete icon`} className={`${StylesB.trash_can_icon} my-auto mr-2`} />
          <div>
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>Delete</p>
            <p>Item will be deleted permanentley!</p>
          </div>
        </div>
        <Modal.Body className={`${StylesB.delete_modal_body}`}>
          <p> Are you sure you want to delete {props.focus_area.item_name} ? </p>
          <div className="d-flex justify-content-center">
            <Button className={`${StylesB.add_button_large} border_on_hover mr-3`} variant="secondary" onClick={deleteFocusArea} style={{ width: "75px" }}>
              Yes
            </Button>
            <Button className={`${StylesB.add_button_large} border_on_hover`} variant="secondary" onClick={() => setDmodal(false)} style={{ width: "75px" }}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Draggable
        index={props.index}
        key={props.focus_area.id + `_module_name_` + props.index}
        draggableId={props.focus_area.id.toString() + "-module_" + props.index.toString()}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`border_on_hover`}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            {showFocusAreaData()}
          </div>
        )}
      </Draggable>
    </React.Fragment>
  )
}

const EditChecklist = (location) => {
  const checklistTypes = ["Self Assessment (Basic)", "Self Assessment (Advanced)"]
  const checklistTypeNames = ["self-assessment-simple", "self-assessment-adv1", "self-assessment-adv2"]
  const checklistTypeValues = {
    "self-assessment-simple": "Self Assessment (Basic)",
    "self-assessment-adv1": "Self Assessment (Advanced)",
    "self-assessment-adv2": "Self Assessment (Advanced)",
  }
  const checklistDescription = [
    "Create a simple checklist for learners to evaluate process, skills or self etc.",
    " Create a checklist with questions grouped under focus areas for learners to assess the work, process or self.",
    // 'Create a checklist for all your pending work. You can create, plan, schedule, and complete different tasks as per your preference.'
  ]
  const [checklist, setChecklist] = useState("")
  const [showDefineChecklistSection, setShowDefineChecklistSection] = useState(true)
  const [loader, setLoader] = useState(false)
  const [callUpdateChecklist, setCallUpdateChecklist] = useState(false)
  const [publishError, setPublishError] = useState("")
  const [published, setPublished] = useState(false)

  const [showChecklistTypes, setShowChecklistTypes] = useState(false)
  const [showCheckListLayoutModal, setShowCheckListLayoutModal] = useState(false)
  const [selectedSlefAssessmentType, setSelectedSlefAssessmentType] = useState(null)

  const [focusAreas, setFocusAreas] = useState([])
  const [createdFocusArea, setCreatedFocusArea] = useState("")
  const [createdSubFocusArea, setCreatedSubFocusArea] = useState("")
  const [checklistQuestions, setQuestions] = useState([])
  const [focusAreaLoader, setFocusAreaLoader] = useState(true)
  const [focusAreaCreateLoader, setFocusAreaCreateLoader] = useState(false)

  const [showDefineResponsesSection, setShowDefineResponsesSection] = useState(true)
  const [responseGroups, setResponseGroups] = useState([])
  const [responseTypeCreateLoader, setResponseTypeCreateLoader] = useState(false)
  const [editingResponseGroup, setEditingResponseGroup] = useState("")
  const [responseTypeDuplicateLoader, setResponseTypeDuplicateLoader] = useState(false)
  const [createdResponseGroup, setCreatedResponseGroup] = useState("")
  const [showCreateResponseGroupModal, setshowCreateResponseGroupModal] = useState(false)

  const [showDefineRulesSection, setShowDefineRulesSection] = useState(true)
  const [checklistRules, setChecklistRules] = useState([])
  const [checklistRuleCreateLoader, setChecklistRuleCreateLoader] = useState(false)
  const [editingRule, setEditingRule] = useState("")

  const [editedItem, setEditedItem] = useState("")

  const delayedQuery = useCallback(
    debounce((name, value) => sendQuery(name, value), 1000),
    []
  )
  const [notificationData, setNotificationData] = useState(null)
  const [sidebarState, dispatch] = useContext(SidebarContext)

  function sendQuery(name, value) {
    updateChecklist(name, value)
  }

  const delayedResponseQuery = useCallback(
    debounce((id, name, value, type = "response_group", index = "") => sendResponseQuery(id, name, value, type), 1000),
    []
  )
  function sendResponseQuery(id, name, value, type, index) {
    if (type === "response_group") {
      updateResponseGroup(id, name, value)
    } else if (type === "response_option") {
      updateResponseOption(id, name, value)
    } else if (type === "checklist_rule") {
      updateChecklistRule(id, name, value, index)
    }
  }

  useEffect(() => {
    if (callUpdateChecklist) {
      updateChecklist()
    }
  }, [callUpdateChecklist])
  useEffect(() => {
    if (editedItem) {
      updateFocusArea(
        editedItem.name,
        editedItem.value,
        editedItem.item_id,
        editedItem.type,
        editedItem.group_index,
        editedItem.sub_group_index,
        editedItem.que_index
      )
      setEditedItem("")
    }
  }, [editedItem])

  useEffect(() => {
    if (checklist.selected_response_group && editingResponseGroup) {
      setEditingResponseGroup("")
    }
  }, [checklist.selected_response_group])

  useEffect(() => {
    let location_state = location.props.location.state
    if (location_state && location_state.checklist) {
      let checklist = {}
      checklist.id = location_state.checklist.id
      checklist.name = location_state.checklist.name
      checklist.published = location_state.checklist.published
      checklist.checklist_type = location_state.checklist.checklist_type
      checklist.selected_response_group = location_state.checklist.selected_response_group
      setChecklist(checklist)
      fetchItems(checklist.checklist_type)
      navigate(`/app/checklist/edit/${location.id}`)
    } else {
      fetchChecklist()
    }
    fetchResponseTypes()
    fetchChecklistRules()
  }, [location.id])

  // checklist CRED operations
  function fetchChecklist() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/${location.id}/`, { headers: login_credentials })
      .then((res) => {
        let data = res.data
        let checklist = {}
        checklist.id = data.id
        checklist.name = data.name
        checklist.published = data.published
        checklist.checklist_type = data.checklist_type
        checklist.selected_response_group = data.selected_response_group
        setChecklist(checklist)
        fetchItems(checklist.checklist_type)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function updateChecklist(name = null, value = null) {
    var formData = new FormData()
    if (name && value) {
      formData.append(name, value)
    } else {
      for (var k in checklist) {
        formData.append(k, checklist[k])
      }
    }
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/${location.id}/`, formData, { headers: login_credentials })
      .then((res) => {
        setCallUpdateChecklist(false)
        setLoader(false)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function publishChecklist() {
    setLoader(true)
    let formData = new FormData()
    if (window && window.FromTrainingOrClassCreation && window.FromTrainingOrClassCreation.sequence) {
      for (let p in window.FromTrainingOrClassCreation) {
        formData.append(p, window.FromTrainingOrClassCreation[p])
      }
    }

    dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Checklists" })
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/${location.id}/publish_checklist/`, formData, { headers: login_credentials })
      .then((res) => {
        if (res.data.errors) {
          setPublishError(res.data.errors)
        } else {
          setNotificationData({ type: "success", title: "Checklist published Sucessfully" })
          setPublishError("")
          setPublished(true)
          dispatch({ type: SET_REFETCH_RESOURCES, payload: true })
          if (window.opener) {
            const evt = new CustomEvent("my-refresh-resources", {
              bubbles: true,
              detail: "checklist",
            })
            window.opener?.document && window.opener.document.dispatchEvent(evt)
          }
        }
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        showErrorMessage(error)
      })
  }

  // Response type CRED operations
  function createResponseType() {
    let response_type = {}
    response_type.response_group_name = "Response Type " + (responseGroups.length + 1)
    var formData = new FormData()
    for (var k in response_type) {
      formData.append(k, response_type[k])
    }
    setResponseTypeCreateLoader(true)
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/checklist/response_group/", formData, { headers: login_credentials })
      .then((res) => {
        handleChange(null, "selected_response_group", res.data.id, true)
        linkResponseType(res.data.id)
        setResponseTypeCreateLoader(false)
        setCreatedResponseGroup(res.data)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function duplicateResponseType(response_group, index) {
    setResponseTypeDuplicateLoader(true)
    var formData = new FormData()
    formData.append("checklist_id", location.id)
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/response_group/${response_group}/duplicate_response_group/`, formData, {
        headers: login_credentials,
      })
      .then((res) => {
        let current_response_groups = [...responseGroups]
        current_response_groups.splice(index, 1, res.data)
        setResponseGroups(current_response_groups)
        if (checklist.selected_response_group === response_group) {
          handleChange(null, "selected_response_group", res.data.id, false)
        }
        setEditingResponseGroup(res.data)
        setResponseTypeDuplicateLoader(false)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function fetchResponseTypes() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/response_group/?checklist_rg_set=${location.id}`, { headers: login_credentials })
      .then((res) => {
        setResponseGroups(res.data.results)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function updateResponseGroup(id, name, value) {
    var formData = new FormData()
    formData.append(name, value)
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/response_group/${id}/`, formData, { headers: login_credentials })
      .then((res) => {})
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function unlinkResponseType(response_group, index) {
    var formData = new FormData()
    formData.append("response_group_id", response_group)
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/${location.id}/unlink_response_group/`, formData, { headers: login_credentials })
      .then((res) => {
        if (checklist.selected_response_group === response_group.id) {
          let current_checklist = { ...checklist }
          current_checklist.selected_response_group = null
          setChecklist(current_checklist)
        }
        let current_response_groups = [...responseGroups]
        current_response_groups.splice(index, 1)
        setResponseGroups(current_response_groups)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function linkResponseType(response_group) {
    var formData = new FormData()
    formData.append("response_group_id", response_group)
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/${location.id}/link_response_group/`, formData, { headers: login_credentials })
      .then((res) => {})
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function deleteResponseType(response_group, index) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/response_group/${response_group.id}/`, { headers: login_credentials })
      .then((res) => {
        if (checklist.selected_response_group === response_group.id) {
          let current_checklist = { ...checklist }
          current_checklist.selected_response_group = null
          setChecklist(current_checklist)
        }
        let current_response_groups = [...responseGroups]
        current_response_groups.splice(index, 1)
        setResponseGroups(current_response_groups)
        setshowCreateResponseGroupModal(false)
        setCreatedResponseGroup("")
        setEditingResponseGroup("")
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  // Response option CRED operations
  function createResponseOption(response_group, response_group_index) {
    var formData = new FormData()
    formData.append("option_name", "New option")
    formData.append("checklist_response", response_group)
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/response_group/option/`, formData, { headers: login_credentials })
      .then((res) => {
        if (showCreateResponseGroupModal) {
          let response_group = { ...createdResponseGroup }
          response_group.checklist_rg_options.push(res.data)
          setCreatedResponseGroup(response_group)
        } else {
          let current_response_groups = [...responseGroups]
          let response_group = current_response_groups[response_group_index]
          response_group.checklist_rg_options.push(res.data)
          setResponseGroups(current_response_groups)
        }
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function updateResponseOption(id, name, value) {
    var formData = new FormData()
    formData.append(name, value)
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/response_group/option/${id}/`, formData, { headers: login_credentials })
      .then((res) => {})
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function deleteResponseOption(response_option, index, response_group_index) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/response_group/option/${response_option}/`, { headers: login_credentials })
      .then((res) => {
        if (showCreateResponseGroupModal) {
          let response_group = { ...createdResponseGroup }
          response_group.checklist_rg_options.splice(index, 1)
          setCreatedResponseGroup(response_group)
        } else {
          let current_response_groups = [...responseGroups]
          let response_group = current_response_groups[response_group_index]
          response_group.checklist_rg_options.splice(index, 1)
          setResponseGroups(current_response_groups)
        }
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  // Focus area and question CRED operations
  function fetchItems(checklist_type, parent_item = null, parent_index = null, parent_parent_index = null) {
    let item_type = "checklist-group"
    let parent_item_url = ""
    if (!parent_item) {
      setFocusAreaLoader(true)
      if (checklist_type === "self-assessment-simple") {
        item_type = "checklist-item"
      }
    } else if (parent_item === "checklist-group") {
      parent_item_url = `&parent_item=${focusAreas[parent_index].id}`
      if (checklist_type === "self-assessment-adv1") {
        item_type = "checklist-item"
      } else {
        item_type = "checklist-subgroup"
      }
    } else {
      parent_item_url = `&parent_item=${focusAreas[parent_parent_index].sub_focus_areas[parent_index].id}`
      item_type = "checklist-item"
    }
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/item/?checklist=${location.id}&item_type=${item_type}${parent_item_url}`, {
        headers: login_credentials,
      })
      .then((res) => {
        if (!parent_item) {
          setFocusAreaLoader(false)
          if (checklist_type === "self-assessment-simple") {
            setQuestions(res.data.results)
          } else {
            setFocusAreas(res.data.results)
          }
        } else if (parent_item === "checklist-group") {
          let focus_areas = [...focusAreas]
          let focus_area = focus_areas[parent_index]
          if (checklist_type === "self-assessment-adv2") {
            focus_area.sub_focus_areas = res.data.results
          } else {
            focus_area.questions = res.data.results
          }
          setFocusAreas(focus_areas)
        } else {
          let focus_areas = [...focusAreas]
          let focus_area = focus_areas[parent_parent_index]
          let sub_focus_area = focus_area.sub_focus_areas[parent_index]
          sub_focus_area.questions = res.data.results
          setFocusAreas(focus_areas)
        }
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function createItem(parent_item = "", parent_index = null, parent_parent_index = null) {
    let focus_areas = [...focusAreas]
    let new_item = {}
    if (parent_item === "checklist-group") {
      let current_focus_area = focus_areas[parent_index]
      current_focus_area.child_create_loader = true
      setFocusAreas(focus_areas)
      new_item.parent_item = current_focus_area.id
      if (checklist.checklist_type === "self-assessment-adv1") {
        new_item.item_name = "Item " + (current_focus_area.questions.length + 1)
        new_item.item_type = "checklist-item"
      } else {
        new_item.item_name = "Subgroup " + (current_focus_area.sub_focus_areas.length + 1)
        new_item.item_type = "checklist-subgroup"
      }
    } else if (parent_item === "checklist-subgroup") {
      let current_focus_area = focus_areas[parent_parent_index]
      let sub_focus_area = current_focus_area.sub_focus_areas[parent_index]
      sub_focus_area.child_create_loader = true
      setFocusAreas(focus_areas)
      new_item.item_name = "Item " + (sub_focus_area.questions.length + 1)
      new_item.item_type = "checklist-item"
      new_item.parent_item = sub_focus_area.id
    } else {
      setFocusAreaCreateLoader(true)
      if (checklist.checklist_type === "self-assessment-simple") {
        new_item.item_name = "Item " + (checklistQuestions.length + 1)
        new_item.item_type = "checklist-item"
      } else {
        new_item.item_name = "Group " + (focusAreas.length + 1)
        new_item.item_type = "checklist-group"
      }
    }
    new_item.checklist = checklist.id
    var formData = new FormData()
    for (var k in new_item) {
      formData.append(k, new_item[k])
    }
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/checklist/item/", formData, { headers: login_credentials })
      .then((res) => {
        let created_item = res.data.created_item
        if (parent_item === "checklist-group") {
          let focus_areas = [...focusAreas]
          let focus_area = focus_areas[parent_index]
          if (checklist.checklist_type === "self-assessment-adv1") {
            let current_questions = focus_area.questions
            current_questions.push(created_item)
          } else {
            let current_subfocus_areas = focus_area.sub_focus_areas
            if (created_item.auto_split_weightage) {
              let weightage_data = res.data.weightage_data
              for (var i = current_subfocus_areas.length - 1; i >= 0; i--) {
                current_subfocus_areas[i].item_weightage = weightage_data[current_subfocus_areas[i].id]
              }
            }
            current_subfocus_areas.push(created_item)
            setCreatedSubFocusArea(created_item.id)
          }
          focus_area.child_create_loader = false
          setFocusAreas(focus_areas)
        } else if (parent_item === "checklist-subgroup") {
          let focus_areas = [...focusAreas]
          let current_focus_area = focus_areas[parent_parent_index]
          let sub_focus_area = current_focus_area.sub_focus_areas[parent_index]
          sub_focus_area.child_create_loader = false
          sub_focus_area.questions.push(res.data.created_item)
          setFocusAreas(focus_areas)
        } else {
          if (checklist.checklist_type === "self-assessment-simple") {
            let current_questions = [...checklistQuestions]
            current_questions.push(created_item)
            setQuestions(current_questions)
          } else {
            let current_focus_areas = [...focusAreas]
            if (created_item.auto_split_weightage) {
              let weightage_data = res.data.weightage_data
              for (let i = current_focus_areas.length - 1; i >= 0; i--) {
                current_focus_areas[i].item_weightage = weightage_data[current_focus_areas[i].id]
              }
            }
            current_focus_areas.push(res.data.created_item)
            setFocusAreas(current_focus_areas)
            setCreatedFocusArea(created_item.id)
          }
          setFocusAreaCreateLoader(false)
        }
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function updateItem(item_id, name, value) {
    var formData = new FormData()
    formData.append(name, value)
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/item/${item_id}/`, formData, { headers: login_credentials })
      .then((res) => {})
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function updateFocusArea(name, value, item_id, type, group_index, sub_group_index, que_index) {
    let focus_areas = [...focusAreas]
    let focus_area = {}
    let sub_focus_area = {}
    if (type === "checklist-group") {
      focus_area = focus_areas[group_index]
      focus_area[name] = value
      setFocusAreas(focus_areas)
    } else if (type === "checklist-subgroup") {
      focus_area = focus_areas[group_index]
      sub_focus_area = focus_area.sub_focus_areas[sub_group_index]
      sub_focus_area[name] = value
      setFocusAreas(focus_areas)
    } else {
      if (checklist.checklist_type === "self-assessment-simple") {
        let current_questions = [...checklistQuestions]
        let question = current_questions[que_index]
        question[name] = value
        setQuestions(current_questions)
      } else if (checklist.checklist_type === "self-assessment-adv1") {
        focus_area = focus_areas[group_index]
        let current_questions = focus_area.questions
        let question = current_questions[que_index]
        question[name] = value
        setFocusAreas(focus_areas)
      } else {
        focus_area = focus_areas[group_index]
        sub_focus_area = focus_area.sub_focus_areas[sub_group_index]
        let current_questions = sub_focus_area.questions
        let question = current_questions[que_index]
        question[name] = value
        setFocusAreas(focus_areas)
      }
    }
    var formData = new FormData()
    formData.append(name, value)
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/item/${item_id}/`, formData, { headers: login_credentials })
      .then((res) => {})
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  // Checklist rules CRED operations
  function fetchChecklistRules() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/rule/?checklist=${location.id}`, { headers: login_credentials })
      .then((res) => {
        setChecklistRules(res.data.results)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function deleteChecklistRules(rule_id, index) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/rule/${rule_id}/`, { headers: login_credentials })
      .then((res) => {
        let rules = [...checklistRules]
        rules.splice(index, 1)
        setChecklistRules(rules)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function createChecklistRule() {
    let rule = {}
    rule.checklist = location.id
    rule.label = "label"
    rule.message = "message"
    var formData = new FormData()
    for (var k in rule) {
      formData.append(k, rule[k])
    }
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/rule/`, formData, { headers: login_credentials })
      .then((res) => {
        let rules = [...checklistRules]
        rules.push(res.data)
        setChecklistRules(rules)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  function updateChecklistRule(id, name, value, index) {
    var formData = new FormData()
    formData.append(name, value)
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/rule/${id}/`, formData, { headers: login_credentials })
      .then((res) => {})
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  function showErrorMessage(error) {
    let error_messages = ""
    for (var key in error.response.data) {
      error_messages += error.response.data[key] + "<br>"
    }
    if (error_messages === "Invalid token.<br>") {
      authClient.signOut()
      localStorage.removeItem("authenticity_token")
      localStorage.removeItem("username")
      window.location.reload(true)
    }
    document.getElementById("fixed-error-div").innerHTML =
      '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
      '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
      '<span aria-hidden="true">×</span></button>' +
      error_messages +
      "</div>"
  }

  // Checklist handling
  function showNameFields() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-0">
        <InputFieldWrap>
          <input
            id="name"
            name="name"
            defaultValue={checklist.name}
            onChange={(e) => {
              e.preventDefault()
              handleChange(e)
              delayedQuery(e.target.name, e.target.value)
            }}
            type="text"
            className={`${StylesA.inputText} fs-16px pt-1`}
            style={{ background: "transparent", borderColor: "transparent" }}
            autoComplete="off"
            required
          />
          <span className={`${StylesA.floating_label} text-color-1 d-flex-align-center`}>
            <span>Checklist Name</span>
          </span>
        </InputFieldWrap>
        {checklist.name ? (
          ""
        ) : (
          <Col xs="12" sm="12" md="12" lg="12" className={`text-left px-0 d-flex align-items-baseline mb-2`}>
            <Image className="mt-1 mr-1 h-13px" src={ExclamationTriangle} />
            <p className="mb-0 fs-14px color-AA5746">Name is required</p>
          </Col>
        )}
      </Col>
    )
  }
  function handleChange(event = null, name = null, value = null, update_checklist = false) {
    if (!((name === "checklist_type" || name === "selected_response_group") && checklist[name] === value)) {
      let current_checklist = { ...checklist }
      current_checklist[name || event.target.name] = value || event.target.value
      setChecklist(current_checklist)
      if (update_checklist) {
        updateChecklist(name, value)
      }
    }
  }
  function setCourseType(checklist_type) {
    let current_checklist = { ...checklist }
    current_checklist["checklist_type"] = checklist_type
    setChecklist(current_checklist)
    updateChecklist("checklist_type", checklist_type)
    setShowChecklistTypes(false)
  }
  function showChecklistTypeCards() {
    return checklistTypes.map((checklist_type, index) => {
      return (
        <Col
          key={checklistTypes[index]}
          onClick={() => {
            if (checklist_type === "Self Assessment (Advanced)") {
              setShowCheckListLayoutModal(true)
            } else {
              handleChange(null, "checklist_type", checklistTypeNames[index], true)
            }
          }}
          lg="3"
          md="3"
          sm="3"
          xs="3"
          className="pointer"
          style={{ padding: "8px" }}
        >
          <div
            className={`${checklistTypeValues[checklist.checklist_type] === checklistTypes[index] ? StylesB.white_border : ""} ${
              StylesB.course_type_card
            } card_border_on_hover`}
          >
            <div className={`${StylesB.card_header} p-2`} style={{ backgroundImage: `url(${CourseImg})` }}>
              <p style={{ maxWidth: "53%" }}>{checklistTypes[index] === "Self Assessment (Basic)" ? "Basic Checklist" : "Advanced Checklist"}</p>
            </div>
            <div className={`${StylesB.card_body} p-2`}>
              <p>{checklistDescription[index]}</p>
            </div>
          </div>
        </Col>
      )
    })
  }
  function checklistTypeSelectionSection() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className={`${checklist.name ? "" : "disabled-div"} ${StylesA.type_selection_section} px-0`}>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="d-flex pointer justify-content-between"
          onClick={() => setShowChecklistTypes(!showChecklistTypes)}
          style={{ padding: "12px" }}
        >
          <p className="mb-0" style={{ fontSize: "16px", color: "#838B9E", lineHeight: 1 }}>
            Checklist Type{" "}
            <span style={{ color: "#E0E2E5", fontWeight: "bold" }}>{checklist.checklist_type ? `: ${checklistTypeValues[checklist.checklist_type]}` : ""}</span>
          </p>
          <Image
            className="pointer h-20px"
            onClick={() => setShowChecklistTypes(!showChecklistTypes)}
            src={showChecklistTypes ? ChevronCircleUp : ChevronCircleDown}
          />
        </Col>
        <Collapse in={showChecklistTypes}>
          <div id="example-collapse-text">
            <Col lg="12" md="12" sm="12" xs="12" className="px-3 pt-2" style={{ borderTop: "1px solid #838B9E" }}>
              <p style={{ fontSize: "16px", color: "#838B9E", margin: "0px" }}>Please select checklist type</p>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12" className="d-flex flex-wrap" style={{ padding: "8px" }}>
              {showChecklistTypeCards()}
            </Col>
          </div>
        </Collapse>
      </Col>
    )
  }
  // Drag and drop functionality
  function onDragEnd(result) {
    if (!result.destination) {
      return
    }
    let source = result.source
    let destination = result.destination
    let sourceIndex = parseInt(source.index)
    let destIndex = parseInt(destination.index)
    let [sourceParentId, sourceParentIndex, sourceParentParentIndex] = source.droppableId.split("_").map(Number)
    let [destParentId, destParentIndex, destinationParentParentIndex] = destination.droppableId.split("_").map(Number)
    let focus_areas = [...focusAreas]
    let focus_area = {}
    let sub_focus_areas = []
    let sub_focus_area = {}
    let source_focus_area = {}
    let dest_focus_area = {}
    let dest_sub_focus_areas = []
    let dest_sub_focus_area = {}
    let source_sub_focus_area = {}

    // When a group is reorderd handle sequence change
    if (result.type === "moduleDraggable") {
      if (sourceIndex !== destIndex) {
        focus_area = focus_areas[sourceIndex]
        if (destIndex === 0) {
          focus_area.sequence = (parseFloat(focus_areas[0].sequence) - 10).toFixed(20)
        } else if (destIndex === focus_areas.length - 1) {
          focus_area.sequence = (parseFloat(focus_areas[focus_areas.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (sourceIndex > destIndex) {
            focus_area.sequence = ((parseFloat(focus_areas[destIndex - 1].sequence) + parseFloat(focus_areas[destIndex].sequence)) / 2).toFixed(20)
          } else if (sourceIndex < destIndex) {
            focus_area.sequence = ((parseFloat(focus_areas[destIndex].sequence) + parseFloat(focus_areas[destIndex + 1].sequence)) / 2).toFixed(20)
          }
        }
        //Update the focus area
        updateItem(focus_area.id, "sequence", focus_area.sequence)
        // Reorder items
        focus_areas = reorder(focus_areas, sourceIndex, destIndex)
        setFocusAreas(focus_areas)
      }
    } else if (result.type === "resourceDraggable") {
      let current_questions = []
      if (checklist.checklist_type === "self-assessment-simple") {
        current_questions = [...checklistQuestions]
      } else if (checklist.checklist_type === "self-assessment-adv1") {
        focus_area = focus_areas[sourceParentIndex]
        current_questions = focus_area.questions
      } else {
        focus_area = focus_areas[sourceParentParentIndex]
        sub_focus_areas = focus_area.sub_focus_areas
        sub_focus_area = sub_focus_areas[sourceParentIndex]
        current_questions = sub_focus_area.questions
      }
      if (sourceParentId === destParentId) {
        if (sourceIndex !== destIndex) {
          let question = current_questions[sourceIndex]
          if (destIndex === 0) {
            question.sequence = (parseFloat(current_questions[0].sequence) - 10).toFixed(20)
          } else if (destIndex === current_questions.length - 1) {
            question.sequence = (parseFloat(current_questions[current_questions.length - 1].sequence) + 10).toFixed(20)
          } else {
            if (sourceIndex > destIndex) {
              question.sequence = ((parseFloat(current_questions[destIndex - 1].sequence) + parseFloat(current_questions[destIndex].sequence)) / 2).toFixed(20)
            } else if (sourceIndex < destIndex) {
              question.sequence = ((parseFloat(current_questions[destIndex].sequence) + parseFloat(current_questions[destIndex + 1].sequence)) / 2).toFixed(20)
            }
          }
          //Update the focus area
          updateItem(question.id, "sequence", question.sequence)
          current_questions = reorder(current_questions, sourceIndex, destIndex)
          if (checklist.checklist_type === "self-assessment-simple") {
            setQuestions(current_questions)
          } else {
            setFocusAreas(focus_areas)
          }
        }
      } else {
        if (checklist.checklist_type === "self-assessment-adv1") {
          source_focus_area = focus_areas[sourceParentIndex]
          dest_focus_area = focus_areas[destParentIndex]
          let dest_questions = dest_focus_area.questions
          let [current_question] = source_focus_area.questions.splice(sourceIndex, 1)
          updateItem(current_question.id, "parent_item", dest_focus_area.id)
          current_question.parent_item = dest_focus_area.id
          if (destIndex === 0 && dest_questions.length === 0) {
            current_question.sequence = parseFloat(destIndex).toFixed(20)
          } else if (destIndex === 0) {
            current_question.sequence = (parseFloat(dest_questions[0].sequence) - 10).toFixed(20)
          } else if (destIndex === dest_questions.length) {
            current_question.sequence = (parseFloat(dest_questions[destIndex - 1].sequence) + 10).toFixed(20)
          } else {
            current_question.sequence = (
              parseFloat(dest_questions[destIndex].sequence) + parseFloat(dest_questions[parseFloat(destIndex) - 1].sequence)
            ).toFixed(20)
          }
          updateItem(current_question.id, "sequence", current_question.sequence)
          dest_questions.splice(destIndex, 0, current_question)
          setFocusAreas(focus_areas)
        } else {
          source_focus_area = focus_areas[sourceParentParentIndex]
          dest_focus_area = focus_areas[destinationParentParentIndex]
          dest_sub_focus_area = dest_focus_area.sub_focus_areas[destParentIndex]
          source_sub_focus_area = source_focus_area.sub_focus_areas[sourceParentIndex]
          let dest_questions = dest_sub_focus_area.questions
          let [current_question] = source_sub_focus_area.questions.splice(sourceIndex, 1)
          updateItem(current_question.id, "parent_item", dest_sub_focus_area.id)
          current_question.parent_item = dest_sub_focus_area.id
          if (destIndex === 0 && dest_questions.length === 0) {
            current_question.sequence = parseFloat(destIndex).toFixed(20)
          } else if (destIndex === 0) {
            current_question.sequence = (parseFloat(dest_questions[0].sequence) - 10).toFixed(20)
          } else if (destIndex === dest_questions.length) {
            current_question.sequence = (parseFloat(dest_questions[destIndex - 1].sequence) + 10).toFixed(20)
          } else {
            current_question.sequence = (
              parseFloat(dest_questions[destIndex].sequence) + parseFloat(dest_questions[parseFloat(destIndex) - 1].sequence)
            ).toFixed(20)
          }
          updateItem(current_question.id, "sequence", current_question.sequence)
          dest_questions.splice(destIndex, 0, current_question)
          setFocusAreas(focus_areas)
        }
      }
    } else if (result.type === "topicDraggable") {
      if (sourceParentId === destParentId) {
        if (sourceIndex !== destIndex) {
          focus_area = focus_areas[sourceParentIndex]
          sub_focus_areas = focus_area.sub_focus_areas
          sub_focus_area = sub_focus_areas[sourceIndex]
          if (destIndex === 0) {
            sub_focus_area.sequence = (parseFloat(sub_focus_areas[0].sequence) - 10).toFixed(20)
          } else if (destIndex === sub_focus_areas.length - 1) {
            sub_focus_area.sequence = (parseFloat(sub_focus_areas[sub_focus_areas.length - 1].sequence) + 10).toFixed(20)
          } else {
            if (sourceIndex > destIndex) {
              sub_focus_area.sequence = ((parseFloat(sub_focus_areas[destIndex - 1].sequence) + parseFloat(sub_focus_areas[destIndex].sequence)) / 2).toFixed(
                20
              )
            } else if (sourceIndex < destIndex) {
              sub_focus_area.sequence = ((parseFloat(sub_focus_areas[destIndex].sequence) + parseFloat(sub_focus_areas[destIndex + 1].sequence)) / 2).toFixed(
                20
              )
            }
          }
          //Update the focus area
          updateItem(sub_focus_area.id, "sequence", sub_focus_area.sequence)
          sub_focus_areas = reorder(sub_focus_areas, sourceIndex, destIndex)
          setFocusAreas(focus_areas)
        }
      } else {
        source_focus_area = focus_areas[sourceParentIndex]
        dest_focus_area = focus_areas[destParentIndex]
        dest_sub_focus_areas = dest_focus_area.sub_focus_areas
        sub_focus_area = source_focus_area.sub_focus_areas.splice(sourceIndex, 1)[0]
        updateItem(sub_focus_area.id, "parent_item", dest_focus_area.id)
        sub_focus_area.parent_item = dest_focus_area.id
        if (destIndex === 0 && dest_sub_focus_areas.length === 0) {
          sub_focus_area.sequence = parseFloat(destIndex).toFixed(20)
        } else if (destIndex === 0) {
          sub_focus_area.sequence = (parseFloat(dest_sub_focus_areas[0].sequence) - 10).toFixed(20)
        } else if (destIndex === dest_sub_focus_areas.length) {
          sub_focus_area.sequence = (parseFloat(dest_sub_focus_areas[destIndex - 1].sequence) + 10).toFixed(20)
        } else {
          sub_focus_area.sequence = (
            parseFloat(dest_sub_focus_areas[destIndex].sequence) + parseFloat(dest_sub_focus_areas[parseFloat(destIndex) - 1].sequence)
          ).toFixed(20)
        }
        updateItem(sub_focus_area.id, "sequence", sub_focus_area.sequence)
        dest_sub_focus_areas.splice(destIndex, 0, sub_focus_area)
        setFocusAreas(focus_areas)
      }
    }
  }
  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1)
    list.splice(endIndex, 0, removed)
    return list
  }

  // Focus area handling
  function defineChecklistSection() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className={`${checklist.checklist_type ? "" : "disabled-div"} ${StylesA.type_selection_section} px-0`}>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="d-flex pointer justify-content-between"
          onClick={() => setShowDefineChecklistSection(!showDefineChecklistSection)}
          style={{ padding: "12px" }}
        >
          <p className="mb-0" style={{ fontSize: "16px", color: "#838B9E", lineHeight: 1 }}>
            Define Checklist
          </p>
          <Image
            className="pointer h-20px"
            onClick={() => setShowDefineChecklistSection(!showDefineChecklistSection)}
            src={showDefineChecklistSection ? ChevronCircleUp : ChevronCircleDown}
          />
        </Col>
        <Collapse in={showDefineChecklistSection}>
          <div id="example-collapse-text">
            <DragDropContext onDragEnd={onDragEnd}>
              <Col lg="12" md="12" sm="12" xs="12" style={{ padding: "12px", borderTop: "1px solid #838B9E" }}>
                {checklist.checklist_type === "self-assessment-simple" ? showQuestionsSection() : showGroupsSection()}
              </Col>
            </DragDropContext>
          </div>
        </Collapse>
      </Col>
    )
  }
  function showGroupsSection() {
    return (
      <Droppable droppableId={`moduleDraggable`} type={`moduleDraggable`}>
        {(provided, snapshot) => (
          <React.Fragment>
            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {showFocusAreas()}
              {provided.placeholder}
            </div>
            {showFocusAreaFooter()}
          </React.Fragment>
        )}
      </Droppable>
    )
  }
  function showQuestionsSection() {
    return (
      <Droppable type={`resourceDraggable`} droppableId={`0_0`}>
        {(provided, snapshot) => (
          <div id={`drop-area`} className={`bg-transparent px-4 py-3`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {showQuestions()}
            {provided.placeholder}
            {showQuestionsFooter()}
          </div>
        )}
      </Droppable>
    )
  }
  function showQuestionsFooter() {
    return (
      <Button disabled={focusAreaCreateLoader} className={`${StylesA.focus_area_footer} py-1 border_on_hover`} variant="none" onClick={(e) => createItem()}>
        {focusAreaCreateLoader ? (
          <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />
        ) : (
          <Image className="pointer mr-2 h-14px" src={Plus} />
        )}
        Add more items
      </Button>
    )
  }

  function showQuestions() {
    if (focusAreaLoader) {
      return showLoader()
    } else {
      if (checklistQuestions.length > 0) {
        return checklistQuestions.map((question, index) => {
          return (
            <Question
              key={"checklit_question_" + question.id.toString() + index.toString()}
              question={question}
              index={index}
              checklist={checklist}
              removeQuestion={removeQuestion}
              showErrorMessage={showErrorMessage}
              setEditedItem={setEditedItem}
            />
          )
        })
      } else {
        return <p className={`${StylesA.empty_content_text} pb-2`}>No items added yet!</p>
      }
    }
  }
  function showLoader() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
        <Spinner animation="border" variant="secondary" size="sm" />
      </Col>
    )
  }
  function removeQuestion(type, index, parent_index, parent_parent_index) {
    let focus_areas = [...focusAreas]
    if (!type) {
      let current_questions = [...checklistQuestions]
      current_questions.splice(index, 1)
      setQuestions(current_questions)
    } else if (type === "checklist-subgroup") {
      let current_focus_area = focus_areas[parent_parent_index]
      let sub_focus_area = current_focus_area.sub_focus_areas[parent_index]
      sub_focus_area.questions.splice(index, 1)
      setFocusAreas(focus_areas)
    } else {
      let current_focus_area = focus_areas[parent_index]
      current_focus_area.questions.splice(index, 1)
      setFocusAreas(focus_areas)
    }
  }
  function showFocusAreas() {
    if (focusAreaLoader) {
      return showLoader()
    } else {
      if (focusAreas.length > 0) {
        return focusAreas.map((focus_area, index) => {
          return (
            <FocusArea
              key={focus_area.id.toString()}
              focus_area={focus_area}
              index={index}
              checklist={checklist}
              fetchItems={fetchItems}
              removeFocusArea={removeFocusArea}
              showErrorMessage={showErrorMessage}
              createdFocusArea={createdFocusArea}
              createItem={createItem}
              createdSubFocusArea={createdSubFocusArea}
              removeSubFocusArea={removeSubFocusArea}
              removeQuestion={removeQuestion}
              setEditedItem={setEditedItem}
            />
          )
        })
      } else {
        return <p className={`${StylesA.empty_content_text} mb-1`}>No groups created yet!</p>
      }
    }
  }
  function showFocusAreaFooter() {
    return (
      <Button disabled={focusAreaCreateLoader} className={`${StylesA.focus_area_footer} py-1 border_on_hover`} variant="none" onClick={(e) => createItem()}>
        {focusAreaCreateLoader ? (
          <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />
        ) : (
          <Image className="pointer mr-2 h-14px" src={Plus} />
        )}
        Add Group
      </Button>
    )
  }
  function removeFocusArea(index, weightage_data, auto_split_weightage) {
    let current_focus_areas = [...focusAreas]
    current_focus_areas.splice(index, 1)
    if (auto_split_weightage && Object.keys(weightage_data).length > 0) {
      for (var i = current_focus_areas.length - 1; i >= 0; i--) {
        if (weightage_data[current_focus_areas[i].id]) {
          current_focus_areas[i].item_weightage = weightage_data[current_focus_areas[i].id]
        }
      }
    }
    setFocusAreas(current_focus_areas)
  }
  function removeSubFocusArea(index, weightage_data, auto_split_weightage, parent_index) {
    let focus_areas = [...focusAreas]
    let focus_area = focus_areas[parent_index]
    let current_subfocus_areas = focus_area.sub_focus_areas
    current_subfocus_areas.splice(index, 1)
    if (auto_split_weightage && Object.keys(weightage_data).length > 0) {
      for (var i = current_subfocus_areas.length - 1; i >= 0; i--) {
        if (weightage_data[current_subfocus_areas[i].id]) {
          current_subfocus_areas[i].item_weightage = weightage_data[current_subfocus_areas[i].id]
        }
      }
    }
    setFocusAreas(focus_areas)
  }

  // Response type handling
  function defineResponsesSection() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className={`${checklist.checklist_type ? "" : "disabled-div"} ${StylesA.type_selection_section} px-0`}>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="d-flex pointer justify-content-between"
          onClick={() => setShowDefineResponsesSection(!showDefineResponsesSection)}
          style={{ padding: "12px" }}
        >
          <p className="mb-0" style={{ fontSize: "16px", color: "#838B9E", lineHeight: 1 }}>
            Define Response {showDefineResponsesSection ? " - select any or define your own" : ""}
          </p>
          <Image
            className="pointer h-20px"
            onClick={() => setShowDefineResponsesSection(!showDefineResponsesSection)}
            src={showDefineResponsesSection ? ChevronCircleUp : ChevronCircleDown}
          />
        </Col>
        <Collapse in={showDefineResponsesSection}>
          <div id="response-type-collapse">
            <Col lg="12" md="12" sm="12" xs="12" className={`${StylesA.scroll_arrow_parent} px-0`} style={{ borderTop: "1px solid #838B9E" }}>
              <ScrollArrows scroll_div={"response_groups_div"} items={responseGroups.length} />
              <Col lg="12" md="12" sm="12" xs="12" id="response_groups_div" className="d-flex" style={{ padding: "12px", overflowX: "auto" }}>
                {showResponseTypes()}
              </Col>
            </Col>
            {showResponseFooter()}
          </div>
        </Collapse>
      </Col>
    )
  }
  function showResponseFooter() {
    return (
      <div className="p-3">
        <Button
          disabled={responseTypeCreateLoader}
          className={`${StylesB.add_button} border_on_hover d-flex`}
          variant="none"
          onClick={(e) => {
            setshowCreateResponseGroupModal(true)
            createResponseType()
          }}
        >
          {!responseTypeCreateLoader ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
          Create Your Own
        </Button>
      </div>
    )
  }
  function showResponseTypes() {
    return responseGroups.map((response_group, index) => {
      let edit_enabled =
        (createdResponseGroup && createdResponseGroup.id === response_group.id) || (editingResponseGroup && editingResponseGroup.id === response_group.id)
          ? true
          : false
      return responseTypeCard(response_group, index, edit_enabled)
    })
  }
  function responseTypeCard(response_group, index, edit_enabled) {
    return (
      <Col
        key={response_group.id.toString()}
        lg="3"
        md="3"
        sm="4"
        xs="5"
        className={`${StylesA.response_type_card} border_on_hover mx-1 p-2 ${edit_enabled ? "pb-5" : ""}`}
      >
        <div className="d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            <div
              onClick={() => {
                handleChange(null, "selected_response_group", response_group.id, true)
              }}
              className={`${StylesA.response_selection_radio_button} mr-2`}
            >
              {checklist.selected_response_group === response_group.id ? <Image className={`${StylesA.check_icon}`} src={Check} /> : ""}
            </div>
            {responseGroupName(response_group, index, edit_enabled)}
          </div>
          <Dropdown className="breakout-groups move-dropdown" style={{ maxHeight: "22px" }}>
            <Dropdown.Toggle className="border-none p-0" style={{ backgroundColor: "transparent", verticalAlign: "top" }} id="dropdown-basic">
              <Image className="pointer h-12px mb-1" src={EllipsisV} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {edit_enabled ? (
                ""
              ) : (
                <Dropdown.Item onClick={() => handleResponseTypeEdit(response_group, index)} className="text-white fs-14px px-2">
                  <img src={EditIcon} alt={`response_type_edit`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `13px` }} />
                  Edit
                </Dropdown.Item>
              )}
              {response_group.owner && checklist.selected_response_group !== response_group.id ? (
                <Dropdown.Item onClick={() => handleResponseTypeDelete(response_group, index)} className="last_drop_item text-white fs-14px px-2">
                  <img
                    src={DeleteIcon}
                    alt={`response_type_delete`}
                    className={`my-auto ml-1 pointer`}
                    style={{ objectFit: `contain`, height: `13px`, marginRight: "12px" }}
                  />
                  Delete
                </Dropdown.Item>
              ) : (
                ""
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {showResponseOptions(response_group, index, edit_enabled)}
        {addResponseOptionButton(response_group.id, index, edit_enabled)}
      </Col>
    )
  }
  function handleResponseTypeDelete(response_group, index) {
    if (response_group.owner) {
      deleteResponseType(response_group, index)
    } else {
      unlinkResponseType(response_group.id, index)
    }
  }
  function handleResponseTypeEdit(response_group, index) {
    if (response_group.owner) {
      setEditingResponseGroup(response_group)
    } else {
      duplicateResponseType(response_group.id, index)
    }
  }
  function responseGroupName(response_group, index, edit_enabled) {
    return (
      <input
        name="response_group_name"
        disabled={!edit_enabled}
        defaultValue={response_group.response_group_name}
        autoFocus={true}
        autoComplete="off"
        onChange={(e) => {
          handleCreatedResponeTypeChange(e)
          delayedResponseQuery(response_group.id, e.target.name, e.target.value)
        }}
        type="text"
        className={`${StylesA.response_group_name_input}`}
        style={{ background: "transparent", borderColor: "transparent", width: "calc(100% - 29px)" }}
        required
      />
    )
  }
  function handleCreatedResponeTypeChange(event) {
    if (showCreateResponseGroupModal) {
      let response_group = { ...createdResponseGroup }
      response_group[event.target.name] = event.target.value
      setCreatedResponseGroup(response_group)
    } else {
      return null
    }
  }
  function createResponseTypeModal() {
    return (
      <Modal
        centered
        contentClassName={`${StylesA.create_response_modal_content}`}
        show={showCreateResponseGroupModal}
        onHide={() => handleCreateModalClose()}
        className={`${StylesA.create_response_modal}`}
      >
        <div className={`${StylesA.delete_modal_header}`}>
          <p>Define your own response</p>
          <img
            onClick={() => handleCreateModalClose(false)}
            src={CancelWhite}
            alt={`cancel_create_response`}
            className={`mb-0 pointer`}
            style={{ objectFit: `contain`, height: `13px` }}
          />
        </div>
        <Modal.Body className={`${StylesA.delete_modal_body} text-center`}>{showCreatedResponseType()}</Modal.Body>
      </Modal>
    )
  }
  function showCreatedResponseType() {
    if (createdResponseGroup) {
      let index = responseGroups.length - 1
      return responseTypeCard(createdResponseGroup, index, true)
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  function handleCreateModalClose() {
    setshowCreateResponseGroupModal(false)
    let current_response_groups = [...responseGroups]
    current_response_groups.push(createdResponseGroup)
    setResponseGroups(current_response_groups)
    setCreatedResponseGroup("")
    setEditingResponseGroup("")
  }

  // Response option handling
  function showResponseOptions(response_group, response_group_index, edit_enabled) {
    let options = response_group.checklist_rg_options
    if (options) {
      return options.map((option, index) => {
        return (
          <div key={option.id.toString()} className={`${edit_enabled ? "d-flex-space-between" : ""}`}>
            <div className={`${StylesA.response_option_card} mt-1 p-2`}>
              {responseOptionName(option, index, response_group, edit_enabled)}
              <div className={`${StylesA.response_option_point}`}>{index}</div>
            </div>
            {edit_enabled ? (
              <img
                onClick={() => deleteResponseOption(option.id, index, response_group_index)}
                src={DeleteIcon}
                alt={`response_option_delete`}
                className={`mb-0 ml-3 pointer`}
                style={{ height: `13px` }}
              />
            ) : (
              ""
            )}
          </div>
        )
      })
    } else {
      return null
    }
  }
  function responseOptionName(option, index, response_group, edit_enabled) {
    return (
      <input
        name="option_name"
        disabled={!edit_enabled}
        defaultValue={option.option_name}
        autoComplete="off"
        onChange={(e) => {
          handleCreatedResponeOptionChange(e, index)
          delayedResponseQuery(option.id, e.target.name, e.target.value, "response_option")
        }}
        type="text"
        className={`${StylesA.response_group_name_input}`}
        placeholder="Option name"
        style={{ background: "transparent", borderColor: "transparent", width: "calc(100% - 29px)" }}
        required
      />
    )
  }
  function handleCreatedResponeOptionChange(event, index) {
    if (showCreateResponseGroupModal) {
      let response_group = { ...createdResponseGroup }
      let option = createdResponseGroup.checklist_rg_options[index]
      option[event.target.name] = event.target.value
      createdResponseGroup.checklist_rg_options[index] = option
      setCreatedResponseGroup(response_group)
    } else {
      return null
    }
  }
  function addResponseOptionButton(response_group, response_group_index, edit_enabled) {
    if (edit_enabled) {
      return (
        <div className={`${StylesA.add_response_option_div} d-flex-space-between pt-1`}>
          <button
            onClick={() => {
              showCreateResponseGroupModal ? handleCreateModalClose() : setEditingResponseGroup("")
              setCreatedResponseGroup("")
            }}
            className={`${StylesA.response_edit_done_button} border_on_hover fs-12px px-2`}
          >
            Done
          </button>
          <button onClick={() => createResponseOption(response_group, response_group_index)} className={`${StylesA.add_response_button}`}>
            Add more response
          </button>
        </div>
      )
    } else {
      return null
    }
  }

  // Checklist rule handling
  function showChecklistRules() {
    if (checklistRules.length > 0) {
      return checklistRules.map((rule, index) => {
        let edit_enabled = rule.id === editingRule
        return (
          <ChecklistRule
            key={rule.id.toString()}
            datum={rule}
            edit_enabled={edit_enabled}
            index={index}
            deleteChecklistRules={deleteChecklistRules}
            showErrorMessage={showErrorMessage}
            reorderRules={reorderRules}
          />
        )
      })
    } else {
      return <p className={`${StylesA.empty_content_text}`}>No rules created yet!</p>
    }
  }
  function defineRulesSection() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className={`${checklist.checklist_type ? "" : "disabled-div"} ${StylesA.type_selection_section} px-0`}>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="d-flex pointer"
          onClick={() => setShowDefineRulesSection(!showDefineRulesSection)}
          style={{ justifyContent: "space-between", padding: "12px" }}
        >
          <p className="mb-0" style={{ fontSize: "16px", color: "#838B9E", lineHeight: 1 }}>
            Define Rules
          </p>
          <Image
            className="pointer h-20px"
            onClick={() => setShowDefineRulesSection(!showDefineRulesSection)}
            src={showDefineRulesSection ? ChevronCircleUp : ChevronCircleDown}
          />
        </Col>
        <Collapse in={showDefineRulesSection}>
          <div id="checklist-rules-collapse">
            <Col lg="12" md="12" sm="12" xs="12" style={{ padding: "12px", borderTop: "1px solid #838B9E" }}>
              <div className="d-flex" style={{ color: "#E0E2E5", opacity: 0.8, fontSize: "16px" }}>
                <div style={{ width: "30%" }}>Score Range</div>
                <div style={{ width: "30%" }}>Label</div>
                <div style={{ width: "40%" }}>Message</div>
              </div>
              {showChecklistRules()}
              <div className="pt-3">
                <Button
                  disabled={checklistRuleCreateLoader}
                  className={`${StylesB.add_button} border_on_hover d-flex`}
                  variant="none"
                  onClick={(e) => createChecklistRule()}
                >
                  {!checklistRuleCreateLoader ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                  Add More
                </Button>
              </div>
            </Col>
          </div>
        </Collapse>
      </Col>
    )
  }
  function reorderRules(rule, index) {
    let rules = [...checklistRules]
    rules[index] = rule
    rules.sort(function (a, b) {
      return parseInt(b.score_range_from) - parseInt(a.score_range_from)
    })
    setChecklistRules(rules)
  }
  function handleCloseWin(e) {
    if (window.opener) window.opener.postMessage({ instruction: "payload", type: "checklist" }, window.location.origin)
    window.close()
  }

  return (
    <div>
      <SEO title="Checklist Creation" />
      <Modal
        show={showCheckListLayoutModal}
        size="lg"
        centered
        onHide={() => setShowCheckListLayoutModal(false)}
        className={`${StylesA.create_response_modal} classroom-resource-delete-modal`}
      >
        <Modal.Header className={`${StylesA.delete_modal_header}`}>
          <p style={{ color: "#E0E2E5", fontWeight: "18px" }}>Self Assessment (Advanced)</p>
          <img
            onClick={(e) => {
              e.preventDefault()
              setShowCheckListLayoutModal(false)
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{ objectFit: `contain`, height: `15px`, position: "absolute", top: "10px", right: "5px" }}
          />
        </Modal.Header>
        <Modal.Body className="text-left" style={{ backgroundColor: "#303C54", borderRadius: "0px 0px 8px 8px" }}>
          <p style={{ color: "#E0E2E5", fontSize: "16px", marginBottom: "10px" }}>Please select a checklist layout</p>
          <div className={`d-flex-centered align-items-baseline w-100 px-0`} style={{}}>
            <div
              className={`${StylesA.whole_checklist} ${
                checklist.checklist_type === "self-assessment-adv1" ? StylesA.advance_type_selected : StylesA.advance_type
              } px-3 mr-4 pt-3 pb-4`}
              onClick={(e) => {
                setCourseType("self-assessment-adv1")
                setShowCheckListLayoutModal(false)
              }}
            >
              {checklist.checklist_type === "self-assessment-adv1" ? (
                <div className={`mr-2 w-15px h-15px rounded-circle bg-E0E2E5 position-absolute top--8px right--8px`}></div>
              ) : null}
              <p className={`mb-2`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
                Checklist with groups
              </p>
              <div className={`${StylesA.checklist_groups} pt-2 pl-2`} style={{}}>
                <p className={`mb-2`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
                  Group
                </p>
                <div className={`px-5 pb-4`}>
                  <div className={`${StylesA.sub_groups} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                    item
                  </div>
                  <div className={`${StylesA.sub_groups} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                    item
                  </div>
                  <div className={`${StylesA.sub_groups} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                    item
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${StylesA.whole_checklist} ${
                checklist.checklist_type === "self-assessment-adv2" ? StylesA.advance_type_selected : StylesA.advance_type
              } p-3`}
              onClick={(e) => {
                setCourseType("self-assessment-adv2")
                setShowCheckListLayoutModal(false)
              }}
            >
              {checklist.checklist_type === "self-assessment-adv2" ? (
                <div className={`mr-2 w-15px h-15px rounded-circle bg-E0E2E5 position-absolute top--8px right--8px`}></div>
              ) : null}
              <p className={`mb-2`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
                Checklist with groups & subgroups
              </p>
              <div className={`${StylesA.checklist_groups} pt-2 pl-2 pb-4`}>
                <p className={`mb-2`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
                  Group
                </p>
                <div className={`${StylesA.sub_groups} mx-4 mb-2`} style={{}}>
                  <p className={`pl-2 py-2 mb-0`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
                    Sub-group
                  </p>
                  <div className={`px-5 pb-4`}>
                    <div className={`${StylesA.sub_groups_item} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                      item
                    </div>
                    <div className={`${StylesA.sub_groups_item} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                      item
                    </div>
                    <div className={`${StylesA.sub_groups_item} mb-1 pl-1 pr-5`} style={{ fontSize: "14px" }}>
                      item
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {checklist ? (
        <Col xs="12" sm="12" md="12" lg="12" className="pt-0 d-flex align-items-center flex-column">
          <Col xs="12" sm="12" md="10" lg="10" className={`${StylesB.course_creation_header} px-0`}>
            <p className={`${StylesA.checklist_name_header} mb-0 py-2 px-3`}>{checklist.name || "New Checklist"}</p>
          </Col>
          <Col xs="12" sm="12" md="10" lg="10" className="px-0">
            <div className={`${StylesA.checklist_content_div} px-3`}>
              {showNameFields()}
              {checklistTypeSelectionSection()}
              {defineChecklistSection()}
              {defineResponsesSection()}
              {defineRulesSection()}
            </div>
            <Col className={`${StylesA.checklist_main_footer} px-0 pl-3 py-2 d-flex`}>
              <button disabled={loader} className={`${StylesA.publish_button} border_on_hover d-flex`} onClick={() => publishChecklist()}>
                {!loader ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                Publish
              </button>
              {publishError ? (
                <div className={`d-flex align-items-baseline ml-2`}>
                  <Image className="mt-1 mr-1 h-10px" src={ExclamationTriangle} />
                  <p className="mb-0 fs-14px color-AA5746"> {publishError.join(". ")}</p>
                </div>
              ) : (
                ""
              )}
              {window && window.name === "checklist12" && published ? (
                <button onClick={(e) => handleCloseWin(e)} className={`ml-2 ${StylesA.publish_button} border_on_hover d-flex`}>
                  Close
                </button>
              ) : null}
            </Col>
          </Col>
        </Col>
      ) : (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-5">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )}
      {createResponseTypeModal()}
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default EditChecklist
