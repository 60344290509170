import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Pagination from "react-bootstrap/Pagination"
import FormControl from "react-bootstrap/FormControl"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Modal from "react-bootstrap/Modal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons"

import StylesD from "../../modularscss/delete.module.scss"

import Layout from "../layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../seo"
import authClient from "../../services/auth"
import Notification from "../custom/Notification"
import DeleteModal from "../custom/DeleteModal"
import { isBrowser } from "../../services/developer_mode"

import TrashCan from "../../images/svgs/trash-can-transparent.svg"
import CancelWhite from "../../images/svgs/close.svg"

import formatDate from "../../services/format_date"

import quizicon from "../../images/svgs/quizicon.svg"

const Quizzes = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [quizLoader, setQuizLoader] = useState(false)
  const [quizzes, setQuizzes] = useState([])

  const [previous_url, setPreviousUrl] = useState()
  const [next_url, setNextUrl] = useState()
  const [total_pages, setTotalPages] = useState()
  const [notificationData, setNotificationData] = useState(null)

  const [deleteObjectModal, setDeleteObjectModal] = useState([])

  const handleDeleteObjectModal = () => {
    setDeleteObjectModal([])
  }

  useEffect(() => {
    fetchQuizzes()
  }, [])

  async function fetchQuizzes() {
    setQuizLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/quizzes/?adminsite=true&data_type=0`, { headers: login_credentials }).then((res) => {
      setQuizzes(res.data.results)
      setNextUrl(res.data.next)
      setPreviousUrl(res.data.previous)
      setTotalPages(parseInt(Number(res.data.count) / 5))
      setQuizLoader(true)
    })
  }

  function displayQuizHeader() {
    let data = quizzes
    var isLoaded = quizLoader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0])
        let heading = []
        heading.push(<td>&nbsp;</td>)
        heading.push(<td>&nbsp;</td>)
        heading.push(<td>&nbsp;</td>)
        for (let key in keys) {
          if (keys[key].toLowerCase() !== "created" && keys[key].toLowerCase() !== "updated_at") {
            heading.push(<th style={{ textTransform: "uppercase" }}>{keys[key].replace(/_/g, " ")}</th>)
          }
        }
        heading.push(<td>&nbsp;</td>)
        return <tr>{heading}</tr>
      } else {
        return ""
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function displayQuizData() {
    let data = quizzes
    var isLoaded = quizLoader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0]),
          parsed_data = []
        parsed_data.push(
          <div className={`d-flex p-3`}>
            <div className={`pl-4 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Quizzes
              </p>
            </div>
            <div className={`pl-3 ml-auto mr-3 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                No of attempts
              </p>
            </div>
            {/*<div className={`pl-3 ml-auto mr-3 align-self-center`}>
                <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>Published</p>
              </div>*/}
          </div>
        )
        data.map((datum, index) => {
          parsed_data.push(
            <div className={`d-flex mb-1 py-2`} key={index + `_training`} style={{ backgroundColor: `#46537E` }}>
              <div className={`pl-3 align-self-center`}>
                <img src={quizicon} alt={`quiz icon`} className={`my-auto`} style={{ objectFit: `contain`, width: `24px`, height: `24px` }} />
              </div>
              <div className={`pl-3 align-self-center`}>
                <p className={`m-0 font-weight-bold text-white`}>{datum.name}</p>
                <p className={`m-0`} style={{ color: `#27E0C3`, fontSize: "12px" }}>
                  <span>Created on </span>{" "}
                  <span>
                    {" "}
                    {`${formatDate.displayDate(datum.created)}-${formatDate.displayMonth(datum.created)}-${formatDate.displayFullYear(datum.created)} `}{" "}
                  </span>{" "}
                </p>
                <Col className="p-0 d-inline-flex">
                  <Link className={`mr-2`} to={`/app/quizzes/edit/` + datum.id}>
                    {" "}
                    <FontAwesomeIcon icon={faEdit} size="sm" />{" "}
                  </Link>
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Delete Quiz</Tooltip>}>
                    <div
                      className={`mr-2 pointer`}
                      onClick={(e) => {
                        e.preventDefault()
                        setDeleteObjectModal(datum)
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faTrash} style={{ color: `#acacac` }} size="sm" />{" "}
                    </div>
                  </OverlayTrigger>
                  {/*<Link to={`/app/quizzes/edit/`+datum.id} > <FontAwesomeIcon icon={faTrash} size="sm" />  </Link>*/}
                </Col>
              </div>
              <div className={`pl-3 pr-5 ml-auto align-self-center`}>
                <p className={`mb-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                  {datum.no_of_attempts}
                </p>
              </div>
              {/*<div className={`pl-3 ml-auto mr-3 align-self-center`}>
              <p className={`mb-0 font-weight-bold pr-5`} style={{ color: `#C6CFF4` }}>{datum.published ? 'Published' : 'Publish'}</p>
            </div>*/}
            </div>
          )
        })
        return <div>{parsed_data}</div>
      } else {
        return (
          <tr id="loader">
            <td colSpan="12" className="animated fadeIn pt-3 text-center">
              You have not created any quizzes yet! Tap on the ‘Add Quiz’ icon to create quiz.
            </td>
          </tr>
        )
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function deleteData() {
    var index = quizzes.findIndex((res) => res.id === deleteObjectModal.id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/quizzes/" + deleteObjectModal.id + "/", { headers: DefaultHeader, body: deleteObjectModal })
      .then((res) => {
        quizzes.splice(index, 1)
        setDeleteObjectModal([])
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  // function showDelModal(datum) {
  //   return (
  //     <Modal show={Object.keys(deleteObjectModal).length > 0} onHide={handleDeleteObjectModal} centered className={`${StylesD.delete_modal} classroom-resource-delete-modal`} >
  //       <div className={`${StylesD.delete_modal_header}`}>
  //       <img onClick={handleDeleteObjectModal} src={CancelWhite} alt={`Cancel`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, height: `20px`,position:'absolute',top:'10px',right:'5px' }}/>
  //         <img src={TrashCan} alt={`delete modal delete icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `20px` }}/>
  //         <div>
  //          <p style={{fontWeight:'bold',fontSize:'15px'}}>Delete</p>
  //         </div>
  //       </div>
  //       <Modal.Body className="text-center" style={{backgroundColor:'#121b2b',borderRadius: '0px 0px 8px 8px'}} >
  //         <p style={{color: '#E0E2E5',fontSize:'15px',marginBottom:'10px'}} > Are you sure to delete ? </p>
  //         <p style={{color: '#E0E2E5', fontWeight:'bold',fontSize:'17px'}}>{deleteObjectModal && deleteObjectModal.name ? deleteObjectModal.name : 'Class'}</p>
  //         <div className="d-flex justify-content-center" >
  //           <Button className={`${StylesD.add_button_large} border_on_hover mr-3`} variant="secondary" onClick={() => {deleteData(deleteObjectModal)}} style={{width:'75px',border:'1px solid transparent'}} >Yes</Button>
  //           <Button className={`${StylesD.add_button_large} border_on_hover`} variant="secondary" onClick={handleDeleteObjectModal} style={{width:'75px',border:'1px solid transparent'}} >No</Button>
  //         </div>
  //       </Modal.Body>
  //     </Modal>
  //   )
  // }

  function showQuizzes() {
    if (authClient.isAuthenticated()) {
      return (
        <table>
          {/*displayQuizHeader()*/}
          <tbody>{displayQuizData()}</tbody>
        </table>
      )
    } else {
      return ""
    }
  }

  function fetchPage(page) {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/quizzes/?page=" + page + "&data_type=0", { headers: DefaultHeader })
      .then((res) => {
        setQuizzes(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function fetchNext() {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(next_url, { headers: DefaultHeader })
      .then((res) => {
        setQuizzes(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }
  function fetchPrevious() {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(previous_url, { headers: DefaultHeader })
      .then((res) => {
        setQuizzes(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function renderPageQuizzes() {
    var rows = []
    if (previous_url) {
      rows.push(<Pagination.Prev className={`m-0 bg-transparent`} onClick={() => fetchPrevious()} />)
    }
    for (var i = 0; i < total_pages; i++) {
      let temp_var = i + 1
      rows.push(
        <Pagination.Item key={i + 1} className={`m-0 bg-transparent`} onClick={() => fetchPage(temp_var)}>
          {i + 1}
        </Pagination.Item>
      )
    }
    if (next_url) {
      rows.push(<Pagination.Next className={`m-0 bg-transparent`} onClick={() => fetchNext()} />)
    }
    return (
      <Pagination className={`bg-card mx-0 my-3`} style={{ width: `fit-content` }}>
        {rows}
      </Pagination>
    )
  }

  function searchQuizzes(event) {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/quizzes/?search=" + event.target.value + "&data_type=0", { headers: DefaultHeader })
      .then((res) => {
        setQuizzes(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  return (
    <div>
      <SEO title="Quizzes" />
      <Col lg="12" md="12" sm="12" xs="12">
        <h4 className={`mt-3`}>
          All Quizzes
          <Button
            onClick={() => {
              navigate("/app/quizzes/create")
            }}
            style={{ background: "#485b9e", border: "none", fontSize: "14px", float: "right" }}
          >
            + Add Quiz
          </Button>
        </h4>
        <div className={`col-12 d-inline-flex`} style={{ backgroundColor: `#414a6c`, borderRadius: "25px" }}>
          <FontAwesomeIcon icon={faSearch} size="lg" style={{ margin: `auto 0px`, color: `#7c84a0` }} />
          <FormControl
            autoFocus
            className="border-0 py-1 px-2 form-control px-0 shadow-none font-14"
            style={{ backgroundColor: `transparent`, color: `#b6bdd4` }}
            placeholder={`search Quiz`}
            onChange={searchQuizzes}
            // value={searchValue}
          />
        </div>
        {renderPageQuizzes()}
        <div className={`bg-card ${Styles.b_radius_15} mb-5`} style={{ overflow: "scroll" }}>
          {showQuizzes()}
        </div>
        {/* {showDelModal()} */}
        <DeleteModal
          show={Object.keys(deleteObjectModal).length > 0}
          onHide={handleDeleteObjectModal}
          itemName={deleteObjectModal?.name}
          action={() => {
            deleteData(deleteObjectModal)
          }}
        />
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default Quizzes
