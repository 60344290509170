import React, { useEffect, useState, useCallback, useRef } from "react"
import { navigate, Link } from "gatsby"

import _ from "lodash"

import axios from "axios"
import Col from "react-bootstrap/Col"
import FormControl from "react-bootstrap/FormControl"
import Button from "react-bootstrap/Button"
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd"
import Spinner from "react-bootstrap/Spinner"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Dropdown from "react-bootstrap/Dropdown"
import TurientLogo from "../../images/logo.png"
import Image from "../custom/Image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpload, faChevronLeft, faChevronRight, faAngleDown, faCircle } from "@fortawesome/free-solid-svg-icons"

import authClient from "../../services/auth"
import Common from "../../services/common"
import { isBrowser } from "../../services/developer_mode"

import ClassRoomPoll from "../../components/class-room-training/classroom-poll"
import ClassroomPopquiz from "../../components/class-room-training/classroom-popquiz"
import UploadResource from "../../components/upload-resource"

import PollIcon from "../../images/svgs/Pollicon.svg"
import HomeIcon from "../../images/svgs/Homeicon.svg"
import ClassIcon from "../../images/svgs/Classicon.svg"
import CoursesIcon from "../../images/svgs/Courseicon.svg"
import ResourcesIcon from "../../images/svgs/Resourcesicon.svg"
import SettingsIcon from "../../images/svgs/Settingsicon.svg"
import LeadboardIcon from "../../images/svgs/Leadboardicon.svg"
import video from "../../images/svgs/video.svg"
import link from "../../images/svgs/link.svg"
import turientimage from "../../images/svgs/turientimage.svg"
import blog from "../../images/svgs/blog.svg"
import quizImg from "../../images/svgs/quiz.svg"
import SearchIcon from "../../images/svgs/search.svg"
import UploadIcon from "../../images/svgs/feather-upload-white.svg"
import BreakoutSessionIcon from "../../images/svgs/classroom/breakout-session_nav-icon.svg"
import doc from "../../images/svgs/doc.svg"
import excel from "../../images/svgs/excel.svg"
import ppt from "../../images/svgs/ppt.svg"
import pdf from "../../images/svgs/pdf.svg"
import poll from "../../images/svgs/poll.svg"
import popQuiz from "../../images/svgs/popquiz.svg"
import checklist from "../../images/svgs/checklist.svg"
import casestudy from "../../images/svgs/casestudy.svg"
import MatchTheFollowingIcon from "../../images/svgs/mtf.svg"
import LeftArrowWhite from "../../images/svgs/leftArrowWhite.svg"
import RightArrowWhite from "../../images/svgs/rightArrowWhite.svg"
import ReportIssueIcon from "../../images/report_an_issue.svg"

import Styles from "./desktop-left-navigation.module.scss"
import StylesA from "../template-resources/classroom-left-nav.module.scss"
import StylesB from "../template-resources/classroom-resource-section.module.scss"
import StylesC from "../../modularscss/styles.module.scss"
import { HasCreatePermission } from "../../utils/PermissionDataAccess"
import { CLASSES, COURSES } from "../../constants/permissionsConstant"
import { useContext } from "react"
import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"

const ResourceList = ["Video", "Blog", "Document", "Turientimage", "Link"]
const AssessmentsList = ["casestudy", "checklist", "mtf", "poll", "pop-quiz", "quiz"]
const AssessmentsDisplayNames = { casestudy: "Case Studies", checklist: "Checklists", mtf: "MTFs", poll: "Polls", "pop-quiz": "Pop Quizzes", quiz: "Quizzes" }
const LeftNavTooltips = ["Home", "Classes", "Courses", "Resources", "Assessment Toolkit", "BreakoutSessions", "Company", "Settings", "Report An Issue"]

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  borderRadius: "5px",
  color: "rgb(224, 226, 229)",
  border: "1px solid transparent",

  // change background colour if dragging
  background: "rgb(48, 60, 84)",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const LeftNavItems = ["Home", "Classes", "Courses", "Resource", "Poll", "BreakoutSessions", "Company", "Settings", "Report An Issue"]
const LeftNavIcons = [HomeIcon, ClassIcon, CoursesIcon, ResourcesIcon, PollIcon, BreakoutSessionIcon, LeadboardIcon, SettingsIcon, ReportIssueIcon]
const ITEM_HEIGHT = 50

const ResourceItem = (props) => {
  function openInNewTabOrExisting(url) {
    window.open(url)
  }
  function gotoSingleParticularSection(id, currentSec) {
    props.setSidebarCollapsed(false)
    if (currentSec === "poll") {
      openInNewTabOrExisting(`/app/polls/${id}/edit/`)
    } else if (currentSec === "pop-quiz") {
      openInNewTabOrExisting(`/app/pop-quizzes/${id}/edit/`)
    } else if (currentSec === "video") {
      openInNewTabOrExisting(`/app/videos/edit/${id}/`)
    } else if (currentSec === "blog") {
      openInNewTabOrExisting(`/app/blogs/edit/${id}/`)
    } else if (currentSec === "link") {
      openInNewTabOrExisting(`/app/links/edit/${id}/`)
    } else if (currentSec === "quiz") {
      openInNewTabOrExisting(`/app/quizzes/edit/${id}/`)
    } else if (currentSec === "turientimage" || currentSec === "document") {
      openInNewTabOrExisting(`/app/files/edit/${id}/`)
    } else if (currentSec === "checklist") {
      openInNewTabOrExisting(`/app/checklist/edit/${id}/`)
    } else if (currentSec === "casestudy") {
      openInNewTabOrExisting(`/app/case-study/edit/${id}/`)
    }
  }
  let imgObj = props.item.avatar ? props.item.avatar : props.logo

  if (props.currentSelectedResourcesType === "turientimage") {
    imgObj = props.item.avatar || (props.list_type === "My List" && props.item.resource_data ? props.item.resource_data?.avatar : props.logo)
  }
  if (props.currentSelectedResourcesType === "document") {
    let fileType = props.item?.resource_data?.file_type || props.item?.file_type
    if (fileType == 2 || fileType == 5) {
      imgObj = doc
    } else if (fileType == 3 || fileType == 7) {
      imgObj = excel
    } else if (fileType == 4 || fileType == 6) {
      imgObj = ppt
    } else if (fileType == 1) {
      imgObj = pdf /*props.logo*/
    }
  } else if (props.currentSelectedResourcesType === "quiz") {
    imgObj = quizImg
    let dataType = props.item?.resource_data?.data_type || 0
    if (dataType == 1) {
      imgObj = poll
    } else if (dataType == 2) {
      imgObj = popQuiz
    }
  } else if (props.currentSelectedResourcesType === "poll") {
    imgObj = poll
  } else if (props.currentSelectedResourcesType === "pop-quiz") {
    imgObj = popQuiz
  } else if (props.currentSelectedResourcesType === "checklist") {
    imgObj = checklist
  } else if (props.currentSelectedResourcesType === "casestudy") {
    imgObj = casestudy
  } else if (props.currentSelectedResourcesType === "mtf") {
    imgObj = MatchTheFollowingIcon
  }

  const getResourceName = () => {
    let resource_data,
      resource_name = ""
    if (props.list_type === "My List" && props.item.resource_data) {
      resource_data = props.item.resource_data
    } else {
      resource_data = props.item
    }
    resource_name = resource_data.display_name ?? resource_data.name
    //For Poll & Pop-Quiz, if name is the default name, then display "untitled"
    if (resource_data?.data_type && ["1", "2"].includes(resource_data?.data_type) && resource_name === "sample quiz console") {
      resource_name = "untitled"
    }
    return resource_name
  }

  const showTooltip = () => {
    let resource_name = getResourceName()
    return resource_name?.length > 35
  }

  function gotoPublishResource(e, id) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    const PublishResourcesUrl = {
      quiz: "/app/quizzes/{id}/publish",
      mtf: "/app/match-the-following/edit/{id}/",
      poll: "/app/polls/{id}/edit/",
      "pop-quiz": "/app/pop-quizzes/{id}/edit/",
      checklist: "/app/checklist/edit/{id}/",
      casestudy: "/app/case-study/edit/{id}/",
    }
    let publishUrl = PublishResourcesUrl[props.currentSelectedResourcesType]
    publishUrl = publishUrl.replace("{id}", id)
    window.open(publishUrl)
  }

  let is_published_resource = ["quiz", "mtf", "poll", "pop-quiz", "checklist", "casestudy"].includes(props.currentSelectedResourcesType)
  let width = is_published_resource && props.item?.is_published === false ? `w-63` : `w-100`
  let resource_name = getResourceName()

  return (
    <div
      onClick={(e) => gotoSingleParticularSection(props.item.id, props.currentSelectedResourcesType)}
      className="d-flex align-items-center float-left w-100 position-relative p-2"
    >
      {props.type === "classroom" && props.item.is_triggered && !props.item.end_activity && props.currentSelectedResourcesType === "poll" ? (
        <div className={`mr-2 w-15px h-15px rounded-circle bg-46C99E`}></div>
      ) : (
        ""
      )}
      <div className={`d-flex align-content-center justify-content-start mr-auto ${width}`}>
        <img src={imgObj} alt={props.currentSelectedResourcesType} className={`mb-0 object-fit-contain h-20px left-0 top-0`} />
        {showTooltip(width === `w-100` ? 35 : 12) ? (
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip
                className="event-tooltip"
                id={`tooltip-${props.item.display_name ?? props.item.name ?? props.item.resource_data?.display_name ?? props.item.resource_data?.name}`}
              >
                {resource_name}
              </Tooltip>
            }
          >
            <p className={`text-truncate w-100 px-2 ${StylesC.font_12} m-0 font-weight-bold line-height-18`}>{resource_name}</p>
          </OverlayTrigger>
        ) : (
          <p className={`text-truncate w-100 px-2 ${StylesC.font_12} ${StylesC.b_0} m-0 font-weight-bold line-height-18`}>{resource_name}</p>
        )}
      </div>
      <div className={`d-flex align-content-center pt-1 mr-1 justify-content-end`}>
        {props.item?.is_published === false && (
          <div className={`fs-10px pb-1 ${StylesB.not_published}`} onClick={(e) => gotoPublishResource(e, props.item.id)}>
            Publish Changes
          </div>
        )}
      </div>
    </div>
  )
}

const Sidebar = (props) => {
  const [sidebarState, setSidebarState] = useContext(SidebarContext)
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  const [curItem, setCurItem] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [showResourceModal, setShowResourceModal] = useState(false)
  const [defineFiles, setDefineFiles] = useState("")

  const [searchData, setSearchData] = useState({})

  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(false)

  const [allPublishedResourcesFetched, setPublishedAllResourcesFetched] = useState(false)

  const [showPollModal, setShowPollModal] = useState(false)
  const [showPollResult, setShowPollResult] = useState(false)
  const [currentPoll, setCurrentPoll] = useState("")
  const [createNewPoll, setCreateNewPoll] = useState(false)

  const [currentPopquiz, setCurrentPopquiz] = useState("")
  const [createNewPopquiz, setCreateNewPopquiz] = useState(false)
  const [showPopquizResult, setShowPopquizResult] = useState(false)
  const [showPopquizModal, setShowPopquizModal] = useState(false)
  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("")
  const [quiz, setQuiz] = useState({ no_of_questions: 1, quiz_time: 5, no_of_attempts: "0", quiz_type: "0", quiz_level: "0", partial_points: true })
  const [location_hash, setLocationHash] = useState("")
  const [currentNavTab, setCurrentNavTab] = useState("")

  const [hoveredResourcesSection, setHoveredResourcesSection] = useState(false)
  const [showScrollRight, setShowScrollRight] = useState(false)
  const [showScrollLeft, setShowScrollLeft] = useState(false)
  const [docType, setDocType] = useState(0)
  const PageSize = useRef(10)
  const [showAddResToLib, setShowAddResToLib] = useState(false)
  const [comapnyDetails, setComapnyDetails] = useState({})
  const pollAndPopQuizzes = ["casestudy", "checklist", "mtf", "poll", "pop-quiz", "quiz"]

  function sendQuery(value, type, section) {
    if (type === "search") {
      fetchAllResources(true, "", value, section, type)
    }
  }

  const delayedQuery = useCallback(
    _.debounce((value, type, section) => sendQuery(value, type, section), 1000),
    []
  )

  useEffect(() => {
    if (window?.screen?.height > 0) {
      handleResize()
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [window?.screen?.height])

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("my-refresh-resources", handleRefresh)
      return () => {
        window.removeEventListener("my-refresh-resources", handleRefresh)
      }
    }
  }, [window])

  function handleRefresh(evt) {
    if (evt.detail) {
      setTimeout(() => {
        fetchAllResources(true, evt.detail)
      }, 500)
    }
  }

  function handleResize() {
    let height = window?.screen?.height ?? 480
    PageSize.current = Math.ceil(height / ITEM_HEIGHT)
  }

  useEffect(() => {
    if (isBrowser()) {
      fetchStudentDefineFiles()
    }
  }, [isBrowser()])

  useEffect(() => {
    if (sidebarCollapsed !== props.leftNavOpen) {
      setSidebarCollapsed(props.leftNavOpen)
    }
  }, [props.leftNavOpen])

  useEffect(() => {
    if (sidebarCollapsed !== props.leftNavOpen) {
      props.toggleLeftNav(sidebarCollapsed)
    }
  }, [sidebarCollapsed])

  useEffect(() => {
    if (currentSelectedResourcesType !== props.activeResourceType) {
      props.setActiveResourceType(currentSelectedResourcesType)
    }
    if (currentSelectedResourcesType === "quiz" || currentSelectedResourcesType === "pop-quiz") {
      scrollRight("resources")
    } else {
      scrollLeft("resources")
    }
  }, [currentSelectedResourcesType])

  useEffect(() => {
    if (curItem !== props.activeNavItem) {
      props.setActiveNavItem(curItem)
    }
  }, [curItem])

  useEffect(() => {
    if (props.showPollModal === true) {
      setShowPollModal(props.showPollModal)
      setShowAddResToLib(true)
      setShowPollResult(false)
      setCreateNewPoll(true)
    }
  }, [props.showPollModal])

  useEffect(() => {
    if (props.showPopQuizModal === true) {
      setShowPopquizModal(props.showPopQuizModal)
      setShowAddResToLib(true)
      setShowPopquizResult(false)
      setCreateNewPopquiz(true)
    }
  }, [props.showPopQuizModal])

  useEffect(() => {
    if (currentNavTab !== props.currentNavTab) {
      props.setCurrentNavTab(currentNavTab)
    }
  }, [currentNavTab])

  useEffect(() => {
    if (currentNavTab !== props.currentNavTab) {
      setCurrentNavTab(props.currentNavTab)
    }
  }, [props.currentNavTab])

  useEffect(() => {
    if (props.activeResourceType && currentSelectedResourcesType !== props.activeResourceType) {
      setCurrentSelectedResourcesType(props.activeResourceType)
    }
  }, [props.activeResourceType])

  useEffect(() => {
    setCurItem(props.activeNavItem)
  }, [props.activeNavItem])

  async function fetchStudentDefineFiles() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/fetch-learner-define-file/`, { headers: login_credentials })
      .then((res) => {
        setDefineFiles(res.data.define_files)
      })
      .catch((error) => {
        if (error.response) {
          let error_messages = ""
          for (let key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        }
        if (!error.response) {
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            "Please check your internet connection" +
            "</div>"
        }
      })
  }

  useEffect(() => {
    if (curItem === "Resource" || curItem === "Poll") {
      if (currentSelectedResourcesType) {
        if (curItem === "Resource" && ["poll", "pop-quiz", "checklist", "casestudy", "mtf", "quiz"].indexOf(currentSelectedResourcesType) !== -1) {
          setCurrentSelectedResourcesType("video")
        } else if (curItem === "Poll" && ["video", "blog", "turientimage", "link"].indexOf(currentSelectedResourcesType) !== -1) {
          let activeTab = choseActiveTab()
          setCurrentSelectedResourcesType(activeTab)
        }
      } else {
        if (curItem === "Resource") {
          setCurrentSelectedResourcesType("video")
        } else {
          let activeTab = choseActiveTab()
          setCurrentSelectedResourcesType(activeTab)
        }
      }
      /*let refetch = false
      if (searchValue !== searchData[currentSelectedResourcesType] ) {
        refetch = true
        let search_data = {...searchData}
        search_data[currentSelectedResourcesType] = searchValue
        setSearchData(search_data)
      }
      fetchAllResources(refetch)*/
    }
  }, [curItem])

  useEffect(() => {
    if (curItem === "Poll") {
      let activeTab = choseActiveTab()
      setCurrentSelectedResourcesType(activeTab)
    }
  }, [props.disabledResources])

  useEffect(() => {
    if (currentSelectedResourcesType) {
      let refetch = false
      if (searchValue !== searchData[currentSelectedResourcesType]) {
        refetch = true
        let search_data = { ...searchData }
        search_data[currentSelectedResourcesType] = searchValue
        setSearchData(search_data)
      }
      fetchAllResources(refetch)
      if (props.setHomepageActiveResource) {
        props.setHomepageActiveResource(currentSelectedResourcesType)
      }
    }
  }, [currentSelectedResourcesType])

  useEffect(() => {
    if (currentSelectedResourcesType) {
      let search_data = { ...searchData }
      search_data[currentSelectedResourcesType] = searchValue
      setSearchData(search_data)
    }
  }, [searchValue])

  useEffect(() => {
    if (currentSelectedResourcesType) {
      fetchAllResources(true)
    }
  }, [docType])

  function changeAllResources(new_resources, selResType) {
    let temp_data = { ...allResources }
    temp_data[selResType] = new_resources

    setAllResources(temp_data)
    if (props.setAllResource) {
      props.setAllResource(temp_data)
    }
    if (props.setHomepageAllResource) {
      props.setHomepageAllResource(temp_data)
    }
  }

  useEffect(() => {
    if (props.allResources) {
      setAllResources(props.allResources)
      setAllResourcesNext(props.allResourcesNext)
    }
  }, [props.allResourcesChanged])

  function refetch() {
    if (currentSelectedResourcesType) {
      fetchAllResources(true)
    }
  }

  const choseActiveTab = () => {
    for (let i = 0; i < pollAndPopQuizzes.length; i++) {
      const resource = pollAndPopQuizzes[i]
      if (!props.disabledResources?.includes(resource)) {
        return resource
      }
    }
  }

  function getFileTypeForDoc() {
    let fileType = "all"
    switch (docType) {
      case "PDF":
        fileType = "1"
        break
      case "DOC":
        fileType = "doc"
        break
      case "EXCEL":
        fileType = "xls"
        break
      case "PPT":
        fileType = "ppt"
        break
      default:
        break
    }
    return fileType
  }

  async function fetchAllResources(refetch = false, selResType = "", value = "", section = null, type = null) {
    if (selResType === "") {
      selResType = currentSelectedResourcesType ? currentSelectedResourcesType : section
    }
    if (refetch === true || allResources[selResType] === undefined) {
      if (!section) {
        setAllResourcesFetched(false)
      }
      let data_type = ""
      let type = "basic-" + selResType + "s-list"
      if (selResType === "turientimage") {
        type = "basic-images-list"
        data_type = "&file_type=0"
      } else if (selResType === "quiz") {
        type = "basic-quizzes-list"
        data_type = "&data_type=0&published=true"
      } else if (selResType === "poll") {
        type = "basic-quizzes-list"
        data_type = "&data_type=1&published=true"
      } else if (selResType === "pop-quiz") {
        type = "basic-quizzes-list"
        data_type = "&data_type=2&published=true"
      } else if (selResType === "document") {
        type = "basic-images-list"
        data_type = `&file_type=` + getFileTypeForDoc()
      } else if (selResType === "checklist") {
        type = "checklist"
        data_type = `&limit=${PageSize.current}&published=true`
      } else if (selResType === "casestudy") {
        type = "case-study"
        data_type = `&limit=${PageSize.current}&published=true`
      } else if (selResType === "mtf") {
        type = "match-the-followings"
        data_type = `&limit=${PageSize.current}&published=true&ordering=-created_at`
      }

      let search = `&search=${searchValue ? searchValue : value}`
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/?paginate_by=${PageSize.current}${search}&adminsite=true${data_type}`, {
          headers: login_credentials,
        })
        .then((res) => {
          let temp_data = {}
          temp_data[selResType] = res.data.results
          setAllResources(temp_data)

          changeAllResources(res.data.results, selResType)
          Common.SetValForID(setAllResourcesNext, selResType, res.data.next === null ? "" : res.data.next)
          setAllResourcesFetched(true)
        })
    } else {
      setAllResourcesFetched(true)
      setPublishedAllResourcesFetched(true)
    }
  }

  async function fetchNextAllResources(next_url) {
    if (next_url && typeof next_url === "string") {
      axios.get(next_url, { headers: login_credentials }).then((res) => {
        if (res.data && res.data.results.length > 0) {
          let temp = { ...allResources }
          changeAllResources(temp[currentSelectedResourcesType].concat(res.data.results), currentSelectedResourcesType)
        }
        if (res.data) Common.SetValForID(setAllResourcesNext, currentSelectedResourcesType, res.data.next === null ? "" : res.data.next)
      })
    }
  }

  function handleNavClick(item, index) {
    if (currentNavTab === item) {
      setCurrentNavTab("")
    } else {
      setCurrentNavTab(item)
    }

    if (item === "participants" || item === "Default1" || item === "Default2" || item === "Settings" || item === "Bookmark" || item === "mainlogo") {
      return null
    } else if (item === "Company") {
      navigate("/app/school-setup")
    } else if (item === "BreakoutSessions") {
      setSidebarCollapsed(false)
      setCurItem(item)
    } else {
      if (sidebarCollapsed === false) {
        setCurItem(item)
        setSidebarCollapsed(true)
      } else {
        if (item === curItem) {
          setSidebarCollapsed(!sidebarCollapsed)
        } else {
          setCurItem(item)
          setSidebarCollapsed(true)
        }
      }
    }
  }

  function showResourceTabName(item) {
    if (item === "Turientimage" || item === "turientimage") {
      return "Images"
    } else if (item === "Quiz" || item === "quiz") {
      return "Quizzes"
    } else {
      return item + "s"
    }
  }

  function showResources(resources, next_url, list_type, type = "resource") {
    const disableResource = props.disabledResources?.includes(currentSelectedResourcesType)
    let is_published_resource = ["quiz", "mtf", "poll", "pop-quiz", "checklist", "casestudy"].includes(currentSelectedResourcesType)

    if (resources.length > 0) {
      let logo = video
      if (currentSelectedResourcesType === "blog") {
        logo = blog
      } else if (currentSelectedResourcesType === "video") {
        logo = video
      } else if (currentSelectedResourcesType === "link") {
        logo = link
      } else if (currentSelectedResourcesType === "quiz") {
        logo = quizImg
      } else if (currentSelectedResourcesType === "turientimage") {
        logo = turientimage
      } else if (currentSelectedResourcesType === "poll" || currentSelectedResourcesType === "pop-quiz") {
        logo = turientimage
      } else if (currentSelectedResourcesType === "checklist" || currentSelectedResourcesType === "casestudy") {
        logo = turientimage
      } else if (currentSelectedResourcesType === "document") {
        logo = turientimage
      }

      return (
        <React.Fragment>
          {currentSelectedResourcesType === "document" ? (
            <>
              <Dropdown className={`breakout-groups pr-3 pb-2`}>
                <Dropdown.Toggle
                  className="border-none d-inline-flex align-items-center p-0 shadow-none"
                  style={{ backgroundColor: "transparent" }}
                  id="dropdown-basic"
                >
                  <div className={`mr-1`} style={{ fontSize: "12px" }}>
                    {docType ? docType : "All Documents"}
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className={`br-9px breakout-groups`} style={{ fontSize: "12px", color: "#E0E2E5" }}>
                  <Dropdown.Item
                    style={{ color: "#E0E2E5" }}
                    onClick={() => {
                      setDocType("All Documents")
                    }}
                  >{`All Documents`}</Dropdown.Item>
                  <Dropdown.Item
                    style={{ color: "#E0E2E5" }}
                    onClick={() => {
                      setDocType("PDF")
                    }}
                  >
                    {"PDF"}
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ color: "#E0E2E5" }}
                    onClick={() => {
                      setDocType("DOC")
                    }}
                  >
                    DOC
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ color: "#E0E2E5" }}
                    onClick={() => {
                      setDocType("EXCEL")
                    }}
                  >{`EXCEL`}</Dropdown.Item>
                  <Dropdown.Item
                    style={{ color: "#E0E2E5" }}
                    onClick={() => {
                      setDocType("PPT")
                    }}
                  >{`PPT`}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            ""
          )}

          {resources.map((item, index) => {
            let not_published = is_published_resource && item?.is_published === false ? true : false
            return (
              <Draggable
                key={item.id + `_${type}_name_` + index}
                draggableId={item.id.toString() + `-${type}` + "_" + index.toString()}
                isDragDisabled={props.enableDND && !disableResource ? false : true}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`${not_published ? StylesB.not_published_highlight : ""} ${
                      disableResource ? "" : StylesB.border_on_hover
                    } mb-2 d-flex align-items-center`}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <ResourceItem
                      setSidebarCollapsed={setSidebarCollapsed}
                      setCreateNewPoll={setCreateNewPoll}
                      setCurrentPoll={setCurrentPoll}
                      setShowPollResult={setShowPollResult}
                      setShowPollModal={setShowPollModal}
                      setShowPopquizModal={setShowPopquizModal}
                      list_type={list_type}
                      index={index}
                      item={item}
                      logo={logo}
                      currentSelectedResourcesType={currentSelectedResourcesType}
                      enableDND={props.enableDND}
                    />
                  </div>
                )}
              </Draggable>
            )
          })}
          {next_url && typeof next_url === "string" ? (
            <div>
              <Button
                variant="none"
                onClick={(e) => fetchNextAllResources(next_url)}
                className={`${StylesC.font_12} ${StylesB.resources_see_more} text-center px-0 pt-0 pb-2`}
                style={{ color: `#E0E2E5`, textDecoration: "underline" }}
              >
                See More
              </Button>
            </div>
          ) : (
            ``
          )}
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  function showSpinner() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
        <Spinner animation="border" variant="secondary" size="sm" />
      </Col>
    )
  }

  let page_uri = isBrowser() ? "/" + window.location.pathname.substr(1) : "false"
  if (page_uri === "/logout" || page_uri === "logout" || page_uri === "logout/" || page_uri === "/logout/") {
    page_uri = "/"
  }

  function showAddContentButton() {
    let button_text = "Add Content"
    if (currentSelectedResourcesType === "poll") {
      button_text = "Create Poll"
    } else if (currentSelectedResourcesType === "pop-quiz") {
      button_text = "Create Pop Quiz"
    } else if (currentSelectedResourcesType === "video") {
      button_text = "Upload Video"
    } else if (currentSelectedResourcesType === "link") {
      button_text = "Upload Link"
    } else if (currentSelectedResourcesType === "quiz") {
      button_text = "Create Quiz"
    } else if (currentSelectedResourcesType === "turientimage") {
      button_text = "Upload File"
    } else if (currentSelectedResourcesType === "blog") {
      button_text = "Create Blog"
    } else if (currentSelectedResourcesType === "document") {
      button_text = "Upload Document"
    } else if (currentSelectedResourcesType === "checklist") {
      button_text = "Create Checklist"
    } else if (currentSelectedResourcesType === "casestudy") {
      button_text = "Create Case Study"
    } else if (currentSelectedResourcesType === "mtf") {
      button_text = " Create MTF"
    } else {
      button_text = "Upload Content"
    }
    if (curItem === "Resource") {
      return (
        <div className={`${sidebarCollapsed ? `d-flex` : `d-none`}`} style={{ position: "absolute", bottom: "0px" }}>
          <Button
            variant="none"
            onClick={(e) => handleAddContent()}
            className={`align-items-center d-flex text-white border_on_hover`}
            style={{ backgroundColor: "#475269", color: "#E0E2E5", height: "50px", borderRadius: "0px", justifyContent: "center", minWidth: "153px" }}
          >
            <img src={UploadIcon} alt={`upload button`} className={`my-auto`} style={{ objectFit: `contain`, width: `24px`, height: `24px` }} />
            <p className="mb-0" style={{ fontSize: "14px", color: "#E0E2E5" }}>
              {button_text}
            </p>
          </Button>
          <Button
            variant="none"
            onClick={(e) => handleViewAllResources()}
            className={`align-items-center d-flex text-white border_on_hover`}
            style={{
              backgroundColor: "#475269",
              color: "#E0E2E5",
              height: "50px",
              borderRadius: "0px",
              marginLeft: "7px",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            View All resources
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{
                height: "14px",
                width: "14px",
                borderRadius: "50%",
                backgroundColor: "#E0E2E5",
                color: "#212c42",
                padding: "2px 2px 2px 3px",
                marginLeft: "3px",
              }}
            />
          </Button>
          {/* <Button onClick={(e)=> handleAddContent()} className={`align-items-center d-flex text-white border_on_hover`} style={{height:'50px',borderRadius:'0px',justifyContent:"center"}}>addContett</Button> */}
        </div>
      )
    } else {
      return (
        <div className={`${sidebarCollapsed ? `d-flex` : `d-none`}`} style={{ position: "absolute", bottom: "0px" }}>
          <Button
            variant="none"
            onClick={(e) => handleAddContent()}
            className={`align-items-center d-flex text-white border_on_hover`}
            style={{ backgroundColor: "#475269", height: "50px", borderRadius: "0px", justifyContent: "center", minWidth: "155px" }}
          >
            <img src={UploadIcon} alt={`upload button`} className={`my-auto`} style={{ objectFit: `contain`, width: `22px`, height: `22px` }} />
            <p className="mt-3" style={button_text === "Add Poll" ? { fontSize: "14px", color: "#E0E2E5" } : { fontSize: "14px", color: "#E0E2E5" }}>
              {button_text}
            </p>
          </Button>
          <Button
            variant="none"
            onClick={(e) => handleViewAllResources()}
            className={`align-items-center d-flex text-white border_on_hover`}
            style={{ backgroundColor: "#475269", height: "50px", borderRadius: "0px", marginLeft: "5px", justifyContent: "center" }}
          >
            <p className="mt-3" style={button_text === "Add Poll" ? { fontSize: "14px", color: "#E0E2E5" } : { fontSize: "14px", color: "#E0E2E5" }}>
              View All resources
              <FontAwesomeIcon
                icon={faChevronRight}
                size="sm"
                style={{
                  height: "12px",
                  width: "12px",
                  borderRadius: "50%",
                  backgroundColor: "#E0E2E5",
                  color: "#212c42",
                  padding: "2px 2px 2px 3px",
                  marginLeft: "5px",
                }}
              />
            </p>
          </Button>
        </div>
      )
    }
  }

  function handleAddContent() {
    setSidebarCollapsed(false)
    if (currentSelectedResourcesType === "poll") {
      setShowPollResult(false)
      setCreateNewPoll(true)
      setShowPollModal(true)
    } else if (currentSelectedResourcesType === "pop-quiz") {
      setShowPopquizResult(false)
      setShowPopquizModal(true)
      setCreateNewPopquiz(true)
    } else if (currentSelectedResourcesType === "blog") {
      navigate(`/app/blogs/create`)
    } else if (currentSelectedResourcesType === "quiz") {
      navigate(`/app/quizzes/create`)
    } else if (currentSelectedResourcesType === "checklist") {
      navigate(`/app/checklist/create`)
    } else if (currentSelectedResourcesType === "casestudy") {
      navigate(`/app/case-study/create`)
    } else if (currentSelectedResourcesType === "mtf") {
      navigate(`/app/match-the-following/create`)
    } else {
      setShowResourceModal(true)
    }
  }

  function handleViewAllResources() {
    setSidebarCollapsed(false)
    if (currentSelectedResourcesType === "video") {
      navigate(`/app/resource-dashboard/`)
    } else if (currentSelectedResourcesType === "blog") {
      navigate(`/app/resource-dashboard/`)
    } else if (currentSelectedResourcesType === "link") {
      navigate(`/app/resource-dashboard/`)
    } else if (currentSelectedResourcesType === "quiz") {
      navigate(`/app/quizzes/`)
    } else if (currentSelectedResourcesType === "turientimage" || currentSelectedResourcesType === "document") {
      navigate(`/app/resource-dashboard/`)
    } else if (currentSelectedResourcesType === "poll") {
      navigate(`/app/polls/`)
    } else if (currentSelectedResourcesType === "pop-quiz") {
      navigate(`/app/pop-quizzes/`)
    } else if (currentSelectedResourcesType === "checklist") {
      navigate(`/app/checklists/`)
    } else if (currentSelectedResourcesType === "casestudy") {
      navigate(`/app/case-study/`)
    } else if (currentSelectedResourcesType === "mtf") {
      navigate(`/app/match-the-following/`)
    }
  }

  function scrollRight(id) {
    let ele = document.getElementById(id)
    if (ele !== null) {
      let flex_scroll_amount = ele.scrollWidth / ele.childElementCount
      let left_pos = ele.scrollLeft
      ele.scrollTo({ top: 0, left: left_pos + 3 * flex_scroll_amount, behavior: "smooth" })
    }
  }

  function scrollLeft(id) {
    let ele = document.getElementById(id)
    if (ele !== null) {
      let flex_scroll_amount = ele.scrollWidth / ele.childElementCount
      let left_pos = ele.scrollLeft
      ele.scrollTo({ top: 0, left: left_pos - 3 * flex_scroll_amount, behavior: "smooth" })
    }
  }

  function displayScrollButtons(id) {
    if (id == "resources" || id == "resources2") {
      setHoveredResourcesSection(true)
    }
    let ele = document.getElementById(id)
    if (ele) {
      if (ele.scrollLeft == 0) {
        setShowScrollLeft(false)
      } else {
        setShowScrollLeft(true)
      }
      if (ele.scrollLeft + ele.offsetWidth >= ele.scrollWidth) {
        setShowScrollRight(false)
      } else {
        setShowScrollRight(true)
      }
    }
  }

  function showResourceTopNav() {
    if (curItem === "Resource") {
      return (
        <>
          <div
            className={`w-100`}
            style={{ position: "relative" }}
            onMouseOver={() => {
              displayScrollButtons("resources")
            }}
            onMouseOut={() => {
              setHoveredResourcesSection(false)
            }}
          >
            <div
              className={`px-0 pointer ${hoveredResourcesSection && showScrollRight ? `` : `d-none`}`}
              onClick={() => {
                scrollRight("resources")
              }}
              style={{
                paddingTop: 3,
                height: "auto",
                width: "fit-content",
                borderRadius: 4,
                position: "absolute",
                right: 0,
                top: 0,
                zIndex: 5,
                backgroundColor: "#303C54",
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} color="#fff" />
            </div>
            <div
              className={`px-0 pointer ${hoveredResourcesSection && showScrollLeft ? `` : `d-none`}`}
              onClick={() => {
                scrollLeft("resources")
              }}
              style={{
                paddingTop: 3,
                height: "auto",
                width: "fit-content",
                borderRadius: 4,
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 5,
                backgroundColor: "#303C54",
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} color="#fff" />
            </div>
            <div
              className={`d-inline-flex mx-3 hide_scrollbar`}
              id="resources"
              onScroll={() => {}}
              style={{ maxWidth: "88%", width: "88%", overflowX: "scroll" }}
            >
              {ResourceList.map((item, index) => {
                //to remove mtf and quiz from resource top nav
                if (props.disabledResources?.includes(item.toLowerCase())) {
                  return
                }
                return (
                  <div
                    key={index.toString()}
                    className={`pointer ${
                      currentSelectedResourcesType === item.toLowerCase() ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item
                    }`}
                    onClick={() => {
                      setCurrentSelectedResourcesType(item.toLowerCase())
                      setPublishedAllResourcesFetched(false)
                    }}
                  >
                    <p>{showResourceTabName(item)}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <div
          className={`w-100`}
          style={{ position: "relative" }}
          onMouseOver={() => {
            displayScrollButtons("resources")
          }}
          onMouseOut={() => {
            setHoveredResourcesSection(false)
          }}
        >
          <div
            className={`px-0 pointer position-absolute br-4px bg-303C54 z-index-5 w-fit-content right-0 top-0 h-auto pt-3px ${
              hoveredResourcesSection && showScrollRight ? `` : `d-none`
            }`}
            onClick={() => {
              scrollRight("resources")
            }}
          >
            <Image src={RightArrowWhite} alt={"right-arrow"} className={`mb-0`} />
          </div>
          <div
            className={`px-0 pointer position-absolute br-4px bg-303C54 z-index-5 w-fit-content left-0 top-0 h-auto pt-3px ${
              hoveredResourcesSection && showScrollLeft ? `` : `d-none`
            }`}
            onClick={() => {
              scrollLeft("resources")
            }}
          >
            <Image src={LeftArrowWhite} alt={`left-arrow`} className={`mb-0`} />
          </div>
          <div className={`d-inline-flex mx-3 hide_scrollbar`} id="resources" style={{ maxWidth: "88%", width: "88%", overflowX: "scroll" }}>
            {AssessmentsList.map((item, index) => {
              if (!props.disabledResources?.includes(item.toLowerCase())) {
                return (
                  <div
                    key={`${item}-assessment`}
                    className={`pointer ${
                      currentSelectedResourcesType === item.toLowerCase() ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item
                    }`}
                    onClick={() => {
                      setCurrentSelectedResourcesType(item.toLowerCase())
                      if (item.toLowerCase() !== currentSelectedResourcesType) {
                        setAllResourcesFetched(false)
                      }
                      setPublishedAllResourcesFetched(false)
                    }}
                  >
                    <p>{AssessmentsDisplayNames[item]}</p>
                  </div>
                )
              }
            })}
          </div>
        </div>
      )
    }
  }

  function showResourcesSection(type = "resource") {
    let all_resources = allResources[currentSelectedResourcesType] ?? []
    let next_resource_url = allResourcesNext && allResourcesNext[currentSelectedResourcesType] ? allResourcesNext[currentSelectedResourcesType] : []
    let button_text = "Add Content"
    if (currentSelectedResourcesType === "poll") {
      button_text = "Create Poll"
      // setButton_text('Add Poll')
    } else if (currentSelectedResourcesType === "pop-quiz") {
      button_text = "Create Pop Quiz"
    } else if (currentSelectedResourcesType === "video") {
      button_text = "Upload Video"
    } else if (currentSelectedResourcesType === "link") {
      button_text = "Upload Link"
    } else if (currentSelectedResourcesType === "quiz") {
      button_text = "Create Quiz"
    } else if (currentSelectedResourcesType === "turientimage") {
      button_text = "Upload File"
    } else if (currentSelectedResourcesType === "blog") {
      button_text = "Create Blog"
    } else if (currentSelectedResourcesType === "checklist") {
      button_text = "Create Checklist"
    } else if (currentSelectedResourcesType === "casestudy") {
      button_text = "Create Case Study"
    } else {
      button_text = "Upload Content"
    }
    if (allResourcesFetched) {
      if (all_resources.length === 0) {
        return (
          <>
            {currentSelectedResourcesType === "document" ? (
              <>
                <Dropdown className={`breakout-groups pr-3 pb-2`}>
                  <Dropdown.Toggle
                    className="border-none d-inline-flex align-items-center p-0 shadow-none"
                    style={{ backgroundColor: "transparent" }}
                    id="dropdown-basic"
                  >
                    <div className={`mr-1`} style={{ fontSize: "12px" }}>
                      {docType ? docType : "All Documents"}
                    </div>
                    <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={`br-9px breakout-groups`} style={{ fontSize: "12px", color: "#E0E2E5" }}>
                    <Dropdown.Item
                      style={{ color: "#E0E2E5" }}
                      onClick={() => {
                        setDocType("All Documents")
                      }}
                    >{`All Documents`}</Dropdown.Item>
                    <Dropdown.Item
                      style={{ color: "#E0E2E5" }}
                      onClick={() => {
                        setDocType("PDF")
                      }}
                    >
                      {"PDF"}
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ color: "#E0E2E5" }}
                      onClick={() => {
                        setDocType("DOC")
                      }}
                    >
                      DOC
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ color: "#E0E2E5" }}
                      onClick={() => {
                        setDocType("EXCEL")
                      }}
                    >{`EXCEL`}</Dropdown.Item>
                    <Dropdown.Item
                      style={{ color: "#E0E2E5" }}
                      onClick={() => {
                        setDocType("PPT")
                      }}
                    >{`PPT`}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              ""
            )}

            {searchValue === "" ? (
              <Col lg="12" md="12" sm="12" xs="12" className="text-center py-">
                <div className="mx-1" style={{ textAlign: "center", display: "block", height: "inherit", marginTop: "30%" }}>
                  <p style={{ color: "#E0E2E5", fontSize: "21px", fontWeight: "500" }}>No content has been uploaded yet!</p>
                  <p style={{ color: "#E0E2E5", fontSize: "16px" }}>
                    Everything you upload can be accessed from here. Drag and drop to add it your courses, quizzes, polls etc.
                  </p>
                  {/* <p style={{color:'#E0E2E5',fontSize:"16px"}}>You can use your uploaded resources In creating a course, to demonstrate during a live class Or use wherever applicable</p> */}
                  <button
                    onClick={() => {
                      handleAddContent()
                    }}
                    className="border_on_hover"
                    style={{
                      backgroundColor: "#586886",
                      border: "1px solid #586886",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      color: "#E0E2E5",
                      fontSize: "18px",
                      padding: "5px 24px 5px 24px",
                    }}
                  >
                    <FontAwesomeIcon className={`mr-2`} icon={faUpload} size="sm" /> {button_text}
                  </button>
                  {currentSelectedResourcesType === "pop-quiz" ||
                  currentSelectedResourcesType === "poll" ||
                  currentSelectedResourcesType === "quiz" ||
                  currentSelectedResourcesType === "checklist" ||
                  currentSelectedResourcesType === "casestudy" ? null : (
                    <p className="pt-2" style={{ fontSize: "14px", color: "#82868D" }}>
                      Upload videos. Images, Documents, blogs, etc
                    </p>
                  )}
                </div>
              </Col>
            ) : (
              <Col lg="12" md="12" sm="12" xs="12" className="text-center py-">
                <div className="mx-1" style={{ textAlign: "center", display: "block", height: "inherit", marginTop: "30%" }}>
                  <p style={{ color: "#E0E2E5", fontSize: "21px", fontWeight: "500" }}>No resources found!</p>
                  <p style={{ color: "#E0E2E5", fontSize: "16px" }}>
                    Everything you upload can be accessed from here. Drag and drop to add it your courses, quizzes, polls etc.
                  </p>
                  {/* <p style={{color:'#E0E2E5',fontSize:"16px"}}>You can use your uploaded resources In creating a course, to demonstrate during a live class Or use wherever applicable</p> */}
                  <button
                    onClick={() => {
                      handleAddContent()
                    }}
                    className="border_on_hover"
                    style={{
                      backgroundColor: "#586886",
                      border: "1px solid #586886",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      color: "#E0E2E5",
                      fontSize: "18px",
                      padding: "5px 24px 5px 24px",
                    }}
                  >
                    <FontAwesomeIcon className={`mr-2`} icon={faUpload} size="sm" /> {button_text}
                  </button>
                  {currentSelectedResourcesType === "pop-quiz" ||
                  currentSelectedResourcesType === "poll" ||
                  currentSelectedResourcesType === "quiz" ||
                  currentSelectedResourcesType === "checklist" ||
                  currentSelectedResourcesType === "casestudy" ? null : (
                    <p className="pt-2" style={{ fontSize: "14px", color: "#82868D" }}>
                      Upload videos. Images, Documents, blogs, etc
                    </p>
                  )}
                </div>
              </Col>
            )}
          </>
        )
      } else {
        return (
          <React.Fragment>
            <Droppable droppableId={type + `_` + currentSelectedResourcesType} type={`resourceDraggable`} isDropDisabled={true}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                  {showResources(all_resources, next_resource_url, "All")}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </React.Fragment>
        )
      }
    } else {
      return showSpinner()
    }
  }

  const fetchTrainings = (type) => {
    setLocationHash(type)
    window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}${type === "" ? "" : `#${type}`}`)
    let fetch_training_btn = document.getElementById("fetch_training_btn")
    if (fetch_training_btn) {
      fetch_training_btn.click()
    }
  }

  function updateIconCss(id) {
    setSidebarCollapsed(false)
    document.getElementById("nav" + id).style.backgroundColor = "transparent"
    document.getElementById("nav" + id).style.opacity = "0.4"
  }

  function displayContent() {
    let url = window.location.pathname
    let hash = window.location.hash
    if (curItem === "Home") {
      return (
        <div style={{ textAlign: "center", display: "block", height: "inherit", marginTop: "30%" }}>
          <div
            className="m-2"
            onClick={(e) => {
              updateIconCss(0)
            }}
          >
            <Link
              to="/app/dashboard"
              style={hash === "" && url.match("dashboard") ? { borderBottom: "1px solid white" } : {}}
              className="borderbottom_on_hover"
            >
              My Dashboard
            </Link>
          </div>
          <div
            className="m-2 disabled-div"
            onClick={(e) => {
              updateIconCss(0)
            }}
          >
            <Link to="#notifications" style={hash === "#notifications" ? { borderBottom: "1px solid white" } : {}} className="borderbottom_on_hover">
              My Notifications
            </Link>
          </div>
          <div
            className="m-2 disabled-div"
            onClick={(e) => {
              updateIconCss(0)
            }}
          >
            <Link to="#calender" style={hash === "#calender" ? { borderBottom: "1px solid white" } : {}} className="borderbottom_on_hover">
              My Calendar
            </Link>
          </div>
          <div
            className="m-2 disabled-div"
            onClick={(e) => {
              updateIconCss(0)
            }}
          >
            <Link to="#profile" style={hash === "#profile" ? { borderBottom: "1px solid white" } : {}} className="borderbottom_on_hover">
              My Profile
            </Link>
          </div>
          <div
            className="m-2 disabled-div"
            onClick={(e) => {
              updateIconCss(0)
            }}
          >
            <Link to="#rewards" style={hash === "#rewards" ? { borderBottom: "1px solid white" } : {}} className="borderbottom_on_hover">
              My Rewards
            </Link>
          </div>
        </div>
      )
    } else if (curItem === "Classes") {
      return (
        <div style={{ textAlign: "center", display: "block", height: "inherit", marginTop: "40%" }}>
          <div
            className="m-2"
            onClick={(e) => {
              updateIconCss(1)
            }}
          >
            <Link
              to={`/app/dashboard/`}
              style={{ borderBottom: location_hash === "" ? "1px solid white" : "", maxWidth: "fit-content" }}
              className="borderbottom_on_hover mb-0 mx-auto pointer"
            >
              All Classes
            </Link>
          </div>
          <div
            className="m-2"
            onClick={(e) => {
              updateIconCss(1)
            }}
          >
            <Link to={`/app/dashboard/#upcoming`} style={{ maxWidth: "fit-content" }} className="borderbottom_on_hover mb-0 mx-auto pointer">
              Upcoming
            </Link>
          </div>
          <div
            className="m-2"
            onClick={(e) => {
              updateIconCss(1)
            }}
          >
            <Link to={`/app/dashboard/#past`} style={{ maxWidth: "fit-content" }} className="borderbottom_on_hover mb-0 mx-auto pointer">
              Completed
            </Link>
          </div>
          {/*<div className='m-2' onClick={(e) => { updateIconCss(1) } } ><Link to='/app/dashboard/#rescheduled' style={hash === '#rescheduled' ? {borderBottom:'1px solid white'}: {}} className='borderbottom_on_hover'>Rescheduled</Link></div>*/}
          {/*<div className='m-2' onClick={(e) => { updateIconCss(1) } } ><Link to='/app/dashboard/#cancelled' style={hash === '#cancelled' ? {borderBottom:'1px solid white'}: {}} className='borderbottom_on_hover'>Cancelled</Link></div>*/}
          {HasCreatePermission(CLASSES) && (
            <div
              className="m-2 mt-4"
              onClick={(e) => {
                updateIconCss(1)
              }}
            >
              <Link to={`/app/companies/${localStorage.getItem("company_id")}/courses/new/trainings/create`}>
                <button
                  className="border_on_hover"
                  style={{
                    backgroundColor: "#586886",
                    border: "1px solid #586886",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "9px",
                    color: "#E0E2E5",
                    fontSize: "18px",
                    padding: "5px 24px 5px 24px",
                  }}
                >
                  Create New Class
                </button>
              </Link>
            </div>
          )}
          {/*<div className='m-2 mt-3' onClick={(e) => { updateIconCss(1) } } ><Link to={`/app/dashboard`}><button className='border_on_hover' style={{backgroundColor:'#586886',border:"1px solid #586886",boxShadow:'0px 3px 6px #00000029',borderRadius:'9px',color:'#E0E2E5',fontSize:'18px',padding:'5px 24px 5px 24px'}} >Set up class</button></Link></div>*/}
        </div>
      )
    } else if (curItem === "Courses") {
      return (
        <div style={{ textAlign: "center", display: "block", height: "inherit", marginTop: "50%" }}>
          <div
            className="m-2"
            onClick={(e) => {
              updateIconCss(2)
            }}
          >
            <Link to={`/app/dashboard/`} className="borderbottom_on_hover">
              All Courses
            </Link>
          </div>
          <div
            className="m-2"
            onClick={(e) => {
              updateIconCss(2)
            }}
          >
            <Link to={`/app/dashboard/#published`} className="borderbottom_on_hover">
              Published
            </Link>
          </div>
          <div
            className="m-2"
            onClick={(e) => {
              updateIconCss(2)
            }}
          >
            <Link to={`/app/dashboard/#draft`} className="borderbottom_on_hover">
              Draft
            </Link>
          </div>
          {HasCreatePermission(COURSES) && (
            <div
              className="m-2 mt-4"
              onClick={() => {
                updateIconCss(2)
              }}
            >
              <Link to={`/app/companies/${localStorage.getItem("company_id")}/courses/course-information`}>
                <button
                  className="border_on_hover"
                  style={{
                    backgroundColor: "#586886",
                    border: "1px solid #586886",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "9px",
                    color: "#E0E2E5",
                    fontSize: "18px",
                    padding: "5px 24px 5px 24px",
                  }}
                >
                  Create New Course
                </button>
              </Link>
            </div>
          )}
        </div>
      )
    } else if (curItem === "Resource" || curItem === "Poll") {
      return (
        <React.Fragment>
          <Col lg="12" md="12" sm="12" xs="12" className="px-0 pb-5">
            <div className="p-3">
              <div className={`col-12 d-inline-flex ${StylesB.search_section}`}>
                <img src={SearchIcon} alt={`search button`} className={`my-auto`} style={{ objectFit: `contain`, width: `16px`, height: `16px` }} />
                <FormControl
                  autoFocus
                  className="border-0 py-0 px-2 form-control shadow-none font-14"
                  style={{ backgroundColor: `#303C54`, color: `#E0E2E5`, fontSize: "16px", height: "32px" }}
                  placeholder={`Search resources`}
                  onChange={(e) => {
                    e.preventDefault()
                    setSearchValue(e.target.value)
                    setAllResourcesFetched(false)
                    delayedQuery(e.target.value, "search", currentSelectedResourcesType)
                  }}
                  value={searchValue}
                />
              </div>
            </div>
            <div className={`${StylesB.resource_nav_section}`}>{showResourceTopNav()}</div>
            <div className={`${StylesB.resource_item_section}`}>{showResourcesSection()}</div>
          </Col>
          {showAddContentButton()}
          <ClassRoomPoll
            path="dashboard"
            create_new_poll={createNewPoll}
            reset_create_poll={() => setCreateNewPoll(false)}
            live_classroom={false}
            poll={currentPoll}
            showPollResult={showPollResult}
            show={showPollModal}
            closeModal={() => setShowPollModal(false)}
            fetchAllResources={(arg) => {
              fetchAllResources(arg)
            }}
            showAddResToLib={showAddResToLib}
          />
          <ClassroomPopquiz
            path="dashboard"
            create_new_popquiz={createNewPopquiz}
            reset_create_popquiz={() => setCreateNewPopquiz(false)}
            live_classroom={false}
            popquiz={currentPopquiz}
            showPopquizResult={false}
            show={showPopquizModal}
            closeModal={() => setShowPopquizModal(false)}
            fetchAllResources={(arg) => {
              fetchAllResources(arg)
            }}
            showAddResToLib={showAddResToLib}
          />
          <UploadResource
            show={showResourceModal}
            defineFiles={defineFiles}
            updateDefineType={(value) => setDefineFiles(value)}
            closeModal={() => setShowResourceModal(false)}
            refetch={refetch}
            showSection={currentSelectedResourcesType === "link" ? "upload_links" : ""}
          />
        </React.Fragment>
      )
    } else {
      return <div>Not designed for this</div>
    }
  }

  function displayNavTabs() {
    return LeftNavItems.map((item, index) => {
      let tab_icon = LeftNavIcons[index]
      if ((item === "BreakoutSessions" && props.parent_page === "course_content") || item !== "BreakoutSessions") {
        return (
          <React.Fragment key={index.toString()}>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip placement="right" className="classroom-tooltip" id={`"tooltip-${item}"`}>
                  {LeftNavTooltips[index]}
                </Tooltip>
              }
            >
              <Button
                style={{ display: "flex", alignItems: "center", justifyContent: "center", opacity: "0.4" }}
                id={"nav" + index}
                key={index.toString()}
                onClick={() => {
                  if (item !== "Report An Issue") {
                    handleNavClick(item, index)
                  } else {
                    document.getElementById("reportAnIssue").click()
                  }
                }}
                variant="none"
                className={`${item === "Report An Issue" ? `mb-5 mt-auto` : null} ${
                  item === currentNavTab ? Styles.active_nav_tab : Styles.inactive_nav_tab
                } py-2 btn_focus pointer text-center my-2 ${item === "Settings" ? "disabled-div" : ""} ${
                  item === "Poll" && props.disabledResources?.includes(item) ? "disabled-div opacity-20" : ""
                }`}
              >
                <img src={tab_icon} alt={item} className={`my-auto`} style={{ objectFit: `contain`, height: "26px" }} />
              </Button>
            </OverlayTrigger>
          </React.Fragment>
        )
      } else {
        return null
      }
    })
  }

  const displaySidebar = () => {
    return (
      <div tabIndex="0" className="d-flex" style={{ left: "0px", zIndex: 1, height: "100vh", boxShadow: "0px 3px 6px #00000029", position: "fixed" }}>
        <div style={{ width: "60px", backgroundColor: "#121B2B", padding: "10px 0px 0px 0px" }}>
          <Link to="/app/dashboard">
            <div
              tabIndex="0"
              onFocus={() => {
                setSidebarCollapsed(true)
              }}
              onClick={() => {
                handleNavClick("mainlogo", 10)
                setSidebarCollapsed(false)
              }}
              className="d-flex align-items-center justify-content-center"
              style={{ backgroundColor: "#303c54", borderRadius: "50%", height: "40px", margin: "0px 10px" }}
            >
              <img
                src={sidebarState?.companyDetails?.logo && sidebarState?.companyDetails?.logo !== "null" ? sidebarState?.companyDetails?.logo : TurientLogo}
                alt={sidebarState?.companyDetails?.name ? sidebarState?.companyDetails?.name : "Turient"}
                style={{ objectFit: `contain`, height: "40px", borderRadius: "50%" }}
              />
            </div>
          </Link>
          <div className="d-flex pt-3" style={{ flexDirection: "column", height: "calc(100vh - 60px)", overflowY: "auto" }}>
            {displayNavTabs()}
          </div>
        </div>
        <div
          className={`${sidebarCollapsed ? `${StylesA.left_nav_opened}` : `${StylesA.left_nav_collapsed}`} mb-3`}
          id={`mySidepanel`}
          style={{
            backgroundColor: `#212C42`,
            height: "100vh",
            boxShadow: "0px 3px 12px #00000098",
            overflowY: curItem === "Resource" || curItem === "Poll" ? "auto" : "hidden",
            textAlign: "left",
          }}
        >
          {displayContent()}
        </div>
      </div>
    )
  }

  return (
    <Col
      xs="12"
      sm="12"
      md="12"
      lg="12"
      className={`${Styles.layout_main_div} px-0`}
      style={{ marginTop: `0px`, backgroundColor: `#12182c`, color: `#C6CFF4` }}
    >
      {authClient.isAuthenticated() ? displaySidebar() : ""}
    </Col>
  )
}

export default Sidebar
