import React, { useEffect, useState, useCallback, useRef, useContext } from "react"
import _ from "lodash"
import axios from "axios"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
import Card from "react-bootstrap/Card"
import Spinner from "react-bootstrap/Spinner"
import Dropdown from "react-bootstrap/Dropdown"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import { DescriptionInputField } from "../courses/course-content"

import Tooltip from "react-bootstrap/Tooltip"

import authClient from "../../services/auth"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Droppable, Draggable } from "react-beautiful-dnd"
import UploadFiles from "../courses/upload-files2"
import Blog from "../blogs/create-edit"
import { isBrowser } from "../../services/developer_mode"
import Notification from "../custom/Notification"
import DeleteModal from "../custom/DeleteModal"
import Btn from "../custom/Button"
import { getResourceDisplayName } from "../utilities/resourceDisplayName"

import { WsConnect } from "../../services/socket"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faSpinner,
  faLink,
  faVideo,
  faChevronCircleUp,
  faChevronCircleDown,
  faExclamationTriangle,
  faImage,
  faUsers,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons"

import video from "../../images/svgs/video.svg"
import link from "../../images/svgs/link.svg"
import turientimage from "../../images/svgs/turientimage.svg"
import blog from "../../images/svgs/blog.svg"
import quiz from "../../images/svgs/quiz.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import documents from "../../images/svgs/documentresWhite.svg"
import whiteMatchingIcon from "../../images/svgs/whiteMatchingIcon.svg"

import DragHandleIcon from "../../images/svgs/drag-handle-white.svg"
import CancelWhite from "../../images/svgs/close.svg"
import CheckIcon from "../../images/svgs/check-white.svg"
import WhiteBlogIcon from "../../images/svgs/blog-white-icon.svg"
import WhiteQuizIcon from "../../images/svgs/quiz-white-icon.svg"
import WhitePollIcon from "../../images/svgs/polls-white-icon.svg"
import GroupIcon from "../../images/svgs/people-group-white.svg"
import AddDescriptionIcon from "../../images/svgs/adddescriptionicon.svg"
import AddedDescriptionIcon from "../../images/svgs/addeddescriptionicon.svg"
import BreakoutSessionIcon from "../../images/svgs/lesson_board_breakout_session_icon.svg"
import doc from "../../images/svgs/doc.svg"
import excel from "../../images/svgs/excel.svg"
import ppt from "../../images/svgs/ppt.svg"
import pdf from "../../images/svgs/pdf.svg"
import poll from "../../images/svgs/poll.svg"
import popQuiz from "../../images/svgs/popquiz.svg"
import checklist from "../../images/svgs/checklist.svg"
import casestudy from "../../images/svgs/casestudy.svg"

import Styles from "../../modularscss/styles.module.scss"
import StylesA from "./breakout-session.module.scss"
import StylesB from "../courses/course.module.scss"

import ActivityWokSpace from "../../components/acitvity-workspace"
import MuralOutlineSync from "../mural/mural-outline-sync"
import { GlobalContext } from "../../stores/global_store/GlobalStore"

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  border: "1px solid transparent",
  borderRadius: "8px",
  color: "#E0E2E5",
  background: "#303C54",
  boxShadow: "0px 3px 6px #00000029",

  // styles we need to apply on draggables
  ...draggableStyle,
})
const getTopicItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  background: "#42506C",
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})
const getResourceItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: "#212C42",
  padding: "8px 8px 0px",
})
const getTopicListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#000" : "#12172c",
  // background: '#212C42',
  // padding: grid,
})

const DurationInputField = (props) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const [timeType, setTimeType] = useState("Mins")
  const [inputValue, setInputValue] = useState(props.datum.duration)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (inputValue !== props.datum.duration) {
      let current_value = timeType === "Mins" ? inputValue : inputValue * 60
      if (inputValue > 0) {
        props.handleDurationSave(props.datum, current_value, "duration", "breakout-session")
      }
    }
  }, [inputValue])

  return (
    <div className="col-4 px-0 mr-2" style={{ position: "relative" }}>
      {showDropDown ? (
        <input
          autoComplete="off"
          type="number"
          min="1"
          name="duration"
          autoFocus
          id={props.datum.id + `_module_duration`}
          value={inputValue}
          onChange={(e) => {
            e.preventDefault()
            if (e.target.value <= 0) {
              setShowError(true)
            } else {
              setShowError(false)
            }
            setInputValue(e.target.value)
          }}
          placeholder={"Session Duration"}
          className={`${StylesA.session_input_field} ${StylesA.session_number_field} form-control border-0 px-3 ${Styles.font_16}`}
          onClick={() => setShowDropDown(!showDropDown)}
          onBlur={() => setShowDropDown(false)}
          required
        />
      ) : (
        <div>
          <div
            style={{ display: "flex", alignItems: "center", borderRadius: "8px", cursor: "text", padding: "8px 5px" }}
            onClick={() => setShowDropDown(!showDropDown)}
            className={`${StylesA.session_input_field} ${StylesA.session_number_field} px-2 ${StylesA.border_on_hover} ${Styles.font_16} ${
              !props?.isPermitted && "disable-content"
            }`}
          >
            Duration: {inputValue} {timeType}
          </div>
          <Col xs="12" sm="12" md="12" lg="12" className={`mt-2 px-0 ${showError ? null : `d-none`}`}>
            <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
              <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
              <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                Please enter valid time
              </p>
            </div>
          </Col>
        </div>
      )}
      <Dropdown.Menu show={showDropDown} className={`${showDropDown ? StylesA.active_input_drop_down : ""}`}>
        {timeType === "Mins" ? (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(10)
                setShowDropDown(false)
              }}
            >
              <p>10 mins</p>
            </div>
            <div
              onClick={() => {
                setInputValue(20)
                setShowDropDown(false)
              }}
            >
              <p>20 mins</p>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(1)
                setShowDropDown(false)
              }}
            >
              <p>1 hrs</p>
            </div>
            <div
              onClick={() => {
                setInputValue(2)
                setShowDropDown(false)
              }}
            >
              <p>2 hrs</p>
            </div>
          </React.Fragment>
        )}
        <div style={{ fontSize: "12px", display: "flex", justifyContent: "flex-end" }}>
          <p onClick={() => setTimeType("Mins")} style={{ opacity: timeType === "Mins" ? 1 : 0.5 }}>
            Mins
          </p>
          <p onClick={() => setTimeType("Hours")} style={{ marginLeft: "10px", opacity: timeType === "Hours" ? 1 : 0.5 }}>
            Hours
          </p>
        </div>
      </Dropdown.Menu>
    </div>
  )
}
const ReminderInputField = (props) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const [timeType, setTimeType] = useState(props.datum.recurring_reminder)
  const [inputValue, setInputValue] = useState(props.datum.reminder_time)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (inputValue !== props.datum.reminder_time) {
      if (inputValue > 0) {
        props.handleDurationSave(props.datum, inputValue, "reminder_time", "breakout-session")
      }
    }
  }, [inputValue])

  useEffect(() => {
    if (timeType !== props.datum.recurring_reminder && inputValue) {
      props.handleDurationSave(props.datum, timeType, "recurring_reminder", "breakout-session")
    }
  }, [timeType])

  function showFieldText() {
    if (inputValue) {
      if (timeType) {
        return `Reminder: Every ${inputValue} min`
      } else {
        return `Reminder: ${inputValue} min`
      }
    } else {
      return `Reminder`
    }
  }

  return (
    <div className="col-4 px-0" style={{ position: "relative" }}>
      {showDropDown ? (
        <input
          autoComplete="off"
          type="number"
          min="1"
          name="duration"
          autoFocus
          id={props.datum.id + `_module_duration`}
          value={inputValue}
          onChange={(e) => {
            e.preventDefault()
            if (e.target.value <= 0) {
              setShowError(true)
            } else {
              setShowError(false)
            }
            setInputValue(e.target.value)
          }}
          placeholder={"Reminder"}
          className={`${StylesA.session_input_field} ${StylesA.session_number_field} form-control border-0 px-2 ${Styles.font_16}`}
          onClick={() => setShowDropDown(!showDropDown)}
          onBlur={() => setShowDropDown(false)}
          required
        />
      ) : (
        <div>
          <div
            style={{ display: "flex", alignItems: "center", borderRadius: "8px", padding: "8px 5px" }}
            onClick={() => setShowDropDown(!showDropDown)}
            className={`${StylesA.session_input_field} ${StylesA.session_number_field} px-2 ${StylesA.border_on_hover} ${Styles.font_16} ${
              !props?.isPermitted && "disable-content"
            }`}
          >
            {showFieldText()}
          </div>
          <Col xs="12" sm="12" md="12" lg="12" className={`mt-2 px-0 ${showError ? null : `d-none`}`}>
            <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
              <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
              <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                Please enter valid time
              </p>
            </div>
          </Col>
        </div>
      )}
      <Dropdown.Menu show={showDropDown} className={`${showDropDown ? StylesA.active_input_drop_down : ""}`}>
        <div
          onClick={() => {
            setInputValue(10)
            setShowDropDown(false)
          }}
        >
          <p>10 mins</p>
        </div>
        <div
          onClick={() => {
            setInputValue(20)
            setShowDropDown(false)
          }}
        >
          <p>20 mins</p>
        </div>
        <div style={{ fontSize: "12px", display: "flex", justifyContent: "flex-end" }}>
          <p onClick={() => setTimeType(true)} style={{ opacity: timeType ? 1 : 0.5 }}>
            Recurring
          </p>
          <p onClick={() => setTimeType(false)} style={{ marginLeft: "10px", opacity: timeType ? 0.5 : 1 }}>
            Once
          </p>
        </div>
      </Dropdown.Menu>
    </div>
  )
}
const DistributionDropDown = (props) => {
  const [inputValue, setInputValue] = useState(props.datum.distribution_type)
  const Distribution_Types = { 0: "Common for all groups", 1: "Distribute amoung groups" }
  let status = parseInt(props.datum.status)

  useEffect(() => {
    if (inputValue !== props.datum.distribution_type) {
      props.handleDurationSave(props.datum, inputValue, "distribution_type", "breakout-session", props.module_id)
    }
  }, [inputValue])

  if (props.topics_count > 1 && status !== 1) {
    return (
      <Dropdown>
        <Dropdown.Toggle
          size="lg"
          className={`bg-transparent border-0 p-0 pointer ${StylesA.btn_focus} ${!props?.isPermitted && "disable-content"}`}
          id="dropdown_list"
          style={{ fontSize: "14px" }}
        >
          <img src={GroupIcon} alt={`group_icon_${props.datum.id}`} className={`my-auto mr-1`} style={{ objectFit: `contain`, width: `19px` }} />
          {Distribution_Types[inputValue]}
        </Dropdown.Toggle>
        <Dropdown.Menu className={`py-0 ${Styles.font_12} ${StylesA.toggle_dropdown_modal}`}>
          <Dropdown.Item
            className="px-2 py-1"
            onClick={() => setInputValue("0")}
            style={{
              background: inputValue === "0" ? "rgb(72, 83, 104)" : "rgb(48, 60, 84)",
              borderTopRightRadius: "8px",
              borderTopLeftRadius: "8px",
              color: "rgb(224, 226, 229)",
            }}
          >
            Common for all groups
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 py-1"
            onClick={() => {
              setInputValue("1")
              props.updateBreakoutSession()
              props.openCreatingGroupsModal1()
            }}
            style={{
              background: inputValue === "1" ? "rgb(72, 83, 104)" : "rgb(48, 60, 84)",
              borderTop: "1px solid rgb(224, 226, 229)",
              color: "rgb(224, 226, 229)",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            Distribute among groups
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  } else {
    return (
      <p style={{ fontSize: "14px", margin: "0px" }}>
        <img src={GroupIcon} alt={`group_icon_${props.datum.id}`} className={`my-auto mr-1`} style={{ objectFit: `contain`, width: `19px` }} />
        {Distribution_Types[inputValue]}
      </p>
    )
  }
}

const TestBreakoutSession = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""
  const [globalState, globalStateDispatch] = useContext(GlobalContext)

  // for modules basic name list
  const [modules, setModules] = useState([])
  const [modulesNext, setModulesNext] = useState(null)
  const [moduleCreated, setModuleCreated] = useState(true)
  const [modulesLoader, setModulesLoader] = useState(false)
  const [loader, setLoader] = useState(false)
  const [topicCreated, setTopicCreated] = useState(true)
  // for slected module
  const [currentModuleFetched, setCurrentModuleFetched] = useState(false)
  // for selected module topics basic name list
  const [currentModuleTopicsFetched, setCurrentModuleTopicsFetched] = useState(false)
  // to open delete modal of topic and module
  const [dmodal, setDmodal] = useState(false)
  const [selectedDeletingModule, setSelectedDeletingModule] = useState("")
  const [selectedDeletingTopic, setSelectedDeletingTopic] = useState("")
  // selceted module id
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedModuleToggle, setSelectedModuleToggle] = useState({})
  const [selectedModuleToggleChange, setSelectedModuleToggleChange] = useState(false)
  // selceted topic id
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedTopicToggle, setSelectedTopicToggle] = useState({})
  const [selectedTopicToggleChange, setSelectedTopicToggleChange] = useState(false)

  // each module full data in modules array
  const [modulesData, setModulesData] = useState({})

  // each topic full data in topics array
  //const [topicFetched, setTopicFetched] = useState(false)
  const [topicsData, setTopicsData] = useState({})

  // for topics and module resources and respective loader
  const [modulesResources, setModulesResources] = useState({})
  const [modulesResourcesNext, setModulesResourcesNext] = useState({})
  const [modulesResourcesFetched, setModulesResourcesFetched] = useState(false)
  const [modulesResourcesFetchedx, setModulesResourcesFetchedx] = useState(true)
  const [topicsResources, setTopicsResources] = useState({})
  const [topicsResourcesNext, setTopicsResourcesNext] = useState({})
  const [topicsResourcesNextLoader, setTopicsResourcesNextLoader] = useState({})
  const [topicsResourcesFetched, setTopicsResourcesFetched] = useState(false)
  const [topicsResourcesFetchedex, setTopicsResourcesFetchedex] = useState({})
  const [topicsResourcesFetchedxx, setTopicsResourcesFetchedxx] = useState(true)
  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("video")
  // each resource type full data in allResources array
  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(false)

  const [resourceDeleted, setResourceDeleted] = useState(true)

  const [initialFetched, setInitialFetched] = useState(false)

  const [collapse, setCollapse] = useState(false)

  const [visibleTopicData, setVisibleTopicData] = useState({})
  const [visibleTopicDataFetched, setVisibleTopicDataFetched] = useState(false)
  const [deleteVisibleResource, setDeleteVisibleResource] = useState("")

  const [showAddInstructionsModal, setShowAddInstructionsModal] = useState(false)
  const [activeDatum, setActiveDatum] = useState(null)
  const [moduleEdited, setModuleEdited] = useState(false)

  const [breakoutGroupList, setBreakoutGroupList] = useState([])
  const [breakoutGroupsDataFetched, setBreakoutGroupsDataFetched] = useState(false)

  const [uploadInTopic, setUploadInTopic] = useState(null)
  const [uploadInModule, setUploadInModule] = useState(null)
  const [uploadDataSequence, setUploadDataSequence] = useState(0)
  const [addBlog, setAddBlog] = useState(false)
  const [showPollModal, setShowPollModal] = useState(false)
  const [showPopQuizModal, setShowPopQuizModal] = useState(false)
  const [showSection, setShowSection] = useState("video")
  const [files, setFiles] = useState([])
  const quizWin = useRef(null)
  const checklistWin = useRef(null)
  const casestudyWin = useRef(null)
  const mtfWin = useRef(null)
  const [attrForResources, setAttrForResources] = useState(null)
  const [addResourceType, setAddResourceType] = useState("")
  const [notificationData, setNotificationData] = useState(null)
  const [socketData, setSocketData] = useState(null)
  const isMuralEnabled = globalState.appData.data?.company?.mural_enabled

  const disabledResource = ["poll", "pop-quiz"]

  const trainingActivityWs = useRef(null)

  useEffect(() => {
    if (props?.trainingActivityWs) {
      trainingActivityWs.current = props.trainingActivityWs
      trainingActivityWs.current.addEventListener("message", trainingActivityWSOnMessageHandler)

      return () => {
        trainingActivityWs.current && trainingActivityWs.current.removeEventListener("message", trainingActivityWSOnMessageHandler)
      }
    }
  }, [props?.trainingActivityWs])

  const trainingActivityWSOnMessageHandler = (e) => {
    if (trainingActivityWs.current) {
      let data = JSON.parse(e.data)
      if (data.value === "echo") return
      let responseData = JSON.parse(data.value)
      if (responseData.event_type && responseData.event_type === "breakout_session_status") {
        setSocketData({ ...responseData, ws_send_receive_type: "receive" })
      }
    }
  }
  useEffect(() => {
    if (isBrowser() && !props?.trainingActivityWs && props?.training_id && props.parent_page !== "course_content") {
      connectTrainingWS()
      window.addEventListener("unload", closeTrainingWs)

      return () => {
        trainingActivityWs.current && trainingActivityWs.current.removeEventListener("message", trainingActivityWSOnMessageHandler)
        closeTrainingWs()
      }
    }
  }, [isBrowser(), props?.trainingActivityWs])

  const connectTrainingWS = () => {
    let updateSessionSocket = WsConnect(`/ws/training-activity/${props.training_id}/`)
    updateSessionSocket.onclose = function (e) {
      if (!e.wasClean) {
        setTimeout(connectTrainingWS(), 5000)
      }
    }
    updateSessionSocket.onopen = function (e) {
      trainingActivityWs.current = updateSessionSocket
      trainingActivityWs.current && trainingActivityWs.current.addEventListener("message", trainingActivityWSOnMessageHandler)
    }
  }

  const closeTrainingWs = () => {
    if (trainingActivityWs.current && !props?.trainingActivityWs) {
      trainingActivityWs.current.close()
      trainingActivityWs.current = null
    }
  }

  const sendWS = (eventType, toBeSentData, otherData) => {
    let ws = trainingActivityWs.current

    //Websocket is not passed & is not open
    if (!ws && ws?.readyState === WebSocket.OPEN) return

    //only allow for - ClassroomHomePage|TrainingContentView|TrainingContent|LiveClass
    if (["course_content"].includes(props.parent_page)) return

    //set the socket data, with the data & it will be handled in below mentioned UseEffect, coz some state variables are not accessible here
    setSocketData({ event_type: eventType, time: Date.now(), data: { ...toBeSentData }, otherData: { ...otherData }, ws_send_receive_type: "send" })
  }

  useEffect(() => {
    if (socketData) {
      if (socketData.ws_send_receive_type === "receive") {
        //Update the breakout session status in the state
        if (socketData.event_type === "breakout_session_status") {
          let dummy = [...modules]
          var index = dummy.findIndex((res) => res.id === socketData.data.id)
          if (index >= 0) {
            dummy[index].status = socketData.data.status.toString()
            setModules(dummy)
          }
          setSocketData(null)
        }
      } else {
        let sessionId = socketData.otherData?.selectedModule,
          index = socketData.otherData?.sessionIndex
        //SessionID is not passed/Session Index in modules array: from the TopicId get the SessionId
        if (!sessionId && (index === undefined || index < 0)) {
          let data = getSessionIDFromTopic(socketData?.otherData?.selectedTopic)
          sessionId = data.selectedModule
        }
        //If Session Index is not passed
        if (index === undefined || index < 0) index = modules.findIndex((res) => res.id === sessionId)

        //Only send this if Breakout session is active
        if (index >= 0 && modules[index].status === "1") {
          let obj = { event_type: socketData.event_type, time: socketData.time, data: { ...socketData.data } }
          trainingActivityWs.current.send(JSON.stringify(obj))
        }
        setSocketData(null)
      }
    }
  }, [socketData])

  useEffect(() => {
    if (props.dragEnded && props.draggedItem) {
      onDragEnd(props.draggedItem)
      props.resetDragState()
    }
  }, [props.dragEnded])

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("message", handleNewWinMessages, false)
      window.addEventListener("close-poll-modal", handlePollModalMsgs, false)
      window.addEventListener("close-pop-quiz-modal", handlePopQuizModalMsgs, false)

      return () => {
        window.removeEventListener("message", handleNewWinMessages)
        window.removeEventListener("close-poll-modal", handlePollModalMsgs)
        window.removeEventListener("close-pop-quiz-modal", handlePopQuizModalMsgs)
      }
    }
  }, [window])

  function updateBreakoutSession() {
    let text = "course"
    let location = props.template_id
    if (props.parent_page == "live_class" || props.parent_page == "training") {
      text = "training"
      location = props.training_id
    }

    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + `/distibute-breakout-group-status/${location}/${text}/`, {}, { headers: DefaultHeader })
      .then((res) => {
        if (res.data.error === "OOPS! No breakout groups.") {
          //setNotificationData({ type: "error", title: res.data.error })
        } else {
          fetchCurrentTopics(true)
        }
      })
      .catch((error) => {
        console.log("Error!", error)
      })
  }

  useEffect(() => {
    if (modulesResourcesFetched === false) {
      setModulesResourcesFetched(true)
    }
  }, [modulesResources[`module_` + selectedModule]])

  useEffect(() => {
    if (modulesResourcesFetchedx === false) {
      setModulesResourcesFetchedx(true)
    }
  }, [modulesResources[`module_` + selectedModule]])

  useEffect(() => {
    if (selectedTopic != null) {
      fetchTopicResources(selectedTopic, false, true)
    }
  }, [selectedTopic])

  useEffect(() => {
    if (topicsResourcesFetched === false) {
      setTopicsResourcesFetched(true)
    }
  }, [topicsResources[`topic_` + selectedTopic], topicsResourcesFetchedex[`topic_` + selectedTopic]])

  useEffect(() => {
    if (topicsResourcesFetchedxx === false) {
      setTopicsResourcesFetchedxx(true)
    }
  }, [topicsResources[`topic_` + selectedTopic], topicsResourcesFetchedex[`topic_` + selectedTopic], topicsResourcesFetchedxx])

  function getSessionIDFromTopic(topicId) {
    let sessionKeys = Object.keys(modulesData)
    if (sessionKeys.length <= 0) {
      return { selectedModule: -1, index: -1 }
    }
    let index = modulesData[`module_` + selectedModule]?.topics.findIndex((data) => data.id === topicId),
      sessionId = selectedModule
    if (index >= 0) {
      return { selectedModule: sessionId, index }
    }
    Object.keys(modulesData).map((key) => {
      if ("module_" + selectedModule !== key && modulesData[key] && modulesData[key]?.topics?.length > 0) {
        index = modulesData[key]?.topics.findIndex((data) => data.id === topicId)
        if (index >= 0) {
          sessionId = parseInt(key.split("_")[1])
          return
        }
      }
    })
    return { selectedModule: sessionId, index }
  }

  const sendWSResourcesSaved = (topicId, resources, fromIndex = -1, toIndex = -1, additionalData) => {
    let data = { selectedModule: additionalData?.selectedModule, index: -1 }
    if (!additionalData?.selectedModule) {
      data = getSessionIDFromTopic(topicId)
    }
    if (data.index >= 0) {
      let idx = modules.findIndex((res) => res.id === data.selectedModule)
      let activity = {
        ...modulesData[`module_` + data.selectedModule].topics[data.index],
        distribution_type: idx >= 0 ? modules[idx]?.distribution_type : "1",
      }

      let dummy = { breakout_activity: activity, resources: resources }
      if (fromIndex >= 0) dummy["fromIndex"] = fromIndex
      if (toIndex >= 0) dummy["toIndex"] = toIndex
      sendWS("breakout_activity_resource_saved", dummy, { selectedModule: data.selectedModule })
    }
  }

  async function fetchTopicResources(current_topic = null, refetch = false, firstTime = false) {
    let currentTopic = current_topic === null ? selectedTopic : current_topic
    if (refetch || (currentTopic !== null && topicsResources[`topic_` + currentTopic] === undefined)) {
      let value = ""
      if (props.draft_content && props.draft_content === "true") {
        value = "&draft_content=true"
      }

      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/resources-names-list/?breakout_activity__id=${currentTopic}&paginate_by=8${value}`, {
          headers: login_credentials,
        })
        .then((res) => {
          //Take the difference between now got results & already existing array, It will give the newly added resources
          /*** */
          let resourcesId = [],
            newlyAddedResources = firstTime ? [] : res.data.results
          if (topicsResources && topicsResources["topic_" + currentTopic] && topicsResources["topic_" + currentTopic]?.length > 0) {
            resourcesId = topicsResources["topic_" + currentTopic].map((x) => x.id)
            newlyAddedResources = res.data.results.filter((x) => !resourcesId.includes(x.id))
          }
          /** */
          let current_data = { ...topicsResources }

          current_data[`topic_` + currentTopic] = res.data.results
          setTopicsResources(current_data)
          // setTopicsResourcesFetched(false)
          let next_data = { ...topicsResourcesNext }
          next_data[`topic_` + currentTopic] = res.data.next === null ? "" : res.data.next
          setTopicsResourcesNext(next_data)

          if (newlyAddedResources && newlyAddedResources?.length > 0) {
            sendWSResourcesSaved(currentTopic, newlyAddedResources, -1, -1, { selectedTopic: currentTopic })
          }
        })
    }
  }

  async function fetchNextTopicResources(next_url) {
    let resources_seemore_loader = { ...topicsResourcesNextLoader }
    resources_seemore_loader[`topic_` + selectedTopic] = true
    setTopicsResourcesNextLoader(resources_seemore_loader)
    setTopicsResourcesFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let current_data = { ...topicsResources }
      current_data[`topic_` + selectedTopic] = current_data[`topic_` + selectedTopic].concat(res.data.results)
      setTopicsResources(current_data)
      let next_data = { ...topicsResourcesNext }
      next_data[`topic_` + selectedTopic] = res.data.next === null ? "" : res.data.next
      setTopicsResourcesNext(next_data)
      setTopicsResourcesFetched(true)
      let resources_seemore_loader = { ...topicsResourcesNextLoader }
      resources_seemore_loader[`topic_` + selectedTopic] = false
      setTopicsResourcesNextLoader(resources_seemore_loader)
    })
  }

  function deleteResource(resource_id, push_to_bucket = false, item = {}, activity_id = 0) {
    let value = ""
    if (props.draft_content && props.draft_content === "true") {
      value = `?draft_content=true`
    }
    activity_id = activity_id <= 0 ? selectedTopic : activity_id
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + resource_id + `/${value}`, { headers: DefaultHeader })
      .then((res) => {
        setResourceDeleted(true)
        sendWS("breakout_activity_resource_deleted", { breakout_activity_id: activity_id, id: resource_id }, { selectedTopic: activity_id })
      })
      .catch((error) => {
        handleErrors(error)
      })
  }

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  function handleDraggedItemSequence(currentItem, type, to = `internal`, srcIdx = -1, destIdx = -1) {
    if (currentItem) {
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      if (type !== `resources`) {
        if (type === "breakout-session") {
          formData.append("template", currentItem.template)
        } else if (type === "breakout-activity") {
          formData.append("breakout_session", currentItem.breakout_session)
        }
        formData.append("name", currentItem.name)
      } else {
        formData.append("resource_id", currentItem.resource_id)
        if (currentItem.resource_type === "document") {
          formData.append("resource_type", "turientimage")
        } else {
          formData.append("resource_type", currentItem.resource_type)
        }
      }
      if (to === `topic_external` && type === `resources`) {
        formData.append("breakout_activity", currentItem.breakout_activity)
      } else if (to === `module_external` && type === `resources`) {
        formData.append("breakout_session", currentItem.breakout_session)
      }
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/` + currentItem.id + "/", formData, { headers: DefaultHeader })
        .then((res) => {
          if (type === "breakout-activity") {
            sendWS("breakout_activity_reordered", { id: currentItem.id, fromIndex: srcIdx, toIndex: destIdx }, { selectedTopic: currentItem.id })
          } else if (type === "resources") {
            sendWS(
              "breakout_activity_resource_reordered",
              {
                breakout_activity_id: currentItem.breakout_activity ?? selectedTopic,
                id: currentItem.id,
                fromIndex: srcIdx,
                toIndex: destIdx,
              },
              { selectedTopic: currentItem.breakout_activity }
            )
          }
        })
        .catch((error) => {
          handleErrors(error)
        })
    }
  }

  function onDragEnd(result) {
    //only for resources
    let type = result.source.droppableId.split("_")[0].toString() + `_resourceDraggable`
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    let itemSubItemMap = []
    let sourceParentId = result.source.droppableId
    let destParentId = result.destination.droppableId
    let sourceSubItems = []
    let destSubItems = []

    if (result.type === "moduleDraggable") {
      const items = reorder(modules, sourceIndex, destIndex)
      let currentModule = items[result.destination.index]
      if (result.destination.index === 0) {
        currentModule.sequence = (parseFloat(modules[0].sequence) - 10).toFixed(20)
      } else if (result.destination.index === modules.length - 1) {
        currentModule.sequence = (parseFloat(modules[modules.length - 1].sequence) + 10).toFixed(20)
      } else {
        if (result.source.index > result.destination.index) {
          currentModule.sequence = (
            (parseFloat(modules[result.destination.index - 1].sequence) + parseFloat(modules[result.destination.index].sequence)) /
            2
          ).toFixed(20)
        } else if (result.source.index < result.destination.index) {
          currentModule.sequence = (
            (parseFloat(modules[result.destination.index].sequence) + parseFloat(modules[result.destination.index + 1].sequence)) /
            2
          ).toFixed(20)
        }
      }
      handleDraggedItemSequence(currentModule, "breakout-session", "internal", sourceIndex, destIndex)
      setModules(items)
    } else if (result.type === "topicDraggable") {
      itemSubItemMap = modulesData

      sourceSubItems = itemSubItemMap[sourceParentId].topics
      destSubItems = itemSubItemMap[destParentId].topics

      let newItems = modulesData[result.destination.droppableId]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(modulesData[result.destination.droppableId].topics, sourceIndex, destIndex)
        let currentTopic = items[result.destination.index]
        let currentData = modulesData[result.destination.droppableId].topics
        if (result.destination.index === 0) {
          currentTopic.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
        } else if (result.destination.index === currentData.length - 1) {
          currentTopic.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (result.source.index > result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
              2
            ).toFixed(20)
          } else if (result.source.index < result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
              2
            ).toFixed(20)
          }
        }
        handleDraggedItemSequence(currentTopic, "breakout-activity", "internal", sourceIndex, destIndex)
        modulesData[result.destination.droppableId].topics = items
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = destSubItems
        newDestSubItems.splice(destIndex, 0, draggedItem)

        if (result.destination.index === 0 && destSubItems.length === 1) {
          draggedItem.sequence = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index + 1].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index + 1 === destSubItems.length) {
          draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem.sequence = (
            (parseFloat(destSubItems[result.destination.index + 1].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        let module_id = result.destination.droppableId.split("_")[1].toString()
        draggedItem.breakout_session = module_id
        handleDraggedItemSequence(draggedItem, "breakout-activity", "external", sourceIndex, destIndex)
        modulesData[result.destination.droppableId] = newItems
      }
    } else if (result.destination.droppableId.split("_")[0].toString() === "visibleTopicData") {
      sourceSubItems = props.allResources[props.currentSelectedResourcesType]
      destSubItems = visibleTopicData[destParentId] ? visibleTopicData[destParentId] : []

      let newSourceSubItems = sourceSubItems
      const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)
      let module_id = result.destination.droppableId.split("_")[1].toString()
      createTopic(null, module_id, draggedItem)

      let newDestSubItems = destSubItems
      newDestSubItems.splice(destIndex, 0, draggedItem)
      let temp_data = props.allResources
      temp_data[props.currentSelectedResourcesType] = newSourceSubItems
      props.setHomepageAllResource(temp_data)
      visibleTopicData[destParentId] = newDestSubItems
      setVisibleTopicDataFetched(!visibleTopicDataFetched)
    } else if (type === "module_resourceDraggable" || type === "topic_resourceDraggable") {
      itemSubItemMap = type === "topic_resourceDraggable" ? topicsResources : modulesResources

      sourceSubItems = itemSubItemMap[sourceParentId]
      destSubItems = itemSubItemMap[destParentId]

      let newItems =
        result.destination.droppableId.split("_")[0].toString() === `resource`
          ? props.allResources[props.currentSelectedResourcesType]
          : type === "topic_resourceDraggable"
          ? topicsResources[result.destination.droppableId]
          : modulesResources[result.destination.droppableId]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        if (type === "topic_resourceDraggable") {
          const items = reorder(topicsResources[result.destination.droppableId], sourceIndex, destIndex)

          let currentResource = items[result.destination.index]
          let currentData = topicsResources[result.destination.droppableId]
          if (result.destination.index === 0) {
            currentResource.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
          } else if (result.destination.index === currentData.length - 1) {
            currentResource.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
          } else {
            if (result.source.index > result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
                2
              ).toFixed(20)
            } else if (result.source.index < result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
                2
              ).toFixed(20)
            }
          }
          handleDraggedItemSequence(currentResource, "resources", "internal", sourceIndex, destIndex)

          setTopicsResourcesFetched(!topicsResourcesFetched)
          topicsResources[result.destination.droppableId] = items
        } else {
          const items = reorder(modulesResources[result.destination.droppableId], sourceIndex, destIndex)

          let currentResource = items[result.destination.index]
          let currentData = modulesResources[result.destination.droppableId]
          if (result.destination.index === 0) {
            currentResource.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
          } else if (result.destination.index === currentData.length - 1) {
            currentResource.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
          } else {
            if (result.source.index > result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
                2
              ).toFixed(20)
            } else if (result.source.index < result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
                2
              ).toFixed(20)
            }
          }
          handleDraggedItemSequence(currentResource, "resources", "internal", sourceIndex, destIndex)

          setModulesResourcesFetched(!modulesResourcesFetched)
          setModulesResourcesFetchedx(!modulesResourcesFetchedx)
          modulesResources[result.destination.droppableId] = items
        }
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems =
          result.destination.droppableId.split("_")[0].toString() === `resource` ? props.allResources[props.currentSelectedResourcesType] : destSubItems
        if (result.destination.droppableId.split("_")[0].toString() === `resource`) {
          /*to be present in api success start*/
          draggedItem.dragged_id = draggedItem.id
          draggedItem.id = draggedItem.resource_data.id
          draggedItem.thumbnail_url = draggedItem.resource_data.thumbnail_url
          draggedItem.name = draggedItem.resource_data?.display_name ?? draggedItem.resource_data?.name
          // draggedItem.sequence = draggedItem.sequence
          delete draggedItem["resource_data"]
          delete draggedItem["resource_id"]
          delete draggedItem["resource_type"]
          /*to be present in api success end*/
          deleteResource(draggedItem.dragged_id)
        }
        newDestSubItems.splice(destIndex, 0, draggedItem)
        if (result.destination.droppableId.split("_")[0].toString() === `resource`) {
          setAllResourcesFetched(false)
          let temp_data = props.allResources
          temp_data[props.currentSelectedResourcesType] = newItems
          props.setHomepageAllResource(temp_data)
        } else {
          /**/
          if (result.destination.index === 0 && destSubItems.length === 1) {
            draggedItem.sequence = result.destination.index
          } else if (result.destination.index === 0) {
            if (draggedItem) {
              draggedItem.sequence = (parseFloat(destSubItems[result.destination.index + 1].sequence) - 10).toFixed(20)
            }
          } else if (result.destination.index + 1 === destSubItems.length) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
          } else {
            draggedItem.sequence = (
              (parseFloat(destSubItems[result.destination.index + 1].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
              2
            ).toFixed(20)
          }

          /**/
          if (type === "topic_resourceDraggable") {
            let topic_id = result.destination.droppableId.split("_")[1].toString()
            draggedItem.breakout_activity = topic_id

            handleDraggedItemSequence(draggedItem, "resources", "topic_external", sourceIndex, destIndex)

            setTopicsResourcesFetched(false)
            topicsResources[result.destination.droppableId] = newItems
          } else {
            let module_id = result.destination.droppableId.split("_")[1].toString()
            draggedItem.breakout_session = module_id

            handleDraggedItemSequence(draggedItem, "resources", "module_external", sourceIndex, destIndex)

            setModulesResourcesFetched(false)
            setModulesResourcesFetchedx(false)
            modulesResources[result.destination.droppableId] = newItems
          }
        }
      }
    } else if (type === "resource_resourceDraggable") {
      itemSubItemMap = props.allResources

      sourceSubItems = itemSubItemMap[sourceParentId]
      destSubItems = itemSubItemMap[destParentId]

      let newItems = props.allResources[props.currentSelectedResourcesType]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        //
      } else {
        let newSourceSubItems = itemSubItemMap[props.currentSelectedResourcesType]
        if (!newSourceSubItems) {
          return
        }
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = []
        var formData = new FormData()
        if (result.destination.droppableId.split("_")[0].toString() === `module`) {
          newDestSubItems = modulesResources[destParentId]
          formData.append("add_to_breakout_session", result.destination.droppableId.split("_")[1])
          setModulesResourcesFetched(false)
        } else {
          newDestSubItems = topicsResources[destParentId]
          formData.append("add_to_breakout_activity", result.destination.droppableId.split("_")[1])
          setTopicsResourcesFetched(false)
        }

        let destSubItems = newDestSubItems

        if (result.destination.index === 0 && destSubItems.length === 0) {
          draggedItem.sequence = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index === destSubItems.length) {
          draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem.sequence = (
            (parseFloat(destSubItems[result.destination.index].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        /*api for creating resource to topic or module*/
        formData.append("sequence", draggedItem.sequence)
        if (props.currentSelectedResourcesType == "poll" || props.currentSelectedResourcesType == "pop-quiz") {
          formData.append("resource_type", "quiz")
        } else if (props.currentSelectedResourcesType === "document") {
          formData.append("resource_type", "turientimage")
        } else if (props.currentSelectedResourcesType === "mtf") {
          formData.append("resource_type", "matchthefollowing")
        } else {
          formData.append("resource_type", props.currentSelectedResourcesType)
        }
        formData.append("resource_id", draggedItem.id)
        if (props.draft_content && props.draft_content === "true") {
          formData.append("draft_content", "true")
        }
        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
          .then((res) => {
            let data_id = parseInt(result.destination.droppableId.split("_")[1])

            if (res.data) {
              draggedItem.id = res.data.id
              draggedItem.resource_data = res.data.resource_data
              draggedItem.resource_id = res.data.resource_id
              draggedItem.resource_type = res.data.resource_type
              draggedItem.sequence = res.data.sequence
              sendWSResourcesSaved(data_id, [res.data], sourceIndex, destIndex, { selectedTopic: data_id })
              delete draggedItem["name"]
            }
            if (result.destination.droppableId.split("_")[0].toString() === `module`) {
              setModulesResourcesFetched(true)
            } else {
              setTopicsResourcesFetched(true)
            }
          })
          .catch((error) => {
            handleErrors(error)
          })
        /*api end*/
        newDestSubItems.splice(destIndex, 0, draggedItem)
        setAllResourcesFetched(false)
        let temp_data = props.allResources
        temp_data[props.currentSelectedResourcesType] = newItems
        props.setHomepageAllResource(temp_data)
      }
    }
  }

  /*end-drag and drop all functions*/

  const handleClose = () => {
    setDmodal(false)
    setSelectedDeletingModule("")
    setSelectedDeletingTopic("")
    setDeleteVisibleResource("")
  }

  useEffect(() => {
    if (props.parent_page === "training_content" || props.parent_page === "course_content") {
      if (props.activeBreakoutSession) {
        fetchBreakoutSessions(props.activeBreakoutSession.id)
      } else if (props.showAllBreakoutSessions) {
        fetchBreakoutSessions()
      } else {
        setModuleCreated(false)
        createModule(null)
        setCurrentModuleFetched(true)
      }
      setLoader(true)
    } else {
      fetchBreakoutSessions()
    }
  }, [])

  useEffect(() => {
    if (props.template_id && props.parent_page === "course_content") {
      fetchBreakoutGroupsData()
    }
  }, [props.template_id])

  useEffect(() => {
    if (props.training_id && props.parent_page !== "course_content") {
      fetchBreakoutGroupsData()
    }
  }, [props.training_id])

  useEffect(() => {
    if (props.refetchBreakoutGroups && (props.template_id || props.training_id)) {
      fetchBreakoutGroupsData()
    }
  }, [props.refetchBreakoutGroups])

  async function fetchBreakoutGroupsData() {
    let class_or_course = ""
    if (props.parent_page === "course_content") {
      class_or_course = `template=${props.template_id}`
    } else {
      class_or_course = `training=${props.training_id}`
    }
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/breakoutgroups/?${class_or_course}`, { headers: login_credentials })
      .then((res) => {
        setBreakoutGroupList(res.data.results)
        setBreakoutGroupsDataFetched(true)
      })
      .catch((error) => {
        handleErrors(error)
      })
  }

  async function fetchBreakoutSessions(session_id = null) {
    let filter_set = ""
    if (session_id > 0) {
      filter_set = "&id=" + session_id
    }
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/breakout-sessions/?template=${props.template_id}${filter_set}`, { headers: login_credentials })
      .then((res) => {
        setModules(res.data.results)
        setModulesNext(res.data.next)
        setInitialFetched(true)
        if (res.data.results.length > 0) {
          setCurrentModuleFetched(false)
          let first_module = res.data.results[0]
          toggleModule(null, first_module.id)
          setSelectedModule(first_module.id)
          // selectedModuleToggle[res.data.results[0].id] = true
        } else {
          setModuleCreated(false)
          createModule(null)
          setCurrentModuleFetched(true)
        }
        setLoader(true)
      })
  }

  async function fetchNextTemplateModules(next_url) {
    setModulesLoader(true)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let current_data = [...modules]
      current_data = current_data.concat(res.data.results)

      setModules(current_data)
      setTopicsResources(current_data)
      setModulesNext(res.data.next)
      setModulesLoader(false)
    })
  }

  async function deleteModule() {
    let url = selectedDeletingModule && selectedDeletingModule.id ? `breakout-session` : `breakout-activity`
    if (url === "breakout-session") {
      var current_id = selectedDeletingModule.id
      var index = modules.findIndex((res) => res.id === current_id)
    } else {
      var current_id = selectedDeletingTopic.id
      var index = modulesData[`module_` + selectedModule].topics.findIndex((res) => res.id === current_id)
    }
    let value = ""
    if (props.draft_content && props.draft_content === "true") {
      value = `?draft_content=true`
    }
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/${url}/` + current_id + `/${value}`, { headers: DefaultHeader })
      .then((res) => {
        if (url === "breakout-session") {
          modules.splice(index, 1)
        } else {
          modulesData[`module_` + selectedDeletingTopic.module].topics.splice(index, 1)

          setSelectedTopic(null)

          sendWS("breakout_activity_deleted", { id: current_id }, { selectedTopic: current_id, selectedModule: selectedDeletingTopic.module })
        }
        setSelectedDeletingModule("")
        setSelectedDeletingTopic("")
        setDeleteVisibleResource("")
        setDmodal(!dmodal)
      })
      .catch((error) => {
        handleErrors(error)
      })
  }
  function sendQuery(name, value, datum, type) {
    handleSave(name, value, datum, type)
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )

  function handleSave(name, value, datum, type, data = null, module_id = null, index = null, successFunc = null, failureFunc = null) {
    let sendWSFlag = false,
      distribution_type = "0"
    setModuleEdited(false)
    if (!datum || datum?.id === undefined || datum?.id <= 0) return

    if (name !== "breakout_groups") {
      datum[name] = value
    } else {
      let dummy1 = { ...datum }
      let dummy = []
      if (dummy1.breakout_groups) {
        dummy = [...dummy1.breakout_groups]
      } else {
        dummy1["breakout_groups"] = []
        dummy = [...dummy1.breakout_groups]
      }
      let fil_arr = dummy.filter((res) => res.id == value)
      if (fil_arr.length === 0) {
        let res = {}
        res["id"] = value
        let ind = breakoutGroupList.findIndex((res) => res.id == value)
        res["title"] = breakoutGroupList[ind].title
        dummy.push(res)
        datum[name] = dummy
      } else {
        let res_arr = []
        for (let i = 0; i < dummy.length; i++) {
          if (dummy[i].id != value) {
            res_arr.push(dummy[i])
          }
        }
        datum[name] = res_arr
      }
    }
    var formData = new FormData()
    for (var k in datum) {
      if (datum[k] !== null) {
        if (k === "start_date" || k === "end_date") {
          if (datum[k]) {
            formData.append(k, new Date(datum[k]).toISOString())
          }
        } else if (k === "activity_workspace") {
          formData.append("activity_workspace", JSON.stringify(datum[k]))
        } else {
          if (k === "breakout_groups") {
            distribution_type = "1"
            let dummy1 = { ...datum }
            let dummy = [...dummy1.breakout_groups]
            let res_arr = []
            for (let i = 0; i < dummy.length; i++) {
              res_arr.push(dummy[i].id)
            }
            formData.append("breakout_groups", JSON.stringify(res_arr))
          } else {
            formData.append(k, datum[k])
          }
        }
      }
    }
    if (type === "breakout-activity") {
      sendWSFlag = true
    }

    if (type) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/` + datum.id + "/", formData, { headers: DefaultHeader })
        .then((res) => {
          if (module_id && index >= 0) {
            let dummy = { ...modulesData }
            dummy[`module_` + module_id].topics[index] = res.data
            setModulesData(dummy)
          }

          if (sendWSFlag) {
            let sessionId
            if (datum?.breakout_session && datum?.breakout_session > 0) {
              sessionId = datum?.breakout_session
            } else {
              let data = getSessionIDFromTopic(datum.id)
              sessionId = data.selectedModule
            }
            let index = modules.findIndex((res) => res.id === sessionId)
            let dummy = { ...res.data }
            if (index >= 0) {
              dummy["distribution_type"] = modules[index].distribution_type
            } else {
              dummy["distribution_type"] = distribution_type
            }
            sendWS("breakout_activity_saved", dummy, { selectedTopic: datum.id, selectedModule: sessionId, sessionIndex: index })
          }
          successFunc && successFunc(res)
          setModuleEdited(true)
        })
        .catch((error) => {
          handleErrors(error, failureFunc)
        })
    }
  }

  function handleErrors(error, func = null) {
    if (!error && error?.response?.data === undefined) return

    let error_messages = ""
    for (var key in error.response.data) {
      error_messages += error.response.data[key] + "<br>"
    }
    if (error_messages === "Invalid token.<br>") {
      authClient.signOut()

      localStorage.removeItem("authenticity_token")
      localStorage.removeItem("username")
      window.location.reload(true)
    }
    document.getElementById("fixed-error-div").innerHTML =
      '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
      '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
      '<span aria-hidden="true">×</span></button>' +
      error_messages +
      "</div>"

    func && func()
  }

  function handleDurationSave(datum, new_value, name, type, module_id = null) {
    let webSocketData = { id: datum.id },
      sendWSFlag = false

    if (datum.id) {
      datum[name] = new_value
      var formData = new FormData()
      for (var k in datum) {
        if (datum[k]) {
          if (k === "start_date" || k === "end_date") {
            formData.append(k, new Date(datum[k]).toISOString())
          } else {
            formData.append(k, datum[k])
            if (name === "distribution_type") {
              webSocketData[k] = datum[k]
              sendWSFlag = true
            }
          }
        }
      }

      if (type) {
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/` + datum.id + "/", formData, { headers: DefaultHeader })
          .then((res) => {
            let distribution_type = 0,
              sessionId = datum.id

            let dummy = [...modules]
            let indx = modules.findIndex((res) => res.id === sessionId)
            if (indx >= 0) {
              dummy[indx] = res.data
              distribution_type = parseInt(dummy[indx].distribution_type)
              setModules(dummy)
            }

            if (sendWSFlag) {
              if (distribution_type === 0) {
                let activityIds = []
                let activitiesToBeSent = []
                if (modulesData && modulesData["module_" + sessionId] && modulesData["module_" + sessionId]?.topics) {
                  activitiesToBeSent = modulesData["module_" + sessionId]["topics"].filter((data) => {
                    if (data.activity_workspace && Object.keys(data.activity_workspace).length > 0) {
                      activityIds.push(data.id)
                      return true
                    } else if (topicsResources[`topic_` + data.id] && topicsResources[`topic_` + data.id]?.length > 0) {
                      activityIds.push(data.id)
                      return true
                    } else return false
                  })
                }

                let resourcesToBeSent = {}
                activityIds.map((activityId) => {
                  resourcesToBeSent["act_" + activityId] = topicsResources[`topic_` + activityId]
                })
                sendWS(
                  "breakout_activity_saved",
                  {
                    show_all_activities: 1,
                    distribution_type: webSocketData["distribution_type"],
                    breakout_activities: activitiesToBeSent,
                    resources: resourcesToBeSent,
                  },
                  { selectedModule: sessionId, sessionIndex: indx }
                )
              } else {
                let dummy = {},
                  index = -1
                if (modulesData && modulesData["module_" + sessionId] && modulesData["module_" + sessionId]?.topics) {
                  index = modulesData["module_" + sessionId]["topics"].findIndex((d) => d.id === selectedTopic)
                }
                if (index >= 0) {
                  dummy = modulesData["module_" + sessionId]["topics"][index]
                  sendWS("breakout_activity_saved", { ...dummy, ...webSocketData }, { selectedModule: sessionId, sessionIndex: indx })
                }
              }
            }
          })
          .catch((error) => {
            handleErrors(error)
          })
      }
    }
  }

  useEffect(() => {
    if (moduleCreated === true && modules.length > 0) {
      setSelectedModule(modules[modules.length - 1].id)
    }
  }, [moduleCreated])

  function createModule(e) {
    let module_length = modules.length
    let sequence = (module_length + 1) * 10
    let module = {}
    module.name = `Breakout Session`
    module.name = module.name + ` ${sequence / 10}`
    module.template = props.template_id
    if ((props.parent_page === "training_content" || props.parent_page === "course_content") && props.topic_id) {
      module.topic = props.topic_id
    }
    var formData = new FormData()
    for (var k in module) {
      if (module[k] != null) {
        formData.append(k, module[k])
      }
    }
    if (module.id !== undefined) {
    } else {
      if (props.draft_content && props.draft_content === "true") {
        formData.append("draft_content", "true")
      }
      setModuleCreated(false)
      var module_id = module.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/breakout-session/create/", formData, { headers: DefaultHeader })
        .then((res) => {
          let new_data = {}
          let datum = modules
          if (res.data) {
            new_data.id = res.data.id
            new_data.name = res.data.name
            new_data.sequence = res.data.sequence
            new_data.duration = res.data.duration
            new_data.reminder_time = res.data.reminder_time
            new_data.recurring_reminder = res.data.recurring_reminder
            new_data.distribution_type = res.data.distribution_type
            new_data.status = res.data.status
            new_data.template = res.data.template
            new_data.topic = res.data.topic
            setModules(datum.concat(new_data))
            setSelectedModule(res.data.id)
            toggleModule(e, res.data.id)
            scrollToSpecific(e, "modules_" + res.data.id)
            setModuleCreated(true)
            props.setDisabledResources(disabledResource)
          }
        })
        .catch((error) => {
          handleErrors(error)
        })
    }
  }

  useEffect(() => {
    if (topicCreated === true && modulesData[`module_` + selectedModule]) {
      let datam = modulesData[`module_` + selectedModule].topics
      setSelectedTopic(datam[datam.length - 1].id)
      scrollToSpecific(null, "topics_" + datam[datam.length - 1].id, "topics")
      setCollapse(false)
      setSelectedTopic(datam[datam.length - 1].id)
    }
  }, [topicCreated])

  function createTopicResource(draggedItem, topic, module_id) {
    var formData = new FormData()
    formData.append("add_to_breakout_activity", topic.id)
    setTopicsResourcesFetched(false)
    formData.append("sequence", 0)
    if (props.currentSelectedResourcesType === "document") {
      formData.append("resource_type", "turientimage")
    } else {
      formData.append("resource_type", props.currentSelectedResourcesType)
    }

    formData.append("resource_id", draggedItem.id)
    if (props.draft_content && props.draft_content === "true") {
      formData.append("draft_content", "true")
    }
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
      .then((res) => {
        if (res.data) {
          draggedItem.id = res.data.id
          draggedItem.resource_data = res.data.resource_data
          draggedItem.resource_id = res.data.resource_id
          draggedItem.resource_type = res.data.resource_type
          draggedItem.sequence = res.data.sequence

          sendWSResourcesSaved(topic.id, [res.data], -1, -1, { selectedModule: module_id, selectedTopic: topic.id })
          delete draggedItem["name"]
        }
        topicsResources[`topic_` + topic.id] = [res.data]
        modulesData[`module_` + module_id].topics[modulesData[`module_` + module_id].topics.length - 1].visible_resources = [res.data]
        setTopicsResourcesFetched(true)
      })
      .catch((error) => {
        handleErrors(error)
      })
  }

  function createTopic(e, module_id, draggedItem = null, from_auto_create = false) {
    let topic = {}
    if (!e) {
      topic.visible_as_resource = true
    }
    let topics = modulesData[`module_` + module_id].topics
    let sequence = (topics.length + 1) * 10
    topic.name = "Activity " + sequence / 10
    topic.breakout_session = module_id
    var formData = new FormData()
    for (var k in topic) {
      formData.append(k, topic[k])
    }
    let dummy = [...modules]
    let indx = modules.findIndex((res) => res.id === module_id)
    let breakoutGroupsId = []
    if (indx >= 0 && dummy[indx].status === "1") {
      if (dummy[indx].distribution_type === "0") {
        if (breakoutGroupList && breakoutGroupList.length > 0) {
          breakoutGroupsId = breakoutGroupList.map((d) => d.id)
        }
        formData.append("breakout_groups", JSON.stringify(breakoutGroupsId))
      }
    }

    if (topic.id !== undefined) {
    } else {
      if (props.draft_content && props.draft_content === "true") {
        formData.append("draft_content", "true")
      }
      setTopicCreated(false)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/breakout-activity/create/", formData, { headers: login_credentials })
        .then((res) => {
          let new_data = {}
          if (res.data) {
            new_data = res.data
            let current_data = { ...modulesData }
            current_data[`module_` + module_id].topics = current_data[`module_` + module_id].topics.concat(new_data)
            setModulesData(current_data)
            props.setDisabledResources(disabledResource)
            toggleTopic(e, res.data.id)
            setTopicCreated(true)
            if (e === null && !from_auto_create) {
              setVisibleTopicData({})
              setVisibleTopicDataFetched(!visibleTopicDataFetched)
              createTopicResource(draggedItem, res.data, module_id)
            }
          }
        })
        .catch((error) => {
          handleErrors(error)
        })
    }
  }

  useEffect(() => {
    if (selectedModule != null) {
      fetchCurrentTopics()
    }
  }, [selectedModule])

  useEffect(() => {
    if (currentModuleTopicsFetched && modulesData[`module_` + selectedModule] && modulesData[`module_` + selectedModule].topics.length === 0) {
      setTopicCreated(false)
      createTopic(null, selectedModule, null, true)
    }
  }, [currentModuleTopicsFetched])

  function sendWSForActivityWorkspace(data, sessionId, activityId) {
    //To send the activity workspace data to student side
    if (
      modulesData &&
      sessionId > 0 &&
      activityId > 0 &&
      modulesData["module_" + sessionId] !== undefined &&
      Object.keys(modulesData["module_" + sessionId]).length > 0
    ) {
      let index = modulesData["module_" + sessionId]["topics"].findIndex((dummy) => dummy.id === activityId)
      if (index >= 0) {
        sendWS("breakout_activity_saved", modulesData["module_" + sessionId]["topics"][index], { selectedModule: sessionId, selectedTopic: activityId })
      }
    }
  }

  async function fetchCurrentTopics(refetch = false) {
    if ((modulesData && (modulesData[`module_` + selectedModule] === undefined || modulesData[`module_` + selectedModule].topics === undefined)) || refetch) {
      setCurrentModuleTopicsFetched(false)
      let value = ""
      if (props.draft_content && props.draft_content === "true") {
        value = "&draft_content=true"
      }
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/breakout-activities/?breakout_session=${selectedModule}&paginate_by=15${value}`, {
          headers: login_credentials,
        })
        .then((res) => {
          let fetched_topics = res.data.results
          let current_data = { ...modulesData }
          if (current_data[`module_` + selectedModule] === undefined) {
            current_data[`module_` + selectedModule] = {}
          }
          current_data[`module_` + selectedModule].topics = fetched_topics
          current_data[`module_` + selectedModule].topics_next = res.data.next
          setModulesData(current_data)
          setCurrentModuleTopicsFetched(true)
          if (fetched_topics.length > 0) {
            let last_topic = fetched_topics[fetched_topics.length - 1]
            setSelectedTopic(last_topic.id)
            toggleTopic(null, last_topic.id)
            props.setDisabledResources(disabledResource)
          }
        })
        .catch((error) => {
          setCurrentModuleTopicsFetched(false)
          handleErrors(error)
        })
    } else {
      setSelectedTopic(null)
      setCurrentModuleTopicsFetched(true)
    }
  }

  async function fetchNextCurrentTopics(next_url) {
    let current_module_data = { ...modulesData }
    current_module_data[`module_` + selectedModule].topics_loader = true
    setModulesData(current_module_data)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let current_module_data = { ...modulesData }
      current_module_data[`module_` + selectedModule].topics = current_module_data[`module_` + selectedModule].topics.concat(res.data.results)
      current_module_data[`module_` + selectedModule].topics_next = res.data.next
      current_module_data[`module_` + selectedModule].topics_loader = false
      setModulesData(current_module_data)
      setCurrentModuleTopicsFetched(true)
    })
  }

  function setType(value) {
    if (props.currentSelectedResourcesType !== value) {
      setCurrentSelectedResourcesType(value)
    }
  }

  /******** */
  function validateAndAdd(event) {
    let new_files = event.target.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        setNotificationData({ type: "error", title: new_files[i].name + " has size more than 200MB." })
      }
    }
  }

  function fileInputClicked(event, id, module_id, data) {
    setUploadInTopic(id)
    setUploadInModule(module_id)
    setUploadDataSequence(data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2))

    window.removeEventListener("focus", handleFocusBack)
  }

  function handleFocusBack() {
    setUploadInTopic(null)
    setUploadInModule(null)
    setUploadDataSequence(null)
    window.removeEventListener("focus", handleFocusBack)
  }

  function clickedFileInput() {
    window.addEventListener("focus", handleFocusBack)
  }

  function uploadDataProps(topic_id, module_id, data, showSection = "") {
    let seq = data.length === 0 ? 10 : parseFloat(data[data.length - 1].sequence + 10).toFixed(2)

    setUploadInTopic(topic_id)
    setUploadInModule(module_id)
    setUploadDataSequence(seq)
    setShowSection(showSection)

    if (addResourceType === "common") {
      setAttrForResources({
        template: topic_id,
        mainId: topic_id,
        add_to_group_resource: "true",
        draft_content: props.draft_content,
        stage_id: props.selectedStage,
      })
    } else {
      setAttrForResources({ breakoutActivity: topic_id, mainId: topic_id, draft_content: props.draft_content, stage_id: props.selectedStage })
    }
  }
  function uploadDocs(e, topic_id, module_id, data, upload, showSection) {
    e.preventDefault()
    e.stopPropagation()
    uploadDataProps(topic_id, module_id, data, showSection)
    let domObj = document.getElementById("fileElem_" + topic_id)
    if (domObj && upload) {
      if (showSection === "turientimage") {
        domObj.accept = "image/*"
      }
      domObj.click()
    }
  }
  function openNewWin(section, type, mainId, module_id, data) {
    let wnd,
      seq = data.length === 0 ? 10 : parseFloat(data[data.length - 1].sequence + 10).toFixed(2)
    uploadDataProps(mainId, module_id, data)
    let draft_content = props.draft_content
    let stage_id = props.selectedStage
    let parentTempId = props.course_real_template_id

    if (type === "quiz") {
      wnd = window.open("/app/quizzes/create", type + "12")
      quizWin.current = { wnd, mainId, module_id, seq, section, draft_content, stage_id, addResourceType, parentTempId }
    } else if (type === "mtf") {
      wnd = window.open("/app/match-the-following/create", type + "12")
      mtfWin.current = { wnd, mainId, module_id, seq, section, draft_content, stage_id, addResourceType, parentTempId }
    } else if (type === "casestudy") {
      wnd = window.open("/app/case-study/create", type + "12")
      casestudyWin.current = { wnd, mainId, module_id, seq, section, draft_content, stage_id, addResourceType, parentTempId }
    } else if (type === "checklist") {
      wnd = window.open("/app/checklist/create", type + "12")
      checklistWin.current = { wnd, mainId, module_id, seq, section, draft_content, stage_id, addResourceType, parentTempId }
    }
  }
  function getNewWinData(type) {
    let win = null
    if (type === "quiz") {
      win = quizWin.current
    } else if (type === "mtf") {
      win = mtfWin.current
    } else if (type === "casestudy") {
      win = casestudyWin.current
    } else if (type === "checklist") {
      win = checklistWin.current
    }
    return win
  }

  function handleNewWinMessages(event) {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      if (event.data.instruction === "ready") {
        let win = getNewWinData(event.data.type)
        if (win && win.wnd) {
          let obj = { breakoutActivity: win.mainId, sequence: win.seq, draft_content: win.draft_content, stage_id: win.stage_id }
          if (win.addResourceType === "common") {
            obj = { template: win.mainId, sequence: win.seq, add_to_group_resource: "true", draft_content: win.draft_content, stage_id: win.stage_id }
          }
          win.wnd.postMessage({ instruction: { ...obj } }, window.location.origin)
        }
      } else if (event.data.instruction === "payload") {
        let win = getNewWinData(event.data.type)
        if (win) {
          setTimeout(() => {
            fetchTopicResources(win.mainId, true)
          }, 1500) //Delay because the python backend thread takes time
          const evt = new CustomEvent("my-refresh-resources", { bubbles: true, detail: event.data.type })
          document.dispatchEvent(evt)
        }
      }
    }
  }

  function openModal(type, mainId, module_id, data) {
    let seq = data.length === 0 ? 10 : parseFloat(data[data.length - 1].sequence + 10).toFixed(2)
    uploadDataProps(mainId, module_id, data)
    let draft_content = props.draft_content
    let stage_id = props.selectedStage
    let parentTempId = props.course_real_template_id

    let obj = { breakoutActivity: mainId, mainId, sequence: seq, draft_content, stage_id, addResourceType, parentTempId }
    if (addResourceType === "common") {
      obj = { template: mainId, mainId, sequence: seq, add_to_group_resource: "true", draft_content, stage_id, addResourceType, parentTempId }
    }

    if (type === "poll") {
      setShowPollModal(true)
      props.setShowPollModal && props.setShowPollModal(true)

      const evt = new CustomEvent("my-poll-data", { bubbles: true, detail: { ...obj } })
      document.dispatchEvent(evt)
    } else if (type === "pop-quiz") {
      setShowPopQuizModal(true)
      props.setShowPopQuizModal && props.setShowPopQuizModal(true)

      const evt = new CustomEvent("my-pop-quiz-data", { bubbles: true, detail: { ...obj } })
      document.dispatchEvent(evt)
    }
  }
  function handlePollModalMsgs(evt) {
    setShowPollModal(false)
    props.setShowPollModal && props.setShowPollModal(false)

    setTimeout(() => {
      if (evt.detail.addResourceType === "common") {
        //fetchBreakoutGroupsResources(evt.detail.mainId, evt.detail.parentTempId, evt.detail.draft_content)
      } else {
        fetchTopicResources(evt.detail.mainId ?? uploadInTopic)
      }
    }, 1500) //Delay because the python backend thread takes time
  }

  function handlePopQuizModalMsgs(evt) {
    setShowPopQuizModal(false)
    props.setShowPopQuizModal && props.setShowPopQuizModal(false)

    setTimeout(() => {
      if (evt.detail.addResourceType === "common") {
        //fetchBreakoutGroupsResources(evt.detail.mainId, evt.detail.parentTempId, evt.detail.draft_content)
      } else {
        fetchTopicResources(evt.detail.mainId ?? uploadInTopic)
      }
    }, 1500) //Delay because the python backend thread takes time
  }

  function refetchLeftSideResourcesBar(pActiveResType = "") {
    const evt = new CustomEvent("my-refresh-resources", { bubbles: true, detail: pActiveResType })
    document.dispatchEvent(evt)
  }

  /******** */

  function showTopicsData(topic_id, visible_as_resource = false, module_id = null, datum = null) {
    if (topic_id !== null) {
      let data = [],
        selectedStage = "module_" + selectedModule
      let stage_data = modulesData[selectedStage]
      var index = null
      if (modulesData[selectedStage] && modulesData[selectedStage].topics && modulesData[selectedStage].topics.length > 0) {
        index = modulesData[selectedStage].topics.findIndex((res) => res.id === topic_id)
      }
      data =
        visible_as_resource === false
          ? topicsResources[`topic_` + topic_id]
          : index !== null && modulesData[selectedStage].topics[index].visible_resources
          ? modulesData[selectedStage].topics[index].visible_resources
          : topicsResources[`topic_` + topic_id]
      if (visible_as_resource) {
        return showResourcesButton(`topic`, topic_id, visible_as_resource, module_id)
      } else if (topic_id !== null && topicsData["topic_" + topic_id]) {
      }
      {
        return (
          <div className={`px-0`} style={{ borderTop: `1px solid #929BBD`, backgroundColor: "#42506c", borderRadius: "0px 0px 8px 8px" }}>
            {showResourcesButton(`topic`, topic_id, visible_as_resource, module_id)}
            <div className="d-flex p-1" style={{ borderTop: `1px solid #929BBD`, borderBottom: `1px solid #929BBD`, borderRadius: "0px 0px 8px 8px" }}>
              {props?.isPermitted && (
                <>
                  {" "}
                  <OverlayTrigger overlay={<Tooltip>Add video</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faVideo}
                      onClick={(e) => {
                        props.openNavWithResourceSection("Resource", "video")
                        uploadDocs(e, topic_id, module_id, data, false, "video")
                      }}
                      title={`Add Videos`}
                      alt={`Add Videos`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{ objectFit: `contain`, width: `17px`, height: `17px`, color: "#fff" }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add blog</Tooltip>}>
                    <img
                      onClick={(e) => {
                        props.openNavWithResourceSection("Resource", "blog")
                        uploadDataProps(topic_id, module_id, data, "")
                        setAddBlog(true)
                      }}
                      src={WhiteBlogIcon}
                      title={`Add Blog`}
                      alt={`add blog button`}
                      className={`${
                        props.leftNavOpen && props.currentSelectedResourcesType === "blog" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{ objectFit: `contain`, width: `17px`, height: `17px`, color: "#fff" }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add documents</Tooltip>}>
                    <img
                      onClick={(e) => {
                        props.openNavWithResourceSection("Resource", "document")
                        uploadDocs(e, topic_id, module_id, data, false, "document")
                      }}
                      src={documents}
                      alt={`Add Documents`}
                      title={`Add Documents(Doc, Excel, PPT, PDF, Google Docs Link)`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{ objectFit: `contain`, width: `17px`, height: `17px`, color: "#fff" }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add images</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faImage}
                      onClick={(e) => {
                        props.openNavWithResourceSection("Resource", "turientimage")
                        uploadDocs(e, topic_id, module_id, data, true, "turientimage")
                      }}
                      title={`Add Images`}
                      alt={`Add Images`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{ objectFit: `contain`, width: `17px`, height: `17px`, color: "#fff" }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add links</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faLink}
                      onClick={(e) => {
                        props.openNavWithResourceSection("Resource", "link")
                        uploadDocs(e, topic_id, module_id, data, false, "link")
                      }}
                      title={`Add Links`}
                      alt={`Add Links`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{ objectFit: `contain`, width: `17px`, height: `17px`, color: "#fff" }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add quiz</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        props.openNavWithResourceSection("Poll", "quiz")
                        openNewWin("Poll", "quiz", topic_id, module_id, data)
                      }}
                      src={WhiteQuizIcon}
                      title={`Add Quiz`}
                      alt={`add quiz button`}
                      className={`${
                        props.leftNavOpen && props.currentSelectedResourcesType === "quiz" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{ objectFit: `contain`, width: `17px`, height: `17px`, color: "#fff" }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add matching</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        props.openNavWithResourceSection("Poll", "mtf")
                        openNewWin("Poll", "mtf", topic_id, module_id, data)
                      }}
                      src={whiteMatchingIcon}
                      title={`Add Matching`}
                      alt={`add matching button`}
                      className={`${
                        props.leftNavOpen && props.currentSelectedResourcesType === "mtf" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{ objectFit: `contain`, width: `17px`, height: `17px`, color: "#fff" }}
                    />
                  </OverlayTrigger>
                  <span style={{ width: 17, height: 17, marginBottom: 12 }}>&nbsp;|&nbsp;</span>
                  <OverlayTrigger overlay={<Tooltip>Add checklist</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        props.openNavWithResourceSection("Poll", "checklist")
                        openNewWin("Poll", "checklist", topic_id, module_id, data)
                      }}
                      src={checklist}
                      title={`Add Checklist`}
                      alt={`add checklist button`}
                      className={`${
                        props.leftNavOpen && (props.currentSelectedResourcesType === "poll" || props.currentSelectedResourcesType === "checklist")
                          ? StylesB.resource_select_icon_active
                          : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{ objectFit: `contain`, width: `17px`, height: `17px`, color: "#fff" }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add case-study</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        props.openNavWithResourceSection("Poll", "casestudy")
                        openNewWin("Poll", "casestudy", topic_id, module_id, data)
                      }}
                      src={casestudy}
                      title={`Add Case-Study`}
                      alt={`add case study button`}
                      className={`${
                        props.leftNavOpen && (props.currentSelectedResourcesType === "poll" || props.currentSelectedResourcesType === "casestudy")
                          ? StylesB.resource_select_icon_active
                          : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{ objectFit: `contain`, width: `17px`, height: `17px`, color: "#fff" }}
                    />
                  </OverlayTrigger>
                </>
              )}
              {isMuralEnabled && (
                <ActivityWokSpace
                  activity_workspace={datum?.activity_workspace}
                  training_id={props.training_id}
                  breakoutGroupList={datum?.breakout_groups.length > 0 ? datum?.breakout_groups : breakoutGroupList}
                  activityId={topic_id}
                  sessionId={module_id}
                  key={topic_id}
                  setModulesData={setModulesData}
                  modulesData={modulesData}
                  sendWS={sendWSForActivityWorkspace}
                  isPermitted={props?.isPermitted}
                />
              )}
            </div>
          </div>
        )
      }
    } else return null
  }

  function showVisibleTopicBtn(module_id, index) {
    var type = "visibleTopicData"
    let data = visibleTopicData[type + `_` + module_id] ? visibleTopicData[type + `_` + module_id] : []
    return (
      <Droppable droppableId={type + `_` + module_id} type={`resourceDraggable`}>
        {(provided, snapshot) => (
          <div className={`bg-transparent`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {data.map((item) => {
              let { typeId, imgObj } = getImageToBeRendered(item)
              return (
                <>
                  <Draggable
                    key={item.id + `_${type}_name_` + index}
                    draggableId={item.id.toString() + `-${type}` + "_" + index.toString()}
                    index={index}
                    isDragDisabled={!props?.isPermitted}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`p-0 float-none`}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div className={`p-2 mb-2`} style={{ color: `#C6CFF4`, position: `relative`, background: "#28314e" }}>
                          <p className={`m-0`}>
                            {item.resource_data ? (
                              item.resource_data.thumbnail_url ? (
                                <LazyLoadImage
                                  className={`my-auto`}
                                  src={item.resource_data.thumbnail_url}
                                  alt={item.name}
                                  style={{ width: `38px`, height: `30px`, fontSize: `12px` }}
                                />
                              ) : (
                                <>
                                  {typeId === 1 ? (
                                    <FontAwesomeIcon
                                      icon={imgObj}
                                      className={`my-0`}
                                      style={{ color: "#67edfd", marginBottom: 0, width: `38px`, height: `30px` }}
                                    />
                                  ) : (
                                    <LazyLoadImage
                                      className={`my-auto`}
                                      src={imgObj}
                                      alt={item.name}
                                      style={{ width: `38px`, height: `30px`, fontSize: `12px` }}
                                    />
                                  )}
                                </>
                              )
                            ) : (
                              ``
                            )}
                            {item.resource_data?.display_name ?? item.resource_data?.name ?? item.display_name ?? item.name}
                          </p>
                        </div>
                      </div>
                    )}
                  </Draggable>
                </>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }

  function showCurrentModuleData(breakout_session) {
    let module_id = breakout_session.id
    if (modulesData[`module_` + module_id] !== undefined) {
      let datum = modulesData[`module_` + module_id]
      return (
        <Card className={`${StylesA.topic_card} w-100`}>
          <Card.Body className={`pt-0 px-0 pb-0`}>
            {/*topics view*/}
            {showTopicsButtons(breakout_session)}
            {currentModuleTopicsFetched && modulesData[`module_` + module_id] && modulesData[`module_` + module_id].topics_next !== null ? (
              <Col lg="12" className={`text-center`}>
                <Btn
                  disabled={modulesData[`module_` + module_id].topics_loader}
                  onClick={(e) => {
                    e.preventDefault()
                    fetchNextCurrentTopics(modulesData[`module_` + module_id].topics_next)
                  }}
                  className={`text-center px-3 py-1 mx-auto mb-2`}
                  style={{ backgroundColor: `#212c42`, color: `#c6cff4`, borderRadius: `15px` }}
                >
                  {modulesData[`module_` + module_id].topics_loader ? <Spinner animation="border" variant="secondary" size="sm" className="mr-2" /> : ""}
                  See More
                </Btn>
              </Col>
            ) : (
              ``
            )}
          </Card.Body>
        </Card>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  useEffect(() => {
    setSelectedModuleToggleChange(!selectedModuleToggleChange)
  }, [selectedModuleToggle[selectedModule]])

  function toggleModule(e = null, datum_id) {
    selectedModuleToggle[datum_id] = !selectedModuleToggle[datum_id]
    setSelectedModuleToggleChange(!selectedModuleToggleChange)
  }

  useEffect(() => {
    setSelectedTopicToggleChange(!selectedModuleToggleChange)
  }, [selectedTopicToggle[selectedTopic]])

  function toggleTopic(e, datum_id) {
    selectedTopicToggle[datum_id] = !selectedTopicToggle[datum_id]
    setSelectedTopicToggleChange(!selectedTopicToggleChange)
  }

  function trigger(session) {
    props.setTriggerSession(session)
  }

  function showBreakoutSessionStartButton(session) {
    if (props.parent_page === "live_class") {
      let session_bg_check =
        modulesData && modulesData["module_" + session.id]?.topics.length > 0
          ? modulesData["module_" + session.id]?.topics?.filter((topic) => topic.breakout_groups.length == 0)
          : []
      let session_bg_check_no_data =
        modulesData && modulesData["module_" + session.id]?.topics.length > 0
          ? modulesData["module_" + session.id]?.topics?.filter((topic) => topic.breakout_groups.length > 0)
          : []
      return (
        <>
          <Btn
            style={{ pointerEvents: "all" }}
            onClick={(e) => {
              if (!(session_bg_check.length > 0 && session_bg_check_no_data.length > 0)) {
                trigger(session)
                selectCloseFunctionality()
              }
            }}
          >
            <img
              src={BreakoutSessionIcon}
              alt={`breakout_session_icon`}
              className={`my-auto`}
              style={{ objectFit: `contain`, width: "15px", marginRight: "5px" }}
            />
            {session.status === "1" ? "View Session" : session.status === "0" ? "Start Session" : "View Session Results"}
          </Btn>
          {session.status === "2" ? (
            <Btn
              style={{ pointerEvents: "all" }}
              onClick={(e) => {
                if (!(session_bg_check.length > 0 && session_bg_check_no_data.length > 0)) {
                  reTriggerBrSession(session)
                  selectCloseFunctionality()
                }
              }}
              className={`ml-3`}
            >
              <img
                src={BreakoutSessionIcon}
                alt={`breakout_session_icon`}
                className={`my-auto`}
                style={{ objectFit: `contain`, width: "15px", marginRight: "5px" }}
              />
              Start Session Again
            </Btn>
          ) : null}

          {topicCreated && props.parent_page === "live_class" && session_bg_check.length > 0 && session_bg_check_no_data.length > 0 ? (
            <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
              <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "11px" }} />
              <p className="mb-0" style={{ fontSize: "12px", color: "#F4F411" }}>
                please select breakout groups for activities
              </p>
            </div>
          ) : (
            ""
          )}
        </>
      )
    } else {
      return null
    }
  }

  function showModulesButtons() {
    if (loader) {
      if (modules && modules.length > 0) {
        return (
          <Droppable droppableId={`moduleDraggable`} type={`moduleDraggable`}>
            {(provided, snapshot) => (
              <React.Fragment>
                <div className={`col-12`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {modules.map((datum, index) => (
                    <Draggable
                      key={datum.id + `_module_name_` + index}
                      isDragDisabled={!props?.isPermitted}
                      draggableId={datum.id.toString() + "-module" + "_" + index.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`${StylesA.border_on_hover} ${datum.status === "2" ? "disabled-div" : ""}`}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div
                            variant="none"
                            id={"modules_" + datum.id}
                            className={`${Styles.font_16} ${selectedModuleToggle[datum.id]} p-2 shadow-none d-flex`}
                            style={{ color: `#F2F4FF`, justifyContent: "space-between" }}
                          >
                            <Col xs="10" sm="10" md="10" lg="10" className="d-flex pl-0">
                              {selectedModuleToggle[datum.id] ? (
                                <img
                                  src={DragHandleIcon}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    toggleModule(e, datum.id)
                                  }}
                                  alt={`drag-handle`}
                                  className={`my-auto mr-1 pointer`}
                                  style={{ objectFit: `contain`, height: `13px`, width: "16px" }}
                                />
                              ) : (
                                <img
                                  src={DragHandleIcon}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedModule(datum.id)
                                    toggleModule(e, datum.id)
                                  }}
                                  alt={`drag-handle`}
                                  className={`my-auto mr-1 pointer`}
                                  style={{ objectFit: `contain`, height: `13px`, width: "16px" }}
                                />
                              )}
                              <div className={`input-group ${StylesA.session_input_field} form-control ${StylesA.border_on_hover} pt-1 px-2 mx-2 col-5`}>
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="name"
                                  id={datum.id + `_module_input_name`}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                  }}
                                  defaultValue={datum.name}
                                  onChange={(e) => {
                                    e.preventDefault()
                                    delayedQuery("name", e.target.value, datum, "breakout-session")
                                  }}
                                  placeholder={"Enter Name"}
                                  className={`border-none ${Styles.font_16} d-inline-flex mr-auto ${!props?.isPermitted && "disable-content"}`}
                                  style={{ backgroundColor: "#42506C", color: "#E0E2E5", borderRadius: "0px !important", width: "85%", height: "fit-content" }}
                                  required
                                />
                              </div>
                              <DurationInputField datum={datum} handleDurationSave={handleDurationSave} isPermitted={props?.isPermitted} />
                              <ReminderInputField datum={datum} handleDurationSave={handleDurationSave} isPermitted={props?.isPermitted} />
                            </Col>
                            <div className="d-flex">
                              {selectedModuleToggle[datum.id] ? (
                                <FontAwesomeIcon
                                  className="mr-2 pointer"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    toggleModule(e, datum.id)
                                  }}
                                  icon={faChevronCircleUp}
                                  style={{ width: `16px`, height: `16px`, margin: `auto 0px`, pointerEvents: "all" }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="mr-2 pointer"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedModule(datum.id)
                                    toggleModule(e, datum.id)
                                  }}
                                  icon={faChevronCircleDown}
                                  style={{ width: `16px`, height: `16px`, margin: `auto 0px`, pointerEvents: "all" }}
                                />
                              )}
                              {datum?.status !== "1" && props?.isPermitted && (
                                <img
                                  src={DeleteIcon}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setDmodal(true)
                                    setSelectedDeletingModule(datum)
                                  }}
                                  alt={`delete icon`}
                                  className={`my-auto mr-1 pointer`}
                                  style={{ objectFit: `contain`, height: `13px` }}
                                />
                              )}
                            </div>
                          </div>
                          <Col xs="12" sm="12" md="12" className={`${selectedModuleToggle[datum.id] ? `` : `d-none`}`} style={{ padding: "0px 12px 0px 39px" }}>
                            {showCurrentModuleData(datum)}
                          </Col>
                          {selectedModuleToggle[datum.id] ? (
                            ""
                          ) : (
                            <div className="pb-2 d-flex align-items-center" style={{ paddingLeft: "40px" }}>
                              {showBreakoutSessionStartButton(datum)}
                            </div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
                {loader && modulesNext !== null ? (
                  <Col md="auto" className={`justify-content-center w-100 d-inline-flex`}>
                    <Btn
                      disabled={modulesLoader}
                      onClick={(e) => {
                        e.preventDefault()
                        fetchNextTemplateModules(modulesNext)
                      }}
                      className={`text-center px-3 py-1 mx-auto`}
                      style={{ backgroundColor: `#303c54`, color: `#c6cff4`, borderRadius: `15px` }}
                    >
                      {modulesLoader ? <Spinner animation="border" variant="secondary" size="sm" className="mr-2" /> : ""}
                      See More
                    </Btn>
                  </Col>
                ) : (
                  ""
                )}
                {props.parent_page === "training_content" || (props.parent_page === "course_content" && !props.showAllBreakoutSessions) ? (
                  ""
                ) : (
                  <div className="px-2 pb-2 d-flex" style={{ justifyContent: "space-between" }}>
                    {loader && modulesNext === null ? (
                      <React.Fragment>
                        <div className="d-flex">
                          <Btn
                            disabled={!moduleCreated}
                            className={`d-flex`}
                            onClick={(e) => {
                              setModuleCreated(false)
                              createModule(e)
                            }}
                          >
                            {moduleCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                            Add Session
                          </Btn>
                          <Btn className={`ml-2`} onClick={() => selectCloseFunctionality()}>
                            I am done!
                          </Btn>
                        </div>
                        <div className="d-flex" style={{ alignItems: "center" }}>
                          <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, width: `13px` }} />
                          <p style={{ fontSize: "12px", margin: "0px", color: "#E0E2E5" }}>All changes are saved</p>
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
          </Droppable>
        )
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-3">
            <p style={{ fontSize: "14px", margin: "0px", color: "#E0E2E5" }}>No sessions created yet!</p>
            {loader && modulesNext === null ? (
              <div className="d-flex justify-content-center pt-2">
                <Btn
                  disabled={!moduleCreated}
                  onClick={(e) => {
                    setModuleCreated(false)
                    createModule(e)
                  }}
                >
                  {moduleCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                  Add Session
                </Btn>
              </div>
            ) : (
              ""
            )}
          </Col>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-3">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function getSelectionStatus(id, arr) {
    let flag = 0
    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == id) {
          flag = 1
        }
      }
    }
    if (flag === 1) {
      return <FontAwesomeIcon icon={faCheck} className={`ml-auto`} />
    }
  }

  function stripHTML(html) {
    let doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  function showTopicsButtons(breakout_session) {
    let module_id = breakout_session.id
    if (modulesData[`module_` + module_id] && modulesData[`module_` + module_id].topics) {
      let module_datum = modules.filter((module) => module.id === module_id)[0]
      if (modulesData[`module_` + module_id].topics.length > 0) {
        return (
          <Droppable droppableId={`module_` + module_id} type={`topicDraggable`}>
            {(provided, snapshot) => (
              <React.Fragment>
                <div className={`bg-transparent`} ref={provided.innerRef} style={getTopicListStyle(snapshot.isDraggingOver)}>
                  {modulesData[`module_` + module_id].topics.map((datum, index) => {
                    return (
                      <Draggable
                        key={datum.id + `_topic_name_` + index}
                        isDragDisabled={!props?.isPermitted}
                        draggableId={datum.id.toString() + "-topic" + "_" + index.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${StylesA.border_on_hover} ${breakout_session.status === "2" ? "disabled-div" : ""} bg-transparent float-none`}
                            style={getTopicItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <div
                              variant="none"
                              id={"topics_" + datum.id}
                              className={`${Styles.font_16} px-2 shadow-none d-inline-flex w-100 align-items-center`}
                              style={{
                                color: `#F2F4FF`,
                                justifyContent: "space-between",
                                backgroundColor: "#42506C",
                                borderRadius: selectedTopicToggle[datum.id] ? "8px 8px 0px 0px" : "8px",
                                padding: "4px 0px",
                              }}
                            >
                              <div className="d-flex pl-0 col-12" style={{ flexGrow: 1, flexShrink: 1, flexBasis: "fit-content" }}>
                                <img
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedTopic(datum.id)
                                    toggleTopic(e, datum.id)
                                  }}
                                  src={DragHandleIcon}
                                  alt={`topic-drag-handle`}
                                  className={`my-auto mr-1 pointer`}
                                  style={{ objectFit: `contain`, height: `13px`, width: "16px" }}
                                />
                                <div
                                  className={`${StylesA.session_input_field} form-control ${StylesA.border_on_hover} pt-1 px-1 mx-1 w-100`}
                                  style={{ position: "relative" }}
                                >
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="name"
                                    id={datum.id + `_topic_input_name`}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                    }}
                                    defaultValue={datum.name}
                                    onChange={(e) => {
                                      e.preventDefault()
                                      delayedQuery("name", e.target.value, datum, "breakout-activity")
                                    }}
                                    placeholder={"Activity Name"}
                                    className={`${StylesA.activity_input_field} ${!props?.isPermitted && "disable-content"} form-control border-0 px-2 ${
                                      Styles.font_16
                                    } d-inline-flex mr-auto`}
                                    style={{
                                      color: "#E0E2E5",
                                      width: "90%",
                                      height: "fit-content",
                                    }}
                                    required
                                  />
                                  <div className={`pointer `} style={{ position: "absolute", right: 0, top: 0, zIndex: 1 }}>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip placement="bottom" className="classroom-tooltip" id={`"tooltip-${datum.id}"`}>
                                          <div>
                                            {datum?.description?.length > 0 ? (
                                              <div className="d-flex align-self-center">
                                                <span>
                                                  Description has been added.
                                                  <br />
                                                  Click to view or edit
                                                </span>
                                              </div>
                                            ) : (
                                              "Add Description"
                                            )}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className={`mx-1 pointer`}>
                                        <img
                                          src={datum?.description?.length > 0 ? AddedDescriptionIcon : AddDescriptionIcon}
                                          alt={`add-description`}
                                          onClick={() => {
                                            setShowAddInstructionsModal(true)
                                            setActiveDatum(datum)
                                          }}
                                          className={`my-auto mr-1 pointer `}
                                          style={{ objectFit: `contain`, height: 30, width: 30 }}
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                                {(props.template_id || props.training_id) && breakout_session?.distribution_type === "1" ? (
                                  <>
                                    <Dropdown className={`breakout-groups`}>
                                      <Dropdown.Toggle
                                        size="lg"
                                        className={`border-0 px-1 py-2 d-inline-flex align-items-center ${StylesA.btn_focus}`}
                                        id="dropdown_list"
                                        style={{ fontSize: "14px", backgroundColor: "#0E121B", borderRadius: "4px" }}
                                      >
                                        <FontAwesomeIcon color="#E0E2E5" className={`mb-0 mr-1`} icon={faUsers} />
                                        {datum?.breakout_groups?.length > 0 ? (
                                          datum?.breakout_groups?.map((grp, indx) => {
                                            return (
                                              <p
                                                key={`${datum.id}_selected_groups_${indx}`}
                                                className={`mb-0 ${indx + 1 == datum?.breakout_groups.length ? "pr-3" : "pr-2"}`}
                                              >
                                                {grp.title}
                                                {`${indx + 1 < datum?.breakout_groups?.length ? "," : ``}`}
                                              </p>
                                            )
                                          })
                                        ) : (
                                          <p className={`mb-0 pr-1`}>Select groups</p>
                                        )}
                                        <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        className={`bg py-0 ${Styles.font_12}`}
                                        style={{ backgroundColor: "#0E121B", overflowY: "scroll", maxHeight: 200 }}
                                      >
                                        {breakoutGroupList.map((group, index_g) => {
                                          return (
                                            <Dropdown.Item
                                              key={`${datum.id}_dropdown_item_${index_g}`}
                                              className="px-2 py-1 d-inline-flex align-items-center"
                                              style={{
                                                borderTop: "1px solid rgb(224, 226, 229)",
                                                color: "rgb(224, 226, 229)",
                                                borderRadius: "0px 0px 8px 8px",
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault()
                                                handleSave("breakout_groups", group.id, datum, "breakout-activity", modulesData, module_id, index)
                                              }}
                                            >
                                              <p className={`mb-0 w-100 d-inline-flex align-items-center`}>
                                                {group.title}
                                                {getSelectionStatus(group.id, datum?.breakout_groups)}
                                              </p>
                                            </Dropdown.Item>
                                          )
                                        })}
                                        {breakoutGroupList.length === 0 ? (
                                          <Dropdown.Item
                                            className="px-2 py-1 d-inline-flex align-items-center"
                                            style={{
                                              borderTop: "1px solid rgb(224, 226, 229)",
                                              color: "rgb(224, 226, 229)",
                                              borderRadius: "0px 0px 8px 8px",
                                            }}
                                          >
                                            No groups Created
                                          </Dropdown.Item>
                                        ) : null}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </>
                                ) : null}
                              </div>
                              <div className="d-flex ml-auto">
                                <FontAwesomeIcon
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedTopic(datum.id)
                                    toggleTopic(e, datum.id)
                                  }}
                                  className="mr-2 pointer"
                                  icon={selectedTopicToggle[datum.id] ? faChevronCircleUp : faChevronCircleDown}
                                  style={{ width: `16px`, height: `16px`, margin: `auto 0px`, pointerEvents: "all" }}
                                />
                                {props?.isPermitted && (
                                  <img
                                    src={DeleteIcon}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      setDmodal(true)
                                      datum.module = module_id
                                      setSelectedDeletingTopic(datum)
                                    }}
                                    alt={`topic-delete`}
                                    className={`my-auto mr-1 pointer`}
                                    style={{ objectFit: `contain`, height: `13px` }}
                                  />
                                )}
                              </div>
                            </div>
                            {selectedTopicToggle[datum.id] ? showTopicsData(datum.id, false, module_id, datum) : ``}
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                  {/*showVisibleTopicBtn(module_id,modulesData[`module_`+module_id].topics.length)*/}
                </div>
                <div className="pb-2 d-flex" style={{ alignItems: "center", justifyContent: "space-between" }}>
                  <div className="d-flex align-items-center">
                    {currentModuleTopicsFetched && modulesData[`module_` + module_id] && modulesData[`module_` + module_id].topics_next === null ? (
                      <React.Fragment>
                        {props?.isPermitted && (
                          <Btn
                            disabled={!topicCreated}
                            className={`mr-3 py-1`}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setTopicCreated(false)
                              setSelectedModule(module_datum.id)
                              selectedModuleToggle[module_datum.id] = true
                              createTopic(e, module_id)
                            }}
                          >
                            {topicCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                            Add Activity
                          </Btn>
                        )}
                        {topicCreated &&
                        props.parent_page != "live_class" &&
                        modulesData &&
                        modulesData["module_" + module_id] != undefined &&
                        modulesData["module_" + module_id]?.topics.length > 0 &&
                        modulesData["module_" + module_id]?.topics?.filter((topic) => topic?.breakout_groups.length == 0).length > 0 &&
                        modulesData["module_" + module_id]?.topics?.filter((topic) => topic?.breakout_groups.length > 0).length > 0 ? (
                          <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
                            <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "11px" }} />
                            <p className="mb-0" style={{ fontSize: "12px", color: "#F4F411" }}>
                              please select breakout groups for activities
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {selectedModuleToggle[module_datum.id] ? showBreakoutSessionStartButton(module_datum) : ""}
                      </React.Fragment>
                    ) : (
                      ``
                    )}
                  </div>
                  <DistributionDropDown
                    isPermitted={props.isPermitted}
                    datum={module_datum}
                    updateBreakoutSession={() => updateBreakoutSession()}
                    module_id={module_id}
                    topics_count={modulesData[`module_` + module_id].topics.length}
                    handleDurationSave={handleDurationSave}
                    openCreatingGroupsModal1={() => {
                      if (props.openCreatingGroupsModal) {
                        props.openCreatingGroupsModal()
                      }
                    }}
                  />
                </div>
              </React.Fragment>
            )}
          </Droppable>
        )
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-4 justify-content-center">
            <p style={{ fontSize: "13px", margin: "0px", color: "#E0E2E5", marginBottom: "5px" }}>No activity created for this session!</p>
            {currentModuleTopicsFetched && modulesData[`module_` + module_id] && modulesData[`module_` + module_id].topics_next === null ? (
              <Btn
                disabled={!topicCreated}
                className={`mx-auto`}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setTopicCreated(false)
                  setSelectedModule(module_datum.id)
                  selectedModuleToggle[module_datum.id] = true
                  createTopic(e, module_id)
                }}
              >
                {topicCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                Add Activity
              </Btn>
            ) : (
              ``
            )}
          </Col>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  function onDragEnter(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  function onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  function onDragLeave(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  function onDrop(event, module_id, id, data) {
    event.preventDefault()
    event.stopPropagation()
    setUploadInTopic(id)
    setUploadInModule(module_id)
    setUploadDataSequence(data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2))

    setShowSection("video")
    handleDrop(event)
  }

  function handleDrop(e) {
    var dt = e.dataTransfer
    let new_files = dt.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        setNotificationData({ type: "error", title: new_files[i].name + " has size more than 200MB." })
      }
    }
  }

  function getImageToBeRendered(item) {
    let imgObj = turientimage,
      itemName = "",
      typeId = 2

    itemName = item.resource_data?.display_name ?? item.resource_data?.name ?? item.display_name ?? item.name
    let typeOfFile = item?.resource_data?.file_type || 0
    if (item.resource_data?.avatar) {
      imgObj = item.resource_data.avatar
    }

    if (item.resource_type === "video") {
      imgObj = video
    } else if (item.resource_type === "blog") {
      imgObj = blog
    } else if (item.resource_type === "link") {
      imgObj = link
    } else if (item.resource_type === "matchthefollowing") {
      imgObj = whiteMatchingIcon
    } else if (item.resource_type === "quiz") {
      imgObj = quiz
      let dataType = item?.resource_data?.data_type || 0
      if (dataType == 1) {
        imgObj = poll
      } else if (dataType == 2) {
        imgObj = popQuiz
      }
    } else if (item.resource_type === "turientimage") {
      imgObj = faImage
      typeId = 1
      if (typeOfFile == 2) {
        imgObj = doc
        typeId = 2
      } else if (typeOfFile == 3) {
        imgObj = excel
        typeId = 2
      } else if (typeOfFile == 4) {
        imgObj = ppt
        typeId = 2
      } else if (typeOfFile == 1) {
        imgObj = pdf
        typeId = 2
      }
    } else if (item.resource_type === "checklist") {
      imgObj = checklist
    } else if (item.resource_type === "casestudy") {
      imgObj = casestudy
    }

    return { typeId, imgObj }
  }

  function showResourcesButton(type, id, visible_as_resource = false, module_id = null) {
    let data = null,
      topicObj = { name: "" }

    if (type === `topic`) {
      // data = topicsResources[`topic_`+id]
      var topicArrIdx = null
      if (modulesData[`module_` + module_id] && modulesData[`module_` + module_id].topics && modulesData[`module_` + module_id].topics.length > 0) {
        topicArrIdx = modulesData[`module_` + module_id].topics.findIndex((res) => res.id === id)
        topicObj = modulesData["module_" + module_id].topics[topicArrIdx]
      }
      data =
        visible_as_resource === false
          ? topicsResources[`topic_` + id]
          : topicArrIdx !== null && modulesData[`module_` + module_id].topics[topicArrIdx].visible_resources
          ? modulesData[`module_` + module_id].topics[topicArrIdx].visible_resources
          : topicsResources[`topic_` + id]
    } else if (type === `module`) {
      data = modulesResources[`module_` + id]
    } else {
      data = allResources[props.currentSelectedResourcesType]
    }
    let next_url = null
    let see_more_loader = false
    if (type === "resource") {
      next_url = allResourcesNext[props.currentSelectedResourcesType]
    } else if (type === "topic") {
      next_url = topicsResourcesNext[`topic_` + id]
      see_more_loader = topicsResourcesNextLoader[`topic_` + id]
    } else {
      next_url = modulesResourcesNext[`module_` + id]
    }
    if (data) {
      return (
        <Droppable key={"drop-" + type + "_" + id} isDropDisabled={false} droppableId={type + `_` + id} type={`resourceDraggable`}>
          {(provided, snapshot) => {
            return (
              <div
                id={"drop-area-bg"}
                className={`bg-transparent px-4 ${type} ${type === `resource` ? `resources` : ``} ${
                  type === `resource` && collapse ? `${Styles.opacity_0} p-0 true` : `${Styles.opacity_1} false`
                }`}
                key={"drop-area-bg-" + type + "-" + id}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                onDragOver={onDragOver}
                onDrop={(e) => onDrop(e, module_id, id, data)}
              >
                {data.map((item, index) => {
                  let { typeId, imgObj } = getImageToBeRendered(item)
                  return (
                    <>
                      <Draggable
                        key={"draggable_" + item.id + `_${type}_name_` + index}
                        draggableId={item.id.toString() + `-${type}` + "_" + index.toString()}
                        index={index}
                        isDragDisabled={!props?.isPermitted}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <div className={`pr-2 d-flex`} style={{ color: `#E0E2E5`, background: "#4F6080", justifyContent: "space-between" }}>
                              <div className="d-flex" style={{ alignItems: "center" }}>
                                <FontAwesomeIcon
                                  className={`${modulesResourcesFetched} ${topicsResourcesFetched} ml-1 ${item.resource_type ? `d-none` : ``}`}
                                  icon={faSpinner}
                                  size="lg"
                                />
                                {item.resource_data ? (
                                  item.resource_data.thumbnail_url ? (
                                    <LazyLoadImage
                                      className={`my-auto`}
                                      src={item.resource_data.thumbnail_url}
                                      alt={item.name}
                                      style={{ width: `28px`, height: `20px`, fontSize: `12px` }}
                                    />
                                  ) : (
                                    <>
                                      {typeId === 1 ? (
                                        <FontAwesomeIcon
                                          icon={imgObj}
                                          className={`my-0`}
                                          style={{ color: "#67edfd", marginBottom: 0, width: `28px`, height: `20px` }}
                                        />
                                      ) : (
                                        <LazyLoadImage
                                          className={`my-auto`}
                                          src={imgObj}
                                          alt={item.name}
                                          style={{ width: `28px`, height: `20px`, fontSize: `12px` }}
                                        />
                                      )}
                                    </>
                                  )
                                ) : (
                                  ``
                                )}

                                <p style={{ fontSize: "14px", margin: "0px 0px 0px 10px" }}>{getResourceDisplayName(item)}</p>
                              </div>
                              {props?.isPermitted && (
                                <div>
                                  <img
                                    src={DeleteIcon}
                                    onClick={() => {
                                      const newState = data
                                      newState.splice(index, 1)
                                      setResourceDeleted(false)
                                      if (type === "topic") {
                                        topicsResources[`topic_` + id] = newState
                                        setTopicsResourcesFetched(false)
                                      } else {
                                        modulesResources[`module_` + id] = newState
                                        setModulesResourcesFetched(false)
                                      }
                                      deleteResource(item.id, true, item)
                                    }}
                                    alt={"resource delete icon"}
                                    className={`my-auto pointer`}
                                    style={{ objectFit: `contain`, height: `13px` }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </>
                  )
                })}
                {provided.placeholder}
                <React.Fragment>
                  {type === "topic" && next_url ? (
                    <div className={`text-center`}>
                      <Btn
                        disabled={see_more_loader}
                        onClick={(e) => {
                          e.preventDefault()
                          fetchNextTopicResources(next_url)
                        }}
                        className={`text-center px-3 py-1 mt-2 mx-auto`}
                        style={{ backgroundColor: `#212c42`, color: `#c6cff4`, borderRadius: `15px` }}
                      >
                        {see_more_loader ? <Spinner animation="border" variant="secondary" size="sm" className="mr-2" /> : ""}
                        See More
                      </Btn>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={`justify-content-center pt-4 pb-3 ${type === "resource" ? `d-none` : `d-flex bg-transparent`}`}>
                    {props?.isPermitted && (
                      <p className={`pl-3`}>
                        <span className={`${Styles.font_14}`} style={{ color: `#E0E2E5` }}>
                          Drag and drop media/document files to add content
                        </span>
                      </p>
                    )}
                    {files.length === 0 ? (
                      <input
                        className={`d-none`}
                        type="file"
                        id={"fileElem_" + id}
                        multiple
                        accept="image/*"
                        onClick={(e) => {
                          clickedFileInput(e)
                        }}
                        onChange={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          fileInputClicked(e, id, module_id, data)
                          validateAndAdd(e)
                        }}
                      />
                    ) : null}
                    {/*<label className={`button btn border-0 px-5 border_on_hover ${StylesM.font_14}`} style={{ backgroundColor: `#586886`, boxShadow:'0px 3px 6px #00000029', color:"#E0E2E5", borderRadius:'36px' }} htmlFor="fileElem">Browse</label>*/}

                    {uploadInTopic !== null && uploadDataSequence !== null && uploadInTopic === id ? (
                      <UploadFiles
                        files={files}
                        setFiles={(value) => setFiles(value)}
                        fetchTopicResources={(id) => fetchTopicResources(id, true)}
                        showSection={showSection}
                        setShowSection={setShowSection}
                        refetchLeftSideResourcesBar={refetchLeftSideResourcesBar}
                        properties={{
                          module: modules[modules.findIndex((r) => r.id === uploadInModule)].name,
                          breakoutActivity: uploadInTopic,
                          mainId: uploadInTopic,
                          itemName: topicObj.name,
                          sequence: uploadDataSequence,
                          draft_content: props.draft_content,
                          stage_id: props.selectedStage,
                        }}
                      />
                    ) : null}
                  </div>
                </React.Fragment>
              </div>
            )
          }}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  let page_uri = isBrowser() ? "/" + window.location.pathname.substr(1) : "false"
  if (page_uri === "/logout" || page_uri === "logout" || page_uri === "logout/" || page_uri === "/logout/") {
    page_uri = "/"
  }

  /*useEffect(() => {
    if (initialFetched && modules.length > 0) {
      setTimeout(function(){
        let module_id = modules[modules.length - 1].id
        if (location.props.location.hash.split(',module=')[1]) {
          module_id = location.props.location.hash.split(',module=')[1]
        }
        setSelectedModule(module_id)
        selectedModuleToggle[module_id] = true
        scrollToSpecific(null,'modules_'+module_id)
      }, 1000);
    }
  }, [initialFetched])*/

  function scrollToSpecific(e, value) {
    if (e !== null) {
      e.preventDefault()
    }
    if (isBrowser() && value !== "" && document.getElementById(value) && document.getElementById("scrollable-div")) {
      var topic_div = document.getElementById(value).getBoundingClientRect()
      setTimeout(function () {
        document.getElementById("scrollable-div").scroll({
          behavior: "smooth",
          left: 0,
          top: topic_div.top - 120,
        })
      }, 200)
    }
  }

  const updateLessonSessionUrl = () => {
    props.setActiveSection("Home")
    if (props.fetchRecentActivity) {
      props.fetchRecentActivity("true", "breakout")
    }
    window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}`)
  }

  const reTriggerBrSession = (session) => {
    session.status = "0"
    props.setTriggerSession(session)
  }

  function selectCloseFunctionality() {
    if (props.parent_page === "training_content" || props.parent_page === "course_content") {
      if (props.topic_id && props.activeBreakoutSession === "") {
        props.setCreatedBreakoutSession(modules[0])
      } else {
        props.handleCloseBreakoutOverlay()
      }
      props.setDisabledResources([])
    } else if (props.parent_page === "live_class") {
      props.handleCloseBreakoutOverlay()
    } else {
      updateLessonSessionUrl()
      props.setCurrentNavTab("")
      props.setprops && props.setprops.leftNavOpen && props.setprops.leftNavOpen(false)
    }
  }

  return (
    <Col lg="12" md="12" sm="12" xs="12" className={`px-0 d-inline-flex`}>
      <DeleteModal
        show={dmodal}
        onHide={handleClose}
        itemName={selectedDeletingModule && selectedDeletingModule.name !== undefined ? `session ` + selectedDeletingModule.name : ""}
        action={deleteModule}
      />
      <Modal
        show={showAddInstructionsModal}
        size="lg"
        onHide={() => {
          setShowAddInstructionsModal(false)
        }}
        centered
        className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
      >
        <Modal.Body className="text-center py-0" style={{ backgroundColor: "#212C42", borderRadius: "8px", border: "1px solid #707070", color: "#E0E2E5" }}>
          <Col className={`w-100 align-items-center d-inline-flex `}>
            <div className={`w-100 pt-3 pb-2`}>
              <DescriptionInputField
                datum={activeDatum}
                handleSave={handleSave}
                moduleEdited={moduleEdited}
                showModalHandler={(arg) => {
                  setShowAddInstructionsModal(arg)
                }}
                type="breakout-activity"
                isPermitted={props?.isPermitted}
              />
            </div>
          </Col>
        </Modal.Body>
      </Modal>
      <Col lg="12" md="12" sm="12" xs="12" style={{ maxHeight: "80vh", overflowY: "clip" }} className={`${StylesA.breakout_main_section} px-0`}>
        <div style={{ position: "sticky", top: "0px" }} className={`${StylesA.breakout_header} d-flex p-2`}>
          <p>Breakout Sessions</p>
          {props?.isPermitted && isMuralEnabled && (
            <MuralOutlineSync
              setBreakoutGroupList={setBreakoutGroupList}
              breakoutGroupList={breakoutGroupList}
              templateId={props.template_id}
              trainingId={props.training_id}
            />
          )}
          <img
            src={CancelWhite}
            onClick={() => selectCloseFunctionality()}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{ objectFit: `contain`, height: `13px` }}
          />
        </div>
        <div style={{ maxHeight: "70vh", overflowY: "auto" }}>{showModulesButtons()}</div>
      </Col>
      <Blog
        showBlog={addBlog}
        setShowBlog={setAddBlog}
        boActivityId={uploadInTopic}
        sequenceId={uploadDataSequence}
        fetchTopicResources={(id) => fetchTopicResources(id, true)}
        refetchLeftSideResourcesBar={() => refetchLeftSideResourcesBar("blog")}
        properties={attrForResources}
      />
      <Notification data={notificationData} setData={setNotificationData} />
    </Col>
  )
}

export default TestBreakoutSession
