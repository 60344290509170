import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { isBrowser } from "../../services/developer_mode"

const ViewBlog = (location, data) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [blog, setBlog] = useState({})

  console.log(data, location, "location.dilsjdio")
  // const { company_id } = location.id

  useEffect(() => {
    fetchBlog()
  }, [])

  async function fetchBlog() {
    // console.log("fetchGroups called")
    // setCompanyLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/blogs/${location.id}/`, { headers: login_credentials }).then((res) => {
      setBlog(res.data)
      // setCompanyLoader(true)
    })
  }

  return (
    <div>
      <SEO title="Company Creation" />
      <Col lg="12" md="12" sm="12" xs="12" className={`py-3`}>
        <Col xs="12" sm="6" md="12" className={`mb-3`}>
          <h4>Edit Blog</h4>
        </Col>
        <Col xs="12" sm="6" md="12">
          <FormGroup>Name: {blog.name}</FormGroup>
        </Col>
        <Col xs="12" sm="6" md="12">
          <FormGroup>sequence: {blog.sequence}</FormGroup>
        </Col>
        <Col xs="12" sm="6" md="12">
          <Button onClick={() => navigate(`/app/blogs`)} variant="danger">
            Go Back
          </Button>
        </Col>
      </Col>
    </div>
  )
}

export default ViewBlog
