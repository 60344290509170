import React, { useState, useContext, useEffect } from "react"
import { getRequest } from "../../../services/rest_service"
import SeeMore from "../../custom/see_more"
import StudentWiseEngagementList from "./StudentWiseEngagementList"

import { InsightsReportsContext } from "../../../stores/insights_reports/InsightsReportsStore"
import { SET_CLASS_LEVEL_REPORTS_DATA } from "../../../stores/insights_reports/InsightsReportsActions"
import Loader from "../../custom/Loader"

const StudentWiseEngagement = () => {
  const [state, dispatch] = useContext(InsightsReportsContext)

  const [participantsDataFetched, setParticipantsDataFetched] = useState(false)

  const participantsList =
    state.classLevelReportsData[`course_${state.activeCourseData?.id}_class_${state.activeClassData?.id}`]?.student_wise_engagement?.results
  const setParticipantsList = (value) => {
    dispatch({
      type: SET_CLASS_LEVEL_REPORTS_DATA,
      payload: {
        key: `course_${state.activeCourseData.id}_class_${state.activeClassData.id}`,
        report_type: `student_wise_engagement`,
        value: value,
      },
    })
  }

  const participantData = state.classLevelReportsData[`course_${state.activeCourseData?.id}_class_${state.activeClassData?.id}`]?.student_wise_engagement

  useState(() => {
    if (state.activeCourseData?.id) {
      setParticipantsDataFetched(false)
    }
  }, [state.activeCourseData?.id])

  useEffect(() => {
    let participantsData =
      state.classLevelReportsData[`course_${state.activeCourseData?.id}_class_${state.activeClassData?.id}`]?.student_wise_engagement?.results
    if (state.activeClassData && Object.keys(state.activeClassData).length > 0 && !participantsData) {
      fetchParticipants()
    } else if (participantsData) {
      setParticipantsDataFetched(true)
    }
  }, [state.activeClassData])

  const fetchParticipants = async () => {
    try {
      setParticipantsDataFetched(false)
      const response = await getRequest(`/training-participants/${state.activeClassData.id}/?badge_data=true&paginate_by=50&ordering=engagement`)
      if (response.success) {
        setParticipantsList(response.data)
        setParticipantsDataFetched(true)
      } else {
        setParticipantsDataFetched(true)
        setParticipantsList({})
      }
    } catch (error) {
      setParticipantsDataFetched(true)
      setParticipantsList({})
      console.error(error)
    }
  }

  const participantDisplayList = () => {
    if (participantsList?.length === 0) {
      return <p className="fs-14px">No students found.</p>
    }

    return participantsList?.map((participant) => {
      return (
        <StudentWiseEngagementList
          key={participant.id}
          participant={participant}
          participantsList={participantsList}
          setParticipantsList={setParticipantsList}
          training_id={state.activeClassData?.id}
        />
      )
    })
  }

  return (
    <div className="w-100 overflow-y-scroll h-32vh">
      <p className="fs-14px">Students Engagement Score</p>
      {!participantsDataFetched ? (
        <div className="w-100 d-flex h-100 justify-center align-items-center">
          <Loader class={`text-white mx-auto`} />
        </div>
      ) : (
        participantDisplayList()
      )}
      {participantData?.next && (
        <SeeMore
          url={participantData?.next}
          handleData={(response) => {
            let participantsDataCopy = { ...participantData }
            participantsDataCopy.results = [...participantData.results, ...response.data.results]
            participantsDataCopy.next = response.data.next
            setParticipantsList(participantsDataCopy)
          }}
        />
      )}
    </div>
  )
}

export default StudentWiseEngagement
