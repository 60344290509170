import React, { useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

const CreateQuestion = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [question, setQuestion] = useState({})

  function handleChange(event) {
    event.preventDefault()
    question[event.target.name] = event.target.value
  }

  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in question) {
      formData.append(k, question[k])
    }
    if (question.id != undefined) {
    } else {
      var question_id = question.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/questions/create/", formData, { headers: login_credentials })
        .then((res) => {
          navigate(`/app/questions/`)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  return (
    <div>
      <SEO title="Question Creation" />
      <h5 className="border-bottom p-2">
        Create Question
        <Link className={`pointer text-white`} to={`/app/questions`}>
          <span style={{ fontWeight: "normal" }} className="float-right small">
            {" "}
            Exit Creation{" "}
          </span>
        </Link>{" "}
      </h5>
      <Col lg="12" md="12" sm="12" xs="12">
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <div style={{ float: "none", alignItems: "center", justifyContent: "center" }}>
            <Col xs="12" sm="6" md="6" className={`float-left`}>
              <Col xs="12" sm="12" md="12" className="light-div p-0 theme-color float-left">
                <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom">
                  <FormGroup className="m-0">
                    <div>Question Title</div>
                    <input
                      style={{ background: "inherit", color: "inherit", fontSize: "12px" }}
                      className="blue-bg video-modal p-0"
                      type="text"
                      name="title"
                      defaultValue={question.title}
                      onChange={handleChange}
                      id="title"
                      placeholder="Enter question title"
                      required
                    />
                  </FormGroup>
                </Col>
              </Col>
            </Col>
            <Col xs="12" sm="6" md="6" className={`float-left`}>
              <Col xs="12" sm="12" md="12" className="light-div p-0 theme-color float-left">
                <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom">
                  <FormGroup className="m-0">
                    <div>score</div>
                    <input
                      style={{ background: "inherit", color: "inherit", fontSize: "12px" }}
                      className="blue-bg video-modal p-0"
                      type="number"
                      name="score"
                      defaultValue={question.score}
                      onChange={handleChange}
                      id="score"
                      placeholder="Enter question score"
                      required
                    />
                  </FormGroup>
                </Col>
              </Col>
            </Col>
            <Col xs="12" sm="12" md="12" className="mt-3 float-left">
              <Button className="float-right" style={{ background: "#576186", border: 0 }} type="submit" color="warning">
                Proceed
              </Button>{" "}
            </Col>
          </div>
        </Form>
        {/*<Button onClick={()=>navigate(`/app/questions`)} variant="danger" >Go Back</Button>*/}
      </Col>
    </div>
  )
}

export default CreateQuestion
