import React, { Component } from "react"
import { navigate, Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Popover from "react-bootstrap/Popover"
import Notification from "./custom/Notification"
import turientLogo from "../images/favicon.png"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/seo"
import Image from "../components/custom/Image/index.js"
import authClient from "../services/auth"
import erroricon from "../images/erroricon.png"
import turientimg from "../images/turientimg.png"
import { debounce } from "lodash"
import { postRequest } from "../services/rest_service"

class Signup extends Component {
  state = {
    email: "",
    password: "",
    error_message: "",
    show_error: "d-none",
    disable_signup: true,
    signup_btn_disabled: false,
    user: { learner_type: 0 },
    domain_error_msg: "",
    width: 1000,
    msg: "",
    toast_data: null,
  }

  isBrowser = () => {
    return typeof window !== "undefined"
  }

  componentDidMount() {
    if (this.props.location.state != null) {
      if (this.props.location.state.email != undefined) {
        this.setState({ email: this.props.location.state.email })
        this.state.user["email"] = this.props.location.state.email
      }
    }
    if (authClient.isAuthenticated()) {
      navigate("/app/dashboard")
    }
  }
  componentWillUnmount() {
    window.addEventListener("resize", this.handleWindowSizeChange)
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }
  setToastData = (val) => {
    this.setState({ toast_data: val })
  }

  hubspotContactForm = async (userData) => {
    let user = {
      properties: {
        email: userData.email,
        full_name: userData.first_name,
        hs_email_domain: userData.domain,
        organization: userData.subdomain,
        lifecyclestage: "lead",
        website: "app.turient.io",
      },
    }
    let res = await postRequest("https://ndzxt65469.execute-api.us-east-1.amazonaws.com/test", user, false)
  }

  handleLogin = async (event) => {
    event.preventDefault()
    let user = this.state.user
    let student_email = this.state.user.email
    if (this.state.disable_signup === false) {
      if (student_email) {
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/
        if (student_email.match(mailformat)) {
          if (this.state.user.password !== "" && this.state.user.password !== undefined) {
            let userData = user
            let res = await postRequest("/trainer-registration/", userData, false)
            if (res.success) {
              if (res.data["non_field_errors"] !== undefined) {
                return ""
              } else {
                if (process.env.GATSBY_REACT_APP_ENVIRONMENT === "PRODUCTION") {
                  this.hubspotContactForm(userData)
                }
                localStorage.setItem(
                  "successMsg",
                  "Successful! You are just one step away from awesomeness! Confirmation link has been sent to your email, Please confirm your account."
                )

                localStorage.setItem("username", userData.email)
                if (window.innerWidth > 980) {
                  localStorage.removeItem("error")
                } else {
                  localStorage.setItem("firstname", userData.first_name)
                  localStorage.setItem("domain", userData.domain)
                }
                navigate(`/app/login`)
              }
            } else {
              if (res.data) {
                let error_messages = ""
                for (var key in res.data) {
                  error_messages += res.data[key]
                }
                if (error_messages === "Company already exist") {
                  this.setToastData({ type: "error", title: "Company name already exist! Choose another" })
                } else if (error_messages === "Ensure this field has at least 8 characters.") {
                  this.setToastData({ type: "error", title: "Password must contain atleast 8 characters" })
                } else {
                  this.setToastData({ type: "error", title: error_messages })
                }
              }
            }

            const msg = localStorage.getItem("successMsg")
            this.setState({ signup_btn_disabled: true })
            this.setState({ error_message: "", show_error: "d-none" })
          } else {
            this.setToastData({ type: "error", title: "Please provide password" })
          }
        } else {
          this.setToastData({ type: "error", title: "Please provide valid email id" })
        }
      } else {
        this.setToastData({ type: "error", title: "Please provide valid email id" })
      }
    } else {
      this.setToastData({ type: "error", title: "Please provide a valid domain" })
    }
  }
  handleChange = (event) => {
    var someProperty = { ...this.state.user }
    if (event.target) {
      if (event.target.name === "domain") {
        someProperty[event.target.name] = event.target.value.replace(/[^a-zA-Z0-9_-]/g, "").toLowerCase()
        this.checkDomain(event.target.value.replace(/[^a-zA-Z0-9_-]/g, "").toLowerCase())
        this.setState({ error_message: "" })
      } else {
        someProperty[event.target.name] = event.target.value
        if (event.target.name === "subdomain") {
          someProperty["domain"] = event.target.value.replace(/[^a-zA-Z0-9_-]/g, "").toLowerCase()
          this.checkDomain(event.target.value.replace(/[^a-zA-Z0-9_-]/g, "").toLowerCase())
          this.setState({ error_message: "" })
        }
      }
      this.setState({ user: someProperty })
      this.setState({ error_message: "" })
    }
  }

  checkDomain = debounce((value) => {
    this.setState({ disable_signup: true })
    if (value.length >= 1) {
      if (/^\d+$/.test(value)) {
        this.setState({ disable_signup: true })
      } else {
        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + `/company-exists/?subdomain=${value}&email=${this.state.user.email}`)
          .then((res) => {
            this.setState({ disable_signup: false })
            this.setToastData(null)
          })
          .catch((error) => {
            this.setState({ disable_signup: true })
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + " "
            }
            this.setToastData({ type: "error", title: error_messages })
          })
      }
    } else {
      this.setState({ domain_error_msg: "" })
      this.setState({ disable_signup: true })
      if (document.getElementById("domain_error")) {
        document.getElementById("domain_error").innerHTML = ""
      }
    }
  }, 1500)

  displayTitle = () => {
    return (
      <h3 className="m-0 pt-10px pb-10px d-inline-flex align-items-center">
        <Image alt="Turient logo" src={turientLogo} className="d-inline-block align-center w-50px h-50px" />{" "}
        <Link to="/" className="ml-2 turient-body-text-color text-decoration-none">
          {"Turient"}
        </Link>
      </h3>
    )
  }
  popOver = (right, top, domain_msg) => {
    if (domain_msg) {
      return (
        <Popover
          id="popover-basic"
          className={`${this.state.show_error} xyz bg-303C54 h-5rem error-input br-7px left-auto w-20rem`}
          style={{
            right: `${right}`,
            top: `${top}`,
          }}
        >
          <Popover.Content>
            <div className="pr-2 pl-2 relative float-left top-10px">
              <img height="40px" src={erroricon} />
            </div>
            <div className="relative left-10px">
              {/* <div className='pb-1' style={{color: 'red'}}><strong>Error</strong></div> */}
              <div className="color-FF7458 fs-15px">{domain_msg}</div>
            </div>
          </Popover.Content>
        </Popover>
      )
    } else {
      return (
        <Popover
          id="popover-basic"
          className={`${this.state.show_error} xyz bg-303C54 error-input br-7px left-auto h-60px w-20rem`}
          style={{
            right: `${right}`,
            top: `${top}`,
          }}
        >
          <Popover.Content>
            <div className="pr-2 pl-2 float-left">
              <img height="30px" src={erroricon} />
            </div>
            <div className="relative left-10px">
              <div className="fs-15px color-FF7458">{this.state.error_message}</div>
            </div>
          </Popover.Content>
        </Popover>
      )
    }
  }

  render() {
    if (this.isBrowser() && window.innerWidth > 980) {
      return (
        <div>
          <SEO title="Turient | Sign Up" />
          <Row className="justify-content-md-center mx-auto w-100 pb-2rem mt-50px">
            <Col lg="3" md="4" sm="4" className="float-left border border-1 border-121B2B bg-121B2B mr-0 pl-20px">
              <Row className="text-center border-bottom-E0E2E5">{this.displayTitle()}</Row>

              <p className="poppins_font text_font_semibold lh-1_2 turient-body-text-color fs-36px pt-20px mb-8px">
                Welcome to
                <br /> your Online School
              </p>
              <p className="poppins_font fs-21px">You are just a step away!</p>
              <Row>
                <Image src={turientimg} alt="turientmg" className="signup-turient-img" />
              </Row>
            </Col>
            <Col lg="4" md="6" sm="6" className="pt-2 pb-2 d-fle bg-212C42 pl-20px">
              <Form onSubmit={this.handleLogin} className="w-90">
                <Col className="mt-4 mb-20px">
                  <Form.Label className="registration-lable poppins_font fs-18px mt-16px">Your Name</Form.Label>
                  <Form.Control
                    className="registration-input font-w500"
                    type="first_name"
                    name="first_name"
                    placeholder="Enter your name"
                    value={this.state.user.first_name}
                    onChange={this.handleChange}
                    required
                  />
                </Col>
                <Col className="mb-20px">
                  <Form.Label className="poppins_font fs-18px">Your e-mail </Form.Label>
                  <Form.Control
                    className="registration-input font-w500"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={this.state.user.email}
                    onChange={this.handleChange}
                  />
                  {this.state.error_message === "Please provide valid email id" ? this.popOver("-103px", "28px") : ""}
                </Col>
                <Col className="mb-20px">
                  <Form.Label className="poppins_font fs-18px">Choose a Password </Form.Label>
                  <Form.Control
                    className="registration-input font-w500"
                    type="password"
                    name="password"
                    placeholder="Choose a password"
                    value={this.state.user.password}
                    onChange={this.handleChange}
                  />
                  {this.state.error_message === "Please provide password" || this.state.error_message === "Password must contain atleast 8 characters"
                    ? this.popOver("-103px", "35px")
                    : ""}
                </Col>
                <Col className="mb-20px">
                  <Form.Label className="poppins_font fs-18px">Your Company Name</Form.Label>
                  <Form.Control
                    className="registration-input font-w500"
                    type="text"
                    name="subdomain"
                    placeholder="Enter company name"
                    value={this.state.user.subdomain}
                    onChange={this.handleChange}
                    required
                  />
                  {this.state.error_message === "Company name already exist! Choose another" ? this.popOver("-120px", "28px") : ""}
                </Col>
                <Col className="mb-20px">
                  <Form.Label className="poppins_font fs-18px">Choose a Domain Name</Form.Label>
                  <div className="d-flex h-45px br-9px bg-303C54 w-inherit turient-header-text-color box-shadow-1321-00000029">
                    <span className="pl-3 pt-7px">https://</span>
                    <span>
                      <Form.Control
                        className="domainfield registration-input font-w500 text-lowercase"
                        type="text"
                        name="domain"
                        placeholder="domainname"
                        value={this.state.user.domain}
                        onChange={this.handleChange}
                      ></Form.Control>
                    </span>
                    <span className="pr-3 pt-7px">.turient.io</span>
                  </div>
                  {this.state.error_message === "Please provide a valid domain" && this.state.domain_error_msg == ""
                    ? this.popOver("-120px", "37px")
                    : this.state.domain_error_msg !== ""
                    ? this.popOver("-120px", "30px", "The domain is already in use. Please select some other domain.")
                    : ""}
                </Col>
                <div className="ml-20px mb-25px ">
                  <Button type="submit" variant="primary" size="sm" className="border_on_hover turient-header-text-color bg-303C54 signup-button">
                    <p className={`m-0 poppins_font d-flex align-items-center`}>
                      <span className="fs-0_9rem float-left">Create my online school</span>
                      <span className={`lp-icon ml-2 my-auto float-right`}>
                        <FontAwesomeIcon icon={faChevronRight} className="color-303C54 bg-E0E2E5 rounded-circle w-20px h-20px mr-18px mt-1 p-6px" />
                      </span>
                    </p>
                  </Button>
                </div>
                <div>
                  <p className="font-w500 ml-35px fs-12px turient-body-text-color poppins_font">
                    {" "}
                    <Link to="/app/login" className="turient-body-text-color border-bottom ml-5px" state={{ email: this.state.user.email }}>
                      Already have an account?<span className="turient-header-text-color">&nbsp;Login</span>
                    </Link>{" "}
                  </p>
                </div>
              </Form>
            </Col>
          </Row>
          <Notification data={this.state.toast_data} setData={this.setToastData} />
        </div>
      )
    } else {
      return (
        <div>
          <SEO title="Turient | Sign Up" />
          <div>
            <div className="signup-display-title">{this.displayTitle()}</div>
            <div className="align-items-center">
              <div className="ml-35px align-items-left">
                <p className="poppins_font text_font_semibold turient-body-text-color fs-30px lh-1_2">
                  Welcome to
                  <br /> your Online School
                </p>
                <p className="poppins_font fs-21px">You are just a step away!</p>
              </div>
              <div className="text-center mt-35px mb-80px">
                <Form onSubmit={this.handleLogin} className="w-90 pl-35px">
                  <div className="mb-20px">
                    <Form.Label className="registration-lable poppins_font fs-13px float-left">Your Name</Form.Label>
                    <Form.Control
                      className="registration-input font-w500"
                      type="first_name"
                      name="first_name"
                      placeholder="Enter your name"
                      value={this.state.user.first_name}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="mb-20px">
                    <Form.Label className="poppins_font fs-13px float-left">Your e-mail </Form.Label>
                    <Form.Control
                      className="registration-input font-w500"
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      value={this.state.user.email}
                      onChange={this.handleChange}
                    />
                    {this.state.error_message === "Please provide valid email id" ? (
                      <p className="color-FF7458 pb-0 mb-0 float-left fs-13px">Please provide email id</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-20px">
                    <Form.Label className="poppins_font fs-13px float-left">Choose a Password </Form.Label>
                    <Form.Control
                      className="registration-input font-w500"
                      type="password"
                      name="password"
                      placeholder="Choose a password"
                      value={this.state.user.password}
                      onChange={this.handleChange}
                    />
                    {this.state.error_message === "Please provide password" || this.state.error_message === "Password must contain atleast 8 characters" ? (
                      <p className="color-FF7458 pb-0 mb-0 float-left fs-13px">{this.state.error_message}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-20px">
                    <Form.Label className="poppins_font fs-13px float-left">Your Company Name</Form.Label>
                    <Form.Control
                      className="registration-input font-w500"
                      type="text"
                      name="subdomain"
                      placeholder="Enter company name"
                      value={this.state.user.subdomain}
                      onChange={this.handleChange}
                      required
                    />
                    {this.state.error_message === "Company already exist! Choose another" ? (
                      <p className="color-FF7458 pb-0 mb-0 float-left fs-13px">Company already exist! Choose another</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-grid mb-20px se-btn-tray">
                    <Form.Label className="poppins_font fs-13px float-left text-left">Choose a Domain Name</Form.Label>
                    <div className="d-flex w-inherit h-inherit br-9px bg-303C54 box-shadow-1321-00000029 turient-header-text-color ">
                      <span className="pl-3 pt-7px">https://</span>
                      <span>
                        <Form.Control
                          className="domainfield registration-input font-w500 text-lowercase"
                          type="text"
                          name="domain"
                          placeholder="domainname"
                          value={this.state.user.domain}
                          onChange={this.handleChange}
                        ></Form.Control>
                      </span>
                      <span className="pr-3 pt-7px">.turient.io</span>
                    </div>
                    {this.state.error_message === "Please provide a valid domain" && this.state.domain_error_msg == "" ? (
                      <p className="color-FF7458 pb-0 mb-0 float-left fs-13px">Please provide a valid domain</p>
                    ) : this.state.domain_error_msg !== "" ? (
                      <p className="color-FF7458 pb-0 mb-0 float-left fs-13px">The domain is already in use. Please select some other domain.</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <Button type="submit" variant="primary" size="sm" className="border_on_hover turient-header-text-color mr-18px bg-303C54 signup-button">
                      <p className={`m-0 poppins_font d-flex align-items-center`}>
                        <span className="fs-0_9rem float-left">Create my online school</span>
                        <span className={`lp-icon ml-2 my-auto float-right`}>
                          <FontAwesomeIcon icon={faChevronRight} className="color-303C54 bg-E0E2E5 rounded-circle w-20px h-20px mr-18px mt-1 p-6px" />
                        </span>
                      </p>
                    </Button>
                  </div>
                  <div className="mt-20px">
                    <p className="font-w500 poppins_font turient-body-text-color fs-14px">
                      {" "}
                      <Link to="/app/login" className="turient-body-text-color border-bottom ml--10px" state={{ email: this.state.user.email }}>
                        Already have an account?<span className="turient-header-text-color">&nbsp;Login</span>
                      </Link>{" "}
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <Notification data={this.state.toast_data} setData={this.setToastData} />
        </div>
      )
    }
  }
}

export default Signup
