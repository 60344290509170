/* eslint-disable */

import React, { useEffect, useState, useCallback, useRef, useContext } from "react"
import debounce from "lodash/debounce"

import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Dropdown from "react-bootstrap/Dropdown"
import Modal from "react-bootstrap/Modal"
import HorizontalScroll from "../custom/HorizontalScroll"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OverlayTrigger, Row, Tooltip } from "react-bootstrap"

import { Rnd } from "react-rnd"
import SunEditor from "suneditor-react"

import getImageToBeRendered from "../utilities/resourceIcons"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import Common from "../../services/common"
import Notification from "../custom/Notification"
import DeleteModal from "../custom/DeleteModal"
import { isBrowser } from "../../services/developer_mode"
import LayoutHeader from "../layoutHeader"

// import CohortSchedule from "./cohort_schedule"
import Image from "../custom/Image"
import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_SELECTED_RESOURCE_TYPE, SET_REFETCH_RESOURCES } from "../../stores/sidebar_store/SidebarActions"

import Plus from "../../images/svgs/plus.svg"
import ExclamationTriangle from "../../images/svgs/exclamation-triangle.svg"
import EllipsisV from "../../images/svgs/threedots.svg"
import DragHandleIcon from "../../images/svgs/drag-handle-white.svg"
import ChevronCircleUp from "../../images/svgs/ChevronCircleUp.svg"
import ChevronCircleDown from "../../images/svgs/ChevronCircleDown.svg"
import ArrowWhiteCircle from "../../images/svgs/ArrowWhiteCircle.svg"
import facheck from "../../images/svgs/facheck.svg"
import fatimes from "../../images/svgs/fatimes.svg"
import deleteimg from "../../images/svgs/delete.svg"
import link from "../../images/svgs/link.svg"
import CancelIcon from "../../images/svgs/cancel-white-icon.svg"
import CancelWhite from "../../images/svgs/close.svg"
import TrashCan from "../../images/svgs/trash-can-transparent.svg"

import UploadFiles from "../../components/courses/upload-files"

import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd"
import { LazyLoadImage } from "react-lazy-load-image-component"

import Styles from "../../modularscss/styles.module.scss"
import StylesB from "./casestudy.module.scss"
import StylesD from "../../modularscss/delete.module.scss"
import Sidebar from "../Sidebar/template-sidebar"
import StylesM from "../../components/upload-resource.module.scss"

var nextExtLinkId = 0

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#000" : "#12172c",
  background: "#212C42",
  padding: "12px",
  borderRadius: "0px 0px 8px 8px",
  // maxHeight: 'calc(100vh - 160px)',
  overflowY: "auto",
})

const getResourceItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  // background: '#42506C',
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  outline: "none",
  padding: grid,
  margin: `0`,
  borderRadius: "20px",
  textAlign: "center",
  color: "#fff",

  // change background colour if dragging
  background: "transparent",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

const UpdateCaseStudy = (location) => {
  const { id } = location
  const quesErr = useRef(`Question Validation Failed`)

  const delayedQuery = useCallback(
    debounce((name, value) => sendQuery(name, value), 1000),
    []
  )
  const delayedQueryQuestion = useCallback(
    debounce((name, index, value) => handleUpdateQuestion(null, index, name, value), 1000),
    []
  )
  const delayedSaveExtLink = useCallback(
    debounce((index, value, pLinksData) => {
      handleLinkSave(index, value, pLinksData)
    }, 1500),
    []
  )
  const delayedQueryOptions = useCallback(
    debounce((option, qid, value) => handleUpdateAnswer(null, option, qid, value), 1000),
    []
  )

  const [casestudy, setCaseStudy] = useState({
    id: id,
    name: null,
  })
  const [toggledOption, setToggledOption] = useState(false)
  const [linksData, setLinksData] = useState([])
  const [loader, setLoader] = useState(false)
  const [loaderTime, setLoaderTime] = useState(false)
  const [addMoreLinks, setAddMoreLinks] = useState(false)
  const [activestate, setActiveState] = useState("add_detail")
  const [linksDataFocusElem, setLinksDataFocusElem] = useState("")
  const [showCohortSchedule, setShowCohortSchedule] = useState(true)
  const [casestudyDatam, setCaseStudyDatam] = useState({})
  const [courseDatamUpdated, setCourseDatamUpdated] = useState(false)
  const [createBreakoutSessionOpened, setCreateBreakoutSessionOpened] = useState(false)
  const [activeResourceType, setActiveResourceType] = useState("")
  const [activeNavItem, setActiveNavItem] = useState("")
  const [leftNavOpen, setLeftNavOpen] = useState(false)
  const [allResources, setAllResources] = useState({})
  const [currentNavTab, setCurrentNavTab] = useState("")
  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("")
  const [allResourcesChanged, setAllResourcesChanged] = useState(false)
  const [topicsResourcesFetched, setTopicsResourcesFetched] = useState(false)
  const [deleteResourceData, setDeleteResourceData] = useState({})
  const [deleteResourceObjData, setDeleteResourceObjData] = useState({})
  const [questionsFetched, setQuestionsFetched] = useState({})
  const [questionCreated, setQuestionCreated] = useState(false)
  const [questions, setQuestions] = useState({})
  const [quizQuestionsFetched, setQuizQuestionsFetched] = useState(false)
  const [questionsNext, setQuestionsNext] = useState({})
  const [optionsFetched, setOptionsFetched] = useState(false)
  const [questionOptions, setQuestionOptions] = useState({})
  const [questionOptionsNext, setQuestionOptionsNext] = useState({})
  const [questionOptionsFetched, setQuestionOptionsFetched] = useState({})
  const [currentQuestionId, setCurrentQuestionId] = useState("")
  const [optionUpdated, setOptionUpdated] = useState(false)
  const [toggleQuestion, setToggleQuestion] = useState(false)
  const [usingDropdown, setUsingDropdown] = useState(false)
  const [draggedData, setDraggedData] = useState(false)
  const [showNotif, setShowNotif] = useState(false)
  const [selectedStage, setSelectedStage] = useState("")

  const [optionCreated, setOptionCreated] = useState(false)
  const [optionCreatedX, setOptionCreatedX] = useState(false)
  const [showRichTextModal, setShowRichTextModal] = useState(false)
  const [activeInputField, setActiveInputField] = useState(null)
  const [draggableHash, setDraggableHash] = useState({ x: 0, y: 0, width: 724, height: 486 })

  /*for system files uploading start*/
  const [highlight, setHighlight] = useState("unhighlight")
  const [files, setFiles] = useState([])
  const [hovered, setHovered] = useState(false)

  const [caseStudyFetched, setCaseStudyFetched] = useState(false)
  const [addToResLib, setAddToResLib] = useState(true)
  const [dispAddToResLib, setDispAddToResLib] = useState(false)
  const [notificationData, setNotificationData] = useState(null)

  const [sidebarStore, dispatch] = useContext(SidebarContext)

  useEffect(() => {
    if (Object.keys(casestudyDatam).length > 0 && courseDatamUpdated) {
      for (const [key, value] of Object.entries(casestudyDatam)) {
        if (value) {
          casestudy[key] = value
        }
      }
      handleSubmit("none")
    }
  }, [Object.keys(casestudyDatam).length, courseDatamUpdated])

  useEffect(() => {
    //console.log("location",location)
    if (location?.props?.location?.state?.addToResLib) {
      let a = location.props.location.state.addToResLib
      setAddToResLib(a)
      setDispAddToResLib(a)
    }
  }, [location])

  async function fetchCaseStudy() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/case-study/${id}/`, { headers: login_credentials }).then((res) => {
      setCaseStudy({ ...res.data, time: Number(res.data.time), resources: [] })
      if (res.data) {
        fetchLinksData(res.data)
        // setLinksData(res.data.toollink_set);
        fetchResources(res.data)
      }
      setCaseStudyFetched(true)
    })
  }

  useEffect(() => {
    fetchCaseStudy()
    fetchQuizQuestions()
  }, [location.id])

  const fetchQuizQuestions = (refetch = false) => {
    if (!refetch) {
      questionsFetched["quiz"] = false
    }
    setQuizQuestionsFetched(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/case-study-question/?case_study=${id}`, { headers: login_credentials })
      .then((res) => {
        questions["quiz"] = res.data.results
        questionsNext["quiz"] = res.data.next
        questionsFetched["quiz"] = true
        setQuizQuestionsFetched(true)
      })
      .catch((err) => {})
  }

  useEffect(() => {
    if (showCohortSchedule) {
      focusOnData(null, "duration")
    }
  }, [showCohortSchedule])

  useEffect(() => {
    if (addMoreLinks) {
      let e = document.getElementById(linksDataFocusElem)
      if (e) e.focus()
    }
  }, [addMoreLinks])

  useEffect(() => {
    if (isBrowser() && document.getElementsByClassName("parent") && document.getElementsByClassName("parent").length > 0) {
      let x = parseInt(document.getElementsByClassName("parent")[0].offsetWidth - 724 - 80) / 2
      let y = parseInt(document.getElementsByClassName("parent")[0].offsetHeight - 486) / 2
      setDraggableHash({ x: x, y: y, width: 724, height: 486 })
    }
  }, [showRichTextModal])

  const openNavWithResourceSection = (section, type) => {
    if (!leftNavOpen) {
      setLeftNavOpen(true)
    }
    if (activeNavItem !== section) {
      setActiveNavItem(section)
    }
    if (activeResourceType !== type) {
      setActiveResourceType(type)
    }
  }

  const toggleLeftNav = (value) => {
    if (value !== leftNavOpen) {
      setLeftNavOpen(value)
    }
  }

  const sendQuery = (name, value) => {
    let someProperty = { ...casestudy }
    someProperty[name] = value
    setCaseStudyDatam(someProperty)
    setCourseDatamUpdated(true)
  }

  const onBlurData = (name, value) => {
    if (name !== null) {
      casestudy[name] = value
      handleSubmit("none")
    }
  }

  const handleAddMoreLinks = (e) => {
    e.preventDefault()
    setLinksData([...linksData, { id: `el-${nextExtLinkId++}`, link: "", name: "", icon: null, notInDB: true }])
    setAddMoreLinks(true)
    setLinksDataFocusElem(`link_${linksData.length}`)
  }

  const handleClose = () => {
    setDeleteResourceData({})
    setDeleteResourceObjData({})
  }

  const handleDelMoreLinks = (e, delIdx) => {
    e.preventDefault()
    if (Number.isInteger(linksData[delIdx].id)) {
      axios
        .delete(process.env.GATSBY_REACT_APP_API_HOST + `/tool-links/${linksData[delIdx].id}/`, { headers: DefaultHeader })
        .then((res) => {
          setLinksData(linksData.filter((n, i) => delIdx !== i))
        })
        .catch((err) => {})
    } else {
      setAddMoreLinks(false)
      setLinksData(linksData.filter((n, i) => delIdx !== i))
    }
  }
  const handleLinkSave = (index, value, obj) => {
    if (!obj[index]["notInDB"]) {
      return
    }

    let v = value
    if (v.indexOf("http://") === -1 && v.indexOf("https://") === -1) {
      v = "https://" + value
    }

    let pLinksData = [...obj]
    if (!Common.isValidURL(v)) {
      pLinksData[index].error = "Enter a valid URL"
      setLinksData([...pLinksData])
      return
    }

    pLinksData[index]["link"] = v
    pLinksData[index]["name"] = "Link"
    //value = v

    let data = { name: pLinksData[index].name, link: v, case_study: id }

    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + `/tool-links/create/`, data, { headers: login_credentials })
      .then((res) => {
        if (res.data.link === "Link") {
          pLinksData[index].error = "Enter a valid URL"
        } else {
          pLinksData[index].error = ""
          pLinksData[index].id = res.data.id
          pLinksData[index].name = res.data.name
          pLinksData[index].case_study = res.data.case_study
          pLinksData[index].icon = res.data.icon
          delete pLinksData[index]["notInDB"]
        }
        setLinksData([...pLinksData])
        setAddMoreLinks(false)
      })
      .catch((err) => {
        if (err.response.data.link?.length > 0) {
          pLinksData[index].error = err.response.data.link[0]
        }
        setLinksData([...pLinksData])
      })
  }

  const focusOnData = (e, id) => {
    let data = document.getElementById(id)
    if (data) {
      data.focus()
    }
  }

  const findScrollableOrNot = () => {
    let ele = document.getElementById("sections")

    if (ele !== null && ele !== undefined) {
      if (ele.scrollWidth > ele.clientWidth) {
        return true
      } else {
        return false
      }
    }
  }

  const updateActiveState = (e) => {
    if (e.target) {
      setActiveState(e.target.id)
    } else {
      setActiveState(e)
    }
  }

  const calculateWidth = () => {
    let ele = document.getElementById("casestudyname")
    if (ele !== null) {
      let data = 1100 - 142 - 50
      return { width: data + "px", overflowX: "scroll" }
    }
  }

  const handleSubmit = (event, type = null, value = null, published = false) => {
    if (event !== null && event !== "none") {
      event.preventDefault()
    }
    if (!casestudy.time) {
      setCaseStudyDatam({})
      setCourseDatamUpdated(false)
    } else {
      let formData = new FormData()
      for (let k in casestudy) {
        if (casestudy[k] !== null && k !== "published" && (k === "name" || k === "grading" || k === "time" || k === "passing_marks" || k === "content")) {
          formData.append(k, casestudy[k])
        }
      }

      if (type !== null) {
        formData.append(type, value)
      }
      formData.append("is_added_to_library", addToResLib)

      if (published) {
        formData.append("published", true)
        if (window && window.FromTrainingOrClassCreation && window.FromTrainingOrClassCreation.sequence) {
          for (let p in window.FromTrainingOrClassCreation) {
            formData.append(p, window.FromTrainingOrClassCreation[p])
          }
        }
        dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "casestudy" })
        if (window.opener) {
          const evt = new CustomEvent("my-refresh-resources", {
            bubbles: true,
            detail: "casestudy",
          })
          window.opener?.document && window.opener.document.dispatchEvent(evt)
        }
      }

      if (location.id !== undefined) {
        var casestudy_id = location.id
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + "/case-study/" + casestudy_id + "/", formData, { headers: login_credentials })
          .then((res) => {
            setLoader(false)
            setLoaderTime(false)
            setCaseStudyDatam({})
            setCourseDatamUpdated(false)
            setCaseStudy((prev) => {
              return {
                ...res.data,
                resources: prev.resources,
                time: Number(res.data.time),
              }
            })
            if (res.data.published) {
              setShowNotif(true)
              setActiveState("publish_course")
              dispatch({ type: SET_REFETCH_RESOURCES, payload: true })
            }
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()

              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onCourseContentDragEnd = (result) => {
    let type = result.source.droppableId.split("_")[0].toString() + `_resourceDraggable`
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    const sourceParentId = result.source.droppableId
    const destParentId = result.destination.droppableId

    if (type === "resource_resourceDraggable") {
      const itemSubItemMap = allResources
      var sourceSubItems, destSubItems, topic_id

      if (sourceParentId.indexOf("resource_") > -1) {
        sourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
      }

      destSubItems = casestudy.resources

      if (sourceParentId === destParentId) {
        const items = reorder(casestudy.resources, sourceIndex, destIndex)
        casestudy.resources = items
        setAllResourcesChanged(!allResourcesChanged)
      } else {
        var newSourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        var formData = new FormData()

        setTopicsResourcesFetched(false)
        if (result.destination.index === 0 && destSubItems.length === 0) {
          draggedItem["sequence"] = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index === destSubItems.length) {
          draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem["sequence"] = (
            (parseFloat(destSubItems[result.destination.index].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        formData.append("sequence", draggedItem.sequence)
        let sourcePIdType = sourceParentId.replace("resource_", "")
        if (sourcePIdType === "poll" || sourcePIdType === "pop-quiz") {
          formData.append("resource_type", "quiz")
        } else {
          if (sourcePIdType === "document") {
            formData.append("resource_type", "turientimage")
          } else {
            formData.append("resource_type", sourcePIdType)
          }
        }
        formData.append("resource_id", draggedItem.id)
        formData.append("case_study", id)

        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
          .then((res) => {
            if (res.data) {
              draggedItem.id = res.data.id
              draggedItem.resource_data = res.data.resource_data
              draggedItem.resource_id = res.data.resource_id
              draggedItem.resource_type = res.data.resource_type
              draggedItem.sequence = res.data.sequence
              delete draggedItem["name"]
              casestudy.resources.splice(destIndex, 0, draggedItem)
            }
            setTopicsResourcesFetched(true)
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()
              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
        /*api end*/
        let temp_data = allResources
        temp_data[sourceParentId.replace("resource_", "")] = newSourceSubItems
        setAllResourcesChanged(!allResourcesChanged)
      }
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    if (result.type === "questionsDraggable") {
      const itemSubItemMap = questions

      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      const sourceSubItems = itemSubItemMap[sourceParentId]
      const destSubItems = itemSubItemMap[destParentId]

      let newItems = questions

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(sourceSubItems, sourceIndex, destIndex)

        let questionsData = reorderedSubItems[destIndex]
        let currentData = sourceSubItems

        if (destParentId === "quiz") {
          if (destIndex === 0) {
            questionsData.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
          } else if (destIndex === currentData.length - 1) {
            questionsData.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
          } else {
            if (result.source.index > destIndex) {
              questionsData.sequence = ((parseFloat(currentData[destIndex - 1].sequence) + parseFloat(currentData[destIndex].sequence)) / 2).toFixed(20)
            } else if (result.source.index < destIndex) {
              questionsData.sequence = ((parseFloat(currentData[destIndex].sequence) + parseFloat(currentData[destIndex + 1].sequence)) / 2).toFixed(20)
            }
          }
        }
        setDraggedData(!draggedData)
        if (destParentId === "quiz") {
          handleDraggedItemSequence(questionsData, "update")
        }
        questions[sourceParentId] = reorderedSubItems
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)
        draggedItem.sequence = destIndex
        if (destParentId === "quiz") {
          if (destIndex === 0 && destSubItems.length === 0) {
            draggedItem.sequence = destIndex
          } else if (destIndex === 0) {
            if (draggedItem) {
              draggedItem.sequence = (parseFloat(destSubItems[destIndex].sequence) - 10).toFixed(20)
            }
          } else if (destIndex === destSubItems.length) {
            draggedItem.sequence = (parseFloat(destSubItems[destIndex - 1].sequence) + 10).toFixed(20)
          } else {
            draggedItem.sequence = ((parseFloat(destSubItems[destIndex].sequence) + parseFloat(destSubItems[parseFloat(destIndex) - 1].sequence)) / 2).toFixed(
              20
            )
          }
          setOptionsFetched(false)
          fetchOptions(draggedItem.id)
          handleDraggedItemSequence(draggedItem, "create", false)
        } else {
          draggedItem.dragged_id = draggedItem.id
          draggedItem.id = draggedItem.question_data.id
          draggedItem.title = draggedItem.question_data.title
          delete draggedItem["question_data"]
          delete draggedItem["question_id"]
          delete draggedItem["question_resource_type"]
          // deleteResourceLinks(draggedItem.dragged_id, 'questions-resources')
        }

        let newDestSubItems = destSubItems
        newDestSubItems.splice(destIndex, 0, draggedItem)
        questions[sourceParentId] = newSourceSubItems
        questions[destParentId] = newDestSubItems
      }
    }

    if (result.type === "optionsDraggable") {
      const itemSubItemMap = questionOptions

      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      const sourceSubItems = itemSubItemMap[sourceParentId]
      const destSubItems = itemSubItemMap[destParentId]

      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(sourceSubItems, sourceIndex, destIndex)

        let questionOptionsData = reorderedSubItems[destIndex]
        let currentData = sourceSubItems

        if (destIndex === 0) {
          questionOptionsData.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
        } else if (destIndex === currentData.length - 1) {
          questionOptionsData.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (result.source.index > destIndex) {
            questionOptionsData.sequence = ((parseFloat(currentData[destIndex - 1].sequence) + parseFloat(currentData[destIndex].sequence)) / 2).toFixed(20)
          } else if (result.source.index < destIndex) {
            questionOptionsData.sequence = ((parseFloat(currentData[destIndex].sequence) + parseFloat(currentData[destIndex + 1].sequence)) / 2).toFixed(20)
          }
        }
        setDraggedData(!draggedData)

        handleDraggedOptionSequence(questionOptionsData, "update")
        questionOptions[sourceParentId] = reorderedSubItems
      }
    }
    if (createBreakoutSessionOpened) {
      // setDragEnded(true)
      // setDraggedItem(result)
    } else {
      onCourseContentDragEnd(result)
    }
  }

  const onDragStart = (result) => {
    if (result.type === "resourceDraggable") {
    }
  }
  const onDragUpdate = (result) => {
    if (result.type === "resourceDraggable" && false) {
    }
  }

  const onDragEnter = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  const onDragOver = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  const onDragLeave = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
    handleDrop(event)
  }

  const handleDrop = (e) => {
    var dt = e.dataTransfer
    let new_files = dt.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        // hideMessage.displayErrorMsg(new_files[i].name + ' has size more than 200MB.')
        setNotificationData({ type: "error", title: new_files[i].name + " has size more than 200MB." })
      }
    }
  }

  const fetchResources = (data) => {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/resources-names-list/?case_study=${id}&paginate_by=15`, { headers: login_credentials }).then((res) => {
      let current_data = { ...data }
      current_data[`resources`] = res.data.results
      setCaseStudy(current_data)
    })
  }

  const fetchLinksData = (data) => {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/tool-links/?case_study=${id}&ordering=id`, { headers: login_credentials }).then((res) => {
      if (res.data["results"]?.length > 0) {
        setLinksData(res.data.results)
      }
    })
  }

  function validateAndAdd(event) {
    let new_files = event.target.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        // hideMessage.displayErrorMsg(new_files[i].name + ' has size more than 200MB.')
        setNotificationData({ type: "error", title: new_files[i].name + " has size more than 200MB." })
      }
    }
  }

  const showLinksData = () => {
    let data = casestudy
    return (
      <Col
        className="px-0 border_on_hover pl-0"
        style={{
          backgroundColor: "#303C54",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "9px",
          border: "1px solid #303C54",
          marginBottom: "12px",
          marginTop: "12px",
        }}
      >
        <p
          className="mb-0 py-2 px-2"
          id="coursename"
          style={{ borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px", lineHeight: "1", minWidth: "max-content" }}
        >
          Case Study content
        </p>
        <InputGroup className="py-0 d-block " style={{ borderRadius: "9px", border: "1px solid transparent" }}>
          <Col className="px-0 pl-0" style={{ backgroundColor: "#303C54", borderRadius: "9px", border: "1px solid #303C54" }}>
            {casestudy.content && casestudy.content !== "<p><br></p>" ? (
              <InputGroup className="py-0 d-block" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                  <InputGroup.Text
                    className="border-0 d-flex justify-content-center"
                    style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                    id="basic-addon1"
                    onClick={(e) => {
                      focusOnData(e, "additional_criteria")
                      setShowRichTextModal(true)
                      setActiveInputField("Question")
                    }}
                  ></InputGroup.Text>
                </InputGroup.Prepend>
                <div className={`pl-0 mx-2 mb-0`} style={{}}>
                  <div
                    onClick={() => {
                      setShowRichTextModal(true)
                      setActiveInputField("Instruction")
                    }}
                    dangerouslySetInnerHTML={{ __html: casestudy.content }}
                    style={{ maxHeight: "100px", overflowY: "scroll", textAlign: "left" }}
                  />
                </div>
              </InputGroup>
            ) : null}
          </Col>

          <Droppable isDropDisabled={createBreakoutSessionOpened} droppableId={`resource_notes`} type={`resourceDraggable`}>
            {(provided, snapshot) => (
              <div
                id={`drop-area`}
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                className={`bg-transparent px-2 notes border_on_hover`}
                ref={provided.innerRef}
                style={{ ...getListStyle(snapshot.isDraggingOver), borderRadius: "5px" }}
              >
                {data && data.resources && data.resources.length > 0
                  ? data.resources.map((item, index) => {
                      let { typeId, imgObj } = getImageToBeRendered(item)

                      return (
                        <>
                          <Draggable
                            key={item.id + `_name_` + index}
                            draggableId={item.id.toString() + "_" + index.toString()}
                            index={index}
                            className={item !== null ? "" : "d-none"}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={item !== null ? "resource-item" : "d-none"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                <div className={`pr-2 d-flex`} style={{ color: `#E0E2E5`, background: "#4F6080", justifyContent: "space-between" }}>
                                  <div className="d-flex" style={{ alignItems: "center" }}>
                                    {item.resource_data ? (
                                      item.resource_data.thumbnail_url ? (
                                        <LazyLoadImage className={`my-auto w-38px h-30px fs-12px`} src={item.resource_data.thumbnail_url} alt={item.name} />
                                      ) : (
                                        <>
                                          {typeId === 1 ? (
                                            <FontAwesomeIcon icon={imgObj} className={`my-0 color-67edfd w-38px h-30px`} />
                                          ) : (
                                            <LazyLoadImage className={`my-auto w-18px h-18px mx-2`} src={imgObj} alt={item.name} />
                                          )}
                                        </>
                                      )
                                    ) : (
                                      ``
                                    )}
                                    <p className="fs-14px m-0">
                                      {item.resource_data?.display_name ?? item.resource_data?.name ?? item.display_name ?? item.name} {item.id}
                                    </p>
                                  </div>
                                  <div>
                                    <img
                                      src={deleteimg}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        const newState = data.resources
                                        newState.splice(index, 1)
                                        deleteResourceLinks(item.id, true, item)
                                        setTopicsResourcesFetched(false)
                                      }}
                                      alt={"resource delete icon"}
                                      className={`my-auto pointer object-fit-contain h-13px`}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </>
                      )
                    })
                  : null}
                <Form className="my-form mb-0 text-center">
                  <React.Fragment>
                    <p className={`${StylesM.font_14} mb-2`}>
                      Drag and drop media/document files to add content /&nbsp;
                      <label className={`button btn border-0 px-0 mb-1 white-text text-underline ${StylesM.font_14}`} htmlFor="fileElem">
                        Browse
                      </label>
                    </p>
                    {/*<p className={`${StylesM.font_14} mb-4`} >Files Supported: MP4/ PDF/ XLS/ JPG/PNG</p>*/}
                  </React.Fragment>
                  {files.length === 0 ? (
                    <input
                      className={`d-none`}
                      type="file"
                      id="fileElem"
                      multiple
                      accept="image/*,application/pdf,video/*,.doc,.docx,.ppt,.pptx,.xlsx,.xls"
                      onChange={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        validateAndAdd(e)
                      }}
                    />
                  ) : null}
                  {/*<label className={`button btn border-0 px-5 border_on_hover ${StylesM.font_14}`} style={{ backgroundColor: `#586886`, boxShadow:'0px 3px 6px #00000029', color:"#E0E2E5", borderRadius:'36px' }} htmlFor="fileElem">Browse</label>*/}
                </Form>
                {caseStudyFetched ? (
                  <UploadFiles
                    sequence={data.resources.length == 0 ? 0 : parseFloat(data.resources[data.resources.length - 1].sequence).toFixed(2)}
                    files={files}
                    setFiles={(value) => setFiles(value)}
                    parentType="casestudy"
                    casestudy={casestudy}
                    fetchTopicResources={() => fetchCaseStudy()}
                  />
                ) : null}
              </div>
            )}
          </Droppable>

          {linksData.map((n, index) => {
            let clBorder = n?.error && n.error != "" ? "border-danger" : ""

            return (
              <>
                {n.error && n.error !== "" ? (
                  <div id={`linkMsg_${index}`}>
                    <div className="d-inline-flex px-3 py-0 br-23px">
                      <Image className="mt-1 mr-1 h-13px" src={ExclamationTriangle} />
                      <p className="mb-0 text-danger fs-14px">{n.error}</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <InputGroup key={`extLinks_${index}`} className={`d-flex m-2 ${clBorder} br-9px border_transparent bg-42506C w-100`}>
                  <InputGroup.Prepend>
                    <Image src={n.icon ? n.icon : link} className="m-0 mt-1 mx-2 w-25px object-fit-contain" />
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 d-inline-flex shadow-none text-white br-0px bg-42506C fs-16px`}
                    autoComplete="off"
                    type="text"
                    name="link"
                    defaultValue={linksData[index].link}
                    onChange={(e) => {
                      e.preventDefault()
                      if (e.target.value !== "") {
                        delayedSaveExtLink(index, e.target.value, linksData)
                      }
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                    }}
                    id={`link_${index}`}
                    placeholder="Add Link"
                    disabled={n.id > 0 ? true : false}
                  />
                  <InputGroup.Append className={`mx-1`}>
                    <div
                      className={`border-0 rounded-circle`}
                      style={{ backgroundColor: "#303C54", height: "75%", padding: "0px 6px 1px 6px", margin: "6px 0" }}
                      onClick={(e) => {
                        handleDelMoreLinks(e, index)
                      }}
                    >
                      <Image className="pointer align-items-center align-middle w-18px h-12px" src={deleteimg} />
                    </div>
                  </InputGroup.Append>
                </InputGroup>
              </>
            )
          })}
          <InputGroup.Append className={`p-2`}>
            {(!casestudy.content || casestudy.content === "<p><br></p>") && (
              <u
                className="pointer"
                style={{ fontSize: "14px", color: "#E0E2E5" }}
                onClick={() => {
                  setShowRichTextModal(true)
                  setActiveInputField("Instruction")
                }}
              >
                Add content
              </u>
            )}
            {addMoreLinks ? (
              <OverlayTrigger key={`top`} placement={`top`} overlay={<Tooltip>Please add a link in already enabled</Tooltip>}>
                <u className="pointer" style={{ fontSize: "14px", color: "#E0E2E5", marginLeft: "10px", opacity: 0.5 }}>
                  Add External Link
                </u>
              </OverlayTrigger>
            ) : (
              <u
                className="pointer"
                style={{ fontSize: "14px", color: "#E0E2E5", marginLeft: "10px" }}
                onClick={(e) => {
                  handleAddMoreLinks(e)
                }}
              >
                Add External Link
              </u>
            )}
            {/* <u className='pointer' style={{ fontSize: '14px', color: "#E0E2E5", marginLeft: '10px' }} onClick={(e) => { handleAddMoreLinks(e) }}>Add External Link</u> */}
          </InputGroup.Append>
        </InputGroup>
      </Col>
    )
  }

  const getFormData = () => {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-0">
        <Col className="px-0 d-flex my-0" style={{ maxWidth: "100%" }}>
          <div className="px-0" style={{ width: "calc(100%)" }}>
            <Col
              lg="12"
              md="12"
              sm="12"
              xs="12"
              className="border_on_hover pl-0"
              style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54", marginBottom: "12px" }}
            >
              <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                  <InputGroup.Text
                    className="border-0 d-flex justify-content-center"
                    style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                    id="basic-addon1"
                    onClick={(e) => focusOnData(e, "name")}
                  >
                    Case Study Name:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  className={`border-0 d-flex justify-content-center shadow-none ${StylesB.cs_create} text-white`}
                  style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px" }}
                  type="text"
                  name="name"
                  defaultValue={casestudy.name}
                  onChange={(e) => {
                    e.preventDefault()
                    setLoader(true)
                    delayedQuery(e.target.name, e.target.value)
                  }}
                  onBlur={(e) => {
                    e.preventDefault()
                    onBlurData(e.target.value === casestudy.name ? null : e.target.name, e.target.value)
                  }}
                  id="name"
                  required
                />
                {loader ? (
                  <svg
                    className="animate-spin my-auto"
                    style={{ height: `16px`, width: `16px`, position: "absolute", bottom: "16px", right: "5px", zIndex: 3 }}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : null}
              </InputGroup>
            </Col>
          </div>
        </Col>
        <React.Fragment>
          <Col
            className="mb-0 border_on_hover pl-0"
            style={{
              backgroundColor: "#303C54",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "9px",
              border: "1px solid #303C54",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            <Row>
              <Col>
                <InputGroup className="py-1 align-items-center" style={{ borderRadius: "9px", border: "1px solid transparent", height: "100%" }}>
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className="border-0 d-flex justify-content-center"
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                      id="basic-addon1"
                      onClick={(e) => focusOnData(e, "duration")}
                    >
                      Duration:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  {Number(casestudy.time) >= 0 && (
                    <FormControl
                      className={`border-0 d-flex justify-content-center shadow-none pr-1 ${StylesB.cs_create} text-white pl-0`}
                      style={{ maxWidth: "40px", backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px", textAlign: "right" }}
                      name="time"
                      type="number"
                      min={1}
                      defaultValue={casestudy.time}
                      onChange={(e) => {
                        e.preventDefault()
                        setLoaderTime(true)
                        delayedQuery(e.target.name, e.target.value)
                      }}
                      onBlur={(e) => {
                        e.preventDefault()
                        onBlurData(e.target.value === casestudy.time ? null : e.target.name, e.target.value)
                      }}
                      id="duration"
                      required
                    />
                  )}
                  <InputGroup.Text className="pl-0" style={{ background: "transparent", border: "0px", color: "#E0E2E5", opacity: 0.4 }} id="basic-addon2">
                    minutes
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col>
                <div className="py-1 d-inline-flex">
                  <p className="pt-3" style={{ color: "#E0E2E5", opacity: "60%", fontSize: "16px" }}>
                    Grading:{" "}
                  </p>
                  <div
                    className="float-left align-self-center ml-2"
                    style={{ background: "#42506C", border: "1px solid #E0E2E5", borderRadius: "9px", boxShadow: "0px 3px 6px #00000029" }}
                  >
                    <Button
                      onClick={(e) => {
                        handleSubmit(null, "grading", true)
                      }}
                      className={`float-left px-4 py-1 ${casestudy.grading ? `font-weight-bold` : ``} border_on_hover`}
                      style={{ background: casestudy.grading ? "#4F6080" : "#42506C", border: "1px solid transparent", borderRadius: "9px" }}
                      color="warning"
                    >
                      <p className={`p-0 m-0`} style={{ fontSize: "16px" }}>
                        Yes
                      </p>
                    </Button>
                    <Button
                      onClick={(e) => {
                        handleSubmit(null, "grading", false)
                      }}
                      className={`float-left px-4 py-1 ${!casestudy.grading ? `font-weight-bold` : ``} border_on_hover`}
                      style={{ background: !casestudy.grading ? "#4F6080" : "#42506C", border: "1px solid transparent", borderRadius: "9px" }}
                      color="warning"
                    >
                      <p className={`p-0 m-0`} style={{ fontSize: "16px" }}>
                        No
                      </p>
                    </Button>
                    {/* <Button onClick={(e) => { updateCertificateData('yes'); if (course.certification !== null) { setLoader(true) } }} className={`float-left px-4 py-1 ${giveCertification === 'yes' ? `font-weight-bold` : ``} border_on_hover`} style={{ background: giveCertification === 'yes' ? '#4F6080' : '#42506C', border: '1px solid transparent', borderRadius: '9px' }} color="warning"><p className={`p-0 m-0`} style={{ fontSize: '16px' }}>Yes</p></Button>
                            <Button onClick={(e) => { updateCertificateData('no'); if (course.certification !== null) { setLoader(true) } }} className={`float-left px-4 py-1 ${giveCertification === 'no' ? `font-weight-bold` : ``} border_on_hover`} style={{ background: giveCertification === 'no' ? '#4F6080' : '#42506C', border: '1px solid transparent', borderRadius: '9px' }} color="warning"><p className={`p-0 m-0`} style={{ fontSize: '16px' }}>No</p></Button> */}
                  </div>
                </div>
              </Col>
              {loaderTime ? (
                <svg
                  className="animate-spin my-auto"
                  style={{ height: `16px`, width: `16px`, position: "absolute", bottom: "25px", right: "5px", zIndex: 3 }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
            </Row>
          </Col>
        </React.Fragment>
        {showLinksData()}
      </Col>
    )
  }
  function handleCloseWin(e) {
    if (window.opener) window.opener.postMessage({ instruction: "payload", type: "casestudy" }, window.location.origin)
    window.close()
  }

  const getFormDataPublished = () => {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-0">
        {/*<Col className='px-0 d-flex my-0' style={{ maxWidth: '100%' }}>
                    <div className='px-0' style={{ width: 'calc(100%)' }}>
                        <Col lg="12" md="12" sm="12" xs="12" className='pl-0' style={{ marginBottom: '12px' }}>
                            <p className="mb-0 py-2 px-0 font-weight-bold" id='casestudyname' style={{ borderRadius: "9px 0px 0px 9px", color: '#E0E2E5', fontSize: '18px', lineHeight: '1', minWidth: 'max-content' }} >Certified Scrum Master Case Study</p>
                            <p className="mb-0 py-2 px-0" id='casestudyname' style={{ borderRadius: "9px 0px 0px 9px", fontSize: '14px', lineHeight: '1', minWidth: 'max-content', color: '#E0E2E5', opacity: "41%" }} >The deadline for the case study is 2 weeks from the date of initiation.</p>

                        </Col>
                    </div>
                </Col>
                */}
        <React.Fragment>
          <Col
            className="mb-0 border_on_hover pl-0"
            style={{
              backgroundColor: "#303C54",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "9px",
              border: "1px solid #303C54",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            <InputGroup className="py-1 align-items-center justify-content-between" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
              <div className={`d-flex`}>
                <>
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className="border-0 d-flex justify-content-center"
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                      id="basic-addon1"
                      onClick={(e) => focusOnData(e, "duration")}
                    >
                      Select Passing Percentage:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 d-flex justify-content-center shadow-none pr-1 text-white`}
                    style={{ maxWidth: "75px", backgroundColor: "#42506c", borderRadius: "0px", fontSize: "16px" }}
                    name="passing_marks"
                    type="number"
                    defaultValue={Number(casestudy.passing_marks)}
                    onChange={(e) => {
                      e.preventDefault()
                      setLoader(true)
                      delayedQuery(e.target.name, e.target.value)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === casestudy.passing_marks ? null : e.target.name, e.target.value)
                    }}
                    id="duration"
                    required
                  />
                </>
              </div>
              <p className="mb-0" style={{ color: "#E0E2E5", opacity: 0.4, textAlign: "right" }}>
                {" "}
                The participant needs to score {Number(casestudy.passing_marks)} points or more to pass
              </p>
              {loader ? (
                <svg
                  className="animate-spin my-auto"
                  style={{ height: `16px`, width: `16px`, position: "absolute", bottom: "16px", right: "5px", zIndex: 3 }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
            </InputGroup>
          </Col>
        </React.Fragment>
        <Col className="py-5 d-flex my-0 justify-content-center align-items-center row " style={{ maxWidth: "100%" }}>
          <div className="px-0" style={{ width: "calc(100%)" }}>
            <Col lg="12" md="12" sm="12" xs="12" className="pl-0" style={{ marginBottom: "12px" }}>
              <p
                className="mb-0 py-2 px-3 font-weight-bold text-center"
                id="casestudyname"
                style={{ borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "18px", lineHeight: "1", minWidth: "max-content" }}
              >
                {showNotif ? `Your case study is published!` : `Your case study is ready to be published!`}
              </p>
            </Col>
          </div>
          <div className="px-0" style={{ width: "calc(100%)" }}>
            <Col lg="12" md="12" sm="12" xs="12" className="pl-0  d-flex my-0 justify-content-center align-items-center row " style={{ marginBottom: "12px" }}>
              {!showNotif && (
                <Button
                  onClick={(e) => handleSubmit(e, null, null, true)}
                  className="border_on_hover d-flex justify-content-center"
                  style={{
                    backgroundColor: "#586886",
                    border: "1px solid #586886",
                    padding: "10px 15px 10px 15px",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "10px",
                    color: "#E0E2E5",
                    fontSize: "14px",
                    minWidth: "100px",
                    textAlign: "center",
                  }}
                >
                  Publish
                </Button>
              )}
              {showNotif && window && window.name === "casestudy12" && (
                <Button
                  onClick={(e) => handleCloseWin(e)}
                  className="border_on_hover d-flex justify-content-center"
                  style={{
                    backgroundColor: "#586886",
                    border: "1px solid #586886",
                    padding: "10px 15px 10px 15px",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "10px",
                    color: "#E0E2E5",
                    fontSize: "14px",
                    minWidth: "100px",
                    textAlign: "center",
                  }}
                >
                  Close
                </Button>
              )}
            </Col>
          </div>
        </Col>
        <Col className="py-5 d-flex my-0 justify-content-center align-items-center" style={{ maxWidth: "100%" }}></Col>
      </Col>
    )
  }

  const getCaseStudyData = () => {
    return (
      <>
        <Col lg="12" md="12" sm="12" xs="12" className="px-0">
          {getFormData()}
        </Col>
        <Col xs="12" className="mt-3 d-none">
          <Button className="float-right" id={`form-proceed`} style={{ background: "#576186", border: 0 }} type="submit" color="warning">
            Proceed
          </Button>{" "}
        </Col>
      </>
    )
  }

  const deleteQuestionModal = () => {
    if (deleteResourceData && Object.keys(deleteResourceData).length > 0) {
      return (
        <Modal
          show={deleteResourceData && Object.keys(deleteResourceData).length > 0}
          onHide={handleClose}
          centered
          className={`${StylesD.delete_modal} classroom-resource-delete-modal`}
        >
          <div className={`${StylesD.delete_modal_header}`}>
            <img
              onClick={() => setDeleteResourceData({})}
              src={CancelWhite}
              alt={`Cancel`}
              className={`my-auto mr-1 pointer`}
              style={{ objectFit: `contain`, height: `20px`, position: "absolute", top: "10px", right: "5px" }}
            />
            <img src={TrashCan} alt={`delete modal delete icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `20px` }} />
            <div>
              <p style={{ fontWeight: "bold", fontSize: "15px" }}>Delete</p>
            </div>
          </div>
          <Modal.Body className="text-center" style={{ backgroundColor: "#121b2b", borderRadius: "0px 0px 8px 8px" }}>
            <p style={{ color: "#E0E2E5", fontSize: "15px", marginBottom: "10px" }}>
              {" "}
              Are you sure you want to delete this question <span className={`font-weight-bold`}>{deleteResourceData.title}</span> ?{" "}
            </p>
            <div className="d-flex justify-content-center">
              <Button
                className={`${StylesD.add_button_large} border_on_hover mr-3`}
                variant="secondary"
                onClick={() => {
                  const newState = questions["quiz"]
                  newState.splice(deleteResourceData.index, 1)
                  setDraggedData(!draggedData)
                  deleteResource(deleteResourceData.id, "case-study-question")
                  setDeleteResourceData({})
                }}
                style={{ width: "75px", border: "1px solid transparent" }}
              >
                Yes
              </Button>
              <Button
                className={`${StylesD.add_button_large} border_on_hover`}
                variant="secondary"
                onClick={() => setDeleteResourceData({})}
                style={{ width: "75px", border: "1px solid transparent" }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )
    }
  }
  // const deleteQuestionObjModal = () => {
  //     if (deleteResourceObjData && Object.keys(deleteResourceObjData).length > 0) {
  //         return (
  //             <Modal show={deleteResourceObjData && Object.keys(deleteResourceObjData).length > 0} onHide={handleClose} centered className={`${StylesD.delete_modal} classroom-resource-delete-modal`} >
  //                 <div className={`${StylesD.delete_modal_header}`}>
  //                     <img onClick={() => setDeleteResourceObjData({})} src={CancelWhite} alt={`Cancel`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, height: `20px`, position: 'absolute', top: '10px', right: '5px' }} />
  //                     <img src={TrashCan} alt={`delete modal delete icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `20px` }} />
  //                     <div>
  //                         <p style={{ fontWeight: 'bold', fontSize: '15px' }}>Delete</p>
  //                     </div>
  //                 </div>
  //                 <Modal.Body className="text-center" style={{ backgroundColor: '#121b2b', borderRadius: '0px 0px 8px 8px' }} >
  //                     <p style={{ color: '#E0E2E5', fontSize: '15px', marginBottom: '10px' }} > Are you sure you want to delete this option ? </p>
  //                     <div className="d-flex justify-content-center" >
  //                         <Button className={`${StylesD.add_button_large} border_on_hover mr-3`} variant="secondary" onClick={() => { const newState = deleteResourceObjData.data; newState.splice(deleteResourceObjData.index, 1); setDraggedData(!draggedData); deleteResource(deleteResourceObjData.id, 'case-study-option', deleteResourceObjData.question_id, deleteResourceObjData.question_resource_id); setDeleteResourceObjData({}) }} style={{ width: '75px', border: '1px solid transparent' }} >Yes</Button>
  //                         <Button className={`${StylesD.add_button_large} border_on_hover`} variant="secondary" onClick={() => setDeleteResourceObjData({})} style={{ width: '75px', border: '1px solid transparent' }} >No</Button>
  //                     </div>
  //                 </Modal.Body>
  //             </Modal>
  //         )
  //     }
  // }

  const deleteResourceLinks = (id, push_to_bucket = false, item = {}) => {
    let value = ""
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        fetchCaseStudy()
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  const deleteResource = (id, type = "case-study-question", question_id = "", question_resource_id = "") => {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/` + id + "/", { headers: DefaultHeader })
      .then((res) => {
        setDraggedData(!draggedData)
        if (type === "case-study-option") {
          var index = questions["quiz"].findIndex((res) => res.id === question_resource_id)
          questions["quiz"][index].is_valid_question = res.data.is_valid_question
          setOptionUpdated(false)
        }
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  const fetchOptions = async (id, fetch = false, question_resource_id = "") => {
    if (questionOptions["question_" + id] === undefined || fetch) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/case-study-option/?casestudy_question=${id}`, { headers: login_credentials })
        .then((res) => {
          questionOptions["question_" + id] = res.data
          questionOptionsNext["question_" + id] = res.next
          questionOptionsFetched["question_" + id] = true
          setCurrentQuestionId(id)
          setOptionsFetched(true)
          if (fetch && res.data.length > 0) {
            let index2 = questions["quiz"].findIndex((res) => res.id === question_resource_id)
            questions["quiz"][index2].is_valid_question = res.data.results[0].is_valid_question
            questions["quiz"][index2].is_valid_answers = res.data.results[0].is_valid_answers
            if (is_valid != res.data.results[0].is_valid_question || is_valid2 != res.data.results[0].is_valid_answers) {
              setOptionUpdated(true)
            }
          } else if (fetch && res.data.length === 0) {
            let index2 = questions["quiz"].findIndex((res) => res.id === question_resource_id)
            questions["quiz"][index2].is_valid_answers = "optionsDoesNotExist"
            if (is_valid2 !== "optionsDoesNotExist") {
              setOptionUpdated(true)
            }
          }
        })
        .catch((error) => {
          setOptionsFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      if (currentQuestionId != id) {
        setCurrentQuestionId(id)
      } else {
        setCurrentQuestionId("")
      }
      setOptionsFetched(true)
      setToggleQuestion(!toggleQuestion)
    }
  }

  const createOption = (data, question_data, second = false, question_resource_id = false) => {
    let question_id = question_data.id
    let option = {}
    if (question_data.length > 0) {
      option["answer_map_id"] = question_data.answermap_set[0]
    }
    option["name"] = `sample option console`
    option["casestudy_question"] = question_id

    var formData = new FormData()
    for (var k in option) {
      formData.append(k, option[k])
    }
    if (option.id != undefined) {
    } else {
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/case-study-option/", formData, { headers: login_credentials })
        .then((res) => {
          option.id = res.data.id
          option.is_correct = false
          if (!questionOptions["question_" + question_id]) {
            questionOptions["question_" + question_id] = []
          }
          questionOptions["question_" + question_id] = questionOptions["question_" + question_id].concat(option)
          delete option["casestudy_question"]
          setOptionCreated(true)
          setOptionCreatedX(true)
          if (question_resource_id !== false) {
            var index = questions["quiz"].findIndex((res) => res.id === question_resource_id)
            if (index > 0) {
              let is_valid = questions["quiz"][index].is_valid_question
              questions["quiz"][index].is_valid_question = res.data.is_valid_question
              let is_valid2 = questions["quiz"][index].is_valid_answers
              questions["quiz"][index].is_valid_answers = res.data.is_valid_answers
              if (is_valid != res.data.is_valid_question || is_valid2 != res.data.is_valid_answers) {
                setOptionUpdated(true)
              }
            }
          }
          if (second == true) {
            setOptionCreatedX(false)
            createOption(data, question_data)
          } else if (second == null) {
            if (document.getElementById(res.data.id + `-question-option-input-id`)) document.getElementById(res.data.id + `-question-option-input-id`).focus()
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()

            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  const handleDraggedOptionSequence = async (currentItem, type, allowCreationOfOptions = true) => {
    if (currentItem) {
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      formData.append("casestudy_question", currentItem.casestudy_question)
      formData.append("name", currentItem.name)
      formData.append("title", type === "update" && currentItem.title)

      let url = type === `create` ? `/case-study-option/` : `/case-study-option/${currentItem.id}/`
      if (type === `update`) {
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + url, formData, { headers: DefaultHeader })
          .then((res) => {})
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()
              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    }
  }
  const handleDraggedItemSequence = async (currentItem, type, allowCreationOfOptions = true) => {
    if (currentItem) {
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      formData.append("question_id", type === "update" && currentItem.question_data ? currentItem.question_data.id : currentItem.id)
      formData.append("case_study", location.id)
      formData.append("title", type === "update" && currentItem.title)
      let url = type === `create` ? `/case-study-question/` : `/case-study-question/${currentItem.id}/`
      if (type === `update`) {
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + url, formData, { headers: DefaultHeader })
          .then((res) => {})
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()
              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    }
  }

  const createQuestion = (value) => {
    let question = {}
    question["case_study"] = id
    question["question_type"] = value
    question["title"] = "sample question console"
    question["score"] = "1.00"
    var formData = new FormData()
    for (var k in question) {
      formData.append(k, question[k])
    }
    if (question.id != undefined) {
    } else {
      var question_id = question.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/case-study-question/", formData, { headers: login_credentials })
        .then((res) => {
          let currentQuestion = {}
          currentQuestion.id = res.data.id
          currentQuestion.title = res.data.title
          currentQuestion.score = res.data.score
          currentQuestion.question_type = res.data.question_type
          if (questions["quiz"].length > 0) {
            currentQuestion.sequence = parseInt(questions["quiz"][questions["quiz"].length - 1].sequence) + 10
          } else {
            currentQuestion.sequence = parseInt(0)
          }
          setOptionsFetched(false)
          fetchOptions(res.data.id)
          questions["quiz"] = questions["quiz"].concat(currentQuestion)
          createOption([], res.data, true, res.data.id)
          setTimeout(() => {
            let input = document.getElementById(res.data.id + `-question-input-id`)
            input && input.focus()
          }, 800)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()

            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  const fetchNextQuizQuestions = async (next_url) => {
    // questionsFetched['quiz'] = false
    setQuizQuestionsFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_template_quizQuestions = questions["quiz"]
      if (res.data.results.length > 0) {
        for (var md = 0; md <= res.data.results.length - 1; md++) {
          next_template_quizQuestions.push(res.data.results[md])
        }
        questions["quiz"] = next_template_quizQuestions
      }
      questionsNext["quiz"] = res.data.next
      // questionsFetched['quiz'] = true
      setQuizQuestionsFetched(true)
    })
  }

  const handleUpdateQuestion = (e, index, name = null, value = null) => {
    setUsingDropdown(!usingDropdown)
    let question = questions["quiz"][index]
    if (name === null) {
      if (e.target.name === "title") {
        question.title = e.target.value
      } else {
        question.title = question.title
        question[e.target.name] = e.target.value
      }
    } else if (name === "description") {
      question.title = question.title
      question["description"] = question.description ? question.description : ""
    } else {
      question.title = question.title
      question[name] = value
    }
    var formData = new FormData()
    for (var k in question) {
      if (k !== "is_valid_question") {
        formData.append(k, question[k])
      }
    }
    if (question.id != undefined) {
      formData.append("case_study", location.id)
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/case-study-question/" + question.id + "/", formData, { headers: login_credentials })
        .then((res) => {
          questions["quiz"][index] = question
          let is_valid = questions["quiz"][index].is_valid_question
          questions["quiz"][index].is_valid_question = res.data.is_valid_question

          if (is_valid != res.data.is_valid_question) {
            setOptionUpdated(true)
          }
          setOptionUpdated(false)
          setUsingDropdown(!usingDropdown)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()

            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  const handleUpdateAnswer = (e, option, question_resource_id, value = null) => {
    var index = questions["quiz"].findIndex((res) => res.id === question_resource_id)
    if (e) {
      option.name = e.target.value
      option.display_name = e.target.value
    } else {
      option.name = value
      option.display_name = value
    }
    option.casestudy_question = question_resource_id
    var formData = new FormData()
    for (var k in option) {
      formData.append(k, option[k])
    }
    if (option.id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/case-study-option/" + option.id + "/", formData, { headers: login_credentials })
        .then((res) => {
          let is_valid = questions["quiz"][index].is_valid_question
          questions["quiz"][index].is_valid_question = res.data.is_valid_question
          let is_valid2 = questions["quiz"][index].is_valid_answers
          questions["quiz"][index].is_valid_answers = res.data.is_valid_answers
          if (is_valid != res.data.is_valid_question || is_valid2 != res.data.is_valid_answers) {
            setOptionUpdated(true)
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()

            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  const handleUpdateOption = (e, option, action, question_id, index, question_resource_id) => {
    let perform = false
    var formData = new FormData()

    if (action === "add" && option.is_correct === false) {
      perform = true
      formData.append("is_correct", true)
      formData.append("casestudy_question", question_resource_id)
      formData.append("name", option.name)
    } else if (action === "remove" && option.is_correct) {
      perform = true
      formData.append("is_correct", false)
      formData.append("casestudy_question", question_resource_id)
      formData.append("name", option.name)
    }

    if (perform && option.id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/case-study-option/" + option.id + "/", formData, { headers: login_credentials })
        .then((res) => {
          if (action === "add" && option.is_correct === false) {
            option.is_correct = true
            questionOptions["question_" + question_id][index].is_correct = true
          } else if (action === "remove" && option.is_correct) {
            option.is_correct = false
            questionOptions["question_" + question_id][index].is_correct = false
          }
          var index2 = questions["quiz"].findIndex((res) => res.id === question_resource_id)
          questions["quiz"][index2].is_valid_question = res.data.is_valid_question
          questions["quiz"][index2].is_valid_answers = res.data.is_valid_answers
          setOptionUpdated(true)
          setToggledOption(true)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()

            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  const getRichTextData = () => {
    if (activeInputField === "Question") {
      const index = questions["quiz"].findIndex((res) => res.id === currentQuestionId)
      return (
        <SunEditor
          setContents={questions["quiz"][index].description}
          onChange={(text) =>
            setQuestions((prev) => {
              let prevData = prev
              prevData["quiz"][index].description = text
              return prevData
            })
          }
          id="additional_criteria"
          placeholder="Add Rules Of Engagement"
          setOptions={{
            height: "auto",
            maxHeight: "400px",
            minHeight: "400px",
            buttonList: [
              [
                "undo",
                "redo",
                "font",
                "fontSize",
                "formatBlock",
                "bold",
                "underline",
                "italic",
                "link",
                "image",
                "codeView",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
                "table",
              ],
            ],
          }}
        />
      )
    }
    if (activeInputField === "Instruction") {
      return (
        <SunEditor
          setContents={casestudy.content}
          onChange={(text) =>
            setCaseStudy((prev) => {
              let prevData = prev
              prevData["content"] = text
              return prevData
            })
          }
          id="additional_criteria"
          placeholder="Add Rules Of Engagement"
          setOptions={{
            height: "auto",
            maxHeight: "400px",
            minHeight: "400px",
            buttonList: [
              [
                "undo",
                "redo",
                "font",
                "fontSize",
                "formatBlock",
                "bold",
                "underline",
                "italic",
                "link",
                "image",
                "codeView",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
                "table",
              ],
            ],
          }}
        />
      )
    }
  }

  const showPublishTab = (e) => {
    e.preventDefault()

    if (!casestudy.name.trim() || casestudy.name.trim() == "") {
      quesErr.current = `Please fill case study name`
      // setShowNotifQues(true);
      setNotificationData({ type: "error", title: `Please fill case study name` })
      // setTimeout(() => {
      //     setShowNotifQues(false);
      // }, 3000)
      return
    }
    if (!casestudy.time || casestudy.time == "") {
      quesErr.current = `Please fill case study duration`
      // setShowNotifQues(true);
      setNotificationData({ type: "error", title: `Please fill case study duration` })
      // setTimeout(() => {
      //     setShowNotifQues(false);
      // }, 3000)
      return
    }

    //console.log(files, linksData, casestudy, casestudy.resources.length === 0 && linksData.length === 0 && casestudy.content.trim() === '')
    if (casestudy.resources.length === 0 && linksData.length === 0 && (casestudy.content.trim() === "" || !casestudy.content.replace(/(<([^>]+)>)/gi, ""))) {
      quesErr.current = `Please add case study content`
      // setShowNotifQues(true);
      setNotificationData({ type: "error", title: `Please add case study content` })
      // setTimeout(() => {
      //     setShowNotifQues(false);
      // }, 3000)
      return
    }

    if (linksData.length > 0) {
      let invalid_link = false
      linksData.forEach(function (item, index) {
        if (!item.link) {
          linksData[index].error = "Enter a valid URL"
          linksData[index].id = ""
          invalid_link = true
        }
      })
      setLinksData([...linksData])

      if (invalid_link) {
        quesErr.current = `Please remove invalid external link`
        // setShowNotifQues(true);
        setNotificationData({ type: "error", title: `Please remove invalid external link` })
        setTimeout(() => {
          setShowNotifQues(false)
        }, 3000)
        return
      }
    }

    if (questions["quiz"].length < 1) {
      quesErr.current = `Please add questions`
      // setShowNotifQues(true);
      setNotificationData({ type: "error", title: `Please add questions` })
      // setTimeout(() => {
      //     setShowNotifQues(false);
      // }, 3000)
      return
    }

    const validQues = checkAllQuestionValid()
    if (validQues.length === 0 && questions["quiz"].length > 0) {
      updateActiveState("publish_course")
      setShowNotif(false)
      setCaseStudy((prev) => {
        return {
          ...prev,
          published: false,
        }
      })
    } else {
      quesErr.current = `Question and correct answer required`
      // setShowNotifQues(true);
      setNotificationData({ type: "error", title: `Question and correct answer required` })
      // setTimeout(() => {
      //     setShowNotifQues(false);
      // }, 3000)
    }
  }

  const addQuestionClicked = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setQuestionCreated(false)
    createQuestion("1")
  }

  const updateInfo = () => {
    if (activeInputField === "Question") {
      const index = questions["quiz"].findIndex((res) => res.id === currentQuestionId)
      handleUpdateQuestion(null, index, "description")
    }
    if (activeInputField === "Instruction") {
      onBlurData("content", casestudy.content)
    }
  }
  const displayData = () => {
    return (
      <div
        id="mod"
        className={`box text-dark p-0 w-100 window_screen`}
        onScroll={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        style={{
          margin: 0,
          height: "100%",
          backgroundColor: "#212C42",
          border: "1px solid #E0E2E5",
          borderRadius: "11px",
          boxShadow: "0px 3px 12px #00000099",
          overflowY: "hidden",
        }}
      >
        <div className={`w-100 py-2 drag d-inline-flex align-items-center`} style={{ borderBottom: "1px solid #E0E2E5", cursor: "move" }}>
          <p className={`mb-0 text-center pl-2 w-100`} style={{ fontSize: "18px", fontWeight: "500", color: "#E0E2E5" }}>
            Add {activeInputField} Below
          </p>
          <img
            src={CancelIcon}
            className="mb-0 pointer ml-auto mr-2"
            style={{ fontSize: "16px", width: "16px" }}
            onClick={() => {
              setShowRichTextModal(false)
              updateInfo()
            }}
          />
        </div>
        {getRichTextData()}
      </div>
    )
  }

  const showOptionButtons = (data, question_data, question_resource_id) => {
    let question_id = question_data.id
    var index = questions["quiz"].findIndex((res) => res.id === question_data.id)
    if (data) {
      return (
        <Droppable droppableId={`question_${question_resource_id}`} type={`optionsDraggable`}>
          {(provided, snapshot) => (
            <div className={`col-12 p-8px`} ref={provided.innerRef} style={{ backgroundColor: `#313c54` }}>
              {question_data.question_type === "1" ? (
                data.map((datum, index) => (
                  <Draggable
                    key={datum.id + `-option-key-` + index + `-${question_id}`}
                    draggableId={`${datum.id.toString()}-question-id-${index.toString() + `-${question_id.toString()}`}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div className="d-flex">
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`pl-48px p-1 tab-ignore flex-grow w-100`}
                        >
                          <div
                            variant="none"
                            id={`quizOptions_${question_id.toString()}` + datum.id}
                            className={`${Styles.font_16} pl-3 shadow-none w-100  text-left d-inline-flex`}
                            style={{ backgroundColor: `#42506c`, color: `#C6CFF4`, borderRadius: `0px`, opacity: 1 }}
                          >
                            <input
                              type="text"
                              name="display_name"
                              id={datum.id + `-question-option-input-id`}
                              defaultValue={datum.name === "sample option console" ? `` : datum.name}
                              onChange={(e) => {
                                e.preventDefault()
                                setOptionUpdated(false)
                                delayedQueryOptions(datum, question_resource_id, e.target.value)
                              }}
                              onBlur={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                if (e.target.value !== datum.name && e.target.value !== "") {
                                  handleUpdateAnswer(null, datum, question_resource_id, e.target.value)
                                }
                              }}
                              placeholder={"Enter Option text"}
                              className={`form-control shadow-none border-0 px-3 w-75 my-auto ${Styles.font_16}`}
                              style={{ outline: `none`, backgroundColor: `transparent`, maxWidth: `fix-content`, color: `#C6CFF4`, verticalAlign: `middle` }}
                              required
                            />
                            <div className={`ml-auto d-inline-flex ${toggledOption}`}>
                              <div
                                className={`h-100 d-inline-flex`}
                                style={{ cursor: "pointer", backgroundColor: datum.is_correct ? `#578684` : `#586886`, verticalAlign: `middle` }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setToggledOption(false)
                                  setOptionUpdated(false)
                                  handleUpdateOption(e, datum, "add", question_id, index, question_resource_id)
                                }}
                              >
                                <img
                                  src={facheck}
                                  alt={"check"}
                                  className={`m-auto px-2`}
                                  style={{ objectFit: `contain`, height: `20px`, opacity: datum.is_correct ? `1` : `0.14` }}
                                />
                              </div>
                              <div
                                className={`h-100 d-inline-flex ml-1`}
                                style={{ cursor: "pointer", backgroundColor: datum.is_correct ? `#586886` : `#904744`, verticalAlign: `middle` }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setToggledOption(false)
                                  setOptionUpdated(false)
                                  handleUpdateOption(e, datum, "remove", question_id, index, question_resource_id)
                                }}
                              >
                                <img
                                  src={fatimes}
                                  alt={"close"}
                                  className={`m-auto px-2`}
                                  style={{ objectFit: `contain`, height: `24px`, opacity: datum.is_correct ? `0.14` : `1` }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* onClick={(e) => { e.preventDefault(); const newState = data; newState.splice(index, 1); setDraggedData(!draggedData); deleteResource(datum.id, 'case-study-option', question_id, question_resource_id); }} */}
                        {/* <Dropdown.Item eventKey="0" className={`text-white`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); datum.index = index; setDeleteResourceData(datum) }} > */}
                        <div
                          style={{ paddingLeft: `9px`, margin: `auto` }}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setDeleteResourceObjData({
                              id: datum.id,
                              type: "case-study-option",
                              question_id: question_id,
                              question_resource_id: question_resource_id,
                              index: index,
                              data: data,
                            })
                          }}
                        >
                          <div
                            className={`align-middle`}
                            style={{ backgroundColor: `#303C54`, width: `41px`, height: `41px`, borderRadius: `50%`, textAlign: `center` }}
                          >
                            <Image className="pointer align-items-center align-middle w-20px h-20px m-10px" src={deleteimg} />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))
              ) : (
                <>
                  <Col className="px-0 border_on_hover pl-0" style={{ backgroundColor: "#303C54", borderRadius: "9px", border: "1px solid #303C54" }}>
                    {question_data.description && question_data.description !== "<p><br></p>" ? (
                      <InputGroup className="py-0 d-block" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                        <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                          <InputGroup.Text
                            className="border-0 d-flex justify-content-center"
                            style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                            id="basic-addon1"
                            onClick={(e) => {
                              focusOnData(e, "additional_criteria")
                              setShowRichTextModal(true)
                              setActiveInputField("Question")
                            }}
                          ></InputGroup.Text>
                        </InputGroup.Prepend>
                        <div className={`pl-0 mx-2 mb-2`} style={{}}>
                          <div
                            onClick={() => {
                              setShowRichTextModal(true)
                              setActiveInputField("Question")
                            }}
                            dangerouslySetInnerHTML={{ __html: question_data.description }}
                            style={{ maxHeight: "100px", minHeight: "50px", overflowY: "scroll", textAlign: "left" }}
                          />
                        </div>
                      </InputGroup>
                    ) : null}
                  </Col>
                </>
              )}
              {provided.placeholder}

              <div className={`d-flex bg-transparent`}>
                <div className={`pl-3 text-center w-100`}>
                  <div className="d-flex flex-row-reverse bd-highlight mb-0 justify-content-between align-items-center">
                    <div className="d-flex">
                      <Dropdown className={`py-2 pl-2 breakout-groups move-dropdown`} style={{ margin: `auto 0 auto auto` }}>
                        <Dropdown.Toggle variant="none" className={`last_drop_item p-0`}>
                          <div
                            variant="none"
                            id="create-question"
                            className={`ml-1 text-center px-3 py-1 mr-1 inner-element-box-shadow align-items-center d-inline-flex fs-16px bg-313C54 color-D4DCF7 br-18px`}
                          >
                            {question_data.question_type === "2" ? "Subjective" : "Objective"} &nbsp;{" "}
                            <span>
                              <img
                                src={ArrowWhiteCircle}
                                className="w-15px h-15px position-absolute"
                                style={{ top: "20px", right: "12px", transform: `rotate(90deg)` }}
                              />
                            </span>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="last_drop_item">
                          <Dropdown.Item
                            eventKey="0"
                            onClick={(e) => {
                              e.preventDefault()
                              handleUpdateQuestion(null, index, "question_type", "1")
                            }}
                            className="last_drop_item text-white fs-14px"
                          >
                            {" "}
                            Objective{" "}
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="0"
                            onClick={(e) => {
                              e.preventDefault()
                              handleUpdateQuestion(null, index, "question_type", "2")
                            }}
                            className="last_drop_item text-white fs-14px"
                          >
                            {" "}
                            Subjective{" "}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <InputGroup
                        className="mt-2 ml-1 text-center p-0 mr-1 inner-element-box-shadow align-items-center  d-inline-flex"
                        style={{ borderRadius: "100px", border: "1px solid transparent", height: "35px" }}
                      >
                        <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "100px" }}>
                          <InputGroup.Text
                            className="border-0 d-flex justify-content-center text-white"
                            style={{ backgroundColor: "#303C54", fontSize: "16px", paddingTop: "2px", paddingBottom: "2px" }}
                            id="basic-addon1"
                            onClick={(e) => focusOnData(e, `ques-${question_data.id}`)}
                          >
                            Score:
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          className={`border-0 d-flex justify-content-center shadow-none pr-1 ${StylesB.cs_create} text-white`}
                          style={{ maxWidth: "50px", backgroundColor: "#303C54", borderRadius: "100px", fontSize: "16px", height: "30px" }}
                          type="number"
                          name={`score`}
                          defaultValue={Number(question_data.score)}
                          onChange={(e) => {
                            e.preventDefault()
                            handleUpdateQuestion(e, index)
                          }}
                          id={`ques-${question_data.id}`}
                          required
                        />
                      </InputGroup>
                    </div>

                    {question_data.question_type === "1" && (
                      <Button
                        variant="none"
                        id="create-question"
                        className={`ml-2 text-center px-3 py-1 mr-1 inner-element-box-shadow align-items-center d-inline-flex`}
                        style={{ backgroundColor: `#313C54`, color: `#D4DCF7`, borderRadius: `18px`, fontSize: "16px", height: "37px" }}
                        onClick={(e) => {
                          e.preventDefault()
                          setOptionCreated(false)
                          setOptionUpdated(false)
                          createOption(data, question_data, null, question_resource_id)
                        }}
                      >
                        <Image src={Plus} className="w-15px h-15px" />
                        &nbsp; Add Option
                      </Button>
                    )}
                    {question_data.question_type !== "1" && (!question_data.description || question_data.description === "<p><br></p>") && (
                      <u
                        className="pointer"
                        style={{ fontSize: "14px", color: "#E0E2E5" }}
                        onClick={() => {
                          setShowRichTextModal(true)
                          setActiveInputField("Question")
                        }}
                      >
                        Add Subjective Text
                      </u>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Droppable>
      )
    }
  }

  const checkAllQuestionValid = () => {
    const invalid_ques = questions["quiz"].filter((datum) => !datum.is_valid_question)
    return invalid_ques
  }

  const showQuizQuestions = () => {
    if (questionsFetched["quiz"] && questions["quiz"]) {
      return (
        <>
          <Col
            className="px-0 border_on_hover pl-0"
            style={{
              backgroundColor: "#303C54",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "9px",
              border: "1px solid #303C54",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            <p
              className="mb-0 py-2 px-2"
              style={{ borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px", lineHeight: "1", minWidth: "max-content" }}
            >
              Questions
            </p>
            <Droppable droppableId={`quiz`} type={`questionsDraggable`}>
              {(provided, snapshot) => (
                <div className={`col-12`} ref={provided.innerRef} style={{ ...getListStyle(snapshot.isDraggingOver), paddingBottom: "50px" }}>
                  {questions["quiz"].map((datum, index) => {
                    let options_error_class = ""
                    let question_error_class = (datum && datum.is_valid_question) || datum.is_valid_question
                    let toggle_class = datum && questionOptions["question_" + datum.id] && currentQuestionId === datum.id
                    return (
                      <Draggable key={datum.id + `-question-key-` + index} draggableId={`${datum.id.toString()}-question-id-${index.toString()}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`tab-ignore`}
                            style={{
                              ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                              cursor: "pointer",
                            }}
                          >
                            <div
                              id={"quizQuestions_" + datum.id}
                              className={`${Styles.font_16} shadow-none w-100 text-left d-inline-flex`}
                              style={{ color: `#b2b6cb`, borderRadius: `0px`, opacity: 1 }}
                            >
                              <div>
                                <h4 className={`pr-18px m-0 h-100`} style={{ color: `#fff`, padding: `7px` }}>
                                  {index + 1}
                                </h4>
                              </div>
                              <div
                                className={`d-inline-flex w-100 ${options_error_class} ${question_error_class ? `` : `red-class`} ${
                                  toggle_class ? `border-bottom-0` : ``
                                } `}
                                style={{
                                  border: `${question_error_class ? `0px` : `1px`} solid #904744`,
                                  boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.6)",
                                  backgroundColor: "#313c54",
                                  borderTopLeftRadius: "5px",
                                  borderTopRightRadius: "5px",
                                }}
                              >
                                <div
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setOptionsFetched(false)
                                    fetchOptions(datum.id)
                                  }}
                                  className={`px-3 py-2`}
                                  style={{ borderRight: `1px dotted #495269`, cursor: "grabbing" }}
                                >
                                  <Image src={DragHandleIcon} className={`my-auto`} />
                                </div>
                                <input
                                  type="text"
                                  name="title"
                                  id={datum.id + `-question-input-id`}
                                  defaultValue={datum ? (datum.title === "sample question console" ? `` : datum.title) : ""}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                  }}
                                  onChange={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setOptionUpdated(false)
                                    delayedQueryQuestion("title", index, e.target.value)
                                  }}
                                  onBlur={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (e.target.value !== "" && e.target.value !== datum.title) {
                                      handleUpdateQuestion(null, index, "title", e.target.value)
                                    }
                                  }}
                                  placeholder={"Enter Question Title"}
                                  className={`bold-600 fs-18px form-control shadow-none border-0 px-3 my-auto ${Styles.font_16}`}
                                  style={{
                                    outline: `none`,
                                    backgroundColor: `transparent`,
                                    maxWidth: `fix-content`,
                                    color: `#C6CFF4`,
                                    verticalAlign: `middle`,
                                  }}
                                  required
                                />
                                <img
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setOptionsFetched(false)
                                    fetchOptions(datum.id)
                                  }}
                                  src={toggle_class ? ChevronCircleUp : ChevronCircleDown}
                                  alt={"arrow for collapse and expand"}
                                  style={{ objectFit: `contain`, width: `20px`, height: `20px`, margin: `auto 9px auto 18px`, verticalAlign: `middle` }}
                                />
                                <Dropdown className={`py-2 pl-2 breakout-groups move-dropdown`} style={{ margin: `auto 0 auto auto` }}>
                                  <Dropdown.Toggle id={`question-delete-menu-${datum.id}`} variant="none" className={`last_drop_item p-0`}>
                                    <Image className="pointer w-20px h-20px" src={EllipsisV} />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="last_drop_item">
                                    <Dropdown.Item
                                      eventKey="0"
                                      className={`text-white`}
                                      onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        datum.index = index
                                        setDeleteResourceData(datum)
                                      }}
                                    >
                                      <Image className="pointer align-items-center align-middle w-20px h-20px m-10px" src={deleteimg} />
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            {toggle_class ? (
                              <div className={`d-inline-flex w-100`}>
                                <div>
                                  <h4 className={`pr-18px m-0 ${Styles.opacity_0}`} style={{ color: `#9ba1c4`, padding: `7px`, visibility: "hidden" }}>
                                    {index + 1}
                                  </h4>
                                </div>
                                <div
                                  className={`px-0 w-100 ${Styles.border_top}`}
                                  style={{
                                    border: `${question_error_class ? `0px` : `1px`} solid #904744`,
                                    borderBottomLeftRadius: "5px",
                                    borderBottomRightRadius: "5px",
                                  }}
                                >
                                  {showOptionButtons(questionOptions["question_" + datum.id], datum, datum.id)}
                                </div>
                              </div>
                            ) : (
                              ``
                            )}
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                  {questionsFetched["quiz"] && questionsNext["quiz"] !== null ? (
                    <Col md="auto" className={`d-inline-flex w-100 my-2`}>
                      <Button
                        variant="none"
                        onClick={(e) => {
                          e.preventDefault()
                          fetchNextQuizQuestions(questionsNext["quiz"])
                        }}
                        className={`${Styles.font_12} text-center px-3 py-2 mx-auto`}
                        style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                      >
                        See More
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Droppable>
          </Col>
        </>
      )
    }
  }

  return (
    <div>
      <SEO title="Casestudy Creation" />
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} onDragUpdate={onDragUpdate}>
        <Sidebar
          activeResourceType={activeResourceType}
          setActiveResourceType={(value) => setActiveResourceType(value)}
          activeNavItem={activeNavItem}
          setActiveNavItem={(value) => setActiveNavItem(value)}
          leftNavOpen={leftNavOpen}
          toggleLeftNav={toggleLeftNav}
          enableDND={true}
          allResourcesChanged={allResourcesChanged}
          allResources={allResources}
          setAllResource={(value) => setAllResources(value)}
          currentSelectedResourcesType={currentSelectedResourcesType}
          setHomepageActiveResource={(value) => setCurrentSelectedResourcesType(value)}
          setHomepageAllResource={(value) => setAllResources(value)}
          parent_page={"course_content"}
          currentNavTab={currentNavTab}
          setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
        />
        <LayoutHeader />
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          onClick={(e) => toggleLeftNav(false)}
          className="px-0 d-flex"
          style={{ marginLeft: "60px", width: "calc(100vw - 60px)", justifyContent: leftNavOpen ? "flex-end" : "center", minHeight: "100vh" }}
        >
          <div
            className={`${leftNavOpen ? "" : "col-12"} mt-0 d-flex px-2`}
            style={{
              alignItems: leftNavOpen ? "flex-end" : "center",
              flexDirection: "column",
              width: leftNavOpen ? "calc(100vw - 250px)" : "calc(100vw - 60px)",
              height: "fit-content",
            }}
          >
            <Col
              xs="12"
              sm="12"
              md="10"
              lg="10"
              onMouseOver={(e) => {
                e.stopPropagation()
                e.preventDefault()
                let ele = document.getElementById("sections")
                if (ele !== null && ele !== undefined) {
                  if (ele.scrollWidth > ele.clientWidth) {
                    setHovered(true)
                  }
                }
              }}
              className={`${StylesB.cs_creation_header} d-flex px-0`}
            >
              <p
                className="mb-0 py-2 px-3 font-weight-bold"
                id="casestudyname"
                style={{ borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "18px", lineHeight: "1", minWidth: "max-content" }}
              >
                {casestudy.name}
              </p>
              <HorizontalScroll className={`d-inline-flex w-100`} scrollId={"top-scroll-id"}>
                <div id="sections" className="d-flex align-items-center" style={calculateWidth()}>
                  <p
                    onClick={updateActiveState}
                    className={`${StylesB.backgroundonhover} ${
                      activestate === "add_detail" ? StylesB.cs_nav_item_active : ""
                    } mb-0 pointer py-2 px-3 fs-16px font-weight-bold`}
                    style={{ color: "#E0E2E5", border: "1px solid tranparent", minWidth: "max-content" }}
                    id="add_detail"
                  >
                    Add Details
                  </p>
                  <p
                    onClick={(e) => showPublishTab(e)}
                    className={`${StylesB.backgroundonhover} ${
                      activestate === "publish_course" ? StylesB.cs_nav_item_active : ""
                    } mb-0 pointer py-2 pl-14px pr-14px fs-16px`}
                    style={{ color: "#E0E2E5", border: "1px solid tranparent", minWidth: "max-content" }}
                    id="publish_course"
                  >
                    Publish
                  </p>
                </div>
              </HorizontalScroll>

              <Image
                src={CancelWhite}
                alt={`close`}
                width={20}
                height={20}
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/app/case-study")
                }}
                className={`pointer mr-3 ml-auto float-right align-self-center`}
              />
            </Col>

            <Col
              xs="12"
              sm="12"
              md="10"
              lg="10"
              onMouseOver={() => {
                setHovered(false)
              }}
              className="px-0"
            >
              {activestate === "add_detail" ? (
                <Form className="form-horizontal" onSubmit={handleSubmit}>
                  <div
                    className="px-3"
                    style={{
                      maxHeight: "calc(100vh - 190px)",
                      overflowY: "scroll",
                      padding: "12px",
                      borderRadius: "9px 9px 0px 0px",
                      boxShadow: "0px 3px 12px #00000099",
                    }}
                  >
                    {getCaseStudyData()}
                    {deleteQuestionModal()}
                    {/* {deleteQuestionObjModal()} */}
                    {showQuizQuestions()}
                  </div>
                  <Col
                    className="px-0 pl-3 py-2"
                    style={{ borderTop: "1px solid #63666f", borderRadius: "0px 0px 9px 9px", boxShadow: "0px -3px 12px #00000099" }}
                  >
                    <div className="d-inline-block justify-content-start">
                      <button
                        onClick={(e) => {
                          addQuestionClicked(e)
                        }}
                        className="border_on_hover fs-14px"
                        style={{
                          backgroundColor: "#586886",
                          border: "1px solid #586886",
                          height: "28px",
                          paddingLeft: "30px",
                          paddingRight: "30px",
                          boxShadow: "0px 3px 6px #00000029",
                          borderRadius: "23px",
                          color: "#E0E2E5",
                        }}
                      >
                        Add questions
                      </button>
                      <button
                        onClick={(e) => showPublishTab(e)}
                        className="border_on_hover fs-14px ml-2"
                        style={{
                          backgroundColor: "#586886",
                          border: "1px solid #586886",
                          height: "28px",
                          paddingLeft: "30px",
                          paddingRight: "30px",
                          boxShadow: "0px 3px 6px #00000029",
                          borderRadius: "23px",
                          color: "#E0E2E5",
                        }}
                      >
                        Next
                      </button>
                    </div>
                    {dispAddToResLib ? (
                      <div className="pr-2 d-inline-block float-right">
                        <Form.Check
                          className=""
                          style={{ fontSize: 14 }}
                          type={"checkbox"}
                          id={`default-checkbox`}
                          label={`Add this case-study to the resource library`}
                          checked={addToResLib}
                          onChange={() => setAddToResLib((p) => !p)}
                        />
                      </div>
                    ) : null}
                  </Col>
                </Form>
              ) : (
                <Form className="form-horizontal" onSubmit={handleSubmit}>
                  <div
                    className="px-3"
                    style={{
                      maxHeight: "calc(100vh - 190px)",
                      overflowY: "scroll",
                      padding: "12px",
                      borderRadius: "9px 9px 0px 0px",
                      boxShadow: "0px 3px 12px #00000099",
                    }}
                  >
                    {getFormDataPublished()}
                  </div>
                </Form>
              )}
            </Col>
          </div>
        </Col>
      </DragDropContext>

      {!showRichTextModal ? null : (
        <div
          className={`w-100 parent h-100 position-fixed`}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setShowRichTextModal(false)
            updateInfo()
          }}
          style={{ inset: "0px", top: "0", bottom: "0", left: "0", right: "0", backgroundColor: "#000000b5" }}
        >
          {isBrowser() && document.getElementsByClassName("parent").length > 0 ? (
            <Rnd
              disableDragging={false}
              dragHandleClassName={"drag"}
              minWidth={324}
              minHeight={191}
              style={{ zIndex: 100 }}
              onClick={(e) => {
                e.stopPropagation()
              }}
              bounds=".parent"
              default={{ x: `50%`, y: `50%`, width: 324, height: 191 }}
              position={{ x: draggableHash.x, y: draggableHash.y }}
              size={{ width: draggableHash.width, height: draggableHash.height }}
              onDragStop={(e, d) => {
                setDraggableHash({ x: d.x, y: d.y, width: draggableHash.width, height: draggableHash.height })
              }}
              onResizeStop={(e, direction, ref, delta, position) => {
                setDraggableHash({ width: ref.style.width, height: ref.style.height, x: position.x, y: position.y })
              }}
            >
              {displayData()}
            </Rnd>
          ) : (
            ``
          )}
        </div>
      )}
      <DeleteModal
        show={Object.keys(deleteResourceObjData).length > 0}
        onHide={() => setDeleteResourceObjData({})}
        itemName={deleteResourceObjData?.name}
        action={() => {
          const newState = deleteResourceObjData.data
          newState.splice(deleteResourceObjData.index, 1)
          setDraggedData(!draggedData)
          deleteResource(deleteResourceObjData.id, "case-study-option", deleteResourceObjData.question_id, deleteResourceObjData.question_resource_id)
          setDeleteResourceObjData({})
        }}
      />
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default UpdateCaseStudy
