import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import sucesstick from "../images/svgs/sucesstick.svg"
import laptop from "../images/svgs/laptopimg.svg"
import logo from "../images/logo.png"

const browser = typeof window !== "undefined" && window

const Signupsucess = () => {
  const [user, setUser] = useState("")
  const [link, setLink] = useState("app.turient.io")

  useEffect(() => {
    if (browser && window.innerWidth > 900) {
      window.location.href = "/app/dashboard"
    }
    setUser(localStorage.getItem("firstname"))
  }, [])
  return (
    <div>
      <SEO title="Sucess: No mobileVersion" />
      <Row className="w-100" style={{ borderBottom: "1px solid #C6CFF4", paddingTop: "18px" }}>
        <Link to="/app/signup">
          <img height="60px" src={logo} style={{ paddingLeft: "20px" }} alt="logo.png" />
        </Link>
        <Link to="/app/signup">
          <p style={{ color: "#C6CFF4", fontSize: "32px", fontWeight: "bold", fontFamily: "CarinaPro", marginTop: "10px", marginLeft: "10px" }}>Turient</p>
        </Link>
      </Row>
      <div style={{ textAlign: "center", alignItems: "center", marginTop: "20%", marginBottom: "20%" }}>
        <img src={sucesstick} alt="sucesstick.svg" />
        <p style={{ color: "#C6CFF4", fontSize: "18px", paddingBottom: "20px" }}>
          {user} School of design
          <br /> Has been created successfully
        </p>
        <p style={{ color: "#C6CFF4", fontSize: "14px" }}>Please visit</p>
        <p
          style={{
            color: "#C6CFF4",
            fontSize: "14px",
            backgroundColor: "#303C54",
            border: "1px solid #707070",
            width: "auto",
            marginLeft: "18%",
            marginRight: "18%",
            padding: "10px",
          }}
        >
          app.turient.io
        </p>
      </div>
      <Row className="d-flex">
        <Col xs="3" sm="4" md="4">
          <img src={laptop} alt="laptopimg.svg" style={{ height: "120px", float: "left" }} />
        </Col>
        <Col xs="9" sm="8" md="8">
          <div style={{ float: "left" }}>
            <p style={{ color: "#C6CFF4", fontSize: "18px" }}>We are desktop only for now!</p>
            <p style={{ color: "#C6CFF4", fontSize: "10px", paddingRight: "10%" }}>
              Hey Trainer! At present there is only deksktop version no mobile version please go and signin in desktop at link <a>app.turient.io</a>{" "}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Signupsucess
