import React, { useEffect, useState, useCallback, useContext } from "react"
import { navigate } from "gatsby"
import debounce from "lodash/debounce"

import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import Dropdown from "react-bootstrap/Dropdown"
import Spinner from "react-bootstrap/Spinner"
import Image from "../custom/Image"
import { getRequest, postRequest, deleteRequest, putRequest } from "../../services/rest_service"

import SEO from "../../components/seo"
import DeleteModal from "../custom/DeleteModal"

import DragHandleIcon from "../../images/svgs/drag-handle-white.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import CheckIcon from "../../images/svgs/check-white.svg"
import ChevronCircleUp from "../../images/svgs/arrow-dropup-white-circle.svg"
import ChevronCircleDown from "../../images/svgs/arrow-dropdown-white-circle.svg"
import Minus from "../../images/svgs/minus.svg"
import Plus from "../../images/svgs/plus1.svg"
import EllipsisV from "../../images/svgs/threedots.svg"
import ExclamationTriangleYellow from "../../images/svgs/exclamation-triangle-yellow.svg"

import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_REFETCH_RESOURCES, SET_SELECTED_RESOURCE_TYPE } from "../../stores/sidebar_store/SidebarActions"

import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../stores/global_store/GlobalActions"

import StylesA from "../quizzes/quiz.module.scss"
import Styles from "../../modularscss/styles.module.scss"
import StylesB from "../courses/course.module.scss"
import StylesC from "../case-study/casestudy.module.scss"

const PublishPopQuizzes = (location) => {
  const [quiz, setQuiz] = useState("")
  const [questions, setQuestions] = useState([])
  const [questionsNext, setQuestionsNext] = useState("")
  const [nextQuestionsLoader, setNextQuestionsLoader] = useState(false)
  const [quizQuestionsFetched, setQuizQuestionsFetched] = useState(false)
  const [questionCreated, setQuestionCreated] = useState(true)
  const [questionToggle, setQuestionToggle] = useState({})

  const [deleteResourceData, setDeleteResourceData] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [questionOptions, setQuestionOptions] = useState({})
  const [optionCreated, setOptionCreated] = useState(true)

  const [questionsCount, setQuestionsCount] = useState(false)
  const [savedContent, setSavedContent] = useState(true)
  const [clickedPublish, setClickedPublish] = useState(false)

  const [publishLoader, setPublishLoader] = useState(false)

  const [sidebarState, sidebarDispatch] = useContext(SidebarContext)
  const [globalState, dispatch] = useContext(GlobalContext)

  useEffect(() => {
    fetchQuiz()
    fetchQuizQuestions()
  }, [location.quiz_id])

  async function fetchQuiz() {
    let res = await getRequest(`/quiz/${location.quiz_id}/`)
    if (res.success) {
      setQuiz(res.data)
      focusOnData("QuizName")
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function handleQuizName(name, value) {
    let formData = new FormData()
    formData.append(name, value)
    if (location.quiz_id !== undefined && value !== "") {
      let quiz_id = location.quiz_id
      let res = await putRequest("/quiz/" + quiz_id + "/", formData)
      if (res.success) {
        setSavedContent(true)
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      setSavedContent(true)
    }
  }

  async function fetchQuizQuestions(refetch = false) {
    setQuizQuestionsFetched(false)
    let res = await getRequest(`/quiz-question/?limit=20&quiz=${location.quiz_id}`)
    if (res.success) {
      setQuestionsCount(res.data.count)
      setQuestions(res.data.results)
      setQuestionsNext(res.data.next)
      setQuizQuestionsFetched(true)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function fetchNextQuizQuestions(next_url) {
    if (next_url) {
      setNextQuestionsLoader(true)
      let res = await getRequest(next_url)
      if (res.success) {
        let all_questions = [...questions]
        all_questions.concat(res.data.results)
        setQuestions(all_questions)
        setQuestionsNext(res.data.next)
        setNextQuestionsLoader(false)
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  async function fetchOptions(id, fetch = false, question_resource_id = "") {
    if (questionOptions["question_" + id] === undefined || fetch) {
      let res = await getRequest(`/question-option/?quiz_question=${id}`)
      if (res.success) {
        let all_options = { ...questionOptions }
        all_options["question_" + id] = res.data.results
        setQuestionOptions(all_options)
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  async function deleteResource(item) {
    if (item) {
      let url = item.quiz_question ? "question-option" : "quiz-question"
      let res = await deleteRequest(`/${url}/${item.id}/`)
      if (res.success) {
        if (url === "quiz-question") {
          let questions_data = [...questions]
          let deleted_question_index = questions_data.findIndex((element) => element.id === item.id)
          questions_data.splice(deleted_question_index, 1)
          setQuestions(questions_data)
          setQuestionsCount(questionsCount - 1)
        } else {
          let options_data = { ...questionOptions }
          let current_options = options_data["question_" + item.quiz_question]
          let deleted_option_index = current_options.findIndex((element) => element.id === item.id)
          current_options.splice(deleted_option_index, 1)
          handleQuestionIsValid(item.quiz_question, options_data, questions)
        }
        setDeleteResourceData({})
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  function handleClose() {
    setShowDeleteModal(false)
    setDeleteResourceData({})
  }

  async function createQuestion(value) {
    let formData = new FormData()
    formData.append("title", "sample question console")
    formData.append("quiz", location.quiz_id)
    formData.append("data_type", "0")
    formData.append("question_type", value)
    let res = await postRequest("/quiz-question/", formData)
    if (res.success) {
      setQuestions((prev) => {
        let all_questions = [...prev]
        let dummy = { ...res.data }
        dummy.is_valid_question = false
        all_questions = all_questions.concat(dummy)
        return all_questions
      })
      setQuestionCreated(true)
      handleQuestionToggle(res.data.id, res.data.question_type)
      let input = document.getElementById(res.data.id + `-question-input-id`)
      input && input.focus()
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  function handleQuestionIsValid(question_id, options_data, questions_data, updateQuestion = 1, filtered_data = -1, value = -1) {
    if (filtered_data < 0) {
      filtered_data = questions_data.findIndex((ques) => ques.id === question_id)
    }

    let isValid = false
    if (value !== -1) {
      isValid = value !== "" && value !== "sample question console" && value !== "0"
    } else {
      let data = options_data["question_" + question_id]
      if (!data || filtered_data < 0) return -1

      let correct_option_count = data.filter((option) => option.is_correct === true && option.name !== "" && option.name !== "sample option console")
      let optionIsNotEmpty = data.filter((option) => option.name !== "" && option.name !== "sample option console")
      let question = questions_data[filtered_data]
      if (
        (options_data["question_" + question_id].length >= 2 &&
          correct_option_count.length >= 1 &&
          data.length !== correct_option_count.length &&
          optionIsNotEmpty.length >= 2 &&
          question.title &&
          question.title !== "" &&
          question.title !== "sample question console") ||
        question.question_type === "2"
      ) {
        isValid = true
      }
    }
    if (updateQuestion === 1) {
      let dummy = [...questions_data]
      dummy[filtered_data].is_valid_question = isValid
      setQuestions(dummy)
    }
    return isValid
  }

  async function createOption(question) {
    let question_id = question.id
    let option_name = `sample option console`
    let formData = new FormData()
    formData.append("quiz_question", question_id)
    formData.append("name", option_name)
    let res = await postRequest("/question-option/", formData)
    if (res.success) {
      setQuestionOptions((prev) => {
        let options_data = { ...prev },
          index = "question_" + question_id
        if (!options_data[index]) {
          options_data[index] = []
        }
        options_data[index] = [...options_data[index], res.data]
        return options_data
      })
      setOptionCreated(true)
      let input = document.getElementById(res.data.id + `-question-option-input-id`)
      input && input.focus()
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }
  async function updateQuestion(name = null, value = null, question_id, questionsData, optionsData, index = null) {
    if (name === "title") {
      value = value.trim()
      if (value === "") value = "sample question console"
    }

    let formData = new FormData()
    formData.append(name, value)
    if (question_id !== undefined && value) {
      let res = await putRequest("/quiz-question/" + question_id + "/", formData)
      if (res.success) {
        setSavedContent(true)
        if (questionsData && index >= 0) {
          setQuestions((prev) => {
            let questions_data = [...prev],
              isValid = res.data.is_valid_question
            questions_data[index][name] = value
            if (["title"].includes(name)) {
              if (optionsData === null) {
                optionsData = { ...questionOptions }
              }
              if (optionsData["question_" + question_id] !== undefined && questions_data[index].question_type === "1") {
                value = -1
              }
              isValid = handleQuestionIsValid(question_id, optionsData, questions_data, 0, index, value)
              if (isValid === -1) {
                return questions_data
              }
            }
            questions_data[index]["is_valid_question"] = isValid
            return questions_data
          })
        }
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      setSavedContent(true)
    }
  }
  async function updateOption(name, value, option_id, questionsData, optionsData, index = null, question_id = null) {
    if (name === "name") {
      value = value.trim()
      if (value === "") value = "sample option console"
    }

    let formData = new FormData()
    formData.append(name, value)
    if (option_id !== undefined && value !== undefined && value !== "") {
      let res = await putRequest("/question-option/" + option_id + "/", formData)
      if (res.success) {
        setSavedContent(true)
        if (optionsData && questionsData && index >= 0 && question_id) {
          setQuestionOptions((prev) => {
            let options_data = { ...prev },
              question_index = "question_" + question_id
            if (!options_data[question_index]) {
              options_data[question_index] = []
            }
            let questions_data = [...questionsData]
            let option = options_data[question_index][index]
            option[name] = value
            if (name === "is_correct" || name === "name") {
              handleQuestionIsValid(question_id, options_data, questions_data)
            }
            return options_data
          })
        }
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      setSavedContent(true)
    }
  }
  function checkOptionCount(index, datum, question_id) {
    let all_option = questionOptions["question_" + question_id]
    let correct_option_count = all_option.filter((option_update) => option_update.is_correct === true)
    if (all_option.length - 1 === correct_option_count.length && !datum.is_correct === true) {
      let error_messages = "Total options and correct answer options  cannot be same"
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error_messages } })
    } else {
      handleUpdateOption(index, "is_correct", !datum.is_correct, datum.id, question_id)
    }
  }

  function sendQuery(name, value, item_id, type, questionsData, optionsData, index, question_id) {
    if (type === "quiz") {
      handleQuizName(name, value)
    } else if (type === "question") {
      updateQuestion(name, value, item_id, questionsData, optionsData, index)
    } else {
      updateOption(name, value, item_id, questionsData, optionsData, index, question_id)
    }
  }

  const delayedQuery = useCallback(
    debounce(
      (name, value, item_id, type, questionsData, optionsData, index, question_id) =>
        sendQuery(name, value, item_id, type, questionsData, optionsData, index, question_id),
      1000
    ),
    []
  )

  function handleUpdateQuestion(index, name = null, value = null, question_id, on_blur = null) {
    if (name === "title") {
      if (on_blur === null) {
        delayedQuery(name, value, question_id, "question", questions, questionOptions, index)
      } else {
        updateQuestion(name, value, question_id, questions, questionOptions, index)
      }
    } else {
      updateQuestion(name, value, question_id, questions, questionOptions, index)
    }
  }

  function handleUpdateOption(index, name, value, option_id, question_id, blur_type = null) {
    if (name === "name") {
      if (blur_type === null) {
        delayedQuery(name, value, option_id, "option", questions, questionOptions, index, question_id)
      } else {
        updateOption(name, value, option_id, questions, questionOptions, index, question_id)
      }
    } else {
      updateOption(name, value, option_id, questions, questionOptions, index, question_id)
    }
  }

  function handleQuestionToggle(question_id, type, fetch_options = true) {
    if (type === "1" && fetch_options) {
      fetchOptions(question_id)
    }
    let toggle_data = { ...questionToggle }
    toggle_data[question_id] = !toggle_data[question_id]
    setQuestionToggle(toggle_data)
  }

  function showQuizQuestions() {
    if (questions.length > 0) {
      return questions.map((datum, index) => {
        let question_error_class = datum.is_valid_question
        let toggle_class = questionToggle[datum.id]
        return (
          <React.Fragment key={datum.id + `-question-key-` + index}>
            <div className={`br-8px ${StylesA.question_div} ${question_error_class == true ? `border_on_hover` : `border_1px_904744`} mb-2`}>
              <div className={`${StylesA.question_name_div} w-100 py-1 ${toggle_class ? `border-bottom-0` : ``}`}>
                <div id={index + 1} className={`col-12 d-inline-flex`}>
                  <div
                    className={`${StylesA.drag_handle_div} px-2 py-1 align-items-center`}
                    onClick={(e) => handleQuestionToggle(datum.id, datum.question_type)}
                  >
                    <img src={DragHandleIcon} alt={`question-drag-handle_${datum.id.toString()}`} className={`my-auto`} />
                    <p className={`mb-0 pl-2 white-text fs-20px`}>{index + 1}</p>
                  </div>
                  <input
                    type="text"
                    name="title"
                    id={datum.id + `-question-input-id`}
                    defaultValue={datum ? (datum.title === "sample question console" ? `` : datum.title) : ""}
                    onChange={(e) => {
                      setSavedContent(false)
                      handleUpdateQuestion(index, e.target.name, e.target.value, datum.id)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (datum.title !== e.target.value) {
                        handleUpdateQuestion(index, e.target.name, e.target.value, datum.id, "on_blur")
                      }
                    }}
                    placeholder={"Enter Question Title"}
                    className={`${StylesA.question_title_input} form-control px-3`}
                    required
                  />
                  <div className="d-flex align-items-center">
                    <Image
                      onClick={(e) => handleQuestionToggle(datum.id, datum.question_type)}
                      src={toggle_class ? ChevronCircleUp : ChevronCircleDown}
                      alt={"arrow for collapse and expand"}
                      className={`${StylesA.arrow_circle} mr-2`}
                    />
                    <Image
                      src={DeleteIcon}
                      onClick={(e) => {
                        setDeleteResourceData(datum)
                        setShowDeleteModal(true)
                      }}
                      alt={`question delete icon`}
                      className={`my-auto mr-3 pointer object-fit-contain h-18px`}
                    />
                  </div>
                </div>
              </div>
              {toggle_class ? (
                <div className={`${StylesA.options_section} py-3 pl-4 pr-3`}>
                  {showOptionButtons(questionOptions["question_" + datum.id], datum)}
                  <div className={`py-2 d-flex bg-transparent`}>
                    <div className={`text-center`} style={{}}>
                      <button
                        disabled={!optionCreated}
                        onClick={(e) => {
                          setOptionCreated(false)
                          createOption(datum)
                        }}
                        className={`${StylesB.add_button} border_on_hover`}
                      >
                        {optionCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                        Add Option
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ``
              )}
            </div>
            {!question_error_class && clickedPublish ? (
              <Col xs="12" sm="12" md="12" lg="12" className={`mt-2 mb-2 px-0 `}>
                <div className="d-inline-flex px-3 py-0 br-23px">
                  <Image className="mt-1 mr-1 h-14px" src={ExclamationTriangleYellow} />
                  <p className="mb-0 fs-14px color-F4F411">The above question is not valid</p>
                </div>
              </Col>
            ) : null}
          </React.Fragment>
        )
      })
    } else {
      return <p className={`fs-14px white-text m-0 mb-5px text-center`}>No questions created yet!</p>
    }
  }

  function showOptionButtons(data, question_data) {
    let question_id = question_data.id
    if (data) {
      if (data.length > 0) {
        return (
          <React.Fragment>
            <div className={`col-12 bg-transparent pt-2`}>
              {data.map((datum, index) => (
                <Col lg="12" md="12" sm="12" xs="12" key={datum.id + `-option-key-` + index + `-${question_id}`} className="px-0 d-flex mb-2 tab-ignore">
                  <div className={`${StylesA.option_div} border_on_hover`}>
                    <div className={`${StylesA.drag_handle_div} px-2 py-1`}>
                      <img src={DragHandleIcon} alt={`option-drag-handle_${datum.id.toString()}`} className={`my-auto`} />
                    </div>
                    <input
                      type="text"
                      name="name"
                      id={datum.id + `-question-option-input-id`}
                      defaultValue={datum.name === "sample option console" ? `` : datum.name}
                      onChange={(e) => {
                        setSavedContent(false)
                        handleUpdateOption(index, e.target.name, e.target.value, datum.id, question_id)
                      }}
                      onBlur={(e) => {
                        e.preventDefault()
                        if (e.target.value !== datum.name) {
                          handleUpdateOption(index, e.target.name, e.target.value, datum.id, question_id, "on_blur")
                        }
                      }}
                      placeholder={"Enter Option text"}
                      className={`${StylesA.question_title_input} form-control px-3`}
                      required
                    />
                    <div
                      className={`${datum.is_correct ? StylesA.correct_answer_check : StylesA.wrong_answer_check} pointer`}
                      onClick={(e) => {
                        checkOptionCount(index, datum, question_id)
                      }}
                    >
                      <Image src={CheckIcon} alt={`check_${datum.id.toString()}`} className={`m-auto px-2`} />
                    </div>
                  </div>
                  <div
                    className={`${StylesA.question_delete_div} pointer ml-3`}
                    onClick={(e) => {
                      setDeleteResourceData(datum)
                      setShowDeleteModal(true)
                    }}
                  >
                    <Image alt={`delete_option_${datum.id.toString()}`} src={DeleteIcon} className="mb-0 w-15px h-15px" />
                  </div>
                </Col>
              ))}
            </div>
          </React.Fragment>
        )
      } else {
        return <p className={`fs-14px white-text m-0 mb-5px text-center`}>No options created yet!</p>
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function handleChangeQuiz(e, name = null, value = null, type = "") {
    let current_quiz = { ...quiz }
    if (name === "quiz_time" && value === "") {
      current_quiz[name] = ""
    } else {
      value = name === "quiz_time" ? parseInt(value) : value
      current_quiz[name] = value
    }
    setQuiz(current_quiz)
    delayedQuery(name, value, location.quiz_id, "quiz")
  }

  async function handleSubmitQuiz() {
    let check_error = questions.filter((ques) => ques.is_valid_question === false)

    if (
      location.quiz_id !== undefined &&
      check_error.length === 0 &&
      quiz.name !== "" &&
      quiz.name !== "sample quiz console" &&
      quiz.quiz_time > 0 &&
      questions.length > 0
    ) {
      let quiz_id = location.quiz_id
      sidebarDispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Pop Quizzes" })
      let res = await postRequest("/quiz/" + quiz_id + "/publish_quiz/", {})
      if (res.success) {
        if (res.data.error) {
          dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.data.error } })
        }
        if (res.data.success) {
          fetchQuizQuestions(true)
          setQuestionToggle({})
          setQuestionOptions({})
          dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "success", title: `Your pop quiz got published Sucessfully` } })
          sidebarDispatch({ type: SET_REFETCH_RESOURCES, payload: true })
          if (window.opener) {
            const evt = new CustomEvent("my-refresh-resources", {
              bubbles: true,
              detail: "pop-quiz",
            })
            window.opener?.document && window.opener.document.dispatchEvent(evt)
          }
        }
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      let error_messages = ""
      if (quiz.name === "" || quiz.name === "sample quiz console") {
        error_messages = "please provide valid quiz name"
      } else if (quiz.quiz_time < 1) {
        error_messages = "Please provide quiz duration"
      } else if (questions.length < 1) {
        error_messages = "Please add questions"
      } else {
        error_messages = "Your questions are not valid"
      }
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error_messages } })
    }
    setPublishLoader(false)
  }

  async function deletePopQuiz(id) {
    let res = await deleteRequest(`/quiz/` + id + "/")
    if (res.success) {
      navigate(`/app/pop-quizzes`)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  const changeQuizTime = (e, type, on_blur) => {
    let current_quiz = { ...quiz }
    let quiz_time_div = document.getElementById("quiz_time")
    if (quiz_time_div) {
      let quiz_time_div_value = quiz_time_div.value
      if (type === "add") {
        current_quiz.quiz_time = parseInt(current_quiz.quiz_time) + 1
      } else {
        if (current_quiz.quiz_time > 1) {
          current_quiz.quiz_time = parseInt(current_quiz.quiz_time) - 1
        }
      }
    }
    setQuiz(current_quiz)
    if (on_blur) {
      handleQuizName("quiz_time", current_quiz.quiz_time)
    } else {
      delayedQuery("quiz_time", current_quiz.quiz_time, location.quiz_id, "quiz")
    }
  }

  const focusOnData = (id) => {
    let data = document.getElementById(id)
    data && data.focus()
  }

  return (
    <div>
      <SEO title="Pop Quiz Publish" />
      <Col lg="12" md="12" sm="12" xs="12" className={`px-4 d-inline-flex float-left`}>
        <div id="error-div-for-option" className="position-absolute text-white" style={{ top: "0px", right: "0px", zIndex: "2" }}></div>
        <div id={`scrollable-div`} className={`px-4 pt-0 float-left w-100`}>
          <div className={`w-100 d-inline-flex align-items-center mb-2 py-2 ${StylesB.course_creation_header}`} style={{ justifyContent: "left" }}>
            <p className="mb-0 py-2 px-3 font-weight-bold br-9009px white-text fs-18px min-w-max-content line-height-1" id="coursename">
              {(quiz && quiz?.name?.length === 0) || quiz?.name === "sample quiz console" ? "Creating Pop Quiz" : quiz?.name}
            </p>
            <Dropdown className="breakout-groups pointer mr-3 ml-auto float-right">
              <Dropdown.Toggle className="border-none p-0 shadow-none bg-transparent" id="dropdown-basic">
                <div>
                  <Image src={EllipsisV} className={`white-text object-fit-contain h-20px`} />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className={`br-9px breakout-groups ${StylesB.min_fit_width}`}>
                <Dropdown.Item
                  onClick={(e) => {
                    e.preventDefault()
                    deletePopQuiz(quiz.id)
                  }}
                  className={`${StylesB.dropitem} text-white fs-14px px-2 py-1 d-inline-flex min-w-max-content`}
                >
                  &nbsp;Cancel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={`w-100 float-left mb-5 white-text bg-212C42 br-9px`}>
            <Col xs="12" sm="12" md="12" className={`px-0 pt-3`}>
              <div className={`w-100 px-0`}>
                <div className="w-100 px-3 overflow-y-scroll bg-212C42" style={{ maxHeight: "calc(100vh - 250px)" }}>
                  <div className={`d-inline-flex w-100`}>
                    <h5 className={`py-3 d-flex align-items-center mb-0 justify-content-between ml-auto white-text`}>
                      <div className="float-right d-flex align-items-center white-text">
                        <p className={`mb-0 pr-3 fs-18px bold-500`} id={"quiz-timer"}>
                          Set Timer
                        </p>
                        <div className="px-0 float-left d-flex align-items-center br-2px bg-303C54">
                          <Image
                            src={Minus}
                            className="mx-2 pointer w-15px h-18px"
                            onClick={(e) => {
                              changeQuizTime(e, "subtract")
                            }}
                            onBlur={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              changeQuizTime(e, "subtract", "on_blur")
                            }}
                          />
                          <p className="float-left mb-0 d-flex align-items-center pr-2 py-1 fs-16px bg-586886 border-left-1e2643 border-right-1e2643">
                            <input
                              className={`w-32px bg-586886 text-white text-center text-right h-100 border-0 number-input ${Styles.primary_button}`}
                              type="number"
                              name="quiz_time"
                              value={quiz.quiz_time === "" ? "" : parseInt(quiz?.quiz_time ?? 5)}
                              min="1"
                              step="1"
                              id="quiz_time"
                              onChange={(e) => handleChangeQuiz(e, e.target.name, e.target.value)}
                              onBlur={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                handleQuizName(e.target.name, e.target.value)
                              }}
                              placeholder="0"
                              required
                            />{" "}
                            Min
                          </p>
                          <Image
                            src={Plus}
                            className="mx-2 pointer w-15px h-18px"
                            onClick={(e) => {
                              changeQuizTime(e, "add")
                            }}
                            onBlur={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              changeQuizTime(e, "add", "on_blur")
                            }}
                          />
                        </div>
                      </div>
                    </h5>
                  </div>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="border_on_hover pl-0 w-100"
                    style={{
                      backgroundColor: "#303C54",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      border: "1px solid #303C54",
                      marginBottom: "12px",
                    }}
                  >
                    <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                      <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                        <InputGroup.Text
                          className="border-0 d-flex justify-content-center"
                          style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                          id="basic-addon1"
                          onClick={(e) => focusOnData("QuizName")}
                        >
                          Pop Quiz Name:
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className={`border-0 d-flex justify-content-center shadow-none ${StylesC.cs_create} text-white`}
                        style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px" }}
                        type="text"
                        name="name"
                        autoFocus={true}
                        defaultValue={quiz.name === "sample quiz console" ? `` : quiz.name}
                        onChange={(e) => {
                          setSavedContent(false)
                          handleChangeQuiz(e, e.target.name, e.target.value)
                        }}
                        onBlur={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          handleQuizName(e.target.name, e.target.value)
                        }}
                        id="QuizName"
                        placeholder={"Enter Pop Quiz Title"}
                        required
                      />
                    </InputGroup>
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12" className="py-3">
                    {showQuizQuestions()}
                    {!nextQuestionsLoader && questionsNext ? (
                      <Col md="auto" className={`d-inline-flex w-100 my-2`}>
                        <Button
                          variant="none"
                          onClick={(e) => {
                            e.preventDefault()
                            fetchNextQuizQuestions(questionsNext)
                          }}
                          className={`${Styles.font_12} text-center px-3 py-2 mx-auto`}
                          style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                        >
                          See More
                        </Button>
                      </Col>
                    ) : (
                      ""
                    )}
                    <div className={`justify-content-center pt-2 d-flex bg-transparent`} style={{ borderRadius: "9px" }}>
                      <div className={` text-center d-inline-flex align-items-center`}>
                        {/* <p className={`${Styles.font_14} m-0`} style={{ color: `#D4DCF7` }}>Drag and drop questions here <span className={`font-weight-bold mx-3`} style={{ fontSize: `16px` }} >or</span> </p> */}
                        <button
                          disabled={!questionCreated}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setQuestionCreated(false)
                            createQuestion("1")
                          }}
                          className={`${StylesB.add_button} border_on_hover fs-14px`}
                        >
                          {questionCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                          Add question
                        </button>
                      </div>
                    </div>
                  </Col>
                </div>
                <Col
                  className="px-3 py-2 d-flex align-items-center justify-content-between"
                  style={{ borderTop: "1px solid #63666f", backgroundColor: "#212C42", borderRadius: "0px 0px 9px 9px", boxShadow: "0px -3px 12px #00000099" }}
                >
                  <button
                    disabled={publishLoader}
                    onClick={(e) => {
                      setPublishLoader(true)
                      handleSubmitQuiz(e)
                      setClickedPublish(true)
                    }}
                    className={`${StylesB.add_button_large} border_on_hover fs-14px d-flex align-items-center`}
                  >
                    {!publishLoader ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                    Publish
                  </button>
                  <div className="d-flex align-items-center">
                    {!savedContent ? (
                      <Spinner animation="border" variant="secondary" size="sm" className="mr-1" />
                    ) : (
                      <Image src={CheckIcon} alt={`changes_saved_check`} className={`ml-auto mb-0 mr-1 object-fit-contain h-12px`} />
                    )}
                    <p className="mb-0 fs-14px">All changes are saved</p>
                  </div>
                </Col>
              </div>
            </Col>
          </div>
        </div>
      </Col>
      <DeleteModal
        show={Object.keys(deleteResourceData).length > 0}
        onHide={handleClose}
        itemName={deleteResourceData?.name}
        action={() => {
          deleteResource(deleteResourceData)
        }}
      />
    </div>
  )
}

export default PublishPopQuizzes
