import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import FormControl from "react-bootstrap/FormControl"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Modal from "react-bootstrap/Modal"
import Dropdown from "react-bootstrap/Dropdown"
import FormGroup from "react-bootstrap/FormGroup"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSearch,
  faPlus,
  faHome,
  faTrashAlt,
  faChevronRight,
  faChevronLeft,
  faUpload,
  faGripLines,
  faEllipsisV,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

import NavWithTriangles from "../../services/nav_with_triangle"

import quizicon from "../../images/svgs/quizicon.svg"
import Close from "../../images/svgs/close.svg"
import ChevronCircleUp from "../../images/svgs/ChevronCircleUp.svg"
import ChevronCircleDown from "../../images/svgs/ChevronCircleDown.svg"

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  outline: "none",
  padding: grid,
  margin: `0`,
  borderRadius: "20px",
  textAlign: "center",
  color: "#fff",

  // change background colour if dragging
  background: isDragging ? "transparent" : "#12172C",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "transparent" : "#12172c",
  padding: grid,
})

const ViewPollQuestions = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [questions, setQuestions] = useState({})
  const [questionsNext, setQuestionsNext] = useState({})
  const [questionsFetched, setQuestionsFetched] = useState({})
  const [allQuestionsFetched, setAllQuestionsFetched] = useState(false)
  const [quizQuestionsFetched, setQuizQuestionsFetched] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  const [collapse, setCollapse] = useState(false)

  const [draggedData, setDraggedData] = useState(false)

  const [deleteResourceData, setDeleteResourceData] = useState({})

  const [questionCreated, setQuestionCreated] = useState(false)

  const [questionOptions, setQuestionOptions] = useState({})
  const [questionOptionsNext, setQuestionOptionsNext] = useState({})
  const [optionsFetched, setOptionsFetched] = useState(false)
  const [toggleQuestion, setToggleQuestion] = useState(false)
  const [questionOptionsFetched, setQuestionOptionsFetched] = useState({})

  const [currentQuestionId, setCurrentQuestionId] = useState("")

  const [toggledOption, setToggledOption] = useState(false)

  const [optionCreated, setOptionCreated] = useState(false)
  const [optionCreatedX, setOptionCreatedX] = useState(false)

  const [usingDropdown, setUsingDropdown] = useState(false)

  const [quiz, setQuiz] = useState({})

  const [optionUpdated, setOptionUpdated] = useState(false)

  useEffect(() => {
    fetchAllQuestions(searchValue ? searchValue : "")
  }, [searchValue, quiz])

  useEffect(() => {
    fetchQuizQuestions()
    fetchQuiz()
  }, [])

  useEffect(() => {
    let tabignore_divs = document.getElementsByClassName("tab-ignore")
    for (let i = 0; i < tabignore_divs.length; i++) {
      tabignore_divs[i].tabIndex = "-1"
    }
  }, [optionsFetched, currentQuestionId, document.getElementsByClassName("tab-ignore").length])

  async function fetchQuiz() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/quizzes/basic/${location.quiz_id}/`, { headers: login_credentials }).then((res) => {
      setQuiz(res.data)
    })
  }

  async function fetchAllQuestions(searchValue) {
    if (quiz && Object.keys(quiz).length > 0) {
      questionsFetched["all"] = false
      setAllQuestionsFetched(false)
      let search = searchValue === "" ? "" : `&search=${searchValue}`
      let filter = quiz.quiz_level != "3" ? `&question_level=${quiz.quiz_level}` : ``
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-questions-list/?paginate_by=15${search}${filter}&data_type=1`, { headers: login_credentials })
        .then((res) => {
          questions["all"] = res.data.results
          questionsNext["all"] = res.data.next
          questionsFetched["all"] = true
          setAllQuestionsFetched(true)
        })
    }
  }

  async function fetchNextAllQuestions(next_url) {
    if (next_url) {
      // questionsFetched['all'] = false
      setAllQuestionsFetched(false)
      axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
        var next_all_res = questions["all"]
        if (res.data.results.length > 0) {
          for (var rs = 0; rs <= res.data.results.length - 1; rs++) {
            next_all_res.push(res.data.results[rs])
          }
          questions["all"] = next_all_res
        }
        questionsNext["all"] = res.data.next
        // questionsFetched['all'] = true
        setAllQuestionsFetched(true)
      })
    }
  }

  async function fetchQuizQuestions(refetch = false) {
    if (!refetch) {
      questionsFetched["quiz"] = false
    }
    setQuizQuestionsFetched(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-questions-resources-list/?paginate_by=15&quiz=${location.quiz_id}`, { headers: login_credentials })
      .then((res) => {
        questions["quiz"] = res.data.results
        questionsNext["quiz"] = res.data.next
        questionsFetched["quiz"] = true
        setQuizQuestionsFetched(true)
      })
  }

  async function fetchNextQuizQuestions(next_url) {
    // questionsFetched['quiz'] = false
    setQuizQuestionsFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_template_quizQuestions = questions["quiz"]
      if (res.data.results.length > 0) {
        for (var md = 0; md <= res.data.results.length - 1; md++) {
          next_template_quizQuestions.push(res.data.results[md])
        }
        questions["quiz"] = next_template_quizQuestions
      }
      questionsNext["quiz"] = res.data.next
      // questionsFetched['quiz'] = true
      setQuizQuestionsFetched(true)
    })
  }

  async function fetchOptions(id, fetch = false, question_resource_id = "") {
    if (questionOptions["question_" + id] === undefined || fetch) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-question-options-list/${id}/?paginate_by=6`, { headers: login_credentials })
        .then((res) => {
          questionOptions["question_" + id] = res.data.results
          questionOptionsNext["question_" + id] = res.data.next
          questionOptionsFetched["question_" + id] = true
          setCurrentQuestionId(id)
          setOptionsFetched(true)
          if (fetch && res.data.results.length > 0) {
            var index2 = questions["quiz"].findIndex((res) => res.id === question_resource_id)
            let is_valid = questions["quiz"][index2].question_data.is_valid_question
            questions["quiz"][index2].is_valid_question = res.data.results[0].is_valid_question
            questions["quiz"][index2].question_data.is_valid_question = res.data.results[0].is_valid_question
            let is_valid2 = questions["quiz"][index2].question_data.is_valid_answers
            questions["quiz"][index2].is_valid_answers = res.data.results[0].is_valid_answers
            questions["quiz"][index2].question_data.is_valid_answers = res.data.results[0].is_valid_answers
            if (is_valid != res.data.results[0].is_valid_question || is_valid2 != res.data.results[0].is_valid_answers) {
              setOptionUpdated(true)
            }
          } else if (fetch && res.data.results.length === 0) {
            var index2 = questions["quiz"].findIndex((res) => res.id === question_resource_id)
            let is_valid2 = questions["quiz"][index2].question_data.is_valid_answers
            questions["quiz"][index2].is_valid_answers = "optionsDoesNotExist"
            questions["quiz"][index2].question_data.is_valid_answers = "optionsDoesNotExist"
            if (is_valid2 != "optionsDoesNotExist") {
              setOptionUpdated(true)
            }
          }
        })
        .catch((error) => {
          setOptionsFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      if (currentQuestionId != id) {
        setCurrentQuestionId(id)
      } else {
        setCurrentQuestionId("")
      }
      setOptionsFetched(true)
      setToggleQuestion(!toggleQuestion)
    }
  }

  function openNav() {
    let side_div_width = document.getElementById("mySidepanel").offsetWidth
    if (side_div_width > 250) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  function deleteResource(id, type = "questions-resources", question_id = "", question_resource_id = "") {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/` + id + "/", { headers: DefaultHeader })
      .then((res) => {
        setDraggedData(!draggedData)
        if (type === "options") {
          setOptionUpdated(false)
          // fetchOptions(question_id,true,question_resource_id)
          fetchQuizQuestions(true)
        }
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  async function handleDraggedItemSequence(currentItem, type, allowCreationOfOptions = true) {
    if (currentItem) {
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      formData.append("question_id", type === "update" && currentItem.question_data ? currentItem.question_data.id : currentItem.id)
      formData.append("question_resource_type", "question")
      if (type === `create`) {
        formData.append("add_to_quiz", location.quiz_id)
      }
      let url = type === `create` ? `/questions-resources/create/` : `/questions-resources/${currentItem.id}/`
      if (type === `update`) {
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + url, formData, { headers: DefaultHeader })
          .then((res) => {})
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()
              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      } else {
        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + url, formData, { headers: DefaultHeader })
          .then((res) => {
            currentItem.id = res.data.id
            currentItem.question_data = res.data.question_data
            currentItem.question_id = res.data.question_id
            currentItem.question_resource_type = res.data.question_resource_type
            currentItem.sequence = res.data.sequence
            delete currentItem["title"]
            setDraggedData(!draggedData)
            if (allowCreationOfOptions) {
              setOptionCreated(false)
              createOption(questionOptions["question_" + currentItem.question_data.id], currentItem.question_data, true)
            }
            setQuestionCreated(true)
            if (document.getElementById(res.data.id + `-question-input-id`)) document.getElementById(res.data.id + `-question-input-id`).focus()
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()
              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    } else {
    }
  }

  function onDragEnd(result) {
    // dropped outside the lis
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    if (result.type === "questionsDraggable") {
      const itemSubItemMap = questions

      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      const sourceSubItems = itemSubItemMap[sourceParentId]
      const destSubItems = itemSubItemMap[destParentId]

      let newItems = questions

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(sourceSubItems, sourceIndex, destIndex)

        let questionsData = reorderedSubItems[destIndex]
        let currentData = sourceSubItems
        if (destParentId === "quiz") {
          if (destIndex === 0) {
            questionsData.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
          } else if (destIndex === currentData.length - 1) {
            questionsData.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
          } else {
            if (result.source.index > destIndex) {
              questionsData.sequence = ((parseFloat(currentData[destIndex - 1].sequence) + parseFloat(currentData[destIndex].sequence)) / 2).toFixed(20)
            } else if (result.source.index < destIndex) {
              questionsData.sequence = ((parseFloat(currentData[destIndex].sequence) + parseFloat(currentData[destIndex + 1].sequence)) / 2).toFixed(20)
            }
          }
        }
        setDraggedData(!draggedData)
        if (destParentId === "quiz") {
          handleDraggedItemSequence(questionsData, "update")
        }
        questions[sourceParentId] = reorderedSubItems
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)
        draggedItem.sequence = destIndex
        if (destParentId === "quiz") {
          if (destIndex === 0 && destSubItems.length === 0) {
            draggedItem.sequence = destIndex
          } else if (destIndex === 0) {
            if (draggedItem) {
              draggedItem.sequence = (parseFloat(destSubItems[destIndex].sequence) - 10).toFixed(20)
            }
          } else if (destIndex === destSubItems.length) {
            draggedItem.sequence = (parseFloat(destSubItems[destIndex - 1].sequence) + 10).toFixed(20)
          } else {
            draggedItem.sequence = ((parseFloat(destSubItems[destIndex].sequence) + parseFloat(destSubItems[parseFloat(destIndex) - 1].sequence)) / 2).toFixed(
              20
            )
          }
          setOptionsFetched(false)
          fetchOptions(draggedItem.id)
          handleDraggedItemSequence(draggedItem, "create", false)
        } else {
          draggedItem.dragged_id = draggedItem.id
          draggedItem.id = draggedItem.question_data.id
          draggedItem.title = draggedItem.question_data.title
          delete draggedItem["question_data"]
          delete draggedItem["question_id"]
          delete draggedItem["question_resource_type"]
          deleteResource(draggedItem.dragged_id, "questions-resources")
        }

        let newDestSubItems = destSubItems
        newDestSubItems.splice(destIndex, 0, draggedItem)
        questions[sourceParentId] = newSourceSubItems
        questions[destParentId] = newDestSubItems
      }
    }
  }

  function showQuestions() {
    let logo = quizicon
    let data = []
    data = questions["all"]
    data = data ? data : []
    let next_url = questionsNext["all"]
    return (
      <Droppable droppableId={`all`} type={`questionsDraggable`}>
        {(provided, snapshot) => (
          <div
            className={`bg-transparent resources ${collapse ? `${Styles.opacity_0} p-0` : `${Styles.opacity_1}`}`}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {data.map((item, index) => (
              <Draggable key={item.id + `-question-key` + index} draggableId={`${item.id.toString()}-question-id-${index.toString()}`} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`w-50 mt-3 px-1 bg-transparent py-0 float-left`}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <div className={`resource-card-bg d-flex`} style={{ color: `#C6CFF4`, position: `relative`, background: "#3d4d7e" }}>
                      <div>
                        <div className={`position-absolute`} style={{ backgroundColor: `#6D77D9`, borderRadius: `10px`, width: `100%`, height: `100%` }}>
                          <div className={`d-inline-flex align-items-center`} style={{ verticalAlign: `middle` }}>
                            <img
                              src={logo}
                              alt={"question"}
                              className={`mb-0 mt-2 ml-2 float-left position-absolute`}
                              style={{ objectFit: `contain`, height: `24px`, left: 0, top: 0 }}
                            />
                            <span
                              className={`float-right px-2 mt-2 mr-2 position-absolute`}
                              style={{ backgroundColor: `#00000080`, boxShadow: `0px 3px 6px #00000029`, borderRadius: `13px`, fontSize: `12px`, right: 0 }}
                            >
                              {" "}
                              Question
                            </span>
                          </div>
                        </div>
                        <div className={`${item.thumbnail_url ? Styles.img_bg : ``}`}></div>
                        {item.title && item.title.length > 35 ? (
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip className="event-tooltip" id={`tooltip-${item.title}`}>
                                {item.title}
                              </Tooltip>
                            }
                          >
                            <p
                              className={`elipsis-2 text-center w-100 px-2 pb-1 mt-auto position-absolute ${Styles.font_12} ${Styles.b_0}`}
                              style={{ paddingTop: `5px`, margin: `0px`, fontWeight: "bold", maxHeight: `42px` }}
                            >
                              {item.title}
                            </p>
                          </OverlayTrigger>
                        ) : (
                          <p
                            className={`elipsis-2 text-center w-100 px-2 pb-1 mt-auto position-absolute ${Styles.font_12} ${Styles.b_0}`}
                            style={{ paddingTop: `5px`, margin: `0px`, fontWeight: "bold", WebkitBoxOrient: "vertical" }}
                          >
                            {item.title}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {next_url && Object.keys(next_url).length > 0 ? (
              <Col md="auto" className={`align-self-center text-center d-inline-flex w-100 justify-content-center`}>
                <Button
                  variant="none"
                  onClick={(e) => {
                    e.preventDefault()
                    fetchNextAllQuestions(next_url)
                  }}
                  className={`${Styles.font_12} text-center px-3 py-2 mt-2`}
                  style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                >
                  See More
                </Button>
              </Col>
            ) : (
              ``
            )}
          </div>
        )}
      </Droppable>
    )
  }

  function handleClose() {
    setDeleteResourceData({})
  }

  function deleteQuestionModal() {
    if (deleteResourceData && Object.keys(deleteResourceData).length > 0) {
      return (
        <Modal show={deleteResourceData && Object.keys(deleteResourceData).length > 0} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this question <span className={`font-weight-bold`}>{deleteResourceData.question_data.display_name}</span>
          </Modal.Body>
          <Modal.Footer>
            <FontAwesomeIcon
              onClick={() => {
                const newState = questions["quiz"]
                newState.splice(deleteResourceData.index, 1)
                setDraggedData(!draggedData)
                deleteResource(deleteResourceData.id, "questions-resources")
                setDeleteResourceData({})
              }}
              icon={faTrashAlt}
              className={`pointer`}
              style={{ width: `20px`, height: `20px`, color: `black` }}
            />
          </Modal.Footer>
        </Modal>
      )
    }
  }

  function createQuestion(value) {
    let question = {}
    question["question_type"] = value
    question["title"] = "sample question " + (questions["quiz"].length + 1).toString()
    question.data_type = "1"
    var formData = new FormData()
    for (var k in question) {
      formData.append(k, question[k])
    }
    if (question.id != undefined) {
    } else {
      var question_id = question.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/questions/create/", formData, { headers: login_credentials })
        .then((res) => {
          let currentQuestion = {}
          currentQuestion.id = res.data.id
          currentQuestion.title = res.data.title
          if (questions["quiz"].length > 0) {
            currentQuestion.sequence = parseInt(questions["quiz"][questions["quiz"].length - 1].sequence) + 10
          } else {
            currentQuestion.sequence = parseInt(0)
          }
          setOptionsFetched(false)
          fetchOptions(res.data.id)
          handleDraggedItemSequence(currentQuestion, "create")
          questions["quiz"] = questions["quiz"].concat(currentQuestion)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function createOption(data, question_data, second = false, question_resource_id = false) {
    let question_id = question_data.id
    let option = {}
    if (question_data.answermap_set.length > 0) {
      option["answer_map_id"] = question_data.answermap_set[0]
    }
    option["name"] = `sample option ${data ? (data.length + 1).toString() : "1"} } `
    option["add_to_question"] = question_id

    var formData = new FormData()
    for (var k in option) {
      formData.append(k, option[k])
    }
    if (option.id != undefined) {
    } else {
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/options/create/", formData, { headers: login_credentials })
        .then((res) => {
          option.id = res.data.id
          option.is_correct = false
          questionOptions["question_" + question_id] = questionOptions["question_" + question_id].concat(option)
          delete option["add_to_question"]
          setOptionCreated(true)
          setOptionCreatedX(true)
          if (second == true) {
            setOptionCreatedX(false)
            createOption(data, question_data)
          } else if (second == null) {
            if (document.getElementById(res.data.id + `-question-option-input-id`)) document.getElementById(res.data.id + `-question-option-input-id`).focus()
          }
          if (question_resource_id !== false) {
            var index = questions["quiz"].findIndex((res) => res.id === question_resource_id)
            let is_valid = questions["quiz"][index].is_valid_question
            questions["quiz"][index].is_valid_question = res.data.is_valid_question
            questions["quiz"][index].question_data.is_valid_question = res.data.is_valid_question
            let is_valid2 = questions["quiz"][index].is_valid_answers
            questions["quiz"][index].is_valid_answers = res.data.is_valid_answers
            questions["quiz"][index].question_data.is_valid_answers = res.data.is_valid_answers
            if (is_valid != res.data.is_valid_question || is_valid2 != res.data.is_valid_answers) {
              setOptionUpdated(true)
            }
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function handleUpdateQuestion(e, index, name = null, value = null) {
    setUsingDropdown(!usingDropdown)
    let question = questions["quiz"][index]
    if (name === null) {
      if (e.target.name === "title") {
        question.title = e.target.value
        question.display_name = e.target.value
        question.question_data.title = e.target.value
      } else {
        question.title = question.question_data.title
        question.display_name = question.question_data.title
        question[e.target.name] = e.target.value
      }
    } else {
      question.title = question.question_data.title
      question.display_name = question.question_data.title
      question.question_data[name] = value
      question[name] = value
    }
    question.data_type = "1"
    var formData = new FormData()
    for (var k in question) {
      formData.append(k, question[k])
    }
    if (question.question_data.id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/questions/" + question.question_data.id + "/", formData, { headers: login_credentials })
        .then((res) => {
          questions["quiz"][index] = question
          let is_valid = questions["quiz"][index].question_data.is_valid_question
          questions["quiz"][index].is_valid_question = res.data.is_valid_question
          questions["quiz"][index].question_data.is_valid_question = res.data.is_valid_question
          let is_valid2 = questions["quiz"][index].question_data.is_valid_answers
          questions["quiz"][index].is_valid_answers = res.data.is_valid_answers
          questions["quiz"][index].question_data.is_valid_answers = res.data.is_valid_answers
          if (is_valid != res.data.is_valid_question || is_valid2 != res.data.is_valid_answers) {
            setOptionUpdated(true)
          }
          setUsingDropdown(!usingDropdown)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function handleUpdateAnswer(e, option, question_resource_id) {
    var index = questions["quiz"].findIndex((res) => res.id === question_resource_id)
    option.name = e.target.value
    option.display_name = e.target.value
    var formData = new FormData()
    for (var k in option) {
      formData.append(k, option[k])
    }
    if (option.id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/options/" + option.id + "/", formData, { headers: login_credentials })
        .then((res) => {
          let is_valid = questions["quiz"][index].is_valid_question
          questions["quiz"][index].is_valid_question = res.data.is_valid_question
          questions["quiz"][index].question_data.is_valid_question = res.data.is_valid_question
          let is_valid2 = questions["quiz"][index].is_valid_answers
          questions["quiz"][index].is_valid_answers = res.data.is_valid_answers
          questions["quiz"][index].question_data.is_valid_answers = res.data.is_valid_answers
          if (is_valid != res.data.is_valid_question || is_valid2 != res.data.is_valid_answers) {
            setOptionUpdated(true)
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function handleUpdateOption(e, option, action, question_id, index, question_resource_id) {
    let perform = false
    var formData = new FormData()
    if (action === "add" && option.is_correct === false) {
      perform = true
      formData.append("add_answer", option.id)
    } else if (action === "remove" && option.is_correct) {
      perform = true
      formData.append("remove_answer", option.id)
    }

    if (perform && option.answer_map_id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/answer-maps/" + option.answer_map_id + "/", formData, { headers: login_credentials })
        .then((res) => {
          if (action === "add" && option.is_correct === false) {
            option.is_correct = true
            questionOptions["question_" + question_id][index].is_correct = true
          } else if (action === "remove" && option.is_correct) {
            option.is_correct = false
            questionOptions["question_" + question_id][index].is_correct = false
          }
          var index2 = questions["quiz"].findIndex((res) => res.id === question_resource_id)
          let is_valid2 = questions["quiz"][index2].question_data.is_valid_answers
          questions["quiz"][index2].is_valid_answers = res.data.is_valid_answers
          questions["quiz"][index2].question_data.is_valid_answers = res.data.is_valid_answers
          if (is_valid2 != res.data.is_valid_answers) {
            setOptionUpdated(true)
          }
          setToggledOption(true)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function showQuizQuestions() {
    if (questionsFetched["quiz"] && questions["quiz"]) {
      return (
        <Droppable droppableId={`quiz`} type={`questionsDraggable`}>
          {(provided, snapshot) => (
            <div className={`col-12`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {questions["quiz"].map((datum, index) => {
                let options_error_class = ""
                if (datum && datum.is_valid_answers) {
                  options_error_class = datum.is_valid_answers
                } else if (datum.question_data && datum.question_data.is_valid_answers) {
                  options_error_class = datum.question_data.is_valid_answers
                }
                let question_error_class = (datum.question_data && datum.question_data.is_valid_question) || datum.is_valid_question
                let toggle_class = datum.question_data && questionOptions["question_" + datum.question_data.id] && currentQuestionId === datum.question_data.id
                return (
                  <Draggable key={datum.id + `-question-key-` + index} draggableId={`${datum.id.toString()}-question-id-${index.toString()}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`tab-ignore`}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div
                          id={"quizQuestions_" + datum.id}
                          className={`${Styles.font_16} shadow-none w-100 text-left d-inline-flex`}
                          style={{ backgroundColor: `#28324e`, color: `#b2b6cb`, borderRadius: `0px`, opacity: 1 }}
                        >
                          <div>
                            <h2 className={`px-3 m-0 h-100`} style={{ color: `#9ba1c4`, backgroundColor: `#12182c` }}>
                              {/*index >= 9 ? `` : <span className={Styles.opacity_0}>0</span>*/}
                              {index + 1}
                            </h2>
                          </div>
                          <div
                            className={`d-inline-flex w-100 ${options_error_class} ${question_error_class ? `` : `red-class`} ${
                              toggle_class ? `border-bottom-0` : ``
                            } `}
                            style={{ border: `${question_error_class && options_error_class == "None" ? `0px` : `1px`} solid #904744` }}
                          >
                            <div
                              onClick={(e) => {
                                e.preventDefault()
                                setOptionsFetched(false)
                                fetchOptions(datum.question_data.id)
                              }}
                              className={`px-3 py-2`}
                              style={{ borderRight: `1px dotted #495269` }}
                            >
                              <FontAwesomeIcon icon={faGripLines} style={{ width: `20px`, height: `20px`, margin: `auto 0px`, verticalAlign: `middle` }} />
                            </div>
                            <img
                              onClick={(e) => {
                                e.preventDefault()
                                setOptionsFetched(false)
                                fetchOptions(datum.question_data.id)
                              }}
                              src={toggle_class ? ChevronCircleUp : ChevronCircleDown}
                              alt={"arrow for collapse and expand"}
                              className="ml-2"
                              style={{ objectFit: `contain`, width: `20px`, height: `20px`, margin: `auto 0px`, verticalAlign: `middle` }}
                            />
                            <input
                              type="text"
                              name="title"
                              id={datum.id + `-question-input-id`}
                              defaultValue={datum.question_data ? datum.question_data.display_name : datum.display_name}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                              }}
                              onChange={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setOptionUpdated(false)
                                handleUpdateQuestion(e, index)
                              }}
                              placeholder={"Enter Question Title"}
                              className={`form-control shadow-none border-0 px-3 w-50 my-auto ${Styles.font_16}`}
                              style={{ outline: `none`, backgroundColor: `transparent`, maxWidth: `fix-content`, color: `#b2b6cb`, verticalAlign: `middle` }}
                              required
                            />
                            <Dropdown className={`py-2 pl-2 pr-4 shadow-none`} style={{ margin: `auto 0 auto auto` }}>
                              <Dropdown.Toggle variant="none" id={`question-delete-menu-${datum.id}`} className={`shadow-none p-0 ${Styles.no_caret}`}>
                                <FontAwesomeIcon className="pointer" icon={faEllipsisV} style={{ width: `20px`, height: `20px`, color: `#9AA7DB` }} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className={`${Styles.dropdown_bg}`}>
                                <Dropdown.Item
                                  eventKey="0"
                                  className={`text-white`}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    datum.index = index
                                    setDeleteResourceData(datum)
                                  }}
                                >
                                  <FontAwesomeIcon className="pointer" icon={faTrashAlt} style={{ width: `20px`, height: `20px` }} /> Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        {toggle_class ? (
                          <div className={`d-inline-flex w-100`}>
                            <div>
                              <h2 className={`px-3 m-0 ${Styles.opacity_0}`} style={{ color: `#9ba1c4` }}>
                                {/*index >= 9 ? `` : <span className={Styles.opacity_0}>0</span>*/}
                                {index + 1}
                              </h2>
                            </div>
                            <div
                              className={`px-0 w-100 ${Styles.border_top}`}
                              style={{
                                backgroundColor: `#28324e`,
                                border: `${question_error_class && options_error_class == "None" ? `0px` : `1px`} solid #904744`,
                              }}
                            >
                              <div className={`d-inline-flex w-100`} style={{ backgroundColor: `#4a526a` }}>
                                <div className={`d-inline-flex w-50 ml-auto px-3`} style={{ justifyContent: `flex-end`, color: `#bfc1d3` }}>
                                  {/*<FormGroup className='m-0 d-flex align-items-center' >
                                  <div style={{ fontSize: `13px`}} >
                                    Points:
                                  </div>
                                  <input style={{background: 'inherit',color: 'inherit', fontSize: '16px', width: `71px`}} className='blue-bg video-modal number-input float-left font-weight-bold text-left' type="number" name="points" defaultValue={datum.question_data.points} onChange={(e) => {e.preventDefault();e.stopPropagation();handleUpdateQuestion(e,index)}} id="points" placeholder=" " />
                                </FormGroup>*/}
                                  {/*<FormGroup className='m-0 d-flex align-items-center'>
                                  <div style={{ fontSize: `13px`, color: `inherit`}} >
                                    Question Level:
                                  </div>
                                  {quiz.quiz_level != '3' ?
                                    <p className={`mt-1 mb-1 text-center dropdown-arrow-big px-3 py-2 d-flex justify-content-center align-items-center font-weight-bold`}
                                      style={{ color: `inherit`, borderRadius: `10px`, fontSize: '16px' }}>
                                      {datum.question_data.question_level ==  '0' ? 'Easy' : datum.question_data.question_level ==  '1' ? 'Medium' : 'Difficult' } &nbsp;
                                    </p>
                                    :
                                    <Dropdown>
                                      <Dropdown.Toggle key={'1'} variant="none" id="quiz-level" className={`mt-1 mb-1 text-center dropdown-arrow-big px-3 py-2 shadow-none d-flex justify-content-center align-items-center font-weight-bold`}
                                        style={{ color: `inherit`, borderRadius: `10px`, fontSize: '16px' }}>
                                        {datum.question_data.question_level ==  '0' ? 'Easy' : datum.question_data.question_level ==  '1' ? 'Medium' : 'Difficult' }
                                        <FontAwesomeIcon icon={faChevronDown} style={{ width: `16px`, height: `16px`, marginLeft: '5px' }}  />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item eventKey="Easy" onClick={(e) => {e.preventDefault();e.stopPropagation(); handleUpdateQuestion(e,index,'question_level', '0') }} >Easy</Dropdown.Item>
                                        <Dropdown.Item eventKey="Medium" onClick={(e) => {e.preventDefault();e.stopPropagation(); handleUpdateQuestion(e,index,'question_level', '1') }} >Medium</Dropdown.Item>
                                        <Dropdown.Item eventKey="Difficult" onClick={(e) => {e.preventDefault();e.stopPropagation(); handleUpdateQuestion(e,index,'question_level', '2') }} >Difficult</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  }
                                </FormGroup>*/}
                                </div>
                              </div>
                              {showOptionButtons(questionOptions["question_" + datum.question_data.id], datum.question_data, datum.id)}
                            </div>
                          </div>
                        ) : (
                          ``
                        )}
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
              {questionsFetched["quiz"] && questionsNext["quiz"] !== null ? (
                <Col md="auto" className={`d-inline-flex w-100 my-2`}>
                  <Button
                    variant="none"
                    onClick={(e) => {
                      e.preventDefault()
                      fetchNextQuizQuestions(questionsNext["quiz"])
                    }}
                    className={`${Styles.font_12} text-center px-3 py-2 mx-auto`}
                    style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                  >
                    See More
                  </Button>
                </Col>
              ) : (
                ""
              )}
              {questions["quiz"] && questions["quiz"].length > 0 ? (
                <div className={`justify-content-center pt-4 pb-3 d-flex bg-transparent`} style={{ border: `1px dashed #C6CFF4` }}>
                  <div className={`pl-3 text-center d-inline-flex`}>
                    <p className={`${Styles.font_14}`} style={{ color: `#D4DCF7` }}>
                      Drag and drop questions here{" "}
                      <span className={`font-weight-bold mx-3`} style={{ fontSize: `24px` }}>
                        or
                      </span>{" "}
                    </p>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        id="create-question"
                        className={`ml-3 text-center px-3 py-2 shadow-none ${Styles.create_question_dropdown_btn}`}
                        style={{ backgroundColor: `#4b5a9b`, color: `#D4DCF7`, borderRadius: `10px`, fontSize: "12px" }}
                      >
                        Create and Add
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey="1"
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setQuestionCreated(false)
                            createQuestion("1")
                          }}
                        >
                          Choice
                        </Dropdown.Item>
                        {/*<Dropdown.Item eventKey="2" onClick={(e) => {e.preventDefault();e.stopPropagation();setQuestionCreated(false);createQuestion('2')}} >Descriptive</Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={(e) => {e.preventDefault();e.stopPropagation();setQuestionCreated(false);createQuestion('3')}} >Boolean</Dropdown.Item>*/}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              ) : (
                <div className={`justify-content-center pt-4 pb-3 d-flex bg-transparent`} style={{ border: `1px dashed #C6CFF4` }}>
                  <div className={`pl-3 text-center`}>
                    <span className={``} style={{ color: `#D4DCF7` }}>
                      Drag and drop questions here
                    </span>
                    <br />
                    or
                    <br />
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        id="create-question"
                        className={`mt-3 mb-3 ml-2 text-center px-3 py-2 mr-2 shadow-none ${Styles.create_question_dropdown_btn}`}
                        style={{ backgroundColor: `#4b5a9b`, color: `#D4DCF7`, borderRadius: `10px`, fontSize: "12px" }}
                      >
                        Create and Add
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/*<Dropdown.Item eventKey="0" onClick={(e) => {e.preventDefault();e.stopPropagation();setQuestionCreated(false);createQuestion('0')}} >Single Choice</Dropdown.Item>*/}
                        <Dropdown.Item
                          eventKey="1"
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setQuestionCreated(false)
                            createQuestion("1")
                          }}
                        >
                          Choice
                        </Dropdown.Item>
                        {/*<Dropdown.Item eventKey="2" onClick={(e) => {e.preventDefault();e.stopPropagation();setQuestionCreated(false);createQuestion('2')}} >Descriptive</Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={(e) => {e.preventDefault();e.stopPropagation();setQuestionCreated(false);createQuestion('3')}} >Boolean</Dropdown.Item>*/}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )}
            </div>
          )}
        </Droppable>
      )
    }
  }

  function showOptionButtons(data, question_data, question_resource_id) {
    let question_id = question_data.id
    if (data) {
      return (
        <Droppable droppableId={`options`} type={`optionsDraggable_` + question_id}>
          {(provided, snapshot) => (
            <div className={`col-12 bg-transparent`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {data.map((datum, index) => (
                <Draggable
                  key={datum.id + `-option-key-` + index + `-${question_id}`}
                  draggableId={`${datum.id.toString()}-question-id-${index.toString() + `-${question_id.toString()}`}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={`p-2 tab-ignore`}>
                      <div
                        variant="none"
                        id={`quizOptions_${question_id.toString()}` + datum.id}
                        className={`${Styles.font_16} px-3 shadow-none w-100  text-left d-inline-flex`}
                        style={{ backgroundColor: `#3d4d7e`, color: `#b2b6cb`, borderRadius: `0px`, opacity: 1 }}
                      >
                        <div className={`pr-2 py-2`} style={{ borderRight: `1px solid #ffffff` }}>
                          <FontAwesomeIcon icon={faGripLines} style={{ width: `20px`, height: `20px`, margin: `auto 0px`, verticalAlign: `middle` }} />
                        </div>
                        <input
                          type="text"
                          name="display_name"
                          id={datum.id + `-question-option-input-id`}
                          defaultValue={datum.display_name}
                          onChange={(e) => {
                            e.preventDefault()
                            setOptionUpdated(false)
                            handleUpdateAnswer(e, datum, question_resource_id)
                          }}
                          placeholder={"Enter Option text"}
                          className={`form-control shadow-none border-0 px-3 w-50 my-auto ${Styles.font_16}`}
                          style={{ outline: `none`, backgroundColor: `transparent`, maxWidth: `fix-content`, color: `#b2b6cb`, verticalAlign: `middle` }}
                          required
                        />
                        <div className={`ml-auto d-inline-flex ${toggledOption}`}>
                          <div
                            className={`p-2`}
                            style={{ margin: `auto 0 auto auto` }}
                            onClick={(e) => {
                              e.preventDefault()
                              const newState = data
                              newState.splice(index, 1)
                              setDraggedData(!draggedData)
                              deleteResource(datum.id, "options", question_id, question_resource_id)
                            }}
                          >
                            <FontAwesomeIcon className="pointer" icon={faTrashAlt} style={{ width: `20px`, height: `20px` }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <div className={`pt-2 pb-2 d-flex bg-transparent`}>
                <div className={`pl-3 text-center`}>
                  <Button
                    variant="none"
                    id="create-question"
                    className={`ml-2 text-center px-3 py-2 mr-2 shadow-none align-items-center d-inline-flex`}
                    style={{ backgroundColor: `#465077`, color: `#D4DCF7`, borderRadius: `10px`, fontSize: "12px" }}
                    onClick={(e) => {
                      e.preventDefault()
                      setOptionCreated(false)
                      setOptionUpdated(false)
                      createOption(data, question_data, null, question_resource_id)
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} style={{ width: `15px`, height: `15px` }} />
                    &nbsp; Add Options
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Droppable>
      )
    }
  }

  const data_for_menu = [
    { id: 0, name: "Poll Information", selected: true, href: `/app/polls/edit/${location.quiz_id}` },
    { id: 1, name: "Add Questions", selected: true, href: `/app/polls/${location.quiz_id}/questions` },
    {
      id: 2,
      name: "Save & Publish",
      selected: false,
      href: questions["quiz"] && questions["quiz"].length > 0 ? `/app/polls/${location.quiz_id}/publish` : `/app/polls/${location.quiz_id}/questions`,
    },
  ]

  return (
    <div>
      <SEO title="Poll Questions" />
      <DragDropContext onDragEnd={onDragEnd}>
        <Col lg="12" md="12" sm="12" xs="12" className={`px-0 d-inline-flex float-left`}>
          <div className={`${deleteResourceData && Object.keys(deleteResourceData).length > 0 ? `` : `position-absolute`} float-left`} style={{ width: `30%` }}>
            <div className={"float-left"} style={{ width: `88px`, borderRight: `1px solid #2e3546`, height: `100vh` }}>
              <div>
                <Button
                  key={0}
                  variant="none"
                  className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(`/app/dashboard/`)
                  }}
                  style={{ backgroundColor: `transparent`, color: `#c6cff4`, borderRadius: `0px`, minWidth: `fit-content`, opacity: 1 }}
                >
                  <FontAwesomeIcon icon={faHome} style={{ width: `20px`, height: `20px` }} />
                  Home
                </Button>
              </div>
            </div>
            <div
              className={`float-left ${collapse ? `${Styles.side_panel_collapsed}` : `${Styles.side_panel_opened}`}`}
              id={`mySidepanel`}
              style={{ backgroundColor: `#2e3446` }}
            >
              <div style={{ padding: `15px 15px 0px 15px` }}>
                <div className={`col-12 d-inline-flex ${collapse ? Styles.opacity_0 : Styles.opacity_1}`} style={{ backgroundColor: `#414a6c` }}>
                  <FontAwesomeIcon icon={faSearch} size="lg" style={{ margin: `auto 0px`, color: `#7c84a0` }} />
                  <FormControl
                    className="border-0 py-1 px-2 form-control px-0 shadow-none font-14"
                    style={{ backgroundColor: `transparent`, color: `#b6bdd4` }}
                    placeholder={`search Questions`}
                    onChange={(e) => {
                      e.preventDefault()
                      setSearchValue(e.target.value)
                    }}
                    value={searchValue}
                  />
                </div>
              </div>
              {questionsFetched["all"] ? showQuestions() : ``}
              <Col
                onClick={() => navigate(`/app/questions/`)}
                className={`${Styles.fixed_button} ${collapse ? `${Styles.fixed_button_0}` : `${Styles.fixed_button_1}`} pointer `}
              >
                {" "}
                <FontAwesomeIcon icon={faUpload} style={{ width: `24px`, height: `24px` }} />{" "}
                {collapse ? `` : <span className={`pl-3`}>Upload More Questions</span>}{" "}
              </Col>
            </div>
            <div className={`d-inline-flex`} style={{ height: `100vh` }}>
              <FontAwesomeIcon
                icon={collapse ? faChevronRight : faChevronLeft}
                onClick={() => openNav()}
                className={`my-auto py-1 pointer`}
                style={{ width: `24px`, height: `24px`, backgroundColor: `#2e3546`, borderRadius: collapse ? `0px 8px 8px 0px` : `0px 8px 8px 0px` }}
              />
            </div>
          </div>
          <div
            id={`scrollable-div`}
            className={`px-0 float-left position-fixed ${collapse ? `${Styles.scrollable_div_collapsed}` : `${Styles.scrollable_div_opened}`}`}
            style={{ height: `calc(100vh - 70px)`, overflowY: `scroll` }}
          >
            <div className={`sticky-top`}>
              <Col xs="12" sm="12" md="12" className={`px-0`} style={{ backgroundColor: `#12192c` }}>
                <h5 className={`${Styles.font_h3} border-bottom p-2 m-0`} style={{ color: `#C6CFF4`, fontFamily: `Helvetica Neue` }}>
                  <Link className={`pointer text-white`} to={`/app/polls/edit/${location.quiz_id}`}>
                    {" "}
                    <FontAwesomeIcon icon={faChevronLeft} style={{ width: `25px`, height: `25px` }} />
                  </Link>
                  &nbsp;
                  <span className={`text-white font-weight-bold`}>Add Questions</span>
                  <Link className={`pointer ml-auto float-right`} to={`/app/polls`} style={{ color: `#C6CFF4` }}>
                    <span style={{ fontWeight: "normal", fontSize: "16px" }} className={`small`}>
                      Exit Poll &nbsp;
                      <img src={Close} alt={`Exit Course`} className={`my-auto`} style={{ objectFit: `contain`, height: `15px`, color: `white` }} />
                    </span>
                  </Link>
                </h5>
              </Col>
            </div>
            <NavWithTriangles button_data={data_for_menu} classes={`mb-4 ml-2 mt-5 triangle-menu d-inline-flex`} />
            <Col className={`col-12 p-0`}>
              <p className="ml-2 m-0 pb-2"> Add Questions</p>
            </Col>
            {/* {deleteQuestionModal()} */}
            {showQuizQuestions()}
          </div>
          <Col
            onClick={(e) => {
              e.preventDefault()
              questions["quiz"] && questions["quiz"].length > 0
                ? window.open(`/app/polls/${location.quiz_id}/publish`, "_self")
                : window.open(`/app/polls/${location.quiz_id}/questions`, "_self")
            }}
            className={"px-0 d-inline-flex fixed-bottom pointer text-center justify-content-center "}
            id={"progress-bar"}
            style={{ backgroundColor: `#576186`, float: "right", marginLeft: "auto", width: "70%", transitionDuration: "0.5s" }}
          >
            <p className={`m-0 p-3`}>
              Proceed &nbsp;
              <FontAwesomeIcon icon={faChevronRight} style={{ width: `20px`, height: `20px` }} />
            </p>
          </Col>
        </Col>
      </DragDropContext>
    </div>
  )
}

export default ViewPollQuestions
