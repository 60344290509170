import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import ProfileUpdateProgress from "../../../../ProfileProgressBar"
import Checkbox from "../../../../custom/Checkbox"
import DefaultUser from "../../../../../images/svgs/DefaultUser.svg"
import Style from "./style.module.scss"
import Loader from "../../../../custom/Loader"
import { postRequest } from "../../../../../services/rest_service"
import { SET_NOTIFICATION_DATA } from "../../../../../stores/global_store/GlobalActions"
import { GlobalContext } from "../../../../../stores/global_store/GlobalStore"
const EducatorAccess = (props) => {
  const [notificationData, dispatch] = useContext(GlobalContext)
  const {
    cropData,
    educatorDetail,
    specificCourseFlag,
    specificClassesFlag,
    courseFlag,
    setCoursePermissions,
    setClassesPermission,
    setShowCourseModal,
    setShowClassesModal,
    handleAddEducator,
    loader,
    classFlag,
    isDisabled,
    setEducatorProfilePageNo,
    educatorProfilePageNo,
    setPageno,
    apicall,
    role,
  } = props
  const changeUserPermission = async () => {
    let payload = { ...educatorDetail }
    let res = await postRequest("/role-management/user-permission-management/set_user_permission_data/", payload)
    if (res.success) {
      apicall()
      dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "success", title: "Permission Updated Successfully!" },
      })
      setPageno("Members List")
      setEducatorProfilePageNo(1)
    } else {
      dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "error", title: res.data.detail },
      })
    }
  }

  return (
    <div className={Style.educator_profile_second_page_div}>
      <div className="d-flex">
        <ProfileUpdateProgress
          percentage={"0"}
          image={cropData !== "#" ? cropData : educatorDetail.profile_pic ? educatorDetail.profile_pic : DefaultUser}
          footerImgFlag={false}
          mainDivStyle={Style.profileProgressMainDiv}
          strokeWidth={3}
          imgName={`${educatorDetail.name || educatorDetail.first_name} logo`}
        />
      </div>
      <p className="pt-2">{`Allow ${educatorDetail.name || educatorDetail.first_name} access to`}</p>
      <div className="d-flex flex-row mb-3">
        <Checkbox flag={specificCourseFlag} id="course" isChecked={courseFlag} onCheckboxChange={setCoursePermissions} />
        <span className="pl-1">
          All Courses or{" "}
          <span className={Style.anchor} onClick={() => setShowCourseModal(true)}>
            Specific Courses
          </span>
        </span>
      </div>
      <div className="d-flex flex-row mb-3">
        <Checkbox flag={specificClassesFlag} id="classes" isChecked={classFlag} onCheckboxChange={setClassesPermission} />
        <span className="pl-1">
          All Classes or{" "}
          <span className={Style.anchor} onClick={() => setShowClassesModal(true)}>
            Specific Classes
          </span>
        </span>
      </div>

      {educatorDetail?.id ? (
        <button className={`${Style.educatorbtn} border_on_hover px-3`} onClick={changeUserPermission} disabled={isDisabled}>
          Save Permissions
          {loader && <Loader class="ml-3 mr-1" />}
        </button>
      ) : (
        <button className={`${Style.educatorbtn} border_on_hover px-3`} onClick={handleAddEducator} disabled={isDisabled}>
          {`Add ${role}`}
          {loader && <Loader class="ml-3 mr-1" />}
        </button>
      )}

      {(specificClassesFlag || specificCourseFlag) && (
        <div className={`${Style.footerCheckmark} disable-content`}>
          <div className="d-flex">
            <Checkbox flag={true} isChecked={true} disabled={true} customCheckboxTickMark={Style.customCheckmarkStyle} />
            <p className="mx-2 mt-1">Restricted Access </p>
          </div>
          <div className="d-flex">
            <Checkbox flag={false} isChecked={true} disabled={true} customCheckboxTickMark={Style.customCheckmarkStyle} />
            <p className="mx-2 mt-1"> Full Access</p>
          </div>
        </div>
      )}
    </div>
  )
}

EducatorAccess.propTypes = {
  cropData: PropTypes.string,
  educatorDetail: PropTypes.shape({
    profile_pic: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  specificCourseFlag: PropTypes.bool,
  specificClassesFlag: PropTypes.bool,
  courseFlag: PropTypes.bool,
  setCoursePermissions: PropTypes.func,
  setClassesPermission: PropTypes.func,
  setShowCourseModal: PropTypes.func,
  setShowClassesModal: PropTypes.func,
  handleAddEducator: PropTypes.func,
  loader: PropTypes.bool,
  classFlag: PropTypes.bool,
  isDisabled: PropTypes.bool,
  setEducatorProfilePageNo: PropTypes.func,
  educatorProfilePageNo: PropTypes.number,
  setPageno: PropTypes.func,
  apicall: PropTypes.func,
}

export default EducatorAccess
