import React, { useEffect, useState } from "react"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
// import Multiselect from 'react-widgets/lib/Multiselect'
// import 'react-widgets/dist/css/react-widgets.css';

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

const CreateCourseTemplate = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [course_template, setCourseTemplate] = useState({})
  const [isGlobal, setIsGlobal] = useState(false)
  // const [allVideos, setAllVideos] = useState([])

  // console.log(props,course, "props.dilsjdio")
  // const { state = {} } = props

  useEffect(() => {
    // searchVideos()
    course_template.videos = []
  }, [])

  // function searchVideos(search = '') {
  //   axios.get(process.env.GATSBY_REACT_APP_API_HOST+`/basic-videos-list/?search=`+search, {headers: login_credentials})
  //   .then(res => {
  //     setAllVideos(res.data.results);
  //   })
  // }

  function handleChange(event) {
    event.preventDefault()
    course_template[event.target.name] = event.target.value
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (isGlobal) {
      course_template["template_type"] = "0"
      if (props.props.location.hash.includes("#type=course")) {
        course_template["course_id"] = props.props.location.hash.split(",")[1]
      } else if (props.props.location.hash.includes("#type=training")) {
        course_template["training_id"] = props.props.location.hash.split(",")[1]
      }
    } else if (props.props.location.hash.includes("#type=course")) {
      course_template["template_type"] = "1"
      course_template["course_id"] = props.props.location.hash.split(",")[1]
    } else if (props.props.location.hash.includes("#type=training")) {
      course_template["template_type"] = "2"
      course_template["training_id"] = props.props.location.hash.split(",")[1]
    }
    var formData = new FormData()
    for (var k in course_template) {
      if (k == "videos") {
        formData.append("videos", JSON.stringify(course_template.videos))
      } else {
        formData.append(k, course_template[k])
      }
    }
    if (course_template.id != undefined) {
    } else {
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/default-course-templates/create/", formData, { headers: login_credentials })
        .then((res) => {
          if (isBrowser()) {
            window.history.back()
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function addToTemplate(fieldData, field_name) {
    let data = course_template.field_name,
      ids = fieldData.map((item) => {
        if (item.id != undefined) {
          return item.id
        } else {
          return item
        }
      })
    data = ids
    course_template[field_name] = data
  }

  return (
    <div>
      <SEO title="CourseTemplate Creation" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>Add Default Course Template here</h4>
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col>
              <div>
                <Row>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        name="name"
                        defaultValue={course_template.name}
                        onChange={handleChange}
                        id="name"
                        placeholder="Enter course_template name"
                        required
                      />
                    </FormGroup>
                  </Col>
                  {props.props.location.hash.includes("#type=") ? (
                    <Col xs="12" sm="6" md="12">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          onChange={() => {
                            setIsGlobal(!isGlobal)
                          }}
                          type="checkbox"
                          label="Want to add it as Global Template"
                        />
                      </Form.Group>
                    </Col>
                  ) : (
                    ""
                  )}
                  {/*<Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="description">description</label>
                      <input type="text" name="description" defaultValue={course_template.description} onChange={handleChange} id="description" placeholder="Enter course_template description" required />
                    </FormGroup>
                  </Col>*/}
                  {/*<Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="videos">Add Videos to Course Template</label>
                      <Multiselect placeholder="Add Videos to Course Template"
                        data={allVideos} valueField="id" textField="name"
                        defaultValue={course_template.videos}
                        onSearch={value => searchVideos(value)}
                        onChange={video => addToTemplate(video,'videos')} />
                    </FormGroup>
                  </Col>*/}
                </Row>
              </div>
            </Col>
            <Button type="submit" color="warning">
              Save
            </Button>{" "}
          </Form>
          <Button onClick={() => (isBrowser() ? window.history.back() : "")} variant="danger">
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CreateCourseTemplate
