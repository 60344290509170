import React, { useContext, useEffect } from "react"
import Style from "./style.module.scss"
import AddEducatorLogo from "../../../../../images/addEducator.png"
import { useState } from "react"
import { Col } from "react-bootstrap"
import CancelIcon from "../../../../../images/svgs/cancel-white-icon.svg"
import CropImage from "../../../../crop-image/crop-image"
import ProfileUpdateProgress from "../../../../ProfileProgressBar"
import Camera from "../../../../../images/svgs/camera_logo.svg"
import CustomInputField from "../../../../custom/CustomInputFiled"
import DefaultUser from "../../../../../images/svgs/DefaultUser.svg"
import PropTypes from "prop-types"
import PermissionModal from "./PermissionModal"
import { classesDetails, courseDetails } from "../../../../Sidebar/constants"
import MemberDetailCard from "../MemberDetailCard"
import { validateEmail, validateMobileNumber } from "../../../../utilities/utils"
import { convertBase64ToFile } from "../../../../utilities/convertBase64ToFile"
import { b64toBlob } from "../../../../utilities/b64toBlob"
import Image from "../../../../custom/Image"
import warningIcon from "../../../../../images/svgs/warning_icon.svg"
import { getRequest, postRequest } from "../../../../../services/rest_service"
import LeftArrow from "../../../../../images/svgs/leftarrow.svg"
import { GlobalContext } from "../../../../../stores/global_store/GlobalStore"
import { useLocation } from "@reach/router"
import EducatorAccess from "./EducatorAccess"
import { SET_NOTIFICATION_DATA } from "../../../../../stores/global_store/GlobalActions"
import PhoneInput from "react-phone-number-input"
import { parsePhoneNumber } from "react-phone-number-input"
import { isValidPhoneNumber } from "react-phone-number-input"
function Educator(props) {
  //state variables
  const [notificationData, dispatch] = useContext(GlobalContext)
  const { setEducatorDetail, educatorDetail, apicall, selectedTeam, teamapi, pageno, setPageno, defaultEducatorDetail, role } = props
  const [savedEducatorDetail, setSavedEducatorDetail] = useState([{ btnText: "Add Educator", logo: { AddEducatorLogo } }])
  const [educatorProfilePageNo, setEducatorProfilePageNo] = useState(1)
  const [modalShow, setModalShow] = useState(false)
  const [cropData, setCropData] = useState("#")
  const [fileName, setFileName] = useState(null)
  const [showCourseModal, setShowCourseModal] = useState(false)
  const [showClassesModal, setShowClassesModal] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [editPermissionPage, setEditPermissionPage] = useState(false)
  const [educatorDetailError, setEducatorDetailError] = useState({
    emailError: false,
    phoneError: false,
    emailErrorMsg: "",
    phoneErrorMsg: "",
  })
  const dropdownCourseOptions = [
    {
      value: "all_courses",
      label: "All Courses",
    },
    {
      value: "draft",
      label: "Draft",
    },
    {
      value: "published",
      label: "Published",
    },
  ]
  const dropdownClassesOptions = [
    {
      value: "all_classes",
      label: "All Classes",
    },
    {
      value: "upcoming_classes",
      label: "Upcoming Classes",
    },
    {
      value: "past_classes",
      label: "Past Classes",
    },
  ]
  const [courseFlag, setCourseFlag] = useState(false)
  const [specificCourseFlag, setSpecificCourseFlag] = useState(false)
  const [classFlag, setClassFlag] = useState(false)

  const [specificClassesFlag, setSpecificClassesFlag] = useState(false)
  const [loader, setLoader] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const location = useLocation()
  const [courseData, setCourseData] = useState([])
  const [classesData, setClassesData] = useState([])

  const fetchCourses = async (company_id, type) => {
    let value = type === "all" ? "" : `&isdraft=${type === "draft" ? "true" : "false"}`
    const response = await getRequest(`/basic-courses-list/?company=${company_id}` + value)
    if (response.success) {
      setCourseData(response.data.results)
    } else {
      setCourseData([])
    }
  }
  const fetchTrainings = async (company_id, classType) => {
    const response = await getRequest(`/basic-trainings-list/?course__company=${company_id}&type=${classType}&showAllTrainings=true`)
    if (response.success) {
      setClassesData(response.data.results)
    } else {
      setClassesData([])
    }
  }
  useEffect(() => {
    fetchCourses(localStorage.getItem("company_id"), "all")

    fetchTrainings(localStorage.getItem("company_id"), "all")
  }, [])

  useEffect(() => {
    setEducatorProfilePageNo(1)
    setPageno("Members List")
    setEditPermissionPage(false)
    setEducatorDetail(defaultEducatorDetail)
  }, [role])
  let company_id = location.pathname.split("/").pop()

  useEffect(() => {
    if (
      educatorDetail.permission.course?.length &&
      educatorDetail.permission?.course?.length !== courseData.length &&
      !educatorDetail.permission.access_to_all_courses
    ) {
      setSpecificCourseFlag(true)
      setCourseFlag(true)
    } else if (educatorDetail.permission.access_to_all_courses) {
      setCourseFlag(true)
      setSpecificCourseFlag(false)
    } else if (!educatorDetail.permission.access_to_all_courses && !educatorDetail.permission.course.length) {
      setCourseFlag(false)
      setSpecificCourseFlag(false)
    } else {
      setSpecificCourseFlag(false)
      setCourseFlag(false)
    }
  }, [educatorDetail?.permission?.course])

  useEffect(() => {
    if (
      educatorDetail.permission.trainings?.length &&
      educatorDetail.permission?.trainings?.length !== classesData.length &&
      !educatorDetail.permission.access_to_all_trainings
    ) {
      setSpecificClassesFlag(true)
      setClassFlag(true)
    } else if (educatorDetail.permission.access_to_all_trainings) {
      setClassFlag(true)
      setSpecificClassesFlag(false)
    } else if (!educatorDetail.permission.access_to_all_trainings && !educatorDetail.permission.trainings.length) {
      setClassFlag(false)
      setSpecificClassesFlag(false)
    } else {
      setSpecificClassesFlag(false)
      setClassFlag(false)
    }
  }, [educatorDetail?.permission?.trainings])

  const handleClick = () => {
    setEducatorDetail({ ...defaultEducatorDetail })
    setPageno("Add Member Form")
  }
  const handleClose = () => {
    setEducatorProfilePageNo(1)
    setPageno("Members List")
    setEditPermissionPage(false)
    setEducatorDetail(defaultEducatorDetail)
  }

  //form validation functions
  const handleEmailValidation = (value) => {
    if (validateEmail(value)) {
      setIsEmailValid(true)
    } else {
      setIsEmailValid(false)
    }
    return validateEmail(value)
  }
  const getCropDataProps = (cropData, fileName) => {
    setCropData(cropData)
    setFileName(fileName)
  }

  useEffect(() => {
    if (cropData !== educatorDetail?.avatar && cropData !== "#") {
      const newEducatorDetail = { ...educatorDetail }
      newEducatorDetail.avatar = convertBase64ToFile(cropData, b64toBlob)
      setEducatorDetail(newEducatorDetail)
    }
  }, [cropData])

  //event handler functions
  const handlePhoneInput = (value) => {
    if (value?.length) {
      setEducatorDetail({ ...educatorDetail, phone: value })
      if (isValidPhoneNumber(value)) {
        setEducatorDetailError({ ...educatorDetailError, phoneError: false, phoneErrorMsg: "" })
      } else {
        setEducatorDetailError({ ...educatorDetailError, phoneError: true, phoneErrorMsg: "Please Enter Correct Phone Number!" })
      }
    }
  }
  const handleChange = (event) => {
    if (event.target) {
      if (event.target.name === "email") {
        setEducatorDetail({ ...educatorDetail, email: event.target.value })
        if (validateEmail(event.target.value)) {
          setEducatorDetailError({ ...educatorDetailError, emailError: false, emailErrorMsg: "" })
        } else {
          setEducatorDetailError({ ...educatorDetailError, emailError: true, emailErrorMsg: "Please Enter Correct Email!" })
        }
      } else {
        let educator_detail = { ...educatorDetail }
        educator_detail[event.target.name] = event.target.value
        setEducatorDetail(educator_detail)
      }
    }
  }
  const handleAddEducator = async (event) => {
    setIsDisabled(true)
    setLoader(true)
    let formData = new FormData()
    formData.append("email", educatorDetail.email.toLowerCase())
    formData.append("name", educatorDetail.name)
    formData.append("phone", parsePhoneNumber(educatorDetail.phone).nationalNumber)
    formData.append("country_code", parsePhoneNumber(educatorDetail.phone)?.countryCallingCode)
    formData.append("designation", educatorDetail.designation)
    formData.append("company", company_id)
    formData.append("role", props.selectedTeam?.id)
    formData.append("profile_pic", educatorDetail.avatar)
    formData.append("progress_percentage", "40")
    const permissionString = JSON.stringify(educatorDetail.permission)
    formData.append("permission", permissionString)
    event.preventDefault()
    let res = {}
    if (props?.selectedTeam?.name === "Educators") {
      res = await postRequest(`/trainers/create/`, formData)
    } else {
      res = await postRequest("/role-management/role-permission-management/assign_user_to_role/", formData)
    }
    if (res.success) {
      setIsDisabled(false)
      setEducatorProfilePageNo(1)
      setPageno("Members List")
      setEducatorDetail(defaultEducatorDetail)
      teamapi()
      setCropData("#")
      dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "success", title: educatorDetail.name + " added as an " + role + " Successfully!" },
      })
    } else {
      setIsDisabled(false)
      setEducatorProfilePageNo(2)
      dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "error", title: res.data.msg ? res.data.msg : "Something went wrong!" },
      })
    }
    setLoader(false)
  }
  const handleNext = async (event) => {
    {
      isEmailValid && setEducatorProfilePageNo(2) && setEditPermissionPage(false)
    }
    event.preventDefault()
  }

  const handleEditPermission = (userDetail, userRoleId) => {
    setEducatorDetail({ ...userDetail, usr_role_id: userRoleId })
    setEditPermissionPage(true)
    setPageno("Add Member Form")
    setEducatorProfilePageNo(2)
    return (
      <EducatorAccess
        cropData={cropData}
        educatorDetail={educatorDetail}
        specificCourseFlag={specificCourseFlag}
        specificClassesFlag={specificClassesFlag}
        courseFlag={courseFlag}
        setCoursePermissions={setCoursePermissions}
        setClassesPermission={setClassesPermission}
        setShowCourseModal={setShowCourseModal}
        setShowClassesModal={setShowClassesModal}
        handleAddEducator={handleAddEducator}
        loader={loader}
        classFlag={classFlag}
        isDisabled={isDisabled}
        setEducatorProfilePageNo={setEducatorProfilePageNo}
        educatorProfilePageno={educatorProfilePageNo}
        pageno={pageno}
        setPageno={setPageno}
        apicall={apicall}
        role={role}
      />
    )
  }

  const setCoursePermissions = (id, newChecked) => {
    setCourseFlag(newChecked)
    setEducatorDetail({ ...educatorDetail, permission: { ...educatorDetail.permission, access_to_all_courses: newChecked, course: [] } })
  }
  const setClassesPermission = (id, newChecked) => {
    setEducatorDetail({ ...educatorDetail, permission: { ...educatorDetail.permission, access_to_all_trainings: newChecked, trainings: [] } })
  }
  let isSavedEducatorDetailEmpty = Object.keys(savedEducatorDetail).length === 1
  return (
    <>
      {pageno == "Members List" && isSavedEducatorDetailEmpty && !props?.fetchedEducationDetail?.length ? (
        <div className="d-flex flex-column align-items-center justify-content-center h-100 ">
          <div className={Style.container_imgdiv}>
            <Image src={AddEducatorLogo} alt="" className={Style.container_img} />
          </div>
          <p className={Style.container_text}>{`Add New ${props?.role}`} </p>
          <button className={Style.container_btn} onClick={handleClick}>
            {`Add ${props?.role}`}
          </button>
        </div>
      ) : (
        pageno == "Members List" &&
        props?.fetchedEducationDetail?.length && (
          <div className={Style.memberCards}>
            <MemberDetailCard
              key={"new-educator"}
              member={{ user_detail: { btnText: `Add ${props?.role}`, logo: { AddEducatorLogo } } }}
              setPageno={setPageno}
              role={props?.role}
              apicall={apicall}
            />
            {props?.fetchedEducationDetail?.map((educator, index) => {
              return (
                educator?.user_detail?.id && (
                  <MemberDetailCard
                    key={index}
                    member={educator}
                    role={props?.role}
                    apicall={apicall}
                    teamapi={teamapi}
                    roleId={selectedTeam?.id}
                    cropData={cropData}
                    educatorDetail={educatorDetail}
                    specificCourseFlag={specificCourseFlag}
                    specificClassesFlag={specificClassesFlag}
                    courseFlag={courseFlag}
                    setCoursePermissions={setCoursePermissions}
                    setClassesPermission={setClassesPermission}
                    setShowCourseModal={setShowCourseModal}
                    setShowClassesModal={setShowClassesModal}
                    handleAddEducator={handleAddEducator}
                    loader={loader}
                    classFlag={classFlag}
                    isDisabled={isDisabled}
                    setEducatorProfilePageNo={setEducatorProfilePageNo}
                    educatorProfilePageNo={educatorProfilePageNo}
                    setEditPermissionPage={setEditPermissionPage}
                    setPageno={setPageno}
                    pageno={pageno}
                    handleEditPermission={handleEditPermission}
                  />
                )
              )
            })}
          </div>
        )
      )}
      {pageno == "Add Member Form" && (
        <div className={Style.pagesecond_div}>
          <PermissionModal
            setShowModal={setShowCourseModal}
            showModal={showCourseModal}
            educatorDetail={educatorDetail}
            setEducatorDetail={setEducatorDetail}
            details={courseDetails}
            dropdownOptions={dropdownCourseOptions}
            courseData={courseData}
            setCourseData={setCourseData}
            type="courses"
          />
          <PermissionModal
            setShowModal={setShowClassesModal}
            showModal={showClassesModal}
            educatorDetail={educatorDetail}
            setEducatorDetail={setEducatorDetail}
            details={classesDetails}
            dropdownOptions={dropdownClassesOptions}
            setClassesData={setClassesData}
            classesData={classesData}
            type="classes"
          />
          <Col xs="12" sm="12" md="12" lg="12" className={`pt-1 px-0 d-flex flex-column align-items-start ${Style.col_style}`}>
            {educatorProfilePageNo === 1 && (
              <Col xs="12" sm="12" md="12" lg="12" className={`header-tab d-flex px-0 mb-0 br-141400`}>
                <div className="d-flex justify-content-space-around w-16rem">
                  <p className={`mb-0 py-2 px-3 poppins_font ${Style.headerlabel}`}>{`Add ${role}`}</p>
                </div>
                <div className="d-flex mr-2 align-items-center justify-content-end w-100 h-3rem p-1">
                  <Image onClick={handleClose} src={CancelIcon} alt={`Cancel`} className={`mb-0 pointer ${Style.image}`} />
                </div>
              </Col>
            )}
            {educatorProfilePageNo === 2 && (
              <Col xs="12" sm="12" md="12" lg="12" className={`header-tab d-flex px-0 mb-0 br-141400`}>
                <div className="d-flex justify-content-space-around w-16rem ">
                  {!editPermissionPage && <Image src={LeftArrow} alt="back" className={Style.headerImage} onClick={() => setEducatorProfilePageNo(1)} />}
                  <p className={`mb-0 py-2  poppins_font ${Style.headerlabel}`}>{editPermissionPage ? `Edit access permission` : `Add ${role}`}</p>
                </div>
                <div className="d-flex mr-2 align-items-center justify-content-end w-100 h-3rem p-1">
                  <Image onClick={handleClose} src={CancelIcon} alt={`Cancel`} className={`mb-0 pointer ${Style.image}`} />
                </div>
              </Col>
            )}
          </Col>
          <Col lg="12" md="12" sm="12" xs="12" className="px-0 pt-2 mx-0 br-12px pb-2 bg-212C42 br-001414 flex-grow-1">
            {educatorProfilePageNo === 1 && (
              <form className="mb-0" id="trainform" onSubmit={handleNext}>
                <div id="educator-box" className={`d-flex mt-2 mb-3 px-3 ${Style.educator_box}`}>
                  <CropImage
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    getCropDataProps={getCropDataProps}
                    fileName={fileName}
                    avatar={cropData !== "#" ? cropData : null}
                  />
                  <Col lg="4" md="4" sm="3" xs="3" className={`float-left px-0 pointer `}>
                    {cropData !== "#" ? (
                      <ProfileUpdateProgress
                        percentage="0"
                        image={cropData}
                        footerImg={Camera}
                        footerImgFlag={true}
                        handleClick={(event) => {
                          event.preventDefault()
                          setModalShow(true)
                        }}
                        mainDivStyle={Style.progressBarMainDiv}
                        imgStyle="w-90 h-90 rounded-circle"
                        footerImgStyle={Style.progressBarFooterImg}
                        strokeWidth={3}
                        imgName="Educator Logo"
                        footerImgName="Camera logo"
                      />
                    ) : (
                      <ProfileUpdateProgress
                        percentage="0"
                        image={DefaultUser}
                        footerImg={Camera}
                        footerImgFlag={true}
                        handleClick={(event) => {
                          event.preventDefault()
                          setModalShow(true)
                        }}
                        mainDivStyle={Style.progressBarMainDiv}
                        imgStyle={Style.imgStyle}
                        footerImgStyle={Style.progressBarFooterImg}
                        strokeWidth={3}
                        imgName="Default School Logo"
                        footerImgName="Camera logo"
                      />
                    )}
                  </Col>
                  <Col lg="10" md="10" sm="9" xs="9" className="float-left">
                    <Col lg="10" md="10" sm="9" xs="9" className="px-1 poppins_font mb-1 h-5_5rem">
                      <CustomInputField
                        type="text"
                        name="name"
                        label={"Full Name"}
                        onChange={handleChange}
                        labelstyle={{
                          fontSize: "12px",
                          lineHeight: "18px",
                        }}
                        value={educatorDetail.name}
                        required
                      />
                    </Col>
                    <Col lg="10" md="10" sm="9" xs="9" className="px-1 poppins_font mb-1 h-5_5rem">
                      <CustomInputField
                        type="text"
                        name="email"
                        label={"Email Id"}
                        customValidation={handleEmailValidation}
                        onChange={handleChange}
                        labelstyle={{
                          fontSize: "12px",
                          lineHeight: "18px",
                        }}
                        value={educatorDetail.email}
                        required
                      />
                      {educatorDetailError.emailError && educatorDetail.email !== "" && (
                        <div className="d-flex align-items-baseline px-1 py-0 br-23px mb-2">
                          <Image className="w-1rem mr-1 " src={warningIcon} alt="warninig" />
                          <p className="mb-0 fs-14px social-link-error">{educatorDetailError.emailErrorMsg}</p>
                        </div>
                      )}
                    </Col>
                    <Col lg="10" md="10" sm="9" xs="9" className="px-1 poppins_font mb-1 h-5_5rem">
                      <div className="bg-303C54 br-9px p-2 fs-14px mb-2 border_on_hover ">
                        <span className="pl-2 opacity-40 ">Phone Number</span>
                        <PhoneInput
                          className={` w-100 bg-1  border-on-hover-blue ${Style.phoneInputfieldStyle}  px-2 py-1 `}
                          defaultCountry="IN"
                          name="phone"
                          type="text"
                          value={educatorDetail.phone}
                          onChange={handlePhoneInput}
                          placeholder={`Enter phone Number`}
                          autoComplete="off"
                        />
                      </div>

                      {educatorDetailError.phoneError && educatorDetail.phone !== "" && (
                        <div className="d-flex align-items-baseline px-1 py-0 br-23px mb-2">
                          <Image className="w-1rem mr-1 " src={warningIcon} alt="warninig" />
                          <p className="mb-0 fs-14px social-link-error">{educatorDetailError.phoneErrorMsg}</p>
                        </div>
                      )}
                    </Col>
                    <Col lg="10" md="10" sm="9" xs="9" className="px-1 poppins_font mb-1 h-5_5rem">
                      <CustomInputField
                        type="text"
                        name="designation"
                        label={"Designation"}
                        onChange={handleChange}
                        labelstyle={{
                          fontSize: "12px",
                          lineHeight: "18px",
                        }}
                        value={educatorDetail.designation}
                        required
                      />
                    </Col>
                    <button type="submit" className={Style.btn}>
                      Next
                    </button>
                  </Col>
                </div>
              </form>
            )}
            {educatorProfilePageNo === 2 && (
              <EducatorAccess
                cropData={cropData}
                educatorDetail={educatorDetail}
                specificCourseFlag={specificCourseFlag}
                specificClassesFlag={specificClassesFlag}
                courseFlag={courseFlag}
                setCoursePermissions={setCoursePermissions}
                setClassesPermission={setClassesPermission}
                setShowCourseModal={setShowCourseModal}
                setShowClassesModal={setShowClassesModal}
                handleAddEducator={handleAddEducator}
                loader={loader}
                classFlag={classFlag}
                isDisabled={isDisabled}
                setEducatorProfilePageNo={setEducatorProfilePageNo}
                educatorProfilePageNo={educatorProfilePageNo}
                pageno={pageno}
                setPageno={setPageno}
                apicall={apicall}
                role={role}
              />
            )}
          </Col>
        </div>
      )}
    </>
  )
}
Educator.propTypes = {
  educatorDetail: PropTypes.shape({
    avatar: PropTypes.any, //profile picture
    name: PropTypes.string.isRequired, // full name data
    email: PropTypes.string.isRequired, // email id data
    phone: PropTypes.number.isRequired, // phone number data
    designation: PropTypes.string.isRequired, // designation value
  }),
  setEducatorDetail: PropTypes.func.isRequired, //function to set educator detail data
}

export default Educator
