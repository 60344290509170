import React, { useContext } from "react"
import SeeMore from "../../custom/see_more"
import FacilitatorEngagementCard from "./FacilitatorEngagementCard"

import { InsightsReportsContext } from "../../../stores/insights_reports/InsightsReportsStore"
import { SET_SCHOOL_LEVEL_REPORTS_DATA } from "../../../stores/insights_reports/InsightsReportsActions"

const FacilitatorEngagement = (props) => {
  const facilitators = props.data?.results
  const [state, dispatch] = useContext(InsightsReportsContext)

  async function fetchMoreFacilitators(response) {
    if (response.success) {
      let copy = {
        ...state.schoolLevelReportsData[`school_${state.activeSchoolData?.id}_period_${state.schoolLevelTimePeriod}_${state.inClassOrOverall}`]
          .facilitator_wise_engagement,
      }
      copy.results = [...copy.results, ...response.data.results]
      copy.next = response.data.next
      dispatch({
        type: SET_SCHOOL_LEVEL_REPORTS_DATA,
        payload: {
          key: `school_${state.activeSchoolData?.id}_period_${state.schoolLevelTimePeriod}_${state.inClassOrOverall}`,
          report_type: `facilitator_wise_engagement`,
          value: copy,
        },
      })
    }
  }

  const showFacilitatorList = () => {
    if (facilitators?.length === 0) {
      return (
        <div className="w-100 d-flex h-100 justify-center align-items-center">
          <p className="fs-14px bold-500 w-100 text-center">No facilitators found.</p>
        </div>
      )
    } else {
      return facilitators?.map((facilitator) => {
        return <FacilitatorEngagementCard key={`facilitator_${facilitator.id}`} facilitator={facilitator} />
      })
    }
  }

  return (
    <div className="w-100 h-32vh overflow-y-scroll">
      {showFacilitatorList()}
      {props.data?.next && (
        <SeeMore
          url={props.data?.next}
          handleData={(response) => {
            fetchMoreFacilitators(response)
          }}
        />
      )}
    </div>
  )
}

export default FacilitatorEngagement
