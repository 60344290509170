import React, { useCallback } from "react"
import _ from "lodash"

import Col from "react-bootstrap/Col"

import TextArea from "../custom/TextArea"

import Bulb from "../../images/svgs/bulb.svg"

function InstructionsField(props) {
  const matchingData = props.matchingData

  const delayedQuery = useCallback(
    _.debounce((name, value, data) => sendQuery(name, value, data), 1000),
    []
  )

  function sendQuery(name, value, data) {
    props.handleUpdateMatchingData(name, value, data)
  }

  return (
    <Col lg="12" md="12" sm="12" xs="12" className="px-2" style={{ borderBottom: "1px dashed #E0E2E570" }}>
      <TextArea
        style={{ background: "#42506C" }}
        name="instructions"
        rows="2"
        label="Type Instructions here"
        defaultValue={matchingData.instructions}
        onChange={(e) => {
          if (props.type === "create") {
            props.handleChange(e, "instructions")
          } else {
            props.setSavedContent(false)
            delayedQuery(e.target.name, e.target.value, matchingData)
          }
        }}
        onBlur={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (props.type === "edit") {
            if (e.target.value !== matchingData.instructions) {
              props.setSavedContent(false)
              props.handleUpdateMatchingData(e.target.name, e.target.value, matchingData)
            }
          }
        }}
        required
      />
      <div className="d-inline-flex w-100 align-items-stretch mb-3">
        <img src={Bulb} alt={`idea`} className={`mr-2 mb-0`} style={{ objectFit: `contain`, height: `20px` }} />
        <p className="mb-0" style={{ fontSize: "14px", color: "#E0E2E560" }}>
          Rows in each column are matched against each other. Please ensure the correct match is in same row for both the columns. The options will be auto
          randomised for students
        </p>
      </div>
    </Col>
  )
}

export default InstructionsField
