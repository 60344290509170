import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import FormGroup from "react-bootstrap/FormGroup"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import queryString from "query-string"

import { LazyLoadImage } from "react-lazy-load-image-component"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import Notification from "../custom/Notification"
import { isBrowser } from "../../services/developer_mode"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile, faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const CreateImage = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [trainer, setTrainer] = useState({})
  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    fetchTrainer()
  }, [])

  async function fetchTrainer() {
    // console.log("fetchGroups called")
    // setCompanyLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/trainers/${location.id}/`, { headers: login_credentials }).then((res) => {
      setTrainer(res.data)
      // setCompanyLoader(true)
    })
  }

  function handleChange(event) {
    event.preventDefault()
    trainer[event.target.name] = event.target.value
  }

  function handleselectedFile(event) {
    event.preventDefault()
    trainer[event.target.name] = event.target.files[0]
  }

  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in trainer) {
      if (k != "avatar") {
        formData.append(k, trainer[k])
      }
    }
    if (document.querySelector(".form-control-file").value.length > 0) {
      formData.append("avatar", trainer["avatar"])
    }
    if (location.id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/trainers/" + location.id + "/", formData, { headers: login_credentials })
        .then((res) => {
          navigate(redirection_url)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          // console.log(error, "sssasas")
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     "error_messages" +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    }
  }

  let window_location = window.location
  let { redirection_url } = queryString.parse(window_location.search)
  redirection_url = redirection_url !== undefined && redirection_url !== "undefined" ? `${redirection_url}` : "/app/dashboard"

  return (
    <div>
      <SEO title="Trainer Edit" />
      <Link className={`pointer text-white`} to={redirection_url}>
        {" "}
        <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
      </Link>
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className={`add-links justify-content-center text-white padding-mob`}
        style={{ alignItems: "center", padding: "1rem 7rem" }}
      >
        <Col>
          {" "}
          <h4>Add Trainer</h4>{" "}
        </Col>
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <Col xs="12" sm="6" md="12">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faFile} style={{ color: "#a9adba", marginBottom: "10px", width: `20px`, height: `20px` }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Name of the trainer"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                defaultValue={trainer.name}
                name="name"
                id="name"
              />
            </InputGroup>
            {/*<FormGroup>
                              <Form.Label htmlFor="name">Name</Form.Label>
                              <input type="text" name="name" defaultValue={trainer.name} onChange={handleChange} id="name" placeholder="Enter trainer name" required />
                            </FormGroup>*/}
          </Col>
          <Col xs="12" sm="6" md="12">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faFile} style={{ color: "#a9adba", marginBottom: "10px", width: `20px`, height: `20px` }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Description of the trainer"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                defaultValue={trainer.description}
                name="name"
                id="name"
              />
            </InputGroup>
          </Col>
          <Col xs="12" sm="6" md="12">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faFile} style={{ color: "#a9adba", marginBottom: "10px", width: `20px`, height: `20px` }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Profile url of the trainer"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                defaultValue={trainer.linkedin_url}
                name="name"
                id="name"
              />
            </InputGroup>
          </Col>
          <Col xs="12" sm="6" md="12">
            <FormGroup>
              <Form.Label htmlFor="avatar">Avatar</Form.Label>
              <Form.File name="avatar" className="form-control-file-avatar" onChange={handleselectedFile} required={trainer.id == undefined ? true : false} />
              {(() => {
                if (trainer.avatar && trainer.avatar.length > 0) {
                  return <LazyLoadImage src={trainer.avatar} width="200" alt={trainer.name} />
                }
              })()}
            </FormGroup>
          </Col>
          <Col>
            <Button type="submit" style={{ background: "#485b9e", border: "none", fontSize: "10px" }}>
              Save
            </Button>
          </Col>
        </Form>
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default CreateImage
