import React, { useState } from "react"
import { navigate } from "gatsby"

import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import FormControl from "react-bootstrap/FormControl"

import { DragDropContext } from "react-beautiful-dnd"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faHome } from "@fortawesome/free-solid-svg-icons"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { isBrowser } from "../../../services/developer_mode"

import Styles from "../../../modularscss/styles.module.scss"

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  outline: "none",
  padding: grid,
  margin: `0`,
  borderRadius: "20px",
  textAlign: "center",
  color: "#fff",

  // change background colour if dragging
  background: isDragging ? "#000" : "#12172C",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#000" : "#12172c",
  padding: grid,
})

const ViewFocusAreaQuestions = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  // each ques type full data in allResources array
  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(false)

  const [searchValue, setSearchValue] = useState("")

  function onDragEnd(result) {}

  return (
    <div>
      <SEO title="Focus Area Questions" />
      <DragDropContext onDragEnd={onDragEnd}>
        <Col lg="12" md="12" sm="12" xs="12" className={`px-0 d-inline-flex float-left`}>
          <div className={`float-left position-absolute`} style={{ width: `30%` }}>
            {/*<div className={`float-left`} style={{ backgroundColor: `#2e3446` }}>
              <div style={{ padding: `15px 15px 0px 15px` }}>
                <div className={`col-12 d-inline-flex`} style={{ backgroundColor: `#414a6c` }} >
                  <Button key={0} variant="none" className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100 w-100`} onClick={(e) => {e.preventDefault();navigate(`/app/dashboard/`)}} style={{ backgroundColor: `transparent`, color: `#c6cff4`, borderRadius: `0px`, minWidth: `fit-content`, opacity: 1 }} >
                    <FontAwesomeIcon icon={faHome} style={{ width: `20px`, height: `20px` }} />
                    Home
                  </Button>
                  <FontAwesomeIcon icon={faSearch} size="lg" style={{ margin: `auto 0px`, color: `#7c84a0` }} />
                  <FormControl
                    autoFocus
                    className="border-0 py-1 px-2 form-control px-0 shadow-none font-14"
                    style={{ backgroundColor: `transparent` ,color: `#b6bdd4` }}
                    placeholder={`search Questions`}
                    onChange={(e) => {e.preventDefault();setSearchValue(e.target.value);setAllResourcesFetched(false)} }
                    value={searchValue}
                  />
                </div>
              </div>
              {showResourcesButton(`resource`,currentSelectedResourcesType)}
              <Button onClick={()=>navigate(`/app/upload?redirection_url=${page_uri}`)} className='position-fixed-button' > Upload More Content </Button>
            </div>*/}
          </div>
        </Col>
      </DragDropContext>
    </div>
  )
}

export default ViewFocusAreaQuestions
