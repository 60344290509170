import React, { useContext, useEffect } from "react"
import Style from "./style.module.scss"
import CheckMark from "../../images/svgs/checkmark.svg"
import DefaultSchoolImage from "../../images/default_school.png"
import { useState } from "react"
import { navigate } from "gatsby"
import { getRequest, postRequest } from "../../services/rest_service"
import Json from "../utilities/json"
import { SET_COMPANY_DETAILS } from "../../stores/sidebar_store/SidebarActions"
import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import LocalStorage from "../../utils/LocalStorageAccess"
import { PERMISSION } from "../../constants/LocalStorageConstant"
import { Auth } from "../../services/auth"

const SignupSuccessRedirection = () => {
  const [sidebarState, dispatch] = useContext(SidebarContext)
  const [timeLeft, setTimeLeft] = useState(7)
  const [school, setSchool] = useState({})
  const [error, setError] = useState({})
  const auth = new Auth()

  useEffect(() => {
    getOrCreateSchool(Json.toJson(localStorage.getItem("organisation")))
  }, [])
  useEffect(() => {
    if (timeLeft === 0) {
      navigate("/app/school-setup")
    }
  }, [timeLeft])

  const startTimer = () => {
    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
    }, 1000)
  }

  const getOrCreateSchool = async (organisation) => {
    let res = await getRequest(`/companies/?organisation=${organisation.id}`)
    if (res.success) {
      if (res.data?.results.length > 0) {
        setSchool(res.data?.results[0])
        localStorage.setItem("company_id", res.data?.results[0].id)
        localStorage.setItem("company_name", res.data?.results[0]?.name)
        localStorage.setItem("company_logo", res.data?.results[0]?.avatar)
        dispatch({ type: SET_COMPANY_DETAILS, payload: { name: res.data.results[0]?.name, logo: res.data.results[0]?.avatar } })
        startTimer()
      } else {
        createSchool(organisation)
      }
    }
  }

  const createSchool = async (organisation) => {
    let res = await postRequest("/companies/create/", {
      organisation: organisation.id,
      sub_folder: organisation.domain,
      name: organisation.name,
      subdomain: organisation.domain,
    })
    if (res.success) {
      localStorage.setItem("company_id", res.data.id)
      localStorage.setItem("company_name", res.data?.name)
      localStorage.setItem("company_logo", res.data?.avatar)
      localStorage.setItem("school_exists", true)
      localStorage.setItem("school_setup", false)
      LocalStorage.setData(PERMISSION, res.data.permission)
      startTimer()
      auth.fetchUser()
    } else {
      setError(res.data)
    }
  }
  return (
    <div className={Style.main_div}>
      {school?.id ? (
        <React.Fragment>
          <div className={Style.icon_div}>
            <img src={DefaultSchoolImage} alt="School-Default-Image" className={Style.schoolimg} />
            <img src={CheckMark} alt="CheckMark" className={Style.checkmark} />
          </div>
          <p className={Style.p1}>Your online school been created successfully</p>
          <p className={Style.p2}>{school.name}</p>
          <div className={Style.sub_div}>
            <p className={Style.p3}>{school.name}</p>
          </div>
          <p className={Style.p4}>{`Redirecting to admin dashboard in ${timeLeft}s…`}</p>
        </React.Fragment>
      ) : (
        <div className="text-center fs-18px poppins_font">Creating online school...</div>
      )}
    </div>
  )
}

export default SignupSuccessRedirection
