import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import Dropdown from "react-bootstrap/Dropdown"

import Layout from "../../../../../../components/layout"
import SEO from "../../../../../../components/seo"
import authClient from "../../../../../../services/auth"
import { isBrowser } from "../../../../../../services/developer_mode"

const CreateTopicResource = (location) => {
  var url = new URL(window.location.href)
  var c = url.searchParams.get("resource_type")
  const [topicResource, setTopicResource] = useState({ add_to_topic: location.topic_id, resource_type: c })
  // if (c === 'video') {
  //   searchVideos()
  // } else if (c === 'blog') {
  //   searchBlogs()
  // }
  // }

  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  // const [topicResource, setTopicResource] = useState({add_to_topic: location.topic_id})
  const [searchValue, setSearchValue] = useState("")
  const [selectedTopicResource, setSelectedTopicResource] = useState(null)
  const [selectedTopicResourceType, setSelectedTopicResourceType] = useState(c)

  // console.log(location,course, "location.dilsjdio")
  // const { state = {} } = location

  const [allData, setAllData] = useState([])

  useEffect(() => {
    if (topicResource.resource_type === "video") {
      searchVideos()
    } else if (topicResource.resource_type === "blog") {
      searchBlogs()
    }
  }, [topicResource.resource_type])

  function searchVideos(search = "") {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-videos-list/?search=` + search, { headers: login_credentials }).then((res) => {
      setAllData(res.data.results)
    })
  }

  function searchBlogs(search = "") {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-blogs-list/?search=` + search, { headers: login_credentials }).then((res) => {
      setAllData(res.data.results)
    })
  }

  function selectItem(e, field, data) {
    e.preventDefault()
    topicResource[field] = [data.id]
    setSelectedTopicResource(data.name)
  }

  function handleChange(event) {
    event.preventDefault()
    if (event.target.name === "resource_type") {
      topicResource[event.target.name] = event.target.value
      setSelectedTopicResourceType(event.target.value)
    } else topicResource[event.target.name] = event.target.value
  }
  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in topicResource) {
      formData.append(k, topicResource[k])
    }
    if (topicResource.id != undefined) {
    } else {
      var resource_id = topicResource.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
        .then((res) => {
          navigate(`/app/default-course-templates/${location.template_id}/stages/${location.stage_id}/modules/${location.module_id}/topics/view/`)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  return (
    <div>
      <SEO title="Add Topic Resource" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>Add Topic Resource</h4>
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col>
              <div>
                <Row>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="sequence">sequence</label>
                      <input
                        type="number"
                        name="sequence"
                        defaultValue={topicResource.sequence}
                        onChange={handleChange}
                        id="sequence"
                        placeholder="Enter resource sequence"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="event_type">Resource Type</label>
                      <Form.Control
                        as="select"
                        name="resource_type"
                        id="resource_type"
                        defaultValue={topicResource.resource_type ? topicResource.resource_type : ""}
                        onChange={handleChange}
                      >
                        <option value="">Select TopicResource Type</option>
                        <option value="video">Video</option>
                        <option value="blog">Blog </option>
                      </Form.Control>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12">
                    {selectedTopicResourceType != null ? (
                      <FormGroup>
                        <label htmlFor="resource">Add Resource</label>
                        <Dropdown>
                          <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            {selectedTopicResource !== null ? selectedTopicResource : "Select TopicResource"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <FormControl
                              autoFocus
                              className="border-0 py-1 form-control px-0 shadow-none font-14"
                              placeholder="Search Video"
                              onChange={(e) => {
                                topicResource.resource_type ? searchVideos(e.target.value) : searchBlogs(e.target.value)
                                setSearchValue(e.target.value)
                              }}
                              value={searchValue}
                            />
                            {allData.map((node, index) => (
                              <Dropdown.Item key={index.toString() + "resource_data"} name="resource_id" onClick={(e) => selectItem(e, "resource_id", node)}>
                                {node.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </FormGroup>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Button type="submit" color="warning">
              Save
            </Button>{" "}
          </Form>
          <Button
            onClick={() =>
              navigate(`/app/default-course-templates/${location.template_id}/stages/${location.stage_id}/modules/${location.module_id}/topics/view/`)
            }
            variant="danger"
          >
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CreateTopicResource
