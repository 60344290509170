import React, { useEffect, useState } from "react"

import SEO from "../../components/seo"
import { isBrowser } from "../../services/developer_mode"

import Helmet from "react-helmet"

// const kaltura = require('kaltura-client');

// const config = new kaltura.Configuration();
// const config = new kaltura.Configuration();
// const client = new kaltura.Client(config);

// const myExtScript = require('../../../express-recorder.js')

const KaltureEditVideo = (location) => {
  // let shouldStop = false;
  // let stopped = false;
  // const downloadLink = document.getElementById('download');
  // const stopButton = document.getElementById('stop');

  // let [options, setOptions] = useState({
  //   "ks": "djJ8MzAzMDI1MXzA5YS1Y80F57nbRqJEh5qREAHm4idAq1Bzz2fZDl73uCUih4dU-yuKFL8Z2OlJrPV6Jo_ADfyBtxoXifgFoOlQ",
  //   "serviceUrl": "https://www.kaltura.com",
  //   "playerUrl": "https://cdnapisec.kaltura.com",
  //   "app": "leanpitch",
  //   "conversionProfileId": null,
  //   "partnerId": "3030251",
  //   "entryName": "",
  //   "uiConfId": 46386761
  // })
  // let [started, setStarted] = useState(false)
  // let [mediaRecorder, setMediaRecorder] = useState({})

  // // useEffect(() => {
  // //   handleSuccess()
  // // }, [shouldStop])

  // function toggleStop() {
  //   console.log(mediaRecorder, "mediaRecorder")
  //   mediaRecorder.stop()
  // }

  // // if (stopButton != null){
  // //   console.log("came here");

  // // }

  // // function handleSuccess(stream) {

  // // };

  // if (navigator.mediaDevices.getUserMedia) {
  //   var constraints = { audio: true, video: true };
  //   var chunks = [];

  //   var onSuccess = function(stream) {
  //     var options = {
  //       audioBitsPerSecond : 128000,
  //       videoBitsPerSecond : 2500000,
  //       mimeType : 'video/mp4'
  //     }
  //     const options = {mimeType: 'video/webm'};
  //     const recordedChunks = [];
  //     setMediaRecorder(new MediaRecorder(stream, options));

  //     mediaRecorder.ondataavailable = function(e) {
  //       console.log("shouldStop, stopped, e")
  //       if (e.data.size > 0) {
  //         recordedChunks.push(e.data);
  //       }

  //       if(shouldStop === true && stopped === false) {
  //         mediaRecorder.stop();
  //         setStopped(true)
  //       }
  //     }

  //     mediaRecorder.onstop = function(e) {
  //       console.log("came inside stop")
  //       downloadLink.href = URL.createObjectURL(new Blob(recordedChunks));
  //       downloadLink.download = 'acetest.webm';
  //     }

  //     mediaRecorder.start();
  //     // setMediaRecorder(new MediaRecorder(stream,options));
  //     // handleSuccess(stream, options)
  //   }
  // }

  // navigator.mediaDevices.getUserMedia({ audio: true, video: true })
  //     .then(handleSuccess);

  // const displayScript = () => {
  //   let options = {
  //         "ks": "",
  //         "serviceUrl": "https://www.kaltura.com",
  //         "playerUrl": "https://cdnapisec.kaltura.com",
  //         "app": "appName",
  //         "conversionProfileId": null,
  //         "partnerId": "3030251",
  //         "entryName": "",
  //         "uiConfId": ""
  //       }
  //   let is_script = (Kaltura === '' || Kaltura === undefined) ? false : true
  //   if (is_script) {
  //     return(
  //       <>
  //         <script src="https://www.kaltura.com/apps/expressrecorder/latest/express-recorder.js"></script>
  //         <script type="text/javascript">
  //           Kaltura.ExpressRecorder.create('recorder', options)
  //         </script>
  //       </>
  //     )
  //   {}
  // }

  // let options = {
  //   "ks": "djJ8MzAzMDI1MXzA5YS1Y80F57nbRqJEh5qREAHm4idAq1Bzz2fZDl73uCUih4dU-yuKFL8Z2OlJrPV6Jo_ADfyBtxoXifgFoOlQ",
  //   "serviceUrl": "https://www.kaltura.com",
  //   "playerUrl": "https://cdnapisec.kaltura.com",
  //   "app": "leanpitch",
  //   "conversionProfileId": null,
  //   "partnerId": "3030251",
  //   "entryName": "",
  //   "uiConfId": 46386761
  // }
  // let component = Kaltura.ExpressRecorder.create('recorder', options)
  // if (isBrowser()){
  // setTimeout(function(){

  // }, 10000)
  // }

  // setInterval(function(){
  //   console.log("Kaltura", Kaltura)
  //   let is_script = (Kaltura === '' || Kaltura === undefined) ? false : true
  //   if (is_script && started === false){
  //     Kaltura.ExpressRecorder.create('recorder', options)
  //     setStarted(true)
  //   }else{
  //     setStarted(false)
  //   }
  // }, 1000)

  // function startVideo() {
  //   let is_script = (Kaltura === '' || Kaltura === undefined) ? false : true
  //   if (started === false && Kaltura.ExpressRecorder != undefined){
  //     Kaltura.ExpressRecorder.create('recorder', options)
  //     setStarted(true)
  //   }else{
  //     setStarted(false)
  //   }
  // }

  // const options =

  let options = []
  options["ks"] = "djJ8MzAzMDI1MXxmBdB7_UOyeFeGe524L20a5IcQ_Ljznl_641ij6bQh5GUnKwppFLv3sbguOKAeCvCVUDJclcc0tm0Qz0fjru-4"
  options["service_url"] = "https://cdnapisec.kaltura.com"
  options["playerUrl"] = "https://cdnapisec.kaltura.com"
  options["app"] = "leanpitch"
  options["conversionProfileId"] = null
  options["partner_id"] = "3030251"
  options["entry_id"] = "1_p2jku04c"
  options["player_uiconf_id"] = 46356311
  options["preview_player_uiconf_id"] = 46356311
  options["load_thumbnail_with_ks"] = true
  options["language_code"] = "en"
  options["help_link"] = "https://leanpitch.com/contact"

  options["tabs"] = {
    edit: {
      name: "edit",
      permissions: ["clip", "trim"],
      userPermissions: ["clip", "trim"],
      showOnlyExpandedView: true,
      showSaveButton: true,
      showSaveAsButton: true,
    },
    // 'quiz': {
    //   name: 'quiz',
    //   permissions: [ 'quiz', 'questions-v2', 'questions-v3' ],
    //   userPermissions: [ 'quiz' ]
    // },
    // 'advertisements': {
    //   name: 'advertisements',
    //   permissions: [ 'CUEPOINT_MANAGE', 'FEATURE_DISABLE_KMC_KDP_ALERTS' ],
    //   showSaveButton: true
    // },
    hotspots: {
      name: "hotspots",
      showSaveButton: true,
    },
  }

  var initParamsListener = window.addEventListener("message", function (e) {
    var postMessageData
    try {
      postMessageData = e.data
      if (postMessageData.messageType === "kea-bootstrap") {
        e.source.postMessage(
          {
            messageType: "kea-config",
            data: options,
          },
          e.origin
        )
      }
    } catch (ex) {
      return
    }
  })

  if (isBrowser()) {
    return (
      <React.Fragment>
        <Helmet>
          <script src="https://www.kaltura.com/apps/expressrecorder/latest/express-recorder.js"></script>
          <script src="https://cdnapi.kaltura.com/p/3030251/sp/303025100/embedIframeJs/uiconf_id/46386761/partner_id/3030251"></script>
          <script type="text/javascript"></script>
        </Helmet>
        <SEO title="Kaltura Video Creation" />
        <iframe src="https://cdnapisec.kaltura.com/apps/kea/latest/index.html" style={{ width: "1200px", height: "800px" }}></iframe>
      </React.Fragment>
    )
  } else {
    return ""
  }
}

export default KaltureEditVideo
