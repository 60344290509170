import React, { useEffect, useState, useCallback, useContext } from "react"
import debounce from "lodash/debounce"

import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import FormControl from "react-bootstrap/FormControl"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Modal from "react-bootstrap/Modal"
import Dropdown from "react-bootstrap/Dropdown"
import Spinner from "react-bootstrap/Spinner"
import InputGroup from "react-bootstrap/InputGroup"

import { Rnd } from "react-rnd"
import SunEditor from "suneditor-react"

import SEO from "../../components/seo"
import StyleA from "../resources-dashboard.module.scss"
import { isBrowser } from "../../services/developer_mode"
import { getRequest, postRequest, deleteRequest, putRequest } from "../../services/rest_service"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../stores/global_store/GlobalActions"

import BackButton from "../../images/svgs/back-arrow.svg"
import Search from "../../images/svgs/search.svg"
import AngleDown from "../../images/svgs/down_arrow_white.svg"
import CheckIcon from "../../images/svgs/check-white.svg"
import ChevronCircleUp from "../../images/svgs/arrow-dropup-white-circle.svg"
import ChevronCircleDown from "../../images/svgs/arrow-dropdown-white-circle.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import TrashCan from "../../images/svgs/trash-can-transparent.svg"
import CancelWhite from "../../images/svgs/close.svg"
import DragHandleIcon from "../../images/svgs/drag-handle-white.svg"
import CancelIcon from "../../images/svgs/cancel-white-icon.svg"

import Styles from "../../modularscss/styles.module.scss"
import StylesA from "../quizzes/quiz.module.scss"
import StylesB from "../courses/course.module.scss"
import Image from "../custom/Image"
import Btn from "../custom/Button"
import { navigate } from "gatsby"

const ViewQuizQuestions = (location) => {
  const [questions, setQuestions] = useState([])
  const [nextQuestionsUrl, setNextQuestionsUrl] = useState("")
  const [allQuestionsFetched, setAllQuestionsFetched] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  const [questionCreated, setQuestionCreated] = useState(true)
  const [createdQuestion, setCreatedQuestion] = useState("")
  const [questionOptions, setQuestionOptions] = useState({})
  const [optionCreated, setOptionCreated] = useState(true)

  // New fields
  const [editLoader, setEditLoader] = useState(false)
  const [dmodal, setDmodal] = useState(false)
  const [questionToggle, setQuestionToggle] = useState({})
  const [itemToDelete, setItemToDelete] = useState("")
  const [showAddDescriptionmodal, setShowAddDescriptionModal] = useState(false)
  const [activeQuestion, setActiveQuestion] = useState("")
  const [questionType, setQuestionType] = useState(null)
  const [questionLevel, setQuestionLevel] = useState(null)
  const [questionOrder, setQuestionOrder] = useState("new")

  const [showRichTextModal, setShowRichTextModal] = useState(false)
  const [activeSubjectiveQuetion, setActiveSubjectiveQuetion] = useState(null)
  const [draggableHash, setDraggableHash] = useState({ x: 0, y: 0, width: 724, height: 486 })
  const [globalState, dispatch] = useContext(GlobalContext)

  const questionLevelType = ["Easy", "Medium", "Difficult"]
  const question_type_data = ["", "Objective", "Subjective"]

  useEffect(() => {
    if (isBrowser() && document.getElementsByClassName("parent") && document.getElementsByClassName("parent").length > 0) {
      let x = parseInt(document.getElementsByClassName("parent")[0].offsetWidth - 724 - 80) / 2
      let y = parseInt(document.getElementsByClassName("parent")[0].offsetHeight - 486) / 2
      setDraggableHash({ x: x, y: y, width: 724, height: 486 })
    }
  }, [showRichTextModal])

  useEffect(() => {
    fetchAllQuestions(searchValue)
  }, [questionLevel, questionType, questionOrder])

  useEffect(() => {
    if (questionCreated && createdQuestion && createdQuestion.question_type === "1") {
      fetchOptions(createdQuestion.id)
      setCreatedQuestion("")
    }
  }, [questionCreated])

  async function fetchAllQuestions(searchValue) {
    setAllQuestionsFetched(false)
    let search = searchValue === "" ? "" : `&search=${searchValue}`
    let type = questionType === null ? "" : `&question_type=${questionType}`
    let level = questionLevel === null ? "" : `&question_level=${questionLevel}`
    let res = await getRequest(`/quiz-question/question_bank_questions/?limit=15&order=${questionOrder}${search}${type}${level}`)
    if (res.success) {
      setQuestions(res.data.results)
      setNextQuestionsUrl(res.data.next)
      setAllQuestionsFetched(true)
    }
  }

  async function fetchNextAllQuestions() {
    if (nextQuestionsUrl) {
      let res = await getRequest(nextQuestionsUrl)
      if (res.success) {
        let questions_data = [...questions]
        questions_data = questions_data.concat(res.data.results)
        setQuestions(questions_data)
        setNextQuestionsUrl(res.data.next)
      }
    }
  }

  async function fetchOptions(id, allowCreationOfOptions = false, currentQuestion = []) {
    if (questionOptions["question_" + id] === undefined || allowCreationOfOptions === "refetch") {
      let res = await getRequest(`/question-option/?quiz_question=${id}`)
      if (res.success) {
        let options_data = { ...questionOptions }
        options_data["question_" + id] = res.data.results
        setQuestionOptions(options_data)
        handleQuestionIsValid(id, options_data, [...questions])
        document.getElementById(id + `-question-input-id`).focus()
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  async function createQuestion(value) {
    setQuestionCreated(false)
    let formData = new FormData()
    formData.append("question_type", value)
    formData.append("title", "Question " + (questions.length + 1).toString())
    let res = await postRequest("/quiz-question/", formData)
    if (res.success) {
      let questions_data = [...questions]
      questions_data.unshift(res.data)
      setQuestions(questions_data)
      setCreatedQuestion(res.data)
      setQuestionCreated(true)
      handleQuestionToggle(res.data.id, res.data.question_type, false)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }
  async function deleteQuestion(question_id) {
    let res = await deleteRequest(`/quiz-question/${question_id}/`)
    if (res.success) {
      setDmodal(false)
      setItemToDelete("")
      let questions_data = [...questions]
      let deleted_question_index = questions_data.findIndex((element) => element.id === question_id)
      questions_data.splice(deleted_question_index, 1)
      setQuestions(questions_data)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function deleteOption(option_id, question_id, index) {
    let res = await deleteRequest(`/question-option/${option_id}/`)
    if (res.success) {
      setDmodal(false)
      setItemToDelete("")
      let options_data = { ...questionOptions }
      let questions_data = [...questions]
      options_data["question_" + question_id].splice(index, 1)
      setQuestionOptions(options_data)
      handleQuestionIsValid(question_id, options_data, questions_data)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function createOption(question_id, second = false) {
    setOptionCreated(false)
    setEditLoader(true)

    let option_name = `sample option console`
    let formData = new FormData()
    formData.append("quiz_question", question_id)
    formData.append("name", option_name)
    let res = await postRequest("/question-option/", formData)
    if (res.success) {
      setQuestionOptions((prev) => {
        let options_data = { ...prev },
          index = "question_" + question_id
        if (!options_data[index]) {
          options_data[index] = []
        }
        let data = { ...res.data }
        data.autoFocusId = res.data.id
        options_data[index] = [...options_data[index], data]
        return options_data
      })
      setOptionCreated(true)
      setEditLoader(false)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  function sendQuery(name, value, item_id, type, questionsData, optionsData, index, question_id) {
    if (type === "question") {
      updateQuestion(name, value, item_id, questionsData, optionsData, index)
    } else {
      updateOption(name, value, item_id, questionsData, optionsData, index, question_id)
    }
  }

  const delayedQuerySearch = useCallback(
    debounce((search) => fetchAllQuestions(search), 1000),
    []
  )

  const delayedQuery = useCallback(
    debounce(
      (name, value, item_id, type, questionsData, optionsData, index, question_id) =>
        sendQuery(name, value, item_id, type, questionsData, optionsData, index, question_id),
      1000
    ),
    []
  )

  function handleUpdateQuestion(index, name = null, value = null, question_id, on_blur = null) {
    if (name === "title") {
      if (on_blur === null) {
        delayedQuery(name, value, question_id, "question", questions, questionOptions, index)
      } else {
        updateQuestion(name, value, question_id, questions, questionOptions, index)
      }
    } else {
      updateQuestion(name, value, question_id, questions, questionOptions, index)
    }
    if (name === "question_type" && value === "1") {
      fetchOptions(question_id, true)
    }
  }

  async function updateQuestion(name = null, value = null, question_id, questionsData, optionsData, index = null) {
    if (name === "title") {
      value = value.trim()
      if (value === "") value = "sample question console"
    }
    setEditLoader(true)

    let formData = new FormData()
    formData.append(name, value)
    if (question_id !== undefined) {
      let res = await putRequest(`/quiz-question/${question_id}/`, formData)
      if (res.success) {
        setEditLoader(false)
        if (questionsData && index >= 0) {
          let questions_data = [...questionsData],
            isValid = questions_data[index].is_valid_question
          questions_data[index][name] = value
          if (["title", "question_type"].includes(name)) {
            let data = optionsData["question_" + question_id]
            if (data !== undefined && questions_data[index].question_type === "1") {
              let rc = handleQuestionIsValid(question_id, optionsData, questions_data, 0)
              if (rc === -1) {
                setQuestions(questions_data)
                return
              }
              isValid = rc
            } else {
              if (value !== "" && value !== "sample question console" && value !== "0") {
                isValid = true
              } else {
                isValid = false
              }
            }
            questions_data[index]["is_valid_question"] = isValid
          }
          setQuestions(questions_data)
        }
      } else {
        setEditLoader(false)
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  function handleQuestionIsValid(question_id, options_data, questions_data, updateQuestion = 1) {
    let data = options_data["question_" + question_id]
    let filtered_data = questions_data.findIndex((ques) => ques.id === question_id)
    if (!data || filtered_data < 0) return -1

    let correct_option_count = data.filter((option) => option.is_correct === true && option.name !== "" && option.name !== "sample option console")
    let question = questions_data[filtered_data]

    let isValid = false
    if (
      (options_data["question_" + question_id].length >= 2 &&
        correct_option_count.length >= 1 &&
        data.length !== correct_option_count.length &&
        question.title &&
        question.title !== "" &&
        question.title !== "sample question console") ||
      question.question_type === "2"
    ) {
      isValid = true
    }
    if (updateQuestion === 1) {
      let dummy = [...questions_data]
      dummy[filtered_data].is_valid_question = isValid
      setQuestions(dummy)
    }
    return isValid
  }

  function handleUpdateOption(index, name, value, option_id, question_id, blur_type = null) {
    let options_data = { ...questionOptions }
    if (name !== "name") {
      let option = options_data["question_" + question_id][index]
      option[name] = value
      setQuestionOptions(options_data)
    }
    if (name === "name") {
      if (blur_type === null) {
        delayedQuery(name, value, option_id, "option", questions, questionOptions, index, question_id)
      } else {
        updateOption(name, value, option_id, questions, questionOptions, index, question_id)
      }
    } else {
      updateOption(name, value, option_id, questions, questionOptions, index, question_id)
    }
  }

  const getRichTextData = () => {
    let index = questions.findIndex((res) => res.id === activeSubjectiveQuetion.id)
    return (
      <SunEditor
        setContents={questions[index].description}
        onChange={(text) =>
          setQuestions((prev) => {
            let prevData = prev
            prevData[index].description = text
            return prevData
          })
        }
        id="description"
        placeholder="Add description"
        setOptions={{
          height: "auto",
          maxHeight: "400px",
          minHeight: "400px",
          buttonList: [
            ["undo", "redo", "font", "fontSize", "formatBlock"],
            ["bold", "underline", "italic", "link", "image", "codeView", "outdent", "indent", "align", "horizontalRule", "list", "table"],
          ],
        }}
      />
    )
  }

  const updateInfo = () => {
    const index = questions.findIndex((res) => res.id === activeSubjectiveQuetion.id)
    updateQuestion("description", questions[index].description, activeSubjectiveQuetion.id)
  }

  const displayData = () => {
    return (
      <div
        id="mod"
        className={`box text-dark p-0 w-100 window_screen`}
        onScroll={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        style={{
          margin: 0,
          height: "100%",
          backgroundColor: "#212C42",
          border: "1px solid #E0E2E5",
          borderRadius: "11px",
          boxShadow: "0px 3px 12px #00000099",
          overflowY: "hidden",
        }}
      >
        <div className={`w-100 py-2 drag d-inline-flex align-items-center`} style={{ borderBottom: "1px solid #E0E2E5", cursor: "move" }}>
          <p className={`mb-0 text-center pl-2 w-100`} style={{ fontSize: "18px", fontWeight: "500", color: "#E0E2E5" }}>
            Add Description Below
          </p>
          <img
            src={CancelIcon}
            alt="description-modal-close"
            className="mb-0 pointer ml-auto mr-2"
            style={{ fontSize: "16px", width: "16px" }}
            onClick={() => {
              setShowRichTextModal(false)
              updateInfo()
            }}
          />
        </div>
        {getRichTextData()}
      </div>
    )
  }

  async function checkOptionCount(index, datum, question_id) {
    let all_option = questionOptions["question_" + question_id]
    let correct_option_count = all_option.filter((option_update) => option_update.is_correct === true)
    if (all_option.length - 1 === correct_option_count.length && !datum.is_correct === true) {
      let error_messages = "Total options and correct answer options cannot be same"
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error_messages } })
    } else {
      setEditLoader(true)
      handleUpdateOption(index, "is_correct", !datum.is_correct, datum.id, question_id)
    }
  }

  async function updateOption(name, value, option_id, questionsData, optionsData, index = null, question_id = null) {
    setEditLoader(true)
    if (name === "name") {
      value = value.trim()
      if (value === "") value = "sample option console"
    }

    let formData = new FormData()
    formData.append(name, value)
    if (option_id !== undefined) {
      let res = await putRequest("/question-option/" + option_id + "/", formData)
      if (res.success) {
        setEditLoader(false)
        if (optionsData && questionsData && index >= 0 && question_id) {
          setQuestionOptions((prev) => {
            let options_data = { ...prev },
              index2 = "question_" + question_id
            if (!options_data[index2]) {
              options_data[index2] = []
            }
            let questions_data = [...questionsData]
            let option = options_data[index2][index]
            option[name] = value
            if (name === "is_correct" || name === "name") {
              handleQuestionIsValid(question_id, options_data, questions_data)
            }
            return options_data
          })
          setEditLoader(false)
        }
      } else {
        setEditLoader(false)
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  function showOptionButtons(options, question) {
    let question_id = question.id
    if (options) {
      return options.map((datum, index) => {
        let datum_id = datum.id.toString()

        return (
          <Col lg="12" md="12" sm="12" xs="12" key={`${datum_id}-option-key-${index}-${question_id}`} className="px-0 d-flex mb-2">
            <div className={`${StylesA.option_div} ${datum.name === "" ? StylesA.question_error_div : "border_on_hover"}`}>
              <div className={`${StylesA.drag_handle_div} px-2 py-1`}>
                <img src={DragHandleIcon} alt={`option-drag-handle`} className={`my-auto`} />
              </div>
              <input
                type="text"
                name="name"
                id={datum.id + `-question-input-option-id`}
                autoFocus={datum?.autoFocusId === datum.id}
                defaultValue={datum.name === "sample option console" ? `` : datum.name}
                onChange={(e) => {
                  handleUpdateOption(index, e.target.name, e.target.value, datum.id, question_id)
                }}
                onBlur={(e) => {
                  e.preventDefault()
                  if (e.target.value !== datum.name) {
                    handleUpdateOption(index, e.target.name, e.target.value, datum.id, question_id, "on_blur")
                  }
                }}
                placeholder={"Enter Option text"}
                className={`${StylesA.question_title_input} form-control px-3`}
                required
              />
              <div
                className={`${datum.is_correct ? StylesA.correct_answer_check : StylesA.wrong_answer_check} pointer`}
                onClick={(e) => {
                  checkOptionCount(index, datum, question_id)
                }}
              >
                <img src={CheckIcon} alt={`check`} className={`m-auto px-2`} />
              </div>
            </div>
            <div
              className={`${StylesA.question_delete_div} pointer ml-3`}
              onClick={(e) => {
                setItemToDelete({ type: "option", datum: datum, index: index })
                setDmodal(true)
              }}
            >
              <img style={{ width: "15px", height: "15px" }} alt={`delete_option`} src={DeleteIcon} className="mb-0" />
            </div>
          </Col>
        )
      })
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function handleDelete() {
    if (itemToDelete) {
      if (itemToDelete.type === "question") {
        deleteQuestion(itemToDelete.datum.id)
      } else {
        deleteOption(itemToDelete.datum.id, itemToDelete.datum.quiz_question, itemToDelete.index)
      }
    }
  }
  function handleQuestionToggle(question_id, type, fetch_options = true) {
    if (type === "1" && fetch_options) {
      fetchOptions(question_id)
    }
    let toggle_data = { ...questionToggle }
    toggle_data[question_id] = !toggle_data[question_id]
    setQuestionToggle(toggle_data)
  }

  function showQuestions() {
    if (allQuestionsFetched) {
      if (questions.length > 0) {
        return questions.map((datum, index) => {
          // let options_error_class = datum.is_valid_answers
          let question_error_class = datum.is_valid_question
          let toggle_class = questionToggle[datum.id]
          return (
            <div
              key={datum.id + `-question-key-` + index}
              className={`br-9px ${StylesA.question_div} ${question_error_class ? "border_on_hover" : StylesA.question_error_div} mb-2`}
            >
              <div className={`${StylesA.question_name_div} w-100 ${toggle_class ? `border-bottom-0` : ``}`}>
                <div onClick={(e) => handleQuestionToggle(datum.id, datum.question_type)} className={`${StylesA.drag_handle_div} px-2 py-1`}>
                  <img src={DragHandleIcon} alt={`question-drag-handle_${datum.id.toString()}`} className={`my-auto`} />
                </div>
                <input
                  type="text"
                  name="title"
                  id={datum.id + `-question-input-id`}
                  defaultValue={datum ? (datum.title === "sample question console" ? `` : datum.title) : ""}
                  placeholder={"Enter Question Title"}
                  onChange={(e) => {
                    handleUpdateQuestion(index, e.target.name, e.target.value, datum.id)
                  }}
                  onBlur={(e) => {
                    e.preventDefault()
                    if (datum.title !== e.target.value) {
                      handleUpdateQuestion(index, e.target.name, e.target.value, datum.id, "on_blur")
                    }
                  }}
                  className={`${StylesA.question_title_input} form-control px-3`}
                  required
                />
                <div className="d-flex align-items-center">
                  <img
                    onClick={(e) => handleQuestionToggle(datum.id, datum.question_type)}
                    src={toggle_class ? ChevronCircleUp : ChevronCircleDown}
                    alt={"arrow for collapse and expand"}
                    className={`${StylesA.arrow_circle} mr-2`}
                  />
                  <img
                    src={DeleteIcon}
                    onClick={(e) => {
                      setItemToDelete({ type: "question", datum: datum })
                      setDmodal(true)
                    }}
                    alt={`question delete icon`}
                    className={`my-auto mr-3 pointer object-fit-contain h-14px`}
                  />
                </div>
              </div>
              {toggle_class ? (
                <div className={`${StylesA.options_section} py-3 pl-4 pr-3 text-center`}>
                  {datum.question_type === 2 || datum.question_type === "2" ? (
                    <u
                      className="pointer fs-14px white-text"
                      onClick={() => {
                        setShowRichTextModal(true)
                        setActiveSubjectiveQuetion(datum)
                      }}
                    >
                      {datum.description.length === 0 || datum.description === "<p><br></p>" ? `Add Subjective Text` : `Edit Subjective Text`}
                    </u>
                  ) : (
                    showOptionButtons(questionOptions["question_" + datum.id], datum)
                  )}
                  <div className={`${StylesA.question_footer} mt-3`}>
                    <div className="d-flex align-items-center">
                      {datum.question_type === 2 || datum.question_type === "2" ? null : (
                        <React.Fragment>
                          <button
                            disabled={!optionCreated}
                            onClick={(e) => {
                              createOption(datum.id)
                            }}
                            className={`${StylesB.add_button} border_on_hover`}
                          >
                            {optionCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                            Add Option
                          </button>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip placement="bottom" className="classroom-tooltip" id={`"tooltip-${datum.id}"`}>
                                <div>
                                  {datum.explanation
                                    ? datum.explanation.length > 90
                                      ? datum.explanation.substring(0, 70) + "..."
                                      : datum.explanation
                                    : "Add Explanation"}
                                </div>
                              </Tooltip>
                            }
                          >
                            <div className={`mx-3 pointer d-flex`}>
                              <button
                                onClick={() => {
                                  setActiveQuestion({ question: datum, index: index })
                                  setShowAddDescriptionModal(true)
                                }}
                                className={`${StylesB.add_button} border_on_hover`}
                              >
                                {datum.explanation ? "Edit Explanation" : "Add explanation"}
                              </button>
                            </div>
                          </OverlayTrigger>
                        </React.Fragment>
                      )}
                      <Dropdown className={`breakout-groups mr-2`}>
                        <Dropdown.Toggle className={`border-none d-inline-flex align-items-center px-2 shadow-none br-23px p-1 bg-586985`} id="dropdown-basic">
                          <div className={`mr-1 fs-12px`}>{questionLevelType[datum.question_level]}</div>
                          <Image src={AngleDown} className={`w-9px h-9px`} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width} fs-12px white-text`}>
                          <Dropdown.Item
                            className="white-text"
                            onClick={(e) => {
                              setEditLoader(true)
                              handleUpdateQuestion(index, "question_level", "0", datum.id)
                            }}
                          >
                            Easy
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="white-text"
                            onClick={(e) => {
                              setEditLoader(true)
                              handleUpdateQuestion(index, "question_level", "1", datum.id)
                            }}
                          >
                            Medium
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="white-text"
                            onClick={(e) => {
                              setEditLoader(true)
                              handleUpdateQuestion(index, "question_level", "2", datum.id)
                            }}
                          >
                            Hard
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown className={`breakout-groups mr-0`}>
                        <Dropdown.Toggle className={`border-none d-inline-flex align-items-center px-2 shadow-none  br-23px p-1 bg-586985`} id="dropdown-basic">
                          <div className={`mr-1 fs-12px`}>{question_type_data[datum.question_type]}</div>
                          <Image src={AngleDown} className={`w-9px h-9px`} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width} fs-12px white-text`}>
                          <Dropdown.Item
                            className="white-text"
                            onClick={(e) => {
                              if (datum.question_type !== "1") {
                                setEditLoader(true)
                                handleUpdateQuestion(index, "question_type", "1", datum.id)
                              }
                            }}
                          >
                            Objective
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="white-text"
                            onClick={(e) => {
                              if (datum.question_type !== "2") {
                                setEditLoader(true)
                                handleUpdateQuestion(index, "question_type", "2", datum.id)
                              }
                            }}
                          >
                            Subjective
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className={`${StylesA.points_input_div}`}>
                      <p>Points:</p>
                      <input
                        type="number"
                        min={1}
                        name="score"
                        defaultValue={parseInt(datum.score)}
                        placeholder={"0"}
                        onChange={(e) => {
                          setEditLoader(true)
                          handleUpdateQuestion(index, e.target.name, e.target.value, datum.id)
                        }}
                        className={`${StylesA.question_point_input} form-control pl-2 pr-0 py-0`}
                        required
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ``
              )}
            </div>
          )
        })
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <h1 className="col-12 float-left fs-16px font-weight-normal color-D3D5E2 opacity-41">OOPS! No questions found for selected filter</h1>
          </Col>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="lg" />
        </Col>
      )
    }
  }
  function showDescriptionInputModal() {
    if (activeQuestion) {
      return (
        <Modal
          show={showAddDescriptionmodal}
          size="lg"
          onHide={() => {
            setShowAddDescriptionModal(false)
            setActiveQuestion("")
          }}
          centered
          className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
        >
          <Modal.Body className="text-center p-0" style={{ backgroundColor: "#212C42", borderRadius: "8px", border: "1px solid #707070", color: "#E0E2E5" }}>
            <Col className={`w-100 align-items-center d-inline-flex`}>
              <div className={`w-100 py-2`}>
                <div className={`w-100 mb-2 d-inline-flex align-items-center`}>
                  <p className={`mb-0`} style={{ color: "#E0E2E5", fontSize: "16px", fontWeight: "500" }}>
                    Add Explanation
                  </p>
                  <img
                    onClick={() => {
                      setShowAddDescriptionModal(false)
                      setActiveQuestion("")
                    }}
                    src={CancelWhite}
                    alt={`Cancel`}
                    className={`ml-auto mb-0 pointer`}
                    style={{ objectFit: `contain`, height: `13px` }}
                  />
                </div>
                <div className={`${StylesB.description_input} px-0 border_on_hover`} style={{ borderRadius: "9px" }}>
                  <InputGroup className="py-0 d-block" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                    <FormControl
                      className={`${StylesB.description_input_field} border-0 d-flex w-100 justify-content-center shadow-none text-white`}
                      style={{ borderRadius: "0px", fontSize: "16px" }}
                      as="textarea"
                      rows="7"
                      type="text"
                      name="explanation"
                      value={activeQuestion.question.explanation}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onChange={(e) => {
                        setEditLoader(true)
                        handleUpdateQuestion(activeQuestion.index, e.target.name, e.target.value, activeQuestion.question.id)
                      }}
                      placeholder=""
                    />
                    {/*{editLoader ?
                    <svg className="animate-spin my-auto" style={{ height: `16px`, width: `16px`,position:'absolute',bottom:'5px',right:'5px',zIndex:3 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg> : null}*/}
                  </InputGroup>
                </div>
              </div>
            </Col>
          </Modal.Body>
        </Modal>
      )
    } else {
      return null
    }
  }

  return (
    <div>
      <SEO title="Quiz Question Bank" />
      <Modal show={dmodal} onHide={() => setDmodal(false)} centered className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}>
        <div className={`${StylesB.delete_modal_header}`}>
          <img onClick={() => setDmodal(false)} src={CancelWhite} alt={`Cancel`} className={`${StylesB.cancel_icon} my-auto mr-1`} />
          <img src={TrashCan} alt={`delete modal delete icon`} className={`${StylesB.trash_can_icon} my-auto mr-2`} />
          <div>
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>Delete</p>
            <p>Item will be deleted permanentley!</p>
          </div>
        </div>
        <Modal.Body className={`${StylesB.delete_modal_body}`}>
          <p> Are you sure you want to delete this {itemToDelete.type}? </p>
          <div className="d-flex justify-content-center">
            <Button className={`${StylesB.add_button_large} border_on_hover mr-3`} variant="secondary" style={{ width: "75px" }} onClick={() => handleDelete()}>
              Yes
            </Button>
            <Button
              className={`${StylesB.add_button_large} border_on_hover`}
              variant="secondary"
              onClick={() => {
                setDmodal(false)
                setItemToDelete("")
              }}
              style={{ width: "75px" }}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <div id="error-div-for-option" className="position-absolute text-white" style={{ top: "0px", right: "0px", zIndex: "2" }}></div>
      {showDescriptionInputModal()}
      <Col xs="12" sm="12" md="12" lg="12" className={`${StylesA.question_bank_main_section} pt-0`}>
        <Col xs="12" sm="12" md="10" lg="10" className={`${StylesA.question_bank_header}`}>
          <div className={`d-flex align-items-center`}>
            <div className="mr-2 fs-20px pointer mb-1" onClick={(e) => navigate(-1)} title={"Go Back to Add Questions"}>
              <Image src={BackButton} className="w-15px h-16px" />
            </div>
            <div className={`font-weight-bolder mr-3 fs-20px`}>All Questions</div>
            <Dropdown className={`breakout-groups mr-0 py-3 px-3 ${StyleA.blue_bg_onhover}`}>
              <Dropdown.Toggle
                className="border-none d-inline-flex align-items-center p-0 shadow-none"
                style={{ backgroundColor: "transparent" }}
                id="dropdown-basic"
              >
                <div className={`mr-1`} style={{ fontSize: "16px" }}>
                  {questionOrder === "new" ? "Newest First" : "Oldest First"}
                </div>
                <Image src={AngleDown} className={`w-9px h-9px`} />
              </Dropdown.Toggle>
              <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width}`} style={{ fontSize: "14px", color: "#E0E2E5" }}>
                <Dropdown.Item
                  style={{ color: "#E0E2E5" }}
                  onClick={() => {
                    setQuestionOrder("old")
                  }}
                >
                  Oldest First
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ color: "#E0E2E5" }}
                  onClick={() => {
                    setQuestionOrder("new")
                  }}
                >
                  Newest First
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className={`breakout-groups mr-0 py-3 px-3 ${StyleA.blue_bg_onhover}`}>
              <Dropdown.Toggle
                className="border-none d-inline-flex align-items-center p-0 shadow-none"
                style={{ backgroundColor: "transparent" }}
                id="dropdown-basic"
              >
                <div className={`mr-1`} style={{ fontSize: "16px" }}>
                  {"Level"}
                </div>
                <Image src={AngleDown} className={`w-9px h-9px`} />
              </Dropdown.Toggle>
              <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width}`} style={{ fontSize: "14px", color: "#E0E2E5" }}>
                <Dropdown.Item
                  style={{ color: "#E0E2E5" }}
                  onClick={() => {
                    setQuestionLevel(null)
                  }}
                >
                  All
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ color: "#E0E2E5" }}
                  onClick={() => {
                    setQuestionLevel(0)
                  }}
                >
                  Easy
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ color: "#E0E2E5" }}
                  onClick={() => {
                    setQuestionLevel(1)
                  }}
                >
                  Medium
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ color: "#E0E2E5" }}
                  onClick={() => {
                    setQuestionLevel(2)
                  }}
                >
                  Hard
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className={`breakout-groups mr-0 py-3 px-3 ${StyleA.blue_bg_onhover}`}>
              <Dropdown.Toggle
                className="border-none d-inline-flex align-items-center p-0 shadow-none"
                style={{ backgroundColor: "transparent" }}
                id="dropdown-basic"
              >
                <div className={`mr-1 fs-16px`}>{"Type"}</div>
                <Image src={AngleDown} className={`w-9px h-9px`} />
              </Dropdown.Toggle>
              <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width} fs-14px white-text`}>
                <Dropdown.Item
                  className={`py-1 white-text`}
                  onClick={() => {
                    setQuestionType(null)
                  }}
                >
                  {" "}
                  All
                </Dropdown.Item>
                <Dropdown.Item
                  className={`py-1 white-text`}
                  onClick={() => {
                    setQuestionType(1)
                  }}
                >
                  Objective
                </Dropdown.Item>
                <Dropdown.Item
                  className={`py-1 white-text`}
                  onClick={() => {
                    setQuestionType(2)
                  }}
                >
                  Subjective
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className="d-flex ml-auto pr-3 bg-303C54 h-45px br-11px">
              <Image src={Search} className={`my-auto ml-2 h-20px w-20px`} />
              <FormControl
                style={{ maxWidth: "400px" }}
                autoFocus
                className={`border-0 py-0 form-control px-2 shadow-none bg-card question_search_bar text-white`}
                defaultValue={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                  delayedQuerySearch(e.target.value)
                }}
                placeholder="Search questions"
              />
            </div>
          </div>
        </Col>
        <Col xs="12" sm="12" md="10" lg="10" className="px-0">
          <div className={`${StylesA.question_bank_content_section} p-3`}>
            <h1 className="col-12 fs-16px font-weight-normal color-D3D5E2 opacity-41">
              {questionOrder === "new" ? "Latest Questions" : "Oldest questions"}
              {questionType === 1 ? ", Type: Objective" : ""}
              {questionType === 2 ? ", Type: Subjective" : ""}
              {questionLevel === 0 ? ", Level: Easy" : ""}
              {questionLevel === 1 ? ", Level: Medium" : ""}
              {questionLevel === 2 ? ", Level: Difficult" : ""}
            </h1>
            {showQuestions()}
            {nextQuestionsUrl ? (
              <Col md="auto" className={`d-inline-flex w-100 my-2`}>
                <Button
                  variant="none"
                  onClick={(e) => {
                    fetchNextAllQuestions()
                  }}
                  className={`${StylesB.add_button} mx-auto`}
                >
                  See More
                </Button>
              </Col>
            ) : (
              ""
            )}
          </div>
          <Col className={`${StylesA.question_bank_footer} px-3 py-2`}>
            <button disabled={!questionCreated} onClick={() => createQuestion("1")} className={`${StylesA.add_question_button} border_on_hover fs-14px`}>
              {questionCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
              Add question
            </button>
            <div className="mb-0 fs-14px">
              {editLoader ? (
                <span className={`bg-transparent border-0`} id={`basic-addon`}>
                  <Spinner animation="border" variant="secondary" size="sm" className="mr-1" />
                </span>
              ) : (
                <img src={CheckIcon} alt={`changes_saved_check`} className={`ml-auto mb-0 mr-1 object-fit-contain`} style={{ height: `11px` }} />
              )}
              All changes are saved
            </div>
          </Col>
        </Col>
      </Col>
      {!showRichTextModal ? null : (
        <div
          className={`w-100 parent h-100 position-fixed`}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setShowRichTextModal(false)
            updateInfo()
          }}
          style={{ inset: "0px", top: "0", bottom: "0", left: "0", right: "0", backgroundColor: "#000000b5" }}
        >
          {isBrowser() && document.getElementsByClassName("parent").length > 0 ? (
            <Rnd
              disableDragging={false}
              dragHandleClassName={"drag"}
              minWidth={324}
              minHeight={191}
              style={{ zIndex: 100 }}
              onClick={(e) => {
                e.stopPropagation()
              }}
              bounds=".parent"
              default={{ x: `50%`, y: `50%`, width: 324, height: 191 }}
              position={{ x: draggableHash.x, y: draggableHash.y }}
              size={{ width: draggableHash.width, height: draggableHash.height }}
              onDragStop={(e, d) => {
                setDraggableHash({ x: d.x, y: d.y, width: draggableHash.width, height: draggableHash.height })
              }}
              onResizeStop={(e, direction, ref, delta, position) => {
                setDraggableHash({ width: ref.style.width, height: ref.style.height, x: position.x, y: position.y })
              }}
            >
              {displayData()}
            </Rnd>
          ) : (
            ``
          )}
        </div>
      )}
    </div>
  )
}

export default ViewQuizQuestions
