import React from "react"

import Styles from "./create.module.scss"

import Col from "react-bootstrap/Col"
import InputField from "../custom/InputField"
import videocam from "../../images/svgs/video-cam.svg"

import { faLink } from "@fortawesome/free-solid-svg-icons"

const VideoCallLinkInput = (props) => {
  function changeHandler(e) {
    if (props.onChange) {
      props.onChange(e)
    }
  }

  function onBlurHandler(e) {
    if (props.onBlur) {
      props.onBlur(e)
    }
  }

  return (
    <>
      <Col lg="12" md="12" sm="12" className={`mx-0 px-0 mt-3`}>
        <div className={`d-flex align-items-center mb-1`} style={{ fontSize: "14px" }}>
          <img className={`mb-0 mr-2`} src={videocam} style={{ height: "15px" }} />
          <span>Video calling interface</span>
        </div>
      </Col>
      <Col className={`${Styles.link_input_wrapper} px-0`}>
        <Col lg="12" md="12" sm="12">
          <InputField
            type="text"
            defaultValue={props.defaultValue ? props.defaultValue : ""}
            onChange={changeHandler}
            onBlur={onBlurHandler}
            name="video_call_link"
            label={"Provide a link"}
            icon={{ name: faLink }}
          />
        </Col>
      </Col>
      <Col className="pb-2"></Col>
    </>
  )
}

export default VideoCallLinkInput
