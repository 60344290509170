import React, { useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

const CreateVideo = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [video, setVideo] = useState({})

  // console.log(location,course, "location.dilsjdio")
  // const { state = {} } = location

  function handleChange(event) {
    event.preventDefault()
    if (event.target.name === "end_date" || event.target.name === "start_date") {
      video[event.target.name] = new Date(event.target.value)
    } else video[event.target.name] = event.target.value
    //console.log(video, "video")
  }
  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in video) {
      formData.append(k, video[k])
    }
    formData.append("add_to_template", true)

    if (video.id != undefined) {
    } else {
      var video_id = video.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/videos/create/", formData, { headers: login_credentials })
        .then((res) => {
          navigate("/app/videos")
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }
  function toggle(e, type) {
    e.preventDefault()
  }

  return (
    <div>
      <SEO title="Video Creation" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>Add video</h4>
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col>
              <div>
                <Row>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="url">url</label>
                      <input type="text" name="url" defaultValue={video.url} onChange={handleChange} id="url" placeholder="Enter video url" required />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" defaultValue={video.name} onChange={handleChange} id="name" placeholder="Enter video name" required />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="description">description</label>
                      <input
                        type="text"
                        name="description"
                        defaultValue={video.description}
                        onChange={handleChange}
                        id="description"
                        placeholder="Enter video description"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="video_id">video_id</label>
                      <input
                        type="text"
                        name="video_id"
                        defaultValue={video.video_id}
                        onChange={handleChange}
                        id="video_id"
                        placeholder="Enter video id"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="channel">channel</label>
                      <input
                        type="text"
                        name="channel"
                        defaultValue={video.channel}
                        onChange={handleChange}
                        id="channel"
                        placeholder="Enter video channel"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <input type="hidden" name="sequence" defaultValue={1} id="sequence" placeholder="Enter video sequence" required />
                </Row>
              </div>
            </Col>
            <Button type="submit" color="warning">
              Save
            </Button>{" "}
          </Form>
          <Button onClick={() => navigate(`/app/videos`)} variant="danger">
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CreateVideo
