import React, { useEffect, useState } from "react"

import { Dropdown } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

import StylesA from "./matching.module.scss"

function SelectCategoryField(props) {
  const [expandDropdown, setExpandDropdown] = useState(false)

  const mtf_type = props.mtf_type

  // If mft_type == 0 ==> ONE-TO-ONE
  // If mft_type == 1 ==> ONE-TO-MANY

  return (
    <div className="col-3 pl-1 pr-0">
      <Dropdown
        className="d-inline-flex align-items-center w-100 px-2"
        style={{ backgroundColor: "#42506C ", borderRadius: "6px", boxShadow: "0px 3px 6px #00000029", height: "60px" }}
      >
        <Dropdown.Toggle
          id="dropdown_list"
          size="lg"
          style={{ backgroundColor: "#42506C", border: "none" }}
          className="d-inline-flex align-items-center caret-none w-100"
        >
          {expandDropdown ? (
            <p className="mb-0" style={{ fontSize: "16px", color: "#E0E2E570" }}>
              Select Category
            </p>
          ) : (
            <p className="mb-0 " style={{ fontSize: "18px", color: "#E0E2E570" }}>
              Category:
              <span className="mb-0 pl-4" style={{ fontSize: "18px", color: "#E0E2E5" }}>
                {mtf_type === "0" ? `one to one` : `one to many`}
              </span>
            </p>
          )}

          <FontAwesomeIcon className="ml-auto" icon={faAngleDown} color="#E0E2E5" style={{ height: "20px", width: "20px" }} />
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={`w-100 ml-lg-n2 ${StylesA.category_dropdown_menu}`}
          style={{ backgroundColor: "#42506C ", boxShadow: "0px 3px 6px #00000029", opacity: "1", padding: "0px" }}
        >
          <Dropdown.Item
            className={`px-2 py-1 ${StylesA.resource_option_hover} ${StylesA.category_dropdown_item1}`}
            style={{ color: "#E0E2E5", fontSize: "16px" }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              props.handleChangeCategory("0")
            }}
          >
            One to one
          </Dropdown.Item>
          <Dropdown.Item
            className={`px-2 py-1 ${StylesA.resource_option_hover}`}
            style={{ border: "#E0E2E5", color: "#E0E2E5", fontSize: "16px" }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              props.handleChangeCategory("1")
            }}
          >
            One to many
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default SelectCategoryField
