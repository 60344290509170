import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import FormControl from "react-bootstrap/FormControl"
import Pagination from "react-bootstrap/Pagination"
import Modal from "react-bootstrap/Modal"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Layout from "../layout"
import SEO from "../seo"
import authClient from "../../services/auth"
import utcDateTime from "../../services/utcdatetime"
// import hideMessage from '../../services/hidemessage'
import Notification from "../custom/Notification"
import { isBrowser } from "../../services/developer_mode"

import blog from "../../images/svgs/blog.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faSearch, faChevronLeft, faCheck, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons"

import Styles from "../../modularscss/styles.module.scss"

const EskolaBlogs = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [blog_loader, setEskolaBlogLoader] = useState(false)
  const [eskola_blogs, setEskolaBlogs] = useState([])
  const [delete_modal, setDeleteModal] = useState(false)
  const [current_blog, setCurrentBlog] = useState({})
  const [previous_url, setPreviousUrl] = useState()
  const [next_url, setNextUrl] = useState()
  const [total_pages, setTotalPages] = useState()
  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    fetchEskolaBlogs()
  }, [])

  async function fetchEskolaBlogs() {
    setEskolaBlogLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/eskola-blogs/`, { headers: login_credentials })
      .then((res) => {
        setEskolaBlogs(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 8))
        setEskolaBlogLoader(true)
      })
      .catch((error) => {
        let error_messages = "",
          error_key = ""
        for (let key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
          error_key = key
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function fetchPage(page) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/eskola-blogs/?page=" + page, { headers: DefaultHeader })
      .then((res) => {
        setEskolaBlogs(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 8))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function searchEskolaBlogs(event) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/eskola-blogs/?search=" + event.target.value, { headers: DefaultHeader })
      .then((res) => {
        setEskolaBlogs(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 8))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function fetchNext() {
    axios
      .get(next_url, { headers: DefaultHeader })
      .then((res) => {
        setEskolaBlogs(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 8))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }
  function fetchPrevious() {
    axios
      .get(previous_url, { headers: DefaultHeader })
      .then((res) => {
        setEskolaBlogs(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 8))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  const renderPageLinks = () => {
    var rows = []
    if (previous_url) {
      rows.push(<Pagination.Prev className={`m-0 bg-transparent`} onClick={() => fetchPrevious()} />)
    }
    for (var i = 0; i < total_pages; i++) {
      let temp_var = i + 1
      rows.push(
        <Pagination.Item key={i + 1} className={`m-0 bg-transparent`} onClick={() => fetchPage(temp_var)}>
          {i + 1}
        </Pagination.Item>
      )
    }
    if (next_url) {
      rows.push(<Pagination.Next className={`m-0 bg-transparent`} onClick={() => fetchNext()} />)
    }
    return (
      <Pagination className={`bg-card mx-0 my-3`} style={{ width: `fit-content` }}>
        {rows}
      </Pagination>
    )
  }

  function showEskolaBlogs() {
    if (authClient.isAuthenticated()) {
      return displayEskolaBlogData()
    } else {
      return ""
    }
  }
  const displayEskolaBlogData = () => {
    if (blog_loader) {
      if (eskola_blogs.length > 0) {
        let parsed_data = []
        parsed_data.push(
          <div key={`0-table`} className={`d-flex p-3`}>
            <div className={`pl-4 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Eskola Blogs
              </p>
            </div>
            <div className={`pl-3 ml-auto mr-3 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Avatar
              </p>
            </div>
          </div>
        )
        eskola_blogs.map((datum, index) => {
          parsed_data.push(
            <div className={`d-flex mb-1 py-2`} key={index + `_training`} style={{ backgroundColor: `#46537E` }}>
              <div className={`pl-3 align-self-center`}>
                <img src={blog} alt={"Article icon"} className={`m-auto`} style={{ objectFit: `contain`, width: `24px` }} />
              </div>
              <div className={`pl-3 align-self-center`}>
                <p className={`m-0 font-weight-bold text-white`}>
                  {datum.name}
                  <FontAwesomeIcon
                    icon={datum.is_draft ? faTimes : faCheck}
                    className={`${datum.is_draft ? "text-danger" : "text-success"} ml-2`}
                    style={{ fontSize: "15px" }}
                  />
                </p>
                <p className={`m-0`} style={{ fontSize: "16px", color: "rgb(198, 207, 244)" }}>
                  {datum.slug}
                </p>
                <p className={`m-0`} style={{ color: `#27E0C3`, fontSize: "12px" }}>
                  <span>Published on </span>{" "}
                  <span>
                    {" "}
                    {`${utcDateTime.utcDateTime(datum.created, "date")}-${utcDateTime.utcDateTime(datum.created, "short_month_name")}-${utcDateTime.utcDateTime(
                      datum.created,
                      "year"
                    )} `}{" "}
                  </span>{" "}
                </p>
                <Col sm="12" md="12" lg="12" className="float-left px-0">
                  <Link className={`mr-3`} to={`/app/eskola-blogs/edit/` + datum.id}>
                    {" "}
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                  </Link>
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="pointer"
                    size="sm"
                    style={{ color: "rgb(146, 150, 160)" }}
                    onClick={() => showDeleteModal(datum)}
                  />
                </Col>
              </div>
              <div className={`pl-3 align-self-center ml-auto mr-3`}>
                {datum.avatar === null ? null : <LazyLoadImage src={datum.avatar} alt={datum.slug} width="100" />}
              </div>
            </div>
          )
        })
        return <div>{parsed_data}</div>
      } else {
        return (
          <div id="loader" className="float-left w-100">
            <div colSpan="12" className="animated fadeIn pt-3 text-center float-left w-100">
              No Data found...
            </div>
          </div>
        )
      }
    } else {
      return (
        <div id="loader" className="float-left w-100">
          <div colSpan="12" className="animated fadeIn pt-3 text-center float-left w-100">
            Loading...
          </div>
        </div>
      )
    }
  }
  const showDeleteModal = (data) => {
    if (data.id) {
      setCurrentBlog(data)
      setDeleteModal(true)
      displayDeleteModal(data)
    }
  }
  const displayDeleteModal = () => {
    if (current_blog) {
      return (
        <Modal show={delete_modal} onHide={() => setDeleteModal(false)} centered>
          <Modal.Header onHide={() => setDeleteModal(false)} closeButton>{`Are you sure you want to delete blog ${current_blog.name}`}</Modal.Header>
          <Modal.Footer>
            <Col sm="12" md="12" lg="12" className="float-left">
              <Button type="button" className="bg-white" style={{ color: "#485b9e", border: "none", fontSize: "15px", float: "right" }}>
                No
              </Button>
              <Button
                type="submit"
                className="mr-2"
                style={{ background: "#485b9e", border: "none", fontSize: "15px", float: "right" }}
                onClick={() => deleteEskolaBlog()}
              >
                Yes
              </Button>
            </Col>
          </Modal.Footer>
        </Modal>
      )
    } else {
      return null
    }
  }
  const deleteEskolaBlog = () => {
    let index = eskola_blogs.findIndex((res) => res.id === current_blog.id)
    axios
      .delete(`${process.env.GATSBY_REACT_APP_API_HOST}/eskola-blogs/${current_blog.id}/`, { headers: login_credentials, body: current_blog })
      .then((res) => {
        eskola_blogs.splice(index, 1)
        setDeleteModal(false)
      })
      .catch((error) => {
        let error_messages = ""
        for (let key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  return (
    <div>
      <SEO title="Eskola Blogs" />
      <Col lg="12" md="12" sm="12" xs="12">
        <h4 className={`mt-3`}>
          <Link className={`pointer text-white mr-2`} to={`/app/dashboard`}>
            {" "}
            <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
          </Link>
          <span>All Eskola Blogs</span>
          <Button
            onClick={() => {
              navigate("/app/eskola-blogs/create")
            }}
            style={{ background: "#485b9e", border: "none", fontSize: "14px", float: "right" }}
          >
            + Add Eskola Blogs
          </Button>
        </h4>
        <div className={`col-12 d-inline-flex`} style={{ backgroundColor: `#414a6c`, borderRadius: "25px" }}>
          <FontAwesomeIcon icon={faSearch} size="lg" style={{ margin: `auto 0px`, color: `#7c84a0` }} />
          <FormControl
            className="border-0 py-1 px-2 form-control px-0 shadow-none font-14"
            style={{ backgroundColor: `transparent`, color: `#b6bdd4` }}
            placeholder={`Search Eskola Blogs`}
            onChange={searchEskolaBlogs}
          />
        </div>
        {renderPageLinks()}
        <div className={`bg-card ${Styles.b_radius_15} mb-5`} style={{ overflow: "scroll" }}>
          {showEskolaBlogs()}
        </div>
      </Col>
      {displayDeleteModal(current_blog)}
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default EskolaBlogs
