import React, { useContext } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import Image from "../custom/Image"
import Loader from "../custom/Loader"

import down_arrow_white from "../../images/svgs/down_arrow_white.svg"
import CalendarIcon from "../../images/svgs/calendar.svg"

import { InsightsReportsContext } from "../../stores/insights_reports/InsightsReportsStore"
import { TimePeriod } from "./constants"

import Style from "./style.module.scss"
import { SET_ACTIVE_SCHOOL_DATA, SET_SCHOOL_LEVEL_TIME_PERIOD } from "../../stores/insights_reports/InsightsReportsActions"
import InClassOrOverallChoice from "./InClassOrOverall"

function SchoolLevelReportDropdowns(props) {
  const [state, dispatch] = useContext(InsightsReportsContext)

  return (
    <div className="d-flex align-items-center">
      <Dropdown className={`mr-3`}>
        <Dropdown.Toggle className={`bg-303C54 border-0 pr-3 caret-none d-flex align-items-center ${Style.primaryDropdownToggle}`}>
          {state.fetchedSchoolsData ? (
            <>
              <p className="mb-0 mr-auto d-inline w-100 ellipsis pr-2 fs-14px">{state.activeSchoolData?.name}</p>
              <Image className={`ml-auto pointer icon}`} src={down_arrow_white} />
            </>
          ) : (
            <Loader />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${Style.primaryDropdownMenu}`}>
          {state.allSchoolsData?.results &&
            state.allSchoolsData?.results.map((school, index) => {
              return (
                <Dropdown.Item
                  key={`school-${school.id}`}
                  onClick={() => {
                    if (state.activeSchoolData.id !== school.id) {
                      dispatch({ type: SET_ACTIVE_SCHOOL_DATA, payload: school })
                    }
                  }}
                  className={`${Style.dropdownItem} fs-14px`}
                >
                  {school.name}
                </Dropdown.Item>
              )
            })}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className={`mr-3`}>
        <Dropdown.Toggle className={`bg-303C54 border-0 px-3 caret-none d-flex align-items-center ${Style.primaryDropdownToggle} w-fit-content`}>
          <p className="mb-0 mr-auto d-inline w-100 pr-4 fs-14px">{state.schoolLevelTimePeriod}</p>
          <Image className={`ml-auto pointer icon}`} src={down_arrow_white} />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${Style.primaryDropdownMenu} w-fit-content`}>
          {TimePeriod.map((period, index) => {
            return (
              <Dropdown.Item
                key={`${period}`}
                onClick={() => {
                  if (state.schoolLevelTimePeriod !== period) {
                    dispatch({ type: SET_SCHOOL_LEVEL_TIME_PERIOD, payload: period })
                  }
                }}
                className={`${Style.dropdownItem} fs-14px`}
              >
                {period}
              </Dropdown.Item>
            )
          })}
          <Dropdown.Item className={`${Style.dropdownItem}`} onClick={props.customOnClick}>
            <div className="d-flex align-items-center">
              <Image src={CalendarIcon} alt={`custom`} width={25} height={25} />
              <p className="mb-0 d-inline fs-14px pb-1">Custom</p>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <InClassOrOverallChoice />
    </div>
  )
}

export default SchoolLevelReportDropdowns
