import React, { useEffect, useState, useCallback, useContext } from "react"
import debounce from "lodash/debounce"

import { navigate, Link } from "gatsby"

import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Dropdown from "react-bootstrap/Dropdown"
import Image from "../custom/Image"
import HorizontalScroll from "../custom/HorizontalScroll"

import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../stores/global_store/GlobalActions"

import EllipsisV from "../../images/svgs/threedots.svg"
import Close from "../../images/svgs/close.svg"
import Lock from "../../images/svgs/lock-grey-icon.svg"

import SEO from "../../components/seo"
import { isBrowser } from "../../services/developer_mode"

import CourseImg from "../../images/svgs/courseImg.svg"
import success from "../../images/success_quiz_tick.svg"

import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_SELECTED_RESOURCE_TYPE, SET_REFETCH_RESOURCES } from "../../stores/sidebar_store/SidebarActions"

import StylesB from "../courses/course.module.scss"
import { getRequest, putRequest, postRequest } from "../../services/rest_service"

const PublishQuiz = (location) => {
  const quizData = ["Quiz", "Mockup", "Assessment"]
  const quizDescription = [
    "Teach with the help of digital learning content that can be accessed anytime, anywhere.",
    "Create digital learning contents including videos, articles, and other resources and enable your students to access them anytime.",
    "Create interactive assessments and evaluate your learners in an interesting way.",
    "Create specialized classes for specific few students and teach them simultaneously.",
  ]
  const quizLevel = ["Easy", "Medium", "Difficult", "Mixed"]
  const quizType = ["Open", "Closed"]
  const score_display_type = ["only score", "score and answers", "answer and explanation"]
  const score_display_time = ["when the quiz ends", "after every question", "After trainer reviews"]

  const [showQuizTypes, setShowQuizTypes] = useState(true)
  const [quizTypeIndex, setQuizTypeIndex] = useState("0")
  const [quiz, setQuiz] = useState({})

  const [width, setWidth] = useState(1000)
  const [activestate, setActiveState] = useState("quiz_detail")
  const [selectedQuizType, setSelectedQuizType] = useState(false)
  const [hovered, setHovered] = useState(false)

  const [loader, setLoader] = useState(true)
  const [durationType, setDurationType] = useState("Mins")
  const [publishQuiz, setPublishQuiz] = useState(false)

  const [sidebarStore, sidebarDispatch] = useContext(SidebarContext)
  const [globalState, dispatch] = useContext(GlobalContext)

  useEffect(() => {
    fetchQuiz()
  }, [])

  async function fetchQuiz() {
    let res = await getRequest(`/quiz/${location.quiz_id}/`)
    if (res.success) {
      setQuiz(res.data)
    }
  }

  useEffect(() => {
    setLoader(true)
  }, [!loader])

  useEffect(() => {
    if (isBrowser()) {
      setWidth(window.innerWidth)
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
      })
      return () => {
        window.removeEventListener("resize", () => {})
      }
    }
  }, [])

  useEffect(() => {
    setHovered(false)
  }, [width])

  async function handleSubmit(event) {
    event.preventDefault()
    if (quiz != undefined && quiz.id != undefined) {
      if (durationType == "Hours") {
        let time = quiz.quiz_time
        quiz.quiz_time = time * 60
      }
      let formData = new FormData()
      quiz.no_of_attempts = quiz.no_of_attempts === "" || quiz.no_of_attempts == "0.00" || quiz.no_of_attempts == "0" ? "0" : quiz.no_of_attempts
      for (let k in quiz) {
        formData.append(k, quiz[k])
      }
      let res = await putRequest("/quiz/" + quiz.id + "/", formData)
      if (res.success) {
        navigate(`/app/quizzes/`)
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  async function handlePublish(event) {
    event.preventDefault()
    sidebarDispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Quizzes" })
    let formData = new FormData()
    if (window && window.FromTrainingOrClassCreation && window.FromTrainingOrClassCreation.sequence) {
      for (let p in window.FromTrainingOrClassCreation) {
        formData.append(p, window.FromTrainingOrClassCreation[p])
      }
    }

    if (quiz != undefined && quiz.id != undefined) {
      let res = await postRequest("/quiz/" + quiz.id + "/publish_quiz/", formData)
      if (res.success) {
        if (res.data.error) {
          setPublishQuiz(false)
          dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.data.error } })
        }
        if (res.data.success) {
          setPublishQuiz(true)
          sidebarDispatch({ type: SET_REFETCH_RESOURCES, payload: true })
          if (window.opener) {
            const evt = new CustomEvent("my-refresh-resources", {
              bubbles: true,
              detail: "quiz",
            })
            window.opener?.document && window.opener.document.dispatchEvent(evt)
          }
        }
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  function setQuizType(quiz_type) {
    setQuizTypeIndex(quiz_type)
    setSelectedQuizType(true)
    if (quiz_type == 1) {
      quiz["data_type"] = 3 // Mockup
    } else if (quiz_type == 2) {
      quiz["data_type"] = 4 // assessment
    } else {
      quiz["data_type"] = 0 //quiz
    }
    setActiveState("quiz_detail")
  }

  function showQuizCards() {
    return quizData.map((quiz, index) => {
      return (
        <Col
          key={quizData[index]}
          onClick={() => {
            setQuizType(index.toString())
            setShowQuizTypes(false)
          }}
          lg="3"
          md="3"
          sm="3"
          xs="3"
          className="pointer"
          style={{ padding: "8px" }}
        >
          <div className={`${quizTypeIndex === index.toString() ? StylesB.white_border : ""} ${StylesB.course_type_card} card_border_on_hover`}>
            <div className={`${StylesB.card_header} p-2`} style={{ backgroundImage: `url(${CourseImg})` }}>
              <p>{quizData[index]}</p>
            </div>
            <div className={`${StylesB.card_body} p-2`}>
              <p>{quizDescription[index]}</p>
            </div>
          </div>
        </Col>
      )
    })
  }

  async function sendQuery(name, value, id) {
    if (id != undefined) {
      let formData = new FormData()
      formData.append(name, value)
      let res = await putRequest("/quiz/" + quiz.id + "/", formData)
      if (!res.success) {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }
  const delayedQuery = useCallback(
    debounce((name, value, id) => {
      sendQuery(name, value, id)
    }, 1000),
    []
  )

  function handleChange(event) {
    let quiz_data = { ...quiz }
    quiz_data[event.target.name] = event.target.value
    setQuiz(quiz_data)
    delayedQuery(event.target.name, event.target.value, quiz_data.id)
  }

  function getFormData() {
    if (loader) {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="px-0 float-left">
          {/*<h1 className={"font-weight-bold mb-2"} style={{color:'#E0E2E5', fontSize: '18px',marginTop: '5px'}}>{quiz.name}</h1>*/}
          <h2 className={"mb-2 white-text fs-15px opacity-41"}>Your quiz details</h2>
          <Image
            className="pointer object-fit-contain h-20px color-E0E2E551 position-absolute"
            src={Lock}
            style={{ top: "70px", right: "-6px", zIndex: "2" }}
          />
          <Col lg="12" md="12" sm="12" xs="12" className="px-0 py-2 float-left white-text bg-303C54 box-shadow-00000029 br-9px border-303C54 mb-12px">
            <Col lg="2" md="2" sm="2" xs="2" className="float-left d-flex flex-column align-items-center justify-content-center">
              <div className="white-text fs-15px opacity-41">No of Questions</div>
              <div className="white-text fs-15px">{quiz.no_of_questions}</div>
            </Col>
            <Col lg="2" md="2" sm="2" xs="2" className="float-left d-flex flex-column align-items-center justify-content-center">
              <div className="white-text fs-15px opacity-41">Total Time</div>
              <div className="white-text fs-15px">{parseInt(quiz.quiz_time)} Mins</div>
            </Col>
            <Col lg="2" md="2" sm="2" xs="2" className="float-left d-flex flex-column align-items-center justify-content-center">
              <div className="white-text fs-15px opacity-41">Category</div>
              <div className="white-text fs-15px">{quizData[quiz.data_type]}</div>
            </Col>
            <Col lg="2" md="2" sm="2" xs="2" className="float-left d-flex flex-column align-items-center justify-content-center">
              <div className="white-text fs-15px opacity-41">Type</div>
              <div className="white-text fs-15px">{quizType[quiz.quiz_type]}</div>
            </Col>
            <Col lg="2" md="2" sm="2" xs="2" className="float-left d-flex flex-column lign-items-center justify-content-center">
              <div className="white-text fs-15px opacity-41">Quiz level</div>
              <div className="white-text fs-15px">{quizLevel[quiz.quiz_level]}</div>
            </Col>
            <Col lg="2" md="2" sm="2" xs="2" className="float-left d-flex flex-column align-items-center justify-content-center">
              <div className="white-text fs-15px opacity-41">No of Attempts</div>
              <div className="white-text fs-15px">{parseInt(quiz?.no_of_attempts ?? 0) == 0 ? "unlimited" : parseInt(quiz?.no_of_attempts ?? 0)}</div>
            </Col>
          </Col>
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className="border_on_hover pl-0 float-left"
            style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54", marginBottom: "12px" }}
          >
            <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
              <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                <InputGroup.Text
                  className="border-0 d-flex justify-content-center"
                  style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                  id="basic-addon1"
                >
                  Select Passing Percentage:
                </InputGroup.Text>
              </InputGroup.Prepend>
              <input
                type="text"
                name="passing_marks"
                defaultValue={quiz.passing_marks}
                className={`border-0 d-flex px-1 justify-content-center shadow-none text-white`}
                style={{ backgroundColor: "#42506C", fontSize: "15px", maxWidth: "65px", borderRadius: "9px 0 0 9px" }}
                onChange={(e) => handleChange(e)}
                id="name"
                placeholder="pass percentage"
                required
              />
              {/*<FormControl className={`border-0 d-flex justify-content-center shadow-none text-white`} style={{backgroundColor:'#42506C', fontSize: '15px', maxWidth: '65px', borderRadius: '9px 0 0 9px !important', paddingRight: 10}}
                type="number" 
                name="passing_marks" 
                defaultValue={quiz.passing_marks} 
                onChange={(e)=>handleChange(e)} 
                id="name" 
                placeholder="pass percentage" 
                required 
                />*/}
              {/** ${StylesB.course_create}  , borderTopLeftRadius: '8px !important', borderBottomLeftRadius: '8px !important', borderTopRightRadius: '8px !important', borderBottomRightRadius: '8px !important'*/}
              <div
                className="d-flex justify-content-center align-items-center pr-2"
                style={{ backgroundColor: "#42506C", fontSize: "15px", borderRadius: "0 9px 9px 0" }}
              >
                %
              </div>
              <div style={{ color: "#E0E2E5", opacity: "0.41", fontSize: "15px", paddingTop: "7px", position: "absolute", right: "0px" }}>
                The participant needs to score <b>{(quiz.total_score * (quiz.passing_marks / 100)).toFixed(1)}</b> points or more to pass
              </div>
            </InputGroup>
          </Col>
          {quiz.score_display_time === "1" /**After every Questions- Only then display these success/error Msgs */ ? (
            <>
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className="border_on_hover pl-0 float-left"
                style={{
                  backgroundColor: "#303C54",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "9px",
                  border: "1px solid #303C54",
                  marginBottom: "12px",
                }}
              >
                <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className="border-0 d-flex justify-content-center"
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                      id="basic-addon1"
                    >
                      Message for Correct Answer:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 d-flex justify-content-center shadow-none ${StylesB.course_create} text-white`}
                    style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "15px" }}
                    type="text"
                    name="success_msg"
                    defaultValue={quiz.success_msg}
                    onChange={(e) => handleChange(e)}
                    id="name"
                    placeholder="Yay! You got that one right."
                    required
                  />
                </InputGroup>
              </Col>
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className="border_on_hover pl-0 float-left"
                style={{
                  backgroundColor: "#303C54",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "9px",
                  border: "1px solid #303C54",
                  marginBottom: "12px",
                }}
              >
                <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className="border-0 d-flex justify-content-center"
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                      id="basic-addon1"
                    >
                      Message for Wrong Answer:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 d-flex justify-content-center shadow-none ${StylesB.course_create} text-white`}
                    style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "15px" }}
                    type="text"
                    name="error_msg"
                    defaultValue={quiz.error_msg}
                    onChange={(e) => handleChange(e)}
                    id="name"
                    placeholder="Oops! That doesn’t seem right. Take a deep breath."
                    required
                  />
                </InputGroup>
              </Col>
            </>
          ) : null}
        </Col>
      )
    } else {
      return "loading..."
    }
  }

  function getQuizDetailsData() {
    return (
      <>
        <Col lg="12" md="12" sm="12" xs="12" className="px-0 float-left">
          {getFormData()}
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className="px-0 d-flex align-items-center justify-content-center"
            style={{ flexDirection: "column", paddingTop: "10px" }}
          >
            <h1 className={"font-weight-bold"} style={{ fontSize: "18px" }}>
              Your Quiz is ready to be Published!
            </h1>
            <Col g="12" md="12" sm="12" xs="12" className="float-left d-flex align-items-center justify-content-center">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  handlePublish(e)
                }}
                className="border_on_hover fs-14px px-5 py-2"
                style={{ backgroundColor: "#586886", border: "1px solid #586886", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", color: "#E0E2E5" }}
              >
                Publish
              </button>
              {/*<button onClick={ (e)=>{e.preventDefault(); } } className='border_on_hover fs-14px px-3 py-2 ml-2' style={{backgroundColor:'#586886',border:'1px solid #586886',boxShadow:'0px 3px 6px #00000029',borderRadius:'9px',color:'#E0E2E5'}}>Add to Course</button>*/}
            </Col>
          </Col>
        </Col>
      </>
    )
  }

  function calculateWidth() {
    let ele = document.getElementById("coursename")
    if (ele !== null) {
      let data = 1100 - 142 - 50
      return { width: data + "px", overflowX: "scroll" }
    }
  }
  function handleCloseWin(e) {
    if (window.opener) window.opener.postMessage({ instruction: "payload", type: "quiz" }, window.location.origin)
    window.close()
  }

  function getPublishDetails() {
    return (
      <Col xs="12" sm="12" md="12" lg="12" className="d-flex align-items-center justify-content-center" style={{ flexDirection: "column" }}>
        <img alt="Success Tick" style={{ width: "50px", height: "50px" }} src={success} className="mb-0" />
        <h1 className="font-weight-bold" style={{ fontSize: "16px", paddingTop: "20px" }}>
          {quiz.name} has been published successfully
        </h1>
        {window && window.name === "quiz12" && (
          <Button
            onClick={(e) => handleCloseWin(e)}
            className="border_on_hover d-flex justify-content-center"
            style={{
              backgroundColor: "#586886",
              border: "1px solid #586886",
              padding: "10px 15px 10px 15px",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "10px",
              color: "#E0E2E5",
              fontSize: "14px",
              minWidth: "100px",
              textAlign: "center",
            }}
          >
            Close
          </Button>
        )}
      </Col>
    )
  }

  return (
    <div>
      <SEO title="Quiz Publish" />
      <div id="error-div-for-option" className="position-absolute text-white" style={{ top: "0px", right: "0px", zIndex: "2" }}></div>
      <Col xs="12" sm="12" md="12" lg="12" className="pt-0 d-flex" style={{ alignItems: "center", flexDirection: "column" }}>
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          onMouseOver={(e) => {
            e.stopPropagation()
            e.preventDefault()
            let ele = document.getElementById("sections")
            if (ele !== null && ele !== undefined) {
              if (ele.scrollWidth > ele.clientWidth) {
                setHovered(true)
              }
            }
          }}
          className={`${StylesB.course_creation_header} d-flex px-0`}
        >
          <p className="mb-0 py-2 px-3 font-weight-bold br-9009px white-text fs-18px min-w-max-content line-height-1" id="coursename">
            {quiz ? quiz.name : "New Quiz"}
          </p>
          <HorizontalScroll className={`d-inline-flex w-100`} scrollId={"top-scroll-id"}>
            <div id="sections" className="d-flex align-items-center" style={calculateWidth()}>
              <Link to={`/app/quizzes/edit/${quiz.id}`} className={`pointer ${StylesB.backgroundonhover} white-text`}>
                <p className={`mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2 fs-16px ${StylesB.backgroundonhover} min-w-max-content`} id="quiz_detail">
                  Quiz Details
                </p>
              </Link>
              <Link to={`/app/quizzes/${quiz.id}/questions`} className={`pointer ${StylesB.backgroundonhover} white-text`}>
                <p className={`mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2 fs-16px ${StylesB.backgroundonhover} min-w-max-content`} id="add_question">
                  Add Questions
                </p>
              </Link>
              <p
                className={`${StylesB.backgroundonhover} ${StylesB.course_nav_item_active} mb-0 pointer py-2 pl-14px pr-14px fs-16px ml-10px text-E0E2E5 border_transparent`}
                style={{ minWidth: "max-content" }}
                id="save_and_publish"
              >
                Publish
              </p>
            </div>
          </HorizontalScroll>
          <Image
            src={Close}
            alt={`close`}
            width={20}
            height={20}
            onClick={(e) => {
              e.preventDefault()
              navigate("/app/dashboard")
            }}
            className={`pointer mr-3 ml-auto float-right align-self-center`}
          />
        </Col>
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          onMouseOver={() => {
            setHovered(false)
          }}
          className="px-0"
        >
          <Form className="form-horizontal" onSubmit={handlePublish}>
            <div
              className={`px-3 ${publishQuiz ? "d-flex" : ""}`}
              style={{
                maxHeight: "calc(100vh - 190px)",
                minHeight: "calc(100vh - 190px)",
                overflowY: "scroll",
                padding: "12px",
                backgroundColor: "#212C42",
                borderRadius: "9px",
                boxShadow: "0px 3px 12px #00000099",
              }}
            >
              {publishQuiz ? getPublishDetails() : getQuizDetailsData()}
            </div>
          </Form>
        </Col>
      </Col>
    </div>
  )
}

export default PublishQuiz
