import React, { useEffect, useState, useRef } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import FormControl from "react-bootstrap/FormControl"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import utcDateTime from "../../services/utcdatetime"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faSearch, faPoll, faPlusCircle, faCircle } from "@fortawesome/free-solid-svg-icons"
import Modal from "react-bootstrap/Modal"

import Layout from "../layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../seo"
import authClient from "../../services/auth"

import TrashCan from "../../images/svgs/trash-can-transparent.svg"
import CancelWhite from "../../images/svgs/close.svg"

import StylesD from "../../modularscss/delete.module.scss"
import Notification from "../custom/Notification"
import DeleteModal from "../custom/DeleteModal"
import { WsConnect } from "../../services/socket"
import { isBrowser } from "../../services/developer_mode"

const Polls = (props) => {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [pollLoader, setPollLoader] = useState(false)
  const [polls, setPolls] = useState([])
  const [ongoing_polls, setOngoingPolls] = useState([])
  const [ongoing_next_url, setOngoingNextUrl] = useState(null)
  const [ongoing_next_url_fetched, setOngoingNextUrlFetched] = useState(false)
  const [ongoing_poll_fetched, setOngoingPollFetched] = useState(false)
  const [search, setSearch] = useState(false)
  const [next_url, setNextUrl] = useState(null)
  const [next_poll_fetched, setNextPollFetched] = useState(false)
  const [ongoing_poll_loader, setOngoingPollLoader] = useState(false)
  const [quiz, setQuiz] = useState({
    no_of_questions: 1,
    quiz_time: 5,
    no_of_attempts: "0",
    quiz_type: "0",
    quiz_level: "0",
    partial_points: true,
  })
  const [hideButton, setHideButton] = useState(false)
  const [activityToggle, setActivityToggle] = useState(false)
  const [notificationData, setNotificationData] = useState(null)

  const [deleteObjectModal, setDeleteObjectModal] = useState([])

  const pollWs = useRef()

  const handleDeleteObjectModal = () => {
    setDeleteObjectModal([])
  }

  useEffect(() => {
    // fetchOngoingPoll()
    fetchPolls()
  }, [])
  // useEffect(() => {
  //   if (!ongoing_next_url_fetched && ongoing_next_url !== null) {
  //     fetchNextOngoingPoll()
  //   }
  // }, [ongoing_next_url_fetched])
  useEffect(() => {
    if (!next_poll_fetched && next_url !== null) {
      fetchNextPoll()
    }
  }, [next_poll_fetched])

  async function fetchPolls() {
    setPollLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/quiz/?data_type=1&limit=6`, { headers: login_credentials })
      .then((res) => {
        setPolls(res.data.results)
        setNextUrl(res.data.next)
        setPollLoader(true)
        setNextPollFetched(true)
      })
      .catch((error) => {
        setPollLoader(false)
        setPolls([])
      })
  }
  async function fetchNextPoll() {
    axios
      .get(`${next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL)}`, { headers: login_credentials })
      .then((res) => {
        let next_poll_data = polls
        if (res.data.results.length > 0) {
          for (let index = 0; index < res.data.results.length; index++) {
            next_poll_data.push(res.data.results[index])
          }
          setPolls(next_poll_data)
        }
        setNextUrl(res.data.next)
        setNextPollFetched(true)
      })
      .catch((error) => {
        setNextPollFetched(false)
        setNextUrl(null)
      })
  }
  // async function fetchOngoingPoll() {
  //   setOngoingPollFetched(false)
  //   axios
  //     .get(
  //       `${process.env.GATSBY_REACT_APP_API_HOST}/basic-resources-list/?paginate_by=1&is_triggered=true&is_poll=true`,
  //       { headers: login_credentials }
  //     )
  //     .then(res => {
  //       if (res.data.count >= 1) {
  //         fetchTriggeredPollDetail(res.data.results[0], res.data.next)
  //       } else {
  //         setOngoingPollLoader(true)
  //       }
  //     })
  //     .catch(error => {
  //       setOngoingPollFetched(false)
  //       setOngoingNextUrl(null)
  //     })
  // }
  // async function fetchNextOngoingPoll() {
  //   axios
  //     .get(
  //       `${ongoing_next_url.replace(
  //         "http:",
  //         process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL
  //       )}`,
  //       { headers: login_credentials }
  //     )
  //     .then(res => {
  //       if (res.data.results.length > 0) {
  //         fetchTriggeredPollDetail(res.data.results[0], res.data.next)
  //       }
  //     })
  //     .catch(error => {
  //       setOngoingNextUrlFetched(false)
  //     })
  // }
  // async function fetchTriggeredPollDetail(poll, poll_next) {
  //   let poll_id = poll.resource_id,
  //     poll_resource_id = poll.id
  //   let triggered_count = poll.triggered_count
  //   connectSocket(poll_id, poll_resource_id, poll, poll_next)
  //   axios
  //     .get(
  //       `${process.env.GATSBY_REACT_APP_API_HOST}/poll-questions-results/${poll_id}/${poll_resource_id}/?triggered_count=${triggered_count}`,
  //       { headers: login_credentials }
  //     )
  //     .then(res => {
  //       let poll_resource = { ...poll }
  //       poll_resource["poll_resource_data"] = res.data.results
  //       setOngoingPolls([...ongoing_polls, poll_resource])
  //       setOngoingPollLoader(true)
  //       setOngoingPollFetched(true)
  //       setOngoingNextUrl(poll_next)
  //       setOngoingNextUrlFetched(true)
  //     })
  //     .catch(error => {
  //       setOngoingPolls([])
  //     })
  // }

  // async function updateTriggeredPollDetail(poll) {
  //   var index = ongoing_polls.findIndex(res => res.id === poll.id)
  //   let poll_id = poll.resource_id,
  //     poll_resource_id = poll.id
  //   let triggered_count = poll.triggered_count
  //   axios
  //     .get(
  //       `${process.env.GATSBY_REACT_APP_API_HOST}/poll-questions-results/${poll_id}/${poll_resource_id}/?triggered_count=${triggered_count}`,
  //       { headers: login_credentials }
  //     )
  //     .then(res => {
  //       let poll_resource = { ...poll }
  //       poll_resource["poll_resource_data"] = res.data.results
  //       ongoing_polls[index] = poll_resource
  //       setHideButton(true)
  //     })
  //     .catch(error => {
  //       setOngoingPolls([])
  //     })
  // }

  function displayPollData() {
    if (pollLoader) {
      if (polls.length > 0) {
        return polls.map((poll, index) => {
          return (
            <Col sm="6" md="6" lg="4" key={index} className="float-left px-3 mb-4 mt-2" style={{ color: "rgb(198, 207, 244)" }}>
              <Col sm="12" md="12" lg="12" className="float-left py-3 px-4 h-100" style={{ background: "rgb(18, 23, 44)", borderRadius: "10px" }}>
                <div className="float-left w-100" style={{ fontSize: "14px" }}>
                  <p className="mb-4 pl-2" style={{ fontWeight: "500", fontSize: "16px" }}>
                    {poll.name === "sample quiz console" ? "" : poll.name}
                  </p>
                  <div className="float-left w-100 mb-2">
                    <Col sm="6" md="6" lg="6" className="float-left pl-2 pr-0">
                      Number of questions
                    </Col>
                    <Col sm="1" md="1" lg="1" className="float-left px-0">
                      :
                    </Col>
                    <Col sm="5" md="5" lg="5" className="float-left px-0">
                      {poll.total_questions}
                    </Col>
                  </div>
                  <div className="float-left w-100 mb-2">
                    <Col sm="6" md="6" lg="6" className="float-left pl-2 pr-0">
                      Type
                    </Col>
                    <Col sm="1" md="1" lg="1" className="float-left px-0">
                      :
                    </Col>
                    <Col sm="5" md="5" lg="5" className="float-left px-0">
                      General
                    </Col>
                  </div>
                </div>
                <Col sm="12" md="12" lg="12" className="float-left d-flex justify-content-around pt-4">
                  <div className="justify-content-center w-100 text-center d-inline-flex">
                    <button
                      className={`font-14 px-4 ${Styles.primary_button} py-1 mr-2 mb-2`}
                      style={{
                        border: "1px solid rgb(198, 207, 244)",
                        borderRadius: "5px",
                        background: "transparent",
                        color: "rgb(198, 207, 244)",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        navigate(`/app/polls/${poll.id}/edit/`)
                      }}
                    >
                      View
                    </button>
                    <button
                      className={`font-14 px-4 ${Styles.primary_button} py-1 mr-2 mb-2`}
                      style={{
                        border: "1px solid rgb(198, 207, 244)",
                        borderRadius: "5px",
                        background: "transparent",
                        color: "rgb(198, 207, 244)",
                        fontSize: "14px",
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        setDeleteObjectModal(poll)
                      }}
                    >
                      Delete
                    </button>
                    {/* <button className={`font-14 px-4 ${Styles.primary_button} py-1 mb-2`} style={{background: 'rgb(78, 87, 116)', borderRadius: '5px', border: 'none', color: 'rgb(198, 207, 244)', fontSize: '14px'}} onClick={() => {navigate('/app/dashboard')}}>Publish</button> */}
                  </div>
                </Col>
              </Col>
            </Col>
          )
        })
      } else {
        if (search) {
          return (
            <p className="mb-0 text-center" style={{ fontSize: "16px" }}>
              No Results Found!!!
            </p>
          )
        } else {
          return (
            <p className="mb-0 text-center" style={{ fontSize: "16px" }}>
              You have not created any polls yet! Tap on the ‘Create new poll’ icon to create poll.
            </p>
          )
        }
      }
    } else {
      return (
        <p className="mb-0 text-center" style={{ fontSize: "16px" }}>
          Loading...
        </p>
      )
    }
  }

  function deleteData() {
    var index = polls.findIndex((res) => res.id === deleteObjectModal.id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/quiz/" + deleteObjectModal.id + "/", { headers: DefaultHeader, body: deleteObjectModal })
      .then((res) => {
        polls.splice(index, 1)
        setDeleteObjectModal([])
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  // function showDelModal(poll) {
  //   return (
  //     <Modal show={Object.keys(deleteObjectModal).length > 0} onHide={handleDeleteObjectModal} centered className={`${StylesD.delete_modal} classroom-resource-delete-modal`} >
  //       <div className={`${StylesD.delete_modal_header}`}>
  //       <img onClick={handleDeleteObjectModal} src={CancelWhite} alt={`Cancel`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, height: `20px`,position:'absolute',top:'10px',right:'5px' }}/>
  //         <img src={TrashCan} alt={`delete modal delete icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `20px` }}/>
  //         <div>
  //          <p style={{fontWeight:'bold',fontSize:'15px'}}>Delete</p>
  //         </div>
  //       </div>
  //       <Modal.Body className="text-center" style={{backgroundColor:'#121b2b',borderRadius: '0px 0px 8px 8px'}} >
  //         <p style={{color: '#E0E2E5',fontSize:'15px',marginBottom:'10px'}} > Are you sure to delete ? </p>
  //         <p style={{color: '#E0E2E5', fontWeight:'bold',fontSize:'17px'}}>{deleteObjectModal && deleteObjectModal.name ? deleteObjectModal.name : 'Class'}</p>
  //         <div className="d-flex justify-content-center" >
  //           <Button className={`${StylesD.add_button_large} border_on_hover mr-3`} variant="secondary" onClick={() => {deleteData(deleteObjectModal)}} style={{width:'75px',border:'1px solid transparent'}} >Yes</Button>
  //           <Button className={`${StylesD.add_button_large} border_on_hover`} variant="secondary" onClick={handleDeleteObjectModal} style={{width:'75px',border:'1px solid transparent'}} >No</Button>
  //         </div>
  //       </Modal.Body>
  //     </Modal>
  //   )
  // }

  function showPolls() {
    return (
      <div className={`mb-4 float-left w-100 py-4 mt-2`} style={{ background: "rgb(30, 38, 67)", borderRadius: "10px" }}>
        <div
          className={`d-flex mx-4 px-3 float-left`}
          style={{
            borderRadius: `5px`,
            background: "rgb(18, 23, 44)",
            width: "95%",
          }}
        >
          <FontAwesomeIcon className={`text-white my-auto`} icon={faSearch} size="sm" />
          <FormControl
            className="border-0 py-1 px-2 form-control px-0 shadow-none font-14"
            style={{ backgroundColor: `transparent`, color: `#b6bdd4` }}
            placeholder={`Search Poll`}
            onChange={searchPolls}
          />
        </div>
        <Row className="float-left mx-auto w-100 mt-4 pt-3 px-2">{displayPollData()}</Row>
      </div>
    )
  }

  function searchPolls(event) {
    setSearch(true)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/quiz/?search=" + event.target.value + `&data_type=1`, { headers: DefaultHeader })
      .then((res) => {
        setPolls(res.data.results)
        setNextUrl(res.data.next)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }
  // const showOngoingPolls = () => {
  //   if (ongoing_poll_loader) {
  //     if (ongoing_poll_fetched || ongoing_next_url_fetched) {
  //       return (
  //         ongoing_polls.map((poll, index) => {
  //           return (
  //             <div key={`${index}-${poll.resource_data?.name.toLowerCase()}`} className={`mb-4 float-left w-100 mt-2`} style={{background: 'rgb(30, 38, 67)', borderRadius: '10px'}}>
  //               <Col sm="7" md="7" lg="7" className="float-left px-0" style={{borderRight: '1px solid rgb(0, 0, 0)'}}>
  //                 {showOngoingPollName(poll)}
  //               </Col>
  //               <Col sm="5" md="5" lg="5" className="float-left px-0">
  //                 {showOngoingPollDetail(poll)}
  //               </Col>
  //             </div>
  //           )
  //         })
  //       )
  //     } else {
  //       return null
  //     }
  //   } else {
  //     return (
  //       <div className={`float-left w-100 py-3 text-center mt-2`} style={{background: 'rgb(30, 38, 67)', borderRadius: '10px', fontSize: '16px'}}>Loading...</div>
  //     )
  //   }
  // }
  const showOngoingPollName = (poll) => {
    return (
      <div className={`float-left w-100`} style={{ maxHeight: "370px", minHeight: "370px", overflowY: "auto" }}>
        <div
          className={`float-left w-100 p-3 mb-2 position-sticky`}
          style={{
            borderRadius: "10px 0 10px 10px",
            background: "rgb(52, 61, 91)",
            color: "rgb(198, 207, 244)",
            top: 0,
            zIndex: 2,
          }}
        >
          <h6 className="align-items-center d-flex justify-content-between mb-0" style={{ fontSize: "16px" }}>
            {poll.resource_data?.name}
            <div className="d-flex align-items-center mb-0 font-weight-normal">
              <FontAwesomeIcon
                icon={faCircle}
                className="mr-2"
                style={{
                  color: poll.end_activity ? "rgb(144, 71, 68)" : "rgb(14, 149, 92)",
                }}
              />
              {poll.end_activity ? `Poll Ended` : `Ongoing Poll`}
            </div>
          </h6>
        </div>
        {showOngoingPollQuestionDetails(poll.poll_resource_data)}
      </div>
    )
  }
  const showOngoingPollQuestionDetails = (poll_resource_data) => {
    if (poll_resource_data.length > 0) {
      return poll_resource_data.map((poll_data, poll_index) => {
        return (
          <div key={`${poll_data.display_name}-${poll_index}`} className={`float-left w-100 p-3`}>
            <h6 style={{ color: "rgb(198, 207, 244)", fontSize: "16px" }}>{`${poll_index + 1}. ${poll_data.display_name}`}</h6>
            {showOngoingPollReport(poll_data.options)}
          </div>
        )
      })
    } else {
      return null
    }
  }
  const showOngoingPollReport = (poll_report) => {
    return poll_report.map((report, index) => {
      return (
        <div key={`${index}-${report.display_name.toLowerCase().split(" ").join("-")}`} className="float-left w-100" style={{ fontSize: "14px" }}>
          <div
            className="progress position-relative"
            style={{
              background: "rgb(40, 49, 78)",
              height: "45px",
              marginBottom: "10px",
            }}
          >
            <div
              className="progress-bar d-flex justify-content-between"
              role="progressbar"
              style={{
                width: `${report.percentage}%`,
                borderRadius: "5px",
                background: "rgb(95, 99, 113)",
              }}
              aria-valuenow={report.percentage}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div className="position-absolute" style={{ left: "1rem", top: "22.5px" }}>
                {report.display_name}
              </div>
              <div
                className="position-absolute"
                style={{
                  right: "1rem",
                  top: "22.5px",
                  color: "rgb(198, 207, 244)",
                }}
              >{`${parseInt(report.percentage)}%`}</div>
            </div>
          </div>
        </div>
      )
    })
  }
  // const showOngoingPolls = () => {
  //   if (ongoing_poll_loader) {
  //     if (ongoing_poll_fetched || ongoing_next_url_fetched) {
  //       return ongoing_polls.map((poll, index) => {
  //         return (
  //           <div
  //             key={`${index}-${poll.resource_data?.name.toLowerCase()}`}
  //             className={`mb-4 float-left w-100 mt-2`}
  //             style={{ background: "rgb(30, 38, 67)", borderRadius: "10px" }}
  //           >
  //             <Col
  //               sm="7"
  //               md="7"
  //               lg="7"
  //               className="float-left px-0"
  //               style={{ borderRight: "1px solid rgb(0, 0, 0)" }}
  //             >
  //               {showOngoingPollName(poll)}
  //             </Col>
  //             <Col sm="5" md="5" lg="5" className="float-left px-0">
  //               {showOngoingPollDetail(poll)}
  //             </Col>
  //           </div>
  //         )
  //       })
  //     } else {
  //       return null
  //     }
  //   } else {
  //     return (
  //       <div
  //         className={`float-left w-100 py-3 text-center mt-2`}
  //         style={{
  //           background: "rgb(30, 38, 67)",
  //           borderRadius: "10px",
  //           fontSize: "16px",
  //         }}
  //       >
  //         Loading...
  //       </div>
  //     )
  //   }
  // }
  // function showOngoingPollName(poll) {
  //   return (
  //     <div
  //       className={`float-left w-100`}
  //       style={{ maxHeight: "370px", minHeight: "370px", overflowY: "auto" }}
  //     >
  //       <div
  //         className={`float-left w-100 p-3 mb-2 position-sticky`}
  //         style={{
  //           borderRadius: "10px 0 10px 10px",
  //           background: "rgb(52, 61, 91)",
  //           color: "rgb(198, 207, 244)",
  //           top: 0,
  //           zIndex: 2,
  //         }}
  //       >
  //         <h6
  //           className="align-items-center d-flex justify-content-between mb-0"
  //           style={{ fontSize: "16px" }}
  //         >
  //           {poll.resource_data?.name}
  //           <div className="d-flex align-items-center mb-0 font-weight-normal">
  //             <FontAwesomeIcon
  //               icon={faCircle}
  //               className="mr-2"
  //               style={{
  //                 color: poll.end_activity
  //                   ? "rgb(144, 71, 68)"
  //                   : "rgb(14, 149, 92)",
  //               }} />
  //             {poll.end_activity ? `Poll Ended` : `Ongoing Poll`}
  //           </div>
  //         </h6>
  //       </div>
  //       {showOngoingPollQuestionDetails(poll.poll_resource_data)}
  //     </div>
  //   )
  // }
  // const showOngoingPollQuestionDetails = poll_resource_data => {
  //   if (poll_resource_data.length > 0) {
  //     return poll_resource_data.map((poll_data, poll_index) => {
  //       return (
  //         <div
  //           key={`${poll_data.display_name}-${poll_index}`}
  //           className={`float-left w-100 p-3`}
  //         >
  //           <h6 style={{ color: "rgb(198, 207, 244)", fontSize: "16px" }}>{`${
  //             poll_index + 1
  //           }. ${poll_data.display_name}`}</h6>
  //           {showOngoingPollReport(poll_data.options)}
  //         </div>
  //       )
  //     })
  //   } else {
  //     return null
  //   }
  // }
  // const showOngoingPollReport = poll_report => {
  //   return poll_report.map((report, index) => {
  //     return (
  //       <div
  //         key={`${index}-${report.display_name
  //           .toLowerCase()
  //           .split(" ")
  //           .join("-")}`}
  //         className="float-left w-100"
  //         style={{ fontSize: "14px" }}
  //       >
  //         <div
  //           className="progress position-relative"
  //           style={{
  //             background: "rgb(40, 49, 78)",
  //             height: "45px",
  //             marginBottom: "10px",
  //           }}
  //         >
  //           <div
  //             className="progress-bar d-flex justify-content-between"
  //             role="progressbar"
  //             style={{
  //               width: `${report.percentage}%`,
  //               borderRadius: "5px",
  //               background: "rgb(95, 99, 113)",
  //             }}
  //             aria-valuenow={report.percentage}
  //             aria-valuemin="0"
  //             aria-valuemax="100"
  //           >
  //             <div
  //               className="position-absolute"
  //               style={{ left: "1rem", top: "22.5px" }}
  //             >
  //               {report.display_name}
  //             </div>
  //             <div
  //               className="position-absolute"
  //               style={{
  //                 right: "1rem",
  //                 top: "22.5px",
  //                 color: "rgb(198, 207, 244)",
  //               }}
  //             >{`${parseInt(report.percentage)}%`}</div>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   })
  // }

  function handleSave(currentItem, show_results, end_activity = false) {
    if (currentItem) {
      var index = ongoing_polls.findIndex((res) => res.id === currentItem.id)
      var formData = new FormData()
      formData.append("resource_id", currentItem.resource_id)
      formData.append("resource_type", currentItem.resource_type)
      formData.append("end_activity", end_activity)
      /*if (!show_results) {
        formData.append('triggered_count',parseInt(currentItem.triggered_count) + 1)
      }*/
      formData.append("show_results", show_results)
      if (show_results) {
        formData.append("created", new Date().toISOString())
      }
      formData.append("is_triggered", true)
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/resources/` + currentItem.id + "/", formData, { headers: DefaultHeader })
        .then((res) => {
          ongoing_polls[index].is_triggered = true
          /*if (!show_results) {
          ongoing_polls[index].triggered_count = parseInt(currentItem.triggered_count) + 1
        }*/
          ongoing_polls[index].show_results = show_results
          ongoing_polls[index].end_activity = end_activity
          setActivityToggle(!activityToggle)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    } else {
    }
  }
  async function triggerResource(template) {
    if (template.length > 0) {
      setTimeout(function () {
        axios
          .get(`${process.env.GATSBY_REACT_APP_API_HOST}/basic-resources-list/?paginate_by=1&is_triggered=true&is_poll=false&template_id=${template[0]}`, {
            headers: login_credentials,
          })
          .then((res) => {
            // console.log('triggered')
          })
          .catch((error) => {})
      }, 1000)
    }
  }
  // const showOngoingPollDetail = poll => {
  //   return (
  //     <div
  //       className={`float-left w-100`}
  //       style={{ color: "rgb(198, 207, 244)" }}
  //     >
  //       <div className="float-left w-100 pt-2 pb-3 px-3 text-right">
  //         <Link
  //           to={`/app/polls/${poll.resource_id}/edit/`}
  //           style={{ color: "rgb(198, 207, 244)" }}
  //         >
  //           <FontAwesomeIcon icon={faEdit} size="sm" />
  //         </Link>
  //       </div>
  //       <div className="float-left w-100 pt-3 d-flex justify-content-center">
  //         <div className="float-left w-75 px-3" style={{ fontSize: "14px" }}>
  //           <div className="float-left w-100 mb-3">
  //             <Col sm="7" md="7" lg="6" className="float-left pl-3 pr-0">
  //               Number of questions
  //             </Col>
  //             <Col sm="1" md="1" lg="1" className="float-left px-0">
  //               :
  //             </Col>
  //             <Col sm="4" md="4" lg="5" className="float-left px-0">
  //               {poll.poll_resource_data.length}
  //             </Col>
  //           </div>
  //           <div className="float-left w-100 mb-3">
  //             <Col sm="7" md="7" lg="6" className="float-left pl-3 pr-0">
  //               Type
  //             </Col>
  //             <Col sm="1" md="1" lg="1" className="float-left px-0">
  //               :
  //             </Col>
  //             <Col sm="4" md="4" lg="5" className="float-left px-0">
  //               General
  //             </Col>
  //           </div>
  //           <div className="float-left w-100 mb-3">
  //             <Col sm="7" md="7" lg="6" className="float-left pl-3 pr-0">
  //               Poll {poll.end_activity ? `Ended` : `Started`}
  //             </Col>
  //             <Col sm="1" md="1" lg="1" className="float-left px-0">
  //               :
  //             </Col>
  //             <Col
  //               sm="4"
  //               md="4"
  //               lg="5"
  //               className="float-left px-0"
  //             >{`${pollTimeDifference(poll.created)} ago`}</Col>
  //           </div>
  //         </div>
  //       </div>
  //       <Col
  //         sm="12"
  //         md="12"
  //         lg="12"
  //         className="float-left d-flex justify-content-around pt-5"
  //       >
  //         <div className="float-left w-75 text-center">
  //           {/*<button className={`px-3 ${Styles.primary_button} py-1 mr-2 mb-2`} style={{border: 'none', borderRadius: '5px', background: 'rgb(78, 87, 116)', color: 'rgb(198, 207, 244)', fontSize: '14px'}}><FontAwesomeIcon className="mr-2" icon={faCommentAlt} />Share on chat</button>*/}
  //           {poll.end_activity === false ? (
  //             <button
  //               onClick={e => {
  //                 e.preventDefault()
  //                 updateTriggeredPollDetail(poll)
  //                 handleSave(poll, false, true)
  //                 triggerResource(poll.defaultcoursetemplate_set)
  //               }}
  //               className={`px-4 ${Styles.primary_button} py-1 mb-2`}
  //               style={{
  //                 background: "rgb(78, 87, 116)",
  //                 borderRadius: "5px",
  //                 border: "none",
  //                 color: "rgb(198, 207, 244)",
  //                 fontSize: "14px",
  //               }}
  //             >
  //               End Poll
  //             </button>
  //           ) : null}
  //           {poll.end_activity === true && poll.show_results === false ? (
  //             <button
  //               onClick={e => {
  //                 e.preventDefault()
  //                 updateTriggeredPollDetail(poll)
  //                 handleSave(poll, true, true)
  //                 triggerResource(poll.defaultcoursetemplate_set)
  //               }}
  //               className={`px-3 mb-2 ${Styles.primary_button} py-1`}
  //               style={{
  //                 border: "none",
  //                 borderRadius: "5px",
  //                 background: "rgb(78, 87, 116)",
  //                 color: "rgb(198, 207, 244)",
  //                 fontSize: "14px",
  //               }}
  //             >
  //               <FontAwesomeIcon className="mr-2" icon={faPoll} />
  //               Publish Results
  //             </button>
  //           ) : null}
  //         </div>
  //       </Col>
  //     </div>
  //   )
  // }
  const pollTimeDifference = (timestamp) => {
    let resulted_min = 0,
      resulted_hr = 0,
      resulted_day = 0,
      resulted_time = ""
    let current_time = utcDateTime.utcDateTime("today", "time_in_sec")
    let poll_created_time = utcDateTime.utcDateTime(timestamp, "time_in_sec")
    let time_difference = Math.floor((current_time - poll_created_time) / 1000)
    resulted_day = Math.floor(time_difference / (60 * 60 * 24))
    resulted_hr = Math.floor((time_difference - resulted_day * 24 * 60 * 60) / (60 * 60))
    resulted_min = Math.floor((time_difference - (resulted_day * 24 * 60 * 60 + resulted_hr * 60 * 60)) / 60)
    if (resulted_day > 0) {
      resulted_day = resulted_day > 1 ? `${resulted_day}days ` : `${resulted_day}day `
    } else {
      resulted_day = ""
    }
    if (resulted_hr > 0) {
      resulted_hr = resulted_hr > 1 ? `${resulted_hr}hrs ` : `${resulted_hr}hr `
    } else {
      resulted_hr = ""
    }
    if (resulted_min > 0) {
      resulted_min = resulted_min > 1 ? `${resulted_min}mins` : `${resulted_min}min`
    } else {
      resulted_min = ""
    }
    if (resulted_day.length > 0) {
      resulted_time = resulted_day
    } else {
      resulted_time = `${resulted_hr}${resulted_min}`
    }
    if (resulted_time.length === 0) {
      resulted_time = "Few seconds"
    }
    return resulted_time
  }
  const showOngoingPollsNextButton = () => {
    return (
      <Col sm="12" md="12" lg="12" className="float-left mb-4 text-center">
        <button
          className={`font-14 px-3 ${Styles.primary_button} py-1`}
          style={{
            border: "none",
            borderRadius: "5px",
            background: "rgb(78, 87, 116)",
            color: "rgb(198, 207, 244)",
            fontSize: "14px",
          }}
          onClick={showMoreButton}
        >
          Show More
        </button>
      </Col>
    )
  }
  const showMoreButton = () => {
    if (ongoing_next_url) {
      setOngoingNextUrlFetched(false)
    }
  }
  const showPollsNextButton = () => {
    return (
      <Col sm="12" md="12" lg="12" className="float-left mb-4 text-center">
        <button
          className={`font-14 px-3 ${Styles.primary_button} py-1`}
          style={{
            border: "none",
            borderRadius: "5px",
            background: "rgb(78, 87, 116)",
            color: "rgb(198, 207, 244)",
            fontSize: "14px",
          }}
          onClick={showMorePollButton}
        >
          Show More
        </button>
      </Col>
    )
  }
  const showMorePollButton = () => {
    if (next_url) {
      setNextPollFetched(false)
    }
  }

  function createOption(question_data, quiz_id, value = "1") {
    let question_id = question_data.id
    let option = {}
    if (question_data.answermap_set.length > 0) {
      option["answer_map_id"] = question_data.answermap_set[0]
    }
    option["name"] = `sample option ${value}`
    option["add_to_question"] = question_id
    var formData = new FormData()
    for (var k in option) {
      formData.append(k, option[k])
    }
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/options/create/", formData, { headers: login_credentials })
      .then((res) => {
        if (value === "1") {
          createOption(question_data, quiz_id, "2")
        } else if (value === "2") {
          navigate(`/app/polls/${quiz_id}/edit/`)
        }
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function createQuestionResource(currentItem, quiz_id) {
    var formData = new FormData()
    formData.append("sequence", "0")
    formData.append("question_id", currentItem.id)
    formData.append("question_resource_type", "question")
    formData.append("add_to_quiz", quiz_id)
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/questions-resources/create/", formData, { headers: DefaultHeader })
      .then((res) => {
        createOption(res.data.question_data, quiz_id)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function createQuestion(quiz_id) {
    let question = {}
    question["question_type"] = "1"
    question["title"] = "sample question 1".toString()
    question.data_type = "1"
    var formData = new FormData()
    for (var k in question) {
      formData.append(k, question[k])
    }
    if (question.id !== undefined) {
    } else {
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/questions/create/", formData, { headers: login_credentials })
        .then((res) => {
          let currentQuestion = {}
          currentQuestion.id = res.data.id
          currentQuestion.title = res.data.title
          createQuestionResource(currentQuestion, quiz_id)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    }
  }

  function handleSubmit() {
    var formData = new FormData()
    formData.append("name", "sample quiz console")
    formData.append("data_type", "1")
    formData.append("is_added_to_library", true)
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/quiz/", formData, {
        headers: login_credentials,
      })
      .then((res) => {
        navigate(`/app/polls/${res.data.id}/edit/`)
        // createQuestion(res.data.id)
      })
      .catch((error) => {
        // showErrorMessage(error)
        setNotificationData({ type: "error", title: error })
      })
  }
  // function showErrorMessage(error) {
  //   let error_messages = ''
  //   for (var key in error.response.data){
  //     error_messages += error.response.data[key] + "<br>"
  //   }
  //   if(error_messages === 'Invalid token.<br>'){authClient.signOut();

  //     localStorage.removeItem('authenticity_token');
  //     localStorage.removeItem('username');
  //     window.location.reload(true);
  //   }
  //   document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
  //     '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
  //       '<span aria-hidden="true">×</span></button>'+
  //       error_messages +
  //   '</div>')
  // }

  function connectSocket(poll_id, poll_resource_id, poll, poll_next) {
    let poll_resource = { ...poll }
    let triggered_count = poll.triggered_count
    let updateSocket = WsConnect("/ws/polData/" + poll_id + "/" + poll_resource_id + "/?triggered_count=" + triggered_count)
    updateSocket.onmessage = function (e) {
      let data = JSON.parse(e.data)
      if (data.value === "echo") return
      let responseData = JSON.parse(data.value)

      //     let poll_data = responseData.results // JSON.parse(JSON.parse(e.data).value).results
      //     let poll_resource = { ...poll }
      //     triggered_count = responseData.triggered_count //JSON.parse(JSON.parse(e.data).value).triggered_count
      //     poll_resource["poll_resource_data"] = poll_data
      //     if (poll_resource.triggered_count !== triggered_count) {
      //       poll_resource.triggered_count = triggered_count
      //     }
      //     setOngoingPolls([...ongoing_polls, poll_resource])
      //     setOngoingPollLoader(true)
      //     setOngoingPollFetched(true)
      //     setOngoingNextUrl(poll_next)
      //     setOngoingNextUrlFetched(true)
      //     console.log(
      //       "Received a message from the socket:" /*poll_data,triggered_count*/
      //     )
      //   }
      //   updateSocket.onclose = function (e) {
      //     console.error("Chat socket closed unexpectedly; reconnecting")
      //     setTimeout(
      //       connectSocket(poll_id, poll_resource_id, poll, poll_next),
      //       1000
      //     )
      //   }
      //   updateSocket.onopen = function (e) {
      //     console.log("Socket connected; sending a ping")
      //     updateSocket.send("echo")
      //   }
      // }

      let poll_data = responseData.results // JSON.parse(JSON.parse(e.data).value).results
      let poll_resource = { ...poll }
      triggered_count = responseData.triggered_count //JSON.parse(JSON.parse(e.data).value).triggered_count
      poll_resource["poll_resource_data"] = poll_data
      if (poll_resource.triggered_count !== triggered_count) {
        poll_resource.triggered_count = triggered_count
      }
      setOngoingPolls([...ongoing_polls, poll_resource])
      setOngoingPollLoader(true)
      setOngoingPollFetched(true)
      setOngoingNextUrl(poll_next)
      setOngoingNextUrlFetched(true)
      console.log("Received a message from the socket:" /*poll_data,triggered_count*/)
    }
    updateSocket.onclose = function (e) {
      if (!e.wasClean) {
        console.error("Chat socket closed unexpectedly; reconnecting")
        setTimeout(connectSocket(poll_id, poll_resource_id, poll, poll_next), 5000)
      }
    }

    updateSocket.onopen = () => {
      pollWs.current = updateSocket
    }
  }

  return (
    <div>
      <SEO title="Polls" />
      <Col lg="12" md="12" sm="12" className="float-left px-4">
        <h4 className={`mt-3 d-flex align-items-end`} style={{ color: "rgb(198, 207, 244)", fontSize: "22px" }}>
          <FontAwesomeIcon icon={faPoll} className="mr-2" style={{ color: "rgb(87, 97, 134)", width: "38px", height: "38px" }} />
          My Polls
        </h4>
        <div
          className={`my-4 float-left w-100 pointer`}
          style={{
            background: "rgb(46, 51, 70)",
            borderRadius: "10px",
            border: "1px solid rgb(198, 207, 244)",
            color: "rgb(198, 207, 244)",
          }}
          onClick={(e) => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <h5 className="align-items-center d-flex justify-content-center mb-0 py-4" style={{ fontSize: "20px" }}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-4" />
            Create new poll
          </h5>
        </div>
        {/* {showOngoingPolls()} */}
        {/* {ongoing_next_url && hideButton === false ? showOngoingPollsNextButton() : null} */}
        {showPolls()}
        {next_url ? showPollsNextButton() : null}
      </Col>
      <DeleteModal
        show={Object.keys(deleteObjectModal).length > 0}
        onHide={handleDeleteObjectModal}
        itemName={deleteObjectModal?.name}
        action={() => {
          deleteData(deleteObjectModal)
        }}
      />
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default Polls
