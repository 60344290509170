import React, { useState, useRef, useContext } from "react"
import { navigate, Link } from "gatsby"

import Col from "react-bootstrap/Col"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import Form from "react-bootstrap/Form"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import SunEditor from "suneditor-react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { postRequest } from "../../services/rest_service"
import Notification from "../custom/Notification"

import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_SELECTED_RESOURCE_TYPE, SET_REFETCH_RESOURCES } from "../../stores/sidebar_store/SidebarActions"

const readingTime = require("read-time-estimate")

const CreateBlog = (location) => {
  const [blog, setBlog] = useState({ description: null, name: null })
  const [blog_description, setBlogDescription] = useState("")
  const [notificationData, setNotificationData] = useState(null)
  const [sidebarStore, dispatch] = useContext(SidebarContext)

  function handleChange(event) {
    event.preventDefault()
    blog[event.target.name] = event.target.value
  }

  const reset = () => {
    setBlog({ name: null, description: null })
    setBlogDescription("")
  }

  const changeDescription = (value) => {
    let plainValue = value.replace(/background:[^;]+/g, "").replace(/background-color:[^;]+/g, "")
    setBlogDescription(plainValue)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Blogs" })
    var formData = new FormData()
    for (var k in blog) {
      if (k === "description") {
        formData.append("description", blog_description)
      } else formData.append(k, blog[k])
    }
    formData.append("points", Math.ceil(stat.duration))
    formData.append("duration", Math.ceil(stat.duration))
    formData.append("is_added_to_library", true)

    if (blog.id != undefined) {
    } else {
      const response = await postRequest("/blogs/create/", formData)
      if (response.success) {
        dispatch({ type: SET_REFETCH_RESOURCES, payload: true })
        navigate("/app/blogs")
      } else {
        setNotificationData({ type: "error", title: response.msg })
      }
    }
  }
  let stat = readingTime(blog_description)

  return (
    <div>
      <SEO title="Create Blog" />
      <Col
        lg="11"
        md="11"
        sm="11"
        xs="11"
        className={`add-links justify-content-center text-white padding-mob`}
        style={{
          alignItems: "center",
          margin: "10px 50px",
          padding: 20,
          borderRadius: 15,
          border: "1px solid #1f2749",
          background: "#212C41",
        }}
      >
        <Col className="mb-2">
          <Link className={`d-inline pointer text-white`} to={`/app/blogs`}>
            {" "}
            <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
          </Link>
          <h4 className="d-inline"> Create Blog</h4>
        </Col>
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <Col xs="12" sm="6" md="12">
            <InputGroup
              className="mb-3"
              style={{
                border: "1px solid #2F3C54",
                background: "#2F3C54",
                borderRadius: 15,
              }}
            >
              <InputGroup.Prepend>
                <InputGroup.Text
                  id="basic-addon1"
                  style={{
                    border: "1px solid #2F3C54",
                    background: "#2F3C54",
                    borderRadius: 15,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      background: "#2F3C54",
                      color: "#a9adba",
                      marginBottom: "5px",
                      width: `20px`,
                      height: `20px`,
                    }}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                style={{
                  color: "#fff",
                  border: "1px solid #2F3C54",
                  background: "#2F3C54",
                  borderRadius: 15,
                }}
                placeholder="Title for this Article"
                aria-label="BlogName"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                defaultValue={blog.name}
                required
                name="name"
                id="name"
              />
            </InputGroup>
          </Col>
          <input type="hidden" name="sequence" defaultValue={1} id="sequence" placeholder="Enter Blog sequence" required />
          <Col xs="12" sm="6" md="12" className="mb-3">
            {/*<div class="badge badge-success">{`${stat.humanizedDuration}`}</div>*/}
            <SunEditor
              setContents={blog.description}
              onChange={changeDescription}
              placeholder="Content of the Article here"
              setOptions={{
                mode: "classic", //balloon-always
                defaultStyle: "font-family: 'Arial'; font-size: 18px",
                height: 300,
                maxHeight: 450,
                buttonList: [
                  [
                    "undo",
                    "redo",
                    "font",
                    "fontSize",
                    "formatBlock",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "removeFormat",
                    "fontColor",
                    "hiliteColor",
                    "outdent",
                    "indent",
                    "align",
                    "horizontalRule",
                    "list",
                    "table",
                    "link",
                    "image",
                    "video",
                    "print",
                    "codeView",
                  ],
                ],
              }}
            />
          </Col>
          <Col>
            <div class="badge badge-secondary">Reading time: {stat.humanizedDuration}</div>{" "}
          </Col>
          <Col className="d-flex justify-content-end">
            <button
              type="submit"
              className="border_on_hover"
              style={{
                backgroundColor: "#586886",
                border: "1px solid #586886",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "4px",
                paddingTop: "5px",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "23px",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              Save
            </button>
            &nbsp;&nbsp;
            <div
              class="border_on_hover pointer"
              onClick={() => {
                reset()
              }}
              style={{
                backgroundColor: "#586886",
                border: "1px solid #586886",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "4px",
                paddingTop: "5px",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "23px",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              Clear
            </div>
          </Col>
        </Form>
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default CreateBlog
