import React, { useContext, useEffect, useState } from "react"
import Style from "./style.module.scss"
import AddEducatorLogo from "../../../../../images/addEducator.png"
import DefaultUser from "../../../../../images/svgs/DefaultUser.svg"
import ThreeDots from "../../../../../images/svgs/threedots.svg"
import ProfileUpdateProgress from "../../../../ProfileProgressBar"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import Loader from "../../../../custom/Loader"
import Image from "../../../../custom/Image"
import { useLocation } from "@reach/router"
import { trimText } from "../../../../utilities/utils"
import { Dropdown } from "react-bootstrap"
import { deleteRequest } from "../../../../../services/rest_service"
import EducatorAccess from "../Educator/EducatorAccess"
import { HasDeletePermission, HasEditPermission } from "../../../../../utils/PermissionDataAccess"
import { ACCESS_CONTROL } from "../../../../../constants/permissionsConstant"
import { GlobalContext } from "../../../../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../../../../stores/global_store/GlobalActions"

function MemberDetailCard(props) {
  const {
    member,
    setPageno,
    role,
    apicall,
    roleId,
    handleEditPermission,
    cropData,
    educatorDetail,
    specificCourseFlag,
    specificClassesFlag,
    courseFlag,
    setCoursePermissions,
    setClassesPermission,
    setShowCourseModal,
    setShowClassesModal,
    handleAddEducator,
    setEditPermissionPage,
    loader,
    classFlag,
    isDisabled,
    setEducatorProfilePageNo,
    educatorProfilePageNo,
    pageno,
    teamapi,
  } = props
  const [plusImgFlag, setPlusImgFlag] = useState(false)
  const [notificationData, dispatch] = useContext(GlobalContext)
  const location = useLocation()
  const urlSubPath = role === "Educators" ? "/educator/educator-profile-setup/" : `/user/user-profile-setup/${roleId}/`
  const urlPath = location.pathname + urlSubPath + member.user_detail?.id
  useEffect(() => {
    if (member?.user_detail === null) {
      setPlusImgFlag(true)
    }
  })

  const handleButtonAction = () => {
    if (!member?.user_detail?.id) {
      setPageno("Add Member Form")
    } else {
      navigate(urlPath)
    }
  }

  const handleDelete = () => {
    DeleteRoleManagementData(member?.user_detail)
  }
  const DeleteRoleManagementData = async (educator) => {
    let response = await deleteRequest(`/role-management/user-role-management/${member.id}/`)
    if (response.success) {
      if (role === "Educators") {
        let res = await deleteRequest(`/trainers/${educator?.id}/`)
        if (res.success) {
          dispatch({
            type: SET_NOTIFICATION_DATA,
            payload: { type: "success", title: educator.name + " deleted successfully!" },
          })
        }
        apicall(roleId)
        teamapi()
      } else {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "success", title: educator.first_name + " deleted successfully!" },
        })
        apicall(roleId)
        teamapi()
      }
    }
  }

  return (
    <div className="d-flex-column">
      {member.user_detail ? (
        <div
          className={`${Style.main} w-15rem h-16rem bg-303C54  br-9px d-flex flex-column border_on_hover justify-content-center align-items-center position-relative`}
        >
          {member?.user_detail?.id && (
            <Dropdown className={`breakout-groups pointer mr-3 ml-2 float-right d-flex flex-row justify-content-end w-100 ${Style.ThreeDots}`}>
              {(HasEditPermission(ACCESS_CONTROL) || HasDeletePermission(ACCESS_CONTROL)) && (
                <Dropdown.Toggle className="border-none p-0 shadow-none bg-transparent" id="dropdown-basic">
                  <div>
                    <Image src={ThreeDots} alt="option" className="object-fit-contain h-1rem w-1rem turient-header-text-color" />
                  </div>
                </Dropdown.Toggle>
              )}
              <Dropdown.Menu className={` breakout-groups min-w-3rem br-9x`}>
                {HasEditPermission(ACCESS_CONTROL) && (
                  <Dropdown.Item
                    onClick={() => {
                      handleEditPermission(member.user_detail, member.id)
                    }}
                    className={` text-white br-9900px fs-14px px-2 py-1 d-inline-flex bg-465167`}
                  >
                    Edit Permissions
                  </Dropdown.Item>
                )}
                {HasDeletePermission(ACCESS_CONTROL) && (
                  <Dropdown.Item
                    onClick={() => {
                      handleDelete()
                    }}
                    className={` text-white br-0099px fs-14px px-2 py-1 d-inline-flex bg-465167`}
                  >
                    Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {member?.user_detail?.id ? (
            <ProfileUpdateProgress
              percentage={member?.user_detail?.progress_percentage || member?.user_detail?.userprofile?.progress_percentage}
              image={member?.user_detail ? member?.user_detail.profile_pic || DefaultUser : AddEducatorLogo}
              footerImgFlag={plusImgFlag}
              mainDivStyle={Style.mainDivStyle}
              imgStyle="h-90 w-90 rounded-circle"
              strokeWidth={3}
              imgName=""
            />
          ) : (
            <Image src={AddEducatorLogo} alt={`Add ${role}`} className="mb-2" />
          )}
          {member?.user_detail?.name || member?.user_detail?.first_name ? (
            <p className=" text-left poppins_font fs-21px mb-1 turient-header-text-color ">
              {trimText(member?.user_detail?.name) || trimText(member?.user_detail?.first_name)}
            </p>
          ) : (
            <p></p>
          )}
          {member?.user_detail?.designation || member?.user_detail?.userprofile?.designation ? (
            <p className=" text-left poppins_font fs-21px mb-1 turient-header-text-color ">
              {trimText(member?.user_detail?.designation) || trimText(member?.user_detail?.userprofile?.designation)}
            </p>
          ) : (
            <p></p>
          )}

          <div>
            <button
              onClick={handleButtonAction}
              className={`${Style.btn} turient-header-text-color bg-586886 br-23px mt-1 text-center fs-15px poppins_font box-shadow-00000029 border_on_hover `}
            >
              {member?.user_detail?.id ? "Set up profile" : `Add ${role}`}
            </button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  )
}
const memberShape = {
  buttonText: PropTypes.string.isRequired, //Text to be shown inside button
  logo: PropTypes.string, // member Logo
}

MemberDetailCard.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape(memberShape)), //member data
}

export default MemberDetailCard
