import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"
import authClient from "../../../../../services/auth"
import { isBrowser } from "../../../../../services/developer_mode"

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: "20px",
  textAlign: "center",
  color: "#fff",

  // change background colour if dragging
  background: isDragging ? "#99a6dc" : "#4b5a9b",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#94b3ea" : "#2e3446",
  padding: grid,
  width: 238,
})

const DroppableSection = (props) => {
  return (
    <Droppable droppableId={props.section_id}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} className={`${props.remaining_class} `}>
          {props.draglist.map((item, index) => (
            <Draggable
              key={(item.id + "-" + item.name).toString() + "-" + props.list_id}
              draggableId={(item.id + "-" + item.name).toString() + "-" + props.list_id}
              draggableItem={item}
              index={index}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                  {item.resource_data?.display_name ?? item.resource_data?.name ?? item.display_name ?? item.name}
                </div>
              )}
            </Draggable>
          ))}
          <p className="m-0 light-text">Drag and Drop items to add</p>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

const TopicSingleView = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [items, setItems] = useState([])
  const [selected, setSelected] = useState([])

  const [topic, setTopic] = useState({})
  const [module, setModule] = useState({})
  // fake data generator
  const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `item-${k + offset}`,
      content: `item ${k + offset}`,
    }))

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const [openResource, setOpenResource] = useState("videos")

  useEffect(() => {
    fetchTopic()
    fetchVideos()
    // fetchResources()
  }, [])

  async function fetchTopic() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/topics/${location.topic_id}/`, { headers: login_credentials }).then((res) => {
      setTopic(res.data)
      setSelected(res.data.resources)
      // setLoader(true)
    })
  }

  // useEffect(() => {
  //   fetchVideos()
  //   fetchBlogs()
  // }, [])

  async function fetchVideos() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/videos/?topic_id=` + location.topic_id, { headers: login_credentials }).then((res) => {
      // setVideos(res.data.results);
      setItems(res.data.results)
    })
  }

  /*async function fetchResources() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST+`/resources/?topic_id=`+location.topic_id, {headers: login_credentials})
    .then(res => {*/
  // setBlogs(res.data.results);
  // let temp_selected = []
  // res.data.results.map(() => {
  //   temp_selected.push(id: `item-${k + offset}`,
  // content: `item ${k + offset}`)
  // })
  /*setSelected(res.data.results)
    })
  }*/

  function handleChange(event) {
    event.preventDefault()
    topic[event.target.name] = event.target.value
  }
  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in topic) {
      formData.append(k, topic[k])
    }
    if (topic.id != undefined) {
    } else {
      var topic_id = topic.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/topics/create/", formData, { headers: login_credentials })
        .then((res) => {
          navigate(
            `/app/default-course-templates/${location.template_id}/stages/${location.stage_id}/modules/${location.module_id}/topics/${res.data.id}/create?resource_type=` +
              openResource
          )
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }
  const [id2List, setId2List] = useState({
    droppable: "items",
    droppable2: "selected",
  })

  function getList(id) {
    if (id2List[id] === "items") {
      return items
    } else {
      return selected
    }
  }

  function deleteResource(id) {
    // var current_resource_id = currentTopicResource.id;
    // var index = TopicResources.findIndex(res=> res.id === current_resource_id);
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        // TopicResources.splice(index, 1);
        // setCurrentTopicResource('')
        // setDmodal(!dmodal)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function handleSequence(currentTopicResource) {
    if (currentTopicResource) {
      var formData = new FormData()
      formData.append("sequence", currentTopicResource.sequence)
      formData.append("resource_type", currentTopicResource.resource_type)
      formData.append("resource_id", currentTopicResource.resource_id)

      var current_resource_id = currentTopicResource.id
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + current_resource_id + "/", formData, { headers: DefaultHeader })
        .then((res) => {})
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
    }
  }

  function onDragEnd(result) {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    console.log(result, "result", source, destination)

    if (source.droppableId === destination.droppableId) {
      const temp_items = reorder(getList(source.droppableId), source.index, destination.index)

      // let state = { temp_items };
      let temp_selected_drop_list_swap = temp_items

      if (source.droppableId === "droppable2") {
        let current_resource_index = result.destination.index
        let currentTopicResource = temp_items[current_resource_index]
        if (result.destination.index === 0) {
          currentTopicResource.sequence = (parseFloat(selected[0].sequence) - 5).toFixed(20)
        } else if (result.destination.index === temp_items.length - 1) {
          currentTopicResource.sequence = (parseFloat(selected[selected.length - 1].sequence) + 5).toFixed(20)
        } else {
          currentTopicResource.sequence = (
            (parseFloat(selected[result.destination.index].sequence) + parseFloat(selected[result.destination.index - 1].sequence)) /
            2
          ).toFixed(20)
        }
        // state = { selected: items };
        setSelected(temp_items)

        handleSequence(currentTopicResource)
      } else {
        setItems(temp_items)
      }
    } else {
      const temp_result = move(getList(source.droppableId), getList(destination.droppableId), source, destination)

      let temp_selected_drop_list = temp_result.droppable2

      var formData = new FormData()
      var current_sequence = 1
      formData.append("resource_type", "video")
      formData.append("resource_id", result.draggableId.split("-")[0])
      formData.append("add_to_topic", location.topic_id)
      if (destination.index === 0 && selected.length === 0) {
        formData.append("sequence", destination.index)
        temp_selected_drop_list[destination.index].sequence = destination.index
      } else if (destination.index === 0) {
        formData.append("sequence", (parseFloat(selected[destination.index].sequence) - 5).toFixed(20))
        temp_selected_drop_list[destination.index].sequence = (parseFloat(selected[destination.index].sequence) - 5).toFixed(20)
      } else if (destination.index === selected.length) {
        formData.append("sequence", (parseFloat(selected[destination.index - 1].sequence) + 5).toFixed(20))
        if (destination.droppableId === "droppable2") {
          temp_selected_drop_list[destination.index].sequence = (parseFloat(selected[destination.index - 1].sequence) + 5).toFixed(20)
        }
      } else {
        if (destination.droppableId === "droppable2") {
          formData.append(
            "sequence",
            ((parseFloat(selected[destination.index].sequence) + parseFloat(selected[parseFloat(destination.index) - 1].sequence)) / 2).toFixed(20)
          )
          temp_selected_drop_list[destination.index].sequence = (
            (parseFloat(selected[destination.index].sequence) + parseFloat(selected[parseFloat(destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }
      }

      if (destination.droppableId === "droppable2") {
        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
          .then((res) => {
            temp_result.droppable2 = temp_selected_drop_list
            setSelected(temp_selected_drop_list)
            // fetchTopic()
            // navigate(`/app/default-course-templates/${location.template_id}/stages/${location.stage_id}/modules/${location.module_id}/topics/view/`)
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()

              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      } else if (destination.droppableId === "droppable") {
        deleteResource(result.draggableId.split("-")[0])
      }

      setItems(temp_result.droppable)
      setSelected(temp_result.droppable2)
    }
  }

  // var resources = selected.map( (course) => {
  //   return {
  //     id: course.id, name: course.resource_data?.name
  //   };
  // });

  return (
    <div>
      <SEO title="TopicSingleView Demo" />
      <Row>
        <DragDropContext onDragEnd={onDragEnd}>
          <Col lg="12" md="12" sm="12" xs="12">
            <h4>Add course templates stages modules topic</h4>
            <p> Courses / Online / Course Content / Topics / {topic.name} </p>
            <Form className="form-horizontal" onSubmit={handleSubmit}>
              <Col lg="12" md="12" sm="12" xs="12" className="float-left p-3" style={{ background: "#2E3346 0% 0% no-repeat padding-box" }}>
                <Col lg="6" md="6" sm="6" xs="12" className="float-left">
                  <DroppableSection remaining_class={"position-absolute-floating-div"} section_id={"droppable"} list_id={"videos"} draglist={items} />
                  <div className="text-input-css light-text">
                    <input
                      className="light-text no-style-input"
                      type="text"
                      name="name"
                      defaultValue={topic.name}
                      onChange={handleChange}
                      id="name"
                      placeholder="Topic name"
                      required
                    />
                  </div>
                  <div className="text-input-css light-text mt-1">
                    <input
                      className="light-text no-style-input"
                      type="text"
                      name="points"
                      defaultValue={topic.points}
                      onChange={handleChange}
                      id="points"
                      placeholder="Enter topic points"
                      required
                    />
                  </div>
                  <div className="text-input-css light-text mt-1">
                    <FormGroup>
                      <p className="light-text" htmlFor="description">
                        Topic Description
                      </p>
                      <textarea
                        style={{ background: "inherit" }}
                        className="blue-bg light-text"
                        type="text"
                        name="description"
                        defaultValue={topic.description}
                        onChange={handleChange}
                        id="description"
                        placeholder="Please add a description about the topic"
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col xs="12" sm="6" md="6" className="float-left">
                  <Col style={{ borderRadius: "25px" }} xs="12" sm="12" md="12" className="text-center theme-color border p-5">
                    <Col xs="12" sm="6" md="12">
                      <DroppableSection section_id={"droppable2"} list_id={"resources"} draglist={selected} />
                      <Button onClick={() => setOpenResource("video")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                        {" "}
                        Add Videos
                      </Button>
                      <Button onClick={() => setOpenResource("blog")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                        {" "}
                        Add Blogs
                      </Button>
                      <Button onClick={() => setOpenResource("document")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                        {" "}
                        Add Documents
                      </Button>
                      <Button onClick={() => setOpenResource("image")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                        {" "}
                        Add Images
                      </Button>
                      <Button onClick={() => setOpenResource("link")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                        {" "}
                        Add Links
                      </Button>
                      <Button onClick={() => setOpenResource("quiz")} type="submit" className="mt-4 button-css p-2 ml-1" style={{ border: 0 }}>
                        {" "}
                        Add Quizzes
                      </Button>
                    </Col>
                  </Col>
                </Col>
              </Col>
              <Button type="submit" color="warning">
                Save
              </Button>{" "}
            </Form>
            <Button
              onClick={() => navigate(`/app/default-course-templates/${location.template_id}/stages/${location.stage_id}/modules/view/`)}
              variant="danger"
            >
              Go Back
            </Button>
          </Col>
        </DragDropContext>
      </Row>
    </div>
  )
}

export default TopicSingleView
