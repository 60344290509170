import React, { useContext, useEffect, useState } from "react"
import SchoolCard from "../SchoolCard"
import { getRequest } from "../../../services/rest_service"
import Loader from "../../custom/Loader"
import Json from "../../utilities/json"
import AppIntegrationModal from "../../app-integration"
import { SidebarContext } from "../../../stores/sidebar_store/SidebarStore"
import { SET_COMPANY_DETAILS } from "../../../stores/sidebar_store/SidebarActions"
import { HasCreatePermission, HasReadPermission } from "../../../utils/PermissionDataAccess"
import { SCHOOL } from "../../../constants/permissionsConstant"
import EngagementCard from "../../insights/FacilitatorEngagement"
import StudentWiseEngagement from "../../insights/StudentWiseEngagement"
import EngagementListCard from "../../insights/EngagementListCard"

const SchoolSetup = () => {
  const [sidebarState, dispatch] = useContext(SidebarContext)

  const [organisationSchool, setOrganisationSchool] = useState([])
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    getOrganisationSchoolList(Json.toJson(localStorage.getItem("organisation")))
  }, [])

  const getOrganisationSchoolList = async (organisation) => {
    let res = await getRequest(`/basic-company-data/?organisation=${organisation.id}`)
    if (res.success) {
      setOrganisationSchool(res.data.results)
      if (!localStorage.getItem("company_id")) {
        localStorage.setItem("company_id", res.data.results[0].id)
        localStorage.setItem("company_name", res.data.results[0]?.name)
        localStorage.setItem("company_logo", res.data.results[0]?.avatar)
        dispatch({ type: SET_COMPANY_DETAILS, payload: { name: res.data.results[0]?.name, logo: res.data.results[0]?.avatar } })
      }
      setLoader(false)
    } else {
      setLoader(false)
    }
  }
  return (
    <div className="mx-68px mb-24px">
      {loader ? (
        <Loader class="center" />
      ) : organisationSchool.length > 0 ? (
        <React.Fragment>
          <h2 className="text-left poppins_font fs-29px turient-header-text-color mb-0 ">Welcome to Turient</h2>
          <h3 className="text-left fs-21px turient-header-text-color ">One stop destination for all your teaching needs!</h3>
          <p className="mb-14px text-left fs-21px turient-header-text-color mt-20px">Your Schools</p>
          <div className="schoolcards">
            {HasReadPermission(SCHOOL) &&
              organisationSchool?.map((school, index) => {
                return <SchoolCard key={`school-list-${index}`} school={school} />
              })}
            {HasCreatePermission(SCHOOL) && <SchoolCard key={"new-create-school"} school={null} />}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p className="text-left poppins_font fs-29px turient-header-text-color mb-0">Welcome to Turient</p>
          <p className="text-left fs-21px turient-header-text-color">One stop destination for all your teaching needs!</p>
          <p className="mb-14px text-left fs-21px turient-header-text-color mt-20px">You need to setup your school before getting started!</p>
        </React.Fragment>
      )}
      <AppIntegrationModal />
    </div>
  )
}

export default SchoolSetup
