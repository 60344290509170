import { getRequest } from "../../services/rest_service"

const muralAccountsData = async (searchKeyword, course = null) => {
  try {
    let response = await getRequest(
      `/murals/mural_accounts_data/?company=${localStorage.getItem("company_id")}&search=${searchKeyword ? searchKeyword : ""}&course=${course ? course : ""}`
    )
    if (response.success) {
      return response.data
    }
  } catch (error) {
    console.error(error)
  }
}

export default muralAccountsData
