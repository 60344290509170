import React, { useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import Progress from "../custom/progressbar"
import Image from "../custom/Image"
import PropTypes from "prop-types"
import PasswordValidation from "./passwordValidation.module.scss"
import GreenSuccess from "../../images/svgs/green_success.svg"
import DarkSuccess from "../../images/svgs/dark_success.svg"
import hidePasswordImg from "../../images/svgs/eye-hide.svg"
import showPasswordImg from "../../images/svgs/eye-show.svg"

const ValidatePasswordText = (props) => {
  return (
    <div className={`d-flex mb-1`}>
      <Image src={props.contains ? GreenSuccess : DarkSuccess} alt="tick" height={15} width={15} unoptimized={true} />
      <p className={`fs-12px mb-0 px-1 ${props.contains ? "color-0B8316" : ""}`}>{props.text}</p>
    </div>
  )
}

const PasswordValidatorInputField = (props) => {
  const [passwordShown, setPasswordShown] = useState(false)

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  const restrictWhiteSpaceInput = (event) => {
    let pattern = /^(?=.*\s)/
    if (pattern.test(event.key)) {
      event.preventDefault()
    }
  }

  const progressPercentage = () => {
    let dummy = { ...props.strongPasswordValidation }
    let percetage = 0
    if (dummy.has_09spcl) {
      percetage = percetage + 100 / 3
    }
    if (dummy.has_azAZ) {
      percetage = percetage + 100 / 3
    }
    if (dummy.has_8char) {
      percetage = percetage + 100 / 3
    }
    return percetage
  }

  return (
    <Dropdown className={`br-6px border-on-hover-blue bg-1`}>
      <Dropdown.Toggle className={`w-100 caret-none p-0`} variant={`none`}>
        <div className={`d-inline-flex w-100 align-items-center registration-input p-0 position-relative`}>
          <input
            onChange={props.onChange}
            name="password"
            value={props.value}
            placeholder={`Enter New Password`}
            className={`form-control registration-input fs-18px bg-303C54 ${props?.error ? "border-AA5746" : ""}`}
            style={{ border: "inherit" }}
            type={passwordShown ? "text" : "password"}
            onKeyPress={restrictWhiteSpaceInput}
            minLength="8"
          />
          <Image src={passwordShown ? showPasswordImg : hidePasswordImg} className="w-22px h-22px mr-1 position-absolute right-5px" onClick={togglePassword} />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu align="left" className="bg-0D131F ">
        <div className={`px-3 z-index-1 color-888B92 ${PasswordValidation.dropdown_menu_inputpwd}`}>
          <p className={`fs-14px mb-2`}>Your password must have:</p>
          <ValidatePasswordText text={"1 Upper case & 1 lower case"} contains={props.strongPasswordValidation.has_azAZ} />
          <ValidatePasswordText text={"1 digit & 1 special character"} contains={props.strongPasswordValidation.has_09spcl} />
          <ValidatePasswordText text={"At least 8 characters"} contains={props.strongPasswordValidation.has_8char} />
          <div className={`mt-2 mr-3`}>
            <Progress
              bgClass={PasswordValidation.progress_bar_password_validation}
              colorClass={PasswordValidation.progress_bar_color}
              height={6}
              now={progressPercentage()}
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

PasswordValidatorInputField.propTypes = {
  strongPasswordValidation: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
}

export default PasswordValidatorInputField
