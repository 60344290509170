import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Style from "./style.module.scss"
import CancelIcon from "../../../../../../images/svgs/cancel-white-icon.svg"
import { FormControl, Modal } from "react-bootstrap"
import Dropdown from "../../../../../custom/Dropdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import Checkbox from "../../../../../custom/Checkbox"
import Image from "../../../../../custom/Image"
import Styles from "../../../../../../modularscss/styles.module.scss"
import Loader from "../../../../../custom/Loader"
import { getRequest } from "../../../../../../services/rest_service"

const PermissionModal = (props) => {
  const { showModal, setShowModal, educatorDetail, setEducatorDetail, dropdownOptions, type, setCourseData, setClassesData, courseData, classesData } = props
  const [loader, setLoader] = useState(false)
  const [dropdownValue, setDropdownValue] = useState(dropdownOptions[0])
  const [searchString, setSearchString] = useState("")
  const [courseIdList, setCourseIdList] = useState([])
  const [classesIdList, setClassesIdList] = useState([])
  const handleClose = () => {
    setShowModal(false)
  }
  const handleSearch = (event) => {
    setSearchString(event.target.value)
  }
  const setCoursePermissions = (id, newChecked) => {
    let updatedCoursePermissions = [...educatorDetail?.permission.course]
    if (!newChecked) {
      if (!updatedCoursePermissions.includes(id)) {
        updatedCoursePermissions.push(id)
      }
      setEducatorDetail({
        ...educatorDetail,
        permission: {
          ...educatorDetail?.permission,
          access_to_all_courses: false,
          course: updatedCoursePermissions,
        },
      })
    } else {
      const index = updatedCoursePermissions.indexOf(id)
      if (index !== -1) {
        updatedCoursePermissions.splice(index, 1)
        {
          updatedCoursePermissions.length
            ? setEducatorDetail({
                ...educatorDetail,
                permission: {
                  ...educatorDetail?.permission,
                  access_to_all_courses: false,
                  course: updatedCoursePermissions,
                },
              })
            : setEducatorDetail({
                ...educatorDetail,
                permission: {
                  ...educatorDetail?.permission,
                  access_to_all_courses: true,
                  course: updatedCoursePermissions,
                },
              })
        }
      } else if (updatedCoursePermissions.length === courseData?.length) {
        setEducatorDetail({
          ...educatorDetail,
          permission: {
            ...educatorDetail?.permission,
            access_to_all_courses: false,
            course: [],
          },
        })
      } else {
        updatedCoursePermissions = courseData.map((course) => course.id).filter((courseId) => courseId !== id)
        setEducatorDetail({
          ...educatorDetail,
          permission: {
            ...educatorDetail?.permission,
            access_to_all_courses: false,
            course: updatedCoursePermissions,
          },
        })
      }
    }
  }

  const setClassesPermission = (id, newChecked) => {
    let updatedClassPermissions = [...educatorDetail?.permission.trainings]
    if (!newChecked) {
      if (!updatedClassPermissions.includes(id)) {
        updatedClassPermissions.push(id)
      }
      setEducatorDetail({
        ...educatorDetail,
        permission: {
          ...educatorDetail?.permission,
          access_to_all_trainings: false,
          trainings: updatedClassPermissions,
        },
      })
    } else {
      const index = updatedClassPermissions.indexOf(id)
      if (index !== -1) {
        updatedClassPermissions.splice(index, 1)
        {
          updatedClassPermissions.length
            ? setEducatorDetail({
                ...educatorDetail,
                permission: {
                  ...educatorDetail?.permission,
                  access_to_all_trainings: false,
                  trainings: updatedClassPermissions,
                },
              })
            : setEducatorDetail({
                ...educatorDetail,
                permission: {
                  ...educatorDetail?.permission,
                  access_to_all_trainings: true,
                  trainings: updatedClassPermissions,
                },
              })
        }
      } else if (updatedClassPermissions.length === classesData?.length) {
        setEducatorDetail({
          ...educatorDetail,
          permission: {
            ...educatorDetail?.permission,
            access_to_all_trainings: false,
            trainings: [],
          },
        })
      } else {
        updatedClassPermissions = classesData.map((cls) => cls.id).filter((clsId) => clsId !== id)
        setEducatorDetail({
          ...educatorDetail,
          permission: {
            ...educatorDetail?.permission,
            access_to_all_trainings: false,
            trainings: updatedClassPermissions,
          },
        })
      }
    }
  }

  useEffect(() => {
    if (type === "courses") {
      fetchCourses(localStorage.getItem("company_id"), "all")
    } else {
      fetchTrainings(localStorage.getItem("company_id"), "all")
    }
  }, [showModal])

  useEffect(() => {
    if (type !== "courses") {
      let classType = dropdownValue.value === "all_classes" ? "all" : dropdownValue.value === "upcoming_classes" ? "upcoming" : "past"
      fetchTrainings(localStorage.getItem("company_id"), classType)
    }
  }, [dropdownValue.value])

  useEffect(() => {
    setCourseIdList(
      courseData?.map((course) => {
        return course.id
      })
    )
  }, [courseData])
  useEffect(() => {
    setClassesIdList(
      classesData?.map((classes) => {
        return classes.id
      })
    )
  }, [classesData])
  useEffect(() => {
    if (
      (educatorDetail?.permission?.access_to_all_courses === false && educatorDetail?.permission?.course.length === 0) ||
      (educatorDetail?.permission.access_to_all_courses === false && educatorDetail?.permission.course.length === 0)
    ) {
      setCourseIdList([])
    }
    if (
      (educatorDetail?.permission?.access_to_all_trainings === false && educatorDetail?.permission?.trainings.length === 0) ||
      (educatorDetail?.permission.access_to_all_trainings === false && educatorDetail?.permission.trainings.length === 0)
    ) {
      setClassesIdList([])
    }
  }, [educatorDetail?.permission, educatorDetail?.permission])

  //function for updated course data after filter

  const fetchCourses = async (company_id, type) => {
    let value = type === "all" ? "" : `&isdraft=${type === "draft" ? "true" : "false"}`
    const response = await getRequest(`/basic-courses-list/?company=${company_id}&paginate_by=40` + value)
    if (response.success) {
      setCourseData(response.data.results)
    }
  }
  const fetchTrainings = async (company_id, classType) => {
    const response = await getRequest(`/basic-trainings-list/?course__company=${company_id}&type=${classType}&showAllTrainings=true&paginate_by=40`)
    if (response.success) {
      setClassesData(response.data.results)
    } else {
      setClassesData([])
    }
  }
  const getCourseData = () => {
    const filteredData = courseData?.filter((detail) => {
      if (
        dropdownValue.value === "all_courses" &&
        (detail.name.toLowerCase().includes(searchString) || detail.description.toLowerCase().includes(searchString))
      ) {
        return detail
      }
      if (
        dropdownValue.value === "draft" &&
        detail.is_draft &&
        (detail.name.toLowerCase().includes(searchString) || detail.description.toLowerCase().includes(searchString))
      ) {
        return detail
      }
      if (
        dropdownValue.value === "published" &&
        !detail.is_draft &&
        (detail.name.toLowerCase().includes(searchString) || detail.description.toLowerCase().includes(searchString))
      ) {
        return detail
      }
    })

    if (filteredData?.length) {
      return filteredData?.map((detail) => {
        let flag = educatorDetail?.permission?.access_to_all_courses
          ? true
          : educatorDetail?.permission.course.includes(detail.id)
          ? false
          : educatorDetail.permission?.course.length
          ? true
          : false
        return (
          <div className={` ${Style.modalbody_main_div}`}>
            <div className="d-flex flex-row h-100 turient-header-text-color ">
              <div className={`d-flex flex-row justify-content-space-between align-items-center w-66 ${Style.modalbody_inner_div}`}>
                <div className="pl-4">{detail.name}</div>
              </div>
              <div className={`${Style.checkbox_div} d-flex justify-content-center pb-24px pt-24px w-34`}>
                <Checkbox flag={false} isChecked={flag} checkmarkStyle={Style.checkmark} id={detail.id} onCheckboxChange={setCoursePermissions} />
              </div>
            </div>
          </div>
        )
      })
    } else {
      return <p className="text-center turient-header-text-color">No courses found</p>
    }
  }
  //function for updated classes data after filter
  const getClassesData = () => {
    const filteredData = classesData?.filter((detail) => {
      if (detail.name.toLowerCase().includes(searchString)) {
        return detail
      }
    })
    if (filteredData?.length) {
      return filteredData?.map((detail) => {
        let flag = educatorDetail?.permission?.access_to_all_trainings
          ? true
          : educatorDetail?.permission.trainings.includes(detail.id)
          ? false
          : educatorDetail.permission?.trainings.length
          ? true
          : false
        return (
          <div className={` ${Style.modalbody_main_div} bg-586886 box-shadow-00000029`}>
            <div className="d-flex flex-row h-100 turient-header-text-color">
              <div className={`${Style.modalbody_inner_div} d-flex flex-column justify-content-center w-66`}>
                <div className="pl-4 pr-5 d-flex flex-row justify-content-between align-items-center">
                  <div>{detail.name}</div>
                </div>
                <div className="pl-4">{`${detail.start_date} - ${detail.end_date}`}</div>
              </div>
              <div className={`${Style.checkbox_div} d-flex justify-content-center pb-24px pt-24px w-34`}>
                <Checkbox flag={false} isChecked={flag} checkmarkStyle={Style.checkmark} id={detail.id} onCheckboxChange={setClassesPermission} />
              </div>
            </div>
          </div>
        )
      })
    } else {
      return <p className="text-center turient-header-text-color">No courses found</p>
    }
  }
  const handleSubmit = () => {
    setLoader(true)
    setShowModal(false)
    setLoader(false)
  }

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false)
      }}
      size="lg"
      centered
      className={` modal-content-bg-transparent `}
    >
      <Modal.Body className="bg-212C42 border border-1 border-E0E2E5 p-0 br-14px">
        <div className={`${Style.imgDiv} d-flex align-items-center justify-content-between pr-2`}>
          <p className={`mb-0 py-2 px-3 poppins_font icon-color`}>{`Restrict access to specific ${type} by deselecting it`}</p>
          <Image onClick={handleClose} src={CancelIcon} alt={`Cancel`} className={`mb-0 pointer ${Style.image_fit}`} />
        </div>
        <div className={`br-9px h-100 m-4 ${Style.listItems}`}>
          <div className="h-3rem ">
            <div className="h-100 w-100 header-item-active br-9900px ">
              <div className="d-flex flex-row h-100 turient-header-text-color">
                <div className={`d-flex flex-row align-items-center justify-content-between pointer ${Style.dropdown_div}`}>
                  <Dropdown
                    options={dropdownOptions}
                    defaultLabel={dropdownOptions[0]}
                    backgroundStyle={Style.dropdownBackground}
                    inputStyle={` bg-transparent turient-header-text-color w-90 h-2_5rem border-none fs-18px poppins_font pl-20px `}
                    liStyle={Style.dropdownLi}
                    ulStyle={Style.dropdownUl}
                    setValue={setDropdownValue}
                  />
                  <div className={`d-flex mx-4 px-3 float-left br-9px bg-586886 w-50`}>
                    <FontAwesomeIcon className={`text-white my-auto`} icon={faSearch} size="sm" />
                    <FormControl
                      className={`border-0 py-1 px-2 form-control px-0 shadow-none font-14 ${Styles.b_transparent} turient-header-text-color`}
                      placeholder={`Search`}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="w-34 d-flex align-items-center justify-content-center">Allow Access</div>
              </div>
            </div>
          </div>
          {type === "courses" ? getCourseData() : getClassesData()}
        </div>
        <footer className="d-flex px-3 py-2">
          <button
            type="button"
            disabled={loader}
            className={`border_on_hover fs-14px mr-2 br-23px bg-586886 border border-1 border-586886 h-2rem px-30px box-shadow-00000029 turient-header-text-color`}
            onClick={handleSubmit}
          >
            Done
          </button>
          {loader && <Loader class="ml-3 mr-1" />}
        </footer>
      </Modal.Body>
    </Modal>
  )
}

PermissionModal.propTypes = {
  showModal: PropTypes.bool.isRequired, //modal state
  setShowModal: PropTypes.func.isRequired, //function to update modal state
  educatorDetail: PropTypes.object.isRequired, // educator detail data
  setEducatorDetail: PropTypes.func.isRequired, // function to update educator detail data
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired, //dropdown object
  details: PropTypes.arrayOf(PropTypes.any).isRequired, // parent data
  type: PropTypes.string.isRequired, // parent data types
}

export default PermissionModal
