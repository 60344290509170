import React from "react"
import Nav from "react-bootstrap/Nav"
import { Link } from "gatsby"

const NavWithTriangles = (props) => {
  return (
    <Nav className={props.classes} activeKey="/home" onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}>
      {props.button_data.map((node, index) => {
        let show_arrow = ""
        if (props.button_data[index + 1] !== undefined && props.button_data[index + 1].selected) {
          show_arrow = ""
        } else {
          show_arrow = "not-selected"
        }
        return (
          <Nav.Item className="d-flex" key={index.toString() + `nav-item`}>
            <Link className={`triangle-div ${node.selected ? "" : "not-selected"}`} to={node.href ? node.href : "#"}>
              {node.name}
            </Link>
            <div className={`div-for-arrow ${show_arrow} ${index === props.button_data.length - 1 ? "d-none" : ""} `}></div>
          </Nav.Item>
        )
      })}
    </Nav>
  )
}

export default NavWithTriangles
