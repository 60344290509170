export const convertBase64ToFile = (file, b64toBlob) => {
  let block = file.split(";")
  let contentType = block[0].split(":")[1]
  let realData = block[1].split(",")[1]
  let blob = b64toBlob(realData, contentType)
  return blob
}
export const imageUrlToBase64 = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl)
    const blob = await response.blob()
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    console.error("Error converting image URL to Base64:", error)
    return null
  }
}
