import React, { useEffect, useState, useContext } from "react"
import { navigate, Link } from "gatsby"

import SunEditor from "suneditor-react"

import axios from "axios"
import Col from "react-bootstrap/Col"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons"

import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_REFETCH_RESOURCES, SET_SELECTED_RESOURCE_TYPE } from "../../stores/sidebar_store/SidebarActions"

const EditVideo = (location, data) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [video, setVideo] = useState({})

  const [transcript, setTranscript] = useState("")

  const [cilckedTranscript, setClickedTranscript] = useState(false)

  const [sidebarState, dispatch] = useContext(SidebarContext)

  // const { company_id } = location.id

  useEffect(() => {
    fetchVideo()
  }, [location.id])

  async function fetchVideo() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/videos/${location.id}/`, { headers: login_credentials }).then((res) => {
      setVideo(res.data)
    })
  }

  function handleChange(event) {
    // event.preventDefault();
    video[event.target.name] = event.target.value
  }
  function handleSubmit(event) {
    event.preventDefault()
    dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Videos" })
    delete video["avatar"]
    // This was deleted because, server wass throwing error the file not found & form has to be multipart
    delete video["video_file"]

    var formData = new FormData()

    for (var k in video) {
      if (k == "avatar" && video[k] == null) {
        formData.append("avatar", "")
      } else if (k == "video_file" && video[k] == null) {
        formData.append("video_file", "")
      } else formData.append(k, video[k])
    }
    // if(document.querySelector(".form-control-file").value.length > 0){
    // formData.append('avatar',null)
    // }
    if (location.id != undefined) {
      var company_id = location.id
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/videos/" + company_id + "/", formData, { headers: login_credentials })
        .then((res) => {
          navigate("/app/videos")
          dispatch({ type: SET_REFETCH_RESOURCES, payload: true })
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()

            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }
  function toggle(e, type) {
    e.preventDefault()
  }

  function updateTranscript(event) {
    let transcript = video
    transcript["transcript"] = event
    setVideo(transcript)
  }

  function getTranscriptCode() {
    return (
      <Col xs="12" className="mt-1 float-left">
        <div className="mt-3 mb-2" style={{ fontSize: `20px`, color: "#c6cff4" }}>
          Video Transcript
        </div>
        <SunEditor
          style={{ marginTop: "100px" }}
          id="video-transcript-data"
          setContents={video.transcript}
          name="transcript"
          onChange={updateTranscript}
          setOptions={{
            height: "auto",
            buttonList: [
              ["undo", "redo", "font", "fontSize", "formatBlock"],
              ["bold", "underline", "italic", "strike", "subscript", "superscript", "removeFormat"],
              "/", // Line break
              ["fontColor", "hiliteColor", "outdent", "indent", "align", "horizontalRule", "list", "table"],
              ["link", "image", "video", "fullScreen", "showBlocks", "codeView", "preview", "print", "save"],
            ],
          }}
        />
      </Col>
    )
  }

  return (
    <div>
      <SEO title="Video Edit" />
      <h5 className="p-2">Video Edit</h5>
      <Col lg="12" md="12" sm="12" xs="12" className={`d-inline-flex justify-content-center p-3 border`} style={{ borderRadius: "10px" }}>
        <Col lg="12" md="12" sm="12" xs="12">
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col xs="12" sm="5" md="5" className={`float-left p-0`}>
              <Col xs="12" sm="12" md="12" className="light-div p-0 theme-color float-left" style={{ minHeight: `fit-content` }}>
                <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom border-right">
                  <FormGroup className="m-0">
                    <div>Video Name</div>
                    <input
                      style={{ background: "inherit", color: "inherit", fontSize: "14px" }}
                      className="blue-bg video-modal p-0 pl-2"
                      type="text"
                      name="display_name"
                      defaultValue={video.display_name}
                      onChange={handleChange}
                      id="name"
                      placeholder="Enter video name"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom border-right">
                  <FormGroup className="m-0">
                    <div>Description</div>
                    <input
                      style={{ background: "inherit", color: "inherit", fontSize: "14px" }}
                      className="blue-bg video-modal p-0 pl-2"
                      type="text"
                      name="description"
                      defaultValue={video.description}
                      onChange={handleChange}
                      id="description"
                      placeholder="Enter video description"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom border-right">
                  <FormGroup className="m-0">
                    <div>Points</div>
                    <input
                      style={{ background: "inherit", color: "inherit", fontSize: "14px" }}
                      className="blue-bg video-modal p-0 pl-2"
                      type="number"
                      name="points"
                      defaultValue={video.points}
                      onChange={handleChange}
                      id="points"
                      placeholder="Enter Video points"
                      required
                    />
                  </FormGroup>
                </Col>
              </Col>
            </Col>
            <Col xs="12" sm="7" md="7" className={`float-left p-0`}>
              <Col xs="12" sm="12" md="12" className="light-div float-left theme-color p-0" style={{ minHeight: `fit-content` }}>
                <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom">
                  <FormGroup className="m-0">
                    <div>Video URL</div>
                    <input
                      style={{ background: "inherit", color: "inherit", fontSize: "14px" }}
                      className="blue-bg video-modal p-0 pl-2"
                      type="text"
                      name="url"
                      defaultValue={video.url}
                      onChange={handleChange}
                      id="url"
                      placeholder="Enter video url"
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom">
                  <FormGroup className="m-0">
                    <div>Video ID</div>
                    <input
                      style={{ background: "inherit", color: "inherit", fontSize: "14px" }}
                      className="blue-bg video-modal p-0 pl-2"
                      type="text"
                      name="video_id"
                      defaultValue={video.video_id}
                      onChange={handleChange}
                      id="video_id"
                      placeholder="Enter video id"
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom">
                  <FormGroup className="m-0">
                    <div>Channel/Source</div>
                    <input
                      style={{ background: "inherit", color: "inherit", fontSize: "14px" }}
                      className="blue-bg video-modal p-0 pl-2"
                      type="text"
                      name="channel"
                      defaultValue={video.channel}
                      onChange={handleChange}
                      id="channel"
                      placeholder="Enter video channel"
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Col>
            </Col>
            {cilckedTranscript ? getTranscriptCode() : null}
            <Col sm="12" md="12" lg="12" className="float-left mt-3 d-flex pt-3 justify-content-between">
              {cilckedTranscript ? (
                <div className="float-left">
                  <Button onClick={() => setClickedTranscript(false)} style={{ background: "#576186", border: 0 }} color="warning">
                    <FontAwesomeIcon icon={faTimes} size="sm" />
                    {` `}Transcript
                  </Button>{" "}
                </div>
              ) : (
                <div className="float-left">
                  <Button onClick={() => setClickedTranscript(true)} className="float-left" style={{ background: "#576186", border: 0 }} color="warning">
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                    {` `}Transcript
                  </Button>{" "}
                </div>
              )}
              <div className="float-right">
                <Button className="" style={{ background: "#576186", border: 0 }} type="submit" color="warning">
                  Update
                </Button>
                {"   "}
                {/**className={`pointer text-white`}  */}
                <Link to={`/app/videos`}>
                  <Button style={{ background: "#576186", border: 0 }} className="">
                    {" "}
                    Back{" "}
                  </Button>
                </Link>
              </div>
            </Col>
          </Form>
        </Col>
      </Col>
    </div>
  )
}

export default EditVideo
