import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef, useState } from "react"
import Style from "./style.module.scss"
import PropTypes from "prop-types"

// !definition of component
/**
 *
 * @param props --> options, inputStyle, backgroundStyle, arrowStyle, defaultLabel, ulStyle, liStyle, setValue, label
 * @returns Dropdown component
 */
// ! component

const Dropdown = ({ options, inputStyle, backgroundStyle, value, arrowStyle, defaultLabel, ulStyle, liStyle, setValue, label }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const [selectedOption, setSelectedOption] = useState(defaultLabel)
  const [isFloating, setIsFloating] = useState(!!selectedOption || !!value)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleOptionClick = (option) => {
    setSelectedOption(option)
    setValue(option)
    setIsOpen(false)
    setIsFloating(true)
  }
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [hoveredIndex, setHoveredIndex] = useState(-1)

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault()
      setSelectedIndex((prevState) => (prevState + 1) % options.length)
      setHoveredIndex((prevState) => (prevState + 1) % options.length)
    } else if (e.key === "ArrowUp") {
      e.preventDefault()
      setSelectedIndex((prevState) => (prevState - 1 + options.length) % options.length)
      setHoveredIndex((prevState) => (prevState - 1 + options.length) % options.length)
    }
  }
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <div className={backgroundStyle} ref={dropdownRef}>
      <input
        type="text"
        readOnly
        value={selectedOption?.label || value?.label}
        onClick={toggleDropdown}
        className={inputStyle}
        onBlur={() => setIsFloating(!!selectedOption || !!value)}
      />
      <span className={`d-flex-align-center ${isFloating ? Style.floating : Style.floating_label}`}>{label}</span>{" "}
      <span onClick={toggleDropdown} className={arrowStyle}>
        <FontAwesomeIcon icon={faAngleDown} />
      </span>
      {isOpen && (
        <ul className={ulStyle}>
          {options.map((option, index) => (
            <li
              key={option.value}
              onClick={() => handleOptionClick(option)}
              className={liStyle}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
Dropdown.defaultProps = {
  backgroundStyle: "bg-303C54 br-9px h-82",
  inputStyle: "bg-transparent turient-body-text-color w-90 h-100 poppins_font fs-18px pl-18px pt-12px",
  arrowStyle: "turient-body-text-color",
  ulStyle: Style.ul,
  liStyle: Style.li,
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired, // list of options
  inputStyle: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    border: PropTypes.string,
    font: PropTypes.string,
    letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    opacity: PropTypes.number,
    paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }), // custom style for input field
  backgroundStyle: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderRaddius: PropTypes.string,
  }), //custom style for dropdown div background
  arrowStyle: PropTypes.shape({
    color: PropTypes.string,
  }), // custom style for arrow
  defaultLabel: PropTypes.string.isRequired, //initial or default label field
  liStyle: PropTypes.shape({
    listStyle: PropTypes.string,
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.string,
    padding: PropTypes.string,
    cursor: PropTypes.any,
  }), //style for dropdown list items
  ulStyle: PropTypes.shape({
    listStyle: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
    width: PropTypes.string,
    borderRadius: PropTypes.string,
    backgroundColor: PropTypes.string,
    boxShadow: PropTypes.string,
    position: PropTypes.string,
    zIndex: PropTypes.number,
  }), //style for dropdown box
}

export default Dropdown
