import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import CropImage from "../../components/crop-image/crop-image"
import NotificationModal from "../../components/notificationModal"
import { isBrowser } from "../../services/developer_mode"

import Image from "../../components/image"

const EditCompany = (location, data) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [company, setCompany] = useState({})

  const [modalShow, setModalShow] = useState(false)
  const [cropData, setCropData] = useState("#")
  const [fileName, setFileName] = useState(null)

  const [isUpdatedNotification, setIsUpdatedNotification] = useState(false)

  useEffect(() => {
    fetchCompany()
  }, [])

  async function fetchCompany() {
    // setCompanyLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/companies/1/`, { headers: login_credentials }).then((res) => {
      setCompany(res.data)
      if (res.data.avatar !== null) {
        var avatar = res.data.avatar.replace("https://turient-static-bucket-dev.s3.amazonaws.com/courses/", "")
        avatar.replace("https://turient-static-bucket.s3.amazonaws.com/courses/", "")
        var res1 = avatar.split("/")
        var res2 = res1[1]
        var res3 = res2.split("?")
        setFileName(res3[0])
      } else {
        setFileName(null)
      }
      // setCompanyLoader(true)
    })
  }

  function handleChange(event) {
    event.preventDefault()
    company[event.target.name] = event.target.value
  }
  function handleSubmit(event) {
    if (event !== null) {
      event.preventDefault()
    }
    var formData = new FormData()
    for (var k in company) {
      if (k == "avatar" && event === null) {
        formData.append(
          "avatar",
          company["avatar"],
          fileName && fileName !== null ? fileName : `default_image${company.id}` + company["avatar"]["type"].replace("image/", ".")
        )
      } else if (k !== "avatar") {
        formData.append(k, company[k])
      }
    }
    console.log(formData, "form")
    if (company.id != undefined) {
      var company_id = company.id
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/companies/" + company_id + "/", formData, { headers: login_credentials })
        .then((res) => {
          company.avatar = res.data.avatar
          company.name = res.data.name
          setIsUpdatedNotification(true)
          setTimeout(() => {
            setIsUpdatedNotification(false)
          }, 2000)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  const convertBase64ToFile = (file) => {
    let block = file.split(";")
    let contentType = block[0].split(":")[1]
    let realData = block[1].split(",")[1]
    let blob = b64toBlob(realData, contentType)
    return blob
  }

  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || ""
    sliceSize = sliceSize || 512
    let byteCharacters = atob(b64Data)
    let byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize)
      let byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      let byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    let blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  useEffect(() => {
    if (cropData !== company.avatar && cropData !== "#") {
      company["avatar"] = convertBase64ToFile(cropData)
      handleSubmit(null)
    }
  }, [cropData])

  function getCropDataProps(cropData, fileName) {
    setCropData(cropData)
    setFileName(fileName)
  }

  function handleJitsiChange(e) {
    let comp = { ...company }
    comp["jitsy_enabled"] = !comp["jitsy_enabled"]
    setCompany(comp)
  }

  return (
    <div>
      <SEO title="Company Edit" />
      <h5 className="border-bottom p-2">
        Company Edit
        <Link className={`pointer text-white`} to={`/app/dashboard`}>
          <span style={{ fontWeight: "normal" }} className="float-right small">
            {" "}
            Exit{" "}
          </span>
        </Link>{" "}
      </h5>
      <Col lg="12" md="12" sm="12" xs="12" className={`d-inline-flex justify-content-center pt-3`}>
        <Col lg="6" md="6" sm="6" xs="6">
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <CropImage
              show={modalShow}
              onHide={() => setModalShow(false)}
              getCropDataProps={getCropDataProps}
              fileName={fileName}
              avatar={company.avatar && company.avatar !== null ? company.avatar : null}
              type="company"
            />
            <Col xs="12" sm="12" md="12" className="light-div p-0 theme-color float-left" style={{ minHeight: `fit-content` }}>
              <Col xs="12" sm="6" md="12" className="float-left border-bottom pt-3 pb-3">
                {cropData === "#" ? (
                  company.avatar ? (
                    <img style={{ width: "70px", height: "70px" }} src={company.avatar} alt="Company logo" className={`my-2`} />
                  ) : (
                    <Image
                      style={{ width: "70px", height: "70px" }}
                      alt="Company logo"
                      src="ionic-logo-dribbble.png"
                      className="align-center float-left mobile-hide my-2"
                    />
                  )
                ) : (
                  <img style={{ width: "70px", height: "70px" }} src={cropData} alt="Company logo" className={`my-2`} />
                )}
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    setModalShow(!modalShow)
                  }}
                  className="float-right edit-button-theme mt-3 shadow-none"
                >
                  <Image
                    style={{ width: "12px", height: "12px", marginRight: "5px" }}
                    alt="Turient logo"
                    src="material-edit.png"
                    className="align-center float-left mobile-hide"
                  />
                  Upload Logo
                </Button>
              </Col>
              <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3">
                <FormGroup className="m-0">
                  <div>Company Name</div>
                  <input
                    style={{ background: "inherit", color: "inherit", fontSize: "12px" }}
                    className="blue-bg video-modal p-0"
                    type="text"
                    name="name"
                    defaultValue={company.name}
                    onChange={handleChange}
                    id="name"
                    placeholder="Enter company name"
                    required
                  />
                </FormGroup>
                <FormGroup className="m-0">
                  <div>Video Call - Jitsi</div>
                  <Form.Check type={"switch"} name="jitsy_enabled" checked={company?.jitsy_enabled} onChange={handleJitsiChange} id="jitsy_enabled" />
                  {/*<input style={{background: 'inherit',color: 'inherit', fontSize: '12px'}} className='blue-bg video-modal p-0' type="checkbox" name="jitsy_enabled" checked={company?.jitsy_enabled} onChange={handleJitsiChange} id="jitsy_enabled" />*/}
                </FormGroup>
              </Col>
            </Col>
            <Col xs="12" className="mt-3 float-left px-0 d-inline-flex">
              <p className={`my-auto text-white pr-3`}>
                {" "}
                Your website is{" "}
                <a target="_blank" href={`https://${company.subdomain}.turient.io`} className={`border-bottom theme-color font-italic`}>
                  {" "}
                  https://{company.subdomain}.turient.io{" "}
                </a>{" "}
              </p>
              <Button className="ml-auto" style={{ background: "#576186", border: 0 }} type="submit" color="warning">
                Update
              </Button>{" "}
            </Col>
          </Form>
          {isUpdatedNotification && <NotificationModal msg="Details Updated Successfully !" />}
        </Col>
      </Col>
    </div>
  )
}

export default EditCompany
