import React, { useEffect, useState, useCallback } from "react"
import _ from "lodash"

import Col from "react-bootstrap/Col"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Dropdown from "react-bootstrap/Dropdown"
import Modal from "react-bootstrap/Modal"

import Datetime from "react-datetime"
import utcDateTime from "../../services/utcdatetime"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleDown, faRetweet, faChevronDown, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

import CancelWhite from "../../images/svgs/close.svg"

import StylesB from "./course.module.scss"
import Styles from "../../modularscss/styles.module.scss"

const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

const CustomDropDown = (props) => {
  const [show, setShow] = useState(false)

  function weekDayDropDownItems(week_schedule) {
    return weekDays.map((day, index) => {
      // if (week_schedule.indexOf(day) === -1) {
      return (
        <Dropdown.Item key={"drop_item" + day + index.toString()} onClick={(e) => props.handleWeekDaySelectionChange(day, index)} className="px-2">
          {day}
        </Dropdown.Item>
      )
    })
  }

  return (
    <Dropdown
      className={`${StylesB.cohort_week_day_drop_down} mx-1`}
      onToggle={() => setShow(!show)}
      show={show}
      style={{ borderRadius: show ? "5px 5px 0px 0px" : "5px" }}
    >
      <Dropdown.Toggle className={`${StylesB.day_toggle_button}`} id="dropdown-basic">
        {"Select a day"}
        <FontAwesomeIcon className="pointer ml-2" color="#19253B" style={{ height: "16px" }} icon={faChevronDown} />
      </Dropdown.Toggle>
      <Dropdown.Menu flip={false} className={`${StylesB.day_drop_down_menu} last_drop_item py-0`}>
        {weekDayDropDownItems(props.week_schedule)}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const CohortSchedule = (props) => {
  const durationTypes = ["Hours", "Days", "Weeks", "Months"]
  const [course, setCompany] = useState(props.course)
  const [showScheduleDays, setShowScheduleDays] = useState(false)
  const [scheduleErrors, setScheduleErrors] = useState({
    session_count_error: "",
    repeat_error: "",
    time_error: "",
    week_schedule_error: "",
    month_schedule_error: "",
  })
  const [showScheduleDropDown, setShowScheduleDropDown] = useState(false)

  function validateFormValues(name, value) {
    let course_duration = calculateCourseDuration()
    let repeat_duration = 0
    let errors = { ...scheduleErrors }
    if (name === "session_count") {
      repeat_duration = calculateRepeatDuration()
      if (value > course_duration) {
        errors[name + "_error"] = "Session count is greater than course duration"
      } else {
        errors[name + "_error"] = ""
      }
      if (repeat_duration < value) {
        errors["repeat_error"] = "Repeat period is less than session count"
      } else {
        errors["repeat_error"] = ""
      }
    } else if (name === "start_date") {
      let start_time = getTimeInMinutes(value)
      let end_time = getTimeInMinutes(course.cohort_schedule.end_date)
      if (start_time >= end_time) {
        errors["time_error"] = "End time should be greater than start time"
      } else {
        errors["time_error"] = ""
      }
    } else if (name === "end_date") {
      let end_time = getTimeInMinutes(value)
      let start_time = getTimeInMinutes(course.cohort_schedule.start_date)
      if (start_time >= end_time) {
        errors["time_error"] = "End time should be greater than start time"
      } else {
        errors["time_error"] = ""
      }
    } else if (name === "duration") {
      course_duration = calculateCourseDuration(value)
      repeat_duration = calculateRepeatDuration()
      if (course.cohort_schedule.session_count > course_duration) {
        errors["session_count_error"] = "Session count is greater than course duration"
      } else {
        errors["session_count_error"] = ""
      }
      if (repeat_duration > course_duration) {
        errors["repeat_error"] = "Repeat period is greater than course duration"
      } else {
        errors["repeat_error"] = ""
      }
    } else if (name === "duration_type") {
      course_duration = calculateCourseDuration(null, value)
      repeat_duration = calculateRepeatDuration()
      if (course.cohort_schedule.session_count > course_duration) {
        errors["session_count_error"] = "Session count is greater than course duration"
      } else {
        errors["session_count_error"] = ""
      }
      if (repeat_duration > course_duration) {
        errors["repeat_error"] = "Repeat period is greater than course duration"
      } else {
        errors["repeat_error"] = ""
      }
    } else if (name === "repeat_count") {
      course_duration = calculateCourseDuration()
      repeat_duration = calculateRepeatDuration(value)
      if (repeat_duration > course_duration) {
        errors["repeat_error"] = "Repeat period is greater than course duration"
      } else if (repeat_duration < course.cohort_schedule.session_count) {
        errors["repeat_error"] = "Repeat period is less than number of sessions"
      } else {
        errors["repeat_error"] = ""
      }
    } else if (name === "repeat_type") {
      course_duration = calculateCourseDuration()
      repeat_duration = calculateRepeatDuration(null, value)
      if (repeat_duration > course_duration) {
        errors["repeat_error"] = "Repeat period is greater than course duration"
      } else if (repeat_duration < course.cohort_schedule.session_count) {
        errors["repeat_error"] = "Repeat period is less than number of sessions"
      } else {
        errors["repeat_error"] = ""
      }
    }
    setScheduleErrors(errors)
  }

  function validateCohortSchedule() {
    let errors = { ...scheduleErrors }
    let course_duration = calculateCourseDuration()
    let repeat_duration = calculateRepeatDuration()
    let start_time = getTimeInMinutes(course.cohort_schedule.start_date)
    let end_time = getTimeInMinutes(course.cohort_schedule.end_date)
    if (course.cohort_schedule.session_count > course_duration) {
      errors["session_count_error"] = "Session count is greater than course duration"
      setScheduleErrors(errors)
      props.setValidCohortSchedule(false)
      props.setInitiateScheduleValidation(false)
      return null
    } else {
      errors["session_count_error"] = ""
    }
    if (repeat_duration > course_duration) {
      errors["repeat_error"] = "Repeat period is greater than course duration"
      setScheduleErrors(errors)
      props.setValidCohortSchedule(false)
      props.setInitiateScheduleValidation(false)
      return null
    } else if (repeat_duration < course.cohort_schedule.session_count) {
      errors["repeat_error"] = "Repeat period is less than number of sessions"
      setScheduleErrors(errors)
      props.setValidCohortSchedule(false)
      props.setInitiateScheduleValidation(false)
      return null
    } else {
      errors["repeat_error"] = ""
    }
    if (course.cohort_schedule.repeat_type === "Week") {
      let week_schedule_count = totalWeekdaysSelected()
      if (week_schedule_count < course.cohort_schedule.session_count) {
        errors["week_schedule_error"] = "All sessions are not selected."
        setScheduleErrors(errors)
        setShowScheduleDays(true)
        props.setValidCohortSchedule(false)
        props.setInitiateScheduleValidation(false)
        return null
      } else {
        errors["week_schedule_error"] = ""
      }
    } else {
      let month_schedule_count = totalMonthdaysSelected()
      if (month_schedule_count < course.cohort_schedule.session_count) {
        errors["month_schedule_error"] = "All sessions are not given."
        setScheduleErrors(errors)
        setShowScheduleDays(true)
        props.setValidCohortSchedule(false)
        props.setInitiateScheduleValidation(false)
        return null
      } else {
        errors["month_schedule_error"] = ""
      }
    }
    if (start_time >= end_time) {
      errors["time_error"] = "End time should be greater than start time"
      setScheduleErrors(errors)
      props.setValidCohortSchedule(false)
      props.setInitiateScheduleValidation(false)
      return null
    } else {
      errors["time_error"] = ""
    }
    setScheduleErrors(errors)
    let final_error = Object.values(errors).join("")
    if (final_error.length === 0) {
      props.setValidCohortSchedule(true)
    }
  }
  function getTimeInMinutes(date) {
    if (date) {
      let date_value = new Date(date)
      return date_value.getHours() * 60 + date_value.getMinutes()
    } else {
      return 0
    }
  }
  function calculateCourseDuration(duration = null, type = null) {
    let duration_type = type ? type : course.duration_type
    let duration_value = duration ? duration : course.duration
    if (duration_type === "2") {
      return duration_value * 7
    } else if (duration_type === "3") {
      return duration_value * 30
    } else {
      return duration_value
    }
  }
  function calculateRepeatDuration(count = null, type = null) {
    let repeat_type = type ? type : course.cohort_schedule.repeat_type
    let repeat_count = count ? count : course.cohort_schedule.repeat_count
    if (repeat_type === "Week") {
      return repeat_count * 7
    } else if (repeat_type === "Month") {
      return repeat_count * 30
    }
  }

  function handleChange(name, value = null) {
    let current_course = { ...course }
    current_course[name] = value
    setCompany(current_course)
    props.setCompany(current_course)
    validateFormValues(name, value)
    if (props.setCallHandleSubmit) {
      props.setCallHandleSubmit(true)
    }
  }

  function handleCohortSchedule(value = null, name = null) {
    let current_course = { ...course }
    if (name === "start_date" || name === "end_date") {
      current_course.cohort_schedule[name] = new Date(value).toISOString()
    } else {
      current_course.cohort_schedule[name] = value
    }
    setCompany(current_course)
    props.setCompany(current_course)
    validateFormValues(name, value)
    if (props.setCallHandleSubmit) {
      props.setCallHandleSubmit(true)
    }
  }

  useEffect(() => {
    let current_course = { ...course }
    if (current_course.course_type === "3") {
      current_course["duration_type"] = "1"
    } else {
      current_course["duration_type"] = "0"
    }
    setCompany(current_course)
  }, [props.courseTypeIndex])

  useEffect(() => {
    setCompany(props.course)
  }, [props.course])

  useEffect(() => {
    if (props.initiateScheduleValidation) {
      validateCohortSchedule()
    }
  }, [props.initiateScheduleValidation])

  function selectHandleFunction(name, value = null) {
    if (props.page_type === "create") {
      handleChange(name, value)
    } else if (props.page_type === "edit") {
      delayedQuery(name, value)
    }
  }
  function selectCohortFunction(value = null, name = null) {
    if (props.page_type === "create") {
      handleCohortSchedule(value, name)
    } else if (props.page_type === "edit") {
      delayedQuery1(value, name)
    }
  }

  const delayedQuery = useCallback(
    _.debounce((name, value) => {
      handleChange(name, value)
    }, 1000),
    []
  )

  const delayedQuery1 = useCallback(
    _.debounce((value, name) => handleCohortSchedule(value, name), 1000),
    []
  )

  // useEffect(() => {
  //   let current_course = {...course}
  //   if (current_course.cohort_schedule.repeat_type === 'Week') {
  //     let week_schedule = current_course.cohort_schedule.week_schedule
  //     let week_schedule_length = Object.keys(week_schedule)
  //     let repeat_count = parseInt(current_course.cohort_schedule.repeat_count)
  //     let key_count = week_schedule_length.length
  //     if ( repeat_count && repeat_count !== key_count) {
  //       if (repeat_count > key_count) {
  //         for (var i = key_count + 1; i <= repeat_count; i++) {
  //           week_schedule[i] = []
  //         }
  //       } else {
  //         for (var j = key_count; j > repeat_count; j--) {
  //           delete week_schedule[j]
  //         }
  //       }
  //       setCompany(current_course)
  //       props.setCompany(current_course)
  //     }
  //   } else {
  //     let month_schedule = current_course.cohort_schedule.month_schedule
  //     let month_schedule_length = Object.keys(month_schedule)
  //     let m_repeat_count = parseInt(current_course.cohort_schedule.repeat_count)
  //     let m_key_count = month_schedule_length.length
  //     if (m_repeat_count > m_key_count) {
  //       for (var k = m_key_count + 1; k <= m_repeat_count; k++) {
  //         month_schedule[k] = {}
  //         month_schedule[k]['schedule'] = []
  //       }
  //     } else {
  //       for (var z = m_key_count; z > m_repeat_count; z--) {
  //         delete month_schedule[z]
  //       }
  //     }
  //     let monthly_count = Math.floor(current_course.cohort_schedule.session_count / current_course.cohort_schedule.repeat_count )
  //     let remaining_days =  current_course.cohort_schedule.session_count % current_course.cohort_schedule.repeat_count
  //     for (const property in month_schedule) {
  //       month_schedule[property]['count'] = monthly_count + (remaining_days > 0 ? 1 : 0)
  //       remaining_days = remaining_days - 1
  //     }
  //     setCompany(current_course)
  //     props.setCompany(current_course)
  //     if (props.setCallHandleSubmit) {
  //       props.setCallHandleSubmit(true)
  //     }
  //   }
  // }, [course.cohort_schedule.repeat_count, course.cohort_schedule.repeat_type, course.cohort_schedule.session_count])

  function updateWeekSchedule(week, day) {
    let submit_changes = true
    let current_course = { ...course }
    let week_schedule = current_course.cohort_schedule.week_schedule[week]
    let index = week_schedule.indexOf(day)
    let errors = { ...scheduleErrors }
    if (index > -1) {
      week_schedule.splice(index, 1)
      errors["week_schedule_error"] = ""
    } else {
      let week_schedule_count = totalWeekdaysSelected()
      if (week_schedule_count >= course.cohort_schedule.session_count) {
        submit_changes = false
        errors["week_schedule_error"] = "All sessions are assigned."
      } else {
        week_schedule.push(day)
        errors["week_schedule_error"] = ""
      }
    }
    setScheduleErrors(errors)
    if (submit_changes) {
      setCompany(current_course)
      props.setCompany(current_course)
      if (props.setCallHandleSubmit) {
        props.setCallHandleSubmit(true)
      }
    } else {
      return null
    }
  }
  function totalWeekdaysSelected() {
    let count = 0
    Object.values(course.cohort_schedule.week_schedule).map((item, index) => (count += item.length))
    return count
  }
  function totalMonthdaysSelected() {
    let count = 0
    Object.values(course.cohort_schedule.month_schedule).map((item, index) => (count += item.schedule.length))
    return count
  }

  function getSingleWeek(week) {
    let weeks = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    let week_data = course.cohort_schedule.week_schedule[week]
    return (
      <div key={"week" + week.toString()} className="w-100 py-2" style={{ borderTop: "1px solid #E0E2E560" }}>
        <p className="mb-0 pl-3" style={{ color: "#E0E2E580", fontSize: "14px" }}>
          Week {week}
        </p>
        <div className="d-inline-flex mt-1">
          {weeks.map((item, index) => {
            return (
              <div
                key={week.toString() + item + index.toString()}
                onClick={() => updateWeekSchedule(week, index)}
                className={`${week_data.indexOf(index) > -1 ? StylesB.selected_day : ""} pointer border_on_hover ml-3 px-2 py-1`}
                style={{ fontSize: "12px", borderRadius: "3px", color: "#E0E2E5", backgroundColor: "#586886", boxShadow: "0px 3px 6px #00000029" }}
              >
                {item}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  function showSelectingDaysForWeek() {
    if (course.cohort_schedule.repeat_count >= 1) {
      return (
        <Col className="px-0 py-0 mx-0 my-0">
          <div className="align-items-center" style={{ maxHeight: "160px", overflowY: "scroll" }}>
            {Object.keys(course.cohort_schedule.week_schedule).map((item, index) => getSingleWeek(item))}
          </div>
        </Col>
      )
    }
  }
  function showError(error_type) {
    if (scheduleErrors[error_type]) {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className={`text-left px-0 d-flex align-items-baseline`}>
          <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
          <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
            {scheduleErrors[error_type]}
          </p>
        </Col>
      )
    } else return null
  }

  function showSelectingDaysForMonth() {
    let schedule = course.cohort_schedule
    if (schedule.repeat_count >= 1) {
      return (
        <div>
          {/*{
            schedule.repeat_count === 2 ? <FontAwesomeIcon className="pointer" color='white' style={{position:'absolute',top:'50%',right:'0px',backgroundColor:'#4F6080'}} icon={faRetweet}/> : null
          }*/}
          <div style={{ maxHeight: "200px", overflowY: "scroll", display: "flex", flexFlow: "column", flexDirection: "column" }}>
            {Object.keys(schedule.month_schedule).map((item, indx) => {
              return (
                <div className="align-items-center py-2" style={{ borderTop: "1px solid #E0E2E560", width: "100%" }}>
                  <p className="mb-0 pl-3" style={{ color: "#E0E2E580", fontSize: "14px" }}>
                    Month {item}
                  </p>
                  <div className="mb-0 pl-2 mt-1 d-inline-flex align-items-center hide_scrollbar" style={{ width: "100%", overflowX: "scroll" }}>
                    <p className="mb-0 pl-2" style={{ color: "#E0E2E5", fontSize: "14px" }}>
                      On
                    </p>
                    {[...Array(schedule.month_schedule[item].count)].map((item1, indx1) => {
                      return (
                        <React.Fragment key={item + item1 + indx1}>
                          {/*<Dropdown className="breakout-groups move-dropdown">
                              <Dropdown.Toggle className="border-none px-3 py-1" style={{backgroundColor:"transparent",color:"#E0E2E5",fontSize:'16px'}} id="dropdown-basic">
                                {schedule.month_schedule[item].schedule[indx1] || 'Select a day'}<FontAwesomeIcon className="pointer ml-2" color='#E0E2E5' style={{height:'16px'}} icon={faChevronCircleDown}/>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="last_drop_item" >
                                {[...Array(31)].map((date, index)=>{
                                  return(
                                    <Dropdown.Item name='month_schedule' onClick={(e)=>chooseUpdateMonthFunction(item, indx1, date)} className="text-white fs-14px px-2" >{date}</Dropdown.Item>
                                  )
                                })}
                              </Dropdown.Menu>
                            </Dropdown>*/}
                          <div className="d-flex align-items-center mx-2" style={{ backgroundColor: "#4F6080", borderRadius: "9px" }}>
                            <InputGroup className="align-items-center" style={{ width: "40px" }}>
                              <FormControl
                                className={`border-0 d-flex justify-content-center text-white pr-0`}
                                style={{ maxWidth: "80px", backgroundColor: "#4F6080", borderRadius: "9px", fontSize: "16px", boxShadow: "none" }}
                                type="number"
                                name="selected_day"
                                defaultValue={schedule.month_schedule[item].schedule[indx1]}
                                onChange={(e) => chooseUpdateMonthFunction(item, indx1, e.target.value)}
                                id="selected_day"
                                required
                                max="31"
                                min="1"
                              />
                            </InputGroup>
                            <span style={{ paddingRight: "8px", color: "#E0E2E5" }}>{showOrdinal(schedule.month_schedule[item].schedule[indx1])}</span>
                          </div>
                          <span style={{ color: "#E0E2E5" }}>
                            {schedule.month_schedule[item].count === 1 || indx1 === schedule.month_schedule[item].count - 1
                              ? null
                              : indx1 === schedule.month_schedule[item].count - 2
                              ? " &"
                              : " ,"}
                          </span>
                        </React.Fragment>
                      )
                    })}
                    <p className="mb-0" style={{ minWidth: "150px", color: "#E0E2E5", fontSize: "14px" }}>
                      Day of month {item}
                    </p>
                    {/*<p className='mb-0 pl-2 d-inline-flex align-items-center' style={{color:'#E0E2E5', fontSize:'16px'}}>day of month {item}</p>*/}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }
  function showOrdinal(n) {
    if (n) {
      var s = ["th", "st", "nd", "rd"]
      var v = n % 100
      return s[(v - 20) % 10] || s[v] || s[0]
    } else return null
  }
  function chooseUpdateMonthFunction(month, index, value) {
    if (props.page_type === "create") {
      updateMonthSchedule(month, index, value)
    } else {
      delayedQuery2(month, index, value)
    }
  }

  const delayedQuery2 = useCallback(
    _.debounce((month, index, value) => updateMonthSchedule(month, index, value), 1000),
    []
  )

  function updateMonthSchedule(month, index, value) {
    let current_course = { ...course }
    let month_schedule = current_course.cohort_schedule.month_schedule[month].schedule
    month_schedule[index] = value
    setCompany(current_course)
    props.setCompany(current_course)
    if (props.setCallHandleSubmit) {
      props.setCallHandleSubmit(true)
    }
  }

  function showSelectingDays() {
    if (course.cohort_schedule.repeat_type === "Week") {
      return showSelectingDaysForWeek()
    } else {
      return showSelectingDaysForMonth()
    }
  }
  function focusOnData(e, id) {
    let data = document.getElementById(id)
    if (data) {
      data.focus()
    }
  }
  function showRepeatTypeToggle(schedule) {
    return (
      <div className={`${StylesB.cohort_toggle_button_div} d-flex`}>
        <div
          className={`${StylesB.cohort_toggle_button} ${schedule.repeat_type === "Week" ? StylesB.cohort_toggle_button_active : ""}`}
          onClick={(e) => handleCohortSchedule("Week", "repeat_type")}
          style={{ borderRight: schedule.repeat_type === "Week" ? "1px solid #E0E2E5" : "0px" }}
        >
          {" "}
          Weekly{" "}
        </div>
        <div
          className={`${StylesB.cohort_toggle_button} ${schedule.repeat_type === "Month" ? StylesB.cohort_toggle_button_active : ""}`}
          onClick={(e) => handleCohortSchedule("Month", "repeat_type")}
          style={{ borderLeft: schedule.repeat_type === "Month" ? "1px solid #E0E2E5" : "0px" }}
        >
          {" "}
          Monthly{" "}
        </div>
      </div>
    )
  }
  function showWeekDaysSelection() {
    let week_schedule = course.cohort_schedule.week_schedule
    console.log(week_schedule, "week_schedule")
    return <CustomDropDown week_schedule={week_schedule} handleWeekDaySelectionChange={handleWeekDaySelectionChange} />
  }
  function handleWeekDaySelectionChange(day, index) {
    let current_course = { ...course }
    let week_schedule = current_course.cohort_schedule.week_schedule
    if (index > -1) {
      if (day === "Select a day") {
        week_schedule.splice(index, 1)
      } else {
        week_schedule.splice(index, 1, day)
      }
    } else if (day !== "Select a day") {
      week_schedule.push(day)
    }
    setCompany(current_course)
  }

  function showCohortScheduleSection() {
    let schedule = course.cohort_schedule
    let start_date = schedule.start_date ? utcDateTime.utcDateTime(schedule.start_date, "time") : "00:00"
    let end_date = schedule.end_date ? utcDateTime.utcDateTime(schedule.end_date, "time") : "00:00"
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-0">
        <Col lg="12" md="12" sm="12" xs="12" className="px-0 align-items-center d-flex my-3">
          <Col lg="5" md="5" sm="5" xs="5" style={{ textAlign: "end" }}>
            <p style={{ color: "#E0E2E5", fontSize: "15px", marginBottom: "0px" }}>Cohort Duration</p>
          </Col>
          <Col lg="7" md="7" sm="7" xs="7">
            <div
              className="border_on_hover align-items-center d-inline-flex"
              style={{ height: "35px", backgroundColor: "#4F6080", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54" }}
            >
              <InputGroup className="align-items-center" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                <FormControl
                  autoComplete="off"
                  className={`border-0 d-flex justify-content-center shadow-none pl-2 pr-1 ${StylesB.course_create} text-white`}
                  style={{ maxWidth: "41px", height: "35px", backgroundColor: "transparent", borderRadius: "0px", fontSize: "15px" }}
                  type="number"
                  name="duration"
                  defaultValue={course.duration}
                  onChange={(e) => selectHandleFunction(e.target.name, parseInt(e.target.value))}
                  id="duration"
                  required
                  placeholder="00"
                />
              </InputGroup>
              <Dropdown className="breakout-groups move-dropdown">
                <Dropdown.Toggle
                  className="border-none px-3 py-1"
                  style={{ backgroundColor: "transparent", color: "#E0E2E5", fontSize: "15px" }}
                  id="dropdown-basic"
                >
                  {durationTypes[parseInt(course.duration_type)]}
                  <FontAwesomeIcon className="pointer ml-2" color="#E0E2E5" style={{ height: "16px" }} icon={faChevronCircleDown} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="last_drop_item">
                  {/*<Dropdown.Item name='duration_type' onClick={(e)=>handleChange(e.target.name,'1')} className="text-white fs-14px px-2" >Days</Dropdown.Item>*/}
                  <Dropdown.Item name="duration_type" onClick={(e) => handleChange(e.target.name, "2")} className="last_drop_item text-white fs-14px px-2">
                    Weeks
                  </Dropdown.Item>
                  <Dropdown.Item name="duration_type" onClick={(e) => handleChange(e.target.name, "3")} className="last_drop_item text-white fs-14px px-2">
                    Months
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Col>
        <div className={`${course.duration > 0 ? "" : StylesB.disabled_div}`}>
          <Col lg="12" md="12" sm="12" xs="12" className="px-0 align-items-center d-flex my-3">
            <Col lg="5" md="5" sm="5" xs="5" style={{ textAlign: "end" }}>
              <p style={{ color: "#E0E2E5", fontSize: "15px", marginBottom: "0px" }}>Schedule </p>
            </Col>
            <Col lg="7" md="7" sm="7" xs="7" className="align-items-center">
              <Dropdown
                autoClose={false}
                show={showScheduleDropDown}
                className={`${StylesB.cohort_schedule_drop_down} ${showScheduleDropDown ? "" : "border_on_hover"}`}
                drop={"down"}
                style={{ borderRadius: showScheduleDropDown ? "9px 9px 0px 0px" : "9px", maxWidth: "300px" }}
              >
                <Dropdown.Toggle
                  onClick={() => setShowScheduleDropDown(!showScheduleDropDown)}
                  className={`${StylesB.toggle_button} ${showScheduleDropDown ? StylesB.top_border_div : "border-0"} py-1`}
                  id="dropdown-basic"
                  style={{ borderRadius: showScheduleDropDown ? "9px 9px 0px 0px" : "9px" }}
                >
                  Customize your schedule
                  <FontAwesomeIcon className="pointer ml-2" color="#E0E2E5" style={{ height: "16px" }} icon={faChevronCircleDown} />
                </Dropdown.Toggle>
                <Dropdown.Menu flip={false} className={`${StylesB.drop_down_menu} last_drop_item p-2`}>
                  <div style={{ width: "140px" }}>{showRepeatTypeToggle(schedule)}</div>
                  <div className={`${StylesB.week_schedule_section} mt-2 p-2`}>
                    <p className="mb-0">Every</p>
                    {showWeekDaysSelection()}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Col>
          <Col lg="12" md="12" sm="12" xs="12" className="px-0 align-items-center d-flex my-3">
            <Col lg="5" md="5" sm="5" xs="5" style={{ textAlign: "end" }}>
              <p style={{ color: "#E0E2E5", fontSize: "15px", marginBottom: "0px" }}>The sessions will </p>
            </Col>
            <Col lg="7" md="7" sm="7" xs="7" className="align-items-center">
              <div className="align-items-center d-flex">
                <Dropdown className="breakout-groups move-dropdown">
                  <Dropdown.Toggle
                    className="px-3 py-1 border_on_hover"
                    style={{
                      height: "35px",
                      minWidth: "112px",
                      backgroundColor: "#4F6080",
                      color: "#E0E2E5",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      fontSize: "16px",
                    }}
                    id="dropdown-basic"
                  >
                    {schedule.session_frequency}
                    <FontAwesomeIcon className="pointer ml-2" color="#E0E2E5" style={{ height: "16px" }} icon={faChevronCircleDown} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="last_drop_item">
                    <Dropdown.Item
                      name="repeat_type"
                      onClick={(e) => handleCohortSchedule("Repeat", "session_frequency")}
                      className="last_drop_item text-white fs-14px px-2"
                    >
                      Repeat
                    </Dropdown.Item>
                    <Dropdown.Item name="repeat_type" onClick={(e) => handleCohortSchedule("Once", "session_frequency")} className="text-white fs-14px px-2">
                      Once
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className={`${schedule.session_frequency === "Repeat" ? "" : StylesB.disabled_div} d-flex align-items-center`}>
                  <p style={{ color: "#E0E2E5", fontSize: "15px", marginBottom: "0px", marginLeft: "8px", marginRight: "8px" }}>every</p>
                  <div
                    className="border_on_hover align-items-center d-inline-flex"
                    style={{ height: "35px", backgroundColor: "#4F6080", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54" }}
                  >
                    <InputGroup className="align-items-center" style={{ borderRadius: "9px" }}>
                      <FormControl
                        className={`border-0 d-flex justify-content-center px-2 text-white`}
                        style={{ maxWidth: "45px", height: "35px", backgroundColor: "transparent", borderRadius: "9px", fontSize: "16px" }}
                        type="number"
                        name="repeat_count"
                        defaultValue={schedule.repeat_count}
                        onChange={(e) => selectCohortFunction(parseInt(e.target.value), e.target.name)}
                        id="repeat_count"
                        required
                        placeholder="00"
                      />
                    </InputGroup>
                  </div>
                  <Dropdown className="breakout-groups move-dropdown ml-2">
                    <Dropdown.Toggle
                      className="px-3 py-1 border_on_hover"
                      style={{
                        height: "35px",
                        backgroundColor: "#4F6080",
                        color: "#E0E2E5",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "9px",
                        fontSize: "16px",
                      }}
                      id="dropdown-basic"
                    >
                      {schedule.repeat_type}
                      <FontAwesomeIcon className="pointer ml-2" color="#E0E2E5" style={{ height: "16px" }} icon={faChevronCircleDown} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="last_drop_item">
                      <Dropdown.Item
                        name="repeat_type"
                        onClick={(e) => {
                          handleCohortSchedule("Week", e.target.name)
                        }}
                        className="text-white fs-14px px-2"
                      >
                        Week
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="repeat_type"
                        onClick={(e) => {
                          handleCohortSchedule("Month", e.target.name)
                        }}
                        className="last_drop_item text-white fs-14px px-2"
                      >
                        Month
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {showError(`repeat_error`)}
            </Col>
          </Col>
          {/*<Col lg="12" md="12" sm="12" xs="12" className='px-0 align-items-center d-flex my-3'>
            <Col lg="5" md="5" sm="5" xs="5" style={{textAlign:'end'}} >
              <p style={{color:'#E0E2E5',fontSize:'15px',marginBottom:'0px'}} >On the following days</p>
            </Col>
            <Col lg="7" md="7" sm="7" xs="7">
              <div onClick={()=>setShowScheduleDays(true)} className="pointer px-2 py-1" style={{display:'inline-block',backgroundColor:"#4F6080",borderRadius:'8px'}} >
                <p style={{color:'#E0E2E5',fontSize:'16px',marginBottom:'0px'}}>{showScheduleText(schedule) ? showScheduleText(schedule) : 'Select days'}</p>
              </div>
            </Col>
          </Col>*/}
          <Col lg="12" md="12" sm="12" xs="12" className="px-0 align-items-center d-flex my-3">
            <Col lg="5" md="5" sm="5" xs="5" style={{ textAlign: "end" }}>
              <p style={{ color: "#E0E2E5", fontSize: "15px", marginBottom: "0px" }}>The timings for each session would be</p>
            </Col>
            <Col lg="7" md="7" sm="7" xs="7">
              <div classsName="px-0 mb-auto border_on_hover" style={{ maxWidth: "360px" }}>
                <Dropdown className="session-time-drop-down">
                  <Dropdown.Toggle
                    as="div"
                    id="dropdown-custom-components"
                    style={{ height: "35px", maxWidth: "150px", backgroundColor: "#4F6080", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px" }}
                  >
                    <div className={`px-0 font-weight-bold`} style={{ position: "relative", minWidth: "300px", borderLeft: `0px solid #ffffff40` }}>
                      <div
                        style={{ display: "flex", alignItems: "center", borderRadius: "8px", cursor: "text" }}
                        className={`${StylesB.session_input_field} ${StylesB.session_number_field} 'border_on_hover'  px-2 ${Styles.font_16}`}
                      >
                        <p className="mb-0" style={{ color: "#E0E2E580", fontSize: "14px" }}>
                          <span style={{ color: "#E0E2E5" }}>
                            {start_date} - {end_date}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={`${StylesB.active_calendar_drop_down}`}>
                    <Col lg="12" className="d-flex" style={{ padding: "12px" }}>
                      <Col lg="6" className="pl-0" style={{ paddingRight: "6px" }}>
                        <p style={{ fontSize: "12px", color: "#E0E2E5", marginBottom: "0px" }}>From</p>
                        <Datetime
                          input={false}
                          dateFormat={false}
                          timeFormat="hh:mm A"
                          closeOnSelect={true}
                          value={new Date(schedule.start_date)}
                          onChange={(e) => selectCohortFunction(e, "start_date")}
                          inputProps={{ placeholder: "Start time", className: "video-modal p-0" }}
                          style={{ background: "inherit", color: "inherit", height: "100%" }}
                          className={`session_start_time_picker blue-bg video-modal`}
                        />
                      </Col>
                      <Col lg="6" className="pr-0" style={{ paddingLeft: "6px" }}>
                        <p style={{ fontSize: "12px", color: "#E0E2E5", marginBottom: "0px" }}>To</p>
                        <Datetime
                          input={false}
                          dateFormat={false}
                          timeFormat="hh:mm A"
                          closeOnSelect={true}
                          value={new Date(schedule.end_date)}
                          onChange={(e) => selectCohortFunction(e, "end_date")}
                          inputProps={{ placeholder: "End time", className: "video-modal p-0" }}
                          style={{ background: "inherit", color: "inherit", height: "100%" }}
                          className={`session_start_time_picker blue-bg video-modal`}
                        />
                      </Col>
                    </Col>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {showError(`time_error`)}
            </Col>
          </Col>
        </div>
        <Col lg="12" md="12" sm="12" xs="12" className="px-0 d-flex my-3">
          <Col lg="5" md="5" sm="5" xs="5" style={{ textAlign: "end" }}>
            <p style={{ color: "#E0E2E5", fontSize: "15px", marginBottom: "0px" }}>No of Sessions</p>
          </Col>
          <Col lg="7" md="7" sm="7" xs="7" className={`${StylesB.disabled_div}`}>
            <div
              style={{
                height: "35px",
                maxWidth: "50px",
                backgroundColor: "#4F6080",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "9px",
                border: "1px solid #303C54",
              }}
            >
              <InputGroup className="align-items-center" style={{ borderRadius: "9px" }}>
                <FormControl
                  className={`border-0 d-flex justify-content-center px-2 text-white`}
                  style={{ maxWidth: "45px", height: "35px", backgroundColor: "transparent", borderRadius: "9px", fontSize: "16px" }}
                  type="number"
                  name="session_count"
                  defaultValue={schedule.session_count}
                  // onChange={(e) => selectCohortFunction(parseInt(e.target.value), e.target.name )}
                  id="session_count"
                  required
                  placeholder="00"
                />
              </InputGroup>
            </div>
            <p style={{ color: "#E0E2E5", fontSize: "15px", marginBottom: "0px", marginLeft: "8px" }}>
              Total number of sessions will depend on cohort duration and schedule
            </p>
          </Col>
        </Col>
      </Col>
    )
  }
  function showScheduleText(schedule) {
    let weeks = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    let schedule_text = ""
    if (schedule.repeat_type === "Week") {
      let week_schedule_keys = Object.keys(schedule.week_schedule)
      week_schedule_keys.map((week, index) => {
        let current_week = schedule.week_schedule[week]
        current_week.forEach((item, index1) => {
          schedule_text += weeks[item]
          schedule_text += index1 === current_week.length - 1 ? "" : index1 === current_week.length - 2 ? " & " : ", "
        })
        if (schedule_text) {
          schedule_text = schedule_text + (week_schedule_keys.length === 1 ? " of every week" : " of week " + week)
          schedule_text += index === week_schedule_keys.length - 1 ? "" : index === week_schedule_keys.length - 2 ? " & " : ", "
        }
      })
      return schedule_text
    } else {
      let month_schedule_keys = Object.keys(schedule.month_schedule)
      month_schedule_keys.map((month, index) => {
        let current_month = schedule.month_schedule[month].schedule
        current_month.forEach((item, index1) => {
          schedule_text += item + showOrdinal(item)
          schedule_text += index1 === current_month.length - 1 ? "" : index1 === current_month.length - 2 ? " & " : ", "
        })
        if (schedule_text) {
          schedule_text = schedule_text + (month_schedule_keys.length === 1 ? " of every month" : " of month " + month)
          schedule_text += index === month_schedule_keys.length - 1 ? "" : index === month_schedule_keys.length - 2 ? " & " : ", "
        }
      })
      return schedule_text
    }
  }

  function handleModalClose() {
    setShowScheduleDays(false)
    let errors = { ...scheduleErrors }
    errors["week_schedule_error"] = ""
    errors["month_schedule_error"] = ""
    setScheduleErrors(errors)
  }

  return (
    <React.Fragment>
      <Modal show={showScheduleDays} onHide={() => handleModalClose()} centered className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}>
        <div className={`${StylesB.delete_modal_header} d-flex`} style={{ backgroundColor: "#42506C" }}>
          <p style={{ color: "#E0E2E5", fontSize: "15px", marginBottom: "0px", lineHeight: 1 }}>Select days</p>
          <div className="pl-3">{showError(`week_schedule_error`)}</div>
          <img
            onClick={() => handleModalClose()}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{ objectFit: `contain`, height: `13px`, position: "absolute", top: "11px", right: "5px" }}
          />
        </div>
        <Modal.Body className="p-0" style={{ backgroundColor: "#42506C", borderRadius: "0px 0px 8px 8px" }}>
          {showSelectingDays()}
        </Modal.Body>
      </Modal>
      {showCohortScheduleSection()}
    </React.Fragment>
  )
}

export default CohortSchedule
