import React, { useEffect, useState } from "react"
// import { ZoomMtg } from "@zoomus/websdk"
import { deleteRequest, postRequest, putRequest, getRequest } from "../../services/rest_service"
import InputField from "../custom/InputField"

let createPayload = {
  settings: {
    additional_data_center_regions: null,
    host_video: true,
    participant_video: true,
    cn_meeting: false,
    in_meeting: false,
    join_before_host: false,
    jbh_time: 0,
    mute_upon_entry: false,
    watermark: false,
    use_pmi: false,
    approval_type: 2,
    registration_type: 0,
    audio: null,
    auto_recording: "none",
    alternative_hosts: null,
    close_registration: false,
    waiting_room: true,
    global_dial_in_countries: null,
    contact_name: null,
    contact_email: null,
    registrants_email_notification: false,
    meeting_authentication: false,
    authentication_option: null,
    authentication_domains: null,
    breakout_room: { enable: true, rooms: [{ name: "Main Meeting Room", participants: ["sanket@leanpitch.com"] }] },
    language_interpretation: null,
    show_share_button: false,
    allow_multiple_devices: false,
    encryption_type: null,
    approved_or_denied_countries_or_regions: null,
    alternative_hosts_email_notification: false,
  },
  template_id: null,
  tracking_fields: null,
  topic: "Turient Zoom Testing",
  type: 2,
  start_time: null,
  duration: 20,
  timezone: "Asia/Kolkata",
  agenda: "Turient Zoom Testing",
  password: null,
  recurrence: null,
}

let apiKeys = {
  apiKey: "VCqh64-HSYGJqix0IP4n_g",
  apiSecret: "dgVOMNLGQzh8fKu4RGxVksLLUo5smvLbzmIR",
}
let meetConfig = {
  apiKey: apiKeys.apiKey,
  meetingNumber: 86995950830,
  userName: "app@playscrum.com",
  role: 1,
  passWord: "",
}

function ZoomList(location, props) {
  meetConfig.meetingNumber = new URLSearchParams(location.props.location.search).get("meeting")
  meetConfig.userName = new URLSearchParams(location.props.location.search).get("username")
  const [showEditPopup, setShowEditPopup] = useState(false)
  const [meetingName, setMeetingName] = useState(null)
  const [meetingBrParticipants, setMeetingBrParticipants] = useState(null)
  const [zoomMeetingDetails, setZoomMeetingDetails] = useState({})

  var meetingNumber = meetConfig.meetingNumber

  /* function getSignature(e) {
    ZoomMtg.setZoomJSLib("https://source.zoom.us/2.7.0/lib", "/av")
    ZoomMtg.preLoadWasm()
    ZoomMtg.prepareJssdk()

    ZoomMtg.generateSDKSignature({
      sdkKey: "oyKr9J1Zs12UtfAovP9OT61GUjoIaJL6qjYG",
      sdkSecret: "OuD0bImQupDm1e3zLpgkFS2Ao3umE7zrkF4c",
      meetingNumber: meetingNumber,
      role: meetConfig.role,
      success: function (res) {
        console.log("res", res)

        startMeeting(res.result)
      },
    })
  } */

  /* function startMeeting(signature) {
    // console.log(signature)
    document.getElementById("zmmtg-root").style.display = "block"
    ZoomMtg.init({
      leaveUrl: window.location.href,
      isSupportAV: true,
      isSupportChat: false,
      success: function (success) {
        console.log("Success While initializing Meeting", success)
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: meetConfig.userName,
          sdkKey: "oyKr9J1Zs12UtfAovP9OT61GUjoIaJL6qjYG",
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          },
        })
      },
      error: function (error) {
        console.log("Error While initializing Meeting", error)
      },
    })
  } */

  const createMeeting = async (event) => {
    const create_meeting = await postRequest(`/zoom/meeting/`, createPayload)
    if (create_meeting.success) {
      setZoomMeetingDetails(create_meeting.data.data)
      window.location.href = `http://localhost:8001/app/zoom/list?meeting=${create_meeting.data.data.id}&username=${create_meeting.data.data.host_email}`
    }
  }

  const deleteMeeting = async (event) => {
    const create_meeting = await deleteRequest(`/zoom/meeting/${meetConfig.meetingNumber}/`, {})
    if (create_meeting.success) {
      window.location.href = `http://localhost:8001/app/zoom/list`
    }
  }

  const updateMeeting = () => {
    setShowEditPopup(true)
  }

  const handleChange = (event) => {
    if (event.target.name === "breakout_participants") {
      setMeetingBrParticipants(event.target.value)
    } else if (event.target.name === "meeting_name") {
      setMeetingName(event.target.value)
    }
  }

  const saveUpdateMeeting = async () => {
    createPayload.agenda = meetingName
    createPayload.topic = meetingName
    createPayload.settings.breakout_room.rooms[0].name = "New Room"
    createPayload.settings.breakout_room.rooms[0].participants.push(meetingBrParticipants)
    const update_meeting = await putRequest(`/zoom/meeting/${meetConfig.meetingNumber}/`, createPayload)
    if (update_meeting.success) {
      window.location.reload()
    }
  }
  const getMeetingDetails = async () => {
    let response = await getRequest(`/zoom/meeting/${meetConfig.meetingNumber}/`)
    if (response.success) {
      console.log(response.data, "====")
      setZoomMeetingDetails(response.data.data)
    }
  }
  useEffect(() => {
    getMeetingDetails()
  }, [])

  return (
    <div className="App">
      <main>
        {meetingNumber ? <h1>{zoomMeetingDetails.agenda}</h1> : ""}
        {/* {meetingNumber ? <div onClick={(event) => getSignature()}>Join Meeting</div> : ""} */}
        {!meetingNumber ? <div onClick={(event) => createMeeting()}>Create Meeting</div> : ""}
        {meetingNumber ? <div onClick={(event) => deleteMeeting()}>Delete Meeting</div> : ""}
        {meetingNumber ? <div onClick={(event) => updateMeeting()}>Update Meeting</div> : ""}
        {showEditPopup ? (
          <div>
            <InputField onChange={handleChange} name="meeting_name" label={"Meeting Name"} required />
            <InputField onChange={handleChange} name="breakout_participants" label={"Breakout Participants"} required />
            <span onClick={saveUpdateMeeting}>Save Update Details</span>
          </div>
        ) : (
          ""
        )}
      </main>
    </div>
  )
}

export default ZoomList
