import React, { useEffect, useState, useCallback } from "react"
import { navigate } from "gatsby"
import _ from "lodash"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CropImage from "../../components/crop-image/crop-image"
import HeaderTab from "./HeaderTab"
import InputFieldWrap from "./InputFieldWrap"
import TextAreaWrap from "./TextAreaWrap"

import Style from "./trainer.module.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft, faVideo, faSignature, faCheck, faExclamationTriangle, faCamera } from "@fortawesome/free-solid-svg-icons"
import TrainerProfilePic from "../../images/svgs/trainer-profile-pic.svg"
import fb from "../../images/svgs/fbicon.svg"
import linkedin from "../../images/svgs/linkedinicon.svg"
import twitter from "../../images/svgs/twittericon.svg"
// import hideMessage from "../../services/hidemessage";
import Notification from "../custom/Notification"
import InputField from "../custom/InputField"
import Btn from "../custom/Button"
import { isBrowser } from "../../services/developer_mode"

const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

const TrainerEdit = (location) => {
  const [activeTab, setActiveTab] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [signatureModalShow, setSignatureModalShow] = useState(false)
  const [cropData, setCropData] = useState("#")
  const [signatureCropData, setSignatureCropData] = useState("#")
  const [fileName, setFileName] = useState(null)
  const [signatureFileName, setSignatureFileName] = useState(null)
  const [showUploadVideo, setShowUploadVideo] = useState(false)
  const [showUploadSign, setShowUploadSign] = useState(false)
  const [socialLinks, setSocialLinks] = useState([
    { name: "facebook", icon: fb, color: "#4261A2", show: false },
    { name: "linkedin", icon: linkedin, color: "#007BB5", show: false },
    { name: "twitter", icon: twitter, color: "#1DA1F2", show: false },
  ])
  const [socialLinksError, setSocialLinksError] = useState({
    facebook_url: false,
    facebook_url_error: "",
    linkedin_url: false,
    linkedin_url_error: "",
    twitter_url: false,
    twitter_url_error: "",
  })
  const [marginSocial, setMarginSocial] = useState(false)
  const [trainerDetail, setTrainerDetail] = useState({})
  const [trainerDetailUpdated, setTrainerDetailUpdated] = useState(false)
  const [loader, setLoader] = useState(false)
  const [changedTrainerDetail, setChangedTrainerDetail] = useState({})
  const [validate, setValidationError] = useState(new Set())
  const [notificationData, setNotificationData] = useState(null)

  const delayedQuery = useCallback(
    _.debounce((name, value) => sendQuery(name, value), 1000),
    [validate]
  )

  const requiredFields = ["name", "display_name", "designation", "trainer_company", "mini_detail", "expertise"]

  useEffect(() => {
    getTrainerDetails()
  }, [])
  useEffect(() => {
    if (Object.keys(trainerDetail).length > 0 && trainerDetailUpdated) {
      handleSubmit("none")
    }
  }, [Object.keys(trainerDetail).length, trainerDetailUpdated])

  function handleCloseWin() {
    if (window.opener) window.opener.postMessage({ instruction: "payload", type: "create-trainer" }, window.location.origin)
    window.close()
  }

  function sendQuery(name, value) {
    if ((value !== "" && requiredFields.indexOf(name) !== -1) || requiredFields.indexOf(name) === -1) {
      if (validate.has(name)) {
        setValidationError((prev) => {
          const next = new Set(prev)
          next.delete(name)
          return next
        })
      }
      changedTrainerDetail[name] = value
      setChangedTrainerDetail(changedTrainerDetail)
      setTrainerDetailUpdated(true)
    } else {
      setValidationError((prev) => new Set(prev).add(name))
    }
  }

  function onBlurData(name, value) {
    if (name !== null) {
      // let validate = new Set([...validationError])
      if ((value !== "" && requiredFields.indexOf(name) !== -1) || requiredFields.indexOf(name) === -1) {
        if (validate.has(name)) {
          setValidationError((prev) => {
            const next = new Set(prev)
            next.delete(name)
            return next
          })
        }
        changedTrainerDetail[name] = value
        setChangedTrainerDetail(changedTrainerDetail)
        setTrainerDetailUpdated(true)
      } else {
        setValidationError((prev) => new Set(prev).add(name))
      }
    }
  }

  const convertBase64ToFile = (file) => {
    let block = file.split(";")
    let contentType = block[0].split(":")[1]
    let realData = block[1].split(",")[1]
    let blob = b64toBlob(realData, contentType)
    return blob
  }

  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || ""
    sliceSize = sliceSize || 512
    let byteCharacters = atob(b64Data)
    let byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize)
      let byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      let byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    let blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  useEffect(() => {
    if (cropData !== trainerDetail.avatar && cropData !== "#") {
      changedTrainerDetail["avatar"] = convertBase64ToFile(cropData)
      setLoader(true)
      setChangedTrainerDetail(changedTrainerDetail)
      setTrainerDetailUpdated(true)
    }
  }, [cropData])
  useEffect(() => {
    if (signatureCropData !== trainerDetail.trainer_signature && signatureCropData !== "#") {
      changedTrainerDetail["trainer_signature"] = convertBase64ToFile(signatureCropData)
      setLoader(true)
      setChangedTrainerDetail(changedTrainerDetail)
      setTrainerDetailUpdated(true)
    }
  }, [signatureCropData])

  function getCropDataProps(cropData, fileName) {
    setCropData(cropData)
    setFileName(fileName)
  }
  function getSignatureCropDataProps(cropData, fileName) {
    setSignatureCropData(cropData)
    setSignatureFileName(fileName)
  }

  function getTrainerDetails() {
    axios
      .get(`${process.env.GATSBY_REACT_APP_API_HOST}/trainers/${location.trainer_id}/`, { headers: login_credentials })
      .then((res) => {
        setTrainerDetail(res.data)
        if (res.data.avatar) {
          setCropData(res.data.avatar)
        }
        if (res.data.trainer_signature) {
          setSignatureCropData(res.data.trainer_signature)
          setShowUploadSign(true)
        }
      })
      .catch((error) => {
        // hideMessage.displayDynamicErrorMsg(error)
        setNotificationData({ type: "error", title: error })
      })
  }

  function handleSubmit(event = null) {
    if (event !== "none" && event !== null) {
      event.preventDefault()
    }
    setSocialLinksError({ facebook_url: false, facebook_url_error: "", linkedin_url: false, linkedin_url_error: "", twitter_url: false, twitter_url_error: "" })
    let formData = new FormData()
    for (let k in changedTrainerDetail) {
      if (k === "avatar") {
        if (changedTrainerDetail["avatar"] && changedTrainerDetail["avatar"]["type"]) {
          formData.append(
            "avatar",
            changedTrainerDetail["avatar"],
            fileName && fileName !== null ? fileName : `${changedTrainerDetail.name}` + changedTrainerDetail["avatar"]["type"].replace("image/", ".")
          )
        } else {
          delete changedTrainerDetail.avatar
        }
      } else if (k === "trainer_signature") {
        if (changedTrainerDetail["trainer_signature"] && changedTrainerDetail["trainer_signature"]["type"]) {
          formData.append(
            "trainer_signature",
            changedTrainerDetail["trainer_signature"],
            signatureFileName && signatureFileName !== null
              ? signatureFileName
              : `trainer-signature-` + changedTrainerDetail["trainer_signature"]["type"].replace("image/", ".")
          )
        } else {
          delete changedTrainerDetail.trainer_signature
        }
      } else if (k !== "avatar" && k !== "trainer_signature") {
        // delete changedTrainerDetail.trainer_video
        formData.append(k, changedTrainerDetail[k])
      }
    }
    formData.append("company", location.company_id)
    axios
      .put(`${process.env.GATSBY_REACT_APP_API_HOST}/trainers/${location.trainer_id}/`, formData, { headers: login_credentials })
      .then((res) => {
        setTrainerDetail(res.data)
        setLoader(false)
        setTrainerDetailUpdated(false)
        if (window.location.hash === "#create") {
          navigate(`/app/dashboard/`)
        } else {
          // navigate(-1)
        }
      })
      .catch((error) => {
        let error_data = error.response.data
        displaySocialMediaLinkError(error_data)
        setLoader(false)
        setTrainerDetailUpdated(false)
        // hideMessage.displayDynamicErrorMsg(error)
        setNotificationData({ type: "error", title: error })
      })
  }
  const displaySocialMediaLinkError = (error_data) => {
    let socialPlatformUrlError = { ...socialLinksError }
    let social_medial_url_key = ["facebook", "linkedin", "twitter"]
    for (let index = 0; index < social_medial_url_key.length; index++) {
      let key = social_medial_url_key[index]
      if (error_data[`${key}_url`]) {
        socialPlatformUrlError[`${key}_url`] = true
        socialPlatformUrlError[`${key}_url_error`] = error_data[`${key}_url`][0]
        displaySocialMediaLinkDiv(key)
      } else {
        socialPlatformUrlError[`${key}_url`] = false
        socialPlatformUrlError[`${key}_url_error`] = ""
      }
    }
    setSocialLinksError(socialPlatformUrlError)
  }
  const displaySocialMediaLinkDiv = (key_name) => {
    let social_media_link = [...socialLinks]
    let index = social_media_link.findIndex((media) => media.name === key_name)
    if (index > -1) {
      social_media_link[index]["show"] = true
    }
    setSocialLinks(social_media_link)
  }

  const updateSocialLinks = (index, toggle) => {
    let social_links = [...socialLinks]
    social_links[index].show = toggle
    setSocialLinks(social_links)
  }

  useEffect(() => {
    let x = false
    socialLinks.forEach((data) => {
      if (data.show || trainerDetail[`${data?.name}_url`] !== "") {
        x = true
      }
    })
    setMarginSocial(x)
  }, [socialLinks, trainerDetail])

  useEffect(() => {
    if (isBrowser()) document.getElementById("trainer-box-edit").scrollBy(0, document.getElementById("trainer-box-edit").scrollHeight)
  }, [showUploadVideo, showUploadSign])

  function getFormData() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-0 pt-2 mx-0 br-9px" style={{ backgroundColor: "#212C42", boxShadow: "0px 3px 12px #00000099" }}>
        <form className="mb-0" onSubmit={handleSubmit}>
          <div id="trainer-box-edit" className="d-flex mt-2 mb-3 px-3" style={{ maxWidth: "99%", maxHeight: "calc(100vh - 290px)", overflowY: "scroll" }}>
            <CropImage
              show={modalShow}
              onHide={() => setModalShow(false)}
              getCropDataProps={getCropDataProps}
              fileName={fileName}
              avatar={
                cropData !== "#"
                  ? cropData
                  : trainerDetail["avatar"] && trainerDetail["avatar"].includes("https://turient-static-bucket")
                  ? trainerDetail["avatar"]
                  : null
              }
            />
            <Col
              lg="2"
              md="2"
              sm="3"
              xs="3"
              className="float-left px-0"
              style={{
                backgroundColor: "#303C54",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "50%",
                textAlign: "center",
                border: "10px solid #586886",
                maxWidth: "200px",
                maxHeight: "200px",
              }}
            >
              {cropData !== "#" ? (
                <LazyLoadImage
                  onClick={(e) => {
                    e.preventDefault()
                    setModalShow(true)
                  }}
                  className={`circle pointer mb-0 h-100 w-100`}
                  src={cropData}
                  alt="Trainer profile pic"
                />
              ) : (
                <>
                  <img
                    src={TrainerProfilePic}
                    onClick={(e) => {
                      e.preventDefault()
                      setModalShow(true)
                    }}
                    className={`circle pointer h-100 w-100`}
                    alt="Trainer profile pic placeholder"
                  />
                </>
              )}
              <div
                className={`text-center p-1 circle ${Style.bg_5} ${Style.hover_border} pointer`}
                onClick={(e) => {
                  e.preventDefault()
                  setModalShow(true)
                }}
                style={{ position: "absolute", bottom: "0px", left: "70%", width: "40px", height: "40px" }}
              >
                <FontAwesomeIcon icon={faCamera} />
              </div>
            </Col>
            <Col lg="10" md="10" sm="9" xs="9" className="float-left">
              <Row className="pl-2">
                <Col lg="6" md="6" sm="12" xs="12" className="px-1">
                  {/* <InputFieldWrap error={validate.has('name')}>
                    <input id='name' name='name' defaultValue={trainerDetail.name}
                    onChange={(e) => {e.preventDefault();console.log('changed');setLoader(true);delayedQuery(e.target.name,e.target.value)}} 
                    onBlur={(e) => {e.preventDefault(); onBlurData(e.target.value === trainerDetail.name ? null : e.target.name,e.target.value) } }
                    type="text" className={`${Style.inputText} fs-16px pt-1`} 
                    style={{background:'transparent', borderColor: 'transparent'}} autoComplete="off" 
                    required/>
                    <span className={`${Style.floating_label} text-color-1 d-flex-align-center`}>
                      Full Name <span className={`sup`}>*</span>
                    </span>
                  </InputFieldWrap> */}
                  <InputField
                    type="text"
                    name="name"
                    id="name"
                    onChange={(e) => {
                      e.preventDefault()
                      console.log("changed")
                      setLoader(true)
                      delayedQuery(e.target.name, e.target.value)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === trainerDetail.name ? null : e.target.name, e.target.value)
                    }}
                    label={"Full Name"}
                    defaultValue={trainerDetail.name}
                    required
                  />
                </Col>
                <Col lg="6" md="6" sm="12" xs="12" className="px-1">
                  {/* <InputFieldWrap error={validate.has('display_name')}>
                    <input id='display_name' name='display_name' defaultValue={trainerDetail.display_name} 
                    onChange={(e) => {e.preventDefault();setLoader(true);delayedQuery(e.target.name,e.target.value)}} 
                    onBlur={(e) => {e.preventDefault(); onBlurData(e.target.value === trainerDetail.display_name ? null : e.target.name,e.target.value) } }
                    type="text" className={`${Style.inputText} fs-16px pt-1`} 
                    style={{background:'transparent', borderColor: 'transparent'}} autoComplete="off" required/>
                    <span className={`${Style.floating_label} text-color-1 d-flex-align-center`}>
                      Display Name <span className={`sup`}>*</span>
                    </span>
                  </InputFieldWrap> */}
                  <InputField
                    type="text"
                    name="display_name"
                    id="display_name"
                    onChange={(e) => {
                      e.preventDefault()
                      setLoader(true)
                      delayedQuery(e.target.name, e.target.value)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === trainerDetail.display_name ? null : e.target.name, e.target.value)
                    }}
                    label={"Display Name"}
                    defaultValue={trainerDetail.display_name}
                    required
                  />
                </Col>
                <Col lg="6" md="6" sm="12" xs="12" className="px-1">
                  {/* <InputFieldWrap error={validate.has('designation')}>
                    <input id='designation' name='designation' defaultValue={trainerDetail.designation} 
                    onChange={(e) => {e.preventDefault();setLoader(true);delayedQuery(e.target.name,e.target.value)}} 
                    onBlur={(e) => {e.preventDefault(); onBlurData(e.target.value === trainerDetail.designation ? null : e.target.name,e.target.value) } }
                    type="text" className={`${Style.inputText} fs-16px pt-1`} 
                    style={{background:'transparent', borderColor: 'transparent'}} autoComplete="off" required/>
                    <span className={`${Style.floating_label} text-color-1 d-flex-align-center`}>
                      Designation <span className={`sup`}>*</span>
                    </span>
                  </InputFieldWrap> */}
                  <InputField
                    type="text"
                    name="designation"
                    id="designation"
                    onChange={(e) => {
                      e.preventDefault()
                      setLoader(true)
                      delayedQuery(e.target.name, e.target.value)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === trainerDetail.designation ? null : e.target.name, e.target.value)
                    }}
                    label={"Designation"}
                    defaultValue={trainerDetail.designation}
                    required
                  />
                </Col>
                <Col lg="6" md="6" sm="12" xs="12" className="px-1">
                  {/* <InputFieldWrap error={validate.has('trainer_company')}>
                    <input id='trainer_company' name='trainer_company' defaultValue={trainerDetail.trainer_company} 
                    onChange={(e) => {e.preventDefault();setLoader(true);delayedQuery(e.target.name,e.target.value)}} 
                    onBlur={(e) => {e.preventDefault(); onBlurData(e.target.value === trainerDetail.trainer_company ? null : e.target.name,e.target.value) } }
                    type="text" className={`${Style.inputText} fs-16px pt-1`} 
                    style={{background:'transparent', borderColor: 'transparent'}} autoComplete="off" required/>
                    <span className={`${Style.floating_label} text-color-1 d-flex-align-center`}>
                      Company <span className={`sup`}>*</span>
                    </span>
                  </InputFieldWrap> */}
                  <InputField
                    type="text"
                    name="trainer_company"
                    id="trainer_company"
                    onChange={(e) => {
                      e.preventDefault()
                      setLoader(true)
                      delayedQuery(e.target.name, e.target.value)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === trainerDetail.trainer_company ? null : e.target.name, e.target.value)
                    }}
                    label={"Company"}
                    defaultValue={trainerDetail.trainer_company}
                    required
                  />
                </Col>
                <Col lg="6" md="6" sm="12" xs="12" className="px-1">
                  <TextAreaWrap error={validate.has("mini_detail")}>
                    <textarea
                      name="mini_detail"
                      id="mini_detail"
                      defaultValue={trainerDetail.mini_detail}
                      onChange={(e) => {
                        e.preventDefault()
                        setLoader(true)
                        delayedQuery(e.target.name, e.target.value)
                      }}
                      onBlur={(e) => {
                        e.preventDefault()
                        onBlurData(e.target.value === trainerDetail.mini_detail ? null : e.target.name, e.target.value)
                      }}
                      rows="3"
                      className={`${Style.inputText} ${Style.textArea} fs-16px pt-1`}
                      style={{ background: "transparent", borderColor: "transparent" }}
                      autoComplete="off"
                      required
                    />
                    <span className={`${Style.floating_label} text-color-1 d-flex-align-center`}>
                      Add a short bio <span className={`sup`}>*</span>
                    </span>
                  </TextAreaWrap>
                </Col>
                <Col lg="6" md="6" sm="12" xs="12" className="px-1">
                  <TextAreaWrap error={validate.has("expertise")}>
                    <textarea
                      name="expertise"
                      id="expertise"
                      defaultValue={trainerDetail.expertise}
                      placeholder={`Type areas of expertise separated by a comma`}
                      onChange={(e) => {
                        e.preventDefault()
                        setLoader(true)
                        delayedQuery(e.target.name, e.target.value)
                      }}
                      onBlur={(e) => {
                        e.preventDefault()
                        onBlurData(e.target.value === trainerDetail.expertise ? null : e.target.name, e.target.value)
                      }}
                      rows="3"
                      className={`${Style.inputText} ${Style.textArea} fs-16px pt-1`}
                      style={{ background: "transparent", borderColor: "transparent" }}
                      autoComplete="off"
                      required
                    />
                    <span className={`${Style.floating_label} text-color-1 d-flex-align-center`}>
                      Areas of expertise <span className={`sup`}>*</span>
                    </span>
                  </TextAreaWrap>
                </Col>
                <Col lg="12" md="12" sm="12" xs="12" className="px-1">
                  {/* <InputFieldWrap>
                    <div className={`d-flex`}>
                      <div className={`p-1`}>
                        <FontAwesomeIcon className="h-100" style={{color: '#838B9E', fontSize: '18px', width: '25px'}} icon={faQuoteLeft}/>
                      </div>
                      <div className={`ml-2 flex-grow-1`}>
                        <input id='personal_quote' name='personal_quote' defaultValue={trainerDetail.personal_quote} 
                        onChange={(e) => {e.preventDefault();setLoader(true);delayedQuery(e.target.name,e.target.value)}} 
                        onBlur={(e) => {e.preventDefault(); onBlurData(e.target.value === trainerDetail.personal_quote ? null : e.target.name,e.target.value) } }
                        type="text" className={`${Style.inputText} fs-16px pt-1`} 
                        style={{background:'transparent', borderColor: 'transparent'}} autoComplete="off" />
                        <span style={{left: 'auto'}} className={`${Style.floating_label} text-color-1 d-flex-align-center`}>
                          <span>Add a personal quote</span>
                        </span>
                      </div>
                    </div>
                  </InputFieldWrap> */}
                  <InputField
                    type="text"
                    name="personal_quote"
                    id="personal_quote"
                    onChange={(e) => {
                      e.preventDefault()
                      setLoader(true)
                      delayedQuery(e.target.name, e.target.value)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === trainerDetail.personal_quote ? null : e.target.name, e.target.value)
                    }}
                    label={"Add a personal quote"}
                    defaultValue={trainerDetail.personal_quote}
                    icon={{ name: faQuoteLeft }}
                    required
                  />
                </Col>

                <div className={`d-flex w-100 flex-column mt-3`}>
                  <div className={`fs-14px mb-1`} style={{ color: "#838B9E" }}>
                    Connect to Social media
                  </div>
                  <div className={`text-center d-flex flex-wrap`}>
                    {socialLinks.map((media, index) =>
                      media.show === true || trainerDetail[`${media.name}_url`] !== "" ? (
                        <Col key={index} lg="12" md="12" sm="12" xs="12" className="px-1">
                          <div className={`mb-2 px-1 border_on_hover inner-element-box-shadow br-9px ${Style.bg_3} ${Style.hover_border}`}>
                            <div
                              className="p-2 d-flex"
                              style={{ border: "1px solid transparent", background: "transparent", borderRadius: "0px", width: "100%" }}
                            >
                              <div
                                className={`mr-2 p-1 circle`}
                                onClick={(e) => {
                                  updateSocialLinks(index, false)
                                }}
                                style={{ width: "40px", height: "40px", backgroundColor: media.color }}
                              >
                                <img className={`mb-0 pointer`} src={media.icon} alt={`${media.name}_icon`} />
                              </div>
                              <div className={`ml-2 flex-grow-1`}>
                                <input
                                  name={`${media.name}_url`}
                                  placeholder={`Add your ${media.name} link`}
                                  defaultValue={trainerDetail[`${media.name}_url`]}
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setLoader(true)
                                    delayedQuery(e.target.name, e.target.value)
                                  }}
                                  onBlur={(e) => {
                                    e.preventDefault()
                                    onBlurData(e.target.value === trainerDetail[`${media.name}_url`] ? null : e.target.name, e.target.value)
                                  }}
                                  type="text"
                                  className={`${Style.inputText} fs-16px`}
                                  style={{ background: "transparent", borderColor: "transparent" }}
                                />
                                {/*<span style={{left: 'auto'}} className={`${Style.floating_label} text-color-1 d-flex-align-center`}>
                                  <span>{`Add your ${media.name} link`}</span>
                                </span>*/}
                              </div>
                            </div>
                          </div>
                          {!socialLinksError[`${media.name}_url`] ? null : (
                            <Col xs="12" sm="12" md="12" lg="12" className={`mb-3 text-left px-0`}>
                              <div className="d-flex align-items-baseline px-1 py-0" style={{ borderRadius: "23px" }}>
                                <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                                <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                                  {socialLinksError[`${media.name}_url_error`]}
                                </p>
                              </div>
                            </Col>
                          )}
                        </Col>
                      ) : (
                        <div
                          key={media.name}
                          onClick={(e) => {
                            updateSocialLinks(index, true)
                          }}
                          className={`p-1 ${marginSocial ? "ml-3" : index !== 0 ? "ml-3" : ""} mb-2 circle`}
                          style={{ width: "40px", height: "40px", backgroundColor: media.color }}
                        >
                          <img className={`mb-0 pointer`} src={media.icon} alt={`${media.name}_icon`} />
                        </div>
                      )
                    )}
                  </div>
                  <div className={`d-flex flex-column w-100 mt-4`}>
                    {!showUploadVideo ? null : (
                      <React.Fragment>
                        <span className={`fs-14px mb-3`} style={{ color: "#838B9E" }}>
                          Your Videos
                        </span>
                        <div
                          style={{ color: "#838B9E" }}
                          className={`${Style.bg_3} ${Style.hover_border} py-4 inner-element-box-shadow bold-500 fs-14px text-center mb-2 br-9px`}
                        >
                          Drag and drop Video
                        </div>
                      </React.Fragment>
                    )}
                    {!showUploadSign ? null : (
                      <React.Fragment>
                        <CropImage
                          show={signatureModalShow}
                          onHide={() => setSignatureModalShow(false)}
                          getCropDataProps={getSignatureCropDataProps}
                          fileName={signatureFileName}
                          avatar={
                            signatureCropData && signatureCropData !== "#"
                              ? signatureCropData
                              : trainerDetail["trainer_signature"] && trainerDetail["trainer_signature"].includes("https://turient-static-bucket")
                              ? trainerDetail["trainer_signature"]
                              : null
                          }
                          type="signature"
                        />
                        <span className={`fs-14px mb-3`} style={{ color: "#838B9E" }}>
                          Your Signature
                        </span>
                        <div
                          style={{ color: "#838B9E" }}
                          className={`${Style.bg_3} ${Style.hover_border} py-4 inner-element-box-shadow bold-500 fs-14px text-center mb-2 br-9px px-3`}
                        >
                          {signatureCropData && signatureCropData !== "#" ? (
                            <LazyLoadImage
                              onClick={(e) => {
                                e.preventDefault()
                                setSignatureModalShow(true)
                              }}
                              className={`pointer float-left mb-0`}
                              src={signatureCropData}
                              alt="Trainer signature pic"
                              style={{ objectFit: "contain", maxHeight: "75px" }}
                            />
                          ) : (
                            <React.Fragment>
                              Drag and drop Image/
                              <span
                                onClick={(e) => {
                                  e.preventDefault()
                                  setSignatureModalShow(true)
                                }}
                                className={`pointer mb-0`}
                                style={{ textDecoration: "underline" }}
                              >
                                browse
                              </span>
                              <div className={`fs-12px`}>Make sure your signature is in .png Format</div>
                            </React.Fragment>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <div className={`w-100 text-right`}>
                    <div className="pt-1">
                      {!showUploadVideo && (
                        <button
                          onClick={(e) => {
                            setShowUploadVideo(true)
                          }}
                          className={`border_on_hover fs-14px text-color-1 px-3 py-1 mr-2 inner-element-box-shadow ${Style.bg_5} ${Style.hover_border} `}
                          style={{ borderRadius: "23px" }}
                        >
                          <span>
                            <FontAwesomeIcon className={`mr-2`} icon={faVideo} />
                          </span>
                          Add Videos that describes you
                        </button>
                      )}

                      {!showUploadSign && (
                        <button
                          onClick={(e) => {
                            setShowUploadSign(true)
                          }}
                          className={`border_on_hover fs-14px text-color-1 px-3 py-1 inner-element-box-shadow ${Style.bg_5} ${Style.hover_border} `}
                          style={{ borderRadius: "23px" }}
                        >
                          <span>
                            <FontAwesomeIcon className={`mr-2`} icon={faSignature} />
                          </span>
                          Upload your Signature
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
          </div>

          <div className="d-flex py-2 px-3" style={{ borderTop: "1px solid #63666f", boxShadow: "0px -3px 12px #00000099" }}>
            {/* <button disabled={loader} onClick={(e)=>{navigate(`/app/companies/${location?.company_id}/trainers/list`); handleCloseWin();}} className='border_on_hover fs-14px' style={{backgroundColor:'#586886',border:'1px solid #586886',height:'28px',paddingLeft:'30px',paddingRight:'30px',boxShadow:'0px 3px 6px #00000029',borderRadius:'23px',color:'#E0E2E5'}}>Done</button> */}
            <Btn
              disabled={loader}
              onClick={(e) => {
                navigate(`/app/companies/${location?.company_id}/trainers/list`)
                handleCloseWin()
              }}
              type="medium"
              className="fs-14px py-1"
              style={{ paddingLeft: "30px", paddingRight: "30px" }}
            >
              Done
            </Btn>
            <p className="mb-0 mt-1 float-left mr-3" style={{ fontSize: "14px", color: "#E0E2E5" }}>
              {loader === true ? (
                <svg
                  className="animate-spin my-auto ml-3 mr-1"
                  style={{ height: `14px`, width: `14px`, position: "relative", top: "-2px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                <FontAwesomeIcon icon={faCheck} className="ml-3 mr-1" />
              )}
              All changes are saved
            </p>
            {/* {validationError !== '' && (
                <div className='d-inline-flex px-2 py-0' style={{borderRadius:'23px'}}>
                <FontAwesomeIcon className='mt-1 mr-1' icon={faExclamationTriangle} color='#F4F411' size="sm"  style={{height:'13px'}} />
                <p className='mb-0' style={{fontSize:'14px',color:'#F4F411'}}>{validationError}</p>
              </div>
              )} */}
          </div>
        </form>
      </Col>
    )
  }

  return (
    <div>
      <SEO title="Edit Trainer" />
      <Col xs="12" sm="12" md="12" lg="12" className="pt-0 d-flex" style={{ alignItems: "center", flexDirection: "column" }}>
        <HeaderTab heading="Your Profile" active={activeTab} setActive={setActiveTab} list={["Personal Details"]} back={true} />
        {getFormData()}
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default TrainerEdit
