import React, { useEffect, useState } from "react"
import _ from "lodash"

import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Dropdown from "react-bootstrap/Dropdown"
import Collapse from "react-bootstrap/Collapse"

import { Rnd } from "react-rnd"
import SunEditor from "suneditor-react"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleDown,
  faExternalLinkSquareAlt,
  faExclamationTriangle,
  faChevronDown,
  faChevronCircleUp,
  faEllipsisV,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import Notification from "../custom/Notification"
import { isBrowser } from "../../services/developer_mode"
import Image from "../custom/Image"

import CourseImg from "../../images/svgs/courseImg.svg"
import CancelIcon from "../../images/svgs/cancel-white-icon.svg"

import StylesB from "../courses/course.module.scss"

var FromTrainingOrClassCreation = {}

const CreateQuiz = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  //Quiz

  const quizData = ["Quiz", "Mockup", "Assessment"]
  const quizDescription = [
    "Create quizzes on any topic for your learners and evaluate them in a fun way.",
    "Create interesting question mockups to help your learners prepare better.",
    "Create questions & activities on your courses to assess your learners.",
  ]
  const quizLevel = ["Easy", "Medium", "Difficult", "Mixed"]
  const quizType = ["Open", "Closed"]
  const score_display_type = ["only score", "score and answers", "answer and explanation"]
  const score_display_time = ["when the quiz ends", "after every question", "After trainer reviews"]

  const [showQuizTypes, setShowQuizTypes] = useState(true)
  const [quizTypeIndex, setQuizTypeIndex] = useState("0")
  const [quiz, setQuiz] = useState({
    name: "",
    no_of_attempts: 0,
    no_of_questions: 10,
    quiz_time: 30,
    quiz_level: 0,
    quiz_type: 0,
    score_display_type: 0,
    score_display_time: 0,
    tolerance_limit: 3,
    description: "",
    points: 0,
    data_type: 0,
    success_msg: "Yay! You got that one right.",
    error_msg: "Oops! That doesn’t seem right. Take a deep breath.",
  })
  const [width, setWidth] = useState(1000)
  const [activestate, setActiveState] = useState("quiz_detail")
  const [selectedQuizType, setSelectedQuizType] = useState(false)
  const [clickedInstructions, setClickedInstructions] = useState(false)
  const [clickedRulesOfEngagement, setClickedRulesOfEngagement] = useState(false)
  const [activeInputField, setActiveInputField] = useState(null)
  const [hovered, setHovered] = useState(false)
  const [quizInstructions, setQuizInstructions] = useState("")
  const [description, setDescription] = useState("")
  const [draggableHash, setDraggableHash] = useState({ x: 0, y: 0, width: 724, height: 486 })
  const [showRichTextModal, setShowRichTextModal] = useState(false)

  const [loader, setLoader] = useState(true)
  const [durationType, setDurationType] = useState("Mins")
  // const [errorMessage, setErrorMessage] = useState( '' )
  const [addToResLib, setAddToResLib] = useState(true)
  const [dispAddToResLib, setDispAddToResLib] = useState(false)
  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    setAddToResLib(true)
    focusOnData(null, "quiz_name")
  }, [])

  useEffect(() => {
    if (isBrowser() && document.getElementsByClassName("parent") && document.getElementsByClassName("parent").length > 0) {
      let x = parseInt(document.getElementsByClassName("parent")[0].offsetWidth - 724 - 80) / 2
      let y = parseInt(document.getElementsByClassName("parent")[0].offsetHeight - 486) / 2
      setDraggableHash({ x: x, y: y, width: 724, height: 486 })
    }
  }, [showRichTextModal])

  useEffect(() => {
    setLoader(true)
  }, [!loader])

  useEffect(() => {
    if (isBrowser()) {
      if (window.opener !== null && window.name === "quiz12") {
        window.opener.postMessage({ instruction: "ready", type: "quiz" }, window.location.origin)
      }
      if (window.FromTrainingOrClassCreation) {
        FromTrainingOrClassCreation = window.FromTrainingOrClassCreation
      }
      window.addEventListener("message", handleCrossWindow, false)
      setWidth(window.innerWidth)
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
      })
      return () => {
        window.removeEventListener("resize", () => {})
        window.removeEventListener("message", handleCrossWindow)
      }
    }
  }, [])

  useEffect(() => {
    setHovered(false)
  }, [width])

  function handleCrossWindow(event) {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      setAddToResLib(true)
      setDispAddToResLib(true)
      //console.log("instruction",FromTrainingOrClassCreation, event.data.instruction)
      window.FromTrainingOrClassCreation = FromTrainingOrClassCreation = { ...event.data.instruction }
    }
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (
      quiz.name != "" &&
      quiz.quiz_time != "0" &&
      quiz.quiz_time != "0.00" &&
      quiz.no_of_questions != "" &&
      quiz.no_of_questions != "0" &&
      quiz.no_of_questions != "0.00" &&
      quiz.no_of_questions != ""
    ) {
      // setErrorMessage('')
      quiz.description = quizInstructions
      if (durationType == "Hours") {
        let time = quiz.quiz_time
        quiz.quiz_time = time * 60
      }
      var formData = new FormData()
      quiz.no_of_attempts = quiz.no_of_attempts === "" || quiz.no_of_attempts == "0.00" || quiz.no_of_attempts == "0" ? "0" : quiz.no_of_attempts
      for (var k in quiz) {
        formData.append(k, quiz[k])
      }
      formData.append("grading", true)
      formData.append("is_added_to_library", addToResLib)
      if (FromTrainingOrClassCreation && FromTrainingOrClassCreation.sequence) {
        for (let p in FromTrainingOrClassCreation) {
          formData.append(p, FromTrainingOrClassCreation[p])
        }
        //formData.append('topic'     , FromTrainingOrClassCreation.topic_id)
        //formData.append('sequence'  , parseFloat(FromTrainingOrClassCreation.seq) + 10)
      }

      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/quiz/", formData, { headers: login_credentials })
        .then((res) => {
          navigate(`/app/quizzes/${res.data.id}/questions`)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    } else {
      if (quiz.name == "") {
        // setErrorMessage('Please enter proper quiz name')
        setNotificationData({ type: "error", title: "Please enter proper quiz name" })
      } else if (quiz.quiz_time == "0" || quiz.quiz_time == "0.00" || quiz.quiz_time == "") {
        // setErrorMessage('Quiz time cannot be 0')
        setNotificationData({ type: "error", title: "Quiz time cannot be 0" })
      } else if (quiz.no_of_questions == "0" || quiz.no_of_questions == "0.00" || quiz.no_of_questions == "") {
        // setErrorMessage('Total no of questions cannot be 0')
        setNotificationData({ type: "error", title: "Total no of questions cannot be 0" })
      } else {
        // setErrorMessage('please Enter proper data')
        setNotificationData({ type: "error", title: "please Enter proper data" })
      }
    }
  }

  function setQuizType(quiz_type) {
    setQuizTypeIndex(quiz_type)
    setSelectedQuizType(true)
    if (quiz_type == 1) {
      quiz["data_type"] = 3 // Mockup
    } else if (quiz_type == 2) {
      quiz["data_type"] = 4 // assessment
    } else {
      quiz["data_type"] = 0 //quiz
    }
    setActiveState("quiz_detail")
  }

  function showQuizCards() {
    return quizData.map((quiz, index) => {
      return (
        <Col
          key={quizData[index]}
          onClick={() => {
            setQuizType(index.toString())
            setShowQuizTypes(false)
          }}
          lg="3"
          md="3"
          sm="3"
          xs="3"
          className="pointer"
          style={{ padding: "8px", opacity: `${index == 1 || index == 2 ? "0.5" : ""}`, pointerEvents: `${index == 1 || index == 2 ? "none" : ""}` }}
        >
          <div className={`${quizTypeIndex === index.toString() ? StylesB.white_border : ""} ${StylesB.course_type_card} card_border_on_hover`}>
            <div className={`${StylesB.card_header} p-2`} style={{ backgroundImage: `url(${CourseImg})` }}>
              <p>{quizData[index]}</p>
            </div>
            <div className={`${StylesB.card_body} p-2`}>
              <p>{quizDescription[index]}</p>
            </div>
          </div>
        </Col>
      )
    })
  }

  function getNewQuizData() {
    return (
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        style={{ border: "1px solid #303C54", backgroundColor: "#303C54", borderRadius: "9px", boxShadow: "0px 3px 12px #00000099", marginBottom: "12px" }}
        className="px-0"
      >
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="d-flex pointer"
          onClick={() => setShowQuizTypes(!showQuizTypes)}
          style={{ justifyContent: "space-between", padding: "12px" }}
        >
          <p className="mb-0" style={{ fontSize: "16px", color: "#838B9E", lineHeight: 1 }}>
            Quiz Category <span style={{ color: "#E0E2E5", fontWeight: "bold" }}>{quizTypeIndex ? `: ${quizData[quizTypeIndex]}` : ""}</span>
          </p>
          {showQuizTypes ? (
            <FontAwesomeIcon
              className="pointer"
              aria-controls="example-collapse-text"
              onClick={() => setShowQuizTypes(!showQuizTypes)}
              color="#E0E2E5"
              style={{ height: "16px" }}
              icon={faChevronCircleUp}
            />
          ) : (
            <FontAwesomeIcon
              className="pointer"
              aria-controls="example-collapse-text"
              onClick={() => setShowQuizTypes(!showQuizTypes)}
              color="#E0E2E5"
              style={{ height: "16px" }}
              icon={faChevronCircleDown}
            />
          )}
        </Col>
        <Collapse in={showQuizTypes}>
          <div id="example-collapse-text">
            <Col lg="12" md="12" sm="12" xs="12" className="px-3 pt-2" style={{ borderTop: "1px solid #838B9E" }}>
              <p style={{ fontSize: "16px", color: "#838B9E", margin: "0px" }}>Please select quiz category</p>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12" className="d-flex" style={{ flexWrap: "wrap", padding: "8px 8px" }}>
              {showQuizCards()}
            </Col>
          </div>
        </Collapse>
      </Col>
    )
  }

  function focusOnData(e, id) {
    let data = document.getElementById(id)
    if (data) {
      data.focus()
    }
  }
  function handleChange(event) {
    event.preventDefault()
    event.stopPropagation()
    let quiz_data = { ...quiz }
    if (event.target.name == "no_of_attempts") {
      quiz_data[event.target.name] = event.target.value == "" ? 0 : event.target.value
    } else {
      quiz_data[event.target.name] = event.target.value
    }
    setQuiz(quiz_data)
    // setErrorMessage('')
  }

  function getFormData() {
    if (loader) {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="px-0 float-left">
          <Col
            lg="6"
            md="6"
            sm="6"
            xs="6"
            className="border_on_hover pl-0 float-left"
            style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54", marginBottom: "12px" }}
          >
            <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
              <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                <InputGroup.Text
                  className="border-0 d-flex justify-content-center"
                  style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                  id="basic-addon1"
                >
                  Quiz Name:
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className={`border-0 d-flex justify-content-center shadow-none ${StylesB.course_create} text-white`}
                style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "15px" }}
                type="text"
                name="name"
                defaultValue={quiz.name}
                onChange={(e) => handleChange(e)}
                id="quiz_name"
                placeholder="Please add a name for your Quiz"
                required
                autoFocus={true}
              />
            </InputGroup>
          </Col>
          <Col
            lg="2"
            md="2"
            sm="2"
            xs="2"
            className="border_on_hover px-0 float-left ml-2"
            style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54", marginBottom: "12px" }}
          >
            <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
              <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                <InputGroup.Text
                  className="border-0 d-flex justify-content-center pr-1"
                  style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                  id="basic-addon1"
                  onClick={(e) => focusOnData(e, "name")}
                >
                  No of questions:
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className={`border-0 p-0 d-flex justify-content-center shadow-none text-white`}
                style={{ maxWidth: "40px", backgroundColor: "#303C54", borderRadius: "0px", fontSize: "15px" }}
                type="number"
                name="no_of_questions"
                defaultValue={quiz.no_of_questions}
                onChange={(e) => handleChange(e)}
                id="no_of_questions"
                required
              />
            </InputGroup>
          </Col>
          <Col
            lg="2"
            md="2"
            sm="2"
            xs="2"
            className="border_on_hover px-0 float-left ml-2"
            style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54", marginBottom: "12px" }}
          >
            <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
              <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                <InputGroup.Text
                  className="border-0 d-flex justify-content-center"
                  style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                  id="basic-addon1"
                  onClick={(e) => focusOnData(e, "name")}
                >
                  Level:
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Dropdown drop={"left"} className="breakout-groups move-dropdown">
                <Dropdown.Toggle
                  key={"1"}
                  variant="none"
                  id="quiz-level"
                  className={`m-0 text-center dropdown-arrow-big px-2 shadow-none d-flex justify-content-center align-items-center`}
                  style={{ color: `#fff`, borderRadius: `10px`, fontSize: "15px" }}
                >
                  {quizLevel[quiz.quiz_level]}
                  <FontAwesomeIcon icon={faChevronDown} style={{ width: `16px`, height: `16px`, marginLeft: "10px" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="last_drop_item">
                  <Dropdown.Item
                    eventKey="0"
                    name="quiz_level"
                    className="last_drop_item text-white fs-14px px-2"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      quiz.quiz_level = 0
                      setLoader(false)
                    }}
                  >
                    Easy
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="1"
                    name="quiz_level"
                    className="last_drop_item text-white fs-14px px-2"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      quiz.quiz_level = 1
                      setLoader(false)
                    }}
                  >
                    Medium
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="2"
                    name="quiz_level"
                    className="last_drop_item text-white fs-14px px-2"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      quiz.quiz_level = 2
                      setLoader(false)
                    }}
                  >
                    Difficult
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="3"
                    name="quiz_level"
                    className="last_drop_item text-white fs-14px px-2"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      quiz.quiz_level = 3
                      setLoader(false)
                    }}
                  >
                    Mixed
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
          </Col>
          <Col
            lg="2"
            md="2"
            sm="2"
            xs="2"
            className="border_on_hover px-0 float-left ml-2"
            style={{
              backgroundColor: "#303C54",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "9px",
              border: "1px solid #303C54",
              marginBottom: "12px",
              width: "14%",
              minWidth: "14%",
              maxWidth: "14%",
            }}
          >
            <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
              <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                <InputGroup.Text
                  className="border-0 d-flex justify-content-center pr-1"
                  style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                  id="basic-addon1"
                >
                  Type:
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Dropdown drop={"left"} className="breakout-groups move-dropdown">
                <Dropdown.Toggle
                  key={"1"}
                  variant="none"
                  id="quiz-type"
                  className={`m-0 text-center dropdown-arrow-big px-2 shadow-none d-flex justify-content-center align-items-center`}
                  style={{ color: `#fff`, borderRadius: `10px`, fontSize: "15px" }}
                >
                  {quizType[quiz.quiz_type]}
                  <FontAwesomeIcon icon={faChevronDown} style={{ width: `16px`, height: `16px`, marginLeft: "5px" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="last_drop_item">
                  <Dropdown.Item
                    eventKey="0"
                    name="quiz_type"
                    className="last_drop_item text-white fs-14px px-2"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      quiz.quiz_type = 0
                      setLoader(false)
                    }}
                  >
                    Open
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="1"
                    name="quiz_type"
                    className="last_drop_item text-white fs-14px px-2"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      quiz.quiz_type = 1
                      setLoader(false)
                    }}
                  >
                    Closed
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
          </Col>
          <Col lg="12" md="12" sm="12" xs="12" className="px-0 float-left">
            <Col lg="6" md="6" sm="6" xs="6" className="px-0 float-left">
              <Col lg="12" md="12" sm="12" xs="12" className="px-0 float-left">
                <Col
                  lg="6"
                  md="6"
                  sm="6"
                  xs="6"
                  className="border_on_hover pl-0 float-left"
                  style={{
                    backgroundColor: "#303C54",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "9px",
                    border: "1px solid #303C54",
                    marginBottom: "12px",
                  }}
                >
                  <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                    <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                      <InputGroup.Text
                        className="border-0 d-flex justify-content-center"
                        style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                        id="basic-addon1"
                        onClick={(e) => focusOnData(e, "name")}
                      >
                        Total Time:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      className={`border-0 p-0 d-flex justify-content-center shadow-none text-white`}
                      style={{ maxWidth: "40px", backgroundColor: "#303C54", borderRadius: "0px", fontSize: "15px" }}
                      type="number"
                      name="quiz_time"
                      defaultValue={quiz.quiz_time}
                      onChange={handleChange}
                      id="quiz_time"
                      required
                    />
                    <div
                      className="d-flex justify-content-center align-self-center px-3 py-1"
                      style={{ backgroundColor: "#42506C", color: "#E0E2E5", fontSize: "15px", borderRadius: "9px" }}
                    >
                      Mins
                    </div>
                    {/*<Dropdown className="breakout-groups move-dropdown mt-1 ml-2">
                      <Dropdown.Toggle className="border-none px-2 py-1 " style={{backgroundColor:"#42506C",color:"#E0E2E5",fontSize:'15px'}} id="dropdown-basic">
                        {durationType}<FontAwesomeIcon className="pointer ml-2" color='#E0E2E5' style={{height:'16px'}} icon={faChevronDown}/>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="last_drop_item" >
                        <Dropdown.Item name='duration_type'  className="last_drop_item text-white fs-14px px-2" onClick={(e) => {e.preventDefault();e.stopPropagation();setDurationType('Mins');setLoader(false); }} >Mins</Dropdown.Item>
                        <Dropdown.Item name='duration_type' className="last_drop_item text-white fs-14px px-2" onClick={(e) => {e.preventDefault();e.stopPropagation();setDurationType('Hours');setLoader(false); }} >Hours</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>*/}
                  </InputGroup>
                </Col>
                <Col
                  lg="6"
                  md="6"
                  sm="6"
                  xs="6"
                  className="border_on_hover px-0 float-left ml-2"
                  style={{
                    backgroundColor: "#303C54",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "9px",
                    border: "1px solid #303C54",
                    marginBottom: "12px",
                    width: "48%",
                    minWidth: "48%",
                    maxWidth: "48%",
                  }}
                >
                  <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                    <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                      <InputGroup.Text
                        className="border-0 d-flex justify-content-center"
                        style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                        id="basic-addon1"
                        onClick={(e) => focusOnData(e, "name")}
                      >
                        No of attempts:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      className={`border-0 p-0 d-flex justify-content-center shadow-none text-white`}
                      style={{ maxWidth: "100px", backgroundColor: "#303C54", borderRadius: "0px", fontSize: "15px" }}
                      type="number"
                      name="no_of_attempts"
                      defaultValue={
                        quiz.no_of_attempts === "" || quiz.no_of_attempts == "0.00" || quiz.no_of_attempts == "0" || quiz.no_of_attempts == 0
                          ? ""
                          : quiz.no_of_attempts
                      }
                      value={
                        quiz.no_of_attempts === "" || quiz.no_of_attempts == "0.00" || quiz.no_of_attempts == "0" || quiz.no_of_attempts == 0
                          ? ""
                          : quiz.no_of_attempts
                      }
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      id="no_of_attempts"
                      placeholder="Unlimited"
                      required
                    />
                  </InputGroup>
                </Col>
              </Col>
            </Col>
            <Col lg="6" md="6" sm="6" xs="6" className="px-0 float-left" style={{ width: "49%", minWidth: "49%", maxWidth: "49%" }}>
              <Col
                className="col-12 border_on_hover px-0 float-left ml-2"
                style={{
                  backgroundColor: "#303C54",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "9px",
                  border: "1px solid #303C54",
                  marginBottom: "12px",
                }}
              >
                <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className="border-0 d-flex justify-content-center"
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                      id="basic-addon1"
                      onClick={(e) => focusOnData(e, "name")}
                    >
                      Display
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Dropdown drop={"up"} className="breakout-groups move-dropdown mt-1 ml-2">
                    <Dropdown.Toggle
                      className="border-none px-2 py-1 "
                      style={{ backgroundColor: "#42506C", color: "#E0E2E5", fontSize: "15px" }}
                      id="dropdown-basic"
                    >
                      {score_display_type[quiz.score_display_type]}
                      <FontAwesomeIcon className="pointer ml-3" color="#E0E2E5" style={{ height: "16px" }} icon={faChevronDown} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="last_drop_item">
                      <Dropdown.Item
                        name="score_display_type"
                        className="last_drop_item text-white fs-14px px-2"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          quiz.score_display_type = 0
                          setLoader(false)
                        }}
                      >
                        show score only
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="score_display_type"
                        className="last_drop_item text-white fs-14px px-2"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          quiz.score_display_type = 1
                          setLoader(false)
                        }}
                      >
                        show score and answers
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="score_display_type"
                        className="last_drop_item text-white fs-14px px-2"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          quiz.score_display_type = 2
                          setLoader(false)
                        }}
                      >
                        show answers and explanations
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown drop={"up"} className="breakout-groups move-dropdown mt-1 ml-2">
                    <Dropdown.Toggle
                      className="border-none px-2 py-1 "
                      style={{ backgroundColor: "#42506C", color: "#E0E2E5", fontSize: "15px" }}
                      id="dropdown-basic"
                    >
                      {score_display_time[quiz.score_display_time]}
                      <FontAwesomeIcon className="pointer ml-3" color="#E0E2E5" style={{ height: "16px" }} icon={faChevronDown} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="last_drop_item">
                      <Dropdown.Item
                        name="score_display_time"
                        className="last_drop_item text-white fs-14px px-2"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          quiz.score_display_time = 0
                          setLoader(false)
                        }}
                      >
                        when the quiz ends
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="score_display_time"
                        className="last_drop_item text-white fs-14px px-2"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          quiz.score_display_time = 1
                          setLoader(false)
                        }}
                      >
                        After every question
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="score_display_time"
                        className="last_drop_item text-white fs-14px px-2"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          quiz.score_display_time = 2
                          setLoader(false)
                        }}
                      >
                        After trainer reviews
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </InputGroup>
              </Col>
            </Col>
          </Col>
          {quiz.quiz_type == 1 ? (
            <Col
              lg="6"
              md="6"
              sm="6"
              xs="6"
              className="border_on_hover px-0 float-left"
              style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54", marginBottom: "12px" }}
            >
              <InputGroup className="py-1" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                  <InputGroup.Text
                    className="border-0 d-flex justify-content-center"
                    style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                    id="basic-addon1"
                    onClick={(e) => focusOnData(e, "name")}
                  >
                    Define no of rule breaks before auto summon:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  className={`border-0 p-0 d-flex justify-content-center shadow-none text-white`}
                  style={{ maxWidth: "40px", backgroundColor: "#303C54", borderRadius: "0px", fontSize: "15px" }}
                  type="number"
                  name="tolerance_limit"
                  defaultValue={quiz.tolerance_limit}
                  onChange={handleChange}
                  id="tolerance_limit"
                  required
                />
              </InputGroup>
            </Col>
          ) : (
            ""
          )}
          <Col lg="12" md="12" sm="12" xs="12" className="px-0 float-left">
            <div className={`${clickedInstructions || clickedRulesOfEngagement ? `` : `d-inline-flex`}`}>
              {clickedInstructions ? (
                <Col
                  className="px-0 border_on_hover pl-0 float-left"
                  style={{
                    backgroundColor: "#303C54",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "9px",
                    border: "1px solid #303C54",
                    marginBottom: "12px",
                    marginTop: "12px",
                  }}
                >
                  <InputGroup className="py-0 d-block" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                    <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                      <InputGroup.Text
                        className="border-0 d-flex justify-content-center"
                        style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "15px" }}
                        id="basic-addon1"
                        onClick={(e) => {
                          focusOnData(e, "instructions")
                          setShowRichTextModal(true)
                          setActiveInputField("Instructions")
                        }}
                      >
                        Instructions
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className={`pl-0 mx-2 mb-2`} style={{}}>
                      <div
                        className={``}
                        onClick={() => {
                          setShowRichTextModal(true)
                          setActiveInputField("Instructions")
                        }}
                        dangerouslySetInnerHTML={{ __html: quizInstructions }}
                        style={{ maxHeight: "100px", minHeight: "50px", overflowY: "scroll" }}
                      />
                    </div>
                  </InputGroup>
                </Col>
              ) : (
                <div lg="12" md="12" sm="12" xs="12" className="px-0 ml-1 mr-3 float-left" style={{ marginBottom: "12px" }}>
                  <p style={{ marginBottom: "0px", lineHeight: 0.7 }}>
                    <u
                      className="pointer"
                      style={{ fontSize: "14px", color: "#E0E2E5" }}
                      onClick={(e) => {
                        setClickedInstructions(true)
                        focusOnData(e, "instructions")
                      }}
                    >
                      Add Instructions
                    </u>
                  </p>
                </div>
              )}
            </div>
          </Col>
        </Col>
      )
    } else {
      return "loading..."
    }
  }

  function getQuizDetailsData() {
    return (
      <>
        <Col lg="12" md="12" sm="12" xs="12" className="px-0">
          {getFormData()}
        </Col>
        <Col xs="12" className="mt-3 d-none">
          <Button className="float-right" id={`form-proceed`} style={{ background: "#576186", border: 0 }} type="submit" color="warning">
            Proceed
          </Button>{" "}
        </Col>
      </>
    )
  }

  function findScrollableOrNot() {
    let ele = document.getElementById("sections")

    if (ele !== null && ele !== undefined) {
      if (ele.scrollWidth > ele.clientWidth) {
        return true
      } else {
        return false
      }
    }
  }

  function scrollRight() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft += 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function scrollLeft() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft -= 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function calculateWidth() {
    let ele = document.getElementById("coursename")
    if (ele !== null) {
      let data = 1100 - 142 - 50
      return { width: data + "px", overflowX: "scroll" }
    }
  }

  function getRichTextData() {
    if (activeInputField === "Description") {
      return (
        <SunEditor
          setContents={description}
          onChange={setDescription}
          id="Description"
          placeholder="Add Description"
          setOptions={{
            height: "auto",
            maxHeight: "400px",
            minHeight: "400px",
            buttonList: [
              ["undo", "redo", "font", "fontSize", "formatBlock"],
              ["bold", "underline", "italic", "link", "image", "codeView", "outdent", "indent", "align", "horizontalRule", "list", "table"],
            ],
          }}
        />
      )
    } else {
      return (
        <SunEditor
          setContents={quizInstructions}
          onDragStop={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onChange={setQuizInstructions}
          onClick={(e) => {
            e.stopPropagation()
          }}
          id="instructions"
          placeholder="Add Instructions"
          setOptions={{
            height: "auto",
            maxHeight: "400px",
            minHeight: "400px",
            buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link"]],
          }}
        />
      )
    }
  }

  function displayData() {
    return (
      <div
        id="mod"
        className={`box text-dark p-0 w-100 window_screen`}
        onScroll={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        style={{
          margin: 0,
          height: "100%",
          backgroundColor: "#212C42",
          border: "1px solid #E0E2E5",
          borderRadius: "11px",
          boxShadow: "0px 3px 12px #00000099",
          overflowY: "hidden",
        }}
      >
        <div className={`w-100 py-2 drag d-inline-flex align-items-center`} style={{ borderBottom: "1px solid #E0E2E5", cursor: "move" }}>
          <p className={`mb-0 text-center pl-2 w-100`} style={{ fontSize: "18px", fontWeight: "500", color: "#E0E2E5" }}>
            Add {activeInputField} Below
          </p>
          <img
            src={CancelIcon}
            className="mb-0 pointer ml-auto mr-2"
            style={{ fontSize: "15px", width: "16px" }}
            onClick={() => {
              setShowRichTextModal(false)
            }}
          />
        </div>
        {getRichTextData()}
      </div>
    )
  }

  return (
    <div>
      <SEO title="Quiz Creation" />
      <Col xs="12" sm="12" md="12" lg="12" className="pt-0 d-flex" style={{ alignItems: "center", flexDirection: "column" }}>
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          onMouseOver={(e) => {
            e.stopPropagation()
            e.preventDefault()
            let ele = document.getElementById("sections")
            if (ele !== null && ele !== undefined) {
              if (ele.scrollWidth > ele.clientWidth) {
                setHovered(true)
              }
            }
          }}
          className={`${StylesB.course_creation_header} d-flex px-0`}
        >
          <p
            className="mb-0 py-2 px-3 font-weight-bold"
            id="coursename"
            style={{ borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "18px", lineHeight: "1", minWidth: "max-content" }}
          >
            New Quiz
          </p>
          {findScrollableOrNot() && hovered ? (
            <FontAwesomeIcon
              onClick={() => {
                scrollLeft()
              }}
              className="pointer"
              icon={faChevronLeft}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551` }}
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => {
                scrollLeft()
              }}
              className="pointer"
              icon={faChevronLeft}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551`, visibility: "hidden" }}
            />
          )}
          <div id="sections" className="d-flex align-items-center" style={calculateWidth()}>
            <p
              className={`${StylesB.backgroundonhover} ${StylesB.course_nav_item_active} mb-0 pointer py-2 px-3 fs-16px font-weight-bold`}
              style={{ color: "#E0E2E5", border: "1px solid tranparent", minWidth: "max-content" }}
              id="quiz_detail"
            >
              Quiz Details
            </p>
            <Link className={`pointer ${StylesB.backgroundonhover}`} style={{ color: "#E0E2E5", border: "1px solid tranparent" }}>
              <p
                className={`mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2 fs-16px ${StylesB.backgroundonhover}`}
                style={{ minWidth: "max-content" }}
                id="add_question"
              >
                Add Questions
              </p>
            </Link>
            <p
              className={`${StylesB.backgroundonhover} mb-0 pointer py-2 pl-14px pr-14px fs-16px ml-10px `}
              style={{ color: "#E0E2E5", border: "1px solid tranparent", minWidth: "max-content" }}
              id="save_and_publish"
            >
              Publish
            </p>
            {/*<div className='float-right ml-auto'>
                <button className='border_on_hover d-flex' style={{backgroundColor:'#586886',border:'1px solid #586886',padding:'5px 15px 7px 15px',boxShadow:'0px 3px 6px #00000029',borderRadius:'23px',color:'#E0E2E5',fontSize:'14px',minWidth:'max-content'}}>
                  <p style={{marginBottom:'0px',lineHeight:1,minWidth:'max-content'}} >Preview students page</p> <FontAwesomeIcon className='ml-2' icon={faExternalLinkSquareAlt}/>
                </button>
              </div>*/}
          </div>
          {findScrollableOrNot() && hovered ? (
            <FontAwesomeIcon
              onClick={() => {
                scrollRight()
              }}
              className="pointer"
              icon={faChevronRight}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551` }}
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => {
                scrollRight()
              }}
              className="pointer"
              icon={faChevronRight}
              size="lg"
              style={{ objectFit: `contain`, height: `20px`, color: `#E0E2E551`, visibility: "hidden" }}
            />
          )}
          <Image
            src={CancelIcon}
            alt={`close`}
            width={20}
            height={20}
            onClick={(e) => {
              e.preventDefault()
              navigate("/app/dashboard")
            }}
            className={`pointer mr-3 ml-auto float-right align-self-center`}
          />
        </Col>
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          onMouseOver={() => {
            setHovered(false)
          }}
          className="px-0"
        >
          <Form className="form-horizontal" onSubmit={handleSubmit} autoComplete="off">
            <div
              className="px-3"
              style={{
                maxHeight: "calc(100vh - 190px)",
                overflowY: "scroll",
                padding: "12px",
                backgroundColor: "#212C42",
                borderRadius: "9px 9px 0px 0px",
                boxShadow: "0px 3px 12px #00000099",
              }}
            >
              {getQuizDetailsData()}
            </div>
            <Col
              className="px-0 pl-3 py-2"
              style={{ borderTop: "1px solid #63666f", backgroundColor: "#212C42", borderRadius: "0px 0px 9px 9px", boxShadow: "0px -3px 12px #00000099" }}
            >
              <div className="d-inline-block justify-content-start">
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    handleSubmit(e)
                  }}
                  className="border_on_hover fs-14px"
                  style={{
                    backgroundColor: "#586886",
                    border: "1px solid #586886",
                    height: "28px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "23px",
                    color: "#E0E2E5",
                  }}
                >
                  Next
                </button>
                {/* {showError()} */}
              </div>
              {dispAddToResLib ? (
                <div className="pr-2 d-inline-block float-right">
                  <Form.Check
                    className=""
                    style={{ fontSize: 14 }}
                    type={"checkbox"}
                    id={`default-checkbox`}
                    label={`Add this quiz to the resource library`}
                    checked={addToResLib}
                    onChange={() => setAddToResLib((p) => !p)}
                  />
                </div>
              ) : null}
            </Col>
          </Form>
        </Col>
        {!showRichTextModal ? null : (
          <div
            className={`w-100 parent h-100 position-fixed`}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowRichTextModal(false)
            }}
            style={{ inset: "0px", top: "0", bottom: "0", left: "0", right: "0", backgroundColor: "#000000b5" }}
          >
            {isBrowser() && document.getElementsByClassName("parent").length > 0 ? (
              <Rnd
                disableDragging={false}
                dragHandleClassName={"drag"}
                minWidth={324}
                minHeight={191}
                style={{ zIndex: 100 }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                bounds=".parent"
                default={{ x: `50%`, y: `50%`, width: 324, height: 191 }}
                position={{ x: draggableHash.x, y: draggableHash.y }}
                size={{ width: draggableHash.width, height: draggableHash.height }}
                onDragStop={(e, d) => {
                  setDraggableHash({ x: d.x, y: d.y, width: draggableHash.width, height: draggableHash.height })
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                  setDraggableHash({ width: ref.style.width, height: ref.style.height, x: position.x, y: position.y })
                }}
              >
                {displayData()}
              </Rnd>
            ) : (
              ``
            )}
          </div>
        )}
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default CreateQuiz
