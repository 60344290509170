import React, { Component, useEffect, useState } from "react"
import axios from "axios"
import { navigate, Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Alert from "react-bootstrap/Alert"
import Form from "react-bootstrap/Form"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import authClient from "../services/auth"
// import hideMessage from '../services/hidemessage'
import Notification from "./custom/Notification"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const Activate = (location) => {
  const [msg, setMsg] = useState("")
  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    activateAccount()
    if (authClient.isAuthenticated()) {
      navigate("/app/dashboard")
    }
  }, [])

  async function activateAccount() {
    console.log(location.uidb64, location.pk)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/activates/${location.uidb64}/${location.pk}/`)
      .then((res) => {
        setMsg(res.data)
        //removeitem
        localStorage.removeItem("successMsg")
      })
      .catch((error) => {
        if (error.response.data["error"] === "Activation link is invalid!") {
          setMsg(error.response.data)
        } else if (error.response) {
          // let error_messages = error.response.statusText
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key]
          }
          // hideMessage.displayErrorMsg(error_messages)
          setNotificationData({ type: "error", title: error_messages })
        }
      })
  }

  function displayTitle() {
    return (
      <h3 style={{ margin: 0, display: "inline-flex", alignItems: "center" }}>
        <Image style={{ width: "50px", height: "50px" }} alt="Turient logo" src="logo.png" className="d-inline-block align-center" />{" "}
        <Link
          to="/"
          className="ml-2 font-weight-bold"
          style={{
            color: `white`,
            textDecoration: `none`,
            fontFamily: `carina pro`,
          }}
        >
          {"Turient"}
        </Link>
      </h3>
    )
  }

  function showMessage() {
    if (msg["error"] === "Activation link is invalid!") {
      return (
        <Alert className={`m-0 px-3`} style={{ borderRadius: `12px`, backgroundColor: "#5b76f2" }}>
          <p className={`m-0 my-3 bg-transparent`} style={{ color: "white", fontWeight: "bold" }}>
            {" "}
            {msg["error"]}{" "}
          </p>
          <Link
            to="/app/login"
            className="py-1 px-3"
            style={{ borderRadius: "10px", fontSize: "19px", border: "1px solid  white", color: "white", fontWeight: "normal" }}
          >
            Login <FontAwesomeIcon icon={faChevronRight} style={{ color: `rgb(242 244 254)`, width: `16px`, height: `16px`, margin: `auto` }} />
          </Link>
        </Alert>
      )
    } else if (msg["error"] !== "") {
      return (
        <Alert className={`m-0 px-3`} style={{ borderRadius: `12px`, backgroundColor: "#5b76f2" }}>
          <p className={`m-0 my-3 bg-transparent`} style={{ color: "white", fontWeight: "bold" }}>
            {" "}
            {msg["success"]}{" "}
          </p>
          <Link
            to="/app/login"
            className="py-1 px-3"
            style={{ borderRadius: "10px", fontSize: "19px", border: "1px solid  white", color: "white", fontWeight: "normal" }}
          >
            {" "}
            Login <FontAwesomeIcon icon={faChevronRight} style={{ color: `rgb(242 244 254)`, width: `16px`, height: `16px`, margin: `auto` }} />
          </Link>
        </Alert>
      )
    } else return "loading..."
  }

  return (
    <div>
      <SEO title="Turient | Activate" />
      <Container className={`d-flex m-auto`} style={{ height: `calc(100vh - 69px)` }}>
        <div className={`text-center m-auto`} style={{ width: `400px` }}>
          {showMessage()}
        </div>
      </Container>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default Activate
