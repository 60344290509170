import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Pagination from "react-bootstrap/Pagination"
import FormControl from "react-bootstrap/FormControl"
import FormGroup from "react-bootstrap/FormGroup"

import Layout from "../layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"
import formatDate from "../../services/format_date"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRss, faChevronDown, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons"

const Questions = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [questionLoader, setQuestionLoader] = useState(false)
  const [questions, setQuestions] = useState([])

  const [previous_url, setPreviousUrl] = useState()
  const [next_url, setNextUrl] = useState()
  const [total_pages, setTotalPages] = useState()

  const [tag, setTag] = useState({})

  useEffect(() => {
    fetchQuestions()
  }, [])

  async function fetchQuestions() {
    setQuestionLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/questions/?data_type=0`, { headers: login_credentials }).then((res) => {
      setQuestions(res.data.results)
      setNextUrl(res.data.next)
      setPreviousUrl(res.data.previous)
      setTotalPages(parseInt(Number(res.data.count) / 5))
      setQuestionLoader(true)
    })
  }

  function displayQuestionHeader() {
    let data = questions
    var isLoaded = questionLoader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0])
        let heading = []
        heading.push(<td>&nbsp;</td>)
        heading.push(<td>&nbsp;</td>)
        heading.push(<td>&nbsp;</td>)
        for (let key in keys) {
          if (keys[key].toLowerCase() !== "created" && keys[key].toLowerCase() !== "updated_at") {
            heading.push(<th style={{ textTransform: "uppercase" }}>{keys[key].replace(/_/g, " ")}</th>)
          }
        }
        heading.push(<td>&nbsp;</td>)
        return <tr>{heading}</tr>
      } else {
        return ""
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function handleChange(event, datum) {
    event.preventDefault()
    tag[datum.id] = event.target.value
  }

  function handleSubmit(event, datum) {
    event.preventDefault()
    var formData = new FormData()
    formData.append("name", tag[datum.id])
    if (tag.id) {
    } else {
      var index = questions.findIndex((res) => res.id === datum.id)
      formData.append("add_to_question", datum.id)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/tags/create/", formData, { headers: login_credentials })
        .then((res) => {
          if (res.data) {
            // setTags(tags.concat(res.data))
            let tags_array = []
            tags_array = questions[index].tags
            questions[index].tags = tags_array.concat(res.data)
            setQuestions([...questions])
            if (document.getElementsByClassName("clear_value_" + datum.id)[0]) {
              document.getElementsByClassName("clear_value_" + datum.id)[0].value = ""
            }
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function displayQuestionData() {
    let data = questions
    var isLoaded = questionLoader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0]),
          parsed_data = []
        parsed_data.push(
          <div key={-1} className={`d-flex p-3`}>
            <div className={`pl-4 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Questions
              </p>
            </div>
            <div className={`pl-3 ml-auto mr-3 align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                score&nbsp; <FontAwesomeIcon icon={faChevronDown} size="sm" />
              </p>
            </div>
          </div>
        )
        data.map((datum, index) => {
          parsed_data.push(
            <div className={`d-flex mb-1 py-2`} key={index + `_training`} style={{ backgroundColor: `#46537E` }}>
              <div className={`pl-3 align-self-center`}>
                <FontAwesomeIcon icon={faRss} style={{ color: `#ebc280` }} size="lg" />
              </div>
              <div className={`pl-3 align-self-center`}>
                <p className={`m-0 font-weight-bold text-white`}>{datum.title}</p>
                <p className={`m-0`} style={{ color: `#27E0C3`, fontSize: "12px" }}>
                  <span>Created on </span>{" "}
                  <span>
                    {" "}
                    {`${formatDate.displayDate(datum.created)}-${formatDate.displayMonth(datum.created)}-${formatDate.displayFullYear(datum.created)} `}{" "}
                  </span>{" "}
                </p>
                <Col className="p-0">
                  <Link className={`mr-2`} to={`/app/questions/edit/` + datum.id}>
                    {" "}
                    <FontAwesomeIcon icon={faEdit} size="sm" />{" "}
                  </Link>
                  {/*<Link to={`/app/questions/edit/`+datum.id} > <FontAwesomeIcon icon={faTrash} size="sm" />  </Link>*/}
                </Col>
              </div>
              <div className={`px-3 align-self-center m-auto`}>
                <Form
                  className={`form-horizontal d-flex align-items-center pt-3`}
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit(e, datum)
                  }}
                >
                  <div className="float-left">
                    <FormGroup className={`m-0`}>
                      <input
                        style={{ background: "rgb(65, 74, 108)", color: "inherit", fontSize: "16px" }}
                        className={`blue-bg video-modal px-3 ${`clear_value_` + datum.id.toString()}`}
                        type="text"
                        name="name"
                        defaultValue={tag[datum.id] ? tag[datum.id] : ""}
                        onChange={(e) => handleChange(e, datum)}
                        id={`tag_create` + datum.id.toString()}
                        placeholder="Enter tag name"
                        required
                      />
                    </FormGroup>
                  </div>
                  <div className="float-left ml-3">
                    <Button type="submit" className={`shadow-none`} style={{ background: "#485b9e", border: "none", fontSize: "14px" }}>
                      Create tag
                    </Button>
                  </div>
                </Form>
                <div>
                  {datum.tags.map((q_tag, tag_index) => (
                    <span className={`${Styles.font_12}`} key={tag_index.toString() + `_` + datum.id.toString() + `_q_tag`}>
                      &nbsp;{q_tag.name} {datum.tags.length - 1 !== tag_index ? "," : ""}
                    </span>
                  ))}
                </div>
              </div>
              <div className={`pl-3 ml-auto mr-3 align-self-center`}>
                <p className={`mb-0 font-weight-bold pr-5`} style={{ color: `#C6CFF4` }}>
                  {datum.score}
                </p>
              </div>
            </div>
          )
        })
        return (
          <tr>
            <td>{parsed_data}</td>
          </tr>
        )
      } else {
        return (
          <tr id="loader">
            <td colSpan="12" className="animated fadeIn pt-3 text-center">
              No Data found...
            </td>
          </tr>
        )
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function showQuestions() {
    if (authClient.isAuthenticated()) {
      return (
        <table>
          {/*displayQuestionHeader()*/}
          <tbody>{displayQuestionData()}</tbody>
        </table>
      )
    } else {
      return ""
    }
  }

  function fetchPage(page) {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/questions/?page=" + page + "&data_type=0", { headers: DefaultHeader })
      .then((res) => {
        setQuestions(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function fetchNext() {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(next_url, { headers: DefaultHeader })
      .then((res) => {
        setQuestions(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }
  function fetchPrevious() {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(previous_url, { headers: DefaultHeader })
      .then((res) => {
        setQuestions(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function renderPageQuestions() {
    var rows = []
    if (previous_url) {
      rows.push(<Pagination.Prev className={`m-0 bg-transparent`} onClick={() => fetchPrevious()} />)
    }
    for (var i = 0; i < total_pages; i++) {
      let temp_var = i + 1
      rows.push(
        <Pagination.Item key={i + 1} className={`m-0 bg-transparent`} onClick={() => fetchPage(temp_var)}>
          {i + 1}
        </Pagination.Item>
      )
    }
    if (next_url) {
      rows.push(<Pagination.Next className={`m-0 bg-transparent`} onClick={() => fetchNext()} />)
    }
    return (
      <Pagination className={`bg-card mx-0 my-3`} style={{ width: `fit-content` }}>
        {rows}
      </Pagination>
    )
  }

  function searchQuestions(event) {
    // {this.setState({practitioners_loaded: false})}
    // this.setState({fetching: true})
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/questions/?search=" + event.target.value + "&data_type=0", { headers: DefaultHeader })
      .then((res) => {
        setQuestions(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  return (
    <div>
      <SEO title="Questions" />
      <Col lg="12" md="12" sm="12" xs="12">
        <h4 className={`mt-3`}>
          All Questions
          <Button
            onClick={() => {
              navigate("/app/questions/create")
            }}
            style={{ background: "#485b9e", border: "none", fontSize: "14px", float: "right" }}
          >
            + Add Question
          </Button>
        </h4>
        <div className={`col-12 d-inline-flex`} style={{ backgroundColor: `#414a6c`, borderRadius: "25px" }}>
          <FontAwesomeIcon icon={faSearch} size="lg" style={{ margin: `auto 0px`, color: `#7c84a0` }} />
          <FormControl
            autoFocus
            className="border-0 py-1 px-2 form-control px-0 shadow-none font-14"
            style={{ backgroundColor: `transparent`, color: `#b6bdd4` }}
            placeholder={`search Question`}
            onChange={searchQuestions}
            // value={searchValue}
          />
        </div>
        {renderPageQuestions()}
        <div className={`bg-card ${Styles.b_radius_15} mb-5`} style={{ overflow: "scroll" }}>
          {showQuestions()}
        </div>
      </Col>
    </div>
  )
}

export default Questions
