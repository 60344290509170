import React, { useEffect, useState } from "react"
import { postRequest } from "../../services/rest_service"
import { ZOOM_AUTHORIZATION_KEY, ZOOM_REDIRECT_KEY } from "./constants"

const ZoomAuth = (location, props) => {
  const [authStatus, setAuthStatus] = useState(false)
  const [authProgress, setAuthProgress] = useState(false)
  const [authErrorMessage, setAuthErrorMessage] = useState(null)
  let forceAuthorization = new URLSearchParams(location.props.location.search).get("forceAuthorization")
  useEffect(() => {
    console.log(location.props, "location.props")
    console.log(location.props.location, "location.props.location")
    let code = new URLSearchParams(location.props.location.search).get("code")
    console.log(code, "code")
    if (code) {
      request(code)
    } else {
      setAuthProgress(false)
      setAuthErrorMessage("You Don't Have permission to use it.")
    }
  }, [])

  if (forceAuthorization) {
    localStorage.removeItem("zoom.authorization")
  }

  const request = async (code) => {
    setAuthProgress(true)
    let zoomAuth = await postRequest(`/zoom/auth/`, {
      code: code,
      forceAuthorization: forceAuthorization,
    })
    if (zoomAuth.status) {
      setAuthProgress(false)
    }
    if (zoomAuth.success) {
      setAuthStatus(true)
      localStorage.setItem(ZOOM_AUTHORIZATION_KEY, true)
      let redirectUrl = localStorage.getItem(ZOOM_REDIRECT_KEY)
      console.log(redirectUrl, "redirectUrl")
      if (redirectUrl) {
        localStorage.removeItem(ZOOM_REDIRECT_KEY)
        window.location.href = redirectUrl
      }
    } else {
      setAuthStatus(false)
      localStorage.setItem(ZOOM_AUTHORIZATION_KEY, zoomAuth?.data?.is_authorized)
      setAuthStatus(zoomAuth?.data?.is_authorized)
      setAuthErrorMessage(zoomAuth?.data?.data?.data?.reason)
    }
    console.log(zoomAuth, "zoomAuth")
  }

  return authProgress ? <div>Authrization In Progress</div> : <div>{authStatus ? "Zoom Authorization Done" : authErrorMessage}</div>
}

export default ZoomAuth
