import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Modal from "react-bootstrap/Modal"
import { LazyLoadImage } from "react-lazy-load-image-component"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faEdit, faTrashAlt, faCircle, faChevronRight } from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Styles from "../../modularscss/styles.module.scss"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

const ViewCompany = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  /*const [company, setCompany] = useState({})*/

  const [courseLoader, setCourseLoader] = useState(false)
  const [courses, setCourses] = useState([])
  const [dmodal, setDmodal] = useState(false)
  const [currentCourse, setCurrentCourse] = useState([])

  // const { company_id } = location.id

  const handleClose = () => {
    setDmodal(false)
    setCurrentCourse([])
  }

  useEffect(() => {
    /*fetchCompany()*/
    fetchCourses()
  }, [])

  /*async function fetchCompany() {
    // console.log("fetchGroups called")
    // setCompanyLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST+`/companies/${location.id}/`, {headers: login_credentials})
    .then(res => {
      setCompany(res.data)
      // setCompanyLoader(true)
    })
  }*/

  async function fetchCourses() {
    setCourseLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-courses-list/?company=${location.id}`, { headers: login_credentials }).then((res) => {
      setCourses(res.data.results)
      setCourseLoader(true)
    })
  }

  function deleteCourse() {
    var current_course_id = currentCourse.id
    var index = courses.findIndex((res) => res.id === current_course_id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/courses/" + current_course_id + "/", { headers: DefaultHeader, body: currentCourse })
      .then((res) => {
        courses.splice(index, 1)
        setCurrentCourse([])
        setDmodal(!dmodal)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  /*function displayCompanyHeader() {
    let data = courses;
    var isLoaded = courseLoader
    if (isLoaded) {
      if(data.length > 0){
        let keys = Object.keys(data[0])
        let heading = []
        for (let key in keys) {
          if (keys[key].toLowerCase() !== 'created' &&
                keys[key].toLowerCase() !== 'updated_at'){
            heading.push(<th style={{textTransform: 'uppercase'}}>
            {keys[key].replace(/_/g, ' ')}
            </th>)
          }
        }
        heading.push(<td>&nbsp;</td>)
        heading.push(<td>&nbsp;</td>)
        heading.push(<td>&nbsp;</td>)
        return (
          <tr >
            {heading}
          </tr>
        );
      }else{
        return(
          ''
        )
      }
    }
    else {
      return (
        <tr id="loader"><td colSpan="12" className="animated fadeIn pt-3 text-center">Loading...</td></tr>
      )
    }
  }

  function displayCompanyData() {
    let data = courses;
    var isLoaded = courseLoader
    if (isLoaded) {
      if(data.length > 0){
        let keys = Object.keys(data[0]),
        parsed_data = []
        data.map( (datum, index) => {
          let values =[]
          for (let key in keys) {
            if (keys[key].toLowerCase() !== 'created' &&
                keys[key].toLowerCase() !== 'updated_at'){
              values.push(<td> {datum[keys[key]]} </td>)
            }
          }
          values.push(<td><Link to={`/app/companies/${location.id}/courses/edit/`+datum.id} > Edit</Link></td>)
          values.push(<td><Link to={`/app/companies/${location.id}/courses/view/`+datum.id} > View</Link></td>)
          values.push(<td>Delete</td>)
          parsed_data.push(<tr key={index} >{values}</tr>)
        })
        return(
          <tbody>
            {parsed_data}
          </tbody>
        )
      }else{
        return(
          'No Data Found'
        )
      }
    }
    else {
      return (
        <tr id="loader"><td colSpan="12" className="animated fadeIn pt-3 text-center">Loading...</td></tr>
      )
    }
  }*/

  function showDmodal() {
    return (
      <Modal show={dmodal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {currentCourse ? currentCourse.name : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete Course {currentCourse ? currentCourse.name : ""}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="primary" onClick={deleteCourse}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function displayCourseCards() {
    var isLoaded = courseLoader
    if (isLoaded) {
      if (courses.length > 0) {
        return (
          <React.Fragment>
            <Col lg="12" md="12" sm="12" xs="12" className={`d-flex flex-wrap p-0`}>
              {courses.map((datum, index) => (
                <Col lg="4" md="6" sm="12" xs="12" key={index} className={`pr-3 py-3 pl-0 text-white`}>
                  <Card className="" style={{ backgroundColor: `#4A5A9B`, height: `100%`, borderRadius: `15px` }}>
                    <Card.Header className={`text-left text-white border-0 bg-transparent p-0`}>
                      <div className="d-flex pt-2">
                        <div className={`pl-3 align-self-center`}>
                          <LazyLoadImage
                            src={`https://dtkmdj8r1pumb.cloudfront.net/courses/csm.png`}
                            alt={datum.name}
                            effect="blur"
                            height={"45px"}
                            width={"45px"}
                            style={{ margin: "0px", objectFit: `cover` }}
                          />
                          <p className={`m-0 font-weight-bold text-white ${Styles.font_16}`}>{datum.name}</p>
                          <p className={`m-0 ${Styles.font_12} font-italic`} style={{ color: `#FFB52B` }}>
                            Ongoing
                          </p>
                        </div>
                        <div className={`pl-3 ml-auto mr-2 align-self-end text-center`}>
                          <p className={`mt-2 mb-0`}>
                            <span className={`mb-0 font-weight-bold text-dark bg-white ${Styles.font_14} p-2`} style={{ borderRadius: `6px` }}>
                              12
                            </span>
                          </p>
                          <p className={`mb-0 mt-2 text-white ${Styles.font_12}`}>Enrolled</p>
                          <Button
                            variant="none"
                            className={`bg-transparent shadow-none text-white`}
                            onClick={(e) => {
                              setDmodal(true)
                              setCurrentCourse(datum)
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                          </Button>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body className={`pt-0 px-0`}>
                      {datum.course_template && datum.course_template.stages
                        ? datum.course_template.stages.map((stage, index) => (
                            <div className={`d-flex mb-2`} key={index + `_stages`} style={{ backgroundColor: `#5969AA` }}>
                              <div className={`${Styles.font_12} p-2`}>
                                <span className={`font-weight-bold ${Styles.font_14}`}>{stage.name}</span>
                                <br />
                                <span> 8 Modules 5 Topics</span>
                                <br />
                                <p className={`mb-0`} style={{ marginTop: `6px` }}>
                                  {" "}
                                  12 Videos 5 Blogs 3 Quizzes 2 Assignments
                                </p>
                              </div>
                              <div className={`ml-auto mr-2 align-self-center`}>
                                <FontAwesomeIcon icon={faChevronRight} size="sm" />
                              </div>
                            </div>
                          ))
                        : ""}
                      <div className={`d-flex mb-2`} style={{ backgroundColor: `#5969AA` }}>
                        <div className={`${Styles.font_14} p-2 font-weight-bold`}>13 Comments</div>
                        <div className={`ml-auto mr-2 align-self-center`}>
                          <FontAwesomeIcon icon={faChevronRight} size="sm" />
                        </div>
                      </div>
                      <div className={`d-flex mb-2`} style={{ backgroundColor: `#5969AA` }}>
                        <div className={`${Styles.font_14} p-2 font-weight-bold`} style={{ position: `relative` }}>
                          2 Pending Tasks{" "}
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{ fontSize: `9px`, color: `rgb(228, 86, 97)`, position: `absolute`, top: `10px` }}
                            size="sm"
                          />
                        </div>
                        <div className={`ml-auto mr-2 align-self-center`}>
                          <FontAwesomeIcon icon={faChevronRight} size="sm" />
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer className="text-muted">
                      <div className={`d-flex`}>
                        <Link className={`${Styles.font_12} text-white`} to={`/app/companies/${location.id}/courses/edit/` + datum.id}>
                          {" "}
                          Edit Course &nbsp;&nbsp;
                          <FontAwesomeIcon icon={faEdit} size="lg" />
                        </Link>
                        <Link className={`${Styles.font_12} ml-auto text-white`} to={`/app/companies/${location.id}/courses/view/` + datum.id}>
                          <FontAwesomeIcon icon={faPlus} size="lg" />
                          &nbsp;&nbsp; Add Trainings
                        </Link>
                      </div>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Col>
            <div className="d-flex my-3 justify-content-center mx-auto">
              <Button
                variant="none"
                className={`${Styles.font_12} text-center px-3 py-2 my-0`}
                style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
              >
                Show More{` `}
                <FontAwesomeIcon icon={faChevronRight} size="sm" />
              </Button>
            </div>
          </React.Fragment>
        )
      } else {
        return <p className={`px-4`}>No Courses Found</p>
      }
    } else {
      return <p className="animated fadeIn pt-3 text-center">Loading...</p>
    }
  }

  function showCompanies() {
    if (authClient.isAuthenticated()) {
      return displayCourseCards()
    } else {
      return "please login"
    }
  }

  return (
    <div>
      <SEO title="Courses of a Company" />
      <Col lg="12" md="12" sm="12" xs="12" className={`py-4 px-4`}>
        {/*<h4>View Company</h4>*/}
        {showDmodal()}
        {/*<Col xs="12" sm="12" md="12" lg="12" className={`p-0`} >
            <Col xs="12" sm="6" md="12">
              <p>Name: {company.name}</p>
            </Col>
            <Col xs="12" sm="6" md="12">
              <p> Subdomain: {company.subdomain}</p>
            </Col>
            <Col xs="12" sm="6" md="12">
              <p> sequence:  {company.sequence}</p>
            </Col>
          </Col>*/}
        <Col xs="12" sm="12" md="12" lg="12" className={`px-5 py-5 mb-3`} style={{ backgroundColor: `#28314E` }}>
          <div className={`px-5`}>
            <Link style={{ color: `#c6cff4` }} className={`align-self-center`} to={`/app/companies/${location.id}/courses/create`}>
              <div className={`${Styles.font_16} text-center px-3 py-2 my-0 d-inline-block`} style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4` }}>
                <FontAwesomeIcon icon={faPlus} size="lg" />
                {` `}&nbsp;&nbsp;Create a new Course
              </div>
            </Link>
            <p className={`${Styles.font_14} mt-3`}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet{" "}
            </p>
          </div>
        </Col>
        <div className={`d-inline-flex w-100 p-0`}>
          <p className={`my-0 font-weight-bold`} style={{ fontSize: `1.5rem` }}>
            My Courses
          </p>
          <div className={`align-self-center ml-3`}>
            <Button variant="primary" size="sm" className={`${Styles.active_filter} ${Styles.font_12} mr-2 py-1 px-3 shadow-none`}>
              {" "}
              All{" "}
            </Button>
            <Button variant="primary" size="sm" className={`${Styles.inactive_filter} ${Styles.font_12} mr-2 py-1 px-3 shadow-none`}>
              {" "}
              Ongoing{" "}
            </Button>
            <Button variant="primary" size="sm" className={`${Styles.inactive_filter} ${Styles.font_12} mr-2 py-1 px-3 shadow-none`}>
              {" "}
              Published{" "}
            </Button>
          </div>
        </div>
        {showCompanies()}
        <Link className={"ml-4"} to={"/app/dashboard/"}>
          <Button variant="danger">Go Back</Button>
        </Link>
      </Col>
    </div>
  )
}

export default ViewCompany
