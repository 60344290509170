import React, { useEffect, useState, useCallback, useRef } from "react"
import _ from "lodash"
import PropTypes from "prop-types"

import Col from "react-bootstrap/Col"
import FormControl from "react-bootstrap/FormControl"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Dropdown from "react-bootstrap/Dropdown"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import SunEditor from "suneditor-react"

import Select from "react-dropdown-select"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { LazyLoadImage } from "react-lazy-load-image-component"

import UploadFiles from "../../courses/upload-files2"
import Blog from "../../blogs/create-edit"
import Btn from "../../custom/Button"
import AvatarCircle from "../../custom/AvatarCircle"
import { isBrowser } from "../../../services/developer_mode"
import { getRequest, postRequest, putRequest, deleteRequest } from "../../../services/rest_service"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleUp,
  faChevronUp,
  faImage,
  faInfoCircle,
  faCommentAlt,
  faChevronCircleRight,
  faAngleDown,
  faExclamationTriangle,
  faEllipsisV,
  faSpinner,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons"

import editSvg from "../../../images/svgs/edit.svg"
import cricleDownArrorw from "../../../images/svgs/arrow-dropdown-white-circle.svg"
import cricleUpArrorw from "../../../images/svgs/arrow-dropup-white-circle.svg"
import video from "../../../images/svgs/video.svg"
import link from "../../../images/svgs/link.svg"
import turientimage from "../../../images/svgs/turientimage.svg"
import blog from "../../../images/svgs/blog.svg"
import quiz from "../../../images/svgs/quiz.svg"
import systemgroup from "../../../images/svgs/systemgroup.svg"
import manualgroup from "../../../images/svgs/manualgroup.svg"
import CancelWhite from "../../../images/svgs/close.svg"
import dropRightArrow from "../../../images/svgs/dropRightArrow.svg"
import Advance from "../../../images/svgs/advance.svg"
import CompareArrow from "../../../images/svgs/compare-arrows.svg"
import DeleteIcon from "../../../images/svgs/delete.svg"
import CheckIcon from "../../../images/svgs/check-white.svg"
import WhiteBlogIcon from "../../../images/svgs/blog-white-icon.svg"
import documents from "../../../images/svgs/documentresWhite.svg"
import WhiteQuizIcon from "../../../images/svgs/quiz-white-icon.svg"
import WhitePollIcon from "../../../images/svgs/polls-white-icon.svg"
import muralIcon from "../../../images/svgs/mural-icon.svg"
import ideaIcon from "../../../images/svgs/idea.svg"
import doc from "../../../images/svgs/doc.svg"
import excel from "../../../images/svgs/excel.svg"
import ppt from "../../../images/svgs/ppt.svg"
import pdf from "../../../images/svgs/pdf.svg"
import poll from "../../../images/svgs/poll.svg"
import popQuiz from "../../../images/svgs/popquiz.svg"
import checklist from "../../../images/svgs/checklist.svg"
import casestudy from "../../../images/svgs/casestudy.svg"

import { muralGetRequest } from "../../mural/mural-api-service"
import MuralAuthWarning from "../../mural/mural-warning-popup"
import config from "../../mural/config"
import validateMuralUrl from "../../mural/validateMuralUrl"

import Styles from "../../../modularscss/styles.module.scss"
import StylesA from "../classroom-resource-section.module.scss"
import StylesB from "../../courses/course.module.scss"
import Style from "./breakoutGroups.module.scss"
import Loader from "../../custom/Loader"
import { getItemStyle, getMoveModalItemStyle, getListStyle, getGroupListStyle, getMoveModalListStyle, getResourceItemStyle } from "./dragAndDropStyle"
import Notification from "../../custom/Notification"
import muralWidgestData from "../../mural/muralWidgestFetching"
import { setZoomBrGroupSync, syncZoomBrGroup } from "../../zoom/utils"
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const GroupNameInputField = (props) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const [inputValue, setInputValue] = useState(props.datum.title)

  useEffect(() => {
    if (inputValue !== props.datum.title) {
      props.handleDurationSave(props.datum.id, inputValue, "title")
      setZoomBrGroupSync()
    }
  }, [inputValue])

  return (
    <div className="col-11 px-0 ml-0 position-relative">
      {showDropDown ? (
        <input
          className={`groups-input p-0 ml-0 col-12 ${Style.workspace_modal_input}`}
          type="text"
          placeholder="Name of the group"
          onBlur={() => setShowDropDown(!showDropDown)}
          onChange={(e) => {
            e.preventDefault()
            setInputValue(e.target.value)
          }}
          defaultValue={`${inputValue}`}
          autoFocus
        />
      ) : (
        <div
          onClick={() => {
            setShowDropDown(!showDropDown)
          }}
          className={`groups-input p-0 ml-0 col-12 ${Style.group_name_input_text}`}
        >
          {inputValue} ({props.datum && props.datum.training_track ? props.datum.training_track.length : ""})
        </div>
      )}
    </div>
  )
}

/**
 * Component for creating breakout groups.
 * @param props -->location,bgModal,bgModalClick,setLeftNavOpen,setCurrentNavTab,leftNavOpen,allResources,openNavWithResourceSection
 * ,currentSelectedResourcesType,resetDragState,dragEnded,draggedItem,activeSection,setActiveSection,setDisableScroll
 * @returns {React.ReactElement} BreakoutGroups component.
 */

const BreakoutGroups = (props) => {
  const [groupsModalVisible, setAddGroupsModalVisible] = useState(false)
  const [visible_add_students_modal, setVisibleAddStudents] = useState(false)

  const [startModalVisible, setStartModalVisible] = useState(false)
  const [groupCreationCount, setGroupCreationCount] = useState(0)
  const [breakoutGroupList, setBreakoutGroupList] = useState([])

  const [groupCreationName, setGroupCreationName] = useState("")
  const [allParticipants, setAllParticipants] = useState([])
  const [activeStudents, setActiveStudents] = useState([])
  const [addGroup, setAddGroup] = useState([])
  const [moveModal, setMoveModal] = useState(false)
  const [moveTrainingTrack, setMoveTrainingTrack] = useState(null)
  const [currentGroup, setCurrentGroup] = useState(-1)
  const [moveGroup, setMoveGroup] = useState([])
  const [moveGroupId, setMoveGroupId] = useState(null)
  const [filters, setFilters] = useState([])

  const [country, setCountry] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [profession, setProfession] = useState("")
  const [age, setAge] = useState("")
  const [experience, setExperience] = useState("")

  const [groupPresent, setGroupPresent] = useState(undefined)
  const [similar, setSimilar] = useState(false)

  const [systemAge, setSystemAge] = useState(false)
  const [systemCountry, setSystemCountry] = useState(false)
  const [systemScore, setSystemScore] = useState(false)
  const [systemCity, setSystemCity] = useState(false)
  const [systemExperience, setSystemExperience] = useState(false)
  const [rerender, setRerender] = useState(false)
  const [allFilterParticipants, setAllFilterParticipants] = useState([])

  const [allParticipantsRender, setAllParticipantsRender] = useState(true)
  const [expToggle, setExpToggle] = useState(false)
  const [proToggle, setProToggle] = useState(false)
  const [ageToggle, setAgeToggle] = useState(false)
  const [countryToggle, setCountryToggle] = useState(false)
  const [cityToggle, setCityToggle] = useState(false)

  const [autoAssign, setAutoAssign] = useState(false)

  const [highlight, setHighlight] = useState("unhighlight")
  const [files, setFiles] = useState([])
  const [allResources, setAllResources] = useState({})

  const [allResourcesChanged, setAllResourcesChanged] = useState(false)
  const [showCommonItemsModal, setShowCommonItemsModal] = useState(false)
  const [openInstructions, setOpenInstructions] = useState(false)
  const [uploadInTopic, setUploadInTopic] = useState(null)
  const [uploadInModule, setUploadInModule] = useState(null)
  const [uploadDataSequence, setUploadDataSequence] = useState(null)

  const [addBlog, setAddBlog] = useState(false)
  const [showSection, setShowSection] = useState("video")
  const quizWin = useRef(null)
  const checklistWin = useRef(null)
  const casestudyWin = useRef(null)
  const [attrForResources, setAttrForResources] = useState(null)
  const [addResourceType, setAddResourceType] = useState("")

  const [breakoutGroupsResourcesFetched, setBreakoutGroupsResourcesFetched] = useState(false)
  const [breakoutGroupResources, setBreakoutGroupResources] = useState([])
  const [breakoutGroupInstructionsFetched, setBreakoutGroupInstructionsFetched] = useState(false)
  const [breakoutGroupInstructions, setBreakoutGroupInstructions] = useState([])
  const [instructionsLoader, setInstructionsLoader] = useState({})

  const [showCreateMuralLinkModal, setShowCreateMuralLinkModal] = useState(false)
  const [activeGroupData, setActiveGroupData] = useState({})
  const [workspace_validation_error, set_workspace_validation_error] = useState(null)
  const [workspaceLinkChanged, setWorkspaceLinkChanged] = useState(false)
  const [showMuralAuthWarning, setShowMuralAuthWarning] = useState(false)
  const [muralWorkspace, setMuralWorkspace] = useState({})

  const [notificationData, setNotificationData] = useState(null)

  const sendQuery = (value, template, datum, index, type, group_id, instructions) => {
    updateCommomResourceInstruction(value, template, datum, index, type, group_id, instructions)
  }

  const delayedQuery = useCallback(
    _.debounce((value, template, datum, index, type, group_id, instructions) => sendQuery(value, template, datum, index, type, group_id, instructions), 1000),
    []
  )
  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("message", handleNewWinMessages, false)
      window.addEventListener("close-poll-modal", handlePollModalMsgs, false)
      window.addEventListener("close-pop-quiz-modal", handlePopQuizModalMsgs, false)

      return () => {
        window.removeEventListener("message", handleNewWinMessages)
        window.removeEventListener("close-poll-modal", handlePollModalMsgs)
        window.removeEventListener("close-pop-quiz-modal", handlePopQuizModalMsgs)
      }
    }
  }, [window])

  useEffect(() => {
    if (showCommonItemsModal) {
      setAddResourceType("common")
    } else {
      setAddResourceType("")
    }
  }, [showCommonItemsModal])

  useEffect(() => {
    if (isBrowser()) {
      if (props.showBgModal === true) {
        fetchCurrentMuralWorkspace()
        fetchBreakoutGroupParticipants()
        fetchTrainingParticipants()
        fetchBreakoutGroupFilters()
        if (!breakoutGroupsResourcesFetched) {
          fetchBreakoutGroupsResources()
        }
      }
    }
  }, [isBrowser(), props.bgModalClick])

  useEffect(() => {
    if (openInstructions && !breakoutGroupInstructionsFetched) {
      fetchBreakoutGroupsInstructions()
    }
  }, [openInstructions])

  useEffect(() => {
    if (isBrowser()) {
      fetchTrainingFilterParticipants()
    }
  }, [isBrowser(), age, country, city, state, profession, experience])

  useEffect(() => {
    if (isBrowser() && allParticipantsRender == false) {
      setAllParticipantsRender(true)
    }
  }, [isBrowser(), allParticipantsRender])

  useEffect(() => {
    if (rerender) {
      setRerender(false)
    }
  }, [rerender])

  useEffect(() => {
    if (props.draggedItem && props.dragEnded) {
      onDragEnd(props.draggedItem)
    }
  }, [props.draggedItem, props.dragEnded])

  useEffect(() => {
    syncZoomBrGroup(props.location.training_id)
  }, [])

  const createCommomResourceInstruction = async () => {
    let formData = new FormData()
    if (props.location.template_id) {
      formData.append("template", props.location.template_id)
      formData.append("instruction", "")
      if (breakoutGroupInstructions.length > 0) {
        let value = (parseFloat(breakoutGroupInstructions[breakoutGroupInstructions.length - 1].sequence) + 10).toFixed(20)
        formData.append("sequence", value)
      }
      try {
        let response = await postRequest(`/common-group-resource/`, formData)
        if (response.success) {
          let dummy = [...breakoutGroupInstructions]
          dummy.push(response.data)
          setBreakoutGroupInstructions(dummy)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  const updateCommomResourceInstruction = async (value, template, datum, index, type = null, group_id = null, instructions = null) => {
    let formData = new FormData()
    formData.append("template", template ? template : props.location.template_id)
    if (type === "instruction") {
      formData.append("instruction", value)
    }
    if (type === "breakout_groups" || type === "breakout_groups_instruction") {
      let dummy1 = { ...datum }
      let dummy = []
      if (dummy1.breakout_groups) {
        dummy = [...dummy1.breakout_groups]
      } else {
        dummy1["breakout_groups"] = []
        dummy = [...dummy1.breakout_groups]
      }
      let fil_arr = dummy.filter((res) => res.id == group_id)
      if (fil_arr.length === 0) {
        let res = {}
        res["id"] = group_id
        let ind = breakoutGroupList.findIndex((res) => res.id == group_id)
        res["title"] = breakoutGroupList[ind].title
        dummy.push(res)
        datum["breakout_groups"] = dummy
      } else {
        let res_arr = []
        for (let i = 0; i < dummy.length; i++) {
          if (dummy[i].id != group_id) {
            res_arr.push(dummy[i])
          }
        }
        datum["breakout_groups"] = res_arr
      }
    }

    for (let k in datum) {
      if (datum[k] !== null) {
        if (k === "start_date" || k === "end_date") {
          if (datum[k]) {
            formData.append(k, new Date(datum[k]).toISOString())
          }
        } else {
          if (k === "breakout_groups") {
            let dummy1 = { ...datum }
            let dummy = [...dummy1.breakout_groups]
            let res_arr = []
            for (let i = 0; i < dummy.length; i++) {
              res_arr.push(dummy[i].id)
            }
            formData.append("breakout_groups", JSON.stringify(res_arr))
          } else {
          }
        }
      }
    }
    try {
      let response = await putRequest(`/common-group-resource/${datum.id}/`, formData)
      if (response.success) {
        if (type === "instruction") {
          let dummy = { ...instructionsLoader }
          let text = `instruction_` + datum.id
          dummy[text] = false
          setInstructionsLoader(dummy)
        }

        if (type === "instruction" || type === "breakout_groups_instruction") {
          if (instructions) {
            let dummy = [...instructions]
            dummy[index] = response.data
            setBreakoutGroupInstructions(dummy)
          } else {
            let dummy = [...breakoutGroupInstructions]
            dummy[index] = response.data
            setBreakoutGroupInstructions(dummy)
          }
        } else if (type === "breakout_groups") {
          let dummy = [...breakoutGroupResources]
          dummy[index] = response.data
          setBreakoutGroupResources(dummy)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBreakoutGroupsResources = async (template = "") => {
    try {
      if (template === "") template = props.location.template_id
      let response = await getRequest(`/common-group-resource/?type=resource&template=${template}`)
      if (response.success) {
        setBreakoutGroupResources(response.data)
        setBreakoutGroupsResourcesFetched(true)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBreakoutGroupsInstructions = async () => {
    try {
      let response = await getRequest(`/common-group-resource/?type=instruction&template=${props.location.template_id}`)
      if (response.success) {
        setBreakoutGroupInstructions(response.data)
        setBreakoutGroupInstructionsFetched(true)
        if (response.data.length === 0) {
          createCommomResourceInstruction()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteBreakoutGroupsResources = async (resource_id, index, type) => {
    try {
      let response = await deleteRequest(`/common-group-resource/${resource_id}/`)
      if (response.success) {
        if (type == "resource") {
          let arr = breakoutGroupResources.filter((item) => item.id !== resource_id)
          setBreakoutGroupResources(arr)
        } else {
          let arr = breakoutGroupInstructions.filter((item) => item.id !== resource_id)
          setBreakoutGroupInstructions(arr)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handlemoveModal = () => {
    setMoveModal(false)
    setCurrentGroup(-1)
    setMoveGroup([])
    if (props.setActiveSection) props.setActiveSection("LessonBoard")
    if (props.setDisableScroll) props.setDisableScroll(true)
  }

  const handleWorkspaceModalClose = () => {
    setShowCreateMuralLinkModal(false)
    setActiveGroupData({})
    set_workspace_validation_error(null)
    if (workspaceLinkChanged) {
      triggerWorkspaceChanges()
      setWorkspaceLinkChanged(false)
    }
  }

  const fetchCurrentMuralWorkspace = async () => {
    // setCompanyLoader(false)
    try {
      let res = await getRequest(`/default-course-templates/fetch_or_create/${props.location.training_id}/?draft_content=false&training=true`)
      if (res.success) {
        setMuralWorkspace(res.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getMuralData = async (muralId, group_id) => {
    let widgestData = {}
    if (muralId) {
      try {
        let data = await muralWidgestData(muralId, [], "", null)
        if (data?.length > 0) {
          data.forEach((element) => {
            if (element.title && element?.presentationIndex > -1) {
              widgestData[element.title] = element.id
            }
          })
        } else {
          setShowMuralAuthWarning(true)
        }
      } catch (error) {
        await getMuralData(muralId, group_id)
      }
    }
    let response = await putRequest(`/breakoutgroups/${group_id}/`, { workspace_widgets: widgestData })
  }

  const saveTitleOnEdit = async (group_id, value, field) => {
    let formData = new FormData()
    formData.append("training", props.location.training_id)
    formData.append(field, value)
    try {
      let response = await putRequest(`/breakoutgroups/${group_id}/`, formData)
      if (response.success) {
        let group_data = breakoutGroupList
        let data_results = breakoutGroupList
        let index = data_results.findIndex((g_id) => g_id.id == response.data.id)
        data_results[index] = response.data
        group_data = data_results
        setBreakoutGroupList(group_data)
        setRerender(true)
        set_workspace_validation_error(null)
        setGroupCreationName("")
        if (field === "workspace_link") {
          if (value && validateMuralUrl(value)) {
            let muralId = null
            muralId = value.split("/").length > 6 ? `${value.split("/")[6]}.${value.split("/")[7]}` : null
            await getMuralData(muralId, group_id)
          } else {
            await getMuralData(null, group_id)
          }
          setActiveGroupData(response.data)
          setWorkspaceLinkChanged(true)
        }
      }
    } catch (error) {
      if (error?.response?.data?.["workspace_link"]) {
        set_workspace_validation_error(error.response.data["workspace_link"])
      }
      console.log(error)
    }
  }

  const checkMuralValidUser = () => {
    setShowCreateMuralLinkModal(true)

    if (!localStorage.getItem(config.accessTokenKey)) {
      setShowMuralAuthWarning(true)
    }
  }

  const showAllGroups = (data_text) => {
    return breakoutGroupList.map((data, index) => {
      let selectedBox = 4
      if (window.innerWidth && window.innerWidth < 1070) {
        selectedBox = 3
      }
      return (
        <div
          id={data.id}
          key={data.id}
          className={`float-left mt-3 ${Style.show_all_groups_container} ${index + 1 === breakoutGroupList.length ? "mx-3" : "ml-3"} `}
        >
          <div className={`col px-2 ${Style.group_card}`}>
            <div className={`d-flex col-12 px-0 justify-content-between`}>
              <GroupNameInputField datum={data} handleDurationSave={saveTitleOnEdit} />
              <Dropdown className="breakout-groups move-dropdown">
                <Dropdown.Toggle className="border-none px-0 pt-0" style={{ backgroundColor: "transparent" }} id="dropdown-basic">
                  <div>
                    <FontAwesomeIcon icon={faEllipsisV} className={`${Style.dot_icon}`} />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="last_drop_item">
                  <Dropdown.Item
                    onClick={(e) => {
                      e.preventDefault()
                      setMoveModal(true)
                      setCurrentGroup(data.id)
                    }}
                    className="text-white fs-14px px-2"
                  >
                    <img src={CompareArrow} alt={`group-delete`} className={`my-auto mr-2 pointer ${Style.move_students}`} />
                    Move Students
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      e.preventDefault()
                      deleteBreakoutGroup(data.id)
                      setZoomBrGroupSync()
                    }}
                    className="last_drop_item text-white fs-14px px-2"
                  >
                    <img src={DeleteIcon} alt={`group-delete`} className={`my-auto ml-1 pointer ${Style.delete_group}`} />
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <Droppable droppableId={`_group_${data.id}`} type={`paxDraggable`}>
            {(provided, snapshot) => (
              <div
                className={`px-2 ${allParticipants.length > 0 ? "" : StylesA.height_325}`}
                ref={provided.innerRef}
                style={getGroupListStyle(snapshot.isDraggingOver)}
              >
                {data.workspace_link !== "" && data.workspace_link !== null ? (
                  <div className={`d-flex align-items-center w-100 p-1 mt-1 ${Style.mural_link_card}`}>
                    <div className={`flex-shrink-0 mr-1`}>
                      {data?.workspace_link?.includes("app.mural") ? (
                        <LazyLoadImage className={`my-auto pointer ${Style.mural_icon}`} src={muralIcon} alt={`mural-icon`} />
                      ) : (
                        <img src={link} height={20} />
                      )}
                    </div>
                    <div className={`mr-1 ${Style.mural_link}`}>{data?.workspace_link}</div>
                    <div className={`ml-auto`}>
                      <Btn
                        onClick={(e) => {
                          e.preventDefault()
                          setActiveGroupData(data)
                          checkMuralValidUser()
                        }}
                        type={`round`}
                        className={`${Style.mural_link_edit_button}`}
                      >
                        <img alt="edit" src={editSvg} className={`mb-0 mx-auto ${Style.edit_icon}`} />
                      </Btn>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`d-inline-flex align-items-center w-100 my-2`}
                    onClick={(e) => {
                      e.preventDefault()
                      setShowCreateMuralLinkModal(true)
                      setActiveGroupData(data)
                    }}
                    style={{ color: "#E0E2E5" }}
                  >
                    <img src={link} height={20} />
                    <p className={`mb-0 ml-2 underline pointer ${Style.add_team_workspace}`}>Add Team workspace</p>
                  </div>
                )}

                {data && data.training_track && data.training_track.length > 0 ? (
                  data.training_track.map((d, i) => {
                    return (
                      <Draggable key={d.id} draggableId={d.id + "draggable_element_xyz"} index={i}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`bg-transparent float-none`}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <div className={`px-2 py-1 mt-2 d-flex ${Style.student_name_container}`}>
                              <AvatarCircle name={d?.owner?.first_name} size={`30px`} />
                              <div className={`text-left ml-2 ${Style.student_name}`}>{d.owner.first_name}</div>
                              <FontAwesomeIcon color="#E0E2E5" className="rotate-90" icon={faChevronCircleUp} style={{ width: "16px", height: "16px" }} />
                              <Dropdown className="breakout-groups move-dropdown">
                                <Dropdown.Toggle className="border-none p-0" style={{ backgroundColor: "transparent" }} id="dropdown-basic">
                                  <div>
                                    <FontAwesomeIcon className={`${Style.student_name_dot_icon}`} icon={faEllipsisV} />
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="mt-1">
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      e.preventDefault()
                                      setMoveModal(true)
                                      setMoveTrainingTrack(d.id)
                                      setCurrentGroup(data.id)
                                    }}
                                    className="text-white fs-14px px-2"
                                  >
                                    <img src={CompareArrow} alt={`group-delete`} className={`my-auto mr-2 pointer ${Style.move_students}`} />
                                    Move Students
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      deleteParticipant(e, d.id, data.id)
                                      setZoomBrGroupSync()
                                    }}
                                    className="last_drop_item text-white fs-14px px-2"
                                  >
                                    <img src={DeleteIcon} alt={`student-delete`} className={`my-auto ml-1 pointer ${Style.delete_student}`} />
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  })
                ) : (
                  <p className={`mb-0 text-center ${Style.add_student_to_group}`}>Please add students to group</p>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div
            className={`float-left d-flex align-items-center justify-content-center ${Style.add_student_container}`}
            style={{ height: `${allParticipants.length > 0 ? "60px" : ""}` }}
          >
            <Btn
              onClick={() => {
                setGroupPresent(data.id)
                setVisibleAddStudents(true)
                fetchTrainingFilterParticipants()
              }}
              className={`border_on_hover ${allParticipants.length > 0 ? "" : "d-none"}`}
            >
              Add Students
            </Btn>
          </div>
        </div>
      )
    })
  }

  const startModal = () => {
    return (
      <div className="d-flex flex-column p-2" style={{ color: "#E0E2E5" }}>
        <div className="d-flex mx-5 my-3">
          <div className="d-flex flex-column">
            <div className="bold-600" style={{ fontSize: "21px" }}>
              Our advanced system algorithm creates groups for you
            </div>
            <div style={{ fontSize: "16px" }}>
              If you want to save time & effort, let the system automatically create breakout groups for your live classes based on criteria you select. Just
              select the criteria and click on the ‘Start Now’ option and voila! Your breakout groups are ready.
            </div>
          </div>
          <div style={{ flex: "none" }}>
            <img src={Advance} />
          </div>
        </div>
        <div className="px-3">
          <div className="d-flex breakout-input-group inner-element-box-shadow mb-2 px-2 py-2 align-items-center">
            {breakoutGroupList.length > 0 ? (
              <div>
                <input placeholder="Select no of groups" className="breakout-input" disabled />
                <div className="pl-2" style={{ fontSize: "15px" }}>{`${breakoutGroupList.length} groups`}</div>
              </div>
            ) : (
              <input
                placeholder="Select no of groups"
                className="breakout-input"
                onChange={(e) => {
                  e.preventDefault()
                  setGroupCreationCount(e.target.value)
                }}
              />
            )}
          </div>
          <div className="d-flex flex-column breakout-input-group inner-element-box-shadow mb-2 px-2 pt-2 pb-3">
            <input placeholder="Select grouping criteria" className="breakout-input" disabled />
            <div className="mt-1 pl-2">
              <Btn
                id={`systemAge`}
                onClick={(e) => {
                  e.preventDefault()
                  setSystemAge(!systemAge)
                }}
                className={`mr-2 text-center ${StylesA.breakout_group_border_on_hover} ${Style.filter_button}`}
                style={{
                  border: `${systemAge ? "1px solid #E0E2E5" : "1px solid transparent"}`,
                }}
              >
                Age
              </Btn>
              <Btn
                id={`systemCountry`}
                onClick={(e) => {
                  e.preventDefault()
                  setSystemCountry(!systemCountry)
                }}
                className={`mr-2 text-center ${StylesA.breakout_group_border_on_hover} ${Style.filter_button}`}
                style={{
                  border: `${systemCountry ? "1px solid #E0E2E5" : "1px solid transparent"}`,
                }}
              >
                Country
              </Btn>
              <Btn
                id={`systemScore`}
                onClick={(e) => {
                  e.preventDefault()
                  setSystemScore(!systemScore)
                }}
                className={`mr-2 text-center ${StylesA.breakout_group_border_on_hover} ${Style.filter_button}`}
                style={{
                  border: `${systemScore ? "1px solid #E0E2E5" : "1px solid transparent"}`,
                }}
              >
                Profession
              </Btn>
              <Btn
                id={`systemCity`}
                onClick={(e) => {
                  e.preventDefault()
                  setSystemCity(!systemCity)
                }}
                className={`mr-2 text-center ${StylesA.breakout_group_border_on_hover} ${Style.filter_button}`}
                style={{
                  border: `${systemCity ? "1px solid #E0E2E5" : "1px solid transparent"}`,
                }}
              >
                City
              </Btn>
              <Btn
                id={`systemExperience`}
                onClick={(e) => {
                  e.preventDefault()
                  setSystemExperience(!systemExperience)
                }}
                className={`mr-2 text-center ${StylesA.breakout_group_border_on_hover} ${Style.filter_button}`}
                style={{
                  border: `${systemExperience ? "1px solid #E0E2E5" : "1px solid transparent"}`,
                }}
              >
                Experience
              </Btn>
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className={`d-flex p-2 mr-2 inner-element-box-shadow pointer border_on_hover ${Style.group_by_similarities_container}`}>
              <div
                onClick={() => {
                  setSimilar(true)
                }}
                className={`text-center d-flex align-items-center justify-content-center ${Style.checkbox_container}`}
              >
                {similar ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : null}
              </div>
              <div className={`ml-2 align-self-center ${Style.group_by_similarities_text}`}>Group by similarities</div>
              <div className="ml-2 align-self-center fs-12px">
                <FontAwesomeIcon icon={faInfoCircle} />
              </div>
            </div>
            <div className={`d-flex p-2 mr-2 inner-element-box-shadow pointer border_on_hover ${Style.group_by_similarities_container}`}>
              <div
                onClick={() => {
                  setSimilar(false)
                }}
                className={`text-center d-flex align-items-center justify-content-center ${Style.checkbox_container}`}
              >
                {similar === false ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : null}
              </div>
              <div className={`ml-2 align-self-center ${Style.group_by_similarities_text}`}>Distribute across criteria</div>
              <div className="ml-2 align-self-center fs-12px">
                <FontAwesomeIcon icon={faInfoCircle} />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center py-3">
          <Btn
            onClick={(e) => {
              handleListClose()
              createGroupSystem(e)
              if (breakoutGroupList.length > 0) {
                setAutoAssign(false)
              }
            }}
            className={`fs-16px px-4 py-1 ${StylesA.breakout_group_border_on_hover}`}
          >
            {breakoutGroupList.length > 0 ? "Auto Assign" : "Create Group"}
          </Btn>
        </div>
      </div>
    )
  }

  const changePartcipantGroup = async (e, current_group = null, destination_group = null, training_track_id = null) => {
    setZoomBrGroupSync()
    if (e !== null) {
      e.preventDefault()
    }
    const cg_id = current_group === null ? currentGroup : current_group
    const mg_id = destination_group === null ? moveGroupId : destination_group
    const track_id = training_track_id === null ? moveTrainingTrack : training_track_id
    let array = []
    array.push(track_id)
    let formData = new FormData()
    formData.append("training", props.location.training_id)
    formData.append("training_track", JSON.stringify(array))
    formData.append("move_to_training_track", mg_id)
    try {
      let response = await putRequest(`/breakoutgroups/${cg_id}/`, formData)
      if (response.success) {
        let group_data = breakoutGroupList
        let data_results = group_data
        let index = data_results.findIndex((g_id) => g_id.id == response.data.id)
        data_results[index] = response.data
        group_data = data_results
        setBreakoutGroupList(group_data)
        setRerender(true)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const deleteParticipant = async (e, training_track_id, group_id) => {
    setZoomBrGroupSync()
    if (e !== null) {
      e.preventDefault()
    }
    let array = []
    array.push(training_track_id)
    let formData = new FormData()
    formData.append("remove_training_track", JSON.stringify(array))
    try {
      let response = await putRequest(`/breakoutgroups/${group_id}/`, formData)
      if (response.success) {
        let group_data = breakoutGroupList
        let data_results = group_data
        let index = data_results.findIndex((g_id) => g_id.id == response.data.id)
        data_results[index] = response.data
        group_data = data_results
        setBreakoutGroupList(group_data)
        setRerender(true)
        fetchTrainingParticipants()
      }
    } catch (error) {
      console.log(error)
    }
  }
  const addParticipant = async (e, training_track_id, group_id, array_data = null) => {
    setZoomBrGroupSync()
    if (e !== null) {
      e.preventDefault()
    }
    let array = []
    if (array_data == null) {
      array.push(training_track_id)
    } else {
      array = training_track_id
    }
    let formData = new FormData()
    formData.append("add_training_track", JSON.stringify(array))
    try {
      let response = await putRequest(`/breakoutgroups/${group_id}/`, formData)
      if (response.success) {
        if (array_data == null) {
          let group_data = breakoutGroupList
          let data_results = group_data
          let index = data_results.findIndex((g_id) => g_id.id == response.data.id)
          data_results[index] = response.data
          group_data = data_results
          setBreakoutGroupList(group_data)
          setRerender(true)
        } else {
          let group_data = breakoutGroupList
          let data_results = group_data
          let index = data_results.findIndex((g_id) => g_id.id == response.data.id)
          data_results[index] = response.data
          group_data = data_results
          setBreakoutGroupList(group_data)
          setRerender(true)
          setVisibleAddStudents(false)
          setActiveStudents([])
          setGroupCreationName("")
        }
        fetchTrainingParticipants()
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBreakoutGroupParticipants = async () => {
    try {
      let response = await getRequest(`/breakoutgroups/?training=${props.location.training_id}`)
      if (response.success) {
        setBreakoutGroupList(response.data.results)
        if (response.data.count > 0 && startModalVisible === false) {
          props.setShowBgModal(true)
          setStartModalVisible(true)
        } else {
          props.setShowBgModal(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchTrainingParticipants = async () => {
    let filter = ""
    if (profession !== "") {
      filter += "&owner__profession=" + profession
    }
    if (city !== "") {
      filter += "&owner__city=" + city
    }
    if (country !== "") {
      filter += "&owner__country=" + country
    }
    if (state !== "") {
      filter += "&owner__state=" + state
    }
    if (age !== "") {
      let age_low = 2021 - parseInt(age.split("-")[0])
      let age_high = 2021 - parseInt(age.split("-")[1])
      filter += `&age_low=${age_low}&age_high=${age_high}`
    }
    if (experience !== "") {
      let experience_low = 2021 - parseInt(experience.split("-")[0])
      let experience_high = 2021 - parseInt(experience.split("-")[1])
      filter += `&experience_low=${experience_low}&experience_high=${experience_high}`
    }
    try {
      let response = await getRequest(`/breakoutgroups-students/unselected/?training_id=${props.location.training_id}${filter}`)
      if (response.success) {
        setAllParticipants(response.data)
        console.log(response.data, "participants")
        setAllParticipantsRender(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchTrainingFilterParticipants = async () => {
    let filter = ""
    if (profession !== "") {
      filter += "&owner__profession=" + profession
    }
    if (city !== "") {
      filter += "&owner__city=" + city
    }
    if (country !== "") {
      filter += "&owner__country=" + country
    }
    if (state !== "") {
      filter += "&owner__state=" + state
    }
    if (age !== "") {
      let age_low = 2021 - parseInt(age.split("-")[0])
      let age_high = 2021 - parseInt(age.split("-")[1])
      filter += `&age_low=${age_low}&age_high=${age_high}`
    }
    if (experience !== "") {
      let experience_low = 2021 - parseInt(experience.split("-")[0])
      let experience_high = 2021 - parseInt(experience.split("-")[1])
      filter += `&experience_low=${experience_low}&experience_high=${experience_high}`
    }
    try {
      let response = await getRequest(`/breakoutgroups-students/unselected/?training_id=${props.location.training_id}${filter}`)
      if (response.success) {
        setAllFilterParticipants(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBreakoutGroupFilters = async () => {
    try {
      let response = await getRequest(`/breakoutgroups-students/filterdata/?training=${props.location.training_id}`)
      if (response.success) {
        setFilters(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateNoOfGroups = (data, value) => {
    if (data == "create") {
      setActiveStudents([])
      createGroupManual(null, "", value)
    }
  }

  const selectStudent = (e, value) => {
    e.preventDefault()
    let array = [...activeStudents]
    let index = array.indexOf(value.id)
    if (index === -1) {
      array.splice(array.length, 0, value.id)
    } else {
      array.splice(index, 1)
    }
    setActiveStudents(array)
  }

  const unselectedStudents = (value) => {
    if (allParticipants.length > 0) {
      return (
        <div className={`float-left ml-3 mt-3 p-2 ${Style.unselected_students_container}`}>
          <div style={{ height: "30px" }}>
            <div className="d-flex w-100 px-0">
              <p className={`w-100 mb-0 px-0 ${Style.unselected_students_count}`}>
                {`${allParticipantsRender ? allParticipants.length : ""} unselected students`}
              </p>
            </div>
          </div>
          <Droppable droppableId={`unselected_group`} type={`paxDraggable`}>
            {(provided, snapshot) => (
              <div className={`bg-transparent ${StylesA.height_340}`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {allParticipants
                  ? allParticipants.map((d, i) => {
                      return (
                        <Draggable key={d.id} draggableId={d.id + "draggable_element"} index={i}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`bg-transparent float-none`}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              <div className={`px-2 py-1 mt-2 d-flex ${Style.student_name_container}`}>
                                <AvatarCircle name={d?.owner?.first_name} size={`30px`} />
                                <div className={`${Style.student_name} align-self-center text-left ml-2`}>{d.owner.first_name}</div>
                                <FontAwesomeIcon color="#E0E2E5" icon={faChevronCircleRight} style={{ fontSize: "16px" }} />
                                <FontAwesomeIcon icon={faEllipsisV} className={`${Style.student_name_dot_icon}`} />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    })
                  : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )
    } else {
      return null
    }
  }
  const handleClose = () => {
    setVisibleAddStudents(false)
    props.setShowBgModal(false)
    if (props.setActiveSection) {
      if (window.location.href.indexOf("classroom-dashboard") >= 0) {
        props.setActiveSection("Home")
      } else {
        props.setActiveSection("LessonBoard")
      }
    }
    syncZoomBrGroup(props.location.training_id)
    if (props.setDisableScroll) props.setDisableScroll(true)
  }

  const createGroupManual = async (e, last_create = null, value = null) => {
    if (e != null) {
      e.preventDefault()
    }
    let formData = new FormData()
    let group_name = groupCreationName
    if (group_name == "") {
      if (value != null) {
        group_name = "Group" + value
      } else {
        group_name = "Group" + String(breakoutGroupList.length + 1)
      }
    }
    formData.append("training", props.location.training_id)
    formData.append("title", group_name)
    formData.append("training_track", JSON.stringify(activeStudents))
    try {
      let response = await postRequest(`/breakoutgroups/create/`, formData)
      if (response.success) {
        if (last_create == null) {
          let group = []
          group = addGroup
          group.push(response.data)
          setAddGroup(group)
          setVisibleAddStudents(false)
          let delete_participant = allParticipants
          activeStudents.map((data) => {
            let index = delete_participant.filter((participant) => participant.id != data)
            delete_participant = index
            setAllParticipants(index)
          })
          setActiveStudents([])
          setGroupCreationName("")
        } else if (last_create == "") {
          let group_data = breakoutGroupList
          let data_results = group_data
          data_results.push(response.data)
          group_data = data_results
          setBreakoutGroupList(group_data)
          setRerender(true)
        } else {
          fetchTrainingParticipants()
          fetchBreakoutGroupParticipants()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const createGroupSystem = async (e) => {
    e.preventDefault()
    const age_bool = new Boolean(systemAge)
    const country_bool = new Boolean(systemCountry)
    const city_bool = new Boolean(systemCity)
    const score_bool = new Boolean(systemScore)
    const experience_bool = new Boolean(systemExperience)
    const similar_bool = new Boolean(similar)

    let create_groups = 0
    if (breakoutGroupList.length > 0) {
      create_groups = 0
    } else {
      create_groups = groupCreationCount
    }
    try {
      let response = await postRequest(
        `/breakoutgroups-students/system-creation/?training=${props.location.training_id}&group_count=${create_groups}&age=${age_bool}&experience=${experience_bool}&similar=${similar_bool}&profession=${score_bool}&city=${city_bool}&country=${country_bool}`
      )
      if (response.success) {
        if (response.data.data === "created") {
          fetchBreakoutGroupParticipants()
          fetchTrainingParticipants()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteBreakoutGroup = async (id) => {
    try {
      let response = await deleteRequest("/breakoutgroups/" + id + "/")
      if (response.success) {
        let arr = breakoutGroupList.filter((item) => item.id !== id)
        setBreakoutGroupList(arr)
        setRerender(true)

        fetchTrainingParticipants()
        if (arr.length == 0) {
          setAddGroupsModalVisible(false)
        } else {
          setAddGroupsModalVisible(true)
        }
        setStartModalVisible(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const bgModalFun = () => {
    if (groupsModalVisible === true) {
      return breakoutGroupList.length > 0 && autoAssign == false ? (
        <div className={`px-0 float-left w-100 ${rerender}`}>
          <div className={`d-flex position-sticky w-100 ${Style.big_modal_container}`}>
            <p className={`mb-0 ${Style.big_modal_heading}`}>All groups</p>
            <img
              onClick={() => {
                handleClose()
                setStartModalVisible(false)
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`my-auto pointer ${Style.workspace_modal_close_icon}`}
            />
          </div>
          <div className="float-left px-0 w-100">
            {unselectedStudents("23%")}
            {showAllGroups("add")}
          </div>
          <div className="float-left px-0 w-100">
            <div className={`d-inline-flex  align-items-center`}>
              <Btn onClick={() => updateNoOfGroups("create")} className={`border_on_hover m-3`}>
                Add More Groups
              </Btn>
              {allParticipants.length > 0 ? (
                <Btn
                  onClick={() => {
                    setAutoAssign(true)
                    handleListClose()
                  }}
                  className={`m-3`}
                >
                  Auto add students to Groups
                </Btn>
              ) : (
                ""
              )}
              <Btn
                className={`ml-2 py-1 px-3 d-flex `}
                variant="none"
                onClick={(e) => {
                  handleClose()
                  props.setLeftNavOpen(true)
                  props.setActiveSection("Resource")
                  props.setCurrentNavTab("Resource")
                  setShowCommonItemsModal(true)
                }}
              >
                {breakoutGroupsResourcesFetched && breakoutGroupResources.length > 0 ? `Edit common items` : `Add common items`}
              </Btn>
            </div>
          </div>
        </div>
      ) : (
        startModal()
      )
    } else if (startModalVisible === true) {
      return breakoutGroupList.length > 0 && autoAssign == false ? (
        <div className={`px-0 float-left w-100  ${rerender}`}>
          <div className={`d-flex w-100 position-sticky ${Style.big_modal_container}`}>
            <p className={`mb-0 ${Style.big_modal_heading}`}>All groups</p>
            <img
              onClick={() => {
                handleClose()
                setStartModalVisible(false)
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`my-auto pointer ${Style.workspace_modal_close_icon}`}
            />
          </div>
          <div className="float-left px-0 w-100">
            {unselectedStudents("23%")}
            {showAllGroups("")}
          </div>
          <div className="float-left px-0 w-100">
            <div className={`d-inline-flex w-100 align-items-center`}>
              <Btn
                onClick={() => {
                  updateNoOfGroups("create")
                  setZoomBrGroupSync()
                }}
                className={`border_on_hover m-3`}
              >
                Add More Groups
              </Btn>
              {allParticipants.length > 0 ? (
                <Btn
                  onClick={() => {
                    setAutoAssign(true)
                  }}
                  className={`m-3`}
                >
                  Auto add students to Groups
                </Btn>
              ) : (
                ""
              )}
              <Btn
                className={` border_on_hover ml-2 py-1 px-3 d-flex `}
                variant="none"
                onClick={(e) => {
                  handleClose()
                  props.setLeftNavOpen(true)
                  props.setActiveSection("Resource")
                  props.setCurrentNavTab("Resource")
                  setShowCommonItemsModal(true)
                }}
              >
                {breakoutGroupsResourcesFetched && breakoutGroupResources.length > 0 ? `Edit common items` : `Add common items`}
              </Btn>
            </div>
          </div>
        </div>
      ) : (
        startModal()
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="float-left px-3 py-3">
          <Col lg="6" md="6" sm="6" xs="6" className="float-left px-0">
            <div className={`pl-2 pr-0 pt-2 px-0 d-flex ${Style.create_group_container}`}>
              <div className="w-100 float-left px-0">
                <h4 className={`${Style.create_group_heading} mb-0 pl-2 align-items-center float-left`}>
                  Create groups<br></br> by yourself
                </h4>
                <img src={manualgroup} className="mb-0 float-right" style={{ height: "100px" }} />
              </div>
            </div>
            <div className={`px-2 py-2 ${Style.create_group_desc_container}`}>
              <p className={`pl-2 mt-3 mb-0 ${Style.create_group_desc_text}`}>
                Want to control how your breakout groups work and who participate in it? Then you can customize it as you like. Click on the ‘Create Groups’
                option to create, define, choose your participants in individual breakout groups and configure them as per your choice.
              </p>
              <Btn
                className={`ml-2 my-3 px-3 ${Style.create_group_button}  ${StylesA.breakout_group_border_on_hover}`}
                onClick={() => {
                  updateNoOfGroups("create", "1")
                  updateNoOfGroups("create", "2")
                  setAddGroupsModalVisible(true)
                }}
              >
                Create Groups
              </Btn>
            </div>
          </Col>
          <Col lg="6" md="6" sm="6" xs="6" className="float-left pr-0">
            <div className={`px-2 pt-2 px-0 d-flex ${Style.create_group_container}`}>
              <div className="w-100 float-left px-0">
                <h4 className={` mb-0 pl-2 align-items-center float-left ${Style.create_group_heading}`}>
                  System creates<br></br> It for you
                </h4>
                <img src={systemgroup} className="mb-0 float-right" style={{ height: "100px" }} />
              </div>
            </div>

            <div className={`px-2 py-2 ${Style.create_group_desc_container}`}>
              <p className={`pl-2 mt-3 mb-0 ${Style.create_group_desc_text}`}>
                If you want to save time & effort, let the system automatically create breakout groups for your live classes based on criteria you select. Just
                select the criteria and click on the ‘Start Now’ option and voila! Your breakout groups are ready.
              </p>
              <Btn
                onClick={() => setStartModalVisible(true)}
                className={`ml-2 my-3 px-3  ${StylesA.breakout_group_border_on_hover} ${Style.create_group_button} `}
              >
                Start Now
              </Btn>
            </div>
          </Col>
        </Col>
      )
    }
  }

  const handleListClose = () => {
    props.setShowBgModal(false)
    if (props.setActiveSection) {
      if (window.location.href.indexOf("classroom-dashboard") >= 0) {
        props.setActiveSection("Home")
      } else {
        props.setActiveSection("LessonBoard")
      }
    }
    if (props.setDisableScroll) props.setDisableScroll(true)
  }
  const selectGroupToMove = () => {
    if (breakoutGroupList.length > 0) {
      let data = []
      data = breakoutGroupList.filter((g_id) => g_id.id != currentGroup)
      return (
        <div className={`px-0 ${Style.select_group_to_move_container}`}>
          <div className="w-100 px-0" style={{ minHeight: "42px" }}>
            <Select
              className={`groups-input pl-2 ${Style.select_component}`}
              labelField={"title"}
              searchBy={"title"}
              placeholder={"Select Group"}
              options={data}
              onChange={(values) => {
                setMoveGroup(values)
              }}
              dropdownPosition="bottom"
            />
            <div className={`position-absolute ${Style.circle_up_icon}`}>
              <FontAwesomeIcon color="#E0E2E5" icon={faChevronCircleUp} style={{ transform: "rotate(180deg)" }} />
            </div>
          </div>
          {moveGroup && moveGroup.length > 0 ? (
            <Droppable droppableId={breakoutGroupList.findIndex((res) => res.id === moveGroup[0].id) + `_group_` + moveGroup[0].id} type={`paxDraggable`}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getMoveModalListStyle(snapshot.isDraggingOver)}>
                  {breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === moveGroup[0].id)] &&
                  breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === moveGroup[0].id)].training_track.length > 0 ? (
                    breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === moveGroup[0].id)].training_track.map((d, i) => {
                      return (
                        <Draggable key={d.id} draggableId={d.id + "draggable_element"} index={i}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`bg-transparent float-none`}
                              style={getMoveModalItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              <div className={`px-2 py-1 mb-2 d-flex ${Style.student_name_container} ${StylesA.breakout_group_border_on_hover}`}>
                                <AvatarCircle name={d?.owner?.first_name} size={`30px`} />
                                <div className={`${Style.student_name} align-self-center text-left ml-2`}>{d.owner.first_name}</div>
                                <FontAwesomeIcon color="#E0E2E5" className="rotate-90 fs-16px" icon={faChevronCircleUp} />
                                <FontAwesomeIcon icon={faEllipsisV} className={`${Style.student_name_dot_icon}`} />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    })
                  ) : (
                    <p className={`mb-0 text-center ${Style.add_student_to_group}`}>Please add students to group</p>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : (
            <p className={`mb-0 text-center ${Style.add_student_to_group}`}>Please select a group to view students</p>
          )}
        </div>
      )
    } else {
      return null
    }
  }

  const triggerWorkspaceChanges = async () => {
    const response = await getRequest(`/training-websocket-trigger/${props.location.training_id}/action/?action="refetch_breakoutgroup"`)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    if (showCommonItemsModal) {
      onCommonResourceDragEnd(result)
    } else {
      const sourceIndex = result.source.index
      const destIndex = result.destination.index
      if (result.type === "paxDraggable") {
        const itemSubItemMap = breakoutGroupList

        let sourceParentId
        let destParentId
        let sourceSubItems
        let destSubItems

        sourceParentId = breakoutGroupList.findIndex((res) => res.id === parseInt(result.source.droppableId.split("_group_")[1]))
        destParentId = breakoutGroupList.findIndex((res) => res.id === parseInt(result.destination.droppableId.split("_group_")[1]))

        if (result.source.droppableId !== `unselected_group` && result.destination.droppableId !== `unselected_group`) {
          sourceParentId = breakoutGroupList.findIndex((res) => res.id === parseInt(result.source.droppableId.split("_group_")[1]))
          destParentId = breakoutGroupList.findIndex((res) => res.id === parseInt(result.destination.droppableId.split("_group_")[1]))
          sourceSubItems = itemSubItemMap[sourceParentId].training_track
          destSubItems = itemSubItemMap[destParentId].training_track

          let newItems = breakoutGroupList[destParentId]

          /** In this case subItems are reOrdered inside same Parent */
          if (sourceParentId === destParentId) {
            const items = reorder(breakoutGroupList[destParentId].training_track, sourceIndex, destIndex)
            let currentTopic = items[result.destination.index]
            let currentData = breakoutGroupList[destParentId].training_track
            breakoutGroupList[destParentId].training_track = items
          } else {
            let newSourceSubItems = sourceSubItems
            const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

            let newDestSubItems = destSubItems
            newDestSubItems.splice(destIndex, 0, draggedItem)
            changePartcipantGroup(
              null,
              parseInt(result.source.droppableId.split("_group_")[1]),
              parseInt(result.destination.droppableId.split("_group_")[1]),
              draggedItem.id
            )
            breakoutGroupList[destParentId] = newItems
          }
        } else {
          if (result.source.droppableId === `unselected_group` && result.destination.droppableId !== `unselected_group`) {
            sourceSubItems = allParticipants
            destSubItems =
              itemSubItemMap[breakoutGroupList.findIndex((res) => res.id === parseInt(result.destination.droppableId.split("_group_")[1]))].training_track
            sourceParentId = `unselected_group`
          } else if (result.source.droppableId !== `unselected_group` && result.destination.droppableId === `unselected_group`) {
            sourceSubItems = itemSubItemMap[sourceParentId].training_track
            destSubItems = allParticipants
            destParentId = `unselected_group`
          } else if (result.source.droppableId === result.destination.droppableId && result.source.droppableId === `unselected_group`) {
            sourceSubItems = allParticipants
            destSubItems = allParticipants
            sourceParentId = `unselected_group`
            destParentId = `unselected_group`
          }

          if (result.source.droppableId === `unselected_group`) {
            sourceSubItems = allParticipants
          } else {
            sourceSubItems = itemSubItemMap[sourceParentId].training_track
          }

          if (result.destination.droppableId === `unselected_group`) {
            destSubItems = allParticipants
          } else {
            destSubItems =
              itemSubItemMap[breakoutGroupList.findIndex((res) => res.id === parseInt(result.destination.droppableId.split("_group_")[1]))].training_track
          }

          let newItems = breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === parseInt(result.destination.droppableId.split("_group_")[1]))]

          /** In this case subItems are reOrdered inside same Parent */
          if (sourceParentId === destParentId) {
            const items = reorder(allParticipants, sourceIndex, destIndex)
            setAllParticipants(items)
          } else {
            let newSourceSubItems = sourceSubItems
            const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

            let newDestSubItems = destSubItems
            newDestSubItems.splice(destIndex, 0, draggedItem)
            if (result.destination.droppableId !== `unselected_group`) {
              breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === parseInt(result.destination.droppableId.split("_group_")[1]))] = newItems
              addParticipant(null, draggedItem.id, parseInt(result.destination.droppableId.split("_group_")[1]))
            } else if (result.source.droppableId !== `unselected_group` && result.destination.droppableId === `unselected_group`) {
              deleteParticipant(null, draggedItem.id, parseInt(result.source.droppableId.split("_group_")[1]))
            }
          }
        }
      }
    }
  }

  const onCommonResourceDragEnd = async (result) => {
    let type = result.source.droppableId.split("_")[0].toString() + `_resourceDraggable`
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    const sourceParentId = result.source.droppableId
    const destParentId = result.destination.droppableId
    if (type === "resource_resourceDraggable") {
      const itemSubItemMap = props.allResources
      let sourceSubItems, destSubItems, topic_id, module_id

      if (sourceParentId.indexOf("resource_") > -1) {
        sourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
      }

      if (destParentId.indexOf("_groupresource") > -1) {
        let datum = destParentId.split("_")
        let resource_id = datum[0]
        destSubItems = [...breakoutGroupResources]
      }

      /* In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(props.allResources[sourceParentId.replace("resource_", "")], sourceIndex, destIndex)
        allResources[sourceParentId.replace("resource_", "")] = items
        setAllResourcesChanged(!allResourcesChanged)
      } else {
        let newSourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)
        let formData = new FormData()

        let newDestSubItems = destSubItems

        if (result.destination.index === 0 && destSubItems.length === 0) {
          draggedItem["sequence"] = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index === destSubItems.length) {
          draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem["sequence"] = (
            (parseFloat(destSubItems[result.destination.index].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        /*api for creating resource to topic or module*/
        formData.append(`template`, props.location.template_id)
        formData.append("sequence", draggedItem.sequence)
        let sourcePIdType = sourceParentId.replace("resource_", "")
        if (sourcePIdType === "poll" || sourcePIdType === "pop-quiz") {
          formData.append("resource_type", "quiz")
        } else {
          if (sourcePIdType === "document") {
            formData.append("resource_type", "turientimage")
          } else {
            formData.append("resource_type", sourcePIdType)
          }
        }
        formData.append("resource_id", draggedItem.id)
        try {
          let response = await postRequest("/common-group-resource/", formData)
          if (response.success) {
            if (response.data) {
              let dummy = [...breakoutGroupResources]
              dummy.splice(result.destination.index, 0, response.data)
              setBreakoutGroupResources(dummy)
            }
          }
        } catch (error) {
          console.log(error)
        }
        /*api end*/
      }
    }
  }

  const GetParticipants = () => {
    const [check, setCheck] = useState(false)
    if (allFilterParticipants.length > 0) {
      return allFilterParticipants.map((value, indx) => {
        const item = activeStudents.indexOf(value.id)
        const border = item === -1 ? "1px solid transparent" : "1px solid #E0E2E5"
        const icon = item === -1 ? "none" : "inline-flex"
        return (
          <div
            key={indx}
            id={value.id + "track"}
            className={`float-left p-2 m-2 inner-element-box-shadow pointer ${Style.get_participant_container}`}
            style={{ border: `${border}` }}
          >
            <div className="d-flex">
              <div
                onClick={(e) => {
                  setCheck(!check)
                  selectStudent(e, value)
                }}
                className={`text-center d-flex align-items-center justify-content-center ${Style.checkbox_container}`}
              >
                <img id={value.id + "track_icon"} style={{ verticalAlign: "bottom", display: `${icon}` }} src={CheckIcon} />
              </div>
              <AvatarCircle name={value?.owner?.first_name} size={`30px`} />
              <div className="ml-2 align-self-center fs-14px float-left" style={{ width: "150px" }}>
                {value.owner.first_name}
              </div>
              <img src={dropRightArrow} alt={`rightarrow`} className={`my-auto float-right ${Style.right_arrow}`} />
            </div>
          </div>
        )
      })
    } else {
      return <p className={`mt-5 d-flex ${Style.no_students_found}`}>OOPS! no students found</p>
    }
  }

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }

  const onDragEnter = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  const onDragOver = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  const onDragLeave = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
  }

  const getImageToBeRendered = (item) => {
    let imgObj = turientimage,
      typeId = 2

    let typeOfFile = item?.resource_data?.file_type || 0
    if (item?.resource_data?.avatar) {
      imgObj = item.resource_data.avatar
    }

    switch (item.resource_type) {
      case "video":
        imgObj = video
        break
      case "blog":
        imgObj = blog
        break
      case "link":
        imgObj = link
        break
      case "quiz":
        imgObj = quiz
        let dataType = item?.resource_data?.data_type || 0
        if (dataType === "1") {
          imgObj = poll
        } else if (dataType === "2") {
          imgObj = popQuiz
        }
        break
      case "turientimage":
        imgObj = faImage
        typeId = 1
        if (typeOfFile === "2" || typeOfFile === "5") {
          imgObj = doc
          typeId = 2
        } else if (typeOfFile === "3" || typeOfFile === "7") {
          imgObj = excel
          typeId = 2
        } else if (typeOfFile === "4" || typeOfFile === "6") {
          imgObj = ppt
          typeId = 2
        } else if (typeOfFile === "1") {
          imgObj = pdf
          typeId = 2
        }
        break
      case "checklist":
        imgObj = checklist
        break
      case "casestudy":
        imgObj = casestudy
        break
    }
    return { typeId, imgObj }
  }

  const getSelectionStatus = (id, arr) => {
    let flag = 0
    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == id) {
          flag = 1
        }
      }
    }
    if (flag === 1) {
      return <img src={CheckIcon} className={`ml-auto`} />
    }
  }

  const refetchLeftSideResourcesBar = (pActiveResType = "") => {
    const evt = new CustomEvent("my-refresh-resources", { bubbles: true, detail: pActiveResType })
    document.dispatchEvent(evt)
  }

  const uploadDataProps = (topic_id, module_id, data, showSection = "") => {
    let seq = data

    setUploadInTopic(topic_id)
    setUploadInModule(module_id)
    setUploadDataSequence(seq)
    setShowSection(showSection)

    if (addResourceType === "common") {
      setAttrForResources({ template: topic_id, mainId: topic_id, add_to_group_resource: "true" })
    } else {
      setAttrForResources({ topic: topic_id, mainId: topic_id })
    }
  }
  const uploadDocs = (e, topic_id, module_id, data, upload, showSection) => {
    e.preventDefault()
    e.stopPropagation()
    uploadDataProps(topic_id, module_id, data, showSection)
    let domObj = document.getElementById("fileElem_" + topic_id)
    if (domObj && upload) {
      if (showSection === "turientimage") {
        domObj.accept = "image/*"
      }
      domObj.click()
    }
  }
  const openNewWin = (section, type, mainId, module_id, data) => {
    let wnd,
      seq = data
    uploadDataProps(mainId, module_id, data)

    if (type === "quiz") {
      wnd = window.open("/app/quizzes/create", type + "12")
      quizWin.current = { wnd, mainId, module_id, seq, section, addResourceType }
    } else if (type === "casestudy") {
      wnd = window.open("/app/case-study/create", type + "12")
      casestudyWin.current = { wnd, mainId, module_id, seq, section, addResourceType }
    } else if (type === "checklist") {
      wnd = window.open("/app/checklist/create", type + "12")
      checklistWin.current = { wnd, mainId, module_id, seq, section, addResourceType }
    }
  }
  const getNewWinData = (type) => {
    let win = null
    if (type === "quiz") {
      win = quizWin.current
    } else if (type === "casestudy") {
      win = casestudyWin.current
    } else if (type === "checklist") {
      win = checklistWin.current
    }
    return win
  }

  const handleNewWinMessages = (event) => {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      if (event.data.instruction === "ready") {
        let win = getNewWinData(event.data.type)
        if (win && win.wnd) {
          let obj = { topic: win.mainId, sequence: win.seq }
          if (win.addResourceType === "common") {
            obj = { template: win.mainId, sequence: win.seq, add_to_group_resource: "true" }
          }
          win.wnd.postMessage({ instruction: { ...obj } }, window.location.origin)
        }
      } else if (event.data.instruction === "payload") {
        let win = getNewWinData(event.data.type)
        if (win) {
          setTimeout(() => {
            if (win.addResourceType === "common") {
              fetchBreakoutGroupsResources(win.mainId)
            } else {
            }
          }, 1500) //Delay because the python backend thread takes time
          const evt = new CustomEvent("my-refresh-resources", { bubbles: true, detail: event.data.type })
          document.dispatchEvent(evt)
        }
      }
    }
  }

  const openModal = (type, mainId, module_id, data) => {
    let seq = data
    uploadDataProps(mainId, module_id, data)

    let obj = { topic: mainId, mainId, sequence: seq, addResourceType }
    if (addResourceType === "common") {
      obj = { template: mainId, mainId, sequence: seq, add_to_group_resource: "true", addResourceType }
    }

    if (type === "poll") {
      const evt1 = new CustomEvent("my-openClosePollPopQuizModal", { bubbles: true, detail: { which: "poll", openclose: true } })
      document.dispatchEvent(evt1)

      const evt = new CustomEvent("my-poll-data", { bubbles: true, detail: { ...obj } })
      document.dispatchEvent(evt)
    } else if (type === "pop-quiz") {
      const evt1 = new CustomEvent("my-openClosePollPopQuizModal", { bubbles: true, detail: { which: "pop-quiz", openclose: true } })
      document.dispatchEvent(evt1)

      const evt = new CustomEvent("my-pop-quiz-data", { bubbles: true, detail: { ...obj } })
      document.dispatchEvent(evt)
    }
  }
  const handlePollModalMsgs = (evt) => {
    setTimeout(() => {
      if (evt.detail.addResourceType === "common") {
        fetchBreakoutGroupsResources(evt.detail.mainId)
      } else {
      }
    }, 1500) //Delay because the python backend thread takes time
  }

  const handlePopQuizModalMsgs = (evt) => {
    setTimeout(() => {
      if (evt.detail.addResourceType === "common") {
        fetchBreakoutGroupsResources(evt.detail.mainId)
      } else {
      }
    }, 1500) //Delay because the python backend thread takes time
  }

  const commonItemsData = () => {
    let topic_id = props.location.template_id,
      module_id = 0,
      seq = 10
    return (
      <div className={`d-inline-flex w-100 align-items-center`}>
        <div className={`w-100`}>
          <div className={`d-inline-flex w-100 align-items-center py-1 ${Style.common_items_text_container}`}>
            <p className={`mb-0 ml-2 ${Style.common_items_text}`}>Add common items for groups</p>
            <img
              onClick={() => {
                setShowCommonItemsModal(false)
                setBreakoutGroupInstructionsFetched(false)
                setOpenInstructions(false)
                props.resetDragState()
                props.setActiveSection("BreakoutGroup")
                props.setLeftNavOpen(false)
                props.setShowBgModal(true)
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`ml-auto mr-2 mb-0 pointer ${Style.close_common_item}`}
            />
          </div>
          <div className={`w-100 px-2 py-3 ${Style.add_instruction_container}`}>
            <div className={`w-100 mb-2 ${Style.add_instruction_text_contaier}`}>
              <div
                className={`d-inline-flex w-100 align-items-center px-2 py-2 pointer`}
                onClick={() => {
                  setOpenInstructions(!openInstructions)
                }}
                style={{ borderBottom: openInstructions ? "1px solid #FFFFFF40" : "" }}
              >
                <FontAwesomeIcon icon={faCommentAlt} className={`mr-2`} color="#E0E2E5" />
                <p className={`mb-0 ml-2 ${Style.add_instruction_text}`}>Add general Instructions</p>
                <img src={openInstructions ? cricleUpArrorw : cricleDownArrorw} className={`ml-auto`} width={20} height={20} />
              </div>
              {openInstructions ? (
                breakoutGroupInstructionsFetched ? (
                  breakoutGroupInstructions.length > 0 ? (
                    breakoutGroupInstructions.map((instruction, i_indx) => {
                      return (
                        <div
                          className={`w-100 pb-2`}
                          key={`instruction` + instruction.id}
                          style={{ borderBottom: i_indx + 1 < breakoutGroupInstructions.length ? "1px solid #FFFFFF60" : "" }}
                        >
                          <div
                            className={`px-0 border_on_hover`}
                            style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54", position: "relative" }}
                          >
                            <SunEditor
                              id={`instruction` + instruction.id}
                              name="instruction"
                              placeholder=""
                              defaultValue={instruction.instruction}
                              onChange={(content) => {
                                let dummy = { ...instructionsLoader }
                                let text = `instruction_` + instruction.id
                                dummy[text] = true
                                setInstructionsLoader(dummy)
                                delayedQuery(content, props.location.template_id, instruction, i_indx, "instruction", null, breakoutGroupInstructions)
                              }}
                              //setContents={breakoutGroupInstructions[i_indx].instruction}
                              setOptions={{
                                fontSize: 18,
                                fontColor: "#fff",
                                height: "auto",
                                maxHeight: "100px",
                                minHeight: "100px",
                                backgroundColor: "#212C42",
                                buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link", "removeFormat"]],
                              }}
                            />
                            <img
                              src={DeleteIcon}
                              className={`ml-auto pointer pt-2 pr-2`}
                              style={{ position: "absolute", right: 0, top: 0, zIndex: 999 }}
                              onClick={(e) => {
                                deleteBreakoutGroupsResources(instruction.id, i_indx, "instruction")
                              }}
                            />
                          </div>
                          <div className={`d-inline-flex w-100 align-items-center px-2 pt-1 pb-2`} style={{}}>
                            <Dropdown className={`breakout-groups`}>
                              <Dropdown.Toggle
                                size="lg"
                                className={`border-0 px-2 py-1 d-inline-flex align-items-center ${StylesA.btn_focus} ${Style.instruction_breakoutgroup}`}
                                id="dropdown_list"
                              >
                                {instruction?.breakout_groups?.length > 0 ? (
                                  instruction?.breakout_groups?.map((grp, indx) => {
                                    return (
                                      <p
                                        key={`${instruction.id}_selected_groups_${indx}`}
                                        className={`mb-0 ${indx + 1 == instruction?.breakout_groups.length ? "pr-3" : "pr-2"}`}
                                      >
                                        {grp.title}
                                        {`${indx + 1 < instruction?.breakout_groups?.length ? "," : ``}`}
                                      </p>
                                    )
                                  })
                                ) : (
                                  <p className={`mb-0 pr-1`}>All Groups</p>
                                )}
                                <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className={`br-9px breakout-groups`}>
                                {breakoutGroupList.length > 0 ? (
                                  breakoutGroupList.map((group, index) => {
                                    return (
                                      <Dropdown.Item
                                        className={`${StylesB.dropitem} text-white fs-14px pl-3 py-1 d-inline-flex`}
                                        style={{ minWidth: `max-content` }}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          updateCommomResourceInstruction(
                                            "breakout_groups",
                                            props.location.template_id,
                                            instruction,
                                            i_indx,
                                            "breakout_groups_instruction",
                                            group.id
                                          )
                                        }}
                                      >
                                        <p className={`mb-0 w-100 d-inline-flex align-items-center`}>
                                          {group.title}
                                          {getSelectionStatus(group.id, instruction?.breakout_groups)}
                                        </p>
                                      </Dropdown.Item>
                                    )
                                  })
                                ) : (
                                  <p className={`text-center mb-0 mx-auto py-2 ${Style.group_by_similarities_text}`}>No groups created yet</p>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            <div className={`mb-0 ml-auto ${Style.all_changes_saved}`}>
                              <span className="mb-0 ml-2 fs-14px">
                                {instructionsLoader[`instruction_` + instruction.id] ? (
                                  <span className={`bg-transparent border-0`} id={`basic-addon`}>
                                    <Loader />
                                  </span>
                                ) : (
                                  <img src={CheckIcon} alt={`changes_saved_check`} className={`ml-auto mb-0 mr-1 ${Style.check_icon}`} />
                                )}
                                All changes are saved
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className={`w-100`}>
                      <p className={`w-100 py-3 mb-0 text-center ${Style.add_instruction_text}`}>No instructions created.</p>
                    </div>
                  )
                ) : (
                  <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                    <Loader />
                  </Col>
                )
              ) : null}
            </div>
            {openInstructions ? (
              <p
                className={`mb-3 pointer ${Style.add_more_instructions}`}
                onClick={() => {
                  createCommomResourceInstruction()
                }}
              >
                <u>{breakoutGroupInstructions.length > 0 ? `Add more Instructions` : `Add Instructions`}</u>
              </p>
            ) : null}

            <div key={"div2"} className={`px-0 ${Style.common_resource_container}`}>
              <div className={`${Style.common_resource_text_container}`}>
                <p className={`${Style.common_resource_text}`}>Common Resources</p>
              </div>
              {showCommonResources()}
              <div className={`d-flex p-1 ${Style.resource_icon_container}`}>
                <OverlayTrigger overlay={<Tooltip>Add video</Tooltip>}>
                  <img
                    src={video}
                    onClick={(e) => {
                      props.openNavWithResourceSection("Resource", "video")
                      uploadDocs(e, topic_id, module_id, seq, false, "video")
                    }}
                    title={`Add Videos`}
                    alt={`Add Videos`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer ${Style.resource_icon}`}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add blog</Tooltip>}>
                  <img
                    onClick={(e) => {
                      props.openNavWithResourceSection("Resource", "blog")
                      uploadDataProps(topic_id, module_id, seq, "")
                      setAddBlog(true)
                    }}
                    src={WhiteBlogIcon}
                    title={`Add Blog`}
                    alt={`add blog button`}
                    className={`${
                      props.leftNavOpen && props.currentSelectedResourcesType === "blog" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                    } my-auto mx-1 pointer ${Style.resource_icon}`}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add documents</Tooltip>}>
                  <img
                    onClick={(e) => {
                      props.openNavWithResourceSection("Resource", "document")
                      uploadDocs(e, topic_id, module_id, seq, false, "document")
                    }}
                    src={documents}
                    alt={`Add Documents`}
                    title={`Add Documents(Doc, Excel, PPT, PDF, Google Docs Link)`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer ${Style.resource_icon}`}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add images</Tooltip>}>
                  <FontAwesomeIcon
                    icon={faImage}
                    onClick={(e) => {
                      props.openNavWithResourceSection("Resource", "turientimage")
                      uploadDocs(e, topic_id, module_id, seq, true, "turientimage")
                    }}
                    title={`Add Images`}
                    alt={`Add Images`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer ${Style.resource_icon}`}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add links</Tooltip>}>
                  <img
                    src={link}
                    onClick={(e) => {
                      props.openNavWithResourceSection("Resource", "link")
                      uploadDocs(e, topic_id, module_id, seq, false, "link")
                    }}
                    title={`Add Links`}
                    alt={`Add Links`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer ${Style.resource_icon}`}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add quiz</Tooltip>}>
                  <img
                    onClick={(e) => {
                      e.stopPropagation()
                      props.openNavWithResourceSection("Poll", "quiz")
                      openNewWin("Poll", "quiz", topic_id, module_id, seq)
                    }}
                    src={WhiteQuizIcon}
                    title={`Add Quiz`}
                    alt={`add quiz button`}
                    className={`${
                      props.leftNavOpen && props.currentSelectedResourcesType === "quiz" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                    } my-auto mx-1 pointer ${Style.resource_icon}`}
                  />
                </OverlayTrigger>
                <span style={{ width: 17, height: 17, marginBottom: 12 }}>&nbsp;|&nbsp;</span>
                <OverlayTrigger overlay={<Tooltip>Add poll</Tooltip>}>
                  <img
                    onClick={(e) => {
                      e.stopPropagation()
                      props.openNavWithResourceSection("Poll", "poll")
                      openModal("poll", topic_id, module_id, seq)
                    }}
                    src={WhitePollIcon}
                    title={`Add Poll`}
                    alt={`add poll button`}
                    className={`${
                      props.leftNavOpen && (props.currentSelectedResourcesType === "poll" || props.currentSelectedResourcesType === "poll")
                        ? StylesB.resource_select_icon_active
                        : StylesB.resource_select_icon
                    } my-auto mx-1 pointer ${Style.resource_icon}`}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add pop-quiz</Tooltip>}>
                  <img
                    onClick={(e) => {
                      e.stopPropagation()
                      props.openNavWithResourceSection("Poll", "pop-quiz")
                      openModal("pop-quiz", topic_id, module_id, seq)
                    }}
                    src={popQuiz}
                    title={`Add Pop-Quiz`}
                    alt={`add pop quiz button`}
                    className={`${
                      props.leftNavOpen && (props.currentSelectedResourcesType === "poll" || props.currentSelectedResourcesType === "pop-quiz")
                        ? StylesB.resource_select_icon_active
                        : StylesB.resource_select_icon
                    } my-auto mx-1 pointer ${Style.resource_icon}`}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add checklist</Tooltip>}>
                  <img
                    onClick={(e) => {
                      e.stopPropagation()
                      props.openNavWithResourceSection("Poll", "checklist")
                      openNewWin("Poll", "checklist", topic_id, module_id, seq)
                    }}
                    src={checklist}
                    title={`Add Checklist`}
                    alt={`add checklist button`}
                    className={`${
                      props.leftNavOpen && (props.currentSelectedResourcesType === "poll" || props.currentSelectedResourcesType === "checklist")
                        ? StylesB.resource_select_icon_active
                        : StylesB.resource_select_icon
                    } my-auto mx-1 pointer ${Style.resource_icon}`}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add case-study</Tooltip>}>
                  <img
                    onClick={(e) => {
                      e.stopPropagation()
                      props.openNavWithResourceSection("Poll", "casestudy")
                      openNewWin("Poll", "casestudy", topic_id, module_id, seq)
                    }}
                    src={casestudy}
                    title={`Add Case-Study`}
                    alt={`add case study button`}
                    className={`${
                      props.leftNavOpen && (props.currentSelectedResourcesType === "poll" || props.currentSelectedResourcesType === "casestudy")
                        ? StylesB.resource_select_icon_active
                        : StylesB.resource_select_icon
                    } my-auto mx-1 pointer ${Style.resource_icon}`}
                  />
                </OverlayTrigger>
              </div>
              {uploadInModule !== null && uploadInTopic !== null && uploadDataSequence !== null && uploadInTopic === topic_id ? (
                <UploadFiles
                  files={files}
                  setFiles={(value) => setFiles(value)}
                  fetchTopicResources={(id) => fetchBreakoutGroupsResources()}
                  showSection={showSection}
                  setShowSection={setShowSection}
                  refetchLeftSideResourcesBar={refetchLeftSideResourcesBar}
                  properties={{
                    module: "Common Items Group",
                    template: uploadInTopic,
                    mainId: uploadInTopic,
                    itemName: "",
                    add_to_group_resource: "true",
                    sequence: uploadDataSequence,
                  }}
                />
              ) : null}
              <Blog
                showBlog={addBlog}
                setShowBlog={setAddBlog}
                topicId={uploadInTopic}
                sequenceId={uploadDataSequence}
                fetchTopicResources={(id) => (addResourceType === "common" ? fetchBreakoutGroupsResources() : null)}
                refetchLeftSideResourcesBar={() => refetchLeftSideResourcesBar("blog")}
                properties={attrForResources}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const showCommonResources = () => {
    let next_url = null
    let see_more_loader = false
    if (breakoutGroupResources) {
      return (
        <Droppable droppableId={`${props.location.template_id}_groupresource`} type={`resourceDraggable`}>
          {(provided, snapshot) => (
            <div
              id={`drop-area`}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              className={`bg-transparent px-4 ${Styles.opacity_1}`}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {breakoutGroupResources.map((common_resource, index) => {
                let item = common_resource.resource
                let { typeId, imgObj } = getImageToBeRendered(item)
                return (
                  <>
                    <Draggable
                      key={item.id + `_groupresource_name_` + index}
                      draggableId={item.id.toString() + `-groupresource` + "_" + index.toString()}
                      index={index}
                      className={item.resource_data !== null ? "" : "d-none"}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={item.resource_data !== null ? "resource-item" : "d-none"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div className={`pr-2 d-inline-flex w-100 ${Style.show_common_resource_container}`}>
                            <div className="ml-1 d-flex align-items-center">
                              <FontAwesomeIcon className={`ml-1 ${item.resource_type ? `d-none` : ``}`} icon={faSpinner} size="lg" />
                              {item.resource_data ? (
                                item.resource_data.thumbnail_url ? (
                                  <LazyLoadImage className={`my-auto ${Style.resource_thumbnail}`} src={item.resource_data.thumbnail_url} alt={item.name} />
                                ) : (
                                  <>
                                    {typeId === 1 ? (
                                      <FontAwesomeIcon icon={imgObj} className={`my-0 ${Style.resource_img_obj}`} />
                                    ) : (
                                      <LazyLoadImage className={`my-auto ${Style.resource_thumbnail}`} src={imgObj} alt={item.name} />
                                    )}
                                  </>
                                )
                              ) : (
                                ``
                              )}
                              <p className={`${Style.resource_name}`}>{item.resource_data ? item.resource_data?.name : item.name}</p>
                            </div>
                            <div className={`d-inline-flex py-1 align-items-center`}>
                              <Dropdown className={`breakout-groups ml-auto mr-2`}>
                                <Dropdown.Toggle
                                  size="lg"
                                  className={`border-0 px-2 py-1 d-inline-flex align-items-center ${StylesA.btn_focus} ${Style.breakoutgroup_toggle}`}
                                  id="dropdown_list"
                                >
                                  {common_resource?.breakout_groups?.length > 0 ? (
                                    common_resource?.breakout_groups?.map((grp, indx) => {
                                      return (
                                        <p
                                          key={`${common_resource.id}_selected_groups_${indx}`}
                                          className={`mb-0 ${indx + 1 == common_resource?.breakout_groups.length ? "pr-3" : "pr-2"}`}
                                        >
                                          {grp.title}
                                          {`${indx + 1 < common_resource?.breakout_groups?.length ? "," : ``}`}
                                        </p>
                                      )
                                    })
                                  ) : (
                                    <p className={`mb-0 pr-1`}>All Groups</p>
                                  )}
                                  <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={`br-9px breakout-groups`}>
                                  {breakoutGroupList.length > 0 ? (
                                    breakoutGroupList.map((group, b_index) => {
                                      return (
                                        <Dropdown.Item
                                          className={`${StylesB.dropitem} text-white fs-14px pl-3 py-1 d-inline-flex`}
                                          style={{ minWidth: `max-content` }}
                                          onClick={(e) => {
                                            e.preventDefault()
                                            updateCommomResourceInstruction(
                                              "breakout_groups",
                                              props.location.template_id,
                                              common_resource,
                                              index,
                                              "breakout_groups",
                                              group.id
                                            )
                                          }}
                                        >
                                          <p className={`mb-0 w-100 d-inline-flex align-items-center`}>
                                            {group.title}
                                            {getSelectionStatus(group.id, common_resource?.breakout_groups)}
                                          </p>
                                        </Dropdown.Item>
                                      )
                                    })
                                  ) : (
                                    <p className={`text-center mb-0 mx-auto py-2 ${Style.add_instruction_text}`}>No groups created yet</p>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                              <img
                                src={DeleteIcon}
                                onClick={(e) => {
                                  deleteBreakoutGroupsResources(common_resource.id, index, "resource")
                                }}
                                alt={"resource delete icon"}
                                className={`my-auto pointer ${Style.delete_icon}`}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </>
                )
              })}
              {provided.placeholder}
              <React.Fragment>
                <div className={`justify-content-center pt-4 pb-3 d-flex bg-transparent`}>
                  <div className={`${Styles.drop_area} m-0 px-0 py-0 bg-transparent  text-center ${files.length === 0 ? `` : `border-0`}`}>
                    <div className={`w-100 col-12`}>
                      <Form className="my-form mb-0">
                        <div>
                          <p className={` mb-2 fs-14px`}>Drag and drop media/document files to add content</p>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          )}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Loader />
        </Col>
      )
    }
  }

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd}>
        <Modal
          show={props.showBgModal && !showCommonItemsModal}
          onHide={handleClose}
          centered={false}
          size={"lg"}
          contentClassName={`br-9px ml-0`}
          dialogClassName={`${
            (breakoutGroupList.length > 0 && startModalVisible && autoAssign == false) ||
            (breakoutGroupList.length == 0 && groupsModalVisible && autoAssign == false) ||
            (breakoutGroupList.length > 0 && groupsModalVisible && autoAssign == false)
              ? "breakout-groups-big"
              : "breakout-groups"
          }`}
          style={{ marginTop: "15vh" }}
        >
          <Modal.Body className="p-0 br-9px " style={{ backgroundColor: "#212C42" }}>
            {bgModalFun()}
          </Modal.Body>
        </Modal>
        {moveModal ? (
          <Modal
            show={moveModal}
            onHide={handlemoveModal}
            centered
            className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal`}
            dialogClassName={`breakout-groups-move`}
          >
            <Modal.Body className={`p-0 ${Style.move_students_modal_body}`}>
              <div className={`d-flex p-2 ${Style.move_students_modal_header}`}>
                <div className="d-flex align-items-center my-1">
                  <img src={CompareArrow} alt={`group-delete`} className={`my-auto mr-2 mb-0 ${Style.move_students_modal_arrow}`} />
                  <p className="text-white mb-0 fs-18px" style={{ lineHeight: 1 }}>
                    Move Students
                  </p>
                </div>
                <img
                  onClick={() => {
                    handlemoveModal()
                  }}
                  src={CancelWhite}
                  alt={`Cancel`}
                  className={`my-auto pointer ${Style.move_students_modal_arrow}`}
                />
              </div>
              <div className="d-flex p-3 col-12">
                <div className={`px-0 pointer ${Style.move_students_group_card}`}>
                  <div>
                    <div className="d-flex col-12 px-0">
                      <input
                        className="groups-input col-12 text-white"
                        style={{ border: "1px solid #42506C" }}
                        type="text"
                        placeholder="Name of the group"
                        defaultValue={`${
                          breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === currentGroup)] &&
                          breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === currentGroup)].title != undefined
                            ? breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === currentGroup)].title
                            : ""
                        }`}
                        readOnly
                      />
                    </div>
                  </div>
                  {breakoutGroupList && breakoutGroupList ? (
                    <Droppable droppableId={breakoutGroupList.findIndex((res) => res.id === currentGroup) + `_group_` + currentGroup} type={`paxDraggable`}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} style={getMoveModalListStyle(snapshot.isDraggingOver)}>
                          {breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === currentGroup)] &&
                          breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === currentGroup)].title != undefined &&
                          breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === currentGroup)].training_track.length > 0 ? (
                            breakoutGroupList[breakoutGroupList.findIndex((res) => res.id === currentGroup)].training_track.map((d, i) => {
                              return (
                                <Draggable key={d.id} draggableId={d.id + "draggable_element"} isDragDisabled={moveGroup && moveGroup.length == 0} index={i}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={`bg-transparent float-none`}
                                      style={getMoveModalItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                      <div className={`px-2 py-1 mb-2 d-flex col-12 ${StylesA.breakout_group_border_on_hover} ${Style.student_name_container}`}>
                                        <AvatarCircle name={d?.owner?.first_name} size={`30px`} />
                                        <div className={` align-self-center text-left ml-2 ${Style.student_name}`}>{d?.owner?.first_name}</div>
                                        <FontAwesomeIcon color="#E0E2E5" className="rotate-90 fs-16px" icon={faChevronCircleUp} />
                                        <FontAwesomeIcon icon={faEllipsisV} className={`${Style.student_name_dot_icon}`} />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              )
                            })
                          ) : (
                            <p className={`mb-0 text-center ${Style.add_student_to_group} `}>Please add students to group</p>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ) : null}
                </div>
                {selectGroupToMove()}
              </div>
            </Modal.Body>
          </Modal>
        ) : null}
        <Modal
          scrollable={true}
          show={visible_add_students_modal}
          centered={false}
          size={"lg"}
          contentClassName={`br-9px ml-5`}
          dialogClassName="breakout-groups-participants"
          style={{ left: "100px", top: "200px", position: "absolute" }}
        >
          <Modal.Header className={`p-0 ${Style.add_student_modal_header}`}>
            <div className={`float-left px-0 align-items-center w-100 ${Style.add_student_modal_container}`}>
              <div className={`float-left d-flex right-border ${Style.all_students_container}`}>
                <p className={`${Style.all_students_text}`}>All Students({allParticipants.length})</p>
              </div>
              <div
                className={`float-left ${
                  (isEmpty(filters.age_range) || filters.age_range[0] == filters.age_range[1]) &&
                  isEmpty(filters.work_experience) &&
                  filters.country &&
                  filters.country[0] == null &&
                  filters.city &&
                  filters.city[0] == null &&
                  filters.profession &&
                  filters.profession[0] == null
                    ? "d-none"
                    : ""
                }`}
              >
                <p className={`ml-2 my-auto ${Style.group_by_similarities_text}`}>Filter by</p>
              </div>
              <div className="float-left">
                <div className={`float-left ${isEmpty(filters.age_range) || filters.age_range[0] == filters.age_range[1] ? "d-none" : ""}`}>
                  <Dropdown
                    onToggle={() => {
                      setAgeToggle(!ageToggle)
                    }}
                    className="mr-2 my-2 br-9px breakout-groups"
                  >
                    <Dropdown.Toggle
                      className={` px-3 ${StylesA.breakout_group_border_on_hover} ${Style.add_students_dropdown}`}
                      variant="Secondary"
                      id="dropdown-basic"
                    >
                      Age {ageToggle ? <FontAwesomeIcon icon={faChevronUp} className="ml-1" /> : <FontAwesomeIcon icon={faChevronDown} className="ml-1" />}{" "}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={` py-0 mt-1 ${Style.add_students_dropdown_menu}`}>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault()
                          setAge("")
                        }}
                        className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                      >
                        All {age == "" ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                      </Dropdown.Item>
                      {filters.age_range && filters.age_range[0] != "" ? (
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault()
                            setAge(filters.age_range[0])
                          }}
                          className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                        >
                          {filters.age_range[0]} Yrs {age == filters.age_range[0] ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                      {filters.age_range && filters.age_range[1] != "" ? (
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault()
                            setAge(filters.age_range[1])
                          }}
                          className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                        >
                          {filters.age_range[1]} Yrs {age == filters.age_range[1] ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                      {filters.age_range && filters.age_range[2] != "" ? (
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault()
                            setAge(filters.age_range[2])
                          }}
                          className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                        >
                          {filters.age_range[2]} Yrs {age == filters.age_range[2] ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                      {filters.age_range && filters.age_range[3] != "" ? (
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault()
                            setAge(filters.age_range[3])
                          }}
                          className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                        >
                          {filters.age_range[3]} Yrs {age == filters.age_range[3] ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                      {filters.age_range && filters.age_range[4] != "" ? (
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault()
                            setAge(filters.age_range[4])
                          }}
                          className={` last_drop_item d-flex align-items-center ${Style.add_students_dropdown_item}`}
                        >
                          {filters.age_range[4]} Yrs {age == filters.age_range[4] ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className={`float-left ${isEmpty(filters.work_experience) ? "d-none" : ""}`}>
                  <Dropdown
                    onToggle={() => {
                      setExpToggle(!expToggle)
                    }}
                    className="mr-2 my-2 br-9px breakout-groups"
                  >
                    <Dropdown.Toggle
                      className={` px-3 ${StylesA.breakout_group_border_on_hover} ${Style.add_students_dropdown}`}
                      variant="Secondary"
                      id="dropdown-basic"
                    >
                      Work Experience{" "}
                      {expToggle ? <FontAwesomeIcon icon={faChevronUp} className="ml-1" /> : <FontAwesomeIcon icon={faChevronDown} className="ml-1" />}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="filter py-0 mt-1">
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault()
                          setExperience("")
                        }}
                        className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                      >
                        All {experience == "" ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                      </Dropdown.Item>
                      {filters.work_experience && filters.work_experience[0] != "" ? (
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault()
                            setExperience(filters.work_experience[0])
                          }}
                          className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                        >
                          {filters.work_experience[0]} Yrs{" "}
                          {experience == filters.work_experience[0] ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                      {filters.work_experience && filters.work_experience[1] != "" ? (
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault()
                            setExperience(filters.work_experience[1])
                          }}
                          className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                        >
                          {filters.work_experience[1]} Yrs{" "}
                          {experience == filters.work_experience[1] ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                      {filters.work_experience && filters.work_experience[2] != "" ? (
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault()
                            setExperience(filters.work_experience[2])
                          }}
                          className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                        >
                          {filters.work_experience[2]} Yrs{" "}
                          {experience == filters.work_experience[2] ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                      {filters.work_experience && filters.work_experience[3] != "" ? (
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault()
                            setExperience(filters.work_experience[3])
                          }}
                          className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                        >
                          {filters.work_experience[3]} Yrs{" "}
                          {experience == filters.work_experience[3] ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                      {filters.work_experience && filters.work_experience[4] != "" ? (
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault()
                            setExperience(filters.work_experience[4])
                          }}
                          className={`last_drop_item d-flex align-items-center ${Style.add_students_dropdown_item}`}
                        >
                          {filters.work_experience[4]} Yrs{" "}
                          {experience == filters.work_experience[4] ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className={`float-left ${filters.country && filters.country[0] != null ? "" : "d-none"}`}>
                  <Dropdown
                    onToggle={() => {
                      setCountryToggle(!countryToggle)
                    }}
                    className="mr-2 my-2 br-9px breakout-groups"
                  >
                    <Dropdown.Toggle
                      className={` ${StylesA.breakout_group_border_on_hover} ${Style.add_students_dropdown}`}
                      variant="Secondary"
                      id="dropdown-basic"
                    >
                      Country{" "}
                      {countryToggle ? <FontAwesomeIcon icon={faChevronUp} className="ml-1" /> : <FontAwesomeIcon icon={faChevronDown} className="ml-1" />}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="filter py-0 mt-1">
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault()
                          setCountry("")
                        }}
                        className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                      >
                        All {country == "" ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                      </Dropdown.Item>
                      {filters.country &&
                        filters.country.map((cty, index) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              onClick={(e) => {
                                e.preventDefault()
                                setCountry(cty)
                              }}
                              className={`${filters.country.length - 1 == index ? "last_drop_item" : ""} ${Style.add_students_dropdown_item}  ${
                                cty == null || cty == "" ? "d-none" : "d-flex align-items-center"
                              }`}
                            >
                              {cty}
                              {country == cty ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                            </Dropdown.Item>
                          )
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className={`float-left ${filters.city && filters.city[0] != null ? "" : "d-none"}`}>
                  <Dropdown
                    onToggle={() => {
                      setCityToggle(!cityToggle)
                    }}
                    className="mr-2 my-2 br-9px breakout-groups"
                  >
                    <Dropdown.Toggle
                      className={` ${StylesA.breakout_group_border_on_hover} ${Style.add_students_dropdown}`}
                      variant="Secondary"
                      id="dropdown-basic"
                    >
                      City {cityToggle ? <FontAwesomeIcon icon={faChevronUp} className="ml-1" /> : <FontAwesomeIcon icon={faChevronDown} className="ml-1" />}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="filter py-0 mt-1">
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault()
                          setCity("")
                        }}
                        className={`d-flex align-items-center ${Style.add_students_dropdown_item}`}
                      >
                        All{city == "" ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                      </Dropdown.Item>
                      {filters.city &&
                        filters.city.map((cty, index) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              onClick={(e) => {
                                e.preventDefault()
                                setCity(cty)
                              }}
                              className={`${filters.city.length - 1 == index ? "last_drop_item" : ""} ${Style.add_students_dropdown_item} ${
                                cty == null || cty == "" ? "d-none" : "d-flex align-items-center"
                              } `}
                            >
                              {cty}
                              {city == cty ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                            </Dropdown.Item>
                          )
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className={`float-left ${filters.profession && filters.profession[0] != null ? "" : "d-none"}`}>
                  <Dropdown
                    onToggle={() => {
                      setProToggle(!proToggle)
                    }}
                    className="mr-2 my-2 br-9px breakout-groups"
                  >
                    <Dropdown.Toggle
                      className={` ${StylesA.breakout_group_border_on_hover} ${Style.add_students_dropdown}`}
                      variant="Secondary"
                      id="dropdown-basic"
                    >
                      Profession{" "}
                      {proToggle ? <FontAwesomeIcon icon={faChevronUp} className="ml-1" /> : <FontAwesomeIcon icon={faChevronDown} className="ml-1" />}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="filter py-0 mt-1">
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault()
                          setProfession("")
                        }}
                        className={`filter d-flex align-items-center ${Style.add_students_dropdown_item}`}
                      >
                        All{profession == "" ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                      </Dropdown.Item>
                      {filters.profession &&
                        filters.profession.map((cty, index) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              onClick={(e) => {
                                e.preventDefault()
                                setProfession(cty)
                              }}
                              className={`${filters.profession.length - 1 == index ? "last_drop_item" : ""} ${Style.add_students_dropdown_item} ${
                                cty == null || cty == "" ? "d-none" : "d-flex align-items-center"
                              }`}
                            >
                              {cty}
                              {profession == cty ? <img className={`${Style.checkbox}`} src={CheckIcon} /> : ""}
                            </Dropdown.Item>
                          )
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className={`pointer position-absolute float-left ${Style.close_icon_container}`}>
                <img
                  onClick={() => {
                    setVisibleAddStudents(false)
                    setActiveStudents([])
                  }}
                  src={CancelWhite}
                  alt={`Cancel`}
                  className={`${Style.close_icon_student_modal}`}
                />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="p-0" style={{ backgroundColor: "#212C42" }}>
            <Col lg="12" md="12" sm="12" xs="12" className="px-0 float-left">
              <Col lg="12" md="12" sm="12" xs="12" className={`px-0 float-left ${activeStudents.length > 0 ? "" : "d-none"}`}>
                <p className="pl-3 mb-0" style={{ backgroundColor: "#FFFFFF" }}>
                  {activeStudents.length} {activeStudents.length > 1 ? "students" : "student"} selected
                </p>
              </Col>
              <Col lg="12" md="12" sm="12" xs="12" className="px-0 float-left" style={{ height: "400px", overflowY: "auto" }}>
                <GetParticipants />
              </Col>
            </Col>
          </Modal.Body>
          <Modal.Footer className="p-0" style={{ backgroundColor: "#212C42", border: "none" }}>
            <Col lg="12" md="12" sm="12" xs="12" className="px-0 float-left text-center" style={{ backgroundColor: "#212C42" }}>
              <Btn className=" text-white mt-2" onClick={(e) => addParticipant(e, activeStudents, groupPresent, "array_data")}>
                Add Students
              </Btn>
            </Col>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showCreateMuralLinkModal}
          onHide={handleWorkspaceModalClose}
          centered
          size="lg"
          className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal`}
          dialogClassName={`breakout-groups`}
        >
          <Modal.Body className={`p-0 ${Style.workspace_modal_body}`}>
            <div className={`d-flex p-2 ${Style.workspace_modal_close_icon_container}`}>
              <div className="d-flex align-items-center my-1">
                <p className="text-white mb-0 fs-18px" style={{ lineHeight: 1 }}>
                  {activeGroupData.title} ({activeGroupData?.training_track?.length})
                </p>
              </div>
              <img onClick={handleWorkspaceModalClose} src={CancelWhite} alt={`Cancel`} className={`my-auto pointer ${Style.workspace_modal_close_icon}`} />
            </div>
            <div className="d-block p-3 col-12">
              <div className={`d-inline-flex align-items-center w-100 my-2`} style={{ color: "#E0E2E5" }}>
                <LazyLoadImage className={`my-auto pointer ${Style.workspace_modal_idea_icon}`} src={ideaIcon} alt={`idea-icon`} />
                <p className={`mb-0 ml-2 ${Style.workspace_modal_text}`}>The team can access their workspace using below link</p>
              </div>
              <div className={`d-inline-flex px-2 mt-2 mb-3 w-100 align-items-center ${Style.mural_link_container}`}>
                {activeGroupData?.workspace_link?.includes("app.mural") ? (
                  <LazyLoadImage className={`my-auto mr-2 pointer ${Style.mural_icon}`} src={muralIcon} alt={`mural-icon`} />
                ) : (
                  <img className={`my-auto mr-2 pointer`} color="#E0E2E5" src={link} height={20} />
                )}

                <input
                  className={`groups-input w-100 p-0 ${Style.workspace_modal_input}`}
                  type="text"
                  placeholder="Add workspace link for the team"
                  onChange={(e) => {
                    e.preventDefault()
                    saveTitleOnEdit(activeGroupData.id, e.target.value, "workspace_link")
                  }}
                  defaultValue={activeGroupData?.workspace_link}
                  autoFocus
                />
              </div>
              <div className={`d-flex align-items-center`}>
                <Btn onClick={handleWorkspaceModalClose}>Done</Btn>
                {workspace_validation_error && (
                  <div className="d-inline-flex px-2 py-0" style={{ borderRadius: "23px" }}>
                    <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                    <p className={`mb-0 ${Style.workspace_validation_error}`}>{workspace_validation_error}</p>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </DragDropContext>
      {showCommonItemsModal ? (
        <div className={`${Style.common_items_modal_container}`}>
          <div
            className="d-flex w-100"
            style={{ justifyContent: props.leftNavOpen ? "flex-end" : "center", maxHeight: "90vh", overflowY: "auto", maxWidth: "1200px" }}
          >
            <Col xs="12" sm="12" md="8" lg="9">
              <div className={`p-0 ${Style.common_data_container}`}>{commonItemsData()}</div>
            </Col>
          </div>
        </div>
      ) : (
        ""
      )}
      <MuralAuthWarning
        showMuralAuthWarning={showMuralAuthWarning}
        setShowMuralAuthWarning={setShowMuralAuthWarning}
        defaultOpenAfterRedirect={"BreakoutGroup"}
      />
      <Notification data={notificationData} setData={setNotificationData} />
    </React.Fragment>
  )
}

export default BreakoutGroups

BreakoutGroups.propTypes = {
  location: PropTypes.object.isRequired,
  bgModal: PropTypes.bool.isRequired,
  bgModalClick: PropTypes.number.isRequired,
  setLeftNavOpen: PropTypes.func.isRequired,
  setCurrentNavTab: PropTypes.func.isRequired,
  leftNavOpen: PropTypes.bool.isRequired,
  allResources: PropTypes.bool.isRequired,
  openNavWithResourceSection: PropTypes.func.isRequired,
  currentSelectedResourcesType: PropTypes.string.isRequired,
  resetDragState: PropTypes.func.isRequired,
  dragEnded: PropTypes.bool.isRequired,
  draggedItem: PropTypes.string.isRequired,
  activeSection: PropTypes.string.isRequired,
  setActiveSection: PropTypes.func.isRequired,
  setDisableScroll: PropTypes.func.isRequired,
}
