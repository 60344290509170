import React, { useState } from "react"
import PropTypes from "prop-types"
import { copyToClipboard } from "../../../services/common"

const CopyLink = (props) => {
  const [isCopied, setIsCopied] = useState(false)

  function afterCopy() {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1500)
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        copyToClipboard(e, props.text, afterCopy)
      }}
      className={`underline pointer ml-auto flex-shrink-0 pl-2`}
    >
      {isCopied ? "Copied" : "Copy link"}
    </div>
  )
}

CopyLink.propTypes = {
  text: PropTypes.string.isRequired,
}

export default CopyLink
