import React from "react"
import { OverlayTrigger } from "react-bootstrap"

// !definition of component
/**
 *
 * @description --> For displaying engagementscore percentages with different colors
 * @returns EngagementScoresBar component
 */
// ! component

const EngagementScoresBar = ({ engagement_scores, className }) => {
  return (
    <div className={`d-inline-flex align-items-center ${className} h-1rem border-707070 br-3px`}>
      {engagement_scores?.map((type, index) => {
        return (
          <OverlayTrigger
            key={`${type.label}`}
            placement="top"
            overlay={
              <div className="d-flex bg-white  br-2px pr-2 mb-1">
                <div
                  className="align-self-strecth flex-grow-1 w-5px"
                  style={{
                    backgroundColor: type.colorCode,
                    color: type.colorCode,
                  }}
                >
                  .
                </div>
                <div className="d-flex flex-column text-center pl-2">
                  <p className="mb-0 color-000000 fs-10px bold-500 ">{type.label}</p>
                  <p className="mb-0 color-000000 fs-10px bold-500">{type.percentage}%</p>
                </div>
              </div>
            }
          >
            <div className="h-100 pointer" style={{ width: `${type.percentage}%`, backgroundColor: type.colorCode }}></div>
          </OverlayTrigger>
        )
      })}
    </div>
  )
}

export default EngagementScoresBar
