import React from "react"
import Style from "./style.module.scss"
import PropTypes from "prop-types"
// import CancelWhite from "./../images/svgs/close.svg"
import CancelWhite from "../../images/svgs/close.svg"

// !definition of component
/**
 *
 * @param props -->  onClick, className
 * @description -->  onClick=> action to be taken when clicked on button, className=> css className
 * @returns CloseBtn used in Modals
 */
// ! component

const CloseBtn = (props) => {
  return (
    <img
      onClick={props.onClick}
      src={CancelWhite}
      alt={`Cancel`}
      className={`my-auto mr-1 pointer position-absolute object-fit-contain ${Style.cancel_btn} ${props.className}`}
    />
  )
}

CloseBtn.defaultProps = {
  className: "",
  onClick: () => {},
}

CloseBtn.prototype = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default CloseBtn
