import React, { useEffect } from "react"
import { Col } from "react-bootstrap"
import Style from "./style.module.scss"
import PlusLogo from "../../../../images/svgs/plus.svg"
import { useState } from "react"
import Educator from "./Educator"
import PropTypes from "prop-types"
import { courseDetails, classesDetails, educatorDetails } from "../../../Sidebar/constants"
import AccessControl from "./AccessControl"
import check from "../../../../images/svgs/check-white.svg"
import Image from "../../../custom/Image"
import Loader from "../../../custom/Loader"
import { getRequest } from "../../../../services/rest_service"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"

function MembersPermission(props) {
  const showButtonFlag = process.env.GATSBY_REACT_APP_SHOW_BUTTON
  const [teamsData, setTeamsData] = useState({ data: [], has_next: null, count: 0 })
  const [activeComponent, setActiveComponent] = useState(teamsData?.data[0]?.name)
  const [pageno, setPageno] = useState("Members List")
  const [selectedTeam, setSelectedTeam] = useState({})
  const [viewCheckbox, setViewCheckbox] = useState(false)

  const defaultEducatorDetail = {
    avatar: null,
    name: "",
    email: "",
    phone: null,
    designation: "",
    country_code: "91",
    permission: {
      course: [],
      trainings: [],
      access_to_all_courses: true,
      access_to_all_trainings: true,
    },
  }
  const [roleDetails, setRoleDetails] = useState({})

  const [fetchedEducationDetail, setFetchedEducationDetail] = useState({})
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const storedActiveComponent = queryParams.get("role")
  let company_id = location.pathname.split("/").pop()
  useEffect(() => {
    if (teamsData && teamsData.data) {
      const updatedRoleDetails = {}
      teamsData.data.forEach((team) => {
        updatedRoleDetails[team.name] = defaultEducatorDetail
      })
      setRoleDetails(updatedRoleDetails)
    }
  }, [teamsData])
  useEffect(() => {
    getSchoolTeams()
  }, [])
  useEffect(() => {
    if (teamsData?.data.length) {
      if (storedActiveComponent && decodeURIComponent(storedActiveComponent) !== "Access Control & permissions") {
        setActiveComponent(decodeURIComponent(storedActiveComponent))
        let team = teamsData.data.filter((data) => data.name === decodeURIComponent(storedActiveComponent))
        setSelectedTeam(team[0])
        getRoleManagementData(team[0]?.id)
      } else {
        navigate(`?role=${teamsData.data[0]?.name}`)
        setActiveComponent(teamsData.data[0]?.name)
        setSelectedTeam(teamsData.data[0])
        getRoleManagementData(teamsData.data[0]?.id)
      }
    }
  }, [teamsData, localStorage.getItem("activeTab")])
  useEffect(() => {
    setViewCheckbox(true)
  }, [activeComponent])

  const handleClick = (selectedOption) => {
    navigate(`?role=${encodeURIComponent(selectedOption)}`)
    setActiveComponent(selectedOption)
  }

  const handleClickTeam = (teamObject) => {
    navigate(`?role=${encodeURIComponent(teamObject.name)}`)
    setSelectedTeam(teamObject)
    setActiveComponent(teamObject.name)
    getRoleManagementData(teamObject.id)
  }
  const setAllMemberTab = () => {
    navigate(`?role=${encodeURIComponent(selectedTeam.name)}`)
    setActiveComponent(selectedTeam.name)
  }
  const handleSubmit = () => {
    if (activeComponent === "Access Control & permissions" && viewCheckbox === false) {
      setViewCheckbox(true)
    } else {
      localStorage.setItem("activeTab", 0)
      navigate("/app/school-setup")
    }
  }
  const getRoleManagementData = async (role_id) => {
    let res = await getRequest(`/role-management/user-role-management/?role=${role_id}`)
    if (res.success) {
      setFetchedEducationDetail(res.data.results)
    }
  }
  const getSchoolTeams = async () => {
    let res = await getRequest(`/role-management/school-teams/?company=${company_id}`)
    if (res.success) {
      setTeamsData({ data: res.data.results, has_next: res.data.next, count: res.data.count })
    }
  }
  const renderEducatorComponent = () => {
    if (activeComponent === "Access Control & permissions") {
      return <AccessControl selectedTeam={selectedTeam} setSelectedTeam={setSelectedTeam} />
    } else if (roleDetails.hasOwnProperty(activeComponent)) {
      return (
        <Educator
          educatorDetail={roleDetails[activeComponent]}
          setEducatorDetail={(detail) => {
            setRoleDetails({ ...roleDetails, [activeComponent]: detail })
          }}
          fetchedEducationDetail={fetchedEducationDetail}
          selectedTeam={selectedTeam}
          apicall={() => getRoleManagementData(selectedTeam.id)}
          teamapi={getSchoolTeams}
          pageno={pageno}
          setPageno={setPageno}
          defaultEducatorDetail={defaultEducatorDetail}
          role={activeComponent}
        />
      )
    }
    // Return null or an empty component if the active role is not found
    return null
  }
  return (
    <Col lg="12" md="12" sm="12" xs="12" className={`px-0 pt-2 mx-0 br-9px mb-3 bg-2 outer-box-shadow`}>
      <div id="member-box" className={`d-flex flex-column mt-2 mb-3 px-3 ${Style.overflow}`}>
        <div className="d-flex justify-content-end">
          <div className={`h-2_5rem br-9px border border-1 border-E0E2E5 box-shadow-00000029 bg-303C54 d-flex align-items-center`}>
            <span
              className={`${Style.access_control_span} ${
                ["Educators", "Teaching Assistance", "Operation Team", "Customer Success", "Super Admin"].includes(activeComponent)
                  ? "bg-586886 z-index-1"
                  : "bg-303C54 opacity-60"
              } text-left poppins_font fs-16px h-100 br-9px pointer `}
              onClick={setAllMemberTab}
            >
              All Members
            </span>
            <span
              className={`${Style.access_control_btn} ${
                activeComponent === "Access Control & permissions" ? "bg-586886 z-index-1" : "bg-303C54 opacity-60"
              } poppins_font fs-16px  h-100 br-9px `}
              onClick={() => {
                handleClick("Access Control & permissions")
              }}
            >
              Access Control & Permissions
            </span>
          </div>
        </div>
        {!teamsData.data ? (
          <Loader />
        ) : (
          <div className="d-flex flex-row ">
            <Col
              lg="3"
              md="3"
              sm="12"
              xs="12"
              className={`px-0 pb-2 mb-2 mx-0 br-9px d-flex flex-column relative bg-2 outer-box-shadow ${Style.heading_column}`}
            >
              <span
                className={`${Style.role_heading} bg-212C42 poppins-font fs-16px text-left opacity-60 border-0 turient-header-text-color pl-14px`}
              >{`All Teams (${teamsData.count})`}</span>
              {teamsData.data.map((teamObject, index) => {
                return (
                  <button
                    className={`${Style.role_btn}  poppins_font fs-16px text-left turient-header-text-color border-0 pl-14px ${
                      selectedTeam?.id === teamObject.id ? Style.selected : ""
                    }`}
                    onClick={() => {
                      handleClickTeam(teamObject)
                    }}
                    key={`${teamObject.name}-${teamObject.id}`}
                    // disabled={!teamObject.total_users}
                  >
                    {`${teamObject.name}(${teamObject.total_users})`}
                  </button>
                )
              })}

              {showButtonFlag == true && (
                <button className="position-absolute bottom-0 w-100 h-3rem bg-586886 box-shadow-00000029 turient-header-text-color d-flex flex-row align-items-center justify-content-center br-0099px ">
                  <Image src={PlusLogo} alt="Add Teams" className="h-1rem" />
                  <p className="mb-0 ml-2">Add Teams</p>
                </button>
              )}
            </Col>
            <div className={`w-87vw d-flex align-items-start justify-content-center position-relative mb-10px ml-20px pt-20px `}>
              {renderEducatorComponent()}
            </div>
          </div>
        )}
      </div>
      <div className={`d-flex px-3 py-2 ${Style.btn_div}`}>
        {pageno === "Members List" && (
          <button type={"submit"} disabled={props?.loader} className={`border_on_hover fs-14px mr-2 ${Style.btn}`} onClick={handleSubmit}>
            Done
          </button>
        )}
        {props?.loader && <Loader class="ml-3 mr-1" />}
      </div>
    </Col>
  )
}
MembersPermission.propTypes = {
  loader: PropTypes.bool, //loader flag
}

export default MembersPermission
