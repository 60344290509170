import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"

import queryString from "query-string"
import LoginInputField from "./custom/Login-InputField"

import turientimg from "../images/turientimg.png"
import turientLogo from "../images/favicon.png"

import SEO from "../components/seo"
import Image from "./custom/Image"
import authClient from "../services/auth"
import Notification from "./custom/Notification"
import { postRequest } from "../services/rest_service"
import LoginCSS from "../modularscss/Login.module.scss"

export default function ForgotPassword(props) {
  const [email, setEmail] = useState("")
  const [dummyEmail, setDummyEmail] = useState(false)
  const [password, setPassword] = useState("")
  const [width, setWidth] = useState(0)
  const [otp_requested, setOtpRequested] = useState(false)
  const [notificationData, setNotificationData] = useState(null)
  const [showOtp, setShowOtp] = useState(false)

  useEffect(() => {
    if (authClient.isAuthenticated()) {
      navigate("/app/dashboard")
    }
    if (props.location.state != null) {
      let Email = props.location.state.email ?? localStorage.getItem("username")
      setEmail(Email)
      setDummyEmail(Email)
    }
    handleWindowSizeChange()
    window.addEventListener("resize", handleWindowSizeChange)

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    if (email !== "" && isEmailValid(email)) {
      handleRequestOtp(null, email)
    }
  }, [email])

  const handleWindowSizeChange = () => {
    setWidth(window?.innerWidth)
  }

  const handleLogin = (event, email) => {
    event.preventDefault()
    if (email !== "" && isEmailValid(email)) {
      if (password !== "") {
        let loginMsg = authClient.GetKey({ username: email.toLowerCase(), otp: password, location: props.location })
        loginMsg.then((res) => {
          console.log(res)
          setNotificationData(res)
        })
      } else {
        setNotificationData({ type: "error", title: "Please provide valid OTP" })
      }
    } else {
      setNotificationData({ type: "error", title: "Please provide email id" })
    }
  }

  const isEmailValid = (email) => {
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/,
      r = true
    if (email === "") {
      r = false
    } else if (email !== "" && email?.match(mailformat) === null) {
      r = false
    }
    return r
  }

  const handleRequestOtp = async (event, email) => {
    if (event) event.preventDefault()
    if (email !== "" && isEmailValid(email)) {
      const user = { email: email.toLowerCase(), location: props.location, domain: "turient" }
      let location = user.location
      let { redirection_url } = queryString.parse(location.search)
      let userData = user

      let res = await postRequest("/learners/otp/", userData, false)
      if (res.success) {
        if (res.data["error"] !== undefined) {
          setNotificationData({ type: "error", title: "Email address not registered" })
        } else {
          localStorage.setItem("username", email)
          setOtpRequested(true)
        }
      } else {
        setNotificationData({ type: "error", title: res.msg })
      }
      localStorage.setItem("username", email)
    } else {
      setNotificationData({ type: "error", title: "Please enter Email Address" })
    }
  }

  const handleChange = (event) => {
    if (event.target) {
      if (event.target.name === `password`) {
        setPassword(event.target.value)
      } else if (event.target.name === `email`) {
        if (otp_requested) {
          setOtpRequested(false)
        }
        setDummyEmail(event.target.value)
        localStorage.setItem("username", event.target.value)
      }
    }
  }

  return (
    <div className="w-100 h-100">
      <SEO title="Turient | Login with OTP" />
      <Row className="justify-content-md-center mx-auto w-100 pb-4 mt-4 ">
        {width > 980 && (
          <Col lg="3" md="4" sm="4" className="float-left mr-0 bg-121B2B border border-1 border-121B2B pl-20px">
            <Row className="text-center border-bottom-E0E2E5 ">
              <div className="m-0 pt-0 pb-9px mt-0 d-inline-flex align-items-center">
                <Image alt="Turient logo" src={turientLogo} className="d-inline-block align-center w-50px h-50px" />{" "}
                <Link to="/" className="ml-2 turient-body-text-color fs-32px text-decoration-none font-weight-bold">
                  {"Turient"}
                </Link>
              </div>
            </Row>
            <p className="poppins_font text_font_semibold turient-body-text-color fs-36px pt-18px mb-10px bold-500 ">
              Welcome to
              <br /> your Online School
            </p>
            <p className="poppins_font fs-21px">You are just a step away!</p>
            <Row>
              <Image src={turientimg} alt="turientmg" />
            </Row>
          </Col>
        )}
        <Col lg="4" md="6" sm="6" className={`pt-2 pb-2 w-100 ${width > 980 ? "pl-20px" : ""} d-flex bg-212C42`}>
          <Form className={`w-100`} onSubmit={(e) => (otp_requested ? handleLogin(e, dummyEmail) : handleRequestOtp(e, dummyEmail))}>
            <h2 className="poppins_font w-100 pl-2 mt-4 mb-1 pb-2 fs-24px bold-600 color-c6cff4">Login</h2>

            {width < 980 && (
              <div className="signup-display-title">
                <div className="m-0 pt-0 pb-9px mt-0 d-inline-flex align-items-center">
                  <Image alt="Turient logo" src={turientLogo} className="d-inline-block align-center w-50px h-50px" />{" "}
                  <Link to="/" className="ml-2 turient-body-text-color fs-32px text-decoration-none font-weight-bold">
                    {"Turient"}
                  </Link>
                </div>
              </div>
            )}
            <div className={`ml-2 pl-1 mt-3`}>
              <p className="m-0 poppins_font fs-12px color-c6cff4 bold-600">
                {otp_requested ? (
                  <span>
                    We have sent an OTP to your email <u>{dummyEmail}</u>
                  </span>
                ) : (
                  `An OTP will be sent to your registered email`
                )}
              </p>
            </div>
            <Col className={`position-relative mt-20px `}>
              <LoginInputField
                labelClass={`small-font poppins_font fs-18px`}
                labelText={"Your Email"}
                onChange={handleChange}
                name="email"
                value={dummyEmail}
                placeholder={`Enter your email`}
                inputClass={`bg-303C54`}
                type={"email"}
                showHideInput={false}
              />
            </Col>
            <Col className={`mt-4 ${otp_requested ? `` : `d-none`}`}>
              <LoginInputField
                labelClass={`poppins_font fs-18px`}
                labelText={"OTP"}
                onChange={handleChange}
                name="password"
                value={password}
                placeholder={`Enter your OTP`}
                inputClass={`bg-303C54`}
                type={showOtp ? "number" : "password"}
                showHideInput={true}
                showHideInputValue={showOtp}
                setShowHideInputValue={() => setShowOtp((prev) => !prev)}
                showErrorMsg={false}
                maxLength={6}
              />
            </Col>
            <Col className="mt-3 d-flex justify-content-center">
              <button type="submit" size="sm" className={`border_on_hover fs-15px ${LoginCSS.login_button}`}>
                <div className={`m-0 poppins_font px-4 d-flex justify-content-between`}>
                  <div>{otp_requested ? `Verify` : `Request OTP`}</div>
                </div>
              </button>
            </Col>
            <Col sm="12" md="12" lg="12" className="mt-3 d-flex justify-content-center">
              <p className="fs-12px poppins_font mt-1 border-bottom-no-color">
                <Link className="color-c6cff4 underline" to="/app/login" state={{ email: dummyEmail }}>
                  Login using Password
                </Link>
              </p>
            </Col>
            <div className="text-center mt-160px">
              <p className="m-0 poppins_font fs-16px color-c6cff4">Don't have an account?</p>
              <div className="poppins_font fs-16px">
                <Link to="/app/signup" state={{ email: dummyEmail }} className="border-bottom-no-color text-white">
                  Create your account for free
                </Link>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}
