import React from "react"
import PropTypes from "prop-types"

import Col from "react-bootstrap/Col"

import Style from "./style.module.scss"

// !definition of component
/**
 *
 * @param props --> title and children
 * @description --> graph holder for insights page
 * @returns ReportGraph component
 */
// ! component

const ReportGraph = (props) => {
  return (
    <Col lg="4" md="6" sm="12" className="d-inline-block px-2 mb-2">
      <div className={`d-flex flex-column border-EDEEF0 ${props.background ? props.background : `bg-303C54`} br-10px h-100`}>
        <div className={`bg-5773AA text-center bold-500 fs-16px ${Style.graph_heading}`}>
          <p className="p-2 mb-0">{props.title}</p>
        </div>
        <div className={`px-0 m-2 d-flex justify-content-center align-items-center flex-grow-1 ${Style.graph_holder}`}>{props.children}</div>
      </div>
    </Col>
  )
}

ReportGraph.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ReportGraph
