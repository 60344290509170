import React, { useEffect, useState } from "react"
import Style from "./style.module.scss"
import SunEditor from "suneditor-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Rnd } from "react-rnd"
// ! import required images for this component
import CancelIcon from "../../../images/svgs/cancel-white-icon.svg"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
// check where window is defined or not
import { isBrowser } from "../../../services/developer_mode"

// !definition of component
/**
 *
 * @param props --> showRichTextModal, activeInputField, minWidth, minHeight, id, placeholder, maxHeight, maxWidth, buttonList, content, loader, onChange function and handleRichTextEditor function
 * @description -->  showRichTextModal for showing and hiding the modal, Title Name => name of Title to be shown on modal,  set disableDragging to false, to make RND  modal draggable, buttonList is for displaying types of buttons in editor
 * @description -->  showRichTextModal for showing and hiding the modal, Title Name => name of Title to be shown on modal,  set disableDragging to false, to make RND  modal draggable, buttonList is for displaying types of buttons in editor
 * @returns suneditor in RND modal
 */
// ! component
const Editor = (props) => {
  const [draggableHash, setDraggableHash] = useState({
    x: 0,
    y: 0,
    width: 724,
    height: 486,
  })
  useEffect(() => {
    if (isBrowser() && document.getElementsByClassName("parent") && document.getElementsByClassName("parent").length > 0) {
      let x = parseInt(document.getElementsByClassName("parent")[0].offsetWidth - 724 - 80) / 1.2
      let y = parseInt(document.getElementsByClassName("parent")[0].offsetHeight - 486) / 2
      setDraggableHash({ x: parseInt(x), y: parseInt(y), width: 724, height: 486 })
    }
  }, [props?.showRichTextModal])
  const showAutoSaveLoader = () => {
    return (
      <p
        className="mb-0 position-absolute"
        style={{
          fontSize: "14px",
          color: "white",
          bottom: "10px",
          right: "10px",
        }}
      >
        {props.loader ? (
          <svg
            className="animate-spin my-auto ml-3 mr-1"
            style={{
              height: `14px`,
              width: `14px`,
              position: "relative",
              top: "-2px",
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : (
          <FontAwesomeIcon icon={faCheck} className="ml-3 mr-1" />
        )}
        All changes are saved
      </p>
    )
  }
  // const handlePaste = (e, maxCharCount) => {
  //   console.log(e, "sdZhcbv")
  // }

  return (
    <div
      className={`w-100 parent h-100 position-fixed ${Style.rnd_custom_parent}`}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        props.handleRichTextEditor(false)
      }}
    >
      {isBrowser() && document.getElementsByClassName("parent").length > 0 ? (
        <Rnd
          minWidth={props?.minWidth}
          minHeight={props?.minHeight}
          style={{ zIndex: 100 }}
          onClick={(e) => {
            e.stopPropagation()
          }}
          bounds=".parent"
          disableDragging={false}
          dragHandleClassName={"drag"}
          default={{
            x: `50%`,
            y: `50%`,
            width: `${props?.minWidth}`,
            height: `${props?.minHeight}`,
          }}
          position={{ x: draggableHash.x, y: draggableHash.y }}
          size={{ width: draggableHash.width, height: draggableHash.height }}
          onDragStop={(e, d) => {
            setDraggableHash({
              x: d.x,
              y: d.y,
              width: draggableHash.width,
              height: draggableHash.height,
            })
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            setDraggableHash({
              width: ref.style.width,
              height: ref.style.height,
              x: position.x,
              y: position.y,
            })
          }}
        >
          <div
            id="mod"
            className={`box text-dark p-0 w-100 window_screen ${Style.rnd_modal_style}`}
            onScroll={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <div className={`w-100 py-2 drag d-inline-flex align-items-center ${Style.border_bottom}`}>
              <p className={`mb-0 text-center pl-2 w-100 ${Style.header_text}`}>
                Add {props?.activeInputField === "guidelines_for_assessment" ? "Assessment Guidelines" : props?.activeInputField} Below
              </p>
              <img
                src={CancelIcon}
                className="mb-0 pointer ml-auto mr-2"
                style={{ fontSize: "16px", width: "16px" }}
                onClick={() => {
                  props.handleRichTextEditor(false)
                }}
              />
            </div>
            <SunEditor
              setContents={props?.content}
              onChange={props.onChange}
              id={props?.id}
              placeholder={props?.placeholder}
              setDefaultStyle="font-family: helvetica; font-size: 18px;"
              // onPaste={handlePaste}
              setOptions={{
                height: "auto",
                maxHeight: `${props?.editorMaxHeight}`,
                minHeight: `${props?.editorMinHeight}`,
                buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link"]],
              }}
            />
            {showAutoSaveLoader()}
          </div>
        </Rnd>
      ) : (
        ``
      )}
    </div>
  )
}

export default Editor
