import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
import FormGroup from "react-bootstrap/FormGroup"
import FormControl from "react-bootstrap/FormControl"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Pagination from "react-bootstrap/Pagination"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRss, faSearch, faTrash, faPlusCircle, faChevronLeft } from "@fortawesome/free-solid-svg-icons"

import Layout from "../../../components/layout"
import Styles from "../../../modularscss/styles.module.scss"
import SEO from "../../../components/seo"
import authClient from "../../../services/auth"
import formatDate from "../../../services/format_date"
import { isBrowser } from "../../../services/developer_mode"

const FocusArea = (location, data) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [focusAreaLoader, setFocusAreaLoader] = useState(false)
  const [focusAreas, setFocusAreas] = useState([])

  const [previous_url, setPreviousUrl] = useState()
  const [next_url, setNextUrl] = useState()
  const [total_pages, setTotalPages] = useState()

  const [focusArea, setFocusArea] = useState({})

  const [dmodal, setDmodal] = useState(false)
  const [deletingFocuseArea, setDeletingFocuseArea] = useState([])

  const [tag, setTag] = useState({})

  const handleClose = () => {
    setDmodal(false)
  }

  useEffect(() => {
    fetchFocusAreas()
  }, [])

  async function fetchFocusAreas() {
    setFocusAreaLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/focus-areas/?quiz__id=${location.quiz_id}`, { headers: login_credentials }).then((res) => {
      setFocusAreas(res.data.results)
      setNextUrl(res.data.next)
      setPreviousUrl(res.data.previous)
      setTotalPages(parseInt(Number(res.data.count) / 5))
      setFocusAreaLoader(true)
    })
  }

  function displayFocusAreaHeader() {
    let data = focusAreas
    var isLoaded = focusAreaLoader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0])
        let heading = []
        heading.push(<td>&nbsp;</td>)
        heading.push(<td>&nbsp;</td>)
        heading.push(<td>&nbsp;</td>)
        for (let key in keys) {
          if (keys[key].toLowerCase() !== "created" && keys[key].toLowerCase() !== "updated_at") {
            heading.push(<th style={{ textTransform: "uppercase" }}>{keys[key].replace(/_/g, " ")}</th>)
          }
        }
        heading.push(<td>&nbsp;</td>)
        return <tr>{heading}</tr>
      } else {
        return ""
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function showDmodal() {
    return (
      <Modal show={dmodal} onHide={handleClose} className={`mt-5`}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {deletingFocuseArea.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete FocusArea {deletingFocuseArea.name}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              deleteFocusArea(e, deletingFocuseArea)
            }}
          >
            delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function deleteFocusArea(e, datum) {
    e.preventDefault()
    var index = focusAreas.findIndex((res) => res.id === datum.id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/focus-areas/` + datum.id + "/", { headers: DefaultHeader, body: datum })
      .then((res) => {
        focusAreas.splice(index, 1)
        setDmodal(!dmodal)
        setDeletingFocuseArea([])
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function handleChangeTag(event, datum) {
    event.preventDefault()
    tag[datum.id] = event.target.value
  }

  function handleSubmitTag(event, datum) {
    event.preventDefault()
    var formData = new FormData()
    formData.append("name", tag[datum.id])
    if (tag.id) {
    } else {
      var index = focusAreas.findIndex((res) => res.id === datum.id)
      formData.append("add_to_focus_area", datum.id)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/tags/create/", formData, { headers: login_credentials })
        .then((res) => {
          if (res.data) {
            // setTags(tags.concat(res.data))
            let tags_array = []
            tags_array = focusAreas[index].tags
            focusAreas[index].tags = tags_array.concat(res.data)
            setFocusAreas([...focusAreas])
            if (document.getElementsByClassName("clear_value_" + datum.id)[0]) {
              document.getElementsByClassName("clear_value_" + datum.id)[0].value = ""
            }
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function displayFocusAreaData() {
    let data = focusAreas
    var isLoaded = focusAreaLoader
    if (isLoaded) {
      if (data.length > 0) {
        let keys = Object.keys(data[0]),
          parsed_data = []
        parsed_data.push(
          <div key={-1} className={`d-flex p-3`}>
            <div className={`align-self-center`}>
              <p className={`m-0 font-weight-bold`} style={{ color: `#C6CFF4` }}>
                Focus Areas
              </p>
            </div>
          </div>
        )
        data.map((datum, index) => {
          parsed_data.push(
            <div className={`d-flex mb-2 col-4 float-left`} key={index + `_training`}>
              <div className={`w-100 py-2`} style={{ backgroundColor: `#46537E` }}>
                <div className={`px-3 align-self-center`}>
                  <FontAwesomeIcon icon={faRss} style={{ color: `#ebc280` }} size="lg" />
                  <Button
                    variant={`transparent`}
                    className={`p-0 m-0 d-inline-flex shadow-none float-right`}
                    onClick={(e) => {
                      setDmodal(true)
                      setDeletingFocuseArea(datum)
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faTrash} style={{ width: `18px`, height: `18px` }} />{" "}
                  </Button>
                </div>
                <div className={`px-3 align-self-center`}>
                  <p className={`m-0 font-weight-bold text-white`}>
                    <input
                      style={{ background: "rgb(65, 74, 108)", color: "inherit", fontSize: "16px" }}
                      className="blue-bg video-modal px-2 py-1"
                      type="text"
                      name="name"
                      defaultValue={datum.name}
                      onChange={(e) => handleSubmit(e, datum)}
                      id={`focus_area_name_` + datum.id.toString()}
                      placeholder="Enter Focus Area name"
                      required
                    />
                  </p>
                  <p className={`m-0`} style={{ color: `#27E0C3`, fontSize: "12px" }}>
                    <span>Published on </span>{" "}
                    <span>
                      {" "}
                      {`${formatDate.displayDate(datum.created)}-${formatDate.displayMonth(datum.created)}-${formatDate.displayFullYear(datum.created)} `}{" "}
                    </span>{" "}
                  </p>
                  <div className={`align-self-center m-auto`}>
                    <Form
                      className={`form-horizontal d-flex align-items-center m-0`}
                      onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmitTag(e, datum)
                      }}
                    >
                      <div className="float-left">
                        <FormGroup className={`m-0`}>
                          <input
                            style={{ background: "rgb(65, 74, 108)", color: "inherit", fontSize: "16px" }}
                            className={`blue-bg video-modal px-3 ${`clear_value_` + datum.id.toString()}`}
                            type="text"
                            name="name"
                            defaultValue={tag[datum.id] ? tag[datum.id] : ""}
                            onChange={(e) => handleChangeTag(e, datum)}
                            id={`tag_create` + datum.id.toString()}
                            placeholder="Enter tag name"
                            required
                          />
                        </FormGroup>
                      </div>
                      <div className="float-left ml-3">
                        <Button type="submit" className={`shadow-none`} style={{ background: "#485b9e", border: "none", fontSize: "12px" }}>
                          Create tag
                        </Button>
                      </div>
                    </Form>
                    <div>
                      {datum.tags.map((q_tag, tag_index) => (
                        <span className={`${Styles.font_12}`} key={tag_index.toString() + `_` + datum.id.toString() + `_q_tag`}>
                          &nbsp;{q_tag.name} {datum.tags.length - 1 !== tag_index ? "," : ""}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className={`${datum.tags.length > 0 ? `` : `d-none`}`}>
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Add Questions</Tooltip>}>
                      <Link className={` ${Styles.font_12} my-auto ml-1 text-white`} to={`/app/quizzes/${location.quiz_id}/focus-areas/${datum.id}/questions`}>
                        <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                      </Link>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          )
        })
        return (
          <tr>
            <td className={`border-0`}>{parsed_data}</td>
          </tr>
        )
      } else {
        return (
          <tr id="loader">
            <td colSpan="12" className="animated fadeIn pt-3 text-center">
              No Data found...
            </td>
          </tr>
        )
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function showFocusAreas() {
    if (authClient.isAuthenticated()) {
      return (
        <table>
          {/*displayFocusAreaHeader()*/}
          <tbody>{displayFocusAreaData()}</tbody>
        </table>
      )
    } else {
      return ""
    }
  }

  function fetchPage(page) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/focus-areas/?page=${page}&quiz__id=${location.quiz_id}`, { headers: DefaultHeader })
      .then((res) => {
        setFocusAreas(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function fetchNext() {
    axios
      .get(next_url, { headers: DefaultHeader })
      .then((res) => {
        setFocusAreas(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        //console.log(error.response.data)
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function fetchPrevious() {
    axios
      .get(previous_url, { headers: DefaultHeader })
      .then((res) => {
        setFocusAreas(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function renderPageFocusAreas() {
    var rows = []
    if (previous_url) {
      rows.push(<Pagination.Prev className={`m-0 bg-transparent`} onClick={() => fetchPrevious()} />)
    }
    for (var i = 0; i < total_pages; i++) {
      let temp_var = i + 1
      rows.push(
        <Pagination.Item key={i + 1} className={`m-0 bg-transparent`} onClick={() => fetchPage(temp_var)}>
          {i + 1}
        </Pagination.Item>
      )
    }
    if (next_url) {
      rows.push(<Pagination.Next className={`m-0 bg-transparent`} onClick={() => fetchNext()} />)
    }
    return (
      <Pagination className={`bg-card mx-0 my-3`} style={{ width: `fit-content` }}>
        {rows}
      </Pagination>
    )
  }

  function searchFocusAreas(event) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/focus-areas/?search=${event.target.value}&quiz__id=${location.quiz_id}`, { headers: DefaultHeader })
      .then((res) => {
        setFocusAreas(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(parseInt(Number(res.data.count) / 5))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function handleChange(event) {
    event.preventDefault()
    focusArea[event.target.name] = event.target.value
  }

  function handleSubmit(event, datum = undefined) {
    event.preventDefault()
    var formData = new FormData()
    if (datum) {
      formData.append("name", event.target.value)
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/focus-areas/" + datum.id + "/", formData, { headers: login_credentials })
        .then((res) => {})
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      for (var k in focusArea) {
        formData.append(k, focusArea[k])
      }
      formData.append("add_to_quiz", location.quiz_id)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/focus-areas/create/", formData, { headers: login_credentials })
        .then((res) => {
          if (res.data) {
            setFocusAreas(focusAreas.concat(res.data))
            if (document.getElementsByClassName("clear_value")[0]) {
              document.getElementsByClassName("clear_value")[0].value = ""
            }
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  return (
    <div>
      <SEO title="Focus Areas" />
      {showDmodal()}
      <h5 className="border-bottom p-2">
        <Link className={`pointer`} to={`/app/quizzes/edit/${location.quiz_id}`} style={{ color: `#C6CFF4` }}>
          {" "}
          <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
        </Link>
        &nbsp; Focus Areas
        <Link className={`pointer text-white`} to={`/app/quizzes`}>
          <span style={{ fontWeight: "normal" }} className="float-right small">
            {" "}
            Exit{" "}
          </span>
        </Link>
      </h5>
      <Col lg="12" md="12" sm="12" xs="12">
        <Form className="form-horizontal d-flex align-items-center pt-3" onSubmit={handleSubmit}>
          <Col xs="12" sm="6" md="3" className="float-left">
            <FormGroup className={`m-0`}>
              <input
                style={{ background: "rgb(65, 74, 108)", color: "inherit", fontSize: "16px" }}
                className="blue-bg video-modal px-3 clear_value"
                type="text"
                name="name"
                defaultValue={focusArea.name}
                onChange={handleChange}
                id={`focus_area_name_create`}
                placeholder="Enter Focus Area name"
                required
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" md="3" className="float-left">
            <Button type="submit" style={{ background: "#485b9e", border: "none", fontSize: "14px" }}>
              Create Focus Area
            </Button>
          </Col>
        </Form>
      </Col>
      <Col lg="12" md="12" sm="12" xs="12" className={`float-left`}>
        <h4 className={``}>Focus Areas List</h4>
        <div className={`col-12 d-inline-flex`} style={{ backgroundColor: `#414a6c`, borderRadius: "25px" }}>
          <FontAwesomeIcon icon={faSearch} size="lg" style={{ margin: `auto 0px`, color: `#7c84a0` }} />
          <FormControl
            autoFocus
            className="border-0 py-1 px-2 form-control px-0 shadow-none font-14"
            style={{ backgroundColor: `transparent`, color: `#b6bdd4` }}
            placeholder={`search FocusArea`}
            onChange={searchFocusAreas}
          />
        </div>
        {renderPageFocusAreas()}
        <div className={`bg-card ${Styles.b_radius_15} mb-5`} style={{ overflow: "scroll" }}>
          {showFocusAreas()}
        </div>
      </Col>
    </div>
  )
}

export default FocusArea
