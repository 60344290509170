import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import utcDateTime from "../../services/utcdatetime"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faPoll, faCircle, faCommentAlt } from "@fortawesome/free-solid-svg-icons"

import Layout from "../layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

const PublishedResult = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [published_poll_data, setPublishedPollData] = useState({})
  const [published_poll_details, setPublishedPollDetails] = useState([])
  const [published_poll_fetched, setPublishedPollFetched] = useState(false)
  const [quiz, setQuiz] = useState({ no_of_questions: 1, quiz_time: 5, no_of_attempts: "0", quiz_type: "0", quiz_level: "0", partial_points: true })

  useEffect(() => {
    fetchPublishedPollData()
  }, [])

  async function fetchPublishedPollData() {
    axios
      .get(`${process.env.GATSBY_REACT_APP_API_HOST}/resources-publish-data/?id=${location.poll_id}`, { headers: login_credentials })
      .then((res) => {
        fetchTriggeredPollDetail(res.data.results[0], true)
        setPublishedPollData(res.data.results[0])
      })
      .catch((error) => {
        setPublishedPollDetails([])
      })
  }
  async function fetchTriggeredPollDetail(poll, set_poll_fetch) {
    axios
      .get(
        `${process.env.GATSBY_REACT_APP_API_HOST}/poll-questions-results/${location.poll_resource_id}/${location.poll_id}/?triggered_count=${poll.triggered_count}`,
        { headers: login_credentials }
      )
      .then((res) => {
        setPublishedPollDetails(res.data.results)
        if (set_poll_fetch) {
          setPublishedPollFetched(true)
        }
      })
      .catch((error) => {
        setPublishedPollData({})
      })
  }

  const showPublishedPolls = () => {
    if (published_poll_fetched) {
      return (
        <div className={`mb-4 float-left w-100 mt-2`}>
          <Col
            sm="7"
            md="7"
            lg="7"
            className="float-left px-0"
            style={{ background: "rgb(30, 38, 67)", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", maxHeight: "80vh", overflowY: "auto" }}
          >
            {showPublishedPollName()}
          </Col>
          <Col
            sm="5"
            md="5"
            lg="5"
            className="float-left px-0"
            style={{ borderTopRightRadius: "10px", borderBottomRightRadius: "10px", borderLeft: "1px solid rgb(0, 0, 0)" }}
          >
            <Col sm="12" md="12" lg="12" className="float-left px-0 pb-3" style={{ background: "rgb(30, 38, 67)" }}>
              {showPublishedPollDetail()}
            </Col>
            {/* <Col sm="12" md="12" lg="12" className="float-left mt-2 pr-0">
                            <button onClick={(e) => {e.preventDefault();updateTriggeredPollDetail();handleSave(true)}} className={`p-3 ${Styles.primary_button} w-100`} style={{border: 'none', borderRadius: '10px', background: 'rgb(78, 87, 116)', color: 'rgb(198, 207, 244)', fontWeight: '500'}}>Publish again</button>
                        </Col> */}
          </Col>
        </div>
      )
    } else {
      return (
        <div className={`mb-4 float-left w-100 mt-2 text-center`} style={{ background: "rgb(30, 38, 67)", borderRadius: "10px" }}>
          <p className="mb-0 py-3">Loading...</p>
        </div>
      )
    }
  }
  const showPublishedPollName = () => {
    return (
      <div className={`float-left w-100`}>
        <div
          className={`float-left w-100 p-3 mb-2 position-sticky`}
          style={{ borderRadius: "10px 0 10px 10px", background: "rgb(52, 61, 91)", color: "rgb(198, 207, 244)", top: 0, zIndex: 2 }}
        >
          <h6 className="align-items-center d-flex justify-content-between mb-0" style={{ fontSize: "16px" }}>
            {published_poll_data.resource_data?.display_name ?? published_poll_data?.resource_data?.name}
            <div className="d-flex align-items-center mb-0 font-weight-normal">
              <FontAwesomeIcon icon={faCircle} className="mr-2" style={{ color: published_poll_data.end_activity ? "rgb(144, 71, 68)" : "rgb(14, 149, 92)" }} />
              {published_poll_data.end_activity ? `Poll Ended` : `Ongoing Poll`}
            </div>
          </h6>
        </div>
        {showPublishedPollQuestionDetails()}
      </div>
    )
  }
  const showPublishedPollQuestionDetails = () => {
    if (published_poll_details.length > 0) {
      return published_poll_details.map((poll_data, poll_index) => {
        return (
          <div key={`${poll_data.display_name}-${poll_index}`} className={`float-left w-100 p-3`}>
            <h6 style={{ color: "rgb(198, 207, 244)", fontSize: "16px" }}>{`${poll_index + 1}. ${poll_data.display_name}`}</h6>
            {showPublishedPollReport(poll_data.options)}
          </div>
        )
      })
    } else {
      return null
    }
  }
  const showPublishedPollReport = (poll_report) => {
    return poll_report.map((report, index) => {
      return (
        <div key={`${index}-${report.display_name.toLowerCase().split(" ").join("-")}`} className="float-left w-100" style={{ fontSize: "14px" }}>
          <div className="progress position-relative" style={{ background: "rgb(40, 49, 78)", height: "45px", marginBottom: "10px" }}>
            <div
              className="progress-bar d-flex justify-content-between"
              role="progressbar"
              style={{ width: `${report.percentage}%`, borderRadius: "5px", background: "rgb(95, 99, 113)" }}
              aria-valuenow={`${report.percentage}`}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div className="position-absolute" style={{ left: "1rem", top: "22.5px" }}>
                {report.display_name}
              </div>
              <div className="position-absolute" style={{ right: "1rem", top: "22.5px", color: "rgb(198, 207, 244)" }}>{`${parseInt(report.percentage)}%`}</div>
            </div>
          </div>
        </div>
      )
    })
  }
  function handleSave(show_results, end_activity = false) {
    var formData = new FormData()
    formData.append("resource_id", location.poll_resource_id)
    formData.append("resource_type", published_poll_data.resource_type)
    formData.append("end_activity", end_activity)
    formData.append("show_results", show_results)
    if (show_results) {
      formData.append("created", new Date().toISOString())
    }
    formData.append("is_triggered", true)
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/resources/` + location.poll_id + "/", formData, { headers: DefaultHeader })
      .then((res) => {
        setTimeout(function () {
          let poll_detail = { ...published_poll_data }
          poll_detail["is_triggered"] = true
          poll_detail["show_results"] = show_results
          poll_detail["end_activity"] = end_activity
          setPublishedPollData(poll_detail)
        }, 300)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }
  async function triggerResource() {
    setTimeout(function () {
      axios
        .get(
          `${process.env.GATSBY_REACT_APP_API_HOST}/basic-resources-list/?paginate_by=1&is_triggered=true&is_poll=false&template_id=${location.template_id}`,
          { headers: login_credentials }
        )
        .then((res) => {
          // console.log('triggered')
        })
        .catch((error) => {})
    }, 300)
  }
  const showPublishedPollDetail = () => {
    return (
      <div className={`float-left w-100`} style={{ color: "rgb(198, 207, 244)" }}>
        <div className="float-left w-100 pt-2 pb-3 px-3 text-right">
          <Link to={`/app/polls/${location.resource_id}/edit/`} style={{ color: "rgb(198, 207, 244)" }}>
            <FontAwesomeIcon icon={faEdit} size="sm" />
          </Link>
        </div>
        <div className="float-left w-100 pt-3 d-flex justify-content-center">
          <div className="float-left w-75 px-3" style={{ fontSize: "14px" }}>
            <div className="float-left w-100 mb-3">
              <Col sm="7" md="7" lg="6" className="float-left pl-3 pr-0">
                Number of questions
              </Col>
              <Col sm="1" md="1" lg="1" className="float-left px-0">
                :
              </Col>
              <Col sm="4" md="4" lg="5" className="float-left px-0">
                {published_poll_details.length}
              </Col>
            </div>
            <div className="float-left w-100 mb-3">
              <Col sm="7" md="7" lg="6" className="float-left pl-3 pr-0">
                Type
              </Col>
              <Col sm="1" md="1" lg="1" className="float-left px-0">
                :
              </Col>
              <Col sm="4" md="4" lg="5" className="float-left px-0">
                General
              </Col>
            </div>
            <div className="float-left w-100 mb-3">
              <Col sm="7" md="7" lg="6" className="float-left pl-3 pr-0">
                Poll ended
              </Col>
              <Col sm="1" md="1" lg="1" className="float-left px-0">
                :
              </Col>
              <Col sm="4" md="4" lg="5" className="float-left px-0">{`${pollTimeDifference(published_poll_data.created)} ago`}</Col>
            </div>
          </div>
        </div>
        <Col sm="12" md="12" lg="12" className="float-left d-flex justify-content-around pt-5">
          <div className="float-left w-75 text-center">
            {/*<button className={`px-3 ${Styles.primary_button} py-1 mr-2 mb-2`} style={{border: 'none', borderRadius: '5px', background: 'rgb(78, 87, 116)', color: 'rgb(198, 207, 244)', fontSize: '14px'}}><FontAwesomeIcon className="mr-2" icon={faCommentAlt} />Share on chat</button>*/}
            {published_poll_data.end_activity === false ? (
              <button
                onClick={(e) => {
                  e.preventDefault()
                  fetchTriggeredPollDetail(published_poll_data)
                  handleSave(false, true)
                  triggerResource()
                }}
                className={`px-4 ${Styles.primary_button} py-1 mb-2`}
                style={{ background: "rgb(78, 87, 116)", borderRadius: "5px", border: "none", color: "rgb(198, 207, 244)", fontSize: "14px" }}
              >
                End Poll
              </button>
            ) : null}
            {published_poll_data.end_activity === true && published_poll_data.show_results === false ? (
              <button
                onClick={(e) => {
                  e.preventDefault()
                  fetchTriggeredPollDetail(published_poll_data)
                  handleSave(true, true)
                  triggerResource()
                }}
                className={`px-3 mb-2 ${Styles.primary_button} py-1`}
                style={{ border: "none", borderRadius: "5px", background: "rgb(78, 87, 116)", color: "rgb(198, 207, 244)", fontSize: "14px" }}
              >
                <FontAwesomeIcon className="mr-2" icon={faPoll} />
                Publish Results
              </button>
            ) : null}
          </div>
        </Col>
      </div>
    )
  }
  const pollTimeDifference = (timestamp) => {
    let resulted_min = 0,
      resulted_hr = 0,
      resulted_day = 0,
      resulted_time = ""
    let current_time = utcDateTime.utcDateTime("today", "time_in_sec")
    let poll_created_time = utcDateTime.utcDateTime(timestamp, "time_in_sec")
    let time_difference = Math.floor((current_time - poll_created_time) / 1000)
    resulted_day = Math.floor(time_difference / (60 * 60 * 24))
    resulted_hr = Math.floor((time_difference - resulted_day * 24 * 60 * 60) / (60 * 60))
    resulted_min = Math.floor((time_difference - (resulted_day * 24 * 60 * 60 + resulted_hr * 60 * 60)) / 60)
    if (resulted_day > 0) {
      resulted_day = resulted_day > 1 ? `${resulted_day}days ` : `${resulted_day}day `
    } else {
      resulted_day = ""
    }
    if (resulted_hr > 0) {
      resulted_hr = resulted_hr > 1 ? `${resulted_hr}hrs ` : `${resulted_hr}hr `
    } else {
      resulted_hr = ""
    }
    if (resulted_min > 0) {
      resulted_min = resulted_min > 1 ? `${resulted_min}mins` : `${resulted_min}min`
    } else {
      resulted_min = ""
    }
    if (resulted_day.length > 0) {
      resulted_time = resulted_day
    } else {
      resulted_time = `${resulted_hr}${resulted_min}`
    }
    if (resulted_time.length === 0) {
      resulted_time = "Few seconds"
    }
    return resulted_time
  }

  return (
    <div>
      <SEO title="Published Poll Result" />
      <Col lg="12" md="12" sm="12" className="float-left px-4">
        <h4 className={`mt-3 d-flex align-items-end`} style={{ color: "rgb(198, 207, 244)", fontSize: "22px" }}>
          <FontAwesomeIcon icon={faPoll} className="mr-2" style={{ color: "rgb(87, 97, 134)", width: "38px", height: "38px" }} />
          Published Poll Result
        </h4>
        {showPublishedPolls()}
      </Col>
    </div>
  )
}

export default PublishedResult
