import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"
import authClient from "../../../../services/auth"
import Image from "../../../../components/image"
import { isBrowser } from "../../../../services/developer_mode"

const CreateModule = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [module, setModule] = useState({ add_to_stage: location.stage_id })
  const [stage, setStage] = useState({})

  useEffect(() => {
    fetchStage()
  }, [])

  async function fetchStage() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/stages/${location.stage_id}/`, { headers: login_credentials }).then((res) => {
      setStage(res.data)
      // setLoader(true)
    })
  }

  function handleChange(event) {
    event.preventDefault()
    module[event.target.name] = event.target.value
  }
  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in module) {
      formData.append(k, module[k])
    }
    if (module.id !== undefined) {
    } else {
      var module_id = module.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/modules/create/", formData, { headers: login_credentials })
        .then((res) => {
          navigate(`/app/default-course-templates/${location.template_id}/stages/${stage.id}/modules/${res.data.id}/topics/create`)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  return (
    <div>
      <SEO title="Add module" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>{stage.name}</h4>
          <p> Courses / Online / Course Content / Modules </p>
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col lg="12" md="12" sm="12" xs="12" className="float-left p-3" style={{ background: "#2E3346 0% 0% no-repeat padding-box" }}>
              <Col lg="6" md="6" sm="6" xs="12" className="float-left">
                <div className="text-input-css light-text">
                  <input
                    className="light-text no-style-input"
                    type="text"
                    name="name"
                    defaultValue={module.name}
                    onChange={handleChange}
                    id="name"
                    placeholder="Module name"
                    required
                  />
                </div>
                <div className="text-input-css light-text mt-1">
                  <input
                    className="light-text no-style-input"
                    type="text"
                    name="sequence"
                    defaultValue={module.sequence}
                    onChange={handleChange}
                    id="sequence"
                    placeholder="Enter Sequence"
                    required
                  />
                </div>
                <div className="text-input-css light-text mt-1">
                  <input
                    className="light-text no-style-input"
                    type="text"
                    name="points"
                    defaultValue={module.points}
                    onChange={handleChange}
                    id="points"
                    placeholder="Enter module points"
                    required
                  />
                </div>
                <div className="text-input-css light-text mt-1">
                  <FormGroup>
                    <p className="light-text" htmlFor="description">
                      Module Description
                    </p>
                    <textarea
                      style={{ background: "inherit" }}
                      className="blue-bg light-text"
                      type="text"
                      name="description"
                      defaultValue={module.description}
                      onChange={handleChange}
                      id="description"
                      placeholder="Please add a description about the module"
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" className="float-left">
                <Col style={{ borderRadius: "25px" }} xs="12" sm="12" md="12" className="text-center theme-color border p-5">
                  <Image style={{ width: "126px", height: "104px" }} alt="Eskola logo" src="stages.png" className="d-inline-block align-center mobile-hide" />
                  <br />
                  <p style={{ fontSize: "10px" }}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
                    diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
                    dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed
                  </p>
                  <Col xs="12" sm="6" md="12">
                    <Button type="submit" className="mt-4" variant="primary">
                      <span style={{ fontSize: "24px" }}>+</span> Add Topic
                    </Button>
                  </Col>
                </Col>
              </Col>
            </Col>
            <Button type="submit" color="warning">
              Save
            </Button>{" "}
          </Form>
          <Button onClick={() => navigate(`/app/default-course-templates/${location.template_id}/stages/view/`)} variant="danger">
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CreateModule
