import React, { useContext, useEffect, useState } from "react"
import { getRequest, postRequest } from "../../services/rest_service"
import { navigate, Link } from "gatsby"
import config from "./config"
import MuralAuthorization from "./authentication"
import Layout from "../../components/layout"

var axios = require("axios")

const MuralSuccess = (props) => {
  return (
    <div>
      <div>
        Success
        <MuralAuthorization />
        <p>
          <a href="/app/mural/mural">Mural List</a>
        </p>
      </div>
    </div>
  )
}

export default MuralSuccess
