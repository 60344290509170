import React from "react"
import { Col, Dropdown } from "react-bootstrap"
import CustomInputField from "../../../../../custom/CustomInputFiled"
import CustomTextArea from "../../../../../custom/CustomTextArea"
import Image from "../../../../../custom/Image"
import Style from "./style.module.scss"
import DeleteIcon from "../../../../../../images/svgs/delete.svg"
import StylesM from "../../../../../../components/upload-resource.module.scss"
import StylesA from "../../../../../../modularscss/upload.module.scss"
import UploadVideo from "/src/images/svgs/video-white-icon.svg"
import Elipsis from "/src/images/svgs/threedots.svg"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"
import UploadVideoModal from "./UploadVideoModal"
import CropImage from "../../../../../crop-image/crop-image"
import SignatureIcon from "../../../../../../images/svgs/SignatureIcon.svg"
import VideoIcon from "../../../../../../images/svgs/videoIcon.svg"
import { LazyLoadImage } from "react-lazy-load-image-component"
import warningIcon from "../../../../../../images/svgs/warning_icon.svg"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"

const FormInput = (props) => {
  const {
    handleChange,
    handlePhoneInput,
    educatorDetail,
    handleEmailValidation,
    socialLinks,
    socialLinksError,
    updateSocialLinks,
    marginSocial,
    showUploadVideo,
    onDragEnter,
    onDragOver,
    onDragLeave,
    onDrop,
    videoFiles,
    deleteVideo,
    removeVideo,
    showVideoModal,
    setShowVideoModal,
    setVideoFiles,
    videos_data,
    setVideosData,
    showUploadSign,
    signatureModalShow,
    setSignatureModalShow,
    getSignatureCropDataProps,
    signatureFileName,
    signatureCropData,
    deleteSignature,
    handleShowVideo,
    handleShowSign,
  } = props
  return (
    <>
      <Col lg="6" md="6" sm="12" xs="12" className="px-1">
        <CustomInputField type="text" name="name" onChange={handleChange} label={"Full Name"} value={educatorDetail.name} required />
      </Col>
      <Col lg="6" md="6" sm="12" xs="12" className="px-1">
        <CustomInputField type="text" name="display_name" onChange={handleChange} label={"Display Name"} value={educatorDetail.display_name} required />
      </Col>
      <Col lg="6" md="6" sm="12" xs="12" className="px-1">
        <CustomInputField type="text" name="designation" onChange={handleChange} label={"Designation"} value={educatorDetail.designation} required />
      </Col>
      <Col lg="6" md="6" sm="12" xs="12" className="px-1 opacity-60">
        <CustomInputField type="text" name="role" label={"Role"} value={"Educator"} disabled required />
      </Col>
      <Col lg="6" md="6" sm="12" xs="12" className="px-1 poppins_font opacity-60">
        <CustomInputField type="text" name="email" customValidation={handleEmailValidation} label={"Email"} value={educatorDetail.email} required disabled />
      </Col>
      <Col lg="6" md="6" sm="12" xs="12" className="px-1 poppins_font">
        <div className="bg-303C54 br-9px p-2 fs-14px mb-2 h-80 border_on_hover">
          <span className="pl-2">Phone Number</span>
          <PhoneInput
            className={` w-100  border-0 ${Style.phoneInputfieldStyle} px-2 py-1 `}
            name="phone"
            defaultCountry="IN"
            type="text"
            maxLength={17}
            value={`${educatorDetail?.country_code ? "+" + educatorDetail?.country_code : "+91"} ${educatorDetail?.phone}`}
            onChange={handlePhoneInput}
            placeholder={`Enter phone Number`}
            autoComplete="off"
          />
        </div>
      </Col>
      <Col lg="6" md="6" sm="12" xs="12" className="px-1">
        <CustomTextArea name="mini_detail" label="Bio" onChange={handleChange} defaultValue={educatorDetail.mini_detail} required />
      </Col>
      <Col lg="6" md="6" sm="12" xs="12" className="px-1">
        <CustomTextArea name="expertise" label="Areas of expertise" onChange={handleChange} defaultValue={educatorDetail.expertise} required />
      </Col>
      <Col lg="12" md="12" sm="12" xs="12" className="px-1">
        <CustomInputField
          type="text"
          name="personal_quote"
          onChange={handleChange}
          label={"Add a personal quote"}
          icon={{ name: faQuoteLeft }}
          value={educatorDetail.personal_quote}
        />
      </Col>

      <div className={`d-flex w-100 flex-column mt-3`}>
        <div className={`fs-16px mb-1 icon-color`}>Connect to Social media</div>
        <div className={`text-center d-flex flex-wrap`}>
          {socialLinks.map((media, index) =>
            media.show === true ? (
              <div key={index} className="px-1 w-100 ">
                <div
                  className={`${
                    !socialLinksError[`${media.name}_url`] ? `mb-2 ${Style.hover_border} border_on_hover` : "mb-1 error-input"
                  } px-1 inner-element-box-shadow br-9px bg-303C54 `}
                >
                  <div className="p-2 d-flex w-100 border border-1 border-transparent bg-transparent br-0">
                    <div
                      className={`mr-2 p-1 circle w-40px h-40px`}
                      onClick={(e) => {
                        updateSocialLinks(index, false)
                      }}
                      style={{
                        backgroundColor: media.color,
                      }}
                    >
                      <Image className={`mb-0 pointer`} src={media.icon} alt={`${media.name}_icon`} />
                    </div>
                    <div className={`ml-2 flex-grow-1`}>
                      <input
                        name={`${media.key}_url`}
                        placeholder={`Add your ${media.name} link`}
                        onChange={handleChange}
                        type="text"
                        defaultValue={educatorDetail?.social_media?.[`${media.key}`]}
                        className={`mt-2 turient-header-text-color w-100 fs-16px bg-transparent border-transparent`}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                {!socialLinksError[`${media.name}_url`] ? null : (
                  <Col xs="12" sm="12" md="12" lg="12" className={`mb-3 text-left px-0`}>
                    <div className="d-flex align-items-baseline px-1 py-0 br-23px">
                      <Image className="w-1rem mr-1 " src={warningIcon} alt="warninig" />
                      <p className="mb-0 fs-14px social-link-error">{socialLinksError[`${media.name}_url_error`]}</p>
                    </div>
                  </Col>
                )}
              </div>
            ) : (
              <div
                key={media.name}
                onClick={(e) => {
                  updateSocialLinks(index, true)
                }}
                className={`p-1 ${marginSocial ? "ml-3" : index !== 0 ? "ml-3" : ""} mb-2 circle w-40px h-40px`}
                style={{
                  backgroundColor: media.color,
                }}
              >
                <Image className={`mb-0 pointer`} src={media.icon} alt={`${media.name}_icon`} />
              </div>
            )
          )}
        </div>
      </div>
      <div className={`d-flex flex-column w-100 mt-4`}>
        {!showUploadVideo ? null : (
          <React.Fragment>
            <span className={`fs-14px mb-3 icon-color`}>Your Videos</span>
            <div
              className={`bg-303C54 ${Style.hover_border} icon-color py-4 inner-element-box-shadow bold-500 fs-14px text-center mb-2 br-9px border_on_hover 
                             py-4 inner-element-box-shadow bold-500 fs-14px text-center mb-2 br-9px`}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDrop={onDrop}
            >
              <input
                className={`d-none`}
                type={"file"}
                multiple={true}
                accept="video/*"
                onChange={(e) => {
                  e.preventDefault()
                }}
                defaultValue={videoFiles}
              />
              {videoFiles.map((fileObj, index) => {
                return (
                  <div
                    className="d-flex flex-row align-items-center justify-content-between text-left p-2 col-12 d-inline-flex w-98 bg-42506C m-2 br-9px"
                    key={fileObj.name + "-" + index}
                  >
                    <div className="d-flex flex-row ">
                      <Image src={fileObj.thumbnail_url || UploadVideo} />
                      <p className="fs-14px turient-header-text-color pl-2 mb-0">{fileObj.name}</p>
                    </div>
                    <Dropdown className="breakout-groups pointer float-right mr-3">
                      <Dropdown.Toggle className="border-none p-0 shadow-none bg-transparent" id="dropdown-basic">
                        <div>
                          <Image src={Elipsis} alt="option" className="turient-header-text-color h-1rem object-fit-contain" />
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className={`br-9px breakout-groups min-w-4rem ${StylesA.min_fit_width}`}>
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault()
                            deleteVideo(fileObj)
                          }}
                          className={`${StylesM.dropitem} text-white fs-14px px-2 py-1 d-inline-flex`}
                        >
                          <Image src={DeleteIcon} alt={`topic-delete`} className={`my-auto object-fit-contain h-13px pr-2`} />
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )
              })}
              {educatorDetail &&
                educatorDetail?.trainer_video &&
                educatorDetail?.trainer_video.map((fileObj, index) => {
                  return (
                    <div
                      className="d-flex flex-row align-items-center justify-content-between text-left p-2 col-12 d-inline-flex w-98 bg-42506C m-2 br-9px pointer"
                      key={fileObj.name + "_" + index}
                    >
                      <div className="d-flex flex-row ">
                        <Image src={UploadVideo} alt={fileObj.name} />
                        <p className="fs-14px turient-header-text-color pl-2 mb-0">{fileObj.name}</p>
                      </div>
                      <Dropdown className="breakout-groups pointer float-right mr-3">
                        <Dropdown.Toggle className="border-none p-0 shadow-none bg-transparent" id="dropdown-basic">
                          <div>
                            <Image src={Elipsis} alt="option" className="turient-header-text-color h-1rem object-fit-contain" />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={`br-9px breakout-groups min-w-4rem ${StylesA.min_fit_width}`}>
                          <Dropdown.Item
                            onClick={(e) => {
                              e.preventDefault()
                              removeVideo(fileObj)
                            }}
                            className={`${StylesM.dropitem} text-white fs-14px px-2 py-1 d-inline-flex`}
                          >
                            <Image src={DeleteIcon} alt={`topic-delete`} className={`my-auto object-fit-contain h-13px pr-2`} />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )
                })}

              <span className="d-flex flex-row align-items-center justify-content-start pl-2">
                Drag and drop Video or
                <p className="turient-header-text-color pointer underline mb-0 pl-2" onClick={() => setShowVideoModal(true)}>
                  Add external Links
                </p>
              </span>
            </div>
          </React.Fragment>
        )}
        {showVideoModal && (
          <UploadVideoModal
            showVideoModal={showVideoModal}
            setShowVideoModal={setShowVideoModal}
            files={videoFiles}
            setFiles={setVideoFiles}
            videos_data={videos_data}
            setVideosData={setVideosData}
          />
        )}
        {!showUploadSign ? null : (
          <React.Fragment>
            <CropImage
              show={signatureModalShow}
              onHide={() => setSignatureModalShow(false)}
              getCropDataProps={getSignatureCropDataProps}
              fileName={signatureFileName}
              avatar={
                signatureCropData && signatureCropData !== "#"
                  ? signatureCropData
                  : educatorDetail["trainer_signature"] &&
                    educatorDetail["trainer_signature"].size !== 0 &&
                    educatorDetail["trainer_signature"]?.includes("https://turient-static-bucket")
                  ? educatorDetail["trainer_signature"]
                  : null
              }
              type="signature"
            />
            <span className={`fs-14px mb-3 icon-color`}>Your Signature</span>
            <div className={`bg-303C54 icon-color ${Style.hover_border} py-4 inner-element-box-shadow bold-500 fs-14px text-center mb-2 br-9px px-3`}>
              {signatureCropData && signatureCropData !== "#" ? (
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <LazyLoadImage
                    onClick={(e) => {
                      e.preventDefault()
                      setSignatureModalShow(true)
                    }}
                    onChange={handleChange}
                    className={`pointer float-left mb-0 ${Style.educatorImg}`}
                    src={signatureCropData || educatorDetail.trainer_signature}
                    alt="Educator signature pic"
                  />
                  <Dropdown className="breakout-groups pointer float-right mr-3">
                    <Dropdown.Toggle className="border-none p-0 shadow-none bg-transparent" id="dropdown-basic">
                      <div>
                        <Image src={Elipsis} alt="option" className="turient-header-text-color h-1rem object-fit-contain" />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`br-9px breakout-groups min-w-4rem ${StylesA.min_fit_width}`}>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault()
                          deleteSignature()
                        }}
                        className={`${StylesM.dropitem} text-white fs-14px px-2 py-1 d-inline-flex`}
                      >
                        <Image src={DeleteIcon} alt={`topic-delete`} className={`my-auto object-fit-contain h-13px pr-2`} />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <React.Fragment>
                  <span
                    className="mb-0 pointer underline"
                    onClick={(e) => {
                      e.preventDefault()
                      setSignatureModalShow(true)
                    }}
                  >
                    Browse
                  </span>
                  <div className={`fs-12px`}>Make sure your signature is in .png Format</div>
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
      <div className={`w-100 text-right`}>
        <div className="pt-1">
          {!showUploadVideo && (
            <button
              onClick={handleShowVideo}
              className={`border_on_hover br-23px fs-14px text-color-1 px-3 py-1 mr-2 inner-element-box-shadow bg-586886 ${Style.hover_border} `}
            >
              <span>
                <Image src={VideoIcon} alt="Video Image" className="mr-2 w-1rem" />
              </span>
              Add Videos that describes you
            </button>
          )}

          {!showUploadSign && (
            <button
              onClick={handleShowSign}
              className={`border_on_hover br-23px fs-14px text-color-1 px-3 py-1 inner-element-box-shadow bg-586886 ${Style.hover_border} `}
            >
              <span>
                <Image src={SignatureIcon} alt="Signature Image" className="mr-2 w-1rem" />
              </span>
              Upload your Signature
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default FormInput
