import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import FormControl from "react-bootstrap/FormControl"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faPoll, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import Modal from "react-bootstrap/Modal"

import Layout from "../layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../seo"
import authClient from "../../services/auth"
import DeleteModal from "../custom/DeleteModal"
import Notification from "../custom/Notification"
import { isBrowser } from "../../services/developer_mode"

import TrashCan from "../../images/svgs/trash-can-transparent.svg"
import CancelWhite from "../../images/svgs/close.svg"
import StylesD from "../../modularscss/delete.module.scss"

const CaseStudy = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [case_study_loader, setCaseStudyLoader] = useState(false)
  const [case_study, setCaseStudy] = useState([])
  const [search, setSearch] = useState(false)
  const [next_url, setNextUrl] = useState(null)
  const [next_case_study_fetched, setNextCaseStudyFetched] = useState(false)
  const [quiz, setQuiz] = useState({ no_of_questions: 1, quiz_time: 5, no_of_attempts: "0", quiz_type: "0", quiz_level: "0", partial_points: true })

  const [notificationData, setNotificationData] = useState(null)

  const [deleteObjectModal, setDeleteObjectModal] = useState([])
  const handleDeleteObjectModal = () => {
    setDeleteObjectModal([])
  }

  useEffect(() => {
    fetchCaseStudy()
  }, [])
  useEffect(() => {
    if (!next_case_study_fetched && next_url !== null) {
      fetchNextCaseStudy()
    }
  }, [next_case_study_fetched])

  async function fetchCaseStudy() {
    setCaseStudyLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/case-study/?paginate_by=6`, { headers: login_credentials })
      .then((res) => {
        setCaseStudy(res.data.results)
        setNextUrl(res.data.next)
        setCaseStudyLoader(true)
        setNextCaseStudyFetched(true)
      })
      .catch((error) => {
        setCaseStudyLoader(false)
        setCaseStudy([])
      })
  }
  async function fetchNextCaseStudy() {
    axios
      .get(`${next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL)}`, { headers: login_credentials })
      .then((res) => {
        let next_case_study_data = case_study
        if (res.data.results.length > 0) {
          for (let index = 0; index < res.data.results.length; index++) {
            next_case_study_data.push(res.data.results[index])
          }
          setCaseStudy(next_case_study_data)
        }
        setNextUrl(res.data.next)
        setNextCaseStudyFetched(true)
      })
      .catch((error) => {
        setNextCaseStudyFetched(false)
        setNextUrl(null)
      })
  }

  function showCaseStudy() {
    return (
      <div className={`mb-4 float-left w-100 py-4 mt-2`} style={{ background: "rgb(30, 38, 67)", borderRadius: "10px" }}>
        <div className={`d-flex mx-4 px-3 float-left`} style={{ borderRadius: `5px`, background: "rgb(18, 23, 44)", width: "95%" }}>
          <FontAwesomeIcon className={`text-white my-auto`} icon={faSearch} size="sm" />
          <FormControl
            autoFocus
            className="border-0 py-1 px-2 form-control px-0 shadow-none font-14"
            style={{ backgroundColor: `transparent`, color: `#b6bdd4` }}
            placeholder={`Search Case Study`}
            onChange={searchCaseStudy}
          />
        </div>
        <Row className="float-left mx-auto w-100 mt-4 pt-3 px-2">{displayCaseStudyData()}</Row>
      </div>
    )
  }
  function displayCaseStudyData() {
    if (case_study_loader) {
      if (case_study.length > 0) {
        return case_study.map((case_study_item, index) => {
          return (
            <Col sm="6" md="6" lg="4" key={index} className="float-left px-3 mb-4 mt-2" style={{ color: "rgb(198, 207, 244)" }}>
              <Col sm="12" md="12" lg="12" className="float-left py-3 px-4 h-100" style={{ background: "rgb(18, 23, 44)", borderRadius: "10px" }}>
                <div className="float-left w-100" style={{ fontSize: "14px" }}>
                  <p className="mb-4 pl-2" style={{ fontWeight: "500", fontSize: "16px" }}>
                    {case_study_item.name === "sample quiz console" ? "" : case_study_item.name}
                  </p>
                  <div className="float-left w-100 mb-2">
                    <Col sm="6" md="6" lg="6" className="float-left pl-2 pr-0">
                      Passing marks
                    </Col>
                    <Col sm="1" md="1" lg="1" className="float-left px-0">
                      :
                    </Col>
                    <Col sm="5" md="5" lg="5" className="float-left px-0">
                      {case_study_item.passing_marks}
                    </Col>
                  </div>
                  <div className="float-left w-100 mb-2">
                    <Col sm="6" md="6" lg="6" className="float-left pl-2 pr-0">
                      Number of iterations
                    </Col>
                    <Col sm="1" md="1" lg="1" className="float-left px-0">
                      :
                    </Col>
                    <Col sm="5" md="5" lg="5" className="float-left px-0">
                      {case_study_item.no_of_iterations}
                    </Col>
                  </div>
                </div>
                <Col sm="12" md="12" lg="12" className="float-left d-flex justify-content-around pt-4">
                  <div className="justify-content-center w-100 text-center d-inline-flex">
                    <button
                      className={`font-14 px-4 ${Styles.primary_button} py-1 mr-2 mb-2`}
                      style={{
                        border: "1px solid rgb(198, 207, 244)",
                        borderRadius: "5px",
                        background: "transparent",
                        color: "rgb(198, 207, 244)",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        navigate(`/app/case-study/edit/${case_study_item.id}`)
                      }}
                    >
                      View
                    </button>
                    <button
                      className={`font-14 px-4 ${Styles.primary_button} py-1 mr-2 mb-2`}
                      style={{
                        border: "1px solid rgb(198, 207, 244)",
                        borderRadius: "5px",
                        background: "transparent",
                        color: "rgb(198, 207, 244)",
                        fontSize: "14px",
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        setDeleteObjectModal(case_study_item)
                      }}
                    >
                      Delete
                    </button>
                    {/* <button className={`font-14 px-4 ${Styles.primary_button} py-1 mb-2`} style={{background: 'rgb(78, 87, 116)', borderRadius: '5px', border: 'none', color: 'rgb(198, 207, 244)', fontSize: '14px'}} onClick={() => {navigate('/app/dashboard')}}>Publish</button> */}
                  </div>
                </Col>
              </Col>
            </Col>
          )
        })
      } else {
        if (search) {
          return (
            <p className="mb-0 text-center" style={{ fontSize: "16px" }}>
              No Results Found!!!
            </p>
          )
        } else {
          return (
            <p className="mb-0 text-center" style={{ fontSize: "16px" }}>
              You have not created any case study yet! Tap on the ‘Create new case study’ icon to create a new case study.
            </p>
          )
        }
      }
    } else {
      return (
        <p className="mb-0 text-center float-left w-100" style={{ fontSize: "16px" }}>
          Loading...
        </p>
      )
    }
  }

  function deleteData() {
    var index = case_study.findIndex((res) => res.id === deleteObjectModal.id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/case-study/" + deleteObjectModal.id + "/", { headers: DefaultHeader, body: deleteObjectModal })
      .then((res) => {
        case_study.splice(index, 1)
        setDeleteObjectModal([])
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  // function showDelModal(case_study) {
  //   return (
  //     <Modal show={Object.keys(deleteObjectModal).length > 0} onHide={handleDeleteObjectModal} centered className={`${StylesD.delete_modal} classroom-resource-delete-modal`} >
  //       <div className={`${StylesD.delete_modal_header}`}>
  //       <img onClick={handleDeleteObjectModal} src={CancelWhite} alt={`Cancel`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, height: `20px`,position:'absolute',top:'10px',right:'5px' }}/>
  //         <img src={TrashCan} alt={`delete modal delete icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `20px` }}/>
  //         <div>
  //          <p style={{fontWeight:'bold',fontSize:'15px'}}>Delete</p>
  //         </div>
  //       </div>
  //       <Modal.Body className="text-center" style={{backgroundColor:'#121b2b',borderRadius: '0px 0px 8px 8px'}} >
  //         <p style={{color: '#E0E2E5',fontSize:'15px',marginBottom:'10px'}} > Are you sure to delete ? </p>
  //         <p style={{color: '#E0E2E5', fontWeight:'bold',fontSize:'17px'}}>{deleteObjectModal && deleteObjectModal.name ? deleteObjectModal.name : 'Class'}</p>
  //         <div className="d-flex justify-content-center" >
  //           <Button className={`${StylesD.add_button_large} border_on_hover mr-3`} variant="secondary" onClick={() => {deleteData(deleteObjectModal)}} style={{width:'75px',border:'1px solid transparent'}} >Yes</Button>
  //           <Button className={`${StylesD.add_button_large} border_on_hover`} variant="secondary" onClick={handleDeleteObjectModal} style={{width:'75px',border:'1px solid transparent'}} >No</Button>
  //         </div>
  //       </Modal.Body>
  //     </Modal>
  //   )
  // }

  function searchCaseStudy(event) {
    setSearch(true)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/case-study/?search=" + event.target.value, { headers: DefaultHeader })
      .then((res) => {
        setCaseStudy(res.data.results)
        setNextUrl(res.data.next)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }
  const showCaseStudyNextButton = () => {
    return (
      <Col sm="12" md="12" lg="12" className="float-left mb-4 text-center">
        <button
          className={`font-14 px-3 ${Styles.primary_button} py-1`}
          style={{ border: "none", borderRadius: "5px", background: "rgb(78, 87, 116)", color: "rgb(198, 207, 244)", fontSize: "14px" }}
          onClick={showMoreCaseStudyButton}
        >
          Show More
        </button>
      </Col>
    )
  }
  const showMoreCaseStudyButton = () => {
    if (next_url) {
      setNextCaseStudyFetched(false)
    }
  }

  function createOption(question_data, quiz_id, value = "1") {
    let question_id = question_data.id
    let option = {}
    if (question_data.answermap_set.length > 0) {
      option["answer_map_id"] = question_data.answermap_set[0]
    }
    option["name"] = `sample option ${value}`
    option["add_to_question"] = question_id
    var formData = new FormData()
    for (var k in option) {
      formData.append(k, option[k])
    }
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/options/create/", formData, { headers: login_credentials })
      .then((res) => {
        if (value === "1") {
          createOption(question_data, quiz_id, "2")
        } else if (value === "2") {
          navigate(`/app/pop-quiz/${quiz_id}/edit/`)
        }
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function createQuestionResource(currentItem, quiz_id) {
    var formData = new FormData()
    formData.append("sequence", "0")
    formData.append("question_id", currentItem.id)
    formData.append("question_resource_type", "question")
    formData.append("add_to_quiz", quiz_id)
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/questions-resources/create/", formData, { headers: DefaultHeader })
      .then((res) => {
        createOption(res.data.question_data, quiz_id)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
        //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
        //     '<span aria-hidden="true">×</span></button>'+
        //     error_messages +
        // '</div>')
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function createQuestion(quiz_id) {
    let question = {}
    question["question_type"] = "1"
    question["title"] = "sample question 1".toString()
    var formData = new FormData()
    for (var k in question) {
      formData.append(k, question[k])
    }
    if (question.id != undefined) {
    } else {
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/questions/create/", formData, { headers: login_credentials })
        .then((res) => {
          let currentQuestion = {}
          currentQuestion.id = res.data.id
          currentQuestion.title = res.data.title
          createQuestionResource(currentQuestion, quiz_id)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    }
  }

  return (
    <div>
      <SEO title="Case Study" />
      <Col lg="12" md="12" sm="12" className="float-left px-4">
        <h4 className={`mt-3 d-flex align-items-end`} style={{ color: "rgb(198, 207, 244)", fontSize: "22px" }}>
          <FontAwesomeIcon icon={faPoll} className="mr-2" style={{ color: "rgb(87, 97, 134)", width: "38px", height: "38px" }} />
          My Case Study
        </h4>
        <div
          className={`my-4 float-left w-100 pointer`}
          style={{ background: "rgb(46, 51, 70)", borderRadius: "10px", border: "1px solid rgb(198, 207, 244)", color: "rgb(198, 207, 244)" }}
          onClick={(e) => {
            e.preventDefault()
            navigate(`/app/case-study/create`)
          }}
        >
          <h5 className="align-items-center d-flex justify-content-center mb-0 py-4" style={{ fontSize: "20px" }}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-4" />
            Create new case study
          </h5>
        </div>
        {showCaseStudy()}
        {next_url ? showCaseStudyNextButton() : null}
      </Col>
      <DeleteModal
        show={Object.keys(deleteObjectModal).length > 0}
        onHide={handleDeleteObjectModal}
        itemName={deleteObjectModal?.name}
        action={() => {
          deleteData(deleteObjectModal)
        }}
      />
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default CaseStudy
