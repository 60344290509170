import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import ProgressBar from "react-bootstrap/ProgressBar"
import Spinner from "react-bootstrap/Spinner"
import Collapse from "react-bootstrap/Collapse"

import Layout from "../layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../seo"
import authClient from "../../services/auth"
// import hideMessage from '../../services/hidemessage';
import Notification from "../custom/Notification"
import { isBrowser } from "../../services/developer_mode"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronDown, faChevronUp, faCheck } from "@fortawesome/free-solid-svg-icons"

const ModuleReport = (props) => {
  const [open, setOpen] = useState(false)

  function showTopicReport(module) {
    return module.topic_reports.map((topic, index) => {
      return (
        <div key={index.toString() + "_topic"} className={`${index === 0 ? "py-1" : "pb-1"}`}>
          <ProgressBar
            bsPrefix="report-progress-bar"
            style={{ width: "188px", height: "1.5rem", borderRadius: "0px" }}
            now={topic.completed_percentage}
            label={
              <span className="pl-2">
                <FontAwesomeIcon
                  className={`${topic.completed_percentage === 100 ? "" : "d-none"}`}
                  icon={faCheck}
                  style={{ width: "10px", height: "10px", marginRight: "5px" }}
                />
                {topic.name}
              </span>
            }
          />
        </div>
      )
    })
  }

  function showCollapseIcon() {
    if (props.module.topic_reports.length > 0) {
      if (open) {
        return (
          <div onClick={() => setOpen(!open)} className="pointer" style={{ position: "absolute", right: "5px" }}>
            <FontAwesomeIcon icon={faChevronUp} style={{ width: `10px`, height: `10px` }} />
          </div>
        )
      } else {
        return (
          <div onClick={() => setOpen(!open)} className="pointer" style={{ position: "absolute", right: "5px" }}>
            <FontAwesomeIcon icon={faChevronDown} style={{ width: `10px`, height: `10px` }} />
          </div>
        )
      }
    } else {
      return null
    }
  }

  return (
    <div className="mb-2">
      <div className="d-flex" style={{ width: "200px", position: "relative" }}>
        <ProgressBar
          bsPrefix="report-progress-bar"
          style={{
            width: "200px",
            height: "2rem",
            borderLeft: "1px solid #5d74c9",
            borderRight: "1px solid #5d74c9",
            borderTop: "1px solid #5d74c9",
            borderBottom: open ? "none" : "1px solid #5d74c9",
            borderRadius: open ? ".25rem .25rem 0rem 0rem" : ".25rem",
          }}
          now={props.module.completed_percentage}
          label={
            <span className="pl-2">
              <FontAwesomeIcon
                className={`${props.module.completed_percentage === 100 ? "" : "d-none"}`}
                icon={faCheck}
                style={{ width: "10px", height: "10px", marginRight: "5px" }}
              />
              {props.module.name}
            </span>
          }
        />
        {showCollapseIcon()}
      </div>
      <Collapse in={open}>
        <div
          className=""
          style={{
            width: "200px",
            borderBottom: "1px solid #5d74c9",
            borderLeft: "1px solid #5d74c9",
            borderRight: "1px solid #5d74c9",
            padding: "0px 5px",
            borderRadius: "0px 0px 7px 7px",
          }}
        >
          {showTopicReport(props.module)}
        </div>
      </Collapse>
    </div>
  )
}

const ClassReport = (location, props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [studentLoader, setStudentLoader] = useState(false)
  const [student, setStudent] = useState("")
  const [classReportFetched, setClassReportFetched] = useState(false)
  const [classReport, setClassReport] = useState("")
  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    fetchStudent()
    fetchClassReport()
  }, [])

  async function fetchStudent() {
    setStudentLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/student-companies/?owner__id=${location.student_id}`, { headers: login_credentials })
      .then((res) => {
        setStudent(res.data.results[0])
        setStudentLoader(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }
  async function fetchClassReport() {
    setStudentLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/course-report/${location.course_id}/${location.student_id}/`, { headers: login_credentials })
      .then((res) => {
        setClassReport(res.data)
        setClassReportFetched(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function showStudentDetail() {
    if (studentLoader && student) {
      return (
        <div style={{ padding: "16px" }}>
          <h5 className="mb-2">Class Report</h5>
          <table className="">
            <thead>
              <tr style={{ background: "#28324e", fontSize: ".9rem" }}>
                <td className="pl-3 py-1">Name</td>
                <td className="py-1">Email</td>
                <td className="py-1">Stage</td>
                <td className="py-1">Completion Status</td>
                <td className="py-1">Pending Actions</td>
                <td className="py-1">Assessment Score</td>
              </tr>
            </thead>
            <tbody>
              <tr style={{ fontSize: ".8rem", color: "rgb(198 207 244)" }}>
                <td className="pl-3 py-2">{student.first_name}</td>
                <td className="py-2">{student.email}</td>
                <td className="py-2">{classReportFetched ? classReport.stage : <Spinner animation="border" size="sm" />}</td>
                <td className="py-2">
                  {classReportFetched ? (
                    <ProgressBar
                      bsPrefix="default-report-progress-bar"
                      style={{ width: "100px" }}
                      now={classReport.completed_percentage}
                      label={`${classReport.completed_percentage}%`}
                    />
                  ) : (
                    <Spinner animation="border" size="sm" />
                  )}
                </td>
                <td className="py-2">
                  {classReportFetched ? classReport.resources_count - classReport.completed_resources : <Spinner animation="border" size="sm" />}
                </td>
                <td className="py-2">Not available yet</td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function showClassreport() {
    if (classReportFetched) {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="py-3" style={{ background: "#28324e" }}>
          <h6 className="mb-3">Pending Tasks</h6>
          <div className="d-flex px-2" style={{ overflowX: "auto" }}>
            {showStages()}
          </div>
        </Col>
      )
    } else {
      return (
        <Col lg="12" className="animated fadeIn pt-3 text-center">
          Loading...
        </Col>
      )
    }
  }

  function showStages() {
    return classReport.stage_reports.map((stage, index) => {
      return (
        <div key={index.toString() + "_stage"} className="" style={{ width: "40%" }}>
          <div className="d-flex mb-3" style={{ alignItems: "center" }}>
            <ProgressBar bsPrefix="report-progress-bar" style={{ width: "200px" }} now={stage.completed_percentage} label={`${stage.name}`} />
            <div
              className={`${index === classReport.stage_reports.length - 1 ? "d-none" : ""}`}
              style={{ border: "1px solid white", width: "calc(100% - 200px)" }}
            ></div>
          </div>
          <div className="">{showModuleReport(stage)}</div>
        </div>
      )
    })
  }
  function showModuleReport(stage) {
    return stage.module_reports.map((module, index) => {
      return <ModuleReport key={index.toString() + "_module_report"} module={module} />
    })
  }

  return (
    <div>
      <SEO title="Class Report" />
      <Col lg="12" md="12" sm="12" xs="12">
        <h4 className={`mt-3`}>
          <Link className={`pointer text-white mr-2`} to={`/app/dashboard`}>
            {" "}
            <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
          </Link>
          <span>Participants</span>
        </h4>
        <div className={`${Styles.b_radius_15} mb-5`} style={{ background: "#46537e" }}>
          {showStudentDetail()}
          {showClassreport()}
        </div>
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default ClassReport
