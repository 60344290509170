import React, { useCallback } from "react"
import _ from "lodash"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

import InputFieldWrap from "../trainer/InputFieldWrap"

import StylesA from "./matching.module.scss"

const NameField = (props) => {
  const matchingData = props.matchingData

  const delayedQuery = useCallback(
    _.debounce((name, value, data) => sendQuery(name, value, data), 1000),
    []
  )

  function sendQuery(name, value, data) {
    props.handleUpdateMatchingData(name, value, data)
  }

  return (
    <div className="col-9 pl-0 pr-1">
      <InputFieldWrap no_mb={true} error={props.mftErrors.mft_name_error}>
        <input
          id="name"
          name="name"
          defaultValue={matchingData.name}
          onChange={(e) => {
            if (props.type === "edit") {
              props.setSavedContent(false)
              delayedQuery(e.target.name, e.target.value, matchingData)
            } else {
              props.handleChange(e, "name")
            }
          }}
          onBlur={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (props.type === "edit") {
              if (e.target.value !== matchingData.name) {
                props.setSavedContent(false)
                props.handleUpdateMatchingData(e.target.name, e.target.value, matchingData)
              }
            } else {
              props.createMatchTheFollowing()
            }
          }}
          type="text"
          className={`${StylesA.inputText} fs-16px pt-1`}
          style={{ background: "transparent", borderColor: "transparent" }}
          autoComplete="off"
          required
        />
        <span className={`${StylesA.floating_label} text-color-1 d-flex-align-center`}>
          <span>Name of the matching</span>
        </span>
      </InputFieldWrap>
      {/* The below code is for displaying error if name field is empty */}
      {props.mftErrors.mft_name_error && (
        <div className="d-inline-flex px-3 py-0 pb-2">
          <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#ff7458" size="sm" style={{ height: "13px" }} />
          <p className="mb-0" style={{ fontSize: "14px", color: "#ff7458" }}>
            Name field required
          </p>
        </div>
      )}
    </div>
  )
}

export default NameField
