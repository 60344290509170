import React, { useEffect, useState, useCallback, useContext } from "react"
import _ from "lodash"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Spinner from "react-bootstrap/Spinner"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import muralIcon from "../../images/svgs/mural-icon.svg"
import { LazyLoadImage } from "react-lazy-load-image-component"
import SEO from "../../components/seo"
import utcDateTime from "../../services/utcdatetime"
import Common from "../../services/common"
import { isBrowser } from "../../services/developer_mode"
import AvatarCircle from "../custom/AvatarCircle"

import SucessTick from "../../images/svgs/sucesstick.svg"
import CustomDateTimePicker from "../custom/CustomDateTimePicker"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AcivityWorkSpaceStyle from "../acitvity-workspace/acitivity-workspace.module.scss"
import {
  faChevronCircleLeft,
  faChevronCircleUp,
  faChevronCircleDown,
  faCheck,
  faChevronCircleRight,
  faExclamationTriangle,
  faCheckCircle,
  faUserCircle,
  faLink,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import edit from "../../images/svgs/edit.svg"
import CalendarAlt from "../../images/svgs/calendaralt.svg"
import Clock from "../../images/svgs/clock.svg"
import StylesM from "../upload-resource.module.scss"
import Styles from "./create.module.scss"
import VideoCallLinkInput from "./_video-link-input"
import { getRequest, putRequest } from "../../services/rest_service"
import MuralAccountsList from "../mural/muralAccountsList"
import AppIntegrationModal from "../app-integration"
import { HasCreatePermission, HasEditPermission } from "../../utils/PermissionDataAccess"
import { CLASSES } from "../../constants/permissionsConstant"
import Staff from "./staff"
import { GlobalContext } from "../../stores/global_store/GlobalStore"

var nextExtLinkId = 0

const EditTraining = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""
  const [globalState, globalStateDispatch] = useContext(GlobalContext)

  const [allTemplates, setAllTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [selectCourseCollapdsed, setSelectCourseCollapsed] = useState(true)
  const [selectTrainerCollapdsed, setSelectTrainerCollapsed] = useState(true)
  const [clickedPrice, setClickedPrice] = useState(false)
  const [currentCourse, setCurrentCourse] = useState(null)
  const [courseName, setCourseName] = useState("")
  const [courseDatam, setCourseDatam] = useState({})
  const [courseDatamUpdated, setCourseDatamUpdated] = useState(false)
  const [activeSection, setActiveSection] = useState("class_details")
  const [trainers, setTrainers] = useState([])
  const [selectedTrainers, setSelectedTrainers] = useState(trainers.length === 1 ? trainers : [])
  const [training, setTraining] = useState({ trainers: selectedTrainers })
  const [clickedPublish, setClickedPublish] = useState(false)
  const [allTrainers, setAllTrainers] = useState()
  const [clickedTrainerDetailsNext, setclickedTrainerDetailsNext] = useState(false)
  const [training_start_date, setTrainingStartDate] = useState(new Date())
  const [training_end_date, setTrainingEndDate] = useState(new Date())
  const [minStartDate, setMinStartDate] = useState(new Date())
  const [minEndDate, setMinEndDate] = useState(new Date())
  const [mergedArray, setMergedArray] = useState(false)
  const [loader, setLoader] = useState(false)
  const [enteredValidTime, setEnteredValidTime] = useState(true)
  const [fetchedTrainingData, setFetchedTrainingData] = useState(false)
  const [timeErrorMsg, setTimeErrorMsg] = useState("Please select the valid time. Start time must be less than end time ")
  const [courseLength, setCourseLength] = useState(0)
  const [trainingDataUpdated, setTrainingDataUpdated] = useState(false)
  const [linksData, setLinksData] = useState([])
  const [addMoreLinks, setAddMoreLinks] = useState(false)
  const [linksDataNextURL, setLinksDataNextURL] = useState(null)
  const [linksDataLoading, setLinksDataLoading] = useState({})
  const [linksDataFocusElem, setLinksDataFocusElem] = useState("")
  const [teamsData, setTeamsData] = useState([])
  const [teamsErrMsg, setTeamsErrMsg] = useState(false)
  const isMuralEnabled = globalState.appData.data?.company?.mural_enabled

  // duplicate Class state variable
  const [duplicateClass, setDuplicateClass] = useState(false)
  function sendQuery(name, value) {
    // setUpdateInputData({name : name,value : value,datum: datum,type: type});
    courseDatam[name] = value
    setCourseDatam(courseDatam)
    setCourseDatamUpdated(true)
  }

  const delayedQuery = useCallback(
    _.debounce((name, value) => sendQuery(name, value), 1000),
    []
  )

  function onBlurData(name, value) {
    if (name !== null) {
      training[name] = value
      handleSubmit("none")
    }
  }

  useEffect(() => {
    if (addMoreLinks && linksDataFocusElem) {
      let e = document.getElementById(linksDataFocusElem)
      if (e && "focus" in e) e.focus()
    }
  }, [addMoreLinks])

  useEffect(() => {
    if (linksDataFocusElem) {
      let e = document.getElementById(linksDataFocusElem)
      if (e) {
        if ("scrollIntoView" in e) {
          e.scrollIntoView(true, { behavior: "smooth", block: "end", inline: "nearest" })
        } else {
          window.scrollTo({ top: e.offsetTop, behavior: "smooth" })
        }
      }
    }
  }, [linksDataFocusElem])

  // const { state = {} } = location

  useEffect(() => {
    let hash_value = window.location.hash
    if (hash_value === "#publish_class") {
      setActiveSection("trainer_details")
    } else {
      setActiveSection("class_details")
    }
    fetchTrainers()
    fetchTraining()
    searchTemplates()
    getSchoolTeams()
  }, [])

  useEffect(() => {
    if (Object.keys(courseDatam).length > 0 && courseDatamUpdated) {
      for (const [key, value] of Object.entries(courseDatam)) {
        training[key] = value
      }
      handleSubmit("none")
    }
  }, [Object.keys(courseDatam).length, courseDatamUpdated])

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("message", handleNewWinMessages, false)
      return () => {
        window.removeEventListener("message", handleNewWinMessages)
      }
    }
  }, [window])

  function openNewWindow(url) {
    let wnd = window.open(url, "edit-class")
  }

  function handleNewWinMessages(event) {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      if (event.data.instruction === "payload") {
        fetchTrainers()
        document.getElementById("collapse-selected-trainers").click()
        setTimeout(() => {
          document.getElementById("collapse-selected-trainers").click()
        }, 100)
      }
    }
  }

  async function fetchTrainers() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/trainers/list/all/?type=all&company=${location.company_id}`, { headers: login_credentials })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let arr = []
          setAllTrainers(res.data)
          res.data.map((item, indx) => {
            arr[indx] = item
            arr[indx].selected = false
          })
          setTrainers([...arr])
        }
      })
  }

  function updateCourseLenght(data) {
    let duration = data.course_duration
    let duration_type = data.course_duration_type
    let n = 0
    if (duration_type === "0") {
      n = Math.ceil(duration / 24)
    }
    setCourseLength(n)
  }

  async function fetchTraining() {
    // setCompanyLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/trainings/${location.id}/`, { headers: login_credentials }).then((res) => {
      const start_date = new Date(res.data["start_date"])
      const end_date = new Date(res.data["end_date"])
      const curr_date = new Date()
      setTrainingStartDate(start_date)
      setTrainingEndDate(end_date)
      setMinStartDate(start_date < curr_date ? start_date : curr_date)
      setMinEndDate(end_date < curr_date ? end_date : curr_date)
      setTraining(res.data)
      setSelectedTrainers(res.data.trainers)
      setCourseName(res.data.course_name)
      setSelectedTemplate(res.data.default_course_template ? res.data.default_course_template : null)
      setFetchedTrainingData(true)
      updateCourseLenght(res.data)
      // setCompanyLoader(true)
      fetchExtLinks(res.data.course_template)
    })
  }

  function searchTemplates(search = "") {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-default-course-templates-list/?search=` + search, { headers: login_credentials }).then((res) => {
      setAllTemplates(res.data.results)
    })
  }

  function selectItem(e, field, data) {
    e.preventDefault()
    training[field] = data.id
    setSelectedTemplate(data.name)
  }

  function handleChange(event) {
    training[event.target.name] = event.target.value
  }
  const getSchoolTeams = async () => {
    let res = await getRequest(`/role-management/school-teams/?company=${localStorage.getItem("company_id")}`)
    if (res.success) {
      setTeamsData({ data: res.data.results, has_next: res.data.next, count: res.data.count })
    } else {
      if (res?.status === 403) {
        setTeamsErrMsg(true)
      }
    }
  }
  async function handleTrainers(index) {
    trainers[index].selected = !trainers[index].selected
    setTrainers([...trainers])
    let x = []
    trainers.map((item, indx) => {
      if (item.selected === true) {
        x.push({ id: item.id, name: item.name, description: item.description, profile_pic: item.profile_pic })
      }
    })
    setSelectedTrainers([...x])
    let orginalData = []
    x.map((item, indx) => {
      orginalData.push({ id: item.id })
    })
    if (orginalData.length > 0) {
      training.trainers = orginalData
      var formData = new FormData()
      for (var k in training) {
        if (k === "end_date" || k === "start_date") {
          formData.append(k, new Date(training[k]).toISOString())
        } else if (k === "course_template") {
          if (training.course_template !== null) {
            formData.append("course_template", JSON.stringify(training.course_template))
          }
        } else if (k === "trainers") {
          formData.append("trainers", JSON.stringify(training["trainers"]))
        } else if (k === "training_staff") {
          formData.append("training_staff", JSON.stringify(training["training_staff"]))
        } else if (k === "resource_json") {
          formData.append("resource_json", JSON.stringify(training["resource_json"]))
        } else if (k === "certification") {
          formData.append("certification", training["certification"] ? training["certification"] : "")
        } else if (k === "mural_account") {
          if (training[k]?.id || training[k]) {
            formData.append("mural_account", training[k]?.id ? parseInt(training[k]?.id) : training[k])
          } else {
            formData.append("mural_account", "")
          }
        } else {
          formData.append(k, training[k])
        }
      }
      if (training.id != undefined) {
        let trainingResponse = await putRequest(`/trainings/${training.id}/`, formData)
        if (trainingResponse.status) {
          setCourseDatam({})
          setCourseDatamUpdated(false)
        }
      }
    }
    setLoader(false)
  }

  function handleDateChange(date, type) {
    let end = new Date(training["end_date"])
    let dummy = { ...training }
    if (type === "start_date") {
      dummy["start_date"] = date
      let end_time = date.getTime() + (courseLength - 1) * 24 * 60 * 60 * 1000
      let end_date = new Date(end_time)
      end_date.setHours(end.getHours(), end.getMinutes())
      dummy["end_date"] = end_date
      setTrainingStartDate(date)
      setTrainingEndDate(end_date)
    } else {
      dummy["end_date"] = date
      setTrainingEndDate(date)
    }
    setTraining(dummy)
    delayedDateQuery()
  }
  const delayedDateQuery = useCallback(
    _.debounce(() => setTrainingDataUpdated(true), 1000),
    []
  )

  useEffect(() => {
    if (trainingDataUpdated) {
      handleSubmit("none")
    }
  }, [trainingDataUpdated])

  function validateCourseLenght(date1, date2) {
    let duration = training.course_duration
    let duration_type = training.course_duration_type
    let n = 0
    if (duration_type === "0") {
      n = Math.ceil(duration / 24)
    }
    let start_day = date1.getTime()
    let end_day = date2.getTime()
    let res = end_day - start_day
    let total_days = res / (1000 * 60 * 60 * 24)
    if (total_days == n - 1) {
      return true
    } else if (total_days > n - 1) {
      return "greater than"
    } else {
      return "less than"
    }
  }

  async function handleSubmit(event = null, click = null) {
    if (event !== "none" && event !== null) {
      event.preventDefault()
    }
    let validation = true
    let orginalData = []
    selectedTrainers.map((item, indx) => {
      orginalData.push({ id: item.id })
    })
    training.trainers = orginalData
    let date1 = new Date(training["start_date"])
    let date2 = new Date(training["end_date"])
    let d3 = new Date(training["start_date"])
    let d4 = new Date(training["end_date"])
    d3.setHours(0, 0, 0, 0)
    d4.setHours(0, 0, 0, 0)
    let h1 = date1.getHours()
    let h2 = date2.getHours()
    let m1 = date1.getMinutes()
    let m2 = date2.getMinutes()
    if (h1 === h2 && m1 === m2) {
      setEnteredValidTime(false)
      validation = false
    } else if (h1 === h2 && m1 > m2) {
      setEnteredValidTime(false)
      validation = false
    } else {
      if (d3.getTime() >= d4.getTime()) {
        if (d3.getTime() == d4.getTime() && h1 != h2) {
          setEnteredValidTime(true)
          validation = true
        } else if (d3.getTime() == d4.getTime() && h1 == h2 && m1 < m2) {
          setEnteredValidTime(true)
          validation = true
        } else {
          setEnteredValidTime(false)
          validation = false
        }
        // setEnteredValidTime(false)
        // validation = false
      } else {
        setEnteredValidTime(true)
        validation = true
      }
    }

    let flag = validateCourseLenght(d3, d4)
    if (flag == true && validation == true) {
      setEnteredValidTime(true)
      validation = true
      setTimeErrorMsg("Please select the valid time. Start time must be less than end time ")
    } else {
      if (validation) {
        setEnteredValidTime(false)
        validation = false
        setTimeErrorMsg(`The start and end date range is ${flag} course length`)
      }
    }

    var formData = new FormData()
    for (var k in training) {
      if (k === "end_date" || k === "start_date") {
        formData.append(k, new Date(training[k]).toISOString())
      } else if (k === "course_template") {
        if (training.course_template !== null) {
          formData.append("course_template", JSON.stringify(training.course_template))
        }
      } else if (k === "trainers") {
        formData.append("trainers", JSON.stringify(training["trainers"]))
      } else if (k === "training_staff") {
        formData.append("training_staff", JSON.stringify(training["training_staff"]))
      } else if (k === "resource_json") {
        formData.append("resource_json", JSON.stringify(training["resource_json"]))
      } else if (k === "certification") {
        formData.append("certification", training["certification"] ? training["certification"] : "")
      } else if (k === "mural_account") {
        if (training[k]?.id || training[k]) {
          formData.append("mural_account", training[k]?.id ? parseInt(training[k]?.id) : typeof training[k] === "object" ? "" : training[k])
        } else {
          formData.append("mural_account", "")
        }
      } else {
        formData.append(k, training[k])
      }
    }

    if (validation === true && training.id != undefined && training.trainers.length > 0) {
      let trainingResponse = await putRequest(`/trainings/${training.id}/`, formData)
      if (trainingResponse.success) {
        if (event !== "none") {
          handlePublishClass()
        }
        setLoader(false)
        setTrainingDataUpdated(false)
        setCourseDatam({})
        setCourseDatamUpdated(false)
      }
    } else {
      setTrainingDataUpdated(false)
    }
  }
  function toggle(e, type) {
    e.preventDefault()
  }

  function updateTrainers() {
    let x = trainers
    let y = selectedTrainers
    if (mergedArray === false) {
      for (let i = 0; i < x.length; i++) {
        for (let j = 0; j < y.length; j++) {
          if (x[i].id === y[j].id) {
            x[i].selected = true
          }
        }
      }
      setTrainers([...x])
    }
  }

  const getTrainingTimeDuration = (start_date, end_date) => {
    let start_time = utcDateTime.utcDateTime(training.start_date, "time_in_sec")
    let end_time = utcDateTime.utcDateTime(training.end_date, "time_in_sec")
    let time_differece_in_day = Math.ceil((end_time - start_time) / (1000 * 60 * 60 * 24))
    if (time_differece_in_day === 0) {
      let time_differece_in_min = Math.floor((end_time - start_time) / (1000 * 60))
      let hrs = Math.floor(time_differece_in_min / 60)
      let mins = time_differece_in_min - hrs * 60
      let hrs_in_string = `${hrs < 10 ? `0${hrs}` : hrs}`
      let mins_in_string = `${mins < 10 ? `0${mins}` : mins}`
      return `${hrs_in_string}:${mins_in_string} ${hrs === 1 ? "hour" : "hours"}`
    } else {
      return `${time_differece_in_day} ${time_differece_in_day === 1 ? "day" : "days"}`
    }
  }

  function publishClass() {
    if (!clickedPublish) {
      return (
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          className={`float-left pt-2`}
          style={{ marginLeft: "7%", borderRadius: "9px", backgroundColor: "#212C42", boxShadow: "0px 3px 12px #00000099", color: "#E0E2E5" }}
        >
          <div className="float-left w-100 d-flex align-items-center">
            <div className="w-25">
              <FontAwesomeIcon
                className="pointer"
                onClick={() => {
                  setActiveSection("class_details")
                }}
                icon={faChevronCircleLeft}
                style={{ width: "30px", height: "30px" }}
              />
            </div>
            <p className="mb-0 poppins_font" style={{ fontSize: "16px", opacity: "56%" }}>
              Your live class is saved as a draft! You can publish now
            </p>
          </div>
          <Col xs="12" sm="12" md="9" lg="9" className={`pt-2 mb-3 float-right pl-4`}>
            <div className="float-left w-100">
              <p className="mb-2 fot-weight-bold poppins_font" style={{ fontSize: "22px", fontWeight: "600" }}>{`${
                training.course_name
              } (${getTrainingTimeDuration(training.start_date, training.end_date)})`}</p>
              <div className="d-flex align-items-center mb-2">
                <img src={CalendarAlt} alt="calendar" className="mr-2 mb-0" style={{ width: "20px", height: "20px" }} />
                <p className="mb-0 poppins_font align-items-center d-inline-flex" style={{ fontSize: "14px" }}>
                  {`${utcDateTime.utcDateTime(training.start_date, "day_name")}, ${utcDateTime.utcDateTime(
                    training.start_date,
                    "date_with_prefix"
                  )} ${utcDateTime.utcDateTime(training.start_date, "short_month_name")}, ${utcDateTime.utcDateTime(
                    training.start_date,
                    "two_digit_year"
                  )} `}{" "}
                  <div className="mx-2" style={{ backgroundColor: "white", height: "3px", width: "16px" }}></div>{" "}
                  {`${utcDateTime.utcDateTime(training.end_date, "day_name")}, ${utcDateTime.utcDateTime(
                    training.end_date,
                    "date_with_prefix"
                  )} ${utcDateTime.utcDateTime(training.end_date, "short_month_name")}, ${utcDateTime.utcDateTime(training.end_date, "two_digit_year")}`}
                </p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img src={Clock} alt="clock" className="mr-2 mb-0" style={{ width: "20px", height: "20px" }} />
                <p className="mb-0" style={{ fontSize: "16px", fontWeight: "600" }}>{`${utcDateTime.utcDateTime(
                  training.start_date,
                  "time"
                )} - ${utcDateTime.utcDateTime(training.end_date, "time")}`}</p>
              </div>
            </div>
            <div className="float-left w-100" style={{ overflowX: "auto" }}>
              {selectedTrainers.length === 0 ? null : (
                <p className="mb-1 poppins_font" style={{ fontSize: "14px", opacity: "60%" }}>
                  Trainers
                </p>
              )}
              <div className="align-items-center">
                {selectedTrainers.map((item, index) => (
                  <div
                    key={`${item.name?.toLowerCase().split(" ").join("-")}-${index}`}
                    className={`px-2 py-1 mr-2 mb-2 d-inline-flex align-items-center`}
                    style={{ backgroundColor: "#303C54", borderRadius: "9px", boxShadow: "0px 3px 6px #00000029" }}
                  >
                    {item.profile_pic === "" || item.profile_pic === null || item.profile_pic === undefined ? (
                      // <p className='mb-0 mr-2 align-items-center text-center' style={{height:'40px',width:"40px",borderRadius:'50%',backgroundColor:"#42506C",color:'#E0E2E5',fontSize:'25px',objectFit:'contain'}}>{item.name.charAt(0)}</p>
                      <FontAwesomeIcon
                        className="mt-0 mr-1"
                        icon={faUserCircle}
                        color="#E0E2E5"
                        size="sm"
                        style={{ borderRadius: "50%", width: "40px", height: "40px" }}
                      />
                    ) : (
                      // <LazyLoadImage className='mb-0 mr-2' size="sm" src={`${process.env.GATSBY_REACT_APP_IMAGE_API}${item.avatar}`}  alt={`${item.name.toLowerCase().split(' ').join('-')}-${index}-trainer`} style={{borderRadius: '50%', width: '40px', height: '40px'}} />}
                      <LazyLoadImage
                        className="mb-0 mr-2"
                        size="sm"
                        src={item.profile_pic}
                        alt={`${item.name?.toLowerCase().split(" ").join("-")}-${index}-trainer`}
                        style={{ borderRadius: "50%", width: "40px", height: "40px", objectFit: "contain" }}
                      />
                    )}
                    <p className="poppins_font mb-0 pt-1 pr-2 float-right" style={{ fontSize: "16px" }}>
                      {item.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="float-left w-50 text-center">
              <button
                onClick={(e) => {
                  setClickedPublish(true)
                }}
                className="border_on_hover px-3 py-2 mt-3 mb-4"
                style={{
                  backgroundColor: "#487666",
                  color: "#E0E2E5",
                  borderRadius: "9px",
                  border: "1px solid transparent",
                  boxShadow: "0px 3px 6px #00000029",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                I want to publish now
              </button>
            </div>
          </Col>
        </Col>
      )
    } else {
      return (
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          className={`w-100 mr-0 pt-2 p-4 pb-5 text-center align-items-center`}
          style={{ marginLeft: "7%", borderRadius: "9px", backgroundColor: "#212C42", boxShadow: "0px 3px 12px #00000099" }}
        >
          <Col xs="12" sm="12" md="12" lg="12" className={`mb-4 w-100 my-3 pt-2 mb-3 text-center align-items-center`}>
            <img src={SucessTick} alt="success" />
            <p style={{ fontSize: "19px", color: "#E0E2E5", fontWeight: "600" }}>Your class has been published successfully</p>
            <p className="mb-2" style={{ fontSize: "16px", color: "#E0E2E5", fontWeight: "500" }}>
              You can view your class details{" "}
            </p>
            <span
              className="py-2 px-3"
              style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", fontSize: "16px", color: "#E0E2E5" }}
            >
              <span
                className={`pointer ${Styles.onfocuswhite}`}
                onClick={() => {
                  navigate("/app/dashboard")
                }}
              >
                Home
              </span>{" "}
              {`>`}{" "}
              <span
                className={`pointer ${Styles.onfocuswhite}`}
                onClick={() => {
                  navigate("/app/dashboard")
                }}
              >
                Classes
              </span>{" "}
              {`>`}{" "}
              <span
                className={`pointer ${Styles.onfocuswhite}`}
                onClick={() => {
                  navigate("/app/dashboard#upcoming")
                }}
              >
                Upcoming classes
              </span>
            </span>
          </Col>
        </Col>
      )
    }
  }

  function getSingleCourse(datum, index) {
    return (
      <Col
        onClick={(e) => {
          handleChange(e, parseInt(datum.id))
          setSelectCourseCollapsed(true)
          setCurrentCourse(datum.name)
        }}
        name="course"
        id={datum.id}
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="border_on_hover d-inline-flex w-100 py-2 px-1 mb-2"
        style={{ backgroundColor: "#42506C", borderRadius: "9px", boxShadow: "0px 3px 6px #00000029" }}
      >
        <div className="float-left align-self-center" style={{ width: "88%" }}>
          <p className="d-inline-flex mb-0 pl-2" style={{ fontSize: "16px", color: "#E0E2E5", fontWeight: "500" }}>
            {datum.name}
          </p>
        </div>
        <div className="float-right align-self-center">
          <div className={`float-right pointer d-inline-flex ${Styles.onfocuswhite}`}>
            <u style={{ fontSize: "16px", color: "#E0E2E5" }}>View Details</u>
          </div>
        </div>
      </Col>
    )
  }

  let page_uri = isBrowser() ? "/" + window.location.pathname.substr(1) : "false"
  if (page_uri === "/logout" || page_uri === "logout" || page_uri === "logout/" || page_uri === "/logout/") {
    page_uri = "/"
  }

  function getSingleTrainer(item, index) {
    return (
      <Col
        name="trainer"
        key={item.id}
        id={item.id}
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="border_on_hover d-inline-flex w-100 py-2 px-1 mb-2"
        style={{
          backgroundColor: "#42506C",
          borderRadius: "9px",
          boxShadow: "0px 3px 6px #00000029",
          border: item.selected === true ? "1px solid #E0E2E5" : "1px solid transparent",
        }}
        onClick={() => {
          setLoader(true)
          handleTrainers(index)
        }}
      >
        <AvatarCircle size={"30px"} name={item.name} avatar={item.profile_pic} />
        <div className="float-left d-inline-flex align-self-center" style={{ width: "88%" }}>
          <p className="d-inline-flex mb-0 pl-2" style={{ fontSize: "16px", color: "#E0E2E5", fontWeight: "500" }}>
            {item.name}
          </p>
          {/* <p onClick={(e)=>{e.stopPropagation()}} className='d-inline-flex mb-0 pl-4' style={{fontSize:'16px',color:'#E0E2E5'}}>23 Trainings</p> */}
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="lg"
            color="#3FBDAC"
            style={{ backgroundColor: "#E0E2E5", borderRadius: "50%", border: "1px solid #3FBDAC" }}
            className={`ml-2 ${item.selected !== true ? `d-none` : `d-flex`}`}
            alt="selected"
          />
        </div>
        <div className="float-right align-self-center" style={{ width: "12%" }}>
          <p className={`${Styles.onfocuswhite} mb-0`}>
            <u
              onClick={() => {
                navigateTrainer(item.id)
              }}
              className=""
              style={{ fontSize: "14px", color: "#E0E2E5" }}
            >
              View Details
            </u>
          </p>
        </div>
      </Col>
    )
  }
  const navigateTrainer = (trainer_id) => {
    navigate(
      `/app/school-setup/school-profile-setup/${localStorage.getItem("company_id")}/educator/educator-profile-setup/${trainer_id}?redirect=${
        window.location.href
      }`
    )
  }

  function updateData() {
    setclickedTrainerDetailsNext(true)
    if (selectedTrainers.length >= 1) {
      setActiveSection("publish_class")
    }
  }

  function fetchExtLinksNext() {
    if (linksDataNextURL == null) return
    axios.get(linksDataNextURL.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      if (res.data["results"]?.length > 0) {
        setLinksDataNextURL(res.data.next)
        setLinksData((s) => [...s, ...res.data.results])
        setLinksDataFocusElem(`link_${linksData.length}`)
      }
    })
  }

  function getNextExtLinksOnScroll() {
    if (linksDataNextURL !== null) {
      let ele = document.getElementById("divExtLinks")
      if (ele) {
        if (ele.scrollTop + ele.offsetHeight >= ele.scrollHeight) fetchExtLinksNext()
      }
    }
  }
  function fetchExtLinks(templateId) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/tool-links/?defaultcoursetemplate=${templateId}&ordering=id`, { headers: login_credentials })
      .then((res) => {
        if (res.data["results"]?.length > 0) {
          setLinksDataNextURL(res.data.next)
          setLinksData(res.data["results"])
        } else {
          setLinksData([{ id: `el-${nextExtLinkId++}`, link: "", name: "", add_to_template: templateId, icon: null, notInDB: true }])
        }
      })
  }

  const delayedSaveExtLink = useCallback(
    _.debounce((index, value, pLinksData) => {
      handleLinkSave(index, value, pLinksData)
    }, 1500),
    []
  )

  function handleLinkSave(index, value, obj) {
    if (!obj[index]["notInDB"]) {
      return
    }

    let v = value
    if (v.indexOf("http://") == -1 && v.indexOf("https://") == -1) {
      v = "https://" + value
    }

    let pLinksData = [...obj]
    if (!Common.isValidURL(v)) {
      pLinksData[index].error = "Enter a valid URL"
      setLinksData([...pLinksData])
      return
    }

    pLinksData[index]["link"] = v
    pLinksData[index]["name"] = "Link"
    //value = v

    let data = { name: pLinksData[index].name, link: v, add_to_template: pLinksData[index].add_to_template }

    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + `/tool-links/create/`, data, { headers: login_credentials })
      .then((res) => {
        if (res.data.link === "Link") {
          pLinksData[index].error = "Enter a valid URL"
        } else {
          pLinksData[index].id = res.data.id
          pLinksData[index].name = res.data.name
          pLinksData[index].icon = res.data.icon
          pLinksData[index].error = ""
          delete pLinksData[index]["notInDB"]
        }
        setLinksData([...pLinksData])
        setAddMoreLinks(false)
      })
      .catch((err) => {
        if (err.response.data.link?.length > 0) {
          pLinksData[index].error = err.response.data.link[0]
        }
        setLinksData([...pLinksData])
      })
  }

  function handleAddMoreLinks(e) {
    e.preventDefault()
    setLinksData([...linksData, { id: `el-${nextExtLinkId++}`, link: "", name: "", add_to_template: training.course_template, icon: null, notInDB: true }])
    setAddMoreLinks(true)
    setLinksDataFocusElem(`link_${linksData.length}`)
  }

  function handleDelMoreLinks(e, delIdx) {
    e.preventDefault()
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/tool-links/${linksData[delIdx].id}/`, { headers: DefaultHeader })
      .then((res) => {
        setLinksData(linksData.filter((n, i) => delIdx !== i))
      })
      .catch((err) => {
        console.error(err)
      })
  }

  function showLinksData() {
    return (
      <Col
        className="px-0 border_on_hover pl-0"
        style={{
          backgroundColor: "#303C54",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "9px",
          border: "1px solid #303C54",
          marginBottom: "12px",
          marginTop: "12px",
        }}
      >
        <InputGroup className="py-0 d-block " style={{ borderRadius: "9px", border: "1px solid transparent" }}>
          <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
            <InputGroup.Text
              className="border-0 d-flex justify-content-center pb-0 mb-2"
              style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
              id="basic-addon1"
            >
              {"Links to download"}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <div
            className={`mx-2`}
            style={{ maxHeight: "250px", overflowY: "scroll", width: "98%", borderTop: "1px solid #929BBD" }}
            id="divExtLinks"
            onScroll={() => {
              getNextExtLinksOnScroll()
            }}
          >
            {linksData.map((n, index) => {
              let clBorder = n?.error && n.error != "" ? "mt-0 border-danger" : "mt-2"
              return (
                <>
                  <div key={`extLinks_${n.id}`}>
                    {n.error && n.error !== "" ? (
                      <div key={`linkMsg_${n.id}`} id={`linkMsg_${index}`} className="d-inline-flex px-3 py-0">
                        <FontAwesomeIcon className="mt-2 mr-1" icon={faExclamationTriangle} color="red" size="sm" style={{ height: "14px" }} />
                        <p className="mb-0 pt-1 text-danger" style={{ fontSize: "14px" }}>
                          {n.error}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <InputGroup
                      className={`d-flex mx-2 mb-2 ${clBorder}`}
                      style={{ borderRadius: "9px", border: "1px solid transparent", backgroundColor: "#42506C", width: "99%" }}
                    >
                      <InputGroup.Prepend>
                        {n.icon !== null ? (
                          <img src={n.icon} className="m-0 mt-1 ml-1" style={{ width: "25px", objectFit: "contain" }} />
                        ) : (
                          <FontAwesomeIcon className="m-0 mt-2 ml-1" icon={faLink} color="#fff" size="1x" style={{ width: "25px", objectFit: "contain" }} />
                        )}
                      </InputGroup.Prepend>
                      <FormControl
                        className={`border-0 d-inline-flex shadow-none text-white`}
                        style={{ backgroundColor: "#42506C", borderRadius: "0px", fontSize: "16px" }}
                        autoComplete="off"
                        type="text"
                        name="link"
                        defaultValue={n.link}
                        onChange={(e) => {
                          e.preventDefault()
                          if (e.target.value !== "") {
                            setLinksDataLoading((s) => {
                              let v = { ...s }
                              v[`l_${n.id}`] = true
                              return v
                            })
                            delayedSaveExtLink(index, e.target.value, linksData)
                            setLinksDataLoading((s) => {
                              let v = { ...s }
                              v[`l_${n.id}`] = false
                              return v
                            })
                          }
                        }}
                        onBlur={(e) => {
                          e.preventDefault()
                          if (e.target.value !== "") {
                            setLinksDataLoading((s) => {
                              let v = { ...s }
                              v[`l_${n.id}`] = true
                              return v
                            })
                            handleLinkSave(index, e.target.value, linksData)
                            setLinksDataLoading((s) => {
                              let v = { ...s }
                              v[`l_${n.id}`] = false
                              return v
                            })
                          }
                        }}
                        id={`link_${index}`}
                        key={`keyLink_${n.id}`}
                        placeholder="Add Link"
                        disabled={n["notInDB"] ? false : true}
                      />
                      <InputGroup.Append className={`mx-1`}>
                        {n["notInDB"] && linksDataLoading[`l_${n.id}`] === false ? (
                          <InputGroup.Text className={`bg-transparent border-0`} id={`basic-addon`}>
                            <Spinner animation="border" variant="danger" size="sm" />
                          </InputGroup.Text>
                        ) : (
                          ""
                        )}
                        {!n["notInDB"] ? (
                          <div
                            className={`border-0 rounded-circle`}
                            style={{ backgroundColor: "#303C54", height: "75%", padding: "0px 6px 1px 6px", margin: "6px 0" }}
                            onClick={(e) => {
                              handleDelMoreLinks(e, index)
                            }}
                          >
                            <FontAwesomeIcon className={`mb-0`} icon={faTrash} color="#fff" size="1x" style={{ height: "12px", objectFit: "contain" }} />
                          </div>
                        ) : (
                          ""
                        )}
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </>
              )
            })}
          </div>
          <InputGroup.Append className={`p-2 mt-1`}>
            {linksDataNextURL !== null ? (
              <>
                <u
                  className="pointer"
                  style={{ fontSize: "14px", color: "#E0E2E5" }}
                  onClick={(e) => {
                    fetchExtLinksNext(e)
                  }}
                >
                  Show More
                </u>
                <span>&nbsp;&nbsp;</span>
              </>
            ) : (
              ""
            )}
            {addMoreLinks ? (
              <OverlayTrigger key={`top`} placement={`top`} overlay={<Tooltip>Please add a link in already enabled</Tooltip>}>
                <u className="pointer" style={{ fontSize: "14px", color: "#E0E2E5" }}>
                  Add More
                </u>
              </OverlayTrigger>
            ) : (
              <u
                className="pointer"
                style={{ fontSize: "14px", color: "#E0E2E5" }}
                onClick={(e) => {
                  handleAddMoreLinks(e)
                }}
                disabled={addMoreLinks}
              >
                Add More
              </u>
            )}
          </InputGroup.Append>
        </InputGroup>
      </Col>
    )
  }

  function handlePublishClass() {
    setclickedTrainerDetailsNext(true)
    let ele = document.getElementById("error")
    if (ele !== null) {
      ele.style.display = "none"
      setTimeout(() => {
        document.getElementById("error").style.display = "block"
      }, 100)
    }
    if (selectedTrainers.length > 0 && enteredValidTime) {
      setActiveSection("trainer_details")
    } else {
      setActiveSection("class_details")
    }
  }

  return (
    <div>
      <SEO title="Training Edit" />
      {/* <h5 className='border-bottom p-2'>
        <Link className={`pointer text-white mr-2`} to={`/app/dashboard`} > <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} /></Link>
        <span>Class Edit</span>
        <Link className={`pointer text-white`} to={`/app/companies/${location.company_id}/courses/view/${location.course_id}`} >
        <span style={{fontWeight: 'normal'}} className='float-right small'> Exit Creation </span>
        </Link> 
      </h5> */}
      <Col xs="12" sm="12" md="10" lg="10" className="d-inline-flex px-0" style={{ marginLeft: "7%", marginBottom: "12px" }}>
        <div
          className={`turient-body-text-color w-100 d-flex`}
          style={{ backgroundColor: `#212c42`, borderRadius: "9px", boxShadow: "0px 3px 12px #00000099" }}
        >
          <p
            className={`mb-0 pl-14px pr-3 font-weight-bold pointer pt-1 pb-2 mr-1 ${Styles.grad} `}
            id="new_course"
            style={{ borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "20px" }}
          >
            {training.name}
          </p>
          <div
            className={`float-left align-self-center text-right`}
            onClick={() => setDuplicateClass(!duplicateClass)}
            onMouseEnter={() => {
              setDuplicateClass(!duplicateClass)
            }}
          >
            <FontAwesomeIcon
              className="float-left pointer"
              icon={duplicateClass ? faChevronCircleUp : faChevronCircleDown}
              style={{ width: `20px`, height: `20px`, color: `#E0E2E5` }}
            />
          </div>
          {HasCreatePermission(CLASSES) && (
            <div
              className={`float-left position-absolute ${duplicateClass ? "" : "d-none"}`}
              onMouseLeave={() => {
                setDuplicateClass(false)
              }}
              style={{
                top: "45px",
                border: "1px solid #E0E2E5",
                backgroundColor: "#212C42",
                zIndex: "2",
                boxShadow: "0px 6px 12px #00000099",
                borderRadius: "9px",
              }}
            >
              <div
                className={`mb-0 py-1 px-2 ${Styles.duplicate_class} pointer`}
                style={{ borderBottom: "1px solid #707070", borderTopLeftRadius: "9px", borderTopRightRadius: "9px", fontSize: "14px" }}
              >
                <Link className={`${Styles.duplicate_class_text}`} to={`/app/companies/${location.company_id}/courses/new/trainings/create`}>
                  Create a new class
                </Link>
              </div>
              <div
                className={`mb-0 py-1 px-2 ${Styles.duplicate_class} pointer`}
                style={{ borderBottomLeftRadius: "9px", borderBottomRightRadius: "9px", fontSize: "14px" }}
              >
                <Link to={`/app/companies/${location.company_id}/courses/new/trainings/duplicate`} className={`${Styles.duplicate_class_text}`}>
                  Duplicate an existing class
                </Link>
              </div>
            </div>
          )}
          {/* <p className="mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2" id='course_detail' onClick={()=>setActiveSection('class_details')}  style={{fontSize:'16px',color:'#E0E2E5',backgroundColor:activeSection === 'class_details' ? "#303C54" : null}}>Class Details</p>
          <p className="mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2" id='course_content' onClick={()=>{setActiveSection('trainer_details');setClickedPublish(false)}} style={{fontSize:'16px',color:'#E0E2E5',backgroundColor:activeSection === 'trainer_details' ? "#303C54" : null}} >Trainer Details</p>
          <p className="mb-0 pl-14px pr-14px ml-10px pointer pt-2 pb-2" id='publish_course' onClick={()=>selectedTrainers.length >= 1 ? setActiveSection('publish_class') : null} style={{fontSize:'16px',color:'#E0E2E5',backgroundColor:activeSection === 'publish_class' ? "#303C54" : null}}>Publish Class</p> */}
        </div>
      </Col>
      {activeSection === "class_details" ? (
        <React.Fragment>
          <Form
            className={`form-horizontal ${!HasEditPermission(CLASSES) && "disable-content"}`}
            onSubmit={(e) => {
              handleSubmit(e, "form")
            }}
          >
            <Col xs="12" sm="12" md="10" lg="10" className={`w-100 mr-0 pt-2 mb-0 bg-212C42 ${Styles.create_training_content_div}`}>
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className={`mb-2 align-items-center ${!selectCourseCollapdsed ? `px-0` : null} w-100`}
                style={{
                  textAlign: "left",
                  backgroundColor: "#303C54",
                  paddingTop: "18px",
                  paddingBottom: "18px",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "9px",
                  border: !selectCourseCollapdsed ? "1px solid white" : null,
                }}
              >
                <Col
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  className={`px-0 d-inline-flex w-100`}
                  style={{ borderBottom: !selectCourseCollapdsed ? "1px solid #63666f" : null }}
                >
                  <div className="float-left align-self-center" style={{}}>
                    {training.course_name !== null || training.course_name !== "" ? (
                      <div className="d-inline-flex align-items-center">
                        <p className="d-inline-flex mb-0 pr-2" style={{ fontSize: "16px", color: "#838B9E" }}>
                          Course:{" "}
                        </p>
                        <p className="d-inline-flex mb-0" style={{ fontSize: "16px", color: "#E0E2E5", fontWeight: "500" }}>
                          {training.course_name}{" "}
                        </p>
                        <p className="d-inline-flex mb-0 ml-1 mr-3" style={{ fontSize: "16px" }}>
                          {`(` + courseLength + `${courseLength > 1 ? ` days)` : ` day)`}`}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Col>
              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className={`pointer mb-2 ${selectTrainerCollapdsed ? `border_on_hover` : null} w-100  px-0`}
                style={{
                  textAlign: "left",
                  backgroundColor: "#303C54",
                  position: "relative",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "9px",
                  border: !selectTrainerCollapdsed ? "1px solid white" : "1px solid transparent",
                }}
              >
                <Col
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  id="collapse-selected-trainers"
                  onClick={() => {
                    setSelectTrainerCollapsed(!selectTrainerCollapdsed)
                    updateTrainers()
                  }}
                  className={`pointer align-items-center ${selectedTrainers.length === 0 && selectTrainerCollapdsed ? `py-3` : `py-2`} d-inline-flex w-100`}
                  style={{ borderBottom: !selectTrainerCollapdsed ? "1px solid #63666f" : "1px solid transparent" }}
                >
                  <div className="float-left align-self-center" style={{ width: "95%" }}>
                    {selectedTrainers.length > 0 ? (
                      <div className="d-inline-flex">
                        <p
                          className={`d-inline-flex mb-0 pt-2`}
                          style={{ fontSize: "16px", paddingRight: "13px", color: selectTrainerCollapdsed ? "#838B9E" : "#C3C7CE" }}
                        >
                          {selectedTrainers.length > 1 ? "Trainers:" : "Trainer:"}{" "}
                        </p>
                        {selectedTrainers.map((item, indx) => (
                          <div
                            className="px-2 py-2 mr-2 align-items-center bg-42506C d-flex align-items-center fs-16px turient-header-text-color bold-500"
                            key={indx}
                            style={{ boxShadow: "0px 3px 6px #00000029" }}
                          >
                            <AvatarCircle size={"30px"} name={item.name} avatar={item.profile_pic} />
                            <span className="ml-1">{item.name}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p
                        className={`d-inline-flex float-left mb-0 d-flex justify-content-center`}
                        style={{ fontSize: "16px", color: selectTrainerCollapdsed ? "#838B9E" : "#C3C7CE" }}
                      >
                        Select Trainers{" "}
                      </p>
                    )}
                  </div>
                  <div className={`float-right align-self-center text-right`} style={{ width: "5%" }}>
                    <FontAwesomeIcon
                      onClick={() => {
                        setSelectTrainerCollapsed(!selectTrainerCollapdsed)
                      }}
                      className="float-right pointer"
                      icon={selectTrainerCollapdsed ? faChevronCircleDown : faChevronCircleUp}
                      style={{ width: `20px`, height: `20px`, color: `#E0E2E5` }}
                    />
                  </div>
                </Col>
                {selectTrainerCollapdsed ? null : (
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="pointer my-2 text-left"
                    style={{ backgroundColor: "#303C54", borderRadius: "9px", position: "relative", paddingBottom: "49px" }}
                  >
                    <Col lg="12" md="12" sm="12" xs="12" className="px-0" style={{ maxHeight: "250px", overflowY: "scroll" }}>
                      {trainers.map((item, index) => getSingleTrainer(item, index))}
                    </Col>
                    <Col xs="12" className=" ml-0 px-0 float-left align-items-center d-inline-flex">
                      <button
                        onClick={() => {
                          setSelectTrainerCollapsed(true)
                          setclickedTrainerDetailsNext(true)
                        }}
                        className="border_on_hover px-3 float-left"
                        style={{
                          backgroundColor: "#586886",
                          border: "1px solid #586886",
                          boxShadow: "0px 3px 6px #00000029",
                          borderRadius: "23px",
                          color: "#E0E2E5",
                          fontSize: "14px",
                        }}
                      >
                        Done
                      </button>
                      <p className="pt-3 ml-2" style={{ fontSize: "14px", color: "#E0E2E5" }}>
                        {selectedTrainers.length} Trainers Selected
                      </p>
                      <div className="px-3 ml-auto">
                        <button
                          onClick={() => {
                            openNewWindow(
                              `/app/school-setup/school-profile-setup/${localStorage.getItem("company_id")}/educator/educator-profile-setup/new?redirect=${
                                window.location.href
                              }`
                            )
                          }}
                          className={`border_on_hover px-3`}
                          style={{
                            backgroundColor: "#586886",
                            border: "1px solid #586886",
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "23px",
                            color: "#E0E2E5",
                            fontSize: "14px",
                          }}
                        >
                          Add New Trainer
                        </button>
                      </div>
                    </Col>
                  </Col>
                )}
              </Col>
              {selectedTrainers.length < 1 && fetchedTrainingData ? (
                <Col id="error" xs="12" sm="12" md="12" lg="12" className={`mt-2 px-0`}>
                  <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
                    <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                    <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                      Please select the trainers to proceed
                    </p>
                  </div>
                </Col>
              ) : null}
              <Staff
                teamsData={teamsData}
                training={training}
                setTraining={setTraining}
                setTrainingDataUpdated={setTrainingDataUpdated}
                teamsErrMsg={teamsErrMsg}
              />
              <Col lg="12" md="12" sm="12" xs="12" className="pointer mt-2 px-0 pl-0 d-inline-flex" style={{ textAlign: "left", borderRadius: "9px" }}>
                <InputGroup className="border_on_hover py-1" style={{ backgroundColor: "#303C54", borderRadius: "9px", border: "1px solid transparent" }}>
                  <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                    <InputGroup.Text
                      className="border-0"
                      onClick={() => {
                        document.getElementById("name").focus()
                      }}
                      style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                      id="basic-addon1"
                    >
                      Class Name:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 ml-0 pl-0 shadow-none ${Styles.class_form} ${StylesM.course_create}`}
                    style={{ backgroundColor: "#303C54", color: "#E0E2E5", borderRadius: "0px", fontSize: "16px" }}
                    type="text"
                    name="name"
                    defaultValue={training.name}
                    onChange={(e) => {
                      e.preventDefault()
                      setLoader(true)
                      delayedQuery(e.target.name, e.target.value)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === training.name ? null : e.target.name, e.target.value)
                    }}
                    id="name"
                    placeholder="Name for your class"
                    required
                  />
                </InputGroup>
              </Col>

              <Col
                lg="12"
                md="12"
                sm="12"
                xs="12"
                className="border_on_hover align-items-center pointer mt-2 mb-2 px-0 py-1 pl-0 d-inline-flex"
                style={{ textAlign: "left", backgroundColor: "#303C54", borderRadius: "9px", border: "1px solid transparent" }}
              >
                <div className={`d-flex ${Styles.label_datetime}`}>
                  <label className={`${Styles.label_basic} ${Styles.label_datetime_start}`}>Start Date and Start Time:</label>
                  <CustomDateTimePicker
                    value={training_start_date}
                    onChange={(val) => {
                      setEnteredValidTime(true)
                      setLoader(true)
                      handleDateChange(val, "start_date")
                    }}
                    minDate={minStartDate}
                  />
                </div>
                <div className={`d-flex ${Styles.label_datetime}`}>
                  <label className={`${Styles.label_basic} ${Styles.label_datetime_end}`}>End Date and End Time:</label>
                  <CustomDateTimePicker
                    value={training_end_date}
                    onChange={(val) => {
                      setEnteredValidTime(true)
                      setLoader(true)
                      handleDateChange(val, "end_date")
                    }}
                    minDate={minEndDate}
                  />
                </div>
                <img
                  src={CalendarAlt}
                  alt="calendar"
                  onClick={() => {
                    document.querySelectorAll(".react-datetime-picker__inputGroup__day").forEach((element) => {
                      element.focus()
                    })
                  }}
                  className={`mb-0 pointer ${Styles.calendar_icon}`}
                />
              </Col>
              {isMuralEnabled && (
                <MuralAccountsList
                  onSelectMuralAccount={(muralAccount) => {
                    setTraining({ ...training, mural_account: muralAccount })
                    setTrainingDataUpdated(true)
                  }}
                  mural_account={training.mural_account_data?.id ? training.mural_account_data : training.mural_account}
                  showMuralAccounts={true}
                />
              )}
              <VideoCallLinkInput
                defaultValue={training.video_call_link}
                onChange={(e) => {
                  e.preventDefault()
                  setLoader(true)
                  delayedQuery(e.target.name, e.target.value)
                }}
                onBlur={(e) => {
                  e.preventDefault()
                  onBlurData(e.target.value === training.video_call_link ? null : e.target.name, e.target.value)
                }}
              />
              {enteredValidTime === false ? (
                <Col xs="12" sm="12" md="12" lg="12" className={`pb-3 px-0`}>
                  <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
                    <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                    <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                      {timeErrorMsg}
                    </p>
                  </div>
                </Col>
              ) : null}
              <Col lg="6" md="6" sm="12" xs="12" className={`pointer my-2 px-0 ${true ? `d-none` : `d-inline-flex`}`} style={{ textAlign: "left" }}>
                {clickedPrice ? (
                  <Col lg="12" md="12" sm="12" xs="12" className="pointer pl-0 d-inline-flex" style={{ textAlign: "left", borderRadius: "9px" }}>
                    <InputGroup className="border_on_hover" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                      <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px" }}>
                        <InputGroup.Text
                          className="border-0"
                          style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", opacity: "41%", fontSize: "16px" }}
                          id="basic-addon1"
                        >
                          Price:
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className={`border-0 ${StylesM.course_create}`}
                        style={{ backgroundColor: "#303C54", borderRadius: "0px", color: "#E0E2E5" }}
                        type="number"
                        name="price"
                        defaultValue={training.price}
                        onChange={(e) => {
                          e.preventDefault()
                          setLoader(true)
                          delayedQuery(e.target.name, e.target.value)
                        }}
                        onBlur={(e) => {
                          e.preventDefault()
                          onBlurData(e.target.value === training.price ? null : e.target.name, e.target.value)
                        }}
                        id="price"
                        placeholder="Please add a price for your class"
                        required
                      />
                    </InputGroup>
                  </Col>
                ) : (
                  <div
                    className="pointer mr-2 mb-2 px-1"
                    onClick={() => {
                      setClickedPrice(true)
                    }}
                    style={{ color: "#E0E2E5", fontSize: "12px", borderBottom: "1px solid #E0E2E5", width: "fit-content" }}
                  >
                    Add Price
                  </div>
                )}
              </Col>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="10"
              lg="10"
              className={`w-100 mr-0 d-inline-flex pt-3 pb-3`}
              style={{
                marginLeft: "7%",
                backgroundColor: "#212C42",
                boxShadow: " 0px -3px 6px #00000029",
                borderRadius: "0px 0px 9px 9px",
                borderTop: "1px solid #63666f",
              }}
            >
              <Col className="px-0">
                <div className="float-left">
                  <button type="submit" className={`border_on_hover ${Styles.publish_btn}`}>
                    Publish Class
                  </button>
                </div>
                {loader === true ? (
                  <div className="float-right d-inline-flex mr-1">
                    <svg
                      className="animate-spin my-auto ml-2"
                      style={{ height: `16px`, width: `16px` }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <p className="pt-0 mb-0 ml-2" style={{ fontSize: "16px", color: "#E0E2E5" }}>
                      All changes are saved
                    </p>
                  </div>
                ) : (
                  <p className="pt-1 mb-0 float-right mr-1" style={{ fontSize: "16px", color: "#E0E2E5" }}>
                    {" "}
                    <FontAwesomeIcon icon={faCheck} color="#E0E2E5" className="ml-3 " /> All changes are saved
                  </p>
                )}
              </Col>
            </Col>
          </Form>
        </React.Fragment>
      ) : (
        publishClass()
      )}
      <AppIntegrationModal />
    </div>
  )
}

export default EditTraining
