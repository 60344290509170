import React, { useEffect, useState, useRef, useContext } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { LazyLoadImage } from "react-lazy-load-image-component"
import SEO from "../../../../../seo"
import CropImage from "../../../../../crop-image/crop-image"
import HeaderTab from "../../../../../trainer/HeaderTab"
import Style from "./style.module.scss"
import warningIcon from "../../../../../../images/svgs/warning_icon.svg"
import fb from "../../../../../../images/svgs/fbicon.svg"
import linkedin from "../../../../../../images/svgs/linkedinicon.svg"
import twitter from "../../../../../../images/svgs/twittericon.svg"
import Camera from "../../../../../../images/svgs/camera_logo.svg"
import EducatorProfilePic from "../../../../../../images/svgs/trainer-profile-pic.svg"
import { convertBase64ToFile } from "../../../../../utilities/convertBase64ToFile"
import { b64toBlob } from "../../../../../utilities/b64toBlob"
import Loader from "../../../../../custom/Loader"
import ProfileUpdateProgress from "../../../../../ProfileProgressBar"
import { deleteRequest, getRequest, postRequest, putRequest } from "../../../../../../services/rest_service"
import { validateEmail, validateMobileNumber } from "../../../../../utilities/utils"
import Json from "../../../../../utilities/json"
import { isBrowser } from "../../../../../../services/developer_mode"
import { validateSocialMediaLink } from "../../../../../utilities/validateSocialMediaLink"
import FormInput from "./FormInput"
import { GlobalContext } from "../../../../../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../../../../../stores/global_store/GlobalActions"
import { isValidPhoneNumber } from "react-phone-number-input"
import { parsePhoneNumber } from "react-phone-number-input"

const EducatorProfileSetup = (location) => {
  const [notificationData, dispatch] = useContext(GlobalContext)
  const [activeTab, setActiveTab] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [signatureModalShow, setSignatureModalShow] = useState(false)
  const [cropData, setCropData] = useState("#")
  const [signatureCropData, setSignatureCropData] = useState("#")
  const [fileName, setFileName] = useState(null)
  const [signatureFileName, setSignatureFileName] = useState(null)
  const [showUploadVideo, setShowUploadVideo] = useState(false)
  const [showUploadSign, setShowUploadSign] = useState(false)
  const [highlightVideoUpload, setHighlightVideoUpload] = useState("unhighlight")
  const [videoFiles, setVideoFiles] = useState([])
  const [educatorDetail, setEducatorDetail] = useState({})
  const [socialMedia, setSocialMedia] = useState({})
  const [updatedFields, setUpdatedFields] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  const [videos_data, setVideosData] = useState([])
  const [socialLinks, setSocialLinks] = useState([
    { name: "facebook", icon: fb, color: "#4261A2", show: false, key: "facebook" },
    { name: "linkedin", icon: linkedin, color: "#007BB5", show: false, key: "linkedin" },
    { name: "twitter", icon: twitter, color: "#1DA1F2", show: false, key: "twitter" },
  ])
  const [socialLinksError, setSocialLinksError] = useState({
    facebook_url: false,
    facebook_url_error: "",
    linkedin_url: false,
    linkedin_url_error: "",
    twitter_url: false,
    twitter_url_error: "",
  })
  const [marginSocial, setMarginSocial] = useState(false)
  const [loader, setLoader] = useState(false)
  let updatedData = {}
  const bottomDiv = useRef(null)
  let educatorId = location.id
  const params = new URLSearchParams(location.props.location.search)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [educatorDetailError, setEducatorDetailError] = useState({
    emailError: false,
    phoneError: false,
    emailErrorMsg: "",
    phoneErrorMsg: "",
  })
  useEffect(() => {
    {
      videoFiles.length
        ? setEducatorDetail({ ...educatorDetail, progress_percentage: calculateProgressPercentage({ ...educatorDetail, trainer_video: videoFiles }) })
        : setEducatorDetail({ ...educatorDetail, progress_percentage: calculateProgressPercentage({ ...educatorDetail, trainer_video: null }) })
    }
  }, [videoFiles])

  useEffect(() => {
    if (cropData !== educatorDetail.profile_pic && cropData !== "#") {
      const avatarChanged = updatedFields.indexOf("profile_pic") === -1
      setUpdatedFields(avatarChanged ? [...updatedFields, "profile_pic"] : updatedFields)
      setEducatorDetail({
        ...educatorDetail,
        progress_percentage: calculateProgressPercentage({ ...educatorDetail, profile_pic: "uploaded" }),
        profile_pic: convertBase64ToFile(cropData, b64toBlob),
      })
    }
  }, [cropData])

  useEffect(() => {
    if (signatureCropData !== educatorDetail.trainer_signature && signatureCropData !== "#") {
      const avatarChanged = updatedFields.indexOf("trainer_signature") === -1
      setUpdatedFields(avatarChanged ? [...updatedFields, "trainer_signature"] : updatedFields)
      educatorDetail["trainer_signature"] = convertBase64ToFile(signatureCropData, b64toBlob)
    } else if (educatorDetail.trainer_signature?.name === "empty") {
      setUpdatedFields([...updatedFields, "trainer_signature"])
    }
  }, [signatureCropData])

  useEffect(() => {
    let x = false
    socialLinks.forEach((data) => {
      if (data.show) {
        x = true
      }
    })
    setMarginSocial(x)
  }, [socialLinks])

  useEffect(() => {
    if (location?.id !== "new") {
      getEducatorDetails(educatorId)
    }
  }, [])

  useEffect(() => {
    if (isBrowser()) document.getElementById("educator-box").scrollBy(0, document.getElementById("educator-box").scrollHeight)
  }, [showUploadVideo, showUploadSign])

  useEffect(() => {
    if (isDisabled) {
      setLoader(true)
    } else {
      setLoader(false)
    }
  }, [isDisabled])

  const onDragEnter = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlightVideoUpload("highlight")
  }

  const onDragOver = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlightVideoUpload("highlight")
  }

  const onDragLeave = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlightVideoUpload("unhighlight")
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    handleDrop(event)
    setHighlightVideoUpload("unhighlight")
  }

  const handleDrop = (e) => {
    var dt = e.dataTransfer
    let new_files = dt.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setVideoFiles([...videoFiles, ...filex])
        setIsDisabled(false)
      } else {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "error", title: new_files[i].name + " has size more than 200MB." },
        })
      }
    }
  }

  // function api calls
  const getEducatorDetails = async (educator_id) => {
    setLoader(true)
    let res = await getRequest(`/trainers/${educator_id}/`)
    if (res.success) {
      if (!res.data.country_code) {
        res.data = { ...res.data, country_code: "91" }
      }
      setEducatorDetail(res.data)
      if (res.data.trainer_signature) {
        setSignatureCropData(res.data.trainer_signature)
        setShowUploadSign(true)
      }
      if (res.data.trainer_video.length) {
        setShowUploadVideo(true)
      }
      if (Object.keys(res.data?.social_media ? res.data?.social_media : {})?.length) {
        let updatedSocialLinks = [...socialLinks]
        Object.keys(res.data?.social_media).map((media) => {
          updatedSocialLinks = updatedSocialLinks.map((link) => {
            if (link.name === media && res.data.social_media[media].length) {
              return { ...link, show: true }
            } else {
              return link
            }
          })
        })
        setSocialLinks(updatedSocialLinks)
      }

      setLoader(false)
    } else {
      setLoader(false)
    }
  }

  const updateEducatorDetails = async (educator_id, educatorDetail) => {
    setLoader(true)
    setIsDisabled(true)
    if (educatorId === "new") {
      let res = await postRequest(`/trainers/create/`, educatorDetail)
      if (res.success) {
        await uploadTrainerVideo(res.data.id)
        setIsDisabled(false)
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "Educator profile updated Sucesssfully!" },
        })
        setUpdatedFields([])
        if (params.get("redirect")) {
          window.location.href = params.get("redirect")
        }
      } else {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "error", title: res.data.msg ? res.data.msg : "Something went wrong!" },
        })
        setLoader(false)
        setIsDisabled(false)
      }
    } else {
      let res = await putRequest(`/trainers/${educator_id}/`, educatorDetail)
      if (res.success) {
        await uploadTrainerVideo(res.data.id)
        setIsDisabled(false)

        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "success", title: "Educator profile updated Sucesssfully!" },
        })
        setUpdatedFields([])
        window.history.back()
      } else {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "error", title: res.data.msg ? res.data.msg : "Something went wrong!" },
        })
        setLoader(false)
        setIsDisabled(false)
      }
    }
  }
  const handlePhoneNumberValidation = (value) => {
    return validateMobileNumber(value)
  }
  const handleEmailValidation = (value) => {
    return validateEmail(value)
  }
  const getCropDataProps = (cropData, fileName) => {
    setCropData(cropData)
    setFileName(fileName)
    setEducatorDetail({ ...educatorDetail, progress_percentage: calculateProgressPercentage({ ...educatorDetail, profile_pic: cropData }) })
  }
  const getSignatureCropDataProps = (cropData, fileName) => {
    setSignatureCropData(cropData)
    setSignatureFileName(fileName)
    setEducatorDetail({ ...educatorDetail, progress_percentage: calculateProgressPercentage({ ...educatorDetail, trainer_signature: cropData }) })
  }

  const calculateProgressPercentage = (data) => {
    const {
      display_name,
      name,
      designation,
      profile_pic,
      trainer_signature,
      trainer_video,
      email,
      phone,
      expertise,
      social_media,
      mini_detail,
      personal_quote,
    } = data
    const totalFields = 13 // total number of fields to be considered
    let completedFields = 1
    if (display_name && display_name?.length) {
      completedFields++
    }
    if (name && name?.length) {
      completedFields++
    }
    if (designation && designation?.length) {
      completedFields++
    }
    if (profile_pic) {
      completedFields++
    }
    if (trainer_signature) {
      completedFields++
    }
    if ((trainer_video && trainer_video?.length) || (videoFiles && videoFiles.length)) {
      completedFields++
    }
    if (email && email?.length) {
      completedFields++
    }
    if (phone) {
      completedFields++
    }
    if (expertise && expertise?.length) {
      completedFields++
    }
    if (social_media && Object.keys(social_media).length > 0) {
      let flag = 0
      for (let key in social_media) {
        if (social_media.hasOwnProperty(key) && social_media[key] !== "") {
          flag = flag + 1
        }
      }
      {
        flag && completedFields++
      }
    }
    if (mini_detail && mini_detail?.length) {
      completedFields++
    }
    if (personal_quote && personal_quote?.length) {
      completedFields++
    }
    const progressPercentage = (completedFields / totalFields) * 100
    return progressPercentage
  }
  let educator_detail = { ...educatorDetail }

  const handlePhoneInput = (value) => {
    if (value?.length) {
      setEducatorDetail({
        ...educatorDetail,
        country_code: parsePhoneNumber(value)?.countryCallingCode,
        phone: parsePhoneNumber(value)?.nationalNumber,
      })
      if (isValidPhoneNumber(value)) {
        setEducatorDetailError({ ...educatorDetailError, phoneError: false, phoneErrorMsg: "" })
      } else {
        setEducatorDetailError({ ...educatorDetailError, phoneError: true, phoneErrorMsg: "Please Enter Correct Phone Number!" })
      }
      setUpdatedFields([...updatedFields, "phone", "progress_percentage"])
    }
  }

  const handleChange = (event) => {
    if (event.target) {
      if (event.target?.name?.includes("_url")) {
        let isValidLink = validateSocialMediaLink(event.target.name, event.target.value)
        if (isValidLink) {
          setSocialLinksError({ ...socialLinksError, [event.target.name]: false, [event.target.name + "_error"]: "" })
          setSocialMedia({ ...socialMedia, [event.target.name.split("_url")[0]]: event.target.value })
          educator_detail.social_media = { ...educator_detail.social_media, [event.target.name.split("_url")[0]]: event.target.value }
          setEducatorDetail({
            ...educator_detail,
            progress_percentage: calculateProgressPercentage({
              ...educator_detail,
              social_media: { ...educator_detail.social_media },
            }),
          })
          setUpdatedFields([...updatedFields, "social_media", "progress_percentage"])
        } else {
          setSocialLinksError({ ...socialLinksError, [event.target.name]: true, [event.target.name + "_error"]: "Link not valid" })
        }
      } else {
        educator_detail[event.target.name] = event.target.value
        setEducatorDetail({
          ...educator_detail,
          progress_percentage: calculateProgressPercentage({ ...educator_detail, [event.target?.name]: event.target?.value }),
        })
        setUpdatedFields([...updatedFields, event.target.name, "progress_percentage"])
      }
    }
  }

  const handleSubmit = (event) => {
    let socialMediaFlag = socialLinksError.facebook_url || socialLinksError.twitter_url || socialLinksError.linkedin_url
    // setIsDisabled(true)
    if (
      educatorDetailError.nameError ||
      educatorDetailError.addressError ||
      educatorDetailError.phoneError ||
      educatorDetailError.descriptionError ||
      educatorDetailError.emailError ||
      educatorDetailError.websiteError
    ) {
      event.preventDefault()
      return // Exit the function without making an API call
    } else {
      if (!socialMediaFlag) {
        for (let field of updatedFields) {
          if (field === "social_media") {
            updatedData[field] = Json.toString(educator_detail.social_media)
          } else if (field === "trainer_signature") {
            if (educator_detail[field] !== null) {
              updatedData[field] = educator_detail[field]
            }
          } else if (field === "phone") {
            if (!educator_detail["country_code"] && educator_detail[field]) {
              updatedData[field] = educator_detail[field]
              updatedData["country_code"] = "+91"
            } else {
              updatedData[field] = educator_detail[field]
              updatedData["country_code"] = educatorDetail["country_code"]
            }
          } else {
            updatedData[field] = educator_detail[field]
          }
        }
        if (!educator_detail["country_code"] && educator_detail["phone"]) {
          updatedData["country_code"] = "91"
        }
        event.preventDefault()
        setLoader(true)
        setSocialLinksError({
          facebook_url: false,
          facebook_url_error: "",
          linkedin_url: false,
          linkedin_url_error: "",
          twitter_url: false,
          twitter_url_error: "",
        })
        let formData = new FormData(event.target)
        formData.delete("phone")
        formData.delete("country_code")

        formData.append("phone", educatorDetail.phone)
        formData.append("country_code", educatorDetail.country_code)
        formData.append("company", localStorage.getItem("company_id"))
        let final_educator_detail = { ...updatedData }
        Object.keys(final_educator_detail).map((detail) => {
          formData.append(detail, final_educator_detail[detail])
        })
        formData.delete("role")
        let formUpdateFlag =
          formData.get("progress_percentage") ||
          videoFiles.length ||
          final_educator_detail?.trainer_signature === "" ||
          formData.get("trainer_signature") ||
          cropData !== "#"

        formUpdateFlag ? updateEducatorDetails(educatorId, formData) : window.history.back()
      }
    }
    event.preventDefault()
    // setIsDisabled(false)
  }

  const uploadTrainerVideo = async (educator_id) => {
    setLoader(true)
    let uploadPromises = videoFiles.map(async (videoObj, index) => {
      let formData = new FormData()
      formData.append("trainer_id", educator_id)
      formData.append("channel", "turient")
      formData.append("name", videoObj.name)
      formData.append("video_file", videoObj)
      let res = await postRequest("/trainers/video/", formData)
      if (res.success) {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "success", title: "Educator Video uploaded Successfully!" },
        })
      } else {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "error", title: res.data?.msg ? res.data?.msg : "Educator video upload Failed!" },
        })
      }
    })
    await Promise.all(uploadPromises) // wait for all uploads to complete before resolving
    setLoader(false)
    videoFiles.length && getEducatorDetails(educatorId)
    setVideoFiles([])
  }
  const deleteVideo = (fileObj) => {
    let videoFileUpdated = videoFiles.filter((file) => {
      if (file.name !== fileObj.name) {
        return file
      }
    })
    setVideoFiles(videoFileUpdated)
    setEducatorDetail({ ...educatorDetail, progress_percentage: calculateProgressPercentage({ ...educatorDetail, trainer_video: videoFileUpdated }) })
  }
  const removeVideo = async (fileObj) => {
    const response = await deleteRequest(`/trainers/video/${fileObj.id}/`)
    if (response.success) {
      getEducatorDetails(educatorId)

      dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "success", title: `${fileObj.name} deleted successfuly!` },
      })
    } else {
      dispatch({
        type: SET_NOTIFICATION_DATA,
        payload: { type: "error", title: response.data.msg ? response.data.msg : "Something went wrong!" },
      })
    }
  }
  const updateSocialLinks = (index, toggle) => {
    let social_links = [...socialLinks]
    social_links[index].show = toggle
    setSocialLinks(social_links)
  }
  const handleShowSign = () => {
    setShowUploadSign(true)
    bottomDiv.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    })
  }
  const deleteSignature = () => {
    setEducatorDetail({
      ...educatorDetail,
      trainer_signature: "",
      progress_percentage: calculateProgressPercentage({ ...educatorDetail, trainer_signature: "" }),
    })
    setUpdatedFields([...updatedFields, "trainer_signature"])

    setSignatureCropData("#")
  }
  const handleShowVideo = () => {
    setShowUploadVideo(true)
    bottomDiv.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    })
  }
  return (
    <div>
      <SEO title="Educator Creation" />
      <Col xs="12" sm="12" md="12" lg="12" className="pt-5 d-flex flex-column align-items-start">
        <HeaderTab
          heading={`${educatorDetail.name ? educatorDetail.name : "Educator"}'s Profile`}
          active={activeTab}
          setActive={setActiveTab}
          list={["Personal Details"]}
          back={false}
        />
        <Col lg="12" md="12" sm="12" xs="12" className="px-0 pt-2 mx-0 mb-3 br-9px bg-212C42 inner-element-box-shadow">
          <form className="mb-0 d-flex flex-column" id="educatorform" onSubmit={handleSubmit}>
            <div id="educator-box" className={`${Style.overflow}d-flex mt-2 mb-3 px-3`}>
              <CropImage
                show={modalShow}
                onHide={() => setModalShow(false)}
                getCropDataProps={getCropDataProps}
                fileName={fileName}
                avatar={cropData !== "#" ? cropData : null}
              />
              <Col lg="2" md="2" sm="3" xs="3" className={`float-left px-0 pointer `}>
                {cropData !== "#" ? (
                  <ProfileUpdateProgress
                    percentage={educatorDetail.progress_percentage}
                    image={cropData}
                    footerImgFlag={false}
                    handleClick={(event) => {
                      event.preventDefault()
                      setModalShow(true)
                    }}
                    mainDivStyle="w-8rem h-8rem"
                    imgStyle={Style.imgStyle}
                    strokeWidth={3}
                    imgName="Educator Logo"
                  />
                ) : (
                  <ProfileUpdateProgress
                    percentage={educatorDetail.progress_percentage}
                    image={educatorDetail.profile_pic || EducatorProfilePic}
                    footerImg={Camera}
                    footerImgFlag={educatorDetail.profile_pic ? false : true}
                    handleClick={(event) => {
                      event.preventDefault()
                      setModalShow(true)
                    }}
                    mainDivStyle="w-8rem h-8rem"
                    imgStyle={Style.imgStyle}
                    footerImgStyle={Style.progressBarFooterImgStyle}
                    strokeWidth={3}
                    imgName="Default Educator Logo"
                    footerImgName="Camera Logo"
                  />
                )}
              </Col>
              <Col lg="10" md="10" sm="9" xs="9" className="float-left">
                <Row className="pl-2">
                  <FormInput
                    handleChange={handleChange}
                    handlePhoneInput={handlePhoneInput}
                    educatorDetail={educatorDetail}
                    handleEmailValidation={handleEmailValidation}
                    handlePhoneNumberValidation={handlePhoneNumberValidation}
                    socialLinks={socialLinks}
                    socialLinksError={socialLinksError}
                    updateSocialLinks={updateSocialLinks}
                    marginSocial={marginSocial}
                    showUploadVideo={showUploadVideo}
                    onDragEnter={onDragEnter}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                    videoFiles={videoFiles}
                    deleteVideo={deleteVideo}
                    removeVideo={removeVideo}
                    showVideoModal={showVideoModal}
                    setShowVideoModal={setShowVideoModal}
                    setVideoFiles={setVideoFiles}
                    videos_data={videos_data}
                    setVideosData={setVideosData}
                    showUploadSign={showUploadSign}
                    signatureModalShow={signatureModalShow}
                    setSignatureModalShow={setSignatureModalShow}
                    getSignatureCropDataProps={getSignatureCropDataProps}
                    signatureFileName={signatureFileName}
                    signatureCropData={signatureCropData}
                    deleteSignature={deleteSignature}
                    handleShowVideo={handleShowVideo}
                    handleShowSign={handleShowSign}
                  />
                </Row>
                <div className="w-100 h-10px" ref={bottomDiv}></div>
              </Col>
            </div>
            <div className={`${Style.btnDiv} d-flex px-3 py-2`}>
              <button
                type={"submit"}
                disabled={isDisabled}
                className="border_on_hover box-shadow-00000029 br-23px turient-header-text-color fs-14px mr-2 bg-586886 border border-1 border-586886 h-2rem px-30px "
              >
                Done
              </button>
              {loader && <Loader class="ml-3 mr-1" />}
            </div>
          </form>
        </Col>
      </Col>
    </div>
  )
}

export default EducatorProfileSetup
