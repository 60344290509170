import React from "react"
import { EducatorLevelBestClassValue, EducatorLevelLeastClassValue } from "./constant"
import EducatorLevelEngagementList from "./EducatorLevelEngagementList"

const EducatorLevelEngagement = (props) => {
  return (
    <div className="w-100 h-32vh d-flex flex-column">
      <EducatorLevelEngagementList title={`${props.title} Engagement Scores`} type={props.type} icon={props.icon} calender={props.calender} data={props.data} />
    </div>
  )
}

export default EducatorLevelEngagement
