import React, { useState } from "react"
import SEO from "../seo"
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap"
import { Link, navigate } from "gatsby"
import authClient from "../../services/auth"
import { useLocation } from "@reach/router"
import Notification from "../custom/Notification"
import { getRequest, postRequest } from "../../services/rest_service"
import turientimg from "../../images/turientimg.png"
import turientLogo from "../../images/favicon.png"
import Image from "../custom/Image"

const PasswordReset = () => {
  const [notificationData, setNotificationData] = useState(null)
  const [newPassword, setNewPassword] = useState("")
  const [newConfirmPassword, setNewConfirmPassword] = useState("")
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const location = useLocation()
  let uuid = new URLSearchParams(location.search).get("uuid")

  if (uuid === null) {
    navigate("/app/login")
  }
  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value)
  }

  const handleConfirmPasswordChange = (event) => {
    setNewConfirmPassword(event.target.value)
  }

  const handleToggle = () => {
    setShowNewPassword(!showNewPassword)
  }
  const handleToggleNew = () => {
    setShowNewConfirmPassword(!showNewConfirmPassword)
  }
  const handleSubmit = async (event) => {
    setIsDisabled(true)
    event.preventDefault()
    if (!newPassword || !newConfirmPassword) {
      setNotificationData({
        type: "error",
        title: "Password cannot be empty!",
      })
      setIsDisabled(false)
    } else if (newPassword !== newConfirmPassword) {
      setNotificationData({
        type: "error",
        title: "Passwords do not match!",
      })
      setIsDisabled(false)
    } else {
      const userDetail = {
        email: localStorage.getItem("username"),
        first_login_password_set: localStorage.getItem("reset_first_time_password"),
        password: newPassword,
        confirm_password: newConfirmPassword,
        confirmation_id: uuid,
      }
      let res = await postRequest(`/reset-password/`, userDetail, false)
      if (res.success) {
        setNotificationData({
          type: "success",
          title: res.data.msg,
        })
        setError("")
        fetchUser()
        navigate("/app/login")
      } else {
        setNotificationData({
          type: "error",
          title: res.msg,
        })
        setIsDisabled(false)
      }
    }
  }
  const fetchUser = async () => {
    try {
      let res = await getRequest("/fetch_or_update_learner/")
      if (res.success) {
        if (res.data["non_field_errors"] !== undefined) {
          return ""
        } else {
          localStorage.setItem("student_first_name", res.data["first_name"])
          localStorage.setItem("login_value", true)
          localStorage.setItem("student_id", res.data["id"])
          localStorage.setItem("student_profile_pic", res.data["profile_pic"] ?? "")
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  const [error, setError] = useState("")

  return (
    <div className="vw-100 vh-100">
      <SEO title="Set Password" />
      <Row className="justify-content-md-center mx-auto w-100 pb-4 mt-4">
        <Col lg="3" md="4" sm="4" className="float-left mr-0 bg-121B2B border border-1 border-121B2B pl-20px">
          <Row className="text-center border-bottom-E0E2E5 ">
            <div className="m-0 pt-0  pb-9px  mt-0 display-flex align-items-center">
              <Image alt="Turient logo" src={turientLogo} className="d-inline-block align-center w-50px h-50px" />{" "}
              <Link to="/" className="ml-2 turient-body-text-color fs-32px text-decoration-none font-weight-bold">
                {"Turient"}
              </Link>
            </div>{" "}
          </Row>
          <p className="poppins_font text_font_semibold turient-body-text-color fs-36px pt-18px mb-10px bold-500 ">
            Welcome to
            <br /> your Online School
          </p>
          <p className="poppins_font fs-21px">You are just a step away!</p>
          <Row>
            <Image src={turientimg} alt="turientmg" />
          </Row>
        </Col>
        <Col lg="4" md="6" sm="6" className="pt-2 pb-2 d-flex pl-35 pr-35 bg-212C42 pt-18px">
          <Form className="mt-4 w-100" onSubmit={handleSubmit}>
            <h2 className="poppins_font">Set Password</h2>
            <Form.Label className="poppins_font fs_18px">New Password</Form.Label>
            <Form.Control
              className="registration-input font-w500"
              type={showNewPassword ? "text" : "password"}
              name="password"
              placeholder="Enter your password"
              value={newPassword}
              onChange={handlePasswordChange}
            />
            <Col className="mt-2 pl-0">
              <InputGroup>
                <Form.Check type="checkbox" id="showpw1" onClick={handleToggle} />
                <Form.Label className="poppins_font ml-1 fs_18px" htmlFor="showpw">
                  Show Password
                </Form.Label>
              </InputGroup>
            </Col>
            <Form.Label className="poppins_font fs_18px mt-4">Confirm New Password</Form.Label>
            <Form.Control
              className="registration-input font-w500"
              type={showNewConfirmPassword ? "text" : "password"}
              name="password"
              placeholder="Enter your password"
              value={newConfirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <Col className="mt-2 pl-0">
              <InputGroup>
                <Form.Check type="checkbox" id="showpw2" onClick={handleToggleNew} />
                <Form.Label className="poppins_font ml-1  fs_18px" htmlFor="showpw">
                  Show Password
                </Form.Label>
              </InputGroup>
            </Col>
            {error && <div className="text-danger">{error}</div>}
            <Col className="mt-4 pl-0">
              <Button
                type="submit"
                size="sm"
                disabled={isDisabled}
                className="border_on_hover header-item-active p-2 turient-header-text-color br-23px w-8rem border border-1 border-transparent"
              >
                Set Password
              </Button>
            </Col>
          </Form>
        </Col>
        {/* </div> */}
      </Row>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default PasswordReset
