import React, { useEffect, useState, useCallback } from "react"
import _ from "lodash"

import Col from "react-bootstrap/Col"
import Spinner from "react-bootstrap/Spinner"

import SEO from "../../components/seo"
import Btn from "../custom/Button"
import SuccessModal from "./_success-modal"
import DeleteModal from "./_delete-modal"
import { isBrowser } from "../../services/developer_mode"
import Image from "../custom/Image"
import CheckIcon from "../../images/svgs/check-white.svg"
import ExclamationTriangle from "../../images/svgs/exclamation-triangle.svg"
import {
  getMatchingData,
  updateMatchingData,
  addMatchingRow,
  deleteMatchingRow,
  addMatchingColumn,
  deleteMatchingColumn,
  addMatchingAnswerCell,
  deleteMatchingAnswerCell,
  updateQuestionData,
  updateColumnData,
  updateAnswerCellData,
  publishMatchTheFollowing,
} from "./_matching_apis"

import SelectCategoryField from "./_select_category_field"
import RowColumnTable from "./_row_column_table"
import NameField from "./_name_field"
import InstructionsField from "./_instructions_field"

import StylesA from "./matching.module.scss"
import StylesB from "../courses/course.module.scss"

const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

const CreateMatchTheFollowing = (location, props) => {
  const [mftErrors, setMftErrors] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showRowDeleteModal, setShowRowDeleteModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [savedContent, setSavedContent] = useState(true)
  const [matchingData, setMatchingData] = useState({})
  const [fetchedMatchingData, setFetchedMatchingData] = useState(false)
  const [activeDeleteColumnData, setActiveDeleteColumnData] = useState(null)
  const [activeDeleteRowData, setActiveDeleteRowData] = useState(null)
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [showCategoryChangeWarningModal, setShowCategoryChangeWarningModal] = useState(false)
  const [scrollToRight, setScrollToRight] = useState(false)

  useEffect(() => {
    if (location.id) {
      fetchMatchTheFollowingData()
    }
  }, [location.id])

  //Function for fetching the match-the-following data
  async function fetchMatchTheFollowingData() {
    const res = await getMatchingData(location.id)
    if (res.success) {
      setMatchingData(res.data)
      setFetchedMatchingData(true)
    } else {
      // showErrorMessage(res.msg)
    }
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, data) => sendQuery(name, value, data), 1000),
    []
  )

  function sendQuery(name, value, data) {
    handleUpdateMatchingData(name, value, data)
  }

  //Funcion for saving the match-the-following data
  async function handleUpdateMatchingData(type, value, matching_data) {
    setSavedContent(false)
    let formData = new FormData()
    formData.append(type, value)
    const res = await updateMatchingData(formData, location.id)
    if (res.success) {
      let dummy = { ...matching_data }
      dummy[type] = res.data[type]

      //The below if condition code is for removing extra options while changing from one-to-many to one-to-one
      if (type === "mtf_type" && value === "0") {
        setShowCategoryChangeWarningModal(false)
        dummy.rows.map((row, index) => {
          row.answers_list.map((list, col_index) => {
            if (list.length > 1) {
              dummy.rows[index].answers_list[col_index].splice(1, list.length)
            }
          })
        })
      }

      setMatchingData(dummy)
      setSavedContent(true)
      setMftErrors({})
    } else {
      if (type === "name" && value.length === 0) {
        showError("name")
      } else if (type === "duration" && value < 0) {
        showError("duration")
      }
      setSavedContent(true)
    }
  }

  function showError(type) {
    let dummy = { ...mftErrors }
    if (type === "name") {
      dummy.mft_name_error = true
    } else if (type === "duration") {
      dummy.mft_duration_error = true
    }
    setMftErrors(dummy)
  }

  function handleChange(e, type) {
    let dummy = { ...matchingData }
    if (type === "name" || type === "duration" || type === "instructions") {
      dummy[type] = e.target.value
    }
    setMatchingData(dummy)
  }

  // Function for adding a new Row
  async function handleAddRow() {
    setSavedContent(false)
    const res = await addMatchingRow(matchingData.id)
    if (res.success) {
      let dummy = { ...matchingData }
      let new_row = { ...res.data }
      dummy.rows.push(new_row)
      setMatchingData(dummy)
      setSavedContent(true)
    } else {
      setSavedContent(true)
    }
  }

  //Function for deleting a Row
  async function handleDeleteRow(questionData_id) {
    setSavedContent(false)
    const res = await deleteMatchingRow(matchingData.id, questionData_id)
    if (res.success) {
      let dummy = { ...matchingData }
      let index = matchingData.rows.findIndex((res) => res.question_data.id === questionData_id)
      dummy.rows.splice(index, 1)
      setMatchingData(dummy)
      setShowRowDeleteModal(false)
      setSavedContent(true)
    } else {
      setSavedContent(true)
    }
  }

  //Function for adding a new Column
  async function handleAddCol() {
    setSavedContent(false)
    const res = await addMatchingColumn(matchingData.id)
    if (res.success) {
      setMatchingData(res.data)
      setSavedContent(true)
      setScrollToRight(true)
    } else {
      setSavedContent(true)
    }
  }

  useEffect(() => {
    if (scrollToRight) {
      scrollScreenToTheNewColumn()
    }
  }, [scrollToRight])

  //This function is for scrolling the screen to the new  column when a new column is created
  function scrollScreenToTheNewColumn() {
    let scrollCompleted = 0
    let ele2 = document.getElementById("header_div")

    // if (matchingData.mtf_type === "0") {
    let ele1 = document.getElementById("bottom_div")
    if (ele1) {
      ele1.scrollTo({ top: 0, left: ele1.scrollWidth, behavior: "smooth" })
    }
    if (ele2) {
      ele2.scrollTo({ top: 0, left: ele2.scrollWidth, behavior: "smooth" })
    }

    var slideVar = setInterval(function () {
      for (let i = 0; i < matchingData?.rows?.length; i++) {
        // For scrolling all rows
        let ele = document.getElementById(`row_${i}`)
        console.log("data", ele.scrollLeft, ele.scrollWidth)
        if (ele) {
          ele.scrollLeft += 100
        }
      }
      scrollCompleted += 100
      if (scrollCompleted >= ele2.scrollWidth) {
        window.clearInterval(slideVar)
      }
    }, 20)
    setScrollToRight(false)
  }

  //Function for deleting a Column
  async function handleDeleteColumn(column_id) {
    setSavedContent(false)
    const res = await deleteMatchingColumn(matchingData.id, column_id)
    if (res.success) {
      let dummy = { ...matchingData }
      let index = matchingData.mtfcolumn_set.findIndex((column) => column.id === column_id)
      dummy.mtfcolumn_set.splice(index, 1)
      for (let i = 0; i < dummy.rows.length; i++) {
        // for removing column data in every Row
        dummy.rows[i].answers_list.splice(index, 1)
      }
      setMatchingData(dummy)
      setShowDeleteModal(false)
      setActiveDeleteColumnData(null)
      setSavedContent(true)
    } else {
      setSavedContent(true)
    }
  }

  //Function for adding a new answer cell for one-to-many MFT
  async function handleAddMultiAnswer(questionData_id, column_id, row_index, col_index) {
    setSavedContent(false)
    let formData = new FormData()
    formData.append("answer", "")
    formData.append("question", questionData_id)
    formData.append("column", column_id)
    const res = await addMatchingAnswerCell(formData)
    if (res.success) {
      let dummy = { ...matchingData }
      dummy.rows[row_index].answers_list[col_index].push(res.data)
      setMatchingData(dummy)
      setSavedContent(true)
      setMftErrors({})
    } else {
      setSavedContent(true)
    }
  }

  //Function for deleting an answer cell for one-to-many MFT
  async function handleDeleteAnswerField(questionData_id, column_id, answer_id, row_index, col_index, col_child_index) {
    setSavedContent(false)
    let formData = new FormData()
    formData.append("question", questionData_id)
    formData.append("column", column_id)
    const res = await deleteMatchingAnswerCell(formData, answer_id)
    if (res.success) {
      let dummy = { ...matchingData }
      dummy.rows[row_index].answers_list[col_index].splice(col_child_index, 1)
      setMatchingData(dummy)
      setSavedContent(true)
    } else {
      setSavedContent(true)
    }
  }

  //Funcion for saving the match-the-following Column data
  async function handleUpdateColumnData(type, value, matching_data, column_id, col_index) {
    setSavedContent(false)
    let formData = new FormData()
    formData.append(type, value)
    const res = await updateColumnData(formData, column_id)
    if (res.success) {
      let dummy = { ...matching_data }
      dummy.mtfcolumn_set[col_index].column = res.data.column
      setMatchingData(dummy)
      setSavedContent(true)
      setMftErrors({})
    } else {
      setSavedContent(true)
    }
  }

  //Funcion for saving the match-the-following Question data
  async function handleUpdateQuestionData(type, value, matching_data, question_id, row_index) {
    setSavedContent(false)
    let formData = new FormData()
    formData.append("question", value)
    const res = await updateQuestionData(formData, question_id)
    if (res.success) {
      let dummy = { ...matching_data }
      dummy.rows[row_index].question_data.question = res.data.question
      setMatchingData(dummy)
      setSavedContent(true)
      setMftErrors({})
    } else {
      if (type === "name" && value.length === 0) {
        showError("name")
      } else if (type === "duration" && value < 0) {
        showError("duration")
      }
      setSavedContent(true)
    }
  }

  //Funcion for saving the match-the-following Answer cell data
  async function handleUpdateAnswerCellData(type, value, matching_data, answer_id, row_index, col_index, col_child_index) {
    setSavedContent(false)
    let formData = new FormData()
    formData.append(type, value)
    const res = await updateAnswerCellData(formData, answer_id)
    if (res.success) {
      let dummy = { ...matching_data }
      dummy.rows[row_index].answers_list[col_index][col_child_index].answer = res.data.answer
      setMatchingData(dummy)
      setSavedContent(true)
      setMftErrors({})
    } else {
      setSavedContent(true)
    }
  }

  //Function is for publishing the Match-The-Following
  async function handlePublishMatchTheFollowing() {
    // let count_of_empty_strings = validateMatchTheFollowing()
    if (matchingData.question_column_name?.length === 0 || matchingData.question_column_name === null) {
      setShowValidationErrors(true)
      let dummy = { ...mftErrors }
      dummy.error = "Question column name cannot be empty"
      setMftErrors(dummy)
    } else {
      let formData = new FormData()
      if (window && window.FromTrainingOrClassCreation && window.FromTrainingOrClassCreation.sequence) {
        for (let p in window.FromTrainingOrClassCreation) {
          formData.append(p, window.FromTrainingOrClassCreation[p])
        }
      }
      const res = await publishMatchTheFollowing(matchingData.id, formData)
      if (res.success) {
        if (res.data.error) {
          setShowValidationErrors(true)

          let dummy = { ...mftErrors }
          dummy.error = res.data.error
          setMftErrors(dummy)
        } else if (res.data.success === "Match the following published successfully") {
          setShowSuccessModal(true)
          if (window.opener) {
            const evt = new CustomEvent("my-refresh-resources", {
              bubbles: true,
              detail: "mtf",
            })
            window.opener?.document && window.opener.document.dispatchEvent(evt)
            window.opener.postMessage({ instruction: "payload", type: "mtf" }, window.location.origin)
            window.close()
          }
        }
      } else {
        setShowValidationErrors(true)
        let dummy = { ...mftErrors }
        dummy.error = res.msg
        setMftErrors(dummy)
      }
    }
  }

  //Function for changing the category for match-the-following
  function handleChangeCategory(type) {
    if (type !== matchingData.mtf_type) {
      if (type === "0") {
        if (checkWetherAnswersFieldsAreEmptyOrNot()) {
          setShowCategoryChangeWarningModal(true)
        } else {
          handleUpdateMatchingData("mtf_type", "0", matchingData)
        }
      } else {
        handleUpdateMatchingData("mtf_type", "1", matchingData)
      }
    }
  }

  //This function is to check wether answer fields are empty or not
  function checkWetherAnswersFieldsAreEmptyOrNot() {
    let value = false

    for (let i = 0; i < matchingData.rows.length; i++) {
      for (let j = 0; j < matchingData.rows[i].answers_list.length; j++) {
        for (let k = 0; k < matchingData.rows[i].answers_list[j].length; k++) {
          if (matchingData.rows[i].answers_list[j][k].answer.length > 0) {
            value = true
            break
          }
        }
      }
    }

    return value
  }

  function showNameFields() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="px-2 mb-2 d-inline-flex align-baseline w-100">
        <NameField
          type={`edit`}
          mftErrors={mftErrors}
          matchingData={matchingData}
          setSavedContent={setSavedContent}
          handleUpdateMatchingData={handleUpdateMatchingData}
        />
        <SelectCategoryField mtf_type={matchingData.mtf_type} handleChangeCategory={handleChangeCategory} />
        {/* <div className="col-3 pl-1 pr-0">
          <div
            className="d-inline-flex align-items-center w-100 px-2"
            style={{
              backgroundColor: "#42506C",
              borderRadius: "6px",
              boxShadow: "0px 3px 6px #00000029",
              border: mftErrors.mft_duration_error ? "1px solid #FF7458" : "",
            }}
          >
            <p className="mb-0 " style={{ fontSize: "18px", color: "#E0E2E570" }}>
              Assign Time
            </p>
            <input
              className={`${StylesA.time_filed} ml-2 pt-1 pt-0 d-flex align-items-center text-white shadow-none`}
              type="number"
              defaultValue={matchingData.duration}
              onChange={(e) => {
                setSavedContent(false)
                delayedQuery(e.target.name, e.target.value, matchingData)
              }}
              onBlur={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (e.target.value !== matchingData.duration) {
                  setSavedContent(false)
                  handleUpdateMatchingData(e.target.name, e.target.value, matchingData)
                }
              }}
              min={0}
              name="duration"
              id="duration"
              placeholder="00"
            />
            <p className="mb-0 ml-2" style={{ fontSize: "18px", color: "#E0E2E570" }}>
              Mins
            </p>
          </div>
          {mftErrors.mft_duration_error && (
            <div className="d-inline-flex px-3 py-0 pb-2">
              <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#ff7458" size="sm" style={{ height: "13px" }} />
              <p className="mb-0" style={{ fontSize: "14px", color: "#ff7458" }}>
                Duration cannot be negative
              </p>
            </div>
          )}
        </div> */}
      </Col>
    )
  }

  return (
    <div>
      <div>
        <SEO title="Match-the-following Creation" />
        <Col xs="12" className="px-4 pl-4 pt-0 d-flex align-items-center" style={{ flexDirection: "column", maxWidth: "calc(100vw - 90px)" }}>
          <Col xs="12" className={`${StylesB.course_creation_header} px-0  `}>
            <p className="mb-0 py-2 px-3 font-weight-bold" id="coursename">
              {matchingData.name || " Create Match the following"}
            </p>
          </Col>
          <Col xs="12" className="px-0">
            <div className={`px-0 py-2 ${StylesA.create_matching_div}`}>
              {fetchedMatchingData ? (
                <React.Fragment>
                  {showNameFields()}
                  <InstructionsField
                    type="edit"
                    matchingData={matchingData}
                    setSavedContent={setSavedContent}
                    handleUpdateMatchingData={handleUpdateMatchingData}
                  />
                  <RowColumnTable
                    matchingData={matchingData}
                    handleAddCol={handleAddCol}
                    handleAddRow={handleAddRow}
                    handleDeleteRow={handleDeleteRow}
                    handleAddMultiAnswer={handleAddMultiAnswer}
                    setShowDeleteModal={setShowDeleteModal}
                    setShowRowDeleteModal={setShowRowDeleteModal}
                    handleDeleteAnswerField={handleDeleteAnswerField}
                    handleDeleteColumn={handleDeleteColumn}
                    setActiveDeleteColumnData={setActiveDeleteColumnData}
                    setActiveDeleteRowData={setActiveDeleteRowData}
                    setSavedContent={setSavedContent}
                    handleUpdateMatchingData={handleUpdateMatchingData}
                    handleUpdateQuestionData={handleUpdateQuestionData}
                    handleUpdateColumnData={handleUpdateColumnData}
                    handleUpdateAnswerCellData={handleUpdateAnswerCellData}
                    showValidationErrors={showValidationErrors}
                  />
                </React.Fragment>
              ) : (
                <div className="w-100 text-center py-3">
                  <Spinner animation="border" variant="secondary" size="sm" className="mr-1" />
                </div>
              )}
            </div>
            <Col className={`px-0 pl-3 py-2 d-inline-flex w-100 align-items-center ${StylesA.create_matching_fotter}`}>
              <Btn
                type="small"
                onClick={(e) => {
                  e.preventDefault()
                  handlePublishMatchTheFollowing()
                }}
                className={`px-4 bg-586886`}
              >
                Publish
              </Btn>
              {mftErrors.error && (
                <div className="d-inline-flex px-3 py-0">
                  <Image className="mt-1 mr-1 h-13px" src={ExclamationTriangle} />
                  <p className="mb-0 fs-14px color-AA5746">{mftErrors.error}</p>
                </div>
              )}
              <span className="mb-0 ml-auto pr-4 fs-14px">
                {!savedContent ? (
                  <span className={`bg-transparent border-0`} id={`basic-addon`}>
                    <Spinner animation="border" variant="secondary" size="sm" className="mr-1" />
                  </span>
                ) : (
                  <img src={CheckIcon} alt={`changes_saved_check`} className={`ml-auto mb-0 mr-1 object-fit-contain h-12px`} />
                )}
                All changes are saved
              </span>
            </Col>
          </Col>
        </Col>
      </div>
      {activeDeleteColumnData && (
        <DeleteModal
          type={"column"}
          activeDeleteColumnData={activeDeleteColumnData}
          handleDeleteColumn={handleDeleteColumn}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
      {activeDeleteRowData && (
        <DeleteModal
          type={"row"}
          activeDeleteRowData={activeDeleteRowData}
          handleDeleteRow={handleDeleteRow}
          showDeleteModal={showRowDeleteModal}
          setShowDeleteModal={setShowRowDeleteModal}
        />
      )}
      {showCategoryChangeWarningModal && (
        <DeleteModal
          type={"category_warning"}
          mtfcolumn_set={matchingData?.mtfcolumn_set}
          showDeleteModal={showCategoryChangeWarningModal}
          setShowDeleteModal={setShowCategoryChangeWarningModal}
          handleChangeCategory={() => {
            handleUpdateMatchingData("mtf_type", "0", matchingData)
          }}
        />
      )}
      <SuccessModal showSuccessModal={showSuccessModal} setShowSuccessModal={setShowSuccessModal} />
    </div>
  )
}

export default CreateMatchTheFollowing
