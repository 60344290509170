import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import ProgressBar from "react-bootstrap/ProgressBar"
import Spinner from "react-bootstrap/Spinner"

import Layout from "../layout"
import Styles from "../../modularscss/styles.module.scss"
import SEO from "../seo"
import authClient from "../../services/auth"
// import hideMessage from '../../services/hidemessage';
import Notification from "../custom/Notification"
import Image from "../image"
import { isBrowser } from "../../services/developer_mode"

import formatDate from "../../services/format_date"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faUserCircle, faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const ViewStudent = (location, props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [studentLoader, setStudentLoader] = useState(false)
  const [student, setStudent] = useState("")
  const [classesFetched, setClassesFetched] = useState(false)
  const [classes, setClasses] = useState(false)
  const [classReportFetched, setClassReportFetched] = useState(false)
  const [classReport, setClassReport] = useState("")
  const [selectedCourse, setSelectedCourse] = useState("")
  const [selectedTraining, setSelectedTraining] = useState("")
  const [showClassReport, setShowClassReport] = useState(false)
  const [quizReports, setQuizReports] = useState([])
  const [quizReportFetched, setQuizReportFetched] = useState(false)
  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    fetchStudent()
    fetchClasses()
  }, [])
  useEffect(() => {
    if (selectedCourse) {
      setClassReport("")
      setQuizReports([])
      setClassReportFetched(false)
      setQuizReportFetched(false)
      fetchClassReport()
      fetchQuizReports()
    }
  }, [selectedCourse])

  async function fetchStudent() {
    setStudentLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/student-companies/?owner__id=${location.id}`, { headers: login_credentials })
      .then((res) => {
        setStudent(res.data.results[0])
        setStudentLoader(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }
  async function fetchClasses() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/trainer/student-trainings/${location.id}/`, { headers: login_credentials })
      .then((res) => {
        setClasses(res.data.results)
        setClassesFetched(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }
  async function fetchClassReport() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/course-resource-report/${selectedCourse}/${location.id}/${selectedTraining}/`, {
        headers: login_credentials,
      })
      .then((res) => {
        setClassReport(res.data)
        setClassReportFetched(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }
  async function fetchQuizReports() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/student-class-quiz-reports/${selectedTraining}/${location.id}/`, { headers: login_credentials })
      .then((res) => {
        setQuizReports(res.data.results)
        setQuizReportFetched(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function showStudentDetail() {
    if (studentLoader && student) {
      return (
        <div style={{ padding: "16px" }}>
          <h6>Profile Details</h6>
          <div className="d-flex my-3 pb-3" style={{ borderBottom: "1px solid gray" }}>
            <div className="text-center col-2">
              <FontAwesomeIcon icon={faUserCircle} style={{ color: `#ebc280` }} size="3x" />
              <p style={{ fontSize: ".9rem", color: "#c6cff4" }} className={`m-0 pt-2`}>
                {student.first_name}
              </p>
            </div>
            <div className="mr-3">
              <p style={{ fontSize: ".7rem", marginBottom: "5px" }}>Email</p>
              <p style={{ fontSize: ".9rem", color: "#c6cff4" }}>{student.email}</p>
            </div>
            <div>
              <p style={{ fontSize: ".7rem", marginBottom: "5px" }}>Date of Registration</p>
              <p style={{ fontSize: ".9rem", color: "#c6cff4" }}>
                {`${formatDate.displayDate(student.created)}-${formatDate.displayMonth(student.created)}-${formatDate.displayFullYear(student.created)} `}
              </p>
            </div>
          </div>
          {showClassesSection()}
        </div>
      )
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }
  function showClassesSection() {
    if (showClassReport) {
      if (classReportFetched) {
        return displayClassReport()
      } else {
        return <Spinner animation="border" size="lg" />
      }
    } else {
      if (classesFetched && classes.length > 0) {
        return (
          <table className="">
            <thead>
              <tr style={{ background: "#28324e", fontSize: ".9rem" }}>
                <td className="pl-3 py-2">Classes Enrolled</td>
                <td className="py-2">Stage</td>
                <td className="py-2">Completion Status</td>
                <td className="py-2">Pending Actions</td>
                <td className="py-2">Assessment Score</td>
                <td></td>
              </tr>
            </thead>
            <tbody>{showClasses()}</tbody>
          </table>
        )
      } else {
        return null
      }
    }
  }
  function displayClassReport() {
    return (
      <Col lg="12" md="12" sm="12" xs="12">
        <div className="d-flex mb-3">
          <div onClick={() => setShowClassReport(false)} className="d-flex px-0" style={{ alignItems: "center", width: "50%" }}>
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2 pointer" style={{ width: `21px`, height: `21px` }} />
            <div>
              <h6 className="mb-1">Class Detailed Report</h6>
              <h6 style={{ color: "#c6cff4" }}>{classReport.name}</h6>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <Link style={{ fontSize: "15px", color: "#c6cff4" }} to={`/app/students/${location.id}/training/${selectedCourse}`}>
              <div
                className="d-flex"
                style={{
                  justifyContent: "center",
                  width: "200px",
                  alignItems: "center",
                  backgroundColor: "#343D5B",
                  padding: "5px",
                  borderRadius: "6px",
                  textAlign: "center",
                }}
              >
                <Image
                  imgStyle={{ margin: 0 }}
                  style={{ width: "17px", height: "18px" }}
                  alt="Turient logo staging"
                  src="bell-icon.png"
                  className="d-inline-block align-center mr-2"
                />
                <span>{classReport.resources_count - classReport.completed_resources} Pending Tasks </span>
                <FontAwesomeIcon className="ml-1" icon={faChevronRight} size="sm" />
              </div>
            </Link>
          </div>
        </div>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="py-3 d-flex"
          style={{ backgroundColor: "#1E2643", alignItems: "flex-end", boxShadow: "0px 7px 12px #00000074", border: "1px solid #707070" }}
        >
          <Col lg="2" md="2" sm="2" xs="2">
            <p style={{ fontSize: "17px", marginBottom: "5px" }}>Overall Completion</p>
            <ProgressBar
              bsPrefix="default-report-progress-bar"
              style={{ height: "1.4rem" }}
              now={classReport.completed_percentage}
              label={`${classReport.completed_percentage}%`}
            />
          </Col>
          {showResourceReports(classReport.course_resource_report)}
        </Col>
        {showStageReports(classReport.stage_reports)}
        <Col lg="8" md="12" sm="12" xs="12" className="pt-5 px-0">
          <h6>Knowledge Score</h6>
          {showQuizReportSection()}
        </Col>
      </Col>
    )
  }
  function showQuizReportSection() {
    if (quizReportFetched && quizReports.length > 0) {
      return (
        <table className="">
          <thead>
            <tr style={{ background: "#28324e", fontSize: ".9rem" }}>
              <td className="pl-3 py-2">Quiz Name</td>
              <td className="py-2">Attempts</td>
              <td className="py-2">Last Score</td>
              <td></td>
            </tr>
          </thead>
          <tbody>{showQuizReports()}</tbody>
        </table>
      )
    } else {
      return <p>No reports to show</p>
    }
  }
  function showQuizReports() {
    return quizReports.map((report, index) => {
      return (
        <tr key={index} style={{ fontSize: ".8rem", color: "rgb(198 207 244)", backgroundColor: "#1f2743", marginBottom: "5px" }}>
          <td className="pl-3 py-2">{report.name}</td>
          <td className="py-2">{report.reports.length}</td>
          <td className="py-2">{report.reports.length > 0 ? Math.round((report.reports[0].score / report.reports[0].total_score) * 100) : 0} %</td>
          <td
            onClick={() => navigate(`/app/students/${student.owner__id}/trainings/${selectedTraining}/quizzes/${report.resource_id}/`)}
            className={`py-2 pointer ${report.reports.length > 0 ? `` : `disabled bg-transparent`} `}
          >
            {report.reports.length > 0 ? `View Details` : `No reports`}
            <FontAwesomeIcon icon={faChevronRight} className={`ml-1 ${report.reports.length > 0 ? `` : `d-none`} `} size="sm" />
          </td>
        </tr>
      )
    })
  }
  function showStageReports(stage_reports) {
    return stage_reports.map((stage, index) => {
      return (
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="py-3 d-flex"
          style={{
            backgroundColor: "#1E2643",
            alignItems: "flex-end",
            boxShadow: "0px 7px 12px #00000074",
            borderLeft: "1px solid #707070",
            borderRight: "1px solid #707070",
            borderBottom: "1px solid #707070",
          }}
        >
          <Col lg="2" md="2" sm="2" xs="2">
            <p style={{ fontSize: "16px", marginBottom: "5px" }}>{stage.name}</p>
            <ProgressBar
              bsPrefix="default-report-progress-bar"
              style={{ height: "1.3rem" }}
              now={stage.completed_percentage}
              label={`${stage.completed_percentage}%`}
            />
          </Col>
          {showResourceReports(stage.report)}
        </Col>
      )
    })
  }
  function showResourceReports(reports) {
    let columns = []
    for (const property in reports) {
      let name = `${property}s`
      if (property === "turientimage") {
        name = "Articles"
      }
      if (property === "quiz") {
        name = "Quizzes"
      }
      let percentage = 0
      if (reports[property].count > 0) {
        percentage = (reports[property].completed / reports[property].count) * 100
      }
      columns.push(
        <Col lg="2" md="2" sm="2" xs="2">
          <p className="text-capitalize" style={{ fontSize: "15px", marginBottom: "5px" }}>
            {name}
          </p>
          <ProgressBar bsPrefix="default-report-progress-bar" style={{}} now={Math.round(percentage)} label={`${Math.round(percentage)}%`} />
        </Col>
      )
    }
    return columns
  }
  function showClasses() {
    return classes.map((student_class, index) => {
      return (
        <tr key={index} style={{ fontSize: ".8rem", color: "rgb(198 207 244)", backgroundColor: "#1f2743", marginBottom: "5px" }}>
          <td className="pl-3 py-2">{student_class.course}</td>
          <td className="py-2">{student_class.stage}</td>
          <td className="py-2">
            <ProgressBar
              bsPrefix="default-report-progress-bar"
              style={{ width: "100px" }}
              now={student_class.course_completion}
              label={`${student_class.course_completion}%`}
            />
          </td>
          <td className="py-2">{student_class.pending_tasks}</td>
          <td className="py-2">Not available yet</td>
          <td
            className="py-2 pointer"
            onClick={() => {
              setShowClassReport(true)
              setSelectedTraining(student_class.training_id)
              setSelectedCourse(student_class.course_id)
            }}
          >
            View Report
            <FontAwesomeIcon icon={faChevronRight} className="ml-1" size="sm" />
          </td>
        </tr>
      )
    })
  }

  return (
    <div>
      <SEO title="Student" />
      <Col lg="12" md="12" sm="12" xs="12">
        <h4 className={`mt-3`}>
          <Link className={`pointer text-white mr-2`} to={`/app/students`}>
            {" "}
            <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
          </Link>
          <span>All Students</span>
        </h4>
        <div className={`bg-card ${Styles.b_radius_15} mb-5`} style={{ overflow: "scroll" }}>
          {showStudentDetail()}
        </div>
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default ViewStudent
