import React, { useEffect, useState } from "react"
import { AuthCodeProvider } from "react-oauth2-authcode-provider"
import config, { setMuralData } from "./config"
import { postRequest, putRequest } from "../../services/rest_service"

const MuralAuth = (location, props) => {
  const [askMuralAuthentication, setaskMuralAuthentication] = useState(false)
  const [authStatus, setAuthStatus] = useState(true)
  let redirectUrl = new URLSearchParams(location.props.location.search).get("redirect_url")
  let muralAuthId = new URLSearchParams(location.props.location.search).get("muralAuthId")
  let forceAuthotization = new URLSearchParams(location.props.location.search).get("forceAuthotization")

  if (redirectUrl) {
    localStorage.setItem("redirect_url", redirectUrl)
  }
  if (muralAuthId) {
    localStorage.setItem("muralAuthId", muralAuthId)
  }
  if (forceAuthotization) {
    localStorage.removeItem(config.accessTokenKey)
  }

  useEffect(() => {
    setaskMuralAuthentication(true)
  }, [])

  const refreshToken = async () => {
    let payload = {
      refresh_token: localStorage.getItem("mural.refresh_token"),
      company: localStorage.getItem("company_id"),
    }

    try {
      let response = await postRequest(`/murals/verify_mural_auth_code/`, payload)
      if (response.success) {
        setMuralData(response.data.access_token, response.data.expires_at)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const request = async (code) => {
    let tempUrl = localStorage.getItem("redirect_url")
    if (tempUrl && !tempUrl.includes(window.location.host)) {
      localStorage.removeItem("redirect_url")
      window.location = `${tempUrl}`
      return
    }

    let payload = {
      redirect_uri: config.callBackPath,
      code: code,
      company: localStorage.getItem("company_id"),
      mural_auth_id: localStorage.getItem("muralAuthId") ? localStorage.getItem("muralAuthId") : null,
    }

    try {
      let response = await postRequest(`/murals/verify_mural_auth_code/`, payload)
      if (response.success) {
        setMuralData(response.data.access_token, response.data.expires_at)
        if (tempUrl) {
          localStorage.removeItem("redirect_url")
          localStorage.removeItem("muralAuthId")
          if (tempUrl.includes("dashboard")) {
            sessionStorage.setItem("openAppIntegrationModal", "true")
          }
          window.location = `${tempUrl}`
          return
        }
      } else {
        setAuthStatus(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onGetAuthCode = (data) => {
    setaskMuralAuthentication(false)
    request(data)
  }

  return askMuralAuthentication ? (
    <AuthCodeProvider authenticationProps={config} onReceiveAuthCode={(data) => onGetAuthCode(data)}>
      <div>Your app will go here</div>
    </AuthCodeProvider>
  ) : (
    <div className="mt-2 ml-2">{authStatus ? "Mural Authorization Done." : "Something went wrong."}</div>
  )
}

export default MuralAuth
