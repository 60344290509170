import React from "react"
import { Router } from "@reach/router"

import Dashboard from "../components/dashboard"

import CompanyCreate from "../components/companies/create"
import CompanyEdit from "../components/companies/edit"
import CompanyView from "../components/companies/view"

import CourseCreate from "../components/courses/create"
import CourseEdit from "../components/courses/edit"
import CourseView from "../components/courses/view"
import CourseInformation from "../components/courses/course-information"
import CourseContent from "../components/courses/course-content"
import CourseDuplicate from "../components/courses/duplicate"
import CourseCertification from "../components/courses/course-certification"
import CoursePublish from "../components/courses/course-publish"
import CourseIdentityEdit from "../components/courses/course-identity-edit"

import TrainingCreate from "../components/trainings/create"
import TrainingEdit from "../components/trainings/edit"
import TrainingView from "../components/trainings/view"
import TrainingContent from "../components/trainings/training-content"
import TrainingContentView from "../components/trainings/training-content-view"
import TrainingDupicate from "../components/trainings/duplicate"

import Videos from "../components/videos/list"
import CreateVideo from "../components/videos/create"
import EditVideo from "../components/videos/edit"

import ValuePacks from "../components/value-packs/list"
import CreateValuePack from "../components/value-packs/create"
import EditValuePack from "../components/value-packs/edit"
import ViewValuePack from "../components/value-packs/view"

import Blogs from "../components/blogs/list"
import CreateBlog from "../components/blogs/create"
import EditBlog from "../components/blogs/edit"
import ViewBlog from "../components/blogs/view"

import EskolaBlogs from "../components/eskola-blogs/list"
import CreateEskolaBlog from "../components/eskola-blogs/create"
import EditEskolaBlog from "../components/eskola-blogs/edit"

import Files from "../components/files/list"
import CreateFile from "../components/files/create"
import EditFile from "../components/files/edit"
import ViewFile from "../components/files/view"

import Links from "../components/links/list"
import CreateLink from "../components/links/create"
import EditLink from "../components/links/edit"
import ViewLink from "../components/links/view"

import Quizzes from "../components/quizzes/list"
import CreateQuiz from "../components/quizzes/create"
import EditQuiz from "../components/quizzes/edit"
import FocusAreas from "../components/quizzes/focus-areas/list"
import FocusAreaQuestions from "../components/quizzes/focus-areas/focus-areas-questions"
//import QuizQuestions from "../components/quizzes/quiz-questions"
import QuizQuestions from "../components/quizzes/new-quiz-questions"

import QuizPublish from "../components/quizzes/publish"

import Polls from "../components/polls/list"
import CreatePoll from "../components/polls/create"
import EditPoll from "../components/polls/edit"
import PollQuestions from "../components/polls/poll-questions"
import PollPublish from "../components/polls/publish"
import PollBank from "../components/polls/all-poll-questions"

import PopQuiz from "../components/pop-quiz/list"
import PopQuizPublish from "../components/pop-quiz/publish"

import CaseStudy from "../components/case-study/list"
import CaseStudyModeration from "../components/case-study/moderation"
import CaseStudyReportCard from "../components/case-study/report-card"
import CaseStudyView from "../components/case-study/view"

import Questions from "../components/questions/list"
import CreateQuestion from "../components/questions/create"
import EditQuestion from "../components/questions/edit"
import QuestionBank from "../components/questions/questionbank"

import Trainers from "../components/trainers/list"
import CreateTrainer from "../components/trainers/create"
import EditTrainer from "../components/trainers/edit"
// import ViewFile from "../components/files/view"

import CourseTemplates from "../components/default-course-templates/list"
import CreateCourseTemplate from "../components/default-course-templates/create"
import EditCourseTemplate from "../components/default-course-templates/edit"
import ViewCourseTemplate from "../components/default-course-templates/view"

import CourseTemplateResources from "../components/template-resources/list"
import CourseTemplateResourcesPublish from "../components/template-resources/publish"
import ClassroomHomePage from "../components/template-resources/classroom-home-page"

import StageCreate from "../components/default-course-templates/stages/create"
import StageView from "../components/default-course-templates/stages/view"

import ModuleCreate from "../components/default-course-templates/stages/modules/create"
import ModuleView from "../components/default-course-templates/stages/modules/view"
import ModuleViews from "../components/default-course-templates/stages/modules/views"

import TopicCreate from "../components/default-course-templates/stages/modules/topics/create"
import TopicView from "../components/default-course-templates/stages/modules/topics/view"

import TopicSingleView from "../components/default-course-templates/stages/modules/topics/singletopic"

import ModuleResourceCreate from "../components/default-course-templates/stages/modules/resources/create"
import ModuleResourceView from "../components/default-course-templates/stages/modules/resources/view"

import TopicResourceCreate from "../components/default-course-templates/stages/modules/topics/resources/create"
import TopicResourceView from "../components/default-course-templates/stages/modules/topics/resources/view"
import NewResourceDashboard from "../components/resource-dashboard"

import KalturaCreateVideo from "../components/videos/kalturavideocreate"
import KalturaEditVideo from "../components/videos/kalturavideoedit"

import MediaCreateVideo from "../components/videos/mediavideocreate"

import ClassRoom from "../components/class-room-training/class-room"
// import ZoomMeeting from "../components/class-room-training/zoom-meeting"

import Login from "../components/login"
import Signup from "../components/signup"
import ForgotPassword from "../components/forgot-password"
import Signupsucess from "../components/signupsucess"
import ResetPasswordWithOTP from "../components/reset-password-otp"

import Activate from "../components/activate"

import Upload from "../components/upload"

import EnquiryList from "../components/enquiries/enquiry"

import PrivateRoute from "../components/PrivateRoute"

import StudentList from "../components/students/list"
import ViewStudent from "../components/students/view"
import ClassReport from "../components/students/class-report"

import TrainingStudentList from "../components/trainings/students/list"

import ReportList from "../components/reports/list"

import TrainerCreate from "../components/trainer/create"
import TrainerEdit from "../components/trainer/edit"

import Checklist from "../components/checklist/list"
import CreateChecklist from "../components/checklist/create"
import EditChecklist from "../components/checklist/edit"

import MTFlist from "../components/match-the-following/list"
import CreateMTF from "../components/match-the-following/create"
import EditMTF from "../components/match-the-following/edit"

import "bootstrap/dist/css/bootstrap.min.css"
import "../modularscss/global.css"
import "../modularscss/turient.scss"
import "../modularscss/custom_datetime_picker.scss"
import TrainersList from "../components/trainer/list"
import CreateCaseStudy from "../components/case-study/create"
import UpdateCaseStudy from "../components/case-study/edit"

import MuralAuth from "../components/mural/auth"
import MuralSuccess from "../components/mural/success"
import Mural from "../components/mural/mural_list"
import SignupSuccessRedirection from "../components/SignUpSuccessRedirection"
import SchoolSetup from "../components/School/SchoolSetup"
import SchoolProfileSetup from "../components/School/SchoolProfileSetup"
import EducatorProfileSetup from "../components/School/SchoolProfileSetup/MembersPermission/Educator/EducatorProfileSetup"
import PasswordReset from "../components/PasswordReset"
import UserProfileSetup from "../components/School/SchoolProfileSetup/MembersPermission/Educator/UserProfileSetup"
import Insights from "../components/insights"
import ZoomAuth from "../components/zoom/auth"
import ZoomList from "../components/zoom/list"
import ZoomAuthentication from "../components/zoom/authentication"

const App = (props) => (
  <Router basepath="/">
    <PrivateRoute path="/" component={Dashboard} props={props} />
    <PrivateRoute path="/app/dashboard" component={Dashboard} props={props} />
    <PrivateRoute path="/app/resource-dashboard" component={NewResourceDashboard} props={props} />

    <PrivateRoute path="/app/school-setup" component={SchoolSetup} props={props} />
    <PrivateRoute path="/app/school-setup/school-profile-setup/:id" component={SchoolProfileSetup} props={props} />
    <PrivateRoute
      path="/app/school-setup/school-profile-setup/:compnay_id/educator/educator-profile-setup/:id"
      component={EducatorProfileSetup}
      props={props}
    />
    <PrivateRoute path="/app/school-setup/school-profile-setup/:compnay_id/user/user-profile-setup/:id/:id" component={UserProfileSetup} props={props} />

    <PrivateRoute path="/app/companies/create" component={CompanyCreate} props={props} />
    <PrivateRoute path={`/app/companies/edit/`} component={CompanyEdit} props={props} />
    <PrivateRoute path={`/app/companies/view/:id`} component={CompanyView} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/courses/create`} component={CourseCreate} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/course-identity/edit/:id`} component={CourseIdentityEdit} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/courses/course-information`} component={CourseInformation} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/courses/course-content/:id`} component={CourseContent} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/courses/course-certification/:id`} component={CourseCertification} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/courses/course-publish/:id`} component={CoursePublish} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/courses/edit/:id`} component={CourseEdit} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/courses/view/:id`} component={CourseView} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/courses/new/courses/duplicate`} component={CourseDuplicate} props={props} />

    <PrivateRoute path={`/app/companies/:company_id/templates/template-content/:id/:template_id`} component={CourseContent} props={props} />

    <PrivateRoute path={`/app/companies/:company_id/courses/:course_id/trainings/create`} component={TrainingCreate} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/courses/new/trainings/duplicate`} component={TrainingDupicate} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/courses/:course_id/trainings/edit/:id`} component={TrainingEdit} props={props} />
    <PrivateRoute path={`/app/companies/:company_id/courses/:course_id/trainings/view/:id`} component={TrainingView} props={props} />

    <PrivateRoute path="/app/videos" component={Videos} props={props} />
    <PrivateRoute path="/app/videos/create" component={CreateVideo} props={props} />
    <PrivateRoute path="/app/videos/edit/:id" component={EditVideo} props={props} />

    <PrivateRoute path="/app/trainings/:training_id/trainers/create" component={CreateTrainer} props={props} />
    <PrivateRoute path="/app/trainings/:training_id/trainers" component={Trainers} props={props} />
    <PrivateRoute path={`/app/trainings/training-content/:id`} component={TrainingContent} props={props} />
    <PrivateRoute path={`/app/trainings/training-content-view/:id`} component={TrainingContentView} props={props} />
    <PrivateRoute path="/app/trainers/:id/edit" component={EditTrainer} props={props} />

    <PrivateRoute path="/app/companies/:company_id/trainers/list" component={TrainersList} props={props} />
    <PrivateRoute path="/app/companies/:company_id/trainers/create" component={TrainerCreate} props={props} />
    <PrivateRoute path="/app/companies/:company_id/trainers/edit/:trainer_id" component={TrainerEdit} props={props} />

    <PrivateRoute path="/app/checklists" component={Checklist} props={props} />
    <PrivateRoute path="/app/checklist/create" component={CreateChecklist} props={props} />
    <PrivateRoute path="/app/checklist/edit/:id" component={EditChecklist} props={props} />

    <PrivateRoute path="/app/match-the-following" component={MTFlist} props={props} />
    <PrivateRoute path="/app/match-the-following/create" component={CreateMTF} props={props} />
    <PrivateRoute path="/app/match-the-following/edit/:id" component={EditMTF} props={props} />

    <PrivateRoute path="/app/blogs" component={Blogs} props={props} />
    <PrivateRoute path="/app/blogs/create" component={CreateBlog} props={props} />
    <PrivateRoute path="/app/blogs/edit/:id" component={EditBlog} props={props} />
    <PrivateRoute path="/app/blogs/view/:id" component={ViewBlog} props={props} />

    <PrivateRoute path="/app/eskola-blogs" component={EskolaBlogs} props={props} />
    <PrivateRoute path="/app/eskola-blogs/create" component={CreateEskolaBlog} props={props} />
    <PrivateRoute path="/app/eskola-blogs/edit/:id" component={EditEskolaBlog} props={props} />

    <PrivateRoute path="/app/files" component={Files} props={props} />
    <PrivateRoute path="/app/files/create" component={CreateFile} props={props} />
    <PrivateRoute path="/app/files/edit/:id" component={EditFile} props={props} />
    <PrivateRoute path="/app/files/view/:id" component={ViewFile} props={props} />

    <PrivateRoute path="/app/links" component={Links} props={props} />
    <PrivateRoute path="/app/links/create" component={CreateLink} props={props} />
    <PrivateRoute path="/app/links/edit/:id" component={EditLink} props={props} />
    <PrivateRoute path="/app/links/view/:id" component={ViewLink} props={props} />

    <PrivateRoute path="/app/quizzes" component={Quizzes} props={props} />
    <PrivateRoute path="/app/quizzes/create" component={CreateQuiz} props={props} />
    <PrivateRoute path="/app/quizzes/edit/:id" component={EditQuiz} props={props} />
    <PrivateRoute path="/app/quizzes/:quiz_id/focus-areas" component={FocusAreas} props={props} />
    <PrivateRoute path="/app/quizzes/:quiz_id/focus-areas/:f_id/questions" component={FocusAreaQuestions} props={props} />
    <PrivateRoute path="/app/quizzes/:quiz_id/questions" component={QuizQuestions} props={props} />
    <PrivateRoute path="/app/quizzes/:quiz_id/publish" component={QuizPublish} props={props} />

    <PrivateRoute path="/app/polls" component={Polls} props={props} />
    <PrivateRoute path="/app/polls/create" component={CreatePoll} props={props} />
    <PrivateRoute path="/app/polls/edit/:id" component={EditPoll} props={props} />
    <PrivateRoute path="/app/polls/:quiz_id/questions" component={PollQuestions} props={props} />
    <PrivateRoute path="/app/polls/:quiz_id/edit" component={PollPublish} props={props} />
    <PrivateRoute path="/app/poll-questions" component={PollBank} props={props} />

    <PrivateRoute path="/app/pop-quizzes" component={PopQuiz} props={props} />
    <PrivateRoute path="/app/pop-quizzes/:quiz_id/edit" component={PopQuizPublish} props={props} />

    <PrivateRoute path="/app/case-study" component={CaseStudy} props={props} />
    <PrivateRoute path="/app/case-study/create" component={CreateCaseStudy} props={props} />
    <PrivateRoute path="/app/case-study/edit/:id" component={UpdateCaseStudy} props={props} />

    <PrivateRoute path="/app/case-study/moderation/:id" component={CaseStudyModeration} props={props} />
    <PrivateRoute path="/app/case-study/report-card/:id" component={CaseStudyReportCard} props={props} />
    <PrivateRoute path="/app/case-study/view/:id" component={CaseStudyView} props={props} />

    <PrivateRoute path="/app/questions" component={Questions} props={props} />
    <PrivateRoute path="/app/questionbank" component={QuestionBank} props={props} />
    <PrivateRoute path="/app/questions/create" component={CreateQuestion} props={props} />
    <PrivateRoute path="/app/questions/edit/:id" component={EditQuestion} props={props} />

    <PrivateRoute path="/app/default-course-templates" component={CourseTemplates} props={props} />
    <PrivateRoute path="/app/default-course-templates/create" component={CreateCourseTemplate} props={props} />
    <PrivateRoute path="/app/default-course-templates/edit/:id" component={EditCourseTemplate} props={props} />
    <PrivateRoute path="/app/default-course-templates/view/:id" component={ViewCourseTemplate} props={props} />

    <PrivateRoute path="/app/template/:template_id/resources" component={CourseTemplateResources} props={props} small_menu={true} />
    <PrivateRoute
      path="/app/template/:template_id/polls/:poll_id/:poll_resource_id/results"
      component={CourseTemplateResourcesPublish}
      props={props}
      small_menu={true}
    />
    <PrivateRoute path="/app/template/:template_id/training/:training_id/classroom-dashboard" component={ClassroomHomePage} props={props} small_menu={true} />

    <PrivateRoute path={`/app/default-course-templates/:template_id/stages/create`} component={StageCreate} props={props} />
    <PrivateRoute path={`/app/default-course-templates/:template_id/stages/view/`} component={StageView} props={props} />

    <PrivateRoute path={`/app/default-course-templates/:template_id/stages/:stage_id/modules/create`} component={ModuleCreate} props={props} />
    <PrivateRoute path={`/app/default-course-templates/:template_id/stages/:stage_id/modules/view/`} component={ModuleView} props={props} />
    <PrivateRoute path={`/app/default-course-templates/:template_id/stages/:stage_id/modules/views/`} component={ModuleViews} props={props} />

    <PrivateRoute path={`/app/default-course-templates/:template_id/stages/:stage_id/modules/:module_id/topics/create`} component={TopicCreate} props={props} />
    <PrivateRoute path={`/app/default-course-templates/:template_id/stages/:stage_id/modules/:module_id/topics/view/`} component={TopicView} props={props} />
    <PrivateRoute
      path={`/app/default-course-templates/:template_id/stages/:stage_id/modules/:module_id/topics/:topic_id/view/`}
      component={TopicSingleView}
      props={props}
    />

    <PrivateRoute path={`/app/default-course-templates/:template_id/stages/:stage_id/modules/:module_id/resources/create`} component={ModuleResourceCreate} />
    <PrivateRoute path={`/app/default-course-templates/:template_id/stages/:stage_id/modules/:module_id/resources/view/`} component={ModuleResourceView} />

    <PrivateRoute
      path={`/app/default-course-templates/:template_id/stages/:stage_id/modules/:module_id/topics/:topic_id/resources/create`}
      component={TopicResourceCreate}
      props={props}
    />
    <PrivateRoute
      path={`/app/default-course-templates/:template_id/stages/:stage_id/modules/:module_id/topics/:topic_id/resources/view/`}
      component={TopicResourceView}
      props={props}
    />

    <PrivateRoute path="/app/value-packs" component={ValuePacks} props={props} />
    <PrivateRoute path="/app/value-packs/create" component={CreateValuePack} props={props} />
    <PrivateRoute path="/app/value-packs/edit/:id" component={EditValuePack} props={props} />
    <PrivateRoute path="/app/value-packs/view/:id" component={ViewValuePack} props={props} />

    <PrivateRoute path="/app/students/:student_id/training/:course_id" component={ClassReport} props={props} />
    <PrivateRoute path="/app/students/view/:id" component={ViewStudent} props={props} />
    <PrivateRoute path="/app/students" component={StudentList} props={props} />

    <PrivateRoute path="/app/trainings/:training_id/students" component={TrainingStudentList} props={props} />

    <PrivateRoute path="/app/students/:student_id/trainings/:training_id/quizzes/:quiz_id" component={ReportList} props={props} />

    <PrivateRoute path="/app/class-room/:training_id/:template_id" component={ClassRoom} props={props} />

    {/* For Zoom */}
    {/* <PrivateRoute path="/app/class-room/:training_id/:template_id/zoom-meeting" component={ZoomMeeting} props={props} /> */}

    <PrivateRoute path="/app/signupsucess" component={Signupsucess} props={props} />

    <PrivateRoute path="/app/upload" component={Upload} props={props} />

    <PrivateRoute path="/app/enquiries" component={EnquiryList} props={props} />

    <PrivateRoute path="/app/insights" component={Insights} props={props} />

    <Activate path="/app/activate/:uidb64/:pk" props={props} />

    <KalturaCreateVideo path="/app/kvideos/create" />
    <KalturaEditVideo path="/app/kvideos/edit" />

    <MediaCreateVideo path="/app/mvideos/create" props={props} />
    <SignupSuccessRedirection path="app/signup-success" props={props} />
    <Login path="/app/login" props={props} />
    <PasswordReset path="/app/password-reset" props={props} />
    <Signup path="/app/signup" props={props} />
    <ForgotPassword path="/app/forgot-password" props={props} />
    <ResetPasswordWithOTP path="/app/reset-password-otp" props={props} />
    <MuralAuth path="/app/mural" props={props} />
    <MuralSuccess path="/app/mural/success" props={props} />
    <Mural path="/app/mural/list" props={props} />

    <ZoomAuth path="/app/zoom/auth" props={props} />
    <ZoomList path="/app/zoom/list" props={props} />
    <ZoomAuthentication path="/app/zoom/authentication/" props={props} />
  </Router>
)

export default App
