import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Loadable from "@loadable/component"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
import { LazyLoadImage } from "react-lazy-load-image-component"
// import Pagination from "react-bootstrap/Pagination"
import InputGroup from "react-bootstrap/InputGroup"
import Form from "react-bootstrap/Form"
import FormGroup from "react-bootstrap/FormGroup"
import Dropdown from "react-bootstrap/Dropdown"
// import Row from "react-bootstrap/Row"
import ProgressBar from "react-bootstrap/ProgressBar"
// import OverlayTrigger from "react-bootstrap/OverlayTrigger"
// import Tooltip from "react-bootstrap/Tooltip"
import FormControl from "react-bootstrap/FormControl"
import Spinner from "react-bootstrap/Spinner"
import SunEditor from "suneditor-react"
import DeleteModal from "./custom/DeleteModal"

import Layout from "./layout"
import SEO from "./seo"
import authClient from "../services/auth"
// import Image from "./image"
import pixelResize from "./utilities/pixelresize"
import utcDateTime from "../services/utcdatetime"
import { isBrowser } from "../services/developer_mode"

import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faSearch, faAngleDown, faTrash, faPen, faGem } from "@fortawesome/free-solid-svg-icons"
import { faFile, faFileImage } from "@fortawesome/free-regular-svg-icons"
// import search from "./../images/svgs/search.svg"
import user from "./../images/svgs/user.svg"
import CancelWhite from "./../images/svgs/close.svg"
// import BellWhite from "./../images/svgs/bell-white-icon.svg"
import vidaudres from "./../images/svgs/vidaudres.svg"
import Vidoeblue from "./../images/svgs/video-blue.svg"
import Imageres from "./../images/svgs/imageres.svg"
import Linkimg from "./../images/svgs/linknew.svg"
import Blogimg from "./../images/svgs/blog-white-icon.svg"
import Docimg from "./../images/svgs/doc.svg"
import Pptimg from "./../images/svgs/ppt.svg"
import Pdfimg from "./../images/svgs/pdf.svg"
import Excelimg from "./../images/svgs/excel.svg"
// import undefined1 from "./../images/svgs/undefined.svg"
// import TrashCan from './../images/svgs/trash-can-transparent.svg'
// import errorface from './../images/errorface.svg'
// import SucessTickIcon1 from './../images/svgs/sucesstick.svg'
import DocumentIcon from "./../images/svgs/document-icon.svg"

// Import Styles here
import Styles from "../modularscss/styles.module.scss"
import StyleA from "./resources-dashboard.module.scss"
import StylesM from "../components/upload-resource.module.scss"
import Notification from "./custom/Notification"

const VideoCard = Loadable(() => import("./videocards/videocards"))

const NewResourceDashboard = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [companyLoader, setCompanyLoader] = useState(false)

  const [trainings, setTrainings] = useState([])
  const [trainingsNext, setTrainingsNext] = useState(null)
  const [trainingsLoader, setTrainingsLoader] = useState(false)
  const [training_type, setTrainingType] = useState("")

  const [student_email, setStudentEmail] = useState("")
  const [file, setFile] = useState("")
  const [student_name, setStudentName] = useState("")
  const [notificationData, setNotificationData] = useState(null)

  const [quiz, setQuiz] = useState({
    no_of_questions: 1,
    quiz_time: 5,
    no_of_attempts: "0",
    quiz_type: "0",
    quiz_level: "0",
    partial_points: true,
  })

  useEffect(() => {
    fetchCompanies()
  }, [])
  useEffect(() => {
    if (isBrowser()) {
      let location_hash = window.location.hash.replace("#", "")
      if (location_hash === "") {
        setTrainingType("")
      } else if (location_hash === "completed") {
        setTrainingType("past")
      } else if (location_hash === "upcoming") {
        setTrainingType("upcoming")
      }
    }
  }, [isBrowser()])

  function showSignout() {
    if (authClient.isAuthenticated()) {
      return (
        <Button variant={"none"} onClick={() => authClient.signOut()} className={`p-0 w-100`}>
          Logout
        </Button>
      )
    } else {
      return ""
    }
  }

  async function fetchCompanies() {
    setCompanyLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/fetch-company/`, {
        headers: login_credentials,
      })
      .then((res) => {
        let location_hash = window.location.hash.replace("#", "")
        localStorage.setItem("company_id", res.data[0].id)
        localStorage.setItem("company_name", res.data[0]?.name)
        localStorage.setItem("company_logo", res.data[0]?.avatar)
        setCompanyLoader(true)

        // for first company
        setCompany(res.data[0])
        fetchCourses(res.data.length > 0 ? res.data[0] : [])
        fetchTrainings(res.data.length > 0 ? res.data[0] : [], location_hash === "completed" ? "past" : location_hash === "" ? "all" : location_hash)
      })
      .catch((error) => {
        console.log(error, "error")
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  async function fetchTrainings(comp, type) {
    setTrainingsLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-trainings-list/?course__company=${comp.id}&type=${type}`, { headers: login_credentials })
      .then((res) => {
        setTrainings(res.data.results)
        setTrainingsNext(res.data.next)
        setTrainingsLoader(true)
      })
  }

  // for first company start
  const [company, setCompany] = useState({})

  const [courseLoader, setCourseLoader] = useState(false)
  const [courses, setCourses] = useState([])
  const [coursesNext, setCoursesNext] = useState(null)
  const [coursesNextFetched, setCoursesNextFetched] = useState(false)
  const [dmodal, setDmodal] = useState(false)
  const [currentCourse, setCurrentCourse] = useState([])
  const [searchBarVisible, setSearchbarVisible] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  const [resources, setResources] = useState({ video: [], image: [], blog: [], link: [], all: [], undefined: [] })
  const [resourcesDataFetched, setResourcesDataFethched] = useState(false)
  const [resourcesNextUrl, setResourcesNextUrl] = useState({ video: null, image: null, blog: null, link: null, all: null, undefined: null })
  const [fetchedNextResources, setFetchedNextResources] = useState(true)
  const [oldFilter, setOldFilter] = useState(true)
  const [categoryFilter, setCategoryFilter] = useState("")
  const [categoryName, setCategoryName] = useState("")
  const [sizeFilter, setSizeFilter] = useState(null)
  const [fileTypeFilter, setFileTypeFilter] = useState(null)
  const [resourceSearchValue, setresourceSearchValue] = useState("")
  const [activeSize, setActiveSize] = useState(null)
  const [activeCategory, setActiveCategory] = useState(null)
  const [activeFileType, setActiveFileType] = useState(null)

  const [activeResource, setActiveResource] = useState(null)
  const [activeOriginalResourceData, setActiveOriginalResourceData] = useState(false)
  const [activeDeleteData, setActiveDeleteData] = useState({})
  const [showEditResourceModal, setShowEditResourceModal] = useState(false)
  const [showEditMainResourseModal, setShowEditMainResourseModal] = useState(false)
  // const [showResourceDeleteModal, setShowResourceDeleteModal] = useState(false)
  // const [showResourceDeleteSucessModal, setShowResourceDeleteSucessModal] = useState(false)
  const [originalResourceDataFetched, setOriginalResourceDataFetched] = useState(false)
  const [resourceDescription, setResourceDescription] = useState("")
  const [selectedVideoData, setSelectedVideoData] = useState(null)
  const [videoDuration, setVideoDuration] = useState(0)
  const [resourceUpdated, setResourceUpdated] = useState(true)

  const totalSize = 5 * 1024 * 1024 * 1024
  const [totalUsedSpace, setTotalUsedSpace] = useState(0)
  const [showTotalSizesObj, setShowTotalSizesObj] = useState({
    videos: {
      fileSrc: vidaudres,
      fileAltText: "Vid/Aud",
      color: "#0EADDD",
      comPer: 0,
      occupiedSpace: 0,
      noOfFiles: 0,
    },
    images: {
      fileSrc: faFileImage,
      fileAltText: "Images",
      color: "#FF6860",
      comPer: 0,
      occupiedSpace: 0,
      noOfFiles: 0,
    },
    docs: {
      fileSrc: faFile,
      fileAltText: "Docs",
      color: "#1AD090",
      comPer: 0,
      occupiedSpace: 0,
      noOfFiles: 0,
    },
  })

  const contentTypeName = { 18: "video", 19: "blog", 20: "image", 21: "link" }

  // const handleClose = () => {setDmodal(false);setCurrentCourse([]);}

  const handleDeleteModalClose = () => {
    setActiveDeleteData({})
  }

  useEffect(() => {
    if (company?.id && company.id > 0) {
      fetchResourcesTotalCount()
      setResourcesDataFethched(false)
      fetchResources()
    }
  }, [company])

  function fetchResourcesTotalCount() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/notifications/size/`, { headers: login_credentials })
      .then((res) => {
        let d = res.data.data,
          totSize = 0,
          totCount = 0,
          gb = 1073741824,
          obj = { ...showTotalSizesObj }
        //d = {"all_videos":249112858,"video_count":71,"all_images":11968623,"images_count":31,"all_docs":11168744,"docs_count":12,"undefined":272250225,"undefined_count":71}
        totSize += obj.videos.occupiedSpace = d.all_videos / gb
        totSize += obj.images.occupiedSpace = d.all_images / gb
        totSize += obj.docs.occupiedSpace = d.all_docs / gb
        totSize += obj.undefined1.occupiedSpace = d.undefined / gb

        totCount += obj.videos.noOfFiles = d.video_count
        totCount += obj.images.noOfFiles = d.images_count
        totCount += obj.docs.noOfFiles = d.docs_count
        totCount += obj.undefined1.noOfFiles = d.undefined_count

        obj.videos.comPer = (d.all_videos / totalSize) * 100
        obj.images.comPer = (d.all_images / totalSize) * 100
        obj.docs.comPer = (d.all_docs / totalSize) * 100
        obj.undefined1.comPer = (d.undefined / totalSize) * 100

        setTotalUsedSpace(totSize)
        setShowTotalSizesObj(obj)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function fetchResources() {
    let params =
      categoryFilter === "" || categoryFilter === "undefined"
        ? activeCategory === "undefined"
          ? `video__is_defined=False&file__is_defined=False&`
          : ""
        : `content_type__model=${categoryName}&`
    let file_type_params = fileTypeFilter === null ? "" : `file_types_in=${fileTypeFilter}&`

    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/notifications/?company=${company?.id}&${file_type_params}old=${oldFilter}&${params}paginate_by=16&size=${sizeFilter}&search=${resourceSearchValue}`,
        { headers: login_credentials }
      )
      .then((res) => {
        let resData = { ...resources }
        let resDataUrl = { ...resourcesNextUrl }
        if (categoryFilter === "") {
          resData["all"] = res?.data?.results
          resDataUrl["all"] = res?.data?.next
        } else if (categoryFilter === "18") {
          resData["video"] = res?.data?.results
          resDataUrl["video"] = res?.data?.next
        } else if (categoryFilter === "19") {
          resData["blog"] = res?.data?.results
          resDataUrl["blog"] = res?.data?.next
        } else if (categoryFilter === "20") {
          resData["image"] = res?.data?.results
          resDataUrl["image"] = res?.data?.next
        } else if (categoryFilter === "21") {
          resData["link"] = res?.data?.results
          resDataUrl["link"] = res?.data?.next
        } else {
          resData["undefined"] = res?.data?.results
          resDataUrl["undefined"] = res?.data?.next
        }
        setResources(resData)
        setResourcesDataFethched(true)
        setResourcesNextUrl(resDataUrl)
      })
      .catch((err) => {})
  }

  function fetchNextResources(next_url) {
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let resData = { ...resources }
      let resDataUrl = { ...resourcesNextUrl }
      if (categoryFilter === "") {
        resData["all"] = [...resData["all"], ...res?.data?.results]
        resDataUrl["all"] = res?.data?.next
      } else if (categoryFilter === "18") {
        resData["video"] = [...resData["video"], ...res?.data?.results]
        resDataUrl["video"] = res?.data?.next
      } else if (categoryFilter === "19") {
        resData["blog"] = [...resData["blog"], ...res?.data?.results]
        resDataUrl["blog"] = res?.data?.next
      } else if (categoryFilter === "20") {
        resData["image"] = [...resData["image"], ...res?.data?.results]
        resDataUrl["image"] = res?.data?.next
      } else if (categoryFilter === "21") {
        resData["link"] = [...resData["link"], ...res?.data?.results]
        resDataUrl["link"] = res?.data?.next
      } else {
        resData["undefined"] = res?.data?.results
        resDataUrl["undefined"] = res?.data?.next
      }
      setResources(resData)
      setFetchedNextResources(true)
      setResourcesNextUrl(resDataUrl)
    })
  }

  useEffect(() => {
    if (company?.id && company.id > 0) {
      if (resources[contentTypeName[categoryFilter]].length <= 0) {
        setResourcesDataFethched(false)
        fetchResources()
      }
    }
  }, [categoryFilter])

  useEffect(() => {
    if (company?.id && company.id > 0) {
      setResourcesDataFethched(false)
      fetchResources()
    }
  }, [oldFilter])

  function handleOldClick() {
    if (!oldFilter) {
      setOldFilter(true)
    }
  }
  function handleNewClick() {
    if (oldFilter) {
      setOldFilter(false)
    }
  }
  async function fetchCourses(comp) {
    setCourseLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-courses-list/?company=${comp.id}`, { headers: login_credentials }).then((res) => {
      setCourses(res.data.results)
      setCoursesNext(res.data.next)
      setCourseLoader(true)
    })
  }

  async function fetchNextCourses(next_url) {
    setCoursesNextFetched(false)
    axios
      .get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), {
        headers: login_credentials,
      })
      .then((res) => {
        var next_course_data = courses
        if (res.data.results.length > 0) {
          for (var crs = 0; crs <= res.data.results.length - 1; crs++) {
            next_course_data.push(res.data.results[crs])
          }
          setCourses(next_course_data)
        }
        setCoursesNext(res.data.next)
        setCoursesNextFetched(true)
      })
  }

  function deleteCourse() {
    var current_course_id = currentCourse.id
    var index = courses.findIndex((res) => res.id === current_course_id)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/courses/" + current_course_id + "/", { headers: DefaultHeader, body: currentCourse })
      .then((res) => {
        courses.splice(index, 1)
        setCurrentCourse([])
        setDmodal(!dmodal)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function handleChange(event) {
    event.preventDefault()
    if (event.target.name === "name") {
      setStudentName(event.target.value)
    } else if (event.target.name === "email") {
      setStudentEmail(event.target.value)
    } else if (event.target.name === "file") {
      setFile(event.target.files[0])
    }
  }

  const fetchTrainingType = (type) => {
    if (type !== training_type) {
      setTrainingType(type)
      window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}${type === "all" || type === "" ? "" : `#${type}`}`)
      fetchTrainings(company, type === "" ? "all" : type)
    }
  }
  const fetchTrainingHash = () => {
    let location_hash = window.location.hash.replace("#", "")
    fetchTrainingType(location_hash === "completed" ? "past" : location_hash === "" ? "all" : location_hash)
  }

  const showHeader = () => {
    return (
      <Col xs="12" sm="12" md="12" lg="12" className={`px-0`} style={{ marginBottom: "0px", height: "60px" }}>
        <div
          className={`my-0 mx-auto align-items-right p-2 ${authClient.isAuthenticated() ? `` : `d-none`} `}
          style={{
            position: `fixed`,
            top: 0,
            right: 0,
            zIndex: 9999,
            height: pixelResize(108, "height"),
          }}
        >
          <div
            className="d-flex align-center"
            style={{
              marginRight: pixelResize(0),
              marginTop: pixelResize(0, "height"),
              marginBottom: pixelResize(24, "height"),
              textAlign: "right",
            }}
          >
            {searchBarVisible === true ? (
              <div
                onBlur={() => setSearchbarVisible(false)}
                className="d-flex "
                style={{
                  borderRadius: `11px`,
                  height: "45px",
                  marginTop: "10px",
                  backgroundColor: "#303C54",
                  border: "1px solid #E0E2E5",
                }}
              >
                <FontAwesomeIcon color="#7D8289" className={`my-auto ml-2`} icon={faSearch} size="lg" />
                <FormControl
                  autoFocus
                  className={`border-0 py-0 form-control px-2 shadow-none bg-card dashboard_search_bar text-white`}
                  placeholder="Search for courses, classes, resources, participants, etc"
                  onChange={(e) => {
                    handleChange(e)
                    setSearchValue(e.target.value)
                  }}
                  value={searchValue}
                />
              </div>
            ) : (
              <FontAwesomeIcon
                onClick={() => setSearchbarVisible(true)}
                color="#E0E2E5"
                className={`my-auto ml-2`}
                style={{
                  width: pixelResize(61),
                  height: pixelResize(55, "height"),
                  backgroundColor: "#303c54",
                  borderRadius: "50%",
                  padding: "15px",
                  marginRight: pixelResize(0),
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                icon={faSearch}
                size="lg"
              />
            )}
          </div>
        </div>
      </Col>
    )
  }

  const topData = [{}]

  const showTopBar = () => {
    return (
      <div className={`d-flex justify-content-between br-9px inner-element-box-shadow p-3 mb-4 ${StyleA.bg_1}`}>
        <div className={`d-flex flex-fill flex-column br-9px p-4 inner-element-box-shadow mr-2 ${StyleA.bg_2}`}>
          <div className={`d-flex justify-content-between ${StyleA.text_1}`}>
            <div>
              <div className={`fs-18px font-weight-bolder`}>
                {totalUsedSpace?.toFixed(1)} <span className={`fs-12px`}>GB</span>
              </div>
              <div className={`fs-14px`}>Used</div>
            </div>
            <div>
              <div className={`fs-18px font-weight-bolder`}>
                5 <span className={`fs-12px`}>GB</span>
              </div>
              <div className={`fs-14px`}>Total</div>
            </div>
          </div>
          <div>
            <ProgressBar className={`mt-2`} style={{ borderRadius: "10px", height: "15px" }}>
              <ProgressBar
                variant="info"
                now={showTotalSizesObj?.videos?.comPer?.toFixed(1)}
                //label={showTotalSizesObj?.videos?.comPer?.toFixed(1)}
                title={showTotalSizesObj?.videos?.comPer?.toFixed(1)}
                key={1}
                style={{ borderRadius: "0px" }}
              />
              <ProgressBar
                variant="danger"
                now={showTotalSizesObj?.images?.comPer?.toFixed(1)}
                //label={showTotalSizesObj?.images?.comPer?.toFixed(1)}
                title={showTotalSizesObj?.images?.comPer?.toFixed(1)}
                key={2}
                style={{ borderRadius: "0px" }}
              />
              <ProgressBar
                variant="success"
                now={showTotalSizesObj?.docs?.comPer?.toFixed(1)}
                //label={showTotalSizesObj?.docs?.comPer?.toFixed(1)}
                title={showTotalSizesObj?.docs?.comPer?.toFixed(1)}
                key={3}
                style={{ borderRadius: "0px" }}
              />
              {/* <ProgressBar
                variant="warning"
                now={showTotalSizesObj?.undefined1?.comPer?.toFixed(1)}
                //abel={showTotalSizesObj?.undefined1?.comPer?.toFixed(1)}
                title={showTotalSizesObj?.undefined1?.comPer?.toFixed(1)}
                key={4}
                style={{ borderRadius: "0px" }}
              /> */}
            </ProgressBar>
          </div>
        </div>

        <div className="d-flex flex-fill justify-content-between flex-wrap">
          {Object.values(showTotalSizesObj).map((data, index) => (
            <div
              key={data.fileAlt}
              className={`d-flex flex-fill justify-content-between flex-column br-9px px-3 py-2 inner-element-box-shadow mr-2 ${StyleA.bg_2}`}
              style={{ width: "150px" }}
            >
              <div className={"d-flex justify-content-between"}>
                <div style={{ width: "100px", height: "100px" }}>
                  <CircularProgressbarWithChildren
                    value={data.comPer}
                    strokeWidth={2}
                    counterClockwise={true}
                    styles={buildStyles({
                      textColor: data.color,
                      pathColor: data.color,
                      trailColor: "#737B9D",
                      //pathColor: "#737B9D", trailColor: data.color,
                    })}
                  >
                    {data.fileAltText == "Vid/Aud" || data.fileAltText == "Undefined" ? (
                      <img style={{ marginBottom: "8px", objectFit: "contain", width: "30%" }} className={`mb-2`} src={data.fileSrc} />
                    ) : (
                      <FontAwesomeIcon icon={data.fileSrc} size="2x" style={{ objectFit: "contain", width: "20%" }} color={data.color} className={`mb-1`} />
                    )}
                    <div className="fs-12px" style={{ marginTop: "-5px", color: data.color }}>
                      {data.fileAltText}
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
                <div className={`align-self-center fs-18px`}>
                  <div className={`font-weight-bolder`}>
                    {data.occupiedSpace?.toFixed(1)} <span className={`fs-12px`}>GB</span>
                  </div>
                  <div>
                    {data.noOfFiles} <span className={`fs-12px`}>files</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  useEffect(() => {
    setResourcesDataFethched(false)
    fetchResources()
  }, [resourceSearchValue])

  useEffect(() => {
    setResourcesDataFethched(false)
    fetchResources()
  }, [sizeFilter])

  useEffect(() => {
    setResourcesDataFethched(false)
    fetchResources()
  }, [fileTypeFilter])

  function getUrlData(data) {
    if (data === "video") {
      return "videos"
    } else if (data === "blog") {
      return "blogs"
    } else if (data === "turient image") {
      return "images"
    } else if (data === "link") {
      return "links"
    }
  }

  async function fetchResourceData(data) {
    let url_data = getUrlData(data.resource_type)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/${url_data}/${data.content_object.id}/`, { headers: login_credentials })
      .then((res) => {
        setActiveOriginalResourceData(res.data)
        setResourceDescription(res.data.description)
        setOriginalResourceDataFetched(true)
      })
      .catch((err) => {})
  }

  function parseVideo(url) {
    // - Supported YouTube URL formats:
    //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
    //   - http://youtu.be/My2FRPA3Gf8
    //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
    // - Supported Vimeo URL formats:
    //   - http://vimeo.com/25451551
    //   - http://player.vimeo.com/video/25451551
    // - Also supports relative URLs:
    //   - //player.vimeo.com/video/25451551

    url.match(
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
    )

    if (RegExp.$3.indexOf("youtu") > -1) {
      var type = "youtube"
    } else if (RegExp.$3.indexOf("vimeo") > -1) {
      var type = "vimeo"
    }

    return {
      type: type,
      id: RegExp.$6,
    }
  }

  useEffect(() => {
    if (selectedVideoData) {
      if (document.getElementById(`video-element`)) {
        let vid = document.getElementById(`video-element`)
        let dummy = { ...activeOriginalResourceData }
        setActiveOriginalResourceData(dummy)
      }
    }
  }, [selectedVideoData])

  function handleEditChange(name, value) {
    let dummy = { ...activeOriginalResourceData }
    if (name === "avatar") {
      if (value && value.type && value.type.indexOf("video") > -1) {
        if (dummy.url.length > 0 || dummy.video_file) {
          dummy["channel"] = "turient"
          dummy["size"] = value.size
          dummy["url"] = ""
          dummy["video_id"] = ""
          setSelectedVideoData(value)
        }
      } else {
        dummy[name] = value
        dummy["size"] = value.size
      }
    } else {
      if (activeResource.resource_type === "video") {
        if (name === "url") {
          dummy[name] = value
          let parsed_video = parseVideo(value)
          updateVideoDuration(value, parsed_video.type)
          if (parsed_video.type === "youtube") {
            dummy["channel"] = "youtube"
            dummy["vimeo_url"] = ""
            dummy["video_file"] = ""
            dummy["video_id"] = parsed_video.id
          } else if (parsed_video.type === "vimeo") {
            dummy["channel"] = "vimeo"
            dummy["video_id"] = parsed_video.id
            dummy["video_file"] = ""
          }
        } else {
          dummy[name] = value
        }
      } else {
        dummy[name] = value
      }
    }
    setActiveOriginalResourceData(dummy)
  }

  function updateVideoDuration(url, type) {
    let dummy = { ...activeOriginalResourceData }
    if (type === "youtube") {
      axios.get(`https://www.youtube.com/oembed?url=${url}&format=json`, { headers: login_credentials }).then((res) => {
        dummy["duration"] = res.data.duration ? res.data.duration : 0
        setVideoDuration(res.data.duration ? res.data.duration : 0)
      })
    } else if (type === "vimeo") {
      axios.get(`https://vimeo.com/api/oembed.json?url=${url}`, { "Access-Control-Allow-Origin": "*" }).then((res) => {
        dummy["duration"] = res.data.duration ? res.data.duration : 0
        setVideoDuration(res.data.duration ? res.data.duration : 0)
      })
    }
    setActiveOriginalResourceData(dummy)
  }

  function handleEditResource() {
    let url_data = getUrlData(activeResource.resource_type)
    let updatedData = {}
    let formData = new FormData()
    if (url_data === "images") {
      formData.append("name", activeOriginalResourceData.name)
      formData.append("points", activeOriginalResourceData.points)
      formData.append("size", activeOriginalResourceData.size)
      formData.append("description", resourceDescription)
      if (
        document.querySelector(".form-control-file") &&
        document.querySelector(".form-control-file").value &&
        document.querySelector(".form-control-file").value.length > 0
      ) {
        formData.append("avatar", activeOriginalResourceData["avatar"])
      }
      if (activeOriginalResourceData.url) {
        formData.append("url", activeOriginalResourceData.url)
      }
      if (activeOriginalResourceData.link) {
        formData.append("link", activeOriginalResourceData.link)
      }
    } else if (url_data === "videos") {
      formData.append("name", activeOriginalResourceData.name)
      formData.append("points", activeOriginalResourceData.points)
      formData.append("size", activeOriginalResourceData.size)
      formData.append("description", resourceDescription)
      if (document.getElementById(`video-element`) && document.getElementById(`video-element`).duration) {
        formData.append("duration", parseInt(document.getElementById(`video-element`).duration))
      }
      formData.append("channel", activeOriginalResourceData.channel)
      if (selectedVideoData) {
        formData.append("url", "")
        formData.append("video_file", selectedVideoData)
        formData.append("video_id", "")
        formData.append("vimeo_url", "")
      } else {
        formData.append("url", activeOriginalResourceData.url)
        formData.append("video_file", "")
        formData.append("video_id", activeOriginalResourceData.video_id)
        formData.append("duration", videoDuration)
      }
    }
    updatedData["name"] = activeOriginalResourceData.name
    updatedData["points"] = activeOriginalResourceData.points
    updatedData["description"] = resourceDescription
    if (activeOriginalResourceData.url) {
      updatedData["url"] = activeOriginalResourceData.url
    }
    if (activeOriginalResourceData.link) {
      updatedData["link"] = activeOriginalResourceData.link
    }
    if (url_data === "videos") {
      updatedData["channel"] = activeOriginalResourceData.channel
    }

    axios
      .put(
        process.env.GATSBY_REACT_APP_API_HOST + `/${url_data}/${activeResource.content_object.id}/`,
        url_data === "images" || url_data === "videos" ? formData : updatedData,
        { headers: login_credentials }
      )
      .then((res) => {
        setResourceUpdated(true)

        setActiveOriginalResourceData(res.data)
        setResourcesDataFethched(false)
        setShowEditMainResourseModal(false)
        setShowEditResourceModal(false)
        fetchResources()
        setOriginalResourceDataFetched(true)
        setShowEditResourceModal(false)
      })
      .catch((error) => {
        console.log("err", error.response)
        if (error.response && error.response.data && error.response.data.link && error.response.data.link[0]) {
          setNotificationData({ type: "error", title: error.response.data.link[0] })
        } else {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          setNotificationData({ type: "error", error_messages })
        }
      })
  }

  // function getUrl(data) {
  //   if(data.content_type === 18) {
  //     return 'videos'
  //   }else if(data.content_type === 19) {
  //     return 'blogs'
  //   }else if(data.content_type === 20) {
  //     return 'images'
  //   }else if(data.content_type === 21) {
  //     return 'links'
  //   }
  // }

  // const [resources, setResources] = useState({'video': [], 'image': [], 'blog': [], 'link':[], 'all':[], 'undefined': []})

  function handleDeleteResource(data) {
    let url_data = getUrlData(data.resource_type)
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/${url_data}/${data.content_object.id}/`, { headers: DefaultHeader })
      .then((res) => {
        // console.log('res',res)
        // let type = contentTypeName[activeDeleteData.content_type]
        // let dummy = {...resources}
        // let index = dummy[type].findIndex(res => res.id === activeDeleteData.content_object.id)
        // console.log(type,dummy,index,'data delete')
        // dummy[type].splice(index,1)
        // setResources(dummy)
        setResourcesDataFethched(false)
        handleDeleteModalClose()
        setNotificationData({ type: "success", title: `${data?.content_object?.name} has been deleted successfully` })
        fetchResources()
        // setShowResourceDeleteModal(false)
        // setShowResourceDeleteSucessModal(true)
        //need to remove resource form list peding
      })
      .catch((err) => {
        console.log("err", err)
      })
  }

  // useEffect(() => {
  //   if(activeResource) {
  //     setOriginalResourceDataFetched(false)
  //     fetchResourceData()
  //   }
  // },[activeResource])

  function getTumbNailView(data) {
    if (data.resource_type === "blog") {
      return <img className={`mb-0 mr-2`} src={Blogimg} alt="bolg" width={"30px"} height={"30px"} style={{ objectFit: "contain" }} />
    } else if (data.resource_type === "video") {
      return <img className={`mb-0 mr-2`} src={Vidoeblue} alt="Video" width={"30px"} height={"30px"} style={{ objectFit: "contain" }} />
    } else if (data.resource_type === "link") {
      return <img className={`mb-0 mr-2`} src={Linkimg} alt="Link" width={"30px"} height={"30px"} style={{ objectFit: "contain" }} />
    } else if (data.resource_type === "turient image") {
      if (data.content_object.file_type == 0) {
        if (data.content_object.avatar) {
          return <img className={`mb-0 mr-2`} src={data.content_object.avatar} alt="Image" width={"30px"} height={"30px"} style={{ objectFit: "contain" }} />
        } else {
          return <img className={`mb-0 mr-2`} src={Imageres} alt="Image" width={"30px"} height={"30px"} style={{ objectFit: "contain" }} />
        }
      } else if (data.content_object.file_type == 1) {
        return <img className={`mb-0 mr-2`} src={Pdfimg} alt="PDF" width={"30px"} height={"30px"} style={{ objectFit: "contain" }} />
      } else if (data.content_object.file_type == 2 || data.content_object.file_type == 5) {
        return <img className={`mb-0 mr-2`} src={Docimg} alt="Document" width={"30px"} height={"30px"} style={{ objectFit: "contain" }} />
      } else if (data.content_object.file_type == 3 || data.content_object.file_type == 7) {
        return <img className={`mb-0 mr-2`} src={Excelimg} alt="Excel" width={"30px"} height={"30px"} style={{ objectFit: "contain" }} />
      } else if (data.content_object.file_type == 4 || data.content_object.file_type == 6) {
        return <img className={`mb-0 mr-2`} src={Pptimg} alt="PPT" width={"30px"} height={"30px"} style={{ objectFit: "contain" }} />
      }
    }
  }

  const showResources = () => {
    return (
      <div className="position-relative">
        <div className={``} style={{ position: "sticky", top: "10px", zIndex: "100" }}>
          <div>
            <div className={`d-inline-flex align-items-center inner-element-box-shadow br-9px px-3 py-0 w-100 ${StyleA.bg_1}`}>
              <div className={`font-weight-bolder mr-3`}>Files</div>

              <Dropdown className={`breakout-groups mr-0 py-3 px-3 ${StyleA.blue_bg_onhover}`}>
                <Dropdown.Toggle
                  className="border-none d-inline-flex align-items-center p-0 shadow-none"
                  style={{ backgroundColor: "transparent" }}
                  id="dropdown-basic"
                >
                  <div className={`mr-1`} style={{ fontSize: "16px" }}>
                    {oldFilter ? "Newest First" : "Oldest First"}
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width}`} style={{ fontSize: "14px", color: "#E0E2E5" }}>
                  <Dropdown.Item onClick={handleNewClick} style={{ color: "#E0E2E5" }}>
                    Oldest First
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleOldClick} style={{ color: "#E0E2E5" }}>
                    Newest First
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className={`breakout-groups mr-0 py-3 px-3 ${StyleA.blue_bg_onhover}`}>
                <Dropdown.Toggle
                  className="border-none d-inline-flex align-items-center p-0 shadow-none"
                  style={{ backgroundColor: "transparent" }}
                  id="dropdown-basic"
                >
                  <div className={`mr-1`} style={{ fontSize: "16px" }}>
                    {activeSize ? activeSize : "File Size"}
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width}`} style={{ fontSize: "14px", color: "#E0E2E5" }}>
                  <Dropdown.Item
                    style={{ color: "#E0E2E5" }}
                    onClick={() => {
                      setSizeFilter(500)
                      setActiveSize("> 500 Mb")
                    }}
                  >{`> 500 Mb`}</Dropdown.Item>
                  <Dropdown.Item
                    style={{ color: "#E0E2E5" }}
                    onClick={() => {
                      setSizeFilter(100)
                      setActiveSize("100Mb - 500 Mb")
                    }}
                  >
                    100Mb - 500 Mb
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ color: "#E0E2E5" }}
                    onClick={() => {
                      setSizeFilter(50)
                      setActiveSize("50 Mb - 100 Mb")
                    }}
                  >
                    50 Mb - 100 Mb
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ color: "#E0E2E5" }}
                    onClick={() => {
                      setSizeFilter(0)
                      setActiveSize("< 50 Mb")
                    }}
                  >{`< 50 Mb`}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className={`breakout-groups mr-0 py-3 px-3 ${StyleA.blue_bg_onhover}`}>
                <Dropdown.Toggle
                  className="border-none d-inline-flex align-items-center p-0 shadow-none"
                  style={{ backgroundColor: "transparent" }}
                  id="dropdown-basic"
                >
                  <div className={`mr-1`} style={{ fontSize: "16px" }}>
                    {activeCategory ? activeCategory : "Category"}
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width}`} style={{ fontSize: "14px", color: "#E0E2E5" }}>
                  <Dropdown.Item
                    className={`py-1`}
                    style={{ color: "#E0E2E5" }}
                    onClick={(e) => {
                      setCategoryFilter("")
                      setCategoryName("")
                      setActiveCategory("All categ..")
                      setFileTypeFilter(null)
                      setActiveFileType(null)
                    }}
                  >
                    {" "}
                    All
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={`py-1`}
                    style={{ color: "#E0E2E5" }}
                    onClick={(e) => {
                      setCategoryFilter("18")
                      setCategoryName("video")
                      setActiveCategory("Videos")
                      setFileTypeFilter(null)
                      setActiveFileType(null)
                    }}
                  >
                    Videos
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={`py-1`}
                    style={{ color: "#E0E2E5" }}
                    onClick={(e) => {
                      setCategoryFilter("19")
                      setCategoryName("blog")
                      setActiveCategory("Blogs")
                      setFileTypeFilter(null)
                      setActiveFileType(null)
                    }}
                  >
                    Blogs
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={`py-1`}
                    style={{ color: "#E0E2E5" }}
                    onClick={(e) => {
                      setCategoryFilter("20")
                      setCategoryName("turientimage")
                      setActiveCategory("Images & Docs")
                      setFileTypeFilter(null)
                      setActiveFileType(null)
                    }}
                  >
                    Images & Docs
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={`py-1`}
                    style={{ color: "#E0E2E5" }}
                    onClick={(e) => {
                      setCategoryFilter("21")
                      setCategoryName("link")
                      setActiveCategory("Links")
                      setFileTypeFilter(null)
                      setActiveFileType(null)
                    }}
                  >
                    Links
                  </Dropdown.Item>
                  {/* <Dropdown.Item  className={`py-1`} style={{color:'#E0E2E5'}} onClick={(e)=>{setCategoryFilter('undefined');setCategoryName('');setActiveCategory('undefined')}}>Undefined</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
              <div className="d-flex ml-auto pr-3" style={{ borderRadius: `11px`, height: "45px", backgroundColor: "#303C54" }}>
                <FontAwesomeIcon color="#7D8289" className={`my-auto ml-2`} icon={faSearch} size="lg" />
                <FormControl
                  style={{ maxWidth: "400px" }}
                  autoFocus
                  className={`border-0 py-0 form-control px-2 shadow-none bg-card dashboard_search_bar text-white`}
                  onChange={(e) => {
                    setresourceSearchValue(e.target.value)
                  }}
                  placeholder="Search Files"
                  value={resourceSearchValue}
                />
              </div>
            </div>
            {activeCategory === "Images & Docs" ? (
              <div className={`d-inline-flex align-items-center mt-0 w-100 pt-2 pb-1`} style={{ backgroundColor: "#13172c" }}>
                <div
                  className={`text-center pointer mr-2`}
                  onClick={(e) => {
                    setFileTypeFilter("0")
                    setActiveFileType("images")
                  }}
                  style={{
                    backgroundColor: "#303C54",
                    border: fileTypeFilter === "0" ? "1px solid #E0E2E5" : "1px solid transparent",
                    borderRadius: "18px",
                    minWidth: "60px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Images
                </div>
                <div
                  className={`text-center pointer mr-2`}
                  onClick={(e) => {
                    setFileTypeFilter("1")
                    setActiveFileType("pdf")
                  }}
                  style={{
                    backgroundColor: "#303C54",
                    border: fileTypeFilter === "1" ? "1px solid #E0E2E5" : "1px solid transparent",
                    borderRadius: "18px",
                    minWidth: "60px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Pdf
                </div>
                <div
                  className={`text-center pointer mr-2`}
                  onClick={(e) => {
                    setFileTypeFilter("2,5")
                    setActiveFileType("docs")
                  }}
                  style={{
                    backgroundColor: "#303C54",
                    border: fileTypeFilter === "2" ? "1px solid #E0E2E5" : "1px solid transparent",
                    borderRadius: "18px",
                    minWidth: "60px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Docs
                </div>
                <div
                  className={`text-center pointer mr-2`}
                  onClick={(e) => {
                    setFileTypeFilter("3,7")
                    setActiveFileType("excel")
                  }}
                  style={{
                    backgroundColor: "#303C54",
                    border: fileTypeFilter === "3" ? "1px solid #E0E2E5" : "1px solid transparent",
                    borderRadius: "18px",
                    minWidth: "60px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Excel
                </div>
                <div
                  className={`text-center pointer mr-2`}
                  onClick={(e) => {
                    setFileTypeFilter("4,6")
                    setActiveFileType("ppt")
                  }}
                  style={{
                    backgroundColor: "#303C54",
                    border: fileTypeFilter === "4" ? "1px solid #E0E2E5" : "1px solid transparent",
                    borderRadius: "18px",
                    minWidth: "60px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  PPT
                </div>
              </div>
            ) : null}
            <div className={`pt-2`} style={{ backgroundColor: "#13172c" }}>
              <div className={`d-inline-flex align-items-center px-0 py-0 w-100 ${StyleA.bg_1}`} style={{ borderRadius: "9px 9px 0px 0px" }}>
                <table className={`mb-0`} style={{ display: "table" }}>
                  <thead className={`w-100`} style={{ display: "table" }}>
                    <tr className={StyleA.resource_item} style={{ color: "#FFFFFF", fontSize: "14px" }}>
                      <th className={`px-2 w-25`} style={{ fontWeight: "400" }}>
                        Name
                      </th>
                      <th className={`text-center`} style={{ fontWeight: "400" }}>
                        Date of Upload
                      </th>
                      {/* <th className={``} style={{width:'20%',fontWeight:'400'}}>Url</th> */}
                      <th className={`text-center`} style={{ fontWeight: "400" }}>
                        Views
                      </th>
                      {/* <th className={`text-center`} style={{fontWeight:'400'}}>Likes</th>
                      <th className={`text-center`} style={{fontWeight:'400'}}>Shares</th> */}
                      <th className={`text-center`} style={{ width: "50px" }}></th>
                      <th className={`text-center`} style={{ width: "50px" }}></th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div style={{ position: "sticky", top: "200px" }}>
          <div className={`d-inline-flex mt-0 inner-element-box-shadow pt-3 px-2 w-100 ${StyleA.bg_1}`} style={{ borderRadius: "0px 0px 9px 9px" }}>
            <table style={{ display: "table" }}>
              <tbody className={`${StyleA.resource_list}`}>
                {resourcesDataFetched ? (
                  resources[categoryFilter === "" ? "all" : contentTypeName[categoryFilter]].length > 0 ? (
                    resources[categoryFilter === "" ? "all" : contentTypeName[categoryFilter]].map((data, index) => {
                      return (
                        <tr className={`${StyleA.bg_2} ${StyleA.resource_item} mb-2`} style={{ borderRadius: "9px", fontSize: "16px" }}>
                          <td className={`px-2 w-25`}>
                            <div className={`d-inline-flex align-items-center`}>
                              {getTumbNailView(data)}
                              {data?.content_object?.name}
                            </div>
                          </td>
                          <td className={`text-center`}>{`${utcDateTime.utcDateTime(data?.created, "date")}/${
                            utcDateTime.utcDateTime(data?.created, "month") + 1
                          }/${utcDateTime.utcDateTime(data?.created, "year")}`}</td>
                          {/* <td className={``} style={{width:'20%'}}>{data?.content_object?.link ? data.content_object.link : 'null'}</td> */}
                          <td className={`text-center`}>{data.resource_report_view}</td>
                          {/* <td className={`text-center`}>250</td>
                      <td className={`text-center`}>250</td> */}
                          <td className={`text-center pointer px-0`} style={{ width: "50px" }}>
                            <FontAwesomeIcon
                              icon={faPen}
                              color="#E0E2E5"
                              onClick={() => {
                                fetchResourceData(data)
                                setActiveResource(data)
                                setOriginalResourceDataFetched(false)
                                setShowEditResourceModal(true)
                              }}
                            />
                          </td>
                          <td className={`text-center pointer px-0`} style={{ width: "50px" }}>
                            <FontAwesomeIcon
                              icon={faTrash}
                              color="#E0E2E5"
                              onClick={() => {
                                setActiveDeleteData({ itemName: data?.content_object?.name, action: () => handleDeleteResource(data) })
                              }}
                            />
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <div className={`w-100 d-inline-flex text-center`}>
                      <p className={`mb-0 py-2 mx-auto`}>No Resources Found</p>
                    </div>
                  )
                ) : (
                  <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                    <Spinner animation="border" variant="secondary" />
                  </Col>
                )}
                {fetchedNextResources === false ? (
                  <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                    <Spinner animation="border" variant="secondary" />
                  </Col>
                ) : null}
                {resourcesNextUrl[categoryFilter === "" ? "all" : contentTypeName[categoryFilter]] && (
                  <div
                    className={`pointer`}
                    style={{ fontSize: "16px" }}
                    onClick={(e) => {
                      setFetchedNextResources(false)
                      fetchNextResources(resourcesNextUrl[categoryFilter === "" ? "all" : contentTypeName[categoryFilter]])
                    }}
                  >
                    <u>See more</u>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  function getFileType(data) {
    if (data === "video") {
      return "Video"
    } else if (data === "blog") {
      return "Blog"
    } else if (data === "turient image") {
      return "Image/Docs"
    } else if (data === "link") {
      return "Link"
    } else {
      return "undefined"
    }
  }

  function getBasicResourceView() {
    let type = getFileType(activeResource.resource_type)
    if (type === "Video") {
      return (
        <VideoCard
          url={activeOriginalResourceData.url === "" ? activeOriginalResourceData.video_file : activeOriginalResourceData.url}
          channel={activeOriginalResourceData.video_file ? `turient` : activeOriginalResourceData.channel}
          width="400px"
          height="200px"
        />
      )
    } else if (type === "Image/Docs") {
      if (activeOriginalResourceData && activeOriginalResourceData.avatar && activeOriginalResourceData.avatar.toLowerCase().indexOf(".svg") > -1) {
        return (
          <img
            src={activeOriginalResourceData.avatar}
            alt={activeOriginalResourceData.name}
            width={"400px"}
            height={"200px"}
            style={{ objectFit: "contain" }}
          />
        )
      } else if (
        activeOriginalResourceData &&
        (activeOriginalResourceData.google_doc_id ||
          activeOriginalResourceData.file_type == "2" ||
          activeOriginalResourceData.file_type == "3" ||
          activeOriginalResourceData.file_type == "4")
      ) {
        let img = Docimg
        if (activeOriginalResourceData?.file_type == "5" || activeOriginalResourceData.file_type == "2") {
          img = Docimg
        } else if (activeOriginalResourceData?.file_type == "6" || activeOriginalResourceData.file_type == "4") {
          img = Pptimg
        } else if (activeOriginalResourceData?.file_type == "7" || activeOriginalResourceData.file_type == "3") {
          img = Excelimg
        }
        return <img src={img} alt={activeOriginalResourceData.name} width={"400px"} height={"200px"} style={{ objectFit: "contain" }} />
      } else if (activeOriginalResourceData.avatar.toLowerCase().indexOf(".pdf") > -1) {
        return (
          <object
            data={activeOriginalResourceData.avatar}
            width="100%"
            height="100%"
            className="mb-0 bg-transparent"
            style={{ borderRadius: "5px", border: "none" }}
          />
        )
      } else {
        return (
          <LazyLoadImage
            src={activeOriginalResourceData.avatar}
            alt={activeOriginalResourceData.name}
            width={"400px"}
            height={"200px"}
            style={{ objectFit: "contain" }}
          />
        )
      }
    } else if (type === "Link") {
      return (
        <iframe
          src={activeOriginalResourceData.link}
          title={activeOriginalResourceData.name}
          loading="lazy"
          referrerPolicy="no-referrer"
          width="400px"
          height="200px"
          className="mb-0"
          style={{ borderRadius: "5px", border: "none" }}
        />
      )
    } else if (type === "Blog") {
      return (
        // <div style={{color:'#FFFFFF',fontSize:'80px',fontWeight:'500'}}>
        <img src={DocumentIcon} alt="document/blog" width={"200px"} height={"200px"} style={{ objectFit: "contain" }} />
        // </div>
      )
    }
  }

  function getEditResourceData() {
    const size = (activeOriginalResourceData.size / 1000000).toFixed(3)

    return (
      <div className={`d-inlne-flex w-100`}>
        <div className="p-2 w-100 ml-0 float-left" style={{ fontSize: "12px", color: "#BBBFCE" }}>
          <div className={`w-100 d-inline-flex align-items-center`}>
            <div
              className={`float-left position-relative`}
              style={{
                height:
                  (activeResource && activeResource.resource_type !== "turient image") ||
                  typeof activeOriginalResourceData.avatar === "string" ||
                  typeof activeOriginalResourceData.google_doc_id == "string"
                    ? "auto"
                    : "200px",
                width:
                  (activeResource && activeResource.resource_type !== "turient image") ||
                  typeof activeOriginalResourceData.avatar === "string" ||
                  typeof activeOriginalResourceData.google_doc_id == "string"
                    ? "auto"
                    : "400px",
              }}
            >
              {(activeResource && activeResource.resource_type !== "turient image") ||
              (activeResource &&
                activeOriginalResourceData &&
                ((activeOriginalResourceData.avatar && typeof activeOriginalResourceData.avatar === "string") ||
                  typeof activeOriginalResourceData.google_doc_id === "string"))
                ? getBasicResourceView()
                : ""}
              <FontAwesomeIcon
                className={`px-3 pointer ${activeResource.resource_type === "turient image" || activeResource.resource_type === "video" ? `` : `d-none`}`}
                onClick={() => {
                  setShowEditMainResourseModal(true)
                }}
                icon={faPen}
                color="#E0E2E5"
                style={{
                  position: "absolute",
                  bottom:
                    typeof activeOriginalResourceData.avatar === "string" ||
                    activeResource.resource_type === "video" ||
                    typeof activeOriginalResourceData.google_doc_id == "string"
                      ? "0px"
                      : "50%",
                  right:
                    typeof activeOriginalResourceData.avatar === "string" ||
                    activeResource.resource_type === "video" ||
                    typeof activeOriginalResourceData.google_doc_id == "string"
                      ? "0px"
                      : "50%",
                  borderRadius: "50%",
                  backgroundColor: "#4F6080",
                  height: "55px",
                  width: "55px",
                }}
              />
            </div>
            <div className={`ml-auto`}>
              <p className="mb-0">
                Uploaded on: {activeOriginalResourceData && activeOriginalResourceData.created ? activeOriginalResourceData.created.toString() : ``}
              </p>
              <p className="mb-0">Uploaded By: {localStorage.getItem("student_first_name")} </p>
              <p className="mb-0">File name: {activeOriginalResourceData.name} </p>
              <p className="mb-0">File type: {getFileType(activeResource.resource_type)}</p>
              <p className={`mb-2 ${activeResource.resource_type === "blog" || activeResource.resource_type === "link" ? `d-none` : ``}`}>
                File size: {size}Mb
              </p>
              {/* ${activeOriginalResourceData.link ? `d-inline-flex` : activeOriginalResourceData.url ? `d-inline-flex` : `d-none`} w-100 align-items-center`} style={{backgroundColor:'#4F6080',borderRadius:'9px', border: '1px solid transparent'}}> */}
              <InputGroup
                className={`py-1 px-2 mt-3 ${
                  activeResource.resource_type === "blog" || activeResource.resource_type === "turient image" ? `d-none` : `d-inline-flex`
                } w-100 align-items-center`}
                style={{ backgroundColor: "#4F6080", borderRadius: "9px", border: "1px solid transparent" }}
              >
                <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#4F6080", borderRadius: "9px 9px 0px 9px" }}>
                  <InputGroup.Text
                    className="border-0 d-flex justify-content-center py-0 px-0 pr-2 "
                    style={{ backgroundColor: "#4F6080", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "16px" }}
                  >
                    URL
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className={`d-inline-flex`}>
                  <FormControl
                    className={`border-0 d-flex p-0 w-100 justify-content-center shadow-none  text-white`}
                    style={{ backgroundColor: "#4F6080", borderRadius: "0px", fontSize: "16px", minWidth: "275px" }}
                    type="text"
                    name={`${activeOriginalResourceData.link ? `link` : `url`}`}
                    id={`${activeOriginalResourceData.link ? `link` : `url`}`}
                    defaultValue={activeOriginalResourceData.link ? activeOriginalResourceData.link : activeOriginalResourceData.url}
                    onChange={(e) => {
                      handleEditChange(e.target.name, e.target.value)
                    }}
                    placeholder={`${activeOriginalResourceData.link ? `link` : `url`}`}
                  />
                </div>
              </InputGroup>
            </div>
          </div>
          <div className={`w-100`}>
            <Col lg="12" md="12" sm="12" xs="8" className="d-inline-flex mb-2 mt-3 p-0 w-100">
              <Col lg="8" md="8" sm="8" xs="8" className={`px-0 d-inline-flex`}>
                <div className={`mr-2 pl-0 px-0 w-100`}>
                  <Col
                    className="px-0 my-0 border_on_hover pl-0"
                    style={{
                      backgroundColor: "#303C54",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      border: "1px solid #303C54",
                      marginBottom: "12px",
                      marginTop: "12px",
                    }}
                  >
                    <InputGroup className="py-0 d-block " style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                      <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 9px 0px 9px" }}>
                        <InputGroup.Text
                          className="border-0 d-flex justify-content-center pb-0"
                          style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "16px" }}
                          onClick={(e) => {
                            document.getElementById("name").focus()
                          }}
                        >
                          Name
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className={`border-0 d-flex pt-0 w-100 justify-content-center shadow-none  text-white`}
                        style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px" }}
                        type="text"
                        name="name"
                        defaultValue={activeOriginalResourceData.name}
                        id="name"
                        placeholder=""
                        onChange={(e) => {
                          handleEditChange(e.target.name, e.target.value)
                        }}
                      />
                    </InputGroup>
                  </Col>
                </div>
              </Col>
              <Col lg="4" md="4" sm="4" xs="4" className={`px-0 d-inline-flex`}>
                <div
                  className={`ml-0 px-0 w-100 border_on_hover px-3`}
                  style={{ backgroundColor: "#303C54", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", border: "1px solid #303C54" }}
                >
                  <Col className="px-0 my-0 w-100 pl-0">
                    <InputGroup className="py-3 d-inline-flex w-100 align-items-center" style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                      <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 9px 0px 9px" }}>
                        <InputGroup.Text
                          className="border-0 d-flex justify-content-center py-0 px-0 pr-2 "
                          style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "16px" }}
                          onClick={(e) => {
                            document.getElementById("description").focus()
                          }}
                        >
                          Points
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <div className={`d-inline-flex`}>
                        <FontAwesomeIcon className={`mx-2`} icon={faGem} color="#FF7AB1" style={{ height: "25px", width: "25px" }} />
                        <FormControl
                          className={`border-0 d-flex p-0 w-100 justify-content-center shadow-none  text-white`}
                          style={{ backgroundColor: "#303C54", borderRadius: "0px", fontSize: "16px", maxWidth: "35px", maxHeight: "25px" }}
                          type="number"
                          name="points"
                          id="points"
                          defaultValue={activeOriginalResourceData.points}
                          onChange={(e) => {
                            handleEditChange(e.target.name, e.target.value)
                          }}
                        />
                        <div style={{ color: "#E0E2E5", fontSize: "16px" }}>Points</div>
                      </div>
                    </InputGroup>
                  </Col>
                </div>
              </Col>
            </Col>
            <Col
              className={`px-0 my-0 border_on_hover pl-0 ${activeOriginalResourceData.description ? `` : `d-none`}`}
              style={{
                backgroundColor: "#303C54",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "9px",
                border: "1px solid #303C54",
                marginBottom: "12px",
                marginTop: "12px",
              }}
            >
              <InputGroup className="py-0 d-block " style={{ borderRadius: "9px", border: "1px solid transparent" }}>
                <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54", borderRadius: "9px 9px 0px 9px" }}>
                  <InputGroup.Text
                    className="border-0 d-flex justify-content-center pb-0"
                    style={{ backgroundColor: "#303C54", borderRadius: "9px 0px 0px 9px", color: "#E0E2E5", fontSize: "16px" }}
                    onClick={(e) => {
                      document.getElementById("description").focus()
                    }}
                  >
                    Description
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {/* <FormControl className={`border-0 d-flex pt-0 w-100 justify-content-center shadow-none  text-white`} style={{backgroundColor:'#303C54',borderRadius:'0px', fontSize: '16px'}}
                  as="textarea"
                  type="text"
                  name="description"
                  id="description"
                  defaultValue={activeOriginalResourceData.description}
                  onChange={(e) => {handleEditChange(e.target.name,e.target.value)}}
                /> */}
                <SunEditor
                  setContents={resourceDescription}
                  onChange={setResourceDescription}
                  id="description"
                  placeholder="Add Description"
                  setOptions={{
                    height: "auto",
                    maxHeight: "200px",
                    minHeight: "200px",
                    buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link"]],
                  }}
                />
              </InputGroup>
            </Col>
          </div>

          {/* <svg className={`animate-spin my-auto ${loader[type+activeResource.id] ? `` : `d-none`}`} style={{ height: `16px`, width: `16px` }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg> */}
          <div
            className={`border_on_hover pointer mt-2 px-2 py-1 text-center`}
            onClick={() => {
              setResourceUpdated(false)
              handleEditResource()
            }}
            style={{ backgroundColor: "#4F6080", boxShadow: "0px 3px 6px #00000029", borderRadius: "9px", fontSize: "16px", width: "100px" }}
          >
            {resourceUpdated ? `save` : `saving...`}
          </div>
        </div>
      </div>
    )
  }

  function getTypeOfFile() {
    if (activeOriginalResourceData.name.toLowerCase().indexOf(".pdf") > -1) {
      return ".pdf"
    } else if (activeOriginalResourceData.name.toLowerCase().indexOf(".doc") > -1) {
      return ".doc,docx"
    } else if (activeOriginalResourceData.name.toLowerCase().indexOf(".xls") > -1) {
      return ".xlsx,.xls"
    } else if (activeOriginalResourceData.name.toLowerCase().indexOf(".ppt") > -1) {
      return ".ppt,.pptx"
    } else if (
      activeOriginalResourceData.name.toLowerCase().indexOf(".png") > -1 ||
      activeOriginalResourceData.name.toLowerCase().indexOf(".svg") > -1 ||
      activeOriginalResourceData.name.toLowerCase().indexOf(".jpeg") > -1 ||
      activeOriginalResourceData.name.toLowerCase().indexOf(".jpg") > -1
    ) {
      return "image/*"
    } else {
      return "video/*"
    }
  }

  function handleScroll(e) {
    // console.log('scroll',e.target.scrollTop)
  }

  function showGoogleDocs(doc_id, doc_type) {
    let type = "document"
    if (doc_type == "5") {
      type = "document"
    } else if (doc_type == "6") {
      type = "presentation"
    } else if (doc_type == "7") {
      type = "spreadsheets"
    }
    let link = `https://docs.google.com/${type}/d/${doc_id}/edit`
    return (
      <div>
        <iframe className={`mb-0`} src={link} style={{ width: "95%", height: "530px" }} />
      </div>
    )
  }

  function showMSDocs(link) {
    let data = `https://view.officeapps.live.com/op/embed.aspx?src=${link}`
    return <iframe className={`mb-0`} src={data} style={{ width: "95%", height: "530px" }} />
  }

  return (
    <div>
      <SEO title="Dashboard" />
      <Modal
        show={showEditResourceModal}
        onHide={() => {
          setShowEditResourceModal(false)
        }}
        size="lg"
        centered
        className={`${StylesM.classroom_delete_modal} modal-content-bg-transparent`}
      >
        <Modal.Body style={{ backgroundColor: "#212C42", borderRadius: "14px" }}>
          <img
            onClick={(e) => {
              e.preventDefault()
              setShowEditResourceModal(false)
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{ objectFit: `contain`, height: `15px`, position: "absolute", top: "10px", right: "5px" }}
          />
          {originalResourceDataFetched ? (
            getEditResourceData()
          ) : (
            <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
              <Spinner animation="border" variant="secondary" />
            </Col>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showEditMainResourseModal}
        onHide={() => {
          setShowEditMainResourseModal(false)
        }}
        size={"lg"}
        centered
        className={`${StylesM.classroom_delete_modal} ${
          activeResource &&
          activeResource.resource_type === "turient image" &&
          activeOriginalResourceData &&
          (typeof activeOriginalResourceData.google_doc_id === "string" ||
            activeOriginalResourceData.file_type == "2" ||
            activeOriginalResourceData.file_type == "3" ||
            activeOriginalResourceData.file_type == "4")
            ? StyleA.doc_resource_list
            : ""
        } modal-content-bg-transparent`}
      >
        <Modal.Header
          className={`py-2`}
          style={{ backgroundColor: "#212C42", borderRadius: "14px 14px 0px 0px", border: "1px solid #DBD7D7", color: "#E0E2E5", fontSize: "16px" }}
        >
          Edit Document
          <img
            onClick={(e) => {
              e.preventDefault()
              setShowEditMainResourseModal(false)
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{ objectFit: `contain`, height: `15px`, position: "absolute", top: "10px", right: "5px" }}
          />
        </Modal.Header>
        <Modal.Body
          className={`text-center`}
          style={{ backgroundColor: "#212C42", borderRadius: "0px 0px 14px", border: "1px solid #DBD7D7", width: "55.5vw" }}
        >
          <div>
            {(activeResource && activeResource.resource_type !== "turient image") ||
            (activeResource &&
              activeOriginalResourceData &&
              activeOriginalResourceData.avatar &&
              typeof activeOriginalResourceData.avatar === "string" &&
              !(activeOriginalResourceData.file_type == "2" || activeOriginalResourceData.file_type == "3" || activeOriginalResourceData.file_type == "4"))
              ? getBasicResourceView()
              : null}
            {activeResource &&
            activeResource.resource_type === "turient image" &&
            activeOriginalResourceData &&
            typeof activeOriginalResourceData.google_doc_id === "string" ? (
              showGoogleDocs(activeOriginalResourceData.google_doc_id, activeOriginalResourceData.file_type)
            ) : activeResource &&
              activeResource.resource_type === "turient image" &&
              activeOriginalResourceData &&
              (activeOriginalResourceData.file_type == "2" || activeOriginalResourceData.file_type == "3" || activeOriginalResourceData.file_type == "4") ? (
              showMSDocs(activeOriginalResourceData.avatar)
            ) : (
              <React.Fragment>
                <img
                  id="output"
                  className={`${
                    (activeResource && activeResource.resource_type !== "turient image") ||
                    (activeOriginalResourceData.avatar && typeof activeOriginalResourceData.avatar === "string")
                      ? `d-none`
                      : ``
                  } `}
                  width="400"
                  height="200"
                />
                <p id="resource-name" className={`mb-2`} style={{ color: "#E0E2E5", fontSize: "`16px", fontWeight: "500" }}></p>
                <FormGroup style={{ color: "#E0E2E5" }}>
                  <Form.File
                    name="avatar"
                    id="getFile"
                    accept={activeOriginalResourceData ? getTypeOfFile() : ""}
                    className="form-control-file-avatar d-none"
                    onChange={(e) => {
                      if (activeResource.resource_type === "turient image") {
                        var image = document.getElementById("output")
                        image.src = URL.createObjectURL(e.target.files[0])
                      }
                      document.getElementById("resource-name").innerText = e.target.files[0].name
                      handleEditChange(e.target.name, e.target.files[0])
                    }}
                    required={activeOriginalResourceData.id === undefined ? true : false}
                  />
                </FormGroup>
                <div className={`d-inline-flex`}>
                  {selectedVideoData ? (
                    <video className="d-none" style={{ width: "350px", height: "250px" }} id={`video-element`} controls>
                      <source src={URL.createObjectURL(selectedVideoData)} type="video/mp4" />
                    </video>
                  ) : null}
                  <label
                    className={`button btn border-0 mb-0 px-4 mr-2 border_on_hover ${StylesM.font_14}`}
                    style={{ backgroundColor: `#586886`, boxShadow: "0px 3px 6px #00000029", color: "#E0E2E5", borderRadius: "36px" }}
                    htmlFor="getFile"
                  >
                    {activeResource && activeResource.resource_type === "video" ? `Change Video` : `Change Document`}
                  </label>
                  <div
                    className={`button btn border-0 px-5 ml-2 border_on_hover ${StylesM.font_14}`}
                    onClick={() => {
                      setResourceUpdated(false)
                      handleEditResource()
                    }}
                    style={{ backgroundColor: `#586886`, boxShadow: "0px 3px 6px #00000029", color: "#E0E2E5", borderRadius: "36px" }}
                  >
                    {resourceUpdated ? `save` : `saving...`}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal show={showResourceDeleteModal} onHide={() => {setShowResourceDeleteModal(false)}} centered className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal`} >
        <div className={`${StylesA.delete_modal_header}`}>
        <img onClick={() => {setShowResourceDeleteModal(false)}} src={CancelWhite} alt={`Cancel`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, height: `20px`,position:'absolute',top:'10px',right:'5px' }}/>
          <img src={TrashCan} alt={`delete modal delete icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `20px` }}/>
          <div>
            <p style={{fontWeight:'bold',fontSize:'16px'}}><u style={{color:'white'}}>{activeDeleteData ?  activeDeleteData.content_object.name : ''}</u> will be deleted premanently</p>
          </div>
        </div>
        <Modal.Body className="text-center" style={{backgroundColor:'#121b2b',borderRadius: '0px 0px 8px 8px'}} >
          <p className={`mb-0`} style={{color: '#E0E2E5',fontSize:'16px',marginBottom:'10px'}} > Are you sure to delete ? </p>
          <u className={`mb-2`} style={{color: '#E0E2E5', fontWeight:'500',fontSize:'16px'}}>{activeDeleteData ?  activeDeleteData.content_object.name : ''}</u>
          <div className="d-flex justify-content-center mt-2" >
            <Button className={`${StylesA.add_button_large} border_on_hover mr-3`} variant="secondary" onClick={() => {handleDeleteResource(activeDeleteData)}} style={{width:'75px',border:'1px solid transparent'}} >Yes</Button>
            <Button className={`${StylesA.add_button_large} border_on_hover`} variant="secondary" onClick={() => {setShowResourceDeleteModal(false)}} style={{width:'75px',border:'1px solid transparent'}} >No</Button>
          </div>
        </Modal.Body>
      </Modal> */}
      {/* <Modal show={showResourceDeleteSucessModal} onHide={() => {setShowResourceDeleteSucessModal(false)}} centered className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal`} >
        <Modal.Body className="text-center" style={{backgroundColor:'#192336',borderRadius: '8px'}} >
          <img onClick={() => {setShowResourceDeleteSucessModal(false)}} src={CancelWhite} alt={`Cancel`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, height: `20px`,position:'absolute',top:'10px',right:'5px' }}/>
          <img src={SucessTickIcon1} alt={`Cancel`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, height: `40px`}}/>
          <p className={`mb-0 mt-2`} style={{color: '#E0E2E5',fontWeight:'500',fontSize:'16px',marginBottom:'10px'}} ><u>{activeDeleteData ?  activeDeleteData.content_object.name : ''}</u> has been deleted successfully </p>
        </Modal.Body>
      </Modal> */}
      <Col className={`align-items-center d-inline-flex w-100 pl-0 pr-3`}>
        <div>
          {company.length === 0 ? (
            <Link to={"/app/companies/create"}>Create Company</Link>
          ) : (
            <div className={`mt-3 position-relative`}>
              {showTopBar()}
              {showResources()}
            </div>
          )}
        </div>
      </Col>
      {/* <div className={`float-left pt-3 w-100`}   onScroll={(e) => {handleScroll(e)}}>
        <div
          id="fetch_training_btn"
          className="d-none"
          onClick={fetchTrainingHash}
        />
        <Col
          xs="12"
          sm="12"
          lg="12"
          md="12"
          className="float-right"
          style={{ paddingLeft: pixelResize(0) }}
         
        >
          {showHeader()}
          {company.length === 0 ? (
            <Link to={"/app/companies/create"}>Create Company</Link>
          ) : (
            <Col
              lg="12"
              md="12"
              sm="12"
              xs="12"
              className={`px-0`}
              style={{ marginTop: pixelResize(0, "height") }}
             
            >
              <div style={{width:'100%',height:"100vh",overflowY:'hidden',position:'relative'}}>
              {showTopBar()}
              {showResources()}
              </div>
            </Col>
          )}
        </Col>
      </div> */}
      <Notification data={notificationData} setData={setNotificationData} />
      <DeleteModal
        show={Object.keys(activeDeleteData).length > 0}
        onHide={handleDeleteModalClose}
        itemName={activeDeleteData?.itemName}
        action={activeDeleteData?.action}
      />
    </div>
  )
}

export default NewResourceDashboard
