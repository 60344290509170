import React, { useState } from "react"
import { navigate } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import authClient from "../../../services/auth"
import { isBrowser } from "../../../services/developer_mode"

const CreateStage = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [stage, setStage] = useState({ add_to_template: location.template_id })

  function handleChange(event) {
    event.preventDefault()
    stage[event.target.name] = event.target.value
  }
  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in stage) {
      formData.append(k, stage[k])
    }
    if (stage.id != undefined) {
    } else {
      var stage_id = stage.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/stages/create/", formData, { headers: login_credentials })
        .then((res) => {
          navigate("/app/default-course-templates/")
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  return (
    <div>
      <SEO title="Add stage" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h4>Add stage</h4>
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col>
              <div>
                <Row>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" defaultValue={stage.name} onChange={handleChange} id="name" placeholder="Enter stage name" required />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6" md="12">
                    <FormGroup>
                      <label htmlFor="sequence">sequence</label>
                      <input
                        type="number"
                        name="sequence"
                        defaultValue={stage.sequence}
                        onChange={handleChange}
                        id="sequence"
                        placeholder="Enter stage sequence"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Col>
            <Button type="submit" color="warning">
              Save
            </Button>{" "}
          </Form>
          <Button onClick={() => navigate(`/app/default-course-templates/`)} variant="danger">
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CreateStage
