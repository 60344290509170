import React, { useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

const CreateLink = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [link, setLink] = useState({})

  // console.log(location,course, "location.dilsjdio")
  // const { state = {} } = location

  function handleChange(event) {
    event.preventDefault()
    link[event.target.name] = event.target.value
  }
  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in link) {
      if (k === "link" && link[k].search(/^http(s):\/\//i) === -1) {
        link[k] = "https://" + link[k]
      }
      formData.append(k, link[k])
    }
    formData.append("add_to_template", true)

    if (link.id != undefined) {
    } else {
      var link_id = link.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/links/create/", formData, { headers: login_credentials })
        .then((res) => {
          navigate("/app/links")
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  return (
    <div>
      <SEO title="Link Creation" />
      <h5 className="border-bottom p-2">
        Add Link
        <Link className={`pointer text-white`} to={`/app/links`}>
          <span style={{ fontWeight: "normal" }} className="float-right small">
            {" "}
            Exit Creation{" "}
          </span>
        </Link>{" "}
      </h5>
      <Col lg="12" md="12" sm="12" xs="12" className={`d-inline-flex justify-content-center pt-3`}>
        <Col lg="6" md="6" sm="6" xs="6">
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col xs="12" sm="12" md="12" className="light-div p-0 theme-color float-left" style={{ minHeight: `fit-content` }}>
              <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom">
                <FormGroup className="m-0">
                  <div>Link Name</div>
                  <input
                    style={{ background: "inherit", color: "inherit", fontSize: "12px" }}
                    className="blue-bg video-modal p-0"
                    type="text"
                    name="name"
                    defaultValue={link.name}
                    onChange={handleChange}
                    id="name"
                    placeholder="Enter Link Name"
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2 border-bottom">
                <FormGroup className="m-0">
                  <div>Link URL</div>
                  <input
                    style={{ background: "inherit", color: "inherit", fontSize: "12px" }}
                    className="blue-bg video-modal p-0"
                    type="text"
                    name="link"
                    defaultValue={link.link}
                    onChange={handleChange}
                    id="link"
                    placeholder="Enter Link URL"
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2">
                <FormGroup className="m-0">
                  <div>Link Points</div>
                  <input
                    style={{ background: "inherit", color: "inherit", fontSize: "12px" }}
                    className="blue-bg video-modal p-0"
                    type="number"
                    name="points"
                    defaultValue={link.points}
                    onChange={handleChange}
                    id="points"
                    placeholder="Enter Link points"
                    required
                  />
                </FormGroup>
              </Col>
            </Col>
            <Col xs="12" className="mt-3 float-left px-0">
              <Button className="float-right" style={{ background: "#576186", border: 0 }} type="submit" color="warning">
                Save
              </Button>{" "}
            </Col>
          </Form>
        </Col>
      </Col>
    </div>
  )
}

export default CreateLink
