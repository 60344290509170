import React, { useEffect, useState, useCallback, useRef } from "react"
import { navigate } from "gatsby"

import _ from "lodash"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import FormControl from "react-bootstrap/FormControl"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Dropdown from "react-bootstrap/Dropdown"
import Spinner from "react-bootstrap/Spinner"

import { Droppable, Draggable } from "react-beautiful-dnd"
import ClassRoomPoll from "../../components/class-room-training/classroom-poll"
import ClassroomPopquiz from "../../components/class-room-training/classroom-popquiz"
import UploadResource from "../../components/upload-resource"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpload, faChevronLeft, faChevronRight, faAngleDown, faCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"

import authClient from "../../services/auth"
import Common from "../../services/common"
import { isBrowser } from "../../services/developer_mode"

import Styles from "../../modularscss/styles.module.scss"
import StylesB from "./classroom-resource-section.module.scss"

import video from "../../images/svgs/video.svg"
import link from "../../images/svgs/link.svg"
import turientimage from "../../images/svgs/turientimage.svg"
import blog from "../../images/svgs/blog.svg"
import quiz from "../../images/svgs/quiz.svg"
import SearchIcon from "../../images/svgs/search.svg"
import AddActivity from "../../images/svgs/add_activity.svg"
import DeleteResource from "../../images/svgs/delete_resource.svg"
import TriggerActivity from "../../images/svgs/trigger_resource_white_icon.svg"
import UploadIcon from "../../images/svgs/feather-upload-white.svg"
import EllipsisWhite from "../../images/svgs/ellipsis-white.svg"
import EditIcon from "../../images/svgs/edit.svg"
import WhitePollIcon from "../../images/svgs/polls-white-icon.svg"
import doc from "../../images/svgs/doc.svg"
import excel from "../../images/svgs/excel.svg"
import ppt from "../../images/svgs/ppt.svg"
import pdf from "../../images/svgs/pdf.svg"
import poll from "../../images/svgs/poll.svg"
import popQuiz from "../../images/svgs/popquiz.svg"
import checklist from "../../images/svgs/checklist.svg"
import casestudy from "../../images/svgs/casestudy.svg"
import mtf from "../../images/svgs/mtf.svg"

import { putRequest } from "../../services/rest_service"

const ResourceList = ["Video", "Blog", "Document", "Turientimage", "Link", "Quiz", "MTF"]
const ResourceTypeList = ["video", "blog", "document", "turientimage", "link", "quiz", "mtf"]
const ResourceTypeList2 = ["poll", "pop-quiz", "checklist", "casestudy"]
const ResourceIcons = {
  video: video,
  blog: blog,
  document: turientimage,
  turientimage: turientimage,
  link: link,
  quiz: quiz,
  poll: quiz,
  "pop-quiz": quiz,
  checklist: checklist,
  casestudy: casestudy,
  mtf: mtf,
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",
  // outline: "none",
  // padding: grid,
  // margin: `0`,
  borderRadius: "5px",
  // textAlign: 'center',
  color: "rgb(224, 226, 229)",
  border: "1px solid transparent",

  // change background colour if dragging
  // background: isDragging ? "transparent" : "#12172C",
  background: "rgb(48, 60, 84)",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "transparent" : "#12172c",
  // padding: grid,
})

const ResourceItem = (props) => {
  function openInNewTabOrExisting(url) {
    //if (props.type === 'classroom') {
    window.open(url, "liveClassResources")
    //} else navigate(url)
  }

  function gotoSingleParticularSection(id, currentSec) {
    //if (props.type === 'classroom') return;
    //if (props.enableDND !== true) {
    //props.setSidebarCollapsed(false)
    if (currentSec === "poll") {
      openInNewTabOrExisting(`/app/polls/${id}/edit/`)
    } else if (currentSec === "pop-quiz") {
      openInNewTabOrExisting(`/app/pop-quizzes/${id}/edit/`)
    } else if (currentSec === "video") {
      openInNewTabOrExisting(`/app/videos/edit/${id}/`)
    } else if (currentSec === "blog") {
      openInNewTabOrExisting(`/app/blogs/edit/${id}/`)
    } else if (currentSec === "link") {
      openInNewTabOrExisting(`/app/links/edit/${id}/`)
    } else if (currentSec === "quiz") {
      openInNewTabOrExisting(`/app/quizzes/edit/${id}/`)
    } else if (currentSec === "turientimage" || currentSec === "document") {
      openInNewTabOrExisting(`/app/files/edit/${id}/`)
    } else if (currentSec === "checklist") {
      openInNewTabOrExisting(`/app/checklist/edit/${id}/`)
    } else if (currentSec === "casestudy") {
      openInNewTabOrExisting(`/app/case-study/edit/${id}/`)
    }
    //}
  }

  function editPollResource(e) {
    let id = props.list_type === "My List" ? props.item.resource_id : props.item.id

    e.preventDefault()
    e.stopPropagation()
    openInNewTabOrExisting(`/app/polls/${id}/edit/`)
    return
    props.setCurrentResource(props.item.resource_data)
    props.setShowPollResult(false)
    props.setCreateNewPoll(false)
    props.setCurrentPoll(id)
    props.setShowPollModal(true)
  }

  function editPopQuizResource(e) {
    let id = props.list_type === "My List" ? props.item.resource_id : props.item.id

    e.preventDefault()
    e.stopPropagation()
    props.setCurrentResource(props.item.resource_data)
    props.setShowPollResult(false)
    props.setCreateNewPopquiz(false)
    props.setCurrentPopquiz(id)
    props.setShowPopquizModal(true)
  }

  function showViewDetails() {
    let icon = { poll: WhitePollIcon, "pop-quiz": popQuiz, quiz: quiz, checklist: checklist, casestudy: casestudy },
      display = 0,
      func = null

    if (props.currentSelectedResourcesType === "poll" && props.item.is_triggered) {
      display = 1
      func = (e) => {
        e.preventDefault()
        e.stopPropagation()
        props.setShowPollResult(true)
        props.setCreateNewPoll(false)
        props.setCurrentPoll(props.item.id)
        props.setShowPollModal(true)
      }
    } else if (
      props.item.is_triggered &&
      (props.currentSelectedResourcesType === "pop-quiz" ||
        props.currentSelectedResourcesType === "quiz" ||
        props.currentSelectedResourcesType === "checklist" ||
        props.currentSelectedResourcesType === "casestudy")
    ) {
      display = 1
      func = (e) => {
        e.preventDefault()
        e.stopPropagation()
        props.setLeftNavOpen(false)
        const evt = new CustomEvent("my-view-details", {
          bubbles: true,
          detail: props.item,
          activeActivityData: props.activeActivityData,
        })
        document.dispatchEvent(evt)
      }
    } else {
      func = void 0
    }

    if (display && props?.item?.triggered_count > 0) {
      return (
        <div onClick={func} className="d-flex pointer" style={{ borderTop: "1px solid #929bbd" }}>
          <img
            src={icon[props.currentSelectedResourcesType]}
            alt={`resource_result_${props.item.id.toString()}`}
            className={`my-auto mr-1`}
            style={{
              objectFit: `contain`,
              width: `13px`,
              height: `13px`,
            }}
          />
          <p>View Results</p>
        </div>
      )
    }
  }
  function showEditButton() {
    let functionName,
      display = 0
    if (props.currentSelectedResourcesType === "poll") {
      if (props.item.is_triggered && !props.item.end_activity) {
        return null
      } else {
        functionName = editPollResource
        display = 1
      }
    } else if (props.currentSelectedResourcesType === "pop-quiz") {
      functionName = editPopQuizResource
      display = 1
    } else if (props.type === "classroom") {
      functionName = editAnyResource
      display = 1
    }

    if (display) {
      return (
        <div className="d-flex pointer" onClick={(e) => functionName(e, true)} style={{ borderBottom: "1px solid #929bbd" }}>
          <img
            src={EditIcon}
            alt={`resource_edit_${props.item.id.toString()}`}
            className={`my-auto mr-1`}
            style={{ objectFit: `contain`, width: `13px`, height: `13px` }}
          />
          <p>Edit Resource</p>
        </div>
      )
    }
  }

  const showTriggeredResourceButton = () => {
    return props.item?.id === props.activeActivityData?.current_active_activity?.resource?.id &&
      !["pop-quiz", "quiz", "checklist", "casestudy", "poll"].includes(props.currentSelectedResourcesType) ? (
      <div
        className="d-flex pointer"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.myListActivityResource(e, props.currentSelectedResourcesType, props.index, props.item, false, true)
        }}
        style={{ borderTop: "1px solid #929bbd" }}
      >
        <img src={TriggerActivity} alt={`resource_edit_delete`} className={`my-auto mr-1`} style={{ objectFit: `contain`, width: `13px`, height: `13px` }} />
        <p>Show Resource</p>
      </div>
    ) : null
  }

  function showDeleteButton() {
    if (props.type !== "classroom") return
    return (
      <div
        className="d-flex pointer"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.myListActivityResource(e, props.currentSelectedResourcesType, props.index, props.item, true /*delete resource */)
          props.fetchResourceActivity("true", "resource")
        }}
      >
        <img src={DeleteResource} alt={`resource_edit_delete`} className={`my-auto mr-1`} style={{ objectFit: `contain`, width: `13px`, height: `13px` }} />
        <p>Remove from List</p>
      </div>
    )
  }

  function showEllipsisMenu() {
    //
    if (
      props.list_type === "My List" &&
      (props.type === "classroom" || props.currentSelectedResourcesType === "poll" || props.currentSelectedResourcesType === "pop-quiz")
    ) {
      return (
        <Dropdown style={{ lineHeight: 1 }}>
          <Dropdown.Toggle
            as="div"
            variant="none"
            id={`resource-option-menu-${props.item.id}`}
            className={`shadow-none p-0 m-0 ${Styles.no_caret} ${StylesB.image_with_hover} `}
          >
            <img
              src={EllipsisWhite}
              alt={`ellipsis_icon_${props.item.id.toString()}`}
              className={`my-auto pointer`}
              style={{
                objectFit: `contain`,
                width: `10px`,
                height: `16px`,
                marginLeft: "3px",
                marginRight: "8px",
                padding: "2px 0px",
              }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className={`${StylesB.resource_option_menu}`}>
            {showEditButton()}
            {showDeleteButton()}
            {showViewDetails()}
            {showTriggeredResourceButton()}
          </Dropdown.Menu>
        </Dropdown>
      )
    } else {
      return null
    }
  }
  function showAddActivityButton() {
    if (
      props.type === "classroom" &&
      (props.activeSection === "BreakoutSession" || props.activeSection === "Resource" || (props.activeSection === "LessonBoard" && props.disableScroll))
    ) {
      return null
    } else {
      return (
        <img
          src={AddActivity}
          alt={`${props.item?.name ? props.item.name.toLowerCase().split(" ").join("-") : "classroom"}-add-activity`}
          className={`my-auto pointer`}
          style={{
            objectFit: `contain`,
            width: props.type === "classroom" ? "20px" : `18px`,
            height: props.type === "classroom" ? "20px" : `18px`,
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            props.publishResource(props.item)
            props.fetchResourceActivity("true", "resource")
          }}
        />
      )
    }
  }
  function editAnyResource(e, doAction) {
    if (!doAction && props.type === "classroom") return

    let id = props.list_type === "My List" ? props.item.resource_id : props.item.id
    e.preventDefault()
    gotoSingleParticularSection(id, props.currentSelectedResourcesType)
  }

  let imgObj = props.item.avatar ? props.item.avatar : props.logo

  if (props.currentSelectedResourcesType === "turientimage") {
    imgObj = props.item.avatar || (props.list_type === "My List" && props.item.resource_data ? props.item.resource_data?.avatar : props.logo)
  }
  if (props.currentSelectedResourcesType === "document") {
    let fileType = props.item?.resource_data?.file_type || props.item?.file_type
    if (fileType == 2 || fileType == 5) {
      imgObj = doc
    } else if (fileType == 3 || fileType == 7) {
      imgObj = excel
    } else if (fileType == 4 || fileType == 6) {
      imgObj = ppt
    } else if (fileType == 1) {
      imgObj = pdf /*props.logo*/
    }
  } else if (props.currentSelectedResourcesType === "quiz") {
    imgObj = quiz
    let dataType = props.item?.resource_data?.data_type || 0
    if (dataType == 1) {
      imgObj = poll
    } else if (dataType == 2) {
      imgObj = popQuiz
    }
  } else if (props.currentSelectedResourcesType === "poll") {
    imgObj = poll
  } else if (props.currentSelectedResourcesType === "pop-quiz") {
    imgObj = popQuiz
  } else if (props.currentSelectedResourcesType === "checklist") {
    imgObj = checklist
  } else if (props.currentSelectedResourcesType === "casestudy") {
    imgObj = casestudy
  }

  const getResourceName = () => {
    if (props.list_type === "My List" && props.item.resource_data) {
      return props.item.resource_data?.display_name ?? props.item.resource_data?.name
    } else {
      return props.item.display_name ?? props.item.name
    }
  }

  const showTooltip = () => {
    if (props.item?.resource_data) {
      if (props.item.resource_data?.display_name) {
        return props.item.resource_data?.display_name.length > 35
      } else {
        return props.item.resource_data?.name.length > 35
      }
    } else {
      if (props.item.display_name) {
        return props.item.display_name.length > 35
      } else {
        return props.item.name.length > 35
      }
    }
  }

  return (
    <div className="d-flex align-items-center float-left w-100 position-relative">
      {props.type === "classroom" && props.item?.id === props.activeActivityData?.current_active_activity?.resource?.id ? (
        <FontAwesomeIcon
          icon={faCircle}
          size="xs"
          style={{
            position: "absolute",
            left: "-14px",
            top: "-15px",
            color: "46C99E",
          }}
        />
      ) : (
        ""
      )}
      <img
        src={imgObj}
        alt={props.currentSelectedResourcesType}
        className={`mb-0`}
        style={{
          objectFit: `contain`,
          height: props.item.avatar && props.item.avatar.indexOf(".pdf") < 1 ? `20px` : `20px`,
          left: 0,
          top: 0,
        }}
        onClick={(e) => editAnyResource(e, false)}
      />
      {showTooltip() ? (
        <OverlayTrigger
          placement={"top"}
          overlay={
            <Tooltip
              className="event-tooltip"
              style={{ zIndex: "10000" }}
              id={`tooltip-${props.item.display_name ?? props.item.name ?? props.item.resource_data?.display_name ?? props.item.resource_data?.name}`}
            >
              {props.item.display_name ?? props.item.name ?? props.item.resource_data?.display_name ?? props.item.resource_data?.name}
            </Tooltip>
          }
        >
          <p
            onClick={(e) => editAnyResource(e, false)}
            className={`elipsis-1 w-100 px-2 ${Styles.font_12}`}
            style={{ margin: `0px`, fontWeight: "bold", lineHeight: "1.3" }}
          >
            {getResourceName()}
          </p>
        </OverlayTrigger>
      ) : (
        <p
          className={`elipsis-1 w-100 px-2 ${Styles.font_12} ${Styles.b_0}`}
          style={{
            margin: `0px`,
            fontWeight: "bold",
            WebkitBoxOrient: "vertical",
            lineHeight: "1.3",
          }}
          onClick={(e) => editAnyResource(e, false)}
        >
          {getResourceName()}
        </p>
      )}
      {props.type === "classroom" && props.loadingResource?.resource_id === props.item.id ? (
        <FontAwesomeIcon
          className={`${props.loadingResource?.loading ? "" : "d-none"}`}
          icon={faSpinner}
          style={{ width: `11px`, height: `11px`, marginRight: "5px" }}
        />
      ) : null}
      {props.list_type === "All" ? (
        showAddActivityButton()
      ) : (
        <img
          src={props.type === "classroom" ? TriggerActivity : DeleteResource}
          alt={`${props.item && props.item.name ? props.item.name.toLowerCase().split(" ").join("-") : "classroom"}-delete-resource`}
          className={`${StylesB.image_with_hover} m-auto p-1 pointer`}
          style={{
            objectFit: `cover`,
            width: props.type === "classroom" ? "34px" : `22px`,
            height: props.type === "classroom" ? "24px" : `22px`,
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            props.myListActivityResource(e, props.currentSelectedResourcesType, props.index, props.item)
            // props.fetchResourceActivity("true", "resource")
            // if (props.type === "classroom" && props.currentSelectedResourcesType !== "poll") {
            //   props.setLeftNavOpen(false)
            // }
          }}
        />
      )}
      {showEllipsisMenu()}
    </div>
  )
}

const ClassResourceSection = (props, location, data) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [searchValue, setSearchValue] = useState("")
  const [searchData, setSearchData] = useState({})

  // each resource type full data in allResources array
  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(false)

  // each resource type full data in allResources array
  const [allPublishedResources, setAllPublishedResources] = useState({})
  const [allPublishedResourcesNext, setPublishedAllResourcesNext] = useState({})
  const [allPublishedResourcesFetched, setPublishedAllResourcesFetched] = useState(false)

  const [draggedData, setDraggedData] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [intialFetch, setIntialFetch] = useState(false)

  const [showPublished, setShowPublished] = useState(false)
  const [showPollModal, setShowPollModal] = useState(false)
  const [showPollResult, setShowPollResult] = useState(false)

  const [currentPoll, setCurrentPoll] = useState("")
  const [currentResource, setCurrentResource] = useState("")
  const [createNewPoll, setCreateNewPoll] = useState(false)

  const [currentPopquiz, setCurrentPopquiz] = useState("")
  const [createNewPopquiz, setCreateNewPopquiz] = useState(false)
  const [showPopquizResult, setShowPopquizResult] = useState(false)
  const [showPopquizModal, setShowPopquizModal] = useState(false)
  const [showResourceModal, setShowResourceModal] = useState(false)
  const [defineFiles, setDefineFiles] = useState("")

  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState(setprops())
  const [resource_to_show, setResourceToShow] = useState("My List")
  const [resource_sub_type, setResourceSubType] = useState("all")

  const [hoveredResourcesSection, setHoveredResourcesSection] = useState(false)
  const [showScrollRight, setShowScrollRight] = useState(false)
  const [showScrollLeft, setShowScrollLeft] = useState(false)
  const [docType, setDocType] = useState(0)
  const [docTypeStr, setDocTypeStr] = useState("")
  const [showAddResToLib, setShowAddResToLib] = useState(false)
  const [loadingResource, setLoadingResource] = useState({
    resource_id: null,
    loading: false,
  })

  const [updatePopQuizState, setUpdatePopQuizState] = useState(false)
  const [popQuizUpdatedData, setPopQuizUpdatedData] = useState({})

  const PageSize = useRef(10)

  function sendQuery(value, type, section, res_sub_type) {
    if (type === "search") {
      fetchAllResources(true, "", value, section, type)
      fetchPublishedResources(true, value, section, type, res_sub_type)
    }
  }

  const delayedQuery = useCallback(
    _.debounce((value, type, section, res_sub_type) => sendQuery(value, type, section, res_sub_type), 1000),
    []
  )

  useEffect(() => {
    if (window?.screen?.height > 0) {
      handleResize()
      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [window?.screen?.height])

  function handleResize() {
    let height = window?.screen?.height ?? 480
    if (height <= 640) {
      PageSize.current = 8
    } else if (height <= 800) {
      PageSize.current = 10
    } else if (height <= 900) {
      PageSize.current = 16
    } else if (height <= 1050) {
      PageSize.current = 20
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("my-refresh-resources", handleRefresh)
      window.addEventListener("my-openClosePollPopQuizModal", handleOpenClosePP)
      window.addEventListener("my-pop-quiz-results", handlePopquizState, false)

      return () => {
        window.removeEventListener("my-refresh-resources", handleRefresh)
        window.removeEventListener("my-openClosePollPopQuizModal", handleOpenClosePP)
        window.removeEventListener("my-pop-quiz-results", handlePopquizState, false)
      }
    }
  }, [window])

  function handleRefresh(evt) {
    if (evt.detail) {
      setTimeout(() => {
        fetchAllResources(true, evt.detail)
      }, 1500)
    }
  }

  useEffect(() => {
    if (updatePopQuizState && popQuizUpdatedData) {
      if (Object.keys(allPublishedResources).length > 0 && allPublishedResources["pop-quiz"].length > 0) {
        let id = allPublishedResources["pop-quiz"].findIndex((res) => res.id === popQuizUpdatedData.id)
        if (id > -1) {
          let resources = { ...allPublishedResources }
          resources["pop-quiz"][id] = popQuizUpdatedData
          setAllPublishedResources(resources)
        }
      }
    }
  }, [updatePopQuizState, popQuizUpdatedData])

  function handlePopquizState(evt) {
    setUpdatePopQuizState(true)
    setPopQuizUpdatedData(evt.detail)
  }

  function handleOpenClosePP(evt) {
    if (evt.detail) {
      let openClose = evt.detail.openclose //( === 'open') ? true : false
      if (evt.detail.which === "poll") {
        setShowPollModal(openClose)
        setShowPollResult(false)
        setCreateNewPoll(true)
      } else if (evt.detail.which === "pop-quiz") {
        setShowPopquizModal(openClose)
        setShowPopquizResult(false)
        setCreateNewPopquiz(true)
      }
    }
  }

  useEffect(() => {
    if (props.showPollModal === true) {
      setShowPollModal(props.showPollModal)
      setShowPollResult(false)
      setCreateNewPoll(true)
    }
  }, [props.showPollModal])

  useEffect(() => {
    if (props.showPopQuizModal === true) {
      setShowPopquizModal(props.showPopQuizModal)
      setShowPopquizResult(false)
      setCreateNewPopquiz(true)
    }
  }, [props.showPopQuizModal])

  function setprops() {
    if (props.currentSelectedResourcesType) {
      return props.currentSelectedResourcesType
    } else {
      setInitialResourceType()
    }
  }
  function setInitialResourceType() {
    if (props.currentNavTab === "Resource") {
      return "video"
    } else {
      return "poll"
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      fetchStudentDefineFiles()
    }
  }, [isBrowser()])

  async function fetchStudentDefineFiles() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/fetch-learner-define-file/`, { headers: login_credentials })
      .then((res) => {
        localStorage.setItem("defineFiles", res.data.define_files)
        setDefineFiles(res.data.define_files)
      })
      .catch((error) => {
        if (error.response) {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        }
        if (!error.response) {
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            "Please check your internet connection" +
            "</div>"
        }
      })
  }

  useEffect(() => {
    if (props.currentNavTab === "Poll" && ResourceTypeList2.indexOf(currentSelectedResourcesType) < 0) {
      setCurrentSelectedResourcesType("poll")
    } else if (props.currentNavTab === "Resource" && ResourceTypeList.indexOf(currentSelectedResourcesType) < 0) {
      setCurrentSelectedResourcesType("video")
    }
  }, [props.currentNavTab])

  useEffect(() => {
    if (props.leftNavOpen && !props.currentSelectedResourcesType) {
      let res = setInitialResourceType()
      setCurrentSelectedResourcesType(res)
    }
  }, [props.leftNavOpen])

  useEffect(() => {
    let refetch = false
    if (searchValue !== searchData[currentSelectedResourcesType]) {
      refetch = true
      let search_data = { ...searchData }
      search_data[currentSelectedResourcesType] = searchValue
      setSearchData(search_data)
    }
    fetchAllResources(refetch)
    fetchPublishedResources(refetch)
    if (props.setHomepageActiveResource) {
      props.setHomepageActiveResource(currentSelectedResourcesType)
    }
  }, [currentSelectedResourcesType, PageSize])

  useEffect(() => {
    if (Object.keys(allPublishedResources).length > 0) {
      fetchPublishedResources(true)
    }
  }, [resource_sub_type])

  useEffect(() => {
    if (props.currentSelectedResourcesType && props.currentSelectedResourcesType !== currentSelectedResourcesType) {
      setCurrentSelectedResourcesType(props.currentSelectedResourcesType)
    }
    if (props.currentSelectedResourcesType === "quiz" || props.currentSelectedResourcesType === "mtf") {
      scrollRight("resources")
    } else {
      scrollLeft("resources")
    }
  }, [props.currentSelectedResourcesType])

  useEffect(() => {
    // fetchAllResources(true)
    // fetchPublishedResources(true)
    let search_data = { ...searchData }
    search_data[currentSelectedResourcesType] = searchValue
    setSearchData(search_data)
  }, [searchValue])

  useEffect(() => {
    if (currentSelectedResourcesType) {
      fetchAllResources(true)
      fetchPublishedResources(true)
    }
  }, [docType])

  useEffect(() => {
    if (props.allResources) {
      setAllResources(props.allResources)
    }
  }, [props.allResourcesChanged])

  function changeAllResources(new_resources, selType = "") {
    let temp_data = { ...allResources }
    if (selType === "") selType = currentSelectedResourcesType
    temp_data[selType] = new_resources
    setAllResources(temp_data)
    if (props.setHomepageAllResource) {
      let temp = { ...temp_data }
      props.setHomepageAllResource(temp)
    }
  }

  function refetch() {
    /*let temp = {...allResources}
    ResourceList.map((v) => {
      temp[v.toLowerCase()] = undefined
    })
    setAllResources(temp)*/

    if (currentSelectedResourcesType) {
      fetchAllResources(true)
    }
  }
  function getFileTypeForDoc() {
    let fileType = "all"
    switch (docType) {
      case "PDF":
        fileType = "1"
        break
      case "DOC":
        fileType = "doc"
        break
      case "EXCEL":
        fileType = "xls"
        break
      case "PPT":
        fileType = "ppt"
        break
      default:
        break
    }
    return fileType
  }

  async function fetchAllResources(refetch = false, selResType = "", value = "", section = null, type = null) {
    if (selResType === "" || selResType === undefined || selResType === null) {
      selResType = currentSelectedResourcesType ? currentSelectedResourcesType : section
    } else {
      setCurrentSelectedResourcesType(selResType)
    }

    if (selResType === "" || selResType === undefined || selResType === null) {
      return
    }

    if (refetch === true || allResources[selResType] === undefined) {
      if (!section) {
        setAllResourcesFetched(false)
      }
      let data_type = ""
      let type = "basic-" + selResType + "s-list"
      if (selResType === "turientimage") {
        type = "basic-images-list"
        data_type = "&file_type=0"
      } else if (selResType === "quiz") {
        type = "basic-quizzes-list"
        data_type = "&data_type=0&published=true"
      } else if (selResType === "poll") {
        type = "basic-quizzes-list"
        data_type = "&data_type=1&published=true"
      } else if (selResType === "pop-quiz") {
        type = "basic-quizzes-list"
        data_type = "&data_type=2&published=true"
      } else if (selResType === "document") {
        type = "basic-images-list"
        data_type = `&file_type=` + getFileTypeForDoc()
      } else if (selResType === "checklist") {
        type = "checklist"
        data_type = `&limit=${PageSize.current}&published=true`
      } else if (selResType === "casestudy") {
        type = "case-study"
        data_type = `&limit=${PageSize.current}&published=true`
      } else if (selResType === "mtf") {
        type = "match-the-followings"
        data_type = `&limit=${PageSize.current}&ordering=-created_at&published=true`
      }

      let search = `&search=${searchValue ? searchValue : value}`
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/?paginate_by=${PageSize.current}${search}&adminsite=true${data_type}`, {
          headers: login_credentials,
        })
        .then((res) => {
          let temp_data = {}
          temp_data[selResType] = res.data.results
          setAllResources(temp_data)

          changeAllResources(res.data.results, selResType)
          Common.SetValForID(setAllResourcesNext, selResType, res.data.next === null ? "" : res.data.next)
          setAllResourcesFetched(true)
        })
        .catch((err) => {
          setAllResourcesFetched(true)
        })
    } else {
      setAllResourcesFetched(true)
      setPublishedAllResourcesFetched(true)
    }
  }

  function getFileTypeForDocLocal(docTypeStr) {
    let fileType
    docTypeStr = docTypeStr.toUpperCase()
    switch (docTypeStr) {
      case "PDF":
        fileType = "1"
        break
      case "DOC":
        fileType = "2"
        break
      case "EXCEL":
      case "XLS":
        fileType = "3"
        break
      case "PPT":
        fileType = "4"
        break
      default:
        break
    }
    return fileType
  }

  function returnCorrectResults(resType, data) {
    let newData = data
    if (resType === "document") {
      let type = getFileTypeForDoc()
      let docTypeStr1 = "",
        lcDocType = docType == "" ? "" : docType.toLowerCase()
      if (lcDocType === "all documents") {
        docTypeStr1 = ""
      } else if (lcDocType === "excel") {
        docTypeStr1 = "xls"
      } else {
        docTypeStr1 = lcDocType
      }
      let fileType = 1
      if (docTypeStr1 !== "") {
        fileType = getFileTypeForDocLocal(docTypeStr1)
      }

      //Only Allow these values in My List of Documents
      newData = data.filter((elem) => {
        if (docTypeStr1 === "") {
          return elem.resource_data?.file_type == 0 ? false : true
        } else {
          return elem.resource_data?.file_type == fileType ? true : false
        }
        /*
        let n = elem.resource_data?.name
        if (docTypeStr1 !== '' && n.includes(docTypeStr1)) {
          return true
        } else if (docTypeStr1 === '' && (n.includes('doc') || n.includes('xls') || n.includes('ppt') || n.includes('pdf'))) {
          return true
        }
        return false*/
      })
    } else if (resType === "turientimage") {
      newData = data.filter((elem) => {
        return elem.resource_data?.file_type == 0 ? true : false
        /*
      let n = elem.resource_data?.name
      if (n.includes('doc') || n.includes('xls') || n.includes('ppt') || n.includes('pdf')) {
        return false
      }
      return true */
      })
    }
    return newData
  }
  async function fetchPublishedResources(refetch = false, value = "", section = null, type = null, res_sub_type = null) {
    if (
      (currentSelectedResourcesType ? currentSelectedResourcesType : section) &&
      (allPublishedResources[currentSelectedResourcesType ? currentSelectedResourcesType : section] === undefined || refetch === true)
    ) {
      if (!section) {
        setPublishedAllResourcesFetched(false)
      }
      let data_type = "",
        sub_type = ""
      let type = currentSelectedResourcesType ? currentSelectedResourcesType : section
      if ((currentSelectedResourcesType ? currentSelectedResourcesType : section) === "quiz") {
        data_type = "&data_type=0&published=true"
      } else if ((currentSelectedResourcesType ? currentSelectedResourcesType : section) === "poll") {
        type = "quiz"
        data_type = "&data_type=1&published=true"
      } else if ((currentSelectedResourcesType ? currentSelectedResourcesType : section) === "pop-quiz") {
        type = "quiz"
        data_type = "&data_type=2&published=true"
      } else if ((currentSelectedResourcesType ? currentSelectedResourcesType : section) === "document") {
        type = "turientimage"
        //data_type = '&data_type=2&published=true'
      } else if ((currentSelectedResourcesType ? currentSelectedResourcesType : section) === "checklist") {
        type = "checklist"
        data_type = "&published=true"
      } else if ((currentSelectedResourcesType ? currentSelectedResourcesType : section) === "casestudy") {
        type = "casestudy"
        data_type = "&published=true"
      }
      if ((resource_sub_type ? resource_sub_type : res_sub_type) !== "all") {
        if ((resource_sub_type ? resource_sub_type : res_sub_type) === "unpublished") {
          sub_type = `&is_triggered=false`
        } else if ((resource_sub_type ? resource_sub_type : res_sub_type) === "published") {
          sub_type = `&is_triggered=true`
        }
      } else {
        sub_type = ""
      }
      let search = `&search=${searchValue ? searchValue : value}`
      axios
        .get(
          process.env.GATSBY_REACT_APP_API_HOST +
            `/basic-resources-list/?paginate_by=${PageSize.current}${search}&defaultcoursetemplate=${props.template_id}&resource_type=${type}${data_type}&ordering=triggered_count${sub_type}`,
          { headers: login_credentials }
        )
        .then((res) => {
          let current_resources = { ...allPublishedResources }
          current_resources[currentSelectedResourcesType ? currentSelectedResourcesType : section] = returnCorrectResults(
            currentSelectedResourcesType ? currentSelectedResourcesType : section,
            res.data.results
          )
          setAllPublishedResources(current_resources)
          allPublishedResourcesNext[currentSelectedResourcesType ? currentSelectedResourcesType : section] = res.data.next === null ? "" : res.data.next
          setPublishedAllResourcesFetched(true)
          if (props?.setActiveActivityData) {
            props.setActiveActivityData({
              current_active_activity: res.data.current_active_activity,
              active_breakout_session: res.data.active_breakout_session,
            })
          }
        })
    }
  }

  async function fetchNextAllResources(next_url) {
    if (next_url) {
      axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
        if (res.data.results.length > 0) {
          let temp = { ...allResources }
          changeAllResources(temp[currentSelectedResourcesType].concat(res.data.results))
        }
        let temp_next = { ...allResourcesNext }
        temp_next[currentSelectedResourcesType] = res.data.next === null ? "" : res.data.next
        setAllResourcesNext(temp_next)
      })
    }
  }
  async function fetchPublishedNextAllResources(next_url) {
    if (next_url) {
      axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
        let temp_all_resources = { ...allPublishedResources }
        if (res.data.results.length > 0) {
          temp_all_resources[currentSelectedResourcesType] = temp_all_resources[currentSelectedResourcesType].concat(
            returnCorrectResults(currentSelectedResourcesType, res.data.results)
          )
          setAllPublishedResources(temp_all_resources)
        }
        let temp_next = { ...allPublishedResourcesNext }
        temp_next[currentSelectedResourcesType] = res.data.next === null ? "" : res.data.next
        setPublishedAllResourcesNext(temp_next)
        setResourceToShow("My List")
      })
    }
  }

  const showTriggeredResource = (currentItem) => {
    var index = allPublishedResources[currentSelectedResourcesType].findIndex((res) => res.id === currentItem.id)
    let resource = props.activeActivityData.current_active_activity.resource

    let triggered_datam = {}
    triggered_datam.version_id = resource.version_id
    triggered_datam.triggered_time = resource.triggered_time
    triggered_datam.triggered_count = resource.triggered_count
    triggered_datam.resource_data = resource.resource_data
    triggered_datam.resource_type = resource.resource_type
    triggered_datam.id = resource.id
    props.setPublishedResource(resource)

    setIsUpdated(true)
    if (currentSelectedResourcesType === "poll") {
      setShowPollResult(true)
      setCreateNewPoll(false)
      setCurrentPoll(resource.id)
      setCurrentResource(resource)
      setShowPollModal(true)
    }
    // setAllPublishedResources(current_data)
    setIsUpdated(true)
    if (currentSelectedResourcesType !== "poll") {
      props.setLeftNavOpen(false)
    }
  }

  const handleSave = async (currentItem, type, value, show_results) => {
    if (currentItem) {
      setLoadingResource({
        resource_id: currentItem.id,
        loading: true,
      })
      var index = allPublishedResources[currentSelectedResourcesType].findIndex((res) => res.id === currentItem.id)
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      formData.append("resource_id", currentItem.resource_id)
      formData.append("resource_type", currentItem.resource_type)
      formData.append("template", props.template_id)
      formData.append("is_triggered", value)
      formData.append("resource_triggered", true)
      if (!show_results || currentItem.resource_type === "casestudy" || currentItem.resource_type === "quiz") {
        formData.append("triggered_count", parseInt(currentItem.triggered_count) + 1)
      }
      formData.append("update_version", true)
      formData.append("show_results", show_results)
      if (value || show_results) {
        formData.append("created", new Date().toISOString())
        let current_data = { ...allPublishedResources }
        if (!show_results) {
          current_data[currentSelectedResourcesType][index].is_triggered = "loading"
        } else {
          current_data[currentSelectedResourcesType][index].show_results = "loading"
        }
        setAllPublishedResources(current_data)
        if (!intialFetch) {
          setIntialFetch(!intialFetch)
        }
      }

      let res = await putRequest(`/resources/${currentItem.id}/`, formData)
      if (res.success) {
        let current_data = { ...allPublishedResources }
        current_data[currentSelectedResourcesType][index].is_triggered = value
        if (!show_results || currentItem.resource_type === "casestudy" || currentItem.resource_type === "quiz") {
          current_data[currentSelectedResourcesType][index].end_activity = res.data.end_activity
          current_data[currentSelectedResourcesType][index]["triggered_count"] = res.data.triggered_count
          current_data[currentSelectedResourcesType][index]["triggered_time"] = res.data.triggered_time
          current_data[currentSelectedResourcesType][index]["version_id"] = res.data.version_id
        }
        current_data[currentSelectedResourcesType][index].show_results = show_results
        triggerResource(currentItem.id)
        setAllPublishedResources(current_data)
        setIsUpdated(true)
        setLoadingResource({})
        props.setLeftNavOpen(false)

        props.setActiveActivityData({ current_active_activity: res.data.current_active_activity, active_breakout_session: res.data.active_breakout_session })
      } else {
        setLoadingResource({})
        props.setActiveActivityData({ current_active_activity: res.data.current_active_activity, active_breakout_session: res.data.active_breakout_session })
        props.setShowActiveActivity(true)
      }
    } else {
      setLoadingResource({})
    }
  }

  async function triggerResource(resourceID) {
    setTimeout(function () {
      axios
        .get(`${process.env.GATSBY_REACT_APP_API_HOST}/basic-resources-list/?paginate_by=1&is_triggered=true&is_poll=false&template_id=${props.template_id}`, {
          headers: login_credentials,
        })
        .then((res) => {
          if (props.setPublishedResource) {
            let triggered_datam = {}
            triggered_datam.version_id = res.data.results[0].version_id
            triggered_datam.triggered_time = res.data.results[0].triggered_time
            triggered_datam.triggered_count = res.data.results[0].triggered_count
            triggered_datam.resource_data = res.data.results[0].resource_data
            triggered_datam.resource_type = res.data.results[0].resource_type
            triggered_datam.sequence = res.data.results[0].sequence
            triggered_datam.resource_id = res.data.results[0].resource_id
            triggered_datam.id = resourceID
            props.setPublishedResource(triggered_datam)
          }
          if (props?.setActiveActivityData) {
            props.setActiveActivityData({
              current_active_activity: res.data.current_active_activity,
              active_breakout_session: res.data.active_breakout_session,
            })
          }
          setIsUpdated(true)
          if (currentSelectedResourcesType === "poll") {
            setShowPollResult(true)
            setCreateNewPoll(false)
            setCurrentPoll(res.data.results[0].id)
            setCurrentResource(res.data.results[0])
            setShowPollModal(true)
          }
        })
        .catch((error) => {})
    }, 300)
  }

  function publishResource(resource) {
    var formData = new FormData()
    formData.append("sequence", 0.0)
    if (currentSelectedResourcesType === "poll" || currentSelectedResourcesType === "pop-quiz") {
      formData.append("resource_type", "quiz")
    } else {
      if (currentSelectedResourcesType === "document") {
        formData.append("resource_type", "turientimage")
      } else if (currentSelectedResourcesType === "mtf") {
        formData.append("resource_type", "matchthefollowing")
      } else {
        formData.append("resource_type", currentSelectedResourcesType)
      }
    }
    formData.append("resource_id", resource.id)
    formData.append("add_to_template", props.template_id)
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
      .then((res) => {
        fetchPublishedResources(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  let page_uri = isBrowser() ? "/" + window.location.pathname.substr(1) : "false"
  if (page_uri === "/logout" || page_uri === "logout" || page_uri === "logout/" || page_uri === "/logout/") {
    page_uri = "/"
  }

  function deleteResource(id) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        setDraggedData(!draggedData)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function scrollRight(id) {
    let ele = document.getElementById(id)
    if (ele !== null) {
      let flex_scroll_amount = ele.scrollWidth / ele.childElementCount
      let left_pos = ele.scrollLeft
      ele.scrollTo({
        top: 0,
        left: left_pos + 3 * flex_scroll_amount,
        behavior: "smooth",
      })
    }
  }

  function scrollLeft(id) {
    let ele = document.getElementById(id)
    if (ele !== null) {
      let flex_scroll_amount = ele.scrollWidth / ele.childElementCount
      let left_pos = ele.scrollLeft
      ele.scrollTo({
        top: 0,
        left: left_pos - 3 * flex_scroll_amount,
        behavior: "smooth",
      })
    }
  }

  function displayScrollButtons(id) {
    if (id == "resources") {
      setHoveredResourcesSection(true)
    }
    let ele = document.getElementById(id)
    if (ele) {
      if (ele.scrollLeft == 0) {
        setShowScrollLeft(false)
      } else {
        setShowScrollLeft(true)
      }
      if (ele.scrollLeft + ele.offsetWidth >= ele.scrollWidth) {
        setShowScrollRight(false)
      } else {
        setShowScrollRight(true)
      }
    }
  }

  function showResourceTopNav() {
    if (props.currentNavTab === "Resource") {
      return (
        <>
          <div
            className={`w-100`}
            style={{ position: "relative" }}
            onMouseOver={() => {
              displayScrollButtons("resources")
            }}
            onMouseOut={() => {
              setHoveredResourcesSection(false)
            }}
          >
            <div
              className={`px-0 pointer ${hoveredResourcesSection && showScrollRight ? `` : `d-none`}`}
              onClick={() => {
                scrollRight("resources")
              }}
              style={{
                paddingTop: 3,
                height: "auto",
                width: "fit-content",
                borderRadius: 4,
                position: "absolute",
                right: 0,
                top: 0,
                zIndex: 5,
                backgroundColor: "#303C54",
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} color="#fff" />
            </div>
            <div
              className={`px-0 pointer ${hoveredResourcesSection && showScrollLeft ? `` : `d-none`}`}
              onClick={() => {
                scrollLeft("resources")
              }}
              style={{
                paddingTop: 3,
                height: "auto",
                width: "fit-content",
                borderRadius: 4,
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 5,
                backgroundColor: "#303C54",
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} color="#fff" />
            </div>
            <div
              className={`d-inline-flex mx-3 hide_scrollbar`}
              id="resources"
              onScroll={() => {}}
              style={{ maxWidth: "88%", width: "88%", overflowX: "scroll" }}
            >
              {ResourceList.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className={`pointer ${
                      currentSelectedResourcesType === item.toLowerCase() ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item
                    }`}
                    onClick={() => {
                      if (currentSelectedResourcesType !== item.toLowerCase()) {
                        setCurrentSelectedResourcesType(item.toLowerCase())
                        setPublishedAllResourcesFetched(false)
                      }
                    }}
                  >
                    <p>{showResourceTabName(item)}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <React.Fragment>
          {/*<div className={`w-100`} style={{position: 'relative'}} onMouseOver={() => {displayScrollButtons('resources2')}} onMouseOut={() => {setHoveredResourcesSection(false)}}>
          <div className={`px-1 py-1 pointer ${hoveredResourcesSection && showScrollRight ? `` : ``}`} onClick={() => {scrollRight('resources2')}} style={{height:'auto',width:"fit-content",borderRadius:"4px",position:'absolute',right:'0px',top:'0%',zIndex:'5'}}><FontAwesomeIcon icon={faChevronRight} color='#fff' /></div>
          <div className={`px-1 py-1 pointer ${hoveredResourcesSection && showScrollLeft ? `` : ``}`} onClick={() => {scrollLeft('resources2')}} style={{height:'auto',width:"fit-content",borderRadius:"4px",position:'absolute',left:'0px',top:'0%',zIndex:'5'}}><FontAwesomeIcon icon={faChevronLeft} color='#fff' /></div>          
          <div className={`d-inline-flex px-3 hide_scrollbar w-100`} id='resources2' onScroll={() => {}} style={{maxWidth:"94%", overflowX:'scroll'}}>
        */}

          <div
            key={1}
            className={`pointer ${currentSelectedResourcesType === "poll" ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item}`}
            onClick={() => {
              setCurrentSelectedResourcesType("poll")
              if ("poll" !== currentSelectedResourcesType) {
                setAllResourcesFetched(false)
              }
            }}
          >
            <p>Polls</p>
          </div>
          <div
            key={2}
            className={`pointer ${currentSelectedResourcesType === "pop-quiz" ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item}`}
            onClick={() => {
              setCurrentSelectedResourcesType("pop-quiz")
              if ("pop-quiz" !== currentSelectedResourcesType) {
                setAllResourcesFetched(false)
              }
            }}
          >
            <p>Pop Quizzes</p>
          </div>
          <div
            key={3}
            className={`pointer ${currentSelectedResourcesType === "checklist" ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item}`}
            onClick={() => {
              setCurrentSelectedResourcesType("checklist")
              if ("checklist" !== currentSelectedResourcesType) {
                setAllResourcesFetched(false)
              }
            }}
          >
            <p>Checklists</p>
          </div>
          <div
            key={4}
            className={`pointer ${currentSelectedResourcesType === "casestudy" ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item}`}
            onClick={() => {
              setCurrentSelectedResourcesType("casestudy")
              if ("casestudy" !== currentSelectedResourcesType) {
                setAllResourcesFetched(false)
              }
            }}
          >
            <p>Case Studies</p>
          </div>
          {/*</div>
          </div>*/}
        </React.Fragment>
      )
    }
  }
  function showResourceTabName(item) {
    if (item === "Turientimage" || item === "turientimage") {
      return "Images"
    } else if (item === "Quiz" || item === "quiz") {
      return "Quizzes"
    } else if (item === "pop-quiz") {
      return "Pop Quizzes"
    } else {
      return item + "s"
    }
  }
  function showMylistResources(published_resources, next_published_resource_url) {
    if (allPublishedResourcesFetched) {
      return (
        <Droppable droppableId={`resource_` + currentSelectedResourcesType} type={`resourceDraggable`}>
          {(provided, snapshot) => <div ref={provided.innerRef}>{showResources(published_resources, next_published_resource_url, "My List")}</div>}
        </Droppable>
      )
    } else {
      return showSpinner()
    }
  }

  function showResourcesSection(type = "resource") {
    let published_resources = allPublishedResources[currentSelectedResourcesType] ? allPublishedResources[currentSelectedResourcesType] : []
    let all_resources = allResources[currentSelectedResourcesType] ? allResources[currentSelectedResourcesType] : []
    let next_resource_url = allResourcesNext[currentSelectedResourcesType]
    let next_published_resource_url = allPublishedResourcesNext[currentSelectedResourcesType]
    let button_text = "Add Content"
    if (currentSelectedResourcesType === "poll") {
      button_text = "Create Poll"
    } else if (currentSelectedResourcesType === "pop-quiz") {
      button_text = "Create Pop Quiz"
    } else if (currentSelectedResourcesType === "video") {
      button_text = "Upload Video"
    } else if (currentSelectedResourcesType === "link") {
      button_text = "Upload Link"
    } else if (currentSelectedResourcesType === "quiz") {
      button_text = "Create Quiz"
    } else if (currentSelectedResourcesType === "turientimage") {
      button_text = "Upload File"
    } else if (currentSelectedResourcesType === "blog") {
      button_text = "Create Blog"
    } else if (currentSelectedResourcesType === "checklist") {
      button_text = "Create Checklist"
    } else if (currentSelectedResourcesType === "casestudy") {
      button_text = "Create Case Study"
    } else {
      button_text = "Upload Content"
    }

    if (props.type === "classroom") {
      // if (searchValue) {
      if (allResourcesFetched && allPublishedResourcesFetched) {
        if (all_resources.length === 0 && published_resources.length === 0) {
          return (
            <>
              {currentSelectedResourcesType === "document" ? <>{dropDownListForDocuments()}</> : ""}
              {searchValue === "" ? (
                <Col lg="12" md="12" sm="12" xs="12" className="text-center py-">
                  <div
                    className="mx-1"
                    style={{
                      textAlign: "center",
                      display: "block",
                      height: "inherit",
                      marginTop: "30%",
                    }}
                  >
                    <p
                      style={{
                        color: "#E0E2E5",
                        fontSize: "21px",
                        fontWeight: "500",
                      }}
                    >
                      No resources found!
                    </p>
                    <p style={{ color: "#E0E2E5", fontSize: "16px" }}>
                      Everything you upload can be accessed from here. Drag and drop to add it your courses, quizzes, polls etc.
                    </p>
                    {/* <p style={{color:'#E0E2E5',fontSize:"16px"}}>You can use your uploaded resources In creating a course, to demonstrate during a live class Or use wherever applicable</p> */}
                    <button
                      onClick={() => {
                        handleAddContent()
                      }}
                      className="border_on_hover"
                      style={{
                        backgroundColor: "#586886",
                        border: "1px solid #586886",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "9px",
                        color: "#E0E2E5",
                        fontSize: "18px",
                        padding: "5px 24px 5px 24px",
                      }}
                    >
                      <FontAwesomeIcon className={`mr-2`} icon={faUpload} size="sm" /> {button_text}
                    </button>
                    {currentSelectedResourcesType === "pop-quiz" ||
                    currentSelectedResourcesType === "poll" ||
                    currentSelectedResourcesType === "quiz" ||
                    currentSelectedResourcesType === "checklist" ||
                    currentSelectedResourcesType === "mtf" ||
                    currentSelectedResourcesType === "casestudy" ? null : (
                      <p className="pt-2" style={{ fontSize: "14px", color: "#82868D" }}>
                        Upload videos. Images, Documents, blogs, etc
                      </p>
                    )}
                  </div>
                </Col>
              ) : (
                ///***Raghu */
                <p className={`mb-0 ${Styles.font_12} mt-2 text-center`}>
                  No resource available {searchValue ? "for searched keyword" : "in list"}!
                </p> /*
                <Col lg="12" md="12" sm="12" xs="12" className="text-center py-">
                  <div
                    className="mx-1"
                    style={{
                      textAlign: "center",
                      display: "block",
                      height: "inherit",
                      marginTop: "30%",
                    }}
                  >
                    <p
                      style={{
                        color: "#E0E2E5",
                        fontSize: "21px",
                        fontWeight: "500",
                      }}
                    >
                      No content has been uploaded yet!
                    </p>
                    <p style={{ color: "#E0E2E5", fontSize: "16px" }}>
                      Everything you upload can be accessed from here. Drag and drop to add it your courses, quizzes, polls etc.
                    </p>
                    <button
                      onClick={() => {
                        handleAddContent()
                      }}
                      className="border_on_hover"
                      style={{
                        backgroundColor: "#586886",
                        border: "1px solid #586886",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "9px",
                        color: "#E0E2E5",
                        fontSize: "18px",
                        padding: "5px 24px 5px 24px",
                      }}
                    >
                      <FontAwesomeIcon className={`mr-2`} icon={faUpload} size="sm" /> {button_text}
                    </button>
                    {currentSelectedResourcesType === "pop-quiz" ||
                    currentSelectedResourcesType === "poll" ||
                    currentSelectedResourcesType === "quiz" ||
                    currentSelectedResourcesType === "checklist" ||
                    currentSelectedResourcesType === "casestudy" ? null : (
                      <p className="pt-2" style={{ fontSize: "14px", color: "#82868D" }}>
                        Upload videos. Images, Documents, blogs, etc
                      </p>
                    )}
                  </div>
                </Col>*/
              )}
            </>
          )
        } else {
          return (
            <React.Fragment>
              <div className={`d-flex`}>
                <div>
                  {props.activeSection !== "BreakoutSession" && props.activeSection !== "Resource" && !props.showAllResources ? (
                    <Dropdown className={`breakout-groups px-3 pb-2`}>
                      <Dropdown.Toggle
                        className="border-none d-inline-flex align-items-center p-0 shadow-none"
                        style={{ backgroundColor: "transparent" }}
                        id="dropdown-basic"
                      >
                        <div className={`mr-1`} style={{ fontSize: "14px" }}>
                          My List
                        </div>
                        <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                      </Dropdown.Toggle>
                      {/*<Dropdown.Menu className={`py-0 ${Styles.font_12} ${StylesB.toggle_dropdown_modal}`}>*/}
                      <Dropdown.Menu className={`br-9px breakout-groups`} style={{ fontSize: "12px", color: "#E0E2E5" }}>
                        <Dropdown.Item
                          style={{ color: "#E0E2E5" }}
                          onClick={(e) => {
                            setResourcesType(e, "all")
                          }}
                        >
                          All
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ color: "#E0E2E5" }}
                          onClick={(e) => {
                            setResourcesType(e, "published")
                          }}
                        >
                          Published
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ color: "#E0E2E5" }}
                          onClick={(e) => {
                            setResourcesType(e, "unpublished")
                          }}
                        >
                          Unpublished
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    ""
                  )}
                </div>
                <div className={`ml-auto`}>{currentSelectedResourcesType === "document" ? <>{dropDownListForDocuments("14px")}</> : ""}</div>
              </div>

              <Droppable droppableId={type + `_` + currentSelectedResourcesType} type={`resourceDraggable`}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef}>
                    {props.activeSection !== "BreakoutSession" && props.activeSection !== "Resource" && !props.showAllResources
                      ? showResources(published_resources, next_published_resource_url, "My List")
                      : ""}
                    {searchValue || props.activeSection === "BreakoutSession" || props.activeSection === "Resource" || props.showAllResources ? (
                      <React.Fragment>
                        <p className="m-3 breakout-groups pr-3 pb-2" style={{ fontSize: 14 }}>
                          {"All " + showResourceTabName(currentSelectedResourcesType)}
                        </p>
                        {showResources(all_resources, next_resource_url, "All")}
                      </React.Fragment>
                    ) : null}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </React.Fragment>
          )
        }
      } else {
        return showSpinner()
      }
      // } else {
      //   return(
      //     <React.Fragment>
      //       <Dropdown>
      //         <Dropdown.Toggle size="lg" className={`bg-transparent border-0 p-0 ${StylesB.btn_focus}`} id="dropdown_list" style={{fontSize: '16px'}}>My List</Dropdown.Toggle>
      //         <Dropdown.Menu className={`py-0 ${Styles.font_12} ${StylesB.toggle_dropdown_modal}`}>
      //           <Dropdown.Item className="px-2 py-1" onClick={(e) => {setResourcesType(e, 'all')}} style={{background: resource_sub_type === 'all' ? 'rgb(72, 83, 104)' : 'rgb(48, 60, 84)', borderTopRightRadius: '9px', borderTopLeftRadius: '9px', color: 'rgb(224, 226, 229)'}}>All</Dropdown.Item>
      //           <Dropdown.Item className="px-2 py-1" onClick={(e) => {setResourcesType(e, 'published')}} style={{background: resource_sub_type === 'published' ? 'rgb(72, 83, 104)' : 'rgb(48, 60, 84)', borderTop: '1px solid rgb(224, 226, 229)', borderBottom: '1px solid rgb(224, 226, 229)', color: 'rgb(224, 226, 229)'}}>Published</Dropdown.Item>
      //           <Dropdown.Item className="px-2 py-1" onClick={(e) => {setResourcesType(e, 'unpublished')}} style={{background: resource_sub_type === 'unpublished' ? 'rgb(72, 83, 104)' : 'rgb(48, 60, 84)', borderBottomRightRadius: '9px', borderBottomLeftRadius: '9px', color: 'rgb(224, 226, 229)'}}>Unpublished</Dropdown.Item>
      //         </Dropdown.Menu>
      //       </Dropdown>
      //       {showMylistResources(published_resources, next_published_resource_url)}
      //     </React.Fragment>
      //   )
      // }
    } else {
      if (allResourcesFetched && allPublishedResourcesFetched) {
        if (all_resources.length === 0 && published_resources.length === 0) {
          return (
            <>
              {currentSelectedResourcesType === "document" ? <>{dropDownListForDocuments()}</> : ""}

              {searchValue === "" ? (
                <Col lg="12" md="12" sm="12" xs="12" className="text-center py-">
                  <div
                    className="mx-1"
                    style={{
                      textAlign: "center",
                      display: "block",
                      height: "inherit",
                      marginTop: "30%",
                    }}
                  >
                    <p
                      style={{
                        color: "#E0E2E5",
                        fontSize: "21px",
                        fontWeight: "500",
                      }}
                    >
                      No content has been uploaded yet!
                    </p>
                    <p style={{ color: "#E0E2E5", fontSize: "16px" }}>
                      Everything you upload can be accessed from here. Drag and drop to add it your courses, quizzes, polls etc.
                    </p>
                    {/* <p style={{color:'#E0E2E5',fontSize:"16px"}}>You can use your uploaded resources In creating a course, to demonstrate during a live class Or use wherever applicable</p> */}
                    <button
                      onClick={() => {
                        handleAddContent()
                      }}
                      className="border_on_hover"
                      style={{
                        backgroundColor: "#586886",
                        border: "1px solid #586886",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "9px",
                        color: "#E0E2E5",
                        fontSize: "18px",
                        padding: "5px 24px 5px 24px",
                      }}
                    >
                      <FontAwesomeIcon className={`mr-2`} icon={faUpload} size="sm" /> {button_text}
                    </button>
                    {currentSelectedResourcesType === "pop-quiz" ||
                    currentSelectedResourcesType === "poll" ||
                    currentSelectedResourcesType === "quiz" ||
                    currentSelectedResourcesType === "checklist" ||
                    currentSelectedResourcesType === "mtf" ||
                    currentSelectedResourcesType === "casestudy" ? null : (
                      <p className="pt-2" style={{ fontSize: "14px", color: "#82868D" }}>
                        Upload videos. Images, Documents, blogs, etc
                      </p>
                    )}
                  </div>
                </Col>
              ) : (
                <p className={`mb-0 ${Styles.font_12} mt-2 text-center`}>No resource available {searchValue ? "for searched keyword" : "in list"}!</p>
                /*
                <Col lg="12" md="12" sm="12" xs="12" className="text-center py-">
                  <div
                    className="mx-1"
                    style={{
                      textAlign: "center",
                      display: "block",
                      height: "inherit",
                      marginTop: "30%",
                    }}
                  >
                    <p
                      style={{
                        color: "#E0E2E5",
                        fontSize: "21px",
                        fontWeight: "500",
                      }}
                    >
                      No resources found!
                    </p>
                    <p style={{ color: "#E0E2E5", fontSize: "16px" }}>
                      Everything you upload can be accessed from here. Drag and drop to add it your courses, quizzes, polls etc.
                    </p>
                    <button
                      onClick={() => {
                        handleAddContent()
                      }}
                      className="border_on_hover"
                      style={{
                        backgroundColor: "#586886",
                        border: "1px solid #586886",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "9px",
                        color: "#E0E2E5",
                        fontSize: "18px",
                        padding: "5px 24px 5px 24px",
                      }}
                    >
                      <FontAwesomeIcon className={`mr-2`} icon={faUpload} size="sm" /> {button_text}
                    </button>
                    {currentSelectedResourcesType === "pop-quiz" ||
                    currentSelectedResourcesType === "poll" ||
                    currentSelectedResourcesType === "quiz" ||
                    currentSelectedResourcesType === "checklist" ||
                    currentSelectedResourcesType === "casestudy" ? null : (
                      <p className="pt-2" style={{ fontSize: "14px", color: "#82868D" }}>
                        Upload videos. Images, Documents, blogs, etc
                      </p>
                    )}
                  </div>
                </Col>*/
              )}
            </>
          )
        } else {
          return (
            <React.Fragment>
              <Droppable droppableId={type + `_` + currentSelectedResourcesType} type={`resourceDraggable`}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef}>
                    {props.activeSection === "BreakoutSession" || props.activeSection === "Resource" || props.activeSection === "LessonPlan"
                      ? ``
                      : showResources(published_resources, next_published_resource_url, "My List")}
                    {showResources(all_resources, next_resource_url, "All")}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </React.Fragment>
          )
        }
      } else {
        return showSpinner()
      }
    }
  }
  function showSpinner() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
        <Spinner animation="border" variant="secondary" size="sm" />
      </Col>
    )
  }
  const setResourcesType = (e, type) => {
    e.preventDefault()
    if (type !== resource_sub_type) {
      setResourceSubType(type)
    }
  }

  async function setDocTypeFilter(p, d) {
    let addlnStr = "&file_type=all"
    switch (p) {
      case 1:
      case 2:
      case 3:
      case 4:
        addlnStr = "&file_type=" + p
        break
      default:
        break
    }
    setDocTypeStr(d)
    fetchAllResources(true, addlnStr)
    fetchPublishedResources(true, d)
  }

  const dropDownListForDocuments = (size = "12px") => {
    return (
      <Dropdown className={`breakout-groups pr-3 pb-2`}>
        <Dropdown.Toggle
          className="border-none d-inline-flex align-items-center p-0 shadow-none"
          style={{ backgroundColor: "transparent" }}
          id="dropdown-basic"
        >
          <div className={`mr-1`} style={{ fontSize: size }}>
            {docType ? docType : "All Documents"}
          </div>
          <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`br-9px breakout-groups`} style={{ fontSize: "12px", color: "#E0E2E5" }}>
          <Dropdown.Item
            style={{ color: "#E0E2E5" }}
            onClick={() => {
              setDocType("All Documents")
            }}
          >{`All Documents`}</Dropdown.Item>
          <Dropdown.Item
            style={{ color: "#E0E2E5" }}
            onClick={() => {
              setDocType("PDF")
            }}
          >
            {"PDF"}
          </Dropdown.Item>
          <Dropdown.Item
            style={{ color: "#E0E2E5" }}
            onClick={() => {
              setDocType("DOC")
            }}
          >
            DOC
          </Dropdown.Item>
          <Dropdown.Item
            style={{ color: "#E0E2E5" }}
            onClick={() => {
              setDocType("EXCEL")
            }}
          >{`EXCEL`}</Dropdown.Item>
          <Dropdown.Item
            style={{ color: "#E0E2E5" }}
            onClick={() => {
              setDocType("PPT")
            }}
          >{`PPT`}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  function showResources(resources, next_url, list_type, type = "resource") {
    if (resources.length > 0) {
      let logo = ResourceIcons[currentSelectedResourcesType]
      const parentFetchResourceActivity = (value, type) => {
        setTimeout(() => {
          props.fetchRecentActivity(value, type)
        }, 300)
      }
      return (
        <React.Fragment>
          {currentSelectedResourcesType === "document" && props.type !== "classroom" && list_type === "My List" ? ( // || published_resources?.length > 0)
            <>{dropDownListForDocuments()}</>
          ) : (
            ""
          )}
          <>
            {props.type === "classroom" ? null : (
              <p className="my-2 breakout-groups pr-3 pb-2">{list_type === "All" ? "All " + showResourceTabName(currentSelectedResourcesType) : list_type}</p>
            )}
          </>

          {resources.map((item, index) => (
            <Draggable
              key={item.id + `_${type}_name_` + index}
              draggableId={item.id.toString() + `-${type}` + "_" + index.toString()}
              isDragDisabled={
                props.activeSection === "BreakoutSession" ||
                props.activeSection === "Resource" ||
                (props.activeSection === "LessonBoard" && props.disableScroll)
                  ? false
                  : true
              }
              index={index}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  className={`${item?.id === props.activeActivityData?.current_active_activity?.resource?.id ? StylesB.poll_live_green_border : ""}
                ${props.type === "classroom" && item.triggered_count > 0 ? StylesB.triggered_item_green_border : StylesB.border_on_hover} ${
                    props.type && index === 0 ? "mt-2" : ""
                  } mb-2 d-flex align-items-center`}
                >
                  <ResourceItem
                    setCreateNewPopquiz={setCreateNewPopquiz}
                    setCurrentPopquiz={setCurrentPopquiz}
                    setCurrentResource={setCurrentResource}
                    setCreateNewPoll={setCreateNewPoll}
                    setCurrentPoll={setCurrentPoll}
                    setShowPollResult={setShowPollResult}
                    setShowPollModal={setShowPollModal}
                    setShowPopquizModal={setShowPopquizModal}
                    list_type={list_type}
                    myListActivityResource={myListActivityResource}
                    publishResource={publishResource}
                    type={props.type}
                    index={index}
                    item={item}
                    logo={logo}
                    currentSelectedResourcesType={currentSelectedResourcesType}
                    fetchResourceActivity={parentFetchResourceActivity}
                    activeSection={props.activeSection}
                    disableScroll={props.disableScroll}
                    setLeftNavOpen={props.setLeftNavOpen}
                    setCurrentNavTab={props.setCurrentNavTab}
                    currentNavTab={props.currentNavTab}
                    training_id={props.training_id}
                    activeActivityData={props.activeActivityData}
                    setActiveActivityData={props.setActiveActivityData}
                    loadingResource={loadingResource}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {next_url ? displaySeeMoreBtn(next_url, list_type) : null}
        </React.Fragment>
      )
    } else {
      if (props.type === "classroom" && list_type === "My List") {
        return <p className={`mb-0 ${Styles.font_12} mt-2 text-center`}>No resource available {searchValue ? "for searched keyword" : "in list"}!</p>
      } else {
        return (
          <>
            {currentSelectedResourcesType === "document" && props.type !== "classroom" && list_type === "My List" ? ( // || published_resources?.length > 0)
              <>{dropDownListForDocuments()}</>
            ) : (
              ""
            )}
          </>
        )
      }
    }
  }
  const displaySeeMoreBtn = (next_url, list_type) => {
    if (props.type === "classroom") {
      if (list_type === "All") {
        return (
          <div>
            <Button
              variant="none"
              onClick={(e) => {
                fetchNextAllResources(next_url)
              }}
              className={`${Styles.font_12} ${StylesB.resources_see_more} text-center px-0 pt-0 pb-2`}
              style={{ color: `#E0E2E5`, textDecoration: "underline" }}
            >
              See More
            </Button>
          </div>
        )
      } else {
        return (
          <div>
            <Button
              variant="none"
              onClick={(e) => {
                fetchPublishedNextAllResources(next_url)
              }}
              className={`${Styles.font_12} ${StylesB.resources_see_more} text-center px-0 pt-0 pb-2`}
              style={{ color: `#E0E2E5`, textDecoration: "underline" }}
            >
              See More
            </Button>
          </div>
        )
      }
    } else {
      return (
        <div>
          <Button
            variant="none"
            onClick={(e) => {
              list_type === "All" ? fetchNextAllResources(next_url) : fetchPublishedNextAllResources(next_url)
            }}
            className={`${Styles.font_12} ${StylesB.resources_see_more} text-center px-0 pt-0 pb-2`}
            style={{ color: `#E0E2E5`, textDecoration: "underline" }}
          >
            See More
          </Button>
        </div>
      )
    }
  }
  const myListActivityResource = (e, type, index, resource, deleteRes = false, showResource = false) => {
    e.preventDefault()
    if (props.type === "classroom") {
      if (deleteRes === true) {
        deletePublishedResource(e, type, index, resource.id)
      } else {
        if (showResource) {
          showTriggeredResource(resource)
        } else {
          handleSave(resource, "update", true, false)
        }

        const evt = new CustomEvent("my-rnd-window-changed", {
          bubbles: true,
          detail: 1,
        })
        document.dispatchEvent(evt)
      }
    } else {
      deletePublishedResource(e, type, index, resource.id)
    }
  }
  const deletePublishedResource = (e, type, index, id) => {
    e.preventDefault()
    e.stopPropagation()
    let all_published_resources = { ...allPublishedResources }
    let published_resource = all_published_resources[type]
    published_resource.splice(index, 1)
    all_published_resources[type] = published_resource
    setAllPublishedResources(all_published_resources)
    deleteResource(id)
  }

  function handleViewAllResources() {
    if (currentSelectedResourcesType === "video") {
      // navigate(`/app/videos/`)
      navigate(`/app/resource-dashboard/`)
    } else if (currentSelectedResourcesType === "blog") {
      // navigate(`/app/blogs/`)
      navigate(`/app/resource-dashboard/`)
    } else if (currentSelectedResourcesType === "link") {
      // navigate(`/app/links/`)
      navigate(`/app/resource-dashboard/`)
    } else if (currentSelectedResourcesType === "quiz") {
      navigate(`/app/quizzes/`)
    } else if (currentSelectedResourcesType === "turientimage") {
      // navigate(`/app/files/`)
      navigate(`/app/resource-dashboard/`)
    } else if (currentSelectedResourcesType === "document") {
      // navigate(`/app/files/`)
      navigate(`/app/resource-dashboard/`)
    } else if (currentSelectedResourcesType === "poll") {
      navigate(`/app/polls/`)
    } else if (currentSelectedResourcesType === "pop-quiz") {
      navigate(`/app/pop-quizzes/`)
    } else if (currentSelectedResourcesType === "checklist") {
      navigate(`/app/checklists/`)
    } else if (currentSelectedResourcesType === "casestudy") {
      navigate(`/app/case-study/`)
    } else if (currentSelectedResourcesType === "mtf") {
      navigate(`/app/match-the-following/`)
    }
  }

  function showAddContentButton() {
    let button_text = "Add Content"
    if (currentSelectedResourcesType === "poll") {
      button_text = "Create Poll"
    } else if (currentSelectedResourcesType === "pop-quiz") {
      button_text = "Create Pop Quiz"
    } else if (currentSelectedResourcesType === "video") {
      button_text = "Upload Video"
    } else if (currentSelectedResourcesType === "link") {
      button_text = "Upload Link"
    } else if (currentSelectedResourcesType === "quiz") {
      button_text = "Create Quiz"
    } else if (currentSelectedResourcesType === "turientimage") {
      button_text = "Upload File"
    } else if (currentSelectedResourcesType === "blog") {
      button_text = "Create Blog"
    } else if (currentSelectedResourcesType === "document") {
      button_text = "Upload Document"
    } else if (currentSelectedResourcesType === "checklist") {
      button_text = "Create Checklist"
    } else if (currentSelectedResourcesType === "casestudy") {
      button_text = "Create Case Study"
    } else if (currentSelectedResourcesType === "mtf") {
      button_text = "Create MTF"
    } else {
      button_text = "Upload Content"
    }
    return (
      // <Button variant="none" onClick={(e)=> handleAddContent()} className={`${StylesB.add_content_button} align-items-center d-flex text-white`} >
      //   <img src={UploadIcon} alt={`upload button`} className={`my-auto`} style={{ objectFit: `contain`, width: `22px`, height: `22px` }}/>
      //   <p>{button_text}</p>
      // </Button>
      <div className="d-flex" style={{ gap: "7px" }}>
        <Button
          variant="none"
          onClick={(e) => handleAddContent()}
          className={`align-items-center justify-content-center d-flex text-white border_on_hover`}
          style={{
            backgroundColor: "#475269",
            color: "#E0E2E5",
            minWidth: "137px",
            height: "50px",
            borderRadius: "0px",
            flex: "1",
          }}
        >
          <img src={UploadIcon} alt={`upload button`} className={`my-auto`} style={{ objectFit: `contain`, width: `24px`, height: `24px` }} />
          <p className="mb-0" style={{ fontSize: "14px", color: "#E0E2E5" }}>
            {button_text}
          </p>
        </Button>
        <Button
          variant="none"
          onClick={(e) => handleViewAllResources()}
          className={`align-items-center justify-content-center d-flex text-white border_on_hover`}
          style={{
            backgroundColor: "#475269",
            color: "#E0E2E5",
            height: "50px",
            borderRadius: "0px",
            flex: "1",
            fontSize: "14px",
          }}
        >
          View All resources
          <FontAwesomeIcon
            className={`ml-3`}
            icon={faChevronRight}
            style={{
              height: "14px",
              width: "14px",
              borderRadius: "50%",
              backgroundColor: "#E0E2E5",
              color: "#212c42",
              padding: "2px 2px 2px 3px",
              marginLeft: "3px",
            }}
          />
        </Button>
        {/* <Button onClick={(e)=> handleAddContent()} className={`align-items-center d-flex text-white border_on_hover`} style={{height:'50px',borderRadius:'0px',justifyContent:"center"}}>addContett</Button> */}
      </div>
    )
  }
  function handleAddContent() {
    if (currentSelectedResourcesType === "poll") {
      setShowPollResult(false)
      setCreateNewPoll(true)
      setShowPollModal(true)
      setCurrentResource("")
      setCurrentPoll("")
    } else if (currentSelectedResourcesType === "pop-quiz") {
      setShowPopquizResult(false)
      setShowPopquizModal(true)
      setCreateNewPopquiz(true)
      setCurrentResource("")
    } else if (currentSelectedResourcesType === "blog") {
      navigate(`/app/blogs/create`)
    } else if (currentSelectedResourcesType === "quiz") {
      navigate(`/app/quizzes/create`)
    } else if (currentSelectedResourcesType === "checklist") {
      navigate(`/app/checklist/create`)
    } else if (currentSelectedResourcesType === "casestudy") {
      navigate(`/app/case-study/create`)
    } else if (currentSelectedResourcesType === "mtf") {
      navigate(`/app/match-the-following/create`)
    } else {
      setShowResourceModal(true)
    }
  }

  return (
    <React.Fragment>
      <div className="px-0 flex-grow-1" style={{ overflowY: "auto" }}>
        <div className="p-3">
          <div className={`col-12 d-inline-flex ${StylesB.search_section}`}>
            <img src={SearchIcon} alt={`search button`} className={`my-auto`} style={{ objectFit: `contain`, width: `16px`, height: `16px` }} />
            <FormControl
              autoFocus
              className="border-0 py-0 px-2 form-control shadow-none font-14"
              style={{
                backgroundColor: `#303C54`,
                color: `#E0E2E5`,
                fontSize: "16px",
                height: "32px",
              }}
              placeholder={`Search resources`}
              onChange={(e) => {
                e.preventDefault()
                setSearchValue(e.target.value)
                setAllResourcesFetched(false)
                setPublishedAllResourcesFetched(false)
                delayedQuery(e.target.value, "search", currentSelectedResourcesType, resource_sub_type)
              }}
              value={searchValue}
            />
          </div>
        </div>
        <div className={`${StylesB.resource_nav_section}`}>{showResourceTopNav()}</div>
        <div className={`${StylesB.resource_item_section}`}>{showResourcesSection()}</div>
      </div>
      {showAddContentButton()}
      {showPollModal ? (
        <ClassRoomPoll
          resource={currentResource}
          fetchPublishedResources={fetchPublishedResources}
          create_new_poll={createNewPoll}
          reset_create_poll={() => setCreateNewPoll(false)}
          live_classroom={false}
          template_id={props.template_id}
          poll={currentPoll}
          showPollResult={showPollResult}
          training_id={props.training_id}
          show={showPollModal}
          closeModal={() => {
            setShowPollModal(false)
            setCurrentPoll("")
          }}
          trainingWsConnection={props.trainingWsConnection}
          setActiveActivityData={props.setActiveActivityData}
        />
      ) : null}

      <ClassroomPopquiz
        resource={currentResource}
        fetchPublishedResources={fetchPublishedResources}
        create_new_popquiz={createNewPopquiz}
        reset_create_popquiz={() => setCreateNewPopquiz(false)}
        live_classroom={false}
        template_id={props.template_id}
        popquiz={currentPopquiz}
        showPopquizResult={false}
        show={showPopquizModal}
        closeModal={() => {
          setShowPopquizModal(false)
          setCurrentPopquiz("")
        }}
      />
      <UploadResource
        show={showResourceModal}
        defineFiles={defineFiles}
        updateDefineType={(value) => setDefineFiles(value)}
        closeModal={() => setShowResourceModal(false)}
        showSection={currentSelectedResourcesType === "link" ? "upload_links" : ""}
        refetch={() => refetch()}
      />
    </React.Fragment>
  )
}

export default ClassResourceSection
