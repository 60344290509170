export const validateSocialMediaLink = (socialMediaName, socialMediaLink) => {
  if (socialMediaLink.length) {
    if (socialMediaName === "facebook_url") {
      const regex = /^(http(s)?:\/\/)?((w){3}.)?facebook(.com)?\/.*/
      return regex.test(socialMediaLink)
    } else if (socialMediaName === "twitter_url") {
      const regex = /^(http(s)?:\/\/)?((w){3}.)?twitter(.com)?\/.*/
      return regex.test(socialMediaLink)
    } else {
      const regex = /^(http(s)?:\/\/)?((w){3}.)?linkedin(.com)?\/.*/
      return regex.test(socialMediaLink)
    }
  } else {
    return true
  }
}
