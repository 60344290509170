export const checkPasswordValidation = (enteredPassword, strongPasswordValidation) => {
  let dummy = { ...strongPasswordValidation }
  const containsUppercaseLowercase = /^(?=.*[A-Z])(?=.*[a-z])/
  const containsSymbolAndNumber = /^(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/
  const validLength = /^.{8,60}$/
  if (containsUppercaseLowercase.test(enteredPassword)) {
    dummy.has_azAZ = true
  } else {
    dummy.has_azAZ = false
  }
  if (containsSymbolAndNumber.test(enteredPassword)) {
    dummy.has_09spcl = true
  } else {
    dummy.has_09spcl = false
  }
  if (validLength.test(enteredPassword)) {
    dummy.has_8char = true
  } else {
    dummy.has_8char = false
  }
  return dummy
}
