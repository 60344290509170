import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Card from "react-bootstrap/Card"
import FormControl from "react-bootstrap/FormControl"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import ProgressBar from "react-bootstrap/ProgressBar"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Popover from "react-bootstrap/Popover"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faChevronLeft,
  faTrashAlt,
  faVideo,
  faLink,
  faFile,
  faSpinner,
  faFileAlt,
  faSearch,
  faTrash,
  faTimesCircle,
  faCheck,
  faChevronUp,
  faGripVertical,
} from "@fortawesome/free-solid-svg-icons"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"
import Styles from "../../../../modularscss/styles.module.scss"
import authClient from "../../../../services/auth"
import Image from "../../../../components/image"
import { isBrowser } from "../../../../services/developer_mode"

import Rewards from "../../../../images/svgs/points.svg"
import ArrowWhite from "../../../../images/svgs/arrow-white.svg"
import video from "../../../../images/svgs/video.svg"
import link from "../../../../images/svgs/link.svg"
import turientimage from "../../../../images/svgs/turientimage.svg"
import blog from "../../../../images/svgs/blog.svg"

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: "20px",
  textAlign: "center",
  color: "#fff",

  // change background colour if dragging
  background: isDragging ? "#99a6dc" : "#4b5a9b",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#94b3ea" : "#2e3446",
  padding: grid,
})

const ViewModule = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [stageData, setStageData] = useState({})

  // for modules basic name list
  const [modules, setModules] = useState({})
  const [moduleFetched, setModuleFetched] = useState(false)
  const [modulesNext, setModulesNext] = useState(null)
  const [moduleCreated, setModuleCreated] = useState(false)
  const [loader, setLoader] = useState(false)
  const [topicCreated, setTopicCreated] = useState(false)
  // for slected module
  const [currentModuleFetched, setCurrentModuleFetched] = useState(false)
  // for selected module topics basic name list
  const [currentModuleTopicsFetched, setCurrentModuleTopicsFetched] = useState(false)
  // to open delete modal of topic and module
  const [dmodal, setDmodal] = useState(false)
  const [selectedDeletingModule, setSelectedDeletingModule] = useState([])
  const [selectedDeletingTopic, setSelectedDeletingTopic] = useState([])
  // selceted module id
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedModuleToggle, setSelectedModuleToggle] = useState({})
  const [selectedModuleToggleChange, setSelectedModuleToggleChange] = useState(false)
  // selceted topic id
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedTopicToggle, setSelectedTopicToggle] = useState({})
  const [selectedTopicToggleChange, setSelectedTopicToggleChange] = useState(false)

  // each module full data in modules array
  const [modulesData, setModulesData] = useState({})

  // each topic full data in topics array
  const [topicFetched, setTopicFetched] = useState(false)
  const [topicsData, setTopicsData] = useState({})

  // for topics and module resources and respective loader
  const [modulesResources, setModulesResources] = useState({})
  const [modulesResourcesNext, setModulesResourcesNext] = useState({})
  const [modulesResourcesFetched, setModulesResourcesFetched] = useState(false)
  const [modulesResourcesFetchedx, setModulesResourcesFetchedx] = useState(true)
  const [topicsResources, setTopicsResources] = useState({})
  const [topicsResourcesNext, setTopicsResourcesNext] = useState({})
  const [topicsResourcesFetched, setTopicsResourcesFetched] = useState(false)
  const [topicsResourcesFetchedex, setTopicsResourcesFetchedex] = useState({})
  const [topicsResourcesFetchedxx, setTopicsResourcesFetchedxx] = useState(true)
  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("video")
  // each resource type full data in allResources array
  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(false)

  const [searchValue, setSearchValue] = useState("")

  const [resourceDeleted, setResourceDeleted] = useState(true)

  /*start-drag and drop all functions*/
  useEffect(() => {
    fetchModuleResources()
  }, [selectedModule])

  useEffect(() => {
    if (modulesResourcesFetched === false) {
      setModulesResourcesFetched(true)
    }
  }, [modulesResources[`module_` + selectedModule]])

  useEffect(() => {
    if (modulesResourcesFetchedx === false) {
      setModulesResourcesFetchedx(true)
    }
  }, [modulesResources[`module_` + selectedModule]])

  async function fetchModuleResources() {
    if (selectedModule !== null && modulesResources[`module_` + selectedModule] === undefined) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/resources-names-list/?module=${selectedModule}&paginate_by=10`, { headers: login_credentials })
        .then((res) => {
          modulesResources[`module_` + selectedModule] = res.data.results
          modulesResourcesNext[`module_` + selectedModule] = res.data.next === null ? {} : { next: res.data.next }
        })
    }
  }

  async function fetchNextModuleResources(next_url) {
    setModulesResourcesFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_module_resources = modulesResources[`module_` + selectedModule]
      if (res.data.results.length > 0) {
        for (var mrs = 0; mrs <= res.data.results.length - 1; mrs++) {
          next_module_resources.push(res.data.results[mrs])
        }
        modulesResources[`module_` + selectedModule] = next_module_resources
      }
      modulesResourcesNext[`module_` + selectedModule] = res.data.next === null ? {} : { next: res.data.next }
      setModulesResourcesFetched(true)
    })
  }

  useEffect(() => {
    if (selectedTopic != null) {
      fetchTopicResources()
    }
  }, [selectedTopic])

  useEffect(() => {
    if (topicsResourcesFetched === false) {
      setTopicsResourcesFetched(true)
    }
  }, [topicsResources[`topic_` + selectedTopic], topicsResourcesFetchedex[`topic_` + selectedTopic]])

  useEffect(() => {
    if (topicsResourcesFetchedxx === false) {
      setTopicsResourcesFetchedxx(true)
    }
  }, [topicsResources[`topic_` + selectedTopic], topicsResourcesFetchedex[`topic_` + selectedTopic], topicsResourcesFetchedxx])

  async function fetchTopicResources() {
    if (selectedTopic !== null && topicsResources[`topic_` + selectedTopic] === undefined) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/resources-names-list/?topic=${selectedTopic}&paginate_by=10`, { headers: login_credentials })
        .then((res) => {
          topicsResources[`topic_` + selectedTopic] = res.data.results
          topicsResourcesFetchedex[`topic_` + selectedTopic] = true
          setTopicsResourcesFetched(false)
          topicsResourcesNext[`topic_` + selectedTopic] = res.data.next === null ? {} : { next: res.data.next }
        })
    }
  }

  async function fetchNextTopicResources(next_url) {
    setTopicsResourcesFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_topic_resources = topicsResources[`topic_` + selectedTopic]
      if (res.data.results.length > 0) {
        for (var tprs = 0; tprs <= res.data.results.length - 1; tprs++) {
          next_topic_resources.push(res.data.results[tprs])
        }
        topicsResources[`topic_` + selectedTopic] = next_topic_resources
      }
      topicsResourcesNext[`topic_` + selectedTopic] = res.data.next === null ? {} : { next: res.data.next }
      setTopicsResourcesFetched(true)
    })
  }

  function deleteResource(id) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        setResourceDeleted(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  const [id2List, setId2List] = useState({
    droppable: "allResources",
    moduleDroppable: "modulesResources",
    topicDroppable: "topicsResources",
  })

  function getList(id) {
    if (id2List[id] === "allResources") {
      return allResources[currentSelectedResourcesType]
    } else if (id2List[id] === "modulesResources") {
      return modulesResources[`module_` + selectedModule]
    } else if (id2List[id] === "topicsResources") {
      return topicsResources[`topic_` + selectedTopic]
    }
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  useEffect(() => {
    setAllResourcesFetched(true)
  }, [allResources[currentSelectedResourcesType]])

  function handleDraggedItemSequence(currentItem, type, to = `internal`) {
    if (currentItem) {
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      if (type !== `resources`) {
        formData.append("name", currentItem.name)
      } else {
        formData.append("resource_id", currentItem.resource_id)
        formData.append("resource_type", currentItem.resource_type)
      }
      if (to === `external` && type === `topics`) {
        formData.append("module", currentItem.module)
      } else if (to === `topic_external` && type === `resources`) {
        formData.append("topic", currentItem.topic)
      } else if (to === `module_external` && type === `resources`) {
        formData.append("module", currentItem.module)
      }
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/` + currentItem.id + "/", formData, { headers: DefaultHeader })
        .then((res) => {})
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
    }
  }

  function onDragEnd(result) {
    //only for resources
    let type = result.source.droppableId.split("_")[0].toString() + `_resourceDraggable`
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    if (result.type === "moduleDraggable") {
      const items = reorder(modules, sourceIndex, destIndex)
      let currentModule = items[result.destination.index]
      if (result.destination.index === 0) {
        currentModule.sequence = (parseFloat(modules[0].sequence) - 10).toFixed(20)
      } else if (result.destination.index === modules.length - 1) {
        currentModule.sequence = (parseFloat(modules[modules.length - 1].sequence) + 10).toFixed(20)
      } else {
        if (result.source.index > result.destination.index) {
          currentModule.sequence = (
            (parseFloat(modules[result.destination.index - 1].sequence) + parseFloat(modules[result.destination.index].sequence)) /
            2
          ).toFixed(20)
        } else if (result.source.index < result.destination.index) {
          currentModule.sequence = (
            (parseFloat(modules[result.destination.index].sequence) + parseFloat(modules[result.destination.index + 1].sequence)) /
            2
          ).toFixed(20)
        }
      }
      handleDraggedItemSequence(currentModule, "modules")
      setModules(items)
    } else if (result.type === "topicDraggable") {
      const itemSubItemMap = modulesData

      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      const sourceSubItems = itemSubItemMap[sourceParentId].topics
      const destSubItems = itemSubItemMap[destParentId].topics

      let newItems = modulesData[result.destination.droppableId]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(modulesData[result.destination.droppableId].topics, sourceIndex, destIndex)
        let currentTopic = items[result.destination.index]
        let currentData = modulesData[result.destination.droppableId].topics
        if (result.destination.index === 0) {
          currentTopic.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
        } else if (result.destination.index === currentData.length - 1) {
          currentTopic.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (result.source.index > result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
              2
            ).toFixed(20)
          } else if (result.source.index < result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
              2
            ).toFixed(20)
          }
        }
        handleDraggedItemSequence(currentTopic, "topics")
        modulesData[result.destination.droppableId].topics = items
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = destSubItems
        newDestSubItems.splice(destIndex, 0, draggedItem)

        if (result.destination.index === 0 && destSubItems.length === 1) {
          draggedItem.sequence = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index + 1].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index + 1 === destSubItems.length) {
          draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem.sequence = (
            (parseFloat(destSubItems[result.destination.index + 1].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        let module_id = result.destination.droppableId.split("_")[1].toString()
        draggedItem.module = module_id
        handleDraggedItemSequence(draggedItem, "topics", "external")
        modulesData[result.destination.droppableId] = newItems
      }
    } else if (type === "module_resourceDraggable" || type === "topic_resourceDraggable") {
      const itemSubItemMap = type === "topic_resourceDraggable" ? topicsResources : modulesResources

      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      const sourceSubItems = itemSubItemMap[sourceParentId]
      const destSubItems = itemSubItemMap[destParentId]

      let newItems =
        result.destination.droppableId.split("_")[0].toString() === `resource`
          ? allResources[currentSelectedResourcesType]
          : type === "topic_resourceDraggable"
          ? topicsResources[result.destination.droppableId]
          : modulesResources[result.destination.droppableId]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        if (type === "topic_resourceDraggable") {
          const items = reorder(topicsResources[result.destination.droppableId], sourceIndex, destIndex)

          let currentResource = items[result.destination.index]
          let currentData = topicsResources[result.destination.droppableId]
          if (result.destination.index === 0) {
            currentResource.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
          } else if (result.destination.index === currentData.length - 1) {
            currentResource.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
          } else {
            if (result.source.index > result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
                2
              ).toFixed(20)
            } else if (result.source.index < result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
                2
              ).toFixed(20)
            }
          }
          handleDraggedItemSequence(currentResource, "resources")

          setTopicsResourcesFetched(false)
          topicsResources[result.destination.droppableId] = items
        } else {
          const items = reorder(modulesResources[result.destination.droppableId], sourceIndex, destIndex)

          let currentResource = items[result.destination.index]
          let currentData = modulesResources[result.destination.droppableId]
          if (result.destination.index === 0) {
            currentResource.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
          } else if (result.destination.index === currentData.length - 1) {
            currentResource.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
          } else {
            if (result.source.index > result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
                2
              ).toFixed(20)
            } else if (result.source.index < result.destination.index) {
              currentResource.sequence = (
                (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
                2
              ).toFixed(20)
            }
          }
          handleDraggedItemSequence(currentResource, "resources")

          setModulesResourcesFetched(false)
          setModulesResourcesFetchedx(false)
          modulesResources[result.destination.droppableId] = items
        }
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = result.destination.droppableId.split("_")[0].toString() === `resource` ? allResources[currentSelectedResourcesType] : destSubItems
        if (result.destination.droppableId.split("_")[0].toString() === `resource`) {
          /*to be present in api success start*/
          draggedItem.dragged_id = draggedItem.id
          draggedItem.id = draggedItem.resource_data.id
          draggedItem.thumbnail_url = draggedItem.resource_data.thumbnail_url
          draggedItem.name = draggedItem.resource_data?.display_name ?? draggedItem.resource_data?.name
          // draggedItem.sequence = draggedItem.sequence
          delete draggedItem["resource_data"]
          delete draggedItem["resource_id"]
          delete draggedItem["resource_type"]
          /*to be present in api success end*/
          deleteResource(draggedItem.dragged_id)
        }
        newDestSubItems.splice(destIndex, 0, draggedItem)
        if (result.destination.droppableId.split("_")[0].toString() === `resource`) {
          setAllResourcesFetched(false)
          allResources[currentSelectedResourcesType] = newItems
        } else {
          /**/
          if (result.destination.index === 0 && destSubItems.length === 1) {
            draggedItem.sequence = result.destination.index
          } else if (result.destination.index === 0) {
            if (draggedItem) {
              draggedItem.sequence = (parseFloat(destSubItems[result.destination.index + 1].sequence) - 10).toFixed(20)
            }
          } else if (result.destination.index + 1 === destSubItems.length) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
          } else {
            draggedItem.sequence = (
              (parseFloat(destSubItems[result.destination.index + 1].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
              2
            ).toFixed(20)
          }

          /**/
          if (type === "topic_resourceDraggable") {
            let topic_id = result.destination.droppableId.split("_")[1].toString()
            draggedItem.topic = topic_id

            handleDraggedItemSequence(draggedItem, "resources", "topic_external")

            setTopicsResourcesFetched(false)
            topicsResources[result.destination.droppableId] = newItems
          } else {
            let module_id = result.destination.droppableId.split("_")[1].toString()
            draggedItem.module = module_id

            handleDraggedItemSequence(draggedItem, "resources", "module_external")

            setModulesResourcesFetched(false)
            setModulesResourcesFetchedx(false)
            modulesResources[result.destination.droppableId] = newItems
          }
        }
      }
    } else if (type === "resource_resourceDraggable") {
      const itemSubItemMap = allResources

      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      const sourceSubItems = itemSubItemMap[sourceParentId]
      const destSubItems = itemSubItemMap[destParentId]

      let newItems = allResources[currentSelectedResourcesType]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(allResources[currentSelectedResourcesType], sourceIndex, destIndex)
        setAllResourcesFetched(false)
        allResources[currentSelectedResourcesType] = items
      } else {
        let newSourceSubItems = itemSubItemMap[currentSelectedResourcesType]
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = []
        var formData = new FormData()
        if (result.destination.droppableId.split("_")[0].toString() === `module`) {
          newDestSubItems = modulesResources[destParentId]
          formData.append("add_to_module", result.destination.droppableId.split("_")[1])
          setModulesResourcesFetched(false)
        } else {
          newDestSubItems = topicsResources[destParentId]
          formData.append("add_to_topic", result.destination.droppableId.split("_")[1])
          setTopicsResourcesFetched(false)
        }

        let destSubItems = newDestSubItems

        if (result.destination.index === 0 && destSubItems.length === 0) {
          draggedItem.sequence = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index === destSubItems.length) {
          draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem.sequence = (
            (parseFloat(destSubItems[result.destination.index].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        /*api for creating resource to topic or module*/
        formData.append("sequence", draggedItem.sequence)
        formData.append("resource_type", currentSelectedResourcesType)
        formData.append("resource_id", draggedItem.id)
        // draggedItem.resource_type = currentSelectedResourcesType

        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
          .then((res) => {
            if (res.data) {
              draggedItem.id = res.data.id
              draggedItem.resource_data = res.data.resource_data
              draggedItem.resource_id = res.data.resource_id
              draggedItem.resource_type = res.data.resource_type
              draggedItem.sequence = res.data.sequence
              delete draggedItem["name"]
            }
            let data_id = result.destination.droppableId.split("_")[1]
            if (result.destination.droppableId.split("_")[0].toString() === `module`) {
              setModulesResourcesFetched(true)
            } else {
              setTopicsResourcesFetched(true)
            }
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()
              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
        /*api end*/
        newDestSubItems.splice(destIndex, 0, draggedItem)
        setAllResourcesFetched(false)
        allResources[currentSelectedResourcesType] = newItems
      }
    }
  }

  /*end-drag and drop all functions*/

  const handleClose = () => {
    setDmodal(false)
    setSelectedDeletingModule([])
    setSelectedDeletingTopic([])
  }

  useEffect(() => {
    fetchStageData()
    fetchTemplateModules()
  }, [])

  async function fetchStageData() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/stages-name-list/?id=${location.stage_id}`, { headers: login_credentials }).then((res) => {
      setStageData(res.data.results[0])
    })
  }

  async function fetchTemplateModules() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-modules-list/?stage=${location.stage_id}`, { headers: login_credentials }).then((res) => {
      setModules(res.data.results)
      setModulesNext(res.data.next)
      if (res.data.results.length > 0) {
        setCurrentModuleFetched(false)
        setSelectedModule(res.data.results[0].id)
        selectedModuleToggle[res.data.results[0].id] = true
      } else {
        setCurrentModuleFetched(true)
      }
      setLoader(true)
    })
  }

  async function fetchNextTemplateModules(next_url) {
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_template_modules = modules
      if (res.data.results.length > 0) {
        for (var md = 0; md <= res.data.results.length - 1; md++) {
          next_template_modules.push(res.data.results[md])
        }
        setModules(next_template_modules)
      }
      setModulesNext(res.data.next)
    })
  }

  async function deleteModule() {
    let url = selectedDeletingModule && selectedDeletingModule.id ? `modules` : `topics`
    if (url === "modules") {
      var current_id = selectedDeletingModule.id
      var index = modules.findIndex((res) => res.id === current_id)
    } else {
      var current_id = selectedDeletingTopic.id
      var index = modulesData[`module_` + selectedModule].topics.findIndex((res) => res.id === current_id)
    }
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/${url}/` + current_id + "/", {
        headers: DefaultHeader,
        body: url === "modules" ? selectedDeletingModule : selectedDeletingTopic,
      })
      .then((res) => {
        if (url === "modules") {
          modules.splice(index, 1)
          if (modules && modules.length > 0) {
            // setCurrentModuleFetched(false)
            setSelectedModule(modules[0].id)
          } else {
            setSelectedModule(null)
          }
        } else {
          modulesData[`module_` + selectedDeletingTopic.module].topics.splice(index, 1)
          if (modulesData[`module_` + selectedDeletingTopic.module].topics && modulesData[`module_` + selectedDeletingTopic.module].topics.length > 0) {
            setTopicFetched(false)
            setSelectedTopic(modulesData[`module_` + selectedDeletingTopic.module].topics[0].id)
          } else {
            setSelectedTopic(null)
          }
        }
        setSelectedDeletingModule([])
        setSelectedDeletingTopic([])
        setDmodal(!dmodal)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function handleSave(event, datum, type) {
    event.preventDefault()
    if (datum.id) {
      datum[event.target.name] = event.target.value
      var formData = new FormData()
      for (var k in datum) {
        formData.append(k, datum[k])
      }
      if (type) {
        let url = type === "topic" ? "topics" : "modules"
        if (url === "modules") {
          var index = modules.findIndex((res) => res.id === datum.id)
          setModuleFetched(false)
        }
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + `/${url}/` + datum.id + "/", formData, { headers: DefaultHeader })
          .then((res) => {
            if (url === "modules") {
              modules[index].name = datum.name
              setModuleFetched(true)
            }
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()

              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    } else {
    }
  }

  useEffect(() => {
    if (moduleCreated === true) {
      setSelectedModule(modules[modules.length - 1].id)
    }
  }, [moduleCreated])

  function createModule(e) {
    e.preventDefault()
    let sequence = (modules.length + 1) * 10
    let module = {}
    module.name = stageData.name === "Workshop" ? `Module ` : `Section `
    module.name = module.name + ` ${sequence / 10}`
    module.sequence = sequence
    module.add_to_stage = location.stage_id
    var formData = new FormData()
    for (var k in module) {
      formData.append(k, module[k])
    }
    if (module.id !== undefined) {
    } else {
      /*to be fixed*/
      // setCurrentModuleFetched(false)
      // setLoader(false)
      setModuleCreated(false)
      var module_id = module.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/modules/create/", formData, { headers: login_credentials })
        .then((res) => {
          let new_data = {}
          let datum = modules
          if (res.data) {
            new_data.id = res.data.id
            new_data.name = res.data.name
            new_data.sequence = res.data.sequence
            setModules(datum.concat(new_data))
            // setCurrentModuleFetched(true)
            setModuleCreated(true)
            // setLoader(true)
          }
        })
        .catch((error) => {
          // setCurrentModuleFetched(true)
          // setLoader(true)
          // setModuleCreated(true)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  useEffect(() => {
    if (topicCreated === true && modulesData[`module_` + selectedModule]) {
      let datam = modulesData[`module_` + selectedModule].topics
      setSelectedTopic(datam[datam.length - 1].id)
    }
  }, [topicCreated])

  function createTopic(e, module_id) {
    e.preventDefault()
    let topics = modulesData[`module_` + module_id].topics
    let sequence = (topics.length + 1) * 10
    let topic = {}
    topic.name = "Topic " + sequence / 10
    topic.sequence = sequence
    topic.add_to_module = module_id
    var formData = new FormData()
    for (var k in topic) {
      formData.append(k, topic[k])
    }
    if (topic.id !== undefined) {
    } else {
      setTopicCreated(false)
      var topic_id = topic.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/topics/create/", formData, { headers: login_credentials })
        .then((res) => {
          let new_data = {}
          let datum = modulesData[`module_` + module_id].topics
          if (res.data) {
            new_data.id = res.data.id
            new_data.name = res.data.name
            new_data.sequence = res.data.sequence
            modulesData[`module_` + module_id].topics = datum.concat(new_data)
            setCurrentModuleTopicsFetched(true)
            setTopicCreated(true)
          }
          /*fetchCurrentTopics(true)*/
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  useEffect(() => {
    if (selectedModule != null) {
      fetchCurrentModule()
    }
  }, [selectedModule])

  async function fetchSelectedTopic() {
    if (topicsData && topicsData["topic_" + selectedTopic] === undefined) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/topic-basic-detail/${selectedTopic}/`, { headers: login_credentials })
        .then((res) => {
          topicsData["topic_" + selectedTopic] = res.data
          setTopicFetched(true)
        })
        .catch((error) => {
          setTopicFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      setTopicFetched(true)
    }
  }

  useEffect(() => {
    if (selectedTopic !== null) {
      fetchSelectedTopic()
    }
  }, [selectedTopic])

  async function fetchCurrentTopics(refetch = false) {
    if ((modulesData && modulesData[`module_` + selectedModule] && modulesData[`module_` + selectedModule].topics === undefined) || refetch) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-topics-list/?module=${selectedModule}`, { headers: login_credentials })
        .then((res) => {
          modulesData[`module_` + selectedModule].topics = res.data.results
          modulesData[`module_` + selectedModule].topics_next = res.data.next
          if (res.data.results.length > 0 && refetch === false && stageData.name !== "Workshop") {
            setTopicFetched(false)
            setSelectedTopic(res.data.results[0].id)
          } else if (res.data.results.length > 0 && refetch && stageData.name !== "Workshop") {
            setTopicFetched(false)
            setSelectedTopic(res.data.results[res.data.results.length - 1].id)
          } else {
            setSelectedTopic(null)
          }
          setCurrentModuleTopicsFetched(true)
        })
        .catch((error) => {
          setCurrentModuleTopicsFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      if (
        modulesData &&
        modulesData[`module_` + selectedModule] &&
        modulesData[`module_` + selectedModule].topics &&
        modulesData[`module_` + selectedModule].topics.length > 0 &&
        stageData.name !== "Workshop"
      ) {
        setTopicFetched(false)
        setSelectedTopic(modulesData[`module_` + selectedModule].topics[0].id)
      } else {
        setSelectedTopic(null)
      }
      setCurrentModuleTopicsFetched(true)
    }
  }

  async function fetchNextCurrentTopics(next_url) {
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_curent_topics = modulesData[`module_` + selectedModule].topics
      if (res.data.results.length > 0) {
        for (var tp = 0; tp <= res.data.results.length - 1; tp++) {
          next_curent_topics.push(res.data.results[tp])
        }
        modulesData[`module_` + selectedModule].topics = next_curent_topics
      }
      modulesData[`module_` + selectedModule].topics_next = res.data.next
      setCurrentModuleTopicsFetched(true)
    })
  }

  async function fetchCurrentModule() {
    if (modulesData && modulesData[`module_` + selectedModule] === undefined) {
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/module-basic-detail/${selectedModule}/`, { headers: login_credentials })
        .then((res) => {
          modulesData[`module_` + selectedModule] = res.data
          setCurrentModuleTopicsFetched(false)
          fetchCurrentTopics()
          setCurrentModuleFetched(true)
        })
        .catch((error) => {
          setCurrentModuleFetched(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
      setCurrentModuleFetched(true)
      setCurrentModuleTopicsFetched(false)
      fetchCurrentTopics()
    }
  }

  useEffect(() => {
    fetchAllResources()
  }, [currentSelectedResourcesType])

  useEffect(() => {
    if (allResources[currentSelectedResourcesType] !== undefined) {
      fetchAllResourcesOnSearch()
    }
  }, [searchValue])

  async function fetchAllResources() {
    if (allResources[currentSelectedResourcesType] === undefined) {
      setAllResourcesFetched(false)
      let type = currentSelectedResourcesType + "s"
      if (currentSelectedResourcesType === "turientimage") {
        type = "images"
      }
      let search = searchValue === "" ? "" : `&search=${searchValue}`
      axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-${type}-list/?paginate_by=15${search}`, { headers: login_credentials }).then((res) => {
        allResources[currentSelectedResourcesType] = res.data.results
        allResourcesNext[currentSelectedResourcesType] = res.data.next === null ? {} : { next: res.data.next }
        setAllResourcesFetched(true)
      })
    }
  }

  async function fetchAllResourcesOnSearch() {
    setAllResourcesFetched(false)
    let type = currentSelectedResourcesType + "s"
    if (currentSelectedResourcesType === "turientimage") {
      type = "images"
    }
    let search = searchValue === "" ? "" : `&search=${searchValue}`
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-${type}-list/?paginate_by=15${search}`, { headers: login_credentials }).then((res) => {
      allResources[currentSelectedResourcesType] = res.data.results
      allResourcesNext[currentSelectedResourcesType] = res.data.next === null ? {} : { next: res.data.next }
      setAllResourcesFetched(true)
    })
  }

  async function fetchNextAllResources(next_url) {
    if (next_url) {
      setAllResourcesFetched(false)
      axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
        var next_all_res = allResources[currentSelectedResourcesType]
        if (res.data.results.length > 0) {
          for (var rs = 0; rs <= res.data.results.length - 1; rs++) {
            next_all_res.push(res.data.results[rs])
          }
          allResources[currentSelectedResourcesType] = next_all_res
        }
        allResourcesNext[currentSelectedResourcesType] = res.data.next === null ? {} : { next: res.data.next }
        setAllResourcesFetched(true)
      })
    }
  }

  function setType(value) {
    if (currentSelectedResourcesType !== value) {
      setCurrentSelectedResourcesType(value)
    }
  }

  function showTopicsData(topic_id) {
    if (topic_id !== null && topicFetched && topicsData["topic_" + topic_id]) {
      let datum = topicsData["topic_" + topic_id]
      return (
        <div className={`px-3`}>
          {/*<Card.Title className={`d-inline-flex p-2 float-left`} >
            <div className={`p-3`} style={{ backgroundColor: `#576186`, borderRadius: `9px` }} >
              <input type="number" name="sequence" defaultValue={datum.sequence} onChange={(e) => handleSave(e,datum,'topic')} id={datum.id+`_topic_sequence`} placeholder={"Enter Topic Sequence"} className={`shadow-none border-0 pr-3 ${Styles.font_16}`} style={{ color: '#C6CFF4', outline: `none`, backgroundColor: `transparent`, maxWidth: `fix-content` }} required />
              <Button className={`float-right bg-transparent ${Styles.font_12} d-inline-flex shadow-none`} style={{ border: `1px solid white` }}>
                <Image
                style={{width: "12px",height: "12px", marginRight: '5px' }}
                alt="Turient logo"
                src="material-edit.png"
                className="my-auto float-left" />
                Edit Sequence
              </Button>
            </div>
          </Card.Title>
          <Card.Title className={`p-2`} >
            <div className={`p-3 d-flex`} style={{ backgroundColor: `#576186`, borderRadius: `9px`, maxWidth: `fit-content` }} >
              <textarea rows={2} type="text" name="description" defaultValue={datum.description} onChange={(e) => handleSave(e,datum,'topic')} id={datum.id+`_topic_input_name`} placeholder="Enter Topic Description" className={`shadow-none border-0 mr-3 my-auto ${Styles.font_16}`} style={{ color: '#C6CFF4', outline: `none`, backgroundColor: `transparent` }} required/>
              <Button className={`float-right bg-transparent align-items-center ${Styles.font_12} d-inline-flex shadow-none`} style={{ border: `1px solid white` }}>
                <Image
                style={{width: "12px",height: "12px", marginRight: '5px' }}
                alt="Turient logo"
                src="material-edit.png"
                className="my-auto float-left" />
                Edit Description
              </Button>
            </div>
          </Card.Title>*/}
          {showResourcesButton(`topic`, topic_id)}
        </div>
      )
    } else return null
  }

  function showModuleDescription(datum) {
    if (false) {
      return (
        <React.Fragment>
          <Card.Header className={`text-right text-white border-0 bg-transparent p-0`}>
            <Button
              variant="none"
              className={`bg-transparent shadow-none text-white`}
              onClick={(e) => {
                setDmodal(true)
                setSelectedDeletingModule(datum)
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </Button>
          </Card.Header>
          <Card.Title className={`d-inline-flex p-2 float-left`}>
            <div className={`p-3`} style={{ backgroundColor: `#576186`, borderRadius: `9px` }}>
              <input
                type="number"
                name="sequence"
                defaultValue={datum.sequence}
                onChange={(e) => handleSave(e, datum, "module")}
                id={datum.id + `_module_sequence`}
                placeholder={"Enter Sequence"}
                className={`shadow-none border-0 pr-3 ${Styles.font_16}`}
                style={{ color: "#C6CFF4", outline: `none`, backgroundColor: `transparent`, maxWidth: `fix-content` }}
                required
              />
              <Button className={`float-right bg-transparent ${Styles.font_12} d-inline-flex shadow-none`} style={{ border: `1px solid white` }}>
                <Image
                  style={{ width: "12px", height: "12px", marginRight: "5px" }}
                  alt="Turient logo"
                  src="material-edit.png"
                  className="my-auto float-left"
                />
                Edit Sequence
              </Button>
            </div>
          </Card.Title>
          <Card.Title className={`p-2`}>
            <div className={`p-3 d-flex`} style={{ backgroundColor: `#576186`, borderRadius: `9px`, maxWidth: `fit-content` }}>
              <textarea
                rows={2}
                type="text"
                name="description"
                defaultValue={datum.description}
                onChange={(e) => handleSave(e, datum, "module")}
                id={datum.id + `_module_input_name`}
                placeholder="Enter Description"
                className={`shadow-none border-0 mr-3 my-auto ${Styles.font_16}`}
                style={{ color: "#C6CFF4", outline: `none`, backgroundColor: `transparent` }}
                required
              />
              <Button
                className={`float-right bg-transparent align-items-center ${Styles.font_12} d-inline-flex shadow-none`}
                style={{ border: `1px solid white` }}
              >
                <Image
                  style={{ width: "12px", height: "12px", marginRight: "5px" }}
                  alt="Turient logo"
                  src="material-edit.png"
                  className="my-auto float-left"
                />
                Edit Description
              </Button>
            </div>
          </Card.Title>
        </React.Fragment>
      )
    } else return null
  }

  function showCurrentModuleData(module_id) {
    var isLoaded = currentModuleFetched
    if (isLoaded) {
      if (modulesData[`module_` + module_id] !== undefined) {
        let datum = modulesData[`module_` + module_id]
        return (
          <Col lg="12" md="12" sm="12" xs="12" className={`d-flex flex-wrap px-0`}>
            <Col lg="12" md="12" sm="12" xs="12" className={`py-3 text-white`}>
              <Card style={{ backgroundColor: `#28324e`, height: `100%`, borderRadius: `15px` }}>
                <Card.Body className={`pt-0 ${stageData.name === "Workshop" ? `px-0` : `px-3`}`}>
                  {stageData.name !== "Workshop" ? showModuleDescription(datum) : ``}
                  {stageData.name !== "Workshop" ? (
                    <div className={`pb-3`} style={{ border: `1px dashed white` }}>
                      {showResourcesButton(`module`, module_id)}
                    </div>
                  ) : (
                    ``
                  )}
                  {/*topics view*/}
                  {stageData.name === "Workshop" ? showTopicsButtons(module_id) : ``}
                  {currentModuleTopicsFetched && modulesData[`module_` + module_id].topics_next !== null ? (
                    <Col md="auto" className={`align-self-center ml-auto`}>
                      <Button
                        variant="none"
                        onClick={(e) => {
                          e.preventDefault()
                          setCurrentModuleTopicsFetched(false)
                          fetchNextCurrentTopics(modulesData[`module_` + module_id].topics_next)
                        }}
                        className={`${Styles.font_12} text-center px-3 py-2 float-right`}
                        style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                      >
                        See More
                      </Button>
                    </Col>
                  ) : (
                    ``
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Col>
        )
      } else {
        /*return(
          <p className="animated fadeIn pt-3 text-center m-0">No Data found...</p>
        )*/
        return null
      }
    } else {
      return (
        <p id="loader" className="animated fadeIn pt-3 text-center">
          Loading...
        </p>
      )
    }
  }

  useEffect(() => {
    setSelectedModuleToggleChange(!selectedModuleToggleChange)
  }, [selectedModuleToggle[selectedModule]])

  function toggleModule(e, datum) {
    e.preventDefault()
    selectedModuleToggle[datum.id] = !selectedModuleToggle[datum.id]
    setSelectedModuleToggleChange(!selectedModuleToggleChange)
  }

  useEffect(() => {
    setSelectedTopicToggleChange(!selectedModuleToggleChange)
  }, [selectedTopicToggle[selectedTopic]])

  function toggleTopic(e, datum) {
    e.preventDefault()
    selectedTopicToggle[datum.id] = !selectedTopicToggle[datum.id]
    setSelectedTopicToggleChange(!selectedTopicToggleChange)
  }

  function showModulesButtons() {
    if (loader && modules) {
      return (
        <Droppable droppableId={`moduleDraggable`} type={`moduleDraggable`}>
          {(provided, snapshot) => (
            <div className={`col-12`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {modules.map((datum, index) => (
                <Draggable key={datum.id + `_module_name_` + index} draggableId={datum.id.toString() + "-module" + "_" + index.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={``}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <div
                        variant="none"
                        className={`${Styles.font_16} px-3 py-1 shadow-none w-100 text-dark mb-3 text-left d-inline-flex`}
                        onClick={(e) => {
                          e.preventDefault()
                          /*setCurrentModuleFetched(false);*/ setSelectedModule(datum.id)
                          toggleModule(e, datum)
                        }}
                        style={{ backgroundColor: `#f5f5f5`, color: `#c6cff4`, borderRadius: `0px`, opacity: 1 }}
                      >
                        <FontAwesomeIcon icon={faGripVertical} style={{ width: `20px`, height: `20px`, margin: `auto 0px` }} />
                        <input
                          type="text"
                          name="name"
                          id={datum.id + `_module_input_name`}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                          value={datum.name}
                          onChange={(e) => {
                            e.preventDefault()
                            handleSave(e, datum, "module")
                          }}
                          placeholder={"Enter Name"}
                          className={`shadow-none border-0 px-3 w-50 my-auto ${Styles.font_16}`}
                          style={{ outline: `none`, backgroundColor: `transparent`, maxWidth: `fix-content` }}
                          required
                        />
                        {datum.sequence}&nbsp;
                        {datum.id}
                        <div className={`ml-auto`}>
                          {stageData.name === "Workshop" &&
                          currentModuleTopicsFetched &&
                          modulesData[`module_` + datum.id] &&
                          modulesData[`module_` + datum.id].topics_next === null ? (
                            <Button
                              variant="none"
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                /*setCurrentModuleTopicsFetched(false);*/ setTopicCreated(false)
                                setSelectedModule(datum.id)
                                createTopic(e, datum.id)
                              }}
                              className={`${Styles.font_12} text-center px-3 py-2`}
                              style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                            >
                              <FontAwesomeIcon icon={faPlus} size="sm" />
                              {` `} Add Topic
                            </Button>
                          ) : (
                            ``
                          )}
                          <Button
                            variant="none"
                            className={`bg-transparent shadow-none text-dark`}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setDmodal(true)
                              setSelectedDeletingModule(datum)
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                          </Button>
                        </div>
                      </div>
                      <Col xs="12" sm="6" md="12" className={`px-0 ${selectedModuleToggle[datum.id] ? `` : `d-none`}`} style={{ clear: `both` }}>
                        {showCurrentModuleData(datum.id)}
                      </Col>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )
    }
  }

  function showTopicsButtons(module_id) {
    if (modulesData[`module_` + module_id] && modulesData[`module_` + module_id].topics) {
      return (
        <Droppable droppableId={`module_` + module_id} type={`topicDraggable`}>
          {(provided, snapshot) => (
            <div className={`bg-transparent`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {modulesData[`module_` + module_id].topics.map((datum, index) => (
                <Draggable key={datum.id + `_topic_name_` + index} draggableId={datum.id.toString() + "-topic" + "_" + index.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`bg-transparent float-none`}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <div
                        variant="none"
                        className={`${Styles.font_16} px-3 py-1 shadow-none w-100 text-dark my-3 text-left d-inline-flex`}
                        onClick={(e) => {
                          e.preventDefault() /*setTopicFetched(false)*/
                          setSelectedTopic(datum.id)
                          toggleTopic(e, datum)
                        }}
                        style={{ backgroundColor: `#f5f5f5`, color: `#c6cff4`, borderRadius: `0px`, opacity: 1 }}
                      >
                        <FontAwesomeIcon icon={faGripVertical} style={{ width: `20px`, height: `20px`, margin: `auto 0px` }} />
                        <input
                          type="text"
                          name="name"
                          id={datum.id + `_topic_input_name`}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                          defaultValue={datum.name}
                          onChange={(e) => {
                            e.preventDefault()
                            handleSave(e, datum, "topic")
                          }}
                          placeholder={"Enter Topic Name"}
                          className={`shadow-none border-0 px-3 w-50 ${Styles.font_16}`}
                          style={{ outline: `none`, backgroundColor: `transparent`, maxWidth: `fix-content` }}
                          required
                        />
                        {datum.sequence} {datum.id}
                        <Button
                          variant="none"
                          className={`bg-transparent shadow-none text-dark ml-auto`}
                          onClick={(e) => {
                            setDmodal(true)
                            datum.module = module_id
                            setSelectedDeletingTopic(datum)
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                        </Button>
                      </div>
                      <Col xs="12" sm="6" md="12" className={`px-0`} style={{ clear: `both` }}>
                        {stageData.name === "Workshop" && selectedTopicToggle[datum.id] ? showTopicsData(datum.id) : ``}
                      </Col>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )
    }
  }

  function showResourcesButton(type, id) {
    let logo = video
    if (currentSelectedResourcesType === "blog") {
      logo = blog
    } else if (currentSelectedResourcesType === "video") {
      logo = video
    } else if (currentSelectedResourcesType === "link") {
      logo = link
    } else {
      logo = turientimage
    }
    let data = []
    if (type === `topic`) {
      data = topicsResources[`topic_` + id]
    } else if (type === `module`) {
      data = modulesResources[`module_` + id]
    } else {
      data = allResources[currentSelectedResourcesType]
    }
    data = data ? data : []
    let next_url = null
    if (type === "resource") {
      next_url = allResourcesNext[currentSelectedResourcesType]
    } else if (type === "topic") {
      next_url = topicsResourcesNext[`topic_` + id]
    } else {
      next_url = modulesResourcesNext[`module_` + id]
    }
    return (
      <Droppable droppableId={type + `_` + id} type={`resourceDraggable`}>
        {(provided, snapshot) => (
          <div className={`bg-transparent ${type === `resource` ? `resources` : ``}`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {data.map((item, index) => (
              <Draggable key={item.id + `_${type}_name_` + index} draggableId={item.id.toString() + "-${type}" + "_" + index.toString()} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={type === "resource" ? `w-50 mt-3 px-1 bg-transparent py-0 float-left` : `float-none`}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <div className={type === "resource" ? `resource-card-bg d-flex` : ``} style={{ color: `#C6CFF4`, position: `relative` }}>
                      {type === "resource" ? (
                        <div>
                          {item.thumbnail_url ? (
                            <LazyLoadImage
                              src={item.thumbnail_url || item.avatar}
                              alt={item.name}
                              style={{ borderRadius: `10px`, width: `100%`, height: `100%`, fontSize: `12px` }}
                            />
                          ) : (
                            <div
                              className={`position-absolute`}
                              style={{
                                backgroundColor:
                                  currentSelectedResourcesType === "video"
                                    ? `#6D77D9`
                                    : currentSelectedResourcesType === "blog"
                                    ? `#619176`
                                    : currentSelectedResourcesType === "link"
                                    ? `#A3801F`
                                    : `#A86E84`,
                                borderRadius: `10px`,
                                width: `100%`,
                                height: `100%`,
                              }}
                            >
                              <div className={`d-inline-flex align-items-center`} style={{ verticalAlign: `middle` }}>
                                <img
                                  src={item.avatar && item.avatar.indexOf(".pdf") < 1 ? item.avatar : logo}
                                  alt={currentSelectedResourcesType}
                                  className={`mb-0 mt-2 ml-2 float-left position-absolute`}
                                  style={{ objectFit: `contain`, height: item.avatar && item.avatar.indexOf(".pdf") < 1 ? `40px` : `24px`, left: 0, top: 0 }}
                                />
                                <span
                                  className={`float-right px-2 mt-2 mr-2 position-absolute`}
                                  style={{ backgroundColor: `#00000080`, boxShadow: `0px 3px 6px #00000029`, borderRadius: `13px`, fontSize: `12px`, right: 0 }}
                                >
                                  {currentSelectedResourcesType === "turientimage" ? `file` : currentSelectedResourcesType}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className={`${item.thumbnail_url ? Styles.img_bg : ``}`}></div>
                          {item.name && item.name.length > 35 ? (
                            <OverlayTrigger
                              placement={"top"}
                              overlay={
                                <Tooltip className="event-tooltip" id={`tooltip-${item.name}`}>
                                  {item.name}
                                </Tooltip>
                              }
                            >
                              <p
                                className={`elipsis-2 text-center w-100 px-2 pb-1 mt-auto position-absolute ${Styles.font_12} ${Styles.b_0}`}
                                style={{ paddingTop: `5px`, margin: `0px`, fontWeight: "bold", maxHeight: `42px` }}
                              >
                                {item.name}
                              </p>
                            </OverlayTrigger>
                          ) : (
                            <p
                              className={`elipsis-2 text-center w-100 px-2 pb-1 mt-auto position-absolute ${Styles.font_12} ${Styles.b_0}`}
                              style={{ paddingTop: `5px`, margin: `0px`, fontWeight: "bold", WebkitBoxOrient: "vertical" }}
                            >
                              {item.name}
                            </p>
                          )}
                        </div>
                      ) : (
                        ``
                      )}
                      <p
                        className={`m-0 ${
                          type === "resource" ? `d-none elipsis-2 text-center w-100 px-2 pb-1 mt-auto position-absolute ${Styles.font_12} ${Styles.b_0}` : ``
                        }`}
                      >
                        {type !== "resource" ? (
                          <FontAwesomeIcon
                            className={`${modulesResourcesFetched} ${topicsResourcesFetched}`}
                            icon={
                              item.resource_type === "video"
                                ? faVideo
                                : item.resource_type === "link"
                                ? faLink
                                : item.resource_type === "blog"
                                ? faFileAlt
                                : item.resource_type === "turientimage"
                                ? faFile
                                : faSpinner
                            }
                            size="lg"
                            style={{ position: `absolute`, left: 0 }}
                          />
                        ) : (
                          ""
                        )}
                        {item.resource_data ? item.resource_data?.display_name ?? item.resource_data?.name : item.name}
                        {type !== "resource" ? (
                          <FontAwesomeIcon
                            onClick={() => {
                              const newState = data
                              newState.splice(index, 1)
                              setResourceDeleted(false)
                              if (type === "topic") {
                                topicsResources[`topic_` + id] = newState
                                setTopicsResourcesFetched(false)
                              } else {
                                modulesResources[`module_` + id] = newState
                                setModulesResourcesFetched(false)
                              }
                              deleteResource(item.id)
                            }}
                            icon={faTrash}
                            size="lg"
                            style={{ position: `absolute`, right: 0 }}
                          />
                        ) : (
                          ""
                        )}{" "}
                        - {id} - {item.sequence}
                      </p>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {type === "resource" && next_url && Object.keys(next_url).length > 0 ? (
              <Col md="auto" className={`align-self-center text-center d-inline-flex w-100 justify-content-center`}>
                <Button
                  variant="none"
                  onClick={(e) => {
                    e.preventDefault()
                    fetchNextAllResources(next_url.next)
                  }}
                  className={`${Styles.font_12} text-center px-3 py-2 mt-2`}
                  style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                >
                  See More
                </Button>
              </Col>
            ) : (
              <React.Fragment>
                {type === "module" && next_url && Object.keys(next_url).length > 0 ? (
                  <div className={`text-center`}>
                    <Button
                      variant="none"
                      onClick={(e) => {
                        e.preventDefault()
                        fetchNextModuleResources(next_url.next)
                      }}
                      className={`${Styles.font_12} text-center px-3 py-2 mt-2`}
                      style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                    >
                      See More
                    </Button>
                  </div>
                ) : type === "topic" && next_url && Object.keys(next_url).length > 0 ? (
                  <div className={`text-center`}>
                    <Button
                      variant="none"
                      onClick={(e) => {
                        e.preventDefault()
                        fetchNextTopicResources(next_url.next)
                      }}
                      className={`${Styles.font_12} text-center px-3 py-2 mt-2`}
                      style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                    >
                      See More
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                <div className={`justify-content-center pt-4 pb-3 ${type === "resource" ? `d-none` : `d-flex bg-transparent`}`}>
                  <Image style={{ width: "100px", height: "80px" }} alt="add resources here" src="stages.png" className="d-inline-block" />
                  <p className={`pl-3`}>
                    <span className={`font-weight-bold`} style={{ color: `#D4DCF7` }}>
                      Add Content {type}
                    </span>
                    <br />
                    <span className={`${Styles.font_14}`} style={{ color: `#C6CFF4` }}>
                      Drag and drop media and files to add content. <br /> As easy as that
                    </span>
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </Droppable>
    )
  }

  let page_uri = isBrowser() ? "/" + window.location.pathname.substr(1) : "false"
  if (page_uri === "/logout" || page_uri === "logout" || page_uri === "logout/" || page_uri === "/logout/") {
    page_uri = "/"
  }

  const renderTooltip = (props) => (
    <Popover id="progress-tooltip" className={`progress-stage`} {...props}>
      <div className={`w-100 text-right p-3`} style={{ color: `#5975D1` }}>
        <FontAwesomeIcon onClick={(e) => document.body.click()} icon={faTimesCircle} style={{ width: `24px`, height: `24px` }} />
      </div>
      <div className={`w-100 px-3`} style={{ color: `#F2F3F5` }}>
        <h6 className={`float-left`}>Your Progress</h6>
        <img src={Rewards} alt={`Rewards Program`} className={`my-auto ml-3 pl-2`} style={{ objectFit: `contain`, height: `24px` }} />
        <span className={`dummy-data`} style={{ color: `#C6CAD8`, fontSize: `12px` }}>
          &nbsp; 20 Points earned
        </span>
        <Col lg="12" md="12" sm="12" xs="12" className={`d-inline-flex`}>
          <div className={`mt-3 mb-5 float-left`}>
            <div className={`d-flex py-2`} style={{ backgroundColor: `#3F5396`, borderRadius: `9px`, width: `200px` }}>
              <span className={`font-weight-bold text-right align-self-center`} style={{ width: `20%`, fontSize: `40px` }}>
                1
              </span>
              <span className={`text-white align-self-center`} style={{ width: `60%`, lineHeight: `normal` }}>
                Course <br />
                Information
              </span>
              <span className={`align-items-center`} style={{ width: `20%` }}>
                <img src={Rewards} alt={`Rewards Program`} className={`m-0`} style={{ objectFit: `contain`, height: `24px` }} />
              </span>
            </div>
            <div className={`d-flex mt-3 py-2 align-items-center`} style={{ backgroundColor: `#3F5396`, color: `white`, borderRadius: `9px`, width: `200px` }}>
              <FontAwesomeIcon icon={faCheck} style={{ width: `18px`, height: `18px`, marginLeft: `15px` }} />
              &emsp; Course Type
            </div>
            <div className={`d-flex mt-3 py-2 align-items-center`} style={{ backgroundColor: `#3F5396`, color: `white`, borderRadius: `9px`, width: `200px` }}>
              <FontAwesomeIcon icon={faCheck} style={{ width: `18px`, height: `18px`, marginLeft: `15px` }} />
              &emsp; Course Identity
            </div>
            <div className={`d-flex mt-3 py-2 align-items-center`} style={{ backgroundColor: `#3F5396`, color: `white`, borderRadius: `9px`, width: `200px` }}>
              <FontAwesomeIcon icon={faCheck} style={{ width: `18px`, height: `18px`, marginLeft: `15px` }} />
              &emsp; Course Benefits
            </div>
            <div className={`d-flex mt-3 py-2 align-items-center`} style={{ backgroundColor: `#3F5396`, color: `white`, borderRadius: `9px`, width: `200px` }}>
              <FontAwesomeIcon icon={faCheck} style={{ width: `18px`, height: `18px`, marginLeft: `15px` }} />
              &emsp; Target Audience
            </div>
          </div>
          <div className={`float-left`}>
            <img src={ArrowWhite} alt={`Connection`} className={`my-auto`} style={{ objectFit: `contain`, width: `100%` }} />
          </div>
          <div className={`mt-3 mb-5 float-left`}>
            <div className={`d-flex py-2`} style={{ backgroundColor: `#3F5396`, borderRadius: `9px`, width: `200px` }}>
              <span className={`font-weight-bold text-right align-self-center`} style={{ width: `20%`, fontSize: `40px` }}>
                2
              </span>
              <span className={`text-white align-self-center pl-1`} style={{ width: `60%`, lineHeight: `normal` }}>
                Course <br />
                Content
              </span>
              <span className={`align-items-center`} style={{ width: `20%` }}>
                <img src={Rewards} alt={`Rewards Program`} className={`m-0`} style={{ objectFit: `contain`, height: `24px` }} />
              </span>
            </div>
            <div className={`d-flex mt-3 align-items-center`} style={{ color: `#AAB6DE`, borderRadius: `9px`, width: `200px` }}>
              &emsp; Pre Worskhop
            </div>
            <div className={`d-flex mt-2 align-items-center`} style={{ color: `#AAB6DE`, borderRadius: `9px`, width: `200px` }}>
              &emsp; Workshop
            </div>
            <div className={`d-flex mt-2 align-items-center`} style={{ color: `#AAB6DE`, borderRadius: `9px`, width: `200px` }}>
              &emsp; Post Workshop
            </div>
          </div>
        </Col>
      </div>
    </Popover>
  )

  return (
    <div>
      <SEO title="CourseTemplate Stages Modules View and Edit" />
      <DragDropContext onDragEnd={onDragEnd}>
        <Col lg="12" md="12" sm="12" xs="12" className={`px-0 d-inline-flex float-left`}>
          <div className={`float-left position-absolute`} style={{ width: `30%` }}>
            <div className={"float-left"} style={{ width: `88px` }}>
              <div>
                <Button
                  key={0}
                  variant="none"
                  className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSelectedResourcesType("video")
                  }}
                  style={{
                    backgroundColor: currentSelectedResourcesType === "video" ? `#576186` : `transparent`,
                    color: `#c6cff4`,
                    borderRadius: `0px`,
                    minWidth: `fit-content`,
                    opacity: 1,
                  }}
                >
                  <FontAwesomeIcon icon={faVideo} style={{ width: `20px`, height: `20px` }} />
                  Videos
                </Button>
                <Button
                  key={1}
                  variant="none"
                  className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSelectedResourcesType("blog")
                  }}
                  style={{
                    backgroundColor: currentSelectedResourcesType === "blog" ? `#576186` : `transparent`,
                    color: `#c6cff4`,
                    borderRadius: `0px`,
                    minWidth: `fit-content`,
                    opacity: 1,
                  }}
                >
                  <FontAwesomeIcon icon={faFileAlt} style={{ width: `20px`, height: `20px` }} />
                  Blogs
                </Button>
                <Button
                  key={2}
                  variant="none"
                  className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSelectedResourcesType("turientimage")
                  }}
                  style={{
                    backgroundColor: currentSelectedResourcesType === "turientimage" ? `#576186` : `transparent`,
                    color: `#c6cff4`,
                    borderRadius: `0px`,
                    minWidth: `fit-content`,
                    opacity: 1,
                  }}
                >
                  <FontAwesomeIcon icon={faFile} style={{ width: `20px`, height: `20px` }} />
                  Files
                </Button>
                <Button
                  key={3}
                  variant="none"
                  className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100 w-100`}
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSelectedResourcesType("link")
                  }}
                  style={{
                    backgroundColor: currentSelectedResourcesType === "link" ? `#576186` : `transparent`,
                    color: `#c6cff4`,
                    borderRadius: `0px`,
                    minWidth: `fit-content`,
                    opacity: 1,
                  }}
                >
                  <FontAwesomeIcon icon={faLink} style={{ width: `20px`, height: `20px` }} />
                  Links
                </Button>
                {/*<Button key={4} variant="none" className={`${Styles.font_16} text-center px-3 py-1 shadow-none h-100`} onClick={(e) => {e.preventDefault();}} style={{ backgroundColor: currentSelectedResourcesType === 'quiz' ? `#576186` : `transparent`, color: `#c6cff4`, borderRadius: `0px`, minWidth: `fit-content`, opacity: 1 }} >
                  Quizzes
                </Button>*/}
              </div>
            </div>
            <div className={`float-left`} style={{ width: `calc(100% - 88px)`, backgroundColor: `#2e3446` }}>
              <div style={{ padding: `15px 15px 0px 15px` }}>
                <div className={`col-12 d-inline-flex`} style={{ backgroundColor: `#414a6c` }}>
                  <FontAwesomeIcon icon={faSearch} size="lg" style={{ margin: `auto 0px`, color: `#7c84a0` }} />
                  <FormControl
                    autoFocus
                    className="border-0 py-1 px-2 form-control px-0 shadow-none font-14"
                    style={{ backgroundColor: `transparent`, color: `#b6bdd4` }}
                    placeholder={`search ${currentSelectedResourcesType}`}
                    onChange={(e) => {
                      e.preventDefault()
                      setSearchValue(e.target.value)
                      setAllResourcesFetched(false)
                    }}
                    value={searchValue}
                  />
                </div>
              </div>
              {/*<DroppableSection remaining_class={'resources'} section_id={'droppable'} type={`droppableSubItem`} list_id={'main_resources'} draglist={allResources[currentSelectedResourcesType]} data_id={``} next_url={allResourcesNext[currentSelectedResourcesType]} fetchNextAllResources={fetchNextAllResources} currentSelectedResourcesType={currentSelectedResourcesType}/>*/}
              {showResourcesButton(`resource`, currentSelectedResourcesType)}
              <Button onClick={() => navigate(`/app/upload?redirection_url=${page_uri}`)} className="position-fixed-button">
                {" "}
                Upload More Content{" "}
              </Button>
            </div>
          </div>
          <Modal show={dmodal} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete{" "}
              {selectedDeletingModule && selectedDeletingModule.name !== undefined ? `Module ` + selectedDeletingModule.name : ""}{" "}
              {selectedDeletingTopic && selectedDeletingTopic.name !== undefined ? `Topic ` + selectedDeletingTopic.name : ""}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                cancel
              </Button>
              <Button variant="primary" onClick={deleteModule}>
                delete
              </Button>
            </Modal.Footer>
          </Modal>
          <div className={`px-0 float-left position-fixed`} style={{ width: `70%`, left: `30%`, height: `calc(100vh - 90px)`, overflowY: `scroll` }}>
            <Col xs="12" sm="12" md="12" className={`px-0`}>
              <p className={`${Styles.font_h3} border-bottom p-2`} style={{ color: `#C6CFF4`, fontFamily: `Helvetica Neue` }}>
                <Link className={`pointer text-white`} to={`/app/default-course-templates/edit/${location.template_id}/${location.props.location.hash}`}>
                  {" "}
                  <FontAwesomeIcon icon={faChevronLeft} style={{ width: `25px`, height: `25px` }} />
                </Link>
                &nbsp;
                <span className={`font-weight-bold`}>2</span>/3 <span className={`text-white font-weight-bold`}>{stageData.name}</span>
              </p>
            </Col>
            <Col xs="12" sm="12" md="12" className={`px-0 d-inline-flex`} style={{ backgroundColor: `#272D41`, height: `60px` }}>
              {loader && modulesNext === null ? (
                <Col md="auto" className={`align-self-center ml-auto`}>
                  <Button
                    variant="none"
                    onClick={(e) => {
                      setModuleCreated(false)
                      createModule(e)
                    }}
                    className={`${Styles.font_12} text-center px-3 py-2 float-right`}
                    style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                  >
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                    {` `} Add {stageData.name === "Workshop" ? `Module` : `Section`}
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Col>
            {showModulesButtons()}
            {loader && modulesNext !== null ? (
              <Col md="auto" className={`align-self-center ml-auto`}>
                <Button
                  variant="none"
                  onClick={(e) => {
                    e.preventDefault()
                    fetchNextTemplateModules(modulesNext)
                  }}
                  className={`${Styles.font_12} text-center px-3 py-2 float-right`}
                  style={{ backgroundColor: `#4b5a9b`, color: `#c6cff4`, borderRadius: `15px` }}
                >
                  See More
                </Button>
              </Col>
            ) : (
              ""
            )}
            <Col
              lg="12"
              md="12"
              sm="12"
              xs="12"
              className={`px-0 d-inline-flex fixed-bottom pointer`}
              style={{ backgroundColor: `#28314E`, width: "70%", float: "right", marginLeft: "auto" }}
            >
              <OverlayTrigger id={`progress_stage`} placement="top-start" flip={true} trigger="click" overlay={renderTooltip} arrowProps={""} rootClose>
                <Col lg="12" md="12" sm="12" xs="12" className={`p-3 align-items-center align-self-center d-inline-flex`}>
                  <div className={`text-white font-italic float-left w-50`}>
                    <p className={`m-0`} style={{ fontSize: `12px` }}>
                      4 of 4 Steps
                    </p>
                    <div className={`d-flex align-items-center`}>
                      <ProgressBar className={`float-left`} animated now={100} style={{ height: `12px`, width: `100%` }} />
                    </div>
                  </div>
                  <span className={`float-left pl-2`} style={{ fontSize: `12px`, alignSelf: `flex-end` }}>
                    All changes Saved
                  </span>
                  <FontAwesomeIcon icon={faChevronUp} style={{ width: `20px`, height: `20px`, float: `right`, marginLeft: `auto` }} />
                </Col>
              </OverlayTrigger>
            </Col>
          </div>
        </Col>
      </DragDropContext>
    </div>
  )
}

export default ViewModule
