import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

import Rewards from "../../images/svgs/points.svg"
import ArrowBlue from "../../images/svgs/arrow-blue.svg"
import Close from "../../images/svgs/close.svg"

const CreateCourse = (location) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [course, setCompany] = useState({ company: location.company_id })
  const [allTemplates, setAllTemplates] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  // const { state = {} } = location

  useEffect(() => {
    searchTemplates()
  }, [])

  function searchTemplates(search = "") {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-default-course-templates-list/?search=` + search, { headers: login_credentials }).then((res) => {
      setAllTemplates(res.data.results)
    })
  }

  function selectItem(e, field, data) {
    e.preventDefault()
    course[field] = data.id
    setSelectedTemplate(data.name)
  }

  function handleChange(event) {
    event.preventDefault()
    course[event.target.name] = event.target.value
    console.log(course, "course")
  }
  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    for (var k in course) {
      /*if(k == 'course_template'){
        formData.append('course_template',JSON.stringify(course.course_template))
      }else {*/
      formData.append(k, course[k])
      /*}*/
    }
    if (course.id != undefined) {
      console.log("edit")
      // var course_id = course.id
      // axios.put(process.env.GATSBY_REACT_APP_API_HOST+'/companies/' + course.id + '/' , formData,  { headers: login_credentials })
      // .then(res => {
      //   navigate('/app/dashboard/')
      // }).catch(error => {
      //   let error_messages = ''
      //   for (var key in error.response.data){
      //     error_messages += error.response.data[key] + "<br>"
      //   }
      //   if(error_messages === 'Invalid token.<br>'){authClient.signOut();

      //     localStorage.removeItem('authenticity_token');
      //     localStorage.removeItem('username');
      //     window.location.reload(true);
      //   }
      //   document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
      //     '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
      //       '<span aria-hidden="true">×</span></button>'+
      //       error_messages +
      //   '</div>')
      // });
    } else {
      var course_id = course.id
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/courses/create/", formData, { headers: login_credentials })
        .then((res) => {
          navigate("/app/dashboard/")
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
      // axios.post(process.env.GATSBY_REACT_APP_API_HOST+'/courses/', formData,  { headers: DefaultHeader })
      // .then(response => {
      //     // response.json().then(data =>{
      //       this.state.courses.push(response.data);
      //       this.setState({
      //         modal: !this.state.modal
      //       });
      //       // document.querySelector(".modal-header .close").click()
      //     // })
      // }).catch(error => {
      //   let error_messages = ''
      //   for (var key in error.response.data){
      //     error_messages += error.response.data[key] + "<br>"
      //   }
      //   if(error_messages === 'Invalid token.<br>'){authClient.signOut();

      //     localStorage.removeItem('authenticity_token');
      //     localStorage.removeItem('username');
      //     window.location.reload(true);
      //   }
      //   document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
      //     '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
      //       '<span aria-hidden="true">×</span></button>'+
      //       error_messages +
      //   '</div>')
      // });
    }
  }
  function toggle(e, type) {
    e.preventDefault()
  }

  return (
    <div>
      <SEO title="Course Creation" />
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <h5 className={`border-bottom p-2 d-inline-flex w-100 align-items-center`}>
            &nbsp;Create a New Course &emsp;
            <img src={Rewards} alt={`Rewards Program`} className={`my-auto`} style={{ objectFit: `contain`, height: `40px` }} />
            <Link className={`pointer ml-auto`} to={`/app/dashboard`} style={{ color: `#C6CFF4` }}>
              <span style={{ fontWeight: "normal" }} className={`small`}>
                Exit Course &nbsp;
                <img src={Close} alt={`Exit Course`} className={`my-auto`} style={{ objectFit: `contain`, height: `23px`, color: `white` }} />
              </span>
            </Link>
          </h5>
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col>
              <div>
                <Row className="mt-5 ml-5" style={{ height: "60vh", alignItems: "center", justifyContent: "center" }}>
                  <Col xs="4" className={`pr-0`}>
                    <Col xs="12" className={`pr-0`}>
                      <Link to={`/app/companies/${location.company_id}/courses/course-information`}>
                        <div class="card border-none course-creation-card">
                          <div class="card-header theme-bg-light text-white border-none">
                            Course Information{" "}
                            <img src={Rewards} alt={`Rewards Program`} className={`my-auto float-right`} style={{ objectFit: `contain`, height: `27px` }} />
                          </div>
                          <Row className="theme-bg-dark w-100 m-0" style={{ alignItems: "center" }}>
                            <Col className="float-left overflow-hidden" xs="4" lg="4" md="4">
                              <h1
                                style={{
                                  color: "#5b6896",
                                  fontSize: "10rem",
                                  fontWeight: 900,
                                  fontFamily: "Helvetica Neue",
                                  lineHeight: 0.77,
                                  margin: 0,
                                  margin: `0px 0px -14px 0px`,
                                }}
                              >
                                1
                              </h1>
                            </Col>
                            <Col className="float-left" xs="8" lg="8" md="8">
                              <ul class="list-group list-group-flush m-0 course-creation-list small">
                                <li class="list-group-item">Course Type</li>
                                <li class="list-group-item">Select the type of course you want to teach, create course identity with course details</li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </Link>
                    </Col>
                  </Col>
                  <div className={`float-left`}>
                    <img src={ArrowBlue} alt={`Connection`} className={`my-auto`} style={{ objectFit: `contain`, width: `100%` }} />
                  </div>
                  <Col xs="4" className={`pl-0`}>
                    <Col xs="12" className={`pl-0`}>
                      <div class="card border-none">
                        <div class="card-header theme-gray-bg-light text-white border-none">
                          Course Content{" "}
                          <img src={Rewards} alt={`Rewards Program`} className={`my-auto float-right`} style={{ objectFit: `contain`, height: `27px` }} />
                        </div>
                        <Row className="theme-gray-bg-dark w-100 m-0" style={{ alignItems: "center" }}>
                          <Col className="float-left overflow-hidden" xs="4" lg="4" md="4">
                            <h1
                              style={{
                                color: "#737680",
                                fontSize: "10rem",
                                fontWeight: 900,
                                fontFamily: "Helvetica Neue",
                                lineHeight: 0.77,
                                margin: 0,
                                margin: `0px 0px -14px 0px`,
                              }}
                            >
                              2
                            </h1>
                          </Col>
                          <Col className="float-left" xs="8" lg="8" md="8">
                            <ul class="list-group list-group-flush m-0 course-creation-list small">
                              {/* <li class="list-group-item">Add different types of content within your course across three different levels. Click on the
                           each level and add sections/modules</li> */}
                              <li class="list-group-item">Pre Worskhop</li>
                              <li class="list-group-item">Workshop</li>
                              <li class="list-group-item">Post Workshop</li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Col>
                  {/*<Col xs="4">
                                      <Col xs="12">
                                        <div class="card">
                                          <div class="card-header">
                                            Course Structure
                                          </div>
                                          <ul class="list-group list-group-flush m-0">
                                            <li class="list-group-item">Timing & Schedule</li>
                                            <li class="list-group-item">Certification Path</li>
                                          </ul>
                                        </div>
                                      </Col>
                                    </Col>*/}

                  {/*<Col xs="3">
                                      <Col xs="12">
                                        <div class="card">
                                          <div class="card-header">
                                            Course Offerings
                                          </div>
                                          <ul class="list-group list-group-flush m-0">
                                            <li class="list-group-item">Pricing</li>
                                            <li class="list-group-item">Career Path</li>
                                          </ul>
                                        </div>
                                      </Col>
                                    </Col>
                                    <Col xs="3" className='mt-5'>
                                      <Col xs="12">
                                        <div class="card">
                                          <div class="card-header">
                                            Engage
                                          </div>
                                          <ul class="list-group list-group-flush m-0">
                                            <li class="list-group-item">Forums</li>
                                            <li class="list-group-item">Discussion</li>
                                          </ul>
                                        </div>
                                      </Col>
                                    </Col>*/}
                </Row>
              </div>
            </Col>
          </Form>
          {/*<Button onClick={()=>navigate('/app/dashboard/')} variant="danger" >Go Back</Button>*/}
        </Col>
      </Row>
    </div>
  )
}

export default CreateCourse
