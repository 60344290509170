import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FormGroup from "react-bootstrap/FormGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import authClient from "../../services/auth"
import NavWithTriangles from "../../services/nav_with_triangle"
import Notification from "../custom/Notification"
import { isBrowser } from "../../services/developer_mode"
import SunEditor from "suneditor-react"

const EditPoll = (location, data) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [quiz, setQuiz] = useState({})
  const [quizDetail, setQuizDetail] = useState("")
  const [usingDropdown, setUsingDropdown] = useState(false)
  const [showGuidelines, setShowGuideLines] = useState(false)
  const [notificationData, setNotificationData] = useState(null)

  const data_for_menu = [
    { id: 0, name: "Poll Information", selected: true, href: `/app/polls/edit/${location.id}` },
    { id: 1, name: "Add Questions", selected: false, href: `/app/polls/${location.id}/questions` },
    { id: 2, name: "Save & Publish", selected: false, href: `/app/polls/${location.id}/publish` },
  ]

  useEffect(() => {
    fetchQuiz()
  }, [location.id])

  useEffect(() => {
    updateQuiz()
  }, [quizDetail])

  function updateQuiz() {
    if (quiz != undefined && quiz.id != undefined) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/quizzes/" + quiz.id + "/", { quiz_details: quizDetail, name: quiz.name }, { headers: login_credentials })
        .then((res) => {})
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    }
  }

  async function fetchQuiz() {
    // console.log("fetchGroups called")
    // setCompanyLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/quizzes/${location.id}/`, { headers: login_credentials }).then((res) => {
      setQuiz(res.data)
      // setCompanyLoader(true)
    })
  }

  function handleChange(event, name = null, value = null) {
    event.preventDefault()
    if (name != null) {
      quiz[name] = value
    } else {
      quiz[event.target.name] = event.target.value
    }
    setQuiz(quiz)
    setUsingDropdown(!usingDropdown)
    handleSubmit(event)
  }

  function handleSubmit(event) {
    event.preventDefault()
    //console.log(quiz, "quiz")
    var formData = new FormData()
    quiz.no_of_attempts = quiz.no_of_attempts === "" || quiz.no_of_attempts == "0.00" || quiz.no_of_attempts == "0" ? "0.00" : quiz.no_of_attempts
    for (var k in quiz) {
      if (k === "quiz_details") {
        formData.append(k, quizDetail)
      } else formData.append(k, quiz[k])
    }
    if (location.id != undefined) {
      var quiz_id = location.id
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/quizzes/" + quiz_id + "/", formData, { headers: login_credentials })
        .then((res) => {
          /*navigate(`/app/quizzes/${quiz_id}/focus-areas`)*/
          // navigate(`/app/quizzes/${quiz_id}/questions`)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          // document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          //   '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
          //     '<span aria-hidden="true">×</span></button>'+
          //     error_messages +
          // '</div>')
          setNotificationData({ type: "error", title: error_messages })
        })
    }
  }

  return (
    <div>
      <SEO title="Edit Poll" />
      <h5 className="border-bottom p-2">
        Edit Poll
        <Link className={`pointer text-white`} to={`/app/polls`}>
          <span style={{ fontWeight: "normal" }} className="float-right small">
            {" "}
            Exit Edit{" "}
          </span>
        </Link>{" "}
      </h5>
      <Row className="justify-content-center d-flex m-0" style={{ alignItems: "center", padding: "2rem 5rem", height: "70vh" }}>
        <Col className={`col-10`}>
          <NavWithTriangles button_data={data_for_menu} classes={`mb-4 ml-2 mt-2 triangle-menu d-inline-flex`} />
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col className={`col-12 p-0`}>
              <p className="ml-2">Poll Details</p>
            </Col>
            <div className="m-2" style={{ float: "none", alignItems: "center", justifyContent: "center" }}>
              <Col
                xs="12"
                sm="12"
                md="12"
                className={`float-left light-div p-0 theme-color`}
                style={{ borderRadius: `15px`, color: `#B2B6CB`, minHeight: "270px" }}
              >
                <Col xs="12" sm="12" md="12" className="float-left border-bottom pl-0" style={{ display: "flex" }}>
                  <Col xs="12" sm="6" md="6" className="float-left p-0" style={{ borderRight: "1px solid", flex: 1 }}>
                    <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3">
                      <FormGroup className="m-0">
                        <div style={{ fontSize: `13px` }}>Poll Name</div>
                        <input
                          style={{ background: "inherit", color: "inherit", fontSize: "15px" }}
                          className="blue-bg video-modal p-0"
                          type="text"
                          name="name"
                          defaultValue={quiz.name}
                          onChange={handleChange}
                          id="name"
                          placeholder="Enter Poll name"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Col>
                  <Col xs="12" sm="6" md="6" className="float-left pl-2 pr-2" style={{ flex: 1 }}>
                    <Col xs="12" sm="6" md="12" className="float-left pt-3 pl-2 pr-2">
                      <FormGroup className="m-0">
                        <div style={{ fontSize: `13px` }}>Poll Description</div>
                        <textarea
                          style={{ background: "inherit", color: "inherit", fontSize: "15px" }}
                          className="blue-bg video-modal p-0 border-none font-italic"
                          type="text"
                          name="description"
                          defaultValue={quiz.description}
                          onChange={handleChange}
                          id="description"
                          placeholder="Enter quiz description"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Col>
                </Col>
                <Col xs="12" sm="12" md="12" className="float-left border-bottom pl-0">
                  <Col xs="12" sm="6" md="6" className="float-left p-0" style={{ borderRight: "1px solid" }}>
                    <Col xs="12" sm="12" md="12" className="float-left pt-3 pb-3">
                      <FormGroup className="m-0 d-flex align-items-center">
                        <div style={{ fontSize: `13px`, minWidth: "150px" }}>No of Questions</div>
                        <input
                          style={{ background: "inherit", color: "inherit", fontSize: "16px" }}
                          className="blue-bg video-modal number-input text-white font-weight-bold text-right"
                          type="number"
                          name="no_of_questions"
                          defaultValue={quiz.no_of_questions}
                          onChange={handleChange}
                          id="no_of_questions"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Col>
                  <Col xs="12" sm="6" md="6" className="float-left p-0">
                    <Col xs="12" sm="12" md="12" className="float-left pt-3 pb-3">
                      <FormGroup className="m-0 d-flex align-items-center">
                        <div style={{ fontSize: `13px`, minWidth: "150px" }}>Total time</div>
                        <input
                          style={{ background: "inherit", color: "inherit", fontSize: "16px" }}
                          className="blue-bg video-modal number-input float-left text-white font-weight-bold text-right"
                          type="number"
                          name="quiz_time"
                          defaultValue={quiz.quiz_time}
                          onChange={handleChange}
                          id="quiz_time"
                          placeholder=" "
                          required
                        />
                        <div className={`font-weight-bold text-white`} style={{ fontSize: `13px` }}>
                          Mins
                        </div>
                      </FormGroup>
                    </Col>
                  </Col>
                </Col>
                <Col xs="12" sm="8" md="8" className={`float-left pb-3 pl-2 pr-2 ${showGuidelines ? "" : "d-none"} `}>
                  <Col xs="12" sm="6" md="12" className="float-left pt-3 pb-3 pl-2 pr-2">
                    <FormGroup className="m-0">
                      <div style={{ fontSize: `13px` }}>Quiz Guidelines</div>
                      <SunEditor
                        id="quiz_details"
                        setContents={quiz.quiz_details}
                        name="quiz_details"
                        onChange={setQuizDetail}
                        setOptions={{
                          height: "auto",
                          buttonList: [
                            ["undo", "redo", "font", "fontSize", "formatBlock"],
                            ["bold", "underline", "italic", "strike", "subscript", "superscript", "removeFormat"],
                            "/", // Line break
                            ["fontColor", "hiliteColor", "outdent", "indent", "align", "horizontalRule", "list", "table"],
                            ["link", "image", "video", "fullScreen", "showBlocks", "codeView", "preview", "print", "save"],
                          ],
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Col>
                <Button
                  variant="none"
                  onClick={(e) => {
                    e.preventDefault()
                    setShowGuideLines(!showGuidelines)
                  }}
                  className={`mt-3 mb-3 mr-0 text-center px-3 py-2 ml-2`}
                  style={{ backgroundColor: `#465077`, color: `#C6CFF4`, borderRadius: `10px`, fontSize: "12px" }}
                >
                  <FontAwesomeIcon icon={showGuidelines ? faTimes : faPlus} size="sm" /> {` `} {showGuidelines ? "Close" : "Add"} Guidelines
                </Button>
              </Col>
              <Col md="auto" className={`p-0 w-100`}>
                <Link
                  to={`/app/polls/${location.id}/questions`}
                  className={`w-100 mt-3 font-weight-bold font-14 text-center px-3 py-2 float-right`}
                  style={{ backgroundColor: `#4b5a9b`, color: `#fff`, borderRadius: `0px` }}
                >
                  <FontAwesomeIcon icon={faPlus} size="sm" />
                  {` `} Add Questions
                </Link>
              </Col>
              {/*<Col xs='12' sm="12" md="12" className='mt-3 float-left'><Button className='float-right' style={{background: '#576186', border: 0}} type="submit" color="warning">Proceed</Button>{' '}</Col>*/}
            </div>
          </Form>
        </Col>
      </Row>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default EditPoll
