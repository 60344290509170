import React, { useEffect, useState } from "react"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Pagination from "react-bootstrap/Pagination"
import FormControl from "react-bootstrap/FormControl"
import FormGroup from "react-bootstrap/FormGroup"

import Layout from "../../layout"
import Styles from "../../../modularscss/styles.module.scss"
import SEO from "../../seo"
import authClient from "../../../services/auth"
// import hideMessage from '../../../services/hidemessage';
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"
import Notification from "../../custom/Notification"
import { isBrowser } from "../../../services/developer_mode"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faSearch, faUserCircle, faCalendarAlt } from "@fortawesome/free-solid-svg-icons"

const TrainingStudents = (location, props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [studentLoader, setStudentLoader] = useState(false)
  const [initialFetch, setInitialFetch] = useState(false)
  const [students, setStudents] = useState([])

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [courses, setCourses] = useState([])
  const [coursesFetched, setCoursesFetched] = useState(false)
  const [course, setCourse] = useState("")
  const [coursesNext, setCoursesNext] = useState(null)
  const [coursesNextFetched, setCoursesNextFetched] = useState(false)
  const [courseLoader, setCourseLoader] = useState(false)

  const [trainings, setTrainings] = useState([])
  const [trainingsNext, setTrainingsNext] = useState(null)
  const [trainingsLoader, setTrainingsLoader] = useState(false)
  const [training, setTraining] = useState("")

  const [previous_url, setPreviousUrl] = useState()
  const [next_url, setNextUrl] = useState()
  const [total_pages, setTotalPages] = useState(0)

  const [companyLoader, setCompanyLoader] = useState(false)
  const [companies, setCompanies] = useState([])
  const [company, setCompany] = useState("")

  const [participantsList, setParticipantsList] = useState([])
  const [seeMoreVisible, setSeeMoreVisible] = useState(true)
  const [next_participant_url, setNextParticipantUrl] = useState(null)
  const [allParticipantsCount, setAllParticipantsCount] = useState(0)
  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    fetchStudents()
    fetchCompanies()
  }, [])

  useEffect(() => {
    if (startDate || endDate) {
      fetchStudents()
    }
  }, [startDate, endDate])

  useEffect(() => {
    setTraining("")
    setTrainings([])
    if (initialFetch) {
      fetchStudents()
    }
    if (course) {
      fetchTrainings(company)
    }
  }, [course])
  useEffect(() => {
    if (course) {
      fetchStudents()
    }
  }, [training])

  async function fetchStudents() {
    setStudentLoader(false)
    let start_date = "",
      end_date = "",
      selected_traing = "",
      selected_course = ""
    if (startDate) {
      start_date = "&start_date=" + new Date(Date.parse(startDate)).toISOString()
    }
    if (endDate) {
      end_date = "&end_date=" + new Date(Date.parse(endDate)).toISOString()
    }
    if (training) {
      selected_traing = "&training=" + training
    } else if (course) {
      selected_course = "&course=" + course
    }
    fetchParticipants()
  }

  function fetchParticipants() {
    axios
      .get(`${process.env.GATSBY_REACT_APP_API_HOST}/training-participants-es-scores/${location.training_id}/?paginate_by=10`, { headers: login_credentials })
      .then((res) => {
        setParticipantsList(res.data.results)
        setAllParticipantsCount(res.data.count)
        setStudentLoader(true)
        if (res.data.next) {
          setSeeMoreVisible(true)
          setNextParticipantUrl(res.data.next)
        } else {
          setSeeMoreVisible(false)
        }
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages == "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
        setParticipantsList([])
      })
  }

  function fetchNextParticipants() {
    setStudentLoader(false)
    axios.get(next_participant_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_pax_data = participantsList
      if (res.data.results.length > 0) {
        for (var md = 0; md <= res.data.results.length - 1; md++) {
          next_pax_data.push(res.data.results[md])
        }
        setParticipantsList(next_pax_data)
      }
      setStudentLoader(true)
      setNextParticipantUrl(res.data.next)
      if (res.data.next == null) {
        setSeeMoreVisible(false)
      }
    })
  }
  async function fetchCompanies() {
    setCompanyLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/fetch-company/`, { headers: login_credentials })
      .then((res) => {
        setCompanies(res.data.results)
        setCompanyLoader(true)

        // for first company
        setCompany(res.data[0])
        fetchCourses(res.data.length > 0 ? res.data[0] : [])
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        setTimeout(function () {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
        }, 5000)
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }
  async function fetchTrainings(comp) {
    setTrainingsLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-trainings-list/?course__company=` + comp.id + `&course=` + course, { headers: login_credentials })
      .then((res) => {
        setTrainings(res.data.results)
        setTrainingsNext(res.data.next)
        setTrainingsLoader(true)
      })
  }
  async function fetchCourses(comp) {
    setCourseLoader(false)
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-courses-list/?company=${comp.id}`, { headers: login_credentials }).then((res) => {
      setCourses(res.data.results)
      setCoursesNext(res.data.next)
      setCourseLoader(true)
    })
  }

  async function fetchNextCourses(next_url) {
    setCoursesNextFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_course_data = courses
      if (res.data.results.length > 0) {
        for (var crs = 0; crs <= res.data.results.length - 1; crs++) {
          next_course_data.push(res.data.results[crs])
        }
        setCourses(next_course_data)
      }
      setCoursesNext(res.data.next)
      setCoursesNextFetched(true)
    })
  }

  async function calculateScore(index, datum) {
    setStudentLoader(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/calculate-score-for-training/" + datum.owner_id + "/" + location.training_id + "/", {
        headers: login_credentials,
      })
      .then((res) => {
        fetchParticipants()
      })
  }

  function displayStudentData() {
    let data = participantsList
    if (studentLoader) {
      if (data.length > 0) {
        return data.map((datum, index) => {
          return (
            <tr key={index.toString() + "_student"} style={{ color: "#c6cff4" }}>
              <td className="pl-3 d-flex">
                <FontAwesomeIcon icon={faUserCircle} style={{ color: `#ebc280` }} size="lg" />
                <p className={`m-0 pl-2`}>{datum.first_name}</p>
              </td>
              <td>
                <p className={`m-0`}>{datum.owner__email}</p>
              </td>
              <td>{datum.score.engagement_score}</td>
            </tr>
          )
        })
      } else {
        return (
          <tr id="loader">
            <td colSpan="12" className="animated fadeIn pt-3 text-center">
              No Data found...
            </td>
          </tr>
        )
      }
    } else {
      return (
        <tr id="loader">
          <td colSpan="12" className="animated fadeIn pt-3 text-center">
            Loading...
          </td>
        </tr>
      )
    }
  }

  function showStudents() {
    if (authClient.isAuthenticated()) {
      return (
        <table>
          <thead>
            <tr>
              <th className="pl-3">Name</th>
              <th>Email</th>
              <td>Engagement Score</td>
            </tr>
          </thead>
          <tbody>{displayStudentData()}</tbody>
        </table>
      )
    } else {
      return ""
    }
  }

  function fetchPage(page) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/student-companies/?page=" + page, { headers: DefaultHeader })
      .then((res) => {
        setStudents(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(Math.ceil(Number(res.data.count) / 15))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function fetchNext() {
    axios
      .get(next_url, { headers: DefaultHeader })
      .then((res) => {
        setStudents(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(Math.ceil(Number(res.data.count) / 15))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }
  function fetchPrevious() {
    axios
      .get(previous_url, { headers: DefaultHeader })
      .then((res) => {
        setStudents(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(Math.ceil(Number(res.data.count) / 15))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function renderPageNumbers() {
    if (total_pages > 1) {
      var rows = []
      if (previous_url) {
        rows.push(<Pagination.Prev className={`m-0 bg-transparent`} onClick={() => fetchPrevious()} />)
      }
      for (var i = 0; i < total_pages; i++) {
        let temp_var = i + 1
        rows.push(
          <Pagination.Item key={i + 1} className={`m-0 bg-transparent`} onClick={() => fetchPage(temp_var)}>
            {i + 1}
          </Pagination.Item>
        )
      }
      if (next_url) {
        rows.push(<Pagination.Next className={`m-0 bg-transparent`} onClick={() => fetchNext()} />)
      }
      return (
        <Pagination className={`bg-card mx-0 my-3`} style={{ width: `fit-content` }}>
          {rows}
        </Pagination>
      )
    } else {
      return null
    }
  }

  function searchStudents(event) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + "/student-companies/?search=" + event.target.value, { headers: DefaultHeader })
      .then((res) => {
        setStudents(res.data.results)
        setNextUrl(res.data.next)
        setPreviousUrl(res.data.previous)
        setTotalPages(Math.ceil(Number(res.data.count) / 15))
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        // hideMessage.displayErrorMsg(error_messages)
        setNotificationData({ type: "error", title: error_messages })
      })
  }

  function showCourseFilter() {
    if (courseLoader && courses.length > 0) {
      return (
        <Form.Group className="mb-0 ml-2">
          <Form.Control as="select" className="student-filter-field" style={{ maxHeight: "33px" }} onChange={(e) => setCourse(e.target.value)}>
            <option value="">Course</option>
            {courses.map((course, index) => (
              <option key={index.toString() + "_course_item"} value={course.id}>
                {course.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )
    } else {
      return null
    }
  }
  function showTrainingFilter() {
    return (
      <Form.Group className="mb-0 ml-2">
        <Form.Control as="select" className="student-filter-field" style={{ maxHeight: "33px" }} onChange={(e) => setTraining(e.target.value)}>
          <option value="">Training</option>
          {trainings.map((training, index) => (
            <option value={training.id}>{training.name}</option>
          ))}
        </Form.Control>
      </Form.Group>
    )
  }

  function showFilters() {
    return (
      <div style={{ borderBottom: "1px solid gray", padding: "10px" }} className="d-flex">
        <div className={`d-inline-flex px-2`} style={{ backgroundColor: `#414a6c`, borderRadius: "7px" }}>
          <FontAwesomeIcon icon={faSearch} size="sm" style={{ margin: `auto 0px`, color: `#7c84a0` }} />
          <FormControl
            className="border-0 pl-2 form-control shadow-none"
            style={{ backgroundColor: `transparent`, color: `#c6cff4`, fontSize: ".9rem", height: "35px" }}
            placeholder={`Search students`}
            onChange={searchStudents}
          />
        </div>
        <FormGroup className="mb-0 ml-2" style={{ position: "relative", color: "#c6cff4" }}>
          <FontAwesomeIcon icon={faCalendarAlt} size="sm" style={{ margin: `auto 0px`, color: `#7c84a0`, position: "absolute", top: "9px", right: "9px" }} />
          <Datetime
            timeFormat={false}
            dateFormat={`DD-MMM-YYYY`}
            closeOnSelect={true}
            onChange={(e) => setStartDate(e)}
            value={startDate}
            inputProps={{ placeholder: "Start Date", className: "student-filter-field" }}
            style={{ background: "inherit", color: "#c6cff4", padding: 0 }}
          />
        </FormGroup>
        <FormGroup className="mb-0 ml-2" style={{ color: "#c6cff4", position: "relative" }}>
          <FontAwesomeIcon icon={faCalendarAlt} size="sm" style={{ margin: `auto 0px`, color: `#7c84a0`, position: "absolute", top: "9px", right: "9px" }} />
          <Datetime
            timeFormat={false}
            dateFormat={`DD-MMM-YYYY`}
            closeOnSelect={true}
            onChange={(e) => setEndDate(e)}
            value={endDate}
            inputProps={{ placeholder: "End Date", className: "student-filter-field" }}
            style={{ background: "inherit", color: "#c6cff4", padding: 0 }}
          />
        </FormGroup>
        {showCourseFilter()}
        {showTrainingFilter()}
        <Button
          onClick={() => {
            calculateScore()
          }}
          style={{ background: "#485b9e", border: "none", fontSize: "14px", float: "right", color: "white" }}
        >
          Calculate Score <FontAwesomeIcon icon={faChevronRight} size="sm" />
        </Button>
      </div>
    )
  }

  return (
    <div>
      <SEO title="My students" />
      <Col lg="12" md="12" sm="12" xs="12">
        <h4 className="mt-3">Students</h4>
        {renderPageNumbers()}
        <div className={`bg-card ${Styles.b_radius_15} mb-5`}>
          {showFilters()}
          {showStudents()}
        </div>
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default TrainingStudents
