import React, { useEffect, useState, useContext } from "react"
import { navigate, Link } from "gatsby"

import Col from "react-bootstrap/Col"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import Form from "react-bootstrap/Form"

import SEO from "../../components/seo"

import SunEditor from "suneditor-react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile, faChevronLeft } from "@fortawesome/free-solid-svg-icons"

import { getRequest, putRequest } from "../../services/rest_service"
import Notification from "../custom/Notification"

import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_REFETCH_RESOURCES } from "../../stores/sidebar_store/SidebarActions"

const readingTime = require("read-time-estimate")

const EditBlog = (location, data) => {
  const [blog, setBlog] = useState({})
  const [blog_description, setBlogDescription] = useState("")
  const [notificationData, setNotificationData] = useState(null)

  const [sidebarStore, dispatch] = useContext(SidebarContext)
  // const { company_id } = location.id

  useEffect(() => {
    fetchBlog()
  }, [location.id])

  const fetchBlog = async () => {
    const response = await getRequest(`/blogs/${location.id}/`)
    if (response.success) {
      setBlog(response.data)
      setBlogDescription(response.data.description)
    }
  }

  const changeDescription = (value) => {
    let plainValue = value.replace(/background:[^;]+/g, "").replace(/background-color:[^;]+/g, "")
    setBlogDescription(plainValue)
  }

  function handleChange(event) {
    event.preventDefault()
    blog[event.target.name] = event.target.value
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    var formData = new FormData()
    for (var k in blog) {
      if (k === "description") {
        formData.append("description", blog_description)
      } else formData.append(k, blog[k])
    }
    formData.append("points", Math.ceil(stat.duration))
    formData.append("duration", Math.ceil(stat.duration))
    if (location.id != undefined) {
      var company_id = location.id
      let response = await putRequest("/blogs/" + company_id + "/", formData)
      if (response.success) {
        navigate("/app/blogs")
        console.log(sidebarStore)
        dispatch({ type: SET_REFETCH_RESOURCES, payload: true })
      } else {
        setNotificationData({ type: "error", title: response.msg })
      }
    }
  }

  let stat = readingTime(blog_description)

  return (
    <div>
      <SEO title="Edit Blog" />
      <Col
        lg="11"
        md="11"
        sm="11"
        xs="11"
        className={`add-links justify-content-center text-white padding-mob`}
        style={{ alignItems: "center", margin: "10px 50px", padding: 20, borderRadius: 15, border: "1px solid #1f2749", background: "#212C41" }}
      >
        <Col className="mb-2">
          <Link className={`d-inline pointer text-white`} to={`/app/blogs`}>
            {" "}
            <FontAwesomeIcon icon={faChevronLeft} style={{ width: `20px`, height: `20px` }} />
          </Link>
          <h4 className="d-inline"> Edit Blog</h4>
        </Col>
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <Col xs="12" sm="6" md="12">
            <InputGroup className="mb-3" style={{ border: "1px solid #2F3C54", background: "#2F3C54", borderRadius: 15 }}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1" style={{ border: "1px solid #2F3C54", background: "#2F3C54", borderRadius: 15 }}>
                  <FontAwesomeIcon icon={faFile} style={{ background: "#2F3C54", color: "#a9adba", marginBottom: "5px", width: `20px`, height: `20px` }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                style={{ color: "#fff", border: "1px solid #2F3C54", background: "#2F3C54", borderRadius: 15 }}
                placeholder="Title for this Article"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                defaultValue={blog.name}
                name="name"
                id="name"
              />
            </InputGroup>
          </Col>
          <input type="hidden" name="sequence" defaultValue={1} id="sequence" placeholder="Enter Blog sequence" required />
          <Col xs="12" sm="6" md="12" className="mb-3">
            <SunEditor
              setContents={blog.description}
              onChange={changeDescription}
              placeholder="Content of the Article here"
              style={{ color: "#fff" }}
              setOptions={{
                mode: "classic", //balloon-always
                defaultStyle: "font-family: 'Arial'; font-size: 18px",
                maxHeight: "450px",
                height: 300,
                buttonList: [
                  [
                    "undo",
                    "redo",
                    "font",
                    "fontSize",
                    "formatBlock",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "removeFormat",
                    "fontColor",
                    "hiliteColor",
                    "outdent",
                    "indent",
                    "align",
                    "horizontalRule",
                    "list",
                    "table",
                    "link",
                    "image",
                    "video",
                    "print",
                    "codeView",
                  ],
                ],
              }}
            />
          </Col>
          <Col>
            <div class="badge badge-secondary">Reading time: {stat.humanizedDuration}</div>{" "}
          </Col>
          <Col className="d-flex justify-content-end">
            <button
              type="submit"
              className="border_on_hover"
              style={{
                backgroundColor: "#586886",
                border: "1px solid #586886",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "4px",
                paddingTop: "5px",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "23px",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              Save
            </button>
          </Col>
        </Form>
      </Col>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default EditBlog
